import JSON5 from "json5";

const fixJSONQuotes = (string, isRecursive = false, silent = false) => {
    const fixed = string.replace(/(\s*[\w]+\s*):/g, (match, p1) => `"${p1}":`);
    if (!isRecursive) {
        try {
            return masterJSONFixer(fixed, true);
        } catch (e) {
            if (!silent) {
                console.error(e, "Fixing JSON string did nothing");
            }
            return string;
        }
    }
    return fixed;
};

const makeJSONReadable = json => {
    let str = JSON.stringify(json);
    try {
        str = str
            .replace(/{|}|"/g, "")
            .replace(/_/g, " ")
            .replace(/,/g, "\n")
            .replace(/:\s*\[/g, ":\n[")
            .replace(/\],/g, "],\n")
            .replace(/\[|]/g, "");
    } catch (e) {
        console.error(e);
    }
    return str;
};

const processJSON = async responses => {
    if (typeof responses !== "string") {
        console.log("parsed json the first time");
        return responses;
    }
    try {
        return JSON5.parse(responses);
    } catch (e) {
        console.error("JSON parsing failed, moving on to substring method", e);
    }

    let json5StringMatch = jsonSubstring(responses);
    let json;

    if (json5StringMatch && isValidJSON(json5StringMatch)) {
        json = JSON5.parse(json5StringMatch);
        return json;
    }

    try {
        json = masterJSONFixer(responses);
        if (json) {
            return json;
        }
    } catch (e) {
        console.error("Master JSON fixer failed:", e);
    }

    let fixAttempt1 = fixJSONQuotes(responses);

    if (fixAttempt1 && isValidJSON(fixAttempt1)) {
        json = JSON.parse(fixAttempt1);
    } else {
        const formattedExample = JSON5.stringify(responses, null, 0);
        json5StringMatch = await fixJSON(json5StringMatch, formattedExample);

        if (isValidJSON(json5StringMatch)) {
            json = JSON5.parse(json5StringMatch);
        } else {
            throw new Error("Unable to fix JSON from response");
        }
    }

    if (Array.isArray(json)) {
        json = json[0];
    }

    let finalState = json.content ? json.content : json;
    return finalState;
};

const fixAndParseJSON = (string, silent) => {
    const fixedQuotes = string.replace(/(\s*[\w]+\s*):/g, (match, p1) => `"${p1}":`);
    return parseJSON(fixedQuotes);
};

const isValidJSON = string => {
    try {
        JSON5.parse(string);
        return true;
    } catch (e) {
        return false;
    }
};

const parseJSON = string => {
    try {
        return JSON.parse(string);
    } catch (e) {
        try {
            return JSON5.parse(string);
        } catch (e) {
            return null;
        }
    }
};

const masterJSONFixer = (string, isRecursive = false, silent) => {
    if (typeof string === "object") {
        return string;
    }
    if (Array.isArray(string)) {
        return string;
    }
    let newString = jsonSubstring(string, silent);
    const maxAttempts = 3;
    let parsedResult = null;

    for (let attempts = 0; attempts < maxAttempts; attempts++) {
        if (attempts > 0) {
            newString = fixJSONQuotes(newString, isRecursive, silent);
        }
        parsedResult = parseJSON(newString);
        if (parsedResult !== null) {
            break;
        }
    }
    console.groupCollapsed("JSON Fixer");
    console.log(parsedResult);
    console.groupEnd();
    return parsedResult || {};
};

const jsonSubstring = (str, silent = false) => {
    const jsonRegex = /\[?\s*({(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}\s*,?\s*)+\]?/;
    const jsonMatch = str.match(jsonRegex);

    if (jsonMatch) {
        return jsonMatch[0];
    } else {
        if (!silent) {
            console.error("No JSON objects or arrays found in the input string");
        }
        return false;
    }
};

const checkStreamingJSON = string => {
    let args = string;
    let stack = [];
    let balance = { '"': 0, "{": 0, "[": 0 };
    for (let i = 0; i < args.length; i++) {
        switch (args[i]) {
            case '"':
                balance['"']++;
                if (balance['"'] % 2 === 0) {
                    stack.pop();
                } else {
                    stack.push('"');
                }
                break;
            case "{":
                balance["{"]++;
                stack.push("{");
                break;
            case "}":
                if (stack[stack.length - 1] === "{") {
                    balance["{"]--;
                    stack.pop();
                }
                break;
            case "[":
                balance["["]++;
                stack.push("[");
                break;
            case "]":
                if (stack[stack.length - 1] === "[") {
                    balance["["]--;
                    stack.pop();
                }
                break;
        }
    }

    if (balance['"'] % 2 !== 0) {
        args += '"';
        stack.pop();
    }
    while (stack.length > 0) {
        let last = stack[stack.length - 1];
        if (last === "{") {
            args += "}";
            balance["{"]--;
        } else if (last === "[") {
            args += "]";
            balance["["]--;
        }
        stack.pop();
    }

    return args;
};

const logInvalidJSON = string => {
    console.groupCollapsed("%c ❌ Invalid JSON", fail);
    console.log(string);
    console.groupEnd();
};
const logValidJSON = string => {
    console.groupCollapsed("%c ✅ Valid JSON", success);
    console.log(string);
    console.groupEnd();
};
const validateJSON = string => {
    let validJSONObject;
    try {
        let args = checkStreamingJSON(string);
        let fixAttempt = JSON5.parse(args);
        validJSONObject = fixAttempt;
        // logValidJSON(validJSONObject);
        return validJSONObject;
    } catch (e) {
        // logInvalidJSON(string);
    }
};
const excludeJSON = string => {
    const jsonRegex = /{[^]*?}(?=\s|$)/g;
    const match = string.match(jsonRegex);
    return match ? string.replace(jsonRegex, "").trim() : string;
};

const jsonString = (string, errorString) => {
    const json = string;
    let trimmedText = json.replace(/[\n\r]+/g, "").replace(/\s{2,}/g, " ");
    try {
        const parsed = JSON.parse(trimmedText);
        return parsed;
    } catch (e) {
        if (errorString) {
            console.error(errorString);
        } else {
            console.error(trimmedText);
            console.error("[JSON ERROR]:", trimmedText);
        }
        let list = parseList(string);
        if (list) {
            list = list.slice(1, 100);
            return list;
        } else {
            return false;
        }
        return trimmedText;
    }
};

export default {
    fixJSONQuotes,
    makeJSONReadable,
    processJSON,
    fixAndParseJSON,
    isValidJSON,
    parseJSON,
    masterJSONFixer,
    jsonSubstring,
    checkStreamingJSON,
    validateJSON,
    excludeJSON,
    jsonString,
};
