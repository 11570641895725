import { mapActions } from "vuex";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            save_summary: {
                name: "Save Summary",
                command: "save_summary",
                kind: "summary",
                start: "[SST]",
                end: "[SND]",
                role: "summary",
                args: { summary: "" },
                description: "Save a summary by providing a 'summary' argument.",
                show: false,
                newMessage: true,
            },
            // trigger_summarize: {
            //     name: "Trigger Summarize",
            //     command: "trigger_summarize",
            //     kind: "summary",
            //     start: "[TXT]",
            //     end: "[TXD]",
            //     role: "text",
            //     args: { summary: "" },
            //     description: "Summarize text by providing a 'text' argument.",
            //     show: false,
            //     newMessage: false,
            // },
            // save_summary: {
            //     name: "Summarize Chat",
            //     command: "save_summary",
            //     start: "[SST]",
            //     end: "[SND]",
            //     kind: "summary",
            //     role: "system",
            //     args: { summary: "" },
            //     description: "Trigger the summarization function by enclosing the text you want to summarize between [SST] and [SND] tags.",
            //     show: false,
            //     newMessage: false,
            // },
        };
    },
    computed: {
        chatSummaryPrompt() {
            return `You are an AI tasked with building a memory for the following conversation. Analyze the messages, and create a structured JSON object that stores meaningful preferences or other relevant information. Ignore any message content that is not important or relevant. Here are the messages:\n
[CONTEXT MEMORY] (memory is not limited to this)
${JSON.stringify(this.memoryContext, null, 2)}\n
Based on this conversation, please return a JSON object containing the important details.
`;
        },
        summaryString() {
            let string = "";
            let summaries = this.$store.state.chat.summary;
            let summary = summaries[summaries.length - 1];
            if (summaries.length > 0) {
                string = `\n\n## Summary:\n`;
                string += summary.event;
                string += "\n";
            }
            return string;
        },
        messagesSinceLastSummary() {
            let lastIndex = 0;
            if (this.$store.state.chat.lastSummaryIndex > 0) {
                lastIndex = this.$store.state.chat.lastSummaryIndex;
            }
            let messages = this.$store.state.chat.messages;
            let messagesSinceLastSummary = messages.slice(lastIndex);
            // map messages so they only have role and content
            // messagesSinceLastSummary = messagesSinceLastSummary.map(message => {
            //     return {
            //         role: message.role,
            //         content: message.content,
            //         action: message.action
            //     };
            // };
            return messagesSinceLastSummary;
        },
        shouldSummarize() {
            let lastSummaryIndex = 0;
            let lastMessageIndex = 0;
            let delta = 0;
            if (this.$store.state.chat.lastSummaryIndex > 0) {
                lastSummaryIndex = this.$store.state.chat.lastSummaryIndex;
            }
            if (this.$store.state.chat.messages.length > 0) {
                lastMessageIndex = this.$store.state.chat.messages.length;
            }
            delta = lastMessageIndex - lastSummaryIndex;
            return delta >= 5;
        },
    },
    methods: {
        ...mapActions("chat", ["addToSummaryStore"]),
        async getTokensSinceLastSummary() {
            // based on message history since last summary. Get the last "system" message and count the tokens for every message after that. Make sure it's the most recent system message in case the user has saved multiple summaries.
            const lastSummary = this.messages.filter(message => message.type === "system").pop();
            const lastSummaryIndex = this.messages.indexOf(lastSummary);
            const messagesSinceLastSummary = this.messages.slice(lastSummaryIndex + 1);
            const tokensSinceLastSummary = messagesSinceLastSummary.reduce((acc, message) => {
                return acc + message.text.split(" ").length;
            }, 0);
            let tokens = await this.getTokenCount(tokensSinceLastSummary);
            return tokens;
        },
        async summarizeChat() {
            const prompt = this.chatSummaryPrompt;
            let summary = await this.completion(prompt, "Follow user instructions to summarize the chat", "summarize chat");
            if (summary) {
                this.addToSummaryStore(summary);
            }
            return summary;
        },
        async trySummary() {
            if (this.shouldSummarize) {
                await this.summarizeChat();
            }
        },
        async saveSummary(args, name, message, index) {
            await this.summarizeChat();
            return;
            // return content;
        },
        triggerSummarize(args, name, message, index) {
            // Implement the "trigger_summarize" command here
            return;
        },
    },
};
