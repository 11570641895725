<template>
    <a v-if="shimmer" :class="[` ${sizeClass} relative inline-flex overflow-hidden rounded-full p-[2px] focus:outline-none focus:ring-2 focus:ring-${color}-400 focus:ring-offset-2 focus:ring-offset-${color}-50 `, { 'h-12': size === 'md' }]">
        <span class="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
        <span :class="`z-1 relative inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full  ${sizeClass} font-medium backdrop-blur-3xl text-${color}-500 f aic jcc gap-2 bg-${shimmerBg} `"><slot></slot></span>
        <!--      px-3 py-1-->
    </a>
    <button v-else-if="link" :class="buttonClasses" class="cursor-pointer f aic gap-3 jcc transition-all !shadow hover:!shadow-high hover:shadow-amber-500 group">
        <slot></slot>
        <span class="group-hover:-translate-x-1 transition-transform" aria-hidden="true">→</span>
    </button>
    <button v-else :class="buttonClasses" @mouseenter="hovered = true" @mouseleave="hovered = false" class="cursor-pointer f aic gap-3 jcc transition-all !shadow hover:!shadow-high hover:shadow-amber-500">
        <transition name="fade-upsimple">
            <ToolTip class="isolate" :show-tooltip="showTooltip" :tooltip="tooltip" />
        </transition>
        <slot></slot>
    </button>
</template>

<script>
import ToolTip from "@/components/CoreUI/ToolTip.vue";

export default {
    name: "SimpleButton",
    components: { ToolTip },
    props: {
        color: { type: String, default: "black" },
        size: {
            type: String,
            default: "md",
            validator: value => ["xs", "sm", "md", "lg", "xl"].includes(value),
        },
        link: { type: Boolean, default: false },
        rounded: { type: Boolean, default: false },
        soft: { type: Boolean, default: false },
        primary: { type: Boolean, default: false },
        secondary: { type: Boolean, default: false },
        shimmer: { type: Boolean, default: false },
        shimmerBg: { type: String, default: "snow" },
        tooltip: { type: String, default: "" },
    },
    data() {
        return {
            hovered: false,
        };
    },
    computed: {
        showTooltip() {
            if (this.tooltip && this.hovered) return true;
            return false;
        },
        buttonClasses() {
            if (this.link) return [`text-${this.color}-500`, `hover:text-${this.color}-600`, `focus-visible:outline-${this.color}-500`, "focus-visible:outline-offset-2", "font-semibold"];
            return [this.roundedClass, this.sizeClass, this.colorClass, "fwsb", "focus-visible:outline", "focus-visible:outline-2", "focus-visible:outline-offset-2"];
        },
        roundedClass() {
            return this.rounded ? " rounded-full " : "br-5";
        },
        sizeClass() {
            let size = this.size;
            if (this.shimmer) {
                return {
                    "h-8 ": size === "xs",
                    " h-10 ": size === "sm",
                    " h-12 ": size === "md",
                    " h-14 ": size === "lg",
                };
            }
            return {
                "px-3 py-1 f-13": size === "xs",
                "px-3 py-1 f-15": size === "sm",
                "px-4 py-2.5 f-17": size === "md",
                "px-3 py-2 f-24": size === "lg",
                "px-3.5 py-2.5 f-30": size === "xl",
            };
        },
        colorClass() {
            let color = this.color;
            if (this.soft) {
                return [`bg-${color}-50 text-${color}-600 hover:bg-${color}-100 border border-${color}-600/30 scale-99`, `dark:bg-${color}-900/10 dark:text-${color}-300 dark:hover:bg-${color}-900/20`].join(" ");
            } else if (this.secondary) {
                return ["bg-snow text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100", "dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:focus-visible:outline-white"].join(" ");
            } else if (this.primary || (!this.primary && !this.secondary)) {
                return [`bg-${color}-600 text-snow hover:bg-${color}-500 focus-visible:outline-${color}-600`, `dark:bg-${color}-500 hover:dark:bg-${color}-400 dark:focus-visible:outline-${color}-500`].join(" ");
            } else {
                return "bg-red-800";
            }
        },
    },
};
</script>
<style lang="scss">
.textTest {
    .gradient {
        background: conic-gradient(from 90deg at 50% 50%, #e2cbff 0%, #393bb2 50%, #e2cbff 100%);
    }
    @apply h-8;
    @apply h-10;
    @apply h-12;
    @apply bg-red-50
    bg-red-100
    bg-red-200
    bg-red-300
    bg-red-400
    bg-red-500
    bg-red-600
    bg-red-700
    bg-red-800
    bg-red-900;
    @apply hover:bg-red-50
    hover:bg-red-100
    hover:bg-red-200
    hover:bg-red-300
    hover:bg-red-400
    hover:bg-red-500
    hover:bg-red-600
    hover:bg-red-700
    hover:bg-red-800
    hover:bg-red-900;
}
</style>
