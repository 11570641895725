<template>
    <div class="mb-md-4 mb-2" v-if="sights">
        <!--        <h5>Other ideas</h5>-->
        <div class="f block whitespace-pre-wrap" v-for="(item, index) in staggeredSights">
            <related-questions class="x border-bottom relative" :title="item.title" :open-override="index === 0">
                <Sights class="p-0" :col="4" :sights="item.items" />
            </related-questions>
        </div>
    </div>
</template>

<script>
import RelatedQuestions from "@/components/chat/specialMessages/Search/RelatedQuestions.vue";
import Sights from "@/components/chat/specialMessages/Search/Sights.vue";

export default {
    name: "PeopleAlsoSearched",
    components: { RelatedQuestions, Sights },
    props: {
        sights: {
            type: Array,
            required: true,
        },
        animate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            staggeredSights: [],
        };
    },
    watch: {
        sights: {
            immediate: true,
            handler(newSights) {
                this.staggerSights(newSights);
            },
        },
    },
    methods: {
        async staggerSights(sights) {
            this.staggeredSights = [];

            if (this.animate) {
                for (let i = 0; i < sights.length; i++) {
                    await this.$nextTick();
                    setTimeout(() => {
                        this.staggeredSights.push(sights[i]);
                    }, i * 300); // Adjust delay time (in ms) to your preference
                }
            } else {
                this.staggeredSights = sights.slice(); // Copy the sights array without animation
            }
        },
    },
};
</script>

<style lang="scss">
hr {
  @apply my-5;
  // style the hr
  border-top: 1px solid;
  @apply border-t border-gray-100;
  @apply dark:border-base-500;
}
</style>
