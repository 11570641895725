// imagestore.js

import getAIModels from "@/mixins/firebase/getAIModels";
import { updateDropdownValue } from "@/store/StoreHelpers";

export default {
    namespaced: true,
    state() {
        return {
            stability_options: {
                engines: [
                    {
                        name: "Real-ESRGAN x2",
                        id: "esrgan-v1-x2plus",
                        type: "PICTURE",
                        show: false,
                    },
                    {
                        name: "Stable Diffusion v1.4",
                        id: "stable-diffusion-v1",
                        type: "PICTURE",
                        show: false,
                    },
                    {
                        name: "Stable Diffusion v1.5",
                        id: "stable-diffusion-v1-5",
                        type: "PICTURE",
                        show: false,
                    },
                    {
                        name: "Stable Diffusion v2.0",
                        id: "stable-diffusion-512-v2-0",
                        type: "PICTURE",
                        show: true,
                    },
                    {
                        name: "Stable Diffusion v2.0-768",
                        id: "stable-diffusion-768-v2-0",
                        type: "PICTURE",
                        show: true,
                    },
                    {
                        name: "Stable Diffusion v2.1",
                        id: "stable-diffusion-512-v2-1",
                        type: "PICTURE",
                        show: true,
                    },
                    {
                        name: "Stable Diffusion v2.1-768",
                        id: "stable-diffusion-768-v2-1",
                        type: "PICTURE",
                        show: true,
                    },
                    {
                        name: "Stable Diffusion v2.2.2-XL Beta",
                        id: "stable-diffusion-xl-beta-v2-2-2",
                        type: "PICTURE",
                        show: true,
                    },
                    {
                        name: "Stable Inpainting v1.0",
                        id: "stable-inpainting-v1-0",
                        type: "PICTURE",
                        show: true,
                    },
                    {
                        name: "Stable Inpainting v2.0",
                        id: "stable-inpainting-512-v2-0",
                        type: "PICTURE",
                        show: true,
                    },
                ],
                style_presets: ["enhance", "anime", "photographic", "digital-art", "comic-book", "fantasy-art", "line-art", "analog-film", "neon-punk", "isometric", "low-poly", "origami", "modeling-compound", "cinematic", "3d-model", "pixel-art", "tile-texture"],
                clip_guidance_presets: ["FAST_BLUE", "FAST_GREEN", "NONE", "SIMPLE", "SLOW", "SLOWER", "SLOWEST"],
                samplers: ["DDIM", "DDPM", "K_DPMPP_2M", "K_DPMPP_2S_ANCESTRAL", "K_DPM_2", "K_DPM_2_ANCESTRAL", "K_EULER", "K_EULER_ANCESTRAL", "K_HEUN", "K_LMS"],
                //      fill out the rest of the options
                sizes: {
                    // give fixed size options for users based on the models
                    "stable-diffusion-768-v2-0": generateSizes({
                        minSize: 589824,
                        maxSize: 1048576,
                        is768Engine: true,
                    }),
                    "stable-diffusion-768-v2-1": generateSizes({
                        minSize: 589824,
                        maxSize: 1048576,
                        is768Engine: true,
                    }),
                    "stable-diffusion-512-v2-0": generateSizes({
                        minSize: 262144,
                        maxSize: 1048576,
                        is768Engine: false,
                    }),
                    "stable-diffusion-512-v2-1": generateSizes({
                        minSize: 262144,
                        maxSize: 1048576,
                        is768Engine: false,
                    }),
                    "stable-diffusion-xl-beta-v2-2-2": generateSizes({
                        minSize: 262144,
                        maxSize: 1048576,
                        is768Engine: false,
                    }),
                },
            },
            selected_model: "stable-diffusion-768-v2-0",
            negativePrompt: "blurry, abstract, disfigured, deformed, cartoon, animated, toy, figure, framed, 3d, hands, nude, cartoon, 3d, disfigured, bad art, deformed, poorly drawn, extra limbs, close up, b&w, weird colors, blurry, watermark",
            stability: {
                style_preset: "enhance",
                engine: "stable-diffusion-768-v2-0",
                cfg_scale: 7,
                height: 512,
                width: 512,
                step: 50,
                clip_guidance_preset: "NONE",
                sampler: null,
                samples: 1,
                seed: 0,
                steps: 50,
            },
        };
    },
    getters: {
        getModelSizes: state => model => {
            // if (state.images && state.images.stability_options && state.images.stability_options.sizes) {
            const sizes = state.images.stability_options.sizes[model];
            if (sizes) {
                return sizes;
            }
            // }
            return [state.images.stability_options.sizes[0]];
        },
    },
    mutations: {
        updateDropdownValue,
        setDefaultModel(state, engine) {
            state.stability.engine = engine;
            let objString = JSON.stringify(engine);
            localStorage.setItem("stableDiffusionModel", objString);
            // console.error("Changed Stability Model", this.StableDiffusionModel);
        },
        // setImageModels(state, payload) {
        //     state.imageModels = payload;
        // },
    },
    actions: {
        async setDefaultEngineModel(context) {
            // console.groupCollapsed("SD - Get Models");
            // console.error(context);
            // console.groupEnd();
            let models;
            try {
                models = getAIModels.methods.getStableDiffusionEngines();
            } catch (e) {
                console.error(e);
            }
            // console.groupEnd();
            // console.groupEnd();
            if (models && models.length > 0) {
                // Set the default model to the first one in the list
                let defaultModel = models[7];
                // default model to local storage
                try {
                    if (localStorage.getItem("StableDiffusionDefault") === null) {
                        localStorage.setItem("StableDiffusionDefault", JSON.stringify(defaultModel));
                    } else {
                        defaultModel = localStorage.getItem("StableDiffusionDefault");
                        try {
                            defaultModel = JSON.parse(defaultModel);
                        } catch (e) {
                            console.groupCollapsed("SDModel");
                            console.log(e, defaultModel);
                            console.groupEnd();
                        }
                    }

                    context.commit("setDefaultModel", defaultModel);
                    // cache the list of models
                    // context.commit("setImageModels", models.models);
                    // save models to firestore
                    const selectedModel = defaultModel;
                } catch (e) {
                    console.error(e);
                }
                // console.groupCollapsed("SD - Set Default");
                // console.error("selectedModel", selectedModel.id);
                // console.groupEnd();
                // console.error("allModels", models);
            }
            // console.groupEnd();
        },
    },
};
function generateSizes(constraints) {
    const sizes = [];

    const aspectRatios = {
        "7:4": 7 / 4,
        "3:2": 3 / 2,
        "4:3": 4 / 3,
        "5:4": 5 / 4,
        "1:1": 1,
        "4:5": 4 / 5,
        "3:4": 3 / 4,
        "2:3": 2 / 3,
        "4:7": 4 / 7,
    };

    Object.keys(aspectRatios).forEach(aspectName => {
        const aspectRatio = aspectRatios[aspectName];
        let width = Math.round(Math.sqrt(constraints.minSize / aspectRatio));
        let height = Math.round((width * aspectRatio) / 64) * 64;

        if (constraints.is768Engine) {
            if (aspectRatio >= 1) {
                width = Math.max(768, Math.round(width / 64) * 64);
                height = Math.round((width * aspectRatio) / 64) * 64;
            } else {
                height = Math.max(768, Math.round(height / 64) * 64);
                width = Math.round(height / aspectRatio / 64) * 64;
            }
        } else {
            if (aspectRatio >= 1) {
                width = Math.max(512, Math.round(width / 64) * 64);
                height = Math.round((width * aspectRatio) / 64) * 64;
            } else {
                height = Math.max(512, Math.round(height / 64) * 64);
                width = Math.round(height / aspectRatio / 64) * 64;
            }
        }

        if (isValidPixelCount(height, width, constraints.is768Engine)) {
            sizes.push({
                name: aspectName,
                width,
                height,
            });
        }
    });

    function isValidPixelCount(height, width, is768Engine) {
        const pixelCount = height * width;
        if (is768Engine) {
            return 589824 <= pixelCount && pixelCount <= 1048576;
        } else {
            return 262144 <= pixelCount && pixelCount <= 1048576;
        }
    }

    return sizes;
}
