<template>
    <div class="p-3">
        <file-uploader></file-uploader>
        <div class="x mx-auto p-0 mw-1100">
            <h4>Sources</h4>
            <p>These are links we'll be syncing your brand's information from.</p>
            <div class="mt-5 x mw-550 text-center mx-auto">
                <div class="f">
                    <BaseInput v-model="newSource.url" class="x mb-3" iclass="!f-20" inline placeholder="Enter a URL to add a source" @keyup.enter="addSource(newSource.url)" />
                </div>
                <div class="f jcc aic gap-3 transition-all">
                    <transition mode="out-in" name="fade-up">
                        <div v-if="detectShopifyURL" class="text-center">
                            <BaseButton class="button-green text-snow bg-green-700 text-center pl-0" style-type="primary" @click.prevent="setAsStore(newSource.url)">Set as store</BaseButton>
                            <p class="mb-0 mt-3">This will make it easier to sync products later.</p>
                            <p class="text-gray-300 mb-0 text-base">— or —</p>
                            <a class="text-blue-500 text-base" href="#">Add as source instead</a>
                        </div>
                        <BaseButton v-else class="text-gray-900 button-blue text-center pl-0" style-type="primary" @click.prevent="addSource(newSource.url)">Add source</BaseButton>
                    </transition>
                </div>
            </div>
            <div class="">
                <div class="py-2 f gap-2 jcs x aic">
                    <div v-if="shopifyURL" class="f aic jcc gap-4">
                        <div class="bg-green-400 c-30 f aic jcc"><i class="fas fa-xs white fa-check"></i></div>
                        <div class="fc f gap-1">
                            <span class="fwb f-20">Store detected</span>
                            <a :href="shopifyURL" class="text-blue-400 f-15">{{ shopifyURL }}</a>
                        </div>
                    </div>
                </div>
                <div class="pb-3 p-0 relative overflow-x-scroll scrollbar-hide">
                    <!--                    <div v-for="(item, index) in sources"><SourceProgressItem :item="item"></SourceProgressItem></div>-->
                    <!--                    <template v-for="(item, index) in products">-->
                    <!--                        <TableRowWithDetails>-->
                    <!--                            <template #title>-->
                    <!--                                <SourceItem :key="`item` + index" :item="item" kind="website" :title="item.title" :updated="item.updated || null" :url="item.url" class="bg-snow" @sync-source="addSource" @remove-source="removeSource" />-->
                    <!--                            </template>-->
                    <!--                            <div>dsjksdjs</div>-->
                    <!--                        </TableRowWithDetails>-->
                    <!--                    </template>-->
                    <!--                    <template class="x" v-for="(item, index) in sources">-->
                    <!--                        <TableRowWithDetails>-->
                    <!--                            <template #title>-->
                    <!--                                <SourceItem :key="`item` + index" :item="item" kind="website" :title="item.title" :updated="item.updated || null" :url="item.url" class="bg-snow" @sync-source="addSource" @remove-source="removeSource" />-->
                    <!--                            </template>-->
                    <!--                        </TableRowWithDetails>-->
                    <!--                    </template>-->
                    <table class="br-10 border !border-gray-100 shadow width-min-900">
                        <thead class="bg-gray-50">
                            <tr class="bg-gray-50">
                                <th class="p-3">Title</th>
                                <th class="p-3">Source link</th>
                                <th class="p-3 text-center">Source type</th>
                                <th class="p-3 text-center">Updated</th>
                                <th class="p-3 text-center">Resync</th>
                            </tr>
                        </thead>

                        <tbody class="x">
                            <SourceItem v-for="(item, index) in sources" :key="`item` + index" :item="item" :title="item.title" :updated="item.updated || null" :url="item.url" class="bg-snow" kind="website" @clicked="previewItem" @sync-source="addSource" @remove-source="removeSource" />
                            <SourceItem v-for="(item, index) in products" :key="`item` + index" :item="item" :title="item.title" :updated="item.updated || null" :url="item.url" class="bg-snow" kind="product" @clicked="previewItem" @sync-source="addSource" @remove-source="removeSource" />
                            <!--                            <SourceItem v-for="(item, index) in combinedSources" :key="`item` + index" :item="item" :kind="item.kind" :title="item.title" :updated="item.updated || null" :url="item.url" class="bg-snow" @sync-source="addSource" @remove-source="removeSource" />-->
                            <!--                            <SourceItem v-for="(item, index) in combinedSources" :key="`item` + index" :item="item" :kind="item.kind" :title="item.title" :updated="item.updated || null" :url="item.url" class="bg-snow" @sync-source="addSource" @remove-source="removeSource" />-->
                            <!--                            <SourceItem v-for="(item, index) in files" :item="item" :key="`item` + index" :status="item.status" :size="item.size" :kind="item.kind" :title="item.title" :updated="item.updated || null" :url="item.url" class="bg-snow" @sync-source="addSource" @remove-source="removeFile" />-->
                        </tbody>
                    </table>
                </div>
                <UISlideOver :item="preview" :open="preview ? true : false" @close="preview = null" />
            </div>
        </div>
    </div>
</template>

<script>
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import SourceItem from "@/components/Admin/SourceItem.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import FileUploader from "@/components/images/fileUploader.vue";
import webRequest from "@/mixins/ai/web_request";
import { mapGetters } from "vuex";
import UISlideOver from "@/components/Admin/UISlideOver.vue";

export default {
    components: { UISlideOver, FileUploader, BaseInput, BaseButton, SourceItem },
    mixins: [StyleGuideMixin],
    data() {
        return {
            preview: null,
            newSource: {
                title: "",
                url: "",
                kind: "Website",
            },
        };
    },
    computed: {
        ...mapGetters("stream", ["files"]),
        ...mapGetters("styleGuide", ["products", "sources"]),
        shopifyURL() {
            if (this.styleGuide?.shopify) {
                return this.styleGuide.shopify;
            }
        },
        detectShopifyURL() {
            if (this.newSource.url.includes(".com")) {
                let stringsToCheck = ["shopify", "myshopify", "shop."];
                let shopifyURL;
                stringsToCheck.forEach(string => {
                    if (this.newSource.url.includes(string)) {
                        shopifyURL = this.newSource.url;
                    }
                });
                return shopifyURL;
            }
        },
        combinedSources() {
            if (!this.styleGuide?.additionalText) return [];
            let styleGuideSources = [];
            let productSources = [];
            try {
                styleGuideSources = this.sources.map(source => {
                    source.kind = "Website";
                    if (source?.content?.length) {
                        source.title = source.content.split("\n")[0];
                    } else {
                        source.title = source.url;
                    }
                    if (!source.updated && source?.product?.updated) {
                        source.updated = source.product.updated;
                    }
                    return source;
                });
            } catch (e) {
                console.log(e);
            }
            try {
                productSources = this.products.map(source => {
                    source.kind = "Product";
                    return source;
                });
            } catch (e) {
                console.log(e);
            }
            let combinedSources = [...styleGuideSources, ...productSources];
            if (this.styleGuide.shopify) {
                let object = {
                    kind: "Shopify",
                    title: "Shopify Store",
                    url: this.styleGuide.shopify,
                };
                combinedSources.unshift(object);
            }
            return combinedSources;
        },
    },
    async mounted() {
        await this.getFiles();
    },
    methods: {
        previewItem(item) {
            this.preview = item;
        },
        async getFiles() {
            let files = await this.listFiles();
            this.updateStreamProp("options.files", files);
        },
        async setAsStore(url) {
            // this.updateStyleGuideProp("shopify", url);
            // await this.updateSection("hasShopify");
            await this.createStyleGuide(this.styleGuide.url, this.$route.params.id, {
                sections: ["hasShopify"],
                url_to_scrape: url,
            });
        },
        async addSource(url) {
            if (this.styleGuide?.shopify === url) {
                return await this.updateGuideSection(["products"]);
            }
            await this.createStyleGuide(this.styleGuide.url, this.$route.params.id, {
                sections: ["sourceSync"],
                url_to_scrape: url,
            });
            // Assuming you have an API endpoint to add a source
        },
        async removeFile(id) {
            let response = await webRequest("files/delete", { id });
            let data = await response.json();
            // splice the file from the files array
            this.updateStreamProp(
                "options.files",
                this.files.filter(file => file.id !== id),
            );
            // await this.getFiles();
            console.log(data);
        },
        async removeSource(url) {
            if (url.startWith("file-")) {
                return await this.removeFile(url);
            }
            this.$store.dispatch("styleGuide/deleteSource", url);
            // find the source in the styleGuide.textSamples
            // let sources = this.styleGuide.additionalText;
            // let source = this.styleGuide.additionalText.find(source => source.url === url);
            // remove the source from the styleGuide.textSamples
            // sources.splice(sources.indexOf(source), 1);

            // Assuming you have an API endpoint to remove a source
        },
    },
};
</script>
<style lang="scss" scoped>
table {
    //border: 1px solid black;
}

thead {
    //border: 1px solid black !important;
    @apply border border-solid border-b;
    @apply border-gray-200;
}

th {
    border: 0px !important;
}

th,
tr {
}

.container {
    overflow-x: auto;
    max-width: 100%;
}

table {
    border-collapse: collapse;
}

thead {
    @apply bg-gray-100;
}

th,
td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e5e7eb;
    @apply border-gray-200;
    @apply border-b-gray-200;
}

th {
    font-weight: 500;
}

tbody tr:nth-child(odd) {
    @apply bg-gray-50;
}

tbody tr:hover {
    @apply bg-gray-100/80;
    cursor: pointer;
}

@media (max-width: 768px) {
    th,
    td {
        padding: 8px;
    }
}
</style>
