<template>
    <div class="relative">
        <div ref="trigger">
            <div ref="container">
                <h1>Hello World</h1>
                <!--                <slot></slot>-->
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const { timeline, set, to, from } = gsap;
export default {
    name: "FadeUpContainer",
    data() {
        return {
            scrollTriggerInstance: null,
        };
    },
    async mounted() {
        await this.$nextTick();
        setTimeout(() => {
            this.timeliner();
        }, 2000);
    },
    beforeUnmount() {
        if (this.scrollTriggerInstance) this.scrollTriggerInstance.kill();
    },
    methods: {
        timeliner() {
            let trigger = this.$refs.trigger;
            let container = this.$refs.container;
            set(container, { opacity: 0, y: -50 });
            let tl = timeline({
                scrollTrigger: {
                    trigger,
                    start: "top center",
                    end: "+=1000",
                    scrub: true,
                },
            });
            this.scrollTriggerInstance = tl.scrollTrigger;
            let fadeIn = { opacity: 0, y: 50 };
            let fadeInEnd = { opacity: 1, y: 0 };
            tl.fromTo(container, { opacity: 0, y: 0 }, { opacity: 1, y: 0, ease: "power1.easeOut" });
        },
    },
};
</script>
