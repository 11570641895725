<template>
    <div v-for="(message, index) in messages" :key="message.content" class="!f-13 preview-md" style="font-size: 13px !important">
        <template v-if="message.role === 'user'">
            <h6>User</h6>
            <ChatUserMessage :index="index" :m="message" style="font-family: system-ui !important" class="!f-15 br-20 !bg-gray-50 border-light" hide-avatar />
        </template>
        <template v-else-if="message.role === 'system'">
            <h6>System</h6>
            <ChatUserMessage :index="index" :m="message" class="!f-15 br-10 border-light !bg-sky-200" hide-avatar />
        </template>
        <template v-else-if="message.role === 'assistant'">
            <h6>Assistant</h6>
            <ChatUserMessage :index="index" :m="message" hide-avatar />
        </template>
        <!--                <ChatUserMessage v-if="message.role === 'system'" :key="message.content" hide-avatar class="!f-15 br-10 border-light !bg-sky-200" :index="index" :m="message" />-->
        <!--                <ChatUserMessage v-if="message.role === 'assistant'" :key="message.content" hide-avatar :index="index" :m="message" />-->
    </div>
</template>
<script>
import ChatUserMessage from "@/components/chat/messages/ChatUserMessage.vue";

export default {
    name: "MessageSet",
    components: { ChatUserMessage },
    props: {
        messages: {},
    },
};
</script>
<style lang="scss">
.preview-md {
    font-family: system-ui !important;
    ul,
    ol {
        @apply ml-3 mb-0 pb-0 pl-3;
        line-height: normal;

        li {
            @apply pl-0 mb-0 py-1 leading-normal;
            line-height: 1;
            padding-bottom: 0;
        }
    }

    .preview-md {
        h1,
        h2 {
            font-size: 0.5em !important;
        }
    }
}
</style>
