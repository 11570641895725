<template>
    <div class="text-center -mt-16">
        <div class="mx-auto h-12 w-12 text-gray-400">
            <div v-if="icon">
                <i :class="`fal fa-${icon} fa-2x`"></i>
            </div>
            <svg v-else aria-hidden="true" class="" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" vector-effect="non-scaling-stroke" />
            </svg>
        </div>
        <div class="text-center">
            <h3 class="mt-2 text-sm font-semibold text-gray-900">{{ title }}</h3>
            <p class="mt-1 text-sm text-gray-500">{{ description }}</p>
        </div>
        <div class="mt-3 x mx-auto text-center" v-if="button">
            <SimpleButton class="mx-auto" @click="$emit('clicked')">
                <PlusIcon aria-hidden="true" class="-ml-0.5 mr-1.5 h-5 w-5" />
                {{ button }}
            </SimpleButton>
        </div>
    </div>
</template>
<script>
import SimpleButton from "@/views/SimpleButton.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";

export default {
    name: "EmptyState",
    components: { SimpleButton, PlusIcon },
    props: {
        title: {
            type: String,
            default: "No projects",
        },
        description: {
            type: String,
            default: "Get started",
        },
        button: {
            type: [Boolean, String],
            default: "Create project",
        },
        icon: {
            type: String,
            default: "folder",
        },
    },
    emits: ["clicked"],
    methods: {
        createProject() {
            // Create a new project
        },
    },
    data() {
        return {
            projects: [],
        };
    },
    mounted() {
        this.projects = this.$store.state.projects;
    },
};
</script>
