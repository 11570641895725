import { mapGetters } from "vuex";
import styleGuideTextMixin from "@/mixins/StyleGuideMixins/StyleGuideTextMixin";
import CreateStyleGuideMixin from "@/mixins/StyleGuideMixins/CreateStyleGuideMixin";
import chroma from "chroma-js";
import findMatchingObject from "@/mixins/firebase/findMatchinObject";
import webRequest from "@/mixins/ai/web_request";
import { db } from "@/firebase";
import stopGenerating from "@/mixins/ai/stop_generation";
import removeFromFirebase from "@/mixins/firebase/deleteFromFirebase";
import { logFetch } from "@/mixins/firebase/firebaseHelpers";
import CSSHelpers from "@/mixins/StyleMixins/CSSHelpers";
import colorPreview from "@/components/styleGuide/Tools/ColorPreview.vue";

export default {
    mixins: [styleGuideTextMixin, CreateStyleGuideMixin, CSSHelpers],
    data() {
        return {
            // styleIds: [],
            // fontLinks: [],
        };
    },
    computed: {
        ...mapGetters("styleGuide", [
            "styleGuide",
            "styleGuides",
            "typography",
            "textStyles",
            "colors",
            "fonts",
            "videos",
            "buttonStyles",
            "headerImage",
            "divStyles",
            "allTextStyles",
            "textSamples",
            "svgs",
            "hasImages",
            "website",
            "copyGuidelines",
            "additionalText",
            "collectedLinks",
            "backgroundColors",
            "productCopy",
            "linkColor",
            "randomDivStyle",
        ]),
        ...mapGetters("styleGuide/llm", ["SubjectLines", "HeaderSamples", "AllWebCopy", "ImageList", "ProductArray", "CopyGuidelines", "Context", "Personas"]),
        ...mapGetters("styleGuide/image", ["logos", "landingPageImages", "logo", "images", "sortedImages", "landingPageImages"]),
        baseText() {
            if (this.styleGuide?.base?.color) {
                return this.styleGuide.base.color;
            }
            return "";
        },
        baseBg() {
            return this.styleGuide?.base?.backgroundColor;
        },
        emailMessages() {
            let m = [
                // sysMessage(systemPrompt),
                // userMessage(`${this.llmAboutBrand} ${this.llmSubjectLines}`),
                // userMessage(this.llmHeaderSamples),
                // userMessage(topic) // Uncomment if topic is defined
            ];
            // if (systemPrompt) m.push(sysMessage(systemPrompt));
            if (this.AboutBrand) m.push(userMessage(this.AboutBrand));
            if (this.HeaderSamples) m.push(userMessage(this.HeaderSamples));
            if (this.SubjectLines) m.push(userMessage(this.SubjectLines));
            if (this.ImageList) m.push(userMessage(`${this.ImageList}`));
            if (this.ProductArray) m.push(userMessage(this.ProductArray));
            return m;
        },
        maskStyles() {
            if (this.logo) {
                // if (!this.logo.includes("svg")) {
                return {
                    maskImage: `url(${this.logo})`,
                    maskSize: "cover",
                    // background: `${this.styleGuide.colors?.bg_colors?.[0]}` || "#fff",
                };
            }
        },
        randomButtonText() {
            let ButtonStrings = ["Learn more", "Shop now", "Let's get started", "Shop the set"];
            const randomIndex = Math.floor(Math.random() * ButtonStrings.length);
            return ButtonStrings[randomIndex];
            //  pull random button text from textStyles.buttons array
            if (this.textSamples.buttons) {
                let styles = [...this.textSamples.buttons];
                let twoWords = styles.filter(style => style.split(" ").length <= 5 && style.split(" ").length >= 2);
                if (twoWords?.length === 0) {
                    styles = [...this.textSamples.buttons, ...this.textSamples.links];
                }
                twoWords = styles.filter(style => style.split(" ").length <= 5 && style.split(" ").length >= 2);
                // filter styles by strings with two words

                // filter styles by strings with three words
                const randomIndex = Math.floor(Math.random() * twoWords.length);
                if (twoWords[randomIndex] !== "") {
                    return twoWords[randomIndex];
                }
            }
            return "Shop now";
        },
        firstLogo() {
            if (this.logos?.[0]?.url) {
                return this.logos[0].url;
            } else {
                return false;
            }
        },
        firstSvg() {
            if (this.styleGuide.svgs?.length > 0) {
                return this.styleGuide.svgs[0];
            } else {
                return false;
            }
        },
    },
    methods: {
        getFontStyle(fontSize, baseFontSize = 16, maxBlur = 2, blurIncrement = 0.05) {
            // Convert fontSize to em if it's 10 or above (assuming it's in pixels)
            let deduplicatedText = this.styleGuide?.deduplicatedText || [];
            let allTextStyles = this.styleGuide?.allTextStyles || [];
            let textArrays = [...deduplicatedText, ...allTextStyles];
            /// sort textArrays by font size
            textArrays.sort((a, b) => a.fontSize - b.fontSize);
            let fontSizeInEm = fontSize >= 10 ? fontSize / baseFontSize : fontSize;

            let textStyles = textArrays.map(f => {
                console.log(f.fontSize);
                return { ...f, fontSize: parseFloat(f.fontSize) }; // Convert stored font sizes to em
            });

            let blur = 0.1;
            let closestTextStyle = textStyles.filter(text => text.fontSize >= fontSizeInEm - blur && text.fontSize <= fontSizeInEm + blur);

            while (!closestTextStyle.length && blur <= maxBlur) {
                blur += blurIncrement;
                closestTextStyle = textStyles.filter(text => text.fontSize >= fontSizeInEm - blur && text.fontSize <= fontSizeInEm + blur);
            }

            if (closestTextStyle.length) return closestTextStyle[0];
            // add rem to the font size
            textStyles[0].fontSize = `${textStyles[0].fontSize}rem`;
            return textStyles[0];
        },
        fixRGBA(color) {
            if (color.includes("#")) return color;
            try {
                let rgba = color.split("(")[1].split(")")[0].split(",");
                let r = rgba[0];
                let g = rgba[1];
                let b = rgba[2];
                let a = rgba[3];
                if (a) a = parseFloat(a);
                color = chroma([r, g, b]).alpha(a).hex();
                console.log(color);
                // .alpha(a).hex();
                return color;
            } catch (e) {
                console.error("Error fixing RGBA", e.message);
            }
            return color;
        },
        getContrastColor(color, array, contrastThreshold = 3) {
            if (!color) return;
            if (color.includes("rgba")) color = this.fixRGBA(color);
            // Map each color in the array to an object with the color and its contrast
            if (!this.colors?.bg_colors) return;
            let hexValue = color || "#000000";
            // handle rgba color
            if (array?.length > 0) {
                array = array.map(color => {
                    if (color.includes("rgba")) color = this.fixRGBA(color);
                    return color;
                });
            }
            try {
                if (!hexValue?.includes("#")) hexValue = chroma(color).hex();

                let colorsArray = this.colors?.all_colors || this.colors?.bg_colors || [];
                if (array) colorsArray = [...colorsArray, ...array];
                let contrastArr = colorsArray?.map(color => {
                    color = color.replace("!important", "");
                    color = color.replace(";", "");
                    let contrast = chroma.contrast(hexValue, color);
                    return { color, contrast };
                });

                // Sort the array by contrast ratio in descending order.
                contrastArr.sort((a, b) => (a.contrast < b.contrast ? 1 : -1));

                // Filter based on your contrast threshold
                const highContrastArr = contrastArr.filter(colorObj => colorObj.contrast >= contrastThreshold);

                // Return first color that has a high enough contrast ratio
                if (highContrastArr.length > 0) {
                    // console.log(highContrastArr);
                    // pull a random color from the array
                    let randomColor = highContrastArr[Math.floor(Math.random() * highContrastArr.length)];
                    return randomColor.color;
                    // return highContrastArr[0].color;
                }
            } catch (e) {
                console.error("Error getting contrast color", e.message);
            }

            // if nothing matches the contrast ratio it will return null
            return "";
        },
        getColorsFromGradient(gradientString) {
            // Regular expression to match hex, RGB(A), and HSL(A) color values
            const colorRegex = /#(?:[0-9a-fA-F]{3}){1,2}|rgba?\((?:\d{1,3},\s?){2}\d{1,3}(?:,\s?\d(?:\.\d{1,2})?)?\)|hsla?\((?:\d{1,3},\s?){2}\d{1,3}(?:,\s?\d(?:\.\d{1,2})?)?\)/g;

            // Use regex to find matches in the gradient string
            let matches = gradientString.match(colorRegex);
            // filter out transparent
            matches = matches.filter(match => !match.includes("rgba(0, 0, 0, 0)"));
            // Return the matches, or an empty array if no matches are found
            return matches || [];
        },
        async deleteStyleGuide(brand) {
            if (!brand) return;
            let { id, url, name, categories } = this.styleGuide;
            categories = categories?.[0] || "Food";
            // await removeFromFirebase("brands", id);
            // await removeFromFirebase("brandsList", id);
            this.$store.dispatch("styleGuide/deleteStyleGuide", brand);
            this.$router.push(`/categories/${categories}`);
        },
        formatCampaignItem(item) {
            if (!item) return "";

            let itemString = "";

            // Add campaign details
            if (item?.name) itemString += `Name: ${item?.name}\n`;
            if (item?.strategy) itemString += `Strategy: ${item?.strategy}\n`;

            // Process schedule items
            if (item?.schedule_items)
                item.schedule_items.forEach((schedule, index) => {
                    // itemString += `Schedule ${index + 1}:\n`;
                    itemString += `## Day: ${schedule?.scheduled_on_day + 1}\n`;

                    schedule.campaign_items.forEach(campaignItem => {
                        itemString += `Campaign Item:\n`;
                        if (campaignItem?.itemType) itemString += `\tItem Type: ${campaignItem.itemType}\n`;
                        if (campaignItem?.goal) itemString += `\tGoal: ${campaignItem.goal}\n`;
                        if (campaignItem?.complete) itemString += `\tComplete: ${campaignItem.complete}\n`;
                        if (campaignItem?.types_of_imagery) itemString += `\tTypes of Imagery:\n${this.arrayToSentence(campaignItem.types_of_imagery, 2, "Types of Imagery")}\n`;
                        if (campaignItem?.talking_points) itemString += `\tTalking Points:\n${this.arrayToBulletPoints(campaignItem.talking_points, 2)}\n`;

                        for (const [key, value] of Object.entries(campaignItem.instructions)) {
                            itemString += "\n" + this.arrayToSentence(value);
                            // itemString += "\n" + this.arrayToSentence(value, 2, key.replace(/_/g, " "));
                        }
                    });
                });

            // Process planning
            if (item?.planning) {
                itemString += `Planning:\n${this.arrayToBulletPoints(item.planning, 2)}\n`;
            }

            return itemString;
        },
        async findMatchingGuide(baseUrl, collection = "brands") {
            const tryUrls = base => {
                const permutations = [base]; // Add the base URL as the first URL to try
                ["http", "https"].forEach(protocol => {
                    ["", "www."].forEach(subdomain => {
                        permutations.push(`${protocol}://${subdomain}${base.replace(/^https?:\/\/(www\.)?/, "")}`);
                    });
                });
                return permutations;
            };

            for (const tryUrl of tryUrls(baseUrl)) {
                const object = await findMatchingObject(collection, "url", tryUrl);
                if (object?.id) {
                    return object;
                }
            }
            return null;
        },
        modifyAltText(altText) {
            altText = altText
                .replace(/[-–—]/g, "")
                .replace(/\n/g, " ")
                .split(" ")
                .filter((value, index, self) => self.indexOf(value) === index) // Dedupe words
                .slice(0, 10)
                .join(" ")
                .trim();
            return altText;
        },
        resizeShopifyImage(image, size, mode = "cover") {
            // Check if the image URL is from Shopify
            if (!image.url.includes("cdn.shopify.com")) {
                console.error("The provided URL is not a valid Shopify image URL.");
                return image.url; // Return original URL if not a Shopify URL
            }

            // Determine dimensions for resizing
            let dimensions = "";
            if (typeof size === "string") {
                dimensions = `${size}x${size}`;
            } else if (typeof size === "object" && size.width && size.height) {
                dimensions = `${size.width}x${size.height}`;
            } else {
                console.error("Invalid size parameter provided.");
                return image.url; // Return original URL if size parameter is invalid
            }

            // Modify URL based on mode
            let resizeSegment = `${dimensions}`;
            if (mode === "fit") {
                // For 'fit', ensure aspect ratio is maintained without cropping
                resizeSegment += "_crop_center"; // Using '_crop_center' to maintain centering, adjust as needed
            }

            // Extract the file extension, removing any query parameters
            const urlParts = image.url.split("?")[0]; // Remove any query parameters
            const extensionMatch = urlParts.match(/(\.\w+)$/); // Extract extension including dot (.)
            if (!extensionMatch) {
                console.error("Could not extract image format from URL.");
                return image.url; // Return original URL if format extraction fails
            }
            const extension = extensionMatch[0];

            // Check for existing dimensions and replace them
            const resizedUrlBase = urlParts.replace(/(_\d+x\d+)(_crop_center|_crop_top|_crop_bottom|_crop_left|_crop_right)?\.\w+$/, "");
            const resizedUrl = `${resizedUrlBase}_${resizeSegment}${extension}`;

            return resizedUrl;
        },
        imageAltArray() {
            // If styleGuide or images doesn't exist, return early
            if (!this.styleGuide?.images?.length) return "";

            const altTextOccurrences = {};
            const processedAlts = this.images.map((image, index) => {
                let altText = (image.alt || "").trim();
                altText = this.modifyAltText(altText);

                if (altText) {
                    // Update alt text based on occurrences
                    if (altTextOccurrences[altText]) {
                        altTextOccurrences[altText]++;
                        altText = `${altText}-${altTextOccurrences[altText]}`;
                    } else {
                        altTextOccurrences[altText] = 1;
                    }
                }

                // Check if the imageAlt === the preview one
                if (altText === this.images[0].alt) {
                    return `${index}. ${altText}`;
                }
                return altText ? `${index}. ${altText}` : `${index}.`;
            });

            return `List of images to choose from:\n\n${processedAlts.join("\n")}`;
        },
        async updateGuideSection(sections, payload) {
            if (typeof sections === "string") sections = [sections];
            let url = this.styleGuide.url;
            let id = this.$route.params.id;
            await this.createStyleGuide(url, id, { sections: sections, payload: payload, updating: true });
        },
        async getStyleGuides(extra) {
            let items;
            if (this.styleGuides.length > 0 && !extra) return;
            if (extra) {
                // items = await this.fetchFromFirebase("brands");
                // items = await this.fetchPaginatedFromFirebase("brands", 10);

                items = items
                    .filter(item => item?.typographyCombos?.length > 0 && item?.buttonStyles?.length > 0)
                    .map(item => {
                        let typographyCombos = { ...item.typographyCombos[0].style, button: item.buttonStyles[0] };

                        return {
                            id: item.id,
                            name: item.name,
                            updated: item.updated,
                            colors: item.divStyles,
                            images: item?.images?.slice(0, 2) || [],
                            combos: item?.typographyCombos || [],
                            fonts: item?.fonts || [],
                            buttonStyles: item?.buttonStyles?.[0] || null,
                            brandStyle: typographyCombos,
                            url: item.url,
                        };
                    })
                    .filter(item => item?.images?.length > 0 && item?.combos?.length > 0)
                    .sort((a, b) => this.toDate(b.updated) - this.toDate(a.updated));
                this.setStyleGuides(items);
            } else {
                if (!this.styleGuides || this.styleGuides.length === 0) {
                    let response;
                    if (this.user) response = await webRequest("getBrands", { user: this.user });
                    else response = await webRequest("getBrands");
                    items = await response.json();
                    items = items.map(item => {
                        delete item.styleGuide;
                        return {
                            ...item,
                            id: item.id,
                            name: item.name,
                            url: item.url,
                            websiteUrl: item.websiteUrl,
                            updated: item.updated,
                        };
                    });
                    // items.sort((a, b) => this.toDate(b.updated) - this.toDate(b.updated));
                    // console.log(items);
                    if (!this.$route.params.id) {
                        let id = items[items.length - 1].id;
                    }
                    this.setStyleGuides(items);
                }
            }
        },
        setStyleGuides(items) {
            this.$store.dispatch("styleGuide/setGuideList", items);
            // this.styleGuides = items;
        },
        updateStyleGuideProp(key, value, save) {
            try {
                this.$store.dispatch("styleGuide/updateStyleGuideProp", { keyPath: key, value: value, save });
            } catch (error) {
                console.error("updateSlideProp", error.message, key, value);
            }
        },
        updateProp(key, value, stream) {
            try {
                this.$store.dispatch("styleGuide/updateProp", { keyPath: key, value: value, stream });
            } catch (error) {
                console.error("Error updating property", error.message, key, value);
            }
        },
        async getBrandImages(id) {
            return;
            let images;
            this.$store.commit("styleGuide/image/SET_IMAGES", []);
            if (!id) id = this.$route.params.id;
            try {
                const brandRef = db.collection("brands").doc(id); // Assuming db is your firestore instance
                const imageCollectionRef = brandRef.collection("images");
                const snapshot = await imageCollectionRef.get();

                if (snapshot.empty) {
                    return [];
                }

                images = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                // sort by source
                images = images.sort((a, b) => {
                    if (a.source < b.source) return -1;
                    if (a.source > b.source) return 1;
                    return 0;
                });
                images = images.filter(img => {
                    if (img.width && img.height) {
                        let width = parseInt(img.width);
                        if (width > 1) return img;
                    }
                });
            } catch (error) {
                console.error("Error fetching brand images:", error);
                images = []; // Return an empty array or handle the error as needed
            }
            let styleGuideImages = this.styleGuide?.images || [];
            if (Array.isArray(styleGuideImages) && styleGuideImages.length > 0) {
                images = [...styleGuideImages, ...images];
            }
            try {
                if (images?.length > 0) {
                    this.$store.commit("styleGuide/image/SET_IMAGES", images);
                }
            } catch (e) {
                console.error("error getting brand images", e.message);
            }
        },
        async getSubcollection(id, collectionName) {
            // console.log("Getting Sub Collection", id, collectionName);
            logFetch(`${collectionName}`, id);
            let items;
            if (!id) id = this.$route.params.id;
            try {
                const brandRef = db.collection("brands").doc(id); // Assuming db is your firestore instance
                const collectionRef = brandRef.collection(collectionName);
                const snapshot = await collectionRef.get();

                if (snapshot.empty) return [];

                items = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.metdata,
                    updated: doc?.metadata?.updated_at,
                    ...doc.data(),
                }));
                // console.log(collectionName, items);
                logGroup(collectionName, items);
            } catch (error) {
                console.error("Error fetching brand images:", error);
                items = []; // Return an empty array or handle the error as needed
            }
            try {
                if (items?.length > 0) {
                    return items;
                }
            } catch (e) {
                console.error("error getting brand images", e.message);
            }
        },
        rankResults(results, excludeBlackWhite = false) {
            let entries = Object.entries(results);

            if (excludeBlackWhite) {
                // Excluding black (#000, #000000) and white (#fff, #ffffff) colors
                entries = entries.filter(([color]) => !(color === "#000" || color === "#000000" || color === "#fff" || color === "#ffffff") && color !== "none");
            }

            let sortedEntries = entries.sort((a, b) => b[1] - a[1]);

            // return only keys (color codes)
            return sortedEntries.map(([key]) => key);
        },
        async wantsToStopGenerating() {
            await stopGenerating(this.$store);
        },
        // Sort divStyles array based on the difference to baseColor
        cdnLinkFixer(styleGuide) {
            if (styleGuide?.fonts?.length > 0) {
                styleGuide.fonts = styleGuide.fonts.map(font => {
                    if (font?.url && font?.url.includes("brandblocks.co") && !font?.url.includes("cdn")) {
                        let oldURL = "brandblocks.co";
                        let newURL = "cdn.brandblocks.co";
                        let fontURL = font.url;
                        font.url = fontURL.replace(oldURL, newURL);
                        // font.src = `url('${font.url}')`;
                        return { ...font };
                    } else {
                        return font;
                    }
                }); // styleGuide.typography.fonts = fixedFonts;
            }
            return styleGuide;
        },
    },
};
