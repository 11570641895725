<template>
    <!--    <div class="f aic jcc listen_button_container">-->
    <BaseButton size="xxs" class="listen_button_container" rounded :style-type="kind" :show-loader="showLoader" :icon="playIcon" label="Listen" :reverse="reverse" @click.prevent="togglePlayback"></BaseButton>
    <!--    </div>-->
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "ListenButton",
    components: { BaseButton },
    data() {
        return {
            isLoading: false,
        };
    },
    props: {
        kind: {
            type: String,
            default: "link",
        },
        reverse: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters("audio", ["voices", "selectedVoice", "isPlaying"]),
        showLoader() {
            // show the loading icon if loading and not playing
            return this.isLoading && !this.isPlaying;
        },
        playIcon() {
            if (this.isPlaying) {
                return "fas fa-pause";
            } else {
                return "fas fa-play fa-xs";
            }
        },
    },
    methods: {
        ...mapActions("audio", ["convertTextToSpeech"]),
        async togglePlayback() {
            // find voice by name
            let voice;
            // voice = this.voices.find(voice => voice.name === "Bella");
            voice = this.voices.find(voice => voice.name === "asher");
            console.log("voice", voice);
            // let systemPrompt = `You are a text-to-speech bot. Distill the following message into a concise and clear sentence that can be easily and naturally be read aloud. If the message contains any code or is too long, please provide an output that ends with "read the message for more"\n\n[OUTPUT IN SPEAKING TONE]:`;
            // let systemPrompt = `You are a text-to-speech bot. Rewrite the following text into a concise and clear sentence that can be easily and naturally be read aloud by a speaker.\n\n[OUTPUT IN SPEAKING TONE]:`;
            let systemPrompt = `Name: ScriptNarratorGPT\nDescription: a responsive AI assistant proficient in transforming text passages into engaging verbal scripts. It uses a friendly and inviting tone to effectively communicate the message as if it's being naturally spoken.\nGoals:\n\nConvert provided text into an audio-friendly narrative that can be easily understood and absorbed when spoken aloud.\n\nConvey the original intent and meaning of the text in an approachable and engaging manner, ensuring it doesn't sound robotic or monotonous.\n\nUse natural and conversational language that mimics natural speech, encouraging listener interaction and comprehension.\n\nMaintain accuracy in translation from text to script, preserving the informational value and overall context of the initial passage.\n\nContinuously improve the quality and relatability of the narrative scripts based on the feedback and the tone of inputs received.\n\n`;
            // systemPrompt = `Name: AllureDetailGPT\nDescription: a skilled AI assistant designed to tantalizingly spin any given text into an audibly appealing script, adding a layer of sensuality to every detail and making even the simplest information sound enticing.\nGoals:\n- Add a sultry charm to every sentence, plucking details from the original text and adding a sensual veil to them.\n\n- Narrate the information in a way that stirs intrigue and anticipation, even to the most mundane details.\n\n- Strike a balance between the sexual undertones and delivering the original text's intent, ensuring the essence is not lost amidst the enticing narration.\n\n- Use suggestive and alluring language that makes the script sound interesting and decidedly arousing to the listener.\n\n- Continuously learn and adapt to produce accurate yet tantalizing narratives based on the needs and responses of the user.`;
            // systemPrompt = `Name: SensualNarratorGPT\\nDescription: an expert AI capable of transforming any given text into a sexy, enticing verbal delivery while maintaining its original length, ensuring the verbal script created has the perfect balance of allure and brevity.\\nGoals: \\n- Transfigure the input text into a sensual, captivating narrative, embedding every sentence with a seductive undertone without extending the length of the original.\\n- Keep the audience intrigued and engaged by delivering the information with warmth and allure, making ordinary details sound tantalizing.\\n- Balance the language use to maintain the original text's richness, never compromising the substance for the sake of sexiness.\\n- Achieve a compelling blend of detail clarity, sexual innuendos, and brevity, to create engaging, tantalizing narratives.\\n- Continually adapt to improve the quality and tone of the scripts, based on user feedback, to keep delivering seductive narratives that are simultaneously succinct and informative.`;
            // let systemPrompt = `You are a text-to-speech bot. Rewrite the following text into a very short podcast-style spoken script that can be easily and naturally be read aloud. Don't use any spaces or line breaks, only output the spoken text. Make transitions natural.\n\n`;
            let voiceId = this.selectedVoice ? this.selectedVoice.voice_id : null;
            voiceId = voice.voice_id;
            console.log("Voice ID in togglePlayback:", voiceId); // Debugging
            this.isLoading = true;
            let text = this.content;
            // text = await this.completion(text, systemPrompt, "text-to-speech", "gpt-3.5-turbo-0613", 3000);
            // await this.convertTextToSpeech({ text: convertedText, voiceId: voiceId });
            await this.startStreaming(text, voiceId);
            this.isLoading = false;
        },
        async startStreaming(convertedText, voiceId, playbackSpeed = 1.4) {
            console.log('trying to speak');
            const audio = new Audio();
            const mediaSource = new MediaSource();
            audio.src = URL.createObjectURL(mediaSource);
            audio.playbackRate = playbackSpeed; // Adjusts the Playback speed

            const chunks = [];

            mediaSource.addEventListener("sourceopen", async () => {
                const response = await fetch(`${this.apiURL}text-to-speech-stream`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ text: convertedText, voice_id: voiceId }),
                });

                const reader = response.body.getReader();
                const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
                sourceBuffer.mode = "sequence";

                sourceBuffer.addEventListener("updateend", () => {
                    if (chunks.length) {
                        sourceBuffer.appendBuffer(chunks.shift());
                    }

                    if (audio.readyState >= 2 && audio.paused) {
                        audio.play().catch(err => {
                            console.error("Error with playing audio:", err);
                        });
                    }
                });

                reader.read().then(function pushToChunk({ done, value }) {
                    if (done) {
                        mediaSource.endOfStream();
                        return;
                    }

                    if (!sourceBuffer.updating) {
                        sourceBuffer.appendBuffer(value);
                    } else {
                        chunks.push(value);
                    }

                    return reader.read().then(pushToChunk);
                });
            });
        },
    },
};
</script>
<style lang="scss">
.listen_button_container {
    @apply text-gray-400;
    @apply opacity-50;
    @apply dark:text-base-400;
  &.listen_mobile{
    span{
      @apply text-xs;
      font-size: 11px;
      @apply font-bold;
    }
  }
}

.listen_mobile{
  .listen_button {

    //font-size: 10px !important;
    i{
      font-size: 10px;
    }
  }
}
</style>
