<template>
    <div class="x p-relative d-block">
        <div class="closed d-inline-block br-5 mx-auto my-1 border border-gray-200 bg-gray-100 py-1 pl-2 pr-3 text-gray-600 dark:border-base-600 dark:bg-base-500/50 dark:text-base-300" @click.prevent="viewDetails">
            <span v-if="command && command.name" class="f aic jcc">
                <i :class="iconClass" class="o-2 small mr-2"></i>
                <span class="text-capitalize fwb line-height-smaller f-17 font-bold">{{ formattedCommandName }}</span>
                <WebLoader v-if="command.name === 'web_search' && command.arguments.query && m.typing" :simple="true" :sources="m.sources" :state="'loading'" class="f-15" />
                <span v-else-if="command.name === 'web_search' && command.arguments.query" class="o-5 f-15 ml-2">{{ truncateText(command.arguments.query) }}</span>
                <span v-if="command.name === 'browse_website' && command.arguments.url" class="o-5 f-15 ml-2">{{ truncateText(command.arguments.url) }}</span>
                <template v-else-if="command.name">{{ command.name }}</template>
                <template v-if="!m.typing">
                    <i class="fal fa-angle-right o-5 ml-3" v-if="!details"></i>
                    <i class="fal fa-angle-down o-5 ml-3" v-if="details"></i>
                </template>
            </span>
        </div>
        <!--        <p>{{ command }}</p>-->
        <transition name="showDetails" enter-active-class=" animated fadeInUpSmooth" leave-active-class=" fadeInUpSmooth duration-1 reverse-animation ">
            <div v-if="details" class="mw-350 x mx-2">
                <!--                <TaskManager class="br-10 x border-light mb-4 mt-3 bg-gray-200/20 p-3 dark:bg-base-700" v-if="command && command.name === 'task_manager'"></TaskManager>-->
                <!--                <MemoryManager class="x f-15 mb-4" v-if="(command && command.name === 'add_to_memory') || command.name === 'remove_from_memory'" />-->
                <div class="br-10 monospaced height-max-500 f-17 overflow-y-scroll bg-gray-900 p-3 text-snow">
                    <span>
                        <span class="fwb">Name:</span>
                        <span class="o-7 ml-2">{{ command.name }}</span>
                    </span>
                    <div v-if="command.arguments" class="mb-3">
                        <span class="fwb d-block mt-2">Arguments:</span>
                        <div v-for="(value, key) in command.arguments">
                            <div class="d-block mb-1 ml-4 mt-2">
                                <span class="fwsb">{{ key }} :</span>
                                <span class="o-7 ml-2">{{ value }}</span>
                            </div>
                        </div>
                    </div>
                    <span class="whitespace-pre-wrap">
                        <span class="fwb d-block">content:</span>
                        <div class="o-7 d-block f-15 mt-2 leading-loose">{{ command?.content?.replace(/\n\n/g, "\n") }}</div>
                    </span>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import ChatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import WebLoader from "@/components/chat/specialMessages/Search/WebLoader.vue";
import TaskManager from "@/views/Chat/TaskManager.vue";
import MemoryManager from "@/views/Chat/MemoryManager.vue";

export default {
    name: "FunctionPill",
    components: { MemoryManager, TaskManager, WebLoader },
    mixins: [ChatCommands],
    data() {
        return {
            details: false,
        };
    },
    props: {
        m: {},
        command: {
            type: Object,
            required: true,
        },
        showSystemMessage: {},
        loading: {
            type: Boolean,
            default: false,
        },
        sources: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        viewDetails() {
            this.details = !this.details;
        },
        truncateText(text) {
            try {
                let url = new URL(text);
                url = url.hostname;
                url = url.replace("www.", "");
                return url;
            } catch (e) {
                if (text.length > 20) {
                    return text.substring(0, 20) + "...";
                } else {
                    return text;
                }
            }
        },
    },
    computed: {
        // command() {
        //     return this.actionsObject[this.command.name];
        // },
        iconClass() {
            const commandName = this.command.name;
            let iconClass = "fa-solid fa-sparkles";

            if (this.loading) {
                iconClass = "fa-solid fa-spinner fa-pulse dark:text-base-50";
            } else {
                switch (commandName) {
                    case "record_thoughts":
                        iconClass = "fa-solid fa-brain";
                        break;
                    case "browse_website":
                        iconClass = "fa-solid fa-globe";
                        break;
                    case "web_search":
                        iconClass = "fa-solid fa-search";
                        break;
                    case "create_agent":
                        iconClass = "fa-solid fa-user-plus";
                        break;
                    case "task_manager":
                        iconClass = "fa-solid fa-tasks";
                    case "add_to_memory":
                        iconClass = "fa-solid fa-tasks";
                        break;
                }
            }

            return iconClass;
        },
        formattedCommandName() {
            let string = this.command?.name?.replace(/_/g, " ");
            if (string === "browse website") {
                return "Browsing";
            } else {
                return string;
            }
            return string;
        },
        functionName() {
            // if (this.actionsObject) {
            //     if (this.actionsObject[this.m.name]) {
            //         return this.actionsObject[this.m.name] + "HELLO";
            //     } else if (this.m.command) {
            //         return "hello"
            //         return this.actionsObject[this.m.command.name].name + "HELLO";
            //     } else if (this.actionsObject[this.m.action.name].name) {
            //         return this.actionsObject[this.m.action.name].name + "HELLO";
            //     }
            // } else {
            //     return false;
            //     return this.m.name || this.m.role;
            // }
        },
    },
};
</script>
<style lang="scss">
.function-message {
    .closed {
        z-index: 3 !important;
        margin-top: -1px !important;
        margin-bottom: -1px !important;
        text-transform: uppercase;
        @apply rounded-md;
        @apply inline-block;
        @apply font-bold;
        @apply border-solid;
        @apply mb-8;
        @apply relative;
        @apply block;
        @apply outline-0;
        @apply border;
        @apply f-15
    cursor-pointer
    py-2
    text-center;
    }
}
</style>
