import { doc, getDoc, collection, Timestamp } from "firebase/firestore";
import { ref as storageRef } from "firebase/storage";
import { isProxy, toRaw } from "vue";
import { mapGetters } from "vuex";
// import { useCollection, useDocument } from "vuefire";

import { db } from "@/firebase";

const storeMethods = {};
export const docStore = {
    state() {
        return {};
    },
    mutations: {},
};
const computedStore = {};
export default {
    data() {
        return {
            actionItems: [
                {
                    id: 1,
                    type: "image",
                    iconClass: "fas fa-image",
                    label: "Generate Image",
                    actionType: "image",
                },
                {
                    id: 2,
                    type: "about",
                    iconClass: "fas fa-worm",
                    label: "Learn More",
                    actionType: "ai",
                },
                { id: 3, type: "expand", iconClass: "fas fa-star", label: "Expand", actionType: "ai" },
                {
                    id: 4,
                    type: "simplify",
                    iconClass: "fas fa-circle",
                    label: "Simplify",
                    actionType: "ai",
                },
                { id: 5, type: "legal", iconClass: "fas fa-book", label: "Legal", actionType: "ai" },
                {
                    id: 6,
                    type: "howto",
                    iconClass: "fas fa-lightbulb",
                    label: "How to",
                    actionType: "ai",
                },
                {
                    id: 7,
                    type: "todo",
                    iconClass: "fas fa-list",
                    label: "To do list",
                    actionType: "ai",
                },
                {
                    id: 8,
                    type: "outline",
                    iconClass: "fas fa-clipboard-list-check",
                    label: "Create outline",
                    actionType: "ai",
                },
                {
                    id: 9,
                    type: "versions",
                    iconClass: "fas fa-sync",
                    label: "Create versions",
                    actionType: "ai",
                },
                // { id: 8, type: 'image', iconClass: 'fas fa-image', label: 'Generate Image' }
            ],
            saving: false,
            mousePosition: { x: null, y: null },
        };
        // artist: null,
    },
    // async mounted() {
    //     await this.getDocument();
    // },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        ...computedStore,
        // document: {
        //     get() {
        //         window.doc = this.$store.state.document;
        //         return this.$store.state.document;
        //     },
        //     set(value) {
        //         window.doc = this.$store.state.document;
        //         this.$store.commit("setDocument", value);
        //     },
        // },
        // sections() {
        //     return this.$store.state.document.sections;
        // },

        sectionIndex() {
            return this.$store.state.sectionIndex;
        },
        sectionId() {
            return this.$store.state.sectionId;
        },
        documentState() {
            return this.$store.state.savingDocument;
        },
    },
    methods: {
        // helps track last modified and which section is loading
        ...storeMethods,
        setDocuments(documents) {
            this.$store.commit("setDocuments", documents);
        },
        addDocument(document) {
            this.$store.commit("setAddDocument", document);
        },
        storeDocument(document) {
            this.$store.commit("storeDocument", document);
        },
        setSectionIndex(index, scroll) {
            // console.log('clicked section:',index);
            // console.error("set section index", index);
            this.$store.commit("setSectionIndex", index);
            if (scroll) {
                this.$nextTick(() => {
                    this.scrollToSlide(index);
                });
            }
        },
        async summarize(text) {
            try {
                const response = await this.completion(`${this.document.title}:\n${text}`, `Describe the presentation in under 100 words and give context to what it is.`, "Summarize document", gpt3, 50);
                if (!this.document.id) {
                    console.error("has no id, setting from route");
                    this.document.id = this.$route.params.id;
                }
                await this.uDoc(this.document.id, { summary: response });
                let updatedDoc = await this.getDocumentById(this.document.id);
                console.error(`summarized doc with id ${updatedDoc.id}`, updatedDoc);
                return;
            } catch (error) {
                if (error.status === 401) {
                    console.log("Error: Invalid API key", error.message);
                } else if (error.status === 403) {
                    console.log("Error: API key has insufficient permissions", error.message);
                } else if (error.status === 429) {
                    console.log("Error: API rate limit exceeded", error.message);
                } else {
                    console.log("Error: " + error.message);
                }
                await this.saveDoc(this.document);
            }
            return;
        },
        savingStateHelper(state, document) {
            if (state) {
                let currentSection = this.sectionIndex;
                let currentDocument = this.$route.params.id;
                let lastSaved = document.updated;
                let savingDocument = {
                    saving: true,
                    section: currentSection,
                    lastSaved: lastSaved,
                    loading: true,
                    documentId: currentDocument,
                };
                this.$store.commit("setSavingDocument", savingDocument);
                console.groupCollapsed("Started save");
                console.log("Started save", savingDocument);
                console.groupEnd();
            } else {
                let currentSection = this.sectionIndex;
                let currentDocument = this.$route.params.id;
                let lastSaved = this.document.updated;
                let savingDocument = {
                    saving: false,
                    loading: false,
                    section: currentSection,
                    lastSaved: lastSaved,
                    documentId: currentDocument,
                };
                this.$store.commit("setSavingDocument", savingDocument);
                console.groupCollapsed("Done saving");
                console.log("Done saving", savingDocument);
                console.groupEnd();
            }
        },
        getRecentDocument() {
            const path = this.$route.path;
            let id, newPath;
            if (path.includes("presentation")) {
                id = localStorage.getItem("lastPresentation");
                newPath = "/presentations/" + id;
            } else if (path.includes("storyboard")) {
                id = localStorage.getItem("lastStoryboard");
                newPath = "/storyboard/" + id;
            } else if (path.includes("canvas")) {
                id = localStorage.getItem("lastCanvas");
                newPath = "/canvas/" + id;
            } else if (path.includes("document")) {
                id = localStorage.getItem("lastDocument");
                newPath = "/documents/" + id;
            }
            if (id !== null) {
                this.$router.push({ path: newPath });
                return id;
            }
        },
        storeDocId() {
            const path = this.$route.path;
            let id, mutationName, storageName;
            if (path.includes("presentation")) {
                mutationName = "setLastPresentation";
                storageName = "lastPresentation";
            } else if (path.includes("document")) {
                mutationName = "setLastDocument";
                storageName = "lastDocument";
            } else if (path.includes("storyboard")) {
                mutationName = "setLastStoryboard";
                storageName = "lastStoryboard";
            } else if (path.includes("canvas")) {
                mutationName = "setLastCanvas";
                storageName = "lastCanvas";
            }
            if (mutationName && storageName) {
                id = this.$route.params.id;
                this.$store.commit(mutationName, id);
                localStorage.setItem(storageName, id);
            }
        },
        docStorageHandler() {
            console.error("setGetLocalStorage");
            if (this.$route && this.$route.params && this.$route.params.id) {
                this.storeDocId();
            } else {
                console.error("no id");
                this.getRecentDocument();
            }
        },
        savingStateInit(document, silent) {
            let currentSection = this.sectionIndex;
            let currentDocument = this.$route.params.id;
            let lastSaved = this.document.updated;
            let savingDocument = {
                saving: false,
                section: currentSection,
                lastSaved: lastSaved,
                loading: false,
                documentId: currentDocument,
            };
            this.$store.commit("setSavingDocument", savingDocument);
            if (!silent) {
                console.log("Init document", savingDocument);
            } else {
                // console.log(silent, currentSection);
            }
        },
        async saveDoc(document) {
            this.savingStateHelper(true, document);
            let doc = toRaw(document);
            if (this.document) doc = this.document;
            if (this.user) {
                doc.owner = this.user.uid;
            }
            if (doc.documentType === "textEditor") {
                console.error("setting title", doc.sections[0].content);
                doc.title = doc.sections[0].content;
            }

            console.error(`Starting to save ${doc.id}`, doc);
            this.saving = true;
            let savedDoc;
            // let title = this.documentTitleHelper(document)
            // doc.title = title;
            if (this.$route.params.id) {
                console.error(`updating ${this.$route.params.id}`);
                if (!doc.id) {
                    doc.id = this.$route.params.id;
                }
                savedDoc = await this.updateDoc(doc);
                console.error(`Document saved with id ${savedDoc.id}`, savedDoc);
            } else {
                // doc.title = this.documentTitleHelper(doc)
                doc.created = new Date();
                doc.updated = new Date();
                console.log("creating + saving");
                try {
                    savedDoc = await this.saveDocument("documents", doc);
                } catch (error) {
                    console.error(error);
                }
            }
            this.savingStateHelper(false, document);
            this.saving = false;
            this.$forceUpdate();
            return;
        },
        sortedDocuments(array) {
            let Array = this.list.sort((a, b) => {
                return b.updated - a.updated;
            });
            return Array;
        },
        sortByUpdated() {
            let Array = this.list.sort((a, b) => {
                return b.updated - a.updated;
            });
            return Array;
        },
        sortByCreated() {
            let Array = this.list.sort((a, b) => {
                return b.created - a.created;
            });
            return Array;
        },
        async updateDoc(document) {
            console.error(`Save/update doc with id: ${document.id}`, document);
            let doc = document;
            doc.updated = new Date();
            if (!doc.created) {
                doc.created = new Date();
            }
            if (!doc.title) {
                doc.title = "untitled";
            }
            if (doc.id && doc.sections && doc.sections.length > 0) {
                this.setDocument(doc);
            }
            console.error(doc);
            try {
                const savedDoc = await this.updateAnyObject("documents", doc.id, doc);
                console.error("Finally updated", savedDoc);
                return savedDoc;
            } catch (error) {
                console.error(error);
                this.addAlert({
                    type: "Error",
                    message: `Saving didn't work` + error,
                });
            }
        },
        async deleteDoc(collection, item) {
            let array = this.documents;
            await this.removeFromFirebase(collection, item.id, false);
        },
        documentTitleHelper(document) {
            if (document) {
                if (!document.title && document.sections[0] && document.sections[0].content) {
                    return document.sections[0].content;
                } else {
                    return document.title;
                }
            } else {
                console.error("No document provided", document);
            }
        },

        async enhancedImagePromptOld(text, index, overview) {
            // let promptMod = this.generatePrompt();
            let promptMod = "";
            if (text.includes("photo")) {
                // promptMod = this.generatePhotoPrompt();
            } else if (text.includes("illustration")) {
                // promptMod = this.generateArtPrompt();
            } else {
                promptMod = "";
            }
            let sectionText = this.document.sections[index].body;
            if (this.document.sections[index].enhancedBody) {
                sectionText = this.document.sections[index].enhancedBody;
            }
            let imagePromptFrame = `\n###\nPRESENTATION CONTEXT: [The image will be supporting this passage in the presentation]\n"${sectionText}"\n\n ###\n\n`;
            const request = {
                model: "text-davinci-003",
                prompt: `PROMPT:
            INSTRUCTIONS: Write a prompt to add succinct but specific details about the image's subject, lighting, composition, environment, focal length or artist's style.
            Prompt (under 300 letters):\n\n${text}\n### This is an image of: ###\n\n`,
                temperature: 0.9,
                max_tokens: 70,
                top_p: 0.9,
                frequency_penalty: 0,
                presence_penalty: 0,
            };
            // NEW VERSION TEST
            //             const request = {
            //                 model: "text-davinci-003",
            //                 prompt: `${imagePromptFrame}INSTRUCTIONS: Write a keyword-dense prompt to add succinct but specific and detailed keywords about the image's subject, lighting, composition, environment and more (see examples). Keyword dense.
            // Add weights after positive and negative keyphrases by appending ":1" (positive / things to include) and ":-1" (negative / things to exclude). You can increase or decease the number to add more or less emphasis.
            // Separate all key-phrases using: " | "
            // ###
            // Examples:
            // "photo realistic portrait of young woman, red hair, pale, realistic eyes: 1 | gold necklace with big ruby:1 | centered in frame:1 | facing camera:1 | symmetrical face:1 ideal human | 85mm lens,f8, photography:0.8 | ultra details, natural light, dark background:1 | out of focus trees in background:0.7 | 9:16:-1 | testp:-0.5"
            // "temple in ruines in a forest with stairs and columns in the background: 1.5 | cinematic and detailed: 1.1 | atmospheric, epic, concept art: 0.6 | Matte painting, background, mist:1 | photo-realistic:1 | concept art, volumetric light:1 | cinematic epic + rule of thirds:1.1 |  octane render, 8k, corona render, movie concept art:0.9 | octane render, cinematic, trending on artstation: 1 |  movie concept art, cinematic composition:1 | ultra-detailed, realistic, hyper-realistic: 1 | volumetric lighting, 8k:1 | test:-0.5 | uplight:-1”
            // "beautiful open kitchen in the style of elena of avalor overlooking aerial wide angle view of a solarpunk: 1 | vibrant city with greenery: 1 | interior architecture, kitchen, eating space: 1 | rendered in octane: 0.9 | in the style of Luc Schuiten, craig mullins: 1 | solarpunk in deviantart: 0.8 |  photorealistic, highly detailed: 1 | Vincent Callebaut, elena of avalor, highly detailed: 1"
            // "A portrait of a man standing on top of a boat, wearing blue hoodie, resting on a tough day: 1 | profile picture 1024px: 1 | stormy seas, documentary, fish man, older male:1 | Oscar winning: 6 | colourful 3d crystals and gems, vintage, full color manga cover, kewpie, two girls, anime, fairytale illustration, Chinese ribbon dance, children illustration, illustration, silk shoes, classic children’s illustrations, adorable and whimsical: -3 | weird faces, extra limbs, weird anatomy: -2 | sharp face: 1"
            // "A realistic photo, award winning photography, of a cat sitting on a ledge, a digital painting, pexel contest winner, furry art, sunbathing, illustration, high detail illustration, portrait of a cartoon animal, realistic gouache painting: 6 | man and woman, modern, neck: -3"
            // "A close up of a comic book cover, a comic book panel, wearing green and yellow hero suit, soldiers running, spectrum, 1980s: 6 | ornate black tuxedo, Bugatti Veyron, sensual lighting, 4k food photography, pink lipstick, detailed white fur, woman with long, peach embellishment, fashion photo shoot, dark blue tint, wearing professional makeup, arknights, peaceful and serene, fashion photography: -4 | childish drawing, poorly drawn, bad art: -1"
            // Example format: "${this.document.sections[index].image}: 1.5 | [supporting keyphrases]: 1 | [negative keyphrases]: -1"
            // Refer to this prompt guide for more guidance: https://stable-diffusion-art.com/prompt-guide/
            // ###
            // ${text}
            // ###
            // This is an image of:
            // ###`,
            //                 temperature: 1,
            //                 max_tokens: 100,
            //                 top_p: 0.9,
            //                 frequency_penalty: 0,
            //                 presence_penalty: 0,
            //             };
            console.error(request.prompt);

            try {
                const response = await this.completion(request.prompt, "Follow the users instructions thoroughly", `Image Prompt ${index}`);
                // let fixedResponse = response.replace(/^Body: /, '');
                // this.documents[id].sections[index].enhancedImage = response;

                this.document.sections[index].enhancedImage = response;

                console.error(response);
                await this.saveDoc(this.document);
                this.stabilityPresentationImage(index, response, sectionText);
                // await this.presentationImage(index, response);
            } catch (error) {
                if (error.status === 401) {
                    console.log("Error: Invalid API key", error.message);
                } else if (error.status === 403) {
                    console.log("Error: API key has insufficient permissions", error.message);
                } else if (error.status === 429) {
                    console.log("Error: API rate limit exceeded", error.message);
                } else {
                    console.log("Error: " + error.message);
                }
                await this.saveDoc(this.document);
            }
            return;
        },
        async saveDocument(collection, item, array) {
            this.saving = true;
            let saved = await this.addToFirebase(collection, item);
            this.saving = false;
            this.addAlert({
                type: "success",
                message: "Saving your doc",
            });
            if (array) {
                this.$nextTick(() => {
                    this.$forceUpdate();
                    // array.push(item)
                });
            }
            return saved;
        },
        async updatePartial(document) {
            await this.updateAnyObject("documents", doc.id, doc);
        },
        async getDocument() {
            if (this.$route.params.id) {
                this.document = await this.documentFromRoute();
                // this.savingStateInit(this.document);
            } else {
                // this.document = this.documents[0];
                // console.error(this.documents[0]);
                // this.savingStateInit(this.document);
            }
        },
    },
};
