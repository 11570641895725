<template>
    <div>
        <BaseAccordion v-if="emailReasoning?.length > 0" class="p-0 mt-3 mw-450 mx-auto">
            <template #title>Thinking</template>
            <div v-if="emailReasoning">
                <presentation-markdown class="whitespace-pre-line f-13" :content="emailReasoning"></presentation-markdown>
            </div>
        </BaseAccordion>
        <BaseAccordion v-if="emailPlan?.length > 0" class="p-0 mt-3 mw-450 mx-auto">
            <template #title>Planning</template>
            <div v-if="emailPlan">
                <presentation-markdown class="whitespace-pre-line f-13" :content="emailPlan"></presentation-markdown>
            </div>
        </BaseAccordion>
        <BaseAccordion v-if="emailSubject?.length > 0" class="p-0 mt-3 mw-450 mx-auto">
            <template #title>Subject</template>
            <div class="f fc gap-3">
                <div class="scrollSection p-3 whitespace-pre-line f-13" style="max-height: 300px">{{ emailSubject }}</div>
            </div>
        </BaseAccordion>
        <BaseAccordion v-if="emailPreview" class="p-0 mt-3 mw-450 mx-auto">
            <template #title>Preview</template>
            <div class="f fc gap-3">
                <div class="scrollSection p-3 whitespace-pre-line f-13" style="max-height: 300px">{{ emailPreview }}</div>
            </div>
        </BaseAccordion>
    </div>
</template>
<script>
import BaseAccordion from "@/components/CoreUI/BaseAccordion.vue";
import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";

export default {
    name: "EmailDetailedThinking",
    components: { BaseAccordion, PresentationMarkdown },
    props: {
        emailPlan: {},
        emailPreview: {},
        emailReasoning: {},
        emailSubject: {},
    },
};
</script>
