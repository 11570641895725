<template>
    <div class="w-2/3 mx-auto">
        <div class="mb-4 gap-3 f fc">
            <SimpleAlert v-if="errorMessage" :message="errorMessage" title="Error message" />
            <SimpleTextarea id="task" v-model="task" label="Task" />
            <SimpleInput id="variables" v-model="variables" label="Variables (comma-separated)" />
            <SimpleButton :disabled="isLoading" @click="generate">{{ isLoading ? "Generating..." : "Send" }}</SimpleButton>
        </div>
        <Markdown :content="response" />
        <div v-if="data" class="mt-5 whitespace-pre-wrap">
            <div v-if="variableArray?.length > 0" class="f aic gap-3">
                <label class="label-hint">Variables</label>
                <BaseBadge v-for="v in variableArray" :key="v">{{ v }}</BaseBadge>
            </div>
            <div class="prose-md" v-if="prompt">
                <div class="f aic jcb x px-2.5 py-2 bg-gray-800 rounded-t-lg">
                    <label class="label-hint fwb text-uppercase text-sm mb-0">Prompt</label>
                    <SimpleButton size="sm" color="gray" @click="copyPrompt">Copy text</SimpleButton>
                </div>
                <div class="bg-snow rounded-b-lg border-light shadow p-4 pb-5">
                    <pre class="whitespace-pre-line" v-if="prompt" prompt>{{ prompt }}</pre>
                    <!--                    <Markdown :content="" />-->
                </div>
            </div>
        </div>
        <div v-if="variableArray?.length > 0 && prompt" class="mt-4">
            <h3>Variable Inputs</h3>
            <template v-for="(variable, key) in variableArray" :key="variable">
                <SimpleTextarea :id="variable" v-model="variableInputs[variable]" :label="variable" />
            </template>
            <SimpleButton :disabled="isRunning" @click="runPrompt">{{ isRunning ? "Running..." : "Run Prompt" }}</SimpleButton>
        </div>
        <Markdown v-if="promptResponse" :content="promptResponse" />
    </div>
</template>

<script>
import webRequest from "@/mixins/ai/web_request";
import SimpleInput from "@/views/SimpleInput.vue";
import SimpleButton from "@/views/SimpleButton.vue";
import Markdown from "@/components/chat/specialMessages/Markdown.vue";
import SimpleAlert from "@/views/SimpleAlert.vue";
import SimpleTextarea from "@/views/SimpleTextArea.vue";
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";

export default {
    name: "MetaPrompt",
    components: { BaseBadge, SimpleTextarea, SimpleAlert, Markdown, SimpleButton, SimpleInput },
    data() {
        return {
            data: { variables: [], prompt: `` },
            task: "",
            variables: "",
            isLoading: false,
            errorMessage: "",
            response: "",
            variableInputs: {},
            isRunning: false,
            promptResponse: "",
        };
    },
    computed: {
        prompt() {
            if (this.data.prompt) {
                let prompt = this.data.prompt;
                if (this.variableInputs) {
                    for (let key in this.variableInputs) {
                        prompt = prompt.split(`{${key}}`).join(this.variableInputs[key]);
                    }
                }
                return prompt;
            }
        },
        variableArray() {
            if (this.variables) {
                try {
                    return this.variables.split(",").map(v => v.toUpperCase().trim()) || [];
                } catch (e) {
                    return [];
                }
            }
            return [];
        },
    },
    methods: {
        copyPrompt() {
            navigator.clipboard.writeText(this.prompt);
        },
        async generate() {
            this.isLoading = true;
            this.errorMessage = "";
            this.response = "";
            try {
                const payload = { task: this.task, variables: this.variableArray };
                let response = await webRequest("generate-metaprompt", { ...payload }, true);
                await this.processResponseStream(
                    response,
                    text => this.handlePartialUpdate(text),
                    error => console.error("Error:", error),
                );
                this.handleComplete();
            } catch (error) {
                console.log("Error generating metaprompt", error.message, "#dc3545");
                this.errorMessage = "An error occurred while generating the metaprompt. Please try again.";
            } finally {
                this.isLoading = false;
            }
        },
        handleComplete() {
            this.processResponse(this.response);
        },
        handlePartialUpdate(token = "") {
            this.response += token;
        },
        processResponse(response = "") {
            const variables = this.variableArray || [];
            const promptRegex = /<Instructions>\s*([\s\S]*?)\s*<\/Instructions>/;
            const promptMatch = response.match(promptRegex);
            const prompt = promptMatch && promptMatch[1] ? promptMatch[1].trim() : "";
            this.data = { variables: variables, prompt: prompt };
            this.initializeVariableInputs();
        },
        initializeVariableInputs() {
            this.variableInputs = {};
            for (let variable of this.variableArray) {
                if (!variable) continue;
                variable = variable.toUpperCase();
                this.variableInputs[variable] = "";
            }
        },
        async runPrompt() {
            this.isRunning = true;
            this.promptResponse = "";

            try {
                const payload = {
                    prompt: this.prompt,
                };
                let response = await webRequest("run-prompt", { ...payload }, true);
                await this.processResponseStream(
                    response,
                    text => this.handlePromptPartialUpdate(text),
                    error => console.error("Error:", error),
                );
            } catch (error) {
                console.log("Error running prompt", error.message, "#dc3545");
                this.errorMessage = "An error occurred while running the prompt. Please try again.";
            } finally {
                this.isRunning = false;
            }
        },
        handlePromptPartialUpdate(token = "") {
            this.promptResponse += token;
        },
    },
};
</script>
<!--            <pre v-if="data?.prompt" class="p-4 bg-gray-100 rounded-lg whitespace-pre-wrap">{{ data.prompt }}</pre>-->
<!--            <pre v-if="response" class="p-4 bg-gray-100 rounded-lg whitespace-pre-wrap">{{ response }}</pre>-->
