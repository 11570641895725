<template>
    <a :href="sight.link" class="place-card fc x y flex cursor-pointer text-gray-800 dark:border-base-900" target="_blank">
        <div :class="{ 'aspect-h-1 aspect-w-1': aspect === 'square', 'aspect-h-9 aspect-w-16': !aspect, 'aspect-h-9 aspect-w-16': aspect !== 'square' }" class="place_image">
            <img v-if="sight.thumbnail || sight.image" :src="sight.thumbnail || sight.image" class="x" />
        </div>
        <div class="x y h-100 relative flex-grow bg-snow p-2 dark:bg-base-700">
            <p v-if="sight.title || sight.name" class="mb-0 text-sm line-clamp-1 dark:text-base-400">{{ sight.title || sight.name }}</p>
            <span v-if="!sight.rating && !sight.reviews" class="d-block o-6 f aic jcc text-xs dark:text-base-400 see_details">
                <span>See details</span>
                <i class="fas fa-arrow-right small"></i>
            </span>
            <span v-if="sight.rating" class="d-block text-xs dark:text-base-400">
                <rating-stars-component :class="{ f: !mobile }" :count="sight.reviews || null" :value="sight.rating" class="whitespace-no-wrap small o-8"></rating-stars-component>
            </span>
            <span v-if="sight.price" class="d-block text-xs dark:text-base-400">Price: {{ sight.price }}</span>
        </div>
    </a>
</template>
<script>
import RatingStarsComponent from "@/components/CoreUI/RatingStarsComponent.vue";

export default {
    name: "Place",
    components: { RatingStarsComponent },
    props: {
        aspect: {},
        sight: {},
    },
};
</script>
<style lang="scss">
.place-card{
  @apply transition-all;
  @apply relative;
  @apply block;
  .place_image{
    @apply object-cover;
    @apply transition-all;
    @apply transform;
    @apply scale-100;
    img{
      @apply object-cover;
    }
  }
.see_details {
        i {
            @apply ml-2;
            @apply transition-all;
        }
}

  &:hover {
      .place_image {
          @apply transition-all;
          @apply aspect-h-10 aspect-w-11;
          img {
              //@apply object-contain;
              @apply object-cover;
              @apply transform;
          }
      }

      .see_details {
              i {
                  @apply transition-all;
                  @apply ml-5;
              }
      }
  }
}
</style>
