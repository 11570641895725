<template>
    <div class="absolute bottom-px inset-x-px p-2 rounded-b-md bg-white dark:bg-slate-900">
        <div class="flex justify-between items-center">
            <!-- Button Group -->
            <div class="flex items-center">
                <IconMic />
                <IconAttachment />
            </div>
            <div class="flex items-center gap-x-1">
                <MicButton />
                <ChatSendButton />
            </div>
            <!-- End Button Group -->
        </div>
    </div>
</template>
<script>
import ChatSendButton from "@/views/ChatSendButton.vue";
import IconAttachment from "@/views/IconAttachment.vue";
import IconMic from "@/views/IconMic.vue";
import MicButton from "@/views/MicButton.vue";

export default {
    name: "NewChatToolbar",
    components: { ChatSendButton, IconAttachment, IconMic, MicButton },
};
</script>
