<template>
    <div class="x z-0 max-w-xs flex-grow border-0 bg-base-900 scrollbar-hide">
        <div class="z-5 f jcb x border-bottom p-absolute h-nav aic bg-base-50 dark:bg-base-900">
            <span class="o-5 fwsb f-13 px-3" v-if="document && document.updated">
                Last saved
                {{ $filters.timeAgo(dateFix(document.updated)) }}
            </span>
            <div class="px-3" @click.prevent="closeDrawer()">
                <i class="fas fa-times f-20"></i>
            </div>
        </div>
        <div class="listStagger heightFix">
            <div class="scrollSection d-flex p-relative pt-nav grow scrollbar-hide" ref="listScroll">
                <div v-if="documents" class="fc d-flex h-auto flex-1">
                    <div v-for="(obj, index) in documents" :key="obj.id">
                        <ListItem v-if="obj && obj.id" :biline="obj.title" :body="obj.title" :headline="obj.title" :list-item="obj" style="color: unset"></ListItem>
                        <!-- </div>-->
                    </div>
                </div>
            </div>
            <div class="x f fc mx-auto bg-base-50 px-3 pb-3 pb-5 pt-3 text-center dark:bg-base-700">
                <button class="btn btn-link border-light hover:text-success br-10 text-base-400 shadow-sm dark:backdrop-brightness-150" @click.prevent="createDocument()">Create new document</button>
            </div>
        </div>
    </div>
</template>
<script>
import ListItem from "@/components/documents/documentListItem.vue";
import themeMixins from "@/mixins/StyleMixins/themeMixins";
import { doc, Timestamp, getDoc, query, collection, where, orderBy, startAfter, limit, getDocs, onSnapshot } from "firebase/firestore";
import { debounce } from "lodash";
import { db, textDocs, presentations } from "@/firebase";
import { mapGetters } from "vuex";
export default {
    components: { ListItem },
    mixins: [themeMixins],
    watch: {
        document: {
            async handler(newVal, oldVal) {
                if (newVal) {
                    await this.initList();
                }
            },
            deep: true,
        },
    },
    data() {
        return {
            debug: false,
            type: "",
            documents: [],
            lastVisible: null,
            documentsLimit: 20,
        };
    },
    async mounted() {
        this.initList();
        this.$nextTick(() => {
            this.$refs.listScroll.addEventListener("scroll", this.handleScroll);
        });
    },
    beforeDestroy() {
        this.$refs.listScroll.removeEventListener("scroll", this.handleScroll);
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
    },
    created() {
        // console.log(presentations);
    },
    methods: {
        async initList() {
            const docType = this.$route.path.includes("presentations") ? "presentation" : this.$route.path.includes("documents") ? "textEditor" : this.$route.path.includes("storyboard") ? "storyboard" : "";
            await this.getDocumentsByType(docType);
        },
        closeDrawer() {
            this.$emit("closeDrawer");
            this.$store.commit("setShowDocumentDrawer", false);
        },
        handleScroll: debounce(function () {
            const scrollTop = this.$refs.listScroll.scrollTop;
            const scrollHeight = this.$refs.listScroll.scrollHeight;
            const clientHeight = this.$refs.listScroll.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight) {
                this.initList();
            }
        }, 300),
        async getDocumentsByType(docType) {
            console.log("getting documents by type");
            if (!docType) return;

            const ownerId = this.user.uid;
            let documentQuery = query(collection(db, "documents"), where("documentType", "==", docType), orderBy("updated", "desc"), limit(this.documentsLimit));

            if (this.lastVisible) {
                documentQuery = query(collection(db, "documents"), where("documentType", "==", docType), orderBy("updated", "desc"), startAfter(this.lastVisible), limit(this.documentsLimit));
            }

            console.log("queried documents", documentQuery);
            // List of keys to exclude
            const keysToExclude = ["sections", "research", "images"]; // replace keyToExclude1, keyToExclude2, etc. with your actual keys

            onSnapshot(documentQuery, snapshot => {
                // Get all changes in the snapshot (added, modified, removed)
                const changes = snapshot.docChanges();
                changes.forEach(change => {
                    const data = change.doc.data();
                    const filteredData = Object.keys(data)
                        .filter(key => !keysToExclude.includes(key))
                        .reduce((obj, key) => {
                            obj[key] = data[key];
                            return obj;
                        }, {});

                    if (change.type === "added") {
                        this.documents.push({ ...filteredData, id: change.doc.id });
                    } else if (change.type === "modified") {
                        const index = this.documents.findIndex(doc => doc.id === change.doc.id);
                        if (index !== -1) {
                            this.documents[index] = { ...filteredData, id: change.doc.id };
                        }
                    } else if (change.type === "removed") {
                        this.documents = this.documents.filter(doc => doc.id !== change.doc.id);
                    }
                });

                if (!snapshot.empty) {
                    this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                }
            });

            return this.documents;
        },

        createDocument() {
            this.$emit("createDocument");
        },
    },
};
</script>
<style lang="scss" scoped>
.listStagger{
   @apply border-solid border-r-gray-200 border-l-0 dark:border-r-base-800 border-t-0;
   @apply bg-gray-50 dark:bg-base-800;
   @apply text-gray-800 dark:text-base-50;
 }
</style>
