<template>
    <div v-if="product?.title">
        <div class="br-10 bg-snow p-1 px-2 shadow text-gray-700">
            <i class="fas fa-shopping-bag text-gray-500 mr-1" />
            {{ product.title }}
            <span v-if="product.price">${{ product.price }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProductBadge",
    props: {
        product: {},
    },
};
</script>
