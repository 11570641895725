<!-- Content -->
<template>
    <div ref="wrapper" class="relative h-screen">
        <div ref="chat">
            <div class="py-10 lg:py-14">
                <!--                <ChatEmptyState />-->
                <ul class="mt-16 space-y-5">
                    <MessageViewer v-if="messages" :chat="messages" />
                    <EmptyState v-if="!messages?.length" class="border-2 border-gray-200 border-dashed inset-3 bg-gray-50 w-80 mx-auto py-5 br-20" title="You have no messages yet" description="Start a conversation to get going." :button="false" icon="paper-plane" />
                    <!--                    <template v-else-if="messages?.length > 0">-->
                    <!--                        <ChatEmptyStateMessage />-->
                    <!--                        <template v-for="(message, index) in messages" :key="message.content">-->
                    <!--                            <ChatAIMessage v-if="message.role === 'system'" :message="message" :text="message.content" />-->
                    <!--                            <ChatAIMessage v-if="message.role === 'assistant'" :message="message" :text="message.content" />-->
                    <!--                            <MessageUserMessage v-else :message="message" :text="message.content" />-->
                    <!--                        </template>-->
                    <!--                    </template>-->
                </ul>
            </div>

            <!-- Search -->
            <footer v-if="false" class="sticky bottom-0 z-10 bg-white border-t border-gray-200 pt-2 pb-3 sm:pt-3 sm:pb-4 dark:bg-slate-900 dark:border-gray-700">
                <div class="max-w-4xl mx-auto px-4 sm:px-4 lg:px-4">
                    <ChatOptions />
                    <div class="relative">
                        <ChatTextArea label="" />
                        <NewChatToolbar />
                    </div>
                </div>
            </footer>
            <!-- End Search -->
        </div>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import MessageUserMessage from "@/views/MessageUserMessage.vue";
import ChatOptions from "@/views/ChatOptions.vue";
import ChatAIMessage from "@/views/ChatAIMessage.vue";
import ChatEmptyState from "@/views/ChatEmptyState.vue";
import ChatEmptyStateMessage from "@/views/ChatEmptyStateMessage.vue";
import NewChatToolbar from "@/views/NewChatToolbar.vue";
import ChatTextArea from "@/views/ChatTextArea.vue";
import EmptyState from "@/components/Admin/EmptyState.vue";
import MessageViewer from "@/components/Admin/MessageViewer.vue";

export default defineComponent({
    components: {
        MessageViewer,
        EmptyState,
        ChatTextArea,
        NewChatToolbar,
        ChatEmptyStateMessage,
        ChatEmptyState,
        ChatAIMessage,
        ChatOptions,
        MessageUserMessage,
    },
    props: {
        messages: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    mounted() {
        // setInterval(() => {
        // try {
        this.$nextTick(() => {
            this.$refs.wrapper.scrollTop = this.$refs?.chat?.scrollHeight;
        });
        //     } catch (e) {
        //         // console.log(e);
        //     }
        // }, 5000);
    },
    data() {
        return {
            messagesDemo: [
                // { role: "assistant", text: "Preline UI Figma is the largest free design system for Figma, crafted with Tailwind CSS styles and Preline UI components with extra top-notch additions." },
                // { role: "user", text: "how many starter pages & examples are there?" },
                // { role: "user", text: "show me the code", files: [{ url: 1, name: "Is Tailwind CSS a free library?" }] },
                // { role: "assistant", text: "Preline UI Figma is the largest free design system for Figma, crafted with Tailwind CSS styles and Preline UI components with extra top-notch additions." },
                // { role: "assistant", text: "Hold on a sec..." },
                // { role: "user", text: "show me the code", options: [{ id: 1, text: "Is Tailwind CSS a free library?" }] },
                //add a mock dialog with special messages, like files, images sources and options
            ],
        };
    },
});
</script>
<!--                <ChatAIMessage text="Hold on a sec..."><ProgressStatusList /></ChatAIMessage>-->
