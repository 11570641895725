<template>
    <li class="max-w-4xl py-2 px-4 sm:px-6 lg:px-8 mx-auto flex gap-x-2 sm:gap-x-4">
        <IconTest />
        <div class="grow max-w-[90%] md:max-w-2xl w-full space-y-3">
            <!--            <MessageText :text="text" />-->
            <Markdown :content="text"></Markdown>
            <slot></slot>
            <SpecialMessages v-if="message" :message="message" />
            <!--            <MessageButtonPile />-->
        </div>
    </li>
</template>
<script>
import IconTest from "@/views/IconTest.vue";
import MessageButtonPile from "@/views/MessageButtonPile.vue";
import MessageText from "@/views/MessageText.vue";
import SpecialMessages from "@/views/SpecialMessages.vue";
import Markdown from "@/components/chat/specialMessages/Markdown.vue";

export default {
    name: "ChatAIMessage",
    components: { Markdown, SpecialMessages, IconTest, MessageButtonPile, MessageText },
    props: {
        message: {
            type: Object,
            default: () => ({}),
        },
        text: {
            type: String,
            default: "Hello, how can I help you?",
        },
    },
};
</script>
