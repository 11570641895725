<template>
    <div class="col-span-12">
        <UIWrapperText :title="sectionName" class="relative" :section="sectionIdOverride">
            <BaseButton
                style-type="secondary"
                class="top right !absolute mr-5"
                style="top: 0px; right: 0px"
                icon="fas fa-pencil"
                icon-only
                @click.prevent="
                    edit = !edit;
                    showAll = true;
                "
                size="sm"
                rounded />

            <div class="col-span-12" v-if="images?.length > 0">
                <!--                <div class="py-3 px-3" v-if="$route.path.includes('admin')">-->
                <!--                    <upload-user-photo @photo-uploaded="handleUploadedImage"></upload-user-photo>-->
                <!--                </div>-->
                <div class="" :class="{ 'mb-5 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-2': sectionName !== 'Logos', 'mb-5 f overflow-x-scroll scrollbar-hide lg:flex-wrap gap-2': sectionName === 'Logos' }">
                    <template v-if="!edit">
                        <template v-for="(image, index) in filteredImages" :key="image">
                            <StyleGuideImage :class="{ 'flex-shrink-0': mobile }" v-if="image.width && image.url" @toggle-logo="toggleLogo" :image="image" :index="index" />
                        </template>
                    </template>
                </div>
                <div class="f aic jcc flex-wrap gap-2 m-0" v-if="edit">
                    <template v-for="(image, index) in images" :key="image.url">
                        <div style="width: 30%">
                            <StyleGuideImageRow :size="400" :bg="bg" :key="image.url + 1" :index="index" :image="image"></StyleGuideImageRow>
                        </div>
                    </template>
                </div>
                <div class="mw-700 mx-auto my-3 text-center f fc gap-4 jcc aic" v-if="sectionName !== 'Logos'">
                    <a class="!rounded-md" v-if="images?.length" href="#" @click.prevent="showAll = !showAll" :style="applyStyle(styleGuide.buttonStyles[1])">Show all ({{ imgs?.length }})</a>
                </div>
                <debug-object v-if="sectionName !== 'Logos'" :object="imgs"></debug-object>
            </div>
        </UIWrapperText>
    </div>
</template>
<script>
import StyleGuideImage from "@/components/styleGuide/Images/StyleGuideImage.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import StyleGuideImageRow from "@/components/styleGuide/Images/StyleGuideImageRow.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import UploadUserPhoto from "@/components/images/PhotoUploader.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";

export default {
    name: "LogoSection",
    components: { UIWrapperText, DebugObject, UploadUserPhoto, BaseButton, StyleGuideImageRow, StyleGuideContainer, StyleGuideImage },
    mixins: [styleGuideMixin],
    props: {
        sectionName: {
            type: String,
            required: false,
            default: "Images",
        },
        sectionId: {
            type: String,
            required: false,
            default: "processedImages",
        },
        imgs: {
            type: Array,
            default: () => [],
            validator: value => {
                return Array.isArray(value) || value === null;
            },
        },
        bg: {
            type: String,
            // required: true,
        },
    },
    data() {
        return {
            weights: {
                type: 1,
                size: 0,
                dimensions: 0.5,
                vibrancy: 3,
                // type: 5,
                // size: 5,
                // dimensions: 0.08,
                // vibrancy: 266,
            },
            edit: false,
            showAll: false,
            order: ["webp", "png", "jpeg", "svg", "gif"],
        };
    },
    mounted() {
        this.$nextTick(() => {});
    },
    computed: {
        sectionIdOverride() {
            let id = "processedImages";
            if (this.sectionId) {
                id = this.sectionId;
            }
            return id;
        },
        filteredImages() {
            if (this.sectionName === "Logos") {
                return this.imgs;
            }
            if (this.public && !this.showAll) {
                return this.imgs.slice(0, 10);
            } else if (this.showAll) {
                return this.imgs;
            } else if (this.isAdmin) {
                return this.imgs;
            } else {
                return this.imgs.slice(0, 10);
            }
        },
    },
    methods: {
        toggleLogo(image) {
            console.log("toggle logo", image);
            image.kind = "logo";
            this.updateStyleGuideProp("logos", [image]);
            if (this.logos && this.logos.includes(image)) {
                this.updateStyleGuideProp(
                    "logos",
                    this.logos.filter(logo => logo !== image),
                    true,
                );
            }
        },
        handleUploadedImage(image) {
            console.log("handling uploaded image", image);
            if (this.sectionIdOverride === "logos") {
                this.updateStyleGuideProp("logos", [{ url: image.url }]);
            }
            this.addStyleGuideImage(image);
        },
    },
};
</script>
