<template>
    <transition enter-active-class="swingInUp duration-3 animated" enter-from-class="o-0 left-50 opacity-0" enter-to-class="bg-blur" leave-active-class="duration-1 fadeOut animated">
        <div ref="editMenu" class="presentation_menu" style="z-index: 0" @mouseleave="$emit('mouseleave')">
            <div class="f fc br-10 text-left">
                <div class="scrollSection scrollbar-hide" style="max-height: 60vh">
                    <div class="f mb-3 mt-5 gap-1">
                        <div v-for="icon in styleTypes">
                            <BaseButton :icon="icon.icon+' text-base-900 dark:text-base-50 o-100 '" class="bg-transparent p-3" icon-only label="none" size="xxs" style-type="secondary" @click.prevent="slide.style = icon.id" />
                        </div>
                        <div class="f ml-3">
                            <BaseButton class="bg-transparent p-3" icon="fas fa-lightbulb text-base-900 o-100" icon-only label="none" size="xxs" style-type="secondary" @click.prevent="(slide.mode = 'dark'), slideClass('dark')" />
                            <BaseButton class="bg-transparent p-3" icon="fal fa-lightbulb text-base-900 o-100" icon-only label="none" size="xxs" style-type="secondary" @click.prevent="(slide.mode = 'light'), slideClass('light')" />
                        </div>
                    </div>
                    <SlideEditColors class="my-3" :color-count="colorCount" :index="index" :slide="slide" />
                    <BaseInput :modelValue="section.title" class="dark mb-0" label="Title" placeholder="Section title" @update:modelValue="section.title = $event"></BaseInput>
                    <BaseTextarea :modelValue="section.body" class="dark mb-0" label="Body" placeholder="Section body" @update:modelValue="section.body = $event"></BaseTextarea>
                    <div class="p-relative">
                        <BaseTextarea :modelValue="section.enhancedBody" class="dark mb-0" label="Enhanced Body" placeholder="Enhanced Body" @update:modelValue="section.enhancedBody = $event" />
                        <div class="p-absolute bottom right p-1">
                            <BaseButton icon="fas fa-sync" class="" icon-only label="none" rounded size="xxs" style-type="secondary" @click.prevent="enhanceBody(section.body, index, extractPresentationText, section)" />
                        </div>
                    </div>
                    <BaseInput :modelValue="section.image" :rows="1" class="mb-1" label="Basic Image" placeholder="Image description" @update:modelValue="section.image = $event" />
                    <BaseTextarea :modelValue="section.enhancedImage" class="mb-1" label="Enhanced image" placeholder="Enhanced image" @update:modelValue="section.enhancedImage = $event" />
                </div>
                <BaseButton class="x bg-dark-900 border-0 text-snow" size="md" label="Update slide" style-type="primary" @click.prevent="clickedUpdate()"></BaseButton>
            </div>
        </div>
    </transition>
</template>

<script>
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import BaseTextarea from "@/components/CoreUI/BaseTextarea.vue";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import SlideEditColors from "@/components/Presentations/slides/SlideEditColors.vue";

export default {
    components: {
        SlideEditColors,
        BaseButton,
        BaseTextarea,
        BaseInput,
    },
    mixins: [documentManagementMixin, presentationMixin],
    props: {
        slide: {
            type: Object,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            styleTypes: [
                {
                    id: "left",
                    name: "Image Left",
                    icon: "fas fa-align-left",
                },
                {
                    id: "right",
                    name: "Image Right",
                    icon: "fas fa-align-right",
                },
                {
                    id: "fullbleed",
                    name: "Full Bleed",
                    icon: "fas fa-align-justify",
                },
                {
                    id: "multiimage",
                    name: "Multiple Images",
                    icon: "fas fa-image",
                },
                {
                    id: "tablegrid",
                    name: "Grid",
                    icon: "fas fa-th",
                },
                {
                    id: "matrix",
                    name: "Matrix",
                    icon: "fas fa-th-large",
                },
                {
                    id: "timeline",
                    name: "Timeline",
                    icon: "fas fa-stream",
                },
                // {
                //     id: "other",
                //     name: "Other",
                //     icon: "fas fa-ellipsis-h",
                // },
            ],
        };
    },
    computed: {
        colorCount() {
            if (this.slide.style === "fullbleed") {
                return 10;
            } else {
                return 9;
            }
        },
    },
    methods: {
        clickedUpdate() {
            // this.updateSlide(this.slide, this.section, this.index, this.document);
            const body = this.section;
            console.error(body);
            this.enhanceBody(body, this.index, this.extractPresentationText, this.section);
            // this.document.sections[this.index] = this.section;
            this.savePresentation(this.$route.params.id);
        },
        saveDoc(document) {
            this.savePresentation(this.$route.params.id);
        },
        changeStyle() {
            this.savePresentation(this.$route.params.id);
        },
    },
};
</script>

<style scoped>
.presentation_menu {
    @apply w-full;
    @apply width-350;
    @apply md:min-w-fit;
    @apply mix-blend-overlay;
    @apply rounded-xl;
    @apply border border-base-200;
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply z-0;
    @apply m-3;
    @apply mt-6;
    @apply mr-2;
    @apply mt-3;
    @apply h-auto;
    @apply bg-base-50;
    @apply dark:bg-base-700;
    @apply p-3;
    @apply shadow;
}
</style>
