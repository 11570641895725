import moment from "moment";

import { db } from "@/firebase";
import JSON5 from "json5";
import App from "@/App.vue";
import { defaults } from "lodash";
import chalk from "chalk";
import JSONmixins from "@/mixins/JSONmixins";
import blacklist_sites from "@/utils/blacklist_sites";
import gptError from "@/mixins/ai/gpt_error";
import randomId from "@/mixins/ai/random_id";
import compareAndLogChanges from "@/mixins/ai/compare_and_change_logs";
import chromaMixin from "@/mixins/StyleMixins/chromaMixin";
import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import webRequest from "@/mixins/ai/web_request";
import { formatObjectToString } from "@/mixins/objectToString";
export default {
    inject: ["isDarkMode"],
    created: function () {},
    data() {
        return {
            debug: false,
            local: true,
        };
    },
    mixins: [JSONmixins, chromaMixin],
    computed: {
        isAdmin() {
            return this.$route?.path?.includes("admin") || this.superAdmin;
        },
        superAdmin() {
            return this.$store.state?.user?.user?.uid === "Pn6tFBNy63US4RpIGyEsLdaSyRy1";
        },
        public() {
            return this.$route?.path?.includes("styleGuide");
        },
        apiURL() {
            const environment = import.meta.env.VITE_ENV;
            const isLocal = environment !== "production";
            const hostname = window.location.hostname;
            const port = window.location.port;

            if (isLocal) {
                return `https://api.brandblocks.co/`;
                // return `http://${hostname}:8080/`;
                // return `http://${hostname}:${port}/`;
            } else {
                return `https://api.brandblocks.co/`;
                // return `https://api.ashh.co/`;
                // return `http://34.170.111.18/`;
            }
        },
        user() {
            return this.$store?.state?.user?.user || false;
        },
        blacklist() {
            return blacklist_sites;
        },
        userPlaceTime() {
            let city = "";
            let state = "";
            let user = this.$store.state.user.user;
            let location;
            let name;
            if (user.location && user.location.structuredAddress && user.location?.structuredAddress?.locality) {
                city = user.location.structuredAddress.locality;
            }
            if (user?.location?.structuredAddress && user.location?.structuredAddress?.administrativeArea) {
                state = user.location.structuredAddress.administrativeArea;
            }
            if (city && state) {
                location = `${city}, ${state}`;
            }

            let dateTimeForLocation = moment().format("MMMM Do YYYY, h:mm a");
            let timePlace = {
                date: dateTimeForLocation,
            };
            if (user.displayName) {
                timePlace.name = user.displayName;
            }
            if (location) {
                timePlace.location = location;
            }
            return timePlace;
        },
        hideHeader() {
            return this.$store.state.hideHeader;
        },
        showMobileChatList() {
            return this.$store.state.chat.showMobileChat;
        },
        showDebugger() {
            return this.$store.state.chat.showDebugger;
        },
        alerts() {
            return this.$store.state.alerts;
        },
        darkMode() {
            const isDarkMode = this.isDarkMode;
            if (isDarkMode) {
                return true;
            } else {
                return false;
            }
        },

        fetching() {
            return this.$store.state.fetching;
        },
        toggleView() {
            return this.$store.state.toggleView;
        },
        viewportSize() {
            return window.innerWidth;
        },
        mobile() {
            return this.$store.state.mobile;
        },
        tablet() {
            return this.$store.state.tablet;
        },
        textResponse() {
            return this.$store.state.textResponse;
        },
        step() {
            return this.$store.state.step;
        },
        promptData() {
            return this.$store.state.promptData;
        },
    },
    methods: {
        formatObjectToString,
        makeId(identifier) {
            // remove dots and dashes
            return identifier.replace(/[\.-]/g, "");
        },
        modelShortName(model) {
            switch (model) {
                case "gpt-4":
                case "gpt-4-1106-preview":
                case "gpt-4-vision-preview":
                case "gpt-4-0613":
                case "gpt-4-32k":
                case "gpt-4-32k-0613":
                    return "GPT-4";

                case "gpt-3.5-turbo":
                case "gpt-3.5-turbo-1106":
                case "gpt-3.5-turbo-16k":
                case "gpt-3.5-turbo-instruct":
                case "gpt-3.5-turbo-0613":
                case "gpt-3.5-turbo-16k-0613":
                case "gpt-3.5-turbo-0301":
                    return "GPT-3.5";

                case "gpt-3":
                case "gpt-3-175b-1106":
                    return "GPT-3";

                // Add cases for other models if needed
                // ...

                default:
                    return model;
            }
        },
        formattedSizeString(size) {
            const bytes = size;
            if (bytes === 0) return "0 Bytes";
            const k = 1024;
            const dm = 2; // number of decimal places
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        },
        removeItemFromArray(storeKey, arrayKey, payload, optionalKeyToFindIndex) {
            this.$store.dispatch("removeItemFromArrayAction", {
                storeKey,
                arrayKey,
                payload,
                optionalKeyToFindIndex,
            });
        },
        goToRoute(name, id) {
            // if no link is detected, treat it as an id and replace the path's ids (this.$route.params.id) with the new id
            if (id) {
                console.log("Navigating to route", name, id);
                try {
                    return this.$router.push({ path: `/${name}/${id}` });
                } catch (error) {
                    console.error(error);
                }
            } else {
                return this.$router.push({ name: name });
            }
        },
        goToLink(path) {
            return this.$router.push(path);
        },
        randomSample(samples) {
            return samples[Math.floor(Math.random() * samples.length)];
        },
        async getReverseGeolocation(position) {
            console.groupCollapsed(`%c 🗺️ Getting reverse geolocation data`, info);
            let latitude = 37.3316851;
            let longitude = -122.0300674;
            if (position) {
                latitude = position.latitude;
                longitude = position.longitude;
            }
            console.log("📍", latitude, longitude);
            console.groupEnd();
            try {
                const request = { latitude: latitude, longitude: longitude };
                const response = await webRequest("geo", request);
                if (response.ok) {
                    console.groupCollapsed(`%c 🗺️ Reverse geolocation data received`, info);
                    const data = await response.json();
                    console.log(data.results[0]);
                    console.groupEnd();
                    return data.results[0];
                } else {
                    throw new Error(`Server Error: ${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                console.error("Error fetching reverse geolocation data:", error);
            }
        },
        isUrl(value) {
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            return urlRegex.test(value);
        },
        isImageUrl(value) {
            if (typeof value === "string") {
                //check if the url is an image by seeing if it has a.jpg or.jpeg extension
                const urlRegex = /^(ftp|http|https):\/\/[^ "]+\.(jpg|jpeg|png|gif|svg|webp|bmp|ico)$/i;
                if (value.includes("googleusercontent") || urlRegex.test(value)) {
                    return true;
                }
            }
            return false;
        },
        getResizedImageUrl(originalUrl, width = 200, height = 200) {
            if (!originalUrl) return "";
            const urlParts = originalUrl?.split(".");
            const filenamePrefix = urlParts.slice(0, -1).join(".");
            const fileExtension = urlParts.slice(-1)[0].split("?")[0];

            const resizedFilename = `${filenamePrefix}_${width}x${height}.${fileExtension}`;
            return `${resizedFilename}?alt=media`;
        },
        production() {
            const environment = import.meta.env.VITE_ENV;

            console.error("environment: ", environment);
            // const store = createStore(Store);
            if (environment !== "production") {
                this.debug = true;
            }
        },
        fixCamelCase(string) {
            const splitCamelCase = str => {
                return str
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .split(/[\s-]+/)
                    .map(word => word)
                    .join(" ");
            };
            return splitCamelCase(string);
        },
        convertCase(str = "") {
            try {
                if (typeof str !== "string") return str;
                if (!str) return;
                // turn pascal, kebab, camel or snake case to title case
                str = str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
                    return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
                });
                str = str.replace(/\s+/g, ""); // trim
                str = str.replace(/-+/g, " "); // collapse dashes
                str = str.replace(/_+/g, " "); // collapse dashes
                str = str.replace(/^-+/, ""); // trim leading dashes
                str = str.replace(/-+$/, ""); // trim trailing dashes
                return str;
            } catch (e) {
                console.error(e);
                console.log(str);
            }
        },
        hideImage(event) {
            event.target.style.display = "none";
        },
        hideImageParent(event) {
            event.target.parentNode.style.display = "none";
        },
        timeAgo(date) {
            moment.locale("en", {
                relativeTime: {
                    future: "in %s",
                    past: "%s ago",
                    s: "seconds",
                    ss: "%ss",
                    m: "a minute",
                    mm: "%dm",
                    h: "an hour",
                    hh: "%dh",
                    d: "a day",
                    dd: "%dd",
                    M: "a month",
                    MM: "%dM",
                    y: "a year",
                    yy: "%dY",
                },
            });
            let relDate = moment(date, "YYYYMMDD");
            var currentTime = moment();
            var minutes = currentTime.diff(relDate, "minutes");
            let text;
            // If less than 60 minutes have passed, display the timestamp in a relative format
            if (minutes < 120) {
                var timeAgoString = relDate.fromNow();
                text = timeAgoString;
            } else if (minutes < 180) {
                var timeAgoString = relDate.fromNow();
                var dateString = relDate.format("hh:mm a");
                // text = timeAgoString+' '+dateString;
                text = dateString;
            } else if (minutes < 1440) {
                var timeAgoString = relDate.fromNow();
                var dateString = relDate.format("hh:mm a");
                text = "Today at " + dateString;
            } else {
                var dateString = relDate.format("MMM D, YYYY");
                text = dateString;
            }
            return text;
            // return relDate;
        },
        gptError,
        handleImageError(error) {
            console.groupCollapsed("Image loading error");
            console.log("Image loading error:", error);
            console.log("Image source URL:", error?.target?.src);
            console.groupEnd();
            return true;
        },
        updateMousePosition(ev) {
            this.mousePosition = { x: ev.clientX, y: ev.clientY };
        },
        stringifyObjectSimple(obj, indent = 2) {
            const processValue = value => {
                if (Array.isArray(value)) {
                    return `${value.map(processValue).join(", ")}`;
                } else if (typeof value === "object" && value !== null) {
                    return this.stringifyObjectSimple(value, indent);
                } else {
                    return "";
                }
            };

            const entries = Object.entries(obj)
                .map(([key, value]) => `${" ".repeat(indent)}${key}: ${processValue(value)}`)
                .join("\n");

            return `${entries}`;
        },
        flattenObject(arrayOfObjects) {
            const flatten = (obj, currentKeyPrefix = "") => {
                let result = {};
                for (const key in obj) {
                    if (key === "key") {
                        currentKeyPrefix = obj[key];
                        continue;
                    }

                    const newKey = currentKeyPrefix ? `${currentKeyPrefix}_${key}` : key;

                    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
                        result = {
                            ...result,
                            ...flatten(obj[key], newKey),
                        };
                    } else {
                        result[newKey] = obj[key];
                    }
                }
                return result;
            };

            const flattenedOutput = {};
            arrayOfObjects.forEach((item, index) => {
                const currentKeyPrefix = item.key || Object.keys(item)[0]; // Use the first property name as a key if "key" property is not present
                const result = flatten(item, currentKeyPrefix);
                Object.keys(result).forEach(key => {
                    flattenedOutput[key] = result[key];
                });
            });
            return flattenedOutput;
        },

        unflattenObject(obj) {
            const result = {};

            for (const key in obj) {
                const keys = key.split("_");
                let current = result;

                for (let i = 0; i < keys.length - 1; i++) {
                    const subKey = keys[i];
                    if (!current.hasOwnProperty(subKey)) {
                        current[subKey] = {};
                    }
                    current = current[subKey];
                }

                current[keys[keys.length - 1]] = obj[key];
            }

            return result;
        },
        isCode(text) {
            const isHTML = new RegExp("<[a-z][\\s\\S]*>", "i").test(text);
            const isCodeBlock = new RegExp("^```[\\s\\S]*```$", "m").test(text);
            const isJS = new RegExp("^(import\\s(\\{[\\s\\S]*\\}|[\\w\\-]+)\\sfrom\\s|export\\s|const\\s|let\\s|function\\s|class\\s|methods:\\s*\\{|async\\s[a-zA-Z_][a-zA-Z0-9_]*\\s*\\()", "m").test(text);

            return isHTML || isCodeBlock || isJS;
        },
        isCSS(text) {
            const isCSS = new RegExp("[\\s\\S]*{[\\s\\S]*:[\\s\\S]*;[\\s\\S]*}", "i").test(text);
            return isCSS;
        },
        addAlert(alert, type = "success") {
            if (typeof alert === "string") {
                alert = { message: alert, type: type };
                this.alert(alert);
            } else if (alert.constructor === Object) {
                this.alert(alert);
            }
            // console.log('addAlert', alert);
            // this.alerts.push(alert);
        },
        sortByUpdated(array) {
            return array.sort((a, b) => {
                return b.updated - a.updated;
            });
        },
        getRandomItem(arr) {
            return arr[Math.floor(Math.random() * arr.length)];
        },
        alert(message, type = "success", icon) {
            let obj;
            if (message.constructor === Object) {
                obj = message;
            } else if (message.constructor === String) {
                obj = {
                    message: message,
                    type: type || "success",
                };
                if (icon) {
                    obj.icon = icon;
                }
            }
            this.$store.commit("pushAlert", obj);
        },
        isObject(value) {
            return value.constructor === Object;
        },
        isArray(value) {
            return value.constructor === Array;
        },
        hasChildren(node) {
            if (node.constructor === Object) {
                return Object.keys(node).length > 0;
            } else if (node.constructor === Array) {
                return node.length > 0;
            } else {
                return false;
            }
        },
        kebabString(string) {
            string = string.replace(/[^\w\s]/gi, ""); // remove special characters
            return string
                .replace(/([a-z])([A-Z])/g, "$1-$2")
                .replace(/\s+/g, "-")
                .toLowerCase();
        },
        uriString(string) {
            return encodeURIComponent(string);
        },
        setShowInput(payload) {
            this.$store.commit("setShowInput", payload);
        },
        setShowButtons(payload) {
            this.$store.commit("setShowButtons", payload);
        },
        setLoadChat(payload) {
            this.scrollChatItem();
            this.$store.commit("setChatLoadingState", payload);
        },
        async scrollTop(ref = "styleGuideContainer") {
            window.scrollTo(0, 0); // Corrected the typo here
            await this.$nextTick(); // Await the next DOM update cycle
            if (this.$refs?.[ref]) this.$refs[ref].scrollTop = 0;
        },
        refreshTopic() {
            const array = this.topics;
            const length = this.arrayLength(array);
            const index = this.pickRandom(length);
            const randomItem = array[index];
            console.log(randomItem);
            console.log(randomItem.nameIdeas);
            this.promptData.topic = randomItem.topic;
        },
        arrayToNumberedList(array) {
            let string = "";
            array.forEach((item, index) => {
                string += `${index + 1}. ` + item;
                string += "\n";
            });
            return string;
        },
        arrayToBulletedList(array, level) {
            let string = "";
            array.forEach((item, index) => {
                if (level) string += " ";
                string += "- " + item;
                string += "\n";
            });
            return string;
        },
        arrayToBulletPoints(array, level) {
            try {
                let string = "";
                array.forEach((item, index) => {
                    if (level) string += " ";
                    string += "- " + item;
                    string += "\n";
                });
                return string;
            } catch (error) {
                console.log(error.message);
            }
        },
        arrayToSentence(array, level, key) {
            try {
                let sentence = array.join(", ");

                if (key) {
                    sentence = `${key}: ${sentence}`;
                }

                return sentence;
            } catch (error) {}
            return array;
        },

        keyValueToString(obj, style = "none") {
            const parts = [];
            let counter = 1;

            Object.entries(obj).forEach(([key, value]) => {
                if (value && key !== "index") {
                    let prefix = "";

                    switch (style) {
                        case "bullet":
                            prefix = "• ";
                            break;
                        case "numbered":
                            prefix = `${counter}. `;
                            counter++;
                            break;
                        // add more styles as needed
                        default:
                            break;
                    }

                    parts.push(`${prefix}${key.replace(/_/g, " ")}: ${value}`);
                }
            });

            return parts.join("\n");
        },
        headerString(header) {
            let headerFormat = "###";
            let string = "";
            string += "\n\n";
            string += headerFormat + " ";
            string += header.toUpperCase();
            string += ":";
            string += "\n";
            return string;
        },
        toDate(i) {
            // Check if the input has a toDate method
            if (i && typeof i.toDate === "function") {
                return i.toDate();
            }

            // Proceed with the i.created check
            if (i && i.created) {
                const obj = i.created;
                const seconds = obj["seconds"];
                const nanoseconds = obj["nanoseconds"];
                const dateRaw = new Date(seconds * 1000 + nanoseconds / 1000000);
                return dateRaw;
            }

            return false;
        },
        dateFix(i) {
            if (i) {
                const obj = i;
                const seconds = obj["seconds"];
                const nanoseconds = obj["nanoseconds"];
                const dateRaw = new Date(seconds * 1000 + nanoseconds / 1000000);
                return dateRaw;
            }
            return false;
        },
        formattedDateString(inputDate) {
            let date;

            if (inputDate) {
                date = moment(inputDate);
            } else {
                date = moment().hour(12).minute(0); // set time to 12 noon if no time is passed
            }

            return date.format("dddd MMMM D, YYYY | h:mma");
        },
        timestamp(item) {
            var timestamp = item.metadata.updateTime;
            return timestamp;
        },
        nextStep() {
            const step = this.step;
            let newStep = step + 1;
            this.setStep(newStep);
        },
        prevStep() {
            const step = this.step;
            let newStep = step - 1;
            this.setStep(newStep);
        },
        setStep(step) {
            this.$store.commit("setStep", step);
        },
        stringArray(array) {
            return array.join(", ");
        },
        parseList(string) {
            try {
                // let array = string.split('\n').map(item => item.replace(/^\d+\.\s/, ""));
                let array = string.split("\n").map(item => item.replace(/^\d+\.\s/, ""));
                this.consoleObject("Trying list instead", { string, array });
                return array;
            } catch (e) {
                return false;
            }
        },
        fixArray(arrayString) {
            if (!arrayString.endsWith("]")) {
                if (!arrayString.endsWith('"')) {
                    arrayString += '"';
                }
                arrayString += "]";
            }
            console.log(arrayString);
            let array = JSON.parse(arrayString);
            console.log(array);
            return arrayString;
        },
        fixArray2(arrayString) {
            // Ensure there are closing square brackets on the array
            if (!arrayString.endsWith("]")) {
                arrayString += "]";
            }

            // Ensure there are matching quotes on each item
            arrayString = arrayString.replace(/"([^"]*)"/g, (match, p1) => {
                if (p1.includes("'")) {
                    // If the item contains single quotes, wrap it in backticks
                    return "`" + match.slice(1, -1) + "`";
                } else {
                    // Otherwise, escape any double quotes in the item
                    return match.replace(/"/g, '\\"');
                }
            });

            // Ensure any string has a closing quote
            arrayString = arrayString.replace(/^\[|,\s*([^,\]]+)$/g, (match, p1) => {
                if (p1 && !p1.endsWith('"')) {
                    return match + '"';
                }
                return match;
            });

            // Ensure items are never wrapped in single quotes
            arrayString = arrayString.replace(/'/g, '"');

            // Parse the fixed array string into a valid array
            const array = JSON.parse(arrayString);

            return array;
        },

        consoleImportant(string) {
            let background = "#d6d9fd";
            let textColor = "#211f2c";
            return console.log(`%c${string}`, `background: ${background}; color: ${textColor}; padding:5px 7px;font-weight:bold; font-size:11px;`);
        },
        randomId,
        removeAlert(alert) {
            this.$store.commit("removeAlert");
            // this.alerts.splice(this.alerts.indexOf(alert), 1);
        },
    },
};
