<template>
    <div class="d-block" v-if="!isCodeNew && !isError" :class="[bgColorClass, { 'c-20 mr-2 mt-1 rounded-full ': mobile, 'c-40 br-10 mr-3': !mobile }]">
        <!--  need to make sure the square doesnt shrink  -->

        <div class="avatar avatar_assistant relative" v-if="!sameRole" :class="[bgColorClass, { 'c-20 rounded-full': mobile, 'c-40': !mobile }]">
            <div class="p-absolute p-fill f white fwb x y aic jcc">
                <!--                <logo class="x" fill-color="white" v-if="role === 'assistant'"></logo>-->
                <img class="x bg-white" src="/assets/icon.png" v-if="role === 'assistant'" />
                <div class="x y p-absolute p-fill f aic jcc bg-red" v-else-if="role === 'function'">
                    <!--                    <i class="fas fa-cog rotate pulse"></i>-->
                    <i class="fas fa-cog fa-rotate fa-spin fa-fw infinite duration-4 ease-back"></i>
                    <!--                    <i class="fas fa-spinner fa-pulse transition-1 fa-fw ml-3 text-gray-600"></i>-->
                </div>
                <template v-else>
                    <img class="x o-cover p-absolute p-fill br-10" v-if="user && user.photoURL" :src="getResizedImageUrl(user.photoURL)" @error="handleImageError(error)" />
                    <div v-else class="p-absolute p-fill f white fwb br-10 f-13 aic jcc bg-base-400">
                        <span v-if="user && user.displayName">{{ user.displayName.split(" ")[0] }}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import Logo from "@/components/CoreUI/navigaion/logo.vue";

export default {
    name: "Chat Avatar",
    displayName: "Chat Avatar",
    components: { Logo },
    props: {
        date: {
            type: [Date, Object],
            required: false,
        },
        sameRole: {
            type: Boolean,
            required: false,
            default: false,
        },
        role: {
            type: String,
            default: "assistant",
            required: true,
        },
        isCodeNew: {},
        bgColor: {
            type: String,
            default: "teal",
        },
        isError: {},
    },
    computed: {
        bgColorClass() {
            if (this.bgColor) {
                return `bg-${this.bgColor}-300 dark:bg-${this.bgColor}-700`;
            } else {
                return "";
            }
        },
    },
};
</script>
