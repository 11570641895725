import findMatchingObject from "@/mixins/firebase/findMatchinObject";

async function findBrand(styleGuideID, originalURL, styleGuideName, database = "brandsList") {
    let brand;
    try {
        brand = await findMatchingObject(database, "url", originalURL);
        let brandID = brand.id;
        return brand;
    } catch {
        try {
            brand = await findMatchingObject(database, "name", styleGuideName);
            let brandID = brand.id;
            return brand;
        } catch {
            try {
                brand = await findMatchingObject(database, "match", styleGuideID);
                let brandID = brand.id;
                return brand;
            } catch {
                try {
                    brand = await findMatchingObject(database, "id", styleGuideID);
                    let brandID = brand.id;
                    return brand;
                } catch (e) {
                    console.log("No brand found");
                }
            }
        }
    }
}
export { findBrand };
