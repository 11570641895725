<template>
    <div>
        <div>
            <!--          {{campaign}}-->
            <!--            <SimpleInput v-model="campaignData.name" label="Name" />-->
            <SimpleInput v-model="campaignData.name" label="Name" />
            <SimpleInput v-model="campaignData.subject" label="Subject" />
            <!--            <InlineMessage message="heyyy" type="error" />-->
            <div class="mb-5" v-if="lists">
                <SimpleSelectorNew v-model="selectedList" :options="lists" label="List:" option-label="name" option-value="id" placeholder-text="Select a list" />
            </div>
            <div class="mt-3" v-if="superAdmin">
                <SimpleButton class="x" @click="showModal = true">Create Campaign</SimpleButton>
            </div>
            <UICollapsableSection v-if="superAdmin" title="details">
                <template #title>Details</template>
                <div>
                    <ObjectViewer v-if="campaign" :data="campaign" />
                </div>
            </UICollapsableSection>
        </div>
        <Transition name="fade-up">
            <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-50">
                <div class="absolute inset-0 bg-gray-200 opacity-75"></div>
                <div class="bg-white rounded-2xl shadow-2xl p-10 w-full max-w-lg z-10">
                    <h2 class="text-3xl font-semibold mb-8">Create Campaign</h2>
                    <SimpleInput v-model="campaignData.name" label="Name" />
                    <SimpleInput v-model="campaignData.subject" label="Subject" />

                    <div class="flex justify-end">
                        <SimpleButton @click="createCampaign">Create</SimpleButton>
                        <SimpleButton class="x" label="Cancel" @click="showModal = false"></SimpleButton>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SimpleButton from "@/views/SimpleButton.vue";
import SimpleInput from "@/views/SimpleInput.vue";
import SimpleSelectorNew from "@/components/Admin/SimpleSelectorNew.vue";
import ObjectViewer from "@/components/Admin/ObjectViewer.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";

export default {
    name: "KlaviyoCampaignForm",
    components: { UICollapsableSection, ObjectViewer, SimpleSelectorNew, SimpleInput, SimpleButton },
    props: {
        campaign: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            showModal: false,
            campaignData: {
                name: "",
                subject: "",
            },
        };
    },
    mounted() {
        this.fetchLists();
        if (this.campaign) {
            this.campaignData = {
                name: this.campaign.name,
                subject: this.campaign.subject,
            };
        }
    },
    computed: {
        ...mapState("email", ["lists", "segments"]),
        selectedList: {
            get() {
                return this.$store.state.email.selectedList;
            },
            set(value) {
                this.$store.commit("email/setSelectedList", value);
            },
        },
        selectedSegment: {
            get() {
                return this.$store.state.email.selectedSegment;
            },
            set(value) {
                this.$store.commit("email/setSelectedSegment", value);
            },
        },
    },
    methods: {
        ...mapActions("email", ["fetchLists"]),
        async createCampaign() {
            try {
                await this.$store.dispatch("email/createCampaign", {
                    name: this.campaignData.name,
                    subject: this.campaignData.subject,
                    listId: this.selectedList,
                    segmentId: this.selectedSegment,
                });
                this.showModal = false;
            } catch (error) {
                console.error("Failed to create campaign", error);
            }
        },
    },
};
</script>
<!--                    <div class="mb-3">&ndash;&gt;-->
<!--                        <label class="block text-gray-700 text-lg font-semibold mb-1">List</label>-->
<!--                        <select v-model="selectedList" class="w-full px-4 py-3 text-lg bg-gray-100 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" @change="fetchSegments">-->
<!--                            <option disabled value="">Select a list</option>-->
<!--                            <option v-for="list in lists" :key="list.id" :value="list.id">-->
<!--                                {{ list.name }}-->
<!--                            </option>-->
<!--                        </select>-->
<!--                    </div>-->
<!--                    <div class="mb-8">-->
<!--                        <label class="block text-gray-700 text-lg font-semibold mb-1">Segment:</label>-->
<!--                        <select v-model="selectedSegment" :disabled="!segments.length" class="w-full px-4 py-3 text-lg bg-gray-100 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-blue-500">-->
<!--                            <option disabled value="">Select a segment</option>-->
<!--                            <option v-for="segment in segments" :key="segment.id" :value="segment.id">-->
<!--                                {{ segment.name }}-->
<!--                            </option>-->
<!--                        </select>-->
<!--                    </div>-->
