// email.js (Vuex store module)
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import webRequest from "@/mixins/ai/web_request";

export default {
    namespaced: true,
    state() {
        return {
            key: "",
            lists: [],
            segments: [],
            templates: [],
            campaigns: [],
            selectedList: "",
            selectedSegment: "",
        };
    },
    mutations: {
        setKey(state, key) {
            state.key = key;
        },
        setLists(state, lists) {
            state.lists = lists;
        },
        setSegments(state, segments) {
            state.segments = segments;
        },
        setTemplates(state, templates) {
            state.templates = templates;
        },
        setCampaigns(state, campaigns) {
            state.campaigns = campaigns;
        },
        setSelectedList(state, listId) {
            state.selectedList = listId;
        },
        setSelectedSegment(state, segmentId) {
            state.selectedSegment = segmentId;
        },
    },
    actions: {
        async fetchLists({ commit, rootGetters }) {
            try {
                let styleGuide = rootGetters["styleGuide/styleGuide"];
                let user = rootGetters["user/user"];
                let response = await webRequest("email/lists", { id: styleGuide.id, user: user });
                let lists = await response.json();
                console.log("Lists", lists);
                commit("setLists", lists || []);
            } catch (error) {
                console.error("Failed to fetch lists", error);
                commit("setLists", []);
            }
        },
        async fetchSegments({ state, commit }) {
            try {
                let response = await webRequest(`email/lists/${state.selectedList}/segments`);
                let segments = await response.json();
                commit("setSegments", segments.data || []);
            } catch (error) {
                console.error("Failed to fetch segments", error);
                commit("setSegments", []);
            }
        },
        async createCampaign({ state, commit }, { name, subject, listId, segmentId }) {
            try {
                const campaignData = {
                    data: {
                        type: "campaign",
                        attributes: {
                            name,
                            "campaign-messages": {
                                data: [{ type: "campaign-message", attributes: { channel: "email", content: { subject } } }],
                            },
                            audiences: { included: [listId] },
                        },
                    },
                };
                if (segmentId) {
                    campaignData.data.attributes.audiences.included.push(segmentId);
                }
                // let response = await webRequest("email/campaigns", campaignData);
                // let campaign = await response.json();
                commit("setCampaigns", [...state.campaigns, campaign.data]);
            } catch (error) {
                console.error("Failed to create campaign", error);
            }
        },
    },
    getters: {
        selectedListObject(state) {
            return state.lists.find(list => list.id === state.selectedList);
        },
    },
};
