import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import webRequest from "@/mixins/ai/web_request";

async function upresImage(url, upscale = 2) {
    let request = { url: url, upscale: upscale };
    console.groupCollapsed(`%c 🌇 Upscale image`, success, url);
    console.log("URL: ", url);
    console.log("Scale: ", upscale);
    console.groupEnd();
    const response = await webRequest("upresImage", request);
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    console.log("Got image!");
    // parse the response as JSON
    const data = await response.json();
    const imageUrl = data.url; // Extracts image URL from the response
    console.log("Image URL:", imageUrl);
    return imageUrl; // return the imageURL
}

export default upresImage;
