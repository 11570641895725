<template>
    <div class="f x jcb aic aic mx-auto gap-2 p-2 text-center" v-if="!mobile">
        <p class="mb-0 line-clamp-1" v-if="chat && chat.chatTitle">{{ chat.chatTitle }}</p>
        <div class="f jcb aic gap-1">
            <Voice-Picker v-show="false"></Voice-Picker>
            <BaseDropdown id="stylePreset" :inline="true" :modelValue="model" :options="models.map(m => ({ label: m, value: m }))" class="mb-0 mr-2 block" placeholder="Select a style preset" @update:modelValue="model = $event"></BaseDropdown>
            <ToggleButton v-if="$store.state.chat.showDebugger" vuex-key="chat.advancedDebugger" :border="1" :height="25" :width="40" label="🦟" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
            <ToggleButton vuex-key="chat.showDebugger" :border="1" :height="22" :width="35" label="🐞" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
            <ToggleButton vuex-key="stream.json_mode" :skip-cookie="true" :border="1" :height="22" :width="35" label="⚙️" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
            <ToggleButton vuex-key="chat.commands" :border="1" :height="22" :width="35" label="🪖" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
            <ToggleButton vuex-key="chat.autoGPT" :border="1" :height="22" :width="35" label="🤖" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
            <BaseInput class="mw-50 f-20" inline placeholder="message length" v-model="maxMessageCount"></BaseInput>
        </div>
        <!--                                <ToggleButton v-model:vue-key="commands" :border="1" :height="25" :width="50" label="Commands: " off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>-->
        <!--        <div v-show="false">-->

        <!--        </div>-->
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ToggleButton from "@/components/CoreUI/ToggleButton.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-cards";
import BaseDropdown from "@/components/CoreUI/BaseDropdown.vue";
// voicepicker
import VoicePicker from "@/components/CoreUI/VoicePicker.vue";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
export default {
    setup() {
        const onSwiper = swiper => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
        };
    },
    name: "ChatToolbar",
    components: { BaseInput, BaseDropdown, BaseButton, ToggleButton, VoicePicker, Swiper, SwiperSlide },
    props: {
        chat: {},
    },
    data() {},
    computed: {
        maxMessageCount: {
            get() {
                // Get the value from the Vuex store
                return this.$store.state.stream.maxMessageCount;
            },
            set(value) {
                // Dispatch an action to update the Vuex store
                this.$store.dispatch("stream/updateMaxMessageCount", value);
            },
        },
        model: {
            get() {
                // Get the value from the Vuex store
                return this.$store.state.chat.model;
            },
            set(value) {
                // Update the value in the Vuex store using the 'updateModel' action
                this.$store.dispatch("chat/updateModel", value);
            },
        },
        models() {
            if (this.$store.state.chat.models && this.$store.state.chat.models.length > 0) {
                return this.$store.state.chat.models;
            } else {
                return ["OpenAI", "gpt-3.5-turbo", gpt4, "claude-1.3", "ANTHROPIC", "claude-instant-1.1", "claude-instant-1.1-100k", "claude-1-100k"];
            }
        },
    },
    methods: {
        updateMaxMessageCount() {
            // Dispatch an action to update the Vuex store
            this.$store.dispatch("stream/updateMaxMessageCount", this.messageLength);
        },
        openChatList() {
            // this.isChatListVisible = true;
            this.$store.commit("chat/setShowMobileChat", true);
        },
    },
};
</script>
