import JSON5 from "json5";
import json_helpers from "@/mixins/ai/json_helpers";
let { fixJSONQuotes, makeJSONReadable, processJSON, masterJSONFixer, parseJSON, fixAndParseJSON, isValidJSON, jsonSubstring, checkStreamingJSON, validateJSON, excludeJSON, jsonString } = json_helpers;
export default {
    created: function () {},
    mounted() {},
    data() {
        return {};
    },
    computed: {},
    methods: {
        fixJSONQuotes,
        makeJSONReadable,
        processJSON,
        fixAndParseJSON,
        isValidJSON,
        parseJSON,
        masterJSONFixer,
        jsonSubstring,
        checkStreamingJSON,
        validateJSON,
        excludeJSON,
        jsonString,
    },
};
