<template>
    <div>
        <table class="br-10 border border-light overflow-hidden shadow mb-8">
            <thead class="rounded-t-lg overflow-hidden">
                <tr>
                    <th>Sample</th>
                    <th>Name</th>
                    <th class="text-center">Style</th>
                    <th class="text-center">Weight</th>
                    <th v-if="showUsage" class="text-center">Usage Count</th>
                    <th class="text-center">Kind</th>
                    <th v-if="false">Code</th>
                    <th>Link</th>
                </tr>
            </thead>
            <tbody v-if="sortFontsByName" class="bg-snow">
                <template v-for="f in sortFontsByName">
                    <CollapsableMenuItem v-if="font?.name !== 'Poppins'" :font="f">
                        <template is="tr" #title>
                            <!--                                <tr>-->
                            <td v-if="f.name.includes('awesome') || f.name.includes('Awesome')">
                                <div :style="`font-family:${f.name};font-weight:${f.weight}!font-style:${f.style}!important;font-size:40px;`" class="f aic gap-3">
                                    <template v-for="item in ['dog', 'house', 'envelope', 'check', 'music', 'star']">
                                        <i :class="`small p-2 fa fa-${item}`"></i>
                                    </template>
                                </div>
                            </td>
                            <td v-else>
                                <div class="f aic jcs gap-3">
                                    <BaseButton icon-only icon="far fa-trash-alt" size="xxs" @click="deleteFont(f)"></BaseButton>
                                    <div :style="`font-family:${f.name};font-weight:${f.weight};font-style:${f.style};font-size:40px;`">The Quick Brown Fox</div>
                                </div>
                            </td>
                            <td v-if="f.name">{{ f.name.slice(0, 30) }}</td>
                            <td class="text-center">{{ f.style || "normal" }}</td>
                            <td class="text-center">{{ f.weight || "400" }}</td>
                            <td v-if="showUsage" class="text-center">{{ f.usageCount || 0 }}</td>
                            <td class="text-center">{{ f.kind || "woff2" }}</td>
                            <td v-if="false">
                                <div class="whitespace-pre-wrap" v-html="`@fontFace{ \n\tfont-family: '${f.name}'; \n\tfont-style: ${f.style}!important; \n\tfont-weight: ${f.weight}; \n\tfont-display: swap; \n\tsrc: url(${f.url}); \n}`"></div>
                            </td>
                            <td>
                                <a v-if="f.kind === 'typekit' && f.name" :href="`https://fonts.adobe.com/fonts/${f.name}`" target="_blank">
                                    <BaseButton is="a" :style-override="`color:${linkColor};!important`" label="Get it" size="xs"></BaseButton>
                                </a>
                                <a v-else :href="`https://www.google.com/search?q=buy+%22${f.name}%22+font&oq=buy+%22+${f.name}+%22+font`" :style="`color:${linkColor};!important`" target="_blank">
                                    <BaseButton is="a" :style-override="`color:${linkColor};!important`" label="Get it" size="xs"></BaseButton>
                                </a>
                            </td>
                            <!--                                </tr>-->
                        </template>
                        <div>
                            {{ f }}
                        </div>
                    </CollapsableMenuItem>
                </template>
            </tbody>
        </table>
        <!--        <div class="x col-span-12">-->
        <!--            <template v-for="f in sortedFonts">-->
        <!--                <MobileFontSample :f="f" class="px-3" />-->
        <!--            </template>-->
        <!--        </div>-->
    </div>
</template>
<script>
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import FontSamplesPicker from "@/components/styleGuide/Sections/FontsSection.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import MobileFontSample from "@/components/styleGuide/Typography/MobileFontSample.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import StyleGuideColors from "@/components/styleGuide/Colors/StyleGuideColors.vue";

export default {
    name: "FontList",
    components: {
        CollapsableMenuItem,
        MobileFontSample,
        BaseButton,
    },
    mixins: [styleGuideMixin],
    data() {
        return {
            // showUsage: false,
            filter: true,
            showUsage: true,
        };
    },
    methods: {
        deleteFont(f) {
            let fonts = this.styleGuide.fonts;
            let index = fonts.findIndex(font => font.id === f.id);
            if (index > -1) {
                fonts.splice(index, 1);
                this.updateStyleGuideProp("fonts", fonts, true);
            }
            // find the index based on fontFamily || name, weight || fontWeight, style || fontStyle
        },
    },
    computed: {
        showAllButton() {
            return false;
        },
        sortFontsByName() {
            let fonts = this.fonts;
            if (!fonts || fonts.length < 1) return [];

            return fonts.sort((a, b) => {
                // Extracting names and weights with fallbacks
                let nameA = (a.name || a.fontFamily || "").toLowerCase();
                let nameB = (b.name || b.fontFamily || "").toLowerCase();

                let weightA = a.weight || a.fontWeight || "normal"; // Fallback to "normal"
                let weightB = b.weight || b.fontWeight || "normal"; // Fallback to "normal"

                // Primary comparison by name
                if (nameA !== nameB) {
                    return nameA < nameB ? -1 : 1;
                }

                // Convert weights to numbers if possible for accurate comparison
                weightA = isNaN(parseInt(weightA)) ? weightA : parseInt(weightA);
                weightB = isNaN(parseInt(weightB)) ? weightB : parseInt(weightB);

                // Secondary comparison by weight
                if (weightA !== weightB) {
                    // Both weights are numbers
                    if (!isNaN(weightA) && !isNaN(weightB)) {
                        return weightA - weightB;
                    }
                    // One or both weights are strings (e.g., "normal", "bold")
                    return weightA < weightB ? -1 : 1;
                }

                return 0; // Identical name and weight
            });
        },
        sortedFonts() {
            let fonts = this.fonts;
            if (!fonts || (fonts && fonts?.length < 1)) return [];
            return fonts.sort((a, b) => {
                let aUsageCount = parseInt(a.usageCount) || 0;
                let bUsageCount = parseInt(b.usageCount) || 0;
                if (aUsageCount !== bUsageCount) return bUsageCount - aUsageCount;
            });
        },
    },
    props: {},
};
</script>
<style lang="scss" scoped>
table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid gray;

    th,
    td {
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid;
        @apply border-gray-200;
    }

    th {
        @apply bg-gray-100;
        font-weight: bold;
    }

    tbody tr {
        @apply bg-snow;
        .url_text {
            @apply hidden;
        }
    }

    tbody tr:hover {
        .url_text {
            @apply transition-all;
            @apply text-gray-900;
            @apply visible;
        }

        //background-color: rgba($airbnb_red, 0.1);
        @apply bg-gray-50;
    }

    &.striped tbody tr:nth-of-type(odd) {
    }

    &.highlight-row tbody tr:hover {
        @apply bg-gray-50;
    }

    caption {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
    }
}
</style>
