<template>
    <div class="f jcb aic editor-section">
        <span class="fwb editor-section-title">Arrange</span>
        <div class="f">
            <div v-for="s in options" :key="s" @click="updateSection('style', s.id)">
                <!--                          <div class="p-2 border-light width-40 height-40 f aic jcc" :class="{ '': s.id === imageStyle, 'o-5': s.id !== imageStyle }" v-for="s in options" :key="s" @click="updateSection('imageStyle', s.id)">-->
                <!---->
                <div v-html="s.html" class="border-light height-40" :class="{ 'o-100': s.id === style, 'o-5': s.id !== style }"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SectionStyleEditor",
    props: {
        style: {},
        identifier: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "email",
        },
    },
    data() {
        return {
            options: [
                {
                    id: "imageLeft",
                    name: "Image Right",
                    icon: "fas fa-align-right",
                    html: `<div class="f aic jcc p-2 py-1 gap-2 f-15"><i class="fas fa-image o-5"></i><i class="fas fa-align-left f-11"></i></div>`,
                },
                {
                    id: "fullBleed",
                    name: "Full Bleed",
                    icon: "fas fa-align-justify",
                    html: `<div class="f aic jcc c-30 px-2 py-0 f-15 px-3 flex-shrink-0 relative gap-2"><div class="p-absolute p-fill f fc aic jcc z-1" style="gap:2px;"><div class="bg-gray-900" style="height:2px;width:15px;"></div><div class="bg-gray-900" style="height:1px;width:7px;"></div></div><i class="fas fa-image o-1"></i></div>`,
                },
                {
                    id: "imageRight",
                    name: "Image Left",
                    icon: "fas fa-align-left",
                    html: `<div class="f aic jcc p-1 c-30 gap-2 py-1 f-15"><i class="fas fa-align-right f-11"></i><i class="fas fa-image"></i></div>`,
                },
            ],
        };
    },
    mounted() {
        if (this.type === "email") this.options = this.options.filter(o => o.id !== "imageLeft");
    },
    methods: {
        updateSection(prop, value) {
            console.log("updateSection", prop, value);
            this.$emit("update-section", prop, value);
            // this.$store.dispatch("stream/updateStreamProp", { keyPath: prop, value });
        },
    },
};
</script>
