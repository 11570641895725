export function stringifySections(sections) {
    return sections
        .map((section, index) => {
            let header = section.header || "";
            let body = section.body || "";
            let button = section.button || "";
            let type = section.type || "";
            let sub_header = section.sub_header || "";
            let pre_header = section.pre_header || "";

            let features = section.features || "";
            if (section.products) type = "products";
            if (features) {
                type = "features";
                features = features
                    .map(feature => {
                        let icon = feature.icon;
                        if (feature.icon) icon = JSON.stringify(icon);
                        let title = feature.title || "";
                        let description = feature.description || "";
                        return ` - ${icon} ${title} - ${description}\n`;
                    })
                    .join("\n");
            }
            try {
                if (type !== null && type !== undefined && typeof type === "string") type = `SECTION ${index} – ${type.toUpperCase()} SECTION`;
            } catch (e) {
                console.log(e);
            }
            if (pre_header) pre_header = `${pre_header} // Pre-header text`;
            if (header) header = `${header} // Header text`;
            if (sub_header) sub_header = `${sub_header} // Subheader text`;
            if (body) body = `${body} // Body text`;
            if (button?.text) button = `${button.text} // Button text`;
            if (features) features = `Features:\n${features}`;
            let parts = [type, pre_header, header, sub_header, body, button, features].filter(Boolean);
            return parts.join("\n");
        })
        .join("\n\n--------\n\n");
}
export function emailText(sections) {
    return sections
        .map((section, index) => {
            let header = section.header || "";
            let body = section.body || "";
            let button = section.button || "";
            let type = section.type || "";
            let sub_header = section.sub_header || "";
            let pre_header = section.pre_header || "";

            let features = section.features || "";
            if (section.products) type = "products";
            if (features) {
                type = "features";
                features = features
                    .map(feature => {
                        let icon = feature.icon;
                        if (feature.icon) icon = JSON.stringify(icon);
                        let title = feature.title || "";
                        let description = feature.description || "";
                        if (icon.emoji) icon = ` ${icon.emoji} `;
                        else icon = " ";
                        return ` •${icon}${title}\n${description}\n`;
                    })
                    .join("\n");
            }
            if (pre_header) pre_header = `${pre_header}`;
            if (header) header = `${header}`;
            if (sub_header) sub_header = `${sub_header}`;
            if (body) body = `${body}`;
            if (button?.text) button = `${button.text}`;
            if (features) features = `Features:\n${features}`;
            let parts = [pre_header, header, sub_header, body, button, features].filter(Boolean);
            return parts.join("\n");
        })
        .join("\n\n--------\n\n");
}
