import { doc, Timestamp, getDoc, query, collection, where, deleteDoc, addDoc, getDocs, orderBy, updateDoc, setDoc } from "firebase/firestore";
import _ from "lodash";

import firestore, { db } from "@/firebase";
import * as firebase from "@/firebase";

function fixUndefinedProps(obj) {
    // console.groupCollapsed(`%cFirebase`, "warn", "Removing undefined props");
    // console.log("Original Object", obj);

    function fixProps(currentObj) {
        for (const prop in currentObj) {
            if (currentObj[prop] === undefined) {
                currentObj[prop] = null;
                // console.log(`%c"${prop}"`, "hilite", "was undefined");
            } else if (typeof currentObj[prop] === "object" && currentObj[prop] !== null) {
                fixProps(currentObj[prop]); // Recursive call for nested objects
            }
        }
    }

    fixProps(obj); // Initial call to start the process

    // console.log("Updated Object", obj);
    // console.groupEnd();
    return obj;
}
function debouncedFirebaseSave(...args) {
    const debouncedFirebaseSave = _.debounce(firebaseSave, 100); // 2 seconds delay
    debouncedFirebaseSave(...args);
}

/**
 * This function is used to save or update data to Firebase Firestore.
 *
 * @async
 * @function firebaseSave
 * @param {string} database - The name of the Firestore collection.
 * @param {Object} payload - The data object to be saved to Firestore.
 * @param {string} [id=null] - The document ID. If provided, the function will update the existing document. If not, a new document will be created.
 * @param {number} [tries=0] - The number of attempts to save the data. If it exceeds 2, the function will return false.
 * @returns {Object|undefined} - Returns the saved data object if successful, undefined otherwise.
 * @throws Will throw an error if the saving process fails.
 */
async function firebaseSave(database, payload, id = null, tries = 0) {
    if (tries > 2) return false;
    const updated = new Date();
    const dataObj = { ...fixUndefinedProps(payload) };
    // Only set the created timestamp for new documents
    if (!id) {
        const created = new Date();
        dataObj.created = created;
    }
    // Always update the 'updated' timestamp
    dataObj.updated = updated;

    const colRef = collection(db, database);
    let docRefNew = {};
    try {
        if (id && tries < 1) {
            dataObj.id = id;
            // If id exists, update the object instead of saving a new one
            const docRef = doc(db, database, id);
            await setDoc(docRef, dataObj).then(() => {
                // await updateDoc(docRef, dataObj).then(() => {
                console.groupCollapsed(`%c ${database}`, warn, "Document updated");
                console.log("Document updated with ID: ", id);
                console.log("dataObj", dataObj);
                console.log("docRef", docRef);
                console.groupEnd();
            });
        } else {
            // If no id exists, add a new document
            await addDoc(colRef, dataObj)
                .then(function (docRef) {
                    docRefNew = docRef;
                    dataObj.id = docRef.id;
                })
                .then(() => {
                    console.groupCollapsed(`%c ${database}`, warn, "Document added");
                    console.log("new doc added");
                    console.log(dataObj);
                    console.log("Document written with ID: ", docRefNew.id);
                    console.groupEnd();
                });
        }
        return dataObj;
    } catch (error) {
        console.groupCollapsed(`%cFirebase Error`, fail, `Error savings to ${database}`);
        console.log(error.message);
        console.log(payload);
        console.groupEnd();
        return;
        // payload = fixUndefinedProps(payload);
        // tries += 1;
        // return await firebaseSave(database, payload, id, tries + 1);
    }
}

// db.collection(this.collectionName)
//     .doc(item.id)
//     .set(item)
//     .then(() => {
//         console.log(`Added to ${this.collectionName}:`, item);
//     })
//     .catch(error => {
//         console.error(`Error adding ${collectionName}:`, error);
//     });

export default firebaseSave;
