<template>
    <div :class="slideContainerClasses" class="x vh-60">
        <!--    <div :ref="`slide-${id}${index}`" :class="slideContainerClasses" class="x vh-60">-->
        <div class="col-md-12 x h-auto text-left">
            <div :ref="`text-${id}${index}`" class="f jcs y p-md-6 z-5 aic p-2 pl-0">
                <!--                <div>-->

                <!--                </div>-->
                <!--                <presentation-image-container :document="document" :image-dark="imageDark" :index="index" :section="section"></presentation-image-container>-->
                <div class="o-1" style="mix-blend-mode: luminosity">
                    <img :src="section.imageURL" class="p-absolute p-fill x y o-cover" style="mix-blend-mode: multiply" />
                </div>
                <div class="z-5 block text-left">
                    <PresentationSlideText :index="index" :document="document" :document-id="document.id" :image-dark="imageDark" :section="section"></PresentationSlideText>
                    <div v-if="section.list" class="f flex-wrap">
                        <div v-for="(item, index) in section.list" :key="index" class="timeline-item w-25 p-3">
                            <h3 v-if="item.title" style="line-height: 1.3em">{{ item.title }}</h3>
                            <h4 v-if="item.subtitle">{{ item.subtitle }}</h4>
                            <p v-if="item.description" class="o-7 f-13 mb-0">{{ item.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin],
    props: {
        id: {},
        index: {},
        section: {},
        // document: {},
        documentId: {},
        slideContainerClasses: {},
    },
    data: () => ({
        imageDark: false,
    }),
    computed: {
        imageInset() {
            return "p-3 p-xl-5";
        },
        imageFullbleed() {
            return "p-0";
        },
    },
    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
    },
    methods: {
        randomImageStyle() {
            return Math.random() < 0.5 ? this.imageInset : this.imageFullbleed;
        },
    },
};
</script>
<style scoped>
.timeline {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.timeline-item {
    margin-bottom: 20px;
}

.timeline-item h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.timeline-item h4 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
}
</style>
