<template>
    <div ref="presentation_container" class="f dark:bg-base-800" style="scroll-behavior: smooth;">
        <DocumentsDrawer :always-open="false" @create-document="createDocument"></DocumentsDrawer>

        <PresentationSlideDrawer v-if="!mobile && !$store.state.presentation.queue.showProgress" key="slideDrawer" @scroll-to-slide="scrollToSlide" @start-new-deck="startNewDeck"></PresentationSlideDrawer>
        <div v-if="!newDeckStarted && !mobile && !$store.state.presentation.queue.showProgress" style="margin-left: 275px"></div>
        <div class="fc align-content-stretch z-0 flex flex-grow">
            <!--        <div :style="`background:${backgroundColor ? backgroundColor : ''}`" class="fc align-content-stretch z-0 flex flex-grow">-->
            <MainPresentationView @start-new-deck="startNewDeck" @new-deck="newDeck" @new-deck-modal="newDeckModal"></MainPresentationView>
        </div>
        <!--        <ken-burns-style :slides="[{ image: 'nature', duration: 5, text: 'Nature 1' }, { image: 'city', duration: 6, text: 'City life' }, { image: 'beach', duration: 4, text: 'Beach' }]"></ken-burns-style>-->
        <Transition enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
            <CreatePresentationFlow v-if="newDeckStarted" :presentation-prompt="presentationPrompt" @close="newDeckStarted = false" @new-deck="newDeck" />
        </Transition>
    </div>
</template>

<script>
import "firebase/storage";

import { collection, doc, Timestamp, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import cursorMixin from "../mixins/cursorMixin.js";

import DocumentsDrawer from "@/components/documents/DocumentsDrawer.vue";
import Notification from "@/components/Imported/tailwind/Notification.vue";
import CreatePresentationFlow from "@/components/Presentations/CreatePresentationFlow.vue";
import PresentationSlideDrawer from "@/components/Presentations/PresentationSlideDrawer.vue";
import MainPresentationView from "@/components/Presentations/slides/MainPresentationView.vue";
import { db, presentations, textDocs } from "@/firebase";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import imageMixin from "@/mixins/images/imageMixin";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import slideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import PresentationProgress from "@/components/Presentations/slides/PresentationProgress.vue";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import updateDocumentProp from "@/mixins/ai/update_document_prop";
import { mapGetters } from "vuex";

export default {
    components: {
        PresentationProgress,
        Notification,
        // SlideGeneratorTest,
        // KenBurnsStyle,
        // FloatingMenu,
        PresentationSlideDrawer,
        CreatePresentationFlow,
        DocumentsDrawer,
        MainPresentationView,
    },
    mixins: [cursorMixin, imageMixin, presentationMixin, slideStyleMixin, documentManagementMixin],
    data() {
        return {
            documents: [],
            sectionCount: 0,
            newDeckStarted: false,
            presentationPrompt: null,
            slideCount: null,
            serpResults: [],
            serpQuery: "",
            showDocs: false,
            sections: [],
            streamingDoc: "",
            currentSlideData: "",
            unbindFirestore: null,
            openingBracketCount: 0,
            closingBracketCount: 0,
        };
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        sectionLength() {
            return this.document.sections.length;
        },
        documentId() {
            if (this.$route.params.id) {
                return this.$route.params.id;
            } else {
                // this.setFirstDocument();
            }
        },
    },
    watch: {
        $route: {
            async handler(to, from) {
                if (this.$route.params && this.$route.params.id) {
                    this.storeDocId();
                } else {
                    this.getRecentDocument();
                }
                this.$nextTick(async () => {
                    this.$forceUpdate();
                });
            },
            immediate: true,
        },
    },
    created() {
        if (this.$route.params.id) {
            this.$store.dispatch("presentation/getPresentation", this.$route.params.id);
            updateQueueProp(this.$store, "fetching", true);
        }
    },
    async mounted() {
        if (!this.$route.params.id) {
            await this.setFirstDocument();
        } else {
            // await this.getPresentation(this.$route.params.id);
        }

        window.addEventListener("keydown", event => {
            if (event.key === "s" && event.metaKey) {
                event.preventDefault();
                // Trigger the save function for your application
            }
            if (event.key === "n" && event.metaKey) {
                event.preventDefault();
                // Trigger the save function for your application
            }
        });
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    provide() {
        return {
            scrollPageToBottom: this.scrollPageToBottom,
        };
    },
    methods: {
        scrollPageToBottom() {
            let container = this.$refs.presentation_container;
            container.scrollTop = container.scrollHeight;
        },
        scrollToSlide(index) {
            this.$refs.mainPresentationView.scrollToSlide(index);
        },
        async setFirstDocument() {
            console.log("Set first document");
            let newSort = await this.documents.sort((a, b) => {
                return b.updated - a.updated;
            });
            let filteredDocs = newSort.filter(documents => documents.documentType === "presentation");
            const firstDoc = filteredDocs[0];
            this.$router.push({ path: "/presentations/" + firstDoc.id });
        },
        async updateDeckOnURL() {
            if (this.$route.params && this.$route.params.id) {
                // this.$firestoreBind('document', doc(documents, this.$route.params.id));
            } else {
                this.$nextTick(() => {
                    this.setFirstDocument();
                });
            }
            return false;
        },
        async newDeck(prompt, kind, slideCount) {
            this.newDeckStarted = false;
            updateDocumentProp(this.$store, "sections", []);
            // await this.requestPresentationFromOpenAI(prompt);
        },
        async createDocument(document) {
            const documents = await this.getAnyDBItem("documents", null, "updated");
            this.setDocuments(documents);
        },
    },
};
</script>

<!--// let prompt = `Write a ${slideCount} slide presentation about ${subject} in this format: Titles should be 2-5 words, the body (optional) should be informative but under 50 words. The image should be-->
<!--descriptive of the containing image. Style is about the type of slide ( Image Left, List, Image Right, Full Bleed Image, Timeline, Matrix, etc). Include a cover, an intro, info slides, and a-->
<!--conclusion. In the style of Steve Jobs // // Slide 1 // Title: // Body: // Image: This is an image of ... // Style: // // ###\n\n // `; // let prompt = `Write a ${slideCount} section outline for a-->
<!--${subject} website: Titles should be 2-5 words, the body (optional) should be informative but under 50 words. The image should be descriptive of the containing image. Style is about the type of slide-->
<!--( imageLeft, imageRight, fullBleedImage, timeline, matrix, etc). Include a cover, an intro, info slides, and a conclusion. In the style of Steve Jobs // // Slide 1 // Title: // Body: // Image: This is-->
<!--an image of ... // Style: // // ###\n\n // `; // let prompt = `Write a ${slideCount} slide presentation about ${subject} in this format: Titles should be 2-5 words, the body (optional) should be-->
<!--informative but under 50 words. The image should be descriptive of the containing image. Style is about the type of slide ( imageLeft, imageRight, fullBleedImage, timeline, matrix, etc). Include a-->
<!--cover, an intro, info slides, and a conclusion. In the style of Steve Jobs // // Slide 1 // Title: // Body: // Image: This is [an Image, a Drawing, or a Painting] of ... // Style: // // ###\n\n // `;-->
<!--// const request = { // model: "text-davinci-003", // prompt: prompt, // temperature: 0.9, // max_tokens: 3000, // top_p: 1, // frequency_penalty: 0, // presence_penalty: 0, // };-->
