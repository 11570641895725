<template>
    <div class="mt-3 flow-root px-0">
        <div class="divide-y divide-gray-200">
            <div :key="product?.handle" class="flex py-2 border-light br-10">
                <div class="min-w-0 flex-1 lg:flex lg:flex-col">
                    <div class="lg:flex-1">
                        <div class="sm:flex">
                            <div>
                                <h5 class="font-medium text-gray-900">{{ product.title }}</h5>
                                <p class="mt-2 text-sm text-gray-500 line-clamp-3">{{ product.description }}</p>
                            </div>
                        </div>
                        <div class="flex text-sm font-medium my-2 f aic jcs gap-3">
                            <p class="font-medium text-gray-900" v-if="product.price">${{ product.price }}</p>
                            <div class="border-left y block relative w-[2px]"></div>
                            <a :href="product?.url" class="text-indigo-600 hover:text-indigo-500 mb-0">View Product</a>
                        </div>
                    </div>
                </div>
                <div class="ml-2 flex-shrink-0 sm:order-first sm:m-0 sm:mr-3" v-if="product.images?.[0].url">
                    <img :src="product.images[0].url" :alt="product.images[0]?.alt" class="col-start-2 col-end-3 h-20 w-20 rounded-lg object-cover object-center sm:col-start-1 sm:row-span-2 sm:row-start-1 sm:h-40 sm:w-40 lg:h-20 lg:w-20" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProductCardSmall",
    props: {
        product: {},
    },
};
</script>
