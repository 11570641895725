<template>
    <div class="topicButton">
        {{ text }}
        <!--        slot-->
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "topic-button",
    props: {
        text: {},
    },
};
</script>
