<template>
    <a :href="link" style="color: inherit" class="places-card" target="_blank">
        <div v-if="place" class="" :class="{ 'height-min-200': place.thumbnail || place.image }">
            <!--        :style="`background:${place.icon_background_color}40!important`"-->
            <div class="x relative flex-1" v-if="place.thumbnail || place.image" :class="{ 'aspect-h-9 aspect-w-16 ': !aspect || aspect !== 'square', 'aspect-h-9 aspect-w-9': aspect === 'square' }">
                <img class="x o-cover y" :src="place.thumbnail || place.image" v-if="place.thumbnail || place.image" />
                <slot v-else></slot>
            </div>
            <div class="x places_bottom h-100 flex-1 px-3 py-2 pb-3" ref="bottom">
                <p class="text font-semibold line-clamp-1" v-if="place.name || place.title">{{ place.name || place.title }}</p>
                <p class="mb-1 block whitespace-pre-wrap text-xs line-clamp-1" :style="`color:${place.icon_background_color};`">{{ place.formatted_address || place.address }}</p>
                <p class="mb-1 block whitespace-pre-wrap text-xs line-clamp-1" v-if="place.description">{{ place.description }}</p>
                <p class="mb-1 block whitespace-pre-wrap text-xs line-clamp-1" v-if="place.hours">
                    {{ place.hours }}
                    <span class="text-xs" v-if="place.price">• {{ place.price }}</span>
                </p>
                <p v-else-if="place.price" class="mb-1 block whitespace-pre-wrap text-xs line-clamp-1" v-if="place.price">
                    {{ place.price }}
                </p>
                <!--            <div class="text-xs o-5">Lat: {{ place.geometry.location.lat() }}, Lng: {{ place.geometry.location.lng() }}</div>-->
                <div class="text-xs">
                    <RatingStarsComponent v-if="place && place.rating" :value="place.rating" :count="place.user_ratings_total || place.reviews" class="small" color="mix-blend-mode-color-burn" />
                    <div v-if="place.price_level">
                        <i class="fas fa-dollar-sign" v-for="(item, index) in Array.from({ length: place.price_level })" :key="index"></i>
                    </div>
                    <template v-if="place" v-for="feature in features" :key="feature.text">
                        <div v-if="place[feature.prop]" class="feature">
                            <i :class="feature.icon"></i>
                            {{ feature.text }}
                        </div>
                    </template>
                </div>
                <div v-if="place.opening_hours" class="mt-4">
                    <div class="f list-inside text-xs">
                        <template v-if="place?.opening_hours?.open_now">Open now</template>
                        <template v-else>Currently Closed</template>
                        <div v-for="(day, index) in place.opening_hours.weekday_text" :key="index">
                            {{ day }}
                        </div>
                    </div>
                </div>
                <template v-if="false">
                    <div v-if="place.opening_hours" class="mt-4">
                        <!-- <h3 class="mb-2 text-lg font-semibold">Opening hours:</h3> -->
                        <div class="text-xs">
                            <div class="d-block">
                                <template v-if="place.opening_hours.open_now">Open now</template>
                                <template v-else>Currently Closed</template>
                            </div>
                            <div class="d-block">
                                {{ place.opening_hours.weekday_text[currentDayIndex] }}
                            </div>
                        </div>
                    </div>
                </template>
                <div v-if="place.website" class="f x mt-4 justify-between gap-3">
                    <a :href="place.website" target="_blank" class="text-sm text-gray-300">
                        <i class="fas fa-link"></i>
                        Official website
                    </a>
                    <div class="text-sm text-gray-300" v-if="place.formatted_phone_number">
                        <i class="far fa-phone"></i>
                        : {{ place.formatted_phone_number }}
                    </div>
                </div>
                <div class="text-uppercase fwb mb-0 mt-3 pb-0 text-sm line-clamp-1 mt-auto" v-if="place.source">{{ place.source }}</div>
            </div>
        </div>
    </a>
</template>
<script>
import RatingStarsComponent from "@/components/CoreUI/RatingStarsComponent.vue";
export default {
    name: "PlacesCard",
    components: {
        RatingStarsComponent,
    },
    props: {
        aspect: {
            type: String,
        },
        place: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            features: [
                { prop: "hasCurbsidePickup", text: "Curbside Pickup", icon: "fas fa-car-side" },
                { prop: "hasDelivery", text: "Delivery", icon: "fas fa-truck" },
                { prop: "hasDineIn", text: "Dine-in", icon: "fas fa-utensils" },
                // { prop: "rating", text: "Rating", icon: "fas fa-star" }, // Rating needs additional handling
                { prop: "price_level", text: "Price Level", icon: "fas fa-dollar-sign" }, // Price Level needs additional handling
                { prop: "reservable", text: "Accepts reservations", icon: "fas fa-calendar-alt" },
                { prop: "icon", text: "Icon", icon: "fas fa-calendar-alt" }, // Icon needs additional handling
                { prop: "serves_beer", text: "Serves Beer", icon: "fas fa-beer" },
                { prop: "serves_breakfast", text: "Serves Breakfast", icon: "fas fa-coffee" },
                { prop: "serves_brunch", text: "Serves Brunch", icon: "fas fa-mug-hot" },
                { prop: "serves_dinner", text: "Serves Dinner", icon: "fas fa-moon" },
                { prop: "serves_lunch", text: "Serves Lunch", icon: "fas fa-sandwich" },
                { prop: "serves_vegetarian_food", text: "Serves Vegetarian Food", icon: "fas fa-seedling" },
                { prop: "serves_wine", text: "Serves Wine", icon: "fas fa-wine-bottle" },
                { prop: "takeout", text: "Takeout", icon: "fas fa-shopping-bag" },
            ],
        };
    },
    computed: {
        link() {
            const place = this.place;
            if (place.links) {
                if (place.links.directions) {
                    return place.links.directions;
                } else if (place.links.website) {
                    return place.links.website;
                }
            } else if (place.url) {
                return place.url;
            } else if (place.link) {
                return place.link;
            } else {
                return null;
            }
        },
        currentDayIndex() {
            const today = new Date().getDay();
            // JavaScript's getDay() starts with Sunday as 0, but Google Places API starts with Monday as 0
            return today === 0 ? 6 : today - 1;
        },
        starRating() {
            const rating = Math.round(this.place.rating * 2) / 2;
            const fullStars = Math.floor(rating);
            const halfStars = rating - fullStars;

            return [...Array(fullStars).fill(1), ...(halfStars ? [0.5] : []), ...Array(5 - fullStars - (halfStars ? 1 : 0)).fill(0)];
        },
    },
    methods: {},
};
</script>
<style lang="scss">
.places_bottom{
    @apply height-min-150;
}
</style>
