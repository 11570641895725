<template>
    <div v-if="user" ref="dropzone" class="image_drag_bg" @mouseleave="dragCounter = 0" @dragover.prevent @dragend.prevent="dragCounter = 0">
        <div class="p-3">
            <div style="box-sizing: border-box">
                <div class="">
                    <label class="f aic jcc justify-center p-absolute p-fill p-3 dropzone" for="dropzone-file" @drag.prevent="onDragEnter" @dragover.prevent="onDragEnter" @dragleave.prevent="onDragLeave" @drop.prevent="onDrop">
                        <div class="flex flex-col y aic jcc py-6 h-100 x uploader_style bg-blur">
                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-base-200 animated swingInUp duration-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                            </svg>
                            <p class="mb-2 text-sm text-base-200 dark:text-gray-400 animated swingInUp duration-3 d-2">
                                <span class="font-semibold">Click to upload</span>
                                or drag and drop
                            </p>
                            <p class="text-xs text-base-200 dark:text-gray-400 animated swingInUp duration-3 d-4">
                                <span class="o-5">SVG, PNG, JPG or GIF (MAX. 800x400px)</span>
                            </p>
                        </div>
                        <input id="dropzone-file" accept="image/*" class="hidden visually-hidden" type="file" @change="onFileChange" />
                    </label>
                </div>
            </div>
            <!--            <h1>{{ dragCounter }}</h1>-->
        </div>
    </div>
</template>
<script>
import user from "@/mixins/usermixin";
import imageMixin from "@/mixins/images/imageMixin";

export default {
    mixins: [user, imageMixin],
    data() {
        return {
            email: "",
            dragCounter: 0,
        };
    },
    computed: {
        isImageDragged() {
            return this.dragCounter >= 1;
        },
    },
    mounted() {
        this.$refs.dropzone.addEventListener("drop", this.onDrop, false);
    },
    beforeUnmount() {
        this.$refs.dropzone.removeEventListener("drop", this.onDrop, false);
    },
    methods: {
        onDragEnter(event) {
            this.dragCounter++;
        },
        onDragLeave(event) {
            this.dragCounter--;
        },
        async onFileChange(event) {
            const file = event.target.files[0];
            if (!file) return;
        },
        async onDrop(event) {
            const file = event.dataTransfer.files[0];
            if (!file) return;
            await this.uploadDroppedImage(file);
            this.$nextTick(() => {
                this.dragCounter = 0;
            });
        },
        async uploadDroppedImage(file) {
            try {
                const photoURL = await this.uploadFileAsImage(file);
                this.$emit("upload-success", photoURL);
            } catch (error) {
                console.error("Error uploading user photo:", error);
                this.$emit("upload-error", error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.uploader_style {
    @apply border-2
  flex
  items-center
  justify-center
  border-gray-300
  border-dashed
  rounded-lg
  cursor-pointer;

    @apply bg-gray-50/10 hover:bg-gray-100/10;
    @apply dark:bg-base-700 dark:hover:bg-base-800/50;

    @apply dark:border-gray-300 dark:hover:border-gray-500;
    @apply text-base-800 hover:text-base-700 dark:hover:text-base-100;
}
.image_drag_bg {
    //@apply backdrop-blur-2xl;
    //@apply backdrop-blur;
    // tailwind blur
    @apply bg-base-900/90;
    @apply rounded-xl;
    //@extend .bg-blur!optional;
}
.dropzone {
    @apply h-full w-full;
    //  pointer-events: none;
}
</style>
