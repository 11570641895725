<template>
    <div>
        <div class="works_title button-1" v-if="text" :style="`font-size:${size}px;${style};border:0px;line-height:1.8em!important;padding:0.5em`">{{ text }}</div>
<!--        <div class="works_title button-2" v-if="text" :style="`background:${color};color:${getContrastColor(color)};font-size:${size}px;${style};line-height:1.8em!important;padding:0.5em`">{{ text }}</div>-->
        <svg style="visibility: hidden; position: absolute" width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
                <filter id="instagram">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -8" result="goo" />
                    <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                </filter>
            </defs>
        </svg>
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    mixins: [styleGuideMixin],
    name: "ImageCaption",
    props: {
        text: {
            type: String,
            default: "Something longlike a title with bunchofletters a more text",
        },
        color: {
            type: String,
            default: "blue",
        },
        style: {
            type: String,
            default: "arial",
        },
        size: {
            type: String,
            default: "30",
        },
    },
};
</script>
<style>
.works_title {
    display: inline;
    padding: 10px 10px;
    //line-height: 1.4; /* adjust this to avoid overlapping the padding */
    border-radius: 4px;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    filter: url("#instagram");
}
.no-filter {
    filter: none;
}
</style>
