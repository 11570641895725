<template>
    <div class="jcc aic text-block">
        <div class="col-12">
            <WordFadeup type="h1" v-if="animatedText && title" class="text-left fluoride-text section-header" :start="start" :end="end" :title="title"></WordFadeup>
            <h1 class="section-header fadeInOnly" v-else-if="title">{{ title }}</h1>

            <template v-if="smallText">
                <WordFadeup type="p" v-if="animatedText && paragraph" class="text-left fluoride-text smallText" :start="start" :end="end" :title="paragraph"></WordFadeup>
                <p class="stagger-body fadeInOnly smallText" v-else-if="paragraph">{{ paragraph }}</p>
            </template>
            <template v-else>
                <WordFadeup type="p" v-if="animatedText && paragraph" class="text-center fluoride-text" :start="start" :end="end" :title="paragraph"></WordFadeup>
                <p class="stagger-body text-center fadeInOnly" v-else-if="paragraph">{{ paragraph }}</p>
            </template>
        </div>
    </div>
</template>
<script>
import WordFadeup from "@/components/elements/WordFadeup.vue";

export default {
    components: { WordFadeup },
    data: () => ({
        scene: null,
    }),
    props: {
        title: {
            type: String,
            default: "", // Default to an empty string if no title is provided
        },
        paragraph: {
            type: String,
            default: "", // Default to an empty string if no paragraph is provided
        },
        animatedText: {
            type: Boolean,
            default: false, // Default to false if no explicit value is provided
        },
        smallText: {
            type: Boolean,
            default: false, // Default to false if no explicit value is provided
        },
        end: { type: String, default: "" },
        start: { type: String, default: "" },
    },
    mounted() {},
    beforeUnmount() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
//.section-header {
//    font-size: 2vw !important;
//}
//
//.text-block {
//    p {
//        font-size: calc(0.5rem + 3vw) !important;
//        text-align: center;
//
//        &.smallText {
//            font-size: calc(0.5rem + 1vw) !important;
//        }
//    }
//}
</style>
