<template>
    <div>
        <div class="f aic jcb">
            <label v-if="label" :for="id" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
            <div v-if="$slots.default" class="">
                <slot></slot>
            </div>
        </div>
        <div class="mt-2">
            <textarea
                :id="id"
                :autocomplete="autocomplete"
                :placeholder="placeholder"
                :required="required"
                :rows="rows"
                :value="modelValue"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:border-black focus:outline-none focus:ring-black-600 sm:text-sm sm:leading-6"
                @input="$emit('update:modelValue', $event.target.value)"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: "SimpleTextarea",
    props: {
        id: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "off", // For textarea, it's common to turn off autocomplete
        },
        label: {
            type: String,
            default: "",
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        rows: {
            // Added to control the height of the textarea
            type: Number,
            default: 3,
        },
    },
    emits: ["update:modelValue"],
};
</script>
