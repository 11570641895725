<template>
    <div v-if="staggeredQuestions" class="animated fadeInUpSmooth d-2 duration-5 br-10 related_questions mb-md-5 mb-3 bg-snow shadow dark:bg-base-700" :class="{'mw-350':mobile}">
        <div class="pt-md-3 pt-2">
            <p class="animated fadeInUpSmooth duration-5 d-2 fwb mb-0 px-3">
                <i class="fas fa-bolt small mr-2 text-green"></i>
                Related Questions
            </p>
        </div>
        <ul class="animated fadeInUpSmooth duration-5 d-2 pt-md-3 whitespace-pre-wrap py-1">
            <transition-group class="overflow-hidden" name="list" tag="div">
                <related-questions v-for="(question, i) in staggeredQuestions" :key="i" ref="questions" :animate="animate" :class="`border-bottom animated d-${i * 5} fadeInUpSmooth duration-5`" :question="question" class="px-3"></related-questions>
            </transition-group>
        </ul>
    </div>
</template>

<script>
import RelatedQuestions from "@/components/chat/specialMessages/Search/RelatedQuestions.vue";

export default {
    inject: ["scrollChatWindowToBottom"],
    name: "RelatedQuestionsContainer",
    components: { RelatedQuestions },
    props: {
        results: {},
        questions: {},
        typing: {},
        animate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            staggeredQuestions: [],
        };
    },
    watch: {
        questions: {
            immediate: true,
            handler(newQuestions) {
                this.staggerQuestions(newQuestions);
            },
        },
    },
    methods: {
        async staggerQuestions(questions) {
            this.staggeredQuestions = [];
            if (questions && questions.length && questions.length > 0) {
                if (this.animate) {
                    for (let i = 0; i < questions.length; i++) {
                        await this.$nextTick();
                        setTimeout(() => {
                            this.staggeredQuestions.push(questions[i]);
                            // this.scrollChatWindowToBottom(true);
                            this.$nextTick(() => {
                                let newItem = this.$refs.questions[i];
                                this.$anime({
                                    targets: newItem.$el,
                                    opacity: [0, 1],
                                    // height: [0, newItem.$el.clientHeight],
                                    translateY: [-150, 0],
                                    overflow: "hidden",
                                    easing: "cubicBezier(0.220, 1.605, 0.365, 0.910)",
                                    duration: 1000,
                                });
                            });
                        }, i * 1500); // Adjust delay time (in ms) to your preference
                    }
                } else {
                    this.staggeredQuestions = questions.slice(); // Copy the questions array without animation
                }
            }
        },
    },
};
</script>
<style lang="scss">
.web-results{
  .related_questions{
    ul{
      //@apply list-inside;
      @apply pt-0;
      li{

        @apply ml-4;
      }
    }
  }
}

.list-enter-active{
  transition: all 1s ease-out;
}

.list-enter{
  transform: translateY(30px);
  opacity: 0;
}

.list-enter-to{
  transform: translateY(0);
  opacity: 1;
}

.list-leave-active{
  transition: all 1s ease-in;
}

.list-leave{
  transform: translateY(0);
  opacity: 1;
}

.list-leave-to{
  transform: translateY(30px);
  opacity: 0;
}
</style>
