<template>
    <transition name="fade-up">
        <div v-if="open" class="fixed inset-0 flex items-center justify-center z-5 mw-500 mx-auto x">
            <div class="bg-white p-3 rounded-lg shadow-lg x">
                <form class="x" @submit.prevent="addMember">
                    <!--                BASE INPUT    -->

                    <!--                REQUIRED     -->
                    <BaseInput label="Name" class="mb-0" :modelValue="newMember.name" @update:modelValue="newMember.name = $event" placeholder="Name" />
                    <BaseInput label="Email" type="email" class="mb-0" :modelValue="newMember.email" @update:modelValue="newMember.email = $event" placeholder="Email" />

                    <select v-model="newMember.role" class="p-2 border rounded w-full mb-4">
                        <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                    </select>
                    <div class="f fc gap-2">
                        <Button style-type="secondary" class="x button-sky" size="md" type="submit" label="Add member" />
                        <Button style-type="secondary" class="x" size="md" label="Cancel" @click="$emit('close-dialog')" />
                    </div>
                </form>
            </div>
        </div>
    </transition>
</template>

<script>
import Button from "@/components/CoreUI/BaseButton.vue";
import BaseInput from "@/components/CoreUI/BaseInput.vue";

export default {
    components: { BaseInput, Button },
    props: {
        open: Boolean,
        roles: Array,
    },
    data() {
        return {
            newMember: {
                id: null,
                name: "",
                email: "",
                role: "viewer",
            },
        };
    },
    methods: {
  addMember() {
    const newMember = { ...this.newMember, id: Date.now() };
    this.$emit("add-member", newMember);  // Renamed to 'add-member'
  },
    },
};
</script>
