<template>
    <div>
        <!--        <UIWrapperText :title="name" :subtitle="description" section="collections">-->
        <div class="f overflow-x-scroll gap-5 scrollbar-hide">
            <div class="flex-shrink-0" v-for="product in collectionProducts" :key="product.handle">
                <ProductCardAlt :p="product" />
            </div>
        </div>
        <!--            {{ collection }}-->
        <!--        </UIWrapperText>-->
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProductCardAlt from "@/views/ProductCardAlt.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";
export default {
    name: "Collection",
    components: { UIWrapperText, ProductCardAlt },
    props: {
        collection: {},
    },
    computed: {
        ...mapGetters("styleGuide", ["products"]),
        collectionProducts() {
            let productsInCollection = this.collection.products || []; // this is an array of product handles
            return this.products.filter(product => productsInCollection.includes(product.handle));
        },
        description() {
            return this.collection.description || "";
        },
        name() {
            return this.collection.title || "";
        },
    },
};
</script>
