<template>
    <div class="flex items-center justify-center bg-gray-50 border-gray-300 border-dashed border-2 flex flex-col items-center justify-center w-full rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600" v-if="user">
        <label for="dropzone-file" class="x y f aic jcc" @dragover.prevent @drop.prevent="onDrop">
            <div class="flex flex-col items-center justify-center pt-5 pb-6 px-3">
                <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400 animated swingInUp duration-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 animated swingInUp duration-3 d-2">
                    <span class="font-semibold">Click to upload</span>
                    or drag and drop
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400 animated swingInUp duration-3 d-4">SVG, PNG, JPG or GIF</p>
            </div>
            <input id="dropzone-file" type="file" accept="image/*" @change="onFileChange" class="hidden visually-hidden" />
        </label>
    </div>
</template>

<script>
import user from "@/mixins/usermixin";
import imageMixin from "@/mixins/images/imageMixin";
import { endpointURL } from "@/mixins/Chat/Commands/api_url";

export default {
    name: "UploadImage",
    mixins: [user, imageMixin],
    emits: ["image-uploaded"],
    data() {
        return {
            email: "",
        };
    },
    methods: {
        async onFileChange(event) {
            const file = event.target.files[0];
            if (!file) return;
            let image = await this.upload(file);
            console.log(image);
            return;
        },
        async onDrop(event) {
            const file = event.dataTransfer.files[0];
            if (!file) return;
            await this.upload(file);
        },
        async upload(blob, imageName = null, id) {
            try {
                // Setup Blob to FormData
                let formData = new FormData();

                formData.append("image", blob, imageName); //adding blob directly
                if (id) {
                    formData.append("id", id);
                } else if (this.$route.params.id) {
                    id = this.$route.params.id;
                    formData.append("id", id);
                }
                const response = await fetch(endpointURL("uploadImage"), {
                    method: "POST",
                    body: formData, //changed content to formData
                });
                if (!response.ok) {
                    const message = `An error has occurred: ${response.status}`;
                    throw new Error(message);
                }
                const result = await response.json();

                console.log(result);
                this.$emit("image-uploaded", result);
                return result;
            } catch (error) {
                console.error("Error:", error);
            }
        },
    },
};
</script>
