<template>
    <div>
        <MessageMultipleChoice v-if="options" :options="options" />
        <ChatImageGallery v-if="images" :images="images" />
        <ChatSourcesLinks v-if="sources" :sources="sources" />
        <MessageFileUploads v-if="files" :files="files" />
        <template v-if="tool">
            <div class="fwsb text-capitalize" v-if="toolName">{{ toolName }}</div>
            <template v-if="args">
                <!--                {{ args }}-->
                <ObjectViewer :data="args" />
            </template>
        </template>
    </div>
</template>
<script>
import ChatImageGallery from "@/views/ChatImageGallery.vue";
import ChatSourcesLinks from "@/views/ChatSourcesLinks.vue";
import MessageMultipleChoice from "@/views/MessageMultipleChoice.vue";
import MessageFileUploads from "@/views/MessageFileUploads.vue";
import ObjectViewer from "@/components/Admin/ObjectViewer.vue";

export default {
    name: "SpecialMessages",
    components: { ObjectViewer, MessageFileUploads, ChatImageGallery, ChatSourcesLinks, MessageMultipleChoice },
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    computed: {
        args() {
            return this.tool?.arguments || [];
        },
        toolName() {
            const toolName = this.tool?.name || "";
            let fixed = toolName.replace(/([A-Z])/g, (match, offset) => (offset === 0 ? match : " " + match));
            fixed = fixed.toLowerCase().replace(/(^|\s)\S/g, t => t.toUpperCase());
            // remove underscores
            fixed = fixed.replace(/_/g, " ");
            return fixed;
        },

        tool() {
            return this.message.function_call || {};
        },
        files() {
            return this.message.files || [];
        },
        options() {
            return this.message.options || [];
        },
        sources() {
            return this.message.sources || [];
        },
        images() {
            return this.message.images || [];
        },
    },
};
</script>
