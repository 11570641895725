<template>
    <div
        class="p-relative secWrap px-3"
        :class="{ 'mt-3': headerPadding }"
        @mouseover="hovered = true"
        @mouseleave="(hovered = false), (showHighlightMenu = false)">
        <!--        <p-->
        <!--            v-if="section.content === ''"-->
        <!--            class="f-20 p-2 text-gray-50 hover:text-gray-300"-->
        <!--            :class="{ 'bg-teal-50 text-teal-300': sectionIndex === index }"-->
        <!--            @click.prevent="populateEmptySection()">-->
        <!--            Add some text here-->
        <!--        </p>-->
        <!--        <template v-else>-->
        <template v-if="isImage">
            <div class="d-block">
                <!--                  @keydown.enter.prevent="keyUp"-->
                <ImageSection
                    v-model="section.content"
                    :index="index"
                    :section="section"
                    @focus="setSectionIndex(index)"
                    @keydown="keyPress"
                    @remove-section="removeSection"></ImageSection>

                <div class="f ais jcs contentSection">
                    <contenteditable
                        ref="contenteditable"
                        data-placeholder="Enter your text here"
                        v-model="section.prompt.prompt"
                        class="p-absolute p-fill visually-hidden f-11 z-0"
                        tag="span"
                        :contenteditable="isEditable"
                        @keydown="keyPress"
                        @focus="setSectionIndex(index)"
                        @keydown.enter.prevent=""
                        @mouseup="onMouseup()"></contenteditable>
                </div>
            </div>
        </template>
        <div v-else class="f ais jcs contentSection">
            <!--              <span class="p-absolute left-0 o-4 f-11 top-[5px]">{{index}}</span>-->
            <contenteditable
                ref="contenteditable"
                v-model="section.content"
                class="x br-10 whitespace-pre-line whitespace-pre-wrap bg-snow px-2 text-left hover:bg-gray-50 dark:text-base-50 dark:hover:bg-base-700"
                :tag="section.tag"
                :contenteditable="isEditable"
                placeholder="Enter some text here"
                :no-nl="noNL"
                :no-html="noHTML"
                @change="fixTag(section)"
                @focus="setSectionIndex(index)"
                @keydown="keyPress"
                @keydown.enter.prevent=""
                @keydown.space="fixTag($event,section)"
                @mouseup="onMouseup()"></contenteditable>
            <!--              @keydown.enter.prevent="keyUp"-->
        </div>
        <!--        </template>-->
        <SectionLoader
            v-if="sectionFetching"
            :section-fetching="sectionFetching"
            :loading-text="loadingText"></SectionLoader>
        <!--        <div v-if="selectedIndexEmpty" class="f">Empty selection</div>-->
        <span v-if="hovered" class="small o-3 p-absolute bottom right mb-1 ml-2 bg-base-100 p-1">
            {{ sectionLength }}
        </span>
        <SearchActions
            v-show="wantsAction"
            class="z-5"
            :wants-action="wantsAction"
            :section="section"
            :index="index"
            :array="actionItems"
            @close="doesntWantAction"
            @set-tag="setTag"
            @fetching-section="setSectionFetching"></SearchActions>
        <PopupMenu
            v-show="hovered"
            :index="index"
            :hovered="hovered"
            :section="section"
            @fetching-section="setSectionFetching"
            @set-tag="setTag"
            @new-section="newSection"></PopupMenu>
    </div>
</template>

<script>
import contenteditable from "vue-contenteditable";

import keyboardMixin from "../../mixins/keyboardMixin";

import HighlightMenu from "@/components/documents/HighlightMenu.vue";
import ImageSection from "@/components/documents/ImageSection.vue";
import PopupMenu from "@/components/documents/PopupMenu.vue";
import SearchActions from "@/components/documents/SearchActions.vue";
import SectionLoader from "@/components/loaders/SectionLoader.vue";
import UploadComponent from "@/components/images/uploadComponent.vue";
import documentMixin from "@/mixins/DocumentArchive/documentMixins";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import { isProxy, toRaw } from "vue";
// import {getCurrentInstance} from "vue";

export default {
    components: {
        UploadComponent,
        ImageSection,
        SectionLoader,
        SearchActions,
        HighlightMenu,
        PopupMenu,
        contenteditable,
    },
    mixins: [keyboardMixin, documentMixin, documentManagementMixin],
    props: {
        // sectionId: {
        //     type: String,
        //     required: true,
        // },
        section: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        isEditable: true,
        sectionFetching: false,
        hovered: false,
        wantsAction: false,
        loadingText: "Loading content",
        kind: "text",
        noNL: false,
        delCount: 0,
        currentIndex: 0,
        showHighlightMenu: false,
        noHTML: false,
        slashMenu: false,
        parent: null,
        message: "",
        event: "",
        selected: "",
        x: 0,
        y: 0,
        cursor: null,
    }),
    computed: {
        isImage() {
            const content = this.section.content.content || this.section.content;
            const kind = this.section.kind;
            if (
                (content && content.includes(".png")) ||
                content.includes(".jpg") ||
                kind === "image" ||
                kind === "img"
            ) {
                return content;
            }
            return false;
            // return this.section.kind === 'image';
        },
        headerPadding() {
            if (
                this.section.tag === "h1" ||
                this.section.tag === "h2" ||
                this.section.tag === "h3" ||
                this.section.tag === "h4" ||
                this.section.tag === "h5" ||
                this.section.tag === "h6"
            ) {
                return true;
            }
            return false;
        },
        offsetTop() {
            if (this.$refs["container"]) {
                return this.$refs["container"].offsetTop;
            }
        },
        iconButton() {
            return "btn btn-outline-black f-13 border-solid border-base-900/30 border-base-500 smaller border-1 border-base/30 x border-solid m-1 my-2 p-1 serif";
        },
        headerComputed() {
            if (this.section.content.startsWith("# ")) {
                this.section.tag = "h1";
                this.section.content = this.section.content.replace("# ", "");
                return "h1";
                // this.focusSection();
            } else if (this.section.content.startsWith("## ")) {
                this.section.tag = "h2";
                this.section.content = this.section.content.replace("## ", "");
                return "h2";
                // this.focusSection();
            } else if (this.section.content.startsWith("### ")) {
                this.section.tag = "h3";
                this.section.content = this.section.content.replace("### ", "");
                return "h3";
                // this.focusSection();
            } else if (this.section.content.startsWith("#### ")) {
                this.section.tag = "h4";
                this.section.content = this.section.content.replace("#### ", "");
                return "h4";
                // this.focusSection();
            } else if (this.section.content.startsWith("##### ")) {
                this.section.tag = "h5";
                this.section.content = this.section.content.replace("##### ", "");
                return "h5";
                // this.focusSection();
            } else if (this.section.content.startsWith("###### ")) {
                this.section.tag = "h6";
                this.section.content = this.section.content.replace("###### ", "");
                return "h6";
                // this.focusSection();
            } else {
                return "p";
            }
        },
        tag() {
            return this.section.tag;
        },
        focused() {
            // this.$nextTick(() => {
            if (
                this.$refs.contenteditable &&
                this.$refs.contenteditable &&
                this.$refs.contenteditable.$refs &&
                this.$refs.contenteditable.$refs.element
            ) {
                return document.activeElement === this.$refs.contenteditable[0].$refs.element
                    ? "focused"
                    : "not focused";
            }
            // });
        },
        sectionLength() {
            if (this.section && this.section.content && this.section.content.length > 0) {
                return this.section.content.length;
            } else {
                return 0;
            }
            return this.section.content.length || 0;
        },
    },
    watch: {
        sectionIndex: function (val, oldVal) {
            if (val) {
                const selectedIndex = this.sectionIndex;
                if (selectedIndex === this.index) {
                    this.$nextTick(() => {
                        // console.error("current section is", this.index);
                        // setTimeout(() => {
                        this.focusSection();
                        // }, 100);
                    });
                }
            }
        },
        tag: function (val, oldVal) {
            if (val) {
                this.headerType();
                this.focusSection();
            }
        },
    },
    created() {
        // this.focusSection();
    },
    mounted() {
        // this.$nextTick(() => {
        //     if (this.sectionIndex === this.index) {
        //         this.$refs.contenteditable[0].$refs.element.focus();
        //     }
        // });
        // this.focusSection();
    },
    methods: {
        // },
        cursorPosition() {
            let position = 0;
            let element = this.$refs.contenteditable.$refs.element;

            const isSupported = typeof window.getSelection !== "undefined";
            if (isSupported) {
                const selection = window.getSelection();
                // Check if there is a selection (i.e. cursor in place)
                if (selection.rangeCount !== 0) {
                    // Store the original range
                    const range = window.getSelection().getRangeAt(0);
                    // Clone the range
                    const preCaretRange = range.cloneRange();
                    // Select all textual contents from the contenteditable element
                    preCaretRange.selectNodeContents(element);
                    // And set the range end to the original clicked position
                    preCaretRange.setEnd(range.endContainer, range.endOffset);
                    // Return the text length from contenteditable start to the range end
                    position = preCaretRange.toString().length;
                }
            }
            window.cursor = position;
            this.cursor = position;
            return position;
        },
        getCaretCoordinates() {
            let x = 0,
                y = 0;
            const isSupported = typeof window.getSelection !== "undefined";
            if (isSupported) {
                const selection = window.getSelection();
                // Check if there is a selection (i.e. cursor in place)
                if (selection.rangeCount !== 0) {
                    // Clone the range
                    const range = selection.getRangeAt(0).cloneRange();
                    // Collapse the range to the start, so there are not multiple chars selected
                    range.collapse(true);
                    // getClientRects returns all the positioning information we need
                    const rect = range.getClientRects()[0];

                    if (rect) {
                        x = rect.left; // since the caret is only 1px wide, left == right
                        y = rect.top; // top edge of the caret
                    }
                }
            }
            return { x, y };
        //
        },
        populateEmptySection() {
            if (this.section.content.length === 0) {
                this.section.content = " ";
            }
            this.$nextTick(() => {
                this.focusSection();
            });
            // this.$refs.contenteditable.$refs.element.focus();
        },
        // removeSection(previousCursor) {
        //   console.error('removing section emit',previousCursor);
        //     this.$emit("removeSection");
        // },
        setSectionFetching([index, loading, text]) {
            console.error(text);
            this.loadingText = text;
            this.sectionFetching = loading;
        },
        setTag([index, tag]) {
            console.error(index);
            this.$emit("set-tag", [this.index, tag]);
        },
        triggerWantsAction() {
            this.wantsAction = true;
        },
        doesntWantAction() {
            this.wantsAction = false;
        },
        getTextAfterCursor() {
            let range = window.getSelection().getRangeAt(0);
            let postCursorRange = range.cloneRange();
            postCursorRange.selectNodeContents(this.$refs.contenteditable.$refs.element);
            postCursorRange.setStart(range.endContainer, range.endOffset);
            return postCursorRange.toString();
        },
        setCursorPosition(position) {
            // const range = this.$refs.contenteditable.$refs.element;
            let selection = window.getSelection();
            const range = selection.getRangeAt(0);
            window.setSelectionRange(position, position);
            const startNode = selectionRange.startContainer.parentNode;
            const endNode = selectionRange.endContainer.parentNode;
        },
        onMouseup() {
            const self = this;
            try {
                const selection = window.getSelection();
                const selectionRange = selection.getRangeAt(0);
                const startNode = selectionRange.startContainer.parentNode;
                const endNode = selectionRange.endContainer.parentNode;
                if (!startNode.isSameNode(this.$refs.contenteditable.$refs.element) || !startNode.isSameNode(endNode)) {
                    console.error("invalid selection");
                    this.showHighlightMenu = false;
                    return;
                }
                self.selected = selection.toString();
                const { x, y, width } = selectionRange.getBoundingClientRect();
                if (!width || width < 2) {
                    this.showHighlightMenu = false;
                    return;
                } else {
                    if (width > 2) {
                        this.showHighlightMenu = true;
                    }
                }
                this.x = x; // + width / 2;
                this.y = y; // + window.scrollY - 10;
                this.selected = selection.toString();
            } finally {
                return;
            }
        },
        focusSection() {
            this.$nextTick(() => {
                if (
                    this.$refs.contenteditable &&
                    this.$refs.contenteditable.$refs &&
                    this.$refs.contenteditable.$refs.element
                ) {
                    this.$refs.contenteditable.$refs.element.focus();
                }
            });
        },
        headerType() {
            this.$nextTick(() => {
                let text = this.section.content;
                if (this.section.content.startsWith("# ")) {
                    this.$emit("setTag", [this.index, "h1"]);
                } else if (this.section.content.startsWith("## ")) {
                    this.$emit("setTag", [this.index, "h2"]);
                } else if (this.section.content.startsWith("### ")) {
                    this.$emit("setTag", [this.index, "h3"]);
                } else if (this.section.content.startsWith("#### ")) {
                    this.$emit("setTag", [this.index, "h4"]);
                } else if (this.section.content.startsWith("##### ")) {
                    this.$emit("setTag", [this.index, "h5"]);
                } else if (this.section.content.startsWith("###### ")) {
                    this.$emit("setTag", [this.index, "h6"]);
                }
                let newText = text.replace(/^#+\s/, "");
                this.section.content = newText;
            });
            return;
            // write a comment explaining the code above
        },
        newLine(event) {
            console.error("trying new line");
            const textAfterCursor = this.getTextAfterCursor();
            console.error(textAfterCursor);
            // this.cursorPosition();

            console.error(this.section.tag);
            console.error(this.section.kind);
            let kind = "newline";
            if (this.section.tag === "li") {
                kind = "newlineList";
            } else if (this.section.tag === "p") {
                kind = "newline";
            }

            if (textAfterCursor) {
                // this.$emit('newSection', [this.index, textAfterCursor]);
                const s = {
                    index: this.index,
                    content: textAfterCursor,
                    cursorPosition: null,
                    kind: kind,
                };
                this.newSection(s);
                if (this.section.content) {
                    this.section.content = this.section.content.slice(
                        0,
                        this.section.content.length - textAfterCursor.length,
                    );
                }
                this.$emit("focusNextSection", this.index);
            } else {
                let s = {
                    index: this.index,
                    content: " ",
                    cursorPosition: null,
                    kind: kind,
                };
                this.newSection(s);
                this.$emit("focusNextSection", this.index);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
//ul{
//    margin-left: 1.5rem;
//    display: block;
//    list-style-type: disc !important;
//
//    li{
//        margin-left: 1.5rem;
//        list-style-type: disc !important;
//        display: list-item !important;
//        font-size: 1rem;
//    }
//}

.contentSection {
    li {
        display: block !important;
        font-size: 1rem;
        padding-left: 0.5rem;
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
    }

    &:has(li) {
        &:last-child {
            padding-bottom: 2rem;
        }

        font-size: 1rem;
        margin-left: 1.5rem;
        display: list-item !important;
        list-style-type: disc !important;
        list-style-position: outside !important;
        align-items: start;
        padding-inline-start: 0ch;
    }
}

ol {
    margin-left: 1.5rem;
    display: block;
}

p {
    ul {
        margin-left: 3rem !important;
        display: block;
    }
}

h1 {
    font-size: 2.5rem;
    //color: #353554 !important; margin-bottom: 2rem;
}

h2 {
    font-size: 2rem;
    line-height: 1.5em;
}

h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1em;
    background: none;
}

h4 {
    line-height: 1em !important;
    font-size: 1.2rem !important;
    margin-bottom: 1rem !important;
    font-weight: 700;
    //background:red;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    margin-top: 0rem;
}

blockquote {
    margin-left: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-left: 3px solid rgba(100, 100, 100, 0.3);
    padding-left: 1.2rem !important;
    display: block;
    font-size: 1rem;
    font-style: italic;
}

h1,
h2,
h3 {
    margin-bottom: 1rem !important;
}

p {
    font-size: 1rem;
}
div[contenteditable]:empty:not(:focus)::before,p[contenteditable]:empty:not(:focus)::before,li[contenteditable]:empty:not(:focus)::before {
    content: attr(data-placeholder);
    color: #aaa;
}
</style>
