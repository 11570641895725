import imageMixin from "@/mixins/images/imageMixin";
import streamingMixin from "@/mixins/ai/streamingMixin";

export default {
    computed: {},
    methods: {
        async speakResponse(message) {
            let systemPrompt = "Convert the following message into a concise and clear text that is appropriate for a text-to-speech API. If the message contains any code or is too long, please provide an output that ends with ‘read the message for more’";
            let convetedText = await this.completion(message, systemPrompt);
            await this.convertTextToSpeech(convetedText);
        },
        async convertTextToSpeech(text) {
            const voiceId = "21m00Tcm4TlvDq8ikWAM";
            const stability = 0.5;
            const similarityBoost = 0.5;

            console.log("Converting text to speech:", text);
            const audioURL = await this.textToSpeech(text, voiceId, stability, similarityBoost);

            if (audioURL) {
                const audio = new Audio(audioURL);
                console.log("Playing converted text-to-speech audio");
                audio.play();
            }
        },
        async textToSpeech(text, voiceId, stability, similarityBoost) {
            try {
                const XI_API_KEY = "cbea36652613e976ba6cd525b9bb6f19";
                // const XI_API_KEY = import.meta.env.VITE_ELEVEN_API;
                console.log("Using XI API Key:", XI_API_KEY);
                const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`;

                const requestBody = {
                    text,
                    voice_settings: {
                        stability,
                        similarity_boost: similarityBoost,
                    },
                };

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "xi-api-key": XI_API_KEY,
                    },
                };

                const response = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                    headers: config.headers,
                });

                if (!response.ok) {
                    throw new Error(`Error converting text to speech: ${response.status}`);
                }

                const data = await response.blob();
                console.log("Text-to-speech response:", data);

                return URL.createObjectURL(new Blob([data], { type: "audio/mpeg" }));
            } catch (error) {
                console.error("Error converting text to speech:", error);
                return null;
            }
        },
        // async getVoices() {
        //     try {
        //         const XI_API_KEY = "cbea36652613e976ba6cd525b9bb6f19";
        //         const url = "https://api.elevenlabs.io/v1/voices";
        //
        //         const config = {
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 "xi-api-key": XI_API_KEY,
        //             },
        //         };
        //
        //         const response = await fetch(url, {
        //             method: "GET",
        //             headers: config.headers,
        //         });
        //
        //         if (!response.ok) {
        //             throw new Error(`Error fetching voices: ${response.status}`);
        //         }
        //
        //         const data = await response.json();
        //         console.log("Voices response:", data);
        //         this.voices = data.voices;
        //         return data.voices;
        //     } catch (error) {
        //         console.error("Error fetching voices:", error);
        //         return [];
        //     }
        // },
    },
};
