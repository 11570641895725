//firebase log helpers
function getDataSize(data) {
    try {
        const strData = JSON.stringify(data);
        const bytes = strData ? strData.length : 0;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Bytes";
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
    } catch (error) {
        console.warn("Could not stringify data to get size", error);
        return "N/A";
    }
}
function logEachItem(database, data, logKey = false, sorted = false) {
    if (sorted) {
        data = data.sort((a, b) => {
            const sizeA = JSON.stringify(a).length;
            const sizeB = JSON.stringify(b).length;
            return sizeB - sizeA;
        });
    }
    data.forEach(item => {
        logFetchData("single", database, item.id, item, false, true);
    });
}
function logFetchData(dataType, database, id, data, logEach, logKey, silent = true) {
    const dataSize = getDataSize(data);
    const logMessage = dataType === "single" ? "Item loaded" : "DB Loaded";
    const idMessage = id || "";
    if (!silent) {
        console.groupCollapsed(`%c${logMessage} %c${idMessage} %c${dataSize}`, logFirebase, green, logId);
        console.log(data);
        if (logEach) {
            logEachItem(database, data, true, true);
        }
        if (logKey) {
            // log each key in the object if it's an object
            logSortedKeys(data, database);
        }
        console.groupCollapsed("Trace");
        console.trace();
        console.groupEnd();
        console.groupEnd();
    }
}
function logSortedKeys(data, database) {
    if (typeof data === "object") {
        // Sort the keys based on the length of their stringified value
        const sortedKeys = Object.keys(data).sort((a, b) => {
            const lenA = JSON.stringify(data[a]).length;
            const lenB = JSON.stringify(data[b]).length;
            return lenB - lenA; // For ascending order, swap lenA and lenB for descending
        });

        // Log each sorted key
        sortedKeys.forEach(key => {
            logFetchData("single", database, key, data[key], false);
        });
    }
}
function logFetch(database, id) {
    let font = "font-family:arial;font-size:9px;line-height:1;margin-bottom:-2px;";
    // let orange = "#7aab48"
    console.groupCollapsed(`%cFirebase fetch%c\n${database}%c\n${id || "No id"}`, `color:darkorange;text-transform:uppercase;${font}`, `color:#7aab48;${font}`, `color:#ccc;${font}`);
    console.log("fetching from firebase", id);
    if (id) console.log("Fetching document with ID: ", id);
    console.groupCollapsed("trace");
    console.trace();
    console.groupEnd();
    console.groupEnd();
}
function logSave(database, id, payload) {
    // console.groupCollapsed(`%c☁️ Save to Firebase`, warn, `${database} ${id}`);

    console.groupCollapsed(`%cFirebase save %c${database} %c${id || "No id"}`, logFirebase, green, logId);
    console.log("saving to firebase", id);
    // console.log("Document written with ID: ", payload.id);
    console.table(payload);
    if (id) console.log("Saving document with ID: ", id);
    console.trace();
    console.groupEnd();
}
function logPagenatedFetch(database, itemsLimit, startAfterDate, startAfterId) {
    console.groupCollapsed(`%cFirebase fetch %c${database} %cLimit:${itemsLimit}  After date:${startAfterDate}  After ID:${startAfterId}`, logFirebase, green, logId);
    console.log("Fetching documents from collection: ", database);
    console.trace();
    console.groupEnd();
}
export { logFetchData, getDataSize, logFetch, logSave, logPagenatedFetch };
