<template>
    <div class="x p-0" @mouseleave="hovered = false">
        <Transition appear enter-active-class="swingInUp animated stagger-1">
            <contenteditable v-if="section.title" ref="contenteditable" v-model="section.title" :contenteditable="true" :no-html="true" :no-nl="false" class="stagger-1 x d-block whitespace-pre-line transition-colors" tag="h2" @blur="saveSection()"></contenteditable>
            <!--            <h2 v-if="section.title">{{ section.title }}</h2>-->
        </Transition>
        <Transition appear enter-active-class="swingInUp animated stagger-2" leave-active-class="swingInUp animated stagger-2 reverse-animation">
            <!--            <p class="f-20 o-5" v-if="section.body && !section.enhancedBody">{{ section.body }}</p>-->
            <div class="p-relative x transform transition-all">
                <!--                <span class="whitespace-pre-line">{{document.sections[index]}}</span>-->
                <!--                <Transition name="fade-up" appear>-->
                <template v-if="editing">
                    <contenteditable
                        v-if="section.enhancedBody"
                        ref="contenteditable"
                        v-model="section.enhancedBody"
                        :class="{ 'f-24 font-bold': fullBleed }"
                        :contenteditable="true"
                        :no-html="true"
                        :no-nl="false"
                        class="o-8 stagger-2 x d-block f-17 whitespace-pre-line transition-colors"
                        tag="p"
                        @blur="saveSection(), (editing = false)"></contenteditable>
                    <contenteditable
                        v-else-if="section.body"
                        ref="contenteditable"
                        v-model="section.body"
                        :class="{ '!f-24 !font-bold': fullBleed }"
                        :contenteditable="true"
                        :no-html="true"
                        :no-nl="false"
                        class="o-8 stagger-2 x d-block whitespace-pre-line transition-all"
                        tag="p"
                        @blur="saveSection(), (editing = false)"></contenteditable>
                </template>
                <presentation-markdown @mouseover="hovered = true" :class="{ ' f-24 !font-bold  o-100': fullBleed }" @click.prevent="editing = true" class="o-8 prose f-17" v-else-if="!editing" :content="section.enhancedBody || section.body"></presentation-markdown>
                <p v-else-if="!section.style.includes('full')" class="o-5 f-20">Add some text</p>
                <!--                </Transition>-->
                <!--{{comments}}-->
                <Transition name="fade-up" appear>
                    <div class="p-relative d-block x f" v-if="hovered">
                        <div class="border-top x o-5 d-inline-flex jcs x f-20 aic mt-2 gap-2 pt-2" v-if="$store.state.presentation.document.theme">
<!--                                                        <div class="f btn-bubble c-40 aic jcc rounded-full" style="" @click.prevent="toggleShowComments()">-->
<!--                                                            <i class="fas fa-comment-alt" style="font-size: 13px"></i>-->
<!--                                                        </div>-->
                            <Voice-Picker class="f visually-hidden my-0 py-0"></Voice-Picker>

                            <ListenButton kind="secondary" class="o-100 bg-transparent !text-snow" :content="spokenContent"></ListenButton>
                            <template v-if="responseText">{{ changeResposeText }}</template>
                        </div>
                        <Transition appear enter-active-class="swingInUp animated duration-1" leave-active-class="swingInUp animated duration-1 reverse-animation">
                            <div v-if="showComments" class="mw-500 x f fc p-absolute mw-400 z-5 p-absolute bottom left right br-10 overflow-hidden bg-snow shadow" style="max-height: 400px !important">
                                <div class="x scrollSection px-2 pl-2">
                                    <template v-if="comments && comments.length && comments.length > 0">
                                        <template v-for="comment in comments">
                                            <CommentItem :comment="comment"></CommentItem>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="f aic jcc p-5 text-base-200" style="height: 100px">No comments yet</div>
                                    </template>
                                </div>
                                <div class="p-absolute top right c-30 f z-5 aic jcc m-1 rounded-full bg-white text-base-900 shadow" @click.prevent="showComments = false">
                                    <i class="far fa-times f-20 text-base-900" aria-hidden="true"></i>
                                </div>
                                <div class="border-top f jce f x aic p-2 pt-1">
                                    <div class="x f p-relative aic jcc">
                                        <input ref="comment" v-model="prompt" class="x f-17 mt-0 p-0 px-2 py-1 pr-5" style="border: 0px solid rgba(0, 0, 0, 0.1); border: 1px solid rgba(0, 0, 0, 0.1); background: rgba(0, 0, 0, 0.02)" @keydown.enter="modifyPrecursor(prompt)" />
                                        <div class="c-20 text-blue d-inline x f p-absolute right f-20 aic jcc mr-0 h-auto flex-shrink pl-2 pr-0 pr-4" style="max-width: 30px" @click.prevent="modifyPrecursor(prompt)">
                                            <i class="fas fa-arrow-circle-up"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </Transition>
            </div>
            <!--            <p class="f-15 o-5" v-else-if="section.enhancedBody">{{ section.enhancedBody }}</p>-->
        </Transition>

        <!--                                    <p class="f-20 o-5" v-if="section.style">{{ section.style }}</p>-->
    </div>
</template>

<script>
import contenteditable from "vue-contenteditable";

import CommentItem from "@/components/Presentations/slides/CommentItem.vue";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import imageMixin from "@/mixins/images/imageMixin";
import ListenButton from "@/components/chat/audio/ListenButton.vue";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import updateSlideProp from "@/mixins/ai/update_slide_prop";
import VoicePicker from "@/components/CoreUI/VoicePicker.vue";

export default {
    components: { VoicePicker, PresentationMarkdown, ListenButton, CommentItem, contenteditable },
    mixins: [documentManagementMixin, imageMixin, presentationMixin],
    data() {
        return {
            responseText: "",
            prompt: "",
            editing: false,
            hovered: false,
            // document: {},
            documents: [],
            comments: [],
            showComments: false,
        };
    },
    created() {
        // this.$firestoreBind('document', doc(documents, this.$route.params.id));
        this.enhancedBody = this.section.enhancedBody;
        // this.getCommentsForSectionFromFirebase();
    },
    // firestore: {
    // documents: collection(db, "documents"),
    // document: getDoc(doc(db, 'documents', route.params.id)),
    // document:useDocument(doc(db, 'settings', this.documentId)),
    // },
    computed: {
        spokenContent() {
            if (this.section.enhancedBody) {
                return this.section.title + " " + this.section.enhancedBody;
            } else {
                return this.section.title + " " + this.section.body;
            }
        },
        updatedDocumentSections() {
            const oldSections = [...this.document.sections];
            const newSection = this.section;
            oldSections.splice(this.index, 1, newSection);
            return;
        },
        changeResposeText() {
            this.section.enhancedBody = this.responseText;
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (this.$route.params.id) {
                    // this.$firestoreBind("document", doc(documents, this.$route.params.id));
                }
            },
        },
        responseText: {
            handler: function (val, oldVal) {
                if (val !== null) {
                    this.section.enhancedBody = val;
                    val.replace("[[DONE]]", "").replace("null", "");
                }
            },
            deep: true,
        },
    },
    methods: {
        async getCommentsForSectionFromFirebase() {
            // Get the comments for the document
            try {
                let showcomments = [];
                // await db.collection('comments').where('documentId', '==', this.$route.params.id).where('sectionId', '==', this.section.id).orderBy('updated', 'desc').get().then(querySnapshot => {
                await db
                    .collection("comments")
                    .where("sectionId", "==", this.section.id)
                    .orderBy("updated")
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            let comment = { ...doc.data() };
                            comment = { ...comment, ...comment.comment };
                            showcomments.push(comment);
                        });
                        this.comments = showcomments;
                    });

                if (showcomments.length > 0) {
                    let sorted = showcomments.sort((a, b) => {
                        if (a.updated > b.updated) {
                            return -1;
                        }
                        if (a.updated < b.updated) {
                            return 1;
                        }
                        return 0;
                    });
                    this.comments = sorted;
                    return;
                } else {
                    this.comments = [];
                    return;
                }
            } catch (e) {
                this.comments = [];
                console.error(e);
                return false;
            }
        },
        addComment(documentId, comment) {
            // Create a new comment in Firebase
            let sectionId;
            if (!this.section.id) {
                sectionId = this.randomId();
            } else {
                sectionId = this.section.id;
            }
            let created = new Date();
            const commentRef = db.collection("comments").doc();
            commentRef.set({
                ...comment,
                created: created,
                updated: created,
                documentId: this.$route.params.id,
                sectionId: sectionId,
            });
            // Get the ID of the newly created comment
            const commentId = commentRef.id;
            console.error(commentRef);
            this.$nextTick(function () {
                this.getCommentsForSectionFromFirebase();
            });

            // // Associate the ID of the comment with the ID of the document
            // db.collection("documents").doc(documentId).update({
            //     comments: firebase.firestore.FieldValue.arrayUnion(commentId)
            // });
        },
        toggleShowComments() {
            this.getCommentsForSectionFromFirebase();
            this.$nextTick(function () {
                if (this.showComments) {
                    this.showComments = false;
                } else {
                    this.showComments = true;
                    // focus on the $ref for the text area
                    this.$nextTick(function () {
                        this.$refs.comment.focus();
                    });
                }
            });
        },
        async saveSection() {
            let key = "enhancedBody";
            if (!this.section.enhancedBody) {
                key = "body";
            }
            const sectionText = this.section.enhancedBody || this.section.body;
            updateSlideProp(this.$store, key, sectionText, this.index);
            await this.savePresentation(this.$route.params.id);
            return;
        },
        async modifyPrecursor(user) {
            let system = `CONTEXT:
- You are a
- A user is editing a presentation slide.
- Your job is to determine the users intent with the message you receive intent.
- Following is a user's instructions to edit a presentation slide.

A user will be leaving comments on a slide and you will do your best to determine if they are speaking about the image("image"), the body's text("bodyText"), or the title ("titleText").
----

RULES:
1. You receive user input and determine a user's intent based off of 3 options.
2. You only respond with your best guess to the option.
3. You only respond with one of the response options.
4. You Only Respond with the format in between quotes. below

----
RESPONSE OPTIONS:
1. "image"
2. "bodyText"
3. "titleText"

EXAMPLE:
- User: Make it longer
- bodyText
- User: Make it shorter
- bodyText
- User: Make it bigger
- image

`;
            const response = await this.completion(user, system, "precursor", "gpt-3.5-turbo", 10);
            let text = response;
            text = text.toLowerCase();
            console.error(text);
            if (text.includes("body")) {
                this.modifyPassage(user);
            } else {
                await this.modifyImage(user);
            }
        },
        async modifyImage(prompt) {
            const index = this.index;
            let imagePrompt = this.section.image;
            if (this.section.enhancedImage) {
                imagePrompt = this.section.enhancedImage;
            }
            let comment = {
                comment: prompt,
                original: imagePrompt,
                user: "Asher Hunt",
            };
            this.addComment(this.$route.params.id, comment);
            this.$parent.section.imageURL = "";
            this.prompt = "";
            this.showComments = false;
            let fullText = "";
            if (this.section.style.includes("full")) {
                fullText = " Negative space in the center. ";
            }
            let messages = [
                { role: "system", content: this.newImagePrompt },
                // { role: "system", content: "Modify the ORIGINAL PROMPT to include the following user notes, then follow instructions below. The AI does not have context on the existing image so you'll have to describe it all in full. Keep it under 400 characters total." },
                { role: "user", content: this.section.image },
                { role: "assistant", content: imagePrompt + " " + fullText },
                { role: "user", content: `[FEEDBACK]:\n\n${prompt + fullText}\n\n[UPDATED TEXT]:\n\n` },
            ];
            let modifiedImagePrompt = await this.completion(messages, "", "modify image prompt", "gpt-3.5-turbo", 75);
            updateSlideProp(this.$store, "enhancedImage", modifiedImagePrompt, index);
            await this.savePresentation(this.$route.params.id);
            await this.stabilityPresentationImage(index, modifiedImagePrompt);
            this.addComment(this.$route.params.id, {
                comment: "Ok! I did that",
                original: this.section.enhancedBody,
                user: "Clearmind",
            });
        },
        async modifyPassage(prompt) {
            const summary = this.document.summary;
            const bodyText = this.section.body;
            const enhancedBodyText = this.section.enhancedBody;
            const feedback = prompt;
            console.error("modifyPassage");
            let comment = {
                comment: prompt,
                original: enhancedBodyText,
                user: "Asher Hunt",
            };
            // this.addComment(this.$route.params.id, comment);
            this.comments.push(comment);
            this.prompt = "";
            this.showComments = false;
            this.responseText = "";
            let text = bodyText;
            if (enhancedBodyText) {
                text = enhancedBodyText;
            }
            let messages = [sysMessage(`PRESENTATION SUMMARY:\n${summary}\n\n Edit the provided text that follows. \n\n\n\nOnly respond with the edited passage.`), aiMessage(text), userMessage(`${feedback}`)];
            await this.simpleMessage(messages, "You are helping a user edit a presentation", () => `responseText`);
            await this.saveSection();
            this.addComment(this.$route.params.id, {
                comment: "Ok! I did that",
                original: text,
                user: "Clearmind",
            });
            // return this.streamText(modString, "responseText"); // NOTE: i want this.streamText() to write to stream to this.responseText
        },
    },
    props: {
        fullBleed: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style>
h2{
    color:unset;
}
</style>
