<template>
    <tr class="" style="border-bottom: 1px solid black !important">
        <td v-if="displayTitle" class="font-semibold text-gray-900 p-3" @click="$emit('clicked', item)">
            <div class="x mw-400 line-clamp-1">{{ displayTitle }}</div>
            <p v-if="displayBody" class="x mw-400 f-13 o-5 mb-3 line-clamp-1 fwn">{{ displayBody }}</p>
            <div v-if="size" class="f-15 o-3 mt-1">
                <i class="fas fa-save mr-2"></i>
                {{ formattedSize }} ·
                <span v-if="status" class="text-capitalize">{{ status }}</span>
            </div>
            <div v-if="item?.images?.length" class="small o-3 f gap-2 flex-wrap f-13 fwn">
                <ItemCountIcon v-if="item?.images?.length" icon="images" :items="item.images.length" />
                <div>{{ item.id }}</div>
            </div>
        </td>
        <td class="max-w-md p-3">
            <a v-if="url" :href="url" class="break-all text-gray-500 text-base f-15 mr-5 block line-height-smaller mw-300 d-block fwn" style="line-height: 1.2" target="_blank">
                {{ url }}
            </a>
        </td>
        <td class="p-3 text-center">
            <div class="block">
                <BaseBadge v-if="kind" :color="badgeColor" :icon="`${sourceIcon}`" :text="kind" class="text-sm !fwn inline gap-2 f-13 py-0 px-2"></BaseBadge>
            </div>
        </td>
        <td class="p-3 text-center">
            <div v-if="displayUpdated" class="block f-15 width-max-100 x px-2">
                <TimeStamp :date="displayUpdated" />
            </div>
        </td>
        <td class="text-center p-3">
            <SourceButtonPile :kind="kind" :url="url" @remove-source="removeSource(url)" @sync-source="syncSource" />
        </td>
    </tr>
</template>

<script>
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import moment from "moment";
import SourceButtonPile from "@/components/Admin/SourceButtonPile.vue";
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import ItemCountIcon from "@/views/ItemCountIcon.vue";

export default {
    name: "SourceItem",
    components: { ItemCountIcon, TimeStamp, SourceButtonPile, BaseBadge },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        url: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        kind: {
            type: String,
            default: "Website",
        },
        updated: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "",
        },
        status: {
            type: String,
            default: "",
        },
    },
    emits: ["clicked"],
    methods: {
        syncSource() {
            this.$emit("sync-source", this.url);
        },
        removeSource() {
            let kind = this.kind;
            kind = kind.toLowerCase();
            if (kind === "website") return this.$store.dispatch("styleGuide/deleteSource", this.item?.id);
            if (kind === "product") return this.$store.dispatch("styleGuide/deleteProduct", this.item?.handle);
            else this.$emit("remove-source", this.url);
        },
    },
    computed: {
        displayUpdated() {
            return this.item?.updated || this.item?.updatedAt || this.updated;
        },
        displayTitle() {
            let title = this.item?.title || this.item?.name;
            return title || this.title || "";
        },
        displayBody() {
            return this.item?.description || this.item?.content;
        },
        formattedSize() {
            const bytes = this.size;
            if (bytes === 0) return "0 Bytes";
            const k = 1024;
            const dm = 2; // number of decimal places
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        },
        updatedDate() {
            let date = this.toDate(this.updated);
            if (this.kind === "assistants") {
                // use moment to format the date
                return moment(this.updated).format("MM/DD/YY h:mma");
                // return this.updated);
            }
            if (!date) {
                date = this.$filters.timeAgo(this.updated);
            }
            if (!date) {
                return this.updated;
                // date = this.$filters.timeAgo(this.updated);
            } else {
                date = this.$filters.timeAgo(this.toDate(this.updated));
            }
            return date;
            // return this.$filters.timeAgo(this.toDate(this.updated));
        },
        sourceIcon() {
            if (this.kind) {
                let kind = this.kind.toLowerCase();
                if (kind === "website") return "far fa-sm o-5 fa-globe";
                if (kind === "product") return "far fa-sm  o-5 fa-tags";
                if (kind === "assistants") return "fas fa-sm  o-5 fa-save";
                return "far o-5 fa-sm fa-globe";
            }
        },
        isProduct() {
            return this.kind === "Product" || this.kind === "product";
        },
        isWebsite() {
            return this.kind === "Website" || this.kind === "website";
        },
        isFile() {
            return this.kind === "file" || this.kind === "assistants";
        },
        badgeColor() {
            if (this.isWebsite) {
                return "blue";
            } else if (this.isProduct) {
                return "green";
            } else if (this.isFile) {
                return "indigo";
            }
        },
    },
};
</script>
<style lang="scss" scoped>
table {
    //border: 1px solid black;
}

thead {
    //border: 1px solid black !important;
    @apply border border-solid border-b;
    @apply border-gray-200;
}

th {
    border: 0px !important;
}

th,
tr {
}

.container {
    max-width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    @apply bg-gray-100;
}

th,
td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #e5e7eb;
    @apply border-gray-200;
    @apply border-b-gray-200;
}

th {
    font-weight: 500;
}

tbody tr:nth-child(odd) {
    @apply bg-gray-50;
}

tbody tr:hover {
    @apply bg-gray-100/80;
    cursor: pointer;
}

@media (max-width: 768px) {
    th,
    td {
        padding: 8px;
    }
}
</style>
