<!-- SimpleSelector.vue -->
<template>
    <div>
        <label class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
        <div class="relative mt-2">
            <button class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" @click="toggleDropdown">
                <span class="block truncate">{{ selectedRole.name }}</span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </button>
            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ul v-if="isOpen" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <li v-for="role in roles" :key="role.id" :class="[isSelected(role) ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']" @click="selectRole(role)">
                        <span :class="[isSelected(role) ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ role.name }}
                        </span>
                        <span v-if="isSelected(role)" :class="[isSelected(role) ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ul>
            </transition>
        </div>
    </div>
</template>

<script>
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
    name: "SimpleSelector",
    components: {
        CheckIcon,
        ChevronUpDownIcon,
    },
    props: {
        label: {
            type: String,
            default: "Role",
        },
        modelValue: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isOpen: false,
            selectedRole: this.modelValue,
            roles: [
                { id: "user", name: "User" },
                { id: "admin", name: "Admin" },
                { id: "super-admin", name: "Super Admin" },
            ],
        };
    },
    mounted() {
        setTimeout(() => {
            this.selectedRole = this.modelValue;
        }, 300);
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectRole(role) {
            this.selectedRole = role;
            this.$emit("update:modelValue", role);
            this.isOpen = false;
        },
        isSelected(role) {
            return this.selectedRole.id === role.id;
        },
    },
};
</script>
