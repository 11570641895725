<template>
    <div class="brand-bg footer_section" style="display: block" v-if="emailFooter">
        <div :style="`width:100%;padding:20px;border-radius: 0px; font-family: Arial; letter-spacing: unset; line-height: unset;color:${color};background-color:${backgroundColor}`" class="email-footer theme1">
            <div>
                <!--                <div>-->
                <!--                    <BrandLogoComponent :color="emailFooterText" class="" style="position: relative; max-width: 100px" width="100px"></BrandLogoComponent>-->
                <!--                </div>-->
                <div v-if="links" style="font-size: 15px; margin-top: 20px; margin-bottom: 20px">
                    <template v-for="{ url, text } in links">
                        <a :href="url" :style="`font-family: Arial, serif; margin-bottom: 20px; text-align: center; font-weight: bold; display: block; padding: 10px 20px; border-radius: 5px; width: 100%;color:${backgroundColor};background-color:${color};`" class="brand_button">
                            <template v-if="text">{{ text }}</template>
                        </a>
                    </template>
                </div>
                <div :style="`text-align: center; max-width: 500px; margin: 0 auto; color:${color}!important;`">
                    <div style="font-size: 13px">
                        <span :style="`font-family: arial, helvetica, sans-serif; color:${color}!important;`">
                            No longer want to receive these emails? {% unsubscribe %}.
                            <br />
                            {{ templateString }}
                        </span>
                    </div>
                    <div style="text-align: center; max-width: 300px; margin: 0 auto">
                        <span :style="`font-weight: 400; font-size: 13px; color:${color}!important;`">
                            Copyright © {{ currentYear }} • All Rights Reserved
                            <br />
                            {{ name }} • {{ url }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import chroma from "chroma-js";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import EmailFooterTable from "@/components/generative_templates/Email/EmailFooterTable.vue";

export default {
    name: "EmailFooterSection",
    components: { EmailFooterTable, BrandLogoComponent },
    mixins: [styleGuideMixin],
    props: {
        // eslint-disable-next-line vue/require-default-prop
        footer: {},
        section: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        color() {
            return this.footer?.color || "#ffffff";
        },
        backgroundColor() {
            return this.footer?.backgroundColor || "#000000";
        },
        templateString() {
            return `{{organization.name}} {{organization.full_address}}`;
        },
        currentYear() {
            return new Date().getFullYear();
        },
        name() {
            return this.styleGuide?.name || "";
        },
        url() {
            return this.styleGuide?.url || "";
        },
        computedColors() {
            return `background:${this.emailFooterBg};color:${this.emailFooterText};`;
        },
        emailFooterHtml() {
            return ``;
            // return this.$refs?.emailFooterTable?.emailFooterHtml || "";
        },
        unsubscribe() {
            return this.emailFooter?.unsubscribe || {};
        },
        links() {
            return this.emailFooter?.links || [];
        },
        footerButton() {
            return this.buttonStyles[1] || this.buttonStyles[0];
        },
        text() {
            return this.emailFooter?.text || "";
        },
        emailFooterBg() {
            // Ensure there is a color value; use a default if not.
            if (!this.section?.backgroundColor) return "#fff";
            const color = this.section?.backgroundColor;
            const buttonColor = this.buttonStyles?.[0]?.backgroundColor;
            // Create a Chroma object from the color.
            let chromaColor = chroma(color);

            try {
                if (chromaColor.luminance() < 0.5) {
                    return chromaColor.luminance() < 0.5 ? chromaColor.brighten(4).saturate(-0.5).css() : color;
                } else {
                    // check saturation
                    chromaColor = chroma(buttonColor);
                    // if (chroma(color).vibrancy() > 0.5) chromaColor = chromaColor.saturate(-0.5).css();
                    return chromaColor.css();
                    // return chromaColor.luminance() > 0.5 ? chromaColor.darken(1).saturate(2).css() : color;
                }
            } catch (error) {
                console.error(`Error processing color '${color}':`, error);
                return color;
            }
        },
        emailFooterText() {
            if (!this.section?.backgroundColor) return "#888";
            const color = this.section.backgroundColor;
            let chromaColor = chroma(color);
            const buttonColor = this.buttonStyles?.[0]?.backgroundColor;
            const buttonText = this.buttonStyles?.[0]?.color;
            if (chromaColor.luminance() < 0.5) {
                // return chromaColor.brighten(-1).saturate(2).css();
                return chromaColor.darken(3).saturate(2).css();
            } else {
                chromaColor = chroma(buttonText);
                return chromaColor.css();
                // return chromaColor.brighten(8).saturate(2).css();
            }
            // return this.buttonStyles[0].color;
            // return this.section?.backgroundColor || "";
        },
        emailFooter() {
            let footer = this.footer || {};
            let { footer_text = "", footer_links = [], unsubscribe_text = "", unsubscribe_link = "" } = footer;
            if (footer_text) footer_text = footer_text?.trim();
            if (footer_links) {
                let links = footer_links;
                if (links.length > 0) {
                    links = links.map(link => {
                        let footerLink = { ...link };
                        let { text, url } = footerLink;
                        if (text) text = text.trim();
                        if (url) url = url.trim();
                        return { text, url };
                    });
                    footer.links = links;
                }
            }
            if (unsubscribe_text) {
                unsubscribe_text = unsubscribe_text.trim();
            }
            return {
                text: footer_text,
                links: footer_links,
                unsubscribe: {
                    text: unsubscribe_text,
                    link: {
                        text: unsubscribe_link,
                        url: "",
                    },
                },
            };
        },
    },
};
</script>
<!--                          `background:${emailFooterText};color:${emailFooterBg}`-->
