let research_brand = {
    name: "research_brand",
    description: "Fill out the queries to research a brand.",
    parameters: {
        type: "object",
        properties: {
            new_prompt: {
                type: "string",
                description: "amplify the original prompt.",
            },
            google_image_query: {
                type: "array",
                description: "google queries to gather images about subject. These will be used to retrieve accompanying images to the content so be sure to ask for a few.",
                items: {
                    type: "string",
                    description: "A well-written google query to research images about a brand",
                },
            },
            google_query: {
                type: "array",
                description: "google queries to research information about the brand. If you don't know enough, don't make things up.",
                items: {
                    type: "string",
                    description: "A well-written google query to research information about a brand (usually just the brand's name)",
                },
            },
            instructions_for_factsheet: {
                type: "string",
                description: "Instructions written for the research specifying what types of written notes to collect while researching various websites for written content. Include formatting, etc. DO NOT mention images. DO NOT chat.",
                example: `Shorten all content to about 5 paragraphs (max) then provide an additional 2 paragraphs with all context related to what the user is looking for. 
Include all main ideas, entities, and details from the original text, highlight relevant all the context related to what the user is looking for.
Use Markdown. Bold Important entities.
Don't chat.`,
            },
            number_of_slides: {
                type: "number",
                description: "The number of slides to create in the presentation.",
            },
            brand_name: {
                type: "string",
                description: "Extract the name of the brand name.",
            },
            website: {
                type: "string",
                description: "The website of the brand.",
            },
            competitors: {
                type: "string",
                description: "The competitors of the brand.",
            },
            target_audience: {
                type: "string",
                description: "Define the target audience for the brand.",
            },
            founders: {
                type: "string",
                description: "The founders of the brand. (leave blank if unknown)",
            },
            thoughts: {
                type: "string",
                description: "Record detailed, strategic expert thoughts on what would make the best possible output for this topic. Example:'I believe we should highlight ...",
            },
            // XYZ and illustrate ABC frame to best present the deck. We should frame their competitors as ...., if we want to get investors, we should strategically trinkle in ABC to leave them thinking XYZ' etc ...
            // query_array: {
            //     type: "array",
            //     description: "a list of google queries to research information about the topic. If you don't know enough, don't make things up.",
            //     items: {
            //         type: "string",
            //         description: "A well-written google query to research information about a topic.",
            //     },
            // },
            // source_ideas: {
            //     type: "string",
            //     description: "Ideas on where to best online resources to source the information. (Example: [brand name] wikipedia.com, [brand name] crunchbase.com, CNN.com, Online News (CNN, Forbes, etc), [company url], etc. They should all be websites.)",
            //     // example: "Try browsing Wikipedia, Crunchbase, Google, News, company website, etc.",
            // },
            // business_sector: {
            //     type: "string",
            //     description: "The business sector of the brand.",
            // },
            // plan: {
            //     type: "string",
            //     description: "List out a 10-step plan to research and then best use the research to provide the best possible output. Example: 1. I will search google to find top-level information.\n2. I will search google for compelling images about the topic.\n3. I'll browse the website to find more images and information. etc ... ",
            //     Example: "1. I will search google to find top-level information.\n2. I will search google for compelling images about the topic.\n3. I'll browse the website to find more images and information. etc ... ",
            //     // description: "This is the plan on what to do with the research.",
            // },
            // whats_needed: {
            //     type: "string",
            //     description: "List what is needed to execute the plan. Example: Browse the brand's website\nSearch google for [brand name], wikipedia and more.\n I should also search google for news and recent press clippings.",
            // },
            // },
            // topics_to_cover: {
            //     type: "array",
            //     description: "Write the topics to cover (or topics) into an array of (strings minimum 10,based on slide/page count)",
            //     items: {
            //         type: "string",
            //         description: "Extract the areas of interest into an array of strings. Example:'topic founders', 'topic origin story', 'timelines'",
            //     },
            // },
            // best_practices: {
            //     type: "string",
            //     description: "List the best practices for the desired medium.",
            // },
            // category: {
            //     type: "string",
            //     enum: ["brand", "historical", "topical", "news", "science", "other", "person", "process"],
            //     description: "This is the category of the topic.",
            // },
        },
        required: [
            "new_prompt",
            "google_query",
            "brand_name",
            "instructions_for_factsheet",
            "target_audience",
            "founders",
            "google_image_query",
            "competitors",
            "number_of_sections",
            "website",
            // "thoughts",
            // "plan",
            // "best_practices",
            // "whats_needed",
            // "source_ideas",
            // "brand_name",
            // "category",
            // "business_sector",
            // "query_array",
            // "topics_to_cover",
            // "website",
        ],
    },
};
export default research_brand;
