<template>
    <div>
        <collapsable-menu-item>
            <template #title>
                <div class="f border-bottom aic jcb py-1 overflow-hidden px-2" :class="{ 'px-3': kind !== 'image' }">
                    <div class="c-40 br-10 border-light aspect-square overflow-hidden mr-2" v-if="kind === 'image' && item.url"><img :src="'https://' + item.url" /></div>
                    <div class="x py-1 f ais jcs gap-3">
                        <!--                        <div class="block pt-2" v-if="item.status"><StatusLight :status="item.status" /></div>-->
                        <div>
                            <div class="fwb line-clamp-1">
                                <template v-if="item?.name">{{ item.name }}</template>
                                <template v-else-if="item?.title">{{ item.title }}</template>
                                <template v-else-if="item?.url">{{ item.url }}</template>
                                <template v-else-if="item?.urlOld">{{ item.urlOld }}</template>
                            </div>
                            <div class="f aic">
                                <div class="text-xs line-clamp-1 o-5" v-if="item?.text">{{ item.text }}</div>
                                <div class="x text-xs f gap-3 o-5">
                                    <ItemCountIcon v-if="item?.images" :items="item.images" icon="image" />
                                    <ItemCountIcon v-if="item?.links" :items="item.links" icon="link" />
                                    <div class="text-xs" v-if="item?.updated">
                                        <time-stamp :date="item.updated" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="f jce gap-3 aic f-13">
                        <div class="flex-shrink-0">
                            <Avatars v-if="images?.length > 0" size="xs" class="x jce" :images="images.slice(0, 4)" />
                        </div>
                        <div class="flex-shrink-0">
                            <BaseBadge class="" v-if="item?.process">{{ item.process }}</BaseBadge>
                        </div>
                        <div class="flex-shrink-0">
                            <StatusBadge v-if="item?.status" :item="item" />
                        </div>
                    </div>
                </div>
            </template>
            <SourceDetails class="border-left border-right p-3" :item="item" />
        </collapsable-menu-item>
    </div>
</template>
<script>
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import Avatars from "@/views/Avatars.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import ItemCountIcon from "@/views/ItemCountIcon.vue";
import StatusBadge from "@/views/StatusBadge.vue";
import SourceDetails from "@/views/SourceDetails.vue";

export default {
    name: "SourceProgressItem",
    components: { SourceDetails, StatusBadge, ItemCountIcon, DynamicObjectViewer, CollapsableMenuItem, Avatars, BaseBadge, TimeStamp },
    props: {
        item: { type: Object, default: () => {} },
        kind: { type: String, default: "source" },
    },
    data() {
        return {};
    },
    computed: {
        source() {
            let { images, ...rest } = this.item?.source || {};
            return rest;
        },
        images() {
            return this.item?.source?.images || [];
        },
    },
};
</script>
<!--                <div class="text-sm line-clamp-2" v-if="item?.categoryLinks">{{ item.categoryLinks }}</div>-->

<!--                        <span v-if="item.product">{{ item.product }}</span>-->
<!--            <div v-if="source">{{ source }}</div>-->
<!--            <div class="whitespace-pre-line" v-if="source?.content">{{ source.content }}</div>-->
<!--                <div v-if="source?.links"><p v-for="s in source.links">{{ s }}</p></div>-->
<!--                        <div v-if="source?.paragraphs"><p v-for="s in item.paragraphs">{{ s }}</p></div>-->
