<template>
    <div class="flex flex-col">
        <div class="mb-2">
            {{ selectedFiles }}
            <label class="mb-2 text-sm font-medium text-gray-900" for="file_ids">Selected Files</label>
            <div class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <div v-if="selectedFileObjects" class="f gap-3 overflow-x-scroll scrollbar-hide pl-2 py-1">
                    <template v-for="file in selectedFileObjects" :key="file?.id">
                        <div class="flex-shrink-0">
                            <FileUploadCard v-if="file?.filename" :file="{ name: file?.filename }" :show-remove="false" file-preview="" @click.prevent="removeFile(file.id)"></FileUploadCard>
                        </div>
                        <div v-for="f in selectedFiles" @click.prevent="removeFile(f)">{{ f }}</div>
                    </template>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <label class="mb-2 text-sm font-medium text-gray-900" for="file_ids">AvailableFiles</label>
            <div v-if="files" class="f gap-3 overflow-x-scroll scrollbar-hide aic pl-3 py-3">
                <div class="c-40 border border-2 border-dashed f aic jcc hover:bg-snow transition-all hover:text-green border-gray-200 hover:border-snow cursor-pointer hover:shadow" @click="editing = !editing"><i class="fas fa-plus"></i></div>
                <div v-for="file in files" :key="file?.id" class="flex-shrink-0">
                    <FileUploadCard :file="{ name: file?.filename, size: file?.bytes, type: file?.object }" :show-remove="false" file-preview="" @click.prevent="addToFiles(file.id)"></FileUploadCard>
                </div>
            </div>
            <OpenAiFileUpload v-if="editing"></OpenAiFileUpload>
        </div>
    </div>
</template>

<script>
import FileUploadCard from "@/components/images/FileUploadCard.vue";
import OpenAiFileUpload from "@/components/images/fileUploader.vue";

export default {
    name: "FilesSelector",
    components: { OpenAiFileUpload, FileUploadCard },
    props: {
        selectedFiles: {
            type: Array,
            default: () => [],
        },
    },
    emits: ["update-files"],
    data() {
        return {
            fileArray: [],
            editing: false,
        };
    },
    computed: {
        selectedFileObjects() {
            if (this.files) {
                return this.selectedFiles.map(id => this.files.find(f => f.id === id));
            }
            return [];
        },
        files() {
            return this.$store.state?.stream?.stream?.options?.files || [];
        },
    },
    methods: {
        addToFiles(id) {
            let selectedFiles = this.selectedFiles || [];
            if (!selectedFiles.includes(id)) {
                selectedFiles.push(id);
            }
            this.$emit("update-files", selectedFiles);
        },
        removeFile(id) {
            let selectedFiles = this.selectedFiles || [];
            if (selectedFiles.includes(id)) {
                selectedFiles = selectedFiles.filter(f => f !== id);
            }
            this.$emit("update-files", selectedFiles);
        },
    },
};
</script>
