<template>
    <div class="text-input" :class="{ 'mb-4': !inline }">
        <label v-if="label" :for="id" class="label">{{ label }}</label>
        <div class="p-relative">
            <input
                ref="input"
                v-input-loader="{ placeholder, isLoading, showIndicator }"
                :id="id"
                v-model="internalValue"
                :type="type"
                :placeholder="placeholder"
                class="base-input"
                :class="[{ disabled: disabled }, ` ${iclass} `, { 'mb-0': inline }]"
                :disabled="disabled"
                @blur="$emit('blur', $event)"
                @focus="$emit('focus', $event)"
                @enter="$emit('enter', $event)"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)" />
            <loading-spinner class="p-absolute right top o-5 bottom right-5" v-if="isLoading" small></loading-spinner>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";

export default {
    components: { LoadingSpinner },
    props: {
        id: String,
        label: String,
        modelValue: {
            type: [String, Number],
            default: "",
        },
        showIndicator: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        iclass: {
            type: String,
            default: "",
        },
        inline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "text",
        },
        placeholder: {
            type: String,
            default: "Type something ...",
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    },
    emits: ["update:modelValue", "enter", "blur", "focus", "input", "change"],
};
</script>
