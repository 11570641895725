<template>
    <div class="mw-500 mx-auto">
      <p class="fwb mb-1" v-if="title">{{title}}</p>
        <div v-for="conversation in discussion" :key="conversation" >
            <div v-for="message in conversation.messages" :key="message">
                <div v-if="message?.from" class="f aic jcs">
                    <div class="c-30 f-40 overflow-hidden bg-blue-500 mr-3 f aic jcc" :class="pickAvatar(message.from).color">{{ pickAvatar(message.from).emoji }}</div>
                    <div>
                        <div class="fwb f-13">
                            <span v-if="pickAvatar(message.from).name">{{ pickAvatar(message.from).name }}</span>
                            <br />
                            {{ message.from }}
                        </div>
                        <div class="whitespace-pre-line f-15">
                            <p v-if="message.content">
                                <span v-if="message.to" class="fwb text-purple-700">@{{ pickAvatar(message.to).name }}</span>
                                {{ message.content }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TeamDiscussions",
    props: {
        discussion: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
            default: "Team Discussions",
        },
    },
    methods: {
        pickAvatar(role) {
            switch (role) {
                case "Entire Team":
                    return { color: "bg-blue-300", emoji: "👥", name: "Entire Team" };
                case "Content Strategist":
                    return { color: "bg-green-300", emoji: "👨‍💼", name: "Billy" }; // Male emoji for Billy
                case "Copywriter":
                    return { color: "bg-yellow-300", emoji: "👩‍💼", name: "Sarah" }; // Female emoji for Sarah
                case "Creative Director":
                    return { color: "bg-red-300", emoji: "👩‍🎨", name: "Jane" }; // Female emoji for Jane
                case "Chief Marketing Officer":
                    return { color: "bg-pink-300", emoji: "👨‍💼", name: "John" }; // Male emoji for John
                case "CEO":
                    return { color: "bg-purple-300", emoji: "👨‍💼", name: "Alex" }; // Male emoji for Alex
                case "CMO":
                    return { color: "bg-sky-300", emoji: "👨‍💼", name: "John" }; // Male emoji for Alexanderx
                case "Brand Manager":
                    return { color: "bg-teal-300", emoji: "👩‍💼", name: "Maddie" }; // Female emoji for Maddie
                case "PM":
                    return { color: "bg-indigo-300", emoji: "👨‍💼", name: "Taylor" }; // Assuming Taylor is male
                case "Direct-Response Advisor":
                    return { color: "bg-orange-300", emoji: "👨‍💼", name: "Craig" }; // Male emoji for Craig
                case "Copywriter":
                    return { color: "bg-pink-300", emoji: "👨‍💼", name: "Christy" }; // Male emoji for Craig
                default:
                    return { color: "bg-gray-300", emoji: "❓", name: "Unknown" };
            }
        },
    },
};
</script>
