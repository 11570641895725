import { db } from "@/firebase";
import chroma from "chroma-js";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query } from "firebase/firestore";
import { isEqual } from "lodash";

import removeFromFirebase from "@/mixins/firebase/deleteFromFirebase.js";
import saveFirebase from "@/mixins/firebase/saveToFirebase.js";
import { updateDropdownValue, updateToggleValue } from "@/store/StoreHelpers";

import webRequest from "@/mixins/ai/web_request";
import { updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
import imageStoreStyleGuide from "@/store/ImageStoreStyleGuide";
import LLMStore from "@/store/LLMStore";
import { findBrand } from "@/mixins/ai/find_brand";
import { logFetch } from "@/mixins/firebase/firebaseHelpers";

const findChangedKeys = (obj1, obj2) => {
    let changedKeys = [];
    for (const key in obj1) {
        if (!isEqual(obj1[key], obj2[key])) changedKeys.push(key);
    }
    for (const key in obj2) {
        if (!obj1.hasOwnProperty(key)) changedKeys.push(key);
    }
    return changedKeys;
};
function cleanStyleGuide(styleGuide) {
    delete styleGuide["link_styles"];
    delete styleGuide["linkStyles"];
    delete styleGuide["button_styles"];
    if (styleGuide?.typography?.p_styles) delete styleGuide["typography"]["p_styles"];
    if (styleGuide?.typography?.header_styles) delete styleGuide["typography"]["header_styles"];
    return styleGuide;
}
function modifyAltText(altText, caption) {
    if (caption) {
        return altText
            .replace(/[-–—]/g, "")
            .replace(/\n/g, " ")
            .split(" ")
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(" ")
            .trim();
    } else {
        return altText
            .replace(/[-–—]/g, "")
            .replace(/\n/g, " ")
            .split(" ")
            .filter((value, index, self) => self.indexOf(value) === index)
            .slice(0, 15)
            .join(" ")
            .trim();
    }
}

function cdnLinkFixer(styleGuide) {
    if (styleGuide?.fonts?.length > 0) {
        styleGuide.fonts = styleGuide.fonts.map(font => {
            if (font?.url && font?.url.includes("brandblocks.co") && !font?.url.includes("cdn")) {
                let oldURL = "brandblocks.co";
                let newURL = "cdn.brandblocks.co";
                let fontURL = font.url;
                font.url = fontURL.replace(oldURL, newURL);
                // font.src = `url('${font.url}')`;
                return { ...font };
            } else {
                return font;
            }
        }); // styleGuide.typography.fonts = fixedFonts;
    }
    return styleGuide;
}
async function getBrandImages(brandId) {
    try {
        const brandRef = db.collection("brands").doc(brandId); // Assuming db is your firestore instance
        const CollectionRef = brandRef.collection("images");
        const snapshot = await CollectionRef.get();
        if (snapshot.empty) return [];
        return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error("Error fetching brand images:", error);
        return []; // Return an empty array or handle the error as needed
    }
}
async function getSubCollection(id, collectionName) {
    logFetch(`${collectionName}`, id);
    try {
        const brandRef = db.collection("brands").doc(id); // Assuming db is your firestore instance
        const collectionRef = brandRef.collection(collectionName);
        const snapshot = await collectionRef.get();
        if (snapshot.empty) return [];

        let items = [];
        for (let doc of snapshot.docs) {
            // const docSnapshot = await doc.ref.get();
            const docSnapshot = doc.data();
            items.push({
                created: doc.createTime ? doc.createTime.toDate() : null,
                updated: doc.updateTime ? doc.updateTime.toDate() : null,
                id: doc.id,
                ...docSnapshot,
            });
        }
        // logGroup(collectionName, items);
        return items;
    } catch (error) {
        console.error("Error fetching brand images:", error);
        return []; // Return an empty array or handle the error as needed
    }
}
// async function getSubCollection(id, collectionName) {
//     logFetch(`${collectionName}`, id);
//     try {
//         const brandRef = db.collection("brands").doc(id); // Assuming db is your firestore instance
//         const CollectionRef = brandRef.collection(collectionName);
//         const snapshot = await CollectionRef.get();
//
//         if (snapshot.empty) return [];
//         let items = snapshot.docs.map(doc => ({
//             //
//             id: doc.id,
//             ...doc.data(),
//         }));
//         logGroup(collectionName, items);
//         return items;
//     } catch (error) {
//         console.error("Error fetching brand images:", error);
//         return []; // Return an empty array or handle the error as needed
//     }
// }

export default {
    namespaced: true,
    modules: { image: imageStoreStyleGuide, llm: LLMStore },
    state() {
        return {
            sortColors: "",
            combinedImages: [],
            progress: {},
            sources: [],
            css: {},
            products: [],
            currentProduct: null,
            categories: [],
            collections: [],
            styleGuide: {
                ai: {},
                loading: false,
                id: null,
                image: null,
                name: null,
                url: null,
                websiteUrl: null,
                updated: null,
                colors: { bg_colors: [], text_colors: [], button_colors: [] },
                backgroundColors: [],
                textColors: [],
                buttonColors: [],
                paragraphStyles: [],
                textStyles: [],
                allTextStyles: [],
                buttonStyles: [],
                email: [],
                divStyles: [],
                logos: [],
                images: [],
                typography: { fonts: [] },
                textSamples: { headers: { h1: [], h2: [], h3: [], h4: [], h5: [], h6: [] }, paragraphs: [], spans: [], links: [], buttons: [], divs: [], emailSubjects: [], emailPreviews: [] },
                deletedImageURL: [],
                mergedImages: false,
            },
            styleGuides: [],
        };
    },
    mutations: {
        updateToggleValue,
        updateDropdownValue,
        updateSortColors(state, sortColors) {
            state.sortColors = sortColors;
        },

        SET_CATEGORIES(state, payload) {
            state.categories = payload;
        },
        setStyleGuide(state, payload) {
            if (payload) state.styleGuide = payload;
        },
        updateProp(state, { keyPath, value }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");

            function setValue(obj, keys, value) {
                if (!obj) return; // Exit if the object is not defined
                if (keys.length === 1) {
                    obj[keys[0]] = value;
                    return;
                }
                if (!obj[keys[0]]) obj[keys[0]] = {}; // Create new key if it doesn't exist
                setValue(obj[keys[0]], keys.slice(1), value);
            }
            setValue(state, keys, value);
        },
        updateStyleGuideProp(state, { keyPath, value }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");

            function setValue(obj, keys, value) {
                if (keys.length === 1) {
                    obj[keys[0]] = value;
                    return;
                }
                setValue(obj[keys[0]], keys.slice(1), value);
            }

            setValue(state.styleGuide, keys, value);
        },
        // if it's an arrsy if (Array.isArray(value)) { obj[keys[0]] = [...obj[keys[0]], ...value]; // return; } else if (typeof value === "object") { obj[keys[0]] = { ...obj[keys[0]], ...value }; } else { obj[keys[0]] = value; }
        updateHeaderImage(state, image) {
            state.headerImage = image;
        },
        addStyleGuideImage(state, image) {
            state.styleGuide.images = state.styleGuide.images || [];
            state.styleGuide.images.push(image);
        },
        setStyleGuideList(state, styleGuideList) {
            state.styleGuides = styleGuideList;
        },
        addStyleGuide(state, payload) {
            state.styleGuides.shift(payload);
        },
        deleteStyleGuide(state, payload) {
            state.styleGuides = state.styleGuides.filter(styleGuide => styleGuide.id !== payload);
        },
        deleteStyleGuideImage(state, image) {
            // Find and remove from styleGuide.images
            let imagesIndex;
            if (state.styleGuide?.images) {
                imagesIndex = state.styleGuide.images.findIndex(img => img.url === image.url);
                if (imagesIndex !== -1) {
                    let updatedImages = [...state.styleGuide.images];
                    updatedImages.splice(imagesIndex, 1);
                    state.styleGuide.images = updatedImages;
                    return;
                }
            }
            // Find and remove from styleGuide.logos
            let logosIndex = state.styleGuide.logos.findIndex(logo => logo.url === image.url);
            if (logosIndex !== -1) {
                let updatedLogos = [...state.styleGuide.logos];
                updatedLogos.splice(logosIndex, 1);
                state.styleGuide.logos = updatedLogos;
                return;
            }
        },
        UPDATE_LOGO(state, payload) {
            let logoIndex = state.styleGuide.logos.findIndex(logo => logo.url === payload.oldUrl);
            if (logoIndex !== -1) state.styleGuide.logos[logoIndex].url = payload.newUrl;
        },
        deleteSVG(state, svg) {
            const svgIndex = state.styleGuide.svgs.indexOf(svg);
            if (svgIndex !== -1) state.styleGuide.svgs.splice(svgIndex, 1);
        },
        deleteButton(state, button) {
            const buttonIndex = state.styleGuide.buttonStyles.indexOf(button);
            if (buttonIndex !== -1) state.styleGuide.buttonStyles.splice(buttonIndex, 1);
        },
        REPLACE_IMAGE(state, { originalIndex, image }) {
            if (originalIndex !== -1) {
                let originalImage = state.combinedImages[originalIndex];
                image = { ...state.combinedImages[originalIndex], ...image };
                console.log(image);
                console.log(originalImage);
                state.combinedImages.splice(originalIndex, 1, image);
            } else console.error("Original image index not found.");
        },
        SET_TEXT_SAMPLES(state, payload) {
            state.styleGuide.textSamples = payload;
        },
        ADD_TO_LOGOS(state, payload) {
            // add it to the begginnig of the array
            state.styleGuide.logos = [payload, ...state.styleGuide.logos];
        },
        REMOVE_FROM_LOGOS(state, payload) {
            state.styleGuide.logos = state.styleGuide.logos.filter(logo => logo.url !== payload);
        },
        updateImagesState(state, { url, image }) {
            const index = state.combinedImages.findIndex(img => img.url === url);
            if (index !== -1) {
                state.combinedImages[index] = { ...state.combinedImages[index], ...image };
            }
        },

        removeImageFromState(state, url) {
            state.combinedImages = state.combinedImages.filter(image => image.url !== url);
        },
        SET_PRODUCTS(state, payload) {
            state.products = payload;
        },
        DELETE_IMAGE_NEW(state, url) {
            state.combinedImages = state.combinedImages.filter(img => img.url !== url);
            console.log("Image deleted successfully");
        },
        MAKE_LOGO(state, url) {
            const index = state.styleGuide.logos.findIndex(img => img.url === url);
            if (index > -1) {
                const image = state.styleGuide.logos.splice(index, 1)[0];
                state.styleGuide.logos.unshift(image);
            }
        },
        SET_SOURCES(state, payload) {
            state.sources = payload;
        },
        DELETE_PRODUCT(state, handle) {
            state.products = state.products.filter(product => product.handle !== handle);
        },
        DELETE_SOURCE(state, id) {
            state.sources = state.sources.filter(source => source.id !== id);
        },
        SET_COLLECTIONS(state, payload) {
            state.collections = payload;
        },
    },
    actions: {
        updateProp({ commit, state, dispatch }, { keyPath, value, stream }) {
            commit("updateProp", { keyPath, value });
        },
        async getCategories({ commit, state, rootState }, user) {
            // commit("SET_CATEGORIES", []);
            let response = await webRequest("getCategories", user);
            let { categories } = await response.json();
            commit("SET_CATEGORIES", categories);
        },
        async getSources({ state, commit, dispatch }) {
            commit("SET_SOURCES", []);
            let data = await getSubCollection(state.styleGuide.id, "sources");
            commit("SET_SOURCES", data);
        },
        async getProducts({ state, commit, dispatch }) {
            commit("SET_PRODUCTS", []);
            let data = await getSubCollection(state.styleGuide.id, "products");
            commit("SET_PRODUCTS", data);
        },
        async getCollections({ state, commit, dispatch }) {
            commit("SET_COLLECTIONS", []);
            let data = await getSubCollection(state.styleGuide.id, "collections");
            if (data.length > 0) {
                dispatch("updateStyleGuideProp", { keyPath: "collections", value: null });
            }
            commit("SET_COLLECTIONS", data);
        },
        async saveToSubCollection(brandId, collectionName, dataToSave) {
            try {
                const brandRef = db.collection("brands").doc(brandId); // Assuming $firestore is injected into Vue instance
                const collectionRef = brandRef.collection(collectionName);

                // Add a new document with a generated id
                const docRef = await collectionRef.add(dataToSave);

                // Optionally, return the id of the newly created document
                return docRef.id;
            } catch (error) {
                console.error("Error saving to the subcollection:", error);
                // Handle the error as needed, perhaps returning null or false
                return null;
            }
        },

        async saveArrayToSubCollection({ commit }, { id, name, items }) {
            try {
                const collectionRef = collection(db, `brands/${id}/${name}`);

                for (let item of items) await addDoc(collectionRef, item);

                // Commit a mutation or dispatch another action as needed
                // For example: commit('someMutation', data);

                // Optionally, return a success message or handle post-save logic
                return "Items saved successfully";
            } catch (error) {
                console.error("Error saving items to the subcollection:", error);
                // Handle the error as needed, perhaps committing an error mutation
                // For example: commit('someErrorMutation', error);

                return null;
            }
        },
        makeLogo({ commit, dispatch }, url) {
            commit("MAKE_LOGO", url);
            dispatch("saveStyleGuide");
        },
        updateTextSamples({ commit }, payload) {
            commit("SET_TEXT_SAMPLES", payload);
        },
        async deleteImage({ commit, state, rootState, dispatch }, image) {
            commit("deleteStyleGuideImage", image);
            let styleGuideImagesIndex;
            if (state.styleGuide?.images) {
                styleGuideImagesIndex = state.styleGuide?.images.findIndex(img => img.url === image.url) || -1;
                if (styleGuideImagesIndex !== -1) commit("deleteStyleGuideImage", image);
            }

            let logoIndex = state.styleGuide.logos.findIndex(img => img.url === image.url);
            if (logoIndex !== -1) commit("REMOVE_FROM_LOGOS", image.url);

            dispatch("saveStyleGuide");
        },
        deleteSVG({ commit, state, rootState, dispatch }, svg) {
            commit("deleteSVG", svg);
            dispatch("saveStyleGuide");
        },
        deleteButton({ commit, state, rootState, dispatch }, button) {
            commit("deleteButton", button);
            dispatch("saveStyleGuide");
        },
        async deleteStyleGuide({ commit, state, rootState, dispatch }, styleGuide) {
            // if (!styleGuide) styleGuide = state.styleGuide;
            if (!styleGuide) {
                console.log("No styleGuide to delete");
                return;
            }
            let { id, url, name, match } = styleGuide;
            match = match || id;
            try {
                // Direct reference to the document using the handle as the doc ID
                const docRef = doc(db, `brands`, id);
                const docRef1 = doc(db, `brandsList`, match);
                await deleteDoc(docRef);
                await deleteDoc(docRef1);
                // remove it from the products array above
                commit("deleteStyleGuide", id);
                console.log("Deleted StyleGuide", name);
            } catch (error) {
                console.error("Error deleting styleGuide", error);
            }
            // let foundBrand = await findBrand(id, url, name);
            // console.log("Found Brand to delete", foundBrand);
            // await removeFromFirebase("brandsList", id);
            // await removeFromFirebase("brandsList", foundBrand.match);
            // await removeFromFirebase("brandsList", foundBrand.match);
        },
        setGuideList({ commit, state, rootState, dispatch }, styleGuideList) {
            // sort styleGuidesList by updated
            styleGuideList = styleGuideList.sort((a, b) => b.updated - a.updated);
            commit("setStyleGuideList", styleGuideList);
        },
        addStyleGuideImage({ commit, state, rootState, dispatch }, image) {
            try {
                commit("addStyleGuideImage", image);
            } catch (e) {
                console.error(e);
            }
        },
        async updateStyleGuideProp({ commit, state, rootState, dispatch }, prop) {
            commit("updateStyleGuideProp", prop);
            if (state.styleGuide.id && prop.save) await dispatch("saveStyleGuide", state.styleGuide);
        },
        async saveStyleGuide({ commit, state, rootState, dispatch }, styleGuide) {
            // return;
            if (!styleGuide || typeof styleGuide !== "object" || Object.keys(styleGuide).length === 0) {
                console.warn("Invalid styleGuide object provided. Falling back to state.styleGuide.");
                if (state.styleGuide) styleGuide = state.styleGuide;
                else return;
            }

            if (!styleGuide) styleGuide = state.styleGuide;

            let id = styleGuide.id || null;
            styleGuide = cleanStyleGuide(styleGuide);
            try {
                const dataObj = await saveFirebase("brands", { ...styleGuide }, id);
                if (!state.styleGuide.id) commit("updateStyleGuideProp", { keyPath: "id", value: dataObj.id });
                const changedKeys = findChangedKeys(state.styleGuide, styleGuide);
                console.groupCollapsed(`%c Saved StyleGuide`, purple, changedKeys.length);
                console.log("Updated keys:", changedKeys.join(","));
                console.log(dataObj);
                changedKeys.forEach(key => {
                    console.groupCollapsed(key);
                    console.log("Old:", state.styleGuide[key]);
                    console.log("New:", styleGuide[key]);
                    console.groupEnd();
                });
                // purge undefined keys from the object
                Object.keys(dataObj).forEach(key => {
                    if (dataObj[key] === undefined) {
                        delete dataObj[key];
                    }
                });
                console.groupCollapsed("Trace");
                console.trace();
                console.log(dataObj);
                console.groupEnd();
                console.groupEnd();

                return dataObj;
            } catch (error) {
                console.error("An error occurred while saving to Firebase:", error);
                throw error;
            }
        },
        updateSortColors({ commit }, sortColors) {
            commit("updateSortColors", sortColors);
        },
        setStyleGuide({ commit, dispatch }, styleGuide) {
            if (!styleGuide) return;

            const keysMap = {
                images: "images",
                pageTitle: "pageTitle",
                websiteUrl: "url",
                url: "websiteUrl",
                colors: "colors",
                bg_colors: "backgroundColors",
                pStyles: "paragraphStyles",
                text_styles: "textStyles",
                bg_button_colors: "buttonColors",
                visual_pallette: "visualPallette",
                allTextStyles: "allTextStyles",
            };

            Object.keys(styleGuide).forEach(key => {
                if (styleGuide[key] !== undefined && keysMap[key]) styleGuide[keysMap[key]] = styleGuide[key];
            });
            commit("setStyleGuide", styleGuide);
        },

        updateHeaderImage({ commit, state, rootState, dispatch }, image) {
            commit("updateHeaderImage", image);
        },
        addStyleGuide({ commit, state, rootState, dispatch }, payload) {
            commit("addStyleGuide", payload);
        },
        async fetchStyleGuide({ commit, state, rootState, dispatch }, { id, database }) {
            console.log("fetching from firebase");
            let newId;
            if (id) {
                // use the database name and id to fetch an item from the database
                const docRef = doc(db, database, id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    let styleGuide = docSnap.data();
                    let { fonts = [] } = styleGuide;
                    // styleGuide.fonts
                    if (fonts?.length > 0) styleGuide = cdnLinkFixer(styleGuide);
                    console.log("styleGuide", styleGuide);
                    commit("setStyleGuide", styleGuide);
                    dispatch("getSources");
                    dispatch("getCollections");
                    dispatch("getProducts");
                    await dispatch("image/getImages", id);
                    commit("updateStyleGuideProp", { keyPath: "fetching", value: false });
                    commit("updateStyleGuideProp", { keyPath: "loading", value: false });
                    await dispatch("updateBrandList", {});
                    document.title = `${styleGuide.name} | Brand Style Guide  | BrandBlocks`;
                    return styleGuide;
                } else console.log("No such document!");
            } else {
                // If no ID is provided, fetch the entire collection
                const querySnapshot = await getDocs(query(collection(db, database)));
                let documents = querySnapshot.docs.map(doc => {
                    // Create a new object with both the id and the data
                    let data = doc.data();
                    data.id = doc.id;
                    return data;
                });
                console.log(documents);
                // set style guide
                commit("setStyleGuide", documents);
                return documents;
            }
        },
        async deleteProduct({ state, commit }, handle) {
            let { id = "" } = state.styleGuide;
            try {
                // Direct reference to the document using the handle as the doc ID
                const docRef = doc(db, `brands/${id}/products`, handle);
                await deleteDoc(docRef);
                // remove it from the products array above
                commit("DELETE_PRODUCT", handle);
            } catch (error) {
                console.error("Error deleting product", error);
            }
        },
        async deleteSource({ state, commit }, id) {
            let { id: brandID = "" } = state.styleGuide;
            try {
                // Direct reference to the document using the handle as the doc ID
                const docRef = doc(db, `brands/${brandID}/sources`, id);
                await deleteDoc(docRef);
                // remove it from the products array above
                commit("DELETE_SOURCE", id);
            } catch (error) {
                console.error("Error deleting product", error);
            }
        },
        async markAsLogo({ commit, state, rootState, dispatch }, image) {
            const originalImageIndex = rootState.styleGuide.image.images.findIndex(i => i.url === image.url);
            if (originalImageIndex === -1) {
                console.error("Image not found in styleGuide images.");
                return;
            }

            let currentImage = { ...rootState.styleGuide.image.images[originalImageIndex] };

            if (currentImage.kind === "logo") {
                console.log("Already a logo!");
                commit("REMOVE_FROM_LOGOS", currentImage);
                currentImage.kind = "image";
            } else {
                console.log("Is an image");
                currentImage.kind = "logo";
                commit("ADD_TO_LOGOS", currentImage);
                // commit("image/REPLACE_IMAGE", { originalIndex: originalImageIndex, image: currentImage }, { root: true });
            }
            if (state.styleGuide.id) {
                try {
                    await dispatch("saveStyleGuide", state.styleGuide);
                } catch (error) {
                    console.error("Error in saveStyleGuide dispatch:", error);
                }
            }
        },
        async clearImages({ state, commit, dispatch }) {
            const id = state.styleGuide.id;
            try {
                // const subcollectionPath = `brands/${id}/images`;
                // const subcollectionRef = collection(db, subcollectionPath);
                // const querySnapshot = await getDocs(subcollectionRef);

                // const deletePromises = [];
                // querySnapshot.forEach(doc => {
                //     deletePromises.push(deleteDoc(doc.ref));
                // });
                await webRequest("getStyleGuide", { url: state.styleGuide.url, id, sections: ["clearImages"] });
                dispatch("updateStyleGuideProp", { keyPath: "images", value: [], save: true });
                d;
                // return Promise.all(deletePromises);
            } catch (e) {
                dispatch("updateStyleGuideProp", { keyPath: "images", value: [], save: true });
            }
        },
        async clearSubcollection({ state }, { id, collectionPath = "images" }) {
            if (!id) id = state.styleGuide.id;
            const subcollectionPath = `${collectionPath}/${id}/images`;
            const subcollectionRef = collection(db, subcollectionPath);
            const querySnapshot = await getDocs(subcollectionRef);
            const deletePromises = [];

            querySnapshot.forEach(doc => {
                deletePromises.push(deleteDoc(doc.ref));
            });

            return Promise.all(deletePromises);
        },
        async updateBrandList({ state, getters, dispatch }, updates) {
            let image = getters.brandImage;
            let styleGuide = state.styleGuide;
            let originalURL = state.styleGuide?.url;
            let brand = {};
            let styleGuideID = state.styleGuide?.id;
            if (!styleGuideID) return;
            brand = await findBrand(styleGuideID, originalURL, styleGuide.name);

            if (brand?.id) {
                logGroup("BrandList Object", { ...brand, styleGuideId: styleGuideID });
                let brandListObject = { ...brand, id: brand.id, name: brand.name || "", categories: brand.categories || [], updated: new Date(), url: brand.url, match: styleGuideID };
                let updatedObject = {
                    image: deepCopy(image),
                    divStyles: deepCopy(getters.divStyles?.[0]) || null,
                };
                let { url, categories, name, description } = updates;
                if (url) updatedObject.url = url;
                if (categories) {
                    if (typeof categories === "string") {
                        categories = categories.split(",") || [];
                    }
                    updatedObject.categories = categories;
                    if (categories) dispatch("updateStyleGuideProp", { keyPath: "categories", value: categories });
                }
                if (name) updatedObject.name = name;
                if (description) updatedObject.description = description;

                let combinedObject = { ...brandListObject, ...updatedObject };
                let updatedOnServer = await updateFirebaseObject("brandsList", combinedObject, brand.id);
                if (description) dispatch("updateStyleGuideProp", { keyPath: "description", value: description });
                if (name) dispatch("updateStyleGuideProp", { keyPath: "name", value: name });
                if (url) dispatch("updateStyleGuideProp", { keyPath: "url", value: url });
                if (brand.id && !state.styleGuide.match) dispatch("updateStyleGuideProp", { keyPath: "match", value: brand.id });
                if (categories || name || url || description) dispatch("updateStyleGuideProp", { keyPath: "fetching", value: false, save: true });
                // await this.objectUpdates();
                // this.updateStyleGuideProp("match", brand.id);
            } else {
                console.log("Failed to update Brandlist", fail, state.styleGuide.url);
                logGroup("BrandList Object", { ...brand, styleGuideId: styleGuideID });
            }
        },
        fixRGBA({ commit }, color) {
            if (color.includes("#")) return color;
            try {
                let rgba = color.split("(")[1].split(")")[0].split(",");
                let r = parseInt(rgba[0]);
                let g = parseInt(rgba[1]);
                let b = parseInt(rgba[2]);
                let a = rgba[3] ? parseFloat(rgba[3]) : 1; // Ensure alpha defaults to 1 if not provided
                color = chroma(r, g, b, a).hex();
                return color;
            } catch (e) {
                console.error("Error fixing RGBA", e.message);
                return color; // Return the original input if an error occurs
            }
        },
        getContrastColor({ commit, dispatch, state }, { color, array, contrastThreshold = 3 }) {
            try {
                logGroup("Get Contrast Color", { color, array, contrastThreshold });
                if (color.includes("rgba")) color = dispatch("fixRGBA", color);
                let hexValue = color.includes("#") ? color : chroma(color).hex();
                let allColors = state.styleGuide?.colors?.all_colors || [];
                let colorsArray = [...allColors];
                if (array?.length > 0) colorsArray = [...colorsArray, ...array];

                let contrastArr = colorsArray.map(color => {
                    color = color.replace("!important", "").replace(";", "");
                    let contrast = chroma.contrast(hexValue, color);
                    return { color, contrast };
                });

                contrastArr.sort((a, b) => b.contrast - a.contrast);
                const highContrastArr = contrastArr.filter(colorObj => colorObj.contrast >= contrastThreshold);

                if (highContrastArr.length > 0) {
                    return highContrastArr[Math.floor(Math.random() * highContrastArr.length)].color;
                } else {
                    return "";
                }
            } catch (e) {
                console.error("Error getting contrast color", e.message);
                reject(e); // Reject the promise on error
            }
        },
    },
    getters: {
        currentProduct: state => state.currentProduct,
        productCount(state) {
            let guideProducts = state.styleGuide.products || [];
            let serverProducts = state.products || [];
            let allProducts = [];
            if (guideProducts?.length > 0) allProducts = [...allProducts, ...guideProducts];
            if (serverProducts?.length > 0) allProducts = [...allProducts, ...serverProducts];
            return allProducts.length;
        },
        guideLoading: state => state.styleGuide.loading,
        styleGuideId: state => state.styleGuide.id,
        styleGuide: state => state?.styleGuide,
        headerImage: state => state?.headerImage,
        buttonStyles: state => state.styleGuide?.buttonStyles || [],
        colors: state => state.styleGuide.colors || [],
        name: state => state.styleGuide?.name || "",
        typography: state => state.styleGuide?.typography || {},
        productCopy: state => state.styleGuide?.textSamples?.productCopy,
        sortColors: state => state.sortColors,
        textSamples: state => state.styleGuide?.textSamples,
        backgroundColors: state => state.styleGuide?.colors?.bg_colors,
        baseColor: state => state.styleGuide?.base?.color,
        website: state => state.styleGuide.websiteUrl || state.styleGuide.url || "",
        allTextStyles: state => state.styleGuide.allTextStyles || [],
        deduplicatedText: state => state.styleGuide?.deduplicatedText || [],
        assignedStyles: state => state.styleGuide?.assignedStyles || [],
        videos: state => state.styleGuide?.typography?.videos,
        svgs: state => state.styleGuide?.svgs,
        copyGuidelines: state => state.styleGuide.copyGuidelines,
        textStyles: state => state.styleGuide?.textStyles,
        products: state => {
            let guideProducts = state.styleGuide.products || [];
            let serverProducts = state.products || [];
            let allProducts = [];
            if (guideProducts?.length > 0) allProducts = [...allProducts, ...guideProducts];
            if (serverProducts?.length > 0) allProducts = [...allProducts, ...serverProducts];
            return allProducts;
        },
        brandImage: (state, getters, rootState, rootGetters) => {
            let guideImages = state.styleGuide.images || [];
            let combinedImages = rootGetters["styleGuide/image/images"] || [];
            let allImages = [];
            if (guideImages) allImages = [...guideImages];
            if (combinedImages) allImages = [...allImages, ...combinedImages];
            // logGroup("Brand Image", { allImages });
            if (allImages.length > 0) {
                const { url, color, backgroundColor, bottomColor } = allImages[0];
                let image = { url: url || null, color: color || "", backgroundColor: backgroundColor || "", bottomColor: bottomColor || "" };
                if (url) return image;
            } else return null;
        },
        styleGuides: state => {
            return state.styleGuides.sort((a, b) => {
                if ((a.image && b.image) || (!a.image && !b.image)) return new Date(b.updated) - new Date(a.updated);
                else if (a.image && !b.image) return -1;
                else return 1;
            });
        },
        // typographyCombos: state => state.styleGuide?.typographyCombos || [],
        divStyles(state) {
            if (!state.styleGuide.divStyles) return [];
            let gradients = [],
                solids = [];
            for (const style of state.styleGuide.divStyles) {
                const background = style.backgroundColor || "";
                if (background.includes("gradient")) {
                    gradients.push(style);
                } else {
                    solids.push(style);
                }
            }
            function fixRGBA(color) {
                if (color.includes("#")) return color;
                try {
                    let rgba = color.split("(")[1].split(")")[0].split(",");
                    let r = rgba[0];
                    let g = rgba[1];
                    let b = rgba[2];
                    let a = rgba[3];
                    if (a) a = parseFloat(a);
                    color = chroma([r, g, b]).alpha(a).hex();
                    console.log(color);
                    // .alpha(a).hex();
                    return color;
                } catch (e) {
                    console.log(color, e);
                    return color;
                }
            }
            solids.sort((a, b) => {
                // solids = solids.sort((a, b) => {
                let colorA = "white";
                let colorB = "white";
                if (a?.backgroundColor) colorA = fixRGBA(a.backgroundColor);
                if (b?.backgroundColor) colorB = fixRGBA(b.backgroundColor);
                // make sure rgba is converted to hex

                try {
                    colorA = chroma(colorA).get("hsl.l") || 1;
                } catch (e) {
                    console.log(colorA);
                }
                try {
                    colorB = chroma(colorB).get("hsl.l") || 1;
                } catch (e) {
                    console.log(colorB);
                }
                return colorA - colorB;
            });

            return [...solids, ...gradients];
        },
        additionalText: state => {
            if (!state.styleGuide?.additionalText) return { urls: [], content: "" };

            const data = state.styleGuide.additionalText;

            function removeMarkdown(inputText) {
                const regexes = [/\*\*(.*?)\*\*/g, /\*(.*?)\*/g, /\#{1,6}\s*(.*?)$/gm, /\-(.*?)(\n|$)/g]; // Bold// Italics // Headers with 1-6 '#' characters // List items
                let cleanText = inputText;
                regexes.forEach(regex => (cleanText = cleanText.replace(regex, "$1")));
                return cleanText.trim();
            }

            const urls = [];
            const contentSets = new Set();

            data.forEach(item => {
                urls.push(item.url);

                let cleanContentArray = item.content
                    .split("\n")
                    .map(line => removeMarkdown(line.trim()))
                    .filter(Boolean);

                cleanContentArray.forEach(line => {
                    line = line.split("cookies")[0];
                    line = removeMarkdown(line);
                    contentSets.add(line);
                });
            });
            const content = Array.from(contentSets).join("\n");
            return { urls, content };
        },
        typographyCombos: state => {
            // function modifyFontFamily(fontFamily) {
            //     if (typeof fontFamily === "string" && fontFamily.trim() !== "") {
            //         const fontFamilyParts = fontFamily.split(",");
            //         const primaryFont = fontFamilyParts[0];
            //
            //         if (primaryFont.includes("-")) {
            //             const modifiedPrimaryFont = primaryFont.split("-")[0];
            //             return `${primaryFont}, ${modifiedPrimaryFont}, ${fontFamilyParts.slice(1).join(",")}`;
            //         }
            //     }
            //
            //     return fontFamily || "sans-serif";
            // }
            const combos = state.styleGuide?.typographyCombos || [];
            return combos.map(combo => {
                return {
                    ...combo,
                    style: {
                        header: {
                            ...combo.style.header, //
                            fontFamily: combo.style.header.fontFamily,
                        },
                        p: { ...combo.style.p, fontFamily: combo.style.p.fontFamily },
                    },
                };
            });
        },
        typefaceCombos: state => {
            if (!state.styleGuide?.typographyCombos) return [];

            const uniqueCombos = new Map();

            const filterKeys = obj => {
                Object.keys(obj).forEach(key => {
                    if (obj[key] === undefined || obj[key] === "none" || obj[key] === "normal") delete obj[key];
                });
                return obj;
            };

            const fontCombos = state.styleGuide.typographyCombos.map(combo => {
                let header = { fontFamily: combo.style.header.fontFamily, fontWeight: combo.style.header.fontWeight, fontStyle: combo.style.header.fontStyle, textTransform: combo.style.header.textTransform };
                let body = { fontFamily: combo.style.p.fontFamily, fontWeight: combo.style.p.fontWeight, fontStyle: combo.style.p.fontStyle, textTransform: combo.style.p.textTransform };

                header = filterKeys(header);
                body = filterKeys(body);

                const headerString = JSON.stringify(header);

                if (!uniqueCombos.has(headerString)) uniqueCombos.set(headerString, { header, body });
                return { header, body };
            });

            // If you want to return only unique combos
            return Array.from(uniqueCombos.values());
        },
        writingSamples: state => {
            if (state.textSamples) {
                let headers = state.textSamples.headers;
                let { h1 = [], h2 = [], h3 = [], h4 = [], h5 = [], h6 = [] } = headers;
                let allHeaders = [...h1, ...h2, ...h3, ...h4, ...h5, ...h6];
                if (allHeaders?.length > 0) {
                    allHeaders = allHeaders.join("\n");
                    let headerExamples = `Here are examples of headers written in the brand's voice:\n\n${allHeaders}`;
                }
                let subjectLines = state.textSamples?.emailSubjects?.join("\n");
                let subjectExamples;
                if (subjectLines?.length > 0) subjectExamples = `Here are examples of the subject written in the brand's voice:\n\n${subjectLines}`;
                let brandName = `\n\nBrand Name:${state.styleGuide.name}\nWebsite:${state.styleGuide.website}\nDescription:${state.styleGuide.description}\n\n`;
            }
        },

        collectedLinks: getters => {
            if (!getters?.additionalText?.urls) return;
            return getters.additionalText.urls;
        },
        // linkColor: state => {
        //     let colors = state.styleGuide?.colors?.text_colors;
        //     if (!colors || colors.length === 0) return null;
        //
        //     let mostVibrantColor = colors[0];
        //     let highestVibrancy = 0;
        //
        //     colors.forEach(color => {
        //         const hsl = chroma(color).hsl();
        //         const saturation = hsl[1];
        //         const lightness = hsl[2];
        //         const vibrancy = saturation + lightness;
        //
        //         if (vibrancy > highestVibrancy) {
        //             highestVibrancy = vibrancy;
        //             mostVibrantColor = color;
        //         }
        //     });
        //
        //     return mostVibrantColor;
        // },
        linkColor: state => {
            let colors = state.styleGuide?.colors?.text_colors;
            if (!colors || colors.length === 0) return null;

            let mostVibrantColor = colors[0];
            let highestVibrancyScore = 0;
            const excludedColors = ["#0000EE", "#ffffff", "rgb(0, 0, 238)"]; // Add the excluded colors here as an array
            const darknessThreshold = 0.3; // Maximum darkness level allowed, adjust as needed

            colors.forEach(color => {
                let hex = chroma(color).hex();
                if (excludedColors.includes(hex) || color === hex) {
                    // Skip this color
                    return;
                }
                let contrastColor = "white";
                if (state.styleGuide.base.backgroundColor) contrastColor = state.styleGuide.base.backgroundColor;
                const contrastToWhite = chroma.contrast(color, contrastColor);
                const saturation = chroma(color).get("hsl.s");
                const lightness = chroma(color).get("hsl.l"); // Lightness in HSL

                // Avoid colors that are too dark or have high contrast but low saturation
                if (saturation > 0 && lightness > darknessThreshold) {
                    const vibrancyScore = contrastToWhite * saturation;
                    if (vibrancyScore > highestVibrancyScore) {
                        highestVibrancyScore = vibrancyScore;
                        mostVibrantColor = color;
                    }
                }
            });

            return mostVibrantColor;
        },
        sources: state => state.sources,
        // fonts: state => state.styleGuide?.typography?.fonts,
        fonts: state => {
            const fontsArray = state.styleGuide?.fonts;

            if (!fontsArray || !Array.isArray(fontsArray)) return [];

            return fontsArray.filter(Boolean).map(font => {
                let { fontFamily, src, name, style, weight, fontWeight, fontStyle, textTransform = "" } = font;
                if (!font.fontFamily) return;

                fontFamily = fontFamily || name;
                fontWeight = fontWeight || weight;
                fontStyle = fontStyle || style;
                fontFamily = fontFamily.split(",")[0];
                let object = {
                    ...font,
                    fontFamily,
                    fontStyle,
                    fontWeight,
                    name: fontFamily,
                    style: fontStyle,
                    weight: fontWeight,
                };
                if (textTransform) object.textTransform = textTransform;
                return object;
            });
        },
        hasImages(state, getters) {
            let images = state.styleGuide?.images?.length > 0 || getters.combinedImages?.length > 0;
            return Array.isArray(state.styleGuide.images) && state.styleGuide.images.length > 0;
        },
        orderedTextSamples: (state, getters) => {
            if (!getters.textSamples) return {};
            // Combine all text samples into a flat structure
            const { paragraphs, headers, buttons, links, spans, divs, emailSubjects, productCopy, emailPreviews } = getters.textSamples;

            // Create an object with header keys sorted and prepended with 'Header'
            const sortedHeaders = Object.keys(headers)
                .sort((a, b) => parseInt(a.substring(1)) - parseInt(b.substring(1)))
                .reduce((acc, key) => {
                    acc[`Header${key}`] = headers[key];
                    return acc;
                }, {});
            let sections = { ...sortedHeaders, Paragraphs: paragraphs, Buttons: buttons, Links: links, Spans: spans, Divs: divs };
            if (emailSubjects) sections["Email Subjects"] = emailSubjects;
            if (emailPreviews) sections["Email Previews"] = emailPreviews;
            if (productCopy) sections["Product Copy"] = productCopy;

            return sections;
        },
        likelyHeaderCombo(state) {
            if (state.styleGuide.typographyCombos?.length > 0) {
                const combos = state.styleGuide.typographyCombos;
                let sortedCombos = combos.sort((a, b) => {
                    let aHeaderFontSize = +parseInt(a.style.header.fontSize);
                    let bHeaderFontSize = +parseInt(b.style.header.fontSize);

                    let aPFontSize = +parseInt(a.style.p.fontSize);
                    let bPFontSize = +parseInt(b.style.p.fontSize);

                    if (bHeaderFontSize !== aHeaderFontSize) {
                        return bHeaderFontSize - aHeaderFontSize;
                    } else {
                        return bPFontSize - aPFontSize;
                    }
                });

                sortedCombos = sortedCombos
                    .map(combo => {
                        if (combo.style.header !== combo.style.p) {
                            let headerFontSize = +parseInt(combo.style.header.fontSize);
                            let pFontSize = +parseInt(combo.style.p.fontSize);
                            if (headerFontSize >= pFontSize) {
                                return {
                                    // header: { ...combo.style.header, fontFamily: modifyFontFamily(combo.style.header.fontFamily) }, // Assuming modifyFontFamily is accessible
                                    // p: { ...combo.style.p, fontFamily: modifyFontFamily(combo.style.p.fontFamily) },
                                };
                            }
                        }
                    })
                    .filter(combo => combo !== undefined); // Filter out undefined values resulting from the map

                return sortedCombos[0];
            } else {
                return [];
            }
        },
        randomDivStyle(state, getters) {
            try {
                const styles = getters.divStyles || [];
                const buttonStyles = state.styleGuide.buttonStyles || [];
                if (styles && styles.length > 0) {
                    const randomIndex = Math.floor(Math.random() * styles.length);
                    let randomStyle = styles[randomIndex];
                    // randomStyle = JSON.parse(JSON.stringify(randomStyle).replace(/background-color/g, "background"));
                    randomStyle.backgroundColor = randomStyle.backgroundColor || randomStyle.background;
                    delete randomStyle.background;
                    let buttonStyle = {};
                    if (buttonStyles.length > 0) {
                        buttonStyle = buttonStyles[0];
                        let { backgroundColor } = randomStyle;
                        backgroundColor = backgroundColor.replace("!important", "");

                        if (backgroundColor !== randomStyle.backgroundColor) buttonStyle = buttonStyles[0];
                        else buttonStyle = buttonStyles[1];
                    }
                    return { randomStyle, buttonStyle };
                }
            } catch (error) {
                console.error(error); // Log the error
                return { randomStyle: "", buttonStyle: "" };
            }
            // Return default values if the conditions are not met
            return { randomStyle: "", buttonStyle: "" };
        },
        collections(state) {
            let collections = state.styleGuide?.collections || [];
            if (state.collections) {
                collections = state.collections;
            }
            return collections || [];
        },
        stylesheet(state) {
            let fontData = state.styleGuide?.stylesheet || [];
            const dedupedFonts = new Map();
            fontData.forEach(font => {
                const { fontFamily = "", fontWeight = "", fontStyle = "" } = font;
                const fontKey = `${fontFamily.join("")}-${fontStyle}-${fontWeight}`;

                if (dedupedFonts.has(fontKey)) {
                    // Font already exists, accumulate the data
                    const existingFont = dedupedFonts.get(fontKey);
                    existingFont.count += font.count;
                    existingFont.texts = [...new Set([...existingFont.texts, ...font.texts])];
                    existingFont.tags = [...new Set([...existingFont.tags, ...font.tags])];
                } else {
                    // Font doesn't exist, add it to the map
                    dedupedFonts.set(fontKey, { ...font });
                }
            });
            const result = Array.from(dedupedFonts.values());
            return result;
            return fontData;
        },
        assignedStylesArray(state) {
            let assignedStyles = state.styleGuide?.assignedStyles || {};
            let { h1, h2, h3, h4, h5, h6, p } = assignedStyles;
            let allStyles = [h1, h2, h3, h4, h5, h6, p];
            allStyles = allStyles.filter(style => style !== undefined);
            return allStyles || [];
        },
        progress(state) {
            // let progress = state.progress || {};
            // if (state?.progress) progress = state?.progress || {};
            if (state?.progress) return state.progress;
            return {};
        },
    },
};
