function setupFunctions(functions) {
    let requestFunctions;
    if (Array.isArray(functions)) {
        requestFunctions = functions;
    } else if (typeof functions === "boolean") {
        requestFunctions = functions;
    } else if (typeof functions === "object") {
        requestFunctions = [functions];
    } else if (typeof functions === "string") {
        requestFunctions = functions;
    }
    return requestFunctions;
}

export default setupFunctions;
