<template>
    <div :class="{ 'vh-50': mobile, 'br-10': inset }" :style="`transition:200ms background ease-in;`" class="y x p-relative bg-dark-100 z-0 overflow-hidden" @dragover.prevent="dragOverHandler" @dragleave.prevent="dragLeaveHandler" @mouseleave.prevent="dragLeaveHandler">
        <Swiper ref="swiper" :loop="true" :pagination="{ clickable: true }" :slides-per-view="1" :space-between="10" class="x y">
            <SwiperSlide v-for="(img, i) in images" class="x y">
                <div class="x y">
                    <div :class="{ 'br-10': inset, 'f jce': fullBleed, 'aic jcc ': !fullBleed }" :style="`transition:200ms background ease-in;`" class="y x p-relative f bg-dark-100 overflow-hidden">
                        <div :class="{ 'br-10': inset }">
                            <Transition name="fade-up" appear>
                                <div :key="img.url">
                                    <template v-if="img.url && !imageLoading && !$store.state.presentation.typing && !$store.state.presentation.queue.typing && !$store.state.presentation.document.typing">
                                        <div>
                                            <Transition appear enter-active-class="animated fadeIn ease-in-out duration-2">
                                                <img v-show="!imageLoading" :class="{ 'o-5 pulse animation infinite': imageLoading, 'br-10': inset }" :src="img.url" class="x y p-absolute p-fill o-cover" @load="onImgLoad" />
                                                <!--                                                <img v-show="!imageLoading" :class="{ 'o-5 pulse animation infinite': imageLoading, 'br-10': inset }" v-lazy="img.url" class="x y p-absolute p-fill o-cover" @load="onImgLoad" />-->
                                            </Transition>
                                        </div>
                                    </template>
                                    <ImageLoadingContent v-else :section="section" />
                                </div>
                            </Transition>
                            <div v-if="!imageLoading && !mobile" class="p-absolute p-fill x y z-1 display:none hover:display:block ease-out-expo translate-y-5 opacity-0 transition-opacity hover:translate-y-0 hover:opacity-100">
                                <div :style="`transition:200ms background ease-in-out;`" class="p-absolute p-fill o-4 x y bg-dark-900 bg-base-100"></div>
                                <div v-if="img.prompt" class="p-absolute p-fill f x y animated fadeInUpSmooth text-light-300 fwb f-15 aic jcc whitespace-pre-wrap p-5" :class="{ 'bottom mw-500 height-max-300 f-24 mx-auto mt-auto h-20 text-center': fullBleed }">
                                    <contenteditable v-if="img.prompt" ref="contenteditable" v-model="img.prompt" :contenteditable="true" :no-html="false" :no-nl="false" class="x d-block whitespace-pre-line" :class="{ 'font-bold': fullBleed }" tag="p" @blur="saveSection()" appear></contenteditable>
                                    <div v-if="img.prompt.length" class="p-absolute bottom left fwb f-11 p-3">{{ img.prompt.length }} Characters</div>
                                </div>
                                <div v-else-if="section.enhancedImage" class="p-absolute p-fill f x y animated fadeInUpSmooth text-light-300 fwb f-15 aic jcc whitespace-pre-wrap p-5" :class="{ 'bottom mw-500 height-max-300 f-24 mx-auto mt-auto h-20 text-center': fullBleed }">
                                    <contenteditable v-if="section.enhancedImage" ref="contenteditable" v-model="section.enhancedImage" :contenteditable="true" :no-html="false" :no-nl="false" class="x d-block whitespace-pre-line" :class="{ 'font-bold': fullBleed }" tag="p" @blur="saveSection()" appear></contenteditable>
                                    <div v-if="section.enhancedImage.length" class="p-absolute bottom left fwb f-11 p-3">{{ section.enhancedImage.length }} Characters</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Transition tag="div" @mouseenter="hovered = true" appear enter-active-class="swingInUp animated duration-3 d-5" leave-active-class="animated swingInUp duration-1 reverse-animation">
                        <div class="p-absolute f aie bottom left right x z-4 h-150 jcc block pb-3 pt-5" v-if="!hovered">
                            <div class="d-inline-block br-50 relative overflow-hidden px-3">
                                <!--                                <span class="z-5 relative block whitespace-pre-wrap text-left text-snow" v-if="img">{{ img }}</span>-->
                                <div class="p-fill bg-dark-900 o-8 z-1 absolute"></div>
                                <div class="z-100 z-5 x y f aic jcc relative"><i class="fas fa-ellipsis-h white z-5 line-height-smaller f-30 relative my-0 py-0"></i></div>
                            </div>
                        </div>
                    </Transition>
                    <ImageUploader v-show="dragging" :key="'slide' + index" class="p-absolute p-fill z-4" @upload-success="onUploadSuccess" />
                    <Transition name="fade-up" appear>
                        <div class="p-absolute bottom right left z-4 f aie jce sectionBg gradient-transparent-white height-min-250 gap-5 p-3 px-5 pb-3 pt-8" v-if="hovered" @mouseleave="hovered = false">
                            <Transition name="fade-up" appear>
                                <BaseButton class="stagger-1 text-dark-800 border-dark-900 duration-3 justify-self-end border border-opacity-30 bg-transparent" icon="fas fa-trash f-20" icon-only label="Remix" size="sm" style-type="bubble" @click.prevent="removeImage(i)" />
                            </Transition>
                            <Transition name="fade-up" appear>
                                <div class="x z-1 f aie stagger-2 jcc mx-auto mt-0 ease-story-progress" v-if="!mobile">
                                    <BaseInput
                                        :is-loading="imageLoading"
                                        :modelValue="imageMod"
                                        class="x border-dark-900 br-50 mb-0 block"
                                        iclass="shadow x height-40 border !border-dark-900 bg-snow active:!bg-snow focus:!bg-snow !f-20 p-2 py-2 !text-gray-900 line-height-normal x !mb-0 !rounded-full placeholder-gray-300 px-3 mx-auto"
                                        inline
                                        placeholder="Modify this image"
                                        show-indicator="Generating"
                                        @update:modelValue="imageMod = $event"
                                        @keydown.enter="replaceBg(img.url, imageMod)" />
                                    <!--                                @keydown.enter="submitImageMod(img.url, imageMod, document.image_style, index)" />-->
                                </div>
                            </Transition>
                            <Transition name="fade-up" appear>
                                <BaseButton class="stagger-3 text-dark-800 border-dark-900 duration-3 justify-self-end border border-opacity-30 bg-transparent" icon=" fas fa-crop f-20" icon-only label="Upscale" size="sm" style-type="bubble" @click.prevent="upresImg(img, 4)" />
                            </Transition>
                            <Transition name="fade-up" appear>
                                <BaseButton class="stagger-4 text-dark-800 border-dark-900 duration-3 justify-self-end border border-opacity-30 bg-transparent" icon=" fas fa-image f-20" icon-only label="Remix" size="sm" style-type="bubble" @click.prevent="fetchingNewImage(), fetchNewImage()" />
                            </Transition>
                        </div>
                    </Transition>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>
<script>
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue";
import "swiper/css/effect-cards";
import "swiper/css";
import { collection, doc } from "firebase/firestore";
import contenteditable from "vue-contenteditable";
import { db } from "@/firebase";
// import documentManagementMixin from "@/mixins/documentManagementMixin";
import imageMixin from "@/mixins/images/imageMixin";
import presenationMixin from "@/mixins/presentations/presentationMixin";
import slideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import ImageUploader from "@/components/images/imageUploader.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import ImageLoadingContent from "@/components/Presentations/slides/ImageLoadingContent.vue";
import updateSlideProp from "@/mixins/ai/update_slide_prop";

import { mapGetters } from "vuex";
export default {
    components: { ImageLoadingContent, BaseInput, BaseButton, ImageUploader, contenteditable, Swiper, SwiperSlide },
    mixins: [imageMixin, slideStyleMixin, presenationMixin],
    setup() {
        const onSwiper = swiper => {};
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
        };
    },
    name: "PresentationImageContainer",
    props: {
        section: {},
        // documentId: {},
        fullBleed: {},
        randomImageStyle: {},
        index: {},
        imageDark: {},
        inset: {},
    },
    data() {
        return {
            largeImage: false,
            // document: {},
            showLoader: false,
            dragging: false,
            showUploader: false,
            hovered: false,
            imageMod: "",
            imageLoading: true,
            swiper: null,
        };
    },
    methods: {
        async fetchNewImage() {
            console.groupCollapsed(`%c 🌇 Getting image for slide ${this.index}`, purple);
            console.log("Index: ", this.index);
            console.log("Section: ", this.section);
            console.log("Enhanced Image: ", this.section.enhancedImage);
            console.log("Fetching new image");
            console.groupEnd();
            const result = await this.stabilityPresentationImage(this.index, this.document.sections[this.index].enhancedImage, undefined, this.document.image_style);
            console.log("Result: ", result);
            this.imageLoading = false;
            return result;
        },
        removeImage(indexToRemove) {
            const images = this.images;
            console.groupCollapsed(`%c🎇 Removing image ${indexToRemove} from slide ${this.index}`, fail);
            console.table(images);

            // Remove the image from array by its index
            const newImages = images.filter((image, index) => index !== indexToRemove);

            console.table(newImages);
            updateSlideProp(this.$store, "images", newImages, this.index);
            console.groupEnd();
            this.$forceUpdate();
        },
        async generateNewImage(imageMod) {
            if (!this.imageMod) return;
            const index = this.index;
            const prompt = this.imageMod;
            this.imageMod = "loading";
            this.stabilityPresentationImage(index, prompt);
            this.imageLoading = true;
        },
        async replaceBg(url, prompt) {
            this.imageLoading = true;
            this.imageMod = "";
            let image = await this.replaceBackground(url, prompt);
            let imageObj = {
                url: image,
                prompt: prompt,
            };
            console.log("Made image object: ", imageObj);
            let existingImages = this.section.images;
            let images = [imageObj, ...existingImages];
            console.log("Added image to existing images", images);
            updateSlideProp(this.$store, "images", images, this.index);
            this.imageLoading = false;
            await this.savePresentation(this.$route.params.id);
        },
        async upresImg(img, upscale = 2) {
            this.imageLoading = true;
            let image = await this.upresImage(img.url, upscale);
            let imageObj = {
                url: image,
            };
            imageObj = { ...img, ...imageObj };
            console.log("Made image object: ", imageObj);
            let existingImages = this.section.images;
            let images = [imageObj, ...existingImages];
            console.log("Added image to existing images", images);
            updateSlideProp(this.$store, "images", images, this.index);
            this.imageLoading = false;
            await this.savePresentation(this.$route.params.id);
        },
        async submitImageMod(url, imageMod, style, index) {
            this.showLoader = true;
            const prompt = imageMod;
            console.error("submitImageMod");
            let customOptions = {
                image_strength: 0.5,
                style_preset: style,
                prompt_weight: 1,
                height: 512,
                width: 512,
                samples: 2,
                steps: 50,
            };
            this.imageMod = "";
            await this.addImageVarationsToSection(index, url, prompt, customOptions);
            this.showLoader = true;
            this.imageMod = "Done! Swipe to see images.";
            // navigate to the next swiper slide

            setTimeout(() => {
                this.imageMod = "";
                this.swiper.slideNext();
            }, 1000);
            // this.stabilityImageToImageServer(url, prompt, newPrompt, customOptions);
        },
        dragOverHandler(e) {
            e.preventDefault();
            if (!this.dragging) {
                this.dragging = true;
            }
        },
        dragLeaveHandler(e) {
            e.preventDefault();
            if (e.target === this.$el) {
                this.dragging = false;
            }
        },
        onUploadSuccess(url) {
            const currentImages = this.images;
            let images = [imageObject(url), ...currentImages];
            updateSlideProp(this.$store, "images", images, this.index);
            this.savePresentation(this.$route.params.id);
        },
        async saveSection() {
            const sectionText = this.section.enhancedImage;
            this.section.enhancedImage = sectionText;
            updateSlideProp(this.$store, "enhancedImage", sectionText, this.index);
            await this.savePresentation(this.$route.params.id);
            return;
        },
        async onImgLoad() {
            this.$nextTick(() => {
                this.imageLoading = false;
            });
        },
        fetchingNewImage() {
            this.imageLoading = true;
        },
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        showRegularImage() {
            return Math.random() < 0.5;
        },
        images() {
            if (this.queue.images && this.queue.images.length > 0) {
                let imageObjectPlaceholder = {
                    prompt: this.section.enhancedImage || this.section.image,
                    url: this.queue.images[this.index].url,
                };
                return this.section.images || [imageObjectPlaceholder];
            }
            return this.section.images;
        },
        blendMode() {
            if (this.imageDark) {
                return "screen";
            } else {
                return "multiply";
            }
        },
    },
    watch: {
        documentId: {
            // call it upon creation too
            immediate: true,
            handler(id) {
                if (this.$route.params && this.$route.params.id) {
                }
                // console.error(this.$firestoreBind);
            },
        },
        section: {
            handler: function (val, oldVal) {
                if (val.images && val.images.length) {
                    let oldValImagesLength = oldVal?.images?.length || 0;
                    let newValImagesLength = val?.images?.length || 0;
                    if (val.images && val.images.length > 0) {
                        // this.imageLoading = false;
                    }
                }
            },
            deep: true,
        },
        "section.images": {
            handler: function (val, oldVal) {
                let oldValLength = oldVal?.length || 0;
                let newValLength = val?.length || 0;
                if (oldValLength !== newValLength) {
                    this.imageLoading = false;
                }
            },
            deep: true,
        },
    },
    created() {
        this.imageLoading = true;
    },
    mounted() {
        // prevent default frop behavior for images
        window.addEventListener(
            "dragover",
            function (e) {
                e = e || event;
                e.preventDefault();
            },
            false,
        );
        this.$nextTick(() => {
            if (this.section.images && this.section.images.length > 0) {
                this.imageLoading = false;
            }
        });
    },
};
</script>
<style lang="scss"></style>
