export function debounce(func) {
    var timer;
    return function (event) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, 100, event);
    };
}

// appHeight.js

export const appHeight = () => {
    const doc = document.body;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};

export const showConsoleLoader = () => {
    console.log(`                            
                                                                         
                            ▄▄                     
▀███▀▀▀██▄                  ██                     
  ██    ▀██▄                                       
  ██     ▀██ ▄▄█▀██ ▄██▀██████  ▄█▀█████████████▄  
  ██      ██▄█▀   ████   ▀▀ ██ ▄██  ██   ██    ██  
  ██     ▄████▀▀▀▀▀▀▀█████▄ ██ ▀█████▀   ██    ██  
  ██    ▄██▀██▄    ▄█▄   ██ ██ ██        ██    ██  
▄████████▀   ▀█████▀██████▀████▄███████▄████  ████▄
                               █▀     ██           
                               ██████▀               
▀████▀            ▄██        
  ██               ██        
  ██      ▄█▀██▄   ██▄████▄  
  ██     ██   ██   ██    ▀██ 
  ██     ▄▄█████   ██     ██ 
  ██    ▄██   ██   ██▄   ▄██ 
██████████████▀██▄ █▀█████▀                                 
`);
    console.log(`                           

                                                          
`);
};
