import Stability from "@/views/Stability.vue";
import updateDocumentProp from "@/mixins/ai/update_document_prop";
import completion from "@/mixins/ai/completion";
import jsonHelpers from "@/mixins/ai/json_helpers";
import sleep from "@/mixins/Chat/Commands/delay_timer";
import themeMixins from "@/mixins/StyleMixins/themeMixins";

export default {
    created: function () {},
    mixins: [Stability, themeMixins],
    data() {
        return {
            randomThemes: [
                {
                    primary: "hsl(20, 90%, 50%)",
                    secondary: "hsl(200, 90%, 50%)",
                },
                {
                    primary: "hsl(280,93%,60%)",
                    secondary: "hsl(118,90%,50%)",
                },
                {
                    primary: "hsl(346,90%,50%)",
                    secondary: "hsl(36,90%,50%)",
                },
            ],
        };
    },
    computed: {},
    methods: {
        getColorScheme(colors) {
            let { primary = "#000000", secondary = "#000000" } = colors;
            if (primary && secondary) {
                const colorValueHelper = (color, scheme) => this.colorValueHelper(color, scheme);
                const primaryColors = colorValueHelper(primary, "light") || [];
                const primaryDark = colorValueHelper(primary, "dark") || [];
                const secondaryColors = colorValueHelper(secondary, "light") || [];
                const secondaryDark = colorValueHelper(secondary, "dark") || [];

                const updatedColors = {
                    colors: {
                        primary: primary,
                        secondary: secondary,
                        primaries: primaryColors?.concat(primaryDark),
                        secondaries: secondaryColors?.concat(secondaryDark),
                        darks: primaryDark?.concat(secondaryDark),
                        lights: primaryColors?.concat(secondaryColors),
                    },
                };
                return updatedColors;
            }
        },
        async enhanceColors(text, index) {
            const format = {
                colors: { primary: "primaryColor", secondary: "secondaryColor" },
            };
            const colorInstructions = `
Generate a vibrant HSL style sheet based on colors inspired directly by the subject. 
Pick a primary and secondary color. Make them vibrant complimentary colors. 
Avoid black and gray.\n\nFormat:\n${JSON.stringify(format, null, 2)}`;
            try {
                let { processJSON } = jsonHelpers;
                const response = await completion({ user: text, system: colorInstructions, id: "Enhance Colors", model: gpt3, tokens: 100 });
                let { colors } = await processJSON(response);

                // Sample from random themes
                colors = this.randomThemes[Math.floor(Math.random() * this.randomThemes.length)];

                const { primary, secondary } = colors;

                if (primary && secondary) {
                    updateDocumentProp(this.$store, "hideSlides", true);
                    let updatedColors = this.getColorScheme(colors); // Pass colors object to getColorScheme method
                    updateDocumentProp(this.$store, "theme", updatedColors);
                    console.log("updated colors", updatedColors);
                    this.setColors(updatedColors.colors);
                    await sleep(1000);
                    updateDocumentProp(this.$store, "hideSlides", false);
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
