<template>
    <!--    <div :ref="`slide-${id}${index}`" class="x f p-relative br-5 aic jcc" style="height: 0px; padding-bottom: 60%">-->
    <div class="x f p-relative br-5 aic jcc" style="height: 0px; padding-bottom: 60%">
        <div class="row no-gutters f p-absolute p-fill aic jcc m-0 p-0">
            <div class="col-6 y">
                <!--            <div :ref="`image-sm-${id}${index}`" class="col-6 y">-->
                <img v-if="imageSrc" :src="imageSrc" class="p-absolute p-fill x y o-cover" />
                <!--                <img v-if="imageSrc" :ref="`image-sm-${id}${index}`" :src="imageSrc" class="p-absolute p-fill x y o-cover" style="mix-blend-mode: luminosity" />-->
                <div v-else class="x y animated loading-pulse o-2"></div>
            </div>
            <div class="col-6 p-2 pl-2">
                <!--            <div :ref="`text-sm-${id}${index}`" class="col-6 p-2 pl-2 text-base-50">-->
                <span v-if="title" class="d-block small fwb line-height-smaller f-11 mb-0">{{ slide.title }}</span>
                <span v-if="text" class="d-block o-5 line-height-smaller f-11 mb-0" style="font-size: 6px !important">{{ text.slice(0, 150) }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import { mapGetters } from "vuex";
export default {
    name: "SlideLeftMini",
    mixins: [SlideStyleMixin],
    props: {
        index: {},
        text: {},
        title: {},
    },
    data() {
        return {
            id: "",
        };
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        slide() {
            return this.$store.state?.presentation?.document?.sections[this.index];
        },
        imageSrc() {
            if (this.slide?.imageURL) {
                return this.slide.imageURL;
            }
            if (Array.isArray(this.slide?.images) && this.slide.images.length > 0) {
                return this.slide.images[0].url;
            }
            return null;
        },
    },
    mounted() {
        if (this.document?.theme) {
            let t = this.document.theme.colors || false;
            // if (t) this.textTheme();
        }
    },
    created() {
        this.id = this.randomId();
    },
};
</script>
