<template>
    <div class="x" style="width: 100vw">
        <div class="vh-100 f aic x"></div>
        <!--        <div ref="row2"></div>-->
        <div ref="images" class="vh-100 f aic x">
            <div class="f fc text-left relative" :class="{ 'gap-10': !mobile }">
                <div ref="row1" class="x f gap-10 my-auto jcc">
                    <template v-for="brand in $store.state.styleGuide.styleGuides.slice(0, 15)">
                        <div>
                            <HomePageBrandButton :scroll="true" hide-gradient class="flex-shrink-0 width-300 height-max-300 relative" :brand="brand"></HomePageBrandButton>
                        </div>
                    </template>
                </div>

                <div class="x block aic jcc" style="max-width: 100vw">
                    <div class="x mx-auto mw-1400 f aic jcc text-center" :class="{ 'vh-10': mobile, 'vh-40': !mobile }">
                        <TextBlock key="a3" animated-text class="white text-center h1 my-0" :class="{ 'f-80': mobile, '!f-200': !mobile }" start="center center" end="bottom top" paragraph="Get inspired."></TextBlock>
                    </div>
                </div>
                <div ref="row2" class="x f gap-10 my-auto jcc">
                    <template v-for="brand in $store.state.styleGuide.styleGuides.slice(31, 46)">
                        <div>
                            <HomePageBrandButton :scroll="true" hide-gradient class="flex-shrink-0 width-300 height-max-300 relative" :brand="brand"></HomePageBrandButton>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <!--                    <template v-for="brand in $store.state.styleGuide.styleGuides.slice(16, 30)">-->
    <!--                        <div>-->
    <!--                            <HomePageBrandButton :scroll="true" hide-gradient class="flex-shrink-0 width-300 height-max-300 relative" :brand="brand"></HomePageBrandButton>-->
    <!--                        </div>-->
    <!--                    </template>-->
</template>
<script>
import { Back, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsapMixin from "@/mixins/ai/gsapMixin";
import HomePageBrandButton from "@/views/HomePageBrandButton.vue";
import TextBlock from "@/components/marketing/TextBlock.vue";
gsap.registerPlugin(ScrollTrigger);
const { timeline, set, to, from } = gsap;
export default {
    name: "GSAPImageScroller",
    components: { TextBlock, HomePageBrandButton },
    mounted() {
        this.$nextTick(() => {
            this.initGsap();
        });
    },
    methods: {
        initGsap() {
            let container = this.$refs.images;
            let row1 = this.$refs.row1;
            let row2 = this.$refs.row2;
            // let row3 = this.$refs.row3;

            // Set initial positions
            set(row1, { x: "-35%" });
            set(row2, { x: "-10%" });
            // set(row3, { x: "-35%" });
            if (this.mobile) {
                set(row1, { x: "-60%", scale: 0.5 });
                set(row2, { x: "-10%", scale: 0.5 });
                // set(row3, { x: "-35%", scale: 0.5 });
            }
            set(container, { opacity: 1 });
            // Create a timeline for the ScrollTrigger
            let tl2 = timeline({
                scrollTrigger: {
                    trigger: container,
                    start: "top top",
                    end: "bottom top",
                    pin: container,
                    scrub: true,
                },
            });
            let tl = timeline({
                scrollTrigger: {
                    trigger: container,
                    start: "top bottom-=200",
                    end: "bottom top",
                    scrub: true,
                },
            });

            // Animate rows
            if (this.mobile) {
                tl.to(row1, { x: "-30%", duration: 1 }, 0);
                tl.to(row2, { x: "-35%", duration: 1 }, 0);
                // tl.to(row3, { x: "0%", duration: 1 }, 0);
            } else {
                tl.to(row1, { x: "-0%", duration: 1 }, 0);
                tl.to(row2, { x: "-35%", duration: 1 }, 0);
                // tl.to(row3, { x: "0%", duration: 1 }, 0);
            }
            tl2.set(container, { height: "0", opacity: 1 });
            tl2.to(container, { height: "0", opacity: 0, duration: 0.3 }, 1);
        },
    },
};
</script>
