<template>
    <div :class="slideContainerClasses" class="x vh-60">
        <div class="col-md-12 x h-auto text-left">
            <div class="f aic jcs y p-md-5 p-md-5 z-5 p-2">
                <div v-if="section.matrix" class="x block" style="mix-blend-mode: luminosity">
                    <PresentationSlideText :document="document" :document-id="document.id" :image-dark="imageDark" :section="section" :index="index" class="mb-4"></PresentationSlideText>
                    <table ref="table" class="x br-10" style="mix-blend-mode: luminosity">
                        <thead class="fwb">
                            <tr :ref="`text-${id}${index}`">
                                <th v-if="extraHeader" class=""></th>
                                <th
                                    v-for="header in section.matrix.headers"
                                    :style="`border:1px solid ${backgroundColor};`"
                                    class="line-height-smaller text-uppercase f-13 bg-base-700 p-3 text-center text-base-50">
                                    {{ header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in section.matrix.rows" :style="`border:1px solid ${backgroundColor}`">
                                <td class="fwh width-200 mw-250 f-13 line-height-small bg-base-700 px-3 py-3 text-base-100">
                                    {{ row.title }}
                                </td>
                                <td v-for="data in row.data" :style="`border:1px solid ${backgroundColor}`" class="p-lg-3 f-17 line-height-smaller p-3 text-left">
                                    {{ data }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import presenationMixin from "@/mixins/presentations/presentationMixin";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import themeMixins from "@/mixins/StyleMixins/themeMixins";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin, presenationMixin, themeMixins],
    props: {
        id: {},
        index: {},
        section: {},
        document: {},
        documentId: {},
        slideContainerClasses: {},
    },
    data: () => ({
        imageDark: false,
    }),
    watch: {
        document: {
            handler: function (val, oldVal) {
                if (val.sections !== oldVal.sections) {
                    let t = val.theme.colors || false;
                    if (t) this.textTheme();
                }
            },
            deep: false,
        },
    },
    computed: {
        extraHeader() {
            // if number of heads is greater than columns in the firs row, add an extra header at beginning
            if(this.section.matrix.headers) {
                return this.section.matrix.headers.length === this.section.matrix.rows[0].data.length;
            }
        },
        imageInset() {
            return "p-3 p-xl-5";
        },
        imageFullbleed() {
            return "p-0";
        },
    },
    watch: {
        $route: function (newVal, oldVal) {},
    },

    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
        if (this.section.matrix && this.section.matrix.matrix) {
            this.section.matrix = this.section.matrix.matrix;
        }
        this.slideColors();
    },
    methods: {
        randomImageStyle() {
            return Math.random() < 0.5 ? this.imageDark : this.imageFullbleed;
        },
    },
};
</script>
<style scoped>
table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    //border: 1px solid; padding: 8px;
}

th {
    //background-color: #f2f2f2;
}

td {
    text-align: center;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
</style>
