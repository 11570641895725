import { createStore } from "vuex";
import { docStore } from "@/mixins/DocumentArchive/documentManagementMixin";
import mixins from "@/mixins/mixins";
import { documentStore } from "@/mixins/DocumentArchive/documentMixins";
import { presStore } from "@/mixins/presentations/presentationMixin";
import StableDiffusion from "@/mixins/images/StableDiffusion";
import OpenAIMixins from "@/mixins/ai/openAIMixins";
import Chat from "@/store/ChatStore";
import MemoryStore from "@/store/MemoryStore";
import user from "@/store/UserStore";
import getAIModels from "@/mixins/firebase/getAIModels";
import FirebasePlugin from "@/mixins/firebase/FirebasePlugin";
import audioStore from "@/store/AudioStore";
import imageStore from "@/store/ImageStore";
import { updateDropdownValue, updateToggleValue } from "./StoreHelpers";
import presentationStore from "@/store/PresentationStore";
import MemStore from "@/store/MemStore";
import StreamStore from "@/store/StreamStore";
import StyleStore from "@/store/StyleStore";
import AssistantStore from "@/store/AssistantStore";
import emailStore from "@/store/EmailStore";

const store = createStore({
    modules: {
        chat: Chat,
        audio: audioStore,
        memory: MemStore,
        user: user,
        image: imageStore,
        presentation: presentationStore,
        stream: StreamStore,
        styleGuide: StyleStore,
        assistants: AssistantStore,
        email: emailStore,
    },
    plugins: [FirebasePlugin],
    state() {
        return {
            ...docStore.state(),
            ...presStore.state(),
            currentRoute: null,
            count: 0,
            alerts: [],
            mobile: null,
            tablet: null,
            fastBubbles: false,
            imageStylesStore: [],
            sectionIndex: 1,
            sectionId: `section-1`,
            selectedStyle: {
                name: "",
                kind: "",
                keywords: [],
                details: [],
                objects: [],
                id: null,
                image: null,
            },
            showInput: false,
            showButtons: true,
            step: 0,
            textResponse: "",
            genreArtist: null,
            fetching: false,
            currentSection: 0,
            showDocumentDrawer: false,
            selectedStyles: {
                scene: [],
                lighting: [],
                art: [],
            },
            imageRequest: {
                text_prompts: null,
                steps: null,
                samples: null,
                clip_guidance_preset: null,
                cfg_scale: null,
                height: null,
                width: null,
                seed: null,
                negativePrompt: null,
                style_preset: null,
            },
            imageModels: [],
            openAIModels: [],
            hideHeader: false,
        };
    },
    mutations: {
        ...documentStore.mutations,
        ...docStore.mutations,
        ...presStore.mutations,
        updateToggleValue,
        updateDropdownValue,
        setcurrentRoute(state, route) {
            state.currentRoute = route;
        },
        setUser(state, user) {
            state.user.user = user;
        },
        increment(state) {
            state.step++;
        },
        setSections(state, payload) {
            state.sections = payload;
        },
        removeAlert(state, index) {
            // state.alerts.splice(index, 1);
            state.alerts.splice(0, 1);
        },
        pushAlert(state, payload) {
            state.alerts.push(payload);
        },
        setSectionIndex(state, index) {
            state.sectionIndex = index;
            state.sectionId = "section-" + index;
        },
        setDocuments(state, payload) {
            state.documents = payload;
        },
        setDocument(state, payload) {
            window.doc = payload;
            state.document = payload;
        },
        setAddDocument(state, payload) {
            state.documents.push(payload);
        },
        storeDocument(state, payload) {
            state.documents.push(payload);
        },
        setCurrentDocument(state, payload) {
            state.currentDocument = payload;
        },
        setShowDocumentDrawer(state, payload) {
            state.showDocumentDrawer = payload;
        },
        addToSelection(state, { value, kind }) {
            state.selectedStyles[kind].push(value);
        },
        addSectionAbove(state, payload) {
            state.document.sections.splice(payload.index, 0, payload);
            console.log("add section above");
        },
        addSectionBelow(state, payload) {
            console.error("store trying to add");
            console.error("section below payload", payload);
            const newIndex = payload.index + 1;
            state.sections.splice(newIndex, 0, payload);
            setTimeout(() => {
                state.sectionIndex = newIndex;
            }, 20);
            console.log("add section below");

            // state.document.sections.splice(payload.index + 1, 0, payload);
        },
        addSectionAtEnd(state, payload) {
            state.document.sections.push(payload);
            console.log("add section at end");
        },
        spliceDocument(state, payload) {
            state.documents.splice(payload, 1);
        },
        setLastDocument(state, payload) {
            state.lastDocument = payload;
        },
        setLastPresentation(state, payload) {
            state.lastPresentation = payload;
        },
        setLastStoryboard(state, payload) {
            state.lastStoryboard = payload;
        },
        setLastCanvas(state, payload) {
            state.lastCanvas = payload;
        },
        setSavingDocument(state, payload) {
            state.savingDocument = payload;
        },
        setImageStyles(state, payload) {
            state.imageStylesStore = payload;
        },
        setCurrentStyle(state, payload) {
            state.selectedStyle = payload;
        },
        resetSelection(state, kind) {
            state.selectedStyles[kind] = [];
            // if (value = 'lighting') {
            //     state.selectedStyles.lightingStyle = [];
            // } else if (value = 'environment') {
            //     state.selectedStyles.environmentStyle = [];
            // } else if (value = 'artStyle') {
            //     state.selectedStyles.artStyle = [];
            // }
            // state.selectedStyles = [];
        },
        removeFromSelection(state, { value, kind }) {
            state.selectedStyles[kind].splice(state.selectedStyles[kind].indexOf(value), 1);
        },
        setCurrentSection(state, index) {
            state.currentSection = index;
        },
        setDarkMode(state, payload) {
            state.darkMode = payload;
        },
        setChatMode(state, payload) {
            state.chatMode = payload;
        },
        setGenreArtist(state, payload) {
            state.genreArtist = payload;
        },
        setShowInput(state, payload) {
            state.showInput = payload;
        },
        setShowButtons(state, payload) {
            state.showButtons = payload;
        },
        setFetching(state, payload) {
            state.fetching = payload;
        },
        setToggleView(state, payload) {
            state.toggleView = payload;
        },
        setChatLoadingState(state, payload) {
            state.chatLoading = payload;
        },
        updateUserFeed(state, payload) {
            state.user.feed.push(payload);
        },
        refeshUserFeed(state, payload) {
            state.user.feed = payload;
        },
        setArtist(state, payload) {
            state.promptData.artists = payload;
        },
        setTextResponse(state, payload) {
            state.textResponse = payload;
        },
        setHtml(state, payload) {
            state.promptData.html = payload;
        },
        setArtistDetails(state, payload) {
            state.promptData.artistDetails = payload;
        },
        setGenre(state, payload) {
            state.promptData.genre = payload;
        },
        setGenreDetails(state, payload) {
            let style = this.styles.find(style => style.style === this.promptData.genre);
            state.promptData.genre = payload;
        },
        setTopic(state, payload) {
            state.promptData.topic = payload;
        },
        setLocation(state, payload) {
            state.promptData.location = payload;
        },
        setStep(state, payload) {
            state.step = payload;
        },
        addToChat(state, payload) {
            state.chatItems.push(payload);
        },
        resetChat(state, payload) {
            state.chatItems = [];
        },
        setArtists(state, payload) {
            state.artists = payload;
        },
        setGenres(state, payload) {
            state.genres = payload;
        },
        setSongs(state, payload) {
            state.songs = payload;
        },
        // addArtistDetails(state, payload) {
        //     state.artists.push(payload);
        // },
        ADD_ITEM(state, item) {
            state.chatItems.push(item);
        },
        setMobile(state, payload) {
            state.mobile = payload;
        },
        setTablet(state, payload) {
            state.tablet = payload;
        },
        setSelectedModel(state, modelID) {
            state.selectedModel = modelID;
        },
        setImageRequest(state, payload) {
            state.imageRequest = payload;
        },
        setOpenAIModels(state, payload) {
            state.openAIModels = payload;
        },
        setHideHeader(state, payload) {
            state.hideHeader = payload;
        },
        REMOVE_ITEM_FROM_ARRAY(state, { store, keys, payload, optionalKeyToFindIndex }) {
            // Access the store dynamically from the root state
            let storeToTarget = state[store];
            if (!storeToTarget) {
                console.error(`Store ${store} not found`);
                return;
            }

            keys = Array.isArray(keys) ? keys : keys.split(".");

            function findAndRemove(obj, keys, payload, optionalKeyToFindIndex) {
                if (keys.length === 1) {
                    const targetArray = obj[keys[0]];
                    let index;

                    if (Array.isArray(optionalKeyToFindIndex)) {
                        // Check for multiple keys (like "backgroundColor" and "color")
                        index = targetArray.findIndex(item => optionalKeyToFindIndex.every(key => item[key] === payload[key]));
                    } else if (optionalKeyToFindIndex) {
                        index = targetArray.findIndex(item => item[optionalKeyToFindIndex] === payload[optionalKeyToFindIndex]);
                    } else {
                        index = targetArray.indexOf(payload);
                    }

                    if (index !== -1) {
                        targetArray.splice(index, 1);
                    }
                    return;
                }

                findAndRemove(obj[keys[0]], keys.slice(1), payload, optionalKeyToFindIndex);
            }

            findAndRemove(storeToTarget, keys, payload, optionalKeyToFindIndex);
        },
    },
    actions: {
        removeItemFromArrayAction({ commit, rootState }, { storeKey, arrayKey, payload, optionalKeyToFindIndex }) {
            commit("REMOVE_ITEM_FROM_ARRAY", { store: storeKey, keys: arrayKey, payload, optionalKeyToFindIndex });
        },
        async getOpenAIModels(context) {
            try {
                let models = getAIModels.methods.getOpenAIModelList();
                if (models.length > 0) {
                    context.commit("setOpenAIModels", models);
                    // console.error("openAIModels", models);
                }
            } catch (e) {
                console.error(e);
            }
        },
        updateItemProperty({ commit }, { itemId, property, value }) {
            // Retrieve the item from the store
            let item = state.documents[itemId];
            // Update the property of the item
            item[property] = value;
            // Commit the update to the store
            commit("updateItem", item);
            // Update the property in Firebase
            this.updateDoc(this.document);
            firestore
                .database()
                .ref(`documents/${itemId}`)
                .update({ [property]: value });
        },
        ...docStore.actions,
    },
});
export default store;
