<!-- CT.vue -->
<template>
    <Component :is="dynamicTag" :class="[computedClass, cclass]" :style="customStyle">
        <slot></slot>
    </Component>
</template>

<script>
export default {
    name: "Cm",
    props: {
        size: { type: String, default: "base" },
        weight: { type: String, default: "normal" },
        color: { type: String, default: "gray-900" },
        h1: { type: Boolean, default: false },
        h2: { type: Boolean, default: false },
        h3: { type: Boolean, default: false },
        h4: { type: Boolean, default: false },
        h5: { type: Boolean, default: false },
        h6: { type: Boolean, default: false },
        quote: { type: Boolean, default: false },
        ul: { type: Boolean, default: false },
        ol: { type: Boolean, default: false },
        full: { type: Boolean, default: false },
        li: { type: Boolean, default: false },
        list: { type: Boolean, default: false },
        a: { type: Boolean, default: false },
        serif: { type: Boolean, default: false },
        monospace: { type: Boolean, default: false },
        code: { type: Boolean, default: false },
        pre: { type: Boolean, default: false },
        table: { type: Boolean, default: false },
        thead: { type: Boolean, default: false },
        blockquote: { type: Boolean, default: false },
        p: { type: Boolean, default: false },
        cclass: { type: String, default: "" },
        customStyle: { type: Object, default: () => ({}) },
    },
    computed: {
        computedClass() {
            if (this.full) {
                return "list_full";
            }
            if (this.list || this.ul || this.ol) {
                return "list-disc list-inside";
            }
            return [`text-${this.size}`, `font-${this.weight}`, ` text-${this.color} `];
        },
        dynamicTag() {
            if (this.h1) return "h1";
            if (this.h2) return "h2";
            if (this.h3) return "h3";
            if (this.h4) return "h4";
            if (this.h5) return "h5";
            if (this.h6) return "h6";
            if (this.quote) return "q";
            if (this.ul) return "ul";
            if (this.ol) return "ol";
            if (this.li) return "li";
            if (this.list) return "ul";
            if (this.a) return "a";
            if (this.blockquote) return "blockquote";
            if (this.serif) return "span";
            if (this.monospace) return "span";
            if (this.code) return "code";
            if (this.pre) return "pre";
            if (this.table) return "table";
            if (this.thead) return "thead";
            if (this.p) return "p";
            return "span"; // Default tag
        },
    },
};
</script>
<style lang="scss" scoped>
h1, h2, h3, h4, h5, h6 {
  @apply font-bold;
}

h1{
  @apply text-4xl;
  @apply font-bold;
}

h2{
  @apply text-3xl;
  @apply font-bold;
  font-weight: 700;
  line-height: 1.2;
}

h3{
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h4{
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}

p, span{
  //@apply text-gray-400;
  //@apply dark:text-base-300;
  @apply content-baseline;
}

p{
  line-height: 1.5;
}

ul{
  @apply list-disc;
  @apply list-inside;
  @apply font-normal;
}

.list_full{
  @apply list-disc;
  @apply list-inside;
  @apply font-normal;
  li{
    @apply px-3 py-1;
    @apply list-none;
    border-top: 1px solid;
    @apply border-b border-gray-200;

    // tailwind border bottom
  }
}
</style>
