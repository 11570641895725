<template>
    <StyleGuideContainer title="Videos" section="videos">
        <div class="z-5 col-span-12 grid grid-cols-12 gap-4">
            <template v-for="v in videos">
                <div class="col-span-4">
                    <div class="p-relative br-10 overflow-hidden">
                        <!--                                {{ v }}-->
                        <div v-if="v" class="video-player x aspect-video bg-black">
                            <video v-if="v.videoSource" class="absolute inset-0 h-full w-full object-cover" controls>
                                <source :src="v.videoSource" type="video/mp4" />
                                <!--                        @loadedmetadata="setMetaData"-->
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <UICollapsableSection title="Details">
                        <template #title>Details</template>
                        <dynamic-object-viewer :data-object="v"></dynamic-object-viewer>
                    </UICollapsableSection>
                </div>
            </template>
        </div>
    </StyleGuideContainer>
</template>

<script>
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";

export default {
    name: "StyleGuideVideoComponent",
    components: { UICollapsableSection, DynamicObjectViewer, StyleGuideContainer },
    props: {
        videos: {},
    },
    data() {
        return {
            duration: 0,
        };
    },
    methods: {},
};
</script>
