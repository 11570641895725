<template>
    <div class="grid grid-cols-2 gap-1 rounded-lg overflow-hidden" v-if="images.length > 0">
        <div class="aspect-w-16 aspect-h-9" v-for="(image, index) in images" :key="index">
            <img v-if="image" :src="image" class="w-full object-cover" />
        </div>
    </div>
</template>
<script>
export default {
    name: "ChatImageGallery",
    props: {
        images: {
            type: Array,
            default: () => [
                "https://images.unsplash.com/photo-1677644334825-0eb411012ac0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3343&q=80",
                "https://images.unsplash.com/photo-1680868543815-b8666dba60f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2532&q=80",
                "https://images.unsplash.com/photo-1680193895115-b51b4ed5392f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80",
                "https://images.unsplash.com/photo-1680587590161-3a1dd77a7609?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2532&q=80",
            ],
        },
    },
};
</script>
