<template>
    <div class="">
        <UICollapsableSection class="border-bottom py-3 pl-2" small>
            <template #title>
                <div class="f aic gap-2 x">
                    <!--                  icon title description -->
                    <div class="f jcb x gap-2" :key="icon">
                        <div class="c-25 f aic jcc border-light fa-13 ml-auto" @click="deleteFeature">
                            <i class="far fa-trash f-13 o-5"></i>
                        </div>
                        <div class="f ais gap-2">
                            <div class="f aic gap-2">
                                <template v-if="icon">
                                    <div class="f aic c-25 f aic jcc">
                                        <template v-if="icon.emoji">{{ icon.emoji }}</template>
                                        <template v-else-if="icon.font_awesome_class"><i :class="icon.font_awesome_class" class="far"></i></template>
                                    </div>
                                </template>
                            </div>
                            <div class="f fc text-left gap-0">
                                <p class="mb-0 text-sm fwb" v-if="title">{{ title }}</p>
                                <p class="text-xs o-7" v-if="description">{{ description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="editor-section f fc border-bottom pb-3">
                <div>
                    <IconPicker @icon-selected="updateIcon" />
                </div>
                <template v-if="icon">
                    <div class="f aic">
                        <template v-if="icon.emoji">{{ icon.emoji }}</template>
                        <template v-if="icon.font_awesome_class"><i :class="icon.font_awesome_class" class="far"></i></template>
                    </div>
                </template>
                <div>
                    <SimpleInput v-model="title" label="title" @input="updateFeature" />
                    <SimpleTextarea v-model="description" label="description" @input="updateFeature" />
                </div>
            </div>
        </UICollapsableSection>
    </div>
</template>
<script>
import SimpleInput from "@/views/SimpleInput.vue";
import SimpleTextarea from "@/views/SimpleTextArea.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";
import IconPicker from "@/components/editor/IconPicker.vue";

export default {
    name: "SectionFeatureEditorItem",
    components: { IconPicker, UICollapsableSection, SimpleTextarea, SimpleInput },
    props: {
        feature: {
            type: Object,
            default: () => ({
                title: "No Title Found",
                description: "No Description Found",
                icon: {
                    emoji: "🚀",
                    font_awesome_class: "fas fa-rocket",
                },
            }),
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    emits: ["updateSection"],
    mounted() {
        this.$nextTick(() => {
            this.title = this.feature.title;
            this.description = this.feature.description;
            this.icon = this.feature.icon;
            this.setSelectedIcon();
        });
    },
    methods: {
        deleteFeature() {
            this.$emit("deleteFeature", this.index);
        },
        updateFeature() {
            let title = this.title;
            let description = this.description;
            let icon = this.icon;
            this.$nextTick(() => {
                this.$emit("updateSection", {
                    index: this.index,
                    prop: `features.${this.index}`,
                    value: {
                        title,
                        description,
                        icon,
                    },
                });
            });
        },
        updateIcon(selectedIcon) {
            this.icon = selectedIcon;
            this.updateFeature();
        },
        setSelectedIcon() {
            if (this.icon.custom) {
                this.selectedIcon = "custom";
                this.customIcon = this.icon.custom;
            } else if (this.icon.emoji) {
                this.selectedIcon = `emoji:${this.icon.emoji}`;
            } else if (this.icon.font_awesome_class) {
                this.selectedIcon = `fa:${this.icon.font_awesome_class}`;
            }
        },
    },
    data() {
        return {
            title: this.feature?.title || "",
            description: this.feature?.description || "",
            icon: this.feature?.icon || {},
            selectedIcon: "",
            customIcon: "",
        };
    },
    computed: {
        originalIcon() {
            return this.feature.icon;
        },
        originalTitle() {
            return this.feature.title;
        },
        originalDescription() {
            return this.feature.description;
        },
    },
};
</script>
