<template>
    <div class="mt-4 border-t border-white/10 pt-4 md:flex md:items-center theme1 fc bg-snow pb-8">
        <div class="flex space-x-6 md:order-2">
            <a v-for="item in navigation.social" :key="item.name" :href="item.href" class="text-gray-500 hover:text-gray-400">
                <span class="sr-only">{{ item.name }}</span>
            </a>
        </div>
        <div class="mw-200 f aic jcc mx-auto grayscale">
            <logo class="width-75 block"></logo>
        </div>
        <p class="mt-2 mb-8 text-xs leading-5 text-inherit md:order-1 md:mt-0">&copy; 2020 Your Company, Inc. All rights reserved.</p>
    </div>
</template>

<script>
import Logo from "@/components/CoreUI/navigaion/logo.vue";

export default {
    name: "FooterBottom",
    components: {
        Logo,
    },
    props: {
        navigation: {
            type: Object,
            required: true,
        },
    },
};
</script>
