<template>
    <ChatMessageWrapper v-if="!(m.hide && !$store.state.chat.showDebugger)" class="x" :m="m" :index="index" @remove-message="removeMessage">
        <div>
            <!--        <div class="relative" style="font-weight: 400" :class="{ 'mw-650': !mobile, chat_content_container: m.content !== '' }">-->
            <!--                    <div class="  relative" style="font-weight: 400" :class="{ 'mw-650': !mobile,'height-min-100 chat_content_container':m.content !=='' }">-->
            <LoadingSpinner v-if="(!m.command && !m.content) || (m.content.length <= 6 && m.content.includes('['))" color="#000" class="o-2 px-4" />
            <template v-else>
                <div class="block" v-for="command in m.commands">
                    <div v-if="command?.name === 'puppeteer_helper' && command?.content">
                        <code>
                            <pre class="overflow-y-scroll height-max-500 white" v-if="puppeteerResults?.content">{{ puppeteerResults.content }}</pre>
                        </code>
                        <div class="f fc jcs" v-for="(item, key) in puppeteerResults.content">
                            <template v-for="i in item">
                                <div v-if="i.outerHTML" v-html="i.outerHTML"></div>
                            </template>
                        </div>
                        <div class="overflow-y-scroll height-max-500">
                            <img class="x" v-if="command?.content?.screenshot" :src="command.content.screenshot" />
                            <img class="x" v-if="command?.content.annotations?.screenshot" :src="command.content?.annotations?.screenshot" />
                        </div>
                        <template v-for="(i, index) in command.content">
                            <template v-if="index === 0">{{ i }}</template>
                        </template>
                        <div class="overflow-y-scroll height-max-500" v-for="i in command.content">
                            <div v-for="(item, key) in i" :key="key" class="whitespace-line-pre" :style="item.style">
                                <div v-html="item.html"></div>
                            </div>
                        </div>
                        <dynamic-object-viewer :data-object="command.arguments"></dynamic-object-viewer>
                        <a class="my-3 block" v-if="command?.arguments?.url" :href="command.arguments.url">{{ command.arguments.url }}</a>
                    </div>
                    <div class="mb-5 mt-4" v-if="command && m.command.name === 'browse_website'">
                        <ArticleMetadata v-if="command.article" :article="command.article" />
                    </div>
                </div>
                <ChatWebResults class="x" v-if="showWebSearch" :key="`web-${index}`" :message="m" :index="index">
                    <chat-markdown :content="m.content.trim()" :message="m" :class="{ 'x block pr-3': mobile, 'mw-650 mb-5': !mobile }"></chat-markdown>
                </ChatWebResults>
                <template v-else>
                    <div class="prose" v-if="m.role === 'function' && m.command?.arguments?.speak">{{ m.command.arguments.speak }}</div>
                    <ChatMarkdown v-if="m.kind && m.kind === 'code'" :content="m.content" :message="m" />
                    <ChatMarkdown v-else :content="m.content.trim()" :message="m" :class="{ 'x block pr-3': mobile, 'mw-650': !mobile }" />
                    <ChatMapCard v-if="m.kind === 'map' && isNotLastMessage" :query="m.prompt || m.content"></ChatMapCard>
                    <ChatImage v-if="isImageMessage && (m.complete || isNotLastMessage)" :index="index" :m="m" :role="m.role" :text="extractPrompt(m)" class="x" @image-loaded="imageLoaded"></ChatImage>

                    <TaskManager class="br-10 x mw-350 border-light my-4 bg-gray-200/20 p-3 dark:bg-base-700" v-if="m.command && m.command.name === 'task_manager'"></TaskManager>
                    <AgentManager :key="index" :message="m"></AgentManager>
                    <!--                    <WebScraperTest />-->
                    <slot></slot>
                </template>
            </template>
            <template v-if="m.command">
                <template v-if="m.commands && m.commands.length > 0">
                    <FunctionPill v-for="command in m.commands" class="" :m="m" @click.prevent="" :command="command" :loading="m.role === 'function'" />
                </template>
                <FunctionPill class="" :m="m" v-else-if="m.command" @click.prevent="" :command="m.command" :loading="m.role === 'function'" />
            </template>
            <!--                      add timestamp from createdAt  -->
        </div>
        <!--                                            <template v-if="false && hovered"><chat-followup-topics @ask-question="askQuestion" appear style="right: unset" class="animated jfc fade-enter-active duration-1 left z-5 mw-800 pb-3 x p-absolute bottom flex overflow-hidden p-1 br-10" :class="{ '1': hovered, 'o-3': !hovered }" v-if="hovered && m.followUpTopics" :message="m" :messages="$store.state.chat.messages" :chat="m"></chat-followup-topics></template>-->
    </ChatMessageWrapper>
</template>
<script>
import ChatImage from "@/components/chat/specialMessages/ChatImage.vue";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import ChatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import ChatMapCard from "@/components/chat/specialMessages/ChatMapCard.vue";
import ChatWebResults from "@/components/chat/specialMessages/Search/ChatWebResults.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import FunctionPill from "@/components/chat/messages/FunctionPill.vue";
import TaskManager from "@/views/Chat/TaskManager.vue";
import AgentManager from "@/views/Chat/AgentManager.vue";
import ChatMessageWrapper from "@/components/chat/messages/ChatMessageWrapper.vue";
import ArticleMetadata from "@/components/chat/messages/ArticleMetadata.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";

export default {
    name: "Assistant",
    emits: ["askQuestion"],
    components: {
        DynamicObjectViewer,
        ArticleMetadata,
        ChatMessageWrapper,
        AgentManager,
        TaskManager,
        FunctionPill,
        LoadingSpinner,
        ChatWebResults,
        ChatMapCard,
        ChatMarkdown,
        ChatImage,
    },
    mixins: [ChatCommands],
    emits: ["image-loaded"],
    props: {
        // isCode: Function,
        // isCSS: Function,
        m: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            editMode: false,
            hovered: false,
        };
    },
    mounted() {},
    computed: {
        puppeteerResults() {
            if (!this.m.commands) return null; // Early return if commands are not available
            let css = "";
            let html = "";
            try {
                let results = this.m.commands.filter(command => command.name === "puppeteer_helper");
                let content = results[0].content;
                if (results.length === 0 || !results[0].content) return null; // Handle case where no matching commands or content is found
                results.forEach(result => {
                    const content = typeof result.content === "string" ? JSON.parse(result.content) : result.content;

                    Object.entries(content).forEach(([key, value]) => {
                        if (key.includes("_styles")) {
                            const styleKey = key.replace("_styles", "");
                            css += `\n\n${styleKey} {\n`;
                            value = value[0];
                            Object.entries(value).forEach(([prop, val]) => (css += `  ${prop}: ${val};\n`));
                            css += "}\n";
                        }
                        if (key.includes("_html")) {
                            html += value;
                        }
                    });
                });

                return {
                    content: content,
                    screenshot: results[0].screenshot,
                    styles: css,
                    html: html,
                };
            } catch (e) {
                console.error(e);
                return null; // Error handling
            }
        },

        showWebSearch() {
            return this.m?.command?.name === "web_search" && this.m.role === "assistant";
        },
        showPuppeteerResults() {
            return this.m?.command?.name === "puppeteer_help" && this.m.role === "assistant";
        },
        isNotLastMessage() {
            return this.index < this.$store.state.chat.messages.length - 1;
        },
        isCodeMessage() {
            return this.m.kind === "code";
        },
        isCommandObjectMessage() {
            return this.isCommandObject(this.m.content);
        },
        isImageMessage() {
            return this.isImage(this.m);
        },

        user() {
            return this.$store.state.user.user;
        },
        cleanMessage() {
            //remove linebreaks from the beginning and end of the message
            return this.m.content.replace(/^\n+|\n+$/g, "");
        },
        removeNewlinesAtStart() {
            return this.m.content;
            // return this.m.content.replace(/^[\n]+/, "\n");
        },

        isCodeNew() {
            if (this.m.kind && this.m.kind === "code") {
                return true;
            }
            return false;
            // return content => {
            //     return content.startsWith("```");
            // };
        },
        // isCSS() {
        //     return content => {
        //         return content.startsWith("```css");
        //     };
        // },
    },
    methods: {
        askQuestion(question) {
            this.$emit("askQuestion", question);
        },
        cancelEdit() {
            this.editMode = false;
        },
        editMessage() {
            console.error("startEditing");
            this.editMode = true;
        },
        saveEdit() {
            console.error("saveEdit");
            this.editMode = false;
            this.$emit("editMessage", this.m, this.index);
        },
        removeMessage() {
            this.$emit("removeMessage", this.m, this.index);
        },
        imageLoaded(index, images) {
            console.error("imageLoaded on ChatAssistantMessage", index, images);
            this.$emit("imageLoaded", index, images);
        },
    },
};
</script>
<style lang="scss">
#map {
    width: 100%;
    height: 400px;
}
</style>
