<template>
    <div>
        <!--        <FineTuningHelper />-->
        <AllCampaignsList :campaigns="campaignArray" @delete-item="deleteCampaignItem" />

        <div v-if="mobile" key="d" class="border-bottom x py-3">
            <h4>Campaigns</h4>
            <div v-if="campaignArray" class="f ais jcs x gap-4 relative overflow-x-scroll scrollbar-hide">
                <router-link :to="`/admin/${$route.params.id}/emails`" class="width-350 flex-shrink-0 y bg-red f aic jcc aspect-landscape br-10 my-3 bg-snow" style="border: 2px dashed rgba(0, 0, 0, 0.2)">
                    <div class="text-center o-5">
                        <span class="f-30 fwb">
                            <i class="fas fa-plus"></i>
                        </span>
                        <p class="f-15 fwb">Add Campaign</p>
                    </div>
                </router-link>
                <template v-for="(i, index) in campaignArray" :key="`item${i + 1}`">
                    <div class="f fc ais">
                        <CampaignCard v-if="images" :key="'campaign-' + index" :images="images" :index="index" :item="i" @delete-item="deleteCampaignItem" @item-clicked="selectCampaign(i)" />
                        <debug-object :object="i"></debug-object>
                    </div>
                </template>
            </div>
        </div>
        <div v-if="emails.length > 0" key="c" class="border-bottom x py-3">
            <h4>Emails</h4>
            <div v-if="emails" class="f ais jcs x gap-4 relative overflow-x-scroll scrollbar-hide">
                <div v-for="(i, index) in emails" :key="`item${i + 1}`">
                    <CampaignCard v-if="images" :images="images" :index="index" :item="i" @delete-item="deleteCampaignItem" @item-clicked="selectCampaign(i)" />
                </div>
            </div>
        </div>
        <div v-if="landingPages.length > 0" key="b" class="border-bottom x py-3">
            <h4>Landing Pages</h4>
            <div v-if="landingPages" class="f ais jcs x gap-4 relative overflow-x-scroll scrollbar-hide">
                <template v-for="(i, index) in landingPages" :key="`item${i + 1}`">
                    <CampaignCard v-if="images" :images="images" :index="index" :item="i" @delete-item="deleteCampaignItem" @item-clicked="selectCampaign(i)" />
                </template>
            </div>
        </div>
        <CampaignPreview v-if="showCampaign && selectedCampaign" :key="$route.params" :selected-campaign="selectedCampaign" />
    </div>
</template>
<script>
import CampaignCard from "@/components/Admin/CampaignCard.vue";
import findAllMatchingObjects from "@/mixins/firebase/findAllMatchingObject";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import deleteFromFirebase from "@/mixins/firebase/deleteFromFirebase";
import CampaignPreview from "@/components/Admin/CampaignPreview.vue";
import { mapGetters } from "vuex";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import AllCampaignsList from "@/components/Admin/AllCampaignsList.vue";
import FineTuningHelper from "@/components/Admin/FineTuningHelper.vue";

export default {
    name: "CampaignView",
    components: { FineTuningHelper, AllCampaignsList, DebugObject, CampaignPreview, CampaignCard },
    mixins: [styleGuideMixin],
    data() {
        return {};
    },
    computed: {
        ...mapGetters("stream", ["result", "campaignData", "campaigns", "showCampaign", "fineTuneMapping"]),
        selectedCampaign() {
            if (this.$store.state?.stream?.stream?.campaignData?.result) {
                return this.$store.state.stream.stream.campaignData;
            }
            return false;
        },
        landingPages() {
            // filter campaigns by item.result.object_type === "landing_page"
            let landingPages = this.campaigns.filter(i => i?.object_type === "page");
            // sort them by updated
            landingPages = landingPages.sort((a, b) => b.updated - a.updated);
            return landingPages;
        },
        emails() {
            return this.campaigns.filter(i => i?.object_type === "email");
        },
        campaignArray() {
            let campaigns = this.campaigns.filter(i => i?.result?.object_type === "campaign");
            //sort them by updated
            campaigns = campaigns.sort((a, b) => b.updated - a.updated);
            return campaigns;
        },
    },
    async mounted() {
        await this.getCampaigns();
    },
    methods: {
        selectCampaign(item) {
            // this.selectedCampaign = item;

            let campaign = item;
            // router link to the campaign
            this.$router.push({ name: "campaign", params: { cid: campaign.id } });
            let fakeSchedule = {
                name: "Schedule",
                schedule_items: [{ campaign_items: [item] }],
            };
            if (campaign?.item_type === "email") {
                this.updateStreamProp("campaignData", fakeSchedule);
                this.updateStreamProp("editor", {
                    [item.id]: {
                        ...item,
                        // messages: [],
                        // result: {},
                    },
                });
            }
            this.updateStreamProp("campaignData", item);
            // this.updateStreamProp("campaignData.result", item.result);
            this.updateStreamProp("showCampaign", true);
        },
        async deleteCampaignItem(item) {
            // find item in the campaigns
            item = deepCopy(item);
            console.log("Deleting item", item);
            await deleteFromFirebase("campaigns", item.id);
            let campaigns = this.campaigns.filter(i => i.id !== item.id);
            this.updateStreamProp("campaigns", campaigns);
        },
        async getCampaigns() {
            let campaigns = await findAllMatchingObjects("campaigns", "brand", this.$route.params.id);
            // campaigns.forEach(i => console.log(i.id, i.result?.object_type));
            // sort campaigns by updated
            campaigns = campaigns.sort((a, b) => b.updated - a.updated);
            this.updateStreamProp("campaigns", campaigns);
        },
    },
};
</script>
