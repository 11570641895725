<template>
    <div>
        <h3 v-if="displayTitle" class="font-medium text-gray-900">{{ displayTitle }}</h3>
        <div class="mt-2 flex items-center justify-between">
            <div class="text-sm italic text-gray-500">
                <slot v-if="$slots.default"></slot>
                <markdown v-else class="prose-gray" :content="description"></markdown>
            </div>
        </div>
    </div>
</template>
<script>
import Markdown from "@/components/chat/specialMessages/Markdown.vue";

export default {
    name: "UISlideoverContent",
    components: { Markdown },
    props: {
        title: {
            type: String,
            default: "Content",
        },
        description: {
            type: String,
            default: "No content available.",
        },
    },
    computed: {
        displayTitle() {
            let title = this.title || "";
            title = this.convertCase(title);
            return title || "";
        },
    },
};
</script>
<style lang="scss">
.prose-gray {
    @apply prose-sm text-gray-500;
}
</style>
