<template>
    <div v-if="places">
        <p class="fwb"><i class="fas fa-bolt mr-2 small text-green "></i>{{ title }}</p>
        <Sights :limit="6" :sights="places.sights" />
    </div>
</template>
<script>
import Sights from "@/components/chat/specialMessages/Search/Sights.vue";

export default {
    name: "TopSites",
    components: { Sights },
    props: {
        title: {
            type: String,
            required: true,
        },
        places: {
            type: Array,
            required: true,
        },
    },
};
</script>
