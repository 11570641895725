<template>
    <div class="bg-base-900 text-sky-400">
        <main>
            <HeroSection :title="heroTitle" :description="heroDescription" :button="mainButton" :secondary-button="secondaryButton" />
            <HomeLogoPile :title="logoPileText" />
            <HomeContentSection :features="primaryFeatures" :preheader="primaryPreheader" :title="primaryTitle" :description="primaryDescription" :subheader="primarySubheader" />
            <HomeContentSectionAlt :features="secondaryFeatures" :image-u-r-l="`public/assets/images/unicorn.jpg`" :title="secondaryTitle" :subheader="secondarySubheader" :description="secondaryDescription" />
            <HomeContentStats :stats="stats" :title="statsTitle" :description="statsDescription" />
            <HomeContentSectionAlt2 :title="ctaTitle" :description="ctaDescription" />
        </main>
        <BrandFooter class="text-snow"></BrandFooter>
    </div>
</template>

<script>
import { ArrowPathIcon, CloudArrowUpIcon, UserCircleIcon, BoltIcon, LockClosedIcon, CpuChipIcon, SparklesIcon, ClockIcon, UsersIcon, CalendarDaysIcon, Cog6ToothIcon, FingerPrintIcon, ServerIcon, ArchiveBoxIcon, PaintBrushIcon, PencilSquareIcon, StarIcon, LinkIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
// } from "@heroicons/vue/20/solid";
import HeroSection from "@/views/HeroSection.vue";
import HomeContentSection from "@/views/HomeContentSection.vue";
import HomeLogoPile from "@/views/HomeLogoPile.vue";
import HomeContentSectionAlt from "@/views/HomeContentSectionAlt.vue";
import HomeContentStats from "@/views/HomeContentStats.vue";
import HomeContentSectionAlt2 from "@/views/HomeContentSectionAlt2.vue";
import BrandFooter from "@/views/BrandFooter.vue";

export default {
    name: "HomeBasic",
    components: {
        BrandFooter,
        HomeContentSectionAlt2,
        HomeContentStats,
        HomeContentSectionAlt,
        HomeLogoPile,
        HomeContentSection,
        HeroSection,
    },
    data() {
        return {
            heroTitle: "Design Agency Quality Emails In Seconds",
            heroDescription:
                "You know that unicorn marketer who can craft jaw-dropping emails, keep your brand on-point, and send your growth into the stratosphere? Yeah, they're about as real as the Loch Ness Monster. But fear not, because we've distilled our collective marketing prowess into one powerhouse platform. It's like having a world-class creative director, copywriter, CMO, brand manager, and growth hacker all working tirelessly for you, without the hefty salaries or office politics.",
            mainButton: {
                text: "Start Growing",
                href: "/signup",
            },
            secondaryButton: {
                text: "See It in Action",
                href: "/features",
            },
            logoPileText: "Trusted by Top Brands Who Know What's Up",

            primaryFeatures: [
                {
                    title: "Plan, Schedule, Design — in seconds.",
                    description: "Plan your email strategy, schedule your campaigns, and design your masterpieces all in one place. It's like having a super-organized personal assistant, minus the coffee runs.",
                    icon: ClockIcon,
                    color: "orange",
                    button: {
                        text: "Plan Away",
                        link: "#",
                    },
                },
                {
                    title: "Pixel Perfect. Every Time.",
                    description: "Your designer might just shed a tear (of joy or envy, we're not sure). It's like having a van Gogh on your marketing team.",
                    icon: StarIcon,
                    color: "sky",
                    button: {
                        text: "Design Now",
                        link: "#",
                    },
                },
                {
                    title: "Seamless Klaviyo Integration",
                    // title: "Klaviyo and Unicorn, Sittin' in a Tree",
                    description: "Integrate with Klaviyo once, and watch the magic happen. It's like when your two best friends start dating, except way less awkward.",
                    icon: LinkIcon,
                    color: "green",
                    button: {
                        text: "Integrate Now",
                        link: "#",
                    },
                },
                {
                    title: "AI That ~ Vibes ~ with Your Brand",
                    description: "Unii carefully studies your brand, absorbs your styles, and crafts campaigns that are so 'you', you'll wonder if it's been stalking your Instagram.",
                    icon: CpuChipIcon,
                    color: "indigo",
                    button: {
                        text: "Brand It Up",
                        link: "#",
                    },
                },
                {
                    title: "Your Assets, Synced in a Blink",
                    description: "Connect your asset library and Unicorn will sync everything faster than you can say 'I love organized content'.",
                    icon: ArrowPathIcon,
                    color: "green",
                    button: {
                        text: "Sync It Up",
                        link: "#",
                    },
                },
                {
                    title: "Unleash the Power of Personalization",
                    description: "Unii's AI learns your customers' preferences and behaviors, crafting email experiences that are as unique as each individual. It's like having a personal shopper for every subscriber.",
                    icon: UserCircleIcon,
                    color: "pink",
                    button: {
                        text: "Get Personal",
                        link: "#",
                    },
                },
            ],
            primaryTitle: "A top-notch creative director, a witty copywriter, a strategic CMO, a meticulous brand manager, and a growth hacker all working tirelessly for you. ",
            // primaryTitle: "The All-In-One Platform Your Marketing Has Been Begging For",
            primaryPreheader: "Stop Juggling, Start Growing",
            primaryDescription:
                "Imagine having a top-notch creative director, a witty copywriter, a strategic CMO, a meticulous brand manager, and a growth hacker extraordinaire all working tirelessly for you. That's us - minus the egos and the beach vacations. We've poured our collective wisdom from leading brands and driving agency growth into a platform that's ready to revolutionize your marketing - one killer email at a time.",

            // primaryDescription: "We've been in the marketing trenches. We know what works and what doesn't. And we've packed all that hard-earned wisdom into one intuitive, AI-powered platform.",
            primarySubheader: "Built by Marketers, for Marketers (and Designed to Make Your Life Easier)",

            secondaryFeatures: [
                {
                    title: "Catalog Support Like Whoa",
                    description: "Got a massive product catalog? Bring it on. Unicorn can handle anything you throw at it.",
                    icon: ArchiveBoxIcon,
                    color: "indigo",
                    href: "/catalog",
                },
                {
                    title: "Your Designer Will Thank You",
                    description: "With Unicorn handling the emails, your designers can focus on the big picture. They might even have time to finally organize that font library.",
                    icon: PaintBrushIcon,
                    color: "amber",
                    href: "/designers",
                },
                {
                    title: "Meet Our Magic Editor",
                    description: "Our opinionated editor has guardrails to keep your emails on-brand and on-point, no matter who's behind the wheel.",
                    icon: SparklesIcon,
                    color: "green",
                    href: "/editor",
                },
            ],
            secondaryTitle: "We've Been There, Done That.",
            secondarySubheader: "Now We're Here to Make Your Life Easier",
            secondaryDescription: "We've run internal marketing teams. We've run agencies. We know the challenges you face daily, and we've built Unii to help.",
            stats: [
                { id: 1, title: "Red Bulls\n(sugar-free)", description: "10,234" },
                { id: 2, title: "Fire Subject Lines Written", description: "6,432" },
                { id: 3, title: "Hours of Sleep Lost\n(worth it)", description: "4,293" },
                { id: 4, title: "Marketers Saved from Insanity\n(and counting)", description: "42,069 " },
            ],
            statsTitle: "Just the Cold Hard Facts",
            statsDescription: "We've put in the work, so you don't have to. But don't just take our word for it. The numbers speak for themselves.",

            ctaTitle: "The Whole F#@%ing Alphabet.",
            ctaDescription: "Coming soon: Let our AI do your A/B testing for you. It's like having a data scientist on your team, minus the salary.",
        };
    },
};
</script>
