<template>
    <ul class="text-left x mw-500">
        <template v-for="feature in features">
            <!--            <FeatureLine :computed-description-class="computedDescriptionClass" :computed-title-class="computedTitleClass" :description-style-string="descriptionStyleString" :feature="feature" :title-style-string="titleStyleString" />-->
            <accordion-section-item class="x block" :color="color" :computed-description-class="computedDescriptionClass" :computed-title-class="computedTitleClass" :description-style-string="descriptionStyleString" :feature="feature" :title-style-string="titleStyleString" />
        </template>
    </ul>
</template>

<script>
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import FeatureLine from "@/components/generative_templates/Email/FeatureLine.vue";
import AccordionSectionItem from "@/components/generative_templates/Email/AccordionSectionItem.vue";

export default {
    name: "AccordionSection",
    components: { AccordionSectionItem, FeatureLine },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin],
    props: {
        section: {
            type: Object,
            required: true,
        },
        // Assuming you want to pass these from the parent for dynamic styles
        defaultFontSize: {
            type: Number,
            default: 60,
        },
        textColorOverride: {
            type: String,
            default: "",
        },
        font: {},
        fontOverride: {
            type: String,
            default: "",
        },
        fullBleed: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "",
        },
    },
    computed: {
        title() {
            return this.section.title;
        },
        features() {
            return this.section?.accordion;
        },
        styleString() {
            // [fontString, `color:${textColorOverride}`, `font-size:${fontSize}px!important`]
            let styles = [this.fontString, `font-size:${this.fontSize}px!important;`, `color:${this.textColorOverride}`];
            // [font.body, `font-size:${fontSize}px`, `color:${textColorOverride}`]
            return styles;
        },
        fontString() {
            if (this.fontOverride) {
                return this.fontOverride;
            } else {
                return this.font.body;
            }
        },
        computedTitleClass() {
            return {
                "font-bold": true,
                "text-lg": true,
                "mb-1": true,
                "!f-80": this.fullBleed,
                "!f-60": !this.fullBleed,
            };
        },
        computedDescriptionClass() {
            return {
                "text-gray-600": true,
            };
        },
        titleStyleString() {
            let styles = `font-size:${this.defaultFontSize}px!important; color:${this.textColorOverride};${this.fontString}`;
            return styles;
        },
        descriptionStyleString() {
            // You can further customize this if needed
            let styles = `font-size:${this.defaultFontSize * 0.75}px; color:${this.textColorOverride};${this.fontString}`;
            return styles;
        },
    },
};
</script>
