<template>
    <i :class="`fal fa-ban text-${color} fa-${size} block`"></i>
</template>
<script>
export default {
    name: "IconNo",
    props: {
        size: {
            type: String,
            default: "2xl",
        },
        color: {
            type: String,
            default: "red-700",
        },
    },
};
</script>
