const indicators = false;
export default {
    mixins: [],
    data() {
        return {
            // styleIds: [],
            // fontLinks: []
        };
    },
    computed: {
        desktopNormalImage() {
            if (this.transparent) {
                let classes = {
                    image: "transition-all my-auto o-contain x height-700",
                    container: "transition-all f aic jcc p-0 !my-0 aspect-auto",
                    inset: "transform",
                    shape: "",
                };
                if (this.isInset) {
                    classes.image = "transition-all my-auto o-contain x height-500";
                    classes.inset = "!p-9 transition-all";
                } else {
                    classes.inset = "!py-5 transition-all";
                }

                return classes;
            }
        },
        desktopRotatedImage() {
            if (this.transparent) {
                let classes = {
                    image: "transition-all my-auto o-contain height-max-800 rotate-45 ",
                    // bg-sky-400
                    // image: "my-auto o-contain bg-sky-400 x h-50",
                    // bg-red
                    container: "f aic jcc  relative !my-0",
                    inset: "transform py-6",
                    shape: "py-5 floating-item ",
                };
                if (this.isInset) {
                    classes.inset = "!p-8 ";
                    classes.image = "transition-all my-auto o-contain height-max-600 rotate-20";
                }

                return classes;
            }
        },
        fullBleedContainerDesktopClasses() {
            if (this.fullBleed) return "";
            let classes = [];
            return classes.join(" ");
        },
        desktopImageInset() {
            if (this.isNormal && this.isTransparent && !this.mobile) return this.desktopNormalImage.inset;
            if (this.isRotated && this.isTransparent && !this.mobile) return this.desktopRotatedImage.inset;
            if (this.isInset) {
                if (this.mobile) return `px-4 pt-4 -mt-10`;
                if (this.isRound) {
                    if (this.mobile) return `px-4 pt-5 x y`;
                    if (this.imageLeft) return `pl-5 py-0`;
                    if (this.imageRight) return `pr-5 py-0`;
                } else if (this.isLandscape) {
                    // return "none";
                    if (this.isHorizontal) {
                        if (this.imageLeft) return `pl-8 py-0`;
                        if (this.imageRight) return `py-3 pr-8`;
                    }
                    if (this.isVertical) {
                        if (this.imageLeft) return `pl-5 py-0`;
                        if (this.imageRight) return `pr-5 py-0`;
                    }
                } else {
                    return "p-5";
                }
            }
        },
        desktopTransparentClasses() {
            if (this.isRotated) {
                if (this.mobile) return "floating-item";
                return `py-5 floating-item mh-50`;
            }
            if (this.isFull) return `floating-item`;
            if (!this.mobile) return `py-5 floating-item`;
        },
        desktopImageContainerClasses() {
            // this is the container
            let classes = [];
            if (this.isRotated) return this.desktopRotatedImage.container;
            if (this.isNormal && this.transparent) return this.desktopNormalImage.container;
            if (this.fullBleed) return this.fullBleedContainerDesktopClasses;
            if (!this.fullBleed && this.isLandscape) classes.push("py-4");
            if (!this.fullBleed && this.isLandscape) classes.push("py-0");
            if (this.isTransparent) this.desktopTransparentClasses;
            if (this.isRound) classes.push("p-5");
            // if (this.isNormal || !this.imageStyle) classes.push("x y");
            // if (this.fullBleed) classes.push("vh-50");
            if (this.isLandscape && !this.mobile) classes.push("py-5");
            if (this.isLandscape && this.showLogo && this.isInset) classes.push("pt-7 ");

            return classes.join(" ");
        },
    },
    methods: {},
};
