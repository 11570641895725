import gptError from "@/mixins/ai/gpt_error";
import webRequest from "@/mixins/ai/web_request";

export async function streamClaude(requestData, from = "streaming", functions) {
    try {
        return await webRequest("streamTest", requestData, true);
    } catch (e) {
        gptError(e);
    }
}
export async function streamMetaprompt(requestData, from = "streaming", functions) {
    try {
        return await webRequest("generate-metaprompt", requestData, true);
    } catch (e) {
        gptError(e);
    }
}
