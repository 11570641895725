<template>
    <ul class="space-y-4 text-left relative" @mouseleave="hovered = false" @mouseover="hovered = true">
        <template v-for="(feature, i) in features">
            <FeatureLine
                :computed-description-class="computedDescriptionClass"
                :computed-title-class="computedTitleClass"
                :description-style-string="descriptionStyleString"
                :feature="feature"
                :feature-index="i"
                :section-font="font"
                :identifier="identifier"
                :index="index"
                :section="section"
                :text-align="textAlign"
                :title-style-string="titleStyleString"
                @save-section="saveSection" />
        </template>
        <transition name="fade-up">
            <div v-if="hovered" class="p-absolute align-fixed-center bg-snow/70 -left-5 shadow border border-solid border-1 bg-blur border-snow f aic jcc c-40 gap-4 rounded-full" @click.prevent="previousStyle">
                <i class="far fa-angle-left"></i>
            </div>
        </transition>
        <transition name="fade-up">
            <div v-if="hovered" class="p-absolute align-fixed-center bg-snow/70 -right-5 shadow border border-solid border-1 bg-blur border-snow f aic jcc c-40 gap-4 rounded-full" @click.prevent="nextStyle">
                <i class="far fa-angle-right"></i>
            </div>
        </transition>
        <!--                <div class="mx-auto bg-snow/70 shadow border border-solid border-1 bg-blur border-snow f aic jcc px-3 py-1 gap-4 rounded-full">-->
        <!--                    <div v-for="item in 4" @click.prevent="setListStyle(item)">{{ item }}</div>-->
        <!--                </div>-->
        <!--            </div>-->
    </ul>
</template>

<script>
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import FeatureLine from "@/components/generative_templates/Email/FeatureLine.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "FeaturesSection",
    components: { BaseButton, FeatureLine },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin],
    props: {
        section: { type: Object, required: true },
        // Assuming you want to pass these from the parent for dynamic styles
        defaultFontSize: { type: Number, default: 24 },
        textColorOverride: { type: String, default: "" },
        font: {},
        fontOverride: { type: String, default: "" },
        fullBleed: { type: Boolean, default: false },
        index: { type: Number, default: 0 },
        identifier: { type: String, required: true },
        textAlign: { type: String, default: "left" },
    },
    data() {
        return {
            hovered: false,
        };
    },
    methods: {
        saveSection() {
            this.$emit("save-section");
        },
        previousStyle() {
            let current = this.section.styleType;
            if (current > 0) {
                this.updateSection("styleType", current - 1);
            } else {
                this.updateSection("styleType", 1);
            }
        },
        nextStyle() {
            let current = this.section.styleType;
            if (current === 1) {
                this.updateSection("styleType", 0);
            } else {
                this.updateSection("styleType", current + 1);
            }
        },
        // saveSection() {
        //     this.$emit("save-section");
        // },
    },
    computed: {
        styleString() {
            // [fontString, `color:${textColorOverride}`, `font-size:${fontSize}px!important`]
            let styles = [this.fontString, `font-size:${this.fontSize}px!important;`, `color:${this.textColorOverride}`];
            // [font.body, `font-size:${fontSize}px`, `color:${textColorOverride}`]
            return styles;
        },
        fontString() {
            if (this.fontOverride) {
                return this.fontOverride;
            } else {
                return this.font.body;
            }
        },
        computedTitleClass() {
            return "font-bold text-xl mb-1";
        },
        computedDescriptionClass() {
            return "text-gray-600";
        },
        titleStyleString() {
            let styles = `font-size:${this.defaultFontSize}px!important; color:${this.textColorOverride};${this.fontString}`;
            return styles;
        },
        descriptionStyleString() {
            // You can further customize this if needed
            let styles = `font-size:${this.defaultFontSize * 0.75}px; color:${this.textColorOverride};${this.fontString}`;
            return styles;
        },
    },
};
</script>
