export default {
    mixins: [],
    data() {
        return {
            // styleIds: [],
            // fontLinks: [],
        };
    },
    computed: {
        themeClass() {
            return this.section.themeClass || "";
        },
        features() {
            return this.section.features || this.section.accordion || null;
        },
        bodySize() {
            let defaultSize = "";
            if (this.isEmail) defaultSize = 18;
            if (this.section?.bodySize) return this.section.bodySize;
            return defaultSize;
        },
        headerSize() {
            let defaultSize = 60;
            if (this.isEmail) defaultSize = 45;
            if (this.fullBleed) defaultSize = 80;
            if (this.fullBleed && this.isEmail) defaultSize = 50;
            if (this.section?.headerSize) return this.section.headerSize;
            return defaultSize;
        },
        subheaderSize() {
            let defaultSize = 25;
            if (this.isEmail) defaultSize = 22;
            if (this.section?.subheaderSize) return this.section.subheaderSize;
            return defaultSize;
        },
        preheaderSize() {
            let defaultSize = 20;
            if (this.isEmail) defaultSize = 20;
            if (this.section?.preheaderSize) return this.section.preheaderSize;
            return defaultSize;
        },
        buttonSize() {
            let defaultSize = "";
            if (this.isEmail) defaultSize = 22;
            if (this.section?.buttonSize) return this.section.buttonSize;
            return defaultSize;
        },
        showLogo() {
            return this.index === 0;
        },
        isBlended() {
            return this.section?.blend;
        },
        blendSections() {
            return this.section?.blend;
        },
        relevantImages() {
            return this.section?.relevant_image_indexes || [];
            // || this.campaignItem?.result?.relevant_image_indexes || [];
        },
        preheader() {
            return this.section?.pre_header;
        },
        header() {
            return this.section?.header;
        },
        body() {
            return this.section?.body;
        },
        subheader() {
            return this.section?.sub_header;
        },
        alt() {
            return this.image?.alt;
        },
        hideGradient() {
            if (this.isInset && !this.isRound) return true;
            return this.section.hideGradient;
        },
        isTucked() {
            return this.section.tuckImage;
        },
        imageStyle() {
            if (this.isTransparent) {
                let currentStyle = this.section?.imageStyle;
                if (currentStyle === "round") return "rotate";
                else if (currentStyle === "square") return "full";
                else if (currentStyle === "normal") return "normal";
                else return this.section?.imageStyle || "normal";
            } else {
                return this.section?.imageStyle || "normal";
            }
        },
        isEmail() {
            if (this.kind) return this.kind === "email";
            return false;
        },
        isRound() {
            return this.imageStyle === "round";
        },
        isSquare() {
            return this.imageStyle === "square";
        },
        isLandscape() {
            return this.imageStyle === "landscape";
        },
        isRotated() {
            return this.imageStyle === "rotate";
        },
        isFull() {
            return this.imageStyle === "full";
        },
        isNormal() {
            return this.imageStyle === "normal";
        },
        isInset() {
            return this.section?.imageInset;
        },
        imageInset() {
            return this.section?.imageInset;
        },
        isSeamless() {
            return this.image?.seamlessBg;
        },
        isTransparent() {
            return this.image?.transparent;
        },
        imageIndex() {
            return Math.max(this.section?.imageIndex ?? 0, 0);
        },
        textAlign() {
            let defaultAlignment = "left";
            if (this.isEmail) defaultAlignment = "center";
            if (this.section?.textAlign) return this.section.textAlign;
            return defaultAlignment;
        },
        transparent() {
            return this.image?.transparent;
        },
        fullBleed() {
            return this.section?.style === "fullBleed";
        },
        originalURL() {
            return this.image?.url;
        },
        sectionMaxHeightString() {
            if (this.transparent) {
                // darken this.image.color with chroma
                // let newColor = chroma(this.image.color).darken(0.5).saturate(2);
                // return `filter: drop-shadow(0px 0px 50px ${newColor});`;
            } else {
                if (this.isEmail) return;
                return `max-height:${this.maxHeight}vh`;
            }
        },
        emailURL() {
            let url;
            if (this.transparent) url = this.originalURL?.replace("Original", "public");
            else url = this.originalURL?.replace("Original", "public");
            return url;
            // else {
            //     if (this.isNormal && this.isHorizontal) return this.fullCrop;
            //     if (this.isSquare || this.isRound) return this.squareCrop;
            //     if (this.isRound && this.isHorizontal) return this.fullCrop;
            //     if (this.isLandscape && this.fullBleed) return this.fullCrop;
            //     if (this.isLandscape && !this.fullBleed) return this.fullCrop;
            //     if (this.fullBleed) return this.halfCrop;
            // }
            // else if (this.isLandscape) return this.originalURL;
            return this.originalURL;
        },
        imageURL() {
            let width = 1920;
            let height = 1080;
            let gravity = "center";
            if (this.transparent) return this.originalURL.replace("public", "width=1000").replace("Original", "width=1000");
            if (this.isEmail) return this.emailURL;
            if (this.fullBleed && this.originalURL) return this.originalURL.replace("public", `width=${width},height=${height},fit=cover,gravity=${gravity}`).replace("Original", `width=${width},height=${height},fit=cover,gravity=${gravity}`);
            // if (this.fullBleed && this.originalURL) return this.originalURL.replace("public", "width=1920,height=1080,fit=cover,gravity=auto").replace("Original", "width=1920,height=1080,fit=cover,gravity=auto");
            if (this.isNormal && this.isHorizontal) return this.fullCrop;
            if (this.isSquare || this.isRound) return this.squareCrop;
            if (this.isRound && this.isHorizontal) return this.fullCrop;
            // if (this.fullBleed) return this.fullCrop;
            if (this.isLandscape) return this.fullCrop;
            return this.halfCrop;
        },
        fullCrop() {
            let width = 1920;
            let height = 1080;
            let gravity = "auto";
            let originalModified = this.originalURL.replace("public", `width=${width},height=${height},fit=cover,gravity=${gravity}`).replace("Original", `width=${width},height=${height},fit=cover,gravity=${gravity}`);
            if (!this.transparent) return originalModified;
            return this.image?.crops?.full || this.originalURL;
        },
        squareCrop() {
            let width = 800;
            let height = 800;
            let gravity = "center";
            let originalModified = this.originalURL.replace("public", `width=${width},height=${height},fit=cover,gravity=${gravity}`).replace("Original", `width=${width},height=${height},fit=cover,gravity=${gravity}`);
            if (!this.transparent) return originalModified;
            return this.image?.crops?.full || this.originalURL;
        },
        halfCrop() {
            if (this.isEmail && this.originalURL) {
                let width = 800;
                let height = 800;
                let gravity = "center";
                let modString = `width=${width},height=${height},fit=cover,gravity=${gravity}`;
                let originalModified = this.originalURL?.replace("public", modString).replace("Original", modString);
                if (!this.transparent) return originalModified;
            } else {
                let width = 800;
                let height = 1200;
                let gravity = "center";
                let modString = `width=${width},height=${height},fit=contain,gravity=${gravity}`;
                let originalModified = this.originalURL?.replace("public", modString).replace("Original", modString);
                if (!this.transparent) return originalModified;
            }
            return this.image?.crops?.half || this.originalURL;
        },
        imageColor() {
            let color = this.image?.bottomColor || this.image?.color;
            return color || false;
        },
        bottomColor() {
            return this.image?.bottomColor;
        },
        sectionHeaderFont() {
            return this.section?.headerFont;
        },
        sectionBodyFont() {
            return this.section?.bodyFont;
        },
        sectionSubheaderFont() {
            return this.section?.subheaderFont;
        },
        imageLeft() {
            return this.section?.style === "imageLeft";
            // && !this.section.alignRight;
        },
        imageRight() {
            return this.section?.style === "imageRight";
            // if (this.section.alignRight) return true;
            // if (!this.section.style || (this.section.style && this.section.style === "imageTop")) return true;
        },
        trueColor() {},
        sectionBackground() {
            return this.section.backgroundColor || this.image.backgroundColor;
        },
        imageGradientColor() {
            return this.section?.backgroundColor || this.tempBackgroundColor;
        },
        imageTopGradientColor() {
            return this.image.topColor || this.section?.backgroundColor || this.tempBackgroundColor;
        },
        imageColorLogic() {
            let imageBackground = this.imageColor;
            let backgroundColor = this.tempBackgroundColor;
            let color = this.textColor;
            let finalColor, finalBackground;
            if (this.transparent) {
                finalBackground = imageBackground;
                if (this.image?.backgroundColor) finalBackground = this.image.backgroundColor;
                finalColor = this.findContrastingColor(imageBackground);
            } else {
                finalBackground = backgroundColor;
                finalColor = color;
                // finalColor = this.findContrastingColor(backgroundColor);
            }
            // this.backgroundColor = finalBackground;
            // this.textColor = finalColor;
            if (this.section?.color && this.section?.backgroundColor) {
                try {
                    let color = this.section?.color || "";
                    let backgroundColor = this.section?.backgroundColor || "";
                    if (color) finalColor = color?.replace("!important;", "");
                    if (backgroundColor) finalBackground = backgroundColor.replace("!important;", "");
                    this.textColorOverride = finalColor;
                } catch (e) {
                    console.log("Error setting section colors", e);
                }
            }
            return `color:${finalColor}!important;background:${finalBackground}!important;`;
        },
        // colors() {
        //     let imageColors = [];
        //     if (this.image?.colors) imageColors = this.image.colors;
        //     let divBGs = this.styleGuide?.divStyles?.map(s => s.backgroundColor);
        //     let imageBackground = this.image?.backgroundColor || null;
        //     let imageColor = this.imageColor || this.image?.color || null;
        //     let textColor = this.textColor || null;
        //     let tempBackgroundColor = this.tempBackgroundColor || null;
        //     let textColorOverride = this.textColorOverride || null;
        //     let colors = [
        //         ...divBGs, //
        //         ...imageColors,
        //         imageColor,
        //         tempBackgroundColor,
        //         imageBackground,
        //         textColor,
        //         imageBackground,
        //     ];
        //     colors = colors.filter(Boolean);
        //     colors = colors.filter(c => chroma?.valid(c)); // Filter out falsy values and then validate with Chroma.js
        //     colors = Array.from(new Set(colors)); // Remove duplicates
        //     // Sort colors by lightness
        //     colors.sort((a, b) => {
        //         let aLight = chroma(a).get("hsl.l");
        //         let bLight = chroma(b).get("hsl.l");
        //         return aLight - bLight;
        //     });
        //     // log the colors with %c in the console it should work in google chrome
        //     // colors.map(c => logColor({ color: c }));
        //     return colors;
        // },
        colors() {
            let imageColors = [];
            if (this.image?.colors) imageColors = this.image?.colors.map(c => ({ color: c, source: "Image Colors" }));
            let divBGs = this.styleGuide?.divStyles?.map(s => ({ color: s.backgroundColor, source: "Brand Colors" }));
            let imageBackground = this.image?.backgroundColor ? [{ color: this.image.backgroundColor, source: "Image BG" }] : [];
            let imageColor = this.imageColor || this.image?.color ? [{ color: this.imageColor || this.image.color, source: "Image Color" }] : [];
            let textColor = this.textColor ? [{ color: this.textColor, source: "Text Color" }] : [];
            let tempBackgroundColor = this.tempBackgroundColor ? [{ color: this.tempBackgroundColor, source: "Temp BG" }] : [];
            let textColorOverride = this.textColorOverride ? [{ color: this.textColorOverride, source: "Text Color" }] : [];
            let imageBottomColor = this.image?.bottomColor ? [{ color: this.image.bottomColor, source: "Image Bottom" }] : [];
            let imageTopColor = this.image?.topColor ? [{ color: this.image.topColor, source: "Image Top" }] : [];
            let imageDominantColor = this.image?.dominant ? [{ color: this.image.dominant, source: "Dominant" }] : [];
            let colors = [...divBGs, ...imageColors, ...imageColor, ...tempBackgroundColor, ...imageBackground, ...textColor, ...textColorOverride, ...imageBottomColor, ...imageTopColor, ...imageDominantColor];

            colors = colors.filter(c => chroma?.valid(c.color)); // Filter out invalid colors using Chroma.js
            colors = Array.from(new Map(colors.map(c => [c.color, c])).values()); // Remove duplicates based on color

            // Sort colors by lightness
            colors.sort((a, b) => {
                let aLight = chroma(a.color).get("hsl.l");
                let bLight = chroma(b.color).get("hsl.l");
                return aLight - bLight;
            });

            // Log the colors with %c in the console (works in Google Chrome)
            // colors.map(c => logColor({ color: c.color, source: c.source }));

            return colors;
        },
        mobileStyles() {
            if (this.mobile) return `font-size:30px!important; line-height:1;`;
            return "";
        },
        font() {
            // return this.likelyHeaderCombo;
            try {
                if (this.sectionFont) return this.sectionFont;
            } catch (e) {
                console.log("Error getting font", e);
            }
            let body = this.comboParagraph?.fontFamily || null;
            let header = this.comboHeader?.fontFamily || null;
            if (body) body = `font-family:${body}!important;`;
            if (header) header = `font-family:${header}!important;`;
            if (this.section?.font?.header) {
                if (body) body = `font-family:${this.section.font.body.fontFamily}!important;`;
                if (header) header = `font-family:${this.section.font.header.fontFamily}!important;`;
            }
            return { body, header };
        },
        buttonCss() {
            let buttonStyle = this.styleGuide?.buttonStyles?.[0];
            if (this.section?.buttonIndex) {
                buttonStyle = this.styleGuide?.buttonStyles?.[this.section.buttonIndex];
            }
            if (!buttonStyle) return;
            if (!buttonStyle?.borderRadius) buttonStyle.borderRadius = "10px";
            return buttonStyle;
        },
        button() {
            if (this.section?.button?.show) return this.section.button?.text;
        },
    },
    methods: {
        setListStyle(index) {
            // let identifier = `${this.identifier}.features.${this.index}`;
            this.updateSection("styleType", index);
            this.$emit("save-section");
            // this.updateStreamProp(identifier, index);
        },
        setThemeClass(index) {
            let themeClass = `theme${index}`;
            if (this.themeClass === themeClass) themeClass = themeClass + "a";
            this.updateSection("themeClass", themeClass);
            this.$emit("save-section");
        },
        updateStyle(style) {
            // this.updateStreamProp(`result.sections.${this.index}.style`, style);
            this.updateSection("style", style);
        },
        toggleFullBleed() {
            if (this.fullBleed) return this.updateSection("style", "imageLeft");
            return this.updateSection("style", "fullBleed");
        },
        toggleInset() {
            return this.updateSection("imageInset", !this.imageInset);
        },
        toggleStyle() {
            // let styles = ["square", "landscape"];
            let styles = ["normal", "round", "square", "landscape"];
            // let styles = ["normal", "round", "square"];
            // if (this.isHorizontal) styles = ["normal", "round", "square", "landscape"];
            // if (this.isVertical) styles = ["normal", "round", "square", "landscape"];
            if (this.transparent) styles = ["normal", "rotate"];
            let nextStyle = styles[this.currentStyleIndex];
            this.updateSection("imageStyle", nextStyle);
            this.currentStyleIndex = (this.currentStyleIndex + 1) % styles.length;
        },
        toggleSwapSides() {
            if (this.section?.style === "imageLeft") return this.updateSection("style", "imageRight");
            if (this.section?.style === "imageRight") return this.updateSection("style", "imageLeft");
            // if (this.section?.style === "imageRight") return this.updateSection("style", "fullBleed");
        },
        updateTextAlign(align) {
            // this.updateStreamProp(`result.sections.${this.index}.textAlign`, align);
            this.updateSection("textAlign", align);
        },
        toggleGradient() {
            // this.updateStreamProp(`result.sections.${this.index}.gradient`, !this.section.gradient);
            this.updateSection("gradient", !this.section.gradient);
            return this.updateSection("hideGradient", !this.section.hideGradient);
        },
        clickedLogo() {
            this.$emit("clickedLogo");
        },
        updateColors(color) {
            let object = {};
            console.log("Updating colors", color);
            if (!color && this.image) color = this.image.bottomColor || this.image.backgroundColor;
            let textColor;
            textColor = this.findContrastingColor(color);
            // textColor = this.$store.dispatch("styleGuide/getContrastColor", { color: color });
            // let textColor = this.getContrastColor(color);
            // store adjustments
            if (color) this.updateSection("backgroundColor", color, true);
            if (textColor) this.updateSection("color", textColor);

            //local adjustments
            this.backgroundColor = color;
            this.tempBackgroundColor = color;
            this.textColor = textColor;
            this.textColorOverride = textColor;
            return;
        },
        // updateColors(color) {
        //     let object = {};
        //     let textColor = this.findContrastingColor(color);
        //     this.updateSection("backgroundColor", color);
        //     this.updateSection("color", textColor);
        //     // this.updateStreamProp(`result.sections.${this.index}.backgroundColor`, color);
        //     // this.updateStreamProp(`result.sections.${this.index}.color`, textColor);
        //     // return
        // },
        mountWithCorrectedColors() {
            // if (this.imageColor) {
            //     this.textColorOverride = this.findContrastingColor(this.imageColor);
            // }
            if (!this.pickedColor) {
                this.getRandomTheme();
                this.pickedColor = true;
            }
            let backgroundColor;
            if (!this.section.color && !this.section.backgroundColor) {
                backgroundColor = this.bottomColor || this.image?.backgroundColor;
            } else {
                // this.updateColors(this.section.backgroundColor);
                backgroundColor = this.section.backgroundColor || this.image?.bottomColor;
            }
            // console.error("Mounting with corrected colors", backgroundColor);
            this.$nextTick = setTimeout(() => this.updateColors(backgroundColor), 100);
            // this.updateColors(backgroundColor);
        },

        getRandomTheme() {
            const themes = this.styleGuide?.divStyles;
            const { backgroundColor, color } = themes[Math.floor(Math.random() * themes.length)];
            if (backgroundColor) this.tempBackgroundColor = backgroundColor;
            if (color) this.textColor = color;
            return { color, backgroundColor };
        },
        logSectionChange(prop, index, value, section, identifier) {
            console.groupCollapsed(
                `%cUpdating ${prop}\n%cSection ${index}`,
                "color: purple; font-weight: bold; text-transform:uppercase; font-size: 9px;", // Styles for "Updating ${prop}"
                "color: black; font-weight: normal; font-size: 9px;", // Styles for "Section ${this.index}"
            );
            console.log("%cProperty:", boldLog, prop);
            console.log("%cValue:", boldLog, value);
            console.log("%cIdentifier:", boldLog, identifier);
            console.log("Updating with prop", prop, value, identifier);
            console.log(section);
            console.groupEnd();
        },
        updateSection(prop, value, skipSave = false, indexOverride) {
            if (this.section && (typeof this.section !== "object" || !this.section.imageIndex)) return;
            this.logSectionChange(prop, indexOverride || this.index, value, this.section, this.identifier);
            if (this.identifier) {
                if (!skipSave) this.$emit("save-section", { prop: prop, identifier: this.identifier });
                return this.updateStreamProp(`${this.identifier}.${prop}`, value, true);
            }
            if (this.inCampaign) return this.updateStreamProp(this.sectionProp(prop), value);
            else return this.updateStreamProp(this.sectionProp(prop), value);
        },
        sectionProp(prop, sections = "result.sections") {
            return `${sections}.${this.index}.${prop}`;
        },
    },
};
