<template>
    <div class="f br-5 jcs add_button aic cursor-pointer py-2">
        <div class="c-20 border-light f aic jcc mr-2">
            <i class="fas fa-plus o-5 f-15"></i>
        </div>
        <span class="m-0 p-0 text-sm leading-tight">{{ text }}</span>
    </div>
</template>
<script>
export default {
    name: "ButtonAddNew",
    props: {
        text: {
            type: String,
            required: true,
            default: "Add new",
        },
    },
};
</script>
<style lang="scss">
.add_button {
  cursor: pointer;
  @apply cursor-pointer;
  &:hover{
    //@apply text-blue;
  }
}
</style>
