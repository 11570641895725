import { mapActions } from "vuex";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            render_code: {
                name: "Render code",
                command: "render_code",
                start: "[START]",
                end: "[END]",
                kind: "code",
                role: "assistant",
                args: { code: "" },
                description: "Render code by inserting snippets between [START] ```language and ``` [END] tags.",
                show: true,
                newMessage: true,
            },
        };
    },
    computed: {},
    methods: {
        processCode(message) {
            const codeStart = "[START]";
            const codeEnd = "[END]";

            while (message.content.includes(codeStart) || message.content.includes(codeEnd)) {
                if (message.content.includes(codeEnd)) {
                    if (message.kind && message.kind === "code") {
                        message.content = message.content.replace("[END]", "");
                        this.$store.dispatch("chat/addMessageStore", aiMessage(""));
                        this.$nextTick(() => {
                            this.messages[this.messages.length - 2].content = "[START]\n" + message.content + " \n[END]";
                        });
                    }
                }
                if (message.content.includes(codeStart)) {
                    if (!message.kind) {
                        message.content = message.content.replace("[START]", "");
                        this.$store.dispatch("chat/addMessageStore", aiMessage("", "code"));
                    }
                }
                this.lastAssistantMessageIndex = this.messages.length - 1;
            }
        },
        // ...mapActions("chat", ["addToSummaryStore"]),
    },
};
