import { db } from "@/firebase";
// import { topics } from "@/mixins/prompts/topics";
import { mapGetters } from "vuex";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            userObjects: {
                relationship: {},
            },
            // user: { ...userObject },
        };
    },
    computed: {
        ...mapGetters("chat", ["prompts"]),
        // Example data: relationship: { partner_name: "", relationship_duration: "", important_milestone: "", communication_style: [], common_interests: [], conflict_resolution_style: [], romantic_gestures: [], love_languages: [], future_goals: [], life_values: [], relationship_strengths: [], trust_level: "", shared_responsibilities: [], favorite_memories: [], growth_areas: [], relationship_challenges: [], external_support: [], traditions_and_routines: [], },
        commands() {},
        recentTopics() {
            if (!this.prompts.length > 0) return;
            // get recent subjects from local storage
            let recentTopics = JSON.parse(localStorage.getItem("recentSubjects"));
            //match recentTopics to prompts.starters.topic
            if (recentTopics) {
                // setTimeout(() => {
                return recentTopics.map(topic => {
                    return this.prompts.find(starter => starter.topic === topic);
                });
                // }, 200);
            }
            return [];
        },
        chatString() {
            const chatStringNoSystem = this.messages
                .filter(m => m.role !== "system")
                .map(m => m.role + ": " + m.content)
                .join("\n");
            let string = chatStringNoSystem;
            if (this.summary) {
                string = chatStringNoSystem + "\n\nPrevious summary: " + this.summary;
            }
            return string;
        },
        recentSubjects() {
            return JSON.parse(localStorage.getItem("recentSubjects"));
        },
        removeExtraMessageFields() {
            return this.messages
                .map(message => {
                    const assistant = message.role === "assistant";
                    const user = message.role === "user";
                    const system = message.role === "system";
                    const blank = message.content.trim() === "";
                    if ((user || assistant || system) && !blank) {
                        return {
                            content: message.content,
                            role: message.role,
                        };
                    }
                })
                .filter(Boolean);
        },
    },
    methods: {
        setRecentTopics(topic) {
            let recentSubjects = JSON.parse(localStorage.getItem("recentSubjects"));
            // if it is already in local storage, don't add it again but move it to the top of the list

            if (recentSubjects && recentSubjects.length > 0) {
                if (recentSubjects.includes(topic)) {
                    recentSubjects.splice(recentSubjects.indexOf(topic), 1);
                }
                recentSubjects.unshift(topic);
                localStorage.setItem("recentSubjects", JSON.stringify(recentSubjects));
            } else {
                localStorage.setItem("recentSubjects", JSON.stringify([topic]));
            }
        },

        stringifyNestedObject(obj, indent = "") {
            let result = "";
            for (const key in obj) {
                if (typeof obj[key] === "object") {
                    result += `${indent}${key}\n${this.stringifyNestedObject(obj[key], indent + "\t")}`;
                } else {
                    result += `${indent}- ${key}: ${obj[key]}\n`;
                }
            }

            return result;
        },
        async setSubject(subject) {
            let object = "";
            let format = "";
            let subjectDataString = "";
            const self = this;
            // if (subject.data_objects) {
            //     let subjectData = subject.data_objects;
            //     subjectData = this.flattenObject(subjectData);
            //     if (subjectData) subjectDataString = this.stringifyObjectSimple(subjectData);
            //     console.table({ object, subjectDataString });
            // }

            if (subject && subject.model) {
                this.model = subject.model;
            }
            if (subject && subject.topic) {
                this.setRecentTopics(subject.topic);
            }
            let contentMod = `            
Format to return at the end:
${format}

Helpful Data: ${object}`;
            let content = `${subject.content}`;
            this.consoleObject("Chat Data Object", {
                subject: subject.topic,
                "Paired Down Object": object,
                Format: format,
                Content: content,
                "subject data": subjectDataString,
            });
            console.error("content", content);
            // save subject title to firestore
            if (subject.topic) {
                if (this.$route.params.id) {
                    try {
                        let topic = {
                            topic: subject.topic,
                        };
                        if (subject.bot_id) {
                            topic.bot_id = subject.bot_id;
                        }
                        await db.collection("chats").doc(this.$route.params.id).update(topic);
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
            this.$store.dispatch("chat/addMessageStore", roleMessage(content, subject.role));
            this.$store.dispatch("chat/addMessageStore", aiMessage(subject.welcome_message));
            // this.sendMessage();
        },
        removeSubjectFromLocalStorage(subject) {
            let recentSubjects = JSON.parse(localStorage.getItem("recentSubjects"));
            if (recentSubjects) {
                recentSubjects.splice(recentSubjects.indexOf(subject), 1);
                localStorage.setItem("recentSubjects", JSON.stringify(recentSubjects));
            }
        },
        //map prompt types and summary to prompt objects
    },
};
