<template>
    <div :class="{ 'editor-section': button.show, 'border-top o-4': !button.show }" class="f fc transition-all group border-bottom hover:opacity-100">
        <UICollapsableSection v-if="button?.show" left small>
            <template #title>
                <div>
                    <span class="editor-section-title">Button</span>
                    <div class="pb-1 absolute top right">
                        <BaseButton v-if="!loading" icon="fas f-17 fa-sparkles !text-indigo-500 opacity-100 !scale-100" icon-only size="xxs" @click="addButtonWithAI" />
                        <BaseButton v-else class="" icon="fas f-17 fas fa-sync fa-spin ease-back duration-3 text-purple-500" icon-only size="xxs" @click="addButtonWithAI" />
                    </div>
                </div>
            </template>
            <div>
                <div class="relative">
                    <div class="pb-1 relative">
                        <SimpleInput :label="'Text'" :model-value="button.text" @input="updateButton('text', $event.target.value)" />
                    </div>
                    <div class="pb-1 relative">
                        <SimpleInput :label="'URL'" :model-value="button.url" @input="updateButton('url', $event.target.value)" />
                    </div>
                    <div class="x f aic jcb overflow-hidden">
                        <div class="f overflow-x-scroll gap-3 scrollbar-hide">
                            <template v-for="(b, i) in buttonStyles">
                                <div :style="b" class="flex-shrink-0 mw-250 f aic jcc !f-13" @click.prevent="updateButton('buttonIndex', i)">Shop now</div>
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="buttons">
                    <div v-for="o in buttons" class="border-bottom py-2" @click.prevent="addButton(o)">
                        <div v-if="o.text" class="f fwb">{{ o.text }}</div>
                        <div v-if="o.url" class="f f-13 o-5">{{ o.url }}</div>
                    </div>
                </div>
            </div>
        </UICollapsableSection>
        <template v-else>
            <div class="group" @click.prevent="addButton()">
                <div class="f jcb aic cursor-pointer group-hover:text-indigo-600">
                    <span class="editor-section-title">Add a Button</span>
                    <div class="p-1">
                        <i class="far fa-plus-circle f-20 o-5"></i>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import SimpleInput from "@/views/SimpleInput.vue";
import { stringifySections } from "@/store/SectionsToStrings";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapGetters } from "vuex";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";

export default {
    name: "SectionButtonEditor",
    components: { UICollapsableSection, CollapsableMenuItem, BaseButton, SimpleInput },
    props: {
        section: {
            type: Object,
            required: true,
        },
        campaign: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    emits: ["update-section"],
    computed: {
        ...mapGetters("styleGuide", ["products", "buttonStyles"]),
        button() {
            return this.section.button || { text: "", url: "", show: false };
        },
        buttonIndex() {
            return this.section.buttonIndex || 0;
        },
        buttons() {
            return this.section?.buttons || [];
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        // updateButton(prop, value) {
        //     if (prop === "buttonIndex") {
        //         this.$emit("update-section", prop, value);
        //     } else {
        //         this.$emit("update-section", "button", { ...this.button, [prop]: value });
        //     }
        // },

        updateButton(prop, value) {
            if (prop === "buttonIndex") {
                this.$emit("update-section", prop, value);
            } else if (prop === "buttons") {
                this.$emit("update-section", "buttons", value);
            } else {
                this.$emit("update-section", "button", { ...this.button, [prop]: value });
            }
        },
        async addButtonWithAI() {
            this.loading = true;
            let section = deepCopy(this.section);
            delete section.imageData;
            let links = this.products
                .map(p => {
                    let title = p.title || p.name || "";
                    let url = p.url || "";
                    return `${title}\n${url}\n\n`;
                })
                .join("\n");

            let obj = {
                id: this.campaign.id,
                index: this.index,
                input: `SOURCE LINKS:${links}\n\n------\n\nCURRENT STATE\n${stringifySections([section])}\n\nJust return the string (NOT in quotes)\n\nGenerate 3 button objects with different text and URLs for SECTION ${this.index}. Return as an array of objects, each with "text" and "url" properties.`,
                existing: section,
                type: "button",
            };
            console.log("addButtonWithAI", obj);
            await this.$store.dispatch("stream/addSectionWithAI", obj);
            try {
                await this.addButton(this.buttons[0]);
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
        async addButton(button) {
            if (button) return this.$emit("update-section", "button", { ...button, show: true });
            else {
                this.$emit("update-section", "button", { text: "Shop now", url: "", show: true });
                this.$emit("update-section", "buttonIndex", 0);
                await this.addButtonWithAI();
            }
        },
    },
};
</script>
