<template>
    <div class="border-light py-4 x">
        <FontAwesomeSample v-if="name.includes('awesome') || name.includes('Awesome')" :f="f" />
        <div v-else :style="`font-family:${name};font-weight:${weight};font-style:${fontStyle};font-size:34px;line-height:1;`" class="mb-3 block">The Quick Brown Fox</div>
        <div class="f fwb gap-3 aic">
            <span v-if="name" class="block line-clamp-1">{{ name }}</span>
            <div class="f text-uppercase fwb o-5 gap-3 f-11 aic jcs mb-0 line-height-normal">
                <span v-if="fontStyle" class="text-center">{{ fontStyle }}</span>
                <span v-if="weight" class="text-center">{{ weight }}</span>
                <span v-if="count" class="text-center">{{ count }}</span>
                <span v-if="f.kind" class="text-center">{{ f.kind }}</span>
            </div>
        </div>
        <!--        <div class="whitespace-pre-wrap" v-html="`@fontFace{ \n\tfont-family: '${name}'; \n\tfont-style: ${fontStyle}!important; \n\tfont-weight: ${weight}; \n\tfont-display: swap; \n\tsrc: url(${url}); \n}`"></div>-->
    </div>
</template>
<script>
import FontAwesomeSample from "@/components/styleGuide/Typography/FontAwesomeSample.vue";

export default {
    name: "MobileFontSample",
    components: { FontAwesomeSample },
    props: {
        f: {
            type: Object,
            required: true,
        },
    },
    computed: {
        name() {
            return this.f.name || this.f.fontFamily;
        },
        weight() {
            return this.f?.weight || this.f?.fontWeight || "400";
        },
        count() {
            return this.f?.usageCount || 0;
        },
        fontStyle() {
            return this.f?.style || this.f?.fontStyle || "normal";
        },
        url() {
            return this.f?.url || "";
        },
    },
};
</script>
