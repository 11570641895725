<template>
    <div>
        <div v-if="collections?.length > 0" class="col-span-12 x">
            <div class="mb-3">
                <UICollapsableSection>
                    <template #title>Collections</template>
                    <template v-for="collection in collections">
                        <div class="">
                            <div v-if="collection?.products?.length > 0">
                                <UIWrapperText :title="collection.name" :subtitle="collection.description" section="collections">
                                    <Collection :collection="collection" />
                                </UIWrapperText>
                            </div>
                        </div>
                    </template>
                </UICollapsableSection>
            </div>
        </div>

        <StyleGuideContainer section="products" title="Products">
            <div v-if="productsToShow?.length > 0" class="col-span-12">
                <ProductDetail v-if="currentProduct" :all-products="productsToShow" :product="currentProduct" class="p-fixed p-fill f aic jcc z-10" />
                <div v-if="productsToShow" class="grid grid-cols-9 md:gap-6" style="grid-template-rows: masonry">
                    <template v-for="(product, index) in productsToShow" :key="product.handle">
                        <StyleGuideProductCard :index="index" :product="product" class="col-span-6 md:col-span-2" />
                    </template>
                </div>
                <BaseAccordion class="mw-500 my-3">
                    <template #title>Product Text</template>
                    <div>
                        <debug-object :object="products" />
                        <div class="whitespace-pre-wrap scrollSection scrollbar-hide height-max-300">{{ ProductArray }}</div>
                    </div>
                </BaseAccordion>
            </div>
        </StyleGuideContainer>
    </div>
</template>

<script>
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BaseAccordion from "@/components/CoreUI/BaseAccordion.vue";
import StyleGuideProductCard from "@/components/styleGuide/Sections/ProductCard.vue";
import { mapGetters } from "vuex";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import ProductDetail from "@/components/styleGuide/Sections/ProductDetail.vue";
import Collections from "@/views/Collections.vue";
import Collection from "@/views/Collection.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";

export default {
    mixins: [styleGuideMixin],
    components: { UIWrapperText, UICollapsableSection, Collection, Collections, ProductDetail, DebugObject, StyleGuideProductCard, BaseAccordion, StyleGuideContainer },
    props: {},
    computed: {
        ...mapGetters("styleGuide", ["products", "styleGuideId", "currentProduct", "collections"]),
        ...mapGetters("styleGuide/image", ["images"]),
        productsToShow() {
            if (this.products) {
                let products = this.products;

                const uniqueProducts = {}; // Hash table for unique product titles
                let dedupedProducts = []; // Array to hold unique products

                // De-duplicate products by title
                products.forEach(product => {
                    const { title } = product;
                    if (!uniqueProducts[title]) {
                        uniqueProducts[title] = true;
                        dedupedProducts.push(product);
                    }
                });
                return dedupedProducts;
            }
        },
    },
    methods: {},
};
</script>
