<template>
    <div class="relative f aic jcb x px-2" :class="{ 'flex-row-reverse': staggered && index % 2 === 0, 'flex-row': !staggered || index % 2 !== 0 }">
        <div v-for="img in productImages.slice(0, 1)" class="width-250 flex-shrink-0" :class="{ 'p-0': img.transparent, 'rounded-full': !rotated && !img.transparent, 'overflow-hidden': !img.transparent, '-rotate-17': rotated && staggered && index % 2 === 0, 'rotate-17': rotated && staggered && index % 2 !== 0 }">
            <!--        <div v-for="img in productImages.slice(0, 1)" class="width-200 flex-shrink-0 rounded-full overflow-hidden" :class="{ 'p-4': img.transparent }">-->
            <img :alt="product.name" :src="img.url" class="section_img_data aspect-square br-10" :class="{ 'o-contain': img.transparent, 'o-cover': !img.transparent }" />
        </div>
        <div class="text-center x z-1">
            <div class="mx-auto">
                <h4 v-if="title" class="fwb f-40 block text-pretty mx-auto line-height-normal" style="line-height: 1">{{ title }}</h4>
                <!--            <p v-if="product.description" class="fwb my-0 f-17 text-pretty line-clamp-2">{{ product.description }}</p>-->
                <p v-if="product.price" class="my-0 o-5 f-17 text-pretty mb-0 line-height-normal text-uppercase fwb">Shop now - ${{ product.price }}</p>
            </div>
            <!--            <p v-if="product.price" class="my-0 m-3 f-17 text-pretty mb-0 button-3">Shop now</p>-->
            <!--            <p v-if="product.handle" class="fwb my-0 f-17 text-pretty">${{ product.handle }}</p>-->
        </div>
    </div>
    <!--    </a>-->
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "EmailProductRow",
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            default: 0,
        },
        staggered: {
            type: Boolean,
            default: true,
        },
        rotated: {
            type: Boolean,
            default: true,
        },
        lifestyle: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // map getters from styleGuide/image/images
        ...mapGetters("styleGuide/image", ["images"]),
        title() {
            let title = this.product?.title || this.product?.name || "";
            return title.split("|")[0].trim();
        },
        handle() {
            return this.product?.handle || "";
        },
        productImages() {
            if (!this.images) return;
            let images = this.images;
            let productImages = images.filter(i => i.source === this.handle);
            if (this.lifestyle) {
                productImages = productImages.sort((a, b) => b?.entropy?.entropy - a?.entropy?.entropy);
            } else {
                productImages = productImages.sort((a, b) => a?.position - b?.position);
            }
            return productImages;
        },
        image() {
            // find cooresonding image in this
            if (!this.product?.images?.[0]?.url) return;
            if (!this.images) return;
            let url = this.product.images[0].url;
            // return { url };
            // return url;
            // find the url in images
            return this.images.find(i => i.originalURL === url) || this.images.find(i => i.originalURL === this.product.image);
        },
    },
};
</script>
<!--    <a :href="product?.url" class="f x" target="_blank">-->

<!--        <div class="f width-50 flex-shrink-0">-->
<!--            <img v-if="image?.url" ref="imgTag" :alt="product.name" :src="image.url" class="section_img_data x aspect-square br-10 o-contain" />-->
<!--        </div>-->
