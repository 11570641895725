import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import { mapGetters } from "vuex";
import styleGuideTextMixin from "@/mixins/StyleGuideMixins/StyleGuideTextMixin";
import chroma from "chroma-js";
function objectToCss(styles) {
    return Object.entries(styles).reduce((cssString, [key, value]) => {
        // Convert camelCase to kebab-case
        const kebabCaseKey = key.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
        // Append the property and its value to the CSS string
        if (key !== "score" && key !== "totalScore" && key !== "hasText") {
            return `${cssString}\t${kebabCaseKey}: ${value};\n`;
        } else {
            return "";
        }
    }, "");
}
function objectToCssSimple(styleObj) {
    return Object.keys(styleObj)
        .map(key => `${key}: ${styleObj[key]};`)
        .join("\n");
}
export default {
    mixins: [],
    data() {
        return {
            styleIds: [],
            fontLinks: [],
        };
    },
    // computed: {
    //     ...mapGetters("styleGuide", ["styleGuide", "textStyles", "colors", "fonts", "buttonStyles", "headerImage", "divStyles", "allTextStyles", "textSamples", "svgs", "hasImages", "website"]),
    // },
    // mounted() {
    //     this.$nextTick(() => {
    //         if (this.fonts?.length) {
    //             this.buildFontFace(this.fonts[0]);
    //         }
    //     });
    // },
    methods: {
        createFontStyles(fonts) {
            if (!fonts) fonts = this.fonts || [];
            const cssRules = this.generateFontFaceRules(fonts);
            const styleEl = document.createElement("style");
            styleEl.id = "dynamic-font-styles";
            styleEl.textContent = cssRules;
            document.head.appendChild(styleEl);
        },
        buildCSSColors(colors) {
            // Fallback to this.colors.all_colors or an empty array if colors is not provided
            if (!colors) colors = this.colors?.all_colors || [];
            let colorString = ":root {";
            if (this.styleGuide?.baseBackground) colorString += `\n\t--base-bg: ${this.styleGuide.baseBackground};`;
            if (this.styleGuide?.baseColor) colorString += `\n\t--base-color: ${this.styleGuide.baseColor};`;
            colors.forEach((color, index) => {
                if (color) {
                    // Basic validation
                    colorString += `\n\t--color${index}: ${color};\n`;
                } else {
                    console.warn(`Invalid color object at index ${index}`, color);
                }
            });
            colorString += "\n}";
            return colorString;
        },
        buildFontClasses(fonts) {
            if (!fonts) return;
            // sort fonts by count
            fonts = fonts.sort((a, b) => b.count - a.count);
            let fontClasses = fonts.map((font, index) => {
                let { name, fontFamily, weight, fontWeight, fontStyle, style, textTransform } = font;
                if (!name) name = fontFamily;
                if (!weight) weight = fontWeight;
                if (!style) style = fontStyle;
                let texts = font?.texts || [];
                let count = font.count;
                let parts = [];
                if (name) parts.push(`\tfont-family: ${name};`);
                if (weight) parts.push(`font-weight: ${weight};`);
                if (style) parts.push(`font-style: ${style};`);
                if (textTransform) parts.push(`text-transform: ${textTransform};`);
                // add a css comment with all the font info
                let comment = `\/* ${name} ${weight} ${style} */\n`;
                let commentParts = [
                    //
                    name,
                    `count: ${count}`,
                    `texts: ${texts?.join(", ")}`,
                    textTransform,
                    weight,
                    style,
                ];
                commentParts = commentParts.filter(Boolean);
                commentParts = commentParts.map(part => `/* ${part} */`);
                comment = commentParts.join("\n");
                let finalParts = [comment, `.font${index} {`, parts.join("\n\t"), "}"];
                return finalParts.join("\n") + "\n";
                // let fontString = `.font${index} { font-family: ${name}; font-weight: ${weight}; font-style: ${style}; }`;
            });
            fontClasses = [...new Set(fontClasses)];
            return fontClasses.join("\n\n");
        },
        buildCSSDivStyles(divStyles) {
            if (!divStyles) divStyles = this.divStyles;
            if (divStyles) {
                let themes = {};
                divStyles = divStyles.map((style, index) => {
                    let color = style.color;
                    let backgroundColor = style.backgroundColor;
                    let backgroundAlt = color;
                    let colorAlt = backgroundColor;
                    let border = `&.border{ border: 2px solid ${color}; }`;
                    let borderAlt = `&.border{ border: 2px solid ${backgroundColor}; }`;
                    color = color ? `color: ${color};` : "";
                    backgroundColor = backgroundColor ? `background-color: ${backgroundColor};` : "";
                    backgroundAlt = backgroundAlt ? `background-color: ${backgroundAlt};` : "";
                    colorAlt = colorAlt ? `color: ${colorAlt};` : "";
                    let styleString = [`.theme${index + 1}{`, color, backgroundColor, border].join("\n\t");
                    let styleStringAlt = [`.theme${index + 1}a {`, colorAlt, backgroundAlt, borderAlt].join("\n\t");
                    themes[`theme${index + 1}`] = `${color} ${backgroundColor}`;
                    themes[`theme${index + 1}a`] = `${colorAlt} ${backgroundAlt}`;
                    styleString = [styleString, "}"].join("\n");
                    styleStringAlt = [styleStringAlt, "}"].join("\n");
                    return [styleString, styleStringAlt].join("\n");
                });
                this.updateProp("themes", themes);
                return divStyles.join("\n");
            }
        },
        buildFonts(fonts) {
            // main new format
            if (!fonts) fonts = this.fonts || [];
            let cssString = "//assigned styles\n";
            if (fonts?.length > 0) {
                cssString = fonts.map(this.createFontFaceRule).join("\n") || "";
                let otherFonts = this.generateFontFaceRules(fonts);
                otherFonts = "";
                cssString += `${cssString}\n\n${otherFonts}`;
            }
            return cssString;
        },
        buildFontLinks(fonts) {
            if (!fonts) fonts = this.fonts;
            if (fonts && Array.isArray(fonts)) {
                this.fontLinks = fonts.filter(Boolean).map(font => {
                    if (!font) return;
                    let name = `"${font.name}"`;
                    let style = font?.style || font.fontStyle || "normal";
                    let weight = font?.weight || font.fontWeight || "normal";
                    const link = document.createElement("link");
                    link.rel = "stylesheet";
                    link.crossOrigin = "anonymous"; // Fixed typo: 'crossorigin' should be 'crossOrigin'
                    link.href = font.url;

                    // Font type based on file extension
                    if (font.url.includes(".eot")) link.type = "application/vnd.ms-fontobject";
                    else if (font.url.includes(".woff")) link.type = "font/woff";
                    else if (font.url.includes(".woff2")) link.type = "font/woff2";
                    else if (font.url.includes(".ttf")) link.type = "font/ttf";
                    else if (font.url.includes(".svg")) link.type = "image/svg+xml";
                    else if (font.url.includes(".otf")) link.type = "font/otf";
                    let id = `${name}-${style}-${weight}`;
                    this.appendToHead(link, id);
                    return link.outerHTML;
                });
                this.updateProp("css.fontLinks", this.fontLinks);
            } else {
                console.warn("Either `this.typography` or `this.typography.fonts` is not properly defined or is not an array.");
            }
        },
        buildFontFace(font) {
            if (!font) return;
            return this.createFontFaceRule(font);
            // let { name, url, src, originalSrc, display, srcArray, fontWeight } = font;
            // console.log("buildFontFace", font);
            // let urlString = "",
            //     srcString = "";
            // let weight = font?.weight || font.fontWeight || "normal";
            // let style = font?.style || font.fontStyle || "normal";
            // if (Array.isArray(src)) {
            //     let srcArray = [];
            //     src.forEach(src => {
            //         let { url, format = "woff" } = src;
            //         let string = ` url(\"${url}\") format(\"${format}\")`;
            //         srcArray.push(string);
            //     });
            //     srcString = `src: ${srcArray.join(", ")};`;
            // } else {
            //     const format = url.split(".").pop();
            //     srcString = `src: url(\"${url}\") format(\"${format}\");`;
            // }
            // if (src) srcString = `\tsrc: ${src};`;
            // // if(srcArray)
            // if (style) style = `font-style: ${style};`;
            // if (name) name = `font-family: ${name};`;
            // if (weight) weight = `font-weight: ${weight};`;
            // urlString = `url: "${urlString}";`;
            // if (display) display = `font-display: ${display};`;
            // let fontProps = [name, style, weight, display, srcString].filter(Boolean).join("\n\t");
            // // console.log(finalString.join("\n"));
            // return `@font-face {\n\t${fontProps}\n};\n`;
        },
        createFontFaceRule(font) {
            if (!font) return;
            let srcPaths = "";
            let fontURL = font.url;
            if (!fontURL.includes("https://")) return;
            if (fontURL.includes("../")) return;

            if (Array.isArray(fontURL)) {
                srcPaths = fontURL.map(url => {
                    if (!this.isGoogleOrTypekitFont(url)) {
                        const format = url.split(".").pop();
                        return `url('${url}') format('${format}')`;
                    } else {
                        return `url('${url}')`;
                    }
                });
                srcPaths = srcPaths.join(", ");
            } else if (typeof fontURL === "string") {
                const fontFormat = fontURL.split(".").pop().split("?")[0];
                srcPaths = `url('${fontURL}') format('${fontFormat}')`;
                if (font?.src) srcPaths = font.src;
            } else {
                throw new TypeError("font.url must be a string or an array of strings");
            }

            let weight = font?.weight || font.fontWeight || "normal";
            let name = font?.name || font.fontFamily || false;
            let style = font?.style || font.fontStyle || "normal";
            let stretch = font?.stretch || font.fontStretch || false;
            let display = font?.display || font.fontDisplay || "swap";
            if (name) name = `font-family: ${name};`;
            if (weight) weight = `font-weight: ${weight};`;
            if (style) style = `font-style: ${style};`;
            if (stretch) stretch = `font-stretch: ${stretch};`;
            if (display) display = `font-display: ${display};`;
            if (srcPaths) srcPaths = `src: ${srcPaths};`;
            if (srcPaths.includes("google") || srcPaths.includes("gstatic")) return;

            let fontProps = [name, weight, style, stretch, display, srcPaths].filter(Boolean).join("\n\t");
            let fontFaceCss = `@font-face {\n\t${fontProps}\n}`;
            let originalCss = font.css;
            let finalString = [fontFaceCss, originalCss].filter(Boolean).join("\n\n");
            return finalString;
            // return JSON.stringify(font);

            // return `@font-face {\n\t${fontName}\n\t${fontWeight}\n\t${fontStyle}\n\tfont-display: ${display};\n\tsrc: ${srcPaths};\n}`;
        },
        generateFontFaceRules(fonts) {
            if (!fonts) fonts = this.fonts || [];
            const fontFaceRules = [];
            if (!fonts) return [];
            fonts.forEach(font => {
                if (!font) return;
                const { name = "", url = "" } = font;
                let fontWeight = "normal"; // Default weight
                let fontStyle = "normal"; // Default style
                fontStyle = font.fontStyle || font.style || "normal";
                // Mapping font name keywords to font-weight
                if (name.includes("Thin")) fontWeight = "100";
                else if (name.includes("ExtraLight") || name.includes("UltraLight")) fontWeight = "200";
                else if (name.includes("Light")) fontWeight = "300";
                else if (name.includes("Regular") || name.includes("Normal")) fontWeight = "400";
                else if (name.includes("Medium")) fontWeight = "500";
                else if (name.includes("SemiBold") || name.includes("DemiBold")) fontWeight = "600";
                else if (name.includes("SemiBold") || name.includes("DemiBold")) fontWeight = "600";
                else if (name.includes("Bold")) fontWeight = "700";
                else if (name.includes("ExtraBold") || name.includes("UltraBold")) fontWeight = "800";
                else if (name.includes("Black") || name.includes("Heavy")) fontWeight = "900";
                if (name.includes("Italic") || name.includes("Oblique")) fontStyle = "italic";
                const rule = `@font-face {\n\tfont-family: ${name.split(" ")[0]};\n\tsrc: url('${url}') format('woff2');\n\tfont-style: ${fontStyle};\n\tfont-display: swap;\n}\n`;
                // const rule = `@font-face {\n\tfont-family: ${name};\n\tsrc: url('${url}') format('woff2');font-weight:\n\tfont-style: ${fontStyle};\n\tfont-display: swap;\n}\n`;
                fontFaceRules.push(rule);
                if (font.css) fontFaceRules.push("\n" + font.css + "\n");
            });

            return fontFaceRules.join("\n");
        },
        removeFontLinks() {
            try {
                this.fontLinks?.forEach(fontLink => fontLink?.parentNode === document.head && document.head.removeChild(fontLink));
                this.fontLinks = [];
            } catch (e) {
                console.error(e);
            }
        },
        mainLoadFonts(fonts) {
            if (!fonts) fonts = this.fonts || [];
            this.createFontStyleSheets(fonts);
            this.buildFontLinks(fonts);
        },
        createFontStyleSheets(fonts) {
            if (!fonts) fonts = this.fonts;
            let styleSheet;
            if (fonts?.length && Array.isArray(fonts)) {
                if (!fonts) return;
                let fontsStyles = fonts
                    .filter(font => font)
                    .filter(font => !font?.url?.includes("google"))
                    .map(font => {
                        if (!font?.url.startsWith("https://")) return;
                        let name = font.name;
                        let style = font?.style || font.fontStyle || "normal";
                        let weight = font?.weight || font.fontWeight || "normal";
                        let url = font.url;
                        const fontStyle = this.buildFontFace(font);
                        // const fontStyle = `@font-face {\n\tfont-family: '${name}';\n\tfont-weight: ${weight};\n\tfont-style: ${style};\n\tsrc: url('${url}');\n\tfont-display: swap;\n}`;
                        const styleElement = document.createElement("style");
                        styleElement.type = "text/css";
                        styleElement.innerHTML = fontStyle;
                        styleSheet = styleElement;
                        return this.appendToHead(styleElement, `${name}-${style}-${weight}`);
                    });
            } else {
                console.warn("Either `this.typography` or `this.typography.fonts` is not properly defined or is not an array.");
            }
        },
        appendFontsToHeader(fonts) {
            try {
                // Google Fonts
                if (!(fonts && Array.isArray(fonts))) {
                    console.warn("Either `this.typography` or `this.typography.fonts` is not properly defined or is not an array.");
                    return;
                }

                if (!fonts) fonts = this.fonts;
                let googleFonts = fonts
                    .filter(font => font.url.includes("fonts.googleapis.com"))
                    .map(font => {
                        return `family=${font.name.replace(" ", "+")}:ital,wght@0,${font.weight}`;
                    })
                    .join("&");
                let googleLink = `https://fonts.googleapis.com/css2?${googleFonts}&display=swap`;
                this.updateProp("css.googleLink", googleLink);
                if (googleFonts?.length > 0) {
                    const linkElement = document.createElement("link");
                    linkElement.href = googleLink;
                    linkElement.rel = "stylesheet";
                    document.head.appendChild(linkElement);

                    // Additionally, you could use preload for high-priority fonts here, but it's optional.
                }

                // Other Fonts
                const otherFonts = fonts
                    .filter(font => !(font.url.includes("fonts.gstatic.com") || font.url.includes("google")))
                    .map(font => {
                        let fixedURL = font.url.split("?")[0];
                        let fontFormat = "";
                        const formatMap = { eot: "woff", woff2: "woff2", woff: "woff", ttf: "truetype", otf: "opentype" };

                        for (const [key, value] of Object.entries(formatMap)) {
                            if (fixedURL.includes(key)) {
                                fontFormat = value;
                                break;
                            }
                        }
                        return this.buildFontFace(font);
                    })
                    .join("\n");
                // font-weight: ${font.weight};
                // font-style: ${font.style};
                const styleElement = document.createElement("style");
                styleElement.type = "text/css";
                styleElement.id = "font-styles";
                styleElement.innerHTML = otherFonts;
                // stringify styleElement
                const styleElementString = JSON.stringify(styleElement);
                this.updateProp("css.otherFonts", otherFonts);
                document.head.appendChild(styleElement);
                // console.log(styleElement);
            } catch (e) {
                console.error(e);
            }
        },
        removeFontStyles() {
            const styleEl = document.getElementById("dynamic-font-styles");
            if (styleEl) {
                document.head.removeChild(styleEl);
            }
        },
        getParagraphStyle() {
            if (this.styleGuide.pStyles) {
                let pStyles = this.styleGuide.pStyles;
                pStyles = pStyles.map(style => (parseInt(style.fontSize) > 0.8 && parseInt(style.fontSize) < 1.2 ? style : null)).filter(style => style !== null);
                // console.log("Paragraph Styles", pStyles);
                const pStyle = pStyles[0];
                // console.log("Paragraph Style", pStyle);
                return pStyle;
            }
        },
        objectToCss(styles, name, flip) {
            // Initialize the CSS string with the selector based on the provided name
            let cssString = `${name} {\n`;

            // Iterate over the styles object entries
            cssString += Object.entries(styles).reduce((acc, [key, value]) => {
                // Exclude specific keys from the CSS string
                // flip color and background color if flip is true
                if (flip) {
                    if (key === "color") key = "backgroundColor";
                    else if (key === "backgroundColor") key = "color";
                    // make the border color the same as the text color
                }
                if (!["score", "totalScore", "hasText", "texts"].includes(key)) {
                    // Convert camelCase keys to kebab-case for CSS properties
                    const kebabCaseKey = key.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
                    // Append the property and its value to the accumulator
                    return `${acc}\t${kebabCaseKey}: ${value};\n`;
                }
                return acc;
            }, "");
            if (flip) cssString += `\tbox-shadow: 0px 0px 0.3em ${styles.backgroundColor}!important;\n`;
            // Close the CSS block
            cssString += "}";

            return cssString;
        },
        async getButtonStyles() {
            let styles = this.styleGuide.buttonStyles;
            let btn = styles
                .map((b, index) => {
                    let buttonStyle = this.objectToCss(b, `.button-${index + 1}`);
                    // flip the colors on hover
                    let hoverStyle = this.objectToCss(b, `\n.button-${index + 1}:hover`, true);
                    let reverse = this.objectToCss(b, `\n.button-${index + 1}-alt`, true);
                    let reverseHover = this.objectToCss(b, `\n.button-${index + 1}-alt:hover`);
                    buttonStyle += hoverStyle;
                    buttonStyle += reverse;
                    buttonStyle += reverseHover;
                    return buttonStyle;
                })
                .join("\n");
            return btn;
        },
        buildBorders(styleGuide) {
            let color = styleGuide?.baseColor || "#000000";
            let border1 = `.border-b-brand {\n\tborder-bottom-color: ${color};\n\tborder-bottom-style: solid;\n\tborder-bottom-width: 2px;\n}`;
            let border2 = `.border-t-brand {\n\tborder-top-color: ${color};\n\tborder-top-style: solid;\n\tborder-top-width: 2px;\n}`;
            let border3 = `.border-brand-1 {\n\tborder-color: ${color};\n\tborder-style: solid;\n\tborder-width: 1px;\n}`;
            let border4 = `.border-brand-2 {\n\tborder-color: ${color};\n\tborder-style: solid;\n\tborder-width: 2px;\n}`;
            let styles = [border1, border2, border3, border4];
            return styles.join("\n");
        },

        applyStyles() {
            try {
                this.removeStyles();
                this.$nextTick().then(async () => {
                    await setTimeout(() => {}, 300);

                    this.styleElement = document.createElement("style");
                    let headers;
                    let header_styles;
                    let paragraph_styles;
                    if (!this.styleGuide) return;
                    if (this.styleGuide?.assignedStyles) {
                        // headers = this.getHeaderStyles();
                        headers = this.createCssRule("h1, h2, h3, h4, h5, h6, p", this.typography.headers || {});
                        // headers = this.createCssRule("h1, h2, h3, h4, h5, h6, p", this.styleGuide.assignedStyles || {});
                        //
                        // header_styles = this.createStyles(this.styleGuide.assignedStyles || {});
                        header_styles = this.createStyles(this.typography.headers || {});
                        if (this.styleGuide.assignedStyles["p"]) {
                            paragraph_styles = this.createCssRule("p", this.styleGuide.assignedStyles["p"]);
                        } else {
                            paragraph_styles = this.createCssRule("p", this.getParagraphStyle() || {});
                        }
                    }
                    const backgroundCSS = this.createBackgroundCSS();

                    //build fonts
                    let fonts = this.buildFonts(this.$store.getters["styleGuide/fonts"]);
                    // console.log("Fonts", fonts);

                    // const paragraph_styles = this.createCssRule("p", this.typography.paragraphs || {});
                    if ((this.styleGuide && this.styleGuide?.baseColor?.includes("255,255,255")) || this?.styleGuide?.baseColor?.includes("ffffff")) {
                        this.styleGuide.baseColor = "#000000";
                        console.log("color", this.styleGuide?.base?.color);
                    }
                    let set_base_size = "",
                        set_base_color = "";
                    let borderColor = "";
                    let linkColor = "";
                    if (this.linkColor) linkColor = `a, .link-color { \n\tcolor: ${this.linkColor}; \n}`;
                    borderColor = this.buildBorders(this.styleGuide);
                    set_base_size = this.styleGuide.baseSize ? `font-size: ${this.styleGuide.baseSize || 16}px !important;` : "";
                    if (this.styleGuide?.base?.backgroundColor) set_base_color = this.styleGuide?.base?.backgroundColor + "!important";
                    else set_base_color = "inherit";
                    let base_font_family = "";
                    let base_font_weight = "";
                    if (this.styleGuide?.base?.fontFamily) base_font_family = `\tfont-family: ${this.styleGuide?.base?.fontFamily};\n`;
                    if (this.styleGuide?.base?.fontWeight) base_font_weight = `\tfont-weight: ${this.styleGuide?.base?.fontWeight}\n`;
                    set_base_color = `.brand-bg {\n\tbackground:${set_base_color};\n\tcolor: ${this.styleGuide?.baseColor || "#000000"} !important;\n\t${set_base_size}\n${base_font_family}${base_font_weight}\n}\n${borderColor}`;

                    // const set_base_size = this.styleGuide.baseSize ? ` body { font-size: ${this.styleGuide.baseSize || 16}px !important; }\n` : "";
                    let { all_colors = [] } = this.colors;
                    let colors = this.buildCSSColors(all_colors);
                    // Combine all styles
                    let pStyles = this.styleGuide.pStyles
                        .reverse()
                        .map(p => {
                            return `.brand-bg p {\n${objectToCss(p)}}`;
                        })
                        .join("\n");
                    let buttonStyles = await this.getButtonStyles();
                    let themes = this.buildCSSDivStyles();
                    let fontStyles = this.$store.getters["styleGuide/assignedStylesArray"] || [];
                    fontStyles = this.buildFontClasses(fontStyles);
                    let stylesArray = [colors, fonts, set_base_color, headers, backgroundCSS, header_styles, paragraph_styles, linkColor, buttonStyles, themes, fontStyles];
                    if (this.$route.path.includes("admin")) {
                        stylesArray = [fonts, headers, header_styles, paragraph_styles, linkColor, buttonStyles, themes, fontStyles];
                    }
                    // const styles = `${fonts}\n${set_base_color}\n${headers}\n${backgroundCSS}\n\n${header_styles}\n${paragraph_styles}\n${linkColor}`;
                    const styles = stylesArray.join("\n");
                    this.updateProp("css.styles", styles);
                    this.styleElement.type = "text/css";
                    this.styleElement.innerHTML = styles;
                    this.styleElement.id = "style-guide";
                    // build font links
                    this.mainLoadFonts(this.fonts);

                    this.appendToHead(this.styleElement, "styleGuide");
                    this.appendFontsToHeader(this.fonts);
                    // this.updateProp("test", styleSheet);
                    setTimeout(() => {
                        this.$store.dispatch("styleGuide/updateStyleGuideProp", {
                            keyPath: `loadedFonts`,
                            value: true,
                            stream: false,
                        });
                        this.appliedStyles = true;
                    }, 1000);
                });
            } catch (err) {
                console.error(err);
            }
        },

        removeStyles() {
            try {
                this.appliedStyles = false;
                this.removeStyleElement();
                this.removeStyleSheetsById();
                this.removeFontLinks();
                this.removeFontStyles();
            } catch (err) {
                console.error(err.message);
            }
        },
        removeStyleSheetsById() {
            try {
                let mainsheet = document.getElementById("font-styles");
                if (mainsheet) {
                    mainsheet.remove();
                }
                this.styleIds.forEach(id => {
                    let sheet = document.getElementById(id);
                    if (sheet) {
                        sheet.remove();
                    }
                });
            } catch (e) {
                console.error(e);
            }
        },
        removeStyleElement() {
            if (!this.styleElement) return;
            try {
                document.head.removeChild(this.styleElement);
                this.styleElement = null;
            } catch (e) {
                console.log(e);
            }
        },
        createBackgroundCSS() {
            if (!this.styleGuide?.base) return;
            let color = "#000000";
            let background = "#ffffff";
            let size = 16;
            if (this.styleGuide?.base?.color) color = this.styleGuide?.base?.color;
            if (this.styleGuide?.baseBackground) {
                background = this.styleGuide?.baseBackground;
                if (background.includes("rgba(0, 0, 0, 0)")) background = "#ffffff";
            }
            if (this.styleGuide?.baseSize) size = this.styleGuide.baseSize;
            if (color?.includes("255, 255, 255") || color?.includes("#fff")) color = "#000000";
            if (this.styleGuide) {
                this.$store.dispatch("styleGuide/updateStyleGuideProp", {
                    keyPath: `baseColor`,
                    value: color,
                    save: false,
                });
            }

            const backgroundColor = chroma(background);
            const isBackgroundLight = backgroundColor.luminance() > 0.3;

            if (isBackgroundLight) {
                // color = "#000000"; // Dark text for light background
            } else {
                color = "#ffffff"; // Light text for dark background
                let allColors = this.colors?.all_colors;
                if (allColors?.length > 0) {
                    allColors = allColors.filter(color => color !== "#ffffff");
                    color = allColors[0];
                }
            }

            let cssString = `.brand-bg {\n\tbackground-color:${background}!important;\n\tcolor:${color}!important;\n\tfont-size:${size}px;\n}`;
            return cssString;
            // return `.brand-bg {${backgroundImageCss} background:${this.backgroundColors[0]}!important; color:${textColor} !important;}`;
        },
        isGoogleOrTypekitFont(url) {
            return url.includes("google") || url.includes("typekit") || url.includes("gstatic") || url.includes("googleapis");
        },
        createStyles(stylesObject) {
            return Object.entries(stylesObject)
                .map(([selector, styles]) => this.createCssRule(selector, styles))
                .join("\n");
        },
        createCssProperty(property, value) {
            if (value === 0 || value === "0" || value === "") return "";
            //TODO: check if important fucked up styles
            // Convert camelCase to kebab-case
            const kebabProperty = property.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
            let finalValue = `${kebabProperty}: ${value};`;
            if (["font-size"].includes(kebabProperty)) {
                try {
                    if (value) value = value.trim() || value;
                } catch (e) {
                    console.log(e.message);
                    console.log(value);
                }
                if (value && value !== "") finalValue = `${kebabProperty}: ${value};`;
                // if (value && value !== "") finalValue = `${kebabProperty}: ${value}!important;`;
                // finalValue = `${kebabProperty}: ${value}rem!important;`;
            }
            if (["letter-spacing", "line-height"].includes(kebabProperty)) {
                if (typeof value === "number") {
                    if (value && value !== null) finalValue = `${kebabProperty}: ${value};`;
                    // if (value && value !== null) finalValue = `${kebabProperty}: ${value}!important;`;
                    // console.log(finalValue);
                    // finalValue = `${kebabProperty}: ${value}em!important;`;
                } else {
                    try {
                        if (value && value !== "") value = value.trim();
                    } catch (e) {
                        console.log(e.message);
                        console.log(value);
                    }
                    if (value && value !== "" && value !== null && value !== "null") finalValue = `${kebabProperty}: ${value};`;
                    // if (value && value !== "" && value !== null && value !== "null") finalValue = `${kebabProperty}: ${value}!important;`;
                    // console.log(finalValue);
                    // finalValue = `${kebabProperty}: ${value}em!important;`;
                }
            }
            return finalValue;
        },
        appendToHead(element, id) {
            if (id) {
                element.id = id;
                this.styleIds.push(id);
            }

            document.head.appendChild(element);
            return element;
        },
        createCssRule(selector, styles) {
            const selectors = selector.split(", ");
            let cssRules = "";

            for (let i = 0; i < selectors.length; i++) {
                const tagName = selectors[i].trim();
                const style = styles[tagName];

                if (!style) continue;
                // console.log(selector[i], styles[tagName]);
                let cssProperties = Object.entries(style).map(([property, value]) => {
                    // if (value && value !== "" && !Array.isArray(value)) console.log(property, deepCopy(value));
                    if (value && value !== "" && !Array.isArray(value)) return "\t" + this.createCssProperty(property, value);
                });
                cssProperties = cssProperties.filter(prop => prop).join("\n");
                // console.log(cssProperties);

                if (cssProperties) cssRules += `${tagName} {\n${cssProperties}\n}\n`;
            }

            return cssRules;
        },
    },
};
