<template>
    <div v-if="progress">
        <div class="bg-snow mx-auto br-10 border-light shadow">
            <ProgressStats v-if="stats" :stats="stats" :title="title" />
            <template v-for="(item, index) in progress.slice(0, 10)" :key="'i' + index">
                <SourceProgressItem v-if="item" :item="item" />
            </template>
        </div>
    </div>
</template>
<script>
import SourceProgressItem from "@/views/SourceProgressItem.vue";
import ProgressStats from "@/views/ProgressStats.vue";

export default {
    name: "ReadPageProgress",
    components: { ProgressStats, SourceProgressItem },
    props: {
        sources: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: "Sources",
        },
    },
    computed: {
        status() {
            return this.progress?.map(s => s?.status) || [];
        },
        stats() {
            try {
                let progress = this.progress || [];
                let stats = {
                    processed: progress?.map(p => p?.processed).reduce((a, b) => a + b, 0),
                    sources: progress?.length,
                    total: progress?.map(p => p?.total).reduce((a, b) => a + b, 0),
                    existing: progress?.map(p => p?.existing).reduce((a, b) => a + b, 0),
                    failed: progress?.map(p => p?.failed).reduce((a, b) => a + b, 0),
                    rejected: progress?.map(p => p?.rejected).reduce((a, b) => a + b, 0),
                    batches: progress?.map(p => p?.batches).reduce((a, b) => a + b, 0),
                    products: progress?.map(p => p?.products).reduce((a, b) => a + b, 0),
                    images: progress?.map(p => p?.images).reduce((a, b) => a + b, 0),
                    links: progress?.map(p => p?.links).reduce((a, b) => a + b, 0),
                    "Current Batch": progress?.map(p => p?.currentBatch).reduce((a, b) => a + b, 0),
                    "Total Batches": progress?.map(p => p?.totalBatches).reduce((a, b) => a + b, 0),
                };
                return stats;
            } catch (e) {
                console.log(e);
            }
        },
        progress() {
            try {
                let sources = this.sources.progress || [];
                // let sourcesArray = Object.keys(sources).map(key => ({
                //     ...sources[key], // Spread the properties of the image
                //     urlOld: key, // Add the original key as the 'url' property
                // }));
                let sourcesArray = sources;
                sourcesArray.sort((a, b) => b?.updated - a?.updated);
                return sourcesArray;
            } catch (e) {
                console.log(e);
            }
            // return this.sources?.progress || [];
        },
    },
};
</script>
