// getCopyGuideMixin.js
import { mapGetters } from "vuex";
import { formatObjectToString } from "@/mixins/objectToString";
export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters("stream", ["messages"]),

        imageList() {
            return this.ImageList;
        },
        CopyGuidelinesMixin() {
            // this outputs a string
            let guidelines = this.$store.getters["styleGuide/copyGuidelines"] || {};
            let examples = guidelines["guidelines"]
                .map(guideline => {
                    return "\n" + "**" + guideline?.name?.toUpperCase() + "**\n" + guideline?.examples?.map(example => `"${example}"`).join("\n");
                })
                .join("\n");
            // emailMessages.push(userMessage("More copy examples \n\n" + examplesFromCopyGuide));

            if (guidelines?.length > 0) {
                try {
                    guidelines = formatObjectToString(guidelines);
                    // guidelines = sysMessage(`${guidelines}`);
                } catch (e) {
                    console.error(e.message);
                }
            }
            return {
                guidelines,
                examples,
            };
        },
        // campaignMessages
        emailMessages() {
            let messages = [];
            if (this.AboutBrand) messages.push(sysMessage(this.AboutBrand));
            if (this.BrandValues) messages.push(sysMessage(this.BrandValues));
            if (this.Audience) messages.push(sysMessage(this.Audience));
            // if (productArray) messages.push(sysMessage(productArray));
            if (this.ValueProps) messages.push(userMessage(this.ValueProps));
            if (this.WritingDevices) messages.push(userMessage(this.WritingDevices));
            if (this.SellingPoints) messages.push(userMessage(this.SellingPoints));
            if (this.EmailSubjectPreview) messages.push(userMessage(this.EmailSubjectPreview));
            if (this.headerParagraph) messages.push(userMessage(this.headerParagraph));
            let additionalMessages = [
                userMessage("Don't be too explicit about the product in the copy."),
                userMessage("Assume the customer is smart enough to understand the product."),
                userMessage("Don't mention testimonials, reviews, etc."), //
            ];
            messages.push(...additionalMessages);
            return messages;
        },
    },
    methods: {
        // beforeStarting() {},
        // whileProcessing(json) {},
        // afterCompletion(obj) {
        //     let { save, result } = obj;
        //     console.log("After completion called", obj);
        //     this.$store.dispatch("styleGuide/saveStyleGuide", this.styleGuide); // the generativeMixin will call this after the stream is complete.
        // },
        messageMap() {
            let products = "";
            let headers = this.headerExamples;
            let paragraphs = this.paragraphs;
            let images = "";
            let about = "";
            let brandVoice = "";
            let copyGuidelines = this.copyGuidelines;
            let headerParagraph = this.headerParagraph;
            let emailSubjectPreview = this.emailSubPrev;
            let brandColors = "";
            let personas = "";
            let guidelines = this.$store.getters["styleGuide/copyGuidelines"]; // string
            let allWebCopy = "";
            let productCopy = this.productCopyMixin;
            guidelines = sysMessage("");

            images = this.imageList; // string
            about = this.aboutBrand; // string

            // brandVoice = this.$store.getters["styleGuide/llmColorsString"]; // string
            // allWebCopy = this.$store.getters["styleGuide/llmAllWebCopy"]; // string

            copyGuidelines =
                guidelines =
                // if (copyExamples?.copywriting) copyExamples = `${JSON.stringify(copyExamples?.copywriting)}`;
                brandColors =
                    this.$store.getters["styleGuide/llm/ColorsString"]; // string
            if (copyGuidelines) copyGuidelines = copyGuidelines.replace(/\n+/g, "\n");
            // let campaign = this.$store.state?.stream?.stream?.campaignData;
            // let campaignMessage;
            // if (campaign) campaignMessage = userMessage(`This is the current state of the campaign \n\n${JSON.stringify(campaign.result)}`);
            // if (productCopy) productCopy = productCopy.slice(0, 100).join("\n");
            // Define a mapping of action names to their corresponding message arrays

            try {
                let brandVoiceMessages = [
                    sysMessage(about),
                    sysMessage(copyGuidelines),
                    userMessage(headerParagraph),
                    //
                ];
                let createBrandVoiceGuide = [
                    //
                    sysMessage(about),
                    userMessage(headerParagraph),
                ];

                // , sysMessage(allWebCopy)
                // sysMessage(copyGuidelines),

                if (productCopy) createBrandVoiceGuide.push(sysMessage(productCopy));
                if (this.styleGuide?.copyGuidelines?.overview?.personas.length > 0) {
                    personas = formatObjectToString({ overview: { personas: this.styleGuide?.copyGuidelines.overview.personas } });
                    personas = userMessage(personas);
                }
                guidelines = this.CopyGuidelines;
                let emailMessages = this.emailMessages;
                return {
                    create_email_new: [
                        //
                        guidelines,
                        ...emailMessages,
                    ],
                    create_campaign: [
                        sysMessage(about),
                        //
                        sysMessage(copyGuidelines),
                        guidelines,
                        // sysMessage(products),
                        userMessage(emailSubjectPreview),
                    ],
                    create_email_section: [
                        //
                        guidelines,
                        ...emailMessages,
                    ],
                    create_landing_page: [
                        sysMessage(about),
                        sysMessage(copyGuidelines),
                        guidelines,
                        // sysMessage(images),
                        // sysMessage(products),
                        // sysMessage(productCopy),
                    ],
                    get_brand_voice: createBrandVoiceGuide,
                    get_brand_voice_continued: [
                        //
                        guidelines,
                        sysMessage(copyGuidelines),
                        ...createBrandVoiceGuide,
                    ],
                    get_brand_personas: createBrandVoiceGuide,
                    create_brand_voice_samples: brandVoiceMessages,
                    generate_synthetic_brand_copy: [
                        //
                        ...createBrandVoiceGuide,
                        sysMessage(copyGuidelines),
                        userMessage("Don't be too explicit about the product in the copy. Assume the customer is smart enough to understand the product."),
                    ],
                    presentation: [userMessage(headerParagraph)],
                    create_typography_style_guide: [
                        //
                        sysMessage(about),
                        userMessage(`${JSON.stringify(this.$store.getters["styleGuide/allTextStyles"], null, 2)}`),
                        userMessage(`${JSON.stringify(this.$store.getters["styleGuide/fonts"], null, 2)}`),
                        userMessage(`${JSON.stringify(this.$store.getters["styleGuide/buttonStyles"], null, 2)}`),
                    ],
                };
            } catch (e) {
                console.error(e.message);
                return [];
            }
        },
    },
};
