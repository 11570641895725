import { mapActions } from "vuex";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            newTask: "",
            record_thoughts: {
                name: "Record Thoughts",
                command: "record_thoughts",
                start: "[think]",
                end: "[think]",
                kind: "Thinking",
                role: "assistant",
                description: "Write down thought every time you respond.",
                show: true,
                newMessage: false,
                resources: ["Improve your reasoning"],
            },
        };
    },
    computed: {},
    methods: {
        async recordThoughts(args, name, message, index) {
            // record_thoughts
            let thoughts, reasoning, plan, criticism, speak;
            let string = "";
            if (args.thoughts) thoughts = args.thoughts;
            if (args.reasoning) reasoning = args.reasoning;
            if (args.plan) plan = args.plan;
            if (args.criticism) criticism = args.criticism;
            if (args.speak) speak = args.speak;
            // if (thoughts) string += "thoughts:" + thoughts;
            // if (reasoning) string += "reasoning:" + reasoning;
            // if (plan) string += "plan:" + plan;
            // if (criticism) string += "criticism:" + criticism;
            // if (speak) string += "speak:" + speak;

            // make a string of key(to uppercase) and value from args
            // args.forEach((value, key) => {
            //     string += `${key}:${value}\n`;
            // });
            string += this.keyValueToString(args);
            return args;
        },
        async removeTask(task) {
            this.$store.dispatch("chat/tasks/removeTaskFromStore", task);
        },
        // ...mapActions("chat", ["addToMemoryStore"]),
    },
};
