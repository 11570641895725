
            (function () {
                const environment = import.meta.env.VITE_ENV;
                var env = environment;
                var faviconPath;
                switch (env) {
                    case "dev":
                        faviconPath = "/favicon-dev.ico";
                        break;
                    case "prod":
                        faviconPath = "/favicon-prod.ico";
                        break;
                    case "test":
                        faviconPath = "/favicon-test.ico";
                        break;
                    default:
                        faviconPath = "/favicon-dev.ico";
                        break;
                }

                var faviconLink = document.createElement("link");
                faviconLink.rel = "icon";
                faviconLink.type = "image/x-icon";
                faviconLink.href = faviconPath;
                document.head.appendChild(faviconLink);
            })();
        