<template>
    <div>
        <UIWrapperText :title="title">
            <template #left>
                <slot></slot>
            </template>
            <div class="f gap-3 jcs text-left" :class="{ 'fc inline-flex': !bubble }">
                <div v-for="(item, index) in list" :key="'a' + index" class="f f-34 border-brand border-b-brand last:border-0 pb-4 !last:pb-0 gap-4 mb-2" :class="classes">
                    <i class="far mr-2 fa-check" v-if="check" />
                    <span>{{ item }}</span>
                </div>
            </div>
        </UIWrapperText>
    </div>
</template>
<script>
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";
import ChartTone from "@/components/generative_templates/Guidelines/ChartTone.vue";

export default {
    name: "TraitList",
    components: { ChartTone, UIWrapperText },
    props: {
        list: {},
        title: {},
        icon: {},
        check: {
            type: Boolean,
            default: true,
        },
        bubble: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {};
        },
    },
};
</script>
