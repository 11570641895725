<template>
    <div :class="{ 'width-max-120 width-120': isLogo, 'd-none': imageFailed }" class="p-relative" @mouseleave="hovered = false">
        <div :key="url" :class="{ '!bg-gray-50 ': isLogo && !divStyles, 'aspect-h-1 aspect-w-1 col-span-1 border-light': !size, 'x y': size }" :style="`background:${colorToUse}`" class="p-relative br-5 relative z-0" @mouseenter="hovered = true">
            <div v-if="imageLoading" class="p-absolute p-full f aic jcc">
                <loading-spinner></loading-spinner>
            </div>
            <a v-else :href="image.croppedImage" class="p-absolute p-fill x h-auto" target="_blank" @click="selectedImage = image" :style="`margin-bottom:${bottomOffset}px!important`">
                <div v-if="isLogo" class="p-relative x y object-contain mx-auto" :style="`background:${colorToUse}`">
                    <div class="bg-gray-400/10 x y z-0 p-absolute p-fill br-10"></div>
                    <div class="p-relative x y p-4">
                        <img v-if="isLogo" :src="url" alt="Image" class="visually-hidden o-contain x bg-red block" style="margin: 0px !important; display: block" />
                        <div :style="[maskStyleNew]" class="p-x y block"></div>
                    </div>
                </div>
                <img v-else-if="image.transparent" :src="url" class="p-absolute p-fill x y o-contain p-3" @error="imageFailed = true" alt="img2" />
                <img v-else :src="url" alt="Image" class="p-absolute p-fill x y object-cover" />

                <div class="p-absolute top left f fc m-3 gap-1">
                    <div v-if="(image?.width < 400 || image?.height < 400) && !isLogo" class="br-5 f-13 bg-rose-700 p-1 px-2 text-snow text-center">Small image</div>
                </div>
                <transition name="fade-up">
                    <div v-if="hovered" class="p-absolute bottom left f fc m-3 gap-1 z-1">
                        <ImageDetails :image="image" class="z-1 f-11" />
                        <div class="z-1 fc f gap-1">
                            <template v-if="!isLogo">
                                <div v-if="isLogo">
                                    <div class="border-light br-5 f-11 bg-blur bg-snow/50 text-black inline-block p-1 px-2">Logo</div>
                                </div>
                                <div v-if="image.seamlessBg">
                                    <div class="border-light br-5 f-11 bg-snow/50 inline-block p-1 px-2 bg-blur text-black">Seamless</div>
                                </div>
                                <div v-if="image.transparent">
                                    <div class="border-light br-5 f-11 bg-snow/50 inline-block p-1 px-2 bg-blur text-black">Transparent</div>
                                </div>
                            </template>
                            <div>
                                <button class="border-light br-5 f-11 bg-snow/50 inline-block p-1 px-2 bg-blur text-black" popovertarget="my-popover">
                                    {{ image.width }}
                                    <i class="fas fa-times" />
                                    {{ image.height }}
                                </button>

                                <!--                                <button>Open Popover</button>-->
                                <transition name="fade-up" appear>
                                    <div class="bg-gray-900/10 x y border-none" popover id="my-popover" popovertarget="my-popover">
                                        <div class="p-4 bg-snow border-light br-20 shadow mx-auto my-5 mh-90 mw-600 overflow-y-scroll scrollbar-hide">
                                            <dynamic-object-viewer :preferred-keys="['colors', 'dominant', 'average', 'mixed', 'second', 'primary', 'color', 'backgroundColor', 'colorTransparent', 'bottomColor']" :data-object="image"></dynamic-object-viewer>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </transition>
                <!--                <image-gradient v-if="image.bottomColor" :height="50" :image="{ color: image.bottomColor }" />-->
                <!--                <image-gradient v-else-if="image.color" :height="50" :image="{ color: image.color }" />-->
                <div v-if="isAdmin" class="p-absolute top right p-2 z-5 gap-2 f">
                    <!--                    {{ image.entropy }}-->
                    <div v-if="false && image?.entropy" class="relative">
                        <BaseBadge v-if="image?.entropy?.containsText" text="HAS TEXT"></BaseBadge>
                        <BaseBadge v-if="image?.entropy?.entropy" :text="'entropy ' + image.entropy.entropy.toFixed(1)"></BaseBadge>
                        <BaseBadge v-if="image?.entropy?.sharpness" :text="'sharpness' + image.entropy.sharpness.toFixed(1)"></BaseBadge>
                    </div>
                </div>
                <div v-if="isAdmin && hovered" class="p-absolute top right p-2 z-5 gap-2 f">
                    <BaseButton v-if="isLogo" :icon="logoIcon" icon-only rounded size="xxs" style-type="brand" @click.prevent="makeMainLogo(image)"></BaseButton>
                    <BaseButton v-if="!isLogo" icon="fa fa-sync" icon-only rounded size="xxs" style-type="brand" @click.prevent="refreshImage(image)"></BaseButton>
                    <BaseButton icon="fa fa-times" icon-only rounded size="xxs" style-type="brand" @click.prevent="deleteImage(image)"></BaseButton>
                </div>
            </a>
        </div>
        <div v-if="isAdmin && hovered && !isLogo" class="x f-11 my-1 text-gray-500 mt-2">
            <div class="x f jcs aic flex-wrap gap-1 relative">
                <div class="f aic jcs f-11 gap-1">
                    <div v-if="image.caption" class="x f-15 line-clamp-3 mr-2 w-75">{{ image.caption }}</div>
                    <div v-else-if="image.alt" class="x f-15 line-clamp-3 mr-2 w-75">{{ image.alt }}</div>
                </div>
                <div>
                    <!--                    <BaseButton v-if="hovered && isLogo" label="remove logo" @click.prevent="toggleAsLogo(image)" size="xxs" rounded class="ml-3"></BaseButton>-->
                    <BaseButton v-if="image.transparent && hovered && !isLogo" class="ml-3" label="make logo" rounded size="xxs" @click.prevent="toggleAsLogo(image)"></BaseButton>
                </div>
                <!--                {{ image }}-->
            </div>
            <span v-if="image.keywords" class="d-block">
                Keywords:
                <template v-for="keyword in image.keywords">
                    <span class="">{{ keyword }}</span>
                </template>
            </span>
            <div class="p-2 top left p-absolute z-10 mw-300">
                <ProductBadge class="w-90 x" :product="relatedProduct" />
            </div>
        </div>
        <UISlideOver :item="{ ...selectedImage, product: relatedProduct }" @close="selectedImage = null" :open="!!selectedImage" />
    </div>
</template>
<script>
import resizeImage from "@/mixins/StyleGuideMixins/imageResizer";
import chroma from "chroma-js";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import ImageDetails from "@/components/styleGuide/Images/ImageDetails.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import ProductBadge from "@/components/styleGuide/Images/ProductBadge.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import UISlideOver from "@/components/Admin/UISlideOver.vue";

export default {
    name: "StyleGuideImage",
    components: { UISlideOver, BaseBadge, DynamicObjectViewer, ProductBadge, ImageDetails, LoadingSpinner, BaseButton },
    mixins: [styleGuideSettingsMixin],
    data() {
        return {
            hovered: false,
            imageLoading: false,
            imageFailed: false,
            selectedImage: null,
        };
    },
    methods: {
        ...mapActions("styleGuide", ["updateImage", "markAsLogo"]),
        makeMainLogo(image) {
            return this.$store.dispatch("styleGuide/makeLogo", image?.url);
        },
        toggleAsLogo(image) {
            this.$store.dispatch("styleGuide/markAsLogo", image);
        },
        maskBackground() {
            if (this.isLogo) {
                const image = this.url;
                let colorOverride;
                if (this.styleGuide?.divStyles?.[0]?.backgroundColor) {
                    this.colorToUse = this.divStyles[0].color;
                    colorOverride = this.divStyles[0].color;
                    return `background:${colorOverride}!important;`;
                }
            }
        },
        async deleteImage(image, id) {
            if (!id) id = this.$route.params.id;
            if (this.isLogo) await this.$store.dispatch("styleGuide/deleteImage", image);
            else {
                this.$store.dispatch("styleGuide/image/deleteImage", { id: id, url: image.url, image });
            }
            try {
                this.$store.dispatch("styleGuide/deleteImage", image);
            } catch (e) {}
        },
        async refreshImage(image, id) {
            this.imageLoading = true;
            try {
                if (!id) id = this.$route.params.id;
                if (image) image = this.image;
                // let url = this.image?.originalURL || this.image?.url;
                // await this.updateImage(url);
                await this.$store.dispatch("styleGuide/image/updateImage", { id, image });
                this.imageLoading = false;
            } catch (error) {
                this.imageLoading = false;
            }
        },
    },
    computed: {
        ...mapGetters("styleGuide", ["products", "divStyles", "linkColor"]),

        maskStyleNew() {
            if (this.isLogo) {
                const image = this.url;
                let colorOverride;
                if (this.divStyles?.[1]?.color) {
                    colorOverride = this.divStyles[1].color;
                    colorOverride = this.linkColor;
                    return `mask-image: url('${image}'); mask-size: contain; mask-repeat: no-repeat; mask-position: center; background:${colorOverride}!important; -webkit-mask-image: url('${image}'); -webkit-mask-size: contain; -webkit-mask-repeat: no-repeat; -webkit-mask-position: center; z-index:3000!important;`;
                }
            }
        },

        relatedProduct() {
            let product;
            if (this.image.source) product = this.image.source;
            // find the product in the products array
            if (this.products) {
                product = this.products.find(product => product.handle === this.image.source);
                if (!product) product = this.products.find(product => product.handle === this.image?.handle);
            }
            return product;
        },
        logoIcon() {
            if (this.isLogo) {
                if (this.isMainLogo) return "fas fa-star !text-gray-900";
                else return " far fa-star ";
            } else return false;
        },
        isMainLogo() {
            return this.isLogo && this.index === 0;
        },
        isLogo() {
            return this.image.kind === "logo";
        },
        colorToUse() {
            if (this.isLogo && this.divStyles?.[1]?.color) {
                let color = this.findContrastingColor();
                return color + "!important";
                // return this.divStyles[1].backgroundColor + "!important";
            } else if (this.image.backgroundColor) {
                return this.image.backgroundColor;
            } else if (this.image.colorToUse) {
                let colorToUse = chroma(this.image.colorToUse).hex();
                return colorToUse;
            }
        },
        url() {
            if (this.size) {
                let size = this.size;
                return resizeImage(this.image, size, "cover");
                console.log(i);
                let crop = "crop";
                if (this.image.transparent) crop = "contain";

                if (typeof size === "string" || typeof size === "number") {
                    return this.image?.url?.replace("Original", `width=${size},height=${size},fit=crop,gravity=auto`)?.replace("public", `width=${size},height=${size},fit=${crop},gravity=auto`);
                } else if (size?.width && size?.height) {
                    let { width, height } = size;
                    return this.image?.url?.replace("Original", `width=${width},height=${height},fit=crop,gravity=auto`)?.replace("public", `width=${width},height=${height},fit=${crop},gravity=auto`);
                }
            } else {
                if (this.image && this.image.crops) {
                    let newURL = this.image?.url?.replace("Original", "squareSmall");
                    newURL = resizeImage(this.image, 400, "cover");
                    return newURL || this.image.url;
                } else {
                    let newURL = this.image?.url?.replace("Original", "squareSmall");
                    newURL = resizeImage(this.image, 400, "cover");
                    return newURL || this.image.url;
                }
            }
        },
    },
    props: {
        bg: {},
        image: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            default: 0,
        },
        size: {
            type: [String, Number, Object],
        },
        bottomOffset: {
            type: Number,
            default: 0,
        },
    },
};
</script>
<style scoped lang="scss">
.bubble-badge {
    @apply font-bold inline-block rounded-full bg-gray-50 px-2 py-0.5 text-center;
}
</style>
