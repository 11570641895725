<template>
    <div ref="chatList" class="chat-list">
        <div class="my-3 px-2">
            <BaseButton class="x d-block mb-0 !justify-start !py-3 text-left !font-semibold" icon="fas fa-plus" label="New chat" size="lg" style-type="secondary" @click.prevent="createNewChat()" />

            <!--            <h6 class="mb-0">Quick start</h6>-->
            <div class="align-items-stretch mb-3 grid grid-cols-2 flex-wrap gap-2 py-3" v-if="recentTopics">
                <template v-for="topic in recentTopics.slice(0, 10)">
                    <BaseButton v-if="topic.topic" :label="topic.topic" class="border-1 cols-span-2 !justify-between truncate text-left transition-all" icon="fas fa-plus !opacity-40" reverse size="xs" style-type="secondary" @click.prevent="quickStart(topic)" />
                </template>
                <!--                <BaseButton v-for="topic in recentTopics.slice(0, 10)" style-type="secondary" class="bg-transparent" @click.prevent="quickStart(topic)" size="xxs" rounded :label="topic.topic" />-->
            </div>
        </div>

        <div class="my-3">
            <div class="px-3">
                <h6 class="mb-0">Filter</h6>
            </div>
            <div class="x d-block overflow-scroll scrollbar-hide">
                <div class="f f-15 gap-1 gap-2 px-2 py-3" v-if="recentTopics">
                    <template v-for="topic in recentTopics.slice(0, 10)">
                        <BaseButton v-if="topic.topic" :label="topic.topic" class="bg-transparent" rounded size="xxs" style-type="secondary" @click.prevent="selectedCategory = topic.topic">
                            <i class="fal fa-times ml-1" @click.prevent="removeSubjectFromLocalStorage(topic.topic)"></i>
                        </BaseButton>
                    </template>
                    <BaseButton class="bg-transparent" label="See all chats" reverse rounded size="xxs" style-type="secondary" @click.prevent="selectedCategory = null" />
                </div>
            </div>
        </div>
        <ul class="f fc list">
            <div class="relative px-3">
                <BaseInput id="inputExample" :modelValue="searchInput" label="Search messages" placeholder="Search for a chat" @update:modelValue="searchInput = $event"></BaseInput>
            </div>

            <li v-for="(chat, index) in filteredChats" :key="chat.id">
                <ChatListItem :chat="chat" :index="index" @delete-chat="deleteChat" @update-title="updateTitle"></ChatListItem>
            </li>
        </ul>
    </div>
</template>

<script>
import { db } from "@/firebase";
import ChatListItem from "@/components/chat/ChatList/ChatListItem.vue";
import datingBot from "@/mixins/Chat/DatingBot";
import { debounce } from "lodash";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapActions, mapGetters } from "vuex";
import AssistantsList from "@/components/chat/ChatList/AssistantsList.vue";

export default {
    components: { AssistantsList, BaseButton, BaseInput, ChatListItem },
    mixins: [datingBot],
    data() {
        return {
            selectedCategory: null,
            lastVisible: null,
            chatsLimit: 100,
            searchInput: "",
            chatListRef: null,
            fetchingMoreChats: false,
        };
    },
    props: {
        currentChat: {
            type: Object,
        },
    },
    async mounted() {
        // this.subscribeToChats();
        const ownerId = this.user.uid;
        this.subscribeToChats({ ownerId, chatsLimit: this.chatsLimit });
        // await this.fetchChats(); // Await chat fetching before checking for the latest chat

        // Check if there is no chat id present in route params
        if (!this.$route.params.id && this.ownerChats.length > 0) {
            // Get the most recent chat
            const mostRecentChat = this.ownerChats[0];

            // Redirect to the most recent chat's URL
            this.$router.push({ path: `/chat/${mostRecentChat.id}` });
        }

        this.$nextTick(() => {
            if (this.$refs.chatList) {
                this.chatListRef = this.$refs.chatList;
                this.chatListRef.addEventListener("scroll", this.handleScroll);
            }
        });
    },

    computed: {
        ...mapGetters("chat", ["ownerChats"]),
        filteredChats() {
            const search = this.searchInput.trim().toLowerCase();

            // Split the search input into individual words
            const searchWords = search.split(" ").filter(word => word.length > 0);

            if (searchWords.length === 0) return this.chatFilteredByCategory;
            const filteredChatResult = this.chatFilteredByCategory.filter(chat => {
                const chatTitle = chat?.title ?? chat?.chatTitle;

                // Check if all search words are in the chat title
                const titleMatch = searchWords.every(word => chatTitle && chatTitle.toLowerCase().includes(word));

                // Check if all search words are in any of the chat messages (non-system messages)
                const messageMatch =
                    chat.messages &&
                    chat.messages.some(message => {
                        if (message.type === "system") return false;
                        if (!message.content || typeof message.content !== "string") return false;

                        const content = message.content.toLowerCase();
                        return searchWords.every(word => content.includes(word));
                    });

                return titleMatch || messageMatch;
            });
            console.error(filteredChatResult);
            return filteredChatResult;
        },
        chatFilteredByCategory() {
            if (!this.selectedCategory) return this.ownerChats;
            return this.ownerChats.filter(chat => chat.topic === this.selectedCategory);
        },
    },
    methods: {
        ...mapActions("chat", ["subscribeToChats", "createNewChat"]),
        async quickStart(topic) {
            console.error(topic);
            await this.createNewChat(topic);
            return;
        },
        async fetchChats(ownerId) {
            const lastVisible = this.$store.state.chat.lastVisible;
            await this.subscribeToChats({ ownerId, chatsLimit: this.chatsLimit, startAfter: lastVisible });
        },
        handleScroll: debounce(async function () {
            const scrollTop = this.chatListRef.scrollTop;
            const scrollHeight = this.chatListRef.scrollHeight;
            const clientHeight = this.chatListRef.clientHeight;

            if (!this.fetchingMoreChats && scrollTop + clientHeight >= scrollHeight) {
                this.fetchingMoreChats = true;
                await this.fetchChats(this.user.uid);
                this.fetchingMoreChats = false;
            }
        }, 300),
        deleteChat({ chat, index }) {
            this.ownerChats.splice(index, 1);
            db.collection("chats").doc(chat.id).delete();
        },
        currentChatName() {
            let chatId = this.$route.params.id;
            const chat = this.ownerChats.find(chat => chat.id === chatId);
            if (!chat) return;
            return chat.title;
        },
        updateTitle({ title, chat, index }) {
            this.editTitle(chat.id, title);
            this.ownerChats[index].title = title;
        },
        editTitle(chatId, newTitle) {
            db.collection("chats").doc(chatId).update({ chatTitle: newTitle });
        },
    },
    beforeDestroy() {
        this.chatListRef.removeEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        if (this.$refs.chatList) {
            this.$refs.chatList.removeEventListener("scroll", this.handleScroll);
        }
    },
};
</script>
<style lang="scss">
.chat-list {
    @apply w-full;
    @apply md:max-w-xs;
}
</style>
