<template>
    <div class="grid grid-cols-12">
        <div v-for="(img, i) in landingPageImages?.slice(0, 1)" class="grid grid-cols-1 md:col-span-12 md:grid-cols-12 br-10 text-black overflow-hidden my-5">
            <div :class="[i % 2 === 0 ? 'col-span-12' : 'col-span-6 order-last', 'aspect-h-1 aspect-w-2 relative']" :style="`background:${img?.colors?.[0]}`">
                <!--                <img class="o-cover x" :src="imageHelper(i, { width: 2000, height: 1000 })" />-->
                <StyleGuideImage :image="img" :size="{ width: 2000, height: 1000 }" class="x y" />
            </div>
        </div>

        <template v-for="(imageObj, i) in imageArrayWithData">
            <div class="grid grid-cols-1 md:grid-cols-12 br-10 text-black overflow-hidden my-5" :class="{ 'md:col-span-9': showAds, 'md:col-span-12': !showAds }">
                <div :class="[i % 2 === 0 ? 'col-span-6' : 'col-span-6 order-last', 'aspect-h-1 aspect-w-1 relative']" :style="`background:${imageObj?.colors?.[0]}`">
                    <div v-if="imageObj" class="x y o-cover">
                        <StyleGuideImage :image="imageObj" :size="{ width: 800, height: 800 }" class="x y" />
                    </div>
                </div>
                <div :class="[i % 2 === 0 ? 'col-span-6' : 'col-span-6 order-first']">
                    <div class="grid grid-cols-1 md:grid-cols-12 z-0 relative">
                        <div v-for="(styleObj, ii) in imageObj.enhancedStyles" :key="'image-' + ii" :style="styleObj" class="col-span-6 aspect-w-1 aspect-h-1 bg-snow">
                            <div class="x y f aic jcc text-center p-5 overflow-hidden">
                                <div v-if="styleObj.isLogo" class="x y o-cover p-absolute p-fill mix-blend-screen o-2">
                                    <StyleGuideImage :image="imageObj" v-if="styleObj.grayscale" class="" style="filter: grayscale(100%)" />
                                </div>
                                <div v-if="styleObj.isLogo" class="f aic jcc x relative">
                                    <div class="relative"><BrandLogoComponent :color="styleObj.color" :width="250" class="mx-auto"></BrandLogoComponent></div>
                                </div>
                                <span v-else class="z-10 relative text-pretty" :style="styleObj.textStyle">{{ styleObj.text }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="showAds">
                <div v-if="i % 2 === 0" class="col-span-3 h-auto">
                    <StyleGuideAdStyle2 :image="imageObj" :text="styleGuide?.description.split('.')[0]" />
                </div>
                <div v-if="i % 2 === 0" class="col-span-3 h-auto">
                    <StyleGuideAd :image="imageObj" :text="styleGuide?.description.split('.')[0]" />
                </div>
            </template>
            <!--            <div v-if="i % 3 === 1" class="col-span-3 h-auto">-->
            <!--                <StyleGuideAd :image="imageObj" :text="styleGuide?.description.split('.')[0]" />-->
            <!--            </div>-->
        </template>
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import StyleGuideImage from "@/components/styleGuide/Images/StyleGuideImage.vue";
import StyleGuideAd from "@/components/styleGuide/Sections/Themes/StyleGuideAd.vue";
import StyleGuideAdStyle2 from "@/components/styleGuide/Sections/Themes/StyleGuideAd-Style-2.vue";

export default {
    name: "ImageDivStyles",
    components: { StyleGuideAdStyle2, StyleGuideAd, StyleGuideImage, BrandLogoComponent },
    mixins: [styleGuideMixin],
    data() {
        return {
            // imageArrayWithData: [],
            showAds: false,
        };
    },
    created() {
        // this.$nextTick(() => {
        //     this.imageArrayWithData = this.setupComponent().images;
        // });
    },
    methods: {
        setupComponent() {
            // Assuming sortedImages, imageHelperWild(), applyRandomTextStyle(), and dynamicTextSample() are part of the component context.
            let enhancedStyles = [];
            let images = this.sortedImages.slice(0, 5).map((img, i) => {
                let styles = this.imageHelperWild(i);
                enhancedStyles = styles.map((style, ii) => {
                    let randomTextStyle = this.applyRandomTextStyle(ii);
                    let { fontSize } = { ...randomTextStyle, textWrap: "pretty" };
                    let text = this.dynamicTextSample(1.4) || "The Quick Brown Fox Jumps Over The Lazy Dog";
                    if (fontSize) {
                        fontSize = parseFloat(fontSize);
                        text = this.dynamicTextSample(fontSize) || "The Quick Brown Fox Jumps Over The Lazy Dog";
                    }
                    return {
                        ...style,
                        textStyle: randomTextStyle, // Merging random text style with the existing style.
                        text,
                        isLogo: ii % 3 === 0,
                        grayscale: ii % 3 === 0, // Decides if the grayscale filter should be applied.
                    };
                });
                return { ...img, enhancedStyles };
            });

            return images;
        },
        applyRandomTextStyle(index) {
            let style = this.randomTextStyleMethod();
            if (parseFloat(style?.fontSize) > 1.4) {
                // style.color = "red";
            }
            delete style?.color;
            style = deepCopy(style);
            // console.log("style", style);
            return style;
        },

        imageHelperWild(index) {
            if (!this.sortedImages?.length) return [];

            if (this.sortedImages?.length > 0) {
                let image = this.sortedImages[index];
                let { colors = [] } = image;
                let styles = [];
                if (colors.length > 0) colors = colors.slice(0, 4);
                colors.forEach((c, i) => {
                    let backgroundColor = c || "#ffffff";
                    let color;
                    // if (i === 1) backgroundColor = `rgba(22, 30, 255, 0.8)`;
                    // convert to hex if needed
                    // backgroundColor = `rgba(22, 30, 255, 0.8)`;
                    // backgroundColor = `rgb(22, 255, 255)`;
                    try {
                        // color = backgroundColor;

                        color = this.getContrastColor(backgroundColor, colors, 4) || "#fffff";
                    } catch (e) {
                        backgroundColor = "#ffffff";
                        color = this.getContrastColor(backgroundColor) || "#fffff";
                        console.error("broken color", c);
                    }
                    let text = this.dynamicTextSample(1.4) || "The Quick Brown Fox Jumps Over The Lazy Dog";
                    // this.textSample(0, 30);
                    let style = { backgroundColor, color, text };
                    // console.log("style", deepCopy(style));
                    styles.push(deepCopy(style));
                });

                return styles;
                // let color = this.getContrastColor(background);
            }
            // });
        },
    },
    computed: {
        imageArrayWithData() {
            return this.setupComponent();
        },
    },
};
</script>
<!--                            {{ applyRandomTextStyle(i) }}-->

<!--                              ...simplifyFont(fonts?.[ii]),-->
<!--                                {{ fonts?.[ii] }}-->
<!--                            <img class="o-cover x y p-absolute p-fill mix-blend-screen o-2" v-if="ii % 3 === 0" style="filter: grayscale(100%)" :src="imageHelper(i, 200)" />-->

<!--OLD SPLIT-->
<!-- <template v-for="(s, ii) in imageHelperWild(i).slice(0, 4)">-->
<!-- <div v-if="s" :style="s" class="col-span-6 aspect-w-1 aspect-h-1 bg-snow">-->
<!-- <div :key="'a' + ii" :style="{ ...s, ...applyRandomTextStyle(i) }" class="x y f aic jcc text-center p-5 overflow-hidden">-->
<!-- <div class="x y o-cover p-absolute p-fill mix-blend-screen o-2">-->
<!-- <StyleGuideImage v-if="img && ii % 3 === 0" :image="img" class="" style="filter: grayscale(100%)" />-->
<!-- </div>-->
<!-- <div v-if="ii % 3 === 0" class="f aic jcc x relative">-->
<!-- <div class="relative">-->
<!-- <BrandLogoComponent :color="s.color" :width="200" class="mx-auto"></BrandLogoComponent>-->
<!-- </div>-->
<!-- </div>-->
<!-- <span v-else class="z-10 relative">{{ textSample(0, 20) }}</span>-->
<!-- </div>-->
<!-- </div>-->
<!-- </template>-->
<!-- make a version using imageArrayWithData  -->
<!-- <div v-for="(s, ii) in imageArrayWithData" :style="s.styles[ii]" class="col-span-6 aic jcc x relative a ic jcc x relative">-->
<!-- <div :key="'a' + ii" :style="{ ...s.styles[ii], ...applyRandomTextStyle(i) }" class="x y f aic jcc text-center p-5 overflow-hidden">-->
<!-- <div class="x y o-cover p-absolute p-fill mix-blend-screen o-2">-->
<!-- <StyleGuideImage v-if="img && ii % 3 === 0" :image="img" class="" style="filter: grayscale(100%)" />-->
<!-- </div>-->
<!-- <div v-if="ii % 3 === 0" class="f aic jcc x relative">-->
<!-- <div class="relative">-->
<!-- <BrandLogoComponent :color="s.color" :width="200" class="mx-auto"></BrandLogoComponent>-->
<!-- </div>-->
<!-- </div>-->
<!-- <span v-else class="z-10 relative">{{ s.text }}</span>-->
<!-- </div>-->
<!-- </div>-->
