import { getCurrentInstance } from "vue";
import { updateDropdownValue, updateToggleValue } from "./StoreHelpers";
import streamingMixin from "@/mixins/ai/streamingMixin";
import { debounce } from "lodash";
import chatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import router from "@/routes";
import mixins from "@/mixins/mixins";
import consoleMixin from "@/mixins/Tools/consoleMixin";

import { db } from "@/firebase";
import { query, collection, where, orderBy, onSnapshot, addDoc, limit, startAfter, doc, Timestamp, getDoc, getDocs } from "firebase/firestore";
import { toRaw } from "vue";

function normalizeImage(image, prompt) {
    // console.log("images", image);
    // If image is a string, return it in the object format
    if (typeof image === "string") {
        return { url: image, prompt: prompt || "" };
    }

    // If image is an object, but has 'src' property instead of 'url'
    if (image?.src && !image.url) {
        let imageObj = { url: image.src, prompt: prompt || "" };
        if (image.alt) imageObj.alt = image.alt;
        return imageObj;
    }

    // If image is already in the right format, add prompt
    // if (prompt) image.prompt = prompt;
    return image;
}
export default {
    namespaced: true,
    state() {
        return {
            id: null,
            progress: [],
            showProgress: false,
            queue: {
                research: {
                    results: null,
                },
                images: [],
                tryGoogleImages: false,
                showProgress: false,
            },
            image_presets: ["enhance", "anime", "photographic", "digital-art", "comic-book", "fantasy-art", "line-art", "analog-film", "neon-punk", "isometric", "low-poly", "origami", "modeling-compound", "cinematic", "3d-model", "pixel-art", "tile-texture"],
            document: {
                title: "Untitled",
                google_image_query: null,
                theme_colors_hsl: null,
                sections: [],
                typing: false,
                id: null,
                created: null,
                updated: null,
                owner: null,
                raw: "",
                summary: "",
                complete: false,
            },
        };
    },
    mutations: {
        updateToggleValue,
        updateDropdownValue,

        updateDocumentProp(state, { keyPath, value }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");

            function setValue(obj, keys, value) {
                if (keys.length === 1) {
                    obj[keys[0]] = value;
                    return;
                }
                setValue(obj[keys[0]], keys.slice(1), value);
            }

            setValue(state.document, keys, value);
        },
        updateQueueProp(state, { keyPath, value }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");

            function setValue(obj, keys, value) {
                if (keys.length === 1) {
                    obj[keys[0]] = value;
                    return;
                }
                setValue(obj[keys[0]], keys.slice(1), value);
            }

            setValue(state.queue, keys, value);
        },
        setDocId(state, payload) {
            state.id = payload;
        },
        setDocument(state, payload) {
            if (payload?.sections) {
                payload.sections = payload.sections.map((section, index) => {
                    if (section.imageIndex !== null && section.imageIndex !== undefined) {
                        let images = state.document.images || state.queue.images;
                        let url = images[section.imageIndex]?.url;
                        let title = images[section.imageIndex]?.title;
                        section.images = [{ url, prompt: title }];
                    }
                    return section;
                });
            }
            state.document = { ...state.document, ...payload };
            // state.document = payload;
            // if (payload.id) state.id = payload.id;
            // state.title = payload.title;
            // state.sections = payload.sections;
            // if (payload.images) state.images = payload.images;
            // state.owner = payload.owner;
            // state.created = payload.created;
            // state.updated = payload.updated;
            // state.raw = payload.raw;
            // state.summary = payload.summary;
            // state.theme = payload.theme;
        },
        setSections(state, payload) {
            state.document.sections = payload;
            state.sections = payload;
        },
        updateSections(state, payload) {
            state.document.sections = payload;
        },
        addSlide(state, payload) {
            state.document.sections.push(payload);
        },
        setTitle(state, payload) {
            state.document.title = payload;
        },
        setQueue(state, payload) {
            state.queue = payload;
        },
        updateSlide(state, { payload, index }) {
            console.error("Updating slide", payload, index);
            state.document.sections[index] = payload;
        },
        updateSlideProp(state, { keyPath, value, index }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");

            function setValue(obj, keys, value) {
                if (keys.length === 1) {
                    obj[keys[0]] = value;
                    return;
                }
                setValue(obj[keys[0]], keys.slice(1), value);
            }

            setValue(state.document.sections[index], keys, value);
        },
        removeSlide(state, index) {
            state.document.sections.splice(index, 1);
        },
        addSlideAtIndex(state, { slide, index }) {
            state.document.sections.splice(index, 0, slide);
        },
        PUSH_TO_QUEUE_IMAGES(state, payload) {
            state.queue.images.push(payload);
        },
        ADD_ARRAY_OF_IMAGES_TO_QUEUE_IMAGES(state, payload) {
            state.queue.images = [...state.queue.images, ...payload];
        },
        addToProgress(state, payload) {
            state.progress.push(payload);
        },
        setProgress(state, payload) {
            state.progress = payload;
        },
        updateProgressStep(state, { index, payload }) {
            state.progress[index] = payload;
        },
        markProgressStepComplete(state, index) {
            state.progress[index].complete = true;
        },
        removeStep(state, index) {
            state.progress.splice(index, 1);
        },
        addDataToStep(state, { index, data }) {
            state.progress[index].data = data;
        },
        setSlide(state, { slide, index }) {
            state.document.sections[index] = slide;
        },
    },
    actions: {
        setSlide({ commit }, { slide, index }) {
            commit("setSlide", { slide, index });
        },
        clearProgress({ state, commit }) {
            commit("setProgress", []);
        },
        removeStep({ state, commit }, index) {
            commit("removeStep", index);
        },
        updateProgressStep({ state, commit }, { index, payload }) {
            commit("updateProgressStep", { index, payload });
        },
        addToProgress({ state, commit }, payload) {
            let obj = payload;
            // const uniqueId = Date.now().toString();
            // obj.id = uniqueId;
            commit("addToProgress", obj);
        },
        markProgressStepComplete({ commit }, index) {
            commit("markProgressStepComplete", index);
        },
        addDataToStep({ state, commit }, { index, data }) {
            commit("addDataToStep", { index, data });
        },
        addSlideAtIndex({ state, commit, dispatch }, { slide, index }) {
            commit("addSlideAtIndex", { slide, index });
            // dispatch("savePresentation");
        },
        removeSlide({ state, commit }, index) {
            commit("removeSlide", index);
        },
        updateDocumentProp({ commit }, prop) {
            commit("updateDocumentProp", prop);
        },
        updateQueueProp({ commit }, prop) {
            commit("updateQueueProp", prop);
        },
        clearQueue({ state, commit }) {
            let cleared = {
                research: {
                    websites: [],
                    messages: [],
                },
                images: [],
                showProgress: false,
            };
            commit("setProgress", []);
            commit("setQueue", cleared);
        },
        pushToQueueImages({ commit }, { image, prompt }) {
            const normalizedPayload = normalizeImage(image, prompt);
            commit("PUSH_TO_QUEUE_IMAGES", normalizedPayload);
        },
        addImagesToQueue({ commit }, { images, prompt }) {
            const normalizedPayload = images.map(image => normalizeImage(image, prompt));
            commit("ADD_ARRAY_OF_IMAGES_TO_QUEUE_IMAGES", normalizedPayload);
        },
        async updateDocument({ commit }, { docId, update }) {
            const collection = "documents";
            let d = await db.collection(collection).where("id", "==", docId).get();
            console.log(d);
            await db.collection(collection).doc(docId).set(update, { merge: true });
            // If you want to refresh the document in the state after updating it
            let doc = (await db.collection(collection).doc(docId).get()).data();
            commit("setDocument", doc);
        },
        updateSlideProp({ commit }, prop) {
            commit("updateSlideProp", prop);
        },
        updatePresentationSections({ state, commit, dispatch }, payload) {
            commit("setDocument", payload);
        },
        setDocId({ state, commit }, payload) {
            commit("setDocId", payload);
        },
        async saveDocumentStore({ state }) {
            const documentRef = doc(collection(db, "documents"), state.document.id);

            try {
                await updateDoc(documentRef, state.document);
            } catch (error) {
                console.error("Error saving document:", error);
            }
        },
        async getPresentation({ state, commit, dispatch }, id) {
            if (state?.queue?.fetching) return;
            // commit("setDocument", {});
            // state.presentation.sections = [];
            console.groupCollapsed("Fetching Presentation");
            console.log(id);
            console.groupEnd();
            const doc = await db.collection("documents").doc(id).get();
            if (doc) {
                const data = doc.data();

                if (data.sections && data.sections.length > 0) {
                    console.groupCollapsed("%c Presentation", info);
                    console.log("got data", doc);
                    data.sections.forEach((section, index) => {
                        console.log(`Slide ${index + 1}:`);
                        consoleTableReduced(section, ["images"]);
                        if (section.images) console.table(section.images);
                    });
                    console.groupEnd();
                } else {
                    console.groupCollapsed("%c Presentation", info);
                    console.groupEnd();
                }
                // state.document = doc.data();
                console.log(doc.data());
                commit("setDocument", doc.data());
                dispatch("clearQueue");
            } else {
                console.log("No such document!");
            }
            // window.presentation = toRaw(state.document);
        },
        async updateSections({ state, commit, dispatch }, payload) {
            commit("setDocument", payload);
        },
        addSlide({ state, commit, dispatch }, payload) {
            commit("addSlide", payload);
            // dispatch("savePresentation");
            return;
        },
        updateSlide({ state, commit, dispatch }, payload, index) {
            commit("updateSlide", { payload, index });
            // dispatch("savePresentation");
            return;
        },
        clearSections({ state, commit, dispatch }, payload) {
            commit("setSections", []);
            return;
        },
        setTitle({ state, commit, dispatch }, payload) {
            commit("setTitle", payload);
            return;
        },
        async savePresentation({ state, commit, dispatch, rootState }, id) {
            console.groupCollapsed("Save deck");
            console.log(id);
            console.trace();
            console.groupEnd();

            const user = rootState.user.user; // Access the user from the UserStore
            // console.log(state.id, state.title, state.model, state.owner);
            if (id) {
                commit("setDocId", id);
                console.log("Saving ...");
                await db
                    .collection("documents")
                    .doc(state.id)
                    .update(
                        {
                            documentType: "presentation",
                            owner: user.uid,
                            created: state.document.created || new Date(),
                            ...state.document,
                            updated: new Date(),
                        },
                        { merge: true },
                    );
            } else if (!state.id) {
                console.log("No document ID found, creating new document");
                const newPresRef = await db.collection("documents").add({
                    documentType: "presentation",
                    owner: user.uid,
                    ...state.document,
                    created: new Date(),
                    updated: new Date(),
                });
                commit("setDocId", newPresRef.id);
            } else {
                console.log("Saving ...");
                await db
                    .collection("documents")
                    .doc(state.id)
                    .update(
                        {
                            sections: state.document.sections,
                            documentType: "presentation",
                            owner: user.uid,
                            ...state.document,
                            created: state.created || new Date(),
                            updated: new Date(),
                        },
                        { merge: true },
                    );
            }
            return;
        },
        async updatePresentation({ state, commit, dispatch }, payload) {
            commit("setDocument", payload);
            const docRef = await doc(collection(db, "documents"), payload.id).update(payload);
            console.log("Document updated with ID: ", docRef.id);
        },
    },
    getters: {
        document: state => state.document,
        imagesList(state) {
            let imageList = "";
            if (!state?.document?.images) return "";
            state.document?.images.forEach((image, index) => {
                imageList += `${index}. ${image.query} - ${image.title}\n`;
                // imageList += image.url + "\n"
            });
            return imageList || "";
        },
        images: state => state.document.images,
        queue: state => state.queue,
        showProgress: state => state.showProgress,
        currentSlideCount: state => {
            return state.document.sections.length;
        },
        complete: state => {
            return state.document.complete;
        },
        progress: state => state.progress,
        extractPresentationText: state => {
            let sections = state.document.sections || state.sections;
            if (state.document && sections) {
                let text = "";
                let objects = sections;
                for (let i = 0; i < objects.length; i++) {
                    const slideIndex = i + 1;
                    text += "[SLIDE " + slideIndex + "]\n";
                    if (objects?.[i]?.title) {
                        text += "Title: " + `${objects[i].title || "New slide"}` + "\n";
                    }
                    if (objects?.[i]?.body) {
                        text += "Body: " + `${objects[i].body || "new slide"}` + "\n";
                    }
                    text += "\n";
                }
                return text;
            }
            return "No summary found";
        },
    },
};
