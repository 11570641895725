import { doc, Timestamp, getDoc, query, collection, where, deleteDoc, addDoc, getDocs, orderBy, updateDoc, setDoc } from "firebase/firestore";
import _ from "lodash";

import firestore, { db } from "@/firebase";
import * as firebase from "@/firebase";
async function findMatchingObject(database, field = "url", value) {
    try {
        const q = query(collection(db, database), where(field, "==", value));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const matchingDoc = querySnapshot.docs[0];
            const data = matchingDoc.data();
            data.id = matchingDoc.id; // Adding the id to the object
            // console.log("Found matching doc!", data);
            return data;
        } else {
            // console.log(`No match found for ${database} for a matching ${field}:`, value);
            return null;
        }
    } catch (error) {
        console.error(`An error occurred while querying ${database} for a matching ${field}:`, error);
        throw error;
    }
}

export default findMatchingObject;
