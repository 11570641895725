<template>
    <div appear class="p-fixed top right bottom vh-100 z-5 w-75 bg-base-700 bg-snow dark:bg-base-800 p-4 pt-7">
        <li class="white animated nav-link p-absolute top left f-20 p-3" appear>
            <a class="gray" @click.prevent="$emit('hide-menu')">
                <i class="fal o-5 fa-times-circle f-30"></i>
            </a>
        </li>
        <ul class="mobileNav">
            <TransitionGroup duration="100" leave-active-class="animated fadeOutDown" name="item">
                <li v-for="(item, index) in navItems" :key="`${item + index}`" :class="`d-${index + 1}`" appear class="animated nav-link swingInUp duration-1 px-2 ease-back">
                    <RouterLink :to="item.path" active-class="text-base-900" class="f-30">
                        {{ item.title }}
                    </RouterLink>
                </li>
            </TransitionGroup>
        </ul>
        <div class="absolute bottom left right p-3 border-top">
            <NavItem v-if="user" title="Tools" avatar class="ml-2"></NavItem>
            <li class="nav-link" v-else><router-link to="/auth">Login</router-link></li>
        </div>
    </div>
</template>
<script>
import NavItem from "@/components/CoreUI/navigaion/NavItem.vue";

export default {
    name: "MobileDrawer",
    components: { NavItem },
    props: {
        navItems: {},
    },
};
</script>
<style lang="scss" scoped>
.nav-link {
    color: black;
}
</style>
