<template>
    <div v-if="colors">
        <template v-if="mobile">
            <StyleGuideColorsMobile :colors="colors" :color-arrays="colorArrays" />
        </template>
        <template v-else>
            <StyleGuideContainer class="p-relative" title="Brand Colors" section="colors">
                <div class="col-span-12 overflow-x-scroll scrollbar-hide" id="colorSection">
                    <div class="f gap-3 flex-shrink-0" v-if="colorArrays">
                        <div v-for="item in colorArrays">
                            <h5 class="fwb">{{ item.name }}</h5>
                            <div class="f mr-8 gap-10">
                                <ColorPallet v-for="(value, key) in colors[`${item.id}${$store.state.styleGuide.sortColors ? `_${$store.state.styleGuide.sortColors}` : ''}`]" :key="key" :color="value" class="p-relative col-span-2 md:col-span-1 flex-shrink-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </StyleGuideContainer>
        </template>
    </div>
</template>
<script>
import ColorPallet from "@/components/styleGuide/Colors/ColorPallet.vue";
import SortColorPicker from "@/components/styleGuide/Colors/SortColorPicker.vue";
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import StyleGuideColorsMobile from "@/components/styleGuide/Colors/StyleGuideColorsMobile.vue";

export default {
    name: "StyleGuideColors",
    components: { StyleGuideColorsMobile, ColorPallet, SortColorPicker, StyleGuideContainer },
    computed: {
        colorArrays() {
            let colors = [
                { name: "Text Colors", id: "text_colors" },
                { name: "Background Colors", id: "bg_colors" },
                { name: "Button colors", id: "button_bg" },
                { name: "All", id: "all_colors" },
            ];
            if (this.$store.state.styleGuide?.styleGuide?.gradients?.length > 0) {
                colors.push({ name: "Gradients", id: "gradients" });
            }
            return colors;
        },
    },
    methods: {
        sortString(id) {
            if (id !== "gradients") {
                if (this.$store.state.styleGuide.sortColors) {
                    return id + "_" + this.$store.state.styleGuide.sortColors;
                }
            } else {
                return "";
            }
            // this.setStyleGuide(item);
        },
    },
    props: {
        colors: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
};
</script>
