<template>
    <div class="mx-auto mt-8 max-w-7xl px-3 sm:mt-16 lg:px-3">
        <h2 class="text-center text-lg font-semibold leading-8 text-white" v-if="title">{{ title }}</h2>
        <div class="mx-auto mt-5 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none xl:grid-cols-5">
            <template v-for="image in images">
                <img :src="image?.src" :alt="image?.alt" :width="image?.width" :height="image?.height" class="max-h-12 w-full object-contain md:xl:col-span-1 md:col-span-1" />
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: "HomeLogoPile",
    props: {
        title: {
            type: String,
            default: "The world’s most innovative companies use our app",
        },
        images: {
            kind: Array,
            default: () => [
                {
                    src: "https://tailwindui.com/img/logos/158x48/transistor-logo-white.svg",
                    alt: "Transistor",
                    width: 158,
                    height: 48,
                },
                {
                    src: "https://tailwindui.com/img/logos/158x48/reform-logo-white.svg",
                    alt: "Reform",
                    width: 158,
                    height: 48,
                },
                {
                    src: "https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg",
                    alt: "Tuple",
                    width: 158,
                    height: 48,
                },
                {
                    src: "https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg",
                    alt: "SavvyCal",
                    width: 158,
                    height: 48,
                },
                {
                    src: "https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg",
                    alt: "Statamic",
                    width: 158,
                    height: 48,
                },
            ],
        },
    },
};
</script>
