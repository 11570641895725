<template>
    <div class="p-4 bg-white shadow sm:rounded-lg mw-900">
        <h1 class="text-2xl font-bold mb-3">
            <span v-if="originalAssistant.model">Modify Assistant</span>
            <span v-else>Create Assistant</span>
        </h1>
        <transition mode="out-in" name="fade-up">
            <div v-if="!loading" class="grid gap-6 mb-3 lg:grid-cols-2">
                <!-- Name field -->
                <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900" for="name">Name</label>
                    <input id="name" v-model="assistant.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Enter the assistant name" required type="text" />
                </div>

                <!-- Model ID Field -->
                <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900" for="model">Model</label>
                    <select id="model" v-model="assistant.model" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required @change="modelChanged">
                        <option disabled value="">Please select a model</option>
                        <option :value="gpt4">GPT-4 Turbo (128k)</option>
                        <option :value="gpt3">Updated GPT 3.5 Turbo (16k)</option>
                        <!--                        <option value=gpt316>GPT-3.5 Turbo (16k)</option>-->
                    </select>
                </div>

                <!-- Description Field -->
                <div class="lg:col-span-2">
                    <label class="block mb-2 text-sm font-medium text-gray-900" for="description">Description</label>
                    <textarea id="description" v-model="assistant.description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Enter a description for the assistant" rows="4"></textarea>
                </div>

                <!-- Instructions Field -->
                <div class="lg:col-span-2">
                    <label class="block mb-2 text-sm font-medium text-gray-900" for="instructions">Instructions</label>
                    <textarea id="instructions" v-model="assistant.instructions" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Enter system instructions for the assistant" rows="4"></textarea>
                </div>

                <!-- Tools Field -->
                <div v-if="assistant.tools" class="lg:col-span-2">
                    <label class="block mb-2 text-sm font-medium text-gray-900" for="tools">Tools</label>
                    <AssociateTools :available-tools="availableTools.map(t => t.type)" :selected-tools="assistant.tools" @update:selectedTools="updateTools" />
                </div>

                <!-- Metadata Field -->
                <!--                <div class="lg:col-span-2">-->
                <!--                    <label class="block mb-2 text-sm font-medium text-gray-900" for="metadata">Metadata (key:value)</label>-->
                <!--                    <KeyValueInput v-model="assistant.metadata" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Enter metadata as key:value pairs" />-->
                <!--                </div>-->

                <!-- File IDs Field -->
                <div class="lg:col-span-2">
                    <label class="block mb-2 text-sm font-medium text-gray-900" for="file_ids">File IDs</label>
                    <FileSelector :selected-files="assistant.file_ids" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" @update-files="updateFiles" />
                </div>
            </div>
            <div v-else class="x height-300 f aic jcc border-light bg-gray-100 br-20 mb-4">
                <loading-spinner color="purple"></loading-spinner>
            </div>
        </transition>
        <BaseButton v-if="originalAssistant.model" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-3 text-center" size="md" @click="modifyAssistant">Update Assistant</BaseButton>
        <BaseButton v-else class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-3 text-center" size="md" @click="makeNewAssistant">Create Assistant</BaseButton>
    </div>
</template>

<script>
import AssociateTools from "@/views/Chat/AssociateTools.vue";
import KeyValueInput from "@/views/Chat/KeyValueInput.vue";
import FileSelector from "@/views/Chat/FileSelector.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import { mapActions } from "vuex";

export default {
    name: "ModifyAssistant",
    components: {
        LoadingSpinner,
        BaseButton,
        FileSelector,
        KeyValueInput,
        AssociateTools,
    },
    props: {
        originalAssistant: {
            type: Object,
            default: () => ({}),
            required: false,
        },
    },
    data() {
        return {
            assistant: {
                name: "New assistant",
                model: gpt3,
                description: "",
                instructions: "",
                tools: [{ type: "code_interpreter" }],
                file_ids: [],
                metadata: {},
            },
            loading: false,
            allTools: [{ type: "code_interpreter" }, { type: "retrieval" }, { type: "function" }],
            limitedTools: [{ type: "code_interpreter" }, { type: "function" }],
            availableTools: [{ type: "code_interpreter" }, { type: "retrieval" }, { type: "function" }],
        };
    },
    watch: {
        assistant: {
            handler: function (newVal, oldVal) {
                this.updateObjectTools();
            },
            deep: true,
        },
    },
    mounted() {
        if (this.originalAssistant.model) {
            this.assistant = { ...this.originalAssistant };
        }
    },
    methods: {
        ...mapActions("assistants", ["createAssistant"]),
        async makeNewAssistant() {
            this.loading = true;
            await this.createAssistant(this.assistant);
            this.loading = false;
        },
        updateFiles(newFiles) {
            this.assistant.file_ids = newFiles;
        },
        updateObjectTools() {
            // this.assistant.tools.map(tool => ({
            //     tool,
            //     ...(tool.type === "function" && { function: tool.function }),
            // }));
        },
        async modifyAssistant() {
            // Here, you should construct the payload according to your server expectation
            // And make an API call to update the assistant on the server
            // const payload = {
            //     ...this.assistant,
            //     metadata: this.constructMetadataObject(this.assistant.metadata),
            // };
            this.loading = true;
            await this.$store.dispatch("assistants/modifyAssistant", this.assistant);
            this.loading = false;

            // try {
            //     const response = await this.updateAssistantRequest(payload);
            //     console.log(response); // Success response from server - You might want to handle this
            // } catch (error) {
            //     console.error("Error modifying the assistant:", error); // Error handling
            // }
        },
        modelChanged() {
            // Filter tools based on model selection
            this.availableTools = this.assistant.model === gpt4 || this.assistant.model === gpt3 ? this.allTools : this.limitedTools;
            this.assistant.tools = this.assistant.tools.filter(tool => this.availableTools.includes(tool.type));
        },
        constructMetadataObject(metadataArray) {
            // This method should iterate over the metadata array and construct an object
            // Example: { "key": "value", ... }
        },
        updateTools(newTools) {
            this.assistant.tools = newTools;
        },
        async updateAssistantRequest(payload) {
            this.$store.dispatch("assistants/modifyAssistant", payload);
            // This method should make the API call to the server to update the assistant
            // Example: return axios.post('/api/endpoint-to-update-assistant', payload);
        },
    },
};
</script>

<style scoped>
/* Add your styles here. Since styles are scoped, they won't affect other parts of your application. */
button {
    transition: background-color 0.3s ease;
}
</style>
