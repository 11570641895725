<template>
    <div class="max-w-4xl px-4 sm:px-6 lg:px-8 mx-auto text-center">
        <div class="mw-100 mx-auto">
            <logo class="" />
        </div>
        <h1 class="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">Welcome to BrandBlocks AI</h1>
        <p class="mt-3 text-gray-600 dark:text-gray-400">Your AI-powered copilot for the web</p>
    </div>
</template>
<script>
import Logo from "@/components/CoreUI/navigaion/logo.vue";

export default {
    name: "ChatEmptyState",
    components: { Logo },
};
</script>
