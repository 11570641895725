<template>
    <div class="p-3">
        <div class="container">
            <h1>Settings</h1>
            <BaseInput label="Brand name" v-model="styleGuide.name" placeholder="Enter a URL" />
            <BaseInput label="Description" v-model="styleGuide.description" placeholder="Edit Categories" />
            <BaseInput label="Categories" v-model="styleGuide.categories" placeholder="Edit Categories" />
            <BaseInput label="URL" v-model="styleGuide.url" placeholder="Edit Categories" />
            <div class="relative y">
                <brand-logo-component class="border-light inline-block" width="150px" :color="'black'" />
            </div>
        </div>
    </div>
</template>

<script>
import styleGuideBrandMixin from "@/mixins/StyleGuideMixins/StyleGuideBrandMixin";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
export default {
    components: { BrandLogoComponent, BaseInput },
    mixins: [StyleGuideMixin, styleGuideBrandMixin],
    data() {
        return {};
    },
    computed: {},
    methods: {},
    watch: {
        $route: {
            async handler(to, from) {
                this.doneMounting = false;
                this.$forceUpdate();
                if (to.params.id) {
                }
                // }
            },
            immediate: false,
        },
    },
    created() {},
    async mounted() {
        // await this.getStyleGuides();
    },
    beforeUnmount() {},
};
</script>
