function initializeMessages(messagesArray, systemMessage) {
    const MAX_MESSAGES = 50; // Set the maximum number of messages
    let messages = [];

    if (systemMessage) {
        const message = typeof systemMessage === "string" ? sysMessage(systemMessage) : systemMessage;
        messages.push(message);
    }

    if (messagesArray) {
        for (let i = 0; i < messagesArray.length; i++) {
            messages.push(messagesArray[i]);
            // Check the number of messages. If exceeded, remove the first client message (the oldest one)
            if (messages.length > MAX_MESSAGES) {
                messages.splice(1, 1); // Always keep system message (at index 0)
            }
        }
    }

    return messages;
}

export default initializeMessages;
