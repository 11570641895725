<template>
    <div class="vh-100 f fc">
        <!--        <SplitterGroup id="splitter-group-2" direction="vertical">-->
        <!--            <SplitterPanel id="splitter-group-2-panel-1" :min-size="20" class="bg-white rounded-xl flex items-center justify-center">Panel B</SplitterPanel>-->
        <!--            <SplitterResizeHandle id="splitter-group-2-resize-handle-1" class="h-2" />-->
        <!--            <SplitterPanel id="splitter-group-2-panel-2" :min-size="20" class="bg-white rounded-xl flex items-center justify-center">Panel C</SplitterPanel>-->
        <!--        </SplitterGroup>-->
        <SplitterGroup id="splitter-group-1" as="div" auto-save-id="editor-panel" class="vh-100" direction="vertical">
            <SplitterPanel id="splitter-group-1-panel-1" :default-size="80" :min-size="10">
                <div class="overflow-y-scroll scrollbar-hide y">
                    <EditorSectionPrimative :detail="name" stack title="Title" />
                    <!--            <div v-for="(section, i) in sections" :key="i"></div>-->
                    <!--            <EditorSectionPrimative title="type" :detail="type" />-->
                    <!--            <EditorSectionPrimative title="id" :detail="id" />-->
                    <!--            <EditorSectionPrimative title="Campaign" :detail="campaignId" />-->
                    <UICollapsableSection left max-height="300" small title="Thinking">
                        <template #title><span class="editor-section-title">Details</span></template>
                        <ObjectViewer :key="id" :data="{ title: name, id, type, campaign: campaignId, subjectLine, previewText, updated: `${currentlyEditing?.updated}`, created: `${currentlyEditing?.created}` }" />
                        <div class="f fc gap-0 f-15">
                            <EditorSectionPrimative :detail="currentlyEditing?.updated" date title="Updated" />
                            <EditorSectionPrimative :detail="currentlyEditing?.created" date title="Created" />
                        </div>
                        <div class="">
                            <UICollapsableSection left max-height="300" small title="Thinking">
                                <template #title>
                                    <span class="editor-section-title">Thinking</span>
                                </template>
                                <ObjectViewer :data="thinking" />
                            </UICollapsableSection>
                            <UICollapsableSection left max-height="300" small title="Thinking">
                                <template #title>
                                    <span class="editor-section-title">Instructions</span>
                                </template>
                                <ObjectViewer key="instructions" :data="instructions" />
                            </UICollapsableSection>
                            <UICollapsableSection left max-height="300" small title="Thinking">
                                <template #title>
                                    <span class="editor-section-title">Talking Points</span>
                                </template>
                                <ObjectViewer key="TalkingPoints" :data="{ talking_points }" />
                            </UICollapsableSection>
                        </div>
                    </UICollapsableSection>

                    <template v-if="subjectLine">
                        <EditorSectionPrimative :detail="subjectLine" class="border-bottom" stack title="Subject Line" />
                        <EditorSectionPrimative :detail="previewText" class="border-bottom" stack title="Preview Text" />
                        <div v-if="currentlyEditing?.template_id" class="f gap-3 p-2">
                            <a :href="`https://www.klaviyo.com/email-editor/${currentlyEditing.template_id}/preview`" class="x" target="_blank">
                                <SimpleButton class="x" color="green" secondary>Preview</SimpleButton>
                            </a>
                            <a :href="`https://www.klaviyo.com/email-editor/${currentlyEditing.template_id}/edit`" class="x" target="_blank">
                                <SimpleButton class="x" color="green" secondary>Edit</SimpleButton>
                            </a>
                        </div>
                        <template v-if="sections?.length === 0">
                            <div class="f jcc p-3">
                                <SimpleButton class="x" color="green" soft @click="addNewSection">Add New Section</SimpleButton>
                            </div>
                        </template>
                    </template>

                    <template v-if="sections?.length > 0" :key="sections">
                        <draggable v-model="localSections" @change="updateSections">
                            <template #item="{ element, index }">
                                <CurrentlyEditingSection :id="id" :campaign="campaign" :index="index" :section="element" />
                            </template>
                        </draggable>
                        <div class="f jcc p-3">
                            <SimpleButton class="x" color="green" soft @click="addNewSection">Add New Section</SimpleButton>
                        </div>
                    </template>
                    <CurrentlyEditingEmailFooter v-if="emailFooter" :id="id" :email-footer="emailFooter" />
                </div>
            </SplitterPanel>
            <SplitterResizeHandle id="splitter-group-1-resize-handle-1" class="h-5 border-t border-b border-gray-300 f jcc p-2 aic bg-gray-100 text-sm text-gray-500">Drag me</SplitterResizeHandle>
            <SplitterPanel id="splitter-group-1-panel-2" :min-size="10" as="div" class="">
                <!--                <div class="height-500">-->
                <div class="flex-shrink-0 overflow-y-scroll y">
                    <slot></slot>
                </div>
            </SplitterPanel>
        </SplitterGroup>
        <!--        <dynamic-object-viewer :data-object="currentlyEditing"></dynamic-object-viewer>-->
    </div>
</template>
<script>
import { SplitterGroup, SplitterPanel, SplitterResizeHandle } from "radix-vue";
import CurrentlyEditingSection from "@/components/elements/CurrentlyEditingSection.vue";
import EditorSectionPrimative from "@/components/editor/EditorSectionPrimative.vue";
import ObjectViewer from "@/components/Admin/ObjectViewer.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";
import draggable from "vuedraggable";
import SimpleButton from "@/views/SimpleButton.vue";
import CurrentlyEditingEmailFooter from "@/components/elements/CurrentlyEditingEmailFooter.vue";
import { mapGetters } from "vuex";

export default {
    name: "CurrentlyEditingBar",
    components: {
        CurrentlyEditingEmailFooter,
        SimpleButton,
        UICollapsableSection,
        ObjectViewer,
        EditorSectionPrimative,
        CurrentlyEditingSection,
        SplitterGroup,
        SplitterPanel,
        SplitterResizeHandle,
        draggable,
    },
    props: {
        currentlyEditing: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            localSections: [],
        };
    },
    watch: {
        id() {
            this.localSections = [...this.sections];
        },
        sections: {
            handler() {
                this.localSections = [...this.sections];
            },
            deep: true,
        },
    },
    created() {
        this.localSections = [...this.sections];
    },
    methods: {
        layoutChanged(event) {
            console.log(event);
        },
        updateSections() {
            console.log("Updating Sections");
            let sectionIdentifier = `editor.${this.id}.result.sections`;
            this.updateStreamProp(sectionIdentifier, this.localSections);
            // this.$store.dispatch("save")
        },
        async saveCampaign() {
            await this.$store.dispatch("stream/saveCampaignItem", this.id);
        },
        updateFooter() {
            let sectionIdentifier = `editor.${this.id}.result.email_footer`;
            this.updateStreamProp(sectionIdentifier, this.emailFooter);
        },
        saveSectionOrder() {
            this.$emit("update-sections", this.localSections);
        },
        addNewSection() {
            let newSection = {
                header: "text",
                body: "New Section",
                imageIndex: 1,
            };
            this.localSections.push(newSection);
            this.$store.dispatch("stream/addSectionWithAI", { id: this.id, index: this.sections.length - 1 });
            // this.updateSections();
        },
    },
    computed: {
        ...mapGetters("stream", ["editor"]),
        emailFooter() {
            let editor = this.editor;
            let id = this.id;
            let campaign = editor?.[id]?.result || {};
            let emailFooter = campaign?.email_footer;
            if (!emailFooter) return {};
            return emailFooter;
            // return this.editor?.[this.id]?.result?.email_footer || false;
        },
        identifier() {
            let identifier = `editor.${this.id}`;
            // let identifier = this.currentlyEditing.campaignIdidentifier;
            if (!identifier) {
                identifier = "editor." + this.id + ".result.sections." + this.index;
            }
            return identifier;
        },
        sectionsIdentifier() {
            return `editor.${this.campaignId}.result.sections`;
        },
        campaign() {
            let campaign = this.currentlyEditing?.result || {};
            campaign.id = this.id;
            return campaign;
        },
        thinking() {
            return this.currentlyEditing?.result?.thinking || this.currentlyEditing?.thinking || "No Thinking";
        },
        subjectLine() {
            return this.currentlyEditing?.result?.subject_line || "No Subject Line";
        },
        previewText() {
            return this.currentlyEditing?.result?.preview_text || "No Preview Text";
        },
        campaignId() {
            return this.currentlyEditing?.campaignId || "No Campaign ID";
        },
        talking_points() {
            return this.currentlyEditing?.talking_points || {};
        },
        name() {
            return this.currentlyEditing?.name || "No Name";
        },
        sections() {
            let editor = this.editor;
            let id = this.id;
            let sections = editor?.[id]?.result?.sections || [];
            return this.editor?.[this.id]?.result?.sections;
        },
        instructions() {
            return this.currentlyEditing?.instructions || {};
        },
        type() {
            return this.currentlyEditing?.object_type || "No Type";
        },
    },
};
</script>
