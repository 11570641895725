<template>
    <div v-if="itemCounts" class="x text-center mt-3">
        <div class="f aic jcc gap-5 border rounded-full d-inline-flex px-3 mx-auto text-center o-4">
            <div v-for="(elem, key) in itemCounts" class="f aic jcc">
                <div class="mr-2">
                    <div v-if="key === 'emails'"><i class="far fa-envelope"></i></div>
                    <div v-if="key === 'pages'"><i class="far fa-globe-americas"></i></div>
                    <div v-if="key === 'SMS'"><i class="far fa-mobile-alt"></i></div>
                </div>
                {{ elem }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ItemCounts",
    props: {
        itemCounts: {},
    },
};
</script>
<style lang="scss" scoped>
h6{
    @apply mb-0 font-bold;
}
</style>