<template>
    <a v-if="story.link" :href="story.link" class="x f fwn" style="color: unset" target="_blank" @click.prevent="summarizeArticle(story.link)">
        <div v-if="story.image" class="flex-2 animated zoomIn d-10 duration-3 p-2">
            <img :src="story.image" class="x o-cover height-75 width-75 width-max-75 br-10" />
        </div>
        <div class="f fc f flex-1 p-2 px-3">
            <div v-if="story.title" class="fwb line-height-small block text-sm line-clamp-2 dark:text-base-200">
                <animated-text v-if="animate" :text="story.title"></animated-text>
                <template v-else>
                    {{ story.title }}
                </template>
            </div>
            <div v-if="story.snippet" class="o-5 mt-auto block text-xs dark:text-base-300">
                <animated-text v-if="animate" :delay-all="1" :text="story.snippet"></animated-text>
                <template v-else>
                    {{ story.snippet }}
                </template>
            </div>
            <div v-if="story.date" class="o-5 mt-auto block text-xs dark:text-base-300">
                <template v-if="animate">
                    <animated-text :delay-all="1.2" :text="story.date"></animated-text>
                    <animated-text v-if="story.source" :delay-all="1.5" :text="` • ${story.source}`"></animated-text>
                </template>
                <template v-else>
                    {{ story.date }}
                    <template v-if="story.source">• {{ story.source }}</template>
                </template>
            </div>
            <div>
                <a class="text-xs" :href="story.link">Read this article</a>
            </div>
        </div>
    </a>
</template>
<script>
import AnimatedText from "@/components/CoreUI/animatedText.vue";
import BrowseCommand from "@/mixins/Chat/Commands/BrowseCommand";

export default {
    name: "SearchArticle",
    components: { AnimatedText },
    mixins: [BrowseCommand],
    inject: ["sendMessage", "chatResponseReceived", "scrollChatWindowToBottom"],
    methods: {},
    props: {
        animate: {},
        story: {},
    },
};
</script>
<style scoped>
a {
    @apply dark:text-purple-300;

}
</style>
