<template>
    <div class="x y p-absolute p-fill overflow-hidden" style="height: 50vh; max-height: 70vh">
        <div class="x z-5 p-absolute p-fill y bg-blur x container br-10 bg-base-900/50 p-0 p-2" style="overflow-y: scroll; overflow-x: hidden">
            <div v-if="images && filteredImages.length > 0" class="relative grid grid-cols-12 gap-3">
                <!--                <transition-group name="fade-up">-->
                <!--                        <div v-if="image && image.url" :class="`d-${index}`" class="animated swingInUp w-100 br-15 duration-5 m-0 flex overflow-hidden bg-base-900/10 p-0 shadow-insetHigh" style="margin-right: 0px;!important;" @click.prevent="addImageToSlide(image)">-->
                <transition-group enter-active-class="animated fadeInUp" leave-active-class="animated fadeInUp reverse-animation" enter-from-class="opacity-0">
                    <div v-for="(img, index) in images" :key="'image' + index" :class="`d-${index}`" class="col-span-6 mb-3 md:col-span-4" style="margin-right: 0px;!important;">
                        <ImagePickerImage @click.prevent="addImageToSlide(img)" :image="img" :index="index" />
                    </div>
                </transition-group>
            </div>
        </div>
        <div class="x bottom left p-absolute z-5 text-center">
            <ButtonIconWhite class="fas f-20 mx-auto cursor-pointer" icon="times fas" @click.prevent="updateSlideProp('showImages', false, index)"></ButtonIconWhite>
        </div>
    </div>
</template>
<script>
import ButtonIconWhite from "@/components/CoreUI/buttons/buttonIconWhite.vue";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import updateSlideProp from "@/mixins/ai/update_slide_prop";

import { mapGetters } from "vuex";
import ImagePickerImage from "@/components/Presentations/slides/ImagePickerImage.vue";

export default {
    name: "ImagePicker",
    mixins: [presentationMixin],
    components: { ImagePickerImage, ButtonIconWhite },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        images() {
            return this.document.images || this.queue.images || [];
        },
        filteredImages() {
            return this.images.filter(image => {
                // Convert all properties to string and check if any of them includes 'logo'
                for (const key in image) {
                    if (String(image[key]).toLowerCase().includes("logo")) {
                        return false; // Exclude this image
                    }
                }
                return true; // Include this image
            });
        },
    },
    methods: {
        replaceImage(image, i = this.queue.selectedImageIndex) {
            console.log("replace image");
            let images = this.slide.images;
            if (i || i === 0) {
                let existingImage = this.slide.images[i];
                let newImage = { ...existingImage, url: image.url, prompt: image.title, title: image.title };
                images.splice(i, 1, newImage);
                updateSlideProp(this.$store, "images", images, this.index);
                updateQueueProp(this.$store, "selectedImageIndex", null);
                updateSlideProp(this.$store, "showImages", false, this.index);
            }
        },
        addImageToSlide(image) {
            if (image.query) {
                image.prompt = image.query;
            } else if (image.title) {
                image.prompt = image.title;
            } else if (image.alt) {
                image.prompt = image.alt;
            }
            console.log("image", image);
            if (this.queue.selectedImageIndex === 0 || (this.queue.selectedImageIndex !== null && this.queue.selectedImageIndex !== undefined)) {
                console.log("replace image");
                this.replaceImage(image, this.queue.selectedImageIndex);
                return;
            } else {
                console.log("add image");
            }
            if (image.source && image.source.length > 0) {
                try {
                    new URL(image.source);
                } catch (e) {
                    image.prompt = `${image.source} ${image.prompt}`;
                }
            }
            let images = this.slide.images;

            if (!images) images = [];
            if (!images) images = updateSlideProp(this.$store, "images", [], this.index);

            let newImage = imageObject(image.url, image.title || this.slide.image);
            newImage = { ...image, ...newImage };
            let newImages = [newImage, ...images];
            updateSlideProp(this.$store, "images", newImages, this.index);
            updateSlideProp(this.$store, "showImages", false, this.index);
        },
    },
    props: {
        slide: {},
        imageIndex: {},
        index: {},
    },
};
</script>
<!--    <div class="x z-5 f p-absolute p-fill y bg-blur br-10 m-0 mx-auto flex-wrap gap-0 grid-cols-12 bg-base-900/50 p-0 p-2" style="overflow-y: scroll">-->
<!--        <div class="p-fill fixed bg-base-900"></div>-->
<!--            <div :key="'image' + index" class="col-6 col-md-3 x z-5 p-md-2 p-md-2 m-0 m-0 block p-1" style="margin-right: 0px;!important;" v-for="(image, index) in document.images">-->
