<template>
    <div class="pb-4 border-bottom editor-section">
        <label class="block editor-section-title">Colors:</label>
        <div class="flex items-center overflow-x-scroll scrollbar-hide flex-wrap gap-1">
            <template :key="color" v-for="(color, index) in colors">
                <div class="c-35 cursor-pointer border f aic jcc" @click.prevent="updateColors(color)" :style="`color:${color?.color};background-color:${color?.backgroundColor};`">
                    <!--                  ;border-color:${color?.color}`-->
                    <span class="fwb f-15">Aa</span>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: "SectionColorEditor",
    props: {
        colors: {
            type: Array,
            default: () => [],
        },
    },
    emits: ["update-colors"],
    methods: {
        updateColors(obj) {
            this.$emit("update-colors", obj);
        },
    },
};
</script>
