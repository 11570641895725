import { mapActions } from "vuex";
import SearchSummary from "@/mixins/Chat/SearchSummary";
import updateMessageProp from "@/mixins/ai/update_message_prop";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import addImagesToQueue from "@/mixins/ai/add_images_to_que";
import webRequest from "@/mixins/ai/web_request";
const start = "[QST]";
const end = "[QND]";
export default {
    created: function () {},
    mounted() {},
    mixins: [SearchSummary],
    data() {
        return {
            web_search: {
                name: "Search the Web",
                command: "web_search",
                kind: "search",
                start: start,
                end: end,
                role: "assistant",
                args: { query: "" },
                description: `Use Google to access the internet, find information, search for things, research and more by typing ${start}< your google query >${end}.`,
                show: true,
                newMessage: false,
                resources: ["Internet access for searches and information gathering.", "Query google for more accurate information.", "Search the web."],
                exampleUses: [
                    `I'll look into flights for you now. ${start} Flights from Los Angeles to Bali ${end}`,
                    `Let me research that a bit more ${start} Current best practices for Vue.js ${end}`,
                    // "Let me research that a bit more [QST] Current best practices for Vue.js [QND]"
                ],
            },
        };
    },
    computed: {
        routeType() {
            let currentRoute;
            // get the current route
            if (this.$route.path) {
                currentRoute = this.$route.path;
            }
            if (currentRoute.includes("presentation")) {
                return "presentation";
            }
            if (currentRoute.includes("chat")) {
                return "chat";
            }
            if (currentRoute.includes("style-guide")) {
                return "styleGuide";
            }
            return currentRoute;
        },
    },
    methods: {
        async saveSearchResults(response) {
            const searchResults = response;
            searchResults.id = this.randomId();
            let object = await this.addToFirebase("searchResults", response);
            console.groupCollapsed("%cSearch results saved", success);
            console.log(object);
            console.groupEnd();
            return object;
        },
        async searchWeb(args, name, message, index, simple, kind) {
            const query = args.query;
            console.groupCollapsed(`%c🔎 Search Web`, purple, query);
            console.log("Name: ", name);
            console.log("Query: ", query);
            console.log("Args: ", args);
            console.log("Message: ", message);
            console.log("Index: ", index);
            console.log("Simple: ", simple);
            console.groupEnd();
            let content;
            try {
                let request = { query: query };
                try {
                    if (this.userPlaceTime && this.userPlaceTime.location) request.location = this.userPlaceTime.location;
                } catch (error) {}
                if (kind) request.kind = kind;
                const response = await webRequest("searchWeb", request);

                if (!response.ok) console.error("ERROR", this.gptError(response));
                const data = await response.json();

                const images = data.images;
                if (images?.length > 0) addImagesToQueue(this.$store, images);
                if (simple) delete data.images;
                logGroup(`Search Results: ${query}`, data);
                content = await this.extractedSectionsMethod(data);
                let { text, urls, sources, results } = content;

                try {
                    if (!simple) updateMessageProp(this.$store, "results", data);
                    else updateQueueProp(this.$store, "research.results", data);
                } catch (error) {
                    console.error(error);
                }
                // }
                // content = content.replace(/\n\n/g, "\n");
                content = {
                    summary_style: "Use paragraphs with markdown to bold important entities. Don't just list results.",
                    query: args.query,
                    urls: data.urls,
                    results: content,
                    ...data,
                };
                // console.log("content", content);
                if (simple) {
                    return {
                        summary_style: "Use paragraphs with markdown to bold important entities. Don't just list results.",
                        query: args.query,
                        urls: data.urls,
                        results: content,
                        search_results: data,
                    };
                }
                return content;
                // return data;
            } catch (error) {
                this.gptError(error);
                console.error("Error fetching searchApi2:");
                return this.gptError(error);
            }
            return;
        },

        async processPresentationSearchResults(data, simple, query) {
            const images = data.images;
            // if (this.routeType === "presentation") {
            if (images && images.length > 0) {
                addImagesToQueue(this.$store, images);
            }
            if (simple) {
                delete data.images;
            }
            let content = await this.extractedSectionsMethod(data);
            if (!simple) {
                updateMessageProp(this.$store, "results", data);
            } else {
                updateQueueProp(this.$store, "research.results", data);
            }
            content = content.replace(/\n\n/g, "\n");
            content = {
                summary_style: "Use paragraphs with markdown to bold important entities. Don't just list results.",
                // summary_style: "Use paragmarkdown with headers. Paragraphs. Bold important entities. Don't use lists.",
                query: args.query,
                urls: data.urls,
                results: content,
            };
            if (simple) return content;
            return content;
        },
        async searchTheWeb(args, name, message, index) {
            // console.error("searching web: ", query);
            let finalQuery = args;
            let msgs = this.$store.state.chat.messages;
            if (typeof args === "object") {
                if (args.query) {
                    finalQuery = args.query.trim();
                    console.log("FIXING QUERY 1", finalQuery);
                }
            }
            let i;
            if (args && args.index) {
                i = args.index;
                // console.error("search index – query index as arg", index);
            } else if (this.index) {
                i = this.index;
                // console.error("search index – this.index", index);
            } else {
                i = this.messages.length - 1;
                // console.error("search index – length", index);
            }
            console.groupCollapsed(` %c 🔎 Searching web`, rose, finalQuery);
            console.log("Query", args);
            try {
                console.log("Query.query", args.query);
            } catch (error) {
                console.error("Query.query", error);
            }
            console.log("Final Query: ", finalQuery);
            console.groupEnd();
            // remove action start and end
            if (!msgs[i].action.query) this.messages[i].action.query = finalQuery.trim();
            let request = { query: finalQuery };
            if (this.userPlaceTime && this.userPlaceTime.location) {
                request.location = this.userPlaceTime.location;
            }
            // await this.saveChat();
            try {
                const response = await webRequest("searchWeb", request);

                if (!response.ok) {
                    console.error("ERROR", this.gptError(response));
                }

                const data = await response.json();
                // this.extractedSectionsMethod(data);
                return data;
            } catch (error) {
                return this.gptError(error);
                console.error("Error fetching searchApi2:", this.gptError(error));
            }
        },
    },
};
