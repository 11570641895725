<template>
    <div v-if="thoughts" class="menu_section mw-500">
        {{ allArrayItems }}
        <CollapsableMenuItem>
            <template #title>
                <p class="fwb line-clamp-1">
                    <span>Thinking</span>
                    <!--                    <span class="fwn ml-2 o-5 text-right f-15">{{ lastArrayItem }}</span>-->
                </p>
            </template>

            <div>
                <CollapsableMenuItem v-if="thinking?.[step]" v-for="(step, sectionIndex) in ['thoughts', 'reasoning', 'plan', 'criticism', 'speak']" :key="`section${sectionIndex}`" class="px-0 border-bottom py-2 f-15" inline>
                    <template #title>
                        <div class="f jcs gap-4 aic">
                            <h6 class="fwb">{{ step }}</h6>
                        </div>
                    </template>
                    <div v-if="step" class="f-11 mb-0 text-uppercase fwb o-5">
                        <template v-if="typeof thinking[step] === 'object'">
                            <span class="block" v-for="s in thinking[step]">{{ s }}</span>
                        </template>
                        <template v-else>
                            {{ thinking[step] }}
                        </template>
                    </div>
                </CollapsableMenuItem>
            </div>
        </CollapsableMenuItem>
    </div>
</template>
<script>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";

export default {
    name: "SideBarThoughts",
    components: { CollapsableMenuItem },
    props: {
        thinking: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        lastArrayItem() {
            // return `${this.thinking[Object.keys(this.thinking)[Object.keys(this.thinking).length - 1]]}`;
        },
        allArrayItems() {
            let thoughts = this.thinking?.thoughts || [];
            let reasoning = this.thinking?.reasoning || [];
            let plan = this.thinking?.plan || this.thinking?.planning || [];
            let criticism = this.thinking?.criticism || [];
            let speak = this.thinking?.speak || "";
            let array = [thoughts, reasoning, plan, criticism, speak];
            array = array.filter(Boolean);
            // if any of the items are an object put them in an array [object]
            array = array.map(item => (typeof item === "object" ? [item] : item));
            array = array.filter(item => item.length > 0);
            // filter out empty items
        },
        reasoning() {
            return this.thinking?.reasoning || [];
        },
        thoughts() {
            return this.thinking?.thoughts || [];
        },
        planning() {
            return this.thinking?.plan || this.thinking?.planning || [];
        },
        criticism() {
            return this.thinking?.criticism || [];
        },
        speak() {
            return this.thinking?.speak || "";
        },
    },
};
</script>
<style lang="scss" scoped>
h6 {
    @apply mb-0 font-bold;
}

.menu_section {
    border-style: solid;
    @apply py-3 border-b border-l-0 border-r-0 border-t-0 border-b-gray-900/10 p-3;
    p {
        @apply mb-0;
    }
}
</style>
