// update_message_prop.js

import compareAndLogChanges from "@/mixins/ai/compare_and_change_logs";
import getNestedValue from "@/mixins/ai/get_nested_object";

function updateMessageProp(store, key, value) {
    try {
        // Get the store instance
        // let lastMessage = store.getters["chat/lastMessage"];
        // const oldVal = getNestedValue(lastMessage, key);
        store.dispatch("chat/updateCurrentMessageProp", { keyPath: key, value: value });
        // const newVal = getNestedValue(lastMessage, key);
        // compareAndLogChanges(newVal, oldVal);
    } catch (error) {
        console.error("updateMessageProp", error.message, key, value);
    }
}

export default updateMessageProp;
