<template>
    <div class="inline-block br-5 inline-flex aic jcs mr-2 mb-1" :class="{ 'pl-2 py-1 border-light': title }">
        <div v-if="title" style="font-family: 'Helvetica Neue'" class="inline fwb mr-1">{{ title }}</div>
        <div class="p-1 px-2 inline fwb text-uppercase br-5 mx-1 border-light" :style="`font-family:arial;color:${lightOrDarkText}; background:${colorToUse}`">{{ colorToUse }}</div>
    </div>
</template>
<script>
import chroma from "chroma-js";
export default {
    name: "ColorPreview",
    props: {
        color: {
            type: String,
            default: "white",
        },
        title: {
            type: String,
            default: "",
        },
    },
    computed: {
        colorToUse() {
            return this.color.includes("gradient") ? this.getColorsFromGradient(this.color)[0] : this.color;
        },
        lightOrDarkText() {
            try {
                return chroma(this.colorToUse).luminance() > 0.5 ? "black" : "white";
            } catch (e) {
                return "white";
            }
        },
    },
    methods: {
        getColorsFromGradient(gradientString) {
            const colorRegex = /#(?:[0-9a-fA-F]{3}){1,2}|rgba?\((?:\d{1,3},\s?){2}\d{1,3}(?:,\s?\d(?:\.\d{1,2})?)?\)|hsla?\((?:\d{1,3},\s?){2}\d{1,3}(?:,\s?\d(?:\.\d{1,2})?)?\)/g;
            let matches = gradientString.match(colorRegex);
            matches = matches.filter(match => !match.includes("rgba(0, 0, 0, 0)"));
            return matches || [];
        },
    },
};
</script>
