<template>
    <div class="x overflow-hidden vw-100">
        <h2 class="mb-0 tracking-tight">See More</h2>
        <div class="p-relative">
            <div class="p-absolute top bottom left z-5 width-max-50 f aic" @click="prevPage">
                <div class="block ml-2 mt-3">
                    <BaseButton icon-only style-type="brand" icon="fa fa-arrow-left c-40 f aic jcc shadow" rounded></BaseButton>
                </div>
            </div>
            <div class="px-2 md:px-4 lg:px-8 z-1 x relative overflow-x-scroll" style="width: 100vw">
                <div class="mt-6 grid grid-cols-1 gap-x-4 gap-y-10 sm:grid-cols-2 lg:grid-cols-6 xl:gap-x-5">
                    <template v-for="(product, index) in paginatedProducts" :key="product.handle">
                        <StyleGuideProductCard :index="index" :product="product"></StyleGuideProductCard>
                    </template>
                </div>
            </div>
            <div class="p-absolute top bottom right z-5 width-max-50 f aic" @click="nextPage">
                <div class="block mr-2 mt-3">
                    <BaseButton icon-only style-type="brand" icon="shadow fa fa-arrow-right c-40 f aic jcc" rounded></BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StyleGuideProductCard from "@/components/styleGuide/Sections/ProductCard.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "ProductCarousel",
    components: { BaseButton, StyleGuideProductCard },
    props: {
        products: Array,
    },
    data() {
        return {
            currentPage: 0,
        };
    },
    computed: {
        productsToShow() {
            // filter out products that are zero dollars or contain the word "route" in the name or "ecocart"
            let products = this.products.filter(product => product.price > 0 && !product.title.toLowerCase().includes("route") && !product.title.toLowerCase().includes("ecocart") && !product.title.toLowerCase().includes("gift card") && !product.title.toLowerCase().includes("giftcard"));
            // sort by updated date
            products = products.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            return products;
        },
        productsPerPage() {
            if (this.mobile) return 1;
            else return 6;
        },
        pageCount() {
            // Ensure we have a whole number of pages, rounding up for any partial page of products.
            return Math.ceil(this.productsToShow.length / this.productsPerPage);
        },
        paginatedProducts() {
            // Calculate start index of products for the current page.
            const start = this.currentPage * this.productsPerPage;
            // Ensure the end index does not exceed the length of the products array.
            const end = Math.min(start + this.productsPerPage, this.products.length);
            return this.productsToShow.slice(start, end);
        },
    },
    methods: {
        nextPage() {
            // Advance the current page, ensuring we do not go beyond the last page.
            if (this.currentPage < this.pageCount - 1) {
                this.currentPage++;
            }
        },
        prevPage() {
            // Go back a page, ensuring we do not go before the first page.
            if (this.currentPage > 0) {
                this.currentPage--;
            }
        },
    },
};
</script>
