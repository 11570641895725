<template>
    <div>
        <div class="f aic jcc gap-3">
            <a :class="{ disabled: generativeLoader }" :style="buttonStyles[0]" class="btn mb-4" href="#" @click.prevent="callForAI">
                <template v-if="generativeLoader">
                    <i class="fal mr-2 fa-sync spinner infinite ease-back"></i>
                    <span>Loading ...</span>
                </template>
                <template v-else>
                    <i class="fal mr-2 fa-pencil-alt"></i>
                    <span>Get {{ styleGuideKey }}</span>
                </template>
            </a>
        </div>

        <div class="text-left mx-auto mw-500" v-if="aiContent?.samples" v-for="sample in aiContent.samples">
            <!--        bulild this out for sample header, subheader, paragraph    -->
            <div v-if="sample.header" class="">
                <h3>{{ sample.header }}</h3>
            </div>
            <div v-if="sample.sub_header" class="">
                <h4>{{ sample.sub_header }}</h4>
            </div>
            <div v-if="sample.body" class="">
                <p>{{ sample.body }}</p>
            </div>
        </div>
        <CollapsableMenuItem class="container-slim" max-height="500" v-if="aiContent">
            <template #title><div class="py-2 pl-3 z-5">Debug Generated</div></template>
            <DynamicObjectViewer v-if="aiContent" :data-object="aiContent" class="container-slim"></DynamicObjectViewer>
            <preview-messages :messages="streamMessages" class="x" />
        </CollapsableMenuItem>

        <div class="x"></div>
        <div :style="mergedStyles" class=""></div>
    </div>
</template>

<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import PreviewMessages from "@/components/styleGuide/debugging/PreviewMessages.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import generativeStyleGuideMixin from "@/mixins/ai/generativeStyleGuideMixin";

export default {
    name: "aiTextSamples",
    components: { CollapsableMenuItem, DynamicObjectViewer, PreviewMessages, },
    mixins: [styleGuideMixin, generativeStyleGuideMixin],
    props: {},
    data() {
        return {
            styleGuideKey: "textSamples",
            componentModel: gpt4,
            save: true,
        };
    },
    computed: {
        aiContent() {
            return this.styleGuide?.ai?.[this.styleGuideKey];
        },
    },
    mounted() {},
    methods: {
        callForAI() {
            // this.setActionTypographyGuide();
            this.setActionWritingSamples();
            this.callFunctionFromComponent("make it good");
        },
    },
};
</script>
