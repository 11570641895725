<template>
    <span class="height-25 cursor-blink inline-block" style="background: limegreen; width: 2px; height: 20px; margin-bottom: -4px"></span>
</template>
<script>
export default {
    name: "BlinkingCursor",
};
</script>
<style lang="scss">
.cursor-blink {
    width: 2px;
    height: 1rem;
    background: black;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>
