<template>
    <div>
        <div class="x web-results p-relative pb-5" :class="{ 'mw-650': !mobile }">
            <transition-group name="list" tag="div" class="animated">
                <div class="x relative" v-if="message && results && results">
                    <div v-for="(section, key) in results">
                        <div class="">
                            <InlineVideos class="mb-md-5 mb-3" v-if="key === 'videos'" :animate="animate" :videos="section"></InlineVideos>
                            <NewsArticles class="mb-md-4 mb-3" v-if="key === 'news'" :column="key === 'news'" :animate="animate" :articles="section"></NewsArticles>
                            <InlineImages v-if="key === 'images'" :images="section" class="mb-md-4 mb-3" rounded></InlineImages>
                            <InlineImages class="y mb-md-4 relative mb-3 h-auto" :height="500" rounded v-if="key === 'images'" :animate="animate" :images="section"></InlineImages>
                            <!--                            <KnowledgeGraph class="mb-md-5 mb-3 block" v-if="key === 'knowledge_graph'" :info="section"></KnowledgeGraph>-->
                            <!--                            <LocalResults v-if="key === 'local_map'" :places="section" title="" :map="section" />-->
                        </div>
                        <TopSites v-if="key === 'top_sights'" :places="section" title="Hot spots" />
                        <ProductResults v-if="key === 'shopping_results'" :products="section"></ProductResults>
                        <PeopleAlsoSearched class="" v-if="key === 'inline_people_also_search_for'" :sights="section" />

                        <!--                        <ImagesResults v-if="key === 'images_results'" :images="section" />-->

                        <template v-if="key === 'answer_box'">
                            <WeatherResults v-if="results && results && results.answer_box && results.answer_box.type === 'weather_result'" :answer_box="results.answer_box" :weather="results.answer_box"></WeatherResults>
                            <LocalResults v-if="results.answer_box.type === 'hotels'" title="" :places="{ places: section.hotels }" />
                            <Places v-if="results.answer_box.type === 'hotels'" :flights="results.answer_box.hotels"></Places>
                        </template>
                        <FlightResults v-if="key === 'flights'" :flights="results.flights"></FlightResults>
                    </div>
                    <ChatMapKit :message="message" :key="`map${index}-${randomId()}`" :pins="[]" v-if="message.results"></ChatMapKit>

                    <slot></slot>
                    <div class="x p-relative">
                        <WebResults v-if="showSources" :show-results="false" class="x flex-wrap overflow-hidden" :class="{ 'mw-300': mobile }" :animate="animate" :search-results="results.organic"></WebResults>
                        <RelatedQuestionsContainer v-if="!message.typing && showRelatedQuestions" :animate="animate" :questions="results.questions" />
                    </div>
                </div>
            </transition-group>

            <!--            <WebLoader class="f-17" :sources="sources" :state="state" />-->
            <div class="border-top my-5 pt-3" v-if="message.results && $store.state.chat.showDebugger">
                <!--<related-questions ref="question1" class="border-bottom p-2" :class="`animated d-${i * 5} fadeInUpSmooth duration-5`" :key="i" :question="{ question: 'Summaries', snippet: extractedSectionString(key, section).trim() }"></related-questions>-->
                <div class="d-block border-light br-5 mb-4 mr-4 bg-snow dark:bg-base-800">
                    <!--                    <ChatMarkdown :content="extractedSections.trim()" :message="extractedSections.trim()" :class="{ 'x block pr-3': mobile, 'mw-650 mb-5': !mobile }"></ChatMarkdown>-->
                    <RelatedQuestions ref="question1" class="border-bottom whitespace-pre-wrap p-2" :class="`animated d-${i * 5} fadeInUpSmooth duration-5`" :key="i" :question="{ question: 'All Sections', snippet: extractedSections.trim() }">
                        {{ extractedSections.trim() }}
                    </RelatedQuestions>
                    <RelatedQuestions ref="question2" v-if="results?.sources > 0" class="border-bottom p-2" :class="`animated d-${i * 5} fadeInUpSmooth duration-5`" :key="i" :question="{ question: 'All sources', snippet: results.sources }" />
                </div>
                <div class="d-block border-light mb-4 mr-4 bg-snow dark:bg-base-800" v-for="(section, key, i) in filteredResults" :key="i">
                    <RelatedQuestions ref="question1" class="border-bottom br-5 p-2" :class="`animated d-${i * 5} fadeInUpSmooth duration-5`" :question="{ question: key.replace('_', ' '), snippet: extractedSectionString(key, section).trim() }" />
                    <RelatedQuestions v-if="results[key]" ref="question1" class="border-bottom f-11 whitespace-pre-wrap px-1" :class="`animated d-${i * 5} fadeInUpSmooth duration-5`" :question="{ question: `JSON`, snippet: JSON.stringify(section, null, 4) }" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ChatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import WebLoader from "@/components/chat/specialMessages/Search/WebLoader.vue";
import { mapActions } from "vuex";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import KnowledgeGraph from "@/components/chat/specialMessages/Search/KnowledgeGraph.vue";
import NewsArticles from "@/components/chat/specialMessages/Search/NewsArticles.vue";
import WeatherResults from "@/components/chat/specialMessages/Search/WeatherResults.vue";
import RelatedQuestionsContainer from "@/components/chat/specialMessages/Search/RelatedQuestionsContainer.vue";
import TopSites from "@/components/chat/specialMessages/Search/TopSites.vue";
import InlineImages from "@/components/chat/specialMessages/Search/InlineImages.vue";
import LocalResults from "@/components/chat/specialMessages/Search/LocalResults.vue";
import WebResults from "@/components/chat/specialMessages/Search/WebResults.vue";
import PeopleAlsoSearched from "@/components/chat/specialMessages/Search/PeopleAlsoSearched.vue";
import InlineVideos from "@/components/chat/specialMessages/Search/InlineVideos.vue";
import RelatedQuestions from "@/components/chat/specialMessages/Search/RelatedQuestions.vue";
import FlightResults from "@/components/chat/specialMessages/Search/SearchFlightBox.vue";
import ProductResults from "@/components/chat/specialMessages/Search/ProductResults.vue";
import SearchSummary from "@/mixins/Chat/SearchSummary";
import ImagesResults from "@/components/chat/specialMessages/Search/ImagesResults.vue";
import ChatMapKit from "@/components/chat/specialMessages/Search/ChatMapKit.vue";

export default {
    inject: ["scrollChatWindowToBottom", "sendMessage", "chatResponseReceived", "toolBot"],
    mixins: [SearchSummary, ChatCommands],
    components: {
        ChatMapKit,
        FlightResults,
        RelatedQuestions,
        ProductResults,
        InlineVideos,
        PeopleAlsoSearched,
        WebResults,
        LocalResults,
        InlineImages,
        TopSites,
        RelatedQuestionsContainer,
        WeatherResults,
        NewsArticles,
        KnowledgeGraph,
        ChatMarkdown,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        message: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            results: {
                data: {},
                content: "",
                summary: {},
            },
            state: "loading",
            sources: [],
            organicLinks: [],
            showWebLoader: false,
            typing: false,
            query: "",
            action: {},
            content: "",
            animate: true,
            allQuestions: [],
            lastMessageIndex: this.index,
            excludeFromResults: ["search_metadata", "created", "updated", "id", "search_information", "ads", "serpapi_pagination", "local_map", "pagination", "search_parameters"],
            excludeFromSummary: ["related_searches", "search_metadata", "created", "updated", "id", "search_information", "ads", "serpapi_pagination", "local_map", "pagination", "search_parameters"],
        };
    },
    decodeResults(results) {
        // this.addDataWithDelay(decodedResults)
    },
    async mounted() {
        if (this.currentIndex === this.index) {
            this.animate = true;
        }
        // this.$nextTick(async () => {
        // await this.handleLoadingResults();
        this.results = this.message.results;
        if (this.message.results) {
            // this.collectOrganicURLs();
            // this.collectSources;
            // this.collectQuestions();

            this.$nextTick(async () => {
                // let sources;
                // if (!this.message.sources) {
                //     sources = this.extractedSources(this.message.results);
                // }
                // const urls = this.extractedUniqueURLS;
                // const organicLinks = this.organicLinks;
                // if (sources && sources.length > 0) {
                //     if (!this.message.sources) this.updateMessageProp(this.$store,"sources", sources);
                // }
                // this.updateMessageProp(this.$store,"urls", urls);
                // this.updateMessageProp(this.$store,"organic_urls", organicLinks);
            });
        }
        // });
    },
    methods: {
        ...mapActions("chat", ["improveMessageObject", "updateMessageSummary"]),
        collectQuestions() {
            if (this.message.results && this.message.results && this.message.results.questions) {
                let all_questions = [];
                let related_question = this.$toRaw(this.message.results.questions);
                let questionsFromOrganicResults = [];
                if (this.message.results.organic) {
                    this.message.results.organic.forEach(result => {
                        if (result.organic) {
                            result.organic.forEach(question => {
                                questionsFromOrganicResults.push(this.$toRaw(question));
                            });
                        }
                    });
                }
                this.allQuestions = all_questions.concat(related_question, questionsFromOrganicResults);
                console.log("All Questions", this.allQuestions);
                return;
            }
            return;
        },

        async handleLoadingResults() {
            this.results = this.message.results;
            return;
            this.sources = [];
            this.state = `<div class="">Web search: <span class="fwn mw-250">${action.query}</span></div>`;
            return;
        },
        async summarizeResults(response) {
            await this.summarizeSections(response, this.query);
            return;
        },

        getOrganicResultSources(results) {
            let sources = [];
            if (this.results && this.results.organic) {
                this.results.organic.forEach(result => {
                    if (result.source) {
                        sources.push(result.source);
                    }
                });
            }
            this.sources = sources;
            this.sources = this.results.sources;
            return this.sources;
        },
        async summarizeSections(response, relatedTo) {
            this.state = "Reviewing Results";
            this.typing = true;
            this.results.content = `Results for ${this.query}\n`;
            return;
        },
        async summarizeSectionSummaries(sections) {
            return false;
            if (!this.results.summary.main) {
                this.state = "Summarizing";
                let sectionSummaries = this.sectionSummaries;
                if (sections) {
                    sectionSummaries = sections;
                }
                let relatedTo = "";
                if (this.message.action.query) {
                    relatedTo = ` related to ${this.message.action.query}`;
                }
                // let sectionSummary = await this.completion(sectionSummaries, ``, `Summarize: all summaries`);
                // `Combine and Summarize the information in a helpful and concise way. Surface key information${relatedTo}. Use markdown for better formatting.`,

                // let systemPrompt = this.summaryPrompt(sectionSummaries);
                let promptRules = [
                    "Write in the style of a New York Times article.",
                    `Don't mention "Sections", the "user", or "the assistant".`,
                    "The presentation must be well-designed and reader-friendly (use tables, lists, etc but don't over-do it)",
                    "Aim for the optimal presentation to the user.",
                    "Write it as if you're an assistant",
                    "Include helpful tips",
                ];
                let promptTools = ["Use Markdown to bold important information.", "Use markdown for tables", 'At the end, you can save notes to your memory using "[RST] <important_information_to_save> [RND]"'];
                let summaryPrompt =
                    "You are a New York Times writer that has fetched structured search results and you're summarizing them for a user. Please provide a reader-friendly summary of the given information, using markdown to emphasize important information. Present the summary in a narrative format suitable for a wide range of categories, and include any relevant highlights or key information. If needed, use a table to list important details for each item";
                summaryPrompt += "RULES:" + "\n";
                summaryPrompt += "- " + promptRules.join("\n- ");
                summaryPrompt += "NARRATIVE:" + "\n";
                summaryPrompt += "- Second Person" + "\n";
                summaryPrompt += "TOOLS:" + "\n";
                summaryPrompt += "- " + promptTools.join("\n- ") + "\n";
                summaryPrompt += "GOOD PRACTICE:" + "\n";
                summaryPrompt += "- It's helpful to start with something to the effect of 'Thanks for your patience, here's what I've found for you ...'" + "\n";
                let exampleMessages = [
                    // { role: "system", content: `You are an info bot that makes information easy to read for me.\n${systemPrompt.responsePrompt}\n${this.formattedActionString}` },
                    sysMessage(summaryPrompt),
                    userMessage(sectionSummaries),
                ];
                const originalContent = this.message.content;
                let tempContent = this.message.query;
                let tempMessage = this.message;
                tempMessage.content = `${tempContent}\n\n`;
                this.$store.dispatch("chat/updateMessageObject", { index: this.index, newMessage: tempMessage });
                // this.$store.dispatch("chat/setMessages", );
                // this.$store.dispatch("chat/addMessageStore", { role: "assistant", content: "" });
                await this.simpleMessage(
                    exampleMessages,
                    null,
                    null,
                    () => {},
                    async () => {
                        console.log("Processing completed");
                        const summary = this.results.summary.main;
                        this.typing = false;
                        // this.toolBot(this.results.summary.main, this.index);
                        tempMessage.content = originalContent + "\n\n" + this.results.summary.main;
                        let finalMessage = { ...tempMessage, results: { ...this.results } };
                        this.$store.dispatch("chat/updateMessageObject", { index: this.index, newMessage: finalMessage });
                        await this.splitMessageIntoParts(finalMessage);
                        await this.chatResponseReceived(this.$store.state.chat.messages[this.$store.state.chat.messages.length - 1], this.$store.state.chat.messages.length - 1);
                        this.results.summary.main = "Results:";
                        this.state = "Saving results";
                        this.sources = [];
                        this.saveAndFetchMessages();
                    },
                    500,
                    token => {
                        this.$store.dispatch("chat/appendContentToLastMessage", token);
                        this.processCodeOrImage(this.$store.state.chat.messages[this.$store.state.chat.messages.length - 1], this.$store.state.chat.messages.length - 1);
                        this.results.summary.main += token;
                        // this.processCodeOrImage(this.$store.state.chat.messages[this.$store.state.chat.messages.length - 1], this.$store.state.chat.messages.length - 1);
                    },
                    0.0,
                    "Search summaries",
                    gpt3,
                );
                await this.saveAndFetchMessages();
                return;
            }
        },
        async addDataWithDelay(response) {
            // filter out keys we don't want to show
            if (typeof response === "string") {
                response = JSON.parse(response);
            }
            let keysToExclude = ["search_metadata", "created", "updated", "id", "search_information", "ads", "serpapi_pagination", "pagination", "search_parameters"];
            for (let key in response) {
                if (response.hasOwnProperty(key) && !keysToExclude.includes(key)) {
                    this.showWebLoader = true;
                    console.groupCollapsed(`Adding ${key}`);
                    console.log(this.$toRaw(response[key]));
                    console.groupEnd();
                    // this.scrollChatWindowToBottom(true);
                    await new Promise(resolve => setTimeout(resolve, 2000)); // Set delay in milliseconds
                    // this.scrollChatWindowToBottom(true);
                    this.results[key] = response[key];
                    // this.message.results = { ...this.$toRaw(this.results) };
                    // await this.saveAndFetchMessages();
                }
            }
            console.error("search response", response);
            this.showWebLoader = false;
            await this.saveAndFetchMessages();
            try {
                await this.summarizeSections(response, this.query);
            } catch (e) {
                console.error(e);
            }
        },
        async getResults(from) {
            let query = this.query.trim();
            let queryFromArgs;
            let action = this.action;
            let message = this.message;
            let queryFromMessage = message.query;
            let results;
            if (message?.results) results = message.results;
            if (message?.action?.args?.query) {
                // console.error("Query from Args", message.action.args.query);
                queryFromArgs = message.action.args.query;
                this.message.query = queryFromArgs.trim();
                this.message.action.query = queryFromArgs.trim();
            }
            if (queryFromMessage) {
                query = queryFromMessage.trim();
            } else if (queryFromArgs) {
                query = queryFromArgs.trim();
            } else {
                query = this.query.trim();
            }
            this.state = `Searching the web for ${query}`;
            this.query = query.trim();
            console.groupCollapsed(`%c🔎 Step 3: Get Results for "${query}"`, info);
            console.log("Step 3: query - ", query.trim());
            console.log("Step 3: message - ", message.content);
            console.log(this.$toRaw(message.action));
            console.groupEnd();
            //
            //
            //
            const response = await this.searchTheWeb(query);
            //
            //
            //
            //
            // map organic_results sources to array
            this.state = "Processing results";

            this.getOrganicResultSources(response);
            await this.saveAndFetchMessages(response);
            await this.summarizeResults(response);
            // await this.addDataWithDelay(response);
            await this.saveSearchResults(response);
            return;
        },
        async saveAndFetchMessages(response) {
            // Replace with your actual values for id, index, and newMessage
            const id = this.$route.params.id;
            const index = this.index;
            let updatedMessage = { ...this.message };
            let newMessage = { ...updatedMessage, action: { ...this.message.action }, results: this.results };
            if (response) {
                newMessage.results = response;
            }
            console.groupCollapsed(`%c💾 Step 5: Save and Fetch Messages`, info);
            console.error(id, index, newMessage);
            // return;
            let saveObject = { id, index, newMessage };
            console.log("ACTION:");
            console.log(newMessage.action);
            console.log(`QUERY: ${newMessage.action.query}`);
            console.log(saveObject);
            console.groupEnd();
            try {
                await this.improveMessageObject(saveObject);
            } catch (e) {
                console.error("Trying to save the message object failed", e);
            }
            return;
        },
    },
    computed: {
        showSources() {
            // return true;
            return this.message.results?.organic?.length > 0 && !this.message.typing;
            // return !this.typing && this.showRelatedQuestions && this.results?.data?.related_questions && this.results?.data?.organic_results?.length > 0 && this.results?.summary?.main?.length > 10;
        },
        summaryText() {
            try {
                let messages = this.$store.state.chat.messages;
                let index = this.index;
                return messages[index].results.summary.main;
            } catch (e) {
                return false;
            }
            return false;
        },
        advancedDebugger() {
            return this.$store.state.chat.advancedDebugger;
        },
        isTyping() {
            if (this.typing) return true;
            return false;
        },
        showRelatedQuestions() {
            const relatedQuestions = this.message.results?.questions?.length > 0;
            if (relatedQuestions) {
                return true;
            }
            return false;
        },
        filteredResults() {
            let results = this.message.results;
            let keysToExclude = this.excludeFromSummary;
            let filteredResults = {};
            for (let key in results) {
                if (results.hasOwnProperty(key) && !keysToExclude.includes(key)) {
                    filteredResults[key] = results[key];
                }
            }
            return filteredResults;
        },
    },
    name: "ChatWebResults",
};
</script>
<style lang="scss">
.list-enter-active {
    transition:
        max-height 1s ease-out,
        opacity 2s linear,
        transform 2s ease-out;
    overflow: hidden;
    max-height: 0px !important;
}
.list-enter {
    transition:
        max-height 1s ease-out,
        opacity 2s linear,
        transform 2s ease-out;
    opacity: 0;
    overflow: hidden;
    max-height: 0px !important;
    transform: translateY(30px);
}
.list-enter-to {
    transition:
        max-height 1s ease-out,
        opacity 2s linear,
        transform 2s ease-out;
    opacity: 1;
    overflow: hidden;
    max-height: 500px !important;
    transform: translateY(0);
}
.list-leave-active {
    transition:
        max-height 1s ease-out,
        opacity 2s linear,
        transform 2s ease-out;
    max-height: 0px;
    overflow: hidden;
}
.list-leave {
    transition:
        max-height 1s ease-out,
        opacity 2s linear,
        transform 2s ease-out;
    opacity: 1;
    overflow: hidden;
    transform: translateY(0);
}
.list-leave-to {
    transition:
        max-height 1s ease-out,
        opacity 2s linear,
        transform 2s ease-out;
    overflow: hidden;
    opacity: 0;
    max-height: 0px !important;
    transform: translateY(30px);
}
.chat-markdown {
    h1 {
        @apply text-xl;
        @apply mt-4;
        @apply mb-2;
        font-weight: 700;
    }
    li {
        //@apply ml-4;
    }
}

hr {
    @apply my-5;
    // style the hr
    border-top: 1px solid;
    @apply border-t border-gray-100;
    @apply dark:border-base-500;
}
</style>
