import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import webRequest from "@/mixins/ai/web_request";

async function replaceBackground(url, prompt) {
    const request = { image_url: url, prompt: prompt };
    const response = await webRequest("replaceBackground", request);

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    console.log("Got image!");
    // parse the response as JSON
    const data = await response.json();
    const imageUrl = data.url; // Extracts image URL from the response
    console.log("Image URL:", imageUrl);
    return imageUrl; // return the imageURL
}
export default replaceBackground;
