import JSON5 from "json5";
import { mapActions, mapGetters } from "vuex";

import BrowseCommand from "@/mixins/Chat/Commands/BrowseCommand";
import SearchCommand from "@/mixins/Chat/Commands/SearchCommand";
import ResearchCommand from "@/mixins/Chat/Commands/ResearchCommand";
import randomId from "@/mixins/ai/random_id";
import streamCompletion from "@/mixins/ai/stream_completion";
import streamingMixin from "@/mixins/ai/streamingMixin";
import updateDocumentProp from "@/mixins/ai/update_document_prop";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import updateSlideProp from "@/mixins/ai/update_slide_prop";
import extractText from "@/mixins/Chat/Commands/extract_text";
import enhancePresentation from "@/mixins/presentations/EnhancePresentation";
import presentationPrompt from "@/mixins/presentations/PresentationPrompt";
import logPresentation from "@/mixins/Chat/Commands/log_presentation";
import create_presentation from "@/ai_functions/create_presentation";
import log_messages from "@/mixins/ai/log_messages";
import logMessages from "@/mixins/ai/log_messages";

export default {
    created: function () {},
    mixins: [streamingMixin, enhancePresentation, SearchCommand, BrowseCommand, ResearchCommand],
    data() {
        return {};
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue", "showProgress", "currentSlideCount", "complete"]),
        slideFormat() {
            const example = {
                title: "<2-5 words representing the slide topic>",
                body: "<a short overview of the slide's content>",
                image: "[<pencil drawing, photograph, painting, etc>][description of the image][in the style of ... (an artist, photographer, etc)]", // describe the image. [An (Image, a Drawing, or a Painting) of (subject description). Required.]
                style: '< CHOOSE ONE:"tableOfContent", "imageLeft", "imageRight", "fullbleed", "timeline", "matrix", "grid">',
            };
            return JSON.stringify(example, null, 0);
        },
    },
    mounted() {
        window.researchPresentation = this.researchPresentation;
    },
    inject: ["scrollPageToBottom"],
    methods: {
        ...mapActions("presentation", ["setTitle", "getPresentation", "savePresentation", "addSlide", "updatePresentation", "setDocId", "clearSections"]),
        async newDeckModal(prompt, kind, slideCount) {
            console.error("saving new deck");
            const tempId = randomId();
            const timestamp = Date.now();
            let doc = {
                title: "New presentation",
                theme: {
                    colors: null,
                    shades: null,
                    why: null,
                },
                id: tempId,
                created: timestamp,
                updated: timestamp,
                owner: this.$store.state.user.user.uid,
                documentType: "presentation",
                sections: [],
            };
            const networkDoc = await this.addToFirebase("documents", doc);
            this.$router.push({ path: `/presentations/${networkDoc.id}` });
            this.clearQueue();
            this.$forceUpdate();
            this.$nextTick(async () => {
                updateQueueProp(this.$store, "showProgress", true);
                await this.runSlideGenerationStream(prompt);
            });
            // await this.runSlideGenerationStream(prompt, slideCount);
            // await this.requestPresentationFromOpenAI(prompt, slideCount);
            // await this.requestPresentationFromOpenAI(prompt, this.document.id);
        },
        clearQueue() {
            this.$store.dispatch("presentation/clearQueue");
        },

        async runSlideGenerationStream(topic) {
            updateQueueProp(this.$store, "topic", topic);
            let messages = [sysMessage(presentationPrompt)];
            this.body = "";
            logPresentation(topic, messages);
            messages = await this.researchPresentation(topic, messages);
            // messages.push(sysMessage(`Existing Image List:\n\n${this.$store.getters["presentation/imagesList"]}`));
            messages.push(userMessage(topic));
            // consoleTableReduced(request, ["messages"]);
            // console.groupEnd();

            updateDocumentProp(this.$store, "typing", true);

            await this.generatePresentation(topic, messages);
        },
        async generatePresentation(topic, messages) {
            // gpt-3.5-turbo-16k
            let imageList = sysMessage(`Existing Image List:\n\n${this.$store.getters["presentation/imagesList"]}`);

            // slot the image list as the second to last message
            messages.splice(messages.length - 1, 0, imageList);
            // deduplicate messages
            messages = messages.filter((message, index, self) => self.findIndex(m => m.content === message.content) === index);
            logGroup(
                "Messages",
                messages.map(m => m.content),
            );
            updateDocumentProp(this.$store, "sections", []);
            await streamCompletion(
                this.$store,
                `${topic}. Must be at least 20 slides.`,
                messages,
                null,
                undefined,
                (token, function_objects, json) => {
                    this.$store.commit("presentation/setDocument", json);
                    this.scrollPageToBottom();
                },
                undefined,
                gpt3, //gpt316,
                undefined,
                `Presentation - ${topic}`,
                0.0,
                create_presentation,
                "create_presentation",
                2000,
            );
            updateDocumentProp(this.$store, "typing", false);
            updateDocumentProp(this.$store, "complete", true);
            await this.savePresentation(this.$route.params.id);
            await this.savePresentationImages();
            await this.setTheme();
            await this.saveResearchToDocument();
            // await this.updateSlidesWhenFinished();
            await this.savePresentation(this.$route.params.id);
            await this.enhancePresentation(true);
        },
        async setTheme() {
            try {
                if (this.document.theme_colors_hsl) {
                    let colors = this.getColorScheme(this.document.theme_colors_hsl);
                    updateDocumentProp(this.$store, "theme", colors);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async saveResearchToDocument() {
            try {
                if (this.queue?.research) updateDocumentProp(this.$store, "research", this.queue.research);
            } catch (error) {}
        },
        async savePresentationImages() {
            try {
                if (this.queue?.images) {
                    updateQueueProp(this.$store, "loading", false);
                    updateDocumentProp(this.$store, "images", this.queue.images);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async updateSlidesWhenFinished() {
            try {
                this.document.sections.forEach((slide, index) => {
                    if (slide.image_description) {
                        this.slideClass(slide.mode, index);
                        updateSlideProp(this.$store, "image", slide.image_description, index);
                        if (this.document.images) {
                            let images = [
                                {
                                    prompt: slide.image_description || "",
                                    url: this.document.images[index].url,
                                },
                            ];
                            updateSlideProp(this.$store, "images", images, index);
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        async afterSlideIsComplete(response, subject) {
            console.error("After slide is complete");
            updateDocumentProp(this.$store, "title", subject);
            updateDocumentProp(this.$store, "id", this.$route.params.id);
            updateDocumentProp(this.$store, "documentType", "presentation");
            updateDocumentProp(this.$store, "raw", response);
            // this.document.sections = this.sections;
            // this.$router.push({ path: `/presentations/${this.$route.params.id}` });
        },
    },
};
