import json_helpers from "@/mixins/ai/json_helpers";

let { masterJSONFixer } = json_helpers;
function isCommandObject(content, message, index) {
    let isCommand;
    try {
        if (content) content.startsWith("{") && content.includes("command");
        if (isCommand) {
            return true;
            try {
                // let newMessage = message;
                // newMessage.action = cleanedJSONString;
                return masterJSONFixer(content, false, true);
            } catch (e) {
                // console.error("Error parsing JSON:", e);
                return content;
            }
        }
    } catch (e) {
        console.error("Error parsing JSON:", e);
    }
    return;
}

export default isCommandObject;
