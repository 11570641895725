<template>
    <div class="mw-700">
        <Radar :data="chartData" :options="options" />
    </div>
</template>

<script lang="ts">
import { Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip } from "chart.js";
import { Radar } from "vue-chartjs";
import { mapGetters } from "vuex";
import chroma from "chroma-js";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export default {
    name: "RadarChart",
    components: {
        Radar,
    },
    props: {
        toneData: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters("styleGuide", ["linkColor", "fonts"]),
        chartData() {
            const labels = this.toneData.map(item => item.name);
            const data = this.toneData.map(item => item.value);
            const color = chroma(this.linkColor).alpha(0.5).css();
            return {
                labels: labels,
                datasets: [
                    {
                        label: "Tone Analysis",
                        backgroundColor: color,
                        borderColor: this.linkColor,
                        pointBackgroundColor: this.linkColor,
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: this.linkColor,
                        data: data,
                    },
                ],
            };
        },
        options() {
            let font = this.fonts?.[0]?.name || "inherit";
            const fontConfig = { family: font, size: 20 };
            const gridAndAngleLineColor = chroma(this.linkColor).alpha(0.1).css();
            const data = this.toneData.map(item => item.value);
            const max = Math.max(...data) + 20;
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    r: {
                        beginAtZero: true,
                        max: max,
                        ticks: {
                            display: false,
                        },
                        pointLabels: {
                            font: fontConfig,
                            // color: "transparent",
                        },
                        grid: {
                            color: gridAndAngleLineColor,
                            lineWidth: 1,
                            circular: true,
                        },
                        angleLines: {
                            color: gridAndAngleLineColor,
                            lineWidth: 1,
                        },
                    },
                },

                elements: {
                    line: {
                        borderWidth: 1,
                        fill: true,
                        tension: 0.3,
                        capBezierPoints: false,
                        borderJoinStyle: "round",
                        borderCapStyle: "round",
                    },
                    point: {
                        radius: 0,
                        borderWidth: 0,
                        hoverRadius: 7,
                        hoverBorderWidth: 0,
                        tension: 0.4,
                    },
                },
                plugins: {
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        display: false, // This will hide the legend
                        // font: fontConfig,
                    },
                },
            };
        },
    },
};
</script>
