function logTokens(data) {
    let { response, responseCost, prompt, promptCost, model } = data;
    let totalCost = parseFloat(responseCost) + parseFloat(promptCost);
    let totalTokens = response + prompt;
    console.groupCollapsed(`🎟️ Tokens: ${totalTokens} %c$${totalCost}`, "color:gray;font-size:10px;font-weight:normal;");
    console.log("Prompt:", prompt, "Tokens");
    console.log("Cost:", "$", promptCost);
    console.log("Completion:", response, "Tokens");
    console.log("Cost:", "$", responseCost);
    console.log("Model:", model);
    console.groupEnd();
}
export default logTokens;
