<template>
    <div class="grid-cols-12 grid">
        <div ref="container" class="col-span-12 bg-black relative">
            <div ref="EmailContainer" class="br-20 mw-500 mx-auto vh-100 f aic jcc">
                <div ref="thinkingText" class="visualy-hidden"></div>
                <div ref="d" class="mt-nav mh-70 overflow-hidden br-20 fc height-max-900 f aic jce" style="min-height: 70vh">
                    <Transition mode="out-in" name="fade-up">
                        <div v-if="!text.sections" :style="emailScale" class="bg-gray-100 f ais jcc vh-70">
                            <LandingPageLoader :result="{ name: text.name, thinking: text.thinking }" class="px-3 y"></LandingPageLoader>
                        </div>
                        <div v-else-if="text.subject_line" :style="emailScale" class="f jcc fc aic">
                            <CampaignHeader ref="emailHeader" :result="text" inline type="isEmail"></CampaignHeader>
                            <EmailSections v-if="text.sections.length > 0" :footer="text.email_footer" :sections="text.sections" class="bg-gray-100 mx-auto" style="min-height: 70vh"></EmailSections>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import gsapMixin from "@/mixins/ai/gsapMixin";
import EmailSections from "@/components/Admin/EmailSections.vue";
import LandingPageLoader from "@/components/Admin/LandingPageLoader.vue";
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import json_helpers from "@/mixins/ai/json_helpers";
import { mockCampaign, mockThinking } from "@/mixins/StyleGuideMixins/mockCampaign.js";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

gsap.registerPlugin(ScrollTrigger);
const { timeline, set, to, from } = gsap;
const { validateJSON } = json_helpers;

// gsap.registerPlugin(SplitText);

export default {
    name: "HomePageEmailPreviewScroll",
    components: { CampaignHeader, LandingPageLoader, EmailSections },
    mixins: [styleGuideMixin, gsapMixin],
    data() {
        return {
            text: "",
            mockCampaign: mockThinking,
            campaignItems: mockThinking,
            mock: mockCampaign,
            headerSize: 200,
            loaded: false,
            colorProg: 1,
            currentStream: "",
            currentIndex: 0,
            speed: 15,
            chunkSize: 3, // Adjust the chunk size as needed
        };
    },
    computed: {
        sections() {
            return this.text?.sections;
        },
        emailScale() {
            if (this.mobile) {
                return `transform: scale(0.8)!important;`;
            } else {
                return `transform: scale(1)!important;`;
            }
        },
    },
    created() {
        this.fetchStyleGuide();
        this.loaded = true;
    },
    updated() {
        // this.$forceUpdate();
    },
    async mounted() {
        if (this.mobile) this.headerSize = 100;
        this.campaignItems = validateJSON(this.campaignItems);
        this.$nextTick(() => {
            this.populateThinking();
        });
    },
    methods: {
        populateThinking() {
            this.$nextTick(() => {
                const ref = this.$refs["thinkingText"];
                const fullText = this.mock; // The full text to display gradually
                const container = this.$refs.container;
                const emailContainer = this.$refs.EmailContainer;
                // const words = fullText.match(/.{1,3}/g) || [];
                const words = fullText.split(" ") || [];
                const length = 5000;
                let currentChunk = "";

                set(ref, { text: "" });

                // TIMELINE 1
                to(ref, {
                    scrollTrigger: {
                        trigger: container,
                        start: "top top",
                        end: `+=${length}`,
                        scrub: true,
                        pin: emailContainer,
                        pinSpacing: true,
                        onUpdate: scrollTrigger => {
                            const progress = scrollTrigger.progress;
                            const chunksToShow = Math.floor(words.length * progress);
                            currentChunk = words.slice(0, chunksToShow).join(" ");
                            // curre ntChunk = words.slice(0, chunksToShow).join("");
                            this.text = validateJSON(currentChunk) || this.text;
                        },
                    },
                });
            });
        },
        async fetchStyleGuide(id = "74QgERD8e7N8o10B6g3T") {
            this.loading = true;
            if (id) {
                let item = await this.fetchFromFirebase("brands", id);
                await this.setStyleGuide(item);
                await this.getBrandImages(id);
                this.updateStyleGuideProp("fetching", false);
                this.loading = false;
            } else this.loading = false;
        },
        mountJSONObject() {
            const topLevelOrder = ["name", "relevant_image_indexes", "thinking", "subject_line", "preview_text", "sections"];
            const thinkingOrder = ["thoughts", "reasoning", "plan", "criticism", "speak"];
            const sectionOrder = ["type", "planning", "imageIndex", "insetImage", "backgroundColor", "color", "imageStyle", "header", "sub_header", "body", "button", "style", "complete", "accordion", "icon", "features"];
            this.$nextTick(() => {
                if (this.mock) {
                    let parsedObject = JSON.parse(this.mock);
                    parsedObject = this.reorderObjectKeys(parsedObject, topLevelOrder);
                    if (parsedObject.thinking) parsedObject.thinking = this.reorderObjectKeys(parsedObject.thinking, thinkingOrder);
                    if (parsedObject.sections) parsedObject.sections = parsedObject.sections.map(section => this.reorderObjectKeys(section, sectionOrder));
                    this.mock = JSON.stringify(parsedObject, null, 2);
                }
            });
        },
        reorderObjectKeys(obj, keyOrder) {
            const ordered = {};
            // Add keys in the specified order
            keyOrder.forEach(key => {
                if (obj.hasOwnProperty(key)) ordered[key] = obj[key];
            });
            // Add remaining keys not specified in the order
            for (const key in obj) {
                if (obj.hasOwnProperty(key) && !keyOrder.includes(key)) ordered[key] = obj[key];
            }
            return ordered;
        },
        async setStyleGuide(styleGuide) {
            // await this.removeStyles();
            // if (styleGuide?.fonts?.length > 0) styleGuide = this.cdnLinkFixer(styleGuide);
            // this.$store.dispatch("styleGuide/setStyleGuide", styleGuide);
            // this.$nextTick(() => this.applyStyles());
        },
    },
    beforeUnmount() {},
};
</script>
<style lang="scss">
.cursor-blink {
    width: 2px;
    height: 1rem;
    background: black;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>
