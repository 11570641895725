<template>
    <div class="message-buttons">
        <button @click.prevent="$emit('removeMessage')">
            <i class="far fa-trash dark:text-base-500"></i>
        </button>
        <button @click.prevent="$emit('editMessage')">
            <i class="far fa-edit dark:text-base-500"></i>
        </button>
        <like-button icon="star" btn-class="hover:opacity-100 bg-transparent" v-if="message && message.id && user && user.uid" collection="chats" :parent="$route.params.id" :obj-id="message.id" :user-id="user.uid"></like-button>
    </div>
</template>
<script>
import LikeButton from "@/components/CoreUI/LikeButton.vue";

export default {
    name: "MessageEditButtons",
    components: { LikeButton },
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style lang="scss">
.message-buttons {
    //transform:translateX(100%);
    // tailwind class translate x 100%
    //@apply translate-x-3/4
    //@apply transform;
    @apply translate-x-full;
    //f lg:fc items-center lg:item-top jcs gap-0 pt-0 f-20
    @apply transition;
    @apply flex items-end;
    //@apply lg:absolute;
    @apply lg:items-end justify-end lg:justify-center gap-0;
    //@apply lg:flex-col;
    @apply top-0;
    //@apply -right-10;
    @apply -right-20;
    //@apply -right-full;
    //@apply absolute;
    button {
        outline: none;
        border: none;
    }
    button {
        @apply p-2 opacity-30 dark:opacity-50 hover:opacity-80 bg-transparent;
    }
    textarea {
        overflow: hidden;
        resize: none;
        border: none;
        padding: 10px;
    }
}
</style>
