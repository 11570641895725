<template>
    <div class="fixed bottom left right bg-snow border-top shadow">
        <!--        <template v-if="parentCampaign">{{ parentCampaign.name }}</template>-->
        <collapsable-menu-item class="container">
            <template #title>
                <div class="x px-3 py-2 f aic jcs gap-4">
                    <ParentCampaign class="mw-500" :parent="parentCampaign" />
                    <span class="fwb">{{ name }}</span>
                    <BaseBadge v-if="sections.length" class="f-11" :text="`${sections.length} sections`"></BaseBadge>
                </div>
            </template>
            <div v-if="showHeaderParent && !inline" class="menu_section">
                <a v-if="parentCampaign || result?.campaignId" :href="parentCampaign?.id || result.campaignId" is="div" class="block mt-5 p-2 border-light br-10 mb-3 text-gray-900 hover:text-gray-700 bg-gray-50 mt-3 hover:shadow hover:bg-snow cursor-pointer">
                    <p class="line-height-smaller fwb" v-if="parentCampaign?.name">{{ parentCampaign.name }}</p>
                    <p class="line-height-smaller fwb" v-else-if="result?.campaignId">{{ result.campaignId }}</p>
                    <h6 class="fwn o-5 mt-2 f-11">Parent Campaign</h6>
                </a>
            </div>
            <div v-if="name && !isEmail" class="menu_section">
                <h6>Name</h6>
                <p>{{ name }}</p>
            </div>
            <div v-if="description" class="menu_section">
                <h6>Description</h6>
                <p>{{ description }}</p>
            </div>
            <div v-if="createdAt && !inline" class="menu_section">
                <h6>Created</h6>
                <p>{{ createdAt }}</p>
            </div>
            <div v-if="strategy" class="menu_section">
                <h6 class="fwb">Strategy</h6>
                <p>{{ strategy }}</p>
            </div>
            <div v-if="isEmail && inline && subject" class="menu_section f jcb aic bg-snow">
                <div v-if="subject && preview">
                    <p class="fwb f-20" v-if="brandName">{{ brandName }}</p>
                    <p class="fwb f-15 line-clamp-1">{{ subject }}</p>
                    <p class="f-15 o-5 line-clamp-1">{{ preview }}</p>
                </div>
                <!--            <span class="fwb f-11">{{ new Date().toLocaleDateString("en-US") }}</span>-->
                <!--          do the time-->
                <span class="fwb f-11 whitespace-nowrap">{{ new Date().toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric" }) }}</span>
            </div>
            <template v-if="isEmail && !inline && subject">
                <div v-if="subject" class="menu_section">
                    <h6>Subject Line</h6>
                    <p>{{ subject }}</p>
                </div>

                <div v-if="preview" class="menu_section">
                    <h6>Preview Text</h6>
                    <p>{{ preview }}</p>
                </div>
            </template>
            <transition name="fade-up"><SideBarSections class="x" v-if="sections?.length > 0 && !inline" :sections="sections" /></transition>
            <transition name="fade-up"><SideBarThoughts class="x" v-if="thinking && !sections" :thinking="thinking" /></transition>
            <template v-if="!(inline && sections)">
                <ArrayToBulletPoints v-if="thoughts" class="menu_section" title="Thoughts" :array="thoughts" />
                <div v-if="reasoningIsString" class="menu_section">
                    <h6>Reasoning</h6>
                    <ul class="pl-3 f-17 mt-3">
                        <li v-if="reasoningIsString">{{ reasoning }}</li>
                    </ul>
                </div>
                <ArrayToBulletPoints v-else-if="reasoning" class="menu_section" title="Reasoning" :array="reasoning" />
                <div v-if="planningIsString" class="menu_section">
                    <h6>Here's the plan</h6>
                    <ul class="pl-3 f-17 mt-3">
                        <li v-if="planningIsString">{{ planning }}</li>
                    </ul>
                </div>
                <ArrayToBulletPoints v-else-if="planning" class="menu_section" title="Here's the plan" :array="planning" />
                <ArrayToBulletPoints v-if="criticism" class="menu_section" title="Guidance" :array="criticism" />
            </template>
            <ItemCounts :item-counts="itemCounts" />
        </collapsable-menu-item>
    </div>
</template>
<script>
import ItemCounts from "@/components/generative_templates/Campaigns/ItemCounts.vue";
import { mapGetters } from "vuex";
import SideBarSections from "@/components/generative_templates/Campaigns/SideBarSections.vue";
import SideBarThoughts from "@/components/generative_templates/Campaigns/SideBarThoughts.vue";
import webRequest from "@/mixins/ai/web_request";
import ArrayToBulletPoints from "@/components/generative_templates/Campaigns/ArrayToBulletPoints.vue";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import ParentCampaign from "@/components/Admin/ParentCampaign.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";

export default {
    name: "CampaignFooter",
    components: { BaseBadge, CollapsableMenuItem, ParentCampaign, ArrayToBulletPoints, SideBarThoughts, SideBarSections, ItemCounts },
    props: {
        result: {
            type: Object,
            required: true,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "",
        },
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            prntId: "",
            parentCampaign: {},
        };
    },
    async mounted() {
        await this.fetchParent();
    },
    methods: {
        async fetchParent() {
            // setTimeout(() => {
            this.$nextTick(async () => {
                console.log("Fetching from CampaignHeader");
                if (this.campaignId) this.parentCampaign = await fetchData("campaigns", this.campaignId);
            });
            // }, 100);
        },
    },
    computed: {
        ...mapGetters("styleGuide", ["images"]),
        ...mapGetters("stream", ["campaignData", "showCampaign", "campaigns"]),
        showHeaderParent() {
            return this.parentCampaign || this.result?.campaignId;
        },
        brandName() {
            return this.$store.state?.styleGuide?.styleGuide?.name;
        },
        campaignId() {
            return this.result?.campaignId || this.data?.campaignId;
        },
        description() {
            return this.result?.description;
        },
        sections() {
            return this.result?.sections || this.result?.result?.sections;
        },
        thinking() {
            if (this.thoughts || this.thinking) {
                return {
                    thoughts: this.thoughts || [],
                    reasoning: this.reasoning || [],
                    criticism: this.criticism || [],
                    planning: this.planning || [],
                    speak: this.speak || "",
                };
            }
            // if (this.result?.thinking || this.result?.thinking?.thoughts || this.result?.result?.thinking) {
            //     return this.result?.thinking || this.result?.thinking?.thoughts || this.result?.result?.thinking;
            // } else
            // }
        },
        completed() {
            return this.result?.complete || this.result?.completed;
        },
        // parentCampaign() {
        //     if (this.campaigns?.length > 0) {
        //         this.campaigns?.find(i => i.id === this.campaignId);
        //     } else if (this.campaignId) return this.prntId;
        // },
        objectTypePlainText() {
            return this.objectType?.replace(/_/g, " ");
        },
        createdAt() {
            if (this.result?.created) {
                return this.$filters.timeAgo(this.toDate(this.result.created));
            }
        },
        itemCounts() {
            return this.result?.item_counts;
        },
        planningIsString() {
            return typeof this.planning === "string";
        },
        reasoningIsString() {
            return typeof this.reasoning === "string";
        },

        objectType() {
            return this.result?.object_type || this.result?.result?.object_type;
        },
        isEmail() {
            if (this.type && this.type === "email") return true;
            else return this.objectType === "email";
        },
        isLandingPage() {
            return this.objectType === "landing_page";
        },
        isCampaign() {
            return this.objectType === "campaign";
        },
        reasoning() {
            return this.result?.reasoning || this.result?.thinking?.reasoning || this.result?.thinking?.criticism;
        },
        preview() {
            return this.result?.preview_text || this.result?.result?.preview_text;
        },
        subject() {
            return this.result?.subject_line || this.result?.result?.subject_line;
        },
        thoughts() {
            return this.result?.thinking?.thoughts || this.result?.thoughts;
        },
        criticism() {
            return this.result?.thinking?.criticism || this, this.result?.criticism;
        },
        planning() {
            return this.result?.planning || this.result?.plan || this.result?.thinking?.plan;
        },
        speak() {
            return this.result?.thinking?.speak || this.result?.result?.speak;
        },
        strategy() {
            return this.result?.strategy;
        },
        name() {
            return this.result?.name || this.result?.result?.name;
        },
    },
};
</script>
<style lang="scss" scoped>
h6 {
    @apply mb-0 font-bold;
}
.menu_section {
    border-style: solid;
    @apply py-3 border-b border-l-0 border-r-0 border-t-0 border-b-gray-900/10 p-3;
    p {
        @apply mb-0;
    }
}
</style>
