<template>
    <div class="f gap-4 aic animated fadeInUpSmooth">
        <!--        <template v-if="parentCategory">{{ parentCategory }}</template>-->
        <div v-for="(category, index) in categoriesToShow" :key="category.id">
            <RouterLink v-if="category.name" active-class="!bg-black !text-snow" :to="`/categories/${category.name}`" class="fwb flex-shrink-0 f bg-snow aic jcc gap-3 border-light px-3 py-2 br-50 f-15 text-gray-900" @click="toggleSubcategories(category)">
                <i v-if="category.icon" :class="`fa far ${category.icon}`"></i>
                <span class="text-uppercase whitespace-nowrap">{{ category.name }}</span>
            </RouterLink>
            <div v-if="!inline && showSubcategories && category?.sub_categories && category?.showSubcategories && $route.params?.cat?.includes(category.name) && category?.sub_categories?.length > 0" class="x fwb flex-shrink-0 f fc bg-snow aib jcb mt-3 gap-3 px-3 py-2">
                <h6 class="mb-0" v-if="category?.sub_categories?.length > 0">Related</h6>
                <RouterLink v-for="subCategory in category.sub_categories" :to="`/categories/${subCategory.name}`" :key="subCategory.id" class="text-gray-900 f aic gap-3" active-class="text-blue-900 ">
                    <i v-if="category.icon" :class="`fa far fa-xs o-5 ${subCategory.icon}`"></i>
                    {{ subCategory.name }}
                </RouterLink>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CategoryList",
    props: {
        categories: {
            type: Array,
            required: true,
        },
        showSubcategories: {
            type: Boolean,
            default: true,
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localCategories: [],
            parentCategory: null,
        };
    },
    // watch the route for changes
    watch: {
        $route: {
            handler: function () {
                this.$nextTick(() => {
                    this.initializeCategories();
                });
            },
            immediate: true,
        },
        categories: {
            handler: function () {
                this.$nextTick(() => {
                    this.initializeCategories();
                });
            },
            immediate: true,
        },
    },
    computed: {
        allCategories() {
            let categories = this.$store.state.styleGuide.categories;
            return categories;
        },
        categoriesToShow() {
            return this.localCategories.map(category => ({
                ...category,
                sub_categories: this.getSubcategories(category),
                showSubcategories: true,
            }));
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.initializeCategories();
        });
    },
    methods: {
        getParentCategory() {
            if (this.$route.params.cat) {
                let category = this.categories.filter(c => c.name === this.$route.params.cat);
                if (category.length === 0) return;
                category = category[0];
                if (category.parent_id) {
                    let pc = this.allCategories.filter(cat => +cat.id === +category.parent_id);
                    console.log("Parent Category", pc);
                    this.parentCategory = pc;
                    // get the subCategories of the parent category
                    if (this.parentCategory) {
                        this.parentCategory.sub_categories = this.categories.filter(cat => +cat.parent_id === +this.parentCategory.id);
                    }
                }
            }
        },
        initializeCategories() {
            this.localCategories = this.categories.map(category => ({
                ...category,
                sub_categories: this.getSubcategories(category),
                showSubcategories: false,
            }));
            this.getParentCategory();
        },
        getSubcategories(category) {
            return this.allCategories.filter(cat => cat.parent_id === +category.id);
        },
        isParentCategory(category) {
            return this.allCategories.some(cat => cat.parent_id === +category.id);
        },
        toggleSubcategories(category) {
            if (!this.showSubcategories || !category.isParent) return;
            this.localCategories = this.localCategories.map(cat => ({
                ...cat,
                showSubcategories: cat.id === +category.id ? !cat.showSubcategories : cat.showSubcategories,
            }));
        },
    },
};
</script>
