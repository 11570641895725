<template>
    <div>
        <i :class="iconClass" />
    </div>
</template>
<script>
export default {
    name: "CampaignIcon",
    props: {
        kind: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "20",
        },
    },
    computed: {
        iconClass() {
            let kind = this.kind || "";
            kind = kind.toLowerCase();
            switch (kind) {
                case "email":
                    return "fal fa-envelope";
                case "campaign":
                    return "fal fa-bullhorn";
                case "sms":
                    return "fal fa-comment";
                case "website":
                    return "fal fa-globe";
                case "page":
                    return "fal fa-globe";
                default:
                    return "fal fa-envelope";
            }
        },
    },
};
</script>
