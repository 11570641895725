import { db } from "@/firebase";

const state = {
    voices: [],
    selectedVoice: null,
    audioPlayer: null,
    isPlaying: false,
};

const mutations = {
    setVoices(state, voices) {
        state.voices = voices;
    },
    setSelectedVoice(state, voice) {
        state.selectedVoice = voice;
    },
    setAudioPlayer(state, player) {
        state.audioPlayer = player;
    },
    setIsPlaying(state, isPlaying) {
        state.isPlaying = isPlaying;
    },
    setAudioSrc(state, { url, onCanPlaythrough }) {
        state.audioPlayer.src = url;
        state.audioPlayer.oncanplaythrough = onCanPlaythrough;
    },
};

const actions = {
    async getVoices({ commit }) {
        // console.groupCollapsed("getVoices");
        try {
            const XI_API_KEY = "cbea36652613e976ba6cd525b9bb6f19";
            const url = "https://api.elevenlabs.io/v1/voices";

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "xi-api-key": XI_API_KEY,
                },
            };

            const response = await fetch(url, {
                method: "GET",
                headers: config.headers,
            });

            if (!response.ok) {
                throw new Error(`Error fetching voices: ${response.status}`);
            }
            // console.groupEnd();
            const data = await response.json();
            // console.groupCollapsed("getVoices");
            // console.log(data);
            // console.groupEnd();
            commit("setVoices", data.voices);
            return data.voices;
        } catch (error) {
            console.error("Error fetching voices:", error);
            // console.groupEnd();
            return [];
        }
    },
    async textToSpeech({ commit }, { text, voiceId, stability, similarityBoost }) {
        console.group("TextToSpeech");
        try {
            const XI_API_KEY = "cbea36652613e976ba6cd525b9bb6f19";
            // const XI_API_KEY = import.meta.env.VITE_ELEVEN_API;
            console.log("Using XI API Key:", XI_API_KEY);
            const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`;

            const requestBody = {
                text,
                voice_settings: {
                    stability,
                    similarity_boost: similarityBoost,
                },
            };

            console.log("Request Body:", JSON.stringify(requestBody)); // Debugging

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "xi-api-key": XI_API_KEY,
                },
            };

            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: config.headers,
            });

            if (!response.ok) {
                console.log("Error Response Body:", await response.text()); // Debugging

                throw new Error(`Error converting text to speech: ${response.status}`);
            }

            const data = await response.blob();
            console.log("Text-to-speech response:", data);

            return URL.createObjectURL(new Blob([data], { type: "audio/mpeg" }));
        } catch (error) {
            console.error("Error converting text to speech:", error);
            return null;
        }
        console.groupEnd("getVoices");
    },
    async convertTextToSpeech({ commit, dispatch, state }, { text, voiceId, stability = 0.35, similarityBoost = 0.7 }) {
        let defaultVoiceId = "21m00Tcm4TlvDq8ikWAM";
        let actualVoiceId = voiceId || (state.selectedVoice && state.selectedVoice.voice_id) || defaultVoiceId;

        console.log(`Text to convert: ${text}`);
        console.log(`Actual Voice ID: ${actualVoiceId}`);

        const audioURL = await dispatch("textToSpeech", { text, voiceId: actualVoiceId, stability, similarityBoost });

        if (audioURL) {
            const audioPlayer = new Audio(audioURL);

            // Store the audio player in the state, in case it's not already there
            if (!state.audioPlayer) {
                commit("setAudioPlayer", audioPlayer);
            }

            // Play and manage state
            audioPlayer.play();
            commit("setIsPlaying", true);
            audioPlayer.addEventListener("ended", () => {
                commit("setIsPlaying", false);
            });
            audioPlayer.addEventListener("error", () => {
                commit("setIsPlaying", false);
            });
        }
    },
    playVoice({ commit, state }, voice) {
        console.error(voice);
        let preview_url = voice.preview_url;
        const audioPlayer = new Audio(preview_url);
        audioPlayer.play();
        commit("setAudioPlayer", audioPlayer);
        commit("setIsPlaying", true);

        audioPlayer.onended = () => {
            commit("setIsPlaying", false);
        };
    },
    playSelectedVoice({ commit, state }) {
        const voice = state.selectedVoice;

        if (voice && !state.isPlaying) {
            const preview_url = voice.preview_url;
            const audioPlayer = state.audioPlayer || new Audio(preview_url);

            // Store the audio player in the state, in case it's not already there
            if (!state.audioPlayer) {
                commit("setAudioPlayer", audioPlayer);
            }

            // Play and manage state
            audioPlayer.play();
            commit("setIsPlaying", true);
            audioPlayer.addEventListener("ended", () => {
                commit("setIsPlaying", false);
            });
        }
    },
    pauseAudioPlayer({ commit, state }) {
        if (state.audioPlayer && state.isPlaying) {
            state.audioPlayer.pause();
            commit("setIsPlaying", false);
        }
    },
    async pauseVoice({ commit, state }) {
        if (state.audioPlayer) {
            state.audioPlayer.pause();
        }
    },
    updateAudioSrc({ commit }, payload) {
        commit("setAudioSrc", payload);
    },
};
const getters = {
    voices: state => state.voices,
    selectedVoice: state => state.selectedVoice,
    audioPlayer: state => state.audioPlayer,
    isPlaying: state => state.isPlaying,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
