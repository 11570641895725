<template>
    <div class="mb-3 pr-3" v-if="searchResults && searchResults.length > 0">
        <h5 class="animated swingInUp duration-5" v-if="showResults">From the web</h5>
        <div class="o-2" v-if="showResults">
            <hr class="o-2 animated swingInUp duration-5 d-2 my-3 border-gray-300" />
        </div>
        <p class="fwb" v-else><i class="fas fa-bolt mr-2 small text-green "></i>Sources</p>
        <div :class="{ 'f flex-wrap gap-1': !showResults }">
            <SearchResult :simple="!showResults" class="animated swingInUp duration-5" :class="`d-${index * 3}`" v-for="(result, index) in searchResults" :result="result" />
        </div>
    </div>
</template>
<script>
import SearchResult from "@/components/chat/specialMessages/Search/SearchResult.vue";

export default {
    name: "WebResults",
    components: { SearchResult },
    props: {
        showResults: {
            type: Boolean,
            required: true,
            default: true,
        },
        searchResults: {
            type: Array,
            required: true,
        },
    },
};
</script>
<style lang="scss">
hr{
  @apply my-5;
  // style the hr
  border-top: 1px solid;
  @apply border-t border-gray-100;
  @apply dark:border-base-500;
}
</style>
