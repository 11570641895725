<template>
    <div v-if="isMounted" class="">
        <template v-for="(value, key) in sortedObjectData" :key="key">
            <div v-if="isObject(value)">
                <UICollapsableSection :depth="depth" class="border-b border-black" left small>
                    <template #title>
                        <div class="text-sm x">
                            <span :class="titleColor">{{ toTitleCase(key) }}</span>
                            <span class="visually-hidden">{{ titleColor }}</span>
                        </div>
                    </template>
                    <ObjectViewer :data="value" :depth="depth + 1" />
                </UICollapsableSection>
            </div>
            <div v-else-if="isArray(value) && !isArrayOfNumbers(value) && !isArrayOfStrings(value)">
                <UICollapsableSection v-if="value" :count="value.length" :depth="depth" class="border-b border-black" left small>
                    <template #title>
                        <div class="text-sm x">
                            <span :class="titleColor">{{ toTitleCase(key) }}</span>
                            <span class="visually-hidden">{{ titleColor }}</span>
                        </div>
                    </template>
                    <div v-if="isArrayOfStrings(value)">
                        <div v-for="(item, index) in value" :key="index">
                            <p>{{ item }}</p>
                        </div>
                    </div>
                    <template v-else-if="key === 'messages'">
                        <MessageViewer :chat="value" />
                    </template>
                    <template v-else-if="key === 'sections'">
                        <UICollapsableSection v-for="(item, i) in value" :count="items?.length" :depth="depth + 1" class="border-b border-black" left small>
                            <template #title>
                                <div class="text-sm x">
                                    <span :class="titleColor">Section {{ i + 1 }}</span>
                                    <span class="visually-hidden">{{ titleColor }}</span>
                                </div>
                            </template>
                            <ObjectViewer :key="index" :data="item" :depth="depth + 1" />
                        </UICollapsableSection>
                    </template>
                    <template v-else>
                        <ObjectViewer v-for="(item, index) in value" :key="index" :data="item" :depth="depth + 1" />
                    </template>
                </UICollapsableSection>
            </div>
            <div v-else-if="typeof value === 'time'"></div>
            <div v-else-if="isString(value)">
                <template v-if="value.length < 20">
                    <div class="flex items-center border-bottom">
                        <TableTitle :depth="depth" :title="toTitleCase(key)" />
                        <dd>{{ formatValue(value) }}</dd>
                    </div>
                </template>
                <template v-else>
                    <div class="flex items-stretch border-bottom">
                        <TableTitle :depth="depth" :title="toTitleCase(key)" />
                        <dd>
                            <div class="w-90 mb-0">
                                <p :class="{ 'whitespace-pre-line': getTruncatedContent(value) }">{{ getTruncatedContent(value) }}</p>
                                <a v-if="shouldTruncate(value)" class="text-black-900 fwb hover:underline inline" @click="toggleTruncation(value)">{{ isTruncated(value) ? "Show more" : "Show less" }}</a>
                            </div>
                        </dd>
                    </div>
                </template>
            </div>
            <div v-else-if="isArrayOfNumbers(value)">
                <div class="flex items-center border-bottom">
                    <TableTitle :depth="depth" :title="toTitleCase(key)" />
                    <dd>{{ value.join(", ") }}</dd>
                </div>
            </div>
            <div v-else-if="isArrayOfStrings(value)">
                <div class="flex items-center border-bottom align-items-stretch">
                    <TableTitle :depth="depth" :title="toTitleCase(key)" />
                    <dd>
                        <p v-for="(item, index) in value" :key="index">{{ item }}</p>
                    </dd>
                </div>
            </div>
            <div v-else class="flex items-center border-bottom f">
                <TableTitle :depth="depth" :title="toTitleCase(key)" />
                <dd>{{ formatValue(value) }}</dd>
            </div>
        </template>
    </div>
</template>

<script>
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";
import MessageViewer from "@/components/Admin/MessageViewer.vue";
import TableTitle from "@/components/Admin/TableTitle.vue";
import Markdown from "@/components/chat/specialMessages/Markdown.vue";

export default {
    name: "ObjectViewer",
    components: {
        Markdown,
        ObjectViewer: () => import("@/components/Admin/ObjectViewer.vue"),
        TableTitle,
        MessageViewer: () => import("@/components/Admin/MessageViewer.vue"),
        UICollapsableSection,
    },
    props: {
        data: { type: Object, default: () => ({}) },
        depth: { type: Number, default: 0 },
        arraysFirst: { type: Boolean, default: false },
    },
    data() {
        return {
            objectData: {},
            sortedObjectData: {},
            isMounted: false,
            truncatedItems: {},
        };
    },
    computed: {
        titleColor() {
            const baseClasses = "border-left border-b";
            const colors = ["gray", "blue", "green", "amber", "red", "indigo", "pink"]; // Index corresponds to depth
            if (this.depth === 0) return `color-gray-900 fwb`;
            else {
                let depth = this.depth + 1;
                const color = colors[depth];
                const textClass = `fwb text-${color === "yellow" ? "amber" : color}-500`;
                return `${textClass}`;
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.objectData = this.data;
            this.sortedObjectData = this.sortObject(this.objectData);
            this.initializeTruncatedItems();
        }, 500);
        this.isMounted = true;
    },
    methods: {
        sortObject(obj) {
            const sorted = {};
            const keys = Object.keys(obj);

            keys.sort((a, b) => {
                const valueA = obj[a];
                const valueB = obj[b];
                if (this.isObject(valueA) && !this.isObject(valueB)) return this.arraysFirst ? -1 : 1;
                if (!this.isObject(valueA) && this.isObject(valueB)) return this.arraysFirst ? 1 : -1;

                if (Array.isArray(valueA) && Array.isArray(valueB)) {
                    if (this.isArrayOfNumbers(valueA) && !this.isArrayOfNumbers(valueB)) return -1;
                    if (!this.isArrayOfNumbers(valueA) && this.isArrayOfNumbers(valueB)) return 1;
                    if (this.isArrayOfStrings(valueA) && !this.isArrayOfStrings(valueB)) return -1;
                    if (!this.isArrayOfStrings(valueA) && this.isArrayOfStrings(valueB)) return 1;
                }
                if (!Array.isArray(valueA) && Array.isArray(valueB)) {
                    if (this.isArrayOfNumbers(valueB)) return this.arraysFirst ? 1 : -1;
                    if (this.isArrayOfStrings(valueB)) return this.arraysFirst ? 1 : -1;
                    return this.arraysFirst ? 1 : -1;
                }
                if (Array.isArray(valueA) && !Array.isArray(valueB)) {
                    if (this.isArrayOfNumbers(valueA)) return this.arraysFirst ? -1 : 1;
                    if (this.isArrayOfStrings(valueA)) return this.arraysFirst ? -1 : 1;
                    return this.arraysFirst ? -1 : 1;
                }
                if (typeof valueA === "string" && typeof valueB !== "string") return -1;
                if (typeof valueA !== "string" && typeof valueB === "string") return 1;
                if (typeof valueA === "number" && typeof valueB !== "number") return -1;
                if (typeof valueA !== "number" && typeof valueB === "number") return 1;
                if (typeof valueA === "boolean" && typeof valueB !== "boolean") return -1;
                if (typeof valueA !== "boolean" && typeof valueB === "boolean") return 1;

                return 0;
            });

            keys.forEach(key => {
                const value = obj[key];
                if (this.isObject(value)) {
                    sorted[key] = this.sortObject(value);
                } else {
                    sorted[key] = value;
                }
            });

            return sorted;
        },
        initializeTruncatedItems() {
            const traverse = obj => {
                if (typeof obj !== "object" || obj === null) return;
                Object.entries(obj).forEach(([key, value]) => {
                    if (typeof value === "string" && value.length > 100) this.truncatedItems[value] = true;
                    else if (typeof value === "object" && value !== null) traverse(value);
                });
            };

            traverse(this.objectData);
        },
        getTruncatedContent(content) {
            if (this.isTruncated(content)) return content.slice(0, 100) + "...";
            return content;
        },
        isObject(value) {
            if (!value) return value;
            return typeof value === "object" && value !== null && !Array.isArray(value);
        },
        isArray(value) {
            if (!value) return value;
            return Array.isArray(value);
        },
        isString(value) {
            if (!value) return value;
            return typeof value === "string";
        },
        isArrayOfStrings(value) {
            return Array.isArray(value) && value.every(item => typeof item === "string");
        },
        isArrayOfNumbers(value) {
            return Array.isArray(value) && value.every(item => typeof item === "number");
        },
        toTitleCase(str) {
            if (!str || typeof str !== "string") return str;
            let spaceBeforeCapitals = str.replace(/([A-Z])/g, " $1").trim();
            let fixPascalAndCamel = spaceBeforeCapitals
                .split(/[-_ ]+/) // Split on hyphen, underscore, or space
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Convert to Title Case
                .join(" ");

            return fixPascalAndCamel;
        },
        // <!--              || typeof value === 'number' || typeof value === 'boolean'-->

        formatValue(value) {
            if (!value) return value;
            if (typeof value === "string" && value.startsWith("http")) {
                const url = new URL(value);
                return `${url.origin}...`;
            }
            return value;
        },
        shouldTruncate(content) {
            return typeof content === "string" && content.length > 150;
        },
        isTruncated(content) {
            return this.truncatedItems[content] || false;
        },
        toggleTruncation(content) {
            this.truncatedItems[content] = !this.truncatedItems[content];
        },
    },
};
</script>
<style lang="scss">
dd {
    @apply text-gray-900 text-wrap text-sm px-2 py-2 w-full;
}
</style>
