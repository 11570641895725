<template>
    <div class="mt-32 sm:mt-56">
        <div class="mx-auto max-w-7xl px-3 lg:px-3">
            <div class="mx-auto max-w-2xl sm:text-center">
                <h2 class="text-base font-semibold leading-7 text-indigo-400" v-if="title">{{ title }}</h2>
                <p class="mt-3 font-bold tracking-tight text-white text-6xl" v-if="subheader">{{ subheader }}</p>
                <p class="mt-3 text-lg leading-8 text-gray-300" v-if="description">{{ description }}</p>
            </div>
        </div>
        <div class="relative overflow-hidden pt-4">
            <div class="mx-auto max-w-7xl px-3 lg:px-4">
                <img :src="imageURL" alt="App screenshot" class="mb-[-5%] rounded-xl shadow-2xl ring-1 ring-white/10" />
                <!--              width="2432" height="1442"-->
                <div class="relative" aria-hidden="true">
                    <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-base-900 pt-[7%]" />
                </div>
            </div>
        </div>
        <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
            <dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                <div v-for="feature in features" :key="feature.title" class="relative pl-9">
                    <dt class="inline font-semibold text-white">
                        <template v-if="typeof feature.icon === 'string'">
                            {{ feature.icon }}
                        </template>
                        <component v-else :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                        {{ feature.title }}
                    </dt>
                    {{ " " }}
                    <dd class="inline">{{ feature.description }}</dd>
                </div>
            </dl>
        </div>
    </div>
</template>
<script>
export default {
    name: "HomeContentSectionAlt",
    props: {
        features: {
            type: Array,
            default: () => [
                {
                    title: "Analytics",
                    description: "Get a better understanding of where your traffic is coming from.",
                    icon: "ChartBarIcon",
                },
                {
                    title: "Engagement",
                    description: "Speak directly to your customers in a more meaningful way.",
                    icon: "ChatAltIcon",
                },
                {
                    title: "Security",
                    description: "Your customers' data will be safe and secure.",
                    icon: "ShieldCheckIcon",
                },
            ],
        },
        imageURL: {
            type: String,
            default: "https://tailwindui.com/img/ecommerce-images/home-page-03-feature-section-01.jpg",
        },
        title: {
            type: String,
            default: "Everything you need",
        },
        description: {
            type: String,
            default: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis.",
        },
        subheader: {
            type: String,
            default: "No server? No problem.",
        },
    },
};
</script>
