<template>
    <div v-if="styleGuide" class="z-0 relative">
        <TemplateDynamicFunctionContent ref="generator" :context-messages="Context" :in-campaign="true" :image-array="images" class="x overflow-clip z-0 vh-100 p-fill" @reset="$store.dispatch('styleGuide/setStyleGuide', { url: styleGuide.url })" @update="updateStyleGuide(styleGuide.url)">
            <template #default="s">
                <div v-if="s">
                    <!--                    !$route.path.includes('campaigns') && -->
                    <template v-if="s?.action === 'create_email' || s?.action === 'create_email_new'">
                        <CampaignHeader :result="s.result" class="container-slim" />
                        <div v-if="s?.sections?.length" class="mw-500 mx-auto border-light relative">
                            <div v-for="(section, index) in s.sections" :key="'email' + index" class="relative"><NewEmailSection :identifier="`result.sections.${index}`" :index="index" :result="section" :sections="s.sections" id="result" /></div>
                            <SectionBlender :key="index" :height="300" :index="index" :section="section" :sections="s.sections" style="z-index: 0" />
                            <EmailFooterSection v-if="s?.sections?.length > 0 && s?.result?.email_footer" :footer="s.result.email_footer" />
                        </div>
                    </template>
                    <template v-else-if="$store.state.stream.stream.result.sections">
                        <LandingPageSection :identifier="`result.sections.${i}`" v-for="(section, i) in $store.state.stream.stream.result.sections" :key="`section${i}`" :images="images" :index="i" :result="section" class="" id="result" />
                    </template>
                    <template v-if="s && s.action && (s.action === 'create_campaign' || s.action === 'experimental_create_campaign')">
                        <transition name="fade-up">
                            <div class="container" v-if="s?.result?.schedule_items?.length > 0">
                                <CampaignHeader :result="s.result" class="container-slim" />
                                <div class="f fc jcc aic">
                                    <CampaignScheduleItem :schedule-items="s.result.schedule_items" :show-loader="s.showLoader" />
                                </div>
                            </div>
                            <div v-else-if="s?.result && s?.result?.name && !s?.result?.schedule_items">
                                <LandingPageLoader class="vh-90" hide-if-complete :style="dynamicGradient" :result="s.result" />
                            </div>
                        </transition>
                        <!--                        <div class="whitespace-pre-line f-15">{{ formatCampaignItem(s.result) }}</div>-->
                        <div>
                            <TeamDiscussions title="Team Discussions" class="p-3 bg-snow x mx-300 block" :key="`followup1`" v-if="s.result?.team_discussions" :discussion="s.result.team_discussions" />
                            <TeamDiscussions title="Follow-ups" class="p-3 bg-snow x mx-300 block" :key="`dicussion1`" v-if="s.result?.team_followups" :discussion="s.result.team_followups" />
                            <TeamDiscussions title="Executive Decisions" class="p-3 pt-3 bg-green-400 bg-snow x mx-300 block" :key="`exec1`" v-if="s.result?.executive_decisions" :discussion="s.result.executive_decisions" />
                        </div>
                    </template>
                    <GenerateIdeas @generate-from-suggestion="generateFromSuggestion" v-if="products?.length > 0 && !s?.result?.name" :products="products" />

                    <TestSlotComponent v-if="s?.result?.imageIndex" :images="images" :result="s.result" class="" />
                    <DynamicObjectViewer v-if="s.result && debugging" :data-object="s?.result" class="container-slim"></DynamicObjectViewer>
                    <div class="fixed bottom left right vh-10 z-10 bg-red" style="background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--base-bg) 30%)"></div>
                </div>
            </template>
        </TemplateDynamicFunctionContent>
        <div v-if="styleGuide && divStyles && doneMounting" class="grid grid-cols-12 overflow-hidden">
            <div v-if="styleGuide" class="x"><div class="block pb-8"></div></div>
        </div>
    </div>
</template>

<script>
import styleGuideBrandMixin from "@/mixins/StyleGuideMixins/StyleGuideBrandMixin";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import TemplateDynamicFunctionContent from "@/components/generative_templates/TemplateDynamicFunctionContent.vue";
import TestSlotComponent from "@/components/generative_templates/TestSlotComponent.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import CampaignScheduleItem from "@/components/generative_templates/Campaigns/CampaignScheduleItem.vue";
import NewEmailSection from "@/components/generative_templates/Email/NewEmailSection.vue";
import SectionBlender from "@/components/generative_templates/Pages/SectionBlender.vue";
import LandingPageSection from "@/components/generative_templates/LandingPageSection.vue";
import EmailFooterSection from "@/components/generative_templates/Email/EmailFooterSection.vue";
import { mapGetters } from "vuex";
import TeamDiscussions from "@/components/generative_templates/Campaigns/TeamDiscussions.vue";
import LandingPageLoader from "@/components/Admin/LandingPageLoader.vue";
import GenerateIdeas from "@/components/generative_templates/Pages/GenerateIdeas.vue";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import MiniFeed from "@/components/generative_templates/Pages/MiniFeed.vue";
// import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";
export default {
    components: {
        MiniFeed,
        GenerateIdeas,
        LandingPageLoader,
        // PresentationMarkdown,
        TeamDiscussions,
        EmailFooterSection,
        LandingPageSection,
        SectionBlender,
        NewEmailSection,
        CampaignScheduleItem,
        CampaignHeader,
        DynamicObjectViewer,
        TestSlotComponent,
        TemplateDynamicFunctionContent,
    },
    mixins: [StyleGuideMixin, styleGuideBrandMixin],
    data() {
        return {
            // styleGuide: {},
            newURL: "",
            doneMounting: false,
            debugging: false,
            guidelineTest: "",
            finishedCampaign: false,
        };
    },
    computed: {
        ...mapGetters("styleGuide", ["colors", "products"]),
        ...mapGetters("stream", ["messages", "functions", "selectedFunction", "messagesWithContext", "showInput", "generate", "result", "model"]),
        dynamicGradient() {
            if (this.sections?.length > 0) return;
            try {
                let colors = this.colors?.bg_colors_sat || [];
                if (!colors || colors?.length === 0) return {};

                // Define a saturation threshold (e.g., 0.3 on a scale from 0 to 1)
                const saturationThreshold = 0.05;

                // Filter out colors with low saturation
                let filteredColors = colors.filter(color => {
                    try {
                        let sat = chroma(color).hsl()[1];
                        let luma = chroma(color).hsl()[2];
                        return sat > saturationThreshold && luma < 0.9;
                    } catch (e) {
                        return false;
                    }
                });
                filteredColors = filteredColors.sort((a, b) => {
                    // sort by saturation
                    let satA = chroma(a).hsl()[0];
                    let satB = chroma(b).hsl()[0];
                    return satB - satA;
                });
                // Transform remaining colors into pastels
                let gradientColors = filteredColors.concat(filteredColors).map(color => {
                    let pastelColor = chroma(color);
                    pastelColor = pastelColor.hsl();
                    let { 0: hue, 1: sat, 2: lum } = pastelColor;
                    hue = parseInt(hue);
                    sat = parseInt(90) + "%";
                    pastelColor = `hsl(${hue}, ${sat}, ${95}%)`;
                    return pastelColor;
                });
                gradientColors = gradientColors.concat().join(", ");
                // gradientColors = gradientColors.concat(gradientColors).join(", ");
                let gradientCSS = `radial-gradient(circle, ${gradientColors})`;

                // Calculate the background size based on the number of filtered colors
                let backgroundSize = filteredColors.length * 100 * 3 + "%!important";

                return {
                    background: gradientCSS,
                    backgroundSize: backgroundSize,
                    animation: "slide 10s linear infinite",
                };
            } catch (e) {
                return false;
            }
        },
    },
    created() {},
    mounted() {
        this.updateStreamProp("result", {});
        // this.updateStreamProp("campaignData", {});
        // this.updateStreamProp("editor", {});
        // await this.$nextTick();
        // if (this.copyGuidelines) {
        //     this.guidelineTest = objectToMarkdown(this.copyGuidelines);
        // }
    },
    methods: {
        async callFunctionFromInput(input, overrides, embeddings) {
            if (overrides?.action) this.setAction(overrides.action);
            let g = this.expandPrompt(input);
            console.log(g);
            embeddings = await this.getEmbeddings(input);
            this.updateStreamProp("options.userInput", embeddings + "\n\n" + input);
            this.addMessage(userMessage(embeddings + "\n\n" + input));

            // return;
            let { name, type } = this.selectedAction; // get the name and type of the selected action. The prompt will be automatically added via the streamFunction(obj) method. // set the action to get_brand_voice. This sets the prompt and the function_call as well.
            this.setActionModel(name);
            let mods = {
                model: this.model || gpt3, // this is the model that will be used to generate this.
                // model: this.model || gpt316, // this is the model that will be used to generate this.
                // model: gpt316, // this is the model that will be used to generate this.
                messages: this.streamMessages, // this is the messages that will be used to generate this.
                temperature: 0.2, // this is the temperature that will be used to generate this.
                function_call: name, // this is the function_call name that will be called to generate this. It needs to match the fucntion being called either locally or on the server.
                functions: undefined, // this is the functions object that will be used to generate this. If blank it will use the server and name to route to the right function if it exists.
                length: 2500, // the length of the message to generate
                persistChat: true, // if true, it streams the result to the messages array in the stream store. This can be used to see the streaming results in chat, but it can also be used to save the results to the database, "chat" add revisions with context, etc.
                // identifier: `result`, // this helps route the stream to the right place and find it later. Good for complex objects and nested sections. For example: 'campaignData.result.schedule_items.${campaignIndex}.campaign_items.${itemIndex}.result'. It is also possible to set a custom identifier for the stream location.
                brand: this.$route.params.id, // set the brand id from the route params but this could be set from anywhere optionally.
                input: input + "\n\nDo not exceed more than 5 emails or pages", // this is the user input that will added to messages.
                type: type, // what type of campaign is this? Currently only used for campaigns.
                storeKey: "stream", // This is the key of the store to save to. For example $store.state.styleGuide
                prop: `result`, // the prop used in the store
                localHandle: `result`, //the prop used in the local component
                save: true, // if true, use the afterCompletion function to define how to save the results.
                returnTo: "result",
            };
            if (overrides) mods = { ...mods, ...overrides };
            await this.callFunction(mods);
        },
        whileProcessing() {},
        async afterCompletion(obj) {
            let { input, save, messages, prompt, function_call, model, system, silent, result, type } = obj;
            // this.afterCompletingGeneration(obj);
            // this.hideLoader();
            let finalCampaignSaved;
            if (save) finalCampaignSaved = await this.saveToCampaigns(obj);
            this.$router.push({ name: "campaign", params: { cid: finalCampaignSaved } });
        },
        async saveToCampaigns(obj, proceed = false) {
            let {
                //
                name,
                brand = this.$route.params.id,
                result,
                itemIndex,
                identifier,
                campaignId,
                campaignIndex,
                save,
                messages,
                prompt,
                function_call,
                model,
                type,
            } = obj;
            let updatedObject = {
                result: {
                    ...result,
                    object_type: type,
                    campaignId,
                    itemIndex,
                    campaignIndex,
                    messages,
                },
                brand,
                created: new Date(),
                updated: new Date(),
                name: name || "",
                campaignId,
                campaignIndex,
                object_type: type,
                identifier,
                itemIndex,
            };
            // remove null and undefined values
            // remove null and undefined values
            updatedObject = Object.fromEntries(Object.entries(updatedObject).filter(([key, value]) => value != null));
            // updatedObject.result = Object.fromEntries(Object.entries(updatedObject.result).filter(([key, value]) => value != null));
            // navigate to the campaign
            try {
                let { id } = await saveToFirebase("campaigns", updatedObject);
                console.log("Campaign saved with ID:", id);
                this.finishedCampaign = true;
                return id;
            } catch (error) {
                console.error("Error saving to Firebase:", error);
                // Handle the error appropriately
            }
        },

        async generateFromSuggestion(suggestion) {
            console.log("Emitted", suggestion);

            this.setActionCampaign();
            // this.$refs.generator.callDynamicFunction({ input: `${suggestion}` });
            await this.callFunctionFromInput(suggestion, undefined);
        },
    },
    beforeUnmount() {
        this.doneMounting = false;
    },
};
</script>
<!--                    <template v-if="isPresentation"><div v-if="s?.result?.sections"><SlideTemplate v-for="(section, index) in s?.result?.sections" :id="'slide' + index" :key="'slide' + index" :ref="`slide-${index}`" :document="s.result" :index="index" :section="section" :slide="section"></SlideTemplate></div></template>-->
