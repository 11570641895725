
<template>
<!--    <div class="component-sample">-->
<!--        <textarea v-model="responseStructure" placeholder="Paste responseStructure here"></textarea>-->
<!--        <textarea v-model="serverTokensString" placeholder="Paste serverTokens here"></textarea>-->
<!--        <template v-if="responseObject">{{ responseObject }}</template>-->
<!--        <button @click="simulateStream">Simulate Stream</button>-->
<!--        <div v-for="(value, key) in responseObject" :key="key">-->
<!--            <strong>{{ key }}:</strong>-->
<!--            {{ value }}-->
<!--        </div>-->
<!--    </div>-->
</template>

<script>
export default {
    name: "StreamObjectDebugger",
//     data() {
//         return {
//             responseStructure: '{"thoughts": "", "reasoning": "","plan": "","criticism": "","speak": ""}',
//             responseObject: {},
//             serverTokensString: "",
//             serverTokens: [],
//             cumulativeStr: "",
//             currentKey: "",
//             currentValue: "",
//             isKey: true,
//         };
//     },
//     methods: {
//         initializeResponseStructure() {
//             try {
//                 let structure = JSON.parse(this.responseStructure);
//                 this.responseObject = Object.assign({}, ...Object.keys(structure).map(key => ({ [key]: "" })));
//             } catch (err) {
//                 console.error("Error parsing responseStructure", err);
//             }
//         },
//         simulateStream() {
//             this.initializeResponseStructure();
//             this.serverTokens = eval(this.serverTokensString);
//             this.processArrayItem(this.serverTokens, 0);
//         },
//         processArrayItem(array, index) {
//             if (index >= array.length) {
//                 return;
//             }
//
//             let item = `${array[index]}`;
//
//             if (item === '":') {
//                 this.isKey = false;
//                 this.currentKey = this.currentKey;
//                 this.responseObject[this.currentKey] = this.currentValue;
//             } else if (item === '.",') {
//                 this.responseObject[this.currentKey] = this.currentValue;
//                 this.currentKey = "";
//                 this.currentValue = "";
//                 this.isKey = true;
//             } else {
//                 if (this.isKey) {
//                     console.log("key", item);
//                     this.currentKey += item.replace(/"/g, "");
//                 } else {
//                     console.log("key", item);
//                     this.currentValue += item.replace(/"/g, "");
//                 }
//                 this.responseObject[this.currentKey] = this.currentValue;
//             }
//
//             this.$nextTick(() => {
//                 setTimeout(() => this.processArrayItem(array, index + 1), 50);
//             });
//         },
//     },
};
</script>

<!--<style lang="scss">-->
<!--.component-sample {-->
<!--    h1 {-->
<!--        font-size: 1.4rem !important;-->
<!--    }-->
<!--    h2 {-->
<!--        font-size: 1.2rem !important;-->
<!--    }-->
<!--    button {-->
<!--        padding-top: 0.2rem !important;-->
<!--        padding-bottom: 0.2rem !important;-->
<!--        border-radius: 0.5rem !important;-->
<!--    }-->
<!--    input {-->
<!--        @apply border;-->
<!--        @apply border-gray-900;-->
<!--        @apply rounded-md;-->
<!--        @apply border-opacity-25;-->
<!--        @apply placeholder-base-400;-->
<!--        @apply my-3;-->
<!--    }-->
<!--    button {-->
<!--        @apply px-3;-->
<!--        @extend .btn!optional;-->
<!--        padding: 10px 20px !important;-->
<!--        @apply rounded-xl;-->
<!--        @apply bg-gray-700;-->
<!--        @apply font-bold;-->
<!--        @apply text-base-50;-->
<!--        @apply my-2;-->
<!--        //@apply f-17;-->
<!--        @apply block;-->
<!--        @apply border-gray-900;-->
<!--        @apply hover:shadow;-->
<!--        @apply border-0;-->
<!--    }-->
<!--}-->
<!--</style>-->
