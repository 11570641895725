<template>
    <transition name="fade-in" appear>
        <div v-if="gradientStart && gradientEnd" class="absolute top left right text-center z-0 transition-all animated" appear :style="gradientStyles"></div>
    </transition>
</template>
<script>
export default {
    name: "SectionBlenderNew",
    props: {
        index: {
            type: Number,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
        sections: {
            type: Array,
            required: true,
        },
        height: {
            type: [Number, String],
            default: 500,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        fullbleed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        gradientStart() {
            // return this.section?.backgroundColor;
            let bgColor = this.section?.backgroundColor;
            if (this.section.imageInset) return "transparent";
            let topColor = this.section?.topColor;
            return topColor || bgColor;
        },
        gradientEnd() {
            if (this.index !== null) {
                let nextIndex = this.index - 1;
                let nextSection = this.sections?.[nextIndex];
                let backgroundColor = nextSection?.backgroundColor;
                let bottomColor = nextSection?.topColor;
                return backgroundColor || bottomColor;
                // return this.sections?.[nextIndex]?.backgroundColor;
                // return this.sections?.[nextIndex]?.backgroundColor;
            }
            return "black";
        },
        gradientStyles() {
            // return `margin-top:-${this.height / 2}px!important;height: ${this.height}px; transform: translateY(50%); background: linear-gradient(transparent, ${this.gradientStart} 25%, ${this.gradientEnd} 50%,transparent 100%)`;
            // margin-top:-${this.height / 2}px!important;
            if (this.transparent) {
                if (this.fullbleed) {
                    // return `height: ${this.height}px; transform: translateY(0%); background: linear-gradient(${this.gradientEnd} 5%, ${this.gradientStart} 50%,transparent 100%)`;
                    return `height: ${this.height}px; transform: translateY(0%); background: linear-gradient(${this.gradientEnd} 50%, transparent 100%); z-index:-1;`;
                } else {
                    return `height: ${this.height}px; transform: translateY(0%); background: linear-gradient(${this.gradientEnd} 50%, transparent 100%); z-index:-1;`;
                }
            } else {
                if (this.fullbleed) {
                    return `height: ${this.height}px; transform: translateY(0%); background: linear-gradient(${this.gradientEnd} 0%, ${this.gradientStart} 50%,transparent 100%)`;
                } else {
                    return `height: ${this.height}px; transform: translateY(0%); background: linear-gradient(${this.gradientEnd} 50%, transparent 100%); z-index:-1;`;
                }
            }
        },
    },
};
</script>
<style scoped lang="scss">
// animate height from 0 to 100%
.fade-in-enter-active {
    transition: all 0.5s ease;
}
.fade-in-enter {
    height: 0;
}
.fade-in-leave-active {
    transition: all 0.5s ease;
}
.fade-in-leave-to {
    height: 0;
}
</style>
