import gptError from "@/mixins/ai/gpt_error";
import messageLogger from "@/mixins/ai/message_logger";
import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import webRequest from "@/mixins/ai/web_request";

async function streamRequest(requestData, from = "streaming", functions) {
    try {
        return await webRequest("streamTest", requestData, true);
    } catch (e) {
        gptError(e);
    }
}
export default streamRequest;
