import { endpointURL } from "@/mixins/Chat/Commands/api_url.js";
import logBrowser from "@/mixins/Chat/Commands/log_browse.js";
import webRequest from "@/mixins/ai/web_request";
async function extractText(url, subject, max_tokens = 3000, summarize, looking_for, instructions, context) {
    try {
        let response;
        let request = {};
        if (max_tokens) request.trim = max_tokens;
        if (subject) request.subject = subject;
        if (summarize) request.summarize = summarize;
        if (looking_for) request.looking_for = looking_for;
        if (instructions) request.instructions = instructions;
        if (context) request.context = context;
        if (typeof url === "string") {
            request.url = url;
            response = await webRequest("readPage", request);
        } else {
            request.urls = url;
            response = await webRequest("extractTextMulti", request);
        }
        let data = await response.json();

        logGroup(`READ: ${url}`, { data });
        let { text, original_text, links, images } = data;
        data.request = request;
        data.url = url;
        if (text && original_text) data.summary = text;
        if (text && original_text) data.page = original_text;
        logBrowser(data);
        data.page = original_text || text;
        return data;
    } catch (error) {
        console.groupCollapsed("%cError browsing", fail, url);
        console.error("Error browsing site:", error);
        console.groupEnd();
        return false;
    }
}
window.extractText = extractText;
export default extractText;
