<template>
    <div>
        <div v-if="simple">
            <a :href="result.link" target="_blank">
                <div class="d-inline-flex jcs border-light o-8 aic my-1 mb-0 gap-1 whitespace-nowrap rounded-full px-2 text-sm text-gray-400 dark:text-base-500" v-if="true">
                    <i class="fal fa-link small"></i>
                    {{ result.source }}
                    <!--                <span class="fwn small line-clamp-1">{{ result.link.replace("https://", "").replace("www.", "") }}</span>-->
                </div>
            </a>
        </div>
        <div v-else class="animated swingInUp duration-5 mb-3">
            <a :href="result.link" target="_blank">
                <!--            <div class="d-inline-flex jcs border-light aic my-1 mb-0 gap-1 rounded-full px-2 text-sm text-gray-400 shadow-sm dark:text-base-500" v-if="true">-->
                <!--                <i class="fal fa-link small"></i>-->
                <!--                {{ result.source }}-->
                <!--                <span class="fwn small line-clamp-1">{{ result.link.replace("https://", "").replace("www.", "") }}</span>-->
                <!--            </div>-->
                <animated-text color="!text-base-800 dark:text-purple-400" class="d-block fwb mb-0 !text-base-800 dark:text-purple-400" :text="result.title"></animated-text>
            </a>
            <div class="d-block mb-0 text-sm dark:text-base-400">
                <p>
                    <span class="gap-2 text-sm line-clamp-2">
                        <span v-if="result.date">{{ result.date }}</span>
                        <span v-if="result.snippet && result.date" class="mx-1">—</span>
                        <!--                    <span v-if="result.snippet" class="o-9">{{ result.snippet }}</span>-->
                        <animated-text v-if="result.snippet" class="d-block fwb mb-0 !text-base-800 dark:text-purple-400" :text="result.snippet"></animated-text>
                    </span>
                    <span class="text-sm line-clamp-2" v-if="result.rich_snippet_list">{{ result.rich_snippet_list.join(", ") }}</span>
                </p>
            </div>
            <div class="f gap-2">
                <div class="d-inline-block">
                    <div class="d-inline-flex jcs border-light o-8 aic my-1 mb-0 gap-1 whitespace-nowrap rounded-full px-2 text-sm text-gray-400 dark:text-base-500" v-if="true">
                        <i class="fal fa-link small"></i>
                        {{ result.source }}
                        <!--                <span class="fwn small line-clamp-1">{{ result.link.replace("https://", "").replace("www.", "") }}</span>-->
                    </div>
                </div>
                <div class="f mt-2 gap-3 text-xs" v-if="result.sitelinks && result.sitelinks.inline">
                    <a v-for="link in result.sitelinks.inline" target="_blank" class="whitespace-nowrap text-gray-900" :href="link.link">{{ link.title }}</a>
                </div>
            </div>

            <DisplayTable v-if="result.rich_snippet_table" :table-data="result.rich_snippet_table"></DisplayTable>
            <template v-if="result.related_questions">
                <related-questions v-for="question in result.related_questions" :question="question"></related-questions>
            </template>
        </div>
    </div>
</template>
<script>
import DisplayTable from "@/components/chat/specialMessages/Search/DisplayTable.vue";
import RelatedQuestions from "@/components/chat/specialMessages/Search/RelatedQuestions.vue";
import AnimatedText from "@/components/CoreUI/animatedText.vue";

export default {
    name: "SearchResult",
    components: { AnimatedText, DisplayTable, RelatedQuestions },
    props: {
        result: {
            type: Object,
            required: true,
        },
        simple: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
};
</script>
