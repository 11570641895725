<template>
    <div>
        <div class="relative" ref="sections">
            <div class="p-absolute top right z-5">
                <div class="c-30 o-1 hover:opacity-50 hover:bg-snow transition-all f aic jcc m-3 cursor-pointer border-light" @click.prevent="exportEmailTemplate(false)">
                    <i class="fas fa-share"></i>
                </div>
                <!--                <div class="c-30 o-1 bg-red hover:opacity-50 hover:bg-snow transition-all f aic jcc m-3 cursor-pointer border-light" @click.prevent="exportEmailTemplate(true)">-->
                <!--                    <i class="fas fa-share"></i>-->
                <!--                </div>-->
            </div>
            <div class="visually-hidden" v-for="image in emailImages"><img :src="image" class="x" /></div>
            <div v-for="(section, index) in sections" :key="`section${index}`" :class="{ 'mw-450': mobile, 'mw-500': !mobile }" class="relative block x">
                <NewEmailSection @mouseover="setSectionEditing(`${identifier}.sections.${index}`, index)" v-if="hasImageIndex(section)" :id="id" :ref="`section-${index}`" :campaign-item="campaignItem" :identifier="`${identifier}.sections.${index}`" :index="index" :result="section" :sections="sections" class="x">
                    <!--                    <EmailFooterSection v-if="footer && index === sections.length - 1" :id="id" :footer="footer" :section="section" />-->
                </NewEmailSection>
            </div>

            <div class="mw-450 x width-450 overflow-x-scroll visually-hidden">
                <pre class="x">{{ emailHTML }}</pre>
            </div>
            <!--      loading indivator-->
            <DialogStatus :loading="loading" :title="dialogContent.title" :description="dialogContent.description" :button="dialogContent.button" :open="dialog" :link="dialogContent.buttonLink" @close="dialog = false" />
            <!--            <div v-html="emailFooterTemplate"></div>-->
            <!--            <div v-html="footerTemplate"></div>-->
            <!--            <pre class="mw-350 whitespace-pre-line">{{ footerTemplate }}</pre>-->
            <!--            <SimpleButton class="w-50 mx-3" @click="getComponentHtmlWithInlineStyles">Save Email Template</SimpleButton>-->
            <!--            <pre>{{ footerHTMLStructure }}</pre>-->
            <!--            <div class="max-w-500 width-500 visually-hidden" v-html="emailHTML"></div>-->
            <div class="x mw-500 block">
                <EmailFooterSection ref="emailFooter" class="theme1 block x" v-if="footer && sections?.length > 0" :id="id" :footer="footer" />
            </div>
            <!--            <CE v-if="currentlyEditing" :id="currentlyEditing" />-->
            <!--                        <div v-html="emailHTML"></div>-->
            <!--            <div v-html="footerHTMLStructure"></div>-->
        </div>
    </div>
</template>
<script>
import NewEmailSection from "@/components/generative_templates/Email/NewEmailSection.vue";
import EmailFooterSection from "@/components/generative_templates/Email/EmailFooterSection.vue";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import { mapGetters } from "vuex";

import imageMixin from "@/mixins/images/imageMixin";
import DialogStatus from "@/components/Admin/DialogStatus.vue";
import dialogMixin from "@/mixins/dialogMixin";
import styleGuideEmailProcessMixin from "@/mixins/Email/styleGuideEmailProcessMixin";
import SimpleButton from "@/views/SimpleButton.vue";
// import CE from "@/components/Admin/CurrentlyEditing.vue";

export default {
    name: "EmailSections",
    components: {
        // CE, //
        SimpleButton,
        DialogStatus,
        EmailFooterSection,
        NewEmailSection,
    },
    mixins: [sectionStyleMixin, styleGuideSettingsMixin, imageMixin, dialogMixin, styleGuideEmailProcessMixin],
    props: {
        sections: { type: Array, required: true },
        footer: {
            type: Object,
            default: null,
        },
        campaignItem: {
            type: Object,
            default: () => ({}),
        },
        identifier: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            emailImages: [],
            dialog: false,
            loading: false,
            footerTemplate: "",
        };
    },
    computed: {
        ...mapGetters("stream", ["editor", "currentlyEditing"]),
        ...mapGetters("styleGuide/image", ["images"]),
        ...mapGetters("styleGuide", ["styleGuide"]),
        emailText() {},
    },
    methods: {
        setSectionEditing(identifier, index) {
            this.updateStreamProp(`editor.${this.id}.editing`, index);
            // this.$store.commit("stream/updateStreamProp", identifier);
        },
        toggleBlend(index) {
            this.updateStreamProp(`editor.${this.id}.result.sections.${index}.blend`, !this.editor[this.id].result.sections[index].blend);
        },
    },
};
</script>
