<template>
    <div class="relative mb-5" :key="newKey" v-if="images">
        <ImageDivStyles />
        <div class="grid col-span-12 grid-cols-12 br-10 overflow-hidden border-light">
            <template v-for="(theme, i) in randomDivStyles">
                <div v-if="showImageInGrid(i)" class="col-span-6 md:col-span-3 aspect-w-1 aspect-h-1">
                    <StyleGuideImage :size="400" v-if="sortedImages[i] && showImageInGrid(i)" :image="sortedImages[i]" />
                </div>
                <div class="col-span-6 md:col-span-3">
                    <DivStyleBlock :show-logo="showLogo" @remove-style="removeDivStyle(theme)" :div-theme="theme" :logo="small" />
                </div>
                <div v-if="!showImageInGrid(i)" class="col-span-6 md:col-span-3 aspect-w-1 aspect-h-1">
                    <StyleGuideImage v-if="sortedImages[i] && !showImageInGrid(i)" size="400" :image="sortedImages[i]" />
                </div>
            </template>
        </div>
        <PickerContainer absolute :height="50">
            <BaseButton icon="fa fa-palette" @mouseover="showLogo = true" icon-only></BaseButton>
            <BaseButton icon="fa fa-text" @mouseover="showLogo = false" @click="makeNewKey" icon-only></BaseButton>
        </PickerContainer>
    </div>
</template>

<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import DivStyleBlock from "@/components/styleGuide/Sections/Themes/DivStyleBlock.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
import PickerContainer from "@/components/styleGuide/Sections/Themes/PickerContainer.vue";
import randomId from "@/mixins/ai/random_id";
import ImageDivStyles from "@/components/styleGuide/Sections/Themes/ImageDivStyles.vue";
import StyleGuideImage from "@/components/styleGuide/Images/StyleGuideImage.vue";

export default {
    name: "ThemeSamples",
    components: { StyleGuideImage, ImageDivStyles, PickerContainer, BaseButton, DivStyleBlock },
    mixins: [styleGuideMixin],
    data() {
        return {
            fontSize: 1,
            small: true,
            selectedTheme: null,
            showLogo: false,
            newKey: "1",
            recomputeFlag: 0,
        };
    },
    mounted() {
        this.selectedTheme = this.randomDivStyles[0];
    },
    watch: {
        showLogo(val) {
            this.randomDivStyles;
        },
    },
    computed: {
        dynamicColor() {
            return this.selectedTheme.color;
        },
        dynamicSample() {
            return this.dynamicTextSample(this.fontSize);
        },
        randomDivStyles() {
            let something = this.recomputeFlag;

            if (!this.divStyles) return [];
            let divStyles = this.divStyles.map(style => {
                let textStyle = this.randomTextStyleMethod(style);
                if (textStyle?.fontSize) {
                    let text = this.dynamicTextSample(textStyle.fontSize);
                    style = { ...style, ...textStyle, text };
                    if (style.textTransform !== "uppercase") {
                        style.textTransform = "capitalize";
                    }
                    return style;
                }
                return style;
            });
            // filter null styles
            // log styles
            divStyles = divStyles.filter(style => style);
            return divStyles;
        },
    },
    methods: {
        showImageInGrid(index) {
            // if (index === 0) return true;
            if (index === 2) return true;
            if (index === 3) return true;
            if (index === 6) return true;
            if (index === 7) return true;
            if (index === 10) return true;
            if (index === 11) return true;
            if (index === 14) return true;
            if (index === 15) return true;
            if (index === 18) return true;
            if (index === 19) return true;
            return false;
        },
        makeNewKey() {
            this.recomputeFlag++;

            this.newKey = randomId();
            this.$forceUpdate();
        },
        async removeDivStyle(ds) {
            console.log("removeDivStyle", ds);
            // Note the change in arrayKey to 'StyleGuide.products' to match the store structure.
            this.removeItemFromArray("styleGuide", "styleGuide.divStyles", ds, ["backgroundColor", "color"]);
            await updateFirebaseObject("brands", { divStyles: this.divStyles }, this.styleGuide.id);
        },
        selectTheme(theme) {
            this.selectedTheme = theme;
            this.$nextTick().then(() => {
                this.$forceUpdate();
            });
        },
        applyRandomTextStyle(index) {
            const style = this.randomTextStyleMethod();
            if (parseFloat(style.fontSize) > 1.4) {
                // style.color = "red";
            }
            delete style.color;
            return style;
        },
    },
};
</script>
<style lang="scss" scoped></style>
