import getModel from "@/mixins/ai/get_model";

function getTokenCost(model, count) {
    try {
        let modelObj = getModel(model);
        let prompt = modelObj.prompt * count;
        let completion = modelObj.completion * count;
        return {
            prompt: (prompt / 1000).toFixed(3),
            completion: (completion / 1000).toFixed(3),
        };
    } catch (error) {
        console.error(error);
        return null;
    }
    // return modelTokenCosts[model];
}
export default getTokenCost;
