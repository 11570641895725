import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import { mapGetters } from "vuex";
import styleGuideTextMixin from "@/mixins/StyleGuideMixins/StyleGuideTextMixin";
import styleGuideCSSMixin from "@/mixins/StyleMixins/StyleGuideCSSMixin";
import CreateStyleGuideMixin from "@/mixins/StyleGuideMixins/CreateStyleGuideMixin";
import chroma from "chroma-js";
export default {
    mixins: [styleGuideTextMixin, styleGuideCSSMixin, CreateStyleGuideMixin],
    data() {
        return {
            currentStyleIndex: 0,
        };
    },
    computed: {
        ...mapGetters("styleGuide", [
            "styleGuide", //
            "typographyCombos",
            "typefaceCombos",
            "typography",
            "textStyles",
            "divStyles",
            "additionalText",
            "collectedLinks",
            "backgroundColors",
        ]),
    },
    methods: {
        sortColorsByDifference(baseColor, colorArray) {
            let sorted = colorArray.sort((a, b) => {
                if (typeof a === "string" || typeof b === "string") {
                    try {
                        return chroma.deltaE(baseColor, a) - chroma.deltaE(baseColor, b);
                    } catch (error) {
                        console.error(error);
                        return;
                    }
                } else {
                    try {
                        return chroma.deltaE(baseColor, a.backgroundColor) - chroma.deltaE(baseColor, b.backgroundColor);
                    } catch (error) {
                        console.error(error);
                        return;
                    }
                }
            });
            // console.log("SORTED COLORS", sorted);
            return sorted;
        },
        updateFont(font) {
            this.updateSection("font", font);
            // this.updateStreamProp(`result.sections.${this.index}.font`, font);
            // console.log(font);
        },
        updateHeaderFont(font) {
            let css = this.updateAnyFont(font);
            if (css) this.updateSection("headerFont", css);
            // this.updateSection("headerFont", fontStyleCSS);
        },
        updateBodyFont(font) {
            let css = this.updateAnyFont(font);
            if (css) this.updateSection("bodyFont", css);
            // this.updateStreamProp(`result.sections.${this.index}.bodyFont`, fontStyleCSS);
        },
        updateAnyFont(font) {
            if (!font) return;
            let name = font?.name || font?.fontFamily;
            let weight = font?.weight || font?.fontWeight;
            let style = font?.style || font?.fontStyle;
            console.log(font);
            return `font-family:${name};font-weight:${weight};font-style:${style};text-transform:${font.transform}`;
        },
        updateSubheaderFont(font) {
            let css = this.updateAnyFont(font);
            // this.updateStreamProp(`result.sections.${this.index}.bodyFont`, fontStyleCSS);
            this.updateSection("subheaderFont", css);
            // console.log(fontStyleCSS);
        },

        // makeContrastingText(color, background, textColorArray) {
        //
        //     const colorLuminance = chroma(color).luminance();
        //     const backgroundLuminance = chroma(background).luminance();
        //
        //     // Check if background is dark
        //     const backgroundIsDark = backgroundLuminance < 0.2;
        //
        //     // Calculate contrast between color and background
        //     const contrast = chroma.contrast(color, background);
        //
        //     // Define a threshold for good contrast
        //     const contrastThreshold = 4.5; // This is a common threshold for legible contrast
        //     const contrastIsGood = contrast > contrastThreshold;
        //
        //     // If contrast is poor and background is dark, return white; otherwise, return color
        //     if (!contrastIsGood && backgroundIsDark) return "#ffffff";
        //     else return color;
        // },
        makeContrastingText(color, background, textColorArray) {
            // If textColorArray is not provided or is empty, use the original color
            if (!textColorArray || textColorArray.length === 0) return color;

            // Calculate the background luminance
            const backgroundLuminance = chroma(background).luminance();

            // Define a threshold for good contrast
            const contrastThreshold = 4.5;

            // Initialize variables to store the best color and its contrast
            let bestColor = textColorArray[0];
            let bestContrast = chroma.contrast(bestColor, background);

            // Iterate through the textColorArray and find the color with the best contrast
            for (let i = 1; i < textColorArray.length; i++) {
                const currentColor = textColorArray[i];
                const currentContrast = chroma.contrast(currentColor, background);

                // If the current color has better contrast, update the best color and contrast
                if (currentContrast > bestContrast) {
                    bestColor = currentColor;
                    bestContrast = currentContrast;
                }
            }

            // Check if the best contrast is below the threshold
            if (bestContrast < contrastThreshold) {
                // If the background is dark, return white; otherwise, return the original color
                return backgroundLuminance < 0.2 ? "#ffffff" : color;
            }

            // Return the color with the best contrast
            return bestColor;
        },
        // Determine the contrasting color
        getContrastingColor(colors) {
            return colors[0] ? colors[0].color : null;
        },
        filterGradientColors(divStyles) {
            return divStyles.filter(item => !item?.backgroundColor?.includes("gradient"));
        },

        findContrastingColor(baseColor) {
            if (!this.backgroundColors || !baseColor) return null;
            let divStyles = this.styleGuide.divStyles; // Array of objects {bg: color, color: color}
            // let divStyles = this.divStyles; // Array of objects {bg: color, color: color}

            divStyles = this.filterGradientColors(divStyles); // Filter out background colors with gradients
            let mappedDivStyles = divStyles.map(item => item.backgroundColor);
            // console.log("MAPPED TO BACKGROUND COLOR", mappedDivStyles);
            // const colors = this.sortColorsByDifference(baseColor, mappedDivStyles); // Sort divStyles array based on the difference to baseColor
            const colors = this.sortColorsByDifference(baseColor, divStyles); // Sort divStyles array based on the difference to baseColor
            // let contrastingColor = this.getContrastColor(baseColor, colors); // Determine the contrasting color
            let contrastingColor = this.getContrastingColor(colors); // Determine the contrasting color
            // this.textColorOverride = contrastingColor;
            // contrastingColor = this.getContrastColor(contrastingColor, colors);
            contrastingColor = this.makeContrastingText(contrastingColor, baseColor, this.backgroundColors);
            // logColor2(contrastingColor, baseColor, colors);
            return contrastingColor;
        },
    },
};
