<template>
    <div class="progress-container flex-shrink-0 transition-all" :style="`width: ${size}px; height: ${size}px; max-height: ${size}px; max-width: ${size}px; border-radius: 100px;`">
        <div class="progress-circle transition-all" :style="circleStyle"></div>
    </div>
</template>

<script>
export default {
    name: "CircularProgress",
    props: {
        progress: { type: Number, required: true }, // Progress value between 0 and 100
        size: { type: Number, default: 56 }, // Size of the progress ring in pixels
    },
    computed: {
        circleStyle() {
            const coveredAngle = (this.progress / 100) * 360;
            return {
                "background-image": `conic-gradient(currentColor ${coveredAngle}deg, transparent ${coveredAngle}deg)`,
            };
        },
    },
};
</script>

<style>
.progress-container {
    position: relative;
}

.progress-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Makes the div circular */
    background-color: transparent; /* Initial background color */
    transform: rotate(-90deg); /* Start the progress from the top */
    transform-origin: center;
}
</style>
