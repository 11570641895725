import firebaseMixin2 from "@/mixins/firebase/firebaseMixin2";

export default {
    mixins: [firebaseMixin2],
    methods: {
        async img2imgWithMask(url, prompt, maskData, customOptions = {}, newPrompt) {
            const endpoint = "stabilityImageToImage";
            const apiURL = `${this.apiURL}`;
            const requestURL = apiURL + endpoint;

            const defaultOptions = {
                init_image_mode: "IMAGE_STRENGTH",
                image_strength: 0.4,
                prompt_weight: 0.5,
                new_prompt_weight: 2,
                cfg_scale: 7,
                clip_guidance_preset: "FAST_BLUE",
                height: 512,
                width: 512,
                samples: 3,
                steps: 50,
            };

            const options = { ...defaultOptions, ...customOptions };

            const request = {
                url: url,
                prompt: prompt,
                maskData: maskData, // Include the maskData in the request object
                options: options,
            };
            if (newPrompt) {
                request.newPrompt = newPrompt;
            }
            const response = await fetch(requestURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(request),
            });

            const responseData = await response.json(); // Parse response JSON
            const imageURLs = [];
            const self = this;

            for (const image of responseData) {
                let url = await self.uploadImage(image);
                imageURLs.push(url);
            }

            console.log(responseData); // Log the response data
            console.log(imageURLs); // Log the response data

            return imageURLs; // Return the response data
        },
        async processCanvasImage() {
            // Get the base64 encoded image data from the canvas
            const canvas = this.$refs.maskCanvas;
            const base64ImageData = canvas.toDataURL("image/png");

            // Call the stabilityImageToImageServer method and include the necessary prompt, mask data, and options
            const prompt = this.textPrompt;
            const maskData = base64ImageData; // Pass the mask data as a new parameter
            const customOptions = {
                // Add custom options if needed
            };

            try {
                const imageURLs = await this.img2imgWithMask(base64ImageData, prompt, maskData, customOptions);

                // Display the resulting image URLs or use the images as needed
                console.log(imageURLs);

                // Example: Show the first image in a separate PhotoViewer component (assuming that component is implemented)
                if (imageURLs.length > 0) {
                    this.$emit("openPhotoViewer", imageURLs[0]);
                }
            } catch (error) {
                console.error("Error processing the image:", error);
            }
        },
    },
};
// async processCanvasImage() {
//     // Capture the mask from the canvas
//     const maskDataURL = this.$refs.maskCanvas.toDataURL("image/png");
//     const response = await fetch(maskDataURL);
//     this.maskImageData = await response.blob();
//
//     const formData = new FormData();
//     formData.append("mask_source", "MASK_IMAGE_BLACK");
//     formData.append("init_image", this.initImageData);
//     formData.append("mask_image", this.maskImageData);
//     formData.append("text_prompts[0][text]", this.textPrompt);
//     formData.append("text_prompts[0][weight]", 1);
//
//     // ... Add other API parameters as required
//
//     const headers = {
//         "Content-Type": "multipart/form-data",
//         "Stability-Client-ID": "your_client_id",
//         "Stability-Client-Version": "1.0.0",
//         Accept: "application/json",
//         Authorization: `Bearer ${this.stabilityAPIKey}`,
//     };
//
//     try {
//         const response = await axios.post(`${this.apiURL}v1/generation/engine_id/image-to-image/masking`, formData, { headers });
//         console.log(response.data);
//     } catch (error) {
//         console.error("Error occurred", error);
//     }
// },
