<template>
    <div class="">
        <div class="f fc gap-2">
            <h6 class="fwb mb-0">MEMORY</h6>
            <div class="block">
                <ul class="pl-3">
                    <li v-for="(mems, index) in memory">
                        <div class="f jcs inline_task_item aic">
                            <span>{{ mems }}</span>
                            <ButtonRemoveListItem @click.prevent="removeMemory(index)" />
                        </div>
                    </li>
                </ul>
            </div>
            <div class="mt-1" v-if="toggleAdd">
                <BaseInput ref="input" :id="randomId()" :modelValue="newMemory" @blur.prevent="toggleAddMemory" :rows="1" placeholder="Add to memory" @keyup.enter="addMemory" @update:modelValue="newMemory = $event" />
            </div>
            <ButtonAddNew v-else @click.prevent="toggleAddMemory" text="Add new memory" />
        </div>
    </div>

</template>

<script>
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import MemoryCommand from "@/mixins/Chat/Commands/MemoryCommand";
import ButtonAddNew from "@/views/Chat/ButtonAddNew.vue";
import ButtonRemoveListItem from "@/views/Chat/ButtonRemoveListItem.vue";

export default {
    name: "MemoryManager",
    components: { ButtonRemoveListItem, ButtonAddNew, BaseInput },
    mixins: [MemoryCommand],
    data() {
        return {
            newMemory: "",
            toggleAdd: false,
        };
    },
    methods: {
        toggleAddMemory() {
            this.toggleAdd = !this.toggleAdd;
            if (this.toggleAdd) {
                this.$nextTick(() => {
                    this.$refs.input?.$refs.input?.focus();
                });
            }
        },
    },
    computed: {
        memory() {
            return this.$store.state.chat.memory.memory;
        },
        summaries() {
            return this.$store.state.chat.summary;
        },
    },
};
</script>
