<template>
    <div class="f-13">
        <base-badge v-if="badge" class="inline-block" :color="badgeColor" :text="status" :class="{ 'dark:text-white': dark }" />
        <div v-else :class="[statusColor, 'h-4 w-4 flex-none rounded-full p-1', `${dark ? 'bg-gray-800' : 'bg-white'}`]">
            <div class="h-2 w-2 rounded-full" :class="dark ? 'bg-current' : 'bg-gray-900'" />
        </div>
    </div>
</template>

<script>
export default {
    name: "StatusLight",
    props: {
        status: {
            type: String,
            default: "queued",
        },
        dark: {
            type: Boolean,
            default: false,
        },
        badge: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        statusColor() {
            const statusColors = {
                warning: this.dark ? "text-yellow-300" : "text-yellow-500",
                info: this.dark ? "text-blue-300" : "text-blue-500",
                done: this.dark ? "text-green-300" : "text-green-500",
                Done: this.dark ? "text-green-300" : "text-green-500",
                processing: this.dark ? "text-yellow-300" : "text-yellow-500",
                Processing: this.dark ? "text-yellow-300" : "text-yellow-500",
                error: this.dark ? "text-red-300" : "text-red-500",
                Error: this.dark ? "text-red-300" : "text-red-500",
                queued: this.dark ? "text-amber-300" : "text-amber-500",
                embedding: this.dark ? "text-amber-300" : "text-amber-500",
                Queued: this.dark ? "text-amber-300" : "text-amber-500",
            };

            let status = this.status || "queued";
            status = status.toLowerCase();
            return statusColors[status];
        },
        badgeColor() {
            const badgeColors = {
                done: this.dark ? "green" : "green",
                Done: this.dark ? "green" : "green",
                processing: this.dark ? "yellow" : "yellow",
                Processing: this.dark ? "yellow" : "yellow",
                error: this.dark ? "red" : "red",
                Error: this.dark ? "red" : "red",
                queued: this.dark ? "yellow" : "yellow",
                Queued: this.dark ? "yellow" : "yellow",
                embedding: this.dark ? "yellow" : "yellow",
                warning: this.dark ? "yellow" : "yellow",
                info: this.dark ? "blue" : "blue",
            };

            return badgeColors[this.status] || "yellow";
        },
    },
};
</script>
