<template>
    <i :class="`fal fa-check text-${color} fa-${size} block`"></i>
</template>
<script>
export default {
    name: "IconCheck",
    props: {
        size: {
            type: String,
            default: "2xl",
        },
        color: {
            type: String,
            default: "green",
        },
    },
};
</script>
