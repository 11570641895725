<template>
    <dt :class="`from-${colorName}/10`" class="text-gray-400 self-stretch h-auto p-2 x w-[100px] max-w-[110px] bg-gradient-to-l to-10%">
        <div class="f aic gap-2 jcb leading-tight text-left text-sm fwsb">
            <div v-if="title" class="line-clamp-2 text-black w-70" :class="{ 'f-15': title.length > 10 }">{{ title }}</div>
            <!--            <div style="width: 2px" :class="depthColors" class="-my-2 self-stretch flex-shrink-0"></div>-->
        </div>
    </dt>
</template>
<script>
export default {
    name: "TableTitle",
    props: {
        title: {
            type: String,
            default: "",
        },
        depth: {
            type: [String, Number],
            default: 0,
        },
    },
    computed: {
        colorName() {
            const colors = ["", "blue", "green", "orange", "red", "purple", "pink"]; // Index corresponds to depth
            let depth = this.depth;
            return colors[depth];
        },
        depthColors() {
            try {
                const baseClasses = "";
                // const baseClasses = "border-left border-b";
                const colors = ["", "blue", "green", "orange", "red", "purple", "pink"]; // Index corresponds to depth
                const defaultClasses = "bg-snow"; // Default class for depth 0
                if (this.depth === 0) {
                    return `${baseClasses} ${defaultClasses}`;
                } else {
                    const color = colors[this.depth];
                    const textClass = `!text-${color}-500`;
                    const borderClass = `!border-${color}-200`;
                    const bgClass = `bg-${color}-500`;
                    return `${baseClasses} ${textClass} ${borderClass} ${bgClass}`;
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>
