<template>
    <div class="block y x f aic jcc" @mouseleave="hovered = false" @mouseover="hovered = true">
        <div class="x mx-auto block p-3 m-5">
            <div class="absolute x">
                <div class="p-3">
                    <div v-if="cssStyle?.before?.content" :style="cssStyle.before" class="x">Shop now</div>
                    <div v-if="cssStyle?.after?.content" :style="cssStyle.after" class="z-0 x"></div>
                </div>
            </div>
            <button v-if="cssStyle" :style="applyStyle(cssStyle)" class="f x aic jcc relative"><span class="relative">Shop now</span></button>
        </div>
        <div class="absolute top right z-10 left">
            <div class="x f aic jcb p-2">
                <div><ItemStyler @save-style="saveStyle" :item-style="cssStyle" class="z-10" /></div>
                <BaseButton v-if="hovered" icon="fas fa-times" icon-only size="xxs" @click.prevent="deleteButton(cssStyle)"></BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ItemStyler from "@/components/styleGuide/Sections/ItemStyler.vue";

export default {
    name: "ButtonSamples",
    mixins: [styleGuideMixin],
    components: { ItemStyler, BaseButton },
    props: {
        cssStyle: {},
    },
    data() {
        return {
            hovered: false,
        };
    },
    methods: {
        saveStyle(style) {
            // find the index
            // let currentButton = this.cssStyle;
            let buttonIndex = this.styleGuide.buttonStyles.findIndex(b => b === this.cssStyle);
            this.updateStyleGuideProp(`buttonStyles.${buttonIndex}`, style, true);
        },
        async deleteButton(button) {
            await this.$store.dispatch("styleGuide/deleteButton", button);
        },
        clickToCopyInnerText() {
            // for $ref styleInfo
            const range = document.createRange();
            range.selectNode(this.$refs.styleInfo);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
        },
    },
};
</script>
<style scoped>
button {
    border: unset !important;
    outline: unset !important;
    cursor: unset !important;
    padding: unset !important;
    margin: unset !important;
    font-size: unset !important;
}
</style>
<!--                                <button class="c-40 aic jcc inline-flex" v-else :style="applyStyle(cssStyle)"></button>-->
<!--            <div class="f aic jcc p-3">-->
