import { createRouter, createWebHistory } from "vue-router";
import NotFound from "./views/NotFound.vue";
import Authentication from "@/views/Auth.vue";
import Documents from "@/views/DocumentHome.vue";
import Presentations from "@/views/Presentations.vue";
import Register from "@/views/Register.vue";
import SignIn from "@/views/SignIn.vue";
import Stability from "@/views/Stability.vue";
import ChatSimple from "@/views/Chat/ChatSimple.vue";
import MyLikes from "@/views/MyLikes.vue";
import Styles from "@/views/Styles.vue";
import store from "@/store/index";
import StyleGuideViewer from "@/views/StyleGuideViewer.vue";
import Home from "@/views/Home.vue";
import Campaigns from "@/components/generative_templates/Campaigns/Campaigns.vue";
import styleGuideMedia from "@/views/AdminHome.vue";
import CampaignList from "@/components/Admin/CampaignList.vue";
import StyleGuideProducts from "@/components/styleGuide/Sections/Products.vue";
import StyleGuideImageArray from "@/components/styleGuide/Sections/ImagesSection.vue";
import StyleGuideVideoComponent from "@/components/styleGuide/Sections/Videos.vue";
import StyleGuideTextSamples from "@/components/styleGuide/Sections/TextSamples.vue";
import AdminTextSamples from "@/components/Admin/AdminTextSamples.vue";
import Settings from "@/components/Admin/Settings.vue";
import Links from "@/components/Admin/Links.vue";
import LandingPage from "@/components/generative_templates/Pages/NewCampaignPage.vue";
import Billing from "@/components/Admin/Billing.vue";
import Fonts from "@/components/Admin/Fonts.vue";
import Buttons from "@/components/styleGuide/Sections/Buttons.vue";
import CampaignPreview from "@/components/Admin/CampaignPreview.vue";
import adminImages from "@/components/styleGuide/Sections/adminImages.vue";
import homePageHeader from "@/views/HomePageHeader.vue";
import Categories from "@/views/Categories.vue";
import ThreadViewer from "@/views/Chat/ThreadViewer.vue";
import BrandIndex from "@/views/BrandIndex.vue";
import Auth from "@/views/Auth.vue";
import AdminViewer from "@/views/AdminViewer.vue";
import allUsers from "@/views/AllUsers.vue";
import HomeBasic from "@/views/HomeBasic.vue";
import KlaviyoTemplates from "@/components/Admin/KlaviyoTemplates.vue";

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
    { path: "/", component: HomeBasic, meta: { title: "BrandBlocks" } },
    // { path: "/", component: Auth, meta: { title: "BrandBlocks" } },
    // { path: "/", component: Home, meta: { title: "BrandBlocks" } },
    { path: "/about", component: homePageHeader, meta: { title: "About BrandBlocks" } },
    { path: "/categories", component: Categories, meta: { title: "Categories" } },
    { path: "/categories/:cat", component: Categories, meta: { title: "Categories" } },
    // all brands
    { path: "/brands", component: BrandIndex, meta: { title: "Brands" } },
    { path: "/chat" + "", meta: { title: "Chat" }, component: ChatSimple },
    { path: "/users" + "", meta: { title: "Admin - All Users" }, component: allUsers },
    { path: "/chat/:id", meta: { title: "Chat" }, component: ChatSimple },
    { path: "/threads", meta: { title: "Assistants" }, component: ThreadViewer },
    { path: "/threads/:id", meta: { title: "Threads" }, component: ThreadViewer },
    { path: "/styleGuide", name: "styleGuide", meta: { title: "Styles" }, component: StyleGuideViewer },
    { path: "/styleGuide/:id", name: "styleGuideId", meta: { title: "Styles" }, component: StyleGuideViewer },
    {
        path: "/admin/:id",
        name: "admin",
        meta: { title: "Styles" },
        component: styleGuideMedia,
        children: [
            { path: "campaigns", name: "campaigns", meta: { title: "Campaigns" }, component: CampaignList },
            { path: "emails", name: "Emails", meta: { title: "Emails" }, component: LandingPage },
            { path: "campaigns/:cid", name: "campaign", meta: { title: "Campaign Preview" }, component: CampaignPreview },
            { path: "buttons", name: "buttons", meta: { title: "Buttons" }, component: Buttons },
            { path: "products", name: "Products", meta: { title: "Products" }, component: StyleGuideProducts }, // Assuming you are using the same Campaigns component, replace as needed},
            {
                path: "images",
                name: "Images",
                meta: { title: "Images" },
                component: adminImages, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "styles",
                name: "Style Guide",
                meta: { title: "Style Guide" },
                component: AdminViewer, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "fonts",
                name: "Typefaces",
                meta: { title: "Typography" },
                component: Fonts, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "videos",
                name: "Videos",
                meta: { title: "Videos" },
                component: StyleGuideVideoComponent, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "brand-voice",
                name: "Brand Voice",
                meta: { title: "Brand Voice" },
                component: AdminTextSamples, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "settings",
                name: "Settings",
                meta: { title: "Settings" },
                component: Settings, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "templates",
                name: "Templates",
                meta: { title: "Klaviyo Templates" },
                component: KlaviyoTemplates, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "links",
                name: "links",
                meta: { title: "links" },
                component: Links, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "billing",
                name: "Billing",
                meta: { title: "billing" },
                component: Billing, // Assuming you are using the same Campaigns component, replace as needed
            },
            {
                path: "account",
                name: "account",
                meta: { title: "Account" },
                component: Auth,
            },
        ],
    },
    { path: "/documents/:id", meta: { title: "Documents" }, component: Documents },
    { path: "/documents", meta: { title: "Documents" }, component: Documents },
    { path: "/presentations/:id", meta: { title: "Presentations" }, component: Presentations },
    { path: "/presentations" + "", meta: { title: "Presentations" }, component: Presentations },
    { path: "/register", meta: { title: "Register" }, component: Register },
    { path: "/auth", meta: { title: "Register" }, component: Authentication },
    { path: "/sign-in", meta: { title: "Sign in" }, component: SignIn },
    { path: "/styles", meta: { title: "Styles" }, component: Styles },
    { path: "/:path(.*)", component: NotFound },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
function checkAuthentication() {
    if (store?.state?.user?.user) {
        return true;
    } else {
        return false;
    }
}
router.beforeEach(async (to, from, next) => {
    const pageTitle = to.meta.title || "Default Title";
    document.title = `${pageTitle} | Clearmind`;

    const path = to.path || "";
    const protectedRoutes = ["/categories/", "/styleGuide", "/admin", "/about", "/threads", "/chat"];

    // Check if the user is authenticated
    const isAuthenticated = await checkAuthentication(); // Implement this function to check authentication status

    if (to.name === "home") {
        if (!isAuthenticated) {
            next("/auth");
            return;
        }
    }
    if (protectedRoutes.some(route => path.startsWith(route)) && !isAuthenticated) {
        // User is not authenticated and trying to access a protected route
        next("/Auth"); // Redirect to the login page
        return;
    }

    if (!store.state.user.user && path.startsWith("/admin/") && !path.includes("account")) {
        const adminId = to?.params?.id;
        console.log("adminId", adminId);
        next(`/admin/${adminId}/account`);
        return;
    }
    store.commit("setcurrentRoute", to);
    const matchedComponents = to.matched.flatMap(routeRecord => {
        return Object.values(routeRecord.components);
    });

    matchedComponents.forEach(component => {
        if (component.mixins === undefined) {
            component.mixins = [];
        }

        if (component.__file === undefined) {
            return;
        }

        const route = to.matched.find(route => {
            return Object.values(route.components).some(nestedComponent => nestedComponent.__file === component.__file);
        });

        if (route && route.meta.mixins) {
            component.mixins = [...component.mixins, ...route.meta.mixins];
        }
    });

    next();
});

export default router;
