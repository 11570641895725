<template>
    <div class="f vh-50 jcs x aic flex flex items-center justify-center">
        <div class="f x fc aic jcc gap-2">
            <div class="text-center mx-auto">
                <div class="lemniscate f-50 font-bold">🪄</div>
                <div v-if="queue?.research?.build?.topic" class="animated fadeInUpSmooth text-capitalize duration-5 f-24 font-bold">{{ queue.research.build.topic }}</div>
                <image-pile-rotation class="mx-auto" v-if="researchImages && researchImages.length > 0" :images="researchImages" />
            </div>
            <transition-group v-if="state && state.length > 0" class="f mw-900 mt-3 flex-wrap aic jcc gap-3 mx-auto" enter-active-class="animated swingInUp" leave-active-class="fadeInUpSmooth duration-1 reverse-animation" name="progress" tag="div">
                <div v-for="(progressStep, index) in state" :key="'p' + index" class="mw-400 x width-min-300">
                    <progress-step :progress-step="progressStep" />
                </div>
            </transition-group>
        </div>
    </div>
</template>
<script>
import PresentationProgressStep from "@/components/Presentations/slides/PresentationProgressStep.vue";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import ImagePileRotation from "@/components/Presentations/slides/ImagePileRotation.vue";
import ProgressStep from "@/components/Presentations/slides/ProgressStep.vue";
import { mapGetters } from "vuex";

export default {
    name: "PresentationProgress",
    components: { ProgressStep, ImagePileRotation, PresentationProgressStep },
    mixins: [presentationMixin],
    props: {},
    data() {
        return {
            name: "",
            text: "",
        };
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue", "progress"]),
        state() {
            return this.progress && this.progress.length > 0 ? this.progress : [];
        },
        researchImages() {
            if (this.document?.images?.length > 0) return this.document.images;
            else if (this.queue?.images?.length > 0) return this.queue.images;
            return [];
        },
    },
    methods: {},
};
</script>
<style lang="scss">
@keyframes figureEight {
    0% {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}
</style>
