<template>
    <div>
        <div class="bg-gray-300 py-10 x w-50" v-if="hasProgress">
            <!--        <template v-if="readPage">{{ readPage }}</template>-->
            <h2 class="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">Latest activity</h2>
            <MiniFeed v-for="(item, key) in progress" :progress="item" :title="key"></MiniFeed>
            <div class="grid grid-cols-12 px-5 gap-5">
                <div class="col-span-4">
                    <ReadPageProgress v-if="readPage" :sources="combinedPageAndCrawler" title="Read Page" />
                    <!--                <ReadPageProgress v-if="readPage" title="Read Page" :sources="crawler" />-->
                    <!--                <ReadPageProgress v-if="processImages" title="Read Page" :sources="processedImages" />-->
                </div>
                <div class="col-span-2">
                    <ReadPageProgress v-if="styleGuide" :sources="styleGuide" title="Style Guide" />
                </div>
                <div class="col-span-3">
                    <ProductProgress v-if="productProgress" :products="productProgress" />
                    <ReadPageProgress v-if="fontProgress" :sources="fontProgress" title="Fonts" />
                </div>
                <div class="col-span-3">
                    <ImageProgress v-if="processImages" :images="processImages" :stats="imageProcessing" />
                </div>
            </div>
            <debug-object v-if="progress" :object="{ progress: progress }" />

            <div v-if="progress" class="divide-y divide-white/5">{{ Object.keys(progress) }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import ImageProgress from "@/views/ImageProgress.vue";
import ProgressStats from "@/views/ProgressStats.vue";
import ProductProgress from "@/views/productProgress.vue";
import ReadPageProgress from "@/views/readPageProgress.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import MiniFeed from "@/components/generative_templates/Pages/MiniFeed.vue";
import Example from "@/components/shad/examples/playground/Example.vue";
// import Dashboard3 from "@/components/shad/examples/Dashboard3.vue";
export default {
    components: {
        Example,
        MiniFeed,
        DebugObject,
        ReadPageProgress,
        ProductProgress,
        ProgressStats,
        ImageProgress,
        BaseBadge,
        TimeStamp,
    },
    computed: {
        ...mapGetters("styleGuide", ["progress"]),
        hasProgress() {
            // check if progress is not empty
            return Object.keys(this.progress).length > 0;
        },
        processedImages() {
            return this.progress?.processedImages || {};
        },
        styleGuide() {
            let progress = this.progress;
            if (progress?.styleGuide) {
                let styleGuide = progress.styleGuide;
                if (styleGuide?.progress?.length > 0) return progress.styleGuide;
                else return false;
            } else return false;
        },
        imageProcessing() {
            let progress = this.progress;
            if (progress?.imageProcessing) return progress.imageProcessing;
            else return false;
        },
        readPage() {
            let progress = this.progress;
            if (progress?.readPage) {
                let readPage = progress.readPage;
                if (readPage?.progress?.length > 0) return progress.readPage;
                return false;
            } else return false;
        },
        processImages() {
            let progress = this.progress;
            if (progress?.processImages) {
                let images = progress.processImages;
                if (images?.progress?.length > 0) return images;
                return false;
            } else return false;
        },
        productProgress() {
            let progress = this.progress;
            if (progress?.products) {
                let products = progress.products;
                if (products?.progress?.length > 0) return progress.products;
                return false;
            } else return false;
        },
        fontProgress() {
            let progress = this.progress;

            if (progress?.fonts) {
                let fonts = progress.fonts;
                if (fonts?.progress?.length > 0) return progress.fonts;
                return false;
            } else return false;
        },
        combinedPageAndCrawler() {
            let progress = this.progress;
            if (progress?.readPage && progress?.crawler) {
                let readPage = progress?.readPage?.progress || [];
                let crawler = progress?.crawler?.progress || [];
                let combined = readPage.concat(crawler);
                let obj = {
                    progress: combined,
                    progressCount: combined.length,
                };
                if (obj?.progress?.length > 0) return obj;
                return false;
            } else return false;
        },
        crawler() {
            let progress = this.progress;
            if (progress?.crawler) {
                let crawler = progress.crawler;
                if (crawler?.progress?.length > 0) return progress.crawler;
                return false;
            } else return false;
        },
    },
    methods: {
        formatDate(timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleString();
        },
    },
};
</script>
