<script>

export default {
    data() {
        return {
            pageArtists: [],
            dbGenres: [],
            selectedGenre: {},
            legalStep: 1,
            steps:[{"step":"Determine your daily calorie goal","description":"The first step in creating a meal plan is to determine your daily calorie goal. This will help you decide how much food you need to eat each day to achieve your weight loss goals."},
                {"step":"Choose healthy foods","description":"Choose foods that are high in fiber and low in calories, such as fruits, vegetables, lean proteins, and whole grains."},
                {"step":"Plan your meals","description":"Plan your meals ahead of time so you know what you will be eating each day. This will make it easier to stick to your diet and help you avoid unhealthy choices."},
                {"step":"Include snacks","description":"Include healthy snacks in your meal plan to keep your energy levels up and help you stay on track."},
                {"step":"Drink plenty of water","description":"Drinking plenty of water throughout the day will help you stay hydrated and may also help with weight loss."},
                {"step":"Keep track of your progress","description":"Keep track of your progress by logging your meals, snacks, and drinks. This will help you stay motivated and make adjustments to your meal plan if needed."}],
            steps2:[
                {"step": "Calculate your Basal Metabolic Rate (BMR)",
                    "description": "Calculate your BMR using an online calculator. Your BMR is the amount of calories you need to maintain your body’s basic functions, such as breathing and circulation. It represents the minimum number of calories you need to consume in a day to be able to function properly."},
                {"step": "Determine your activity level",
                    "description": "Determine your activity level, which can range from sedentary (little to no exercise) to very active (intense exercise several times a week). This will help you figure out the amount of exercise you need to do to reach your calorie goal."},
                {"step": "Calculate your daily calorie goal",
                    "description": "Multiply your BMR by your activity level factor to get your daily calorie goal. This number is the approximate number of calories you should consume each day to reach your weight goal."}
            ],
            legalSteps: [
                {
                    "step": "Confirm that the will or trust exists",
                    "description": "If the will or trust has been filed with a court or a financial institution, you may be able to obtain a copy by contacting the relevant parties and providing proof of your identity. If the will or trust has not been filed with a court or financial institution, you may need to ask the person who created the document (the 'testator' or 'grantor') or their legal representative for a copy."
                },
                {
                    "step": "Gather any relevant documentation",
                    "description": "This may include any communications you had with the testator or grantor about their will or trust, or any documents that mention your name or your potential inheritance."
                },
                {
                    "step": "Seek legal assistance",
                    "description": "An attorney can help you understand your rights and options, and can advise you on the best course of action to take to obtain the information you are seeking."
                },
                {
                    "step": "Send a written request for the information",
                    "description": "If you are unable to obtain the information you are seeking through other means, consider sending a written request for the information to the person or entity who holds the will or trust (such as the trustee or the testator's estate). Be sure to include any relevant documentation that supports your claim to be a beneficiary."
                },
                {
                    "step": "Consider legal action",
                    "description": "If you are still unable to obtain the information you are seeking after taking these steps, you may need to consider legal action to enforce your rights as a beneficiary. An attorney can advise you on the specific steps you need to take to pursue this course of action."
                }
            ],
            legalSteps2: [
                {
                    "step": "Contact the relevant parties",
                    "description": "If the will or trust has been filed with a court or a financial institution, you may be able to obtain a copy by contacting the relevant parties and providing proof of your identity. This may include the probate court, the testator's attorney, or the financial institution where the trust is being held."
                },
                {
                    "step": "Ask the testator or grantor",
                    "description": "If the will or trust has not been filed with a court or financial institution, you may need to ask the person who created the document (the 'testator' or 'grantor') or their legal representative for a copy. It is important to keep in mind that the testator or grantor may not be able to provide a copy if the document is being held by a third party, such as a financial institution or an attorney."
                },
                {
                    "step": "Seek legal assistance",
                    "description": "If you are unable to confirm the existence of the will or trust through these means, you may need to seek legal assistance. An attorney can help you understand your rights and options, and can advise you on the best course of action to take to obtain the information you are seeking."
                }
            ]
        };
    },
    async mounted() {

    },
    computed: {},

    methods: {}
};
</script>
<template>
    <div class=" mw-600 mx-auto pt-7">
        <div @click.prevent="addImageToFirebase()">Click to upload</div>
        <div class="d-block" v-for="item in steps" v-if="legalStep === 1">
            <div class="fwb d-block" @click.prevent="legalStep = 2">{{ item.step }}</div>
            <p>{{ item.description }}</p>
        </div>
        <div class="d-block" v-for="item in steps2" v-if="legalStep === 1">
            <div class="fwb d-block" @click.prevent="legalStep = 2">{{ item.step }}</div>
            <p>{{ item.description }}</p>
        </div>
<!--        <div class="d-block" v-for="item in legalSteps" v-if="legalStep === 1">-->
<!--            <div class="fwb d-block" @click.prevent="legalStep = 2">{{ item.step }}</div>-->
<!--            <p>{{ item.description }}</p>-->
<!--        </div>-->
<!--        <div class="d-block" :class="`animated fadeInUpSmooth d-${index+2}`" v-if="legalStep === 2" v-for="(item,index) in legalSteps2" @click.prevent="legalStep = 2">-->
<!--            <div class="fwb d-block">{{ item.step }}</div>-->
<!--            <p>{{ item.description }}</p>-->
<!--        </div>-->

    </div>
</template>
