window.userMessage = function (message) {
    return { role: "user", content: message };
};
window.aiMessage = function (message, kind, func) {
    let msg = { role: "assistant", content: message };
    if (func) msg.role = "function";
    if (func) msg.function_call = func;
    if (kind) msg.kind = kind;
    return msg;
};
window.sysMessage = function (message) {
    return { role: "system", content: message };
};
window.functionMessage = function (name, content) {
    return { role: "function", name: name, content: content };
};
window.roleMessage = function (message = "", role = "", kind) {
    let msg = { role: role, content: message };
    if (kind) msg.kind = kind;
    return msg;
};
window.objectToMarkdown = function (obj, config = { headerLevel: 3 }) {
    let markdown = "";

    const createHeader = key => `#${"#".repeat(config.headerLevel)} ${key}\n`;
    const createListItem = item => `- ${item}\n`;
    const createKeyValue = (key, value) => `${key}: ${value}\n`;
    const createNameValue = (name, value) => `${name}: ${value}\n`;
    const createSeparator = () => "\n---\n";

    const isNameValueObject = obj => obj.hasOwnProperty("name") && obj.hasOwnProperty("value");

    const processArray = (key, array) => {
        if (array.length === 0) return "";
        let arrayMarkdown = createHeader(key);
        array.forEach(item => {
            if (typeof item === "object" && item !== null) {
                if (isNameValueObject(item)) {
                    arrayMarkdown += createNameValue(item.name, item.value);
                } else {
                    arrayMarkdown += objectToMarkdown(item, config);
                    arrayMarkdown += createSeparator();
                }
            } else {
                arrayMarkdown += createListItem(item);
            }
        });
        return arrayMarkdown;
    };

    const processObject = (key, value) => {
        if (Object.keys(value).length === 0) return "";
        return createHeader(key) + objectToMarkdown(value, config);
    };

    for (const [key, value] of Object.entries(obj)) {
        try {
            if (Array.isArray(value)) {
                markdown += processArray(key, value);
            } else if (typeof value === "object" && value !== null) {
                if (isNameValueObject(value)) {
                    markdown += createNameValue(value.name, value.value);
                } else {
                    markdown += processObject(key, value);
                }
            } else if (typeof value === "number") {
                markdown += createKeyValue(key, value);
            } else if (value || typeof value === "boolean") {
                markdown += createHeader(key) + value + "\n";
            }
        } catch (error) {
            console.error("Error processing object to markdown:", error);
        }
    }

    return markdown;
};

window.imageObject = function (url, prompt) {
    return {
        url: url,
        prompt: prompt || "",
    };
};
export default {};
