<template>
    <div v-if="products" class="bg-snow mw-900 mx-auto br-10 border-light shadow">
        <!--        <ProgressStats :stats="stats" />-->
        <!--        <pre>{{ sortedproductsArray }}</pre>-->
        <ProgressStats v-if="stats" :stats="stats" title="Product Progress" />
        <template v-for="item in sortedproductsArray?.slice(0, 10)">
            <SourceProgressItem :item="item" />
        </template>
    </div>
</template>
<script>
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import ProgressStats from "@/views/ProgressStats.vue";
import SourceProgressItem from "@/views/SourceProgressItem.vue";

export default {
    name: "productProgress",
    components: { SourceProgressItem, ProgressStats, BaseBadge, TimeStamp },
    props: {
        products: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        stats() {
            let stats = {
                products: this.sortedproductsArray?.length,
                links: this.sortedproductsArray?.filter(p => p.links)?.length,
                images: this.sortedproductsArray?.filter(p => p.products)?.length,
            };
            return stats;
        },
        sortedproductsArray() {
            let productsArray = this.products.progress;
            productsArray.sort((a, b) => b.updated - a.updated);
            return productsArray;
        },
    },
};
</script>
