<template>
    <div class="p-3 brand-bg">
        <div class="container">
            <h2>Settings</h2>
            <!--            <div class="mx-auto relative bg-snow br-20 overflow-hidden my-3"><StyleGuideAboutBrand /></div>-->
            <div class="content-container">
                <!--                <Example />-->
                <div class="grid-cols-12 grid gap-8 my-5">
                    <div class="col-span-12 md:col-span-6">
                        <SimpleInput v-model="name" label="Brand name" placeholder="Enter a your brand's name" />
                        <SimpleInput v-model="description" label="Description" placeholder="Enter a description" />
                        <SimpleInput v-model="categories" label="Categories" placeholder="Enter categories" />
                        <SimpleInput v-model="url" label="URL" placeholder="Enter a URL" />
                        <SimpleInput v-model="klaviyoAPI" label="Klaviyo API" placeholder="Enter your Klaviyo API Key" />
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <label class="fwsb mb-3">Logo</label>
                        <div class="f x gap-3">
                            <div class="relative y x block p-relative mw-250 aspect-square x border-light p-3 f aic jcc" style="height: 150px; min-height: 150px">
                                <div class="width-300">
                                    <brand-logo-component :color="'black'" class="mw-150 x inline-block" width="150px" />
                                </div>
                            </div>
                            <div class="mw-250 x aspect-square">
                                <UploadImage class="aspect-square" @image-uploaded="setLogo" />
                            </div>
                        </div>
                    </div>
                </div>
                <SimpleButton @click="saveSettings">Save settings</SimpleButton>
            </div>
            <!-- add update button-->
        </div>
    </div>
</template>

<script>
import styleGuideBrandMixin from "@/mixins/StyleGuideMixins/StyleGuideBrandMixin";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import UploadImage from "@/components/images/uploadImage.vue";
import SimpleButton from "@/views/SimpleButton.vue";
import BasicInput from "@/components/Admin/BasicInput.vue";
import SimpleInput from "@/views/SimpleInput.vue";
import Example from "@/components/shad/examples/playground/Example.vue";

export default {
    components: { Example, SimpleInput, BasicInput, SimpleButton, UploadImage, BrandLogoComponent, BaseInput },
    mixins: [StyleGuideMixin, styleGuideBrandMixin],
    data() {
        return {
            name: "",
            description: "",
            categories: "",
            url: "",
            klaviyoAPI: "",
        };
    },
    computed: {},
    watch: {
        styleGuide(val) {
            if (val) {
                this.name = val?.name;
                this.description = val?.description;
                this.categories = val?.categories;
                this.url = val?.url;
                this.klaviyoAPI = val?.klaviyoAPI;
            }
        },
    },
    async mounted() {
        this.$nextTick(() => {
            this.name = this.styleGuide.name;
            this.description = this.styleGuide.description;
            this.categories = this.styleGuide.categories;
            this.url = this.styleGuide.url;
            this.klaviyoAPI = this.styleGuide.klaviyoAPI;
        });
    },
    methods: {
        async setLogo(image) {
            console.log("UPLOADED", image);
            let logos = this.styleGuide?.logos || [];
            if (image) image.kind = "logo";
            this.updateStyleGuideProp("logos", [image, ...logos], true);
            console.log("logos", logos);
        },
        async saveSettings() {
            try {
                let updates = {};
                if (this.styleGuide.name !== this.name) updates.name = this.name;
                if (this.styleGuide.description !== this.description) updates.description = this.description;
                if (this.styleGuide.categories !== this.categories) updates.categories = this.categories;
                if (this.styleGuide.url !== this.url) updates.url = this.url;
                this.updateStyleGuideProp("klaviyoAPI", this.klaviyoAPI, true);
                this.$store.dispatch("styleGuide/updateBrandList", updates);
                this.$store.dispatch("styleGuide/saveStyleGuide");
            } catch (e) {
                console.error("failed to updateBrandList");
            }
        },
    },
};
</script>
