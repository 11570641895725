import initialize_messages from "@/mixins/ai/initialize_messages";

async function setupMessages(user, system, messageArray) {
    let messages = [];
    let sysM = null;
    let userM;
    if (system) {
        if (Array.isArray(system)) {
            sysM = sysMessage(system[0]);
        } else if (typeof system === "object") {
            sysM = system;
        } else if (typeof system === "string") {
            sysM = sysMessage(system);
        }
        messages.push(sysM);
    }

    if (Array.isArray(user)) {
        messages = user; // if 'user' is an array then it is assumed to be a list of messages including system messages
    } else if (typeof user === "object") {
        messages.push(user); // if 'user' is an object then it is assumed to be a message object
    } else if (typeof user === "string") {
        messages.push(userMessage(user)); // if 'user' is a string then it is assumed to be a message string
    } else {
        messages = [sysMessage(system), userMessage(user)];
    }

    if (messageArray) {
        messages = initialize_messages(messageArray);
    } else {
        messages = initialize_messages(messages); // trim messages over 30
    }
    return messages;
}

export default setupMessages;
