function updateProgress(store, name, title, text, completion, data) {
    const index = store.state.presentation.progress.findIndex(item => item.name === name);
    let object = store.state.presentation.progress[index];
    if (title) object.name = title;
    if (text) object.text = text;
    if (completion) object.completion = completion;
    if (data) object.data = data;
    store.dispatch("presentation/updateProgressStep", { index: index, payload: object });
}
export default updateProgress;
