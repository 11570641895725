import { globalFirestoreOptions } from "vuefire";
import { getFunctions } from "firebase/functions";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/firestore";
import { GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";

import firebase from "firebase/compat/app";
import { getFirestore, collection, onSnapshot, query, where, orderBy } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import axios from "axios";

const defaultConverter = globalFirestoreOptions.converter;
globalFirestoreOptions.converter = {
    toFirestore: defaultConverter.toFirestore,
    fromFirestore: (snapshot, options) => {
        const data = defaultConverter.fromFirestore(snapshot, options);
        // add anything custom to the returned object
        data.metadata = snapshot.metadata;
        return data;
    },
};

const apiKey = import.meta.env.VITE_FIREBASE_API;

const firebaseConfig = {
    apiKey: apiKey,
    authDomain: "ashermuse.firebaseapp.com",
    databaseURL: "https://ashermuse-default-rtdb.firebaseio.com",
    projectId: "ashermuse",
    storageBucket: "ashermuse.appspot.com",
    messagingSenderId: "478548386549",
    appId: "1:478548386549:web:bbd47b8cd46b9a9d511bbc",
    measurementId: "G-1685KM949D",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
// const messaging = firebase.messaging()

const customAxios = axios.create();

const firestore = getFirestore();
const db = firebaseApp.firestore();
const genreRef = collection(db, "genre");
const feed = collection(db, "feedItem");
const documents = collection(db, "documents");
const presentations = query(documents, where("documentType", "==", "presentation"));
const textDocs = query(documents, where("documentType", "!=", "presentation"));
const storyboards = query(documents, where("documentType", "==", "storyboard"));
// export const textDocs1 = query(documents, where('documentType', '!=', 'presentation')).orderBy('updated', 'asc');

const auth = firebase.auth();
const storage = getStorage(firebaseApp);
export default db;
const functions = getFunctions(firebaseApp);
const googleAuthProvider = new GoogleAuthProvider();

export { firestore, storage, getFunctions, httpsCallable, functions, collection, googleAuthProvider, genreRef, orderBy, query, feed, presentations, textDocs, documents, db, auth, storyboards, onAuthStateChanged };
