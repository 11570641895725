<template>
    <div class="px-3 py-2 sm:p-3">
        <dt v-if="number" class="text-sm font-normal text-capitalize text-gray-900">{{ number }}</dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div v-if="name" class="flex items-baseline text-2xl font-semibold text-indigo-600">
                {{ name }}
                <!--                <span v-if="previousStat" class="ml-2 text-sm font-medium text-gray-500">from {{ previousStat }}</span>-->
            </div>

            <!--            <div :class="[item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0']">-->
            <!--                <ArrowUpIcon v-if="item.changeType === 'increase'" class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />-->
            <!--                <ArrowDownIcon v-else class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />-->
            <!--                <span class="sr-only">{{ item.changeType === "increase" ? "Increased" : "Decreased" }} by</span>-->
            <!--                {{ item.change }}-->
            <!--            </div>-->
        </dd>
    </div>
</template>
<script>
export default {
    name: "Stat",
    props: {
        name: {
            type: String,
            default: "",
        },
        number: {
            type: Number,
            default: 0,
        },
        previousStat: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            change: 0,
            changeType: "",
        };
    },
    computed: {},
};
</script>
