<template>
    <!--   max-w-2xl  lg:max-w-7xl lg:gap-x-8-->
    <div v-if="images" class="mx-auto mt-6 sm:px-6 lg:grid lg:px-8" :class="{ 'lg:grid-cols-3': images.length === 4, 'lg:grid-cols-2': images.length < 4, 'lg:grid-cols-1': images.length < 3 }">
        <div v-for="(image, index) in images.slice(0, 1)" :key="`main-image-${index}`" class="border-light shadow relative aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
            <img :alt="image.alt" :src="matchImageToImagesArray(image).url" class="h-full w-full object-cover object-center" />
            <div :style="`background:${matchImageToImagesArray(image).backgroundColor}`" class="z-5 p-absolute p-fill"></div>
        </div>

        <!-- Secondary images display -->
        <div class="hidden lg:grid lg:grid-cols-1 lg:gap-y-8" v-if="images.length > 1">
            <div v-for="(image, index) in images.slice(1, 3)" :key="`secondary-image-${index}`" class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                <img :alt="image.alt" :src="matchImageToImagesArray(image).url" class="shadow h-full w-full object-cover object-center" />
                <div :style="`background:${matchImageToImagesArray(image).backgroundColor}`" class="shadow z-5 p-absolute p-fill"></div>
            </div>
        </div>

        <!-- Additional image display -->
        <div v-if="images.length >= 4" v-for="(image, index) in images.slice(4, 4)" class="shadow aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
            <img :alt="images.alt" :src="matchImageToImagesArray(image).url" class="h-full w-full object-cover object-center" />
            <div :style="`background:${matchImageToImagesArray(image).backgroundColor}`" class="z-5 p-absolute p-fill"></div>
            {{ matchImageToImagesArray(image).backgroundColor }}
        </div>
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "ProduceImageGrid",
    mixins: [styleGuideMixin],
    props: {
        images: {
            type: Array,
            required: true,
        },
    },
    methods: {
        matchImageToImagesArray(image) {
            let imagesArray = this.images;
            return imagesArray.find(img => img.originalURL === image.originalURL);
        },
    },
};
</script>
