let create_timeline_slide = {
    name: "create_timeline_slide",
    description: "Generate a timeline slide for a presentation.",
    parameters: {
        type: "object",
        properties: {
            internal_note_topic: {
                type: "string",
                description: "This is an internal note for the logic behind choosing this slide. Refer to the fact sheet.",
            },
            style: {
                type: "string",
                description: "The style of the slide's layout. This should always be timeline.",
                enum: ["timeline"],
            },
            mode: {
                type: "string",
                description: "The visual background of the slide",
                enum: ["dark", "light"],
            },
            title: {
                type: "string",
                description: "This is the slide's title. 2-5 words. Use the fact sheet",
            },
            body: {
                type: "string",
                description: "A very brief outline of what to cover here. 5 words. Example: 'this section should cover XYZ'",
                // description: "This is the slide's body. One paragraph. Markdown is supported.",
            },
            timeline: {
                description: "This is the timeline slide slide. Fill out the timeline items",
                type: "array",
                items: {
                    minItems: 5,
                    maxItems: 15,
                    type: "object",
                    properties: {
                        event: {
                            type: "string",
                            description: "Name of the event.",
                        },
                        description: {
                            type: "string",
                            description: "Detailed description of the event",
                        },
                        date: {
                            type: "string",
                            description: "The date of the timeline item",
                        },
                    },
                },
            },
        },
        required: ["title", "style", "mode", "body", "timeline"],
    },
};

export default create_timeline_slide;
