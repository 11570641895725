<template>
    <div v-if="tooltip && showTooltip" class="absolute z-10 p-1 bg-gray-800 text-white rounded f-11" style="transform: translateY(-150%)">
        {{ tooltip }}
    </div>
</template>
<script>
export default {
    name: "ToolTip",
    props: {
        showTooltip: {},
        tooltip: {},
    },
};
</script>
<style lang="scss">

$colors: "sky", "green", "red", "yellow", "orange", "purple", "gray", "blue", "indigo", "violet", "pink", "rose", "pink";
$bgColor: 100;
@each $color in $colors{
}

</style>