<template>
    <!--    <BaseButton label="get voices" size="md" style-type="primary" @click.prevent="getVoices()" />-->
    <div>
        <BaseDropdown ref="voicePreset" :inline="true" :options="voices.map(voice => ({ label: voice.name, value: voice }))" :modelValue="selectedVoice" class="mb-0" label="" placeholder="Select a voice preset" @update:modelValue="updateSelectedVoice($event)"></BaseDropdown>
        <!--  play/pause button  -->
        <BaseButton size="xxs" rounded style-type="secondary" :icon="playIcon" label="test" icon-only @click.prevent="togglePlayPause" />
    </div>
</template>

<script>
import BaseDropdown from "@/components/CoreUI/BaseDropdown.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "VoicePicker",
    components: { BaseButton, BaseDropdown },
    async mounted() {
        await this.getVoices();
        this.setSelectedVoice(this.voices[9]);
    },
    computed: {
        ...mapGetters("audio", ["voices", "selectedVoice", "audioPlayer", "isPlaying"]),

        playIcon() {
            if (this.isPlaying) {
                return "fas fa-pause";
            } else {
                return "fas fa-play";
            }
        },
    },
    methods: {
        ...mapActions("audio", ["getVoices", "playSelectedVoice", "convertTextToSpeech", "pauseAudioPlayer"]),
        ...mapMutations("audio", ["setSelectedVoice", "setIsPlaying", "setAudioPlayer"]),
        updateSelectedVoice(voice) {
            this.setSelectedVoice(voice);
            this.setAudioPlayer(null);
        },
        togglePlayPause() {
            if (this.isPlaying) {
                this.pauseAudioPlayer();
            } else {
                if (!this.selectedVoice) {
                    return;
                }
                this.playSelectedVoice();
            }
        },
    },
};
</script>
<style scoped>
textarea {
    overflow: hidden;
    resize: none;
    border: none;
    padding: 10px;
    min-height: unset !important;
}
</style>
