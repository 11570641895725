<template>
    <div class="space-y-1.5">
        <ul class="list-none">
            <li v-for="item in list" class="space-y-0.5">
                <a :href="`${item?.url ? item.url : item}`" class="text-sm text-blue-600 decoration-2 hover:underline font-medium dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                    <span v-if="item.text">{{ item.text }}</span>
                    <span v-else>{{ item.url || item }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "ChatSourcesLinks",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
