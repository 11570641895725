<template>
    <div>
        <div v-if="basicBrands?.length > 0" :class="{ 'px-3': !mobile }" class="br-10 py-5">
            <div class="text-center">
                <!--                <h2 class="fwb">Similar brands</h2>-->
                <div :class="{ 'pl-3': mobile }" class="f aic jcs gap-4 overflow-x-scroll scrollbar-hide">
                    <template v-for="(brand, index) in basicBrands" :key="brand.id">
                        <div :class="{ 'width-min-500 width-max-500': !mobile, 'width-min-200 width-max-200': mobile }" class="x">
                            <HomePageBrandButton v-if="brand?.url" :brand="brand" :random-style="randomDivStyle || {}" class="relative h-auto text-left" related scroll>
                                <div class="absolute top right p-3 z-5">
                                    <BaseButton icon="fas fa-times" icon-only size="xxs" @click.prevent="$store.dispatch('styleGuide/deleteStyleGuide', brand)"></BaseButton>
                                </div>
                            </HomePageBrandButton>
                        </div>
                    </template>
                    <!--                    <template v-for="brand in basicBrands.slice(0, 50)" :key="brand?.id">-->
                    <!--                                            <BrandCard :random-style="randomDivStyle" v-if="brand?.url && brand.name !== styleGuide?.name" class="mw-500 x flex-shrink-0" :brand="brand" />-->
                    <!--                    </template>-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BrandCard from "@/views/BrandCard.vue";
import webRequest from "@/mixins/ai/web_request";
import HomePageBrandButton from "@/views/HomePageBrandButton.vue";

export default {
    name: "SimilarBrandsCards",
    components: { HomePageBrandButton, BrandCard, BaseButton, BaseBadge },
    mixins: [styleGuideMixin],
    props: {
        categories: {
            type: Array,
            required: true,
        },
    },
    computed: {
        brandMatches() {
            return this.similarBrands.slice(0, 30).filter(brand => brand.name !== this.styleGuide.name);
        },
        basicBrands() {
            return this.similarBrands.filter(brand => {
                // Assuming each brand in similarBrands and loadedBrands has a 'url' property
                const isBrandLoaded = this.loadedBrands.some(loadedBrand => loadedBrand.url === brand.url);
                return !isBrandLoaded;
            });
        },
    },
    data() {
        return {
            similarBrands: [],
            loadedBrands: [],
        };
    },
    async mounted() {
        await this.getSimilarBrands();
    },
    methods: {
        async getSimilarBrands() {
            if (this.categories && this.categories.length > 0 && !this.similarBrands.length) {
                let obj = { categories: this.categories, strictMatch: true };
                if (this.user) obj.user = this.user;
                //  TODO fix auth
                let response = await webRequest("getBrands", { ...obj, from: "getSimilar" });
                let brands = await response.json();
                console.log("Similar Brands", brands);
                this.similarBrands = brands?.brands.filter(brand => brand.url && brand.name && this.styleGuide.name !== brand.name);
            }
        },
    },
};
</script>
