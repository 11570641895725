<template>
    <div class="py-3">
        <div>
            <strong class="text-capitalize" v-if="assistantName">{{ assistantName }}</strong>
            <strong class="text-capitalize" v-else-if="role">{{ role }}</strong>
            {{ assistantId }}
<!--            {{ currentAssistant }}-->
        </div>
        <div>
            <template v-for="item in content">
                <ThreadMessageContent :content="item" :message-id="messageId" :file-ids="fileIds" :thread-id="threadId" />
            </template>
        </div>
    </div>
</template>
<script>
import ThreadMessageContent from "@/views/Chat/ThreadMessageContent.vue";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
    name: "ThreadMessage",
    components: { ThreadMessageContent },
    props: {
        message: { type: Object, require: true, default: () => ({}) },
    },
    computed: {
        ...mapGetters("assistants", ["currentAssistant", "assistants"]),
        fileIds() {
            return this.message?.file_ids || [];
        },
        threadId() {
            return this.message?.thread_id || "";
        },
        assistantName() {
            let id = this.message?.assistant_id;
            let assistant = this.assistants.find(asst => asst.id === id);
            return assistant?.name || "";
        },
        object() {
            return this.message?.object || "";
        },
        messageId() {
            return this.message?.id || "";
        },
        assistantId() {
            return this.message?.assistant_id || "";
        },
        created() {
            if (this.message?.created_at) {
                //convert to moment with time ago logic
                return moment(this.message?.created_at).fromNow();

                // return this.message?.created_at || "";
            }
        },
        metadata() {
            return this.message?.metadata || "";
        },
        role() {
            return this.message?.role || "";
        },
        content() {
            return this.message?.content || [];
        },
        rest() {
            // return false;
            const message = this.message;
            let { role, content, ...rest } = message;
            return rest;
        },
        id() {
            return this.message.id;
        },
    },
};
</script>
