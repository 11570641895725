import chalk from "chalk";
window.consolef = function (foreground = "black", background = "white") {
    return `color: ${foreground};font-size:11px; font-family:Helvetica; background-color: ${background};border-radius:5px; margin-top:5px;margin-bottom:5px;border:1px solid rgba(0,0,0,0.2);padding: 3px 5px;width:100%;display:block;`;
};
window.consoletext = function (foreground = "black", background = "white") {
    return `color: ${foreground}; width:250px;font-size:13px; background-color: ${background};padding: 5px 5px;width:80%;max-width:250px;display:block;`;
    // font-family:Helvetica;
};
window.textSmall = function (foreground = "black", background = "white") {
    return `color: ${foreground};font-size:11px; font-family:Helvetica; background-color: ${background};border-radius:5px; margin-top:5px;margin-bottom:5px;padding: 3px 5px;width:100%;display:block;`;
};
window.simpleBold = function (foreground = "black", background = "white") {
    return `color: ${foreground};font-weight:bold; background-color: ${background};  margin-top:5px; margin-bottom:5px;padding: 2px 4px;display:inline-block;`;
};
// showConsoleLoader();

function logColor2(color = "", backgroundColor = "", arrayOfColors) {
    console.groupCollapsed(`%cContrasting Color`, `padding:2px 5px;border-radius:10px;color:${color};background: ${backgroundColor}`);
    console.log(`%c Text color: ${color}`, `color:${color}`);
    if (backgroundColor) console.log(`%cBackground Color: ${backgroundColor}`, `color:${backgroundColor}`);
    if (arrayOfColors) {
        arrayOfColors.forEach(c => {
            if (typeof c === "string") console.log(`%c${c}`, c);
            else if (typeof c === "object") {
                let bg = c.background || c.backgroundColor;
                console.log(`%c${c.color} + ${bg}`, `font-weight:bold;font-size:15px;color:${c.color};background:${bg};padding:2px 5px;border-radius:10px;`);
            }
        });
    }
    console.groupEnd();
}
function logColor({ color, background }) {
    if (background && typeof background === "string") {
        console.groupCollapsed(`%c${color} + ${background}`, `color: ${color}; background:${background}; padding:10px;`);
    } else {
        console.groupCollapsed(`%c${color}`, `color: white; background:${color}; padding:10px;`);
    }
    console.trace();
    console.groupEnd();
}
window.logColor = logColor;
window.logColor2 = logColor2;
window.fail = consolef("#520000", "#ffd0dc");
window.success = consolef("#002600", "#87ff42");
window.cht = consolef("#002600", "#2ab4ff");
window.warn = consolef("#ff7a00", "#fffc71");
window.info = consolef("darkblue", "#d0e8ff");
window.teal = consolef("#000813", "#46f0ff");
window.rose = consolef("#da0051", "#ffdcf1");
window.purple = consolef("#2b004f", "#d0c8ff");
window.logFirebase = textSmall("#ba3b05", "#fbcc7e");
window.yellow = consolef("#000813", "#fffc71");
window.green = textSmall("#000813", "#87ff42");
window.logId = textSmall("#666666", "#eeeeee");
window.txt = consoletext("#000", "");
window.bot = consolef("#c00000", "#dad6ff");
window.deck = consolef("#056262", "#d2ffd1");
window.hilite = simpleBold("#0a3c2c", "#d7ff9e");
window.hiliteError = simpleBold("#3c0a0a", "#ff9ed2");
window.boldLog = simpleBold("#000", "");
window.errorMessage = function (message) {
    let msg = { role: "error", content: message, kind: "error" };
    return msg;
};
window.logGroup = function (title, items, open) {
    return;
    if (!items) return;
    try {
        if (open) {
            console.group(`%c${title}`, green);
            console.trace();
        } else {
            console.groupCollapsed(`%c${title}`, green);
        }
        try {
            Object.entries(items).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    console.groupCollapsed(`%c${key}`, "color: #ff4500;", `${value.length} items`);
                    value.forEach(i => {
                        let stringToLog = "";
                        Object.entries(i).forEach(([k, v]) => {
                            stringToLog += `${k}: ${v} `;
                        });
                        console.log(stringToLog);
                    });
                    console.groupEnd();
                } else {
                    console.log(`%c${key}:`, "color: #ff4500;", value);
                }
            });
        } catch (e) {
            console.log(e);
            console.groupEnd();
        }
    } catch (e) {
        console.groupEnd();
    }
};
window.deepCopy = function (obj) {
    try {
        return JSON.parse(JSON.stringify(obj));
    } catch (e) {
        return obj;
    }
};

window.consoleTableReduced = function consoleTableReduced(obj, excludedKeys) {
    const newObj = { ...obj }; // make a shallow copy of the object
    for (const key of excludedKeys) {
        delete newObj[key]; // delete the excluded keys
    }
    console.table(newObj); // print the remaining object
};

const error = chalk.bold.red;
const warning = chalk.hex("#FFA500"); // Orange color
// consoleMixin.js
const style = {
    success: "color: #142d00; background-color: #77ff41; padding: 2px 5px; font-weight: bold; font-size: 11px;width: 100%;",
    warning: "color: #845204; background-color: #ffc107; padding: 2px 5px; font-weight: bold; font-size: 11px; width: 100%;",
    error: "color: #721c24; background-color: #f8d7da; padding: 2px 5px; font-weight: bold; font-size: 11px;width: 100%;",
    info: "color: #004085; background-color: #cce5ff; padding: 2px 5px; font-weight: bold; font-size: 11px;width: 100%;",
};
function log(type, ...messages) {
    if (style[type]) {
        console.log(`%c[${type.toUpperCase()}]:\n`, style[type], ...messages);
    } else {
        console.log(type, ...messages);
    }
}

function logGroup(type, groupTitle, ...messages) {
    if (style[type]) {
        console.groupCollapsed(`%c${type.toUpperCase()} - ${groupTitle}`, style[type]);
        messages.forEach(msg => console.log(msg));
        console.groupEnd();
    } else {
        console.group(groupTitle);
        messages.forEach(msg => console.log(msg));
        console.groupEnd();
    }
}
function consoleTableReduced(obj, excludedKeys) {
    const newObj = { ...obj }; // make a shallow copy of the object
    for (const key of excludedKeys) {
        delete newObj[key]; // delete the excluded keys
    }
    console.table(newObj); // print the remaining object
}

function logGroupCollapsed(type, groupTitle, ...messages) {
    if (style[type]) {
        console.groupCollapsed(`%c${type.toUpperCase()} - ${groupTitle}`, style[type]);
        messages.forEach(msg => console.log(msg));
        console.groupEnd();
    } else {
        console.group(groupTitle);
        messages.forEach(msg => console.log(msg));
        // console.groupEnd();
    }
}
function closeGroup() {
    console.groupEnd();
}

export default {
    methods: {
        $logGroupCollapsed(type, groupTitle, ...messages) {
            logGroupCollapsed(type, groupTitle, ...messages);
        },
        $logResponse(content, from = "streaming") {
            this.consoleSpecial(`[RESPONSE]:Text Completion - ${from}`, content, messages, "#77ff41", "#142d00");
        },
        $messageLog(messages, from = "streaming", details) {
            try {
                let lastMessage = messages[messages.length - 1];
                let lastMessageContent = lastMessage.content;
                this.consoleSpecial(`[BEGIN]:Text Completion - ${from}`, lastMessageContent, messages, "#41d0ff", "#00132d", details);
            } catch (e) {
                console.error(e);
            }
            return;
        },
        consoleObject(string, object, messages) {
            let background = "#d6d9fd";
            let textColor = "#211f2c";
            console.groupCollapsed(`%c${string}`, `background: ${background}; color: ${textColor}; padding:5px 7px;font-weight:bold; font-size:11px;`);
            if (messages) {
                messages.slice(-1).forEach(message => {
                    console.log(message.content, message.role);
                });
            }
            console.dir(object);
            console.groupEnd(string);
            return;
        },
        consoleChat(messages, kind) {
            if (kind === "filtered") {
                console.groupCollapsed("🟩 Chat (filtered)");
                console.table(messages, ["role", "content"]);
                console.groupEnd();
            } else {
                console.groupCollapsed("🟦 Chat (raw)");
                console.table(messages, ["role", "content"]);
                console.groupEnd();
            }
        },
        consoleSpecial(title, body, messages, background = "#41d0ff", textColor = "#00132d", details) {
            let bodyBackground = "#cdfffc";
            let bodyColor = "#00132d";
            let titleStyle = `color: ${textColor}; background-color: ${background}; padding:0px 7px;font-weight:bold; font-size:10px;`;
            let bodyStyle = `color: ${bodyColor}; background-color: ${bodyBackground}; padding:2px 3px;font-weight:normal; font-size:10px;`;
            console.groupCollapsed(`%c${title}\n%c${body}`, titleStyle, bodyStyle);
            if (details) {
                consoleTableReduced(details, ["messages"]);
                if (details.messages) {
                    console.table(details.messages);
                }
                console.log(details);
            }
            console.trace();
            console.groupEnd();
            return;
        },
        $log(type, ...messages) {
            log(type, ...messages);
        },
        $logGroup(type, groupTitle, ...messages) {
            logGroup(type, groupTitle, ...messages);
        },
        $closeGroup() {
            closeGroup();
        },
    },
};
