<template>
    <!--    <div class="f jcc gap-4">-->
    <div class="relative f aic jcc">
        <Transition appear name="scale-up">
            <div v-if="hovered" class="">
                <TransitionGroup :css="false" appear class="f aic jcc gap-3" name="toolbuttons" tag="div" @enter="onEnter" @leave="onLeave" @before-enter="onBeforeEnter">
                    <ToolButton v-for="(b, i) in buttonMap" :key="i" :data-index="i" :icon="b.icon" @click.prevent="b.clickHandler" @mouseenter="b.hoverHandler" />
                    <!--                    <ToolButton key="button2" :data-index="2" icon="trash" @click.prevent="removeSection" />-->
                    <!--                    <ToolButton key="button1" :data-index="1" icon="ellipsis-h" @mouseenter="showSettings" />-->
                    <!--                    <ToolButton icon="plus" @click.prevent="addSection" />-->
                    <!--                    <ToolButton key="button3" :data-index="3" icon="sparkles" @click.prevent="showEmailModal" />-->
                </TransitionGroup>
            </div>
        </Transition>
    </div>
    <!--    </div>-->
</template>
<script>
import { gsap } from "gsap";
import ToolButton from "@/components/generative_templates/Email/ToolButton.vue";

export default {
    name: "SectionToolButtons",
    components: { ToolButton },
    props: {
        hovered: {
            type: Boolean,
        },
    },
    computed: {
        buttonMap() {
            return [
                //
                { icon: "trash", clickHandler: this.removeSection },
                { icon: "ellipsis-h", clickHandler: this.showSettings, hoverHandler: this.showSettings },
                { icon: "sparkles", clickHandler: this.showEmailModal },
            ];
        },
    },
    methods: {
        removeSection() {
            this.$emit("remove-section");
        },
        showEmailModal() {
            this.$emit("show-email-modal");
        },
        showSettings() {
            this.$emit("show-settings");
        },
        onBeforeEnter(el) {
            gsap.set(el, { alpha: 0.0, y: 10 });
        },
        onEnter(el, done) {
            const delay = el.dataset.index * 0.1;
            gsap.to(el, {
                alpha: 1,
                y: 0,
                delay,
                duration: 0.5,
                ease: "back.inOut",
                onComplete: done,
            });
        },
        onLeave(el, done) {
            gsap.to(el, {
                alpha: 0,
                y: 10,
                duration: 0.5,
                ease: "back.inOut",
                onComplete: done,
            });
        },
    },
};
</script>
