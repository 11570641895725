<template>
    <div v-if="colors">
        <template v-if="mobile">
            <StyleGuideColorsMobile :colors="colors" :color-arrays="colorArrays" />
        </template>
        <template v-else>
            <UIWrapperText class="p-relative" title="Colors" section="colors">
                <!--                <template #left>-->
                <div class="f aic jcc" id="colorSection">
                    <div class="f gap-5 flex-wrap" v-if="colorArrays">
                        <div class="mb-4" v-for="item in colorArrays">
                            <div class="f gap-10 mx-auto x flex-wrap">
                                <template v-for="(value, key) in colors[item.id]" :key="key">
                                    <ColorPallet w="150" :color="value" class="p-relative col-span-2 md:col-span-1 flex-shrink-0" @save-color="saveColor($event, key)" />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                </template>-->
            </UIWrapperText>
        </template>
    </div>
</template>
<script>
import ColorPallet from "@/components/styleGuide/Colors/ColorPallet.vue";
import SortColorPicker from "@/components/styleGuide/Colors/SortColorPicker.vue";
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import StyleGuideColorsMobile from "@/components/styleGuide/Colors/StyleGuideColorsMobile.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";

export default {
    name: "StyleGuideColorsNew",
    components: { UIWrapperText, StyleGuideColorsMobile, ColorPallet, SortColorPicker, StyleGuideContainer },
    computed: {
        colorArrays() {
            let colors = [
                { name: "All", id: "all_colors" },
                // { name: "Background Colors", id: "bg_colors" },
                // { name: "Text Colors", id: "text_colors" },
                // { name: "Button colors", id: "button_bg" },
            ];
            if (this.$store.state.styleGuide?.styleGuide?.gradients?.length > 0) {
                colors.push({ name: "Gradients", id: "gradients" });
            }
            return colors;
        },
    },
    methods: {
        saveColor(value) {
            // this needs to recieve a color, find in in the arrays and change it.
            // Update the color in the colors object based on the index and arrayId
            this.colors[arrayId][index] = color;
        },
        sortString(id) {
            if (id !== "gradients") {
                if (this.$store.state.styleGuide.sortColors) return id + "_" + this.$store.state.styleGuide.sortColors;
            } else return "";
            // this.setStyleGuide(item);
        },
    },
    props: {
        colors: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
};
</script>
