<template>
    <transition name="fade-in" appear>
        <div v-if="gradientStart && gradientEnd" class="absolute bottom left right text-center z-1 transition-all animated" appear :style="gradientStyles"></div>
    </transition>
    <!--                                    <div-->
    <!--                                    class="absolute bottom left right text-center z-1"-->
    <!--                                    v-if="section.backgroundColor && slotProps.sections?.[index + 1]?.backgroundColor"-->
    <!--                                    :style="`margin-top:-250px!important;height: 500px; transform: translateY(50%); background: linear-gradient(transparent, ${section.backgroundColor}, ${slotProps.sections[index + 1].backgroundColor})`"></div>-->
</template>
<script>
export default {
    name: "SectionBlender",
    props: {
        index: {
            type: Number,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
        sections: {
            type: Array,
            required: true,
        },
        height: {
            type: [Number, String],
            default: 500,
        },
    },
    computed: {
        gradientStart() {
            return this.section?.backgroundColor;
        },
        gradientEnd() {
            if (this.index !== null) {
                let nextIndex = this.index + 1;
                return this.sections?.[nextIndex]?.backgroundColor;
            }
            return "black";
        },
        gradientStyles() {
            // return `margin-top:-${this.height / 2}px!important;height: ${this.height}px; transform: translateY(50%); background: linear-gradient(transparent, ${this.gradientStart} 25%, ${this.gradientEnd} 50%,transparent 100%)`;
            return `margin-top:-${this.height / 2}px!important;height: ${this.height}px; transform: translateY(75%); background: linear-gradient(${this.gradientStart} 0%, ${this.gradientEnd} 100%)`;
        },
    },
};
</script>
<style scoped lang="scss">
// animate height from 0 to 100%
.fade-in-enter-active {
    transition: all 0.5s ease;
}
.fade-in-enter {
    height: 0;
}
.fade-in-leave-active {
    transition: all 0.5s ease;
}
.fade-in-leave-to {
    height: 0;
}
</style>
