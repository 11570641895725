import streamRequest from "@/mixins/ai/stream_request";
import processContentFull from "@/mixins/ai/stream_tokens";
import handleFunctionResponse from "@/mixins/ai/handle_function_response";
import messageLogger from "@/mixins/ai/message_logger";

async function recieveStream(response, whileProcessing, model = "gpt-3.5-turbo", afterCompleting) {
    // if (!silent) console.time("processContent");

    const { messageContent, functionObject, role, messageObject } = await processContentFull(
        response,
        (token, function_object, function_arguments, messageObject) => {
            whileProcessing(token, function_object, function_arguments);
        },
        model,
        finalResponse => {
            let message_object = finalResponse.messageObject;
            if (finalResponse.finish_reason === "function_call") response.function_call = handleFunctionResponse(message_object.function_call);
            // messageLogger(message_object, from, requestData, requestData.messages, response, functions, message_object.role); // log the message
        },
    );
    if (functionObject && functionObject.name && functionObject.arguments) {
        functionObject.content = messageContent;
        response.function_call = functionObject;
    }

    // this.$forceUpdate();

    if (functionObject.arguments) {
        if (functionObject.arguments) functionObject.arguments = JSON.parse(functionObject.arguments);
        response.function_call = functionObject;
        afterCompleting(functionObject);
    } else {
        afterCompleting();
    }
}

export default recieveStream;
