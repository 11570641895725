<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="z-5 pointer-events-none fixed inset-0 flex items-end px-2 py-4 sm:items-start sm:p-6">
        <div class="flex w-full flex-col items-center space-y-2 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <!--            enter-from-class="translate-y-5 opacity-0 sm:translate-y-0 sm:translate-x-2"-->
            <TransitionGroup enter-active-class="transform swingInUp" leave-active-class="opacity-0 ease-back">
                <template v-for="(alert, index) in alerts" :key="randomId()">
                    <div :style="animatedList(index)" class="mw-300 border-light pointer-events-auto w-full overflow-hidden rounded-full bg-gray-900 shadow">
                        <div class="p-1">
                            <div class="f aic">
                                <div class="c-30 f aic jcc" v-if="alert.icon">{{ alert.icon }}</div>
                                <div class="mx-1 mb-0 flex-shrink-0 pt-1" v-else>
                                    <CheckCircleIcon v-if="alert.type && alert.type.includes('success')" aria-hidden="true" class="h-6 w-6 text-green-400" />
                                    <XMarkIcon v-else aria-hidden="true" class="h-6 w-6 text-red-500"></XMarkIcon>
                                </div>
                                <div class="w-0 flex-1">
                                    <p v-if="alert && alert.type" class="fwh text-capitalize line-height-smaller mb-0 text-gray-50">{{ alert.type }}</p>
                                    <p v-if="alert && alert.message" class="line-height-smaller o-8 f-13 mb-0 mb-1 text-gray-50">{{ alert.message }}</p>
                                </div>
                                <div class="ml-4 flex flex-shrink-0">
                                    <a class="inline-flex rounded-md bg-none text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" href="#" @click="removeAlert(alert)">
                                        <span class="sr-only">Close</span>
                                        <XMarkIcon aria-hidden="false" class="h-5 w-5" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { ref } from "vue";

const show = ref(true);
</script>
<script>
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";

export default {
    mixins: [documentManagementMixin],
    data() {
        // return {
        //     alerts: [],
        // };
    },
    watch: {
        alerts: {
            handler: function (val) {
                setTimeout(() => {
                    this.removeAlert(val[0]);
                }, 5000);
            },
            deep: true,
        },
    },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s;
    opacity: 1;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
