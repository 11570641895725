<template>
    <div class="f aic jcc gap-1">
        <span v-if="label" class="d-block fwsb f-15 text-gray-500">{{ label }}</span>
        <div style="position: relative; display: block">
            <label
                class="f aic jcc"
                :style="{
                    position: 'relative',
                    display: 'inline-block',
                    width: `${width}px`,
                    height: `${height}px`,
                }">
                <input type="checkbox" :checked="currentValue" @change="toggleSwitch" style="display: none" />

                <span
                    :style="{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: `${height / 2}px`,
                    }"
                    class="!shadow-inset"
                    :class="{ 'border border-solid border-green-500 bg-green-400 shadow-inset dark:border-green-400 dark:bg-green-500': currentValue, 'border-light border border-solid border-gray-200 bg-gray-100 dark:border-base-600 dark:bg-base-900': !currentValue }"
                    style="box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2) inset !important">
                    <!--                // backgroundColor: currentValue ? onColor : 'unset',-->
                    <!--                    class="switch-bg border-light bg-gray-200 dark:bg-base-900"-->
                </span>
                <span
                    class="switch-knob dark:shadow-inset"
                    style="box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4) !important"
                    :class="{ 'bg-snow dark:border dark:border-solid dark:border-base-300 dark:bg-base-400': currentValue, ' bg-snow dark:border-base-500 dark:bg-base-600': !currentValue }"
                    :style="{
                        position: 'absolute',
                        top: `${border}px`,
                        left: `${border}px`,
                        width: `${knobSize}px`,
                        height: `${knobSize}px`,
                        // backgroundColor: 'white',
                        borderRadius: '50%',
                        transform: currentValue ? `translateX(${width - knobSize - 2 * border}px)` : 'translateX(0)',
                        transition: 'transform 150ms ease-in-out',
                    }"></span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        vuexKey: {
            type: String,
            default: null,
        },
        vueKey: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            required: false,
        },
        width: {
            type: Number,
            default: 54,
        },
        height: {
            type: Number,
            default: 30.6,
        },
        border: {
            type: Number,
            default: 1.8,
        },
        onColor: {
            type: String,
            default: "#34D399",
        },
        offColor: {
            type: String,
            default: "#9CA3AF",
        },
        skipCookie: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        currentValue(newValue) {
            if (this.vuexKey) {
                if (!this.skipCookie) localStorage.setItem(this.vuexKey, newValue);
                if (this.vuexKey.includes(".") || this.vuexKey.includes("/")) {
                    const [moduleName, prop] = this.vuexKey.split(/[./]/);
                    this.$store.commit(`${moduleName}/updateToggleValue`, { key: prop, value: newValue });
                } else {
                    this.$store.commit("updateToggleValue", { key: this.vuexKey, value: newValue });
                }
            } else {
                if (!this.skipCookie) localStorage.setItem(this.vueKey, newValue);
            }
        },
    },
    mounted() {
        const savedValue = localStorage.getItem(this.vuexKey || this.vueKey);
        const parsedValue = savedValue === "true"; // Convert savedValue to boolean

        if (this.vuexKey) {
            if (this.vuexKey.includes(".") || this.vuexKey.includes("/")) {
                const [moduleName, prop] = this.vuexKey.split(/[./]/);
                this.$store.commit(`${moduleName}/updateToggleValue`, { key: prop, value: parsedValue });
            } else {
                this.$store.commit("updateToggleValue", { key: this.vuexKey, value: parsedValue });
            }
        } else {
            this.$emit("update:vueKey", parsedValue);
        }
    },

    computed: {
        knobSize() {
            return this.height - 2 * this.border;
        },
        currentValue() {
            if (this.vuexKey && ((this.vuexKey.length > 0 && this.vuexKey.includes(".")) || this.vuexKey.includes("/"))) {
                const [moduleName, prop] = this.vuexKey.split(/[./]/);
                return this.$store.state[moduleName][prop];
            } else if (this.vuexKey) {
                return this.$store.state[this.vuexKey];
            } else {
                return this.vueKey;
            }
        },
    },
    methods: {
        toggleSwitch() {
            const value = !this.currentValue;
            if (this.vuexKey && ((this.vuexKey.length > 0 && this.vuexKey.includes(".")) || this.vuexKey.includes("/"))) {
                const [moduleName, prop] = this.vuexKey.split(/[./]/);
                this.$store.commit(`${moduleName}/updateToggleValue`, { key: prop, value });
            } else if (this.vuexKey) {
                this.$store.commit("updateToggleValue", { key: this.vuexKey, value });
            } else {
                this.$emit("update:vueKey", value);
            }
        },
    },
};
</script>
