<template>
    <div>
        <!--        <pre>{{ run }}</pre>-->
        <template v-if="isToolCalls">
            <template v-for="tool in toolCalls">
                <div v-if="tool.type === 'code_interpreter'">
                    <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold mb-0">Code Interpreter</div>
                    <!--                {{ tool.code_interpreter }}-->
                    <chat-markdown class="p-0 !my-0" :content="`\`\`\`python\n${tool?.code_interpreter?.input}\n\`\`\``" />
                    <template v-for="{ image, logs } in tool.code_interpreter.outputs">
                        <template v-if="image">{{ image }}</template>

                        <chat-markdown v-if="logs" class="p-0 !my-0" :content="`\`\`\`python\n${logs}\n\`\`\``" />
                    </template>
                </div>
                <div v-else-if="tool.type === 'retrieval'">
                    <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold mb-0">Retrieval</div>
                    <div class="inline-flex fc gap-1 py-1">
                        {{ tool }}
                    </div>
                </div>
                <div v-else-if="tool.type === 'function'">
                    <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold mb-0">Function</div>
                    <div class="inline-flex fc gap-1 py-1">
                        <div v-if="tool?.function?.name" class="text-indigo-500 bg-snow b-5 text-sm font-bold border-light px-3 gap-3 f aic jcs br-20 py-1">
                            <i class="far fa-cog text-indigo-400"></i>
                            {{ tool.function.name }}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold mb-0">Thread Run Detail</div>
                    <div class="inline-flex fc gap-1 py-1">
                        {{ tool }}
                    </div>
                </div>
            </template>
        </template>
        <template v-else>
            <!--            <div v-if="!" class="uppercase tracking-wide text-sm text-indigo-500 font-semibold mb-0">Thread Run Details</div>-->
            <!--            {{ runStep }}-->
        </template>
    </div>
</template>
<script>
import ChatCodeRenderer from "@/components/chat/specialMessages/ChatCodeRenderer.vue";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import FunctionPill from "@/components/chat/messages/FunctionPill.vue";

export default {
    name: "ThreadRunStep",
    components: { FunctionPill, ChatMarkdown, ChatCodeRenderer },
    props: {
        runStep: {
            type: Object,
            default: () => ({}),
        },
        run: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        // mape computed propeerties for the structure above
        id() {
            return this.runStep?.id || "";
        },
        isToolCalls() {
            return this.runStep?.type === "tool_calls";
        },
        isMessage() {
            return this.runStep?.object === "thread.message";
        },
        object() {
            return this.runStep?.object || "";
        },
        createdAt() {
            return this.runStep?.created_at || "";
        },
        runId() {
            return this.runStep?.run_id || "";
        },
        assistantId() {
            return this.runStep?.assistant_id || "";
        },
        threadId() {
            return this.runStep?.thread_id || "";
        },
        type() {
            return this.runStep?.type || "";
        },
        status() {
            return this.runStep?.status || "";
        },
        cancelledAt() {
            return this.runStep?.cancelled_at || "";
        },
        completedAt() {
            return this.runStep?.completed_at || "";
        },
        expiresAt() {
            return this.runStep?.expires_at || "";
        },
        failedAt() {
            return this.runStep?.failed_at || "";
        },
        lastError() {
            return this.runStep?.last_error || "";
        },
        stepDetails() {
            return this.runStep?.step_details || "";
        },
        toolCalls() {
            return this.runStep?.step_details?.tool_calls || "";
        },
        codeInterpreter() {
            return this.runStep?.code_interpreter || "";
        },
        input() {
            return this.runStep?.input || "";
        },
        outputs() {
            return this.runStep?.outputs || "";
        },
        image() {
            return this.runStep?.image || "";
        },
    },
};
</script>
