<template>
    <div>
        <h4 v-if="answer_box">
            <AnimatedText key="a" :text="`${answer_box.location}`"></AnimatedText>
        </h4>
        <div>
            <p><AnimatedText key="bd" text="Today's weather"></AnimatedText></p>
            <div class="f x jcs aic mb-3">
                <div class="x animated fadeInUpSmooth duration-5 d-1 width-max-100">
                    <img class="x width-max-75" :src="answer_box.thumbnail" alt="Weather Thumbnail" />
                </div>

                <div class="x animated fadeInUpSmooth duration-5 d-2 width-max-100 text-left">
                    <p class="mb-0 text-3xl"><AnimatedText key="c" :text="answer_box.temperature + '°'"></AnimatedText></p>
                    <h6 class="mb-0">{{ answer_box.unit }}</h6>
                </div>
                <div class="x animated fadeInUpSmooth duration-5 d-3 text-left">
                    <p class="animated fadeInUpSmooth duration-5 mb-0 text-xs"><AnimatedText key="cs" :text="`Precipitation: ${answer_box.precipitation}`"></AnimatedText></p>
                    <p class="animated fadeInUpSmooth duration-5 d-2 mb-0 text-xs">Humidity: {{ answer_box.humidity }}</p>
                    <p class="animated fadeInUpSmooth duration-5 d-3 mb-0 text-xs">Wind: {{ answer_box.wind }}</p>
                </div>
            </div>
        </div>
        <div class="x mb-5">
            <h4>This Week's Forecast</h4>
            <div class="f x jcb gap-3">
                <div v-for="(day, index) in answer_box.forecast" class="animated fadeInUpSmooth duration-5 text-center" :class="`d-${index * 3}`" :key="day">
                    <img class="animated fadeInUpSmooth duration-5 width-max-30" :src="day.thumbnail" alt="Weather Thumbnail" />
                    <h6 class="animated fadeInUpSmooth duration-5 d-3 f-11 mb-0">{{ day.day }}</h6>
                    <p class="animated fadeInUpSmooth duration-5 d-5 mb-0 text-sm"><AnimatedText key="c" :text="`${day.temperature.low} – ${day.temperature.high}°`"></AnimatedText></p>
                    <!--                    <p class="fwb mb-0 text-xs mw-100">{{ day.weather }}</p>-->
                    <!--                    <h6 class="fwn mb-0 text-xs f-11">☔️ {{ day.precipitation }}</h6>-->
                    <!--                    <h6 class="fwn mb-0 text-xs f-11">Humidity: {{ day.humidity }}</h6>-->
                    <!--                    <h6 class="fwn mb-0 f-11">💨 {{ day.wind }}</h6>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AnimatedText from "@/components/CoreUI/animatedText.vue";

export default {
    name: "WeatherResults",
    components: { AnimatedText },
    data() {
        return {
            data: {
                // Your data goes here...
            },
        };
    },
    props: {
        answer_box: {
            type: Object,
            default: () => {},
            required: false,
        },
    },
};
</script>
