<template>
    <!--    <div>-->
    <!--        {{ newColor }}-->
    <div class="relative bg-snow/10" :class="{ 'bg-gray-400/10': showDelete }">
        <!--        <div class="relative x aspect-w-1 aspect-h-1 overflow-hidden border-light br-20">-->
        <div ref="svgItem" class="x d-block svgPreview y" v-html="svg"></div>
        <!--        </div>-->
        <div class="p-absolute top right p-3" v-if="showDelete">
            <BaseButton icon-only rounded size="xxs" style-type="secondary" icon="fa fa-times" @click.prevent="deleteSVG(svg)"></BaseButton>
        </div>
    </div>
    <!--    </div>-->
</template>
<script>
import chroma from "chroma-js";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
export default {
    name: "svgPreview",
    components: { BaseButton },
    props: {
        svg: {},
        color: {
            type: String,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
        widthAdjustment: {
            type: String,
            default: "100%",
        },
    },
    data() {
        return {
            newColor: null,
        };
    },
    watch: {
        color(val) {
            this.assignColor();
        },
    },
    methods: {
        async deleteSVG(image) {
            console.log(image);
            await this.$store.dispatch("styleGuide/deleteSVG", image);
        },
        svgColor() {
            if (this.color) {
                try {
                    let newColor = this.color.replace("!important", "").replace(";", "") || "";
                    let color = chroma(newColor).css();
                    this.newColor = color;
                    return this.newColor;
                } catch (e) {
                    console.error("svg color broken", this.color);
                }
            } else {
                return this.$store.state.styleGuide.styleGuide.baseColor.replace("!important", "").replace(";", "") || "";
            }
        },
        assignColor() {
            this.newColor = this.svgColor();
            this.$nextTick().then(() => {
                const svgElement = this.$refs.svgItem.querySelector("svg");
                if (svgElement) {
                    svgElement.style.fill = this.newColor;

                    let paths = svgElement.querySelectorAll("path");
                    paths.forEach(path => {
                        // let color = chroma(this.color).rgb();
                        path.style.fill = this.newColor;
                        // path.style.fill = 'red';
                    });
                }
            });
        },
    },
    mounted() {
        this.assignColor();
    },
};
</script>
<style lang="scss">
.svgPreview {
    svg {
        width: 100%;
        height: auto;
        //fill: red !important;

        path {
            //fill: red; // TODO: this works but the $ref code does not. Fix the $ref code.

            d {
                //fill: red !important;
            }
        }
    }
}
</style>
