// filters.js
import moment from "moment";
export const prettyObject = object => {
    let template = ``;
    Object.keys(object).forEach((key, value) => {
        let objHtml;
        if (object[key] && typeof object[key] === "object") {
            objHtml = `<div class="d-block" data-toggle="collapse" aria-expanded="true"><span class="fwb mr-2">${key}:</span></div>`;
            if (Object.keys(object[key].length > 0)) {
                Object.keys(object[key]).forEach((childKey, value) => {
                    var childObjHtml = `<div  class=" d-block ml-2 f-15 border-left pl-3"><span class="fwb mr-2">${childKey}:</span> ${object[key][childKey]}</div>`;
                    objHtml = objHtml + childObjHtml;
                });
            }
        } else {
            objHtml = `<div class="d-block"><span class="fwb mr-2">${key}:</span> ${object[key]}</div>`;
        }
        template = template + objHtml;
    });
    return template;
};
export const prettyObjectNew = (object, keysToHide = [], title) => {
    const TYPES = ["array", "object"];

    const isObjectOrArray = value => value && TYPES.includes(typeof value) && (value.constructor === Object || Array.isArray(value));

    const generateIndentation = depth => `<span class="indentation" style="margin-left: ${depth * 20}px;"></span>`;

    const generateKeyValueLine = (key, value, depth, isObjectOrArray) => {
        const indentation = generateIndentation(depth);
        const valueType = isObjectOrArray ? ` (${Array.isArray(value) ? "Array" : "Object"})` : "";
        const displayKey = `<span class="key">${key}${valueType}:</span> `;
        const displayValue = isObjectOrArray ? "" : `<span class="value">${value}</span>`;
        return `<div class="line">${indentation}${displayKey}${displayValue}</div>`;
    };

    const createTemplate = (obj, depth = 0) => {
        let template = "";

        for (const [key, value] of Object.entries(obj)) {
            if (keysToHide.includes(key)) continue;

            const isValueObjectOrArray = isObjectOrArray(value);
            template += generateKeyValueLine(key, value, depth, isValueObjectOrArray);

            if (isValueObjectOrArray) {
                template += createTemplate(value, depth + 1);
            }
        }

        return template;
    };

    let result = createTemplate(object);

    if (title) {
        result = `<div class="title">${title}</div>${result}`;
    }

    return result;
};

// export const prettyObjectNew = (object, keysToHide = [], title) => {
//     let template = ``;
//     if (title) {
//         template += `<div class="d-block x border-bottom pb-2"><span class="fwb mr-2 text-lg">${title}</span></div>`;
//     }
//     const createTemplate = (obj, depth = 0) => {
//         const marginLeft = depth > 0 ? " ".repeat(depth * 2) : ""; // No indentation for top-level items
//
//         for (const [key, value] of Object.entries(obj)) {
//             let isObj = false;
//             let array = Array.isArray(obj);
//             let types = [];
//             if (obj && typeof obj === "object") {
//                 isObj = true;
//             }
//             let valueArray = false;
//             if (Array.isArray(value)) {
//                 types.push("Value is array");
//                 valueArray = true;
//                 // types.push(JSON.stringify(value));
//             } else if (value && typeof value === "object") {
//                 valueArray = false;
//                 types.push("Value is object");
//                 // types.push(JSON.stringify(value));
//             }
//
//             let typesString = types.join(", ");
//
//             let widthMax;
//
//             if (array && depth > 0) {
//                 widthMax = "width-max-25 x ";
//             }
//             if (depth == 0) {
//                 if (array) {
//                     widthMax = "width-max-100 x ";
//                 } else if (isObj) {
//                     widthMax = "text-left width-max-100 x";
//                 } else {
//                     widthMax = "width-max-100 x ";
//                 }
//             } else if (depth == 1) {
//                 if (array) {
//                     widthMax = "w-5 text-right ml-3 ";
//                 } else if (isObj) {
//                     widthMax = "width-max-100 x text-right ml-3 ";
//                 } else {
//                     widthMax = "width-max-100 x "; // Different widths for arrays and objects
//                 }
//             } else if (depth === 2) {
//                 if (array) {
//                     widthMax = "text-left w-5";
//                 } else if (isObj) {
//                     widthMax = "text-left";
//                 } else {
//                     widthMax = "text-left x";
//                 }
//             } else {
//                 widthMax = "text-left";
//             }
//             let fontSize = "";
//             if (depth === 0) {
//                 fontSize = "";
//             } else if (depth === 1) {
//                 fontSize = "f-17";
//             } else if (depth === 2) {
//                 fontSize = "f-15";
//             } else {
//                 fontSize = "f-13";
//             }
//
//             if (keysToHide.includes(key)) continue; // Skip keys to hide
//
//             const displayKey = Array.isArray(obj) ? `${key}:` : `${key}:`; // Don't display keys for arrays
//
//             if (value && typeof value === "object") {
//                 if (depth === 0) {
//                     let string = "";
//                     if (isObj && !valueArray) {
//                         string = `<span class="f-11">${typesString}</span>`;
//                     }
//                     template += `<div class="d-block my-3 ${fontSize} ${widthMax} f fc" style="margin-left: ${marginLeft}px;">
//                                      <span class="fwb mr-2">${displayKey}</span>
//                                 </div>`;
//                 } else if (depth > 0) {
//                     let string = "";
//                     if (isObj && !valueArray) {
//                         string = `<span class="f-11">${typesString}</span>`;
//                     }
//                     template += `<div class="d-block my-3 pb-2 ${fontSize} ${widthMax}" style="margin-left: ${marginLeft}px;">
//                                     <span class="fwb mr-2">${displayKey}</span>
//                                 </div>`;
//                 }
//                 createTemplate(value, depth + 1);
//             } else if (value && typeof value === "string") {
//                 const displayValue = value !== null ? value : ""; // Handle null values
//                 let color = "";
//                 if (displayValue === "") {
//                     color = "opacity-30";
//                 }
//                 template += `<div class="f ais jcs my-2 ${fontSize} ${color}" style="margin-left: ${marginLeft}px">
//                                 <div class="fwb mr-2 text-left break-words block ${widthMax}">${displayKey}</div>
//                                 <div class="d-block">${displayValue}</div>
//                             </div>`;
//             } else {
//                 const displayValue = value !== null ? value : ""; // Handle null values
//                 let color = "";
//                 if (displayValue === "") {
//                     color = "opacity-30";
//                 }
//                 template += `<div class="f ais jcs my-2 ${fontSize} ${color}" style="margin-left: ${marginLeft}px">
//                                 <div class="fwb mr-2 ${widthMax} text-right break-words block">${displayKey}</div>
//                                 <div class="d-block">${displayValue}</div>
//                             </div>`;
//             }
//         }
//     };
//
//     createTemplate(object);
//     return template;
// };

export const timeAgo = date => {
    moment.updateLocale("en", {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "seconds",
            ss: "%ss",
            m: "a minute",
            mm: "%dm",
            h: "an hour",
            hh: "%dh",
            d: "a day",
            dd: "%dd",
            M: "a month",
            MM: "%dM",
            y: "a year",
            yy: "%dY",
        },
    });
    let relDate = moment(date, "YYYYMMDD");
    var currentTime = moment();
    var minutes = currentTime.diff(relDate, "minutes");
    let text;
    // If less than 60 minutes have passed, display the timestamp in a relative format
    if (minutes < 120) {
        var timeAgoString = relDate.fromNow();
        text = timeAgoString;
    } else if (minutes < 180) {
        var timeAgoString = relDate.fromNow();
        var dateString = relDate.format("hh:mm a");
        // text = timeAgoString+' '+dateString;
        text = dateString;
    } else if (minutes < 1440) {
        var timeAgoString = relDate.fromNow();
        var dateString = relDate.format("hh:mm a");
        text = "Today at " + dateString;
    } else {
        var dateString = relDate.format("MMM D, YYYY");
        text = dateString;
    }
    return text;
    // return relDate;
};
