const openAIKey = import.meta.env.VITE_OPENAI_API;
export default {
    computed: {},
    methods: {
        async uploadAudio() {
            if (!this.recordedChunks.length) return;

            const audioBlob = new Blob(this.recordedChunks, { type: "audio/mp4" });

            try {
                const headers = {
                    Authorization: `Bearer ${openAIKey}`,
                };
                const formData = new FormData();
                formData.append("file", audioBlob, "audio.mp4");
                formData.append("model", "whisper-1");

                console.log("Submitting transcription request");
                const response = await fetch("https://api.openai.com/v1/audio/transcriptions", {
                    method: "POST",
                    headers: headers,
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error(`Failed to process the file upload, status: ${response.status}`);
                }

                const data = await response.json();
                console.log("Transcription response:", data);
                this.processTranscription(data);
            } catch (error) {
                console.error("Error processing the file upload:", error.message);
            }
        },
        async transcribeAudio(file) {
            const headers = {
                Authorization: `Bearer ${openAIKey}`,
            };
            const formData = new FormData();
            formData.append("file", file, "audio.mp4");
            formData.append("model", "whisper-1");

            try {
                console.log("Submitting transcription request");
                const response = await fetch("https://api.openai.com/v1/audio/transcriptions", {
                    method: "POST",
                    headers: headers,
                    body: formData,
                });
                const data = await response.json();
                console.log("Transcription response: ", data);
                this.processTranscription(data);
            } catch (error) {
                console.error("Error transcribing audio:", error);
            }
        },
    },
};
