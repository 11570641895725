<template>
    <div :ref="`slide-sm-${id}${index}`" class="x f br-5 br-5 aic jcc overflow-hidden bg-base-900" style="height: 0px; padding-bottom: 60%">
        <img v-if="slide?.imageURL || slide?.images?.[0]" :ref="`image-sm-${id}${index}`" :src="slide.imageURL || slide.images[0]" class="p-absolute p-fill x y o-cover o-2" style="mix-blend-mode: luminosity" />
        <div class="p-absolute p-fill f aic jcc">
            <div :ref="`text-sm-${id}${index}`" class="z-3 p-3 text-center">
                <p v-if="slide?.title" class="fwb line-height-smaller f-13 mb-0">{{ slide.title }}</p>
                <p v-if="slide?.body" class="o-8 f-11 mb-0" style="font-size: 8px">{{ slide.body }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import { mapGetters } from "vuex";
export default {
    name: "SlideOtherMini",
    mixins: [SlideStyleMixin],
    props: {
        index: {},
        slide: {},
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
    },
    mounted() {
        // if (this.document?.theme) {
        //     let t = this.document?.theme?.colors || false;
        //     if (t) this.textTheme();
        // }
    },
    created() {
        this.id = this.randomId();
    },
};
</script>
