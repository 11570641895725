<!-- SimpleInput.vue -->
<template>
    <div>
        <label v-if="label" :for="id" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
        <div class="mt-2">
            <input
                :id="id"
                :autocomplete="autocomplete"
                :required="required"
                :type="type"
                :value="modelValue"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:border-black focus:outline-none focus:ring-black-600 sm:text-sm sm:leading-6"
                @input="$emit('update:modelValue', $event.target.value)" />
        </div>
    </div>
</template>

<script>
export default {
    name: "SimpleInput",
    props: {
        id: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        required: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "text",
        },
    },
    emits: ["update:modelValue"],
};
</script>
