import addImagesToQueue from "@/mixins/ai/add_images_to_que";
import gptError from "@/mixins/ai/gpt_error";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import webRequest from "@/mixins/ai/web_request";

async function googleImageSearch(store, query, name, location, limit) {
    // if (store.state.presentation.queue.loading) return;

    updateQueueProp(store, "loading", true);
    console.groupCollapsed(`%c⚡️ Google Image Search`, purple, query);
    console.log(query);
    console.groupEnd();
    try {
        let request = { query: query.replace("logo", "") + " images" };
        try {
            if (location?.location) {
                request.location = location.location;
            }
        } catch (error) {
            console.error(error);
        }
        const response = await webRequest("searchWeb", request);
        if (!response.ok) {
            console.error("ERROR", response);
        }

        const data = await response.json();
        let images = data.images || data;
        // window.images = images;
        if (images) {
            if (limit) addImagesToQueue(store, images.slice(0, limit));
            else addImagesToQueue(store, images);
        }
        if (limit) return images.slice(0, limit);
        else return images;

        // return data;
    } catch (error) {
        console.error(error);
        console.error("Error fetching searchApi2:", gptError(error));
    }
}

export default googleImageSearch;
