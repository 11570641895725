// function getExtension(url = "") {
//     let path = url.split("/").pop();
//     path = path.split("?").shift(); // Remove query parameters
//     let extension = path.split(".").pop();
//     if (extension.includes("?")) extension = extension.split("?")[0]; // Remove query parameters
//     if (extension.includes("&")) extension = extension.split("&")[0]; // Remove query parameters
//     if (extension.includes("jpg")) extension = "jpg";
//     else if (extension.includes("jpeg")) extension = "jpeg";
//     else if (extension.includes("png")) extension = "png";
//     else if (extension.includes("gif")) extension = "gif";
//     else if (extension.includes("webp")) extension = "webp";
//     else if (extension.includes("svg")) extension = "svg";
//     extension = `.${extension}`;
//     // now return {url,extension} after reassumbling the url
//     let finalURL = url.split(path).shift() + path;
//     return { extension, finalURL };
// }

function getExtension(url = "") {
    let path = url.split("/").pop();
    path = path.split("?").shift(); // Remove query parameters before extension extraction
    let extension = path.split(".").pop();
    // No need for further checks as we just need the extension's format
    return `.${extension}`; // Ensure dot prefix for consistency
}

function resizeImage(image, size, mode = "cover") {
    // Adjust mode based on transparency requirement
    if (image.transparent) mode = "contain";

    // Helper function for non-Shopify images
    const applyNonShopifyTransformations = (url, size, mode) => {
        let resizeParams;
        if (typeof size === "string" || typeof size === "number") {
            resizeParams = `width=${size},height=${size},fit=${mode},gravity=center`;
        } else if (size.width && size.height) {
            resizeParams = `width=${size.width},height=${size.height},fit=${mode},gravity=center`;
        }
        // Directly replace known placeholders without iterating
        return url.replace("Original", resizeParams).replace("public", resizeParams);
    };

    // Shopify image handling
    if (image.url.includes("cdn.shopify.com") || image.url.includes("cdn/shop")) {
        const extension = getExtension(image.url);
        const baseURL = image.url.split("?")[0]; // Clean URL from parameters
        const cleanURL = baseURL.split(".").slice(0, -1).join("."); // Remove extension

        let dimensions;
        if (typeof size === "string" || typeof size === "number") dimensions = `?width=${size}&height=${size}`;
        else if (size.width && size.height) {
            if (mode === "cover") dimensions = `?width=${size.width}&height=${size.height}`;
            else dimensions = `?width=${size.width}`;
        }

        // Initialize resizeSegment with dimensions
        let resizeSegment = `${dimensions}`;

        // Apply mode logic for Shopify images
        switch (mode) {
            case "fit":
                resizeSegment += "_fit";
                break;
            case "contain":
                resizeSegment += "";
                break;
            case "cover":
                resizeSegment += "&crop=center";
                // resizeSegment += "_crop";
                break;
            case "fill":
                resizeSegment += "_fill";
                break;
            case "scale":
                resizeSegment += "_scale";
                break;
            case "pad":
                resizeSegment += "_pad";
                break;
            default:
                resizeSegment += "_crop_center"; // Default to cover behavior
        }

        return `${cleanURL}${extension}${resizeSegment}`;
    } else {
        // If not a Shopify image, try alternative resizing logic before returning
        return applyNonShopifyTransformations(image.url, size, mode);
    }
}

export default resizeImage;
