<template>
    <div ref="markdownContent" class="presentation-markdown-it-container x">
        <div class="" v-html="html"></div>
        <!--            markdown-content-->
    </div>
</template>
<script>
import Prism from "prismjs";
import ClipboardJS from "clipboard";
import "prismjs/plugins/toolbar/prism-toolbar.min.js";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min.js";
import "prismjs/plugins/toolbar/prism-toolbar.css";
import "prismjs/components/prism-javascript.js";
import "prismjs/components/prism-css.js";
import "prismjs/themes/prism.css";
import MarkdownIt from "markdown-it";
import mdAbbr from "markdown-it-abbr";
import mdAnchor from "markdown-it-anchor";
// import mdToc from "markdown-it-table-of-contents";
import mdEmoji from "markdown-it-emoji";
import mdFootnote from "markdown-it-footnote";
import mdIns from "markdown-it-ins";
import mdMark from "markdown-it-mark";
import mdSub from "markdown-it-sub";
import mdSup from "markdown-it-sup";
import mdTaskLists from "markdown-it-task-lists";
import { createApp } from "vue";

// import { Vue } from "vue";
// import mdContainer from "markdown-it-container";

import Cm from "@/components/CoreUI/cm.vue";
import ChatCodeRenderer from "@/components/chat/specialMessages/ChatCodeRenderer.vue";
import chatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import newChatMixin from "@/mixins/Chat/newChatMixins";
import TaskManager from "@/views/Chat/TaskManager.vue";
import ChatMapCard from "@/components/chat/specialMessages/ChatMapCard.vue";

export default {
    components: { Cm, ChatCodeRenderer, TaskManager },
    mixins: [newChatMixin, chatCommands],
    props: {
        content: {
            type: String,
            default: "",
            required: true,
        },
        message: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dynamicComponent: null,
            currentAction: null,
            buttonText: "Copy code",
        };
    },
    computed: {
        html() {
            const md = new MarkdownIt({
                linkify: true,
                typographer: false,
            })
                .use(mdAnchor, {
                    // anchorOptions
                })
                .use(mdFootnote)
                .use(mdEmoji)
                .use(mdSub)
                .use(mdSup)
                .use(mdMark)
                .use(mdIns)
                .use(mdAbbr)
                .use(mdTaskLists, {
                    label: true,
                    enabled: true,
                    labelAfter: true,
                    className: "task-list",
                });
            // .use(markdownItMermaid)
            // .use(markdownItLists)
            this.$forceUpdate();

            md.renderer.rules.fence = (tokens, idx, options, env, self) => {
                // 1. Define HTML part
                Prism.languages.vue = Prism.languages.extend("markup", {});

                // 2. Define JavaScript part
                Prism.languages.insertBefore("vue", "tag", { ...Prism.languages.javascript });

                // 3. Define CSS part
                Prism.languages.insertBefore("vue", "javascript", { ...Prism.languages.css });

                // 4. Define other languages like SCSS (optional)
                Prism.languages.insertBefore("vue", "javascript", { ...Prism.languages.scss });

                // 5. Customize specific Vue properties, components, and patterns (if needed)
                Prism.languages.vue["v-attribute"] = {
                    pattern: /v-(bind|on|slot|model|if|else|else-if|for|text|html|cloak|once|pre|is|staticClass)\b/,
                    inside: { ...Prism.languages.vue },
                };

                const token = tokens[idx];
                let langName = token.info.trim().split(/\s+/g)[0];
                let lang = Prism.languages[langName] || Prism.languages.markup;

                const code = token.content.trim();

                // Determine if code block is a Vue.js block or if the language is already set to 'vue'
                if (code.includes("<template>") || langName === "vue") {
                    // langName = "vue";
                    lang = Prism.languages.html;
                }

                const html = Prism.highlight(code, lang, langName);
                return this.fixedHTML(langName, html, code);
            };
            const defaultLinkRender =
                md.renderer.rules.link_open ||
                function (tokens, idx, options, env, self) {
                    return self.renderToken(tokens, idx, options);
                };

            md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
                const aIndex = tokens[idx].attrIndex("target");

                if (aIndex < 0) {
                    tokens[idx].attrPush(["target", "_blank"]);
                } else {
                    tokens[idx].attrs[aIndex][1] = "_blank";
                }

                const relIndex = tokens[idx].attrIndex("rel");
                if (relIndex < 0) {
                    tokens[idx].attrPush(["rel", "noopener noreferrer"]);
                } else {
                    tokens[idx].attrs[relIndex][1] = "noopener noreferrer";
                }

                return defaultLinkRender(tokens, idx, options, env, self);
            };
            return md.render(this.content);
        },
    },
    watch: {
        content(newVal, oldVal) {
            // this.$nextTick(() => {
            // Prism.highlightAll();
            // });
        },
        "message.typing": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$nextTick(() => {
                    this.createDynamicComponent();
                });
            }
        },
    },
    // updated() {
    //     Prism.highlightAll();
    // },
    mounted() {
        // this.$nextTick(() => {
        //     new ClipboardJS(".copy-to-clipboard-button");
        // });
        this.$nextTick(() => {
            try {
                this.createDynamicComponent();
                // const copyButtons = this.$refs?.markdownContent?.querySelectorAll(".copy-code-button");
                // copyButtons.forEach(button => {
                //     button.addEventListener("click", event => {
                //         const code = event.target.getAttribute("data-code");
                //         this.$emit("copied", code);
                //         event.preventDefault();
                //     });
                // });
            } catch (e) {
                console.error(e);
            }
        });
    },
    methods: {
        createDynamicComponent(code) {
            // You should sanitize your code before processing it
            this.dynamicComponent = {
                components: {
                    TaskManager: TaskManager,
                    Map: ChatMapCard,
                },
                props: ["test"],
                template: `
                    <div>${this.html}</div>`, // or your dynamic code string
            };
        },
        removeHiddenContent(text) {
            // console.error("removing hidden content", text);
            let finalText = text.replace(/\[HIDE][\s\S]*?\[HID]/g, "");
            // console.error("final text", finalText);
            return finalText;
        },
        fixedHTML(langName, html, code) {
            let newCode = code.replace(/"/g, "&quot;");
            let renderCode = "";
            if (code.includes("PLACEHOLDER_URL")) {
                renderCode = code.replace("PLACEHOLDER_URL", "https://source.unsplash.com/random/?city,night");
            }
            if (code.includes("<") && code.includes("div") && langName !== "vue" && !code.includes("<script>")) renderCode = code.replace("<template>", "").replace("</template>", "");
            return `<div class="chat_code x mx-auto rounded-lg mb-4 overflow-hidden bg-gray-800 dark:bg-base-800 mt-4 relative mx-auto">
                        <div class="pl-3 pr-3 flex justify-between items-center my-0 py-0" style="padding:0px;!important">
                            <div class="font-bold text-sm mb-0 text-white my-0 py-3 f aic jcc">Code Snippet <span class="ml-2 badge-pill badge text-teal-900 bg-teal dark:text-teal-200 dark:bg-teal-700 f-11 py-1 text-bold font-bold text-uppercase">${langName}</span></div>
                            <button class="border border-gray-50 br-10 bg-transparent text-white hover:text-gray-100 py-0 px-2 rounded font-semibold copy-to-clipboard-button py-1 my-0 text-sm o-5" type="button" data-clipboard-text="${newCode}"> <i class="far fa-clipboard mr-2"></i>Copy Code</button>
                        </div>
                        <div class="code-example mx-auto my-0 py-0" style="padding:0!important"><pre data-previewers="color time" class="py-3 my-0 language-${langName}"><code style="margin:0px;padding:0px!important;" class="my-0 py-0 language-${langName}">${html}</code></pre></div>
                    </div>
                    ${renderCode}
`;
        },
        async copyCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                this.buttonText = "Copied!";
            } catch (err) {
                console.error("Error copying code to clipboard", err);
            } finally {
                setTimeout(() => {
                    this.buttonText = "Copy code";
                }, 2000);
            }
        },
        generateCodeBlock(langName, code) {
            return {
                component: "CodeBlock",
                props: { langName, code },
            };
        },
    },
};
</script>
<style lang="scss">
// .prose
// :where(ul > li):not(:where(
// [class~="not-prose"] *))::marker {
//    color: unset !important;
//}

//.prose {
//    strong {
//        color: unset;
//    }
//}

.markdown-it-container {
    $baseFontSize: 18;

    li {
        //.prose
        //
        // :where(ul > li)
        // :not(:where([class~=not-prose] *)):
        &::marker {
            color: black !important;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    strong {
        //font-family: "Inter", sans-serif !important;
        color: unset !important;
    }

    div {
        h1,
        h2,
        h3,
        h4,
        h5 {
            //font-family: "Inter", sans-serif !important;
            font-size: calc($baseFontSize * 1.618) px !important;
            color: unset;
        }

        p {
            line-height: unset;
            margin-bottom: 0px;

            strong {
                font-family: "Inter", sans-serif !important;
                color: unset !important;
            }
        }
    }

    div {
        ul {
            line-height: unset;
            margin: unset !important;
            padding: unset !important;

            li {
                margin-left: 1.5rem !important;
                padding-left: 0.5rem !important;
                @apply text-base-600 dark:text-base-300;
                //@apply mb-5;
                //margin-bottom:20px!important;
            }
        }
    }

    #isolated-component,
    #isolated-component * {
        /* Reset styles for the target element and its descendants */
        font-family: inherit;
        text-wrap: balance;
        font-size: 100%;
        font-weight: inherit;
        line-height: inherit;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        text-decoration: none;
        color: inherit;
        border: none;
        background: transparent;
        /* Add any other styles you want to reset */
    }

    .markdown-it-container ul.contains-task-list > li input[type="checkbox"] {
        top: 0px !important;
    }
}

//.prose {
//    color: unset;
//}
</style>
