<template>
    <li class="max-w-4xl py-2 px-4 sm:px-6 lg:px-8 mx-auto flex gap-x-2 sm:gap-x-4">
        <IconTest />

        <div class="space-y-3">
            <h2 class="font-medium text-gray-800 dark:text-white">How can we help?</h2>
            <div class="space-y-1.5">
                <p class="mb-1.5 text-sm text-gray-800 dark:text-white">You can ask questions like:</p>
                <ul class="list-disc list-outside space-y-1.5 ps-3.5">
                    <li class="text-sm text-gray-800 dark:text-white">What's Preline UI?</li>

                    <li class="text-sm text-gray-800 dark:text-white">How many Starter Pages & Examples are there?</li>

                    <li class="text-sm text-gray-800 dark:text-white">Is there a PRO version?</li>
                </ul>
            </div>
        </div>
    </li>
</template>
<script>
import IconTest from "@/views/IconTest.vue";

export default {
    name: "ChatEmptyStateMessage",
    components: { IconTest },
};
</script>
