import randomId from "@/mixins/ai/random_id";

function addProgressStep(store, name, text = "", data) {
    let id = randomId();
    try {
        let object = { name: name, text: text, complete: false, id: id };
        if (data && data.length > 0) {
            object.data = data;
        }
        store.dispatch("presentation/addToProgress", { ...object });
    } catch (error) {
        console.error("addToProgress", error.message, name, text);
    }
}
export default addProgressStep;
