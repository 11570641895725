<!-- AnimatedText.vue -->
<template>
    <span v-for="(word, index) in wordsWithDelay" :key="`word-${index}`" :class="color" :style="animatedWordStyle(index)" class="animated o-0 mb-0 mr-1 inline-block text-inherit">{{ word }}</span>
</template>

<script>
export default {
    name: "AnimatedText",
    props: {
        text: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        delayAll: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            wordsWithDelay: [],
        };
    },
    mounted() {
        this.populateWordsWithDelay();
    },
    methods: {
        splitWords(text) {
            return text.split(" ");
        },
        populateWordsWithDelay() {
            const words = this.text.split(" ");
            words.forEach((word, index) => {
                setTimeout(() => {
                    this.wordsWithDelay.push(word);
                }, index * 75); // 200ms delay for each word
            });
        },
        animatedWordStyle(index) {
            const delayInSeconds = 0.1 + this.delayAll;
            // const delayInSeconds = index * 0.2 + this.delayAll;
            return `animation: fadeIn 0.5s ${delayInSeconds}s forwards ease-out`;
        },
    },
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
        margin-bottom: -10px;
    }
    to {
        opacity: 1;
        transform: translateY(0px);
        margin-bottom: 0;
    }
}

.animated::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #fff;
    animation: blink 0.5s infinite linear;
}
</style>
