<template>
    <div>
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>

                <div class="fixed inset-0 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full">
                        <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                            <TransitionChild
                                as="template"
                                enter="ease-in-out duration-300"
                                enter-from="opacity-0"
                                enter-to="opacity-100"
                                leave="ease-in-out duration-300"
                                leave-from="opacity-100"
                                leave-to="opacity-0">
                                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                                        <span class="sr-only">Close sidebar</span>
                                        <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </TransitionChild>
                            <!-- Sidebar component, swap this element with another sidebar if you like -->
                            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-3 pb-2 ring-1 ring-white/10">
                                <div class="flex h-16 shrink-0 items-center">
                                    <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company" />
                                </div>
                                <nav class="flex flex-1 flex-col">
                                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                                        <li>
                                            <ul role="list" class="-mx-2 space-y-1">
                                                <li v-for="item in navigation" :key="item.name">
                                                    <a
                                                        :href="item.href"
                                                        :class="[
                                                            item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                        ]">
                                                        <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                                                        {{ item.name }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <!--            name a second slot -->
            <slot name="sidebar"></slot>
            <!--                        <SideBarTemplate :navigation="navigation" />-->
        </div>

        <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-1 py-4 shadow-sm sm:px-6 lg:hidden">
            <button type="button" class="-m-2.5 p-2.5 text-gray-400 lg:hidden" @click="sidebarOpen = true">
                <span class="sr-only">Open sidebar</span>
                <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </button>
            <div class="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
            <a href="#">
                <span class="sr-only">Your profile</span>
                <img class="h-8 w-8 rounded-full bg-gray-800" :src="user.photoURL" alt="" />
            </a>
        </div>

        <main class="py-10 lg:pl-72">
            <div class="px-4 sm:px-4 lg:px-8">
                <slot name="content"></slot>
            </div>
        </main>
    </div>
</template>

<script>
import { ref } from "vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { Bars3Icon, BookOpenIcon, ChatBubbleOvalLeftIcon, HeartIcon, NewspaperIcon, PaintBrushIcon, PhotoIcon, PresentationChartLineIcon, UsersIcon, XMarkIcon } from "@heroicons/vue/24/outline";
// import { ClipboardList, Template, PhotographIcon, PresentationChartLine, PencilRule, ChatAlt, LightBulb, UsersIcon } from "@heroicons/vue/24/outline";
import SideBarTemplate from "@/views/SideBarTemplate.vue";

export default {
    components: {
        SideBarTemplate,
    },

    data() {
        return {
            navigation: [
                { name: "Buttons", description: "Manage and create text documents", path: "/documents", icon: NewspaperIcon },
                { name: "Typography", description: "Manage and create text documents", path: "/documents", icon: NewspaperIcon },
                { name: "Decks", description: "Create and edit slide presentations", path: "/presentations", icon: PresentationChartLineIcon },
                { name: "Canvas", description: "Visually plan and organize your ideas", path: "/canvas", icon: PaintBrushIcon },
                { name: "Storyboard", description: "Bring your stories to life visually", path: "/storyboard", icon: BookOpenIcon },
                { name: "Chat", description: "Interact and collaborate with teammates", path: "/chat", icon: ChatBubbleOvalLeftIcon },
                { name: "Images", description: "Play with images and adjust settings", path: "/imgplay", icon: PhotoIcon },
                { name: "Likes", description: "Play with images and adjust settings", path: "/likes", icon: HeartIcon },
                { name: "Account", description: "Manage your account", path: "/auth", icon: UsersIcon },
            ],
            // teams: [
            // { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
            // { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
            // { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
            // ],
            sidebarOpen: ref(false),
        };
    },
    mounted() {},
};
</script>
