<template>
    <div ref="component" class="vh-100">
        <div style="width: 500px; height: 3px; z-index: 100" ref="trigger"></div>

        <div ref="wrapper"></div>
        <div class="x vh-100">
            <div ref="container" class="z-5 f fc x jcc aic"></div>
        </div>

        <div ref="textContainer" class="fixed p-fill f fc aic jcc x" style="z-index: 300">
            <h1 ref="header" :class="{ 'f-75': mobile, 'f-200': !mobile }" class="header">Boom, magic.</h1>
            <h1 class="visually-hidden" ref="header2"></h1>
            <!--            <div class="mx-auto white">{{ campaignText }}</div>-->
            <div ref="cards" class="gap-4 f aic jcc left right top bottom fc z-10">
                <!--                        <div class="p-absolute mw-350 mx-auto my-auto c-400" style="background: #ff02fb; filter: blur(300px)"></div>-->
                <div v-for="(item, index) in campaignText" :key="index" class="f">
                    <div class="mx-auto" :class="nodeClass">
                        <div class="mw-300 mx-auto">
                            <CardBody v-if="item?.type" :complete="item?.complete || false" :day="item?.day" :name="item?.name || ''" :show-status="item.showStatus || false" :status="item.statusString || ' '" :type="item?.type" appear />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { mockThinking } from "@/mixins/StyleGuideMixins/mockCampaign";
import CardBody from "@/components/generative_templates/Campaigns/CardBody.vue";
import gsapMixin from "@/mixins/ai/gsapMixin";
import json_helpers from "@/mixins/ai/json_helpers";

const { validateJSON } = json_helpers;
const { timeline, set, to, from } = gsap;
gsap.registerPlugin(ScrollTrigger);
export default {
    name: "LandingPageCampaignItems",
    components: { CardBody },
    mixins: [gsapMixin],
    data() {
        return {
            mockCampaign: mockThinking,
            campaignItems: mockThinking,
            campaignText: [],
            showMarkers: false,
            gsapTimelines: [], // Array to store timelines
        };
    },
    computed: {
        nodeClass() {
            return `animated fadeInUpSmooth duration-5 !f-30 shadow block x width-min-300 text-gray-900 text-left`;
        },
    },
    mounted() {
        if (this.mobile) this.headerSize = 100;
        this.campaignItems = validateJSON(this.campaignItems);
        this.$nextTick(() => {}).then(() => {
            this.campaignAnimation();
            this.textAnimation();
            this.gradientAnimation();
        });
    },
    beforeUnmount() {
        // Kill the GSAP timeline and ScrollTrigger instance
        this.gsapTimelines.forEach(tl => {
            if (tl) {
                tl.kill();
            }
        });
    },
    methods: {
        nodeStyle(index, array) {
            return `box-shadow: 0px 0px ${500 * index}px ${(5 - index) * index}px #fb47af`;
        },
        textAnimation() {
            // this.$nextTick(() => {
            let header = this.$refs.header;
            let trigger = this.$refs.trigger;
            let wrapper = this.$refs.wrapper;
            let component = this.$refs.component;
            let pink1 = `rgba(255, 0, 140, 1)`;
            let white = `rgba(255, 255, 255, 1)`;
            let whiteGradient = `radial-gradient(${white} 0%, ${white} 100%)`;
            let whitePinkGradient = `radial-gradient(${white} 0%, ${pink1} 100%)`;
            let purpleBlueGradient = `radial-gradient(${pink1} 50%, rgba(0,0,255,1) 100%)`;
            let fontSizeBig = 400;
            if (this.mobile) fontSizeBig = 150;
            let fontSizeMid = 100;
            if (this.mobile) fontSizeMid = 75;
            // set(header, { text: "Boom", scale: 0, fontSize: fontSizeBig, opacity: 1 });
            let lt = timeline({
                id: "textAnimation",
                scrollTrigger: {
                    trigger: wrapper,
                    start: "top bottom",
                    end: `+=2000`,
                    scrub: true,
                    markers: this.markers({ show: this.showMarkers }),
                },
            });

            lt.set(header, { backgroundImage: whiteGradient, fillColor: "transparent" }, 0.4);
            lt.addLabel("boom", 0.0);

            lt.addLabel("beginning", 0);
            lt.set(header, { text: "Boom", opacity: 0, scale: 0 }, "beginning");

            lt.fromTo(
                header,
                { text: "Boom", opacity: 0, scale: 0, backgroundImage: whiteGradient }, //
                { text: "Boom", opacity: 1, scale: 3, duration: 0.2, backgroundSize: "300%", backgroundImage: whiteGradient, ease: "back.out(2)" },
                "beginning",
            );
            this.cloneAndAnimateElement();
            // lt.to(header, { opacity: 0, scale: 0.5, duration: 0.2, ease: "back.out(2)" }, "beginning+=0.05");
            lt.to(header, { opacity: 0, backgroundImage: whiteGradient, scale: 5, duration: 0.2, ease: "back.out(2)" }, "beginning+=0.05");

            lt.set(header, { text: "Campaigns", opacity: 1, scale: 1, backgroundImage: whiteGradient }, 0.3);
            // lt.set(header, { text: "Campaigns", scale: 1, opacity: 1, fontSize: 200 }, "beginning+=0.5");
            lt.to(header, { y: -20, duration: 0.1 }, 0.3);
            this.animateSentence(lt, header, "Like never before.", this.headerSize, 25, 0.4, 0.05, false);
            lt.to(header, { backgroundImage: whiteGradient, duration: 0.1 }, 0.4);
            this.gsapTimelines.push(lt);
        },
        cloneAndAnimateElement() {
            // Step 1: Select the Element
            const elementToClone = this.$refs.header;

            if (!elementToClone) {
                console.error("Element to clone not found");
                return;
            }

            // Step 2: Clone the Element
            const clonedElement = elementToClone.cloneNode(true);

            // Step 3: Insert the Cloned Element
            // Assuming you want to append it to the same parent element
            elementToClone.parentNode.insertBefore(clonedElement, elementToClone.nextSibling);

            // Step 4: Animate with GSAP
            // Ensure GSAP is available and correctly imported
            let tl = timeline({
                id: "cloneAndAnimateElement",
                scrollTrigger: {
                    trigger: this.$refs.wrapper,
                    start: "top bottom",
                    end: `+=2000`,
                    scrub: true,
                    markers: this.markers({ show: this.showMarkers }),
                },
            });
            tl.to(clonedElement, { duration: 1, x: 100, rotateX: 90, opacity: 0.5 });
        },

        textShadow() {
            // let lt = timeline({});
            // lt.to(
            //     header2,
            //     {
            //         onUpdate: () => {
            //             header2.style.color = "black";
            //             header2.style.textShadow = this.shadowHelper(lt.progress());
            //         },
            //         backgroundImage: "linear-gradient(90deg, rgba(255,0,0,1) 50%, rgba(0,0,0,1) 100%)",
            //         backgroundClip: "text",
            //         fontSize: 200,
            //         duration: 1,
            //     },
            //     0,
            // );
        },
        shadowHelper(progress, color) {
            const radius = 1; // Radius of the circular path
            const rotations = 10; // Number of rotations
            const angle = progress * rotations * 2 * Math.PI; // Adjust for multiple rotations
            const x = radius * Math.cos(angle); // Calculate the x position
            const y = radius * Math.sin(angle); // Calculate the y position
            return `${x}px ${y}px 2px rgba(255,255,255,0.5)`;
        },
        gradientAnimation() {
            let wrapper = this.$refs.wrapper;
            let container = this.$refs.container;
            let trigger = this.$refs.trigger;
            let textContainer = this.$refs.textContainer;
            // container = this.$refs.textContainer;
            let pink1 = `rgba(255, 0, 140, 1)`;
            let black1 = `rgba(0, 0, 0, 1)`;
            let orange1 = `rgb(223, 120, 0)`;
            let blue1 = `rgb(48, 138, 255)`;
            let backgroundAttr = { backgroundSize: "cover", backgroundPosition: "center" };

            let gtl = timeline({
                id: "GradientAnimation",
                scrollTrigger: {
                    trigger: trigger,
                    start: "top bottom",
                    end: `+=200`,
                    // end: `bottom bottom`,
                    scrub: true,
                    markers: this.markers({ show: this.showMarkers }),
                },
            });
            gtl.set(container, {
                background: "radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)",
                backgroundSize: "cover",
                backgroundPosition: "center",
            });

            let duration = 0.05;
            let gradient1 = `radial-gradient(circle, ${pink1} 0%, ${pink1} 0%, ${pink1} 0%, ${black1} 100%)`;
            let gradient3 = `radial-gradient(circle, ${black1} 0%, ${black1} 10%, ${orange1} 50%, ${black1} 100%)`;
            let gradient4 = `radial-gradient(circle, ${black1} 0%, ${blue1} 47%, ${orange1} 50%, ${black1} 100%)`;
            let gradient2 = `radial-gradient(circle, ${black1} 100%, ${black1} 100%,${black1} 90%, ${black1} 100%)`;
            // let gradient3 = `radial-gradient(circle, ${black1} 0%,${orange1} 50%, ${black1} 100%)`;

            gtl.to(textContainer, { background: gradient1, ...backgroundAttr, duration }, 0.0);
            gtl.to(textContainer, { background: gradient3, ...backgroundAttr, duration }, 0.05);
            gtl.to(textContainer, { background: gradient4, ...backgroundAttr, duration }, 0.07);
            gtl.to(textContainer, { background: gradient2, ...backgroundAttr, duration }, 0.1);
            this.gsapTimelines.push(gtl);
        },
        campaignAnimation() {
            // this.$nextTick(() => {
            let trigger = this.$refs.trigger;
            let component = this.$refs.component;
            let wrapper = this.$refs.wrapper;
            let container = this.$refs.container;
            let cards = this.$refs.cards;
            let header = this.$refs.header;
            let fullText = this.mockCampaign;
            let textContainer = this.$refs.textContainer;
            const words = fullText.split(" ") || [];
            let currentChunk = "";
            const self = this;
            // set(header, { text: "" });
            let lt = timeline({
                id: "campaign",
                scrollTrigger: {
                    trigger: wrapper,
                    start: "center center",
                    end: `+=2000`,
                    scrub: true,
                    markers: this.markers({ show: true }),
                    pin: true,
                },
            });
            lt.set(textContainer, { scale: 1 });
            lt.set(cards, { opacity: 0, scale: 0 });
            lt.set(container, { opacity: 0, scale: 0 });
            lt.to(cards, { opacity: 1, scale: 1, duration: 0.1 }, 0.01);
            lt.to(container, { opacity: 1, scale: 1, duration: 0.1 }, 0.01);
            lt.to(
                wrapper,
                {
                    onUpdate: timeline => {
                        // Assuming 'progress' is passed as a parameter to the onUpdate callback
                        let progress = lt?.progress();
                        console.log(progress);

                        const startOffset = 0.5; // Delay start until 30% of the scroll progress
                        let adjustedProgress = progress > startOffset ? (progress - startOffset) / (1 - startOffset) : 0;
                        const chunksToShow = Math.floor(words.length * adjustedProgress);
                        currentChunk = words.slice(0, chunksToShow).join(" ");
                        this.campaignText = validateJSON(currentChunk) || this.campaignText;

                        // const chunksToShow = Math.floor(words.length * progress);
                        // let currentChunk = words.slice(0, chunksToShow).join(" ");
                        // this.campaignText = validateJSON(currentChunk) || this.campaignText;
                        // console.log(currentChunk);
                    },
                    duration: 1,
                },
                0.8,
            );
            lt.to(cards, { scale: 2, duration: 0.2 }, 0.8);
            lt.to(textContainer, { scale: 0, duration: 0.02 }, 1.5);
            this.gsapTimelines.push(lt);
        },
    },
};
</script>
<style lang="scss">
.header {
    // Apply gradient background
    background-image: linear-gradient(to right, blue, purple);

    // Clip the background to the text
    -webkit-background-clip: text;
    background-clip: text;

    // Make the text color transparent to reveal the background
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: 300%;
    -webkit-text-fill-color: transparent;
}
</style>
