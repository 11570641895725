<template>
    <div class="block px-3 pt-3 border-light pb-3">
        <h3 v-if="title" class="mb-3 font-semibold leading-6 text-gray-900">{{ title }}</h3>
        <dl v-if="showStats" :class="`border-light f justify-content-center mt-3 x divide-y divide-gray-200 overflow-hidden justify-items-stretch rounded-lg bg-white shadow md:divide-x md:divide-y-0`">
            <template v-for="(value, key) in orderedStats">
                <Stat v-if="key !== 'progress' && key !== 'status' && value !== 0 && value !== ''" :name="value" :number="key" class="x" />
            </template>
        </dl>
    </div>
</template>

<script>
import Stat from "@/views/Stat.vue";

export default {
    name: "ProgressStats",
    components: { Stat },
    props: {
        stats: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: "Images",
        },
    },
    data() {
        return {};
    },
    computed: {
        showStats() {
            //Object.keys(stats)?.length > 0
            try {
                if (Object.keys(this.stats).length > 0) {
                    return true;
                }
            } catch (e) {
                console.error(e);
                return false;
            }
            return false;
        },
        orderedStats() {
            try {
                if (this.stats) {
                    let obj = {
                        saved: this.stats?.saved || "",
                        processing: this.stats?.processing || "",
                        processed: this.stats?.processed || "",
                        total: this.stats?.total || "",
                        existing: this.stats?.existing || "",
                        failed: this.stats?.failed || "",
                        rejected: this.stats?.rejected || "",
                        products: this.stats?.products || "",
                        images: this.stats?.images || "",
                        links: this.stats?.links || "",
                        sources: this.stats?.sources || "",
                    };
                    if (this.stats?.batches > 1) obj.batch = `${this.stats?.currentBatch}/${this.stats?.batches}` || "";
                    return obj;
                }
            } catch (e) {
                console.error(e);
            }
            return {};
        },
    },
};
</script>
