<template>
    <div class="x width-400">
        <div class="grid grid-cols-2 mx-0 px-0 x gap-3" v-if="false">
            <div v-for="product in products">
                <SmallProductItem :ref="product" :key="product.handle" :product="product" />
                <!--            {{ product.price }}-->
                <!--            <div>-->
                <!--                <img class="aspect-square o-cover x" v-if="product.imageIndex" :src="images[product.imageIndex].url" alt="product.name" />-->
                <!--            </div>-->
                <!--            <h4 v-if="product?.name" class="mt-2">{{ product.name }}</h4>-->
                <!--            <SectionButton v-if="product.button" key="bottombutton" :button-text="product?.button?.text" css="" default-size="15" identifier="" image-color="" :index="0" section="" class="z-0" @save-section="" />-->
            </div>
        </div>
        <EmailSectionProductsVertical :products="products"></EmailSectionProductsVertical>
    </div>
</template>
<script>
import SmallProductItem from "@/components/generative_templates/Email/SmallProductItem.vue";
import EmailSectionProductsVertical from "@/components/generative_templates/Email/EmailSectionProductsVertical.vue";

export default {
    name: "EmailSectionProducts",
    components: { EmailSectionProductsVertical, SmallProductItem },
    props: {
        products: {},
        images: {},
    },
};
</script>
