import { mapActions } from "vuex";
// import processCommands from "@/mixins/Chat/Commands/processing/ProcessCommands";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            newTask: "",
            message_agent: {
                name: "message_agent",
                command: "message_agent",
                start: "[AST]",
                end: "[AND]",
                kind: "Message agent",
            },
            create_agent: {
                name: "create_agent",
                command: "create_agent",
                start: "[AST]",
                end: "[AND]",
                kind: "Create agent",
                role: "assistant",
                description: "Create an agent",
                show: true,
                newMessage: false,
                resources: ["Add tasks to your tasks list", "Remove tasks from your tasks list"],
            },
            agentPrompt: `"""Your task is to devise up to 5 highly effective goals and an appropriate role-based name (_GPT) for an autonomous agent, ensuring that the goals are optimally aligned with the successful completion of its assigned task.

The user will provide the task, you will provide only the output in the exact format specified below with no explanation or conversation.

Example input:
Help me with marketing my business

Example output:
Name: CMOGPT
Description: a professional digital marketer AI that assists Solopreneurs in growing their businesses by providing world-class expertise in solving marketing problems for SaaS, content products, agencies, and more.
Goals:
- Engage in effective problem-solving, prioritization, planning, and supporting execution to address your marketing needs as your virtual Chief Marketing Officer.\n
- Provide specific, actionable, and concise advice to help you make informed decisions without the use of platitudes or overly wordy explanations.\n
- Identify and prioritize quick wins and cost-effective campaigns that maximize results with minimal time and budget investment.\n
- Proactively take the lead in guiding you and offering suggestions when faced with unclear information or uncertainty to ensure your marketing strategy remains on track."""`,
        };
    },
    // mixins: [processCommands],
    computed: {
        activeAgents() {
            return this.$store.getters["chat/agents/activeAgents"];
        },
        agentString() {
            const agents = this.activeAgents;
            let string = "";
            if (agents && agents.length > 0) {
                string += this.headerString("Active Agents");
                string += "\n";
                for (let agent of this.activeAgents) {
                    // string += "\n----\n";
                    string += "\nNAME: " + agent.name + "\n\n";
                    string += "DESCRIPTION: " + agent.description + "\n\n";
                    // string += "Goals:\n" + agent.goals.join("\n") + "\n";
                    // string += "\n--\n";
                }
            } else {
                string += this.headerString("Active agents");
                string += "No active agents";
            }
            // string = `\n\nTASKS:\n`;

            return `${string}`;
        },
    },
    methods: {
        async manageAgent(args, name, message, index) {
            if (args.opertation && args.operation === "remove") {
                await this.$store.dispatch("chat/agents/removeAgent", { name });
                return this.$store.state.chat.agents;
            } else if (args.opertation && args.operation === "message") {
                await this.messageAgent(args);
                return
            }
        },
        async messageAgent(args, name, message, index) {
            let agent = args["agent_name"];
            let msg = args["content"];
            return await this.addMessageToAgentConvo(agent, msg);
        },
        async addMessageToAgentConvo(agentName, m) {
            const content = m;
            this.newMessage = "";
            let message = userMessage(content);
            // find the agent conversation where the activeAgent name matches the agentName
            await this.$store.dispatch("chat/agents/addMessageToAgentConvo", { agentName, message });
            // const conversation = this.$store.state.chat.agents[agentName].conversation;
            const agent = this.activeAgents.find(agent => agent.name === agentName);
            if (agent.conversation) {
                console.log("conversation", agent.conversation);
                const response = await this.completion(agent.conversation, null, `${agentName} Agent`, gpt3, 300, false, 0, false, false);
                console.log("response", response);
                let message = aiMessage(response);
                await this.$store.dispatch("chat/agents/addMessageToAgentConvo", { agentName, message });
            }
            let msgString = "";
            for (let m of this.activeAgents.find(agent => agent.name === agentName).conversation) {
                let role = m.role;
                if (role === "assistant") {
                    role = agentName;
                } else {
                    role = "YOU";
                }
                msgString += `${role}: ${m.content}\n\n`;
            }
            return msgString;
        },
        parseAgentData(text) {
            if (!text || !text.trim()) {
                console.error("parseAgentData received undefined, null or empty text.");
                return {
                    id: "",
                    name: "Unnamed agent",
                    description: "No description available",
                    goals: [],
                    original: text,
                    conversation: [],
                };
            }

            const lines = text.replace(/\r\n/g, "\n").split("\n");
            let agent = {
                id: "",
                name: "Unnamed agent",
                description: "No description available",
                goals: [],
                original: text,
                conversation: [],
            };
            let isUpdated = false;
            let inGoalsSection = false;

            for (let line of lines) {
                if (line.startsWith("Name:")) {
                    agent.name = line.replace("Name:", "").trim();
                    isUpdated = true;
                    inGoalsSection = false; // we're out of Goals section if we encounter another field
                } else if (line.startsWith("Description:")) {
                    agent.description = line.replace("Description:", "").trim();
                    isUpdated = true;
                    inGoalsSection = false; // we're out of Goals section if we encounter another field
                } else if (line.startsWith("Goals:")) {
                    inGoalsSection = true;
                } else if (inGoalsSection) {
                    if (line.trim()) {
                        // Add the goal if the line is not empty
                        agent.goals.push(line.replace("-", "").trim());
                        isUpdated = true;
                    }
                }
            }

            if (!isUpdated) {
                console.error("parseAgentData did not find valid fields in the text.");
            }

            return agent;
        },

        async createAndSaveAgent(response) {
            let agentObj = this.parseAgentData(response);
            let agent = {
                role: "system",
            };
            if (agentObj.name) agent.name = agentObj.name;
            if (agentObj.description) agent.description = agentObj.description;
            if (agentObj.goals) agent.goals = agentObj.goals;
            if (agentObj.conversation) agent.conversation = agentObj.conversation;
            if (agentObj.original) agent.original = agentObj.original;
            this.$store.dispatch("chat/agents/addAgent", agentObj);

            return agentObj;
        },
        async agentBot(args, name, message, index) {
            let content;
            if (args.task) content = args.task;
            console.groupCollapsed(`%c🤖 Request Agent`, bot, content + "...");
            console.log("%c" + content, txt);
            console.log("index", index);
            console.groupEnd();
            let latest = { role: "user", content: `Task: '${content}'\nRespond only with the output in the exact format specified in the system prompt, with no explanation or conversation.\n\n` };
            let prompt = this.agentPrompt;

            let messages = [{ role: "system", content: prompt }, latest];
            let response = await this.completion(messages, null, "agentBot", gpt3, 500, false, 0, false);

            // if (!response.includes("]")) {
            //     return;
            // }

            console.groupCollapsed(`%c🤖 Response`, bot, response.slice(0, 20) + "...");
            console.log("%c" + response, txt);
            console.groupEnd();
            let agentObj = await this.createAndSaveAgent(response);
            let string = `Your new agent is now available. Feel free to send them a message.\n\nNAME: ${agentObj.name}\nDESCRIPTION:\n${agentObj.description}\n\n`;
            return string;
            // return content;
        },

        newAgentChat(message) {
            console.groupCollapsed(`%c🤖 New Agent Chat`, bot, message.content.slice(0, 15) + "...");
            console.log("%c" + message.content, txt);
            console.groupEnd();
            let systemMessage = { role: "system", content: message.content };
            let sampleMessages = {
                systemMessage,
            };
            let title;
            if (message.content) {
                title = this.parseAgentName(message.content);
            }
            let format = {
                topic: `🤖 ${title}`,
                role: "system",
                content: message.content,
                welcome_message: " ",
                model: gpt4,
                input_format: "Task: [MESSAGE]\n\nRespond only with the output in the exact format specified in the system prompt, with no explanation or conversation.",
            };
            console.groupCollapsed(`%c Create ${title} Agent`, bot, message.content.slice(0, 15) + "...");
            console.log("%c" + message.content, txt);
            console.table(format);
            console.groupEnd();
            return format;
        },

        async createAgentChat(message) {
            let topic = this.newAgentChat(message);
            await this.$store.dispatch("chat/createNewChat", topic);
            return;
        },
        parseAgentName(text) {
            let start = text.indexOf("Name: ") + "Name: ".length;
            let end = text.indexOf("\n");
            return text.slice(start, end);
        },
        async addAgent() {
            if (!this.newAgentName.trim() || !this.newAgentDescription.trim()) {
                console.log("Please input valid agent data");
                return;
            }

            // Pass an object with more data about the agent here
            const agentData = {
                name: this.newAgentName,
                description: this.newAgentDescription,
            };

            await this.$store.dispatch("chat/agents/addAgent", agentData);

            // Clear the input and close the input field
            this.newAgentName = "";
            this.newAgentDescription = "";
            this.toggleAdd = false;
        },
        async removeAgent(agentName) {
            // Get agent data from Vuex state
            const agent = this.$store.getters["chat/agents/getAgent"](agentName);
            if (agent.id) {
                // Dispatch the action to remove the agent
                await this.$store.dispatch("chat/agents/removeAgent", agent.id);
            }
        },
    },
};
