<script setup lang="ts">
import { useRoute } from "vue-router";
import { cn } from "@/lib/utils";
import { Button } from "@/components/shad/ui/button";

interface Item {
    title: string;
    href: string;
}

const $route = useRoute();

const sidebarNavItems: Item[] = [
    {
        title: "Profile",
        href: "/examples/forms/forms",
    },
    {
        title: "Account",
        href: "/examples/forms/account",
    },
    {
        title: "Appearance",
        href: "/examples/forms/appearance",
    },
    {
        title: "Notifications",
        href: "/examples/forms/notifications",
    },
    {
        title: "Display",
        href: "/examples/forms/display",
    },
];
</script>

<template>
    <nav class="flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1">
        <Button v-for="item in sidebarNavItems" :key="item.title" as="a" :href="item.href" variant="ghost" :class="cn('w-full text-left justify-start', $route.path === `${item.href}.html` && 'bg-muted hover:bg-muted')">
            {{ item.title }}
        </Button>
    </nav>
</template>
