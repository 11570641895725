<script setup>
import { PopoverRoot, useForwardPropsEmits } from "radix-vue";

const props = defineProps({
    defaultOpen: { type: Boolean, required: false },
    open: { type: Boolean, required: false },
    modal: { type: Boolean, required: false },
});
const emits = defineEmits(["update:open"]);

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
    <PopoverRoot v-bind="forwarded">
        <slot />
    </PopoverRoot>
</template>
