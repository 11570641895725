<template>
    <div class="chat-system system-message x">
        <template v-if="showSystemMessage">
            <div class="message_container x">
                <div class="chat_content_container x height-min-100">
                    <!--                    <ChatAvatar :role="m.role" :date="m.createdAt"></ChatAvatar>-->
                    <ChatMessageEdit v-if="editMode" :m="m" @cancelEdit="cancelEdit" @saveEdit="saveEdit"></ChatMessageEdit>
                    <template v-else>
                        <template v-if="isSummary">
                            <h6 class="mx-auto text-center">Summary</h6>
                            <div class="row hover-reset align-items-stretch jcc mt-2 gap-1 p-0 text-xs text-gray-500">
                                <div class="col-1 f jcs x aic h-auto h-full p-0" v-for="(item, index) in chunkArray">
                                    <div class="item-transition border-light br-10 smaller line-height-smaller p-relative f-11 h-auto h-full bg-snow p-1 dark:bg-base-900" :style="'font-size: 6px; line-height: 0.5rem;' + bringItemsToCenter(chunkArray.length, index)" @click="selectItem($event)">
                                        <span>{{ item.summary.slice(0, 250) }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div v-else class="x mw-800 markdown-system">
                            <div class="p-relative d-block">
                                <MessageEditButtons :m="m" @edit-message="editMessage" :message="m" @remove-message="removeMessage" />
                            </div>
                            <ChatMarkdown @click="showSystemMessage = !showSystemMessage" class="prose text-teal-500" :content="m.content"></ChatMarkdown>
                            <debugObject :object="m" v-if="$store.state.chat.showDebugger"></debugObject>
                            <div class="container-slim f fc my-5 gap-5">
                                <ChatMarkdown v-if="MemoryTaskString" @click="showSystemMessage = !showSystemMessage" class="text-teal-500" :content="MemoryTaskString"></ChatMarkdown>
                                <!--                                <ChatMarkdown v-if="MemoryTaskString" @click="showSystemMessage = !showSystemMessage" class="prose text-teal-500" :content="userInfoString"></ChatMarkdown>-->
                                <MemoryManager />
                                <AgentManager :message="m" :key="index" />
                                <TaskManager />
                            </div>
                            <div class="br-10 relative bg-teal-200/30 p-2 dark:bg-base-800 dark:text-base-500" v-if="$store.state.chat.commands">
                                <a href="#" class="p-absolute top right p-3" @click.prevent="showRaw = !showRaw"><i class="fas fa-code"></i></a>
                                <div @click="showSystemMessage = !showSystemMessage" class="f-15 whitespace-pre-wrap" v-if="showRaw">
                                    {{ formattedActionString }}
                                </div>
                                <ChatMarkdown v-else @click="showSystemMessage = !showSystemMessage" class="f-15 block text-teal-500" :message="formattedActionString" :content="formattedActionString"></ChatMarkdown>
                            </div>
                        </div>
                    </template>
                    <div class="right top bottom">
                        <MessageEditButtons :m="m" @edit-message="editMessage" :message="m" @remove-message="removeMessage" />
                    </div>
                </div>
            </div>
        </template>
        <div
            class="fwsb text-capitalize border-top f-15 cursor-pointer border-b-teal-100 border-t-teal-100 py-2 text-center text-teal-600/50"
            style="margin-top: -1px !important; margin-bottom: -1px !important; z-index: 3 !important"
            :class="{ ' bg-base-50 !py-1 !text-xs text-gray-600 dark:bg-base-900': m.role === 'chunk', 'bg-teal-50 dark:bg-base-800 dark:text-base-500': m.role !== 'chunk' }"
            v-else
            @click.prevent="showSystemMessage = !showSystemMessage">
            <template v-if="m.role === 'chunk'">
                Chunked document
                <i class="fal fa-clipboard-list ml-2 hover:text-teal-500"></i>
            </template>
            <template v-else>
                {{ kindOrRole }} message - {{ m.role }}
                <!--                <i class="far fa-info-circle ml-2 hover:text-teal-400"></i>-->
            </template>
        </div>
    </div>
</template>
<script>
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import ChatMessageEdit from "@/components/chat/messages/ChatMessageEdit.vue";
import MessageEditButtons from "@/components/chat/MessageEditButtons.vue";
import ChatClonedDocument from "@/components/chat/specialMessages/ChatClonedDocument.vue";
import ChatAvatar from "@/components/CoreUI/ChatAvatar.vue";
import ChatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import MemoryManager from "@/views/Chat/MemoryManager.vue";
import TaskManager from "@/views/Chat/TaskManager.vue";
import AgentManager from "@/views/Chat/AgentManager.vue";

export default {
    name: "SystemChat",
    components: { AgentManager, TaskManager, MemoryManager, DebugObject, ChatAvatar, ChatClonedDocument, ChatMarkdown, ChatMessageEdit, MessageEditButtons },
    mixins: [ChatCommands],
    props: {
        m: {
            type: Object,
        },
        index: {
            type: Number,
        },
    },
    data() {
        return {
            showRaw: false,
            showSystemMessage: false,
            editMode: false,
            selectedItem: null,
        };
    },
    mounted() {
        if (this.m.role === "summary") {
            this.showSystemMessage = true;
        }
    },
    computed: {
        kindOrRole() {
            return this.m.kind || this.m.role;
        },
        isSummary() {
            return this.m.role === "summary";
        },
        chunkArray() {
            return JSON.parse(this.m.content);
        },
    },
    methods: {
        async selectItem(element) {
            const originalItem = element.target.closest(".item-transition");
            const rect = originalItem.getBoundingClientRect();
            const summary = originalItem.textContent.trim();

            const clonedItem = {
                component: ChatClonedDocument,
                propsData: {
                    summary,
                    itemStyle: `
        left: ${rect.left}px;
        top: ${rect.top}px;
        transform: translateX(-50%) translateY(-50%) rotateY(0deg) rotate(0deg) scale(1);
      `,
                },
                el: document.createElement("div"),
            };

            new Vue(clonedItem).$mount();
            document.body.appendChild(clonedItem.el);

            requestAnimationFrame(() => {
                clonedItem.el.style.transform = "translateX(-50%) translateY(-50%) rotateY(0deg) rotate(0deg) scale(1.5)";
                clonedItem.el.style.left = "60%";
                clonedItem.el.style.top = "50%";
            });
        },
        // give center of the array the highest z-index
        bringItemsToCenter(length, index) {
            if (this.selectedItem !== null && this.selectedItem === index) {
                return `
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%) scale(1.5);
            z-index: 100 !important;
        `;
            }
            const curveIntensity = 2;
            const distanceIntensity = 200;
            const rotationIntensity = 5; // Add rotation variable

            const curveOffset = (length - 1) / 2;
            const angle = (index - curveOffset) * curveIntensity;
            const translateY = Math.abs(index - curveOffset) * curveIntensity;

            let translateX = 0;

            if (index < curveOffset) {
                // Items before the middle
                translateX = distanceIntensity - (index * distanceIntensity) / curveOffset;
            } else if (index > curveOffset) {
                // Items after the middle
                translateX = -((index - curveOffset) * distanceIntensity) / curveOffset;
            } else {
                // The middle item
                translateX = 0;
            }

            const rotation = (index - curveOffset) * rotationIntensity; // Calculate rotation

            return `
      transform: translateX(${translateX}px) translateY(${translateY}px) rotateY(${angle}deg) rotate(${rotation}deg);z-index:${length - Math.abs(index - Math.floor(length / 2))}!important;
  `;
        },

        zIndexHighestInCenter(array, index) {
            return array.length - Math.abs(index - Math.floor(array.length / 2));
        },
        cancelEdit() {
            this.editMode = false;
        },
        editMessage() {
            console.error("startEditing");
            this.editMode = true;
        },
        saveEdit() {
            console.error("saveEdit");
            this.editMode = false;
            this.$emit("editMessage", this.m, this.index);
        },
        removeMessage() {
            this.$emit("removeMessage", this.m, this.index);
        },
    },
};
</script>
<style lang="scss">
.system-message{
  &.chat-system {
    @apply w-full;
    @apply bg-teal-50;
    @apply dark:bg-base-700;
}
  
  h2{
    line-height:unset!important;
    letter-spacing: unset!important;
    font-size:17px!important;
  }
  h3{
    line-height:unset!important;
    letter-spacing: unset!important;
    font-size:14px!important;
    font-weight: 700!important;
  }
}
.message-edit{

  textarea{
    overflow: hidden;
    padding: 10px;
    resize: none;
    border: none;
  }
}

.item-clone {
    transition: transform 0.7s, left 0.7s, top 0.7s;
    z-index: 100;
    max-width:250px;
    padding:10px;
    border-radius:10px;
    font-size:0.5rem!important;
    line-height:normal!important;
}

.item-transition {
    transition-property: transform, z-index, font-size, line-height, position, left, top, scale;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0, 1.68, 0.18, 0.94);
}
.hover-reset:hover .item-transition {
  transform: translateX(0) translateY(0) rotateY(0deg) rotate(0deg)!important;
  z-index: 1 !important;
  //font-size: 1rem !important;
  //line-height: 1.5rem !important;
}
</style>
