<template>
    <div class="p-2 border-b border-gray-200 flex flex-col">
        <div class="font-semibold text-lg">{{ assistant.name }}</div>
        <div class="text-sm text-gray-600">{{ formattedDate }}</div>
        <div v-if="assistant.description" class="text-gray-500">{{ assistant.description }}</div>
        <ModelBadge :model="assistant.model" />
    </div>
</template>

<script>
import moment from "moment";
import ModelBadge from "@/views/Chat/ModelBadge.vue";
export default {
    name: "AssistantListItem",
    components: { ModelBadge },
    props: {
        assistant: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        formattedDate() {
            // return this.assistant.created_at ? format(new Date(this.assistant.created_at * 1000), "PPP") : "Unknown date";
            return this.assistant.created_at ? moment(this.assistant.created_at).format("MMM Do YYYY") : "Unknown date";
        },
    },
};
</script>
