<template>
    <div ref="markdownContent" class="email-markdown x">
        <div class="" v-html="html"></div>
        <!--            markdown-content-->
    </div>
</template>
<script>
import MarkdownIt from "markdown-it";
import mdAbbr from "markdown-it-abbr";
import mdAnchor from "markdown-it-anchor";
// import mdToc from "markdown-it-table-of-contents";
import mdEmoji from "markdown-it-emoji";
import mdFootnote from "markdown-it-footnote";
import mdIns from "markdown-it-ins";
import mdMark from "markdown-it-mark";

// import { Vue } from "vue";
// import mdContainer from "markdown-it-container";

import Cm from "@/components/CoreUI/cm.vue";
import ChatCodeRenderer from "@/components/chat/specialMessages/ChatCodeRenderer.vue";
import chatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import newChatMixin from "@/mixins/Chat/newChatMixins";

export default {
    name: "EmailMarkdown",
    components: {},
    mixins: [newChatMixin, chatCommands],
    props: {
        content: {
            type: String,
            default: "",
            required: true,
        },
        message: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dynamicComponent: null,
            currentAction: null,
            buttonText: "Copy code",
        };
    },
    computed: {
        html() {
            const md = new MarkdownIt({
                linkify: true,
                typographer: false,
            })
                .use(mdAnchor, {
                    // anchorOptions
                })
                .use(mdFootnote)
                .use(mdEmoji)
                .use(mdMark)
                .use(mdIns)
                .use(mdAbbr);
            // .use(markdownItMermaid)
            // .use(markdownItLists)
            this.$forceUpdate();

            const defaultLinkRender =
                md.renderer.rules.link_open ||
                function (tokens, idx, options, env, self) {
                    return self.renderToken(tokens, idx, options);
                };

            md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
                const aIndex = tokens[idx].attrIndex("target");

                if (aIndex < 0) {
                    tokens[idx].attrPush(["target", "_blank"]);
                } else {
                    tokens[idx].attrs[aIndex][1] = "_blank";
                }

                const relIndex = tokens[idx].attrIndex("rel");
                if (relIndex < 0) {
                    tokens[idx].attrPush(["rel", "noopener noreferrer"]);
                } else {
                    tokens[idx].attrs[relIndex][1] = "noopener noreferrer";
                }

                return defaultLinkRender(tokens, idx, options, env, self);
            };
            return md.render(this.content);
        },
    },
    watch: {
        content(newVal, oldVal) {
            // this.$nextTick(() => {
            // Prism.highlightAll();
            // });
        },
        "message.typing": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$nextTick(() => {
                    this.createDynamicComponent();
                });
            }
        },
    },
    // updated() {
    //     Prism.highlightAll();
    // },
    mounted() {
        // this.$nextTick(() => {
        //     new ClipboardJS(".copy-to-clipboard-button");
        // });
        this.$nextTick(() => {
            try {
                // const copyButtons = this.$refs?.markdownContent?.querySelectorAll(".copy-code-button");
                // copyButtons.forEach(button => {
                //     button.addEventListener("click", event => {
                //         const code = event.target.getAttribute("data-code");
                //         this.$emit("copied", code);
                //         event.preventDefault();
                //     });
                // });
            } catch (e) {
                console.error(e);
            }
        });
    },
    methods: {},
};
</script>
<style lang="scss">
.email-markdown {
    $baseFontSize: 18;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
        //font-size: calc($baseFontSize * 1.618) px !important;
        color: unset;
    }

    p {
        line-height: unset;
        text-wrap: balance;
    }

    blockquote {
        background: color-mix(in srgb, currentColor 15%, rgba(255, 255, 255, 0.3));
        //color: color-mix(in srgb, currentColor 50%, white);
        @apply p-3;
        @apply my-4;
        @apply text-lg;
        @apply py-3;
        &:first-child {
            @apply mt-8;
        }
        &:last-child {
            @apply mb-8;
            @apply br-10;
        }
        p {
            @apply py-0;
            @apply mb-0;
        }
        border: 1px solid color-mix(in srgb, currentColor 5%, rgba(0, 0, 0, 0.05));
    }
    ul {
        line-height: unset;
        @apply mx-auto;
        li {
            //margin-left: 1.5rem !important;
            @apply ml-6;
            @apply pl-2;
            //padding-left: 0.5rem !important;
            @apply text-left;
            @apply text-pretty;
            @apply leading-relaxed;
        }
        @apply my-4;
        @apply list-disc;
    }

    ol {
        @apply mt-6;
        list-style: none; /* Removes default list styling */
        counter-reset: list-counter; /* Initializes the counter */
        padding-left: 0; /* Removes default padding */
        li {
            position: relative; /* Needed for absolute positioning of the ::before content */
            @apply text-left;
            @apply mb-5;
            //padding-left: 2.5rem; /* Adjust based on your design, ensures space for the numbers */
            @apply pl-6;
            @apply ml-6;
            @apply pb-5;
            border-bottom: 1px solid color-mix(in srgb, currentColor 20%, rgba(0, 0, 0, 0.2)) !important;
            &::before {
                content: counter(list-counter) "";
                counter-increment: list-counter;
                position: absolute;
                font-weight: 700;
                font-size: 1.2rem;
                left: -1.4em;
                @apply c-40;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                border: 2px solid color-mix(in srgb, currentColor 5%, rgba(255, 255, 255, 0.8));
                @apply flex;
                @apply aic;
                @apply jcc;
                background: color-mix(in srgb, currentColor 5%, rgba(0, 0, 0, 0.1));
                //@layer button-1;
                top: 0%;
                transform: translateY(-5px) translateX(0%);
            }
        }
    }
}
</style>
