<template>
    <div>
        <div v-if="accessToken">
            <p>Access Token: {{ accessToken }}</p>
            <p class="bg-red">Refresh Token: {{ refreshToken }}</p>
        </div>
        <div class="group shadow-black">
            <SimpleButton class="my-3 z-1000 mx-auto xs hover:shadow-high !shadow-black/10" color="sky" @click.prevent="startOAuth">Connect with Klaviyo</SimpleButton>
        </div>
    </div>
</template>

<script>
import webRequest from "@/mixins/ai/web_request";
import SimpleButton from "@/views/SimpleButton.vue";

export default {
    name: "KlaviyoOAuth",
    components: { SimpleButton },
    data() {
        return {
            accessToken: null,
            refreshToken: null,
        };
    },
    // mounted() {
    //     if (window.location.search.includes("code=")) {
    //         this.handleOAuthCallback();
    //     }
    // },
    methods: {
        async startOAuth() {
            const response = await webRequest("email/oauth/authorize");
            window.location.href = response.authorizationUrl;
        },
        async handleOAuthCallback() {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            const response = await webRequest("email/oauth/token", { code });
            this.accessToken = response.access_token;
            this.refreshToken = response.refresh_token;
        },
    },
};
</script>
<style lang="scss"></style>
