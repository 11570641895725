<template>
    <div class="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-4">
        <svg class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
            <defs>
                <pattern id="1d4240dd-898f-445f-932d-e2872fd12de3" width="200" height="200" x="50%" y="0" patternUnits="userSpaceOnUse">
                    <path d="M.5 200V.5H200" fill="none" />
                </pattern>
            </defs>
            <svg x="50%" y="0" class="overflow-visible fill-gray-800/20">
                <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
            </svg>
            <rect width="100%" height="100%" stroke-width="0" fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
        </svg>
        <div class="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
            <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>
        <div class="mx-auto max-w-2xl text-center">
            <SimpleButton shimmer class="!text-snow mb-3" size="sm" shimmer-bg="base-700 text-snow px-2 !py-0 !p-[12px] font-bold ">Coming soon</SimpleButton>
            <h2 class="text-6xl font-bold tracking-tight text-white sm:text-6xl" v-if="title">
                {{ title }}
            </h2>
            <p class="mx-auto mt-3 max-w-xl text-lg leading-8 text-gray-300" v-if="description">{{ description }}</p>
            <div class="mt-10 flex items-center justify-center gap-x-6 o-5">
                <SimpleButton color="indigo" class="grayscale" :href="button.link" v-if="button?.link">
                    {{ button.text }}
                </SimpleButton>
                <!--                <SimpleButton link color="green" @click="buttonSecondary" v-if="buttonSecondary">-->
                <!--                    {{ buttonSecondary.text }}-->
                <!--                </SimpleButton>-->
            </div>
        </div>
    </div>
</template>
<script>
import SimpleButton from "@/views/SimpleButton.vue";

export default {
    name: "HomeContentSectionAlt2",
    components: { SimpleButton },
    props: {
        title: {
            type: String,
            default: "Boost your productivity.<br>Start using our app today.",
        },
        description: {
            type: String,
            default: "Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur commodo do ea.",
        },
        button: {
            type: Object,
            default: () => ({
                text: "Get started",
                link: "#",
            }),
        },
        buttonSecondary: {
            type: Object,
            default: () => ({
                text: "Learn more",
                link: "#",
            }),
        },
    },
};
</script>
