<template>
    <div class="px-3 pb-3">
        <h6>Threads</h6>
        <template v-if="assistants.length > 0">
            <div v-for="assistant in assistants">
                <AssistantListItem :assistant="assistant" />
            </div>
        </template>
        <div class="x p-5 f aic jcc bg-gray-100 br-10 text-center fwb text-gray-400">Threads will eventually go here</div>
    </div>
</template>
<script>
import AssistantListItem from "@/components/chat/ChatList/AssistantListItem.vue";

export default {
    name: "AssistantsList",
    components: { AssistantListItem },
    computed: {
        assistants() {
            return this.$store?.state?.chat?.assistants || [];
        },
        // threadList() {
        //     return this.threads;
        // },
    },
};
</script>
