<template>
    <div>
        <div class="f aic jcc gap-3">
            <a :class="{ disabled: generativeLoader }" :style="buttonStyles[0]" class="btn mb-4" href="#" @click.prevent="callForAI">
                <template v-if="generativeLoader">
                    <i class="fal mr-2 fa-sync spinner infinite ease-back"></i>
                    <span>Loading ...</span>
                </template>
                <template v-else>
                    <i class="fal mr-2 fa-pencil-alt"></i>
                    <span>Get {{ styleGuideKey }}</span>
                </template>
            </a>
        </div>

        <CollapsableMenuItem class="container-slim" max-height="500" v-if="aiContent">
            <template #title><div class="py-2 pl-3 z-5">Debug Generated</div></template>
            <DynamicObjectViewer v-if="aiContent" :data-object="aiContent" class="container-slim"></DynamicObjectViewer>
            <preview-messages :messages="streamMessages" class="x" />
        </CollapsableMenuItem>

        <div class="x"></div>
        <div :style="mergedStyles" class=""></div>
    </div>
</template>

<script>
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import CopyGuideSection from "@/components/generative_templates/Guidelines/CopyGuideSection.vue";
import PreviewMessages from "@/components/styleGuide/debugging/PreviewMessages.vue";
import functionTemplateMixin from "@/mixins/ai/functionTemplateMixin";
import StreamObjectDebugger from "@/components/Presentations/slides/StreamObjectDebugger.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import generativeStyleGuideMixin from "@/mixins/ai/generativeStyleGuideMixin";

export default {
    name: "functionTemplate",
    components: { CollapsableMenuItem, DynamicObjectViewer, StreamObjectDebugger, PreviewMessages, CopyGuideSection, ChatMarkdown },
    mixins: [styleGuideMixin, generativeStyleGuideMixin],
    props: {},
    data() {
        return {
            styleGuideKey: "textSamples",
            save: false,
        };
    },
    mounted() {},
    methods: {
        callForAI() {
            // this.setActionTypographyGuide();
            this.setActionWritingSamples();
            this.callFunctionFromComponent("make it good");
        },
        // async getMoreText() {
        //     await this.createStyleGuide(this.styleGuide.url, this.$route.params.id, { sections: ["additionalText"] });
        // },
    },

    computed: {
        aiContent() {
            return this.styleGuide?.ai?.[this.styleGuideKey];
        },
        mergedStyles() {
            const baseStyle = "line-height:unset;font-weight:unset";
            const paragraphStyle = this.paragraphStyle || "";
            return [baseStyle, paragraphStyle];
        },
    },
};
</script>

<style lang="scss" scoped>
h3 {
    font-size: 2rem !important;
}

strong {
    font-weight: 500 !important;
}

ol > div {
    counter-increment: list-counter;
}

ol > div > li {
    position: relative;
    padding-left: 2em;
    list-style-type: none;

    &::before {
        font-size: 0.8rem;
        line-height: 1.5em;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.5em;
        height: 1.5em;
        margin-right: 30px;
        @apply text-base-500;
        content: counter(list-counter);
        transform: translateY(-50%);
        @apply border-base-900/10;
        @apply bg-base-900/5;
        text-align: center;
        border: 1px solid;
        border-radius: 50%;
    }
}

p {
    font-size: 1rem !important;
    font-weight: normal !important;
    line-height: normal !important;
}

/* Include your styles here */
</style>
