<template>
    <a :href="`/admin/${$route.params.id}/campaigns/${parent.id}`" class="f-15 br-10 border-light p-1 f aic px-2 gap-2 py-1 bg-gray-100">
        <!--      @click.prevent="goToNextCampaign()"-->
        <div class="c-20 border-light f-15 border-green text-green f aic jcc bg-snow shadow">
            <i class="fa-solid fa-arrows-split-up-and-left"></i>
        </div>
        <div class="py-1">
            <h6 class="mb-0 p-0 m-0 o-5 f-11 text-gray-900">Campaign</h6>
            <span class="line-clamp-1 f-13 text-gray-900">{{ parentName }}</span>
        </div>
    </a>
</template>
<script>
export default {
    name: "ParentCampaign",
    props: {
        parent: {
            type: Object,
            required: true,
        },
    },
    computed: {
        parentName() {
            return this.parent?.name || this.parent?.result?.name || "";
        },
        parentId() {
            return this.parent?.id || this.parent?.result?.id || "";
        },
    },
    methods: {
        goToNextCampaign() {
            let path = `/admin/${this.route.params.id}/campaigns/${this.parent.id}`;
            this.$router.push({ path });
            // this.$router.push({ name: "Campaign", params: { cid: this.parent.id } });
            // campaigns/${parent.id}
        },
    },
};
</script>
