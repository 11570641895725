function apiURL() {
    const environment = import.meta.env.VITE_ENV;
    const isLocal = environment !== "production";
    const hostname = window.location.hostname;

    if (isLocal) {
        // return `https://api.brandblocks.co/`;
        return `http://${hostname}:8080/`;
    } else {
        // return `https://api.ashh.co/`;
        return `https://api.brandblocks.co/`;
    }
}
function endpointURL(endpoint) {
    return apiURL() + endpoint;
}
export default apiURL;
export { endpointURL };
