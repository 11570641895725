<template>
    <div v-if="tone">
        <Listbox as="div" class="f aic jcs my-0 py-0" v-model="tone">
            <ListboxLabel class="line-height-smaller mb-0 mr-3 inline py-0 pb-0 text-sm font-medium text-gray-700"
                >Tone
            </ListboxLabel>
            <div class="d-block x mw-250 relative py-0">
                <ListboxButton
                    class="x min-w-350 bg-white relative my-0 w-full cursor-default rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span class="block truncate">{{ tone.label }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>
                <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                        class="bg-white x ring-black absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm">
                        <ListboxOption
                            as="template"
                            v-for="tone in tones"
                            :key="tone.value"
                            :value="tone"
                            v-slot="{ active, selected }">
                            <li
                                @click="selectTone(tone)"
                                :class="[
                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-5 pr-5',
                                ]">
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                    {{ tone.label }}
                                </span>

                                <span
                                    v-if="selected"
                                    :class="[
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 left-0 flex items-center pl-2',
                                    ]">
                                    <CheckIcon class="d-block ml-0 h-5 w-5 pl-0" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import { db } from "@/firebase";
const router = useRoute();
const id = router.params.id;
// const document = reactive(useDocument(doc(collection(db, "documents"), id)));
</script>
<script>
import { ref } from "vue";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import { db } from "@/firebase";
import { reactive } from "vue";
import { useCollection, useDocument } from "vuefire";
import { doc, getDoc, collection, Timestamp } from "firebase/firestore";
import cursorMixin from "@/mixins/cursorMixin";
import documentMixins from "@/mixins/DocumentArchive/documentMixins";
import imageMixin from "@/mixins/images/imageMixin";
import { isProxy, toRaw } from "vue";

// const documents = collection(db, "documents");
export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        ChevronUpDownIcon,
    },
    mixins: [cursorMixin, documentMixins, imageMixin, documentManagementMixin],
    watch: {
        currentDocument() {
            if (this.currentDocument.tone) {
                this.tone = this.currentDocument.tone;
                this.document.tone = this.currentDocument.tone;
                // this.saveDoc(this.document);
            }
        },
        tone(newVal, oldVal) {
            // handler: function (newVal, oldVal) {
            if (this.currentDocument.tone && newVal) {
                // console.error(this.document);
                this.document.tone = newVal;
                // this.tone = this.document.tone;

            }
            // },
            // immediate: false,
        },
    },
    created() {},
    async mounted() {
        await this.getDocument();
        this.tone = this.document.tone;
    },
    computed: {
        hasLoaded() {
            if (this.document && this.document.tone && this.document.tone.length > 0) {
                return true;
            }
        },
    },
    methods: {
        selectTone(tone) {
          this.document.tone = tone;
          this.saveDoc(this.document);
        },
        async rawDocument(id) {
            let docId = this.$route.params.id;
            if (id) {
                docId = id;
            }
            const d = await useDocument(doc(collection(db, "documents"), docId));
            if (d) {
                // this.document = d;
                setTimeout(() => {
                    const doc = d._rawValue;
                    this.currentDocument = toRaw(doc);
                    if (this.currentDocument && this.currentDocument.tone) {
                        // this.document.tone = doc.tone;
                        this.tone = this.currentDocument.tone;
                    } else {
                        // this.tone = this.tones[0];
                    }
                    this.setDocument(this.currentDocument);
                    return toRaw(this.currentDocument);
                }, 30);
            }
        },
        async getDocument() {
            // await this.$firestoreBind("document", document);
            if (this.$route.params.id) {
                // this.document = d;

                const doc = await this.rawDocument();
                setTimeout(() => {
                    return doc;
                }, 50);

                // this.savingStateInit(this.document);
            } else {
                // this.document = this.documents[0];
                // console.error(this.documents[0]);
                // this.savingStateInit(this.document);
            }
        },
    },
    data() {
        return {
            document: {},
            currentDocument: {},
          // tones: [
          //   {id: 1, label: "Expert"},
          //   {id: 2, label: "Professional"},
          //   {id: 3, label: "Formal"},
          //   {id: 4, label: "Friendly"},
          //   {id: 5, label: "Informal"},
          //   {id: 6, label: "Casual"},
          //   {id: 7, label: "Very casual"},
          //   {id: 8, label: "Legal"},
          // ],
            tones: [
                { label: "Expert", value: "Expert" },
                { label: "Professional", value: "Professional" },
                { label: "Formal", value: "Formal" },
                { label: "Friendly", value: "Friendly" },
                { label: "Informal", value: "Informal" },
                { label: "Casual", value: "Casual" },
                { label: "Very casual", value: "Very" },
                { label: "Legal", value: "Legal" },
                { label: "Neutral", value: "neutral" },
                { label: "Positive", value: "positive" },
                { label: "Frustrated", value: "frustrated" },
                { label: "Happy", value: "happy" },
                { label: "Excited", value: "excited" },
                { label: "Surprised", value: "surprised" },
                { label: "Confused", value: "confused" },
                { label: "Displeased", value: "displeased" },
                { label: "Doubtful", value: "doubtful" },
                { label: "Analytical", value: "analytical" },
                { label: "Confident", value: "confident" },
                { label: "Conversational", value: "conversational" },
                { label: "Casual", value: "casual" },
                { label: "Formal", value: "formal" },
                { label: "elegant", value: "elegant" },
                { label: "Familiar", value: "familiar" },
                { label: "expert", value: "expert" },
                { label: "Argumentative", value: "argumentative" },
                { label: "Critical", value: "critical" },
                { label: "Descriptive", value: "descriptive" },
                { label: "Expository", value: "expository" },
                { label: "Explanatory", value: "explanatory" },
                { label: "Factual", value: "factual" },
                { label: "Informative", value: "informative" },
                { label: "Persuasive", value: "persuasive" },
                { label: "Narrative", value: "narrative" },
                { label: "Reflective", value: "reflective" },
                { label: "Opinion", value: "opinion" },
            ],
            tone: null,
        };
    },
};
</script>
