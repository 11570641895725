import { mapGetters } from "vuex";
import extractText from "@/mixins/Chat/Commands/extract_text";
import webRequest from "@/mixins/ai/web_request";

export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            browse_website: {
                name: "Browse Website",
                command: "browse_website",
                kind: "browse",
                start: "[BST]",
                end: "[BND]",
                role: "url, question",
                args: { url: "", question: "" },
                description: "Browse a website to find specific information by providing a 'url' argument and a 'question' argument.",
                show: false,
                newMessage: true,
            },
            url: "",
            tags: null,
            readerViewEnabled: false,
            readerViewContent: null,
            customTags: "",
        };
    },
    computed: {
        ...mapGetters("presentation", ["queue"]),
    },
    methods: {
        async summarizeArticle(url) {
            // let message = functionMessage("New article");
            let args = { url: url };
            let functionObject = {
                content: "",
                name: "browse_website",
                arguments: args,
            };
            let content = this.browseWebsite(args);
            this.$store.dispatch("chat/addMessageStore", aiMessage(""));
            this.scrollChatWindowToBottom(true);
            await this.chatResponseReceived(undefined, undefined, "browse_website", functionObject);
            // this.sendMessage();
        },
        async browseWebsite(args) {
            let { url = "", looking_for = "" } = args;
            let { summary = "", page = "", links = [], images = [], metadata = {}, text } = await extractText(url, { url, looking_for });
            console.log(text);
            console.log(summary);
            console.log(page);
            return {
                url: args.url,
                content: text,
                page,
                summary,
                links: links || [],
                images: images || [],
                metadata: metadata || {},
            };
        },
        compressAndVisualizeWhitespace(inputString) {
            // Remove extra spaces, empty lines, and replace consecutive occurrences of '\n'
            try {
                const compressedString = inputString
                    .split("\n")
                    .map(line => line.trim())
                    .filter(line => line.length > 0)
                    .join("\n\n");
                return compressedString;
            } catch (error) {
                return inputString;
            }
        },
        async clickedURL(url) {
            if (url.includes("google.com")) {
                const response = await this.fetchTags(url, true, "a");
                console.log(response);
                this.message += response;
            } else {
                const response = await this.fetchTags(url, true, "");
                console.log(response);
                this.message += response;
            }
        },
        async fetchTags(url, readerViewEnabled, tags) {
            try {
                let request = {
                    url: url,
                    readerViewEnabled: readerViewEnabled,
                };
                if (tags) {
                    request.customTags = tags.split(",").map(tag => tag.trim());
                }
                const response = await webRequest("scrape", request);

                if (!response.ok) {
                    // throw new Error("Network response was not ok.");
                }

                const data = await response.json();
                console.log(data);
                this.tags = data.tags;
                let modifiedReaderContent = data.readerViewContent;
                // modifiedReaderContent.replace(/\n/g, "\\n").replace(/\t/g, "\\t").replace(/ /g, "\\s");
                this.readerViewContent = this.compressAndVisualizeWhitespace(modifiedReaderContent);
                return data;
            } catch (error) {
                console.error("Error while fetching tags:", error);
                this.tags = "Error: an error occurred while fetching the tags.";
            }
        },
        // ...mapActions("chat", ["addToMemoryStore"]),
    },
};
