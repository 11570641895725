import { doc, Timestamp, getDoc, query, collection, where, deleteDoc, addDoc, getDocs, orderBy, updateDoc, setDoc } from "firebase/firestore";

import firestore, { db } from "@/firebase";
import * as firebase from "@/firebase";
async function removeFromFirebase(database, item, preventReload) {
    try {
        // 'users' collection reference
        const docRef = doc(db, database, item);
        // const docRef = doc(db, database, item);
        // await removeDoc(colRef, dataObj);
        deleteDoc(docRef)
            .then(() => {
                console.log("An item has been deleted from " + database);
            })
            .catch(error => {
                console.log(error);
            });
        return;
        console.log("Document was removed with ID:", docRef.id);
        if (preventReload) {
        } else {
            await this.getDBItem(database);
        }
    } catch (e) {
        console.error(e);
        console.log(e.message, database, item);
    }
}

export default removeFromFirebase;
