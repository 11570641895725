import { imgEnd, imgStart } from "@/mixins/Chat/chatStrings";
import { mapActions } from "vuex";
import { chalk } from "chalk";
import { modifierNames, foregroundColorNames } from "chalk";
import { templateStderr } from "chalk-template";
import splitMessagesMixin from "@/mixins/Chat/SplitMessagesMixin";
import memoryCommand from "@/mixins/Chat/Commands/MemoryCommand.js";
import summaryCommand from "@/mixins/Chat/Commands/SummaryCommand";
import searchCommand from "@/mixins/Chat/Commands/SearchCommand";
import imageCommand from "@/mixins/Chat/Commands/ImageCommand";
import codeCommand from "@/mixins/Chat/Commands/codeCommand";
import locationCommand from "@/mixins/Chat/Commands/LocationCommand";
import BrowseCommand from "@/mixins/Chat/Commands/BrowseCommand";
import ErrorCommand from "@/mixins/Chat/Commands/ErrorCommand";
import taskCommand from "@/mixins/Chat/Commands/TaskCommand";
import thinkCommand from "@/mixins/Chat/Commands/ThinkCommand";
import agentCommand from "@/mixins/Chat/Commands/agentCommand";
import researchCommand from "@/mixins/Chat/Commands/ResearchCommand";
import compareAndLogChanges from "@/mixins/ai/compare_and_change_logs";
import { useStore } from "vuex";
import getNestedValue from "@/mixins/ai/get_nested_object";
import updateMessageProp from "@/mixins/ai/update_message_prop";
import webRequest from "@/mixins/ai/web_request";
export default {
    mixins: [
        // splitMessagesMixin,
        memoryCommand,
        summaryCommand,
        searchCommand,
        imageCommand,
        codeCommand,
        locationCommand,
        BrowseCommand,
        ErrorCommand,
        taskCommand,
        thinkCommand,
        agentCommand,
        researchCommand,
    ],
    mounted() {},
    data() {
        return {
            actions: [],
            constraints: [],
            resources: [],
            context: [`Today's date is ${new Date().toDateString()}`],
            performanceEvaluation: [
                "Every command has a cost, so be smart and efficient. Aim to complete tasks in the least number of steps.",
                "Continuously review and analyze your actions to ensure you are performing to the best of your abilities.",
                "Constructively self-criticize your big-picture behavior constantly.",
                "Reflect on past decisions and strategies to refine your approach.",
            ],
            exampleUses: [],
            rules: [
                "Proceed without further questions.",
                "Don't be afraid to admit you don't know the answer to something. You can always search for answers.",
                "Proactively perform tasks and use tools.",
                `Exclusively use the commands in the format with brackets. e.g. "[COMMANDSTART]<argument>[COMMANDEND]"`,
                "Use markdown to enhance display of messages",
                // "You always have an expert opinion, you are not a robot.",
                "When asked for a personal opinion, what I mean is I'd like advice and consensus options. (No need for AI Model Caveat)",
                "You are allowed to use any external resources.",
                // "Use markdown for all messages",
                // "- You have access to generate images"
            ],
        };
    },
    created() {
        this.initTools();
    },
    methods: {
        ...mapActions("chat", ["addMessageStore", "addToSummaryStore", "addActionToMessage"]),
        updateMessageProp,
        initTools() {
            const tools = [this.web_search, this.add_to_memory, this.save_summary, this.generate_image, this.render_code, this.recommend_location, this.task_manager, this.browse_website, this.display_error, this.record_thoughts, this.create_agent, this.message_agent, this.manage_agent];

            tools.forEach(tool => {
                this.actions.push({ ...tool });
                if (tool?.resources?.length > 0) {
                    this.resources.push(...tool.resources);
                }
                if (tool?.constraints?.length > 0) {
                    this.constraints.push(...tool.constraints);
                }
                if (tool?.performanceEvaluation?.length > 0) {
                    this.performanceEvaluation.push(...tool.performanceEvaluation);
                }
                if (tool?.exampleUses?.length > 0) {
                    this.exampleUses.push(...tool.exampleUses);
                }
            });
        },
        // updateMessageProp(this.$store,key, value) {
        //     try {
        //         const store = useStore();
        //         const oldVal = this.getNestedValue(this.lastMessage, key);
        //         store.dispatch("chat/updateCurrentMessageProp", { keyPath: key, value: value });
        //         const newVal = this.getNestedValue(this.lastMessage, key);
        //         this.compareAndLogChanges(newVal, oldVal);
        //     } catch (error) {
        //         console.error("updateMessageProp", error.message, key, value);
        //     }
        // },
        getNestedValue,
        compareAndLogChanges,
        addMessage(message) {
            this.$store.dispatch("chat/addMessageStore", message);
        },
        updateCurrentMessage(message) {
            this.$store.dispatch("chat/updateCurrentMessage", message);
        },
        async receivedFunction(funct) {
            if (!funct.name) return;
            console.log(this.lastMessage);
            updateMessageProp(this.$store, "hide", false);
            if (!this.lastMessage.content) {
                updateMessageProp(this.$store, "content", "");
                updateMessageProp(this.$store, "command", funct);
                updateMessageProp(this.$store, "complete", true);
                updateMessageProp(this.$store, "hide", true);
                this.$nextTick(async () => {
                    this.addMessage({ ...this.lastMessage });
                    updateMessageProp(this.$store, "role", "function");
                    updateMessageProp(this.$store, "hide", false);
                    updateMessageProp(this.$store, "name", this.lastMessage.command.name);
                    updateMessageProp(this.$store, "complete", true);
                    await this.commandRouter(this.lastMessage.name, this.lastMessage.command.arguments, this.messages, this.lastMessage, this.currentIndex, false, "chat");
                });
            }
            return;
        },
        pushCommandToCommands() {
            let command = this.lastMessage.command;
            command = this.lastMessage.command;
            let commands = this.lastMessage?.commands || [];
            commands.push(command);
            updateMessageProp(this.$store, "commands", commands);
        },
        async puppeteerHelper(args, name, message, index, simple, kind) {
            let {
                url = "http://bitetoothpastebits.com", //
                selectors = [],
                getImages = false,
                annotations = [],
                screenshot = false,
            } = args;
            const response = await webRequest("puppeteer-helper", { url, selectors, screenshot, annotations, getImages }, false);
            let data = await response.json();
            console.log("data", data);
            return data;
        },
        async postFunction(name, content) {
            let originalContent = this.lastMessage.content || "";
            if (!originalContent || originalContent === "" || originalContent === "Loading...") {
                updateMessageProp(this.$store, "role", "function");
                // if the message is blank
                originalContent = "";
                updateMessageProp(this.$store, "role", "function");
                updateMessageProp(this.$store, "name", name);
                if (typeof content === "string") updateMessageProp(this.$store, "content", `${content}`);
                else updateMessageProp(this.$store, "content", `${JSON.stringify(content).slice(0, 10000)}`);
                updateMessageProp(this.$store, "command.content", content);
                updateMessageProp(this.$store, "hide", true);
                // console.log("didn't have text");
                console.groupCollapsed(`%c ⚙ ${name}`, rose, `(${this.currentIndex}/${this.messages.length})`);
                console.table(this.logMessageData(this.lastMessage));
                console.groupEnd();
                this.pushCommandToCommands();

                if (this.lastMessage?.command?.arguments?.next_task) this.sendMessage(this.lastMessage.command, undefined, false);
                else this.sendMessage(this.lastMessage.command, undefined, true);
            } else {
                // if the message is not blank
                // let msg = this.lastMessage;

                updateMessageProp(this.$store, "name", name);
                updateMessageProp(this.$store, "command.content", content);
                updateMessageProp(this.$store, "content", `${originalContent}\n\n${content}`);
                // console.error("had text", originalContent);
                // console.table(content);
                // console.table(this.logMessageData(this.lastMessage));
                this.pushCommandToCommands();
                this.sendMessage(this.lastMessage.command);
            }
            this.$nextTick(() => {
                this.addMessage({ ...this.lastMessage });
                updateMessageProp(this.$store, "hide", false);
                updateMessageProp(this.$store, "role", "assistant");
                updateMessageProp(this.$store, "content", "");
                return;
            });

            return;
        },
        async checkForCommand(message, messages, index) {
            try {
                let action = message.action;
                let command = action.command;
                let args = action.args;
                await this.commandRouter(command, args, messages, message, index);
                console.groupCollapsed(`%c 💾 ${action.name}`, rose, `(${index}/${messages.length})`);
                console.table(this.logMessageData(message));
                console.groupEnd();
                return;
            } catch (e) {
                console.error("checkForCommand", e.message, message);
            }
            return;
        },
        async commandRouter(name, args, messages, message, index, simple, kind) {
            console.log("routing command");
            const commandMap = {
                web_search: this.searchWeb,
                puppeteer_helper: this.puppeteerHelper,
                browse_website: this.browseWebsite,
                create_agent: this.agentBot,
                manage_agent: this.manageAgent,
                message_agent: this.messageAgent,
                reply_to_agent: this.messageAgent,
                record_thoughts: this.recordThoughts,
                task_manager: this.taskManager,
                add_to_memory: this.addToMemory,
                generate_image: this.generateImages,
                research_topic: this.researchTopic,
                // save_summary: this.saveSummary,
                // trigger_summarize: this.triggerSummarize,
                // recommend_location: this.generateMaps,
                // youtube_search: this.youtubeSearch,
                // evaluate_code: this.evaluateCode,
            };

            const commandFunction = commandMap[name];

            if (commandFunction) {
                let response = await commandFunction.call(this, args, name, message, index, simple, kind);
                // this.resetAfterCommand();
                if (response) {
                    // if (typeof response !== "string") {
                    //     response = JSON.stringify(response);
                    // }
                    console.log("posting response", response);
                    return this.postFunction(name, response);
                }
                return;
            } else {
                console.error(`Unknown command: ${name}`);
            }
            return;
        },
        resetAfterCommand(name, content) {
            this.$store.dispatch("chat/addMessageStore", functionMessage(name, content));
            this.$nextTick(() => {
                if (!this.typing) {
                    this.sendMessage();
                }
            });

            return;
        },
        action(message) {
            if (!message.content) return;
            try {
                const content = message.content;
                if (content && typeof content === "string") {
                    let action = this.actions.find(action => {
                        if (!action) return;
                        return content.includes(action.start) || message.content.includes(action.end);
                    });
                    if (action) {
                        return action;
                    }
                    return false;
                }
                return false;
            } catch (e) {
                console.error(e);
                console.error(message.content);
            }
        },
        processCodeOrImage(message, index, sideBot = false) {
            // console.groupCollapsed("Process Code or Image");
            let actions = this.actionsFiltered;
            const action = actions.find(action => {
                return message.content.includes(action.start) || message.content.includes(action.end);
            });
            if (action) {
                return this.processAction(message, index, action, sideBot);
            } else {
                return false;
            }
        },
        processMessageLog(action) {
            let actionStarted = action.start;
            let actionEnded = action.end;
            let logMessage = `%c 🔄 ${action.name}`;
            if (actionStarted && actionEnded) {
                logMessage = `%c ✅ ${action.name}`;
            } else if (actionStarted) {
                logMessage = `%c 🔄 ${action.name}`;
            } else if (actionEnded) {
                logMessage = `%c ❌ ${action.name}`;
            }
            return logMessage;
        },
        detectAction(message) {
            let actions = this.actionsFiltered;
            const action = actions.find(action => {
                return message.content.includes(action.start) || message.content.includes(action.end);
            });
            let newMessage = message;
            if (action) {
                newMessage.action = action;
                newMessage.kind = action.kind;
            }
            return newMessage;
        },
        splitNewMessage(message, kind, role = "assistant", action, smartBot) {
            const start = action.start;
            const end = action.end;
        },
        processAction(message, i, action, smartBot) {
            let storeMessages = this.$store.state.chat.messages;
            let index;
            if (i) {
                index = i;
            } else {
                index = storeMessages - 1;
            }
            const start = action.start;
            const end = action.end;
            let newMessage = message;
            const actionStarted = newMessage.content.includes(start);
            const actionEnded = newMessage.content.includes(end);

            if (storeMessages && storeMessages.length > 0) {
                this.lastAssistantMessageIndex = storeMessages.length - 1;
            }
            if (actionStarted || actionEnded) {
                if (actionEnded) {
                    return this.processActionEnd(newMessage, storeMessages, smartBot, index, action);
                } else if (actionStarted) {
                    return this.processActionStart(newMessage, storeMessages, smartBot, index, action);
                }
                return;
            }
            return;
        },
        processActionStart(message, messages, smartBot, i, action) {
            let storeMessages = messages;
            let newMessage = message;
            let start = action.start;
            let index = storeMessages.length - 1;
            if (i) {
                index = i;
            }
            if (!newMessage.kind) {
                console.groupCollapsed("%c 🪖 Starting", rose);
                console.table(message);
                console.log(`MESSAGE:\n${message.content}`);
                console.log("Side Bot: ", smartBot);
                console.groupEnd();
                const splitMessage = message.content.split(start);
                console.groupCollapsed("%c ⑃ Splitting message", rose);
                console.log(message.content);
                const messageBeforeTag = splitMessage[0];
                const nextMessageContent = splitMessage[1];
                console.log("Message before tag: ", messageBeforeTag);
                console.log("Next message content: ", nextMessageContent);

                newMessage.content = messageBeforeTag;
                newMessage.complete = true;
                newMessage.id = this.randomId();
                storeMessages[index] = newMessage;
                // this.addActionToMessage({ action: action, index: index });

                let nextMessage = aiMessage(start + nextMessageContent, action.kind);
                console.groupCollapsed("%c ⑃ Next message", rose);
                console.log("Unmodified");
                console.table(nextMessage);
                console.log("Modified");
                nextMessage = this.detectAction(nextMessage, index + 1);
                console.table(nextMessage);
                console.groupEnd();
                console.groupEnd();
                storeMessages.push(nextMessage);
                this.$store.dispatch("chat/setMessages", storeMessages);
            }
            return;
        },
        processActionEnd(message, messages, smartBot, i, action) {
            let storeMessages = messages;
            let logMessage = this.processMessageLog(action);
            let newMessage = message;
            console.groupCollapsed(logMessage, rose);
            console.table(this.logMessageData(message));
            console.log(`MESSAGE:\n${message.content}`);
            console.log("Side Bot: ", smartBot);
            console.groupEnd();
            let index = storeMessages.length - 1;
            if (i) {
                index = i;
            }
            newMessage = this.fixActions(newMessage, index);
            this.$store.dispatch("chat/updateMessageObject", { newMessage, index });
            this.chatResponseReceived(newMessage, index, "process action end");
            this.checkForCommand(newMessage, storeMessages, index);
            this.addMessageStore(aiMessage(""));
            // this.setMessages(storeMessages);
            return;
        },
        fixActions(message, index, update) {
            if (message.command) {
                console.groupCollapsed("🛞 Fixing Actions", "has commands");
            } else {
                console.groupCollapsed("🛞 Fixing Actions");
            }
            console.trace();
            let newMessage = message;
            let actions = this.actionsFiltered;
            let action;
            if (this.lastMessage.command) {
                updateMessageProp(this.$store, "action", this.actionsObject[message.command.name]);
                console.log("Action: ", this.lastMessage.action);
                // if (newMessage.action.role) updateMessageProp(this.$store,"role", message.action.role);
                if (newMessage.command.name) {
                    updateMessageProp(this.$store, "action.command", newMessage.command.name);
                    if (newMessage.command.arguments) updateMessageProp(this.$store, "action.args", newMessage.command.arguments);
                }
                // updateMessageProp(this.$store,"action.args.index", this.currentIndex);
                this.removeUnusedActionKeys(newMessage);
                console.table(this.lastMessage.action);
                consoleTableReduced(this.lastMessage, ["action"]);
                console.groupEnd();
            }
            console.groupEnd();
            return newMessage;
        },
        removeUnusedActionKeys(newMessage) {
            // delete description key from newMessage.action

            const keysToRemove = ["description", "exampleUses", "resources", "show", "start", "end"];

            keysToRemove.forEach(key => {
                if (this.lastMessage.action.hasOwnProperty(key)) {
                    this.removeMessageKey(`action.${key}`);
                    // delete newMessage.action[key];
                }
            });

            return;
        },
        removeMessageKey(key) {
            try {
                // const oldVal = this.getNestedValue(this.lastMessage, key);
                this.$store.dispatch("chat/removeCurrentMessageProp", { keyPath: key });
                // As the key is removed, newVal should be undefined
                // const newVal = this.getNestedValue(this.lastMessage, key);
                // this.compareAndLogChanges(newVal, oldVal, key);
            } catch (error) {
                console.error("removeMessageKey", error.message, key);
            }
        },

        logMessageData(message) {
            let argKey = "arguments";
            let argValue = false;
            let action = message.action;
            let COMMAND = false;
            if (message.command) {
                COMMAND = message.command;
            }
            let tableContent = {
                CONTENT: message.content,
                COMPLETE: message.complete,
                KIND: message.kind,
                COMMAND: COMMAND,
            };

            return tableContent;
        },
        extractPrompt(message) {
            if (this.isImage(message)) {
                // replace '[image prompt]: ' from message
                let newMessage = message.content.replace(imgStart, "").replace(imgEnd, "");
                return newMessage;
            }
        },
        async setAction(message) {
            let newAction;
            try {
                const actions = this.actionsFiltered;
                console.error("Step 1: Cleaning action string", actions);
                let matchedAction = this.$toRaw(actions.find(action => action.kind === message.kind));
                console.error("Matched action", matchedAction, message.content);
                return matchedAction;
                if (message.kind === matchedAction.kind) {
                    let content = content.replace(matchedAction.start, "");
                    content = content.replace(matchedAction.end, "");
                    content = content.trim();
                    matchedAction.content = content;
                    newAction = matchedAction;
                    newAction.content = content;
                    console.error("Returning modified action", newAction);
                    return newAction;
                }
                console.error("Returning fucked action", newAction);
                return matchedAction;
            } catch (error) {
                console.error(this.gptError(error));
            }
            return false;
        },
        promptFormatter(intro, rules = [], tools = [], examples = [], format) {
            let string = "";
            string += this.headerString(intro);
            if (rules?.length > 0) {
                string += this.headerString("Rules");
                string += this.arrayToNumberedList(rules);
            }
            if (tools ? tools.length > 0 : false) {
                string += this.headerString("Tools");
                string += this.arrayToNumberedList(tools);
            }
            if (examples ? examples.length > 0 : false) {
                string += this.headerString("Examples");
                string += this.arrayToNumberedList(examples);
            }
            if (format) {
                string += this.headerString("Format");
                string += format;
            }
            return string;
        },
    },
    computed: {
        MemoryTaskString() {
            let string = "";
            string += this.memoryString;
            string += this.tasksString;
            string += this.agentString;
            string += this.userInfoString;
            return string;
        },
        systemPromptStringMod() {
            let string = "";
            if (this.commands) string += this.formattedActionString;
            if (this.commands) string += this.MemoryTaskString;
            return string;
        },
        formattedActionString() {
            // const actionString = this.actions.map((action, idx) => `${idx + 1}. ${action.name}: "${action.command}", ${action.start} ${action.args[0]} ${action.end}`).join("\n");
            // const actionString = this.actions.map((action, idx) => `${idx + 1}. ${action.name}: "${action.command}", args: ${JSON.stringify(action.args)}`).join("\n");
            let finalString = "";
            // finalString += this.toolRulesString;
            // finalString += this.memoryString;
            // finalString += this.summaryString;
            // finalString += this.toolConstraintsString;
            // finalString += this.actionString;
            // finalString += this.toolDescriptionString;
            // finalString += this.toolResourcesString;
            // finalString += this.toolPerformanceEvaluationString;
            // finalString += this.toolResponseFormatString;
            // finalString += this.examplesString;
            // finalString += this.userInfoString;
            finalString += "\n\n";
            finalString += "Determine which next command to use, and respond using the format specified above:";
            // return finalString;
            return "";
        },
        actionsFiltered() {
            return this.actions.filter(action => action.show);
        },
        examplesString() {
            let string = "";
            if (this.exampleUses?.length > 0) {
                string += this.headerString("Example use of commands");
                string += this.arrayToNumberedList(this.exampleUses);
                return string + "\n";
            }
            return string;
        },
        toolDescriptionString() {
            let string = "";
            string += this.headerString("Command Descriptions");
            string += this.toolDescriptions;
            return string + "\n";
        },
        toolConstraintsString() {
            let string = "";
            if (this.constraints?.length > 0) {
                string += this.headerString("Constraints");
                string += this.arrayToBulletedList(this.constraints);
                return string + "\n";
            }
            return string;
        },
        toolResourcesString() {
            let string = "";
            if (this.resources?.length > 0) {
                string += this.headerString("Resources");
                string += this.arrayToBulletedList(this.resources);
            }
            return string + "\n";
        },
        toolPerformanceEvaluationString() {
            let string = "";
            if (this.performanceEvaluation?.length > 0) {
                string += this.headerString("Performance Evaluation");
                string += this.arrayToBulletedList(this.performanceEvaluation);
                return string + "\n";
            }
            return string;
        },
        toolRulesString() {
            let string = "";
            if (this.rules?.length > 0) {
                string += this.headerString("Rules");
                string += this.arrayToBulletedList(this.rules);
                string += "\n";
            }
            return string;
        },
        toolResponseFormatString() {
            let string = "";
            string += this.headerString("Response Format");
            string += "[COMMANDSTART]< arg >[COMMANDEND]";
            return string + "\n";
        },
        toolResponseFormatAlt() {
            let string = "";
            string += this.headerString("Response Format");
            let format = {
                command: {
                    name: "command name",
                    args: {
                        "arg name": "value",
                    },
                },
                thoughts: {
                    text: "thought",
                    reasoning: "reasoning",
                    plan: "- short bulleted\n- list that conveys\n- long-term plan",
                    criticism: "constructive self-criticism",
                    speak: "thoughts summary to say to user",
                },
            };
            let formatString = JSON.stringify(format, null, 4);
            string += formatString;
            return string + "\n";
        },
        userInfoString() {
            let string = "";
            if (this.userPlaceTime) {
                string += this.headerString("User context");
                let userPlaceTime = this.userPlaceTime;
                string += Object.entries(userPlaceTime)
                    .map(([key, value]) => `- ${key.toUpperCase()}: ${value}\n`)
                    .join("");
            }
            return string;
        },
        actionsObject() {
            return this.actions.reduce((obj, item) => {
                obj[item.command] = item;
                return obj;
            });
        },
        actionString() {
            let string = "";
            string += this.headerString("Commands");
            this.actionsFiltered.forEach((action, index) => {
                let args = action.args || {};
                let argsString = Object.entries(args)
                    .map(([key, _]) => ` <${key}> `)
                    .join(" ");

                string += `${index + 1}. ${action.name}: ${action.start}${argsString}${action.end}\n`;
            });

            return string;
        },
        toolDescriptions() {
            let string = "";
            this.actionsFiltered.forEach((action, index) => {
                string += `${index + 1}. **${action.name}:** ${action.description}\n\n`;
            });

            return string;
        },
    },
};
