<template>
    <div>
        <template v-if="!user">
            <div class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <simple-alert v-if="failed" title="Failed to login" description="Please check your email and password" type="error" />
                <div class="sm:mx-auto sm:w-full sm:max-w-md">
                    <div class="relative">
                        <logo class="mw-200 mx-auto relative block" style="width: 150px" width="100"></logo>
                    </div>
                    <h2 class="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        {{ showRegister ? "Sign up for an account" : "Sign in to your account" }}
                    </h2>
                </div>
                <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form @submit.prevent="showRegister ? register() : login()" class="space-y-6">
                            <SimpleInput id="email" v-model="email" autocomplete="current-email" label="Email address" required type="email" />
                            <SimpleInput id="password" v-model="password" autocomplete="current-password" label="Password" required type="password" />
                            {{ email }}
                            <div class="flex items-center justify-between" v-if="!showRegister">
                                <div class="flex items-center">
                                    <input id="remember-me" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" name="remember-me" type="checkbox" />
                                    <label class="ml-3 block text-sm leading-6 text-gray-900" for="remember-me">Remember me</label>
                                </div>

                                <div class="text-sm leading-6">
                                    <SimpleLink>Forgot password?</SimpleLink>
                                </div>
                            </div>

                            <div>
                                <SimpleButton class="w-full" type="submit">{{ showRegister ? "Sign up" : "Sign in" }}</SimpleButton>
                            </div>
                        </form>

                        <div class="mt-4 text-center">
                            <template v-if="showRegister">
                                Already have an account?
                                <SimpleLink @click="showRegister = false">Sign in here</SimpleLink>
                            </template>
                            <template v-else>
                                Don't have an account?
                                <SimpleLink @click="showRegister = true">Create one here</SimpleLink>
                            </template>
                        </div>

                        <div class="mt-6">
                            <div class="relative">
                                <div aria-hidden="true" class="absolute inset-0 flex items-center">
                                    <div class="w-full border-t border-gray-200" />
                                </div>
                                <div class="relative flex justify-center text-sm font-medium leading-6">
                                    <span class="bg-white px-4 text-gray-900">Or continue with</span>
                                </div>
                            </div>

                            <div class="mt-4 grid grid-cols-1 gap-4">
                                <SimpleButton secondary shimmer @click="signInWithGoogle">
                                    <IconGoogle />
                                    Google
                                </SimpleButton>
                                <!--                                <SimpleButton secondary shimmer class="grayscale o-2" disable><i class="fab fa-github fa-xl"></i>Github</SimpleButton>-->
                            </div>
                        </div>
                    </div>

                    <p class="mt-4 text-center text-sm text-gray-500" v-if="!showRegister">
                        Not a member?
                        {{ " " }}
                        <SimpleLink @click="showRegister = true">Start a 14 day free trial</SimpleLink>
                    </p>
                </div>
            </div>
        </template>
        <Profile v-else />
    </div>
</template>

<script>
import moment from "moment";
import user from "@/mixins/usermixin";
import SimpleButton from "@/views/SimpleButton.vue";
import SimpleLink from "@/views/SimpleLink.vue";
import Logo from "@/components/CoreUI/navigaion/logo.vue";
import IconGoogle from "@/views/IconGoogle.vue";
import SimpleInput from "@/views/SimpleInput.vue";
import Profile from "@/views/Profile.vue";
import { mapGetters } from "vuex";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import SimpleAlert from "@/views/SimpleAlert.vue";
import { collection, setDoc, doc, Timestamp, getDoc, query, where, deleteDoc, addDoc } from "firebase/firestore";
import { mapActions } from "vuex";
import { auth, db, googleAuthProvider } from "@/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";

export default {
    name: "LogIn",
    components: { SimpleAlert, SimpleInput, IconGoogle, Logo, SimpleLink, SimpleButton, Profile },
    mixins: [user],
    data() {
        return {
            email: "",
            password: "",
            showRegister: false,
            userDescription: "",
            failed: false,
        };
    },
    computed: {
        ...mapGetters("styleGuide", ["styleGuide"]),
        createdAtFormatted() {
            if (this.user && this.user.createdAt) {
                return moment(parseInt(this.user.createdAt)).format("MMMM Do YYYY");
            }
        },
    },
    methods: {},
};
</script>
