<template>
    <div class="p-relative z-0" :class="`${slide.background_class}  ${slide.text_class}`" style="overflow: hidden; border-radius: 10px">
        <!--        <SlideLeftMini v-if="blockStyle === 'left'" :document="document" :block-style="blockStyle" :index="index" :slide="slide"></SlideLeftMini>-->
        <SlideLeftMini v-if="slideStyle === 'left'" :document="document" :image="image" :title="title" :text="text" :index="index" :slide="slide"></SlideLeftMini>
        <SlideRightMini v-else-if="slideStyle === 'right'" :document="document" :image="image" :title="title" :text="text" :index="index" :slide="slide"></SlideRightMini>
        <SlideFullbleedMini v-else-if="slideStyle === 'fullbleed'" :image="image" :document="document" :title="title" :text="text" :index="index" :slide="slide"></SlideFullbleedMini>
        <SlideOtherMini v-else :document="document" :block-style="blockStyle" :image="image" :title="title" :text="text" :index="index" :slide="slide"></SlideOtherMini>
    </div>
</template>
<script>
import SlideFullbleedMini from "@/components/Presentations/slides/smallSlides/FullbleedSlideMini.vue";
import SlideLeftMini from "@/components/Presentations/slides/smallSlides/LeftSlideMini.vue";
import SlideOtherMini from "@/components/Presentations/slides/smallSlides/OtherSlideMini.vue";
import SlideRightMini from "@/components/Presentations/slides/smallSlides/RightSlideMini.vue";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import {mapGetters} from 'vuex';
export default {
    name: "PresentationMiniSlide",
    components: { SlideOtherMini, SlideFullbleedMini, SlideRightMini, SlideLeftMini },
    mixins: [SlideStyleMixin],
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    watch: {
        "slide.style": {
            handler: function (val, oldVal) {
                if (val !== oldVal && val !== null) {
                    this.blockStyle = this.slideStyle;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    data() {
        return {
            blockStyle: null,
        };
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        text() {
            return this.slide.enhancedBody || this.slide.body;
        },
        title() {
            return this.slide.title;
        },
        image() {
            try {
                return this.slide?.images[0] || this.slide?.imageURL || null;
            } catch (e) {
                return false;
            }
        },
        slide() {
            return this.$store.state?.presentation?.document?.sections[this.index];
        },
        slideStyle() {
            let slideStyle = "left";
            if (this.slide && this.slide.style) slideStyle = this.slide.style.toLowerCase();
            if (slideStyle.includes("right") || slideStyle.includes("imageRight") || slideStyle.includes("rSlide")) {
                return "right";
            } else if (slideStyle.includes("left")) {
                return "left";
            } else if (slideStyle.includes("matrix")) {
                return "matrix";
            } else if (slideStyle.includes("timeline")) {
                return "timeline";
            } else if (slideStyle.includes("tablegrid") || slideStyle.includes("list")) {
                return "tablegrid";
            } else if (slideStyle.includes("fullbleed") || slideStyle.includes("full bleed") || slideStyle.includes("fullBleedImage")) {
                return "fullbleed";
            } else {
                return "left";
            }
        },
    },
    mounted() {
        // this.blockStyle = this.slideStyle;
    },
    methods: {},
};
</script>
