<template>
    <div class="block mb-3 ease-back duration-2 animated fadeInUpSmooth d-2 relative" @mouseenter="hovered = true" @mouseleave="(hovered = false), (hoverEdit = false)">
        <PresentationMarkdown v-if="!isEditing" :class="{ 'mb-6 transition-all': hovered }" :content="text" :style="styleString"></PresentationMarkdown>
        <EditSectionIndicator v-if="hovered || isEditing" key="bodyText" :editing="isEditing" :identifier="identifier" class="absolute z-0" @click="isEditing = true" @mouseenter="hoverEdit = true" />
        <EditingFont :editing="isEditing" :font-size="fontSize" :hovered="isHovered" :identifier="identifier" :index="index" :max-size="maxFontSize" :min-size="minFontSize" :section="section" class="block x" kind="body" @hover-edit="hoverEdit = true" @changed-font-size="editFontSize" @font-done-changing="editFontSize" />
        <contenteditable v-if="isEditing" ref="contenteditable" v-model="textEdit" :class="{ 'mb-6 transition-all': hovered }" :style="styleString" class="relative block z-6" data-placeholder="Enter your text here" tag="p" @blur="doneEditing"></contenteditable>
    </div>
</template>
<script>
import EditSectionIndicator from "@/components/generative_templates/Editing/EditSectionIndicator.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";
import contenteditable from "vue-contenteditable";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import EditingFont from "@/components/generative_templates/Editing/EditingFont.vue";

export default {
    name: "BodyText",
    components: { EditingFont, PresentationMarkdown, EditSectionIndicator, contenteditable },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin],
    props: {
        text: { type: String, default: "" },
        font: {
            type: Object,
            default: () => ({}),
        },
        defaultSize: { type: [String, Number], default: "" },
        index: { default: 0, type: Number, required: true },
        textColorOverride: {},
        fontOverride: {},
        identifier: { type: String, required: true },
        section: { type: Object, default: () => {} },
    },
    data() {
        return {
            hovered: false,
            fontSize: 25,
            hoverEdit: false,
            isEditing: false,
            minFontSize: 15,
            maxFontSize: 30,
            textEdit: "",
        };
    },
    watch: {
        isEditing: {
            handler(val, oldVal) {
                if (val && val !== oldVal) this.$nextTick(() => this.$refs.contenteditable.$refs.element.focus());
            },
        },
    },
    computed: {
        styleString() {
            return [this.fontString, `font-size:${this.fontSize}px`, `color:${this.textColorOverride}`];
        },
        fontString() {
            if (this.fontOverride) return this.fontOverride;
            else return this.font.body;
        },
    },
    mounted() {
        if (this.defaultSize) this.fontSize = this.defaultSize;
        this.textEdit = this.text;
    },
    methods: {
        editFontSize(number, save) {
            this.fontSize = number;
            if (save) this.updateSection("headerSize", number);
        },
        doneEditing(event) {
            this.updateSection("body", this.textEdit);
            this.isEditing = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.range-input[type="range"] {
    @apply cursor-pointer;
}
</style>
