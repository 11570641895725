<template>
    <div :class="{ 'jcb aic': !stack, 'jcs  text-left fc text-pretty': stack }" class="editor-section f">
        <span class="text-capitalize editor-section-title">{{ title }}</span>
        <template v-if="date">
            <time-stamp v-if="detail" :date="detail" />
        </template>
        <template v-else-if="detail">
            <span class="text-xs">{{ detail }}</span>
        </template>
        <template v-else><slot></slot></template>
    </div>
</template>
<script>
import TimeStamp from "@/views/Chat/TimeStamp.vue";

export default {
    name: "EditorSectionPrimative",
    components: { TimeStamp },
    props: {
        title: {
            type: String,
            default: "",
        },
        detail: {
            type: [String, Date],
        },
        stack: {
            type: Boolean,
            default: false,
        },
        date: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
