<template>
    <div style="text-wrap: balance" v-if="text" @blur="isEditing = false" @mouseenter="hovered = true" @mouseleave="hovered = false">
        <!--        <Markdown v-if="!isEditing" :class="[computedMarkdownClass, { hoveredClass: hovered }]" :content="text" :style="styleString" @click="isEditing = true"></Markdown>-->
        <EmailMarkdown v-if="!isEditing" :class="[computedMarkdownClass, { hoveredClass: hovered }]" :content="text" :style="styleString" @click="isEditing = true"></EmailMarkdown>
        <!--        <PresentationMarkdown v-if="!isEditing" :class="[computedMarkdownClass, { hoveredClass: hovered }]" :content="text" :style="styleString" @click="isEditing = true"></PresentationMarkdown>-->
        <EditSectionIndicator v-if="hovered || isEditing" class="absolute z-0" :editing="isEditing" :key="editKey" @click="isEditing = true" @mouseenter="hoverEdit = true" />
        <EditingFont
            :editing="isEditing"
            :font-size="fontSize"
            :hovered="hovered"
            :identifier="identifier"
            :index="index"
            :max-size="maxSize"
            :min-size="minSize"
            :font-override="font.header"
            :section="section"
            class="block x"
            :kind="textType"
            @update-font-name="updateFontName"
            @hover-edit="hoverEdit = true"
            @changed-font-size="editFontSize"
            @font-done-changing="editFontSize" />
        <contenteditable v-if="isEditing" ref="contenteditable" v-model="textEdit" :class="computedClass" :style="styleString" class="ease-back relative block z-6" data-placeholder="Enter your text here" tag="span" @blur="doneEditing"></contenteditable>
        <SimpleButton class="mt-3 x f-20" rounded tooltip="Save This section (it also saves automatically)" @click="doneEditing" v-if="isEditing">Done</SimpleButton>
    </div>
</template>

<script>
import EditSectionIndicator from "@/components/generative_templates/Editing/EditSectionIndicator.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";
import contenteditable from "vue-contenteditable";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import EditingFont from "@/components/generative_templates/Editing/EditingFont.vue";
import Markdown from "@/components/chat/specialMessages/Markdown.vue";
import EmailMarkdown from "@/components/Presentations/EmailMarkdown.vue";
import SimpleButton from "@/views/SimpleButton.vue";

export default {
    name: "EditableText",
    components: { SimpleButton, EmailMarkdown, Markdown, EditingFont, PresentationMarkdown, EditSectionIndicator, contenteditable },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin],
    props: {
        text: { type: String, default: "" },
        textType: { type: String, default: "body" }, // New prop to determine the type
        sectionFont: { type: Object, default: () => ({}) },
        defaultSize: { type: [String, Number], default: "" },
        // fullBleed: { type: Boolean, default: false },
        index: { type: [Number, String], default: 0, required: true },
        textColorOverride: { type: String, default: "" },
        fontOverride: { type: String, default: "" },
        identifier: { type: String, required: true },
        hoveredClass: { type: String, default: "mb-6 transition-all" },
        section: { type: Object, default: () => {} },
        minSize: { type: Number, default: 15 },
        maxSize: { type: Number, default: 40 },
        identifierDetails: { type: String, default: "" },
    },
    data() {
        return {
            hovered: false,
            fontSize: 20,
            hoverEdit: false,
            isEditing: false,
            textEdit: "",
        };
    },
    computed: {
        // styleString() {
        //     let fontValue = this.fontOverride ? this.fontOverride : this.font[this.textType] || this.font.body;
        //     return [fontValue, `font-size:${this.fontSize}px!important;`, `color:${this.textColorOverride}`];
        // },
        computedClass() {
            if (this.textType === "header") {
                return {
                    "!f-80": this.fullBleed,
                    "!f-60": !this.fullBleed,
                };
            }
        },
        styleString() {
            let wrap = "pretty";
            // wrap = "balance";
            return [this.fontString, `font-size:${this.fontSize}px!important; text-wrap: ${wrap}!important;`, `color:${this.textColorOverride}`];
        },
        fontString() {
            if (this.fontOverride) return this.fontOverride;
            else return this.font[this.textType] || this.font.body;
        },
        computedMarkdownClass() {
            let classes = [];
            if (this.textType === "header") {
                if (this.fullBleed) classes.push("!f-80");
                else classes.push("!f-60");
            }
            if (this.hovered) classes.push(this.hoveredClass);
            return classes.join(" ");
        },
        editKey() {
            return `${this.textType}Text`;
        },
    },
    watch: {
        text: {
            handler(val) {
                this.textEdit = val;
            },
        },
        isEditing: {
            handler(val, oldVal) {
                if (val && val !== oldVal) this.$nextTick(() => this.$refs.contenteditable.$refs.element.focus());
            },
        },
    },
    created() {
        if (this.defaultSize) this.fontSize = this.defaultSize;
    },
    mounted() {
        this.textEdit = this.text;
        if (this.defaultSize) this.fontSize = this.defaultSize;
    },
    methods: {
        editFontSize(number, save) {
            this.fontSize = number;
            if (save) this.updateSection(`${this.textType}Size`, number);
        },
        doneEditing() {
            if (this.textType === "custom") this.updateSection(this.identifierDetails, this.textEdit);
            else if (this.textType === "subheader") this.updateSection("sub_header", this.textEdit);
            else if (this.textType === "header") this.updateSection("header", this.textEdit);
            else if (this.textType === "body") this.updateSection("body", this.textEdit);
            else this.updateSection(this.textType + "Text", this.textEdit);
            this.isEditing = false;
        },
        updateFontName(newFont) {
            // Method to handle font name updates
        },
    },
};
</script>

<style lang="scss">
.range-input[type="range"] {
    @apply cursor-pointer;
}
</style>
