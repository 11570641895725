<template>
    <div class="md:p-3">
        <h4 class="md:mb-3" v-if="title">{{ title }}</h4>
        <ol class="br-10 overflow-hidden border-light" :style="`border:1px solid ${color}`">
            <li v-for="(example, index) in examples" :key="index" class="f-20 fwn p-2 md:p-4 bg-snow/20 border-bottom brandBg fwn mb-0 border-l-0 border-r-0 last:!border-none" :style="`border-bottom:1px solid ${color};`">
                <div class="list-inside p-2" :style="[]">
                    <span style="font-weight: 400 !important" class="p">{{ example }}</span>
                </div>
            </li>
        </ol>
    </div>
</template>
<script>
export default {
    name: "CopyGuidelinesExamples",
    props: {
        color: {},
        examples: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: "Examples",
        },
    },
};
</script>
