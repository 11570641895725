<template>
    <div>
        <label>Brand name</label>
        <input :value="value" class="form-input" placeholder="Your brand's name" type="text" @input="$emit('input', $event.target.value)" />
    </div>
</template>

<script>
export default {
    name: "BasicInput",
    props: {
        value: { type: String, default: "" },
    },
    emits: ["input"],
};
</script>
