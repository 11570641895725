<template>
    <div class="f gap-3 aic jce">
        <BaseButton v-if="kind === 'Shopify'" class="" icon="fas fa-images" icon-only label="Resync" size="xxs" style-type="secondary" tooltip="sync images" @click.prevent="$emit('syncSource')" />
        <BaseButton class="" icon="fas fa-sync" icon-only label="Resync" size="xxs" style-type="secondary" tooltip="sync products" @click.prevent="$emit('syncSource')" />
        <BaseButton class="" icon="fas fa-trash" icon-only label="Remove" size="xxs" style-type="secondary" @click.prevent="$emit('removeSource')" />
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "SourceButtonPile",
    components: { BaseButton },
    props: {
        kind: {
            type: String,
            default: "Website",
        },
        url: {
            type: String,
            default: "",
        },
    },
    emits: ["syncSource", "removeSource"],
};
</script>
<style lang="scss" scoped>
table {
    //border: 1px solid black;
}

thead {
    //border: 1px solid black !important;
    @apply border border-solid border-b;
    @apply border-gray-200;
}

th {
    border: 0px !important;
}

th,
tr {
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    @apply bg-gray-100;
}

th,
td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #e5e7eb;
    @apply border-gray-200;
    @apply border-b-gray-200;
}

th {
    font-weight: 500;
}

tbody tr:nth-child(odd) {
    @apply bg-gray-50;
}

tbody tr:hover {
    @apply bg-gray-100/80;
    cursor: pointer;
}

@media (max-width: 768px) {
    th,
    td {
        padding: 8px;
    }
}
</style>
