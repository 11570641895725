<template>
    <TransitionRoot :show="open" as="template">
        <Dialog as="div" class="relative z-10" @close="close">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black-500/10 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                            <div>
                                <div :class="{ 'bg-green-100': !loading, 'bg-purple-100': loading }" class="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                                    <!--                                  loading icon -->
                                    <i v-if="loading" class="fas fa-sync fa-spin ease-back duration-3 text-purple-500"></i>
                                    <CheckIcon v-else aria-hidden="true" class="h-6 w-6 text-green-600" />
                                </div>
                                <div class="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ title }}</DialogTitle>
                                    <div class="mt-2">
                                        <p class="text-sm text-gray-500">{{ description }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 f fc gap-3">
                                <!--                                  https://www.klaviyo.com/email-editor/-->
                                <template v-if="link">
                                    <div class="x mx-auto text-center">
                                        <h6 class="mx-auto mb-2">View on Klaviyo</h6>
                                        <div class="f gap-3">
                                            <a class="x" :href="`https://www.klaviyo.com/email-editor/${link}/edit`" rel="noopener noreferrer" target="_blank">
                                                <SimpleButton v-if="button && button !== ''" class="x" secondary>Edit</SimpleButton>
                                            </a>
                                            <a class="x" :href="`https://www.klaviyo.com/email-editor/${link}/preview`" rel="noopener noreferrer" target="_blank">
                                                <SimpleButton v-if="button && button !== ''" class="x" secondary>Preview</SimpleButton>
                                            </a>
                                        </div>
                                    </div>
                                </template>
                                <!--                              https://www.klaviyo.com/email-editor/RmctH8/preview-->
                                <SimpleButton v-else-if="button && button !== ''" class="mx-auto x" type="button" @click="close">{{ button }}</SimpleButton>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/outline";
import SimpleButton from "@/views/SimpleButton.vue";

export default {
    components: { SimpleButton, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, CheckIcon },
    data() {
        return {
            brand: {},
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        button: {
            type: String,
            default: "",
        },
        // email_id: {
        //     type: String,
        //     default: "",
        // },
        link: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "Payment successful",
        },
        description: {
            type: String,
            default: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.",
        },
    },
};
</script>
