<template>
    <div v-if="sections?.length > 0" class="menu_section">
        <CollapsableMenuItem>
            <template #title>
                <p class="fwb">Sections</p>
            </template>
            <div>
                <CollapsableMenuItem v-for="(section, sectionIndex) in sections" inline class="px-0 border-bottom py-2 f-15 x" :key="`section${sectionIndex}`">
                    <template #title>
                        <div class="f jcs gap-4 aic">
                            <h6 class="fwb">Section {{ sectionIndex + 1 }}</h6>
                            <span v-if="section.type" class="f-11 mb-0 text-uppercase fwb o-5">{{ section.type }}</span>
                        </div>
                    </template>
                    <div>
                        <template v-for="element in ['planning', 'header', 'body']">
                            <div v-if="section[element]">
                                <template v-if="Array.isArray(section[element])">
                                    <h6 class="fwb">{{ element }}</h6>
                                    <ul class="pl-3 f-17 my-3">
                                        <li v-for="(item, index) in section[element]" :key="`item${index}`">
                                            {{ item }}
                                        </li>
                                    </ul>
                                </template>
                                <div class="f-17" v-else>
                                    <h6 class="fwb">{{ element }}</h6>
                                    {{ section[element] }}
                                </div>
                            </div>
                        </template>
                    </div>
                </CollapsableMenuItem>
            </div>
        </CollapsableMenuItem>
    </div>
</template>
<script>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";

export default {
    name: "SideBarSections",
    components: { CollapsableMenuItem },
    props: {
        sections: {},
    },
};
</script>
<style lang="scss" scoped>
h6 {
    @apply mb-0 font-bold;
}

.menu_section {
    border-style: solid;
    @apply py-3 border-b border-l-0 border-r-0 border-t-0 border-b-gray-900/10 p-3;
    p {
        @apply mb-0;
    }
}
</style>
