<template>
    <div>
        <!--                        <span class="whitespace-pre-line d-block fwb">Thoughts</span>-->

        <span class="whitespace-pre-line" v-if="command && command.thoughts">{{ command.thoughts.speak }}</span>
        <div v-if="message">
            <!--            <div class="my-5 bg-snow br-10 p-3 shadow" v-if="message" v-html="$filters.prettyObjectNew(message, ['content'], 'Message')"></div>-->
            <!--            <div class="my-5 bg-snow br-10 p-3 shadow" v-if="command" v-html="$filters.prettyObjectNew(command, ['content'], 'Commands')"></div>-->
            <button class="btn btn-secondary btn-sm mt-3" v-if="command" @click.prevent="addCommandsToMessage(message)">Add action to message</button>
        </div>
        <!--        <div class="my-3" v-if="commandContent" v-html="$filters.prettyObjectNew(commandContent)"></div>-->
        <loading-spinner v-if="!command" color="#000" class="o-2"></loading-spinner>
        <span v-if="command && command.thoughts" class="o-5 d-block f-13 mt-3 cursor-pointer hover:text-teal-700" @click.prevent="open = !open">
            <template v-if="open">
                Details
                <i class="fas fa-caret-up ml-1"></i>
            </template>
            <template v-else>
                Details
                <i class="fas fa-caret-down ml-1"></i>
            </template>
        </span>
        <div v-if="open && command" class="br-10 bg-snow p-5 shadow">
            <div class="my-3" v-if="command && command.thoughts" v-html="$filters.prettyObjectNew(command.thoughts, [], 'Thoughts')"></div>
            <div class="my-3" v-if="command && command.command" v-html="$filters.prettyObjectNew(command.command, [], 'Commands')"></div>

            <div class="f gap-3">
                <button class="btn btn-secondary btn-sm mt-3" v-if="command.command && command.command.name && command.command.args" @click.prevent="commandRouter(command.command.name, command.command.args, this.$store.state.chat.messages)">Run action</button>
            </div>
        </div>
        <div v-show="false">{{ commandContent }}</div>
    </div>
</template>
<script>
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import streamingMixin from "@/mixins/ai/streamingMixin";
import chatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import { mapActions } from "vuex";
export default {
    data() {
        return {
            open: false,
            command: null,
            updatedMessage: {},
        };
    },
    name: "chat-command-object",
    components: { LoadingSpinner },
    mixins: [streamingMixin, chatCommands],
    props: {
        content: {
            type: String,
            required: true,
        },
        message: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        // this.command = this.commandContent;
        this.command = this.masterJSONFixer(this.content.content);
        this.updatedMessage = this.message;
    },
    methods: {
        ...mapActions("chat", ["updateMessageObject"]),
        addCommandsToMessage(message) {
            const newMessage = { ...message };
            const index = this.index;
            newMessage.action = { ...this.command };
            console.log(index, newMessage);
            // this.$store.dispatch("chat/updateMessageObject", { newMessage, index });
            return;
        },
    },
    computed: {
        commandContent() {
            // if (!this.messages[this.index].action) return;
            return this.masterJSONFixer(this.content.content);
            if (this.command) return;

            let command = this.isCommandObject(this.content.content);
            this.command = command;
            this.updatedMessage = { ...this.message, action: this.command };
            // console.error("command:", command);
            // if content ends with \n remove it
            // this.messages[this.index].action = command;

            return command;
        },
    },
};
</script>
