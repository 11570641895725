<template>
    <table ref="emailFooterTable" :style="`background-color: ${emailFooterBg}; color: ${emailFooterText}; border-radius: 0px; font-family: Arial; letter-spacing: unset; line-height: unset;`" border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
            <td align="center" style="padding: 20px" valign="top">
                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                        <td align="center" valign="top">
                            <div class="x text-center">
                                <BrandLogoComponent :color="emailFooterText" style="display: block; max-width: 100px" width="100"></BrandLogoComponent>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="padding-top: 20px; padding-bottom: 20px" valign="top">
                            <p class="text-balance" style="font-size: 13px; margin: 0; width: 75%; text-align: center; white-space: pre-line">
                                {{ emailFooter.text }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="font-size: 0" valign="top">
                            <!--[if mso]>
              <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
              <![endif]-->
                            <template v-for="link in links">
                                <!--[if mso]>
                <td align="center" valign="top" :width="100 / links.length + '%'">
                <![endif]-->
                                <div style="display: inline-block; width: 100%; max-width: 200px; vertical-align: top">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td align="center" style="padding: 10px" valign="top">
                                                <a :href="link.url" :style="`background-color: ${emailFooterText}; border-radius: 5px; color: ${emailFooterBg}; display: inline-block; font-family: Arial; font-size: 15px; font-weight: bold; line-height: 40px; text-align: center; text-decoration: none; width: 200px;`" target="_blank">
                                                    {{ link.text }}
                                                </a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <!--[if mso]>
                </td>
                <![endif]-->
                            </template>
                            <!--[if mso]>
              </tr>
              </table>
              <![endif]-->
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="padding-top: 20px" valign="top">
                            <span style="font-size: 11px">
                                {{ unsubscribe.text }}
                                <a :href="unsubscribe.link.url" :style="`color: ${emailFooterText}; text-decoration: none;`" target="_blank">{{ unsubscribe.link.text }}</a>
                            </span>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td align="center" style="padding-top: 20px; padding-bottom: 20px" valign="top">
                <!--                <span style="font-size: 13px; font-weight: normal">Copyright © 2023 • {{ styleGuide.name }} • {{ styleGuide.url }}</span>-->
            </td>
        </tr>
    </table>
</template>
<script>
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";

export default {
    name: "EmailFooterTable",
    components: { BrandLogoComponent },
    props: {
        emailFooter: {
            type: Object,
            required: true,
        },
        emailFooterBg: {
            type: String,
            required: true,
        },
        emailFooterText: {
            type: String,
            required: true,
        },
    },
    computed: {
        emailFooterHtml() {
            return this.$refs.emailFooterTable.outerHTML;
        },
        unsubscribe() {
            return this.emailFooter.unsubscribe || {};
        },
        links() {
            return this.emailFooter.links || [];
        },
    },
};
</script>
