<template>
    <li class="border-bottom relative py-3 pl-2 pr-2">
        <p class="fwb mb-0 line-clamp-1">
            <span v-if="brand.name">{{ brand.name }}</span>
            <span v-else>Name of brand</span>
        </p>

        <!--        <pre v-if="brand.id">{{ brand.id }}</pre>-->
        <div class="f jcb aic gap-1">
            <span class="o-5 f-11" v-if="cleanURL">{{ cleanURL }}</span>
            <span class="f-11 text-red-700" v-else>No website</span>
            <span class="fwb text-uppercase o-5 f-11 line-clamp-1" v-if="brand.updated">{{ timeAgo(toDate(brand.updated)) }}</span>
        </div>
        <div class="p-absolute p-fill z-1" @click.once="clickedItem(brand.id)"></div>
        <div class="p-absolute top right o-5 z-2 px-2 py-1" @click.prevent="deleteItem(brand.id)"><i class="fa fal fa-times"></i></div>
        <!--                    <span v-if="guide?.typography?.fonts">{{ guide.typography.fonts[0] }}</span>-->
    </li>
</template>
<script>
import findMatchingObject from "@/mixins/firebase/findMatchinObject";

export default {
    name: "BrandListItem",
    methods: {
        async clickedItem(id) {
            const brand = this.brand;
            let object = await findMatchingObject("brands", "url", brand.url);
            if (!object?.id) {
                console.log("none found");
                brand.id = id;
                this.$emit("createNew", brand);
            } else {
                this.goToRoute("styleGuide", object.id);
            }
        },
        async deleteItem(id) {
            console.log("Deleting brand", id);
            // return id;
            await this.removeFromFirebase("brandsList", id);
            await this.$store.dispatch("styleGuide/deleteStyleGuide", id);

            // await this.$store.dispatch("styleGuide/")
        },
    },
    computed: {
        cleanURL() {
            try {
                let hostname;
                let url = this.brand.websiteUrl || this.brand.url;
                url = new URL(url);
                hostname = url.hostname;
                hostname = hostname.replace("www.", "");
                // hostname = hostname.replace(".com", "");
                hostname = hostname.replace(".org", "");
                if (hostname.startsWith("en.")) {
                    hostname = hostname.replace("en,", "");
                }
                return hostname;
            } catch (error) {
                // console.error("Error cleaning URL:", error);
                return this.brand.websiteUrl;
            }
            return this.brand.websiteUrl;
        },
        websiteUrl() {
            try {
                return this.cleanURL(this.brand.websiteUrl);
            } catch (e) {
                return "";
            }
        },
    },
    props: {
        brand: {},
    },
};
</script>
