<template>
    <div>
        <div ref="markdownContent" class="markdown-plain whitespace-pre-wrap mx-auto" style="font-family: sans-serif !important">
            <div v-html="renderedHtml"></div>
        </div>
    </div>
</template>

<script>
import Prism from "prismjs";
import ClipboardJS from "clipboard";
import "prismjs/plugins/toolbar/prism-toolbar.min.js";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min.js";
import "prismjs/plugins/toolbar/prism-toolbar.css";
import "prismjs/components/prism-javascript.js";
import "prismjs/components/prism-css.js";
import "prismjs/themes/prism.css";
import MarkdownIt from "markdown-it";
import mdAbbr from "markdown-it-abbr";
import mdAnchor from "markdown-it-anchor";
import mdEmoji from "markdown-it-emoji";
import mdIns from "markdown-it-ins";
import mdMark from "markdown-it-mark";

export default {
    name: "Markdown",
    props: {
        content: {
            type: String,
            default: "",
            required: true,
        },
        message: {
            type: Object,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            buttonText: "Copy Code",
        };
    },
    computed: {
        cleanedMarkdownContent() {
            if (!this.content) return "";
            let content = this.content || "";
            // content = content.replace(/<br>/g, "\n").replace(/\n+/g, "\n");
            // content = content.replace(/<br>/g, "\n").replace(/\n+/g, "\n");
            content = content
                .split("\n")
                .filter(Boolean)
                .filter(line => line?.length > 0)
                .map(line => line.replace("  *", "*").replace("  -", "*"))
                .join("\n");
            return content;
        },
        renderedHtml() {
            const md = new MarkdownIt({ linkify: true, typographer: true, highlight: true, breaks: false }).use(mdAnchor).use(mdEmoji).enable(["link"]).enable(["image"]).use(mdMark).use(mdIns).use(mdAbbr);

            md.renderer.rules.bullet_list_open = () => '<ul class="list-disc whitespace-pre-line border-top-0 my-0 py-0 mt-3 pl-3 py-0 !my-0">';
            md.renderer.rules.ordered_list_open = () => '<ol class="list-decimal whitespace-pre-line my-0 py-0 pl-3 mt-3">';
            md.renderer.rules.ordered_list_close = () => "</ol>";
            md.renderer.rules.bullet_list_close = () => "</ul>";
            md.renderer.rules.list_item_open = () => '<li class="my-0 py-0 line-height-normal line-height-normal my-1"><p>';
            md.renderer.rules.list_item_close = () => "</p></li>";
            md.renderer.rules.heading_open = () => '<h1 class="text-xl text-capitalize !font-bold mt-3 mb-3">';
            md.renderer.rules.heading_close = () => "</h1>";
            // handle quoted text
            md.renderer.rules.blockquote_open = () => '<blockquote class="border-l-4 text-blue-500 border-gray-300 pl-2 mt-3 mb-3">';
            md.renderer.rules.blockquote_close = () => "</blockquote>";

            md.renderer.rules.fence = this.renderCodeFence;
            md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
                const href = tokens[idx].href || "";
                const isExternalLink = href.startsWith("http") || href.startsWith("//");
                const target = isExternalLink ? ' target="_blank"' : "";
                const rel = isExternalLink ? ' rel="noopener noreferrer"' : "";

                const token = tokens[idx];
                const linkText = token?.children?.[0].content || "";
                const isBoldLink = linkText.includes("url:");
                const boldClass = isBoldLink ? " font-bold" : "";

                return `<a class="fwb linkColor underline"${target}${rel}${boldClass} href="${href}">${href}`;
            };
            md.renderer.rules.link_close = () => "</a><br>";

            return md.render(this.cleanedMarkdownContent);
        },
    },
    mounted() {
        this.$nextTick(() => {
            new ClipboardJS(".copy-to-clipboard-button");
            Prism.highlightAll();
            this.bindCopyButtonEvents();
        });
    },
    methods: {
        renderCodeFence(tokens, idx, options, env, self) {
            // this.setupVueLanguage();

            const token = tokens[idx];
            const langName = token.info.trim().split(/\s+/g)[0];
            const lang = Prism.languages[langName] || Prism.languages.markup;
            const code = token.content.trim();

            let codeLanguage = lang;
            if (code.includes("<template>") || langName === "vue") {
                codeLanguage = Prism.languages.html;
            }

            const highlightedCode = Prism.highlight(code, codeLanguage, langName);
            return this.generateCodeBlock(langName, highlightedCode, code);
        },
        generateCodeBlock(langName, highlightedCode, plainCode) {
            return `
                <div class="code-block rounded-lg mb-4 bg-gray-800 text-snow dark:bg-base-800 mt-3">
                    <div class="header px-3 flex justify-between items-center">
                        <span class="text-sm font-bold text-white">Code Snippet <span class="badge text-teal-900 bg-teal ml-2 px-2">${langName}</span></span>
                    </div>
                    <pre data-previewers="color time" class="language-${langName}"><code class="language-${langName}">${highlightedCode}</code></pre>
                </div>
                <div class="rendered-code">${plainCode}</div>
            `;
        },
        bindCopyButtonEvents() {
            if (!this.$refs.markdownContent) return;

            const copyButtons = this.$refs.markdownContent.querySelectorAll(".copy-code-button");
            copyButtons.forEach(button => {
                button.addEventListener("click", event => {
                    const code = event.target.getAttribute("data-code");
                    this.$emit("copied", code);
                    event.preventDefault();
                });
            });
        },
    },
};
</script>
<style lang="scss">
.markdown-plain {
    button {
        border: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li {
        font-family: sans-serif !important;
        line-height: unset;
        margin: unset;
        padding: unset;
        letter-spacing: unset;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: capitalize;
    }

    h1 {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size: 1.2rem;
    }

    h5 {
        font-size: 1rem;
    }

    h6 {
        font-size: 0.8rem;
    }
    ul,
    ol {
        //li {
        //    margin-bottom: 0px !important;
        //    padding-bottom: 0px !important;
        //    p {
        //        margin-bottom: 0px !important;
        //        padding-bottom: 0px !important;
        //        padding-top: 0px !important;
        //        margin-top: 0px !important;
        //    }
        //}
    }
    ul {
        list-style: disc;
        li {
            ul {
                list-style: circle;
            }
        }
    }
    ol {
        list-style: decimal;
        li {
            ul {
                list-style: circle;
            }
        }
    }
    //ul,
    //ol {
    //    margin: 1em 0;
    //    padding-left: 1.5em;
    //
    //    li {
    //        margin: 0em 0;
    //    }
    //    ul,
    //    ol {
    //        li {
    //            margin: 0em 0;
    //            margin-left: 0.2em;
    //        }
    //    }
    //}
    hr {
        @apply my-0 py-0;
    }
}

.code-block {
    .header {
        padding: 0.3rem;
    }

    pre {
        margin: 0;
        padding: 1rem;

        code {
            font-size: 0.875rem;
        }
    }
}

.blinking-cursor {
    animation: blink 1s step-end infinite;
}

@keyframes blink {
    from,
    to {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>
