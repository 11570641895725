<template>
    <div :class="divClasses" :style="backgroundStyle(divTheme)" class="rounded-xs relative overflow-hidden shadow z-1" @mouseenter="hover" @mouseleave="cancelHover">
        <template v-if="showLogo">
            <div class="f x y p-fill aic jcc mx-auto">
                <div>
                    <BrandLogoComponent :color="divTheme" width="250" class="!relative !mx-auto"></BrandLogoComponent>
                </div>
            </div>
        </template>
        <template v-else>
            <div :style="applyStyle(divTheme)" class="p-absolute p-fill f aic jcc p-5 text-center">
                <div class="md:p-6">
                    <!--                <span class="d-block !text-4xl" :style="mostCommonCombo.header">{{ dynamicTextSample(3) }}</span>-->
                    <!--                <span class="d-block" :style="mostCommonCombo.p">{{ dynamicTextSample(1.4) }}</span>-->
                    {{ divTheme.text }}
                </div>
                <!--                        <template v-if="divTheme.contrastRatio">{{ divTheme.contrastRatio.toFixed(2) }}</template>-->
            </div>
        </template>
        <div class="p-absolute p-fill z-1">
            <div class="f-11 line-height-smaller block overflow-scroll x y">
                <div v-if="divTheme.backgroundColor && divTheme.backgroundColor.includes('gradient')" class="visually-hidden">
                    {{ gradientHelper(divTheme.backgroundColor) }}
                    {{ divTheme.backgroundColor }}
                </div>
            </div>
            <div v-if="hovered" class="absolute top bottom left text-right right p-2 z-5">
                <BaseButton icon="fa fa-times fa-xl" icon-only rounded size="xxs" style-type="secondary" @click.prevent="removeDivStyle(divTheme)"></BaseButton>
            </div>
            <Transition name="fade-up">
                <div v-if="hovered" class="absolute bottom p-3 z-5">
                    <ColorPreview class="f-11" :color="divTheme.color" />
                    <ColorPreview class="f-11" :color="divTheme.backgroundColor" />
                </div>
            </Transition>
        </div>
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import chromaMixin from "@/mixins/StyleMixins/chromaMixin";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ColorPreview from "@/components/styleGuide/Tools/ColorPreview.vue";

export default {
    components: { ColorPreview, BaseButton, BrandLogoComponent },
    mixins: [styleGuideMixin, chromaMixin],
    emits: ["removeStyle"],
    data() {
        return {
            large: false,
            hovered: false,
        };
    },
    methods: {
        hover() {
            this.hovered = true;
        },
        cancelHover() {
            this.hovered = false;
        },
        removeDivStyle(theme) {
            this.$emit("removeStyle", theme);
        },
        backgroundStyle(theme) {
            try {
                return {
                    background: theme.backgroundColor + "!important",
                };
            } catch (e) {}
        },
    },
    computed: {
        divClasses() {
            let coreStyles = "aspect-h-1 aspect-w-1";
            return coreStyles;
            // return {
            //     `${coreStyles`: this.logo,
            //     "col-span-12 md:col-span-3 aspect-h-1 aspect-w-1": !this.logo,
            // };
        },
    },
    props: {
        divTheme: {
            type: Object,
            required: false,
        },
        showLogo: {
            type: Boolean,
            required: false,
        },
        // logo: {
        //     type: Boolean,
        //     required: false,
        // },
    },
    name: "DivStyleBlock",
};
</script>
