// chromaMixin.js
import chroma from "chroma-js";
import { gradientHelper } from "./gradientHelper";

export default {
    methods: {
        gradientHelper,
        getColorScale(startColor, endColor, numberOfSteps) {
            return chroma.scale([startColor, endColor]).colors(numberOfSteps);
        },
        adjustBrightness(color, factor) {
            return chroma(color).brighten(factor);
        },
        interpolateColors(startColor, endColor, position) {
            return chroma.interpolate(startColor, endColor, position);
        },
        blendColors(color1, color2, method) {
            return chroma.blend(color1, color2, method);
        },
        desaturateColor(color, amount) {
            return chroma(color).desaturate(amount);
        },
        saturateColor(color, amount) {
            return chroma(color).saturate(amount);
        },
        setColorAlpha(color, alphaValue) {
            return chroma(color).alpha(alphaValue);
        },
        getColorLuminance(color) {
            return chroma(color).luminance();
        },
        getContrastRatio(color1, color2) {
            return chroma.contrast(color1, color2);
        },
        darkenColor(color, amount) {
            return chroma(color).darken(amount);
        },
        lightenColor(color, amount) {
            return chroma(color).brighten(amount); // using brighten for lighten
        },
        getColorTemperature(color) {
            return chroma.temperature(color);
        },
        quantizeColors(colors, numberOfColors) {
            return chroma.quantize(chroma.scale(colors), numberOfColors);
        },
        isColorReadable(background, text) {
            const contrastRatio = chroma.contrast(background, text);
            return contrastRatio > 4.5; // WCAG standard for AA level
        },
        getComplementaryColor(color) {
            return chroma(color).set("hsl.h", "+180");
        },
        invertColor(color) {
            return chroma(color)
                .rgb()
                .map(v => 255 - v);
        },
        averageColors(...colors) {
            return chroma.average(colors);
        },
        getRandomColor() {
            return chroma.random();
        },
        toRGB(color, alpha) {
            let rgb = chroma(color).rgb();
            if (alpha || alpha === 0) {
                return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
            }
            return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
        },
        clampColor(color) {
            return chroma(color).clamp();
        },
        isColorDark(color) {
            return chroma(color).luminance() < 0.5;
        },
    },
};
