<template>
    <div class="col-span-12 grid grid-cols-12 mb-5 z-1 block">
        <div id="fontSection" :style="`background:${backgroundColor};color:${textColor};`" class="col-span-12 grid grid-cols-12 border-light br-20 relative">
            <div class="col-span-12 z-0 relative">
                <div v-if="selectedFont && selectedFont !== 'all'" :key="selectedFont.name" class="transition-all duration-1">
                    <div class="x grid grid-cols-12">
                        <div :class="{ 'pb-8': mobile, 'md:col-span-6': !hideLetter }" class="col-span-12 f aic jcs">
                            <div :style="`color:${selectedFont?.color}`" class="f fc gap-0 transition-all duration-1 line-height-smaller p-4 p-md-8 x">
                                <span class="line-height-smaller mb-0 text-4xl py-0 text-capitalize animated duration-3 fadeInUpSmooth">{{ fontName }}</span>
                                <div class="f aic jcs gap-3 x mb-6 overflow-x-scroll overflow-y-visible scrollbar-hide">
                                    <span v-for="(weight, i) in selectedFont.weights" :style="`animation-delay:${100 * (i + 1)}ms!important;font-weight:${weight};font-family:${selectedFont.name};`" class="animated fadeinUpSmooth duration-2 mb-0 f-200 lg:f-110">
                                        <span class="animated fadeInUpSmooth block d-1 duration-2" :style="`animation-delay:${100 * (i + 1)}ms!important;`">
                                            <span v-if="selectedFont.name.includes('awesome') || selectedFont.name.includes('Awesome')" :style="`font-family:${selectedFont.name};font-weight:${selectedFont.weight}!font-style:${selectedFont.style}!important;`" class="f aic gap-3 flex-wrap">
                                                <template v-for="item in fontAwesomeIcons(selectedFont).slice(0, 2)"><i :class="`p-2 fa ${faWeight(selectedFont, weight)} fa-${item}`"></i></template>
                                            </span>
                                            <template v-else>Aa</template>
                                        </span>
                                        <span class="block f-17 animated fadeInUpSmooth duration-2" :style="`animation-delay:${150 * (i + 1)}ms!important;font-family: arial; font-size: 17px`">{{ weight }}</span>
                                    </span>
                                    <template v-for="(style, i) in selectedFont.styles">
                                        <span v-if="style !== 'normal'" :style="`animation-delay:${200 * (i + selectedFont.weights.length + 1)}ms!;font-style:${style};font-weight:400;font-family:${selectedFont.name};`" class="animated block fadeinUpSmooth duration-2 mb-0 f-200 lg:f-110">
                                            <span class="animated fadeInUpSmooth block duration-2" :style="`animation-delay:${100 * selectedFont.weights.length * (i + 1)}ms!;`">Aa</span>
                                            <span class="block f-17 animated fadeInUpSmooth duration-2" :style="`animation-delay:${150 * (i + selectedFont.weights.length + 1)}ms!;font-family: arial; font-size: 17px`">{{ style }}</span>
                                        </span>
                                    </template>
                                </div>
                                <!--                                 <span class="line-height-smaller mb-0 text-lg w-75 fwn" :style="`font-family:${selectedFont.name};`">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>-->
                                <div class="grid grid-cols-12">
                                    <template v-for="(style, index) in selectedFont.styles">
                                        <div v-for="(weight, i) in selectedFont.weights" :style="`animation-delay:${20 * (i + 1 + index + 1)}ms!important;`" class="mb-3 col-span-12 md:col-span-6 px-2 animated fadeInUpSmooth duration-2 text-balance">
                                            <span :style="`animation-delay:${50 * (i + 1 + index + 1)}ms!important;font-family:${selectedFont.name};font-style:${style}!important;font-weight:${weight};`" class="d-block line-height-smaller mb-0 f-40 mb-0 x animated fadeInUpSmooth duration-2">
                                                <span v-if="selectedFont.name.includes('awesome') || selectedFont.name.includes('Awesome')" :style="`font-family:${selectedFont.name};font-weight:${selectedFont.weight}!font-style:${selectedFont.style}!important;`" class="f aic gap-3 flex-wrap">
                                                    <template v-for="item in fontAwesomeIcons(selectedFont).slice(0, 12)"><i :class="`p-2 fa ${faWeight(selectedFont, weight)} fa-${item}`"></i></template>
                                                </span>
                                                <template v-else>The Quick Brown Fox</template>
                                            </span>
                                            <p :style="`animation-delay:${100 * (i + 1 + index + 1)}ms!important;font-style:${style};font-weight:${weight}!important;font-family:${selectedFont.name};text-transform:${selectedFont.style};`" class="d-block my-3 x mw-500 f-17 animated fadeInUpSmooth duration-2 text-balance">
                                                <span v-if="selectedFont.name.includes('awesome') || selectedFont.name.includes('Awesome')" :style="`font-family:${selectedFont.name};font-weight:${weight}!font-style:${selectedFont.style}!important;`" class="f aic gap-3 flex-wrap text-balance">
                                                    <template v-for="item in fontAwesomeIcons(selectedFont).slice(0, 18)"><i :class="`p-2 fa ${faWeight(selectedFont, weight)} fa-${item}`"></i></template>
                                                </span>
                                                <span class="line-clamp-2" v-else>
                                                    {{ lorem }}
                                                </span>
                                            </p>
                                            <span class="d-block mb-3 text-capitalize fwb x animated duration-2 fadeInUpSmooth" :style="`font-family: arial; font-size: 16px;animation-delay:${150 * (i + 1 + index + 1)}ms!important;`">{{ style }} {{ weight }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div v-if="!mobile && !hideLetter" class="col-span-12 md:col-span-6 f aic jcc y relative p-4 p-md-8">
                            <div class="aspect-w-1 aspect-h-1 x">
                                <!--                        <div class="aspect-w-1 aspect-h-1 p-absolute p-fill x">-->
                                <div class="f aic jcc border-4 border border-dashed br-50">
                                    <span ref="bigFont" :data-index="0" :style="`text-align:baseline;font-family:${selectedFont.name};`" class="block bigFont f-200 md:f-600 duration-5 animated fadeInUpSmooth">Aa</span>
                                    <span class="absolute bottom mb-5 text-capitalize f-50 duration-4 d-5 animated fadeInUpSmooth">{{ selectedFont.name.split("-").join(" ") }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PickerContainer absolute class="">
                <BaseButton v-for="font in mapFonts" class="!text-base-900 !link-color" icon-only size="xl" @mouseover="selectTheme(font)">
                    <a :style="[font, `font-size:20px;font-family:${font.name};font-weight:400;`]" class="link-color">
                        <span v-if="font.name.includes('awesome') || font.name.includes('Awesome')" :style="`font-family:${font.name};font-weight:${font.weight}!font-style:${font.style}!important;`" class="f aic gap-1">
                            <template v-for="item in fontAwesomeIcons(font).slice(0, 1)"><i :class="`small fa fa-${item}`"></i></template>
                        </span>
                        <template v-else>Aa</template>
                    </a>
                </BaseButton>
                <BaseButton v-if="showAllButton" class="!text-base-900" icon-only size="xl" @mouseover="selectTheme('all')">All</BaseButton>
            </PickerContainer>
        </div>
        <div class="col-span-12">
            <div class="container py-5 br-10 pb-0 block">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";

import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import DivStyleBlock from "@/components/styleGuide/Sections/Themes/DivStyleBlock.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import PickerContainer from "@/components/styleGuide/Sections/Themes/PickerContainer.vue";
import copyGuidelines from "../../generative_templates/Guidelines/CopyGuidelines.vue";
import chroma from "chroma-js";
export default {
    name: "FontSamplesPicker",
    components: { PickerContainer, BrandLogoComponent, BaseButton, DivStyleBlock },
    mixins: [styleGuideMixin],
    props: {
        hideLetter: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fontSize: 1,
            small: true,
            selectedFont: null,
        };
    },
    computed: {
        backgroundColor() {
            let background = "#ffffff";
            if (this.divStyles.length > 0) {
                if (this.divStyles?.[2]?.backgroundColor) background = this.divStyles[2].backgroundColor;
                else if (this.divStyles?.[1]?.backgroundColor) background = this.divStyles[1].backgroundColor;
                else if (this.divStyles?.[0]?.backgroundColor) background = this.divStyles[0].backgroundColor;
                try {
                    background = chroma(background)?.hex();
                } catch (e) {
                    console.log(e);
                }
            }
            if (this.colors?.bg_colors_popular?.length > 0) {
                let popularBackgrounds = this.colors?.bg_colors_popular;
                if (popularBackgrounds[1]) background = popularBackgrounds[1];
            }
            return background;
        },
        textColor() {
            return this.getContrastColor(this.backgroundColor);
        },
        copyGuidelines() {
            return copyGuidelines;
        },
        showAllButton() {
            return false;
        },
        lorem() {
            if (this.styleGuide?.copyGuidelines?.overview?.pangram) return this.styleGuide?.copyGuidelines?.overview?.pangram;
            return `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`;
        },
        mapFonts() {
            if (!this.fonts?.length) return {};
            const fontMap = {};
            for (const font of this.fonts) {
                if (!font) continue;
                let { name = "", weight = "", style = "" } = font;
                // console.log(font);
                if (!fontMap[name]) fontMap[name] = { name, weights: [], styles: [] };
                if (!fontMap[name].weights.includes(weight)) fontMap[name].weights.push(weight);
                if (!fontMap[name].styles.includes(style)) fontMap[name].styles.push(style);
                fontMap[name].weights = fontMap[name].weights.sort((a, b) => a - b);
                fontMap[name].styles = fontMap[name].styles.sort((a, b) => a - b);
            }
            const dedupedFonts = Object.values(fontMap);
            return dedupedFonts;
        },
        fontName() {
            let name = this.selectedFont.name.split("-").join(" ");
            name = name.replace("web", "");
            return name;
        },
        dynamicColor() {
            return this.selectedTheme.color;
        },
        dynamicSample() {
            return this.dynamicTextSample(this.fontSize);
        },
        randomDivStyles() {
            if (!this.divStyles) return [];
            let divStyles = this.divStyles.map(style => {
                let textStyle = this.randomTextStyleMethod(style);
                if (textStyle?.fontSize) {
                    let text = this.dynamicTextSample(textStyle.fontSize);
                    style = { ...style, ...textStyle, text };
                    if (style.textTransform !== "uppercase") style.textTransform = "capitalize";
                    return style;
                }
                return style;
            });
            divStyles = divStyles.filter(style => style);
            return divStyles;
        },
    },
    mounted() {
        this.$nextTick(() => (this.selectedFont = this.mapFonts[0]));
    },
    methods: {
        fontAwesomeIcons(font) {
            console.log(font);
            let name = font?.name?.toLowerCase();
            if (name?.includes("brands")) return ["facebook", "twitter", "tiktok", "linkedin", "google", "slack", "dribbble"];
            return ["heart", "dog", "house", "envelope", "check", "music", "star", "camera", "globe", "rocket", "plane", "car", "bicycle", "tree", "leaf", "sun", "moon", "cloud", "umbrella", "star", "heart", "camera", "globe", "rocket", "plane", "car", "bicycle", "tree", "leaf", "sun", "moon", "cloud", "umbrella"];
        },
        faWeight(font, weight) {
            // font awesome class weights
            let { name } = font;
            name = name.toLowerCase();
            if (name?.includes("brands")) return "fab";
            if (weight === 300 || weight === "300") return "fal";
            if (weight === 400 || weight === "400") return "far";
            if (weight === 500 || weight === "500") return "fas";
            if (weight === 600 || weight === "600") return "fad";
            if (weight === 700 || weight === "900") return "fas";
            return "far";
        },
        animateFontSelection() {
            this.$refs.bigFonts.forEach((el, index) => {
                const delay = index * 0.05;

                gsap.set(el, { alpha: 0.0, y: 10 });

                gsap.to(el, {
                    alpha: 1,
                    y: 0,
                    delay,
                    duration: 0.7,
                    ease: "back.inOut",
                });
            });
        },
        selectTheme(theme) {
            if (theme === "all") {
                this.selectedFont = theme;
            } else {
                this.selectedFont = theme;
                this.$nextTick().then(() => {
                    this.$forceUpdate();
                    // this.animateFontSelection(); // Trigger the animation
                });
            }
        },
        applyRandomTextStyle(index) {
            const style = this.randomTextStyleMethod();
            if (parseFloat(style.fontSize) > 1.4) style.color = "red";
            return style;
        },
    },
};
</script>
<style lang="scss" scoped></style>
