// getOAIModels.js // Description: This mixin contains functions for retrieving AI model information from the OpenAI API and the Stability API.
import JSON5 from "json5";
export default {
    data() {
        return {};
    },
    mounted() {},
    mixins: [],
    methods: {
        getStableDiffusionEngines(fromStore) {
            let models = localStorage.getItem("models");

            if (models) {
                // console.groupCollapsed("SD Models");
                try {
                    let localModels = JSON5.parse(models).models;
                    models = this.$store.state.image.stability_options.engines || localModels.models;
                    // console.error("Models", models);
                } catch (e) {
                    // console.error(e, models);
                }
                // console.groupEnd();
                // window.models = models;
                // console.log("Stable Diffusion Models", models);
                // console.groupEnd();
                // only shoe models where show is true
                // models = models.models.filter(model => model.show);
                return models;
            }

            let storeModels = fromStore;
            localStorage.setItem("models", JSON5.stringify(storeModels));
            storeModels = storeModels.filter(model => model.show);
            return storeModels;
        },
        /**
         * Retrieves information about a specific AI engine model from the OpenAI API.
         * The function first checks if the model data exists in local storage. If not found,
         * it then checks the Firebase database. If the model data is still not found, it fetches
         * the data from the OpenAI API. The fetched model data is saved in Firebase and local storage
         * for future use.
         *
         * @param {string} modelId - The ID of the model to retrieve.
         * @param {string} apiKey - The OpenAI API key to use for authentication.
         * @returns {Promise<Object>} A promise that resolves to an object containing information about the model.
         * @throws {Error} If the API returns a non-200 response or an error occurs while fetching the model information.
         */
        async getOpenAIModelList() {
            // let modelId = "OpenAI";
            // const openaiApiKey = import.meta.env.VITE_OPENAI_API;
            // const apiHost = "https://api.openai.com";
            // const apiUrl = `${apiHost}/v1/models/`;
            // const headers = { Authorization: `Bearer ${openaiApiKey}` };
            //
            // let modelData = {};
            //
            // // Check if model data exists in local storage
            // const storedModelData = localStorage.getItem(`model_${modelId}`);
            // if (storedModelData) {
            //     modelData = JSON.parse(storedModelData);
            //     return modelData;
            // }
            //
            // // Fetch model data from OpenAI API
            // try {
            //     // console.error(response.data);
            //     // modelData.models = response.data.data;
            // } catch (error) {
            //     throw new Error(`Error retrieving model: ${error.message}`);
            // }
            // modelData.name = modelId;
            // modelData.id = modelId;
            // // Save model data to Firebase and local storage
            // console.error(modelData);
            // // await this.saveToFirebase("models", modelData);
            // localStorage.setItem(`model_${modelId}`, JSON.stringify(modelData));
            // return modelData;
        },
    },
};
