<template>
    <div class="flex justify-between items-center mb-3">
        <ChatNewChatButton />

        <ChatButtonStop />
    </div>
</template>
<script>
import ChatButtonStop from "@/views/ChatButtonStop.vue";
import ChatNewChatButton from "@/views/ChatNewChatButton.vue";

export default {
    name: "ChatOptions",
    components: { ChatButtonStop, ChatNewChatButton },
};
</script>
