<template>
    <div v-if="article" class="relative" :class="{ 'mw-500': !mobile, 'mw-350': mobile }">
        <div class="br-10 f border-light mw-550 x relative overflow-hidden bg-snow dark:bg-base-700" v-if="!mobile">
            <div v-if="article.metadata.image" class="relative">
                <img :src="article.metadata.image" class="xs:height-100 xs:width-100 o-center height-150 width-150 object-cover object-center" />
            </div>
            <div class="p-3">
                <p v-if="article.metadata.title" class="text-md xs:text-sm mb-0 font-bold">{{ article.metadata.title }}</p>
                <div v-if="article.metadata.description" class="text-xs line-clamp-2">{{ article.metadata.description }}</div>
                <a v-if="article.metadata.url" :href="article.metadata.url" target="_blank" class="text-xs line-clamp-2">Read more</a>
            </div>
        </div>
        <div class="br-10 f fc border-light mw-300 relative overflow-hidden bg-snow dark:bg-base-700" v-if="mobile">
            <div v-if="article.metadata.image" class="aspect-h-9 aspect-w-16 relative block">
                <img :src="article.metadata.image" class="x o-cover" />
            </div>
            <div class="p-3">
                <p v-if="article.metadata.title" class="mb-1 text-sm font-bold line-clamp-2">{{ article.metadata.title }}</p>
                <div v-if="article.metadata.description" class="mb-1 text-xs line-clamp-2">{{ article.metadata.description }}</div>
                <a v-if="article.metadata.url" :href="article.metadata.url" target="_blank" class="mt-2 line-clamp-2 dark:text-base-400">Read more</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ArticleMetadata",
    props: {
        m: {},
        article: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
