<template>
    <button class="inline-flex justify-center items-center gap-x-2 rounded-lg font-medium text-gray-800 hover:text-blue-600 text-xs sm:text-sm dark:text-gray-200 dark:hover:text-blue-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" type="button">
        <IconPlus />
        {{ text }}
    </button>
</template>
<script>
import IconPlus from "@/views/IconPlus.vue";

export default {
    name: "ChatNewChatButton",
    components: { IconPlus },
    props: {
        text: {
            type: String,
            default: "New chat",
        },
    },
    data() {
        return {};
    },
    methods: {
        newChat() {
            this.$emit("new-chat");
        },
    },
};
</script>
