<template>
    <div v-if="text !== ''" class="mb-3 ml-3 w-80 py-0 pl-2">
        <Transition enter-from-class="animated fadeIn" leave-to-class="animated fadeOut" name="fade">
            <div v-if="(images && images.length && images.length > 0) || (m.images && m.images.length && m.images.length > 0)" class="f flex-wrap">
                <div v-for="(image, index) in images" :key="index" class="w-100">
                    <div class="br-10 x br-10 my-3 overflow-hidden shadow p-relative" v-if="image.url">
                        <img :src="image.url" class="x y o-cover border-light br-10" />
                        <div class="p-absolute top right p-2">
                            <button class="btn p-1 px-2 btn-bubble bg-transparent text-snow border-light bg-blur text-shadow" @click="deleteImageFromArray(index)">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                        <div class="p-absolute bottom right p-2">
                            <button class="btn p-1 px-2 btn-bubble bg-transparent text-snow border-light bg-blur text-shadow" @click="downloadImage(index)">
                                <i class="fas fa-download"></i>
                            </button>
                        </div>
                    </div>
                    <div class="aic jcc x ratio-100 p-relative" v-else>
                        <div class="p-absolute p-fill f aic jcc bg-snow p-3">
                            <LoadingSpinner class="z-1" color="#ccc"></LoadingSpinner>
                        </div>
                    </div>
                    <div class="text-xxs smaller px-1 text-center x" v-html="image.prompt"></div>
                </div>
            </div>
        </Transition>
        <a class="border-light text-gray-400 px-3 d-block text-center br-20 py-2 x mt-5 !bg-transparent shadow-sm hover:bg-snow hover:!text-gray-400" href="#" @click.prevent="addVariation">
            Add variation
        </a>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import imageMixin from "@/mixins/images/imageMixin";

export default {
    components: { LoadingSpinner },
    mixins: [imageMixin],
    props: ["text", "index", "m"],
    data: () => ({
        images: [],
    }),
    computed: {
        updatedImages() {
            // if (this.m.images) {
            //     return this.m.images;
            // }
            return this.images;
        },
    },
    async created() {
        console.log("created() called");
        await this.updateOldImageToArray();
        await this.normalizeImageData();

        // Check if any of the images have a valid URL
        const hasValidUrl = this.images.some(image => image.url && image.url !== "");

        // Fetch images only if no valid URL is found
        if (!hasValidUrl) {
            await this.getImages();
        }
    },
    async mounted() {
        console.log("mounted() called");
        // await this.checkImages();
    },
    methods: {
        async checkImages() {
            console.log("checkImages() called");
            let hasEmpty = false;
            for (const image of this.images) {
                if (!image.url || image.url === "") {
                    hasEmpty = true;
                }
            }
            if (hasEmpty) {
                await this.getImages();
            }
        },
        async deleteImageFromArray(index) {
            console.log("deleteImageFromArray() called", index);
            if (this.m.images) {
                this.m.images.splice(index, 1);
                this.$emit("imageLoaded", this.index, this.m.images);
            } else {
                this.images.splice(index, 1);
                this.$emit("imageLoaded", this.index, this.images);
            }
        },
        async addVariation() {
            const newImage = {
                url: "",
                prompt: this.text,
                index: this.images.length,
            };

            const response = await this.getStabilityImage(newImage.prompt, { height: 512, width: 512 }, "stable-diffusion-xl-beta-v2-2-2");

            if (Array.isArray(response)) {
                for (const img of response) {
                    if (img && img.url) {
                        this.images.push({
                            url: img.url,
                            prompt: this.text,
                            index: this.images.length,
                        });
                    }
                }
            } else if (response && response.url) {
                // If it's a single image
                newImage.url = response.url;
                this.images.push(newImage);
            } else {
                // Handle error case if needed
            }

            this.$emit("imageLoaded", this.index, this.images);
        },
        async downloadImage(index) {
            console.log("downloadImage() called", index);
            let image = this.updatedImages[index];
            let prompt = image.prompt;
            let filename = prompt.replace(/\s/g, "") + ".png";
            let url = image.url;
            let a = document.createElement("a");

            // Set the href and download attributes
            a.href = url;
            a.download = filename;

            // Append the anchor element to the DOM (it will be invisible)
            document.body.appendChild(a);

            // Trigger a click event on the anchor element
            a.click();

            // Remove the anchor element from the DOM after the download starts
            setTimeout(() => {
                document.body.removeChild(a);
            }, 100);
        },
        async normalizeImageData() {
            console.log("normalizeImageData() called");
            if (this.m.images) {
                this.images = this.m.images.map(image => ({
                    prompt: `${image.prompt}`,
                    index: this.m.index,
                    url: image.url && typeof image.url === "object" ? image.url.url : image.url,
                }));
            } else if (this.m.url && Array.isArray(this.m.url)) {
                this.images = this.m.url.map(url => ({
                    prompt: this.m.prompt,
                    index: this.m.index,
                    url: typeof url === "object" ? url.url : url,
                }));
            } else if (this.m.url) {
                this.images = [
                    {
                        prompt: this.m.prompt,
                        index: this.m.index,
                        url: this.m.url,
                    },
                ];
            }
        },
        async updateOldImageToArray() {
            console.log("updateOldImageToArray() called");
            if (this.m.url && this.m.url.length > 0) {
                this.images = this.m.url.map(url => ({
                    prompt: this.text,
                    index: this.index,
                    url: `${url}`,
                }));
                return;
            }
            if (this.m.url) {
                this.images = this.m.url.map(url => ({
                    prompt: this.m.prompt,
                    index: this.m.index,
                    url: url,
                }));
            }
            let prompt = this.text;
            let index = this.index;
            let url = this.m.imageURL;
            if (this.m.imageURL) {
                let imageObject = {
                    prompt: prompt,
                    index: index,
                    url: `${url}`,
                };
                this.images.push(imageObject);
                this.imageURL = null;
            }
        },
        async getImages() {
            console.log("getImages() called");

            if (this.images.length === 0) {
                this.addVariation();
            } else {
                let updatedImages = [];

                for (const image of this.images) {
                    if (!image.url) {
                        const response = await this.getStabilityImage(image.prompt, { height: 512, width: 512 }, "stable-diffusion-xl-beta-v2-2-2");

                        if (Array.isArray(response)) {
                            for (const img of response) {
                                const newImage = {
                                    url: img.url,
                                    prompt: this.text,
                                    index: this.index,
                                };
                                updatedImages.push(newImage);
                            }
                        } else {
                            image.url = response.url;
                            image.prompt = this.text;
                            updatedImages.push(image);
                        }
                    } else {
                        updatedImages.push(image);
                    }
                }

                this.images = updatedImages;
                this.$emit("imageLoaded", this.index, updatedImages);
            }
        },
    },
};
</script>

<style lang="scss"></style>
