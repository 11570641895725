<template>
    <div>
        <div class="py-2">
            <h4 v-if="name" class="fwb mb-0" :class="{ 'line-height-smaller mb-2': isLarge, 'line-clamp-1': !isLarge }">{{ name }}</h4>
            <p v-if="updated" class="fwb f-13 o-5">Last edited: {{ updated }}</p>
            <div v-if="kind" class="f gap-2 mt-2">
                <div><BaseBadge color="gray" class="f-11 text-uppercase" text="draft"></BaseBadge></div>
                <div><BaseBadge v-if="kind" color="green" class="f-11 text-uppercase" :text="kind"></BaseBadge></div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";

export default {
    name: "CampaignItemDetails",
    components: { BaseBadge },
    props: {
        item: {},
        size: {
            type: String,
            default: null,
        },
    },
    computed: {
        isLarge() {
            return this.size === "large" || this.size === "lg";
        },
        name() {
            return this.item.name || this.item?.result?.name;
        },
        description() {
            return this.item.description;
        },
        kind() {
            return this.item?.result?.object_type?.replace("_", " ") || "";
        },
        status() {
            return this.item.status;
        },
        updated() {
            try {
                return this.$filters.timeAgo(this.dateFix(this.item.updated));
            } catch (e) {
                // return this.$filters.timeAgo(this.dateFix(this.item.created));
                return "";
            }
        },
    },
};
</script>
