<!-- SimpleSelect.vue -->
<template>
    <div>
        <Listbox v-model="selectedValue" as="div" @change="$emit('change', selectedValue)">
            <label v-if="label" :for="id" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
            <div class="relative mt-2">
                <ListboxButton class="relative w-full cursor-default rounded-md bg-snow py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-lg sm:leading-6">
                    <span class="inline-flex w-full truncate aic f">
                        <span class="text-sm truncate fwsb">{{ selectedOption ? selectedOption[optionLabel] : placeholderText }}</span>
                        <span class="text-sm o-5 ml-2 truncate text-gray-500">{{ selectedOption ? selectedOption[optionValue] : "" }}</span>
                    </span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon aria-hidden="true" class="h-5 w-5 text-gray-400" />
                    </span>
                </ListboxButton>
                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-lg">
                        <ListboxOption v-for="option in options" :key="option[optionValue]" v-slot="{ active, selected }" :value="option[optionValue]" as="template">
                            <li :class="[active ? 'bg-green-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-4']">
                                <div class="flex aic gap-2">
                                    <span class="text-sm" :class="[selected ? 'font-semibold' : 'font-normal', 'truncate']">{{ option[optionLabel] }}</span>
                                    <span class="text-sm o-5" :class="[active ? 'text-green-200' : 'text-gray-500', 'truncate']">{{ option[optionValue] }}</span>
                                </div>
                                <span v-if="selected" :class="[active ? 'text-white' : 'text-blue-600', 'absolute inset-y-0 right-0 flex items-center pr-3']">
                                    <CheckIcon aria-hidden="true" class="h-5 w-5" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
    name: "SimpleSelectorNew",
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        ChevronUpDownIcon,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        optionLabel: {
            type: String,
            default: "name",
        },
        optionValue: {
            type: String,
            default: "id",
        },
        label: {
            type: String,
            default: "",
        },
        placeholderText: {
            type: String,
            default: "Select an option",
        },
        modelValue: {
            type: [String, Number],
            default: "",
        },
    },
    computed: {
        selectedValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        selectedOption() {
            return this.options.find(option => option[this.optionValue] === this.selectedValue);
        },
    },
};
</script>
