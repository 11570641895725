<template>
    <div class="x p-relative z-1">
        <div class="x grid grid-cols-12 vh-100" :class="{ 'py-5 fixed p-fill': !mobile }">
            <div class="lg:col-span-2 px-5" v-if="categories && !mobile" :class="{ 'px-3  scrollSection pt-nav bg-gray-50': !mobile }">
                <h5 class="fwb">Categories</h5>
                <CategoryList class="jcb fc aib" :categories="categories.filter(cat => !cat.parent_id)"></CategoryList>
            </div>
            <transition name="fade-up" mode="out-in">
                <div class="x bg-gray-50 vh-60 border-light col-span-10 f aic jcc" v-if="loading">
                    <div class="text-center">
                        <h4 class="loading-pulse-lighter-text mix-blend-multiply">Loading</h4>
                        <LoadingSpinner color="black" />
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-10 z-0 relative md:px-6 relative" :class="{ 'px-3  scrollSection pt-nav': !mobile }" v-else-if="filteredCategories?.brands?.length > 0">
                    <template v-if="currentCategories?.length > 0">
                        <div class="mb-5" v-for="category in currentCategories?.slice(0, 1)">
                            <div class="f overflow-x-scroll scrollbar-hide mb-3 border-top border-bottom py-3 top fixed mt-nav bg-snow z-10" style="max-width: 100vw" v-if="mobile">
                                <CategoryList inline class="jcb f aib pl-3" key="maincats" :categories="categories.filter(cat => !cat.parent_id)"></CategoryList>
                            </div>
                            <div class="mt-nav pt-5 pb-5" v-if="category?.name">
                                <h2 class="pl-3 md:pl-5 f aic gap-3 f-24 lg:f-50 whitespace-nowrap">
                                    <template v-if="parentCategory">
                                        <router-link v-if="parentCategory?.name" class="text-black hover:text-blue-900" :to="`/categories/${parentCategory?.name}`">{{ parentCategory.name }}</router-link>
                                        <i class="fas fa-angle-right fa-2xs mb-0"></i>
                                    </template>
                                    <span :class="{ 'text-gray-400': parentCategory }">{{ category.name }}</span>
                                </h2>
                                <p class="px-3 md:px-5 sticky-top mb-0" v-if="category?.description">{{ category.description }}</p>
                            </div>
                            <template v-if="!loading">
                                <div v-if="category?.sub_categories?.length > 0" class="f overflow-x-scroll scrollbar-hide mb-3 border-top border-bottom py-3 pl-3">
                                    <CategoryList :categories="category.sub_categories" />
                                </div>
                                <div v-else-if="parentCategory?.sub_categories?.length > 0" class="f overflow-x-scroll scrollbar-hide mb-3 border-top border-bottom py-3 pl-3">
                                    <CategoryList :categories="parentCategory.sub_categories" />
                                </div>
                            </template>
                        </div>
                        <transition name="fade-up">
                            <div class="block" :key="$route.params.cat" v-if="!loading">
                                <div class="grid grid-cols-12 gap-3 md:gap-5 animated fadeInUpSmooth" :class="{ 'px-3': mobile }">
                                    <template v-for="(brand, index) in filteredCategories.brands" :key="brand.name">
                                        <HomePageBrandButton v-if="brand.url" :brand="brand"></HomePageBrandButton>
                                    </template>
                                </div>
                            </div>
                        </transition>
                    </template>
                </div>
            </transition>
        </div>
        <!--        </template>-->
    </div>
</template>
<script>
// import BaseButton from "@/components/CoreUI/BaseButton.vue";
import findMatchingObject from "@/mixins/firebase/findMatchinObject.js";
import styleGuideCSSMixin from "@/mixins/StyleMixins/StyleGuideCSSMixin";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import StyleGuideSearch from "@/components/styleGuide/ListOfGuides/StyleGuideSearch.vue";
import HomePageBrandButton from "@/views/HomePageBrandButton.vue";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import webRequest from "@/mixins/ai/web_request";
import CategoryList from "@/views/CategoryList.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    components: { BaseButton, LoadingSpinner, CategoryList, HomePageBrandButton, StyleGuideSearch },
    mixins: [styleGuideCSSMixin, styleGuideMixin],
    data() {
        return {
            allPallets: [],
            textSections: [
                {
                    headerText: "Design Goldmine",
                    bodyText: "Tap into what's now. A curated selection of designs that set today's pace.",
                },
                {
                    headerText: "More Than Meets the Eye",
                    bodyText: "Zoom in on the trends. Breakdown the best in design.",
                },
                {
                    headerText: "Inspiration Unleashed",
                    bodyText: "Find what fuels you. Dive in, get inspired, and create something memorable.",
                },
                {
                    headerText: "Details in Depth",
                    bodyText: "Explore up close. Dissect designs, spot trends, and get the full picture.",
                },
            ],
            pallete: {},
            textStyle: {},
            fontFamilies: {},
            brands: null,
            loadingStyles: false,
            showGuides: false,
            currentCategories: [],
            parentCategory: null,
            categoryBrands: [],
            filteredCategories: null,
            loading: true,
        };
    },
    computed: {
        categories() {
            return this.$store.state?.styleGuide?.categories;
        },
        brandsWithImages() {
            return this.styleGuides
                .filter(guide => guide.image)
                .map(guide => guide.image.url)
                .slice(0, 30);
        },
    },
    watch: {
        // watch the route for categories changes
        async $route(to, from) {
            this.loading = true;
            this.setCategories();
            if (this.currentCategories) {
                await this.mountCategories(this.currentCategories);
            }
            this.loading = false;
        },
        categories() {
            this.loading = true;
            this.setCategories();
            if (this.currentCategories) {
                this.mountCategories(this.currentCategories);
            }
            this.loading = false;
        },
    },
    // components: { BaseButton },
    async created() {},
    async mounted() {
        await this.$nextTick(() => {}).then(async () => {
            this.loading = true;
            this.setCategories();
            this.mountCategories();
            this.loading = false;
        });
    },
    methods: {
        getParentCategory(category) {
            this.parentCategory = this.categories.find(cat => +cat.id === +category?.parent_id);
            // get the subCategories of the parent category
            if (this.parentCategory) {
                this.parentCategory.sub_categories = this.categories.filter(cat => +cat.parent_id === +this.parentCategory.id);
            }
        },
        async mountCategories() {
            if (this.currentCategories.length > 0) {
                const categoryNames = this.currentCategories;
                let categories = this.getCategoryObject(categoryNames);
                // get subcategories by matching category id to parent id of other categories
                categories = categories.map(category => {
                    let id = category.id;
                    let subcategories = this.categories.filter(cat => cat.parent_id === +id);
                    console.log(id);
                    console.log(subcategories);
                    return { ...category, sub_categories: subcategories };
                });
                this.currentCategories = categories;
                let brands = await this.fetchByCategory(categoryNames);
                this.filteredCategories = { categories, brands };
                this.getParentCategory(categories[0]);
                return { categories, brands, parentCategory: this.parentCategory };
            }
        },
        getCategoryObject(categories) {
            let filteredCategories = this.categories.filter(categoryObject => categories.includes(categoryObject.name));
            return filteredCategories;
        },
        async fetchByCategory(category) {
            let obj = { categories: `${category}`, strictMatch: false };
            if (this.user) obj.user = this.user;
            let response = await webRequest("getBrands", obj);
            let brands = await response.json();
            console.log("Similar Brands", brands);
            this.categoryBrands = brands?.brands.filter(brand => brand.url && brand.name);
            return this.categoryBrands;
            // await findSimilarBrands(this.categories).then(brands => {
            //     // filter out brands with no url
            //     this.similarBrands = brands.filter(brand => brand.url && brand.name);
            // });
        },
        setCategories() {
            this.currentCategories = this.$route.params?.cat?.split(",") || [];
        },
        async selectBrand(index, brand) {
            return;
            // find index baseed on the url in this.styleGuides
            if (!index) index = this.styleGuides.findIndex(guide => guide.url === brand.url);

            const tryUrls = baseUrl => {
                const permutations = [];
                ["http", "https"].forEach(protocol => {
                    ["", "www."].forEach(subdomain => {
                        permutations.push(`${protocol}://${subdomain}${baseUrl.replace(/^https?:\/\/(www\.)?/, "")}`);
                    });
                });
                return permutations;
            };

            let url = this.styleGuides[index]?.url || brand.url;
            let object = null;

            for (const tryUrl of tryUrls(url)) {
                object = await findMatchingObject("brands", "url", tryUrl);
                if (object?.id) break;
            }

            if (!object?.id) {
                // this.offerNew = true;
                let newBrand = await saveToFirebase("brands", { ...brand, match: brand.id }, brand.id);
                object = await findMatchingObject("brandsList", "url", url);
                await saveToFirebase("brandsList", { ...brand, match: brand.id }, brand.id);
                // this.$router.push(`/styleGuide/${newBrand.id}`);
                await this.createStyleGuide(url, newBrand.id, { newGuide: true, id: newBrand.id });
                // this.query = "";

                return;
            }
            // else if (brand.id && !object.match) {
            //     await this.createStyleGuide(url, brand.id, { newGuide: true, id: brand.id });
            // }

            this.$router.push(`/styleGuide/${object.id}`);
        },
        async getBrands() {
            const guides = this.$store.state.styleGuide.styleGuides.filter(Boolean);
            const firstTen = guides.slice(0, 25);
            let styleGuides = await Promise.all(
                firstTen.map(async guide => {
                    try {
                        if (guide && guide.url) {
                            let brand = await findMatchingObject("brands", "url", guide.url);
                            if (brand?.images?.length > 0 && brand?.images?.[0]?.url) {
                                if (brand.url) {
                                    return {
                                        id: brand.id,
                                        name: brand.name,
                                        images: brand.images.slice(0, 3),
                                        fonts: brand.fonts || {},
                                        description: brand.description || "",
                                        url: brand.url || "",
                                    };
                                }
                            }
                        }
                    } catch (error) {
                        console.log(error.message);
                    }
                }),
            );
            this.brands = styleGuides.filter(Boolean);
        },
    },
};
</script>
