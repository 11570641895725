<template>
    <textarea
        class="p-4 pb-6 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
        placeholder="Ask me anything..."
        :value="modelValue"
        :id="id"
        :autocomplete="autocomplete"
        :required="required"
        :rows="rows"
        @input="$emit('update:modelValue', $event.target.value)"></textarea>
</template>
<script>
export default {
    name: "ChatTextArea",
    props: {
        id: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "off", // For textarea, it's common to turn off autocomplete
        },
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        rows: {
            // Added to control the height of the textarea
            type: Number,
            default: 3,
        },
    },
    emits: ["update:modelValue"],
};
</script>
