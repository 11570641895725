<template>
    <div ref="styleGuideContainer" v-if="styleGuide.base" class="mt-nav scrollSection" :key="$route.params.id">
        <div class="brand-bg">
            <div class="x z-0 relative">
                <UIWrapperText title="Style Guide">
                    <SimpleAlert class="mb-3" inline title="Takes 1-2 minutes" description="This will take a few minutes and may result in things going a little wacky." color="red" />
                    <div>
                        <h1>Refresh Style Guide</h1>
                        <p>
                            <b>Takes 1-2 Minute</b>
                            This will take a few minutes and may result in things going a little wacky.
                        </p>
                        <div class="my-4 text-left">
                            <SimpleButton style-type="secondary" label="Refresh" color="red" rounded icon="far fa-sync" size="sm" soft @click.prevent="updateStyleGuide(styleGuide.url)">
                                <i class="fas fa-sync"></i>
                                <span>Refresh</span>
                            </SimpleButton>
                        </div>
                    </div>
                </UIWrapperText>
                <StyleGuideColorsNew :key="colors" :colors="colors" />
                <LogoSection :imgs="logos" bg="" class="col-span-12" section-id="logos" section-name="Logos"></LogoSection>
                <StyleGuideButtons v-if="buttonStyles" :buttons="buttonStyles" />
                <!--                    <StyleGuideDivs v-if="styleGuide" />-->
                <!--                    <StyleGuideFonts class="x overflow-hidden w-80 mx-auto" />-->
                <FontList class="mx-5 w-80 mx-auto" />
                <FontSamplesPicker class="px-0 md:px-3 block mx-auto w-80" />
                <AssignedStyleDisplay></AssignedStyleDisplay>

                <!--                <UIWrapperText title="Typography Settings" class="col-span-12" section="stylesheet">-->
                <div class="w-80 mx-auto">
                    <StyleSheetSection v-if="styleGuide.stylesheet" class="x" />
                </div>
                <!--                </UIWrapperText>-->
                <StyleGuideTextSamples v-if="styleGuide?.textSamples" :samples="styleGuide.textSamples" />
            </div>
        </div>
        <BrandFooter />
    </div>
</template>

<script>
import StyleGuideButtons from "@/components/styleGuide/Sections/Buttons.vue";
import styleGuideBrandMixin from "@/mixins/StyleGuideMixins/StyleGuideBrandMixin";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import AssignedStyleDisplay from "@/components/styleGuide/Typography/AssignedStyleDisplay.vue";
import styleGuideCSSMixin from "@/mixins/StyleMixins/StyleGuideCSSMixin";
import StyleGuideColorsNew from "@/components/styleGuide/Colors/StyleGuideColorsNew.vue";
import LogoSection from "@/components/styleGuide/Sections/LogoSection.vue";
import BrandFooter from "@/views/BrandFooter.vue";
import StyleSheetSection from "@/components/styleGuide/Typography/StyleSheetSection.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";
import FontList from "@/components/styleGuide/Typography/StyleGuideFontsList.vue";
import FontSamplesPicker from "@/components/styleGuide/Sections/FontsSection.vue";
import SimpleButton from "@/views/SimpleButton.vue";
import SimpleAlert from "@/views/SimpleAlert.vue";
import MiniFeed from "@/components/generative_templates/Pages/MiniFeed.vue";

export default {
    components: {
        MiniFeed,
        SimpleAlert,
        SimpleButton,
        FontSamplesPicker,
        FontList,
        UIWrapperText,
        StyleSheetSection,
        BrandFooter,
        LogoSection,
        StyleGuideColorsNew,
        AssignedStyleDisplay,
        StyleGuideButtons,
    },
    mixins: [StyleGuideMixin, styleGuideBrandMixin, styleGuideCSSMixin],
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    async mounted() {
        this.scrollTop();
    },
    beforeUnmount() {},
    methods: {
        scrollTop() {
            if (this.$refs.styleGuideContainer) this.$refs.styleGuideContainer.scrollTop = 0;
        },
        sortKeysByValue(obj) {
            if (!obj) {
                obj = this.textStyles;
            }

            if (Array.isArray(obj)) {
                return obj.map(val => this.sortKeysByValue(val));
            } else if (typeof obj === "object") {
                const entries = Object.entries(obj);

                return Object.fromEntries(entries.sort(([, v1], [, v2]) => v2 - v1).map(([k, v]) => [k, this.sortKeysByValue(v)]));
            }
            return obj;
        },
    },
};
</script>
