<template>
    <div class="container-fluid pt-8 mt-nav x" :class="selectedBg">
        <div class="">
            <div class="flex items-center justify-center gap-1 fixed top left right mt-nav pt-nav z-5 f">
                <div v-for="(bg, index) in backgroundColors" @click="selectedBg = bg" :key="index" :class="[bg, { 'border border-base-50': bg === selectedBg }]" class="c-50 border-light"></div>
            </div>
            <div v-for="styleType in styleTypes" :key="styleType" class="space-y-4 mb-8 x">
                <h1 class="text-xl font-bold mb-2">{{ styleType }}</h1>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 x" v-for="size in sizes" :key="`${styleType}-${size}`">
                    <h2>{{ styleType }} {{ size }}</h2>
                    <div v-for="roundedState in [true, false]" :key="`${roundedState}`" :class="{ f: size !== 'xl' && size !== 'lg' && size !== 'md' }">
                        <span class="d-block">{{ roundedState ? "Rounded" : "Squared" }}</span>
                        <div v-for="line in lines" :key="`${iconOnly}`" class="m-1">

                            <div v-for="icon in icons" :key="`${icon}`" class="my-2 x f">
                                <template v-if="icon !== ''">
                                    <div v-for="iconOnly in [true, false]" :key="`${iconOnly}`" class="m-1 f">
                                        <Button
                                            :style-type="styleType"
                                            :outlined="outlined"
                                            :color="color"
                                            :rounded="roundedState"
                                            :line="line"
                                            :size="size"
                                            reverse
                                            :icon="icon"
                                            :iconOnly="iconOnly"
                                            @click="handleClick"
                                            :label="`${styleType} Button`" />
                                    </div>
                                </template>
                                <template v-else>
                                    <Button
                                        :style-type="styleType"
                                        :outlined="outlined"
                                        :color="color"
                                        :rounded="roundedState"
                                        :line="line"
                                        :size="size"
                                        :icon="icon"
                                        :iconOnly="false"
                                        @click="handleClick"
                                        :label="`${styleType} Button`" />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/CoreUI/BaseButton.vue";

export default {
    components: {
        Button,
    },
    data() {
        return {
            styleTypes: ["clear", "primary", "secondary", "success", "tertiary", "link","bubble"],
            outlined: false,
            color: null,
            sizes: ["xxs", "xs", "sm", "md", "lg", "xl"],
            icons: ["❤️", "fas fa-sync", "fab fa-google", "fas fa-spinner-third fa-spin","loader", ""],
            lines: [true, false],
            iconOnly: false,
            backgroundColors: [
                "bg-snow dark:bg-base-800",
                "bg-gray-50 dark:bg-base-900",
                "bg-gray-100 dark:bg-base-900",
                "bg-gray-200 dark:bg-base-800",
                "bg-gray-300 dark:bg-base-700",
                "bg-gray-400 dark:bg-base-600",
                "bg-gray-500 dark:bg-base-500",
                "bg-gray-600 dark:bg-base-400",
                "bg-gray-700 dark:bg-base-300",
                "bg-gray-800 dark:bg-base-200",
                "bg-sky-800 dark:bg-sky-700",
            ],
            selectedBg: null,
        };
    },
    methods: {
        handleClick(event) {
            console.log("Button clicked:", event);
        },
        getLabel(size) {
            return size.toUpperCase();
        },
    },
};
</script>
