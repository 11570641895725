<template>
    <div v-if="mapResults || localResults" class="br-10 p-relative map-container w-full overflow-hidden bg-gray-100 dark:bg-base-800 mb-4" :class="{'mw-350':mobile}">
        <div ref="mapContainer" class=""></div>
        <div class="f z-5 gap-2 overflow-x-scroll px-2 py-2 scrollbar-hide">
            <!--            <swiper v-if="localResults" class="swiper-container" :slides-per-view="1" :space-between="10" :pagination="{ clickable: true }" :navigation="{ clickable: true }" :scrollbar="{ draggable: true }" :loop="true">-->
            <swiper-slide v-for="place in pinsArray" v-if="pinsArray" class="place-container">
                <div class="cursor-pointer" @click.prevent="getPlace(place)">
                    <places-card :place="place" class="x d-block br-10 border-light places-card mr-2 overflow-hidden"></places-card>
                </div>
                <!--                <places-card :aspect="'square'" :place="place" class="x d-block br-10 border-light places-card mr-2 overflow-hidden"></places-card>-->
            </swiper-slide>
            <!--            </swiper>-->
        </div>
    </div>
</template>

<script>
// import { Vue } from "vue";
import { renderToString } from "@vue/server-renderer";
import { createApp, h } from "vue";

import chatMapAnnotate from "@/components/chat/specialMessages/Search/ChatMapAnnotate.vue";
import PlacesCard from "@/components/chat/specialMessages/PlacesCard.vue";

export default {
    name: "MapKit",
    components: { PlacesCard },
    props: {
        latitude: {
            type: Number,
            default: 37.7749,
        },
        longitude: {
            type: Number,
            default: -122.4194,
        },
        pins: {
            type: Array,
            default: () => [],
        },
        message: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            pinsArray: [],
            mapContainer: null,
            map: null, // add map property to your data
            authToken: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjJHUlc5V1E1QkQifQ.eyJpc3MiOiI5Uk1CNTdES1QzIiwiaWF0IjoxNjg1OTM2Mzc2LCJleHAiOjE3MTc1NDU2MDB9.0JCB5UNx6u3osG1Hu3s6GCUlfKWJt5rBsjmT7vgVgmAMvyjO4bSpEOlomm-BNZNVU8NWVqmjYPeeuNEcAMQe8g",
        };
    },
    beforeUnmount() {
        if (this.$refs.mapContainer && this.$refs.mapContainer.map) {
            this.$refs.mapContainer.map.destroy();
        }
    },
    async mounted() {
        if (this.localResults || this.mapResults) {
            await this.updatePins();
            this.$nextTick(() => {
                setTimeout(() => {
                    this.initMapKit();
                }, 500);
            });
        }
    },
    watch: {
        mapPins: {
            handler(newPins) {
                if (newPins && newPins.length > 0) {
                    // this.pinsArray = newPins;
                    this.updatePins();
                    this.$nextTick(() => {
                        // this.initMapKit();
                    });
                }
            },
            immediate: true,
            deep: true,
        },
        //     immediate: true,
    },
    computed: {
        getPlace(place) {

        },
        localResults() {
            if (this.message.results.places) {
                return this.message.results.places;
            }
            return false;
        },
        mapResults() {
            return false;
            if (this.message.results.answer_box && this.message.results.answer_box["map_results"]) {
                return this.message.results.answer_box.places;
            }
            return false;
        },
        mapPins() {
            if (this.message && this.message.results && this.message.results && this.message.results) {
                let locations = [];
                if (this.localResults && this.localResults.places) {
                    locations = this.localResults.places;
                } else {
                    locations = this.localResults;
                }
                if (this.mapResults) {
                    console.error("map_results", this.mapResults);
                    this.mapResults.forEach(result => {
                        console.error("result", result);
                        if (result.latitude && result.longitude) {
                            locations.push(this.$toRaw(result));
                        }
                    });
                    locations.concat(this.mapResults);
                }

                if (!this.mapResults && !this.localResults) {
                    return;
                }
                locations = locations.map(location => {
                    let pin = {};
                    let latitude;
                    let longitude;
                    if (location.location) {
                        latitude = location.location.latitude;
                        longitude = location.location.longitude;
                    } else {
                        latitude = parseFloat(location.latitude);
                        longitude = parseFloat(location.longitude);
                    }
                    if (latitude) pin.latitude = latitude;
                    if (longitude) pin.longitude = longitude;
                    if (location.link) pin.link = location.link;
                    if (location.description) pin.description = location.description;
                    if (location.title) pin.title = location.title;
                    if (location.thumbnail) pin.thumbnail = location.thumbnail;
                    if (location.image) pin.image = location.image;
                    if (location.website) pin.link = location.website;
                    if (location.price) pin.price = location.price;
                    if (location.rating) pin.rating = location.rating;
                    if (location.reviews) pin.reviews = location.reviews;
                    if (location.phone) pin.phone = location.phone;
                    if (location.links && location.links.directions) pin.directions = location.links.directions;
                    if (location.links && location.links.website) pin.website = location.links.website;
                    if (location.hours) pin.hours = location.hours;
                    pin = {
                        ...pin,
                        // ...location,
                    };
                    return pin;
                });
                return locations;
            }
            return false;
        },
    },
    methods: {
        async updatePins() {
            if (this.mapPins && this.mapPins.length > 0) {
                let mapPins = this.mapPins;
                let pins = [];
                if (this.mapPins && this.mapPins.length > 0) {
                    this.mapPins.forEach(pin => {
                        let newPin = pin;
                        // console.log("newPin", newPin);
                        pins.push(pin);
                    });
                    this.pinsArray = pins;
                }
                if (this.pins && this.pins.length > 0) {
                    this.pinsArray.concat(this.pins);
                }
                return pins;
            } else {
                return false;
            }
        },
        initMapKit() {
            // if (!mapkit) {
            mapkit.init({
                authorizationCallback: done => {
                    done(this.authToken);
                },
            });
            // }
            setTimeout(() => {
                this.initMap();
            }, 200);
        },
        displayCallout(annotation) {
            if (annotation && annotation.data && annotation.data.description) {
                const calloutElement = document.createElement("div");
                calloutElement.innerHTML = `<p>${annotation.title}</p><p>${annotation.data.description}</p>`;
                calloutElement.style.padding = "10px 20px";

                if (!annotation.callout.data) {
                    annotation.callout.data = calloutElement;
                }
            }
        },
        calloutDelegate() {
            return {
                calloutContentForAnnotation: annotation => {
                    const element = document.createElement("div");
                    element.className = "review-callout-content";
                    element.style.maxWidth = "250px";
                    if (annotation.data.image || annotation.data.thumbnail) {
                        const img = document.createElement("img");
                        img.className = "annotation-image";
                        img.src = annotation.data.image || annotation.data.thumbnail;
                        img.style.maxWidth = "75px";
                        img.style.padding = "0px";
                        img.style.height = "75px";
                        img.style.objectFit = "cover";
                        element.appendChild(img);
                    }
                    const textContainer = element.appendChild(document.createElement("div"));
                    textContainer.className = "annotation-text-container";
                    if (annotation.title) {
                        const title = textContainer.appendChild(document.createElement("p"));
                        title.className = "annotation_h1";
                        title.textContent = annotation.title;
                    }

                    if (annotation.data.description) {
                        const description = textContainer.appendChild(document.createElement("p"));
                        description.textContent = annotation.data.description;
                        description.className = "annotation_p";
                    }
                    if (annotation.data.phone) {
                        const phone = textContainer.appendChild(document.createElement("p"));
                        phone.className = "annotation_p";
                        phone.textContent = annotation.data.phone;
                    }
                    if (annotation.data.price) {
                        const price = textContainer.appendChild(document.createElement("p"));
                        price.className = "annotation_p";
                        price.textContent = annotation.data.price;
                    }
                    if (annotation.data.hours) {
                        const hours = textContainer.appendChild(document.createElement("p"));
                        hours.className = "annotation_p";
                        hours.textContent = annotation.data.hours;
                    }
                    if (annotation.data.directions) {
                        const directions = textContainer.appendChild(document.createElement("p"));
                        directions.className = "annotation_p";
                        directions.textContent = annotation.data.directions;
                    }
                    if (annotation.data.website) {
                        const website = textContainer.appendChild(document.createElement("p"));
                        website.className = "annotation_p";
                        website.textContent = annotation.data.website;
                    }
                    if (annotation.data.rating) {
                        const rating = textContainer.appendChild(document.createElement("p"));
                        rating.textContent = `Rating: ${annotation.data.rating}`;
                        rating.className = "annotation_p";
                    }
                    if (annotation.data.link) {
                        const link = textContainer.appendChild(document.createElement("a"));
                        link.href = annotation.data.link;
                        link.textContent = "Read more";
                    }

                    return element;
                },
            };
        },
        createMarkerWithDetails(pin) {
            const coordinate = new mapkit.Coordinate(pin.latitude, pin.longitude);
            let pinData = {
                image: pin.image,
                description: pin.description,
                rating: pin.rating,
                link: pin.link,
            };
            if (pin.title) pinData.title = pin.title;
            if (pin.phone) pinData.phone = pin.phone;
            if (pin.directions) pinData.directions = pin.directions;
            if (pin.website) pinData.website = pin.website;
            if (pin.price) pinData.price = pin.price;
            if (pin.thumbnail) pinData.thumbnail = pin.thumbnail;
            if (pin.image) pinData.image = pin.image;
            if (pin.hours) pinData.hours = pin.hours;
            const marker = new mapkit.MarkerAnnotation(coordinate, {
                callout: this.calloutDelegate(),
                title: pin.title,
                data: pinData,
            });

            return marker;
        },

        fitMapToCoordinates(map, coordinates, padding = new mapkit.Padding(100, 100, 100, 100)) {
            const minLat = Math.min(...coordinates.map(coordinate => coordinate.latitude));
            const maxLat = Math.max(...coordinates.map(coordinate => coordinate.latitude));
            const minLng = Math.min(...coordinates.map(coordinate => coordinate.longitude));
            const maxLng = Math.max(...coordinates.map(coordinate => coordinate.longitude));

            const center = new mapkit.Coordinate((minLat + maxLat) / 2, (minLng + maxLng) / 2);
            const span = new mapkit.CoordinateSpan(Math.abs(maxLat - minLat), Math.abs(maxLng - minLng));

            const region = new mapkit.CoordinateRegion(center, span);

            // Apply padding in degrees to the CoordinateSpan
            span.latitudeDelta += padding.top + padding.bottom;
            span.longitudeDelta += padding.left + padding.right;

            map.region = region;
            map.setRegionAnimated(region, 1000);
        },
        initMap() {
            if (!window.mapkit || !mapkit.Coordinate) return;

            this.mapContainer = this.$refs.mapContainer;
            this.mapContainer.map = new mapkit.Map(this.mapContainer); // Store the map instance on the DOM node directly
            this.mapContainer.map.showsUserLocation = true;

            const allCoordinates = this.pinsArray.map(pin => {
                const marker = this.createMarkerWithDetails.call(this, pin);
                this.mapContainer.map.addAnnotation(marker);
                return new mapkit.Coordinate(pin.latitude, pin.longitude);
            });

            const padding = new mapkit.Padding(100, 100, 100, 100);

            // If there are pins, set the region to fit all pins. Otherwise, set the region around the center coordinate.
            if (allCoordinates.length > 0) {
                this.fitMapToCoordinates(this.mapContainer.map, allCoordinates, padding);
            } else {
                const coordinates = new mapkit.Coordinate(this.latitude, this.longitude);
                const region = new mapkit.CoordinateRegion(coordinates, new mapkit.CoordinateSpan(0.01, 0.01));
                this.mapContainer.map.region = region;
            }

            // Add an event listener for the 'select' event.
            this.mapContainer.map.addEventListener("select", event => {
                this.displayCallout(event.annotation);
            });
        },
    },
};
</script>

<style lang="scss">
#map {
    width: 100%;
    height: 400px;
}
.map-container {
  .place-container {
    @apply width-max-200;
    &:hover {
      //@apply width-max-200;
      @apply transition-all;
    }

    .place_image {
      //@apply w-full;
      @apply aspect-h-1;
      @apply aspect-w-4;
      @apply object-cover;
    }
  }

  .places-card {
    //@apply bg-base-900;
    @apply h-full;
    @apply bg-snow dark:bg-base-700 dark:text-base-300;
    @apply transition-all;
    @apply hover:shadow-lg;
    &:hover {
      @apply shadow;
      @apply width-max-300;
      @apply transition-all;
    }
  }
}

.mk-map-view {
  width: 100% !important;
  @apply aspect-h-2;
  @apply aspect-w-4;
  @apply transition-all;

  &:hover {
    /*@apply transition-all;*/
    /*@apply aspect-h-4;*/
    /*@apply aspect-w-4;*/
  }
}

.review-callout-content {
  @apply flex;

  .annotation-text-container {
    @apply p-2;

    .annotation_h1 {
      font-size: 15px;
      @apply font-bold;
      @apply mb-0;
      line-height: 1;
    }

    .annotation_p {
      font-size: 12px;
      @apply mb-0;
      line-height: 1;
      @apply font-normal;
    }
  }
}

.review-callout-content {
  @apply text-sm;

  border-radius: 10px;

  /*@apply bg-teal-500;*/
  @apply p-2;
  h1, h2, h3 {
    /*@apply text-sm;*/
    font-size: 10px;
  }
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}
</style>
