// getCopyGuideMixin.js
import { mapGetters } from "vuex";
import completion from "@/mixins/ai/completion";
import webRequest from "@/mixins/ai/web_request";
import { updateFirestoreProperty } from "@/mixins/firebase/updateFirebaseProp";
export default {
    data() {
        return {};
    },
    computed: {},
    methods: {
        async getBrandPersonas(input, overrides) {
            let model = gpt3;
            // model = gpt316;
            await updateFirestoreProperty("brands", {}, this.styleGuide.id, "copyGuidelines.overview.personas");
            this.updateStyleGuideProp("copyGuidelines.overview.personas", []);
            this.setActionBrandPersonas();
            let { name, type } = this.selectedAction; // get the name and type of the selected action. The prompt will be automatically added via the streamFunction(obj) method. // set the action to get_brand_voice. This sets the prompt and the function_call as well.
            let mods = {
                // model: "gpt-3.5-turbo-1106", // this is the model that will be used to generate this.
                model: model, // this is the model that will be used to generate this.
                messages: deepCopy(this.messages), // this is the messages that will be used to generate this.
                temperature: 0, // this is the temperature that will be used to generate this.
                function_call: name, // this is the function_call name that will be called to generate this. It needs to match the fucntion being called either locally or on the server.
                functions: undefined, // this is the functions object that will be used  to generate this. If blank it will use the server and name to route to the right function if it exists.
                length: 3000, // the length of the message to generate
                persistChat: true, // if true, it streams the result to the messages array in the stream store. This can be used to see the streaming results in chat, but it can also be used to save the results to the database, "chat" add revisions with context, etc.
                identifier: "personas", // this helps route the stream to the right place and find it later. Good for complex objects and nested sections. For example: 'campaignData.result.schedule_items.${campaignIndex}.campaign_items.${itemIndex}.result'. It is also possible to set a custom identifier for the stream location.
                brand: this.$route.params.id, // set the brand id from the route params but this could be set from anywhere optionally.
                input: input, // this is the user input that will added to messages.
                type: type, // what type of campaign is this? Currently only used for campaigns.
                storeKey: "styleGuide", // This is the key of the store to save to. For example $store.state.styleGuide
                prop: "personas", // the prop used in the store
                // localHandle: "copyGuidelines", //the prop used in the local component
                save: false, // if true, use the afterCompletion function to define how to save the results.
                returnTo: "result",
                nested: false,
            };
            if (overrides) mods = { ...mods, ...overrides };

            return await this.callFunction(mods);
        },
        async generatePersonaImagePrompt() {
            let model = gpt316;
            model = gpt4;

            let imagePrompt = await completion({
                user: [
                    sysMessage(
                        `You're an expert AI in writing DALLE-3 Image prompts. Create a scene based on the input provided. It must accurately portray the persona's criteria.
EXAMPLE OUTPUT:
'Shot on a 35mm leica. A realistic photograph of a 25 year old African American male with an average body-type wearing realistic casual clothes, browsing through health products in new york grocery store. Showing a curious expression while reading product labels and comparing different items. The grocery store showcases a variety of health products in the background and is lit with hues of green and orange.

RULES:
- This is for a modern brand so make sure references like 'nostalgic' refer to the demographic's definition of nostalgia (ie. 90's nostalgia, 80's nostalgia).
- Describe the scene as a realistic photograph shot on an iphone, a 35mm leica or a DSLR. Be very visually descriptive and detailed.
- Ensure it abides by DALL-E's image guidelines (ie. avoid overemphasis on body-parts or scenes which can be interpreted as containing nudity)
– Detail the scene's lighting.
– Be mindful of accurately representing the body-type of the person in the scene, especially if it's an inclusive brand.
- IMPORTANT: Keep it under 40 words.
                        `,
                    ),
                    sysMessage("Under 40 words."),
                    userMessage(`Here are the brand's colors to optionally include:\n${this.colorsString}\n\n (convert them to normal english when including them - ie. 'light blue')`),
                    userMessage(`Make a DALLE prompt for realistic photograph prompt for this persona:\n\n${this.personaDescription}`),
                    userMessage("AVERAGE BODY TYPE. Make people look normal, not like models."),
                    // userMessage("iPhone Shot on iphone or DSLR Leica 35mm. "),
                    userMessage("Make sure there are NO GRAPHICS OR TEXT in the image."),
                ],
                system: undefined,
                id: "image prompt",
                model: model,
                tokens: 120,
            });
            console.log(imagePrompt);
            this.imagePrompt = imagePrompt;
            await this.getPersonaImage(imagePrompt);
            return imagePrompt;
        },
        async getPersonaImage(prompt) {
            let request = {
                text: `Realistic Photo. Body-type: Average.\n${prompt}\n NO Text. NO graphics.`,
                kind: "dalle",
                process: true,
                size: "lg",
                // style: "vivid",
                // style: "natural",
            };
            let response = await webRequest("text-to-image", request);
            response = await response.json();
            let image = response.image;
            this.personaImageURL = image;
            try {
                this.updateStyleGuideProp(`copyGuidelines.overview.personas.${this.index}.imagePrompt`, prompt);
                this.updateStyleGuideProp(`copyGuidelines.overview.personas.${this.index}.image`, image, true);
                // this.$store.dispatch("styleGuide/updateStyleGuideProp", {
                //     keyPath: `copyGuidelines.overview.personas.${this.index}.imagePrompt`,
                //     value: prompt,
                //     // save: true,
                // });
                // this.$store.dispatch("styleGuide/updateStyleGuideProp", {
                //     keyPath: `copyGuidelines.overview.personas.${this.index}.image`,
                //     value: image,
                //     save: false,
                // });
            } catch (e) {
                console.error(e.message);
            }
        },
    },
};
