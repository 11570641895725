import { ref as storageRef } from "firebase/storage";
import JSON5 from "json5";
import streamingMixin from "@/mixins/ai/streamingMixin";
import { debounce, update } from "lodash";
import { mapActions, mapGetters } from "vuex";
import enhancePresentation from "@/mixins/presentations/EnhancePresentation";
import BuildPresentationMixin from "@/mixins/presentations/BuildPresentationMixin";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import updateDocumentProp from "@/mixins/ai/update_document_prop";
import addImagesToQueue from "@/mixins/ai/add_images_to_que";
import updateSlideProp from "@/mixins/ai/update_slide_prop";
import googleImageSearch from "@/mixins/ai/google_image_search";
import completion from "@/mixins/ai/completion";
import log_function from "@/mixins/ai/log_function";
import logFunction from "@/mixins/ai/log_function";
import messageLogger from "@/mixins/ai/message_logger";
import streamCompletion from "@/mixins/ai/stream_completion";
import create_presentation from "@/ai_functions/create_presentation";
import setSlide from "@/mixins/ai/set_slide";
import create_slide from "@/ai_functions/create_slide";
import sleep from "@/mixins/Chat/Commands/delay_timer";
import create_timeline_slide from "@/ai_functions/create_timeline_slide";
export const presStore = {
    state() {
        return {};
    },
    mutations: {},
};
export default {
    created: function () {},
    mixins: [BuildPresentationMixin, streamingMixin, enhancePresentation],
    data() {
        return {
            subject: "",
            slideDataList: [],
        };
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue", "imagesList"]),
    },
    methods: {
        ...mapActions("presentation", ["setTitle", "getPresentation", "savePresentation", "addSlide", "updatePresentation", "setDocId", "clearSections"]),
        setDocument(d) {
            window.doc = d;
            this.$store.commit("presentation/setDocument", d);
        },
        addSlideAtIndex(slide, index) {
            this.$store.dispatch("presentation/addSlideAtIndex", { slide, index });
        },
        async summarizePresentation(presentationText, force) {
            if (this.document.summary && !force) return this.document.summary;
            let extractedText = this.extractPresentationText;
            console.log(extractedText);
            let messages = [sysMessage("Write a summarized description about the presentation in 20 words or less. Example:'The differences and similarities between dogs and cats, including their evolution, historical significance, characteristics, training, and health benefits.'"), userMessage(extractedText)];
            let summary = await this.completion(messages, null, "summarize presentation in 20 words or less", gpt3, 150, false, 1, false, false);
            updateDocumentProp(this.$store, "summary", summary);
            return summary;
        },
        updateSlideProp(key, value, index) {
            updateSlideProp(this.$store, key, value, index);
        },
        updateDocumentProp(key, value) {
            updateDocumentProp(this.$store, key, value);
        },
        updateQueueProp(key, value) {
            updateQueueProp(this.$store, key, value);
        },
        addImagesToQueue(images, url) {
            addImagesToQueue(this.$store, images, url);
        },
        scrollToSlide(index) {
            console.error(index);
            const ref = `slide${index}`;
            console.error(ref);
            const element = document.getElementById(ref);
            console.error(element);
            element.scrollIntoView({ behavior: "auto", block: "center" });
        },
        async addSlideBelow(newSlidePrompt) {
            //add section below current section in documents.section (slice array)
            const context = this.extractPresentationText;
            let slideAContext = `Slide ${this.index + 1} (${this.document.sections[this.index].title || ""})`;
            let slideBContext = `Slide ${this.index + 2} (${this.document?.sections?.[this.index + 1]?.title || "the last slide"})`;
            let messages = [sysMessage(`Create new slides as requested.\nEnsure they do not already exist in the presentation.\nNEVER REPEAT EXISTING SLIDES\nUse the fact sheet\n- Cite sources.`)];
            messages.push(sysMessage("Presentation Outline:\n\n" + context));

            // let newContext = JSON.stringify(this.document.sections);
            // messages.push(userMessage(newContext));
            let slide = {
                title: " ",
                body: " ",
                style: "imageleft",
                image: "",
                // background_class: "bg-dark-900",
                // mode: "dark",
            };
            const facts = this.document.research.facts;
            messages.push(sysMessage(facts));
            const functions = this.addSmartSlide;
            messages.push(userMessage(`Make a new slide with content that fits naturally between ${slideAContext} and ${slideBContext || "the end"}.\n\nDO NOT REPEAT EXISTING SLIDES\nUse the fact sheet.\n\nCite sources.`));
            let slideDraft = {};
            if (this.newSlidePrompt !== "") {
                const newSlide = "The slide should be about:" + this.newSlidePrompt;
                messages.push(userMessage(newSlide));
            }

            this.$store.dispatch("presentation/addSlideAtIndex", { slide: slide, index: this.index + 1 });
            this.slideClass(undefined, this.index + 1);
            this.newSlidePrompt = "";
            let imageList = sysMessage(`Existing Image List:\n\n${this.$store.getters["presentation/imagesList"]}`);
            messages.splice(messages.length - 1, 0, imageList);
            let images = this.$store.getters["presentation/images"];
            await streamCompletion(
                this.$store,
                messages,
                undefined,
                undefined,
                undefined,
                (token, function_object, json) => {
                    if (json?.imageIndex) {
                        let imageIndex = json.imageIndex || 0;
                        let url = images[imageIndex]?.url;
                        let prompt = images[imageIndex]?.title || "";
                        json.images = [{ url, prompt }];
                    }
                    slide = { ...slide, ...json };
                    setSlide(this.$store, slide, this.index + 1);
                    // setSlide(this.$store, { ...slide, ...json }, this.index + 1);
                },
                () => {
                    let url = images[slide.imageIndex]?.url;
                    let prompt = images[slide.imageIndex]?.title || "";
                    slide.images = [{ url, prompt }];
                    setSlide(this.$store, slide, this.index + 1);
                },
                gpt316,
                undefined,
                `New slide`,
                0.0,
                [create_slide, create_timeline_slide],
                "auto",
                1000,
            );
            if (!this.document.sections[this.index + 1].background_class) {
                this.slideClass(undefined, this.index + 1);
            }
            await sleep(100);
            if (slide.style !== "timeline") {
                this.enhanceBodyStream(slide.body, this.index + 1, context, slide, facts);
                await sleep(1000);
                if (!slide.imageIndex) this.getGoogleImagesForSlide(slide, this.index + 1);
            }
            this.slideClass(undefined, this.index + 1);
            await this.savePresentation(this.$route.params.id);
            await sleep(500);
        },
        async getGoogleImagesForSlide(slide, index) {
            console.log("SLIDE DRAFT", slide);
            if (this.document.sections[this.index + 1].google_image_query) {
                updateQueueProp(this.$store, "loading", false);
                let newImages = await googleImageSearch(this.$store, slide.google_image_query, undefined, this.userPlaceTime);
                let image = newImages[0];
                image.prompt = image.source;
                updateSlideProp(this.$store, "images", [image], this.index + 1);
                // updateDocumentProp(this.$store, "images", [...newImages, ...this.document.images]);
                await this.savePresentation(this.$route.params.id);
            }
        },
        async enhanceBodyStream(body, index, context, slide, facts) {
            // let response = await completion(messages, `Fill in the slide the new slide presentation based on the context. Cite sources.`, "add slide below", gpt316, 1000, false, 0, false, functions, "create_slide");
            const newContext = this.extractPresentationText;
            let { prompt, systemMessage } = await this.buildEnhancedBodyPrompt(slide.body, this.index + 1, newContext, slide, this.document);
            console.log("enhanced prompt", prompt);
            let enhancedBodyString = "";
            let emessages = [sysMessage(this.document.summary)];
            emessages.push(sysMessage("Presentation Outline:\n\n" + this.extractPresentationText));
            emessages.push(sysMessage(facts));
            emessages.push(sysMessage(prompt));
            emessages.push(userMessage(this.document.sections[this.index + 1].body));
            let response = await streamCompletion(
                this.$store,
                emessages,
                undefined,
                undefined,
                undefined,
                (token, token2, token3) => {
                    enhancedBodyString += token;
                    updateSlideProp(this.$store, "enhancedBody", enhancedBodyString, this.index + 1);
                },
                undefined,
                gpt3, //gpt316,
                undefined,
                `Update slide`,
                0.0,
            );
            log_function(response);
            this.savePresentation(this.$route.params.id);
            this.clearQueue();
        },
        removeSlide(index) {
            this.$store.dispatch("presentation/removeSlide", index);
        },
        async startNewDeck(prompt, kind) {
            this.newDeckStarted = true;
            console.error("saving new deck");
            const tempId = this.randomId();
            const timestamp = Date.now();
            let doc = {
                title: "New presentation",
                theme: {
                    colors: null,
                    shades: null,
                },
                id: null,
                created: timestamp,
                updated: timestamp,
                documentType: "presentation",
                sections: [
                    // {
                    //     title: "New slide",
                    //     imageURL: null,
                    //     body: "new slide",
                    //     id: tempId,
                    //     created: timestamp,
                    //     updated: timestamp,
                    // },
                ],
            };
            const networkDoc = await this.addToFirebase("documents", doc);
            this.$router.push({ path: `/presentations/${networkDoc.id}` });
            console.error(networkDoc);
            this.$forceUpdate();
        },
        getPresentationOverview(summary = "", overview = "", slideNumber = 1) {
            return `\n[OVERVIEW]\n ${summary}\n\n---\n\n[PRESENTATION SLIDES]\n${overview}---\n\n`;
        },

        styleFixer(word) {
            const styleMap = {
                right: ["right", "imageright", "rslide"],
                left: ["left"],
                toc: ["tableofcontent", "toc"],
                matrix: ["matrix"],
                timeline: ["timeline"],
                tablegrid: ["tablegrid", "list"],
                fullBleed: ["fullbleed", "cover", "full bleed", "fullbleedimage"],
            };

            let slideStyle;
            try {
                slideStyle = word?.toLowerCase();
            } catch (error) {
                console.error(error);
                return "left";
            }

            for (let style in styleMap) {
                if (styleMap[style].some(pattern => slideStyle?.includes(pattern))) {
                    return style;
                }
            }

            return "left";
        },
        // debounce save
        debounceSaveDeck: debounce(function () {
            console.groupCollapsed("%c 💾Debounce saving deck", info);
            console.log(this.$toRaw(this.document));
            console.log(this.$toRaw(this.sections));
            console.log(this.$toRaw(this.document.sections));
            console.groupEnd();
            // console.error("saving chat debounce");
            this.savePresentation(this.$route.params.id);
        }, 500),
    },
};
