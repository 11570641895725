<template>
    <div class="">
        <div ref="trigger"></div>
        <div ref="container" class="">
            <div ref="innerContainer" class="f aic jcc fixed p-fill">
                <div class="text-center f aic jcc fc">
                    <h1 ref="headlineText" :class="{ 'f-70': mobile, 'f-200': !mobile }"></h1>
                    <h1 ref="bilineText" class="whitespace-nowrap" :class="{ 'f-70': mobile, 'f-200': !mobile }"></h1>
                    <div ref="colorContainer" class="f gap-5 pb-8 mx-auto jcc aic vh-100">
                        <template v-for="(color, index) in colorPalette" :key="color">
                            <div :ref="`color-${index}`" class="block mb-auto">
                                <ColorPallet :color="color" :w="`${mobile ? 100 : 200}`" class="p-relative flex-shrink-0" />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ColorPallet from "@/components/styleGuide/Colors/ColorPallet.vue";
import { Back, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsapMixin from "@/mixins/ai/gsapMixin";
gsap.registerPlugin(ScrollTrigger);
const { timeline, set, to, from } = gsap;
export default {
    name: "LandingPageColorPallette",
    components: { ColorPallet },
    mixins: [gsapMixin],
    data() {
        return {
            headerSize: 200,
            showMarkers: false,
            timeline: {},
        };
    },
    async mounted() {
        if (this.mobile) this.headerSize = 100;
        this.$nextTick(() => {
            this.timeline = timeline();
            this.textAnimation();
            this.colorPalleteAnimation();
        });
    },
    methods: {
        textAnimation() {
            this.$nextTick(() => {
                let container = this.$refs.container;
                let trigger = this.$refs.trigger;
                let innerContainer = this.$refs.innerContainer;
                let headline = this.$refs.headlineText;
                let biline = this.$refs.bilineText;
                let length = "+=2000";
                // length = "+=1000";
                // let start = "top bottom";
                let start = "top bottom-=100px";
                let words = ["Style Guides", "Copywriting Guides", "Campaigns", "Emails", "Ad Creative", "Landing Pages"];
                let colors = ["#FFFF00", "#3df43d", "#6495ED", "#FF3333", "#67ffea", "#8884ff"];
                set(innerContainer, { opacity: 0 });
                set(biline, { id: "a2", text: { value: words[0] }, color: colors[0] });
                let tl = this.timeline;
                tl = timeline({
                    id: "Timeline1",
                    scrollTrigger: {
                        //
                        trigger: trigger,
                        start,
                        // end: "bottom top+=200",
                        // end: "center top",
                        end: length,
                        pin: container,
                        pinSpacing: true,
                        scrub: true,
                        markers: this.markers({ show: this.showMarkers, inset: 400 }),
                    },
                });
                tl.addLabel("beginning", 0);
                tl.addLabel("middle", 0.5);
                tl.addLabel("finished", 2);

                //ENTERING
                tl.fromTo(innerContainer, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 0.1, ease: "back.out(2)" }, "beginning");
                tl.fromTo(biline, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 0.1, ease: "back.out(2)" }, "middle");

                //TEXT
                let t2 = timeline({
                    id: "Timeline2",
                    scrollTrigger: { trigger: trigger, start: start, end: length, scrub: true, markers: this.markers({ show: this.showMarkers, inset: 500 }) },
                });
                t2.addLabel("beginning", 0);
                t2.addLabel("middle", 0.5);
                t2.addLabel("finished", 2);
                set(headline, { text: { value: "Create your", delimiter: "\n" } });
                t2.to(headline, { text: { value: "Create your", delimiter: "\n", duration: 0 } }, "beginning");
                t2.to(headline, { text: { value: "Design your", delimiter: "\n", duration: 0 } }, "beginning+0.5");

                //FADE OUT
                tl.to([biline, headline], { opacity: 0, y: 10, ease: "back.out(2)", duration: 0.05 }, 1);

                tl.to(innerContainer, { opacity: 0, y: 0, ease: "back.out(2)", duration: 0.1 }, "finished");
                t2.to(container, { opacity: 0, duration: 0.1 }, "finished");
                // t2.to(biline, { text: { value: "Campaigns", delimiter: " ", duration: 0.2 }, color: "yellow" }, 0.2);
                words.forEach((word, index) => {
                    let offset = 0.2 * index;
                    t2.fromTo(
                        biline,
                        {
                            opacity: 0,
                            y: 30,
                            text: { value: word, delimiter: "\n", duration: 0.5 },
                            color: colors[index],
                            textShadow: `0px 0px -1000px ${colors[index]}`,
                        },
                        {
                            opacity: 1,
                            y: 0,
                            duration: 0.2,
                            text: { value: word, delimiter: "\n" },
                            color: colors[index],
                            textShadow: `0px 0px 50px ${colors[index]}`,
                            ease: "back.out(2)",
                        },
                        offset,
                    );
                    if (index !== words.length - 1)
                        t2.to(
                            biline,
                            {
                                opacity: 0,
                                y: 50,
                                duration: 0.05,
                                textShadow: `0px 0px 0px ${colors[index]}`,
                                immediateRender: false,
                            },
                            offset + offset / 2,
                        );
                    // t2.to(biline, { id: "word3", text: { value: word, delimiter: "\n", duration: 0.2 }, color: colors[index] }, offset + offset / 2);
                });
            });
        },
        colorPalleteAnimation() {
            this.$nextTick(() => {
                let container = this.$refs.container;
                let trigger = this.$refs.trigger;
                let colorContainer = this.$refs.colorContainer;
                let start = "top bottom-=100px";
                let tl = timeline({
                    id: "colorPallete",
                    scrollTrigger: {
                        trigger: trigger,
                        start,
                        end: "+=1000",
                        scrub: true,
                        markers: this.markers({ show: this.showMarkers, inset: 150 }),
                    },
                });
                let middle = 0.5;
                tl.addLabel("beginning", 0);
                tl.addLabel("middle", middle);
                tl.addLabel("end", 1);
                set(colorContainer, { height: 0 });
                tl.to(colorContainer, { height: 350, duration: 1 }, "beginning");
                let colors = this.colorPalette;
                container.style.marginTop = "30px";

                colors.forEach((color, index) => {
                    let cr = this.$refs[`color-${index}`];
                    tl.fromTo(cr, { color: color, y: 50, opacity: 0, immediateRender: false }, { color: color, y: 0, opacity: 1, duration: 0.5, ease: "back.out(2)" }, `-=0.4`);
                });
                let fadeOutTimeline = timeline({
                    scrollTrigger: {
                        trigger: container,
                        start: "top bottom",
                        end: "+=2500",
                        scrub: true,
                        markers: this.markers({}),
                    },
                });
                fadeOutTimeline.to(this.$refs["color-0"], { y: 50, opacity: 0, duration: 0.5, ease: "back.out(2)", immediateRender: false }, 0.95);
                colors.forEach((color, index) => {
                    let cr = this.$refs[`color-${index}`];
                    fadeOutTimeline.to(cr, { color: color, y: 50, opacity: 0, duration: 0.5, ease: "back.out(2)", immediateRender: false }, "-=0.4");
                });
            });
        },
    },
};
</script>
