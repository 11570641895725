<template>
    <div class="" :class="{ 'nav-link px-2': !avatar }" @mouseleave="leaveMenu" @mouseover="hoverMenu">
        <div v-if="user && avatar" class="nav-link mr-2 p-0">
            <router-link to="/auth">
                <div class="c-40 overflow-hidden" style="border: 2px solid black">
                    <img :src="`${user.photoURL}`" class="x" @error="handleImageError" />
                </div>
            </router-link>
        </div>
        <BaseButton v-else class="" label="Tools" rounded size="md" style-type="link"></BaseButton>
        <ul v-if="showSubMenu" class="dropdown-content p-fixed p-3 br-5 right mr-2 z-5 f fc bg-snow dark:bg-base-700 border-light animated fadeInUpSmooth shadow-inset">
            <li v-for="item in subArray" v-delay:li="1000" class="nav-link px-2 animated fadeInUpSmooth">
                <RouterLink :to="item.path">
                    <BaseButton :label="item.title" class="" rounded size="md" style-type="link"></BaseButton>
                </RouterLink>
            </li>
        </ul>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "nav-item",
    components: { BaseButton },
    props: {
        subArray: {},
        avatar: {
            //boolean
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        showSubMenu: false,
    }),
    methods: {
        hoverMenu() {
            if (this.subArray && this.subArray.length > 0) {
                this.showSubMenu = true;
            }
        },
        leaveMenu() {
            this.showSubMenu = false;
        },
    },
};
</script>
