<!--TEAM MEMBER-->
<template>
    <li class="list-none py-2 px-2 border-light f x aic jcb">
        <div class="f x aic jcb">
            <div>
                <span class="fwb d-block f-20">{{ member.name }}</span>
                <span class="d-block fwn o-5">{{ member.email }}</span>
            </div>
            <div class="px-1">
                <span v-if="!showDropdown" @click="toggleDropdown" class="border-light br-5 text-capitalize f-15 py-1 px-2 bg-gray-100 fwb text-gray-500 inline-block">
                    {{ member.role }}
                </span>
                <select v-else v-model="localRole" @blur="toggleDropdown" class="border-light br-5 text-capitalize f-15 py-1 px-2 bg-gray-100 fwb text-gray-500 inline-block">
                    <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                </select>
            </div>
        </div>
        <div class="c-10 bg-green-400" v-if="member.is_active"></div>
    </li>
</template>

<script>
export default {
    props: {
        member: {
            type: Object,
            required: true,
        },
        roles: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showDropdown: false,
            localRole: this.member.role,
        };
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        updateRole() {
            if (this.localRole !== this.member.role) {
                this.$emit("update-role", this.member.id, this.localRole);
            }
        },
    },
    watch: {
        localRole(newRole) {
            this.updateRole();
        },
    },
};
</script>
