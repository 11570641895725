<!--text component receiving slot data -->
<template>
    <div class="block section overflow-hidden">
        <div class="grid grid-cols-12 relative" v-if="section" :style="imageLogic" :class="{ 'vh-90': fullBleed, 'vh-60': !fullBleed }">
            <Transition name="fade-up" appear>
                <template v-if="imageURL && imageLeft">
                    <div class="col-span-12 !relative md:col-span-6 transition-all">
                        <LandingPageImage :images="images" :key="`image${index}Left`" :section="section" :index="index" appear class="block transition-all" :image="image"  identifier=""/>
                    </div>
                </template>
            </Transition>
            <div class="p-5 f aic jcc relative order-1 md:order-0" :class="{ 'col-span-12': fullBleed, 'col-span-12 md:col-span-6': !fullBleed }">
                <Transition name="fade-up" appear>
                    <div v-if="imageURL && fullBleed" class="p-absolute p-fill z-1 o-cover transition-all">
                        <LandingPageImage :section="section" :images="images" :index="index" class="x y transition-all" :image-inset="imageInset" :class="[imageRoundClasses, imageSquareClasses]" :image="image"  identifier=""/>
                    </div>
                </Transition>

                <div class="z-2" :class="{ 'text-center x mw-900': fullBleed, textAlign: !fullBleed, 'p-md-8 mw-800': !fullBleed }" :style="[textColorOverride]">
                    <ImageGradient v-if="fullBleed && image" class="z-1 x y" radial :image="{ color: section.backgroundColor }" />
                    <!--                    <ImageGradient v-if="fullBleed && image" class="z-1 x y" radial :image="image" />-->
                    <div class="z-2 relative mx-auto pb-5 md:pb-0" :class="{ 'mw-800': !fullBleed, 'mw-600': !fullBleed }">
                        <HeaderText v-if="header" :font="font" :font-override="sectionHeaderFont" :full-bleed="fullBleed" :header="header" :text-color-override="textColorOverride" />
                        <BodyText v-if="body" :body="body" :font="font" :font-override="sectionBodyFont" :text-color-override="textColorOverride"  identifier="" :index="index"/>
                        <SectionButton :button="button" :index="index" class="mt-4" :button-css="buttonCss"  :image-color="imageColor" />
                        <DebugObject class="mt-5 absolute bottom left -bottom-32" :object="section"></DebugObject>
                    </div>
                </div>
            </div>
            <Transition name="fade-up" appear>
                <template v-if="imageURL && imageRight">
                    <div class="col-span-12 !relative md:col-span-6 transition-all order-0 md:order-1">
                        <LandingPageImage :images="images" :key="`image${index}Right`" :section="section" :index="index" appear class="block transition-all" :image="image" />
                    </div>
                </template>
            </Transition>

            <div class="p-absolute bottom p-3 z-5 x justify-fixed-center mw-300" @mouseleave="showSettings = false" :class="{ 'left right': imageLeft, 'left right': imageRight }">
                <Transition name="fade-up" appear>
                    <div class="pb-5 p-absolute bottom z-4 mx-auto left right x" v-if="showSettings">
                        <!--                        :class="{ left: imageLeft, right: imageRight }"-->
                        <SectionSettings class="mx-auto" :colors="colors" :index="index" :section="section"  identifier=""/>
                    </div>
                </Transition>

                <a @mouseenter="showSettings = true" href="#" class="bg-snow edit_button relative transition-all text-gray-900 mx-auto hover:bg-gray-50/10 border border-gray-50 p-3 c-30 f aic jcc mix-blend-difference shadow border">
                    <div class="absolute p-fill z-1 blurred"></div>
                    <i class="fas fa-ellipsis-h relative z-1"></i>
                </a>
            </div>
            <div v-if="index === 0" class="p-absolute top left right z-6">
                <brand-logo-component :color="textColorOverride" width="150px" class="mt-4 mw-150"></brand-logo-component>
            </div>
        </div>
    </div>
</template>
<script>
import chroma from "chroma-js";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import ImageGradient from "@/components/generative_templates/ImageGradient.vue";
import LandingPageImage from "@/components/generative_templates/Pages/LandingPageImage.vue";
import SectionSettings from "@/components/generative_templates/Editing/SectionSettings.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import BodyText from "@/components/generative_templates/Editing/BodyText.vue";
import HeaderText from "@/components/generative_templates/Editing/HeaderText.vue";
import SectionButton from "@/components/generative_templates/Editing/SectionButton.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import SectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";

export default {
    name: "TestSlotComponent",
    components: { BrandLogoComponent, SectionButton, HeaderText, BodyText, SectionSettings, LandingPageImage, ImageGradient, DebugObject },
    mixins: [styleGuideMixin,  styleGuideSettingsMixin, SectionStyleMixin],
    computed: {
        section() {
            return this.result;
        },
        image() {
            let imageIndex = this.imageIndex;
            if (imageIndex !== null && imageIndex !== undefined) {
                return this.images[imageIndex];
            } else {
                return {};
            }
        },
        sectionHeaderFont() {
            return this.section?.headerFont;
        },
        sectionBodyFont() {
            return this.section?.bodyFont;
        },
        imageRoundClasses() {
            if (this.isRound) return `aspect-w-1 aspect-h-1 rounded-full overflow-hidden `;
        },
        imageLogic() {
            let imageBackground = this.imageColor;
            let backgroundColor = this.backgroundColor;
            let color = this.textColor;
            let finalColor;
            let finalBackground;
            if (this.transparent || this.fullBleed) {
                finalBackground = imageBackground;
                if (this.image.backgroundColor) finalBackground = this.image.backgroundColor;
                finalColor = this.findContrastingColor(imageBackground);
            } else {
                finalBackground = backgroundColor;
                finalColor = color;
                // finalColor = this.findContrastingColor(backgroundColor);
            }
            // this.backgroundColor = finalBackground;
            // this.textColor = finalColor;
            if (this.section.color && this.section.backgroundColor) {
                finalColor = this.section.color.replace("!important;", "");
                finalBackground = this.section.backgroundColor.replace("!important;", "");
                this.textColorOverride = finalColor;
            }
            return `color:${finalColor}!important;background:${finalBackground}!important;`;
        },
        colors() {
            let imageColors = [];
            if (this.image?.colors) {
                imageColors = this.image.colors;
            }
            let divStyleBackgrounds = this.styleGuide?.divStyles?.map(style => {
                return style.backgroundColor;
            });
            let colors = [this.backgroundColor, this.textColor, this.imageColor, this.image?.backgroundColor, this.image?.transparent, this.textColorOverride, ...imageColors, ...divStyleBackgrounds];

            // Filter out falsy values and then validate with Chroma.js
            colors = colors.filter(color => {
                return color && chroma.valid(color);
            });

            // Remove duplicates
            colors = Array.from(new Set(colors));

            // Sort colors by lightness
            colors.sort((a, b) => {
                let aLightness = chroma(a).get("hsl.l");
                let bLightness = chroma(b).get("hsl.l");
                return aLightness - bLightness;
            });
            return colors;
        },
        mobileStyles() {
            if (this.mobile) {
                return `font-size:30px!important; line-height:1;`;
            }
            return "";
        },
        componentData() {
            return JSON.stringify(this.$data, null, 2);
        },
        font() {
            // return this.likelyHeaderCombo;
            let body = this.comboParagraph?.fontFamily || null;
            let header = this.comboHeader?.fontFamily || null;
            if (body) body = `font-family:${body}!important;`;
            if (header) header = `font-family:${header}!important;`;
            if (this.section?.font?.header) {
                if (body) body = `font-family:${this.section.font.body.fontFamily}!important;`;
                if (header) header = `font-family:${this.section.font.header.fontFamily}!important;`;
            }
            return { body, header };
        },
        headerImageCandidate() {
            if (this.image?.width && this.image?.height) {
                let width = this.image.width;
                let height = this.image.height;
                let ratio = width / height;
                if (ratio > 1.5) {
                    return true;
                }
            }
        },
        buttonCss() {
            let buttonStyle = this.styleGuide?.buttonStyles?.[0];
            if (this.section?.buttonIndex) {
                buttonStyle = this.styleGuide?.buttonStyles?.[this.section.buttonIndex];
            }
            if (!buttonStyle) return;
            if (!buttonStyle?.borderRadius) {
                buttonStyle.borderRadius = "10px";
            }
            return buttonStyle;
        },
        button() {
            if (this.section?.button?.show) {
                return this.section?.button?.text;
            }
        },
    },
    data() {
        return {
            textColorOverride: "",
            backgroundColor: "",
            textColor: "",
            pickedColor: false,
            showSettings: false,
        };
    },
    watch: {
        imageStyle: {
            handler(val, oldVal) {
                if (val === "fullBleed") {
                    // this.updateColors(this.image.backgroundColor);
                    this.updateStreamProp(`result.sections.${this.index}.imageInset`, false);
                    this.updateStreamProp(`result.sections.${this.index}.imageStyle`, "normal");
                }
            },
        },
        imageColor: {
            handler(val, oldVal) {
                // if (!val) return;
                console.log(val);
                this.textColorOverride = this.findContrastingColor(val);
                this.textColorOverride = `color:${this.textColorOverride}!important;`;
                let background = this.image.backgroundColor;
                this.updateColors(background);
            },
        },
        imageIndex: {
            handler(val, oldVal) {
                if (val) {
                    this.updateColors(this.imageColor);
                }
            },
        },
    },
    mounted() {
        if (this.imageColor) {
            this.textColorOverride = this.findContrastingColor(this.imageColor);
        }
        if (!this.pickedColor) {
            this.getRandomTheme();
            this.pickedColor = true;
        }
        this.$nextTick(() => {
            if (!this.section.color || !this.section.backgroundColor) {
                this.updateColors(this.image.backgroundColor);
            }
        });
    },
    methods: {
        // Filter out background colors with gradients
        updateColors(color) {
            let object = {};

            if (!color && this.imageURL) color = this.image.backgroundColor;
            let textColor = this.findContrastingColor(color);
            this.updateStreamProp(`result.sections.${this.index}.backgroundColor`, color);
            this.backgroundColor = color;
            this.textColor = textColor;
            this.textColorOverride = textColor;
            this.updateStreamProp(`result.sections.${this.index}.color`, textColor);
            // return
        },
        getRandomTheme() {
            const themes = this.styleGuide?.divStyles;
            const { backgroundColor, color } = themes[Math.floor(Math.random() * themes.length)];
            if (backgroundColor) this.backgroundColor = backgroundColor;
            if (color) this.textColor = color;
            return { color, backgroundColor };
        },
    },
    props: {
        result: {
            type: Object,
            default: () => ({
                header: "",
                body: "",
                button: {
                    show: true,
                    text: "Shop now",
                },
                // pick a random style from "imageLeft", "imageRight", "fullBleed"
                style: "imageLeft",
                imageIndex: 0,
                textAlign: "left",
                imageInset: false,
            }),
        },
        index: {
            type: Number,
            default: 0,
        },
    },
};
</script>
<style lang="scss" scoped>
.edit_button {
    @apply opacity-0;
    @apply bg-opacity-50;
    @apply border-opacity-0;
    @apply backdrop-blur-2xl;
}
.section {
    &:hover {
        .edit_button {
            @apply opacity-100;
            @apply bg-opacity-70;
            @apply border-opacity-100;
        }
    }
}
</style>
<!--                :style="`border-color:${textColorOverride};color:${textColorOverride}`"-->
