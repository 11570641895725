<template>
    <!--    <div>-->
    <div v-if="logo" ref="logoContainer" class="x relative justify-fixed-center z-2 f aic jcc">
        <!--      {{logo.width}}-->
        <!--      {{logo.height}}-->
        <!--      {{logo.url}}-->
        <img :src="logo.url" :style="widthAdjustment" alt="Logo" class="x y o-contain o-0 logo-image z-1" />
        <div :style="[maskStyleNew, widthAdjustment]" class="z-2 p-absolute x y top logo-image-mask"></div>
    </div>
    <div v-else-if="firstSvg" :style="widthAdjustment" class="p-absolute top justify-fixed-center z-3">
        <SvgPrevoew :color="colorOverride" :svg="firstSvg" />
    </div>
    <!--    </div>-->
</template>
<script>
import SvgPrevoew from "@/components/styleGuide/Images/SvgPreview.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import chroma from "chroma-js";

export default {
    name: "BrandLogoComponent",
    components: { SvgPrevoew },
    mixins: [styleGuideMixin],
    props: {
        color: {
            type: [Object, String],
            default: "white",
            validator: value => typeof value === "object" || typeof value === "string",
        },
        width: {
            type: [String, Number],
            default: "200px",
        },
        maxHeight: {
            type: String,
            default: "auto",
        },
        isHeader: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        // widthAdjustment() {
        //     let originalWidth = this.logo.width;
        //     let originalHeight = this.logo.height;
        //
        //     let newHeight = (this.width / originalWidth) * originalHeight;
        //     return `max-width:${this.width};max-height:${newHeight};width:${this.width};`;
        // },
        widthAdjustment() {
            const originalWidth = this.logo.width || 100;
            const originalHeight = this.logo.height || 100;
            // console.log("Original dimenstions", originalWidth, originalHeight, this.width, this.maxHeight);
            // Calculate new dimensions based on width
            let newWidth = Math.min(parseInt(this.width), originalWidth);
            let newHeight = parseInt((newWidth / originalWidth) * originalHeight);
            // Adjust height if it exceeds the maximum height
            if (this.maxHeight && this.maxHeight !== "auto" && newHeight < parseInt(this.maxHeight)) {
                newHeight = parseInt(this.maxHeight);
                newWidth = parseInt((newHeight / originalHeight) * originalWidth);
            }
            if (!this.maxHeight) newHeight = parseInt((this.width / originalWidth) * originalHeight) + "px"; // old logic

            if (!this.maxHeight || this.maxHeight === "auto") {
                let string = `max-width:${this.width}px;min-height:${newHeight};max-height:${newHeight};width:${this.width}px;`;
                return string;
            } else if (this.maxHeight) {
                if (newWidth && newWidth) newWidth = `${newWidth}px`;
                let string = `max-width:${newWidth}; max-height:${newHeight}px; width:${newWidth}; height:${newHeight}px;`;
                return string;
            }
        },
        maskStyleNew() {
            if (this.logo.url) {
                const image = this.logo.url;
                const colorOverride = this.colorOverride;
                return `mask-image: url('${image}'); mask-size: cover; background:${colorOverride}; -webkit-mask-image: url('${image}'); -webkit-mask-size: cover;`;
            }
        },
        colorOverride() {
            if (this.color) {
                if (typeof this.color === "string") return this.color;
                if (this.color.backgroundColor && this.color.color) {
                    return this.color.color.replace("!important", "").replace(";", "") || "";
                } else {
                    let color = determineColor(this.color);
                    return chroma(color).brighten(2).hex();
                }
            }
        },
    },
    watch: {
        colorOverride() {
            this.$nextTick().then(() => {
                this.$forceUpdate();
            });
        },
    },
    mounted() {
        this.$nextTick().then(() => {
            if (this.isHeader) this.getLogoHeight();
        });
    },
    methods: {
        getLogoHeight() {
            // if (!this.showLogo) return 0;
            if (this.$refs?.logoContainer) {
                let logoHeight = this.$refs?.logoContainer?.clientHeight;
                this.updateStreamProp("options.logoHeight", logoHeight);
                let style = document.createElement("style");
                style.type = "text/css";
                style.innerHTML = `.pt-logo { padding-top: ${logoHeight}px; } .mt-logo { margin-top: ${logoHeight}px; }`;
                document.getElementsByTagName("head")[0].appendChild(style);

                return logoHeight;
            }
        },
        determineColor(colorObj) {
            let randomColor = colorObj?.randomStyle?.background?.replace("!important", "").replace(";", "") || "";
            let buttonColor = colorObj?.buttonStyle?.backgroundColor?.replace("!important", "").replace(";", "") || "";

            if (!randomColor || randomColor.includes("gradient")) {
                return buttonColor.replace("!important", "").replace(";", "") || "#fff";
            }
            return randomColor;
        },
    },
};
</script>
