<template>
    <div>
        <UIWrapperText>
            <template #left>
                <div class="relative">
                    <div v-if="imageURL" class="x relative z-0 md:p-3">
                        <img :src="imageURL" class="x y o-cover br-10" />
                    </div>
                    <div class="p-absolute top right p-3 z-4">
                        <BaseButton icon="fas fa-image" icon-only label="Get Image" rounded @click="generatePersonaImagePrompt()"></BaseButton>
                    </div>
                </div>
            </template>
            <div>
                <div class="mb-3 pb-4">
                    <h1 v-if="persona?.name">{{ persona.name }}</h1>
                    <p v-if="persona?.about" class="text lg:w-90 block f-30 mb-3 !font-light !leading-normal" style="font-weight: 300 !important">{{ persona.about }}</p>
                </div>
                <div class="f fc gap-2">
                    <template v-for="item in ['demographics', 'interests', 'media_consumption', 'goals', 'psychographics', 'behaviors', 'decision_drivers', 'pain_points', 'influencers_and_role_models']">
                        <UITextBlurb v-if="persona?.[item]" :body="persona[item]" :title="item" grid></UITextBlurb>
                    </template>
                </div>
                <!--            <template v-for="(item, key) in persona"><UITextBlurb :body="item" :title="key" collapse></UITextBlurb></template>-->
                <!--            <div class="grid grid-cols-2 gap-2">-->
                <!--                <template v-for="item in ['demographics', 'interests', 'media_consumption', 'goals', 'psychographics', 'behaviors', 'decision_drivers', 'pain_points', 'influencers_and_role_models']">-->
                <!--                    <UITextBlurb v-if="persona?.[item]" :body="persona[item]" :title="item"></UITextBlurb>-->
                <!--                </template>-->
                <!--            </div>-->
                <!--            <BaseChecklist v-if="persona?.interests" :list="persona.interests" />-->
                <!--                <PersonaSectionAlt v-if="persona.demographics" :list="persona.demographics" title="Demographics" />-->
                <!--                <PersonaSectionAlt v-if="persona.interests" :list="persona.interests" title="Interests" />-->
                <!--                <PersonaSectionAlt v-if="persona.media_consumption" :list="persona.media_consumption" title="Media Consumption" />-->
            </div>
        </UIWrapperText>
    </div>
    <!--    <UIWrapperText :subtitle="item" v-for="item in ['pain-points', 'demographics', 'interests', 'media_consumption', 'goals', 'psychographics', 'behaviors', 'decision_drivers', 'influencers_and_role_models']"><div v-if="persona?.[item]">{{ persona[item].join(", ") }}</div></UIWrapperText>-->
    <div v-if="false && persona" :style="backgroundString" class="persona br-10 p-0 ais relative grid grid-cols-12 overflow-clip mx-auto" style="max-width: 1400px">
        <div class="x lg:width-min-600 col-span-12 lg:col-span-6 z-1">
            <div class="br-10 overflow-clip x f relative aspect-portrait parent-div">
                <div v-if="personaImage" class="x h-auto p-absolute p-fill z-0">
                    <img :src="imageURL" class="x y o-cover object-center image-class" />
                </div>
                <div v-else-if="!personaImage" class="p-absolute p-fill f aic jcc">
                    <loading-spinner></loading-spinner>
                </div>

                <!--                <div :style="rightGradient" class="p-absolute top bottom ml-auto right w-50"></div>-->
            </div>
        </div>
        <div v-if="personaImage" class="x y absolute blur p-fill flip-horizontal" style="z-index: 0">
            <img :src="imageURL" class="x y o-cover" style="filter: blur(100px)" />
        </div>
        <div class="lg:px-4 pb-5 lg:pt-5 col-span-12 lg:col-span-6 z-1 y">
            <div class="px-4 md:px-0 md:pt-6 mt-4">
                <h4 class="md:mb-4 mt-0 f-70 md:mt-4" v-if="persona.name">{{ persona.name }}</h4>

                <div class="border-b-brand mb-3 pb-4">
                    <p v-if="persona?.about" class="text lg:w-90 mb-3 f-30 font-light !leading-normal">{{ persona.about }}</p>
                </div>
            </div>
            <div class="px-3">
                <PersonaSectionAlt v-if="persona?.demographics" :list="persona.demographics" :section-colors="sectionColors" title="Demographics" />
                <PersonaSectionAlt v-if="persona?.interests" :list="persona.interests" :section-colors="sectionColors" title="Interests" />
                <PersonaSectionAlt v-if="persona?.media_consumption" :list="persona.media_consumption" :section-colors="sectionColors" title="Media Consumption" />
            </div>
            <!--            <PersonaSectionAlt v-if="persona.physical_appearance" :list="persona.physical_appearance" title="Appearance" />-->
            <div :class="{ 'grid grid-cols-1 lg:grid-cols-3 mw-1000 br-20 x': !mobile, 'f flex-nowrap scrollbar-hide overflow-x-scroll gap-3 ': mobile }" class="">
                <!--                <PersonaSection v-if="persona?.demographics?.length > 0" title="Demographics" :items="persona.demographics" />-->
                <PersonaSection v-if="persona?.pain_points?.length > 0" :class="{ 'bg-snow br-5 width-min-250': mobile }" :items="persona.pain_points" :section-colors="sectionColors" class="flex-shrink-0" title="Pain Points" />
                <PersonaSection v-if="persona?.goals?.length > 0" :class="{ 'bg-snow br-5 width-min-250': mobile }" :items="persona.goals" :section-colors="sectionColors" class="flex-shrink-0" title="Goals" />
                <PersonaSection v-if="persona?.psychographics?.length > 0" :class="{ 'bg-snow br-5 width-min-250': mobile }" :items="persona.psychographics" :section-colors="sectionColors" class="flex-shrink-0" title="Psychographics" />
                <PersonaSection v-if="persona?.behaviors?.length > 0" :class="{ 'bg-snow br-5 width-min-250': mobile }" :items="persona.behaviors" :section-colors="sectionColors" class="flex-shrink-0" title="Behaviors" />
                <PersonaSection v-if="persona?.decision_drivers?.length > 0" :class="{ 'bg-snow br-5 width-min-250': mobile }" :items="persona.decision_drivers" :section-colors="sectionColors" class="flex-shrink-0" title="Decision Drivers" />
                <PersonaSection v-if="persona?.influencers_and_role_models?.length > 0" :class="{ 'bg-snow br-5 width-min-250': mobile }" :items="persona.influencers_and_role_models" :section-colors="sectionColors" class="flex-shrink-0" title="Follows" />
                <!--                <PersonaSection v-if="persona?.interests?.length > 0" title="Interests" :items="persona.interests" />-->
            </div>

            <span class="visually-hidden">{{ personaDescription }}</span>
            <span v-if="imagePrompt" class="block f-15 mt-3 visually-hidden">{{ imagePrompt }}</span>
        </div>
    </div>
</template>
<script>
import personaMixin from "@/mixins/ai/PersonaMixin";

let example = `INPUT: 'Wellness Newbie Nate
Nate is a young adult who is just beginning his wellness journey. He recently became more conscious of his health and wants to make positive changes in his lifestyle. Nate is open to trying new products and practices to support his overall well-being. He enjoys exploring different flavors and is eager to discover beverages that are both tasty and beneficial for his health.

Demographics
Male
25-35 years old
African American
Urban area
Pain Points
Lack of knowledge about nutrition and wellness
Finding healthy options that fit his taste preferences
Balancing health goals with social activities
Goals
Learning about healthy lifestyle choices
Incorporating wellness practices into his daily routine
Finding flavorful and enjoyable health products
Psychographics
Curious and open-minded about wellness
Active on social media for wellness inspiration
Interested in learning about nutrition and healthy cooking
Values balance between health and enjoyment
Behaviors
Explores health blogs and influencers on social media
Enjoys trying new flavors and food trends
Shops at grocery stores that offer a variety of health products
Attends wellness events and workshops
Interests
Fitness and exercise
Trying new recipes and cooking techniques
Self-improvement and personal development
Exploring different types of workouts`;
import completion from "@/mixins/ai/completion";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import webRequest from "@/mixins/ai/web_request";
import PersonaSection from "@/components/generative_templates/Guidelines/PersonaSection.vue";
import PersonaSectionAlt from "@/components/generative_templates/Guidelines/PersonaSectionAlt.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import chroma from "chroma-js";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";
import UITextBlurb from "@/components/generative_templates/Guidelines/UITextBlurb.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";
import BaseChecklist from "@/components/generative_templates/Guidelines/BaseChecklist.vue";

export default {
    name: "CopyGuideLinesPersona",
    components: {
        BaseChecklist,
        UICollapsableSection,
        UITextBlurb,
        UIWrapperText,
        LoadingSpinner,
        PersonaSectionAlt,
        PersonaSection,
        BaseButton,
    },
    mixins: [styleGuideMixin, personaMixin],
    props: {
        persona: {
            type: Object,
        },
        index: {
            type: [String, Number],
            default: 0,
        },
    },
    data() {
        return {
            imagePrompt: "",
            personaImageURL: "",
        };
    },
    computed: {
        sectionColors() {
            let bg = "#ffffff";
            let color = "#000000";
            if (this.personaImage && typeof this.personaImage === "object") {
                if (this.personaImage.backgroundColor) bg = this.personaImage.colors[0];
                if (bg) color = this.getContrastColor(bg);
            } else {
                if (this.divStyle?.backgroundColor) bg = this.divStyle?.backgroundColor;
                if (this.divStyle?.color) color = this.divStyle?.color;
            }
            return { bg, color };
        },
        backgroundString() {
            // `background:${divStyle?.backgroundColor}!important;background:${personaImage?.backgroundColor}!important;color:${divStyle?.color}!important`
            let backgroundColor = "#ffffff";
            let color = "#000000";
            if (this.personaImage && typeof this.personaImage === "object") {
                if (this.personaImage.backgroundColor) backgroundColor = this.personaImage.backgroundColor;
                if (backgroundColor) color = this.getContrastColor(backgroundColor);
            } else {
                if (this.divStyle?.backgroundColor) backgroundColor = this.divStyle?.backgroundColor;
                if (this.divStyle?.color) color = this.divStyle?.color;
            }
            return `background:${backgroundColor}!important;color:${color}!important`;
        },
        rightGradient() {
            let backgroundColor = "#ffffff";
            if (this.personaImage && typeof this.personaImage === "object") {
                if (this.personaImage.backgroundColor) backgroundColor = this.personaImage.backgroundColor;
            } else {
                if (this.divStyle?.backgroundColor) backgroundColor = this.divStyle?.backgroundColor;
            }
            return `background: linear-gradient(90deg, transparent 50%, ${backgroundColor} 100%) !important;`;
        },
        colorsString() {
            let colorString = this.$store.getters["styleGuide/llm/ColorsString"];
            return colorString;
        },
        divStyle() {
            let divStyles = this.$store.getters["styleGuide/divStyles"];

            // Pre-filter divStyles to exclude those with invalid color formats
            const validStyles = divStyles.filter(style => {
                try {
                    chroma(style.backgroundColor).luminance();
                    return true; // Include style if no error occurs
                } catch (e) {
                    return false; // Exclude style if error occurs
                }
            });

            // Sort validStyles by lightness of background color, lightest first
            validStyles.sort((a, b) => {
                const lightnessA = chroma(a.backgroundColor).luminance();
                const lightnessB = chroma(b.backgroundColor).luminance();
                return lightnessB - lightnessA; // ascending order
            });

            // Filter out too bright colors (e.g., close to white)
            const filteredStyles = validStyles.filter(style => chroma(style.backgroundColor).luminance() < 0.8);

            let closestPastel = null;
            let minDistance = Infinity;

            filteredStyles.forEach(style => {
                const color = chroma(style.backgroundColor);
                const [hue, saturation, lightness] = color.hsl();

                // Check if the color is a pastel
                const isPastel = lightness > 0.7 && saturation < 0.5;
                if (isPastel) {
                    // Calculate the distance to a reference pastel color in LAB space
                    const referenceColor = chroma("lightblue").lab();
                    const colorLAB = color.lab();
                    const distance = Math.sqrt(Math.pow(colorLAB[0] - referenceColor[0], 2) + Math.pow(colorLAB[1] - referenceColor[1], 2) + Math.pow(colorLAB[2] - referenceColor[2], 2));
                    if (distance < minDistance) {
                        minDistance = distance;
                        closestPastel = style;
                    }
                }
            });

            return closestPastel || validStyles[0]; // Return the closest pastel or the first valid style
        },
        imageURL() {
            if (this.persona?.image) {
                if (typeof this.persona.image === "string") {
                    return this.persona.image?.replace("Original", "/width=400,height=600,fit=cover,gravity=auto");
                } else {
                    return this.persona.image?.url?.replace("Original", "/width=400,height=600,fit=cover,gravity=auto");
                }
            } else if (this.personaImageURL) {
                if (typeof this.personaImageURL === "string") return this.personaImageURL.replace("Original", "/width=400,height=600,fit=cover,gravity=auto") || "";
                else {
                    return this.personaImageURL?.url.replace("Original", "/width=400,height=800,fit=cover,gravity=auto") || "";
                }
            }
        },
        personaImage() {
            if (this.persona?.image?.url) return this.persona.image;
            else if (typeof this.personaImageURL === "string") return { url: this.personaImageURL };
            return "";
            // return this.persona?.image || { url: this.personaImageURL } || "";
        },
        personaDescription() {
            try {
                const { name, about, pain_points, goals, demographics, psychographics, behaviors, interests, media_consumption, decision_drivers, physical_appearance } = this.persona;
                let description = `${about}\n\n`;
                // let description = `${name}\nAbout: ${about}\n\n`;

                if (pain_points) description += "Pain Points:\n - " + pain_points.join("\n - ");
                if (goals) description += "\nGoals:\n - " + goals.join("\n") + "\n - ";
                if (demographics) description += "\nDemographics:\n - " + demographics.join("\n - ");
                if (psychographics) description += "\nPsychographics:\n - " + psychographics.join("\n - ");
                if (behaviors) description += "\nBehaviors:\n - " + behaviors.join("\n - ");
                if (interests) description += "\nInterests:\n - " + interests.join("\n - ");
                if (physical_appearance) description += "\nPhysical Appearance:\n - " + physical_appearance.join("\n - ");
                // if (decision_drivers) description += "Decision drivers:\n - " + decision_drivers.join("\n - ");

                return description;
            } catch (e) {}
        },
    },
    methods: {},
};
</script>
<style>
.parent-div {
    position: relative;
}

.image-class {
    -webkit-mask-image: linear-gradient(to right, black 80%, transparent 100%);
    mask-image: linear-gradient(to right, black 70%, transparent 100%);
}

.flip-horizontal {
    transform: scaleX(-1);
}

.flip-vertical {
    transform: scaleY(-1);
}
</style>
