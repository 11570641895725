<template>
    <div class="x f br-5 br-5 aic jcc overflow-hidden" style="height: 0px; padding-bottom: 66%">
        <!--    <div :ref="styledRef" class="x f br-5 br-5 aic jcc overflow-hidden bg-base-900" style="height: 0px; padding-bottom: 66%">-->
        <img v-if="imageSrc" :src="imageSrc" class="p-absolute p-fill x y o-cover" :class="{ 'o-2': imageStyle }" :style="`mix-blend-mode: ${blendMode}`" />
        <!--        <img v-if="imageSrc" :ref="`image-sm-${id}${index}`" :src="imageSrc" class="p-absolute p-fill x y o-cover" :class="{ 'o-2': imageStyle }" :style="imageStyleText" />-->
        <div class="p-absolute p-fill f aic jcc">
            <div class="z-3 p-3 text-center">
                <p v-if="title" class="fwb line-height-smaller f-13 mb-0">
                    <!--                <p v-if="title" :ref="`text-sm-${id}${index}`" class="fwb line-height-smaller f-13 mb-0">-->
                    {{ title }}
                </p>
                <p v-if="text" class="o-8 f-11 mb-0" style="font-size: 8px">
                    {{ text.slice(0, 50) }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import {mapGetters} from 'vuex';
export default {
    name: "SlideFullbleedMini",
    mixins: [SlideStyleMixin],
    props: {
        index: {},
        text: {},
        title: {},
    },
    data() {
        return {
            id: null,
            imageStyle: false,
        };
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        blendMode() {
            if (this.slide?.mode === "dark") {
                return "screen";
            } else {
                return "multiply";
            }
        },
        slide() {
            return this.$store.state?.presentation?.document?.sections[this.index];
        },
        styledRef() {
            // if (this.imageStyle) {
            //     return `slide-sm-s${this.index}`;
            // }
        },
        imageSrc() {
            if (this.slide?.imageURL) {
                return this.slide.imageURL;
            }
            if (Array.isArray(this.slide?.images) && this.slide.images.length > 0) {
                return this.slide.images[0].url;
            }
            return null;
        },
    },
    created() {
        this.id = this.randomId();
    },
    mounted() {
        // if (this.document.theme) {
        //     let t = this.document.theme.colors || false;
        //     if (t) this.textTheme();
        // }
    },
};
</script>
