<template>
    <TransitionRoot :show="open" as="template">
        <Dialog as="div" class="relative z-10" @close="close">
            <TransitionChild as="template" enter="ease-in-out duration-1" enter-from="opacity-0" enter-to="opacity-100" leave="ease-out duration-1" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500/50 bg-opacity-75 transition-opacity" @click="close" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-500" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-500" leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto relative width-450">
                                <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                                    <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                        <button class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" type="button" @click="close">
                                            <span class="absolute -inset-2.5" />
                                            <span class="sr-only">Close panel</span>
                                            <XMarkIcon aria-hidden="true" class="h-6 w-6" />
                                        </button>
                                    </div>
                                </TransitionChild>
                                <div class="h-full overflow-y-auto bg-white p-4 prose-md">
                                    <div class="space-y-6 pb-8">
                                        <div>
                                            <div v-if="image" class="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
                                                <img :src="image" alt="" class="object-cover" />
                                            </div>
                                            <div class="mt-4 flex items-start justify-between">
                                                <div>
                                                    <h2 class="text-base font-semibold leading-6 text-gray-900">
                                                        <span class="sr-only">Details for</span>
                                                        {{ displayTitle }}
                                                    </h2>
                                                    <p class="text-sm font-medium text-gray-400 f aic jcs gap-3">
                                                        <span v-if="item?.price">${{ item.price }}</span>
                                                        <ItemCountIcon v-if="item?.links" :items="item.links" icon="link"></ItemCountIcon>
                                                        <ItemCountIcon v-if="item?.images" :items="item.images" icon="images"></ItemCountIcon>
                                                    </p>
                                                </div>
                                                <!--                                                <button class="relative ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500" type="button">-->
                                                <!--                                                    <span class="absolute -inset-1.5" />-->
                                                <!--                                                    <HeartIcon aria-hidden="true" class="h-6 w-6" />-->
                                                <!--                                                    <span class="sr-only">Favorite</span>-->
                                                <!--                                                </button>-->
                                            </div>
                                        </div>
                                        <div>
                                            <h3 class="font-medium text-gray-900">Description</h3>
                                            <div class="mt-2 flex items-center justify-between">
                                                <p class="text-sm italic text-gray-500">{{ displayDescription }}</p>
                                                <button class="relative -mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500" type="button">
                                                    <span class="absolute -inset-1.5" />
                                                    <!--                                                    <PencilIcon aria-hidden="true" class="h-5 w-5" />-->
                                                    <!--                                                    <span class="sr-only">Add description</span>-->
                                                </button>
                                            </div>
                                        </div>
                                        <div v-if="product?.handle">
                                            <h3 class="font-medium text-gray-900">Product</h3>
                                            <ProductCardSmall :product="product" />
                                            <dl v-if="detailKeys" class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                                                <template v-for="(value, key) in productKeys">
                                                    <ItemDetails v-if="typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number'" :description="value" :title="key" />
                                                </template>
                                            </dl>
                                        </div>
                                        <div>
                                            <h3 class="font-medium text-gray-900">Information</h3>
                                            <dl v-if="detailKeys" class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                                                <ItemDetails v-if="item?.updated" date :description="item.updated" title="updated" />
                                                <ItemDetails v-if="item?.colors" :description="item.colors" kind="color" title="colors" />
                                                <template v-for="i in ['primary', 'second', 'mixed', 'dominant', 'topColor', 'bottomColor', 'textColor']">
                                                    <ItemDetails v-if="item?.[i]" kind="color" :description="[item[i]]" :title="i" />
                                                </template>
                                                <template v-for="(value, key) in detailKeys">
                                                    <ItemDetails v-if="typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number'" :description="value" :title="key" />
                                                </template>
                                            </dl>
                                        </div>

                                        <div v-if="item?.url" class="flex flex-col items-start space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
                                            <div>
                                                <a :href="item.url" class="group flex items-center space-x-2.5 text-sm font-medium text-indigo-600 hover:text-indigo-900" target="_blank">
                                                    <LinkIcon aria-hidden="true" class="h-5 w-5 text-indigo-500 group-hover:text-indigo-900" />
                                                    <span>Visit Link</span>
                                                </a>
                                            </div>
                                            <div class="x">
                                                <a class="group flex items-center space-x-2.5 text-sm text-gray-500 hover:text-gray-900" href="#">
                                                    <!--                                                    <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />-->
                                                    <!--                                                    <span>Learn more about sharing</span>-->
                                                </a>
                                            </div>
                                        </div>
                                        <UISlideoverContent v-if="text" :key="text" title="Content" :description="text"></UISlideoverContent>
                                        <UISlideoverContent v-if="item?.tags" title="tags">
                                            <div class="f gap-3 flex-wrap">
                                                <div v-for="tag in item.tags">
                                                    <SimpleButton size="xs" class="button1" secondary>{{ tag }}</SimpleButton>
                                                </div>
                                            </div>
                                        </UISlideoverContent>
                                        <UISlideoverContent v-if="content" :key="content" title="text" :description="content" />
                                        <debug-object :object="item"></debug-object>
                                        <!--                                        <UIDialogSharedWith />-->
                                        <div class="flex">
                                            <SimpleButton secondary @click="close">Close</SimpleButton>
                                            <!--                                            <button class="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" type="button">Download</button>-->
                                            <!--                                            <button class="ml-3 flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" type="button">Delete</button>-->
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import ItemDetails from "@/components/Admin/ItemDetails.vue";
import { LinkIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import SimpleButton from "@/views/SimpleButton.vue";
import ItemCountIcon from "@/views/ItemCountIcon.vue";
import UISlideoverContent from "@/components/Admin/UISlideoverContent.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import ProductCardSmall from "@/components/Admin/ProductCardSmall.vue";

export default {
    components: {
        ProductCardSmall,
        DebugObject,
        UISlideoverContent,
        ItemCountIcon,
        SimpleButton,
        ItemDetails,
        Dialog,
        LinkIcon,
        XMarkIcon,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        show: {
            type: Boolean,
            default: false,
        },
        open: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["close"],
    data() {
        return {};
    },
    computed: {
        detailKeys() {
            if (this.item) {
                let keysToFilterOut = [
                    "body_html",
                    "created_at",
                    "updated_at",
                    "score",
                    "vibrancy",
                    "dimensions",
                    "published_at",
                    "published_scope",
                    "content",
                    "text",
                    "title",
                    "mixed",
                    "second",
                    "size",
                    "fileSize",
                    "average",
                    "dominant",
                    "primary",
                    "colorTransparent",
                    "color",
                    "backgroundColor",
                    "topColor",
                    "bottomColor",
                    "textColor",
                    "url",
                    "variants",
                    "description",
                    "images",
                ];
                let keys = Object.keys(this.item).filter(key => !keysToFilterOut.includes(key));
                return keys.reduce((obj, key) => {
                    obj[key] = this.item[key];
                    return obj;
                }, {});
            } else {
                return {};
            }
        },
        productKeys() {
            if (this.product) {
                let keysToFilterOut = ["body_html", "created_at", "updated_at", "published_at", "published_scope", "content", "text", "title", "variants", "images"];
                let keys = Object.keys(this.product).filter(key => !keysToFilterOut.includes(key));
                return keys.reduce((obj, key) => {
                    obj[key] = this.item[key];
                    return obj;
                }, {});
            } else {
                return {};
            }
        },
        content() {
            return this.item?.content || "";
        },
        text() {
            return this.item?.text || "";
        },
        product() {
            return this.item?.product || "";
        },
        image() {
            return this.item?.images?.[0]?.url || this.item?.image?.url || this.item?.url || "";
        },
        displayTitle() {
            let alt = this.item?.alt || false;
            let title = this.item?.title || false;
            let name = this.item?.name || false;
            title = title || name || alt || "";
            return title || "No title available";
        },
        displayDescription() {
            return this.item?.description || "No description available";
        },
    },
    methods: {
        close() {
            this.$emit("close", true);
        },
    },
};
</script>
<!--                                                <div class="flex justify-between py-3 text-sm font-medium">-->
<!--                                                    <dt class="text-gray-500">Created</dt>-->
<!--                                                    <dd class="text-gray-900">June 8, 2020</dd>-->
<!--                                                </div>-->
<!--                                                <div class="flex justify-between py-3 text-sm font-medium">-->
<!--                                                    <dt class="text-gray-500">Last modified</dt>-->
<!--                                                    <dd class="text-gray-900">June 8, 2020</dd>-->
<!--                                                </div>-->
<!--                                                <div class="flex justify-between py-3 text-sm font-medium">-->
<!--                                                    <dt class="text-gray-500">Dimensions</dt>-->
<!--                                                    <dd class="text-gray-900">4032 x 3024</dd>-->
<!--                                                </div>-->
<!--                                                <div class="flex justify-between py-3 text-sm font-medium">-->
<!--                                                    <dt class="text-gray-500">Resolution</dt>-->
<!--                                                    <dd class="text-gray-900">72 x 72</dd>-->
<!--                                                </div>-->
