<template>
    <div v-if="fonts?.length > 0" class="py-3 relative overflow-x-scroll scrollbar-hide">
        <div v-if="title" class="px-3">
            <h6>{{ title }}</h6>
        </div>
        <div class="f px-3 gap-1">
            <!--            <div v-for="font in deduplicatedFonts" class="c-40 border-light hover:shadow cursor-pointer f aic jcc text-gray-900 f-24" @click="updateFont(font)">-->
            <!--                <span :style="`font-family:${font.name};font-weight:${font.weight};font-style:${font.style};text-transform:${font?.textTransform || ''}`">Aa</span>-->
            <!--            </div>-->
            <template v-for="font in deduplicatedFonts" :key="font?.name">
                <BaseButton :size="size" :tooltip="`${font.name} ${font.weight} ${font?.fontStyle || ''}`" class="f aic jcc" icon-only label="" rounded @click="updateFont(font)" @mouseover="updateTempFont(font)">
                    <span :style="`font-family:${font?.name}; font-weight:${font?.weight}; font-size:23px;font-style:${font?.style};text-transform:${font?.textTransform || ''}`">Aa</span>
                </BaseButton>
            </template>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapGetters } from "vuex";

export default {
    name: "UpdateFontPicker",
    components: { BaseButton },
    props: {
        // fonts: {
        //     type: Array,
        //     required: true,
        // },
        size: {
            type: String,
            default: "xs",
        },
        title: {
            type: String,
            required: false,
        },
    },
    computed: {
        ...mapGetters("styleGuide", ["fonts"]),
        deduplicatedFonts() {
            let fonts = this.fonts || [];
            fonts = [...new Set(fonts)];

            fonts = fonts.map(font => {
                // make sure both fontWeight, weight and fontStyle, style are present

                let weight = font.weight || font.fontWeight;
                let style = font.style || font.fontStyle;
                let name = font.name || font.fontFamily;
                font.weight = weight;
                font.style = style;
                font.fontWeight = weight;
                font.fontStyle = style;
                font.name = name;
                font.fontFamily = name;
                font.textTransform = font.textTransform || "";
                return font;
            });
            // deduplicate by id
            let uniqueFontSet = new Set();
            return fonts.filter(font => {
                let fontSignature = `${font.name}-${font.weight}-${font.style}`;
                if (font.id) fontSignature = font.id;
                if (!uniqueFontSet.has(fontSignature)) {
                    uniqueFontSet.add(fontSignature);
                    return true;
                }
                return false;
            });
            // return fonts;

            // fonts = fonts?.filter(font => {
            //     // let fontSignature = `${font.name}-${font.weight}-${font.style}`;
            //     // if (font.id) fontSignature = font.id;
            //     // if (!uniqueFontSet.has(fontSignature)) {
            //     //     uniqueFontSet.add(fontSignature);
            //     //     return true;
            //     // }
            //     return false;
            // });
        },
    },
    methods: {
        updateTempFont(font) {
            this.$emit("update-temp-font", font);
        },
        updateFont(font) {
            this.$emit("update-font", font);
        },
    },
};
</script>
