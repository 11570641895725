import { imgEnd, imgStart } from "@/mixins/Chat/chatStrings";
import { mapActions } from "vuex";
import { chalk } from "chalk";
import { modifierNames, foregroundColorNames } from "chalk";
import { templateStderr } from "chalk-template";
import processCommands from "@/mixins/Chat/Commands/processing/ProcessCommands";
import toolBot from "@/mixins/Chat/Commands/ToolBot";
import agentCreator from "@/mixins/Chat/AgentCreator";
export default {
    created: function () {},
    mounted() {},
    mixins: [processCommands, toolBot, agentCreator],
    computed: {
        currentIndex() {
            return this.$store.state?.chat?.messages?.length - 1;
        },
    },
    methods: {
        ...mapActions("chat", ["addMessageStore", "setMessages", "saveChatStore"]),
        ...mapActions("user", ["saveUser"]),
        isMap(message) {
            const content = message.content;
            const actions = this.actionsFiltered;
            // get the recommend_location action
            const generateMapAction = actions.find(action => action.command === "recommend_location");
            if (content && content.startsWith(generateMapAction.start)) {
                return true;
            }
            return false;
        },
        isWeb(message) {
            const content = message.content;
            const actions = this.actionsFiltered;
            // get the recommend_location action
            const webAction = actions.find(action => action.command === "web_results");
            if (content && content.startsWith(webAction.start)) {
                return true;
            }
            return false;
        },
        // get tokens since last summary
        async generateMaps(args) {
            try {
                const location = args.location_name_or_address;
                // find map action
                let actions = this.actionsFiltered;
                let mapAction = actions.find(action => action.command === "recommend_location");
                let finalPrompt = `${mapAction.start} ${location} ${mapAction.end}`;
                let message = { content: finalPrompt, role: "assistant", kind: "map" };
                this.addMessageStore(message);
                return;
            } catch (error) {
                console.error(`Geocode API call failed: ${error}`);
            }
            return;
        },
        async getUserLocation() {
            try {
                const position = await this.getCurrentPositionPromise();
                if (!position) return;
                let locationData = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    updated: new Date(),
                };
                let reverseLocation = await this.getReverseGeolocation(locationData);
                locationData = { ...locationData, ...reverseLocation };
                this.$store.dispatch("user/saveUser", {
                    location: { ...locationData },
                });
            } catch (error) {
                console.error("Error getting user location:", error);
            }
        },
        getCurrentPositionPromise() {
            return new Promise((resolve, reject) => {
                if (!navigator.geolocation) {
                    reject(new Error("Geolocation is not supported by this browser."));
                }
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
        },
        youtubeSearch(args) {
            // Implement the "youtube_search" command here
            return;
        },
        evaluateCode(args) {
            // Implement the "evaluate_code" command here
            return;
        },
        convertMessageToFormat(originalMessage, format) {
            let message = {
                thoughts: {
                    text: "",
                    reasoning: "",
                    plan: "",
                    criticism: "",
                },
                command: {
                    name: "",
                    args: {},
                },
            };
        },
        getNewlyTrimmedMessages(fullMessageHistory, currentContext) {
            let lastMemoryIndex = this.$store.state.chat.lastMemoryIndex || 1;
            const newMessages = this.$store.state.messages.filter((msg, i) => i > lastMemoryIndex);

            const newMessagesNotInContext = newMessages.filter(msg => !currentContext.includes(msg));

            let newIndex = lastMemoryIndex;

            if (newMessagesNotInContext.length > 0) {
                const lastMessage = newMessagesNotInContext[newMessagesNotInContext.length - 1];
                newIndex = fullMessageHistory.indexOf(lastMessage);
            }

            return [newMessagesNotInContext, newIndex];
        },
    },
};
