<template>
    <div class="group relative">
        <div>
            <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img v-if="productImage" :alt="p?.alt" :src="productImage.url" class="h-full w-full object-cover object-center lg:h-full lg:w-full" />
            </div>
            <div class="mt-4 flex justify-between">
                <div>
                    <h1 class="f-20">
                        <a :href="p?.url">{{ p?.title }}</a>
                    </h1>
                    <!--                                    <p class="mt-1 text-sm text-gray-500">{{ product?.color }}</p>-->
                </div>
                <p class="text-sm font-medium text-gray-900">${{ p?.price }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "ProductCardAlt",
    computed: {
        ...mapGetters("styleGuide/image", ["images"]),
        productImages() {
            let { images } = this.p;
            // match the image to images array
            let imageUrls = images ? images.map(img => img.url) : [];
            let matchedImages = this.images ? this.images.filter(img => imageUrls.includes(img.originalURL)) : [];
            // sort matched images by entropy (image.entropy.entropy)
            matchedImages.sort((a, b) => {
                let entropyA = a.entropy && a.entropy.entropy ? a.entropy.entropy : 0;
                let entropyB = b.entropy && b.entropy.entropy ? b.entropy.entropy : 0;
                return entropyB - entropyA;
            });

            // find unmatched images
            let unmatchedImages = this.images ? this.images.filter(img => !imageUrls.includes(img.originalURL)) : [];

            // append unmatched images at the end
            let allImages = [...matchedImages, ...unmatchedImages];

            return allImages;
        },
        productImage() {
            return this.productImages[0];
        },
    },
    props: {
        p: {},
        product: {},
    },
};
</script>
