import setupMessages from "@/mixins/ai/setup_messages";
import gptError from "@/mixins/ai/gpt_error";
import webRequest from "@/mixins/ai/web_request";

async function groq(options) {
    let { model = "mixtral-8x7b-32768", messages = [], temperature = 0 } = options;
    let request = {
        model: model,
        messages,
        // max_tokens: tokens,
        temperature: temperature,
        // top_p: 0.9,
    };
    try {
        let response = await webRequest("groq", request);
        response = await response.json();
        response = response.completion;
        console.groupCollapsed("%cGROQ Response", cht);
        console.log(response);
        console.groupEnd();
        return response;
    } catch (error) {
        console.error(error);
        console.error(gptError(error));
        return null;
    }
}

// window.groq = groq;
export default groq;
