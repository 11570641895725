<template>
    <ul class="list-inside list-none py-0">
        <div class="list-group-flush px-3 bg-snow py-3 border-bottom dark:border-base-500 dark:bg-base-800"><h5 class="mb-0">Settings</h5></div>
        <li v-for="(item, index) in sidebarItems" :key="item.title" :class="`d-${index * 1}`" class="pl-3 pr-3 fwb f aic jcb border-bottom py-3 !border-gray-100 dark:!border-base-500 animated duration-3 swingInUp">
            <router-link :to="`/admin/${$route.params.id}/${item.link}`" active-class="!text-gray-900" class="f-20 text-gray-500 hover:text-blue-900 dark:text-base-300 f gap-3">
                <div class="mx-1 text-center f aic jcc">
                    <i :class="[$route.path.includes(item.link) ? 'fas text-gray-900' : 'fas text-gray-500 dark:text-base-300 o-3', '', item.icon]"></i>
                </div>
                {{ item.title }}
            </router-link>
            <div>
                <div v-if="item.count" class="c-25 f aic jcc border-light bg-gray-100 f-11 p-1 block">{{ item.count }}</div>
            </div>
        </li>
        <!--        <collapsable-menu-item>-->
        <!--            <template #title>-->
        <!--                <li :class="`d-${11}`" class="pl-3 pr-3 fwb f aic jcb border-bottom py-3 !border-gray-100 animated duration-3 swingInUp">-->
        <!--                    <div class="f-20 text-gray-500 dark:text-base-300 hover:text-blue-900 f gap-3">-->
        <!--                        <div class="mx-1 text-center f aic jcc">-->
        <!--                            <i class="fas text-gray-500 o-3 fa-chart-line"></i>-->
        <!--                        </div>-->
        <!--                        Progress-->
        <!--                    </div>-->
        <!--                </li>-->
        <!--            </template>-->
        <!--            <StyleGuideProgress :style-guide="styleGuide" class="f-15" />-->
        <!--        </collapsable-menu-item>-->
    </ul>
</template>
<script>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import StyleGuideProgress from "@/components/styleGuide/Sections/Progress.vue";

export default {
    name: "AdminSidebar",
    components: { CollapsableMenuItem, StyleGuideProgress },
    props: {
        sidebarItems: {},
        styleGuide: {},
    },
};
</script>
