export function fineTuneMapping(arrayOfItems, dummyStructure) {
    return arrayOfItems.map(item => {
        if (!item) return;

        const { result } = item;
        const structuredItem = structureObject(result, dummyStructure);

        return structuredItem;
    });
}

function structureObject(item, structure) {
    const structuredItem = {};

    for (const key in structure) {
        if (structure.hasOwnProperty(key)) {
            const nestedStructure = structure[key];

            if (item.hasOwnProperty(key)) {
                const value = item[key];

                if (nestedStructure === null) {
                    // Simple value
                    structuredItem[key] = value;
                } else if (typeof nestedStructure === "object") {
                    // Nested object or array
                    if (Array.isArray(nestedStructure)) {
                        // Array of items
                        if (Array.isArray(value)) {
                            structuredItem[key] = value.map(nestedItem => structureObject(nestedItem, nestedStructure[0]));
                        } else {
                            structuredItem[key] = [];
                        }
                    } else {
                        // Nested object
                        if (typeof value === "object" && !Array.isArray(value)) {
                            structuredItem[key] = structureObject(value, nestedStructure);
                        } else {
                            structuredItem[key] = {};
                        }
                    }
                }
            }
        }
    }

    return structuredItem;
}
