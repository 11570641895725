let create_slide = {
    name: "create_slide",
    description: "Generate a slide for a presentation.",
    parameters: {
        type: "object",
        properties: {
            internal_note_topic: {
                type: "string",
                description: "This is an internal note for the logic behind choosing this slide. Refer to the fact sheet.",
            },
            style: {
                type: "string",
                description: "The style of the slide's layout",
                enum: ["imageleft", "imageright", "matrix", "timeline", "tablegrid", "multiimage"],
                // enum: ["imageleft", "imageright", "fullbleed", "matrix", "timeline", "tablegrid", "tableofcontent"],
            },
            title: {
                type: "string",
                description: "This is the slide's title. 2-5 words. Use the fact sheet",
            },
            body: {
                type: "string",
                description: "A very brief outline of what to cover here. 5 words. Example: 'this section should cover XYZ'",
                // description: "This is the slide's body. One paragraph. Markdown is supported.",
            },
            // image_description: {
            //     type: "string",
            //     description: "Write a description of the image to accompany the slide",
            //     example: "A picture of a cat, jumping on a roof.",
            // },
            image: {
                type: "string",
                description: "Describe the image alt tag that will accompany the slide",
                example: "A picture of a cat, jumping on a roof.",
            },
            relevant_image_indexes: {
                type: "array",
                description: "Indexes of the most relevant images from the provided Image List.",
                items: { type: "integer", description: "The index of the image in the image list." },
                minItems: 3,
            },
            imageIndex: {
                type: "integer",
                description: "Select the index of the image most relevant to this slide's content.",
                example: 2,
            },
            google_image_query: {
                type: "string",
                description: "Usually includes the topic name. A query to search for an image to accompany the slide. It should be scoped to the overall topic. Example: '[topic] reviews' ",
            },
            mode: {
                type: "string",
                description: "The visual background of the slide",
                enum: ["dark", "light"],
            },
        },
        required: ["title", "body", "image", "style", "imageIndex", "relevant_image_indexes", "mode", "google_image_query"],
    },
};

export default create_slide;
