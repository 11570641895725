import { endpointURL } from "@/mixins/Chat/Commands/api_url";

async function webRequest(endpoint, data, stream, isFileUpload = false, onProgress = null, auth, type = "POST") {
    try {
        const url = endpointURL(endpoint);
        // if (data) logGroup("Request", { data });
        if (!isFileUpload) {
            let headers = { "Content-Type": "application/json" };
            if (stream) headers["Connection"] = "keep-alive";
            if (auth) headers["Authorization"] = auth;

            let requestConfig = { method: type, headers: headers };
            if (type !== "GET") requestConfig.body = JSON.stringify(data);

            try {
                const response = await fetch(url, requestConfig);
                if (response?.status === 401) {
                    console.log("Unauthorized request");
                    window.location.href = "/auth";
                    return response;
                }
                if (!response.ok) throw new Error(`HTTP request failed with status ${response.status}`);
                return response;
            } catch (error) {
                console.error("Error during fetch operation:", error);
                throw error;
            }
        } else {
            // For file uploads, use XMLHttpRequest for progress tracking
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", url);

                if (stream) xhr.setRequestHeader("Connection", "keep-alive");

                if (onProgress) {
                    xhr.upload.onprogress = event => {
                        if (event.lengthComputable) {
                            const percentComplete = (event.loaded / event.total) * 100;
                            onProgress(percentComplete);
                        }
                    };
                }

                xhr.onload = () => {
                    if (xhr.status >= 200 && xhr.status < 300) resolve(JSON.parse(xhr.responseText));
                    else reject(new Error(`HTTP request failed with status ${xhr.status}`));
                };

                xhr.onerror = () => reject(new Error("Network error"));
                xhr.send(data);
            });
        }
    } catch (e) {
        console.log(e);
        // logGroup("Request", { data });
    }
}

export default webRequest;

// async function webRequest(endpoint, data, stream, isFileUpload = false, onProgress = null) {
//     console.log("webRequest", endpoint, data, stream);
//     const url = endpointURL(endpoint);
//
//     // For non-file uploads, use the original fetch-based implementation
//     if (!isFileUpload) {
//         let headers = {};
//         if (stream) headers["Connection"] = "keep-alive";
//         headers["Content-Type"] = "application/json";
//         headers["Access-Control-Allow-Origin"] = "*";
//
//         let requestToFetch = {
//             method: "POST",
//             headers: headers,
//             body: JSON.stringify(data),
//         };
//
//         try {
//             const response = await fetch(url, requestToFetch);
//             if (!response.ok) {
//                 console.log(`HTTP request failed with status ${response.status}`);
//             }
//             return response;
//         } catch (error) {
//             console.error("Error during fetch operation:", error);
//             throw error;
//         }
//     } else {
//         // For file uploads, use XMLHttpRequest for progress tracking
//         return new Promise((resolve, reject) => {
//             const xhr = new XMLHttpRequest();
//             xhr.open("POST", url);
//
//             if (stream) xhr.setRequestHeader("Connection", "keep-alive");
//
//             if (onProgress) {
//                 xhr.upload.onprogress = event => {
//                     if (event.lengthComputable) {
//                         const percentComplete = (event.loaded / event.total) * 100;
//                         onProgress(percentComplete);
//                     }
//                 };
//             }
//
//             xhr.onload = () => {
//                 if (xhr.status >= 200 && xhr.status < 300) {
//                     resolve(JSON.parse(xhr.responseText));
//                 } else {
//                     reject(new Error(`HTTP request failed with status ${xhr.status}`));
//                 }
//             };
//
//             xhr.onerror = () => reject(new Error("Network error"));
//             xhr.send(data);
//         });
//     }
// }

// export default webRequest;
