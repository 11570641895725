<template>
    <div v-if="urlDetected?.length > 0" class="f animated fadeInUpSmooth duration-3 ml-auto mr-5">
        <span class="fwsb o-5">Summarize:</span>
        <div class="f ml-2">
            <a v-for="(url, index) in urlDetected" v-delay :class="`d-${index * 2}`" class="animated fadeInUpSmooth duration-3 fwb text-blue f-15 pr-3" href="#" @click.prevent="clickedURL(url.url)">{{ url.title }}</a>
        </div>
    </div>
</template>
<script>
import BrowseCommand from "@/mixins/Chat/Commands/BrowseCommand";

export default {
    name: "ChatInputURLDetector",
    mixin: [BrowseCommand],
    props: {
        message: {
            type: String,
            required: true,
        },
    },
    methods: {
        urlDetected() {
            if (typeof this.message === "string") {
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                const urlMatches = this.message.match(urlRegex);
                if (urlMatches) {
                    return urlMatches.map(url => {
                        const urlObj = new URL(url);
                        return {
                            url: url,
                            title: urlObj.hostname,
                        };
                    });
                }
            }
            return [];
        },
    },
};
</script>
<style scoped>
textarea {
    overflow: hidden;
    resize: none;
    border: none;
    padding: 10px;
    min-height: unset !important;
}
</style>
