<template>
    <!--    <StreamCompletionTest />-->
    <div class="container-slim mw-600 my-2">
        <div v-if="messages" class="f fc gap-3" style="font-family: unset !important">
            <MessageSet :messages="messages" />
<!--            {{ emailMessages }}-->
<!--                        <MessageSet :key="emailMessages" :messages="emailMessages" />-->
        </div>
    </div>
</template>
<script>
import generateEmailMixin from "@/mixins/ai/generateEmailMixin";
import MessageSet from "@/components/styleGuide/debugging/MessageSet.vue";
// import StreamCompletionTest from "@/components/styleGuide/debugging/StreamCompletionTest.vue";

export default {
    name: "PreviewMessages",
    mixins: [generateEmailMixin],
    components: { MessageSet },
    props: {
        messages: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {};
    },
    methods: {
        removeMessage(index) {
            this.$emit("remove-message", index);
        },
        editMessage(index) {
            this.$emit("edit-message", index);
        },
    },
};
</script>
<style lang="scss">
.preview-md {
    ul,
    ol {
        @apply ml-3 mb-0 pb-0 pl-3;
        line-height: normal;

        li {
            @apply pl-0 mb-0 py-1 leading-normal;
            line-height: 1;
            padding-bottom: 0;
        }
    }
    .preview-md {
        h1,
        h2 {
            font-size: 0.5em !important;
        }
    }
}
</style>
