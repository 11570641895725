<template>
    <li class="flex items-center gap-x-2 p-3 text-sm bg-white border text-gray-800 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
        <div class="w-full flex justify-between truncate">
            <span class="me-3 flex-1 w-0 truncate">{{ file.name }}</span>
            <button class="flex items-center gap-x-2 text-gray-500 hover:text-blue-500 whitespace-nowrap dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" type="button">
                <IconDownload />
                Download
            </button>
        </div>
    </li>
</template>
<script>
import IconDownload from "@/views/IconDownload.vue";

export default {
    name: "MessageDownload",
    components: { IconDownload },
    props: {
        file: {
            type: Object,
            default: () => ({
                name: "resume_web_ui_developer.csv",
            }),
        },
    },
};
</script>
