<template>
    <div :class="{ 'editor-section': text, 'px-2.5 border-top o-4': !text }" class="f fc border-bottom">
        <template v-if="text">
            <div class="pb-1 relative">
                <!--                <span class="fwb text-left mr-auto editor-section-title">Edit {{ type }}</span>-->
                <!--                <textarea class="f-20" v-if="text" type="text" :value="text" @input="handleTextEdit" />-->
                <SimpleTextarea :label="`Edit ${displayType}`" :model-value="text" @input="handleTextEdit">
                    <BaseButton v-if="!loading" icon="fas f-17 fa-sparkles !text-indigo-500 opacity-100 !scale-100" size="xxs" icon-only @click="updateCopyForSection" />
                    <BaseButton v-else icon="fas f-17 fas fa-sync fa-spin ease-back duration-3 text-purple-500" class="" size="xxs" icon-only @click="updateCopyForSection" />
                </SimpleTextarea>
                <!--                <div class="f">-->
                <!--            <div v-for="s in options" :key="s" @click="updateSection('style', s.id)">-->
                <!--                <div v-html="s.html" class="border-light" :class="{ 'border border-solid border-black': s.id === style }"></div>-->
                <!--            </div>-->
                <!--                </div>-->
            </div>
            <template v-for="item in textOptions">
                <div class="border-bottom py-2 text-center text-blue-900 text-sm o-5 text-balance" @click="handleTextEdit(item)">"{{ item }}"</div>
            </template>
            <div class="x f aic jcb" v-if="false">
                <span class="editor-section-title text-left mr-auto x">Font size</span>
                <div>
                    <SimpleInput :model-value="fontSize" class="mb-0 f-15 !width-50" type="text" @input="handleFontSizeChange($event.target.value)" />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="">
                <div class="f jcb aic">
                    <span class="editor-section-title">Add a {{ displayType }}</span>
                    <div class="p-1" @click.prevent="addSection">
                        <i class="far fa-plus-circle f-20 o-2"></i>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import SimpleTextarea from "@/views/SimpleTextArea.vue";
import SimpleInput from "@/views/SimpleInput.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { stringifySections } from "@/store/SectionsToStrings";

export default {
    name: "SectionTextEditor",
    components: { BaseButton, SimpleInput, SimpleTextarea },
    props: {
        style: {},
        section: {
            type: Object,
            required: true,
        },
        identifier: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "header",
        },
        fontSize: {
            type: [Number, String],
            default: 16,
        },
        index: {
            type: Number,
            default: 0,
        },
        campaign: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ["update-section"],
    data() {
        return {
            loading: false,
            options: [
                {
                    id: "imageLeft",
                    name: "Image Right",
                    icon: "fas fa-align-right",
                    html: `<div class="f aic jcc p-2 py-1 gap-2 f-15"><i class="fas fa-image o-5"></i><i class="fas fa-align-left o-5 f-11"></i></div>`,
                },
                {
                    id: "fullBleed",
                    name: "Full Bleed",
                    icon: "fas fa-align-justify",
                    html: `<div class="f aic jcc p-2 px-2 py-1 f-15 px-3 flex-shrink-0 relative gap-2"><div class="p-absolute p-fill f fc aic jcc z-1" style="gap:2px;"><div class="bg-gray-900 o-5" style="height:2px;width:20px;"></div><div class="bg-gray-900 o-5" style="height:2px;width:10px;"></div></div><i class="fas fa-image o-1"></i></div>`,
                },
                {
                    id: "imageRight",
                    name: "Image Left",
                    icon: "fas fa-align-left",
                    html: `<div class="f aic jcc p-2 gap-2 py-1 f-15"><i class="fas fa-align-right f-11 o-5"></i><i class="fas fa-image o-5"></i></div>`,
                },
            ],
        };
    },
    mounted() {
        if (this.type === "email") this.options = this.options.filter(o => o.id !== "imageLeft");
    },
    computed: {
        textOptions() {
            return this.section?.[`${this.type}Options`] || [];
        },
        displayType() {
            let type = this.type || "";
            type = type.replace("_", " ");
            // title case the string
            type = type.replace(/_/g, " ").replace(/\b\w/g, l => l.toUpperCase());
            return type;
        },
    },
    methods: {
        handleTextEdit(event) {
            let text;
            if (typeof event === "string") text = event;
            else text = event.target.value;
            let type = this.type;
            if (type === "subheader") type = "sub_header";
            this.updateSection(type, `${text}`);
        },
        handleFontSizeChange(value) {
            this.updateSection(`${this.type}Size`, value);
        },
        async addSection() {
            // this.$emit("add-section", this.type);
            this.updateSection(this.type, " ");
            await this.updateCopyForSection();
            this.updateSection(this.type, this.textOptions?.[0]);
        },
        async saveCampaign() {
            await this.$store.dispatch("stream/saveCampaignItem", { id: this.campaign.id });
        },
        async updateCopyForSection() {
            this.loading = true;
            let type = this.type;
            if (type === "subheader") type = "sub_header";
            // this.updateSection(type, " ");
            let section = deepCopy(this.section);
            delete section.imageData;

            let obj = {
                id: this.campaign.id, //
                index: this.index,
                input: `CURRENT STATE\n${stringifySections([section])}\n\nJust return the string (NOT in quotes)\n\nRewrite SECTION ${this.index}'s ${type.toUpperCase()} COPY. USE MARKDOWN + A MIX OF STYLES TONES AND TOPICS`,
                existing: section,
                type: type,
            };
            console.log("updateCopyForSection", obj);
            await this.$store.dispatch("stream/addSectionWithAI", obj);
            this.loading = false;
        },
        updateSection(prop, value) {
            console.log("updateSection", prop, value);
            this.$emit("update-section", prop, value);
        },
    },
};
</script>
