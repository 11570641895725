<template>
    <div class="p-3 container">
        <h4>Files</h4>

        <div :class="['drop-zone', { 'drop-zone-active': isDragActive }]" @drop.prevent="onDrop" @dragover.prevent="onDragOver" @dragleave.prevent="onDragLeave">
            Drop files here or click to upload
            <input type="file" @change="onFileChange" style="display: none" />
        </div>
        <div class="x py-2">
            <div class="f flex-wrap gap-2">
                <FileUploadCard v-for="(file, index) of selectedFiles" :key="'item' + index" :loading="file?.loading" :file-preview="filePreviews[index]" class="relative" @remove-item="removeFile(index)" :file="file" />
            </div>
        </div>
        <BaseButton class="mt-3" label="Upload" style-type="secondary" @click.prevent="uploadFiles" />
        <div v-if="uploading">
            <progress :value="progress" class="transition-all duration-300 w-full" max="100">{{ progress }}%</progress>
        </div>
        <div v-if="uploadResponse">
            <p v-if="uploadResponse.filename">Uploaded {{ uploadResponse.filename }}</p>
            <p v-if="uploadResponse.id" class="mb-0 text-sm o-5">Uploaded {{ uploadResponse.id }}</p>
            <p v-if="uploadResponse.purpose" class="mb-0 text-xs o-5">Purpose {{ uploadResponse.purpose }}</p>
            <p v-if="uploadResponse.status" class="mb-0 text-xs o-5">Status {{ uploadResponse.status }}</p>
            <p v-if="uploadResponse.status_details" class="mb-0 text-xs o-5">Status details {{ uploadResponse.status_details }}</p>
        </div>
        <template v-if="$route.params.id">
            <pre class="visually-hidden" v-if="textForStyleGuideFile">{{ textForStyleGuideFile }}</pre>
            <BaseButton @click="stringifyObjectThenUpload(textForStyleGuideFile, $store.state.styleGuide.styleGuide.name + ' - Brand writing style examples')">Make it a file</BaseButton>
        </template>
    </div>
</template>

<script>
import webRequest from "@/mixins/ai/web_request";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import FileUploadCard from "@/components/images/FileUploadCard.vue";

// webRequest('files/create', {test:'test'}, undefined, false, (progress) => { console.log('Progress:', progress); // Debugging line this.progress = progress; });

export default {
    name: "OpenAiFileUpload",
    components: { FileUploadCard, BaseButton },
    data() {
        return {
            selectedFiles: [],
            filePreviews: [],
            isDragActive: false,
            uploadResponse: [],
            uploading: false,
            progress: 0,
        };
    },
    computed: {
        textForStyleGuideFile() {
            let additionalText = this.$store.state?.styleGuide?.styleGuide?.additionalText;
            additionalText = additionalText ? additionalText.map(t => t.content.trim()) : [];
            let name = this.$store.state.styleGuide.styleGuide.name;
            let textSamples = this.$store.state.styleGuide.styleGuide.textSamples;
            let guidelines = this.$store.getters["styleGuide/llm/CopyGuidelines"];
            let StyleGuide = this.$store.getters["styleGuide/styleGuide"];
            let images = this.$store.getters["styleGuide/llm/ImageList"];
            let products = this.$store.getters["styleGuide/llm/ProductArray"];
            let imageURLS = this.$store.getters["styleGuide/image/images"].map(i => i.url);
            let styles = this.$store.getters["styleGuide/divStyles"];
            let text = {
                brandName: name,
                sources: additionalText,
                samples: textSamples,
                guidelines,
                images: images,
                products: products,
                colorCombos: styles.map(s => {
                    return { background: s.backgroundColor, text: s.color };
                }),
            };
            text = objectToMarkdown(text);
            return `# ${name} Style Guide\n\n${text}`;
        },
    },
    methods: {
        removeFile(index) {
            this.selectedFiles.splice(index, 1);
            this.filePreviews.splice(index, 1);
        },

        onFileChange(e) {
            Array.from(e.target.files).forEach(file => this.handleFileSelect(file));
            this.shrinkIframe();
        },
        onDragOver(e) {
            this.isDragActive = true;
            e.dataTransfer.dropEffect = "copy";
        },
        onDrop(e) {
            Array.from(e.dataTransfer.files).forEach(file => this.handleFileSelect(file));
            this.isDragActive = false;
        },
        onDragLeave(e) {
            this.isDragActive = false;
        },
        handleFileSelect(file) {
            this.selectedFiles.push(file);
            let preview = file.type.startsWith("image/") || file.type === "application/pdf" || file.type.startsWith("text/") ? URL.createObjectURL(file) : null;
            this.filePreviews.push(preview);
        },
        async getFiles() {
            let files = await this.listFiles();
            this.updateStreamProp("options.files", files);
        },
        async uploadFiles() {
            if (!this.selectedFiles.length) {
                alert("Please select files to upload.");
                return;
            }

            this.uploadResponse = [];
            this.uploading = true;

            // Track loading state for each file
            const loadingStates = this.selectedFiles.map(() => false);
            const selectedfiles = this.selectedFiles;
            for (const [index, file] of selectedfiles.entries()) {
                try {
                    const formData = new FormData();
                    formData.append("file", file);
                    loadingStates[index] = true; // Set loading state for the current file
                    this.selectedFiles[index].loading = true;
                    const response = await webRequest("files/create", formData, undefined, true, progress => {
                        console.log("Progress:", progress); // Debugging line
                        this.progress = progress;
                    });

                    if (response.success === false) throw new Error(response.error || "Failed to upload file");
                    this.selectedFiles.splice(index, 1);
                    this.uploadResponse.push(response.data);
                } catch (error) {
                    console.error("Error uploading file:", error);
                    this.uploadResponse.push({ error: error.toString() });
                } finally {
                    loadingStates[index] = false; // Reset loading state after upload (success or fail)
                }
            }

            this.selectedFiles = [];
            this.filePreviews = [];
            await this.getFiles();
            this.uploading = false;
        },
        async stringifyObjectThenUpload(obj, name) {
            try {
                const jsonString = JSON.stringify(obj);
                const blob = new Blob([jsonString], { type: "text/plain" }); // Changed to text/plain
                const file = new File([blob], `${name}.txt`, { type: "text/plain" }); // Changed file extension to .txt

                const formData = new FormData();
                formData.append("file", file);

                this.uploading = true;

                const response = await webRequest("files/create", formData, undefined, true, progress => {
                    this.progress = progress;
                });

                if (response.success === false) throw new Error(response.error || "Failed to upload JSON");

                this.uploadResponse.push(response.data);
            } catch (error) {
                console.error("Error uploading JSON:", error);
                this.uploadResponse.push({ error: error.toString() });
            } finally {
                this.uploading = false;
            }
        },
    },
};
</script>

<style>
.drop-zone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}
.drop-zone-active {
    background-color: #f3f3f3; /* or any other color to indicate active state */
}
input[type="file"] {
}

progress {
    width: 100%;
    height: 20px;
}
</style>
