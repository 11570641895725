<template>
    <div>
        <template v-if="small">
            <div @click="isOpen = !isOpen" class="mb-0 transition-all cursor-pointer f-15 f aic jcs gap-3 font-xxs text-gray-400 fb">
                <div>
                    <slot name="title"></slot>
                </div>
                <span class="f aic jcc mb-0 f-11 mt-1 transform transition-transform !rotate-45">
                    <i class="fas fa-angle-down mb-0" v-if="isOpen"></i>
                    <i class="fas fa-angle-right mb-0" v-else></i>
                </span>
            </div>
        </template>
        <div v-else @click="isOpen = !isOpen" class="border border-gray-200 f aic jcb rounded-full mb-0 border-bottom w-full transition-all cursor-pointer hover:shadow-md bg-gray-200 bg-snow px-4 py-2 font-medium text-gray-900">
            <div>
                <slot name="title"></slot>
            </div>
            <div>
                <span class="float-right">{{ isOpen ? "-" : "+" }}</span>
            </div>
        </div>
        <transition name="fade-up">
            <div v-show="isOpen" class="my-2 rounded text-gray-700 shadow">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.open) {
            this.isOpen = true;
        }
    },
    data() {
        return {
            isOpen: false,
        };
    },
};
</script>

<style scoped>
.collapse-enter-active,
.collapse-leave-active {
    transition: all 0.3s ease;
}
.collapse-enter-from,
.collapse-leave-to {
    height: 0;
    opacity: 0;
}
.collapse-enter-to,
.collapse-leave-from {
    height: auto;
    opacity: 1;
}
</style>
