import { mapActions } from "vuex";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            display_error: {
                name: "Error",
                command: "display_error",
                start: "[ERR]",
                end: "[ERD]",
                kind: "error",
                role: "Error",
                description: "Display an error",
                show: true,
                newMessage: false,
            },
        };
    },
    computed: {},
    methods: {
        // ...mapActions("chat", ["addToMemoryStore"]),
    },
};
