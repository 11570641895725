<template>
    <div class="py-1 px-3 d-inline-block white bg-success br-50 mx-auto">
        <i class="fas fa-check mr-2"></i>
        Done
    </div>
</template>
<script>
export default {
    name: "StreamingStatusDone",
};
</script>
<style lang="scss" scoped>
strong{
    font-weight: bold;
}
</style>