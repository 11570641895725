import addUserMessage from "@/mixins/ai/add_user_message";
import gptError from "@/mixins/ai/gpt_error";
import handleFunctionResponse from "@/mixins/ai/handle_function_response";
import messageLogger from "@/mixins/ai/message_logger";
import prepareRequestData from "@/mixins/ai/prepare_request";
import setupFunctionCall from "@/mixins/ai/setup_function_call";
import setupFunctions from "@/mixins/ai/setup_functions";
import setupMessages from "@/mixins/ai/setup_messages";
import setupStreamId from "@/mixins/ai/setup_stream_id";
import streamRequest from "@/mixins/ai/stream_request";
import processContentFull from "@/mixins/ai/stream_tokens";
import cleanMessages from "@/mixins/ai/clean_messages";

/**
 * @module streamCompletion
 *
 * @description Handles the streaming completions for new user messages.
 *
 * @param {object} store - The Vuex store instance.
 * @param {string} user - The input message from the user.
 * @param {Array} messagesArray - The existing array of messages.
 * @param {string} system - The system message to be sent with the user's message.
 * @param {Function} beforeStarting - A function to execute before the stream request starts.
 * @param {Function} whileProcessing - A function to execute while the streamed content is being processed.
 * @param {Function} afterCompleting - A function to execute after the stream completion.
 * @param {string} [model="gpt-3.5-turbo"] - The AI model to be used for the stream.
 * @param {boolean} [silent=true] - Determines whether to log the messages to the console.
 * @param {string} from - The source of the message.
 * @param {number} [temperature=0] - The temperature for the AI model's randomness in responses.
 * @param {Array} functions - The functions to be used for the stream.
 * @param {string} function_call - The function call to be used for the stream.
 * @param {number} [length=100] - The length of the response from the AI model.
 * @param {boolean} [json_mode=false] - Determines whether to use the JSON mode for the stream.
 *
 * @returns {Promise} Returns a promise which resolves when the streaming is complete.
 *
 * @throws Will throw an error if the streaming process encounters an issue.
 *
 * @example
 * streamCompletion(store, "Hello, how are you?", messages, "System message", beforeFunc, whileFunc, afterFunc, "gpt-3.5-turbo", true, "user", 0.5, functions, "call", 150);
 *
 */
async function streamCompletion(store, user, messagesArray, system, beforeStarting, whileProcessing, afterCompleting, model = "gpt-3.5-turbo", silent = true, from, temperature = 0, functions, function_call, length, json_mode) {
    let object = {
        store,
        user,
        messagesArray,
        system,
        beforeStarting,
        whileProcessing,
        afterCompleting,
        model,
        silent,
        from,
        temperature,
        functions,
        function_call,
        length,
        json_mode,
    };
    console.log("streamCompletion", object);
    try {
        // Initialize messages variable for processingx
        let messages = await setupMessages(user, system, messagesArray);
        let lastMessage = messages[messages.length - 1];
        // messages = initializeMessages(messagesArray, system);
        if (!silent) console.log("Cleaned Messages", messages);
        // Set default model if not provided

        // Add the user's message to the chat
        // addUserMessage(store, messages, user);
        // If a beforeStarting function is provided, run it
        try {
            if (beforeStarting) beforeStarting();
        } catch (e) {
            gptError(e);
        }
        // Prepare the request data
        let request = { messages: messages, model: model, temperature: temperature, response_length: length };
        if (store.state.stream.maxMessageCount) request.maxCount = store.state.stream.maxMessageCount;
        console.log("Max Message Count", request.maxCount);
        if (store.state.stream.json_mode) request.json_mode = store.state.stream.json_mode;
        console.log("Max Message Count", request.maxCount);
        if (json_mode) request.json_mode = json_mode;
        if (functions) {
            request.functions = setupFunctions(functions);
            if (functions) request.function_call = setupFunctionCall(function_call, functions);
        } else if (!functions && function_call) {
            request.function_call = function_call;
        }
        const streamId = setupStreamId(store);
        request.messages = cleanMessages(messages);
        let requestData = prepareRequestData(request, streamId);

        await messageLogger(lastMessage, from, requestData, requestData.messages, undefined, functions, lastMessage.role); // log the message

        // Send a request to the streamTest endpoint with the user's message

        let response = await streamRequest(requestData, from);

        // if (!silent) console.time("processContent");

        const { messageContent, functionObject, role, messageObject } = await processContentFull(
            response,
            (token, function_object, function_arguments, messageObject) => {
                whileProcessing(token, function_object, function_arguments, messageObject);
            },
            model,
            finalResponse => {
                let message_object = finalResponse.messageObject;
                if (finalResponse.finish_reason === "function_call") response.function_call = handleFunctionResponse(message_object.function_call);
                requestData.messages.push(message_object);
                messageLogger(message_object, from, requestData, requestData.messages, response, functions, message_object.role); // log the message
            },
        );
        if (functionObject && functionObject.name && functionObject.arguments) {
            functionObject.content = messageContent;
            response.function_call = functionObject;
        }

        // this.$forceUpdate();

        if (functionObject.arguments) {
            if (functionObject.arguments) functionObject.arguments = JSON.parse(functionObject.arguments);
            response.function_call = functionObject;
            afterCompleting(functionObject);
            afterCompleting().then(() => {
                console.log("done streaming");
            });
        } else {
            afterCompleting();
        }
    } catch (e) {
        gptError(e);
    }
}
export default streamCompletion;
