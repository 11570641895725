<template>
    <div class="x overflow-hidden">
        <div class="z-5 border-top isolate" v-if="isAdmin" :class="{ 'fixed bottom left right ': !mobile }" style="z-index: 1000; backdrop-filter: blur(100px); background: rgba(255, 255, 255, 0.7)">
            <div class="f aic jcb px-3 gap-3 flex-wrap border-top z-5 py-2 x mw-1400 mx-auto">
                <div class="f aib jcc fc text-left">
                    <p class="fwb mb-0 font-bold text-left f-24 f aic jcc" style="font-size: 22px !important; font-weight: bold">
                        <strong>{{ styleGuide.name }}</strong>
                        <a v-if="styleGuide.url" :href="styleGuide.url" target="_blank" class="fwb mb-0 !f-13 text-black ml-2"><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                    </p>
                    <p class="fwb mb-0 !f-13 line-clamp-1">{{ styleGuide.description }}</p>
                </div>
                <div class="f ais scrollbar-hide gap-3 link-pile" :class="{ 'overflow-x-scroll jcc aic': !mobile, 'flex-wrap jcs': mobile }">
                    <a :class="{ disabled: loadingGuide }" v-if="!loadingGuide" :style="buttonStyles[0]" class="btn" href="#" @click.prevent="startCopyGuide()">
                        <template v-if="loadingGuide">
                            <i class="fal mr-2 fa-sync spinner infinite ease-back"></i>
                            <span>Loading ...</span>
                        </template>
                        <template v-else>
                            <i class="fal mr-2 fa-pencil-alt"></i>
                            <span>Make Guidelines</span>
                        </template>
                    </a>
                    <a v-else :style="buttonStyles[0]" class="btn button-1" href="#" @click.prevent="stopCopyGuide">
                        <i class="fal mr-3 fa-stop"></i>
                        Stop
                    </a>
                    <a class="f gap-3 button-1" href="#" @click.prevent="getMoreText">
                        <i class="fal fa-notes-medical"></i>
                        Sources
                        <div v-if="sourceCount" class="border-light rounded-full f aic jcc bg-gray-50 text-gray-900 fwb" style="padding: 2px 5px; font-size: 10px">{{ sourceCount }}</div>
                    </a>
                    <a class="f button-1 aic jcc gap-3" href="#" @click.prevent="getMoreText(true)">
                        <i class="fal mr-3 fa-image"></i>
                        Images
                        <div v-if="imageCount" class="border-light rounded-full f aic jcc bg-gray-50 text-gray-900 fwb" style="padding: 2px 5px; font-size: 10px">{{ imageCount }}</div>
                    </a>
                    <a v-if="styleGuide.copyGuidelines" class="btn" href="#" @click.prevent="updateStyleGuideProp('copyGuidelines', null, true)">
                        <i class="fal mr-3 fa-trash"></i>
                        Clear Guide
                    </a>
                    <a v-if="!styleGuide.logos" class="btn" href="#" @click.prevent="updateStyleGuideProp('logos', null)">
                        <i class="fal mr-3 fa-trash"></i>
                        Get logos
                    </a>
                    <a class="btn button-1" href="#" @click.prevent="startBrandPersonas">
                        <i class="fal mr-3 fa-user"></i>
                        Get Personas
                    </a>
                    <!--                toggle feature  -->
                    <a class="btn button-1" href="#" @click.prevent="startCopywritingGuide">
                        <i class="fal mr-3 fa-user"></i>
                        Continue guide
                    </a>
                    <a class="btn button-1" :class="{ 'button-1': styleGuide?.categories?.includes('Featured'), button02: !styleGuide?.categories?.includes('Featured') }" href="#" @click.prevent="toggleFeatureStyleGuide">
                        <i class="" :class="{ 'fas fa-star': styleGuide?.categories?.includes('Featured'), 'fal fa-star': !styleGuide?.categories?.includes('Featured') }"></i>
                        <!--                        {{ styleGuide.categories?.includes("Featured") ? "Unfeature" : "Feature" }}-->
                    </a>
                    <a class="btn button-1" href="#" @click.prevent="updateGuideSection(['stylesheet'])">
                        <i class="far fa-text-size"></i>
                    </a>
                    <a v-if="styleGuide.shopify" class="button-1 btn f aic jcc gap-3" href="#" @click.prevent="updateGuideSection(['clearProducts', 'shopify'])">
                        <i class="far fa-shopping-bag"></i>
                        <div v-if="productCount" class="border-light rounded-full f aic jcc bg-gray-50 text-gray-900 fwb" style="padding: 2px 5px; font-size: 10px">{{ productCount }}</div>
                    </a>
                    <a class="btn button-2" :href="`https://console.firebase.google.com/project/ashermuse/firestore/data/~2Fbrands~2F${$route.params.id}~2Fimages`" target="_blank">
                        <i class="far fa-cog"></i>
                    </a>
                    <a class="btn" style="background: red; color: white" href="#" @click.prevent="deleteStyleGuide(styleGuide)">
                        <i class="far fa-trash"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import CopyGuideSection from "@/components/generative_templates/Guidelines/CopyGuideSection.vue";
import getCopyGuideMixin from "@/mixins/ai/getCopyGuideMixin";
import CopyGuideLinesPersona from "@/components/generative_templates/Guidelines/CopyGuideLinesPersona.vue";
import DosDontsList from "@/components/generative_templates/Guidelines/DosDontsList.vue";
import { mapGetters } from "vuex";
import ChartTone from "@/components/generative_templates/Guidelines/ChartTone.vue";
import TraitList from "@/components/generative_templates/Guidelines/TraitList.vue";
import WritingSamples from "@/components/generative_templates/Guidelines/WritingSamples.vue";
import BrandEthos from "@/components/generative_templates/Guidelines/BrandEthos.vue";
import CopyGuidelineExamples from "@/components/generative_templates/Guidelines/CopyGuidelineExamples.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import StyleGuideToolbar from "@/components/generative_templates/Guidelines/StyleGuideToolbar.vue";

export default {
    name: "CopyGuidelines",
    components: {},
    mixins: [styleGuideMixin, getCopyGuideMixin],
    props: {
        guidelines: {
            type: Object,
            default: () => ({
                overview: {
                    name: "",
                    about: "",
                    analogous_comparison: "",
                    voice: [],
                },
                guidelines: [
                    {
                        name: "",
                        tone: "",
                        examples: [],
                        guidelines: [],
                        avoid: [],
                    },
                ],
            }),
        },
    },
    data() {
        return {
            aborted: false,
            loadingGuide: false,
        };
    },
    watch: {
        // guidelines: {
        //     handler(newValue) {
        //         this.updateGuidelines(newValue);
        //     },
        //     deep: true,
        // },
    },
    async mounted() {
        await this.getGenerativeFunctions();
        // this.$nextTick(() => {
        //     this.updateGuidelines(this.guidelines);
        // });
    },
    methods: {
        async featureStyleGuide(id) {
            let categories = this.styleGuide.categories || [];
            categories.push("Featured");
            await this.updateStyleGuideProp("categories", categories, true);
            this.$store.dispatch("styleGuide/updateBrandList", { categories: categories });
        },
        unfeatureStyleGuide(id) {
            let categories = this.styleGuide.categories || [];
            categories = categories.filter(category => category !== "Featured");
            this.updateStyleGuideProp("categories", categories, true);
            this.$store.dispatch("styleGuide/updateBrandList", { categories: categories });
        },
        toggleFeatureStyleGuide(id) {
            let categories = this.styleGuide.categories || [];
            if (categories.includes("Featured")) {
                this.unfeatureStyleGuide(id);
            } else {
                this.featureStyleGuide(id);
            }
        },
        async stopCopyGuide() {
            this.aborted = true;
            await this.wantsToStopGenerating();
        },
        async startCopyGuide() {
            this.loadingGuide = true;
            this.aborted = false;

            await this.getCopyGuide(`Make ${this.styleGuide?.name}'s copywriting guide.`);
            // await this.startCopyGuideContinued();
            if (!this.aborted) await this.startBrandPersonas();
            if (!this.aborted) await this.startCopywritingGuide();
            this.loadingGuide = false;
        },
        async startCopyGuideContinued() {
            let model = "gpt-3.5-turbo-1106";
            model = gpt316;
            // model = gpt4;
            await this.getCopyGuideContinued(`Make ${this.styleGuide.name}'s copywriting guide.`, { model });
        },
        async startCopywritingGuide() {
            let model = "gpt-3.5-turbo-1106";
            model = gpt316;
            // model = gpt3;
            // model = gpt4;
            // `Don't forget to make 20 samples of TEXT for the brand ${this.styleGuide.name} including multiple email, paragraph, header and social examples. Be very mindful of punctuation styles. Think step by step to ensure you cover all value props.`
            await this.getCopywritingGuide(`Be sure to complete all sections in the brand's voice.\nAUDIENCE: ${this.styleGuide.name}'s customers\n\n`, { model });
        },
        async startBrandPersonas() {
            // model = gpt4;
            this.updateStyleGuideProp("copyGuidelines.overview.personas", []);
            await this.getBrandPersonas(`Make ${this.styleGuide.name}'s personas.`);
            this.updateStyleGuideProp("copyGuidelines.overview.personas", this.personas);
            this.updateStyleGuideProp("personas", null);
            this.getPersonaImages();
        },
        async getPersonaImages() {
            // Wrap the nextTick and for-loop logic in a new Promise

            return new Promise((resolve, reject) => {
                this.$nextTick(() => {
                    if (this.personas.length > 0) {
                        const imagePromises = [];

                        for (const [index, persona] of this.personas.entries()) {
                            // Ensure the ref is available and has the method
                            if (this.$refs[`persona${index}`] && typeof this.$refs[`persona${index}`][0].generatePersonaImagePrompt === "function") {
                                // Push each promise to the array
                                imagePromises.push(this.$refs[`persona${index}`][0].generatePersonaImagePrompt());
                            } else {
                                console.error("generatePersonaImagePrompt method not found for persona", index);
                                // If a method is not found, reject the promise
                                reject(new Error(`generatePersonaImagePrompt method not found for persona ${index}`));
                                return;
                            }
                        }

                        // Resolve once all promises are completed
                        Promise.all(imagePromises).then(resolve).catch(reject);
                    } else {
                        // Resolve immediately if there are no personas
                        resolve();
                    }
                });
                this.$store.dispatch("styleGuide/saveStyleGuide", this.styleGuide);
            });
        },
        async getMoreText(images) {
            let sections = ["additionalText"];
            // if (!this.productCount || this.productCount.length === 0) sections.push("shopify");
            await this.createStyleGuide(this.styleGuide.url, this.$route.params.id, {
                sections: sections,
                id: this.$route.params.id,
                saveImages: true,
            });
        },
        isValidJson(obj) {
            try {
                JSON.stringify(obj);
                return true;
            } catch (e) {
                return false;
            }
        },
    },

    computed: {
        ...mapGetters("styleGuide", ["productCount", "copyGuidelines"]),
        sourceCount() {
            return this.$store.state.styleGuide?.sources?.length;
        },
        imageCount() {
            return this.$store.getters["styleGuide/image/images"]?.length;
        },
        audience() {
            return this.copyGuidelines?.audience;
        },
        examples() {
            return this.copyGuidelines?.copywriting?.examples || [];
        },
        ethos() {
            return this.copyGuidelines?.ethos;
        },
        voice() {
            return this.copyGuidelines?.voice;
        },
        personas() {
            return this.styleGuide?.personas?.personas || this.copyGuidelines?.overview?.personas;
        },
        tone() {
            return this.copyGuidelines?.overview?.tone;
        },
        dos() {
            return this.copyGuidelines?.overview?.dos;
        },
        donts() {
            return this.copyGuidelines?.overview?.donts;
        },
        punctuation() {
            return this.copyGuidelines?.overview?.punctuation;
        },
        paragraphStyle() {
            // let style = this.likelyHeaderCombo?.style?.p || {};
            // style.fontSize = style.fontSize || "unset";
            // return style;
        },
    },
};
</script>

<style lang="scss" scoped>
h3 {
    font-size: 2rem !important;
}

strong {
    font-weight: 500 !important;
}

ol > div {
    counter-increment: list-counter;
}
.link-pile {
    a {
        font-size: 13px !important;
        font-weight: bold !important;
        text-transform: none !important;
        letter-spacing: normal !important;
        line-height: normal !important;
        padding: 0.5rem 1rem !important;
    }
}
ol > div > li {
    position: relative;
    padding-left: 2em;
    list-style-type: none;

    &::before {
        font-size: 0.8rem;
        line-height: 1.5em;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.5em;
        height: 1.5em;
        margin-right: 30px;
        @apply text-base-500;
        content: counter(list-counter);
        transform: translateY(-50%);
        @apply border-base-900/10;
        @apply bg-base-900/5;
        text-align: center;
        border: 1px solid;
        border-radius: 50%;
    }
}

p {
    font-size: 1rem !important;
    font-weight: normal !important;
    line-height: normal !important;
}

/* Include your styles here */
</style>
