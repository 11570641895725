<template>
    <StyleGuideContainer title="Emails" section="email">
        <div class="emailViewer col-span-12 grid grid-cols-12 gap-4" v-if="emails && emails.length > 0">
            <template v-for="email in emails">
                <EmailPreview :email="email" />
            </template>
        </div>
        <div class="x border-light br-10 col-span-12 gap-4 border-dashed bg-gray-50/5 py-5 text-center text-gray-300" v-else>
            <span>No emails yet</span>
        </div>
    </StyleGuideContainer>
</template>
<script>
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import EmailPreview from "@/components/styleGuide/Sections/EmailCard.vue";

export default {
    name: "emailViewer",
    components: { EmailPreview, StyleGuideContainer },
    props: {
        emails: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {};
    },
};
</script>
