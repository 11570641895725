import { isProxy, toRaw } from "vue";
const storeMethods = {};
export const documentStore = { mutations: {}, store: {} };

export default {
    created: function () {},
    mounted() {
        // window.addEventListener('mousemove', this.updateMousePosition);
    },
    beforeDestroy() {
        // window.removeEventListener('mousemove', this.updateMousePosition);
    },
    data() {
        return {
            tags: [
                {
                    id: 1,
                    iconClass: "fas fa-heading",
                    type: "h1",
                    label: "H1",
                    longLabel: "Heading 1",
                    actionType: "style",
                },
                {
                    id: 2,
                    iconClass: "fas fa-heading",
                    type: "h2",
                    label: "H2",
                    longLabel: "Heading 2",
                    actionType: "style",
                },
                {
                    id: 3,
                    iconClass: "fas fa-heading",
                    type: "h3",
                    label: "H3",
                    longLabel: "Heading 3",
                    actionType: "style",
                },
                {
                    id: 4,
                    iconClass: "fas fa-heading",
                    type: "p",
                    label: "P",
                    longLabel: "Paragraph",
                    actionType: "style",
                },
                {
                    id: 5,
                    iconClass: "fas fa-heading",
                    type: "li",
                    label: "•",
                    longLabel: "List item",
                    actionType: "style",
                },
                {
                    id: 6,
                    iconClass: "fas fa-heading",
                    type: "ol",
                    label: "1.)",
                    longLabel: "Ordered list",
                    actionType: "style",
                },
                {
                    id: 7,
                    iconClass: "fas fa-heading",
                    type: "img",
                    label: "Img",
                    longLabel: "Image",
                    actionType: "style",
                },
                {
                    id: 7,
                    iconClass: "fas fa-heading",
                    type: "div",
                    label: "Div",
                    longLabel: "Div",
                    actionType: "style",
                },
                {
                    id: 9,
                    iconClass: "fas fa-heading",
                    type: "span",
                    label: "Span",
                    longLabel: "Span",
                    actionType: "style",
                },
                {
                    id: 10,
                    iconClass: "fas fa-heading",
                    type: "blockquote",
                    label: "Blockquote",
                    longLabel: "Blockquote",
                    actionType: "style",
                },
                {
                    id: 11,
                    iconClass: "fas fa-heading",
                    type: "pre",
                    label: "Pre",
                    longLabel: "Pre",
                    actionType: "style",
                },
                {
                    id: 12,
                    iconClass: "fas fa-heading",
                    type: "a",
                    label: "A",
                    longLabel: "Link",
                    actionType: "style",
                },
            ],
            mousePosition: { x: null, y: null },
        };
        // artist: null,
    },
    computed: {
        // sections() {
        //     if (this.document && this.document.sections) {
        //         return this.document.sections;
        //     }
        // },

        // sections() {
        //     let sections = this.$store.state.document.sections;
        //     if (isProxy(sections)) {
        //         return toRaw(sections);
        //     } else {
        //         return sections;
        //     }
        // },
        sections: {
            get() {
                // window.doc = this.document.sections;
                if (this.document && this.document.sections) {
                    return this.document.sections;
                }
            },
            set(value) {
                this.$store.commit("setSections", value);
            },
        },
        selectedIndexEmpty() {
            if (this.sectionIndex === this.index && this.section.content === "") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        ...storeMethods,
        async aiHelper(text, kind, index, tone, customInstruction) {
            let toneText = "";
            if (tone) {
                toneText = `\n\n[The tone should be ${tone}]\n\n###\n\n`;
                console.error("tone", tone);
            } else if (this.document && this.document.tone) {
                toneText = `\n\n[The tone should be ${this.$store.state.document.tone.label}]\n\n###\n\n`;
            }
            let prompt;
            if (customInstruction || kind === "custom") {
                this.$emit("fetchingSection", [index, true, `We're working on this for you`]);
                prompt = `Modify the original passage using the feedback provided:
[Feedback]: ${customInstruction}
[Instructions]: If requested, use html; otherwise, use \\n for new lines and markdown syntax.
### 
[Original Passage]:
${text}             
###
[New Section]:
`;
            } else if (kind === "expand") {
                this.$emit("fetchingSection", [index, true, `We're expanding on this for you`]);
                prompt = `[Expand on this: Use markdown syntax (#, ## for headers, - for bullets etc) and \\n for new lines.]\n\n###\n\n${text}\n\n  ###`;
            } else if (kind === "simplify") {
                this.$emit("fetchingSection", [index, true, `We're working to simplify this for you`]);
                prompt = `Can you make this more simple for me?:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "todo") {
                this.$emit("fetchingSection", [index, true, `We're putting together a todo list for you`]);
                prompt = `Return a to-do list :\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "about") {
                this.$emit("fetchingSection", [index, true, `We're expanding on this for you`]);
                prompt = `write to paragraphs about:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "howto") {
                this.$emit("fetchingSection", [index, true, `We're pulling together a quick how-to on this`]);
                prompt = `Return a how-to list about this:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "legal") {
                this.$emit("fetchingSection", [index, true, `Helping make translate this to legalese`]);
                prompt = `Turn this into legalese :\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "html") {
                this.$emit("fetchingSection", [index, true, `We're pulling together some code for you`]);
                prompt = `Can you expand on this for me?:\n\n ${text}\n \n ${toneText} Return html code format with header tags, p tags, bullet points etc. ###`;
            } else if (kind === "versions") {
                this.$emit("fetchingSection", [
                    index,
                    true,
                    `We're pulling together a few versions for you to choose from`,
                ]);
                // remove the space from only the very first character of text if it exists

                prompt = `Write 3 different versions of this passage:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "outline") {
                this.$emit("fetchingSection", [index, true, `We're pulling together an outline for you`]);
                prompt = `Write structured outline for a larger more in-depth body of content I'll work on later. Based on this passage:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            }
            console.error(toneText);
            prompt += toneText;

            console.log(text);
            console.log(prompt);
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 4000,
                temperature: 1,
                top_p: 0.9,
                frequency_penalty: 0.5,
                presence_penalty: 0,
            };
            // best_of: 1,
            const response = await this.requestFromOpenAI(request, "Testing this");

            let newResponses = response;
            let textArray = response.split("\n");
            //remove /n from text array items
            textArray = textArray.map(item => {
                let cleanedItem = item.replace(/\\n/g, " ");
                let cleanerItem = cleanedItem.replace(/\\n/g, " ");
                return cleanerItem;
                // return item.replace(/\\n/g, ' ');
            });
            let i;
            if (this.index) {
                i = this.index;
            } else {
                i = index;
            }

            this.$emit("fetchingSection", [index, true, `Done!`]);

            setTimeout(() => {
                this.$emit("fetchingSection", [index, false, null]);
                if (!this.isCode(newResponses)) {
                    textArray.forEach((substring, index) => {
                        setTimeout(() => {
                            let text = substring.replace("\n", " ");
                            text = text.replace("\n", "");
                            let kind = "text";
                            let tag = "p";
                            if (text.charAt(0) === " ") {
                                text = text.substr(1); // remove the first character
                            }
                            if (text.includes("**")) {
                                text = text.replace(/(?<!<[^>]+)\*\*(?![^<]*>)(.*?)\*\*(?![^<]*>)/g, "<b>$1</b>");
                            }
                            if (text.includes("*")) {
                                text = text.replace(/(?<!<[^>]+)\*(?![^<]*>)(.*?)\*(?![^<]*>)/g, "<i>$1</i>");
                            }
                            if (text.startsWith("-") || text.startsWith("*") || text.startsWith("- ")) {
                                text = text.replace("- ", "").replace("-", "").replace("*", "").trim();
                                kind = "list";
                                tag = "li";
                                // this.$store.commit('addSection', {index: i, text: text, type: 'bullet'});
                            } else if (text.match(/^[0-9]+\./)) {
                                text = text.replace(/[0-9]+\./, "").trim();
                                kind = "numberedList";
                                tag = "li";
                            }
                            // if a portion of the text is surrounded by ** or ***, make it bold with <b> tags use regex to ensure you ignore the string between the ** or ***
                            if (text.includes("**") || text.includes("***")) {
                                text = text.replace("**", "<b>").replace("***", "</b>");
                            }
                            if (text.includes("-")) {
                                text = text.replace("-", "<b>").replace("-", "</b>");
                            }
                            if (text.includes("*")) {
                                text = text.replace("*", "<b>").replace("*", "</b>");
                            }

                            if (text.includes("**")) {

                            }
                            let s = {
                                content: text,
                                index: i,
                                cursorPosition: null,
                                kind: kind,
                                tag: tag,
                            };
                            if (text.length > 1 && !text.includes("\n")) {
                                this.newSection(s);
                            }
                            i += 1;
                            this.saveThisDoc(this.document);
                        }, 200 * index); // delay by one second per index
                    });
                } else {
                    let s = {
                        content: newResponses,
                        index: i,
                        cursorPosition: null,
                        kind: "div",
                        tag: "div",
                    };
                    this.newSection(s);
                    this.saveThisDoc(this.document);
                }
            }, 200);

            // this.$emit('newSection', [this.index, response]);
            // this.newSection([this.index, response, null, 'text']);
        },
        saveThisDoc() {
            let d = toRaw(this.document);
            console.error(d);
            d.title = d.sections[0].content;
            d.summary = d.sections[1].content;
            // d.id = this.$route.params.id;
            d.documentType = "textEditor";
            this.saveDoc(d);
        },
        newSection(s) {
            //s is section.
            console.table(s);
            let index = s.index;
            let text = s.content;
            let kind = s.kind;
            let prompt = s.prompt;
            let tag = s.tag;
            let cursorPosition = s.cursorPosition;
            console.error("making new section", kind);
            console.error(index, text, cursorPosition, kind, prompt);
            console.error("newSection args", index, text, cursorPosition, kind, prompt);
            if (!tag) {
                tag = "p";
            }
            let payload = {
                content: text,
                tag: tag,
                favorite: false,
                id: "section-" + this.randomId(),
                index: index,
                kind: kind || "text",
            };
            console.error(kind);
            if (kind === "newlineList") {
                console.error("making new list");
                payload.index = index;
                payload.tag = "li";
                payload.kind = "text";
                payload.content = text;
                if (tag) {
                    payload.tag = tag;
                }
                this.addSectionBelow(index, payload);
                return;
            } else if (kind === "newline") {
                console.log("newSection: newline");
                payload.tag = "p";
                if (tag) {
                    payload.tag = tag;
                }
                payload.kind = "text";
                payload.content = text;
                this.addSectionBelow(index, payload);
                return;
            } else if (kind === "list") {
                console.log("newSection: list");
                payload.tag = "li";
                payload.kind = "text";
                payload.content = text;
                if (tag) {
                    payload.tag = tag;
                }
                this.addSectionBelow(index, payload);
                return;
            } else if (kind === "numberedList") {
                console.log("newSection: numberedList");
                payload.tag = "li";
                payload.kind = "numberedList";
                if (tag) {
                    payload.tag = tag;
                }
                payload.content = text;
                this.addSectionBelow(index, payload);
            } else if (kind) {
                if (kind === "image") {
                    console.log("newSection: image");
                    payload.content = text;
                    payload.tag = "img";
                    payload.kind = "image";
                    payload.image = true;
                    payload.prompt = prompt;
                    this.addSectionBelow(index, payload);
                    return;
                } else if (kind === "versions") {
                    console.log("newSection: versions");
                    payload.content = "";
                    payload.versions = [];
                    payload.tag = "div";
                    this.addSectionBelow(index, payload);
                    return;
                } else if (kind === "list") {
                    console.log("newSection: list");
                    payload.content = text;
                    payload.tag = "li";
                    payload.kind = "text";
                    this.addSectionBelow(index, payload);
                    return;
                } else if (kind === "numberedList") {
                    payload.content = text;
                    payload.tag = "li";
                    console.error("add # list");
                    this.addSectionBelow(index, payload);
                    return;
                } else {
                    console.log("newSection: other");
                    payload.content = text;
                    payload.kind = "text";
                    payload.tag = "p";
                    if (tag) {
                        payload.tag = tag;
                    }
                    console.error("splice text");
                    this.addSectionBelow(index, payload);
                    return;
                }
            } else {
                console.log("newSection: other 2");
                payload.content = text || " ";
                payload.kind = "text";
                payload.tag = "p";
                if (tag) {
                    payload.tag = tag;
                }
                payload.index = index;
                console.error("section at end");
                this.addSectionBelow(index, payload);
                // this.addSectionAtEnd(index, payload);
                return;
            }
        },
        previousSection() {
            const sectionIndex = this.sectionIndex - 1;
            this.setSectionIndex(sectionIndex);
        },
        nextSection() {
            const sectionIndex = this.sectionIndex + 1;
            this.setSectionIndex(sectionIndex);
        },
        updatePreviousSection([index, text]) {
            console.error("update previous section");
            const newIndex = index - 1;
            const previousText = this.sections[newIndex].content;
            let appendText = previousText + " " + text;
            this.document.sections[newIndex].content = appendText;
            // this.removeSection(index);
        },
        callRemoveSection(previousCursorLength) {
            console.error("callRemoveSection", "previousCursorLength", previousCursorLength);
            const sectionToRemoveIndex = this.sectionIndex;
            this.$emit("removeSection", sectionToRemoveIndex, previousCursorLength);
        },
        discardSection() {
            this.$emit("removeSection", this.sectionIndex);
        },
        createDocument() {
            let doc = {
                title: "New document",
                id: null,
                documentType: "textEditor",
                sections: [
                    {
                        content: "New document",
                        tag: "h1",
                        kind: "text",
                        index: 0,
                        favorite: false,
                        id: this.randomId(),
                    },
                    {
                        content: "What do you want to do?",
                        kind: "text",
                        tag: "p",
                        index: 1,
                        favorite: false,
                        id: this.randomId(),
                    },
                ],
            };
            this.$emit("createDocument", doc);
        },
        //store methods
        addSectionBelow(index, payload) {
            console.error("index", index);
            console.error("payload", payload);
            console.error("section", toRaw(this.section));
            console.error("payload in addSectionBelow", payload);
            console.error("New section: Below");
            console.error("trying to add");
            // if payload.content starts with " " replace it with ""
            if (payload.content.startsWith(" ")) {
                payload.content = payload.content.replace(" ", "");
                console.error("replaced space");
                console.error(payload.content);
            }
            this.$store.commit("addSectionBelow", payload);
        },
        addSectionAbove(index, payload) {
            let section = payload;
            const indexAbove = index - 1;
            console.error("New section: above");
            console.error(index, section);
            this.$store.commit("addSectionAbove", payload);
            this.setSectionIndex(indexAbove);
        },
        addSectionAtEnd(index, payload) {
            let section = payload;
            const indexBelow = this.document.sections.length + 1;
            section.index = indexBelow;
            console.error("New section: end");
            this.$store.commit("addSectionAtEnd", payload);
        },
        // async saveDocument(collection, item, array) {
        //     this.saving = true;
        //     let saved = await this.addToFirebase(collection, item);
        //     this.saving = false;
        //     this.addAlert({
        //         type: 'success',
        //         message: 'Saving your doc',
        //     });
        //     if (array) {
        //         this.$nextTick(() => {
        //             this.$forceUpdate();
        //             // array.push(item)
        //         });
        //     }
        //     return saved;
        //     // this.updateObject('genre', ['id', this.selectedGenre.id], this.selectedGenre);
        // },
    },
};
