<template>
    <div>
        <div :class="wrapperClass" class="text-area button1 mx-auto br-10 mb-5 border border-gray-500 shadow-lg overflow-hidden br-20 group duration-2" @mouseover="expand()" @mouseleave="collapse()">
            <!--        <label v-if="label" :for="id" class="label">{{ label }}</label>-->
            <div class="textarea p-0 br-20" :class="midWrapperClass" style="border: 0px solid transparent">
                <textarea
                    :id="id"
                    ref="txtArea"
                    v-model="internalValue"
                    :class="[{ disabled: disabled }, ` mb-0 !f-24 outline-0 stroke-0 `]"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :rows="rows"
                    :style="inputHeight"
                    class="border-none br-20 bg-transparent width-min-100 active:!outline-0 ease-in-out focus:!outline-0 !outline-transparent !shadow-transparent !ring-0 overflow-y-scroll overflow-x-hidden scrollbar-hide p-4"
                    style="height: 50px; min-width: 100%"
                    @blur="$emit('blur', $event)"
                    @change="$emit('change', $event)"
                    @focus="$emit('focus', $event)"
                    @input="$emit('input', $event)"
                    v-on="$attrs"
                    @keydown.enter.prevent="onEnter"></textarea>
                <div ref="slotContainer" :style="slotHeight" class="transition-all ease-elastic duration-1">
                    <slot></slot>
                </div>
                <!--                <div class="p-absolute top right p-2 border-light br-10">-->
                <!--                {{ slotHeight }}-->
                <!--                {{ inputHeight }}-->
                <!--                    {{ hovered }}-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: { type: String, default: "" },
        label: { type: String, default: "" },
        modelValue: { type: String, default: "" },
        iclass: { type: String, default: "" },
        rows: { type: Number, default: 3 },
        disabled: { type: Boolean, default: false },
        placeholder: { type: String, default: "" },
    },
    emits: ["update:modelValue", "enter", "blur", "focus", "input", "change"],
    data() {
        return {
            dynamicContainerHeight: 100,
            dynamicHeight: 0,
            dynamicTextAreaHeight: 0,
            textAreaExpanded: "auto",
            textAreaCollapsed: 35,
            containerCollapsedHeight: 45,
            containerExpandedHeight: 300,
            expandedHeight: 150,
            collapsedHeight: 100,
            closedSlot: 0,
            hovered: false,
        };
    },
    computed: {
        midWrapperClass() {
            let animation = `transition-all duration-1 ease-in-out overflow-hidden`;
            return `opacity-0 hover:opacity-100 border-none bg-none border-0 ${animation}`;
        },
        wrapperClass() {
            // let animation = "transition-all elastic-easing duration-1 ease-back ";
            let radius = "br-20";
            if (!this.hovered) {
                radius = `rounded-full`;
            }
            // transition-height transition-colors elastic-easing
            let animation = " transition-bg elastic-easing duration-1 brand-bg transition-all  ";
            let colors = `bg-green-800 hover:!w-full text-snow hover:bg-snow hover:text-gray-900 `;
            let hover = ` w-[100px] h-[100px] hover:h-100 width-min-100 hover:w-full `;
            return `text-area mx-auto f group hover:shadow-high !shadow-black/5 ${hover} ${colors} ${animation} ${radius}`;
        },
        slotHeight() {
            return `height:${this.dynamicHeight}px`;
        },
        inputHeight() {
            return `min-height: ${this.dynamicContainerHeight}px`;
        },
        internalValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    },
    watch: {
        modelValue: {
            immediate: true,
            handler() {
                this.calculateExpandedHeight();
            },
        },
    },
    mounted() {
        this.calculateExpandedHeight(); // Calculate initial expanded height
    },
    updated() {
        this.calculateExpandedHeight(); // Calculate initial expanded height
    },
    methods: {
        calculateExpandedHeight() {
            const slotContainer = this.$refs.slotContainer;
            if (slotContainer) {
                let totalHeight = this.closedSlot;
                for (const child of slotContainer.children) totalHeight += child.clientHeight;
                this.expandedHeight = totalHeight;
                if (this.hovered) this.dynamicContainerHeight = 150;
            }
        },
        expand() {
            this.hovered = true;
            this.$refs.txtArea.focus();
            this.calculateExpandedHeight();
            this.dynamicHeight = this.expandedHeight;
            this.dynamicContainerHeight = this.expandedHeight;
        },
        collapse() {
            this.hovered = false;
            this.dynamicHeight = this.collapsedHeight;
            this.calculateExpandedHeight();
            this.dynamicContainerHeight = this.collapsedHeight;
            this.dynamicHeight = this.closedSlot;
        },
        onEnter() {
            this.$emit("enter");
        },
    },
};
</script>

<style lang="scss" scoped>
textarea {
    width: 100%;
    margin-bottom: 0px;
    border: none;
}

.text-area {
    @apply ease-elastic;
    @apply scale-50;
    @apply transition-height;
    &:hover {
        //background-color: white;
        width: 100%;
        transition-property: all;
        transition-duration: 1s;
        @apply scale-100;
        //transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
        transition-delay: 0.1s;
        @apply ease-elastic;
    }
}
</style>
