<template>
    <div style="min-width: 275px; max-width: 275px; width: 275px; position: fixed; top: 0px; bottom: 0px" class="p-relative x f fc vh-100 sticky-top" v-if="document && document.sections">
        <div :style="`transition:200ms background ease-in;background:${colorDarker};`" class="x f fc scrollSection flex-basis h-auto flex-grow gap-3 bg-gray-100 px-3 pl-5 pt-8 scrollbar-hide">
            <h3 class="line-height-smaller text-base-50">Slides</h3>

            <draggable v-model="$store.state.presentation.document.sections" enter-active-class="animated fadeInUpSmooth" leave-active-class="animated fadeOutDown" group="sections" item-key="'a'+index" @end="drag = false" @start="drag = true">
                <!--&lt;!&ndash;                 &#45;&#45; tag="transition-group">-->
                <template #item="{ element, index }" :key="'slide' + index">
                    <div :key="`slidea+${index}`" :style="animatedList(index)" class="animated fadeInUpSmooth" @click.prevent="clickedSlide(i)">
                        <div :style="`background:${backgroundColor};`" class="p-relative transition:background br-10 br-5 mb-3 scale-75 cursor-pointer shadow hover:scale-75 hover:bg-snow">
                            <PresentationMiniSlide :index="index" :slide="element"></PresentationMiniSlide>
                        </div>
                    </div>
                </template>
            </draggable>

            <div class="x mx-auto text-center">
                <ButtonIconWhite class="fas f-20 align-self-center mx-auto cursor-pointer" icon="plus" @click.prevent="addNewSlide(slideCount)"></ButtonIconWhite>
            </div>
        </div>
        <div :style="`box-shadow:0px -5px 2px rgba(0,0,0,0.05);transition:200ms background ease-in;background:${colorDarker};`" class="x bg-gray-100 p-3 shadow-lg">
            <StartDeckButton class="btn btn-secondary x d-block" text="New Deck" @start-new-deck="startNewDeck"></StartDeckButton>
        </div>
    </div>
</template>
<script setup>
import lodash from "lodash";
</script>

<script>
import { collection, doc, Timestamp } from "firebase/firestore";
import draggable from "vuedraggable";
import { toRaw } from "vue";
import ButtonIconWhite from "@/components/CoreUI/buttons/buttonIconWhite.vue";
import PresentationMiniSlide from "@/components/Presentations/slides/SlideContainerMini.vue";
import StartDeckButton from "@/components/Presentations/StartDeckButton.vue";
import presenationMixin from "@/mixins/presentations/presentationMixin";
import themeMixins from "@/mixins/StyleMixins/themeMixins";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import { mapGetters } from "vuex";
export default {
    components: { StartDeckButton, ButtonIconWhite, PresentationMiniSlide, draggable },
    mixins: [presenationMixin, themeMixins, documentManagementMixin],
    data() {
        return {
            showPlaintext: false,
            documents: [],
            drag: false,
        };
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        slideCount() {
            if (this.document?.sections?.length) return this.document.sections.length;
            else {
                return 1;
            }
        },
    },
    methods: {
        clickedSlide(index) {
            // this.setSectionIndex(index, scroll);
            // this.$emit("scrollToSlide", index);
        },
        startNewDeck() {
            this.$emit("startNewDeck");
        },
        addNewSlide(index) {
            this.addSlide({
                title: "New Slide",
                body: "Fill in this slide",
                style: "left",
                createdAt: Timestamp.now(),
                updatedAt: Timestamp.now(),
            });
        },
    },
};
</script>
