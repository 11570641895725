import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import { useStore } from "vuex";

async function getModels() {
    const store = useStore(); // Get the store instance
    // Check if models exist in local storage & are not expired
    const storedModels = localStorage.getItem("models");
    const modelsExpiry = localStorage.getItem("modelsExpiry");
    const currentDate = new Date().getTime();

    if (!storedModels || !modelsExpiry || currentDate > modelsExpiry) {
        // Fetch models if not exist or expired
        let request_url = endpointURL("models/list");
        const r = await fetch(request_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        });
        if (r.ok) {
            let textModels = await r.json();
            // console.log(textModels);
            store.commit("chat/setModels", textModels || []); // Set models in Vuex store
            // Save models to local storage & new expiry date
            localStorage.setItem("models", JSON.stringify(textModels));
            const expirationDate = currentDate + 3 * 24 * 60 * 60 * 1000; // 3 days in the future
            localStorage.setItem("modelsExpiry", expirationDate);

            // console.log(textModels);
            return textModels;
        }
        return;
    } else {
        // Models exist in local storage & not expired
        let textModels = JSON.parse(storedModels);
        store.commit("chat/setModels", textModels); // Set models in Vuex store

        return textModels;
    }
}
export default getModels;
