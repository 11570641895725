<template>
    <div class="mx-auto max-w-2xl px-4 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8 lg:pt-16">
        <div class="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 v-if="product?.title" class="f-70 text-balance">{{ product.title }}</h1>
        </div>

        <!-- Options -->
        <div class="mt-4 lg:row-span-3 lg:mt-0">
            <p v-if="product?.price" class="text-3xl tracking-tight text-gray-900">${{ product.price }}</p>

            <!-- Reviews -->
            <div v-if="product?.reviews" class="mt-6">
                <div class="flex items-center">
                    <div class="flex items-center">
                        <StarIcon v-for="rating in [0, 1, 2, 3, 4]" :key="rating" :class="[reviews.average > rating ? 'text-gray-900' : 'text-gray-200', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
                    </div>
                    <!--                            {{ product.reviews }}-->
                    <ProductReviews :reviews="product.reviews"></ProductReviews>
                    <a v-if="product?.reviews?.reviewCount" class="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">{{ product.reviews.reviewCount }} reviews</a>
                </div>
            </div>

            <form class="mt-10">
                <button class="button-1 x" type="submit">Add to bag</button>
            </form>
        </div>

        <div class="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            <!-- Description and details -->
            <div>
                <h3 class="sr-only">Description</h3>

                <div class="space-y-6">
                    <p v-if="product?.description" class="text-lg text-balance text-gray-900">{{ product.description }}</p>
                </div>
            </div>

            <div v-if="product?.highlights" class="mt-10">
                <h3 class="text-sm font-medium text-gray-900">Highlights</h3>

                <div class="mt-4">
                    <ul class="list-disc space-y-2 pl-4 text-sm" role="list">
                        <li v-for="highlight in product.highlights" :key="highlight" class="text-gray-400">
                            <span class="text-gray-600">{{ highlight }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductReviews from "@/views/ProductReviews.vue";

export default {
    name: "ProductDetails",
    components: { ProductReviews },
    props: {
        product: {},
        reviews: {},
    },
};
</script>
