<template>
    <PreviewWrapper v-if="sections" :data="campaignData" class="bg-gray-50 p-absolute p-fill" menu-title="Campaign">
        <template #side>
            <CampaignHeader v-if="campaign" :result="campaign" />
            <CampaignScheduleItem v-if="campaignSiblings" :id="id" :schedule-items="campaignSiblings" @trigger-generation="triggerGeneration" />
        </template>
        <div>
            <div class="mw-500 mx-auto x border-light z-0 relative">
                <!--                <NewEmailSection v-for="(section, i) in sections" :id="id" :key="`section${i}`" :index="i" :result="section" :sections="sections" />-->
                <EmailSections v-if="sections" :campaign-item="editorItem" :footer="campaign.email_footer" :id="id" :identifier="identifier" :sections="sections" />

                <EmailFooterSection v-if="sections?.length > 0" :id="id" :footer="campaign.email_footer" />
            </div>
        </div>
    </PreviewWrapper>
</template>
<script>
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import EmailFooterSection from "@/components/generative_templates/Email/EmailFooterSection.vue";
import NewEmailSection from "@/components/generative_templates/Email/NewEmailSection.vue";
import PreviewWrapper from "@/components/Admin/CampaignPreviewWrapper.vue";
import CampaignScheduleItem from "@/components/generative_templates/Campaigns/CampaignScheduleItem.vue";
import { mapGetters } from "vuex";
import EmailSections from "@/components/Admin/EmailSections.vue";

export default {
    name: "EmailCampaignPreview",
    mixins: [],
    components: { EmailSections, CampaignScheduleItem, PreviewWrapper, CampaignHeader, EmailFooterSection, NewEmailSection },
    computed: {
        ...mapGetters("stream", ["result", "campaignData", "showCampaign", "campaigns", "parentCampaign", "campaignSiblings"]),
    },
    watch: {
        campaignData: {
            handler(val) {
                // this.$emit("trigger-generation");
                if (val.result) {
                    this.updateStreamProp("result", val.result);
                    // this.updateStreamProp("editor", val.campaignData)
                }
            },
            deep: true,
        },
    },
    props: {
        id: {
            type: String,
        },
        campaign: {
            type: Object,
            default: null,
        },
        sections: {
            type: Array,
            default: null,
        },
    },
};
</script>
