<template>
    <div class="mb-md-5 mb-3">
        <LocalResults title="" :slider="true" :places="{ places: products }"></LocalResults>
    </div>
</template>
<script>
import PlacesCard from "@/components/chat/specialMessages/PlacesCard.vue";
import AnimatedText from "@/components/CoreUI/animatedText.vue";
import Place from "@/components/chat/specialMessages/Search/Place.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import LocalResults from "@/components/chat/specialMessages/Search/LocalResults.vue";

export default {
    name: "ProductResults",
    components: { LocalResults, Place, AnimatedText, PlacesCard, Swiper, SwiperSlide },
    props: {
        products: {
            type: Array,
            required: false,
        },
        title: {
            type: String,
            required: true,
            default: "Recommended places",
        },
    },
};
</script>
<style lang="scss">
.place_card{
  @apply border;
  @apply rounded-xl;
  @apply overflow-hidden;
  @apply bg-snow;
  @apply shadow;
  @apply dark:bg-base-700;
  @apply w-full;
  @apply width-max-200;
  //mw-250 x br-10 overflow-hidden bg-white shadow
}

.map-view{
  @apply transition-all;
  @apply object-cover;
  @apply aspect-h-5;
  @apply aspect-w-16;

  &:hover{
    @apply transition-all;
    @apply aspect-h-2;
    @apply aspect-w-4;
  }
}

hr{
  @apply my-5;
  // style the hr
  border-top: 1px solid;
  @apply border-t border-gray-100;
  @apply dark:border-base-500;
}
</style>
