<template>
    <transition appear enter-active-class="animated zoomIn ease-back duration-1" leave-active-class="fadeOutDown ">
        <div class="hover:bg-gray-50 hover:border-light hover:shadow" :class="[`d-${delay}`,{ 'f z-5 height-50 width-50 br-50 aic jcc bg-snow': !mobile && !small, 'f z-5 height-30 width-30 br-50 aic jcc bg-snow': small || mobile }]">
            <i v-if="!stateBool" aria-hidden="true" class="fal" :class="`fa-${icon}`"></i>
            <i v-else aria-hidden="true" class="fal fa-times f-24"></i>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
        delay: {
            type: Number,
            default: 0,
        },
        small: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: "plus",
        },
        stateBool: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
};
</script>
<style lang="scss"></style>
