<template>
    <div>
        <!--        <ProductPageHeader></ProductPageHeader>-->
        <main v-if="product" class="pt-10 sm:pt-16 x">
            <ProduceImageGrid v-if="product.images" :images="product.images" />
            <ProductDetails :product="product" :reviews="reviews" />
        </main>
        <!--        <ProductFooter />-->
    </div>
</template>

<script>
import ProduceImageGrid from "@/views/ProduceImageGrid.vue";
import ProductCarousel from "@/views/ProductCarousel.vue";
import ProductDetails from "@/views/ProductDetails.vue";

const currencies = ["CAD", "USD", "AUD", "EUR", "GBP"];

export default {
    components: {
        ProductDetails,
        ProductCarousel,
        ProduceImageGrid,

        // ProductFooter,
    },
    data() {
        return {
            currencies: ["CAD", "USD", "AUD", "EUR", "GBP"],
            navigation: {
                categories: [
                    {
                        name: "Women",
                        featured: [
                            { name: "Sleep", href: "#" },
                            { name: "Swimwear", href: "#" },
                            { name: "Underwear", href: "#" },
                        ],
                        collection: [
                            { name: "Everything", href: "#" },
                            { name: "Core", href: "#" },
                            { name: "New Arrivals", href: "#" },
                            { name: "Sale", href: "#" },
                        ],
                        categories: [
                            { name: "Basic Tees", href: "#" },
                            { name: "Artwork Tees", href: "#" },
                            { name: "Bottoms", href: "#" },
                            { name: "Underwear", href: "#" },
                            { name: "Accessories", href: "#" },
                        ],
                        brands: [
                            { name: "Full Nelson", href: "#" },
                            { name: "My Way", href: "#" },
                            { name: "Re-Arranged", href: "#" },
                            { name: "Counterfeit", href: "#" },
                            { name: "Significant Other", href: "#" },
                        ],
                    },
                    {
                        name: "Men",
                        featured: [
                            { name: "Casual", href: "#" },
                            { name: "Boxers", href: "#" },
                            { name: "Outdoor", href: "#" },
                        ],
                        collection: [
                            { name: "Everything", href: "#" },
                            { name: "Core", href: "#" },
                            { name: "New Arrivals", href: "#" },
                            { name: "Sale", href: "#" },
                        ],
                        categories: [
                            { name: "Artwork Tees", href: "#" },
                            { name: "Pants", href: "#" },
                            { name: "Accessories", href: "#" },
                            { name: "Boxers", href: "#" },
                            { name: "Basic Tees", href: "#" },
                        ],
                        brands: [
                            { name: "Significant Other", href: "#" },
                            { name: "My Way", href: "#" },
                            { name: "Counterfeit", href: "#" },
                            { name: "Re-Arranged", href: "#" },
                            { name: "Full Nelson", href: "#" },
                        ],
                    },
                ],
                pages: [
                    { name: "Company", href: "#" },
                    { name: "Stores", href: "#" },
                ],
            },
            reviews: {
                href: "#",
                average: 4,
                totalCount: 117,
                featured: [
                    {
                        id: 1,
                        title: "This is the best white t-shirt out there",
                        rating: 5,
                        content: `
        <p>I've searched my entire life for a t-shirt that reflects every color in the visible spectrum. Scientists said it couldn't be done, but when I look at this shirt, I see white light bouncing right back into my eyes. Incredible!</p>
      `,
                        author: "Mark Edwards",
                        avatarSrc: "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixqx=oilqXxSqey&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    },
                    {
                        id: 2,
                        title: "Adds the perfect variety to my wardrobe",
                        rating: 4,
                        content: `
        <p>I used to be one of those unbearable minimalists who only wore the same black v-necks every day. Now, I have expanded my wardrobe with three new crewneck options! Leaving off one star only because I wish the heather gray was more gray.</p>
      `,
                        author: "Blake Reid",
                        avatarSrc: "https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80",
                    },
                    {
                        id: 3,
                        title: "All good things come in 6-Packs",
                        rating: 5,
                        content: `
        <p>Tasty beverages, strong abs that will never be seen due to aforementioned tasty beverages, and these Basic Tees!</p>
      `,
                        author: "Ben Russel",
                        avatarSrc: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    },
                ],
            },
            footerNavigation: {
                account: [
                    { name: "Manage Account", href: "#" },
                    { name: "Saved Items", href: "#" },
                    { name: "Orders", href: "#" },
                    { name: "Redeem Gift card", href: "#" },
                ],
                service: [
                    { name: "Shipping & Returns", href: "#" },
                    { name: "Warranty", href: "#" },
                    { name: "FAQ", href: "#" },
                    { name: "Find a store", href: "#" },
                    { name: "Get in touch", href: "#" },
                ],
                company: [
                    { name: "Who we are", href: "#" },
                    { name: "Press", href: "#" },
                    { name: "Careers", href: "#" },
                    { name: "Terms & Conditions", href: "#" },
                    { name: "Privacy", href: "#" },
                ],
                connect: [
                    { name: "Facebook", href: "#" },
                    { name: "Instagram", href: "#" },
                    { name: "Pinterest", href: "#" },
                ],
            },
            open: false,
            selectedColor: null,
            selectedSize: null,
        };
    },
    computed: {
        products() {
            return this.$store.getters["styleGuide/products"];
        },
        product() {
            if (!this.products || this.products.length === 0) return null;
            return this.products[3];
        },
    },
    mounted() {
        // this.selectedColor = this.product.colors[0];
        // this.selectedSize = this.product.sizes.find(size => size.inStock);
    },
};
</script>

<style scoped>
/* Your styles here */
</style>
