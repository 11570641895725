<!--<script setup>-->
<!--import { ref } from "vue";-->
<!--</script>-->
<script>
// import contextCursor from "@/mixins/contextCursor";
import SiteHeader from "@/components/CoreUI/navigaion/SiteHeader.vue";
// import "@/mixins/contextCursor";
import usermixin from "@/mixins/usermixin";
import Notification from "@/components/Imported/tailwind/Notification.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    mixins: [usermixin],
    components: {
        Notification,
        SiteHeader,
    },
    data() {
        return {
            configuration: null,
        };
    },
    beforeUnmount() {
        if (typeof window === "undefined") return;
    },
    async mounted() {
        this.setDarkMode();
        // contextCursor();

        this.$nextTick(() => {
            // this.configuration = this.$store.state.configuration;
        });
    },
    async created() {
        const models = this.$store.state.image.stability_options.engines || [];
        // await this.getModels();
        await this.fetchUser();
        await this.getOpenAIModels();
        // await this.setDefaultEngineModel(models);
        if (!this.$route?.path?.includes("auth")) {
            await this.$store.dispatch("styleGuide/getCategories", { user: this.user });
        }
    },
    methods: {
        ...mapActions("image", ["setDefaultEngineModel"]),
        ...mapActions(["getOpenAIModels"]),
        setDarkMode() {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                this.$store.commit("setDarkMode", true);
            } else {
                this.$store.commit("setDarkMode", false);
            }
        },
    },
};
</script>
<template>
    <div class="p-relative">
        <SiteHeader class="z-4 fixed" :key="$route" v-if="!hideHeader"></SiteHeader>
        <main class="mt-nav" v-cloak>
            <RouterView></RouterView>
        </main>
        <Notification></Notification>
    </div>
</template>
<style lang="scss">
//@import "../public/assets/main.scss";
</style>
