<template>
    <div class="text-center f fc aic jcc">
        <h6 class="d-inline my-3 x">
            <span v-if="followUpTypeInstructions && followUpTopicsType" class="animated flex-shrink swingInUp block whitespace-nowrap duration-5">{{ followUpTypeInstructions }}</span>
        </h6>

        <div class="f flex-wrap gap-2 px-3 jcc w-auto">
            <template v-for="(suggestion, index) in followUpTopicsArray">
                <Button
                    :style-type="`${multipleSelectArray.includes(suggestion) ? 'primary' : 'secondary'}`"
                    :icon="`${multipleSelectArray.includes(suggestion) ? 'fas fa-check' : ''}`"
                    class="{{ 'bg-base-500': multipleSelectArray.includes(suggestion) }} swingInUp animated"
                    reverse
                    :class="`d-${index + 2}`"
                    @click.prevent="clickedBubble(suggestion)"
                    size="xs"
                    rounded
                    v-if="!responsesToIgnore(suggestion)"
                    :label="suggestion.trim()" />
            </template>
            <Button style-type="primary" v-if="followUpType === 'multi_select'" size="sm" :class="`animated swingInUp d-${followUpTopicsArray.length + 2}`" class="button-purple" @click.prevent="sendMultipleChoice" rounded :confirm-button="true" label="Done" />
        </div>
        <!--        <div class="mx-auto x">-->
        <follow-up-topic-stream :messages="messages" @add-to-suggestions="addToSuggestions" @pick-suggestion="pickSuggestion" @add-suggestions="addSuggestions" :prompt-text="messageForQuestions.content"></follow-up-topic-stream>
        <!--        </div>-->
    </div>
</template>

<script>
import ChatToggleButton from "@/components/CoreUI/ChatToggleButton.vue";
import Button from "@/components/CoreUI/BaseButton.vue";
import JSON5 from "json5";
import { db } from "@/firebase";
import FollowUpTopicStream from "@/components/chat/FollowUpTopicStream.vue";
import { mapActions, mapGetters } from "vuex";
import newChatMixins from "@/mixins/Chat/newChatMixins";
export default {
    components: { FollowUpTopicStream, Button, ChatToggleButton },
    mixins: [newChatMixins],
    data() {
        return {
            followUpTopicsArray: [],
            followUpTopicsType: null,
            multipleSelectArray: [],
            showFollowUpTopics: false,
        };
    },
    mounted() {
        this.followUpTopicsType = this.messageForQuestions.followUpTopicsType;
        this.followUpTopicsArray = this.messageForQuestions.followUpTopics;
        this.showFollowUpTopics = true;
    },
    props: {
        messages: {
            type: Array,
            required: true,
        },
        message: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters("chat", ["lastMessage"]),
        chat() {
            return this.$store.state.chat;
        },
        messageForQuestions() {
            if (this.message && this.message.followUpTopics) {
                return this.message;
            } else {
                return this.lastMessage;
            }
        },
        fastBubbles() {
            return this.$store.state.chat.fastBubbles;
        },
        followUpTypeInstructions() {
            if (this.followUpType === "multi_select") {
                return "Select one or more";
            } else {
                return "Select one";
            }
        },
        followUpType() {
            let type = this.lastMessage.followUpTopicsType || "single_select";
            let evalString = type.toLowerCase();
            const singleSelectStrings = ["single_select", "single_select", "single_select", "single select", "single-select", "single-Select", "single-select"];
            const multiSelectStrings = ["multi_select", "multi_select", "multi_select", "multi select", "multi-select", "Multi-Select", "multi-select", "Multi Select", "multiple choice"];
            // types is concat of both arrays
            let types = [].concat(singleSelectStrings, multiSelectStrings);
            // see if string contains any of the types then return either "single_select" or "multi_select"
            if (singleSelectStrings.includes(evalString)) {
                return "single_select";
            } else if (multiSelectStrings.includes(evalString)) {
                return "multi_select";
            }
            return null;
        },
    },
    methods: {
        ...mapActions("chat", ["setMessages"]),
        clickedBubble(item) {
            if (this.lastMessage.followUpTopicsType === "multi_select") {
                this.toggleMultipleSelect(item);
            } else {
                this.askQuestion(item);
            }
        },
        addToSuggestions(suggestion) {
            try {
                this.followUpTopicsType = null;
                this.followUpTopicsArray.push(suggestion);
            } catch (e) {
                console.log("error during handling", e);
            }
        },
        addSuggestions(suggestions, type) {
            this.saveFollowUpTopics(suggestions, type);
        },
        toggleMultipleSelect(response) {
            console.error(response);
            if (this.multipleSelectArray.includes(response)) {
                this.removeFromMultipleSelect(response);
            } else {
                this.addToMultipleSelect(response);
            }
        },
        addToMultipleSelect(response) {
            console.error(response);
            this.multipleSelectArray.push(response);
            // this.multipleSelectArray = [...new Set(this.multipleSelectArray)];
            console.error(this.multipleSelectArray);
        },
        removeFromMultipleSelect(response) {
            console.error(response);
            this.multipleSelectArray = this.multipleSelectArray.filter(item => item !== response);
            console.error(this.multipleSelectArray);
        },
        async sendMultipleChoice() {
            let message = this.multipleSelectArray.join(", ");
            this.$emit("sendMultipleChoice", this.multipleSelectArray);
            this.followUpTopicsArray = [];
            this.multipleSelectArray = [];
            this.followUpQuestionsArray = [];
            this.followUpTopicsType = null;
            // save this.questions to firestore
        },
        askQuestion(suggestion) {
            this.$emit("askQuestion", suggestion);
        },
        pickSuggestion(suggestion) {
            try {
                this.$emit("askQuestion", suggestion);
            } catch (e) {
                console.log("error during handling", e);
            }
        },
        async saveFollowUpTopics(topics, type) {

            let chatId = this.$route.params.id;
            const messages = this.messages;
            let lastMessageIndex = messages.length - 1;
            if (!type) {
                type = "single_select";
                this.lastMessage.followUpTopicsType = "single_select";
                this.followUpTopicsType = "single_select";
                messages[lastMessageIndex].followUpTopicsType = "single_select";
            } else {
                this.lastMessage.followUpTopicsType = type;
                this.followUpTopicsType = type;
                messages[lastMessageIndex].followUpTopicsType = type;
            }
            if (!this.followUpTopicsArray || this.followUpTopicsArray.length === 0) {
                this.followUpTopicsArray = topics;
            }
            messages[lastMessageIndex].followUpTopics = topics;
            let nonEmptyMessages = messages.filter(message => message.content !== "");
            console.groupCollapsed("Save Follow-up Topics");
            console.log("Save follow up topics", topics, type);
            console.groupEnd();
            await db.collection("chats").doc(chatId).update({
                messages: nonEmptyMessages,
            });
            this.setMessages(messages);
        },
        getLastMessagesString(messages, messageCount) {
            let lastMessageIndex = messages.length - 1;
            let result = "";

            for (let i = Math.max(0, lastMessageIndex - (messageCount - 1)); i <= lastMessageIndex; i++) {
                if (messages[i]) {
                    let content = i === lastMessageIndex ? messages[i].content : messages[i].content.slice(0, 1000);

                    result = result.concat(`${messages[i].role}: ${content}\n`);
                }
            }

            return result;
        },
        buildPrompt(message) {
            let format = {
                type: "<'multiple choice', 'single_select', or 'finish_survey'>",
                suggestions: ["suggestedFollowup1", "suggestedFollowup2", "suggestedFollowup3", "etc"],
            };
            let formattedExample = JSON5.stringify(format, null, 0);
            const messagesString = this.getLastMessagesString(this.messages, 3);
            let instructionStart = `[CONVERSATION CONTEXT]\n${messagesString}----\n\n[TASK]\n- Generate at 3-5 short follow-ups ("quick responses") so users can tap on a single button to respond and move the conversation forward.\n- Create the optimal UI/UX for users to respond to the message below.\n\n- Make creative decisions to form answer suggestions for users.\n- Each response should be short (2-5 words is ideal).\n- Always respond with JSON.\n\n[RESPONSE FORMAT]`;
            let instructions = `${instructionStart}\n${formattedExample}`;
            let prompt = `\n\n[NEW MESSAGE] \nassistant: ${message.content}\n\n[JSON FOR USER'S QUICK RESPONSES]`;
            return { prompt, instructions };
        },
        async followUpTopics(response, push) {
            this.showFollowUpTopics = true;
            this.followUpTopicsArray = [];
            this.multipleSelectArray = [];
        },
    },
};
</script>
