import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import webRequest from "@/mixins/ai/web_request";
// import models
async function getModel(model) {
    const r = await webRequest("models/array");
    if (r.ok) {
        const data = await r.json();
        // console.log(data);
        return data;
    }

    // return models[model];
}
export default getModel;
