<template>
    <UIWrapperText section="buttonStyles" title="Buttons">
        <div v-if="buttons && buttons?.length > 0" id="buttonSection" class="gridBackground grid grid-cols-2 text-center align-items-center br-10 border-light">
            <template v-for="cssStyle in buttons">
                <div class="relative f aic jcc aspect-video br-10 hover:bg-snow/10 transition-all group px-3">
                    <ButtonSamples :css-style="cssStyle" class="" />
                </div>
            </template>
        </div>
    </UIWrapperText>
</template>
<script>
import ButtonSamples from "@/views/ButtonSamples.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";
import ItemStyler from "@/components/styleGuide/Sections/ItemStyler.vue";

export default {
    name: "StyleGuideButtons",
    mixins: [styleGuideMixin],
    computed: {
        buttons() {
            return this.styleGuide?.buttonStyles;
        },
    },
    components: { ItemStyler, UIWrapperText, ButtonSamples },
};
</script>
<style lang="scss">
.gridBackground {
    // make a background like a photoshop grid
    background-image: linear-gradient(0deg, #eeeeee 1px, transparent 1px), linear-gradient(90deg, #eeeeee 1px, transparent 1px);
    background-size: 10px 10px;
    background-position: center;
    background-repeat: repeat;
    @apply bg-gray-200;
    //background-attachment: fixed;;
}
</style>
