<template>
    <!--    <UIWrapperText section="stylesheet" title="Style Sheet">-->
    <div v-for="item in styleSheet" class="f aic gap-2">
        <BaseButton @click="deleteStyle(item)" style-type="secondary" size="xxs" icon="fa fa-trash-alt" icon-only />
        <div class="brand-bg border-b border-b-brand pb-3 mb-4 f fc">
            <!--                    <component :is="item?.tag" v-if="item" :style="item">The Quick Brown Fox</component>-->
            <div class="x f gap-3 aic">
                <div>
                    <div>
                        <base-badge v-if="item?.candidate" class="block f-13 mb-3" color="var(--color1)" rounded>{{ item.candidate.toUpperCase() }} Candidate</base-badge>
                        <!--                    <base-badge v-if="item?.suggestedTag" class="block f-13 mb-3" color="var(&#45;&#45;color1)" rounded>{{ item.suggestedTag.toUpperCase() }} Suggested</base-badge>-->
                    </div>
                    <span v-if="item" :style="item">
                        <span v-if="item.text">{{ item.text }}</span>
                        <span v-else class="line-clamp-1">The Quick Brown Fox</span>

                        <!--                <span v-for="(text, key) in item">-->
                        <!--                    <pre>{{ text }}</pre>-->
                        <!--                </span>-->
                    </span>
                </div>
                <!--            <span class="f-20">{{ item }}</span>-->
            </div>
            <div class="f aic jcb">
                <div><TextStyleDetails :style="item" class="mt-3 text-balance leading-loose line-clamp-2" /></div>
                <div class="f gap-3 bg-brand" :key="styleGuide.assignedStyles">
                    <div v-for="tagKey in ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']">
                        <div v-if="checkAssignedStyle(item, tagKey)" class="text-uppercase mb-3 inline-block border border-brand-1 px-2 rounded py-1 linkColor c-40 f aic jcc button-1" style="font-family: Helvetica !important; font-size: 13px; letter-spacing: 0rem !important; font-weight: 700">
                            {{ tagKey.toUpperCase() }}
                        </div>
                        <template v-else-if="item?.suggestedTag === tagKey">
                            <span @click.prevent="setAsStyle(item, tagKey)" class="text-uppercase mb-3 inline-block border border-brand-1 px-2 rounded py-1 linkColor c-40 f aic jcc border-2" style="font-family: Helvetica !important; font-size: 13px; letter-spacing: 0rem !important; font-weight: 700">
                                {{ tagKey.toUpperCase() }}
                            </span>
                        </template>
                        <span v-else @click.prevent="setAsStyle(item, tagKey)" class="text-uppercase mb-3 inline-block border border-brand-1 px-2 rounded py-1 linkColor c-40 f aic jcc" style="font-family: Helvetica !important; font-size: 13px; letter-spacing: 0rem !important; font-weight: 700">
                            {{ tagKey.toUpperCase() }}
                        </span>
                        <!--                        <BaseButton icon-only style-type="brand" :class="{ '!bg-red': styleGuide.assignedStyles[tagKey] === item }" @click.prevent="setAsStyle(item, tagKey)" class="button-1-alt br-50" rounded>{{ tagKey }}</BaseButton>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--    </UIWrapperText>-->
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import TextStyleDetails from "@/components/styleGuide/Typography/TextStyleDetails.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";
import { mapGetters } from "vuex";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import assignedStyleDisplay from "@/components/styleGuide/Typography/AssignedStyleDisplay.vue";
export default {
    name: "StyleSheetSection",
    components: { BaseButton, BaseBadge, TextStyleDetails },
    props: {},
    mixins: [styleGuideMixin],
    computed: {
        assignedStyleDisplay() {
            return assignedStyleDisplay;
        },
        styleSheet() {
            try {
                if (this.styleGuide?.stylesheet) {
                    // sort by fontSize
                    let s = this.styleGuide.stylesheet;
                    // styles = styles.sort((a, b) => parseFloat(b.fontSize) - parseFloat(a.fontSize));
                    s = s.map((style, index) => {
                        // assign text randomly
                        let texts = style.texts;
                        style.text = texts[Math.floor(Math.random() * texts.length)];
                        if (Array.isArray(style.fontFamily)) {
                            style.fontFamily = style.fontFamily.join(", ");
                        }
                        return style;
                    });
                    // sort by count
                    //   let styles = this.styleGuide.stylesheet;
                    s = s.sort((a, b) => {
                        let aSuggested = a?.suggestedTag || 0;
                        let bSuggested = b?.suggestedTag || 0;
                        return parseFloat(bSuggested) - parseFloat(aSuggested);
                    });
                    s = s.sort((a, b) => parseFloat(b.fontSize) - parseFloat(a.fontSize));
                    // let candidates = s.filter(style => style?.candidate) || [];
                    // s = [...candidates, ...s];
                    return s;
                }
            } catch (e) {
                console.log(e);
            }
            return [];
        },
    },
    methods: {
        checkAssignedStyle(fontStyle, type) {
            try {
                // Validate input parameters
                if (!fontStyle || typeof fontStyle !== "object" || !type || typeof this.styleGuide.assignedStyles[type] !== "object") {
                    // console.error("Invalid parameters or style type does not exist.");
                    return false;
                }

                const assignedStyle = this.styleGuide?.assignedStyles[type];
                const styleToCompare = fontStyle;

                // Safeguard check: Ensure all necessary style properties exist
                const requiredProperties = ["fontSize", "fontWeight", "fontFamily", "fontStyle"];
                for (const property of requiredProperties) {
                    if (!(property in assignedStyle) || !(property in styleToCompare)) {
                        // console.warn(`Missing style property: ${property}`);
                        return false;
                    }
                }

                // Compare the specific style properties
                return requiredProperties.every(property => assignedStyle[property] === styleToCompare[property]);
            } catch (e) {
                console.log(e);
            }
        },
        deleteStyle(style) {
            let stylesheet = this.styleGuide.stylesheet.filter(s => {
                // compare fontWeight fontFamily fontSize fontStyle
                return !(s?.fontWeight === style?.fontWeight && s?.fontFamily === style?.fontFamily && s?.fontSize === style?.fontSize && s?.fontStyle === style?.fontStyle);
            });
            this.updateStyleGuideProp("stylesheet", stylesheet, true);
        },
        setAsStyle(style, type) {
            let cleanStyle = deepCopy(style);
            delete cleanStyle.tags;
            delete cleanStyle.text;
            this.updateStyleGuideProp(`assignedStyles.${type}`, cleanStyle, true);
        },
    },
};
</script>
