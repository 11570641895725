<template>
    <Transition enter-active-class="animated slideInLeft ease-in-out" leave-active-class="animated slideOutLeft ease-in-out">
        <div v-if="showMobileChatList" class="p-fill fixed inset-0 z-50" style="z-index: 100000 !important">
            <div class="flex items-center justify-between !border-b border-base-800 bg-snow dark:bg-base-900">
                <Button style-type="link" class="f-30 ml-auto border-0 px-3 py-2 text-2xl transition-all" @click="close" size="md" icon="fal fa-times" icon-only rounded label="Summarize chat" />
            </div>
            <ChatList class="p-fixed vh-100 mt-0" @set-subject="setSubject" @send-message="sendMessage"></ChatList>
        </div>
    </Transition>
</template>

<script>
import ChatList from "@/components/chat/ChatList/ChatList.vue";
import Button from "@/components/CoreUI/BaseButton.vue";

export default {
    components: { Button, ChatList },
    props: ["show"],
    watch: {
        show(val) {
            if (val) {
                this.$store.commit("setHideHeader", true);
            }
        },
    },
    mounted() {},
    methods: {
        close() {
            // this.$emit("update:show", false);
            this.$store.commit("chat/setShowMobileChat", false);
            this.$store.commit("setHideHeader", false);
        },
        setSubject(subject) {
            this.$emit("set-subject", subject);
            this.close();
        },
        sendMessage(message) {
            this.$emit("send-message", message);
            this.close();
        },
    },
};
</script>

<style lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
