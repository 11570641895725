<template>
    <div class="chat relative pb-8 pt-8" @keydown.esc="wantsToStopGenerating">
        <div class="f p-fixed p-fill chat-window">
            <ChatList class="mt-nav mw-350 width-min-250" v-if="!mobile" @set-subject="setSubject" @send-message="sendMessage"></ChatList>

            <div class="x flex flex-col" v-if="!loadingChat">
                <!-- Add this wrapper -->

                <div ref="chatWindow" class="p-relative x mt-nav flex-grow overflow-y-auto overflow-x-hidden bg-gray-50 pb-5 pb-8 dark:bg-base-800" :style="`padding-bottom:${mobile ? $store.state.chat.inputHeight : $store.state.chat.inputHeight + 100}px!important`">
                    <!--:style="`margin-bottom:${inputSectionHeight}px;`"-->
                    <div class="chat-container x z-1 p-relative mx-auto">
                        <div class="chat-toolbar border-b-0" v-if="!loadingChat">
                            <ChatToolbarMobile :chat="chat" v-if="mobile" @open-chat-list="openChatList" />
                            <ChatToolbar :chat="chat" class="x"></ChatToolbar>
                        </div>
                        <ChatTopicsPicker v-if="chat && !chat.topic" :messages="messages" :prompts="prompts" :recent-topics="recentTopics" @set-subject="setSubject" />
                        <template v-if="messages && !loadingChat && !showMobileChatList">
                            <div v-for="(m, index) in messages" :key="'message' + $route.params.id + index">
                                <ChatErrorMessage v-if="m.role === 'error' && m.kind === 'error'" :index="index" @ask-question="askQuestion" :m="m" :key="$route.params.id + '-message-' + index" @remove-message="removeMessage" @edit-message="editMessage" @image-loaded="imageLoaded"></ChatErrorMessage>
                                <FunctionMessage v-else-if="m.role === 'function'" :m="m" @remove-message="removeMessage" :key="$route.params.id.slice(0, 5) + '-message-' + index" @edit-message="editMessage"></FunctionMessage>
                                <template v-else-if="m.role === 'assistant'">
                                    <ChatAssistantMessage :index="index" @ask-question="askQuestion" :m="m" :key="$route.params.id + '-message-' + index" @remove-message="removeMessage" @edit-message="editMessage" @image-loaded="imageLoaded">
                                        <div>
                                            <div v-if="$store?.state?.chat?.bot_id === 'agent_generator'">
                                                <BaseButton style-type="secondary" @click.prevent="createAgentChat(m)" :label="'Create ' + parseAgentName(m.content) + ' bot'" />
                                            </div>
                                        </div>
                                    </ChatAssistantMessage>
                                </template>
                                <ChatUserMessage v-else-if="m.role === 'user'" :index="index" :m="m" @remove-message="removeMessage" :key="$route.params.id + '-message-' + index" @edit-message="editMessage" />
                                <ChatSystemMessage v-else-if="m.role === 'system'" :index="index" :m="m" @remove-message="removeMessage" :key="route + '-message-' + index" @edit-message="editMessage" />
                            </div>
                            <div class="container mb-3">
                                <div class="mw-1300 mx-auto pt-3">
                                    <div v-if="!typing" class="p-relative f-13 gap-2 pb-3">
                                        <transition name="fade" enter-from-class="o-0" enter-active-class="animated zoomIn" enter-to-class="o-1" leave-active-class="" leave-to-class="" leave-from-class="">
                                            <ChatFollowupTopics :messages="messages" v-if="chat && chat.id && $store.state.chat.followUps" :key="chat.id + randomId()" ref="followupTopics" @ask-question="askQuestion" @send-multiple-choice="sendMultipleChoice" />
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <loading-spinner v-else class="x vh-100 f aic jcc bg-gray-300"></loading-spinner>
                        <MetaPrompt />
                    </div>
                </div>
                <ChatInput class="mw-800 absolute mr-auto translate-x-32" :messages="messages" :typing="typing" @emit-summarize="summarizeServer" @stop-generating="wantsToStopGenerating" @trigger-send-message="triggerSendMessage"></ChatInput>
            </div>
            <!--<ChatQuestionsPanel :questions="questions" />-->
            <ChatListMobile v-if="mobile" v-model:show="showMobileChatList" @set-subject="setSubject" @send-message="sendMessage"></ChatListMobile>
        </div>
    </div>
</template>
<script>
import logMessages from "@/mixins/ai/log_messages";
import updateMessageProp from "@/mixins/ai/update_message_prop";
import streamCompletion from "@/mixins/ai/stream_completion";
import { debounce } from "lodash";
import { mapActions, mapGetters } from "vuex";
import { db } from "@/firebase"; // Import your Firebase instance
import datingBot from "@/mixins/Chat/DatingBot";
import imageMixin from "@/mixins/images/imageMixin";
import usermixin from "@/mixins/usermixin";
import memoryMixin from "@/mixins/ai/memoryMixin";
import ChatAssistantMessage from "@/components/chat/messages/ChatAssistantMessage.vue";
import ChatFollowupTopics from "@/components/chat/ChatFollowupTopics.vue";
import ChatList from "@/components/chat/ChatList/ChatList.vue";
import ChatTopicsPicker from "@/components/chat/ChatTopicsPicker.vue";
import ChatUserMessage from "@/components/chat/messages/ChatUserMessage.vue";
import ChatSystemMessage from "@/components/chat/messages/ChatSystemMessage.vue";
import newChatMixins from "@/mixins/Chat/newChatMixins";
import chatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import ChatInput from "@/components/chat/ChatInput.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ChatListMobile from "@/components/chat/ChatList/ChatListMobile.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import FunctionMessage from "@/components/chat/messages/ChatFunctionMessage.vue";
import ChatErrorMessage from "@/components/chat/messages/ChatErrorMessage.vue";
import ChatToolbarMobile from "@/views/Chat/ChatToolbarMobile.vue";
import ChatToolbar from "@/components/chat/ChatToolbar.vue";
import stopGenerating from "@/mixins/ai/stop_generation";
import gptError from "@/mixins/ai/gpt_error";
import MetaPrompt from "@/views/Chat/MetaPrompt.vue";

export default {
    name: "ChatSimple",
    displayName: "ChatSimple",
    components: { MetaPrompt, ChatToolbarMobile, ChatErrorMessage, FunctionMessage, LoadingSpinner, ChatToolbar, ChatListMobile, BaseButton, ChatInput, ChatTopicsPicker, ChatFollowupTopics, ChatSystemMessage, ChatUserMessage, ChatAssistantMessage, ChatList },
    mixins: [imageMixin, datingBot, usermixin, memoryMixin, newChatMixins, chatCommands],
    data() {
        return {
            messagesForAPI: [],
            conversationTokenCount: 0,
            followUpQuestionsArray: [],
            messageTokens: 0,
            message: "",
            lastAssistantMessageIndex: 0,
            chatID: null,
            chat: null,
            inputSectionHeight: 0,
            chatTitle: null,
            currentChat: null,
            JSONBuffer: "",
            questions: [],
            height: 10,
            typing: false,
            loadingChat: false,
            memoryChat: {
                model: gpt3,
                messages: [],
                messagesForAPI: [],
            },
        };
    },
    provide() {
        return {
            scrollChatWindowToBottom: this.scrollChatWindowToBottom,
            sendMessage: this.sendMessage,
            chatResponseReceived: this.chatResponseReceived,
            // chatResponseReceived: this.chatResponseReceived,
            // toolBot: this.toolBot,
            // processCodeOrImage: this.processCodeOrImage,
        };
    },
    computed: {
        ...mapGetters("chat", ["lastMessage", "messages", "prompts"]),
        models() {
            if (this.$store.state.chat.models && this.$store.state.chat.models.length > 0) {
                return this.$store.state.chat.models;
            } else {
                return ["OpenAI", gpt3, gpt4, "claude-1.3", "ANTHROPIC", "claude-instant-1.1", "claude-instant-1.1-100k", "claude-1-100k"];
            }
        },
        commands() {
            return this.$store.state.chat.commands;
        },
        fastBubbles() {
            return this.$store.state.chat.fastBubbles;
        },
        debug() {
            return this.$store.state.chat.showDebugger;
        },
        model: {
            get() {
                // Get the value from the Vuex store
                return this.$store.state.chat.model;
            },
            set(value) {
                // Update the value in the Vuex store using the 'updateModel' action
                this.$store.dispatch("chat/updateModel", value);
            },
        },
        route() {
            if (this.$route && this.$route.params && this.$route.params.id) {
                return this.$route;
            } else {
                return "";
            }
        },
    },
    async created() {
        await this.$store.dispatch("chat/getPrompts");
        logMessages(this.$store);
        await this.loadChat(this.$route.params.id);
    },
    watch: {
        $route: {
            immediate: false,
            async handler(to, from) {
                if (to.path.includes("chat")) {
                    await this.handleRouteChange(this.$route.params.id);
                    this.$nextTick(() => {
                        this.scrollChatWindowToBottom(true);
                    });
                }
            },
        },
    },
    async mounted() {
        this.scrollChatWindowToBottom(true);
        this.initTools();
        // console.log(templateStderr(`{blue.#:ABCDEF  ${this.$route.params.id}}`), "blue hey tis is good", "hey");
    },
    methods: {
        ...mapActions("chat", ["updateMessage", "setMessageId", "loadChatStore", "setMessages", "setChat", "updateModel", "saveChatStore", "addMessageStore", "appendContentToLastMessage", "setMessageComplete"]),
        // ...mapMutations("chat", ["setShowMobileChat"]),
        ...mapGetters("chat", ["showMobileChat"]),
        askQuestion(question) {
            this.addMessage(userMessage(question));
            this.sendMessage();
        },
        async handleRouteChange(route) {
            // this.messages = [];
            this.loadingChat = true;
            this.chat = null;
            // this.loadChatStore({ chatID: route.params.id, route });
            await this.loadChat(this.$route.params.id);
            this.loadingChat = false;
            if (this.chatTitle) {
                document.title = `${this.chatTitle} | Clearmind`;
            }
        },
        scrollChatWindowToBottom(firstLoad) {
            this.$nextTick(() => {
                setTimeout(() => {
                    const chatWindow = this.$refs.chatWindow;

                    if (!chatWindow) {
                        console.warn('Ref "chatWindow" is not defined or not rendered.');
                        return;
                    } else {
                        // Check if the user is already at the bottom allowing some tolerance (e.g., 100px)
                        const tolerancePx = 50;
                        let isAtBottom = chatWindow.scrollHeight - (chatWindow.scrollTop + chatWindow.offsetHeight) <= tolerancePx;
                        if (firstLoad) {
                            isAtBottom = true;
                        }
                        // Scroll to the bottom only if the user is already at the bottom
                        if (isAtBottom) {
                            chatWindow.scrollTop = chatWindow.scrollHeight;
                        }
                    }
                }, 0);
            });
            return;
        },
        messagesForChat(messages) {
            // Declare and initialize a variable to keep track of the last system message's index.
            // Initialized to -1 to denote that no system message has been found yet.
            let lastSystemIndex = -1;

            // Iterate over the messages list in reverse order
            for (let i = messages.length - 1; i >= 0; i--) {
                // Check if the role of the current message is "system"
                if (messages[i].role === "system") {
                    // If it is, update lastSystemIndex to the current index and break the loop
                    lastSystemIndex = i;
                    break;
                }
            }

            // Check if this object has a commands property
            if (false) {
                // If commands do not exist, return the original messages
                return messages;
            }

            // Create a new array of modified messages
            let modifiedMessages = messages.map((message, index) => {
                // Check if the current index is equal to the last system message's index
                if (index === lastSystemIndex) {
                    // If it is, create a new object with the same properties as the original message
                    let modifiedMessage = { ...message };

                    // Append the formattedActionString to the content of the modified message
                    modifiedMessage.content = `${modifiedMessage.content}\n\n${this.systemPromptStringMod}`;

                    // Return the modified message
                    return modifiedMessage;
                }

                // If the current index is not equal to the last system message's index,
                // return the original message
                return message;
            });

            // Return the modified messages
            return modifiedMessages;
        },

        triggerSendMessage(e) {
            // console.log(e);
            this.message = "";
            this.sendMessage();
        },
        async wantsToStopGenerating() {
            if (this.typing) {
                this.typing = false;
                await stopGenerating(this.$store);
            }
        },
        // debounce get message
        prepareMessageToSend(message, role = "user") {
            this.typing = true;
            this.scrollChatWindowToBottom(true);
        },
        prepareForResponse(func, continueCompletion) {
            // this.$store.dispatch("chat/addMessageStore", roleMessage("", role));
            if (func && func !== "") {
                let message = { role: "tool", name: func.name, content: func.content };
                message.command = func;
                console.log(message);
                this.addMessage(message);
            } else {
                this.addMessage(aiMessage(""));
            }
        },
        async sendMessage(action, continueCompletion = false, skipFunctions) {
            let messageResponse = "";
            this.typing = true;
            let useFunctions;
            if (this.$store.state.chat.commands) useFunctions = "commands";
            if (skipFunctions) useFunctions = false;
            // console.error("before sending message");
            try {
                // await this.simpleMessage(message, "system message or prompt", () => `message`);
                // return;
                const messages = this.messagesForChat(this.messages);
                let functionObject = null;
                let newMessage = {};
                let functionStream = [];
                functionObject = await streamCompletion(
                    this.$store,
                    this.message,
                    messages,
                    null,
                    () => {
                        this.prepareMessageToSend();
                        if (!action) this.prepareForResponse(action, continueCompletion);

                        if (this.lastMessage.role === "assistant") {
                            updateMessageProp(this.$store, "typing", false);
                        }
                        if (action) {
                            let content = deepCopy(action.content);
                            let message = { role: "tool", name: action.name, content: JSON.stringify(content.slice(0, 10000)) };
                            console.log(message);
                            console.log(content);
                            this.addMessage(message);
                        }
                    },
                    (token, function_object, function_arguments) => {
                        messageResponse += token;
                        this.appendContentToLastMessage(token);
                        try {
                            if (function_arguments) functionStream = { name: function_object?.function_call?.name, arguments: function_arguments };
                        } catch (error) {
                            console.error(error);
                        }
                        if (!this.lastMessage.typing) updateMessageProp(this.$store, "typing", true);
                        this.scrollChatWindowToBottom();
                    },
                    async functionObject => {
                        updateMessageProp(this.$store, "typing", true);
                        this.removeMessageKey("typing");
                        if (functionStream) {
                            this.$nextTick(() => {
                                this.chatResponseReceived(this.lastMessage, this.currentIndex, "MESSAGE END", functionStream);
                            });
                        } else {
                            await this.chatResponseReceived(this.lastMessage, this.currentIndex, "MESSAGE END");
                        }
                        // await this.sideBot();
                    },
                    this.model,
                    true,
                    "Chat",
                    0,
                    useFunctions,
                );
                if (functionObject) {
                    // Handle the functionObject as needed
                }
            } catch (e) {
                gptError(e);
                this.typing = false;
                // Handle errors and display the error message
            }
        },
        setQuestion(message) {
            if (this.questions[this.questions.length - 1]) {
                this.questions[this.questions.length - 1].answer = message;
            }
        },
        async chatResponseReceived(message = this.lastMessage, index = this.currentIndex, from, functionObject) {
            this.typing = false;
            // newMessage = this.fixActions(newMessage, this.messages.length - 1, true);
            if (functionObject && functionObject.name) {
                await this.receivedFunction(functionObject);
            }
            updateMessageProp(this.$store, "id", this.randomId());
            updateMessageProp(this.$store, "complete", true);
            this.typing = false;
            this.$forceUpdate();
            // console.groupCollapsed(`%c 🔄 Chat Response Received`, info);
            // console.table(this.logMessageData(this.lastMessage));
            // console.groupEnd();
            try {
                if (this.$refs.followupTopics) {
                    await this.$refs.followupTopics.followUpTopics(this.lastMessage);
                }
            } catch (e) {
                console.trace();
                console.error(this.gptError(e));
            }
            // this.speakResponse(this.lastMessage.content); // if (from !== "toolbot")  await this.toolBot(newMessage.content, index);
            this.scrollChatWindowToBottom();
            await this.saveChat();
            // await this.trySummary();
            // if (message.kind !== "sidebot") await this.sideBot();
            return;
        },
        async sendMultipleChoice(choices) {
            let choicesString = choices.join(", ");
            this.message = this.message + choicesString;
            if (this.questions[this.questions.length - 1]) {
                this.questions[this.questions.length - 1].answer = this.message;
            }
            await this.sendMessage();
        },
        debounceSave: debounce(function () {
            // console.error("saving chat debounce");
            this.saveChat();
        }, 2000),
        debounceScrollChat: debounce(function () {
            this.scrollChatWindowToBottom(true);
        }, 500),

        async loadChat(id) {
            try {
                // Fetch chat data from Firestore
                // let id = this.$route.params.id;
                try {
                    let chatDoc;
                    try {
                        chatDoc = await db.collection("chats").doc(this.$route.params.id).get();
                    } catch (e) {
                        console.error(this.gptError(e));
                        console.error("Chat loading issues", e);
                    }
                    // Check if the chat exists
                    if (chatDoc.exists) {
                        const { messages, model, questions, followUpTopicsArray, followUpTopicsType, followUpQuestionsArray, chatTitle, createdAt, updatedAt } = chatDoc.data();

                        this.chat = chatDoc.data();
                        this.chat.id = this.$route.params.id;
                        this.setChat(this.chat);
                        this.setMessages(messages);
                        this.currentChat = this.chat;
                        await this.setMessageId();
                        this.$nextTick(() => {
                            this.$store.dispatch("chat/memory/fetchMemories");
                            this.$store.dispatch("chat/tasks/fetchTasks");
                            this.$store.dispatch("chat/agents/fetchAgents");
                        });
                        return;
                        this.model = model || this.models[0];
                        this.questions = questions || [];
                    } else {
                        console.error("Chat not found");
                    }
                } catch (error) {
                    console.error("Error fetching chat:", error);
                }
            } catch (error) {
                console.error(error);
                console.error(this.gptError(error));
            }
        },
        async saveChat() {
            this.saveChatStore();
            return;
        },
        async saveQuestions() {
            console.log("save questions");
            await db.collection("chats").doc(this.chatID).update({
                questions: this.questions,
            });
        },
        //method to remove a message from the messages array then save to firebase
        async removeMessage(message) {
            console.groupCollapsed(`🚫 Remove Message`);
            console.log(message.content);
            console.groupEnd();
            const index = this.messages.indexOf(message);
            if (index > -1) {
                this.messages.splice(index, 1);
            }
            await this.saveChat();
        },
        //method to edit message in messages array then save to firebase
        async editMessage(message, index) {
            console.error("edit message", message, index);
            this.messages[index].content = message.content;
            await this.saveChat();
        },
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.debounceScrollChat);
    },
};
</script>
<style scoped>
textarea {
    overflow: hidden;
    resize: none;
    border: none;
    padding: 10px;
    min-height: unset !important;
}
</style>
