<template>
    <div>
        <div class="col-span-12 grid grid-cols-12 relative mb-5 z-1">
            <div class="col-span-12 grid grid-cols-12 overflow-hidden border-light br-20 overflow-hidden">
                <div class="col-span-12 z-0 relative">
                    <div v-if="selectedTheme && selectedTheme !== 'all'" id="themeSection" :style="`background:${selectedTheme?.backgroundColor}`" class="transition-all duration-1">
                        <div class="x y grid grid-cols-12">
                            <div :class="{ 'height-800': !mobile, '': mobile }" class="md:vh-80 col-span-12 md:col-span-6 f md:aic jcc p-4 pb-8 p-md-8 order-1 order-md-0">
                                <div :style="`color:${selectedTheme.color}`" class="f fc gap-4 transition-all duration-1 mw-600 text-balance">
                                    <span :class="{ '!f-40': mobile, '!f-80': !mobile }" :style="comboHeader" class="leading-tight">{{ quickBrown }}</span>
                                    <span :style="comboParagraph" class="w-75">{{ lorem }}</span>
                                </div>
                            </div>
                            <div :class="{ 'height-800': !mobile, 'height-400': mobile }" class="vh-30 md:vh-80 f aic jcc col-span-12 h-auto md:col-span-6 p-md-8 relative order-0 order-md-1 flex-shrink-0">
                                <div class="x y fc gap-3 f aic jcc relative p-5">
                                    <div class="relative block x f aic jcc">
                                        <brand-logo-component :color="dynamicColor" :width="`100%`" class="relative transition-all duration-1" style="width: 1000px"></brand-logo-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PickerContainer absolute>
                    <div v-for="(theme, i) in randomDivStyles" class="relative">
                        <div class="p-absolute p-fill x y f aic jcc">
                            <ProgressRing v-if="isSelectedTheme(theme)" :progress="progressValue" :style="`;color:${theme?.color}`" class="p-0" />
                        </div>
                        <div :style="`background:${theme?.backgroundColor}`" class="flex-shrink-0 c-50 md:c-50 border relative border-light cursor-pointer transition transform hover:-translate-y-1 duration-300 f aic jcc" @mouseover="selectTheme(theme)">
                            <div :style="[`color:${theme?.color};`, 'font-family:Helvetica;font-weight:500;']" class="f-20 md:f-24">
                                <span class="">A</span>
                                <span class="italic" style="font-weight: 300">a</span>
                            </div>
                        </div>
                    </div>

                    <div v-if="showAllButton" class="c-40 md:c-50 border border-light cursor-pointer transition transform hover:-translate-y-1 duration-300 f aic jcc" @mouseover="selectTheme('all')">
                        <div class="f-20 md:f-24" style="font-family: Helvetica">All</div>
                    </div>
                </PickerContainer>
            </div>
        </div>
        <div class="grid-cols-12 col-span-12 overflow-hidden">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import PickerContainer from "@/components/styleGuide/Sections/Themes/PickerContainer.vue";
import ProgressRing from "@/components/styleGuide/Sections/Themes/ProgressRing.vue";

export default {
    name: "ThemeSamplesPicker",
    components: { ProgressRing, PickerContainer, BrandLogoComponent },
    mixins: [styleGuideMixin],
    data() {
        return {
            fontSize: 1,
            small: true,
            selectedTheme: null,
            progressValue: 0,
            maxProgressTime: 3000,
        };
    },
    computed: {
        quickBrown() {
            // if (this.headerSample) return this.headerSample;
            return "The Quick Brown Fox Jumps Over The Lazy Dog";
        },
        lorem() {
            return `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`;
        },
        showAllButton() {
            return false;
        },
        dynamicColor() {
            return this.selectedTheme.color;
        },
        randomDivStyles() {
            if (!this.divStyles) return [];
            let divStyles = this.divStyles.map(style => {
                let textStyle = this.randomTextStyleMethod(style);
                if (textStyle?.fontSize) {
                    let text = this.dynamicTextSample(textStyle.fontSize);
                    style = { ...style, ...textStyle, text };
                    if (style.textTransform !== "uppercase") {
                        style.textTransform = "capitalize";
                    }
                    return style;
                }
                return style;
            });
            // filter null styles
            // log styles
            divStyles = divStyles.filter(style => style);
            return divStyles;
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.divStyles?.length > 0) {
                this.selectedTheme = this.divStyles[0];
            }
        });
        // this.selectedTheme = this.divStyles[0];
        // this.startThemeRotation();
    },
    beforeUnmount() {
        if (this.themeInterval) clearInterval(this.themeInterval);
    },
    methods: {
        isSelectedTheme(theme) {
            let selectedBg = this.selectedTheme?.backgroundColor;
            let selectedColor = this.selectedTheme?.color;
            let bg = theme?.backgroundColor;
            let color = theme?.color;
            if (selectedBg === bg && selectedColor === color) return true;
        },
        resetProgressTimer() {
            // Reset the progress timer
            clearInterval(this.progressTimer);
            this.progressValue = 0;
            this.progressTimer = setInterval(() => {
                if (this.progressValue < 100) {
                    this.progressValue += 1; // Increment the progress
                } else {
                    clearInterval(this.progressTimer); // Stop the timer at 100%
                }
            }, 100); // Update every 100ms
        },
        startThemeRotation() {
            this.themeInterval = setInterval(() => {
                let currentIndex = this.divStyles.indexOf(this.selectedTheme);
                let nextIndex = (currentIndex + 1) % this.divStyles.length;
                this.selectedTheme = this.divStyles[nextIndex];

                // Reset progress for the next theme
                this.progressValue = 0;
            }, this.maxProgressTime);

            // Start a separate interval to update the progress every 100ms
            setInterval(() => {
                if (this.progressValue < 100) {
                    this.progressValue += 100 / (this.maxProgressTime / 100); // Increment the progress relative to maxProgressTime
                }
            }, 100);
        },
        selectTheme(theme) {
            this.selectedTheme = theme;
            this.$nextTick().then(() => {
                this.$forceUpdate();
            });
        },
        applyRandomTextStyle(index) {
            const style = this.randomTextStyleMethod();
            if (parseFloat(style.fontSize) > 1.4) {
                style.color = "red";
            }
            return style;
        },
    },
};
</script>
