import { mapActions } from "vuex";
export default {
    created: function () {},
    mounted() {},
    data() {
        const start = "[RST]";
        const end = "[RND]";
        return {
            add_to_memory: {
                name: "Add to Memory",
                command: "add_to_memory",
                start: start,
                end: end,
                kind: "remember",
                role: "assistant",
                args: { remember: "" },
                description: `Save a short message to the system's memory by enclosing the message between ${start} and ${end} tags.`,
                show: true,
                newMessage: true,
                resources: ["Add a note to your memory.", "Long Term memory management."],
                exampleUses: [
                    `"Thanks for letting me know, I'll remember that. ${start}User is traveling to Bali next week${end}"`,
                    // "I appreciate you letting me know. [RST]User is traveling to Bali next week[RND]"
                ],
                constraints: [
                    "Save user preferences and important details to your memory.",
                    "~2000 word limit for memory. Your memory is short, so immediately save important notes to memory and code to files.",
                    // other
                ],
            },
        };
    },
    computed: {
        memoryString() {
            // memoryString = `\n${this.memoryString.trim()}\n\n--------- COMMAND INSTRUCTIONS ---------\n\n`;
            let memory = this.$store.state.chat.memory.memory;
            let memoryString = "";
            // memoryString = `\n\nMEMORY:\n`;
            if (memory && memory.length > 0) {
                memoryString += this.headerString("Memory");
                memory.forEach((item, index) => {
                    memoryString += `\n- ${item}\n`;
                });
            }
            return `${memoryString}`;
        },
        memoryPrompt() {
            if (this.$store.state.chat.messages && this.$store.state.chat.messages.length > 0) {
                let msgs = this.messagesSinceLastSummary;
                if (msgs.length > 10) {
                    msgs = msgs.slice(msgs.length - 10);
                }
                const summaries = this.$store.state.chat.summary;
                const summary = summaries[summaries.length - 1];
                const messagesStringWithRole = msgs.map(message => {
                    let messageString = `[${message.role.toUpperCase()}]: ${message.content.trim()}`;
                    if (message.action && message.action.command) {
                        const action = this.actionsObject[message.action.command];
                        const start = action.start;
                        const end = action.end;
                        let cleanMessageString = message.content.replace(start, "").replace(end, "");
                        return `[${message.role.toUpperCase()}]: ${action.name}: ${cleanMessageString}`;
                    } else {
                        return messageString.replace(/\\n/g, "").trim();
                    }
                });
                let current_memory;
                if (this.$store.state.chat.summary && this.$store.state.chat.summary.length === 0) {
                    current_memory = "Not yet summarized.";
                } else {
                    if (summary.event) {
                        current_memory = summary.event + "\n\n";
                    }
                }
                let latest_event = messagesStringWithRole.join("\n\n");

                let prompt = `
Your task is to create a concise running summary of actions and information results in the provided text, focusing on key and potentially important information to remember.\m
You will receive the current summary and the latest actions. Combine them, adding relevant key information from the latest development in 1st person past tense and keeping the summary concise.\n
Summary So Far:
"""
${current_memory.trim() || "Nothing here yet"}
"""

Latest Development:
"""
${latest_event.replace(/\\n/g, "") || "Nothing here yet"}
"""
`;
                return prompt;
            }
            return false;
        },
    },
    methods: {
        // ...mapActions("chat", ["addToMemoryStore"]),
        // async updateMemory(messages, memory, index) {
        //     const message = `[SYSTEM MESSAGE]\n${this.systemMessages}\n\n[MESSAGES]\n${this.messagesForMemories}\n\n [MEMORY]`;
        //     try {
        //         // let response = await this.uploadMemory(memory);
        //         let m;
        //         // let m = await this.completion(message, this.memoryPrompt);
        //         // console.error(this.savedMemory);
        //         let messageString = this.stringifyMessage(messages, ["system"]);
        //         return await this.getChunkSummaries(messageString);
        //         if (m) {
        //             this.savedMemory = m;
        //             let json = this.masterJSONFixer(m);
        //             console.error(json);
        //             console.error(m);
        //         } else {
        //             console.error("No memory returned");
        //             // map messages to string
        //             // await this.getDocumentChunks(message);
        //         }
        //     } catch (error) {
        //         console.error(this.gptError(error));
        //         try {
        //             await this.getDocumentChunks(this.messagesString);
        //         } catch (error) {
        //             console.error(this.gptError(error));
        //         }
        //     }
        // },
        async addMemory() {
            if (this.newMemory.trim() !== "") {
                this.$store.dispatch("chat/memory/addMemoryToStore", this.newMemory);
                this.newMemory = "";
            }
        },
        async removeMemory(index) {
            this.$store.dispatch("chat/memory/removeMemoryFromStore", index);
        },
        // this.$store.dispatch("chat/tasks/removeTaskFromStore", task);
        async addToMemory(args, name, message, index) {
            if (args.operation === "add") {
                await this.$store.dispatch("chat/memory/addMemoryToStore", args.remember);
            } else if (args.operation === "remove") {
                await this.$store.dispatch("chat/memory/removeMemoryFromStore", args.remember);
            }
            let content = `${this.lastMessage.content}`;
            content += "\n\n" + this.$store.state.chat.memory.memory;

            // console.log("Updating Memory", updatedMemory);
            let memory_object = {
                memory: this.$store.state.chat.memory.memory,
            };
            return JSON.stringify(memory_object);
        },
        appendToMemory(messages) {
            // get the first system message
            if (this.messages[0].type === "system") {
                let firstMessage = this.messages[0];
                // if first message is a system message
                if (firstMessage.role === "system") {
                }
            }
        },
    },
};
