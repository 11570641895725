<template>
    <div>
        <collapsable-menu-item :key="identifier" max-height="600" title="Section Type">
            <template #title>
                <div class="border-top f gap-3 editor-section aic pr-5 border-b-gray-200 border-bottom group cursor-grabbing hover:bg-green-50">
                    <div v-if="imageIndex" class="flex-shrink-0 relative width-50 height-50 border border-black br-10 overflow-hidden">
                        <div class="p-absolute p-fill aic jcc bg-snow/20 ease-back transition-all opacity-0 f group-hover:opacity-100 text-snow bg-blur">
                            <i class="fas fa-arrows-v scale-50 opacity-0 transition-all duration-1 ease-elastic group-hover:opacity-100 group-hover:scale-100" style="text-shadow: 0px 1px 0px black"></i>
                        </div>
                    </div>
                    <div class="group-hover:text-green-800">
                        <span class="leading-tight line-clamp-1 text-sm fwb text-pretty tracking-tight">Email Footer</span>
                        <span v-if="footer_text" class="fwsb line-clamp-1 text-xs o-7">{{ footer_text }}</span>
                        <span v-if="body" class="text-pretty line-clamp-1 text-xs small o-3 leading-tight">{{ body }}</span>
                    </div>
                </div>
            </template>
        </collapsable-menu-item>
        <SectionColorEditor :colors="footerColors" @update-colors="updateFooterColors" />
        <div v-if="localFooterLinks?.length">
            <draggable v-model="localFooterLinks" item-key="id" @change="updateFooter">
                <template #item="{ element, index }">
                    <collapsable-menu-item max-height="600" title="Section Type">
                        <template #title>
                            <div class="border-bottom p-2 f aic jcs x gap-2">
                                <div class="c-25 f aic jcc border-light fa-13" @click="deleteFooterLink(index)">
                                    <i class="far fa-trash f-13 o-5"></i>
                                </div>
                                {{ element.text }}
                            </div>
                        </template>
                        <div class="border-bottom p-2">
                            <SimpleInput v-model="element.text" label="Link text" @input="updateFooter" />
                            <SimpleInput v-model="element.url" label="URL" @input="updateFooter" />
                            <div class="f jce">
                                <a class="x red" @click.prevent="deleteFooterLink(index)">Delete</a>
                            </div>
                        </div>
                    </collapsable-menu-item>
                </template>
            </draggable>
        </div>
        <div>
            <div class="f jcb aic px-2.5 o-4">
                <span class="editor-section-title">Add a Footer Link</span>
                <div class="p-1" @click.prevent="addFooterLink">
                    <i class="far fa-plus-circle f-20 o-2"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import SimpleInput from "@/views/SimpleInput.vue";
import SectionColorEditor from "@/components/elements/SectionColorEditor.vue";
import { mapGetters } from "vuex";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import chroma from "chroma-js";
import draggable from "vuedraggable";
export default {
    name: "CurrentlyEditingEmailFooter",
    mixins: [StyleGuideMixin],
    components: {
        SimpleInput,
        CollapsableMenuItem,
        SectionColorEditor,
        draggable,
    },
    props: {
        emailFooter: {
            type: Object,
            default: () => ({}),
        },
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            localFooterLinks: [],
            linkId: 0,
        };
    },
    created() {
        this.localFooterLinks = [...this.footer_links];
    },
    mounted() {
        setTimeout(() => {
            if (!this.color) {
                this.$nextTick(() => {
                    this.updateFooterColors(this.footerColors[0]);
                });
            }
        }, 500);
    },
    methods: {
        async saveCampaign() {
            await this.$store.dispatch("stream/saveCampaignItem", { id: this.id });
        },
        updateFooter() {
            let sectionIdentifier = `editor.${this.id}.result.email_footer`;
            let updatedFooter = {
                ...this.emailFooter,
                footer_links: this.localFooterLinks,
            };
            this.$store.dispatch("stream/updateStreamProp", { keyPath: sectionIdentifier, value: updatedFooter });
        },
        async updateFooterColors(colors) {
            console.log(colors);
            let sectionIdentifier = `editor.${this.id}.result.email_footer`;
            let updatedFooter = {
                ...this.emailFooter,
                color: colors?.color,
                backgroundColor: colors.backgroundColor,
            };
            this.$store.dispatch("stream/updateStreamProp", { keyPath: sectionIdentifier, value: updatedFooter });
            await this.saveCampaign();
        },
        addFooterLink() {
            const newLink = {
                id: `link-${this.linkId++}`,
                text: "New Link",
                url: "https://example.com",
            };
            this.localFooterLinks.push(newLink);
            this.updateFooter();
        },
        deleteFooterLink(index) {
            this.localFooterLinks.splice(index, 1);
            this.updateFooter();
        },
    },
    computed: {
        ...mapGetters("styleGuide", ["divStyles", "colors"]),
        footerColors() {
            let colors = [];
            if (!this.divStyles) return [];
            let all_colors = this.colors?.all_colors || [];
            colors.push(...all_colors);
            let divStyles = this.divStyles?.map(s => {
                return {
                    color: s.color,
                    backgroundColor: s.backgroundColor,
                };
            });
            divStyles.forEach(style => {
                if (style.color) colors.push(style.color);
                if (style.backgroundColor) colors.push(style.backgroundColor);
            });
            colors = [...new Set(colors)];
            colors = colors.filter(c => !c.includes("gradient"));
            colors = colors.filter(Boolean);
            colors = colors.map(c => {
                let background = c;
                let color = "black";
                try {
                    color = this.getContrastColor(background, colors);
                } catch (e) {}
                return {
                    color: color,
                    backgroundColor: background,
                };
            });
            colors.push(...divStyles);
            colors.sort((a, b) => {
                try {
                    let aL = chroma(a.backgroundColor).luminance();
                    let bL = chroma(b.backgroundColor).luminance();
                    return aL - bL;
                } catch (e) {
                    return 0;
                }
            });
            return colors;
        },
        color() {
            if (this.emailFooter?.color) return this.emailFooter?.color;
            return false;
        },
        footer_text() {
            return this.emailFooter?.footer_text;
        },
        footer_links() {
            return this.emailFooter?.footer_links || [];
        },
    },
};
</script>
