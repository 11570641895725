<template>
    <div id="imageInsetContainer" :class="[fullBleedImageClass, insetImageClass, imageClasses.inset]" class="relative" @mouseenter="hover" @mouseleave="cancelHover">
        <div id="imageContainer" :class="[imageContainerClasses, imageClasses.container]">
            <div id="imageShape" :class="[imageShapeClasses, imageClasses.shape]" @click="clicked">
                <transition name="fade-up">
                    <img v-show="imageLoaded" :style="addBorder" :id="`section-${index}-image`" ref="imgTag" :alt="alt" :class="[mainImageClasses, imageClasses.image]" :src="imageURL" appear @load="imageLoaded = true" />
                </transition>
                <!--              :style="sectionMaxHeightString"-->
                <transition name="fade-scale">
                    <div v-if="isRound && !hideGradient && imageGradientColor && !(this.fullBleed && !this.isEmail)" :style="`box-shadow: 0px 0px 25px 25px ${imageGradientColor} inset, 0px 0px 10px 10px ${imageGradientColor}!important;`" class="p-absolute p-fill x y f aic jcc z-1 rounded-full"></div>
                    <!--                    <div v-if="isRound && !hideGradient" :style="`box-shadow: 0px 0px 25px 25px ${sectionBackground} inset, 0px 0px 10px 10px ${sectionBackground}!important;`" class="p-absolute p-fill x y f aic jcc z-1 rounded-full"></div>-->
                </transition>
                <transition appear name="fade-scale">
                    <div v-if="!isRound" :class="{ '': !isRound && hideGradient, 'scale-100': isRound && !hideGradient }" class="absolute duration-1 p-fill top bottom left right">
                        <slot></slot>
                    </div>
                </transition>
                <!--                </transition>-->
            </div>
        </div>
        <ImageTools
            :image="image"
            :index="index"
            :is-hovered="hovered"
            :kind="kind"
            :relevant-images="section.relevantImages"
            :section="section"
            class="fixed bottom z-5"
            @edit="edit"
            @toggle-swap-sides="toggleSwapSides"
            @toggle-full-bleed="toggleFullBleed"
            @toggle-full-bleed-margin="toggleFullBleedMargin"
            @set-image-index="updateSection('imageIndex', images.length - 1)"
            @toggle-gradient="toggleGradient"
            @toggle-inset="toggleInset"
            @toggle-style="toggleStyle" />
        <!--        <div class="absolute left right bottom z-0 height-max-300 x overflow-scroll scrollSection"><div class="relative mw-350 br-10 p-3"><DebugObject :object="image" class="text-snow p f aic jcc br-20 px-2 d-inline-block" /></div></div>-->
        <teleport v-if="isEditing" :to="`#${identifierId}`">
            <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
                <div v-if="isEditing" class="absolute x top justify-fixed-center bottom text-gray-900 mw-450 height-max-500 mx-auto mt-3 right" style="z-index: 10000 !important">
                    <EmailImagePicker :identifier="identifier" :image-array="images" :index="index" :relevant-images="relevantImages" class="relative mx-auto left" section-key="result.sections" section-kind="streaming" @blur="cancelEdit" @close="cancelEdit" />
                </div>
            </transition>
        </teleport>
    </div>
</template>
<script>
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import EmailImagePicker from "@/components/generative_templates/Email/EmailImagePicker.vue";
import SectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import ImageTools from "@/components/generative_templates/Pages/ImageTools.vue";
import emailImageMixin from "@/mixins/StyleGuideMixins/emailImageMixin";
import desktopImageMixin from "@/mixins/StyleGuideMixins/desktopImageMixin";

export default {
    name: "LandingPageImage",
    components: { ImageTools, EmailImagePicker, DebugObject },
    mixins: [SectionStyleMixin, emailImageMixin, desktopImageMixin],
    computed: {
        // ...mapGetters("styleGuide", ["images"]),
        fullBleedImageClass() {
            if (this.isEmail) {
                return {
                    "!-mt-40 transition-all duration-3": this.fullBleed && this.isTucked && !this.isTransparent,
                    // "transition-all !-mt-16": !this.isTucked && !this.isTransparent,
                    // "mb-3": this.isTransparent,
                };
            } else {
                return {
                    "": this.fullBleed,
                };
            }
        },
        imageClasses() {
            let easeDuration = " transition-all animated duration-2  elastic-easing  ";
            return {
                inset: "" + easeDuration,
                shape: " z-1  relative " + easeDuration,
                container: "" + easeDuration,
                image: " overflow-clip " + easeDuration,
            };
        },
        identifierId() {
            const id = this.identifier;
            return this.makeId(id);
        },
        isEditing() {
            return this.editImages;
        },
        fullBleedDesktopClasses() {
            return "mh-70 y o-center x o-cover z-5";
        },
        addBorder() {
            if (this.isInset && this.isEmail && !this.transparent) {
                // if (this.isInset && this.isEmail && this.fullBleed && !this.transparent) {
                let radius = "20px";
                let color = `var(--color1)`;
                if (this.section.color) color = this.section.color;
                if (this.isRound) radius = "100%";
                return `border: 2px solid ${color};overflow:hidden;border-radius:${radius};transition-duration:0.5s;transition-property:all;`;
            }
        },
        mainImageClasses() {
            // this is the image
            if (this.isEmail) return this.emailImageClasses;
            if (this.isRotated) return this.desktopRotatedImage.image;
            if (this.fullBleed) return this.fullBleedDesktopClasses;
            if (this.isNormal && this.transparent) return this.desktopNormalImage.image;
            if (this.transparent) {
                // if (this.isRotated) return `mx-auto y x height-500 o-contain rotate-20`;
                if (this.isFull) return `vh-70 x y o-contain mx-auto y x px-5 pt-7`;
                return `o-contain mx-auto y x mh-60 floating-item`;
            }
            if (this.isNormal || !this.imageStyle) return " x y o-cover overflow-scroll p-0";
            if (this.isSeamless) return `x y o-cover`;
            return `x y o-cover`;
        },

        imageContainerClasses() {
            if (this.isEmail) return this.emailContainerClasses;
            return this.desktopImageContainerClasses;
        },

        insetImageClass() {
            if (this.isEmail) return this.emailInsetClasses;
            else return this.desktopImageInset;
        },
        // if (this.imageNormal) return `aspect-h-4 aspect-w-3 overflow-clip rounded-none`;
        transparentImageShapes() {
            if (this.isEmail) return this.emailTransparentImageShapes;
            if (this.isFull) return this.imageFullClasses;
            if (this.isRotated) return this.imageRotateClasses;
        },
        imageShapeClasses() {
            if (this.transparent) return this.transparentImageShapes;
            if (this.fullBleed && !this.isEmail) return;

            // if (this.fullBleed) return `aspect-h-9 aspect-w-16 overflow-clip rounded-none`;

            if (this.isNormal) return this.imageNormalClasses;
            if (this.isRound) return this.imageRoundClasses;
            if (this.isSquare) return this.imageSquareClasses;
            if (this.isLandscape) return this.imageLandscapeClasses;
        },
        isHorizontal() {
            const ratio = this.image.width / this.image.height;
            if (ratio > 1.05 && ratio < 1.5)
                return "aspect-landscape-short"; // Close to 4:3 aspect ratio
            else if (ratio >= 1.5)
                return "aspect-landscape"; // 16:9 aspect ratio for wider images
            else if (ratio > 0.95 && ratio <= 1.05) return "aspect-square"; // Square images
            return false;
        },
        isHorizontalReverse() {
            const ratio = this.image.height / this.image.width;
            if (ratio > 1.05 && ratio < 1.5)
                return "aspect-landscape-short"; // Close to 4:3 aspect ratio
            else if (ratio >= 1.5)
                return "aspect-landscape"; // 16:9 aspect ratio for wider images
            else if (ratio > 0.95 && ratio <= 1.05) return "aspect-square"; // Square images

            return false;
        },
        isVertical() {
            const ratio = this.image.width / this.image.height;
            if (ratio < 0.95 && ratio > 0.75)
                return "aspect-portrait"; // Close to 3:4 aspect ratio
            else if (ratio <= 0.75)
                return "aspect-portrait"; // More extreme tall aspect ratio
            else if (ratio >= 0.95 && ratio < 1.05) return "aspect-square"; // Square images
            return false;
        },
        isVerticalReverse() {
            const ratio = this.image.height / this.image.width;
            if (ratio < 0.95 && ratio > 0.75)
                return "aspect-portrait-short"; // Close to 3:4 aspect ratio
            else if (ratio <= 0.75)
                return "aspect-portrait"; // More extreme tall aspect ratio
            else if (ratio >= 0.95 && ratio < 1.05) return "aspect-square"; // Square images
            return false;
        },
        imageNormalClasses() {
            // return "";
            if (this.isInset) {
                if (this.isHorizontal) return this.isHorizontal + " overflow-clip o-contain br-10";
                else if (this.isVertical) return this.isVertical + `  overflow-clip o-contain br-10`;
            } else {
                if (this.isHorizontal) return this.isHorizontal + "  overflow-clip o-contain";
                else if (this.isVertical) return this.isVertical + ` overflow-clip o-contain`;
                else return `aspect-square overflow-clip o-contain`;
            }
        },
        imageSquareClasses() {
            if (this.isInset) return ` aspect-square o-cover x y br-10 overflow-clip`;
            else return "aspect-square o-cover";
        },
        imageRoundClasses() {
            return `aspect-square rounded-full overflow-clip `;
        },
        imageLandscapeClasses() {
            // if (this.isHorizontal) return `aspect-h-4 aspect-w-5 overflow-clip o-contain br-10`;
            // else if (this.isVertical) return `aspect-h-5 aspect-w-4 overflow-clip o-contain br-10`;
            // if (this.isEmail) return this.landscapeImage.image;
            let horizontal = "aspect-landscape-short";
            let vertical = "aspect-portrait-short";
            horizontal = this.isHorizontalReverse;
            vertical = this.isVerticalReverse;

            if (this.isVertical) {
                if (this.isInset) return `${[horizontal, "overflow-clip o-contain br-10"].join(" ")}`;
                else return [horizontal, "br-10"].join(" ");
            } else {
                if (this.isInset) return `${[vertical, "overflow-clip o-contain br-10"].join(" ")}`;
                else return [vertical, "br-10"].join(" ");
            }
        },
        imageFullClasses() {
            return "aspect-landscape";
        },
    },
    watch: {
        isInset: {
            handler(val) {
                if (val) this.updateSection("hideGradient", true);
                else this.updateSection("hideGradient", false);
            },
        },
        fullBleed: {
            handler(val) {
                if (val) this.$nextTick(() => this.updateSection("textAlign", "center"));
            },
        },
        imageStyle: {
            handler(val) {
                if (val) {
                    if (this.isEmail && this.isNormal && !this.hideGradient && this.fullBleed && !this.isInset) {
                    } else this.updateSection("tuckImage", false);
                }
            },
        },
        isTransparent: {
            handler(val) {
                if (val) {
                    this.updateSection("imageStyle", "normal");
                    this.updateSection("hideGradient", true);
                    this.updateSection("imageInset", false);
                } else {
                    this.updateSection("imageStyle", "normal");
                    this.updateSection("hideGradient", false);
                }
            },
        },
    },
    props: {
        image: { type: Object, default: () => ({}) },
        section: { type: Object, default: () => ({}) },
        index: { type: Number, default: 0 },
        images: { type: Array, default: () => [] },
        kind: { type: String },
        identifier: { type: String, required: true },
        maxHeight: { type: [Number, String], default: 90 },
    },
    data() {
        return {
            editImages: false,
            currentStyleIndex: 0,
            imageLoaded: false,
            hovered: false,
            tempBackgroundColor: "",
        };
    },
    methods: {
        clicked() {
            if (!this.mobile) {
                this.editImages = true;
            }
        },
        hover() {
            this.hovered = true;
        },
        cancelHover() {
            this.hovered = false;
        },
        edit() {
            this.editImages = true;
        },
        cancelEdit() {
            this.editImages = false;
        },
        toggleEdit() {
            this.editImages = !this.editImages;
        },
        toggleFullBleed() {
            if (this.fullBleed) return this.updateSection("style", "imageLeft");
            return this.updateSection("style", "fullBleed");
        },
        toggleInset() {
            return this.updateSection("imageInset", !this.imageInset);
        },
        toggleGradient() {
            return this.updateSection("hideGradient", !this.section.hideGradient);
        },
        toggleFullBleedMargin() {
            return this.updateSection("tuckImage", !this.section.tuckImage);
        },
    },
};
</script>
<style lang="scss" scoped>
.elastic-easing {
    /* Approximate an elastic easing using cubic-bezier */
    transition-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.height-primer {
    @apply height-max-100;
    @apply height-max-150;
    @apply height-max-200;
    @apply height-max-250;
    @apply height-max-300;
    @apply height-max-350;
    @apply height-max-400;
    @apply height-max-450;
    @apply height-max-500;
    @apply height-max-550;
    @apply height-max-600;
    @apply height-max-650;
    @apply height-max-700;
    @apply height-max-800;
    @apply height-max-900;
    @apply aspect-square;
}

.hortizontal {
    &-1 {
        @apply aspect-w-4 aspect-h-3;
    }

    &-2 {
        @apply aspect-w-16 aspect-h-9;
    }

    &-2 {
        @apply aspect-w-1 aspect-h-1;
    }
}

.vertical {
    &-1 {
        @apply aspect-w-3 aspect-h-4;
    }

    &-2 {
        @apply aspect-w-9 aspect-h-16;
    }

    &-3 {
        @apply aspect-w-1 aspect-h-1;
    }
}

.rotate-20 {
    transform: rotate(20deg);
}

.rotate-45 {
    transform: rotate(45deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.gradient-line {
    background: linear-gradient(to bottom, #ffffff 50%, #000000 50%);
}
</style>
