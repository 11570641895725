<template>
    <div class="tabs-component col-span-12 p-2 md:p-3">
        <div class="x text-center scrollbar-hide" style="overflow-x: scroll">
            <ul ref="tabGroup" class="tabgroup border-light d-inline-flex br-50 aic jcc relative mx-auto flex w-auto overflow-hidden border-b mb-3" @mousemove="hoverEffect" @mouseout="resetHover">
                <!--         this should slide depending on which tab is selected       -->
                <div ref="floatingBg" class="floating-bg" :style="sliderButtonStyle"></div>
                <li
                    v-for="(tab, index) in availableTabs"
                    :key="index"
                    ref="tabs"
                    :class="[tab === selectedTab ? 'selectedTab font-medium text-gray-600' : 'text-gray-400']"
                    class="block rounded-full px-1 md:px-3 py-2 transition-all hover:text-gray-900 focus:outline-none"
                    @click="selectTab(tab, index)"
                    @mouseout="resetHover"
                    @mouseover="hoverEffect(index)">
                    <span :style="`color:${tab === selectedTab ? sliderButtonStyle.color : ''}`">
                        {{ tab }}
                    </span>
                    <!--                    <template v-if="tab === selectedTab && tab.toLowerCase() === 'images'"><i class="fa fas fa-sync-alt text-base-900"></i></template>-->
                </li>
            </ul>
        </div>
        <div v-for="(tab, index) in availableTabs" :key="'tab-' + index" class="x y block">
            <transition name="fade-up">
                <template v-if="tab === selectedTab">
                    <slot :name="tab" class="x y"></slot>
                </template>
            </transition>
        </div>
    </div>
</template>

<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "Tabs",
    mixins: [styleGuideMixin],
    props: {
        initialTab: {
            type: String,
            default: "",
        },
    },
    computed: {
        sliderButtonStyle() {
            try {
                let buttonStyle = {};
                if (this.$store.getters["styleGuide/buttonStyles"]?.[0]) {
                    buttonStyle = this.$store.getters["styleGuide/buttonStyles"][0];
                } else {
                    return "";
                }
                let objectStyle = {
                    color: `${buttonStyle.color}!important`,
                    backgroundColor: buttonStyle.backgroundColor,
                    fontSize: buttonStyle.fontSize,
                    fontWeight: buttonStyle.fontWeight,
                    // padding: buttonStyle.padding,
                    // fontFamily: `${buttonStyle.fontFamily}`,
                    letterSpacing: buttonStyle.letterSpacing || 0,
                };
                if (buttonStyle.border) {
                    objectStyle.border = buttonStyle.border;
                }
                return objectStyle;
            } catch (e) {
                return {};
            }
        },
    },
    data() {
        return {
            selectedTab: this.initialTab,
            availableTabs: [],
            mousePosition: 0, // New property to hold mouse x position
        };
    },
    mounted() {
        this.availableTabs = Object.keys(this.$slots).filter(name => name !== "default");
        if (!this.selectedTab && this.availableTabs.length > 0) {
            this.selectedTab = this.availableTabs[0];
        }
        this.$nextTick(() => {
            this.initializeFloatingBg(); // Initialize floating background
        });
    },
    methods: {
        async hoverEffect(index) {
            this.$nextTick(async () => {
                const hoverElement = this.$refs.tabs[index];
                const floatingBg = this.$refs.floatingBg;
                const parentRect = this.$refs.tabGroup.getBoundingClientRect();
                if (hoverElement && floatingBg) {
                    const rect = hoverElement.getBoundingClientRect();
                    let widthAlt = 20;
                    if (this.mobile) {
                        widthAlt = 10;
                    }
                    floatingBg.style.left = `${rect.left + 10 - parentRect.left - widthAlt / 2}px`;
                    floatingBg.style.width = `${rect.width + widthAlt}px`;
                    await setTimeout(() => {
                        // floatingBg.style.left = `${rect.left + 10 - parentRect.left - widthAlt / 2}px`;
                        floatingBg.style.width = `${rect.width - 10 + widthAlt / 2}px`;
                    }, 300);
                    // floatingBg.style.left = `${rect.left - parentRect.left - widthAlt / 2}px`;
                }
            });
        },
        resetHover() {
            this.initializeFloatingBg();
        },
        initializeFloatingBg() {
            this.$nextTick(() => {
                const initialIndex = this.availableTabs.indexOf(this.selectedTab);
                const initialElement = this.$refs.tabs[initialIndex];
                const floatingBg = this.$refs.floatingBg;
                const parentRect = this.$refs.tabGroup.getBoundingClientRect();
                if (initialElement && floatingBg) {
                    const rect = initialElement.getBoundingClientRect();
                    floatingBg.style.left = `${rect.left - parentRect.left}px`;
                    floatingBg.style.width = `${rect.width}px`;
                }
            });
        },
        selectTab(tab, index) {
            if (tab === this.selectedTab) return;

            this.selectedTab = tab;
            this.$nextTick(() => {
                const selectedElement = this.$refs.tabs[index];
                const floatingBg = this.$refs.floatingBg;
                const parentRect = this.$refs.tabGroup.getBoundingClientRect();
                let w = 10;
                if (this.mobile) w = 5;
                if (selectedElement && floatingBg) {
                    const rect = selectedElement.getBoundingClientRect();
                    floatingBg.style.left = `${rect.left - parentRect.left + w}px`;
                    floatingBg.style.width = `${rect.width - w * 2}px`;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// keyframes for jelly animation with px-3
@keyframes jelly {
    0%,
    100% {
        @apply px-2;
    }
    50% {
        @apply px-4;
    }
}

.tabgroup {
    position: relative;
    transition: all 1s ease-out;
    background: rgba(0, 0, 0, 0.02);
    @apply px-1;
    //ul:hover li:not(.selectedTab){
    //background: rgba(0, 0, 0, 0.05); // gray background

    //::before{
    // Your existing styling for ::before can go here
    //}
    //}
    &:hover {
        animation: jelly 1s;
        @apply ease-back;
        //@apply px-3;
        &.floating-bg {
            transform: scale(2.05) !important;
            background: red !important;
        }

        li {
            position: relative;
            //background: rgba(0, 0, 0, 0.02);

            &.selectedTab {
                ::before {
                    transition: all 0.3s ease-in-out;
                    transition-delay: 0.5s;
                    transform: scale(1);
                    opacity: 1;
                    //background: white;
                    border: 0px solid rgba(0, 0, 0, 0);
                    background: rgba(0, 0, 0, 0.05);
                }
            }

            &:hover {
                ::before {
                    transition: all 0.3s ease-out;
                    @apply ease-back;
                    transition-delay: 0.4s;
                    opacity: 1;
                    //background: white;
                    background: rgba(0, 0, 0, 0);
                }
            }
        }
    }

    li {
        position: relative;
        //background: rgba(0, 0, 0, 0.02);

        &.selectedTab {
            position: relative;

            &:hover {
                //background: inherit;

                &::before {
                    background: none !important;
                }
            }

            ::before {
                position: absolute;
                z-index: -8 !important;
                top: -5px;
                right: -20px;
                bottom: -5px;
                left: -20px;
                //height: auto;
                //height: 100%;
                //padding: ;
                content: " ";
                transition: all 0.3s ease-in-out;
                transform: scale(0);
                opacity: 0;
                border: 0px solid;
                border-radius: 50px;
                background: rgba(0, 0, 0, 0.05);

                &:not(.selectedTab:hover) {
                    background: rgba(0, 0, 0, 0.02);
                }
            }

            // if the selected tab is not hovered
        }
    }

    .floating-bg {
        position: absolute;
        z-index: 2;
        top: 2px;
        right: 10px;
        bottom: 2px;
        left: 0px;
        transition:
            all 0.7s cubic-bezier(0.22, 1, 0.36, 1),
            box-shadow 0.3s ease; // Modified cubic-bezier
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        //background: white;
        background: rgba(255, 255, 255, 0.3);
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0); // Shadow for lifted feel
    }

    .floating-bg {
        transition:
            left 0.5s cubic-bezier(0.68, -0.25, 0.27, 1.25),
            width 0.3s ease-out,
            box-shadow 0.3s ease,
            background 1s cubic-bezier(0.68, -0.25, 0.27, 1.25),
            transform 0.3s cubic-bezier(0.3, -0.25, 0.27, 1.25),
            border 0.3s cubic-bezier(0.68, -0.25, 0.27, 1.25);

        &:hover {
            transform: scale(1.2); // Slight increase in size on hover
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); // Increased shadow on hover
        }
    }

    li {
        z-index: 2;

        min-width: 150px;
        //shadow-inner

        @apply w-100;
        @apply text-center;
        @apply width-max-200;
        @apply relative;
        border-radius: 0px 0px 0px 0px;

        &.selectedTab {
            position: relative;
            z-index: 4;
            //
            //&:after{
            //    position: absolute;
            //    z-index: -3;
            //    top: 0;
            //    left: 0;
            //    width: 100%;
            //    height: 100%;
            //    content: "";
            //    border-radius: 50px;
            //    background: rgba(0, 0, 0, 0.2);
            //}

            //background:red;
            //z-index: 0;
        }

        &:first-child {
            border-radius: 50px 0px 0px 50px;
        }

        &:last-child {
            border-radius: 0px 50px 50px 0px;
        }

        span {
            position: relative;
            z-index: 5;
        }
    }

    &:hover {
        .floating-bg {
            //transition: background 1s cubic-bezier(0.68, -0.25, 0.27, 1.25);
            transform: scale(1.05) !important;
            border: 1px solid rgba(0, 0, 0, 0.2);
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Increased shadow on hover
        }
    }
}

.tabs-component ul li {
    cursor: pointer;
}
</style>
