<template>
    <div class="relative f aic jcc">
        <div :class="`absolute opacity-5 animate__infinite duration-10 inline-block w-3 h-3 rounded-full bg-${color}-500 animate__heartBeat`"></div>
        <div :class="`absolute opacity-10 animate__infinite duration-9 inline-block w-2 h-2 rounded-full bg-${color}-500 animate__heartBeat`"></div>
        <div style="box-shadow:0px -1px 1px white" :class="`animate__infinite shadow duration-8 inline-block w-1 h-1 rounded-full bg-${color}-800 animate__heartBeat`"></div>
    </div>
</template>
<script>
export default {
    name: "PulsingLight",
    props: {
        color: {
            type: String,
            default: "green",
        },
    },
};
</script>
