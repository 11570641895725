let formatWithNoBreak = [
    "interests",
    "occupation",
    "values",
    "lifestyle",
    "attitudes",
    "age_range",
    "voice",
    "rhetorical_strategies",
    "occupation",
    "values",
    "lifestyle",
    "attitudes",
    "age_range",
    "voice",
    "income_level",
    "about",
    "rhetorical_strategies",
    "discovery_pathways",
    "gender",
    "customer_journey_stages",
    "education",
    "pain_points",
    "behaviors",
    "goals",
    "demographics",
    "decision_drivers",
    "brand_interaction_history",
    "media_consumption",
    "interests",
    "influencers_and_role_models",
];
let formatWithBreaks = [
    "target_audience",
    //
    "dos",
    "psychographics",
    "donts",
    "value_propositions",
    "selling_points",
    "purchase_motivations",
    "target_audience",
    "sentence_structure",
    "stylistic_features_analysis",
    "punctuation",
    "pangram",
    "tone_variation",
    "rhetorical_devices",
];
function formatObjectToString(obj, depth = 0, parentKey = "") {
    try {
        const formatKey = (key, depth, parentKey) => {
            let prefix = `${"#".repeat(depth + 1)} `;
            let string = `${key
                .replace(/([A-Z])/g, " $1")
                .replace(/_/g, " ")
                .trim()
                .replace(/\b\w/g, l => l.toUpperCase())}`;
            if (key === "tone") return `**TONE:** `;
            if ((key === "guidelines" && !depth) || depth === 0) return "# Guidelines";
            if (obj && obj.name) {
                if (key === "guidelines" && depth === 0) return `### Copywriting ${obj.name}`;
                if (key === "guidelines" && (depth === 1 || depth > 2)) return `**Copywriting rules for ${obj.name}:** `.toUpperCase();
                if (key === "avoid") return `**Avoid in ${obj.name}:** `.toUpperCase();
                if (key === "examples") return `**Examples of ${obj.name}:** `.toUpperCase();
            }
            if (parentKey === "personas") {
                if (key === "name") return `### ${string}`;
                if (key === "about") return `**ABOUT THIS PERSONA:**\n`;
                return `**${string.toUpperCase()}:**`;
            }
            if (key === "overview") return "### Overview";
            if (parentKey === "overview") {
                if (key === "name") return "";
                if (key === "personas") return `## ${string}`;
                if (key === "value_propositions") return `## ${string}`;
                return `**${string}:** `;
            } else if (parentKey === "guidelines" && key !== "guidelines") {
                return `## Guidelines`;
                // return prefix + string;
            } else if (key === "target_audience") {
                return `## ${string}`;
            } else if (key === "about" && parentKey !== "personas") {
                return `#### `;
                // return prefix + string;
            } else if (key === "personas") {
                return `#### ${string}`;
            } else if (parentKey === "paragraphs") {
                prefix = `### ${parentKey}`;
                return prefix + string;
            } else if (parentKey === "punctuation") {
                return `**${string}:** `;
            } else {
                prefix = "### ";
                return prefix + string;
            }
            if (depth === 1) return `## **${string}:** ${parentKey}`;
            if (depth === 2) return `**${string}:** `;
            // if (parentKey depth === 3) return `**${string}:** `;
            // if (depth === 4) return `  **${string}:** `;
            // if (depth === 5) return `\t **${string}:** `;
            // if (depth === 6) return `\t\t **${string}:** `;
            // else if (depth === 2) prefix = "##### ";
            // else if (depth >= 3) prefix = "###### ";
            return string;
            return prefix + string;
        };

        const escapeString = str => str.replace(/(["\\])/g, "\\$1");

        const formatValue = (value, depth, key, parentKey) => {
            if (typeof value === "string") return escapeString(value);
            else if (Array.isArray(value) && value?.[0]?.name && value?.[0]?.value) {
                let toneStringArray = [];
                value.forEach(item => {
                    let name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
                    toneStringArray.push(`${item.value}% ${name}`);
                });
                return `${toneStringArray.join(", ")}`;
            } else if (Array.isArray(value) && typeof value?.[0] === "string") {
                if (key === "examples") return "\n" + value.map(m => '"' + m + '"').join("\n") + "\n";
                else return "\n — " + value.join("\n — ") + "\n";
            } else if (Array.isArray(value)) return value.map(v => `\n${" ".repeat((depth + 1) * 2)}${typeof v === "object" ? formatObjectToString(v, depth + 1, key) : "- " + v}`).join("");
            else if (typeof value === "object" && value !== null) return `\n${formatObjectToString(value, depth + 1, key)}`;
            return value !== null ? value.toString() : "null";
        };

        const indent = " ".repeat(depth * 2);
        let entries = Object.entries(obj);

        // Sorting the entries based on the required order
        let ignoreKeys = [
            "image",
            "image_prompt",
            "imagePrompt",
            "physical_appearance",
            "analogous_comparison",
            // "brand_interaction_history",
        ];
        let sortOrder = ["name", "tone", "guidelines", "avoid", "examples", ...ignoreKeys];
        if (!depth || depth === 0) sortOrder = ["name", "about", "voice", "tone", "overview", "guidelines", "avoid", "value_propositions", "target_audience", "selling_points", "purchase_motivations", "rhetorical_strategies", "punctuation", "personas", "dos", "donts", "examples", "pangram", "stylistic_features_analysis", "advanced"];
        else if (depth === 1) sortOrder = ["name", "about", "tone", "voice", "overview", "guidelines", "personas", "avoid", "value_propositions", "target_audience", "selling_points", "purchase_motivations", "rhetorical_strategies", "punctuation", "personas", "dos", "donts", "examples", "pangram", "stylistic_features_analysis", "advanced"];

        if (parentKey === "personas") {
            sortOrder = ["name", "about", "pain_points", "goals", "demographics", "psychographics", "physical_appearance", "interests", "decision_drivers", "media_consumption", "influencers_and_role_models", "behaviors", "brand_interaction_history", "image_prompt", "image", ...ignoreKeys];
        }

        entries.sort((a, b) => sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]));

        if (parentKey === "personas") formatWithNoBreak.push(...["name", "about", "psychographics"]);

        const formattedEntries = entries.map(([key, value]) => {
            // const formatKey = key => key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ");
            const prefix = ""; // Uncomment if needed: if (depth >= 1) prefix = "\t- ";

            if (ignoreKeys.includes(key)) {
                return "";
            }
            if (formatWithNoBreak.includes(key)) {
                if (value !== null) {
                    let string = formatKey(key, 3, parentKey) + " ";
                    if (Array.isArray(value)) {
                        string += value.join(", ");
                    } else if (typeof value === "object") string += this.formatObjectToString(value, depth + 1, key);
                    else if (typeof value === "string") string += value;
                    else if (typeof value === "number") string += value;
                    // ${prefix}

                    string = string.replace("\n\n", "\n");
                    return string;
                }
            } else if (formatWithBreaks.includes(key)) {
                if (value !== null) {
                    let string = "";
                    if (Array.isArray(value)) {
                        string += formatKey(key, 3, parentKey);
                        value = "\n — " + value.join("\n — ");
                        string += "\n" + value;
                    } else if (typeof value === "object") {
                        string += formatKey(key, 2, parentKey);
                        string += "\n" + this.formatObjectToString(value, depth + 1, key);
                    } else if (typeof value === "string") string += " " + value;
                    else if (typeof value === "number") string += " " + value;
                    // ${prefix}
                    // string += " " + value + "\n";
                    string = string.replace("\n\n", "\n") + "\n";
                    return string;
                }
            } else if (key === "paragraphs" || key === "headers" || key === "buttons" || key === "emails") {
                // return `${formatValue(value, depth, key, key)}`;
            } else if (key === "overview") {
                return `# Overview:\n${formatValue(value, depth, key, parentKey)}`;
            } else if (key === "name") {
                if (parentKey !== "personas") return `## ${formatValue(value, depth, key, key)} ${parentKey} `;
                return `${formatKey(key, 0, parentKey)}${formatValue(value, 3, key, key)}`;
            } else if (typeof value === "string") {
                return formatKey(key, 3, parentKey) + ` ${formatValue(value, depth, key, parentKey)}`;
            } else if (typeof value === "number") {
                return `${formatKey(key, depth, parentKey)} ${formatValue(value, depth, key, parentKey)}`;
            } else {
                let fixedKey = key;
                if (["headers", "paragraphs", "buttons", "emails"].includes(parentKey)) fixedKey = parentKey + key;
                return `${formatKey(fixedKey, 3, parentKey)} ${formatValue(value, depth, key, parentKey)}`;
            }
        });

        return formattedEntries
            .join("\n")
            .replace(/ - ##/g, "##")
            .replace("\n\n", "\n")
            .replace(/  ### Name/g, "#####")
            .replace("\t**", "**");
    } catch (e) {
        console.error("Error in formatObjectToString", e);
    }
}

export { formatObjectToString };
