<template>
    <div v-if="brand">
        <router-link :to="link">
            <div class="relative br-20 overflow-hidden border-light" :style="`background:${background}`">
                <div class="aspect-w-4 aspect-h-3 x">
                    <img v-if="image" :alt="brand" :src="img" class="object-cover br-10 overflow-hidden" />
                </div>
                <template v-for="height in [200, 100, 75, 50, 50]">
                    <image-gradient v-if="image" :key="image" :image="image" class="absolute bottom z-0 left right" :height="height"></image-gradient>
                </template>
                <div class="text-left absolute z-2 bottom p-2 px-3 w-75" :class="{ white: image }">
                    <h1 v-if="name" class="f-40 fwb">{{ name }}</h1>
                    <p v-if="description" class="line-clamp-2 line-height-smaller">{{ description }}</p>
                    <!--                    <p v-if="brand?.url" class="line-clamp-2 line-height-smaller">{{ brand.url }}</p>-->
                    <!--                    <div v-if="brand?.categories" class="line-clamp-2 gap-1 f flex-wrap line-height-smaller">-->
                    <!--                        <base-badge class="text-uppercase" :style="`background:${background};color:black;border:1px solid black; font-size:10px;`" v-for="category in brand.categories" :text="category"></base-badge>-->
                    <!--                    </div>-->
                </div>
            </div>
        </router-link>
    </div>
</template>
<script>
import ImageGradient from "@/components/generative_templates/ImageGradient.vue";
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";

export default {
    name: "BrandCard",
    components: { BaseBadge, ImageGradient },
    data() {
        return {
            background: null,
            pastelColorArray: [
                "#ffd1dc", // Pastel pink
                "#ffadad", // Soft red
                "#ffd6a5", // Peach
                "#fdffb6", // Pale yellow
                "#caffbf", // Mint
                "#9bf6ff", // Sky blue
                "#a0c4ff", // Periwinkle
                "#bdb2ff", // Lavender
                "#ffc6ff", // Pink lavender
                "#fffffc", // Off white
            ],
        };
    },
    mounted() {
        this.background = this.randomPastelColor();
        // if (this.brand?.image?.backgroundColor) {
        // this.background = this.color;
        if (this.brand?.image?.backgroundColor) this.background = this.brand?.image?.color || this.brand?.divStyle?.backgroundColor;
        // }
    },
    methods: {
        randomPastelColor() {
            const randomIndex = Math.floor(Math.random() * this.pastelColorArray.length);
            return this.pastelColorArray[randomIndex];
        },
    },
    computed: {
        link() {
            if (this.image) {
                return `/styleGuide/${this.brand.id}`;
            } else {
                return `/styleGuide/${this.brand.match}`;
            }
        },
        image() {
            return this.brand.image;
        },
        id() {
            return this.$route.id;
        },
        img() {
            return this.brand?.image?.url || this.brand.image;
        },
        description() {
            return this.brand?.description;
        },
        name() {
            return this.brand?.name;
        },
    },
    props: {
        randomStyle: {
            type: Object,
            default: false,
        },
        brand: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            required: false,
        },
    },
};
</script>
