<template>
    <div class="brand-bg">
        <transition name="fade-up" mode="out-in" appear>
            <div v-if="!selectedItem" class="columns-1 sm:columns-2 md:columns-3 lg:columns-4 gap-3 space-y-4 p-4 f-17 leading-normal bg-brand" id="makeMasonry">
                <template :key="index" v-for="(suggestion, index) in filteredSuggestions.slice(0, 100)">
                    <SuggestedCampaignCard :text="suggestion.text" v-if="suggestion?.randomProduct" :product="suggestion.randomProduct" @click.prevent="generateFromSuggestion(suggestion)" />
                </template>
            </div>
            <div class="f aic jcc vh-50" v-else>
                <div>
                    <h1 class="animated fadeInUpSmooth duration-4 fwb f-50 mb-4 mw-750 text-pretty text-center d-5">{{ selectedItem.text }}</h1>
                    <div class="x text-center mb-4">
                        <loading-spinner class="animated zoomIn mx-auto" color="var(--base-color)" background="" />
                    </div>
                    <div class="floating-item">
                        <SuggestedCampaignCard class="animated zoomIn duration-2 mw-500 d-20 mx-auto" :text="selectedItem.text" v-if="selectedItem?.randomProduct" :product="selectedItem.randomProduct" @click.prevent="generateFromSuggestion(suggestion)" />
                    </div>
                    <div class="x text-center mx-auto my-4">
                        <a href="#" @click="selectedItem = null" class="animated zoomIn f-20 mx-auto text-center text-brand underline">Back to suggestions</a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductBadge from "@/components/styleGuide/Images/ProductBadge.vue";
import SuggestedCampaignCard from "@/components/generative_templates/Pages/SuggestedCampaignCard.vue";
import BrandProductCard from "@/views/BrandProductCard.vue";
import ChatLoader from "@/components/chat/old/ChatLoader.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";

export default {
    name: "GenerateIdeas",
    components: { LoadingSpinner, ChatLoader, BrandProductCard, SuggestedCampaignCard, ProductBadge },
    props: {
        products: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedSegment: "All",
            selectedItem: null,
            strategies: [
                "drip campaign",
                "email campaign",
                // "landing page",
            ],
        };
    },
    computed: {
        ...mapGetters("styleGuide", ["buttonStyles"]),
        productColor() {
            let color = this.buttonStyles?.[0]?.backgroundColor || "#fff";
            return color;
        },
        // Filter the suggestions based on the selected segment (or show all if "All" is selected
        filteredSuggestions() {
            return this.selectedSegment === "All" ? this.generateSuggestions() : this.generateSuggestions().filter(suggestion => suggestion.includes(this.selectedSegment));
        },
        segments() {
            // Generate a list of segments based on the product array and other useful segments
            let segments = ["First-Time Buyers", "Non-purchasers", "Repeat Customers", "High-Spenders", "Subscribers", "Returning customers", "Email subscribers", "Website visitors"];
            segments = segments.map(segment => `<strong class="bdg-pnk">${segment}</strong>`);
            this.products.forEach(product => {
                if (product.title) {
                    segments.push(`people who purchased ${this.processTitle(product.title)},`);
                    // segments.push(`Website visitors who viewed <strong>${this.processTitle(product.title)}</strong>`);
                }
            });
            // randomize the order of segments
            segments = segments.sort(() => Math.random() - 0.5);
            return segments;
        },
    },
    methods: {
        suggestedImage(product) {
            let imgUrl = product?.images?.[0]?.url || "";
            // find the image in the images array
            imgUrl = imgUrl.replace("Original", "public");
            return imgUrl;
        },
        generateFromSuggestion(suggestion) {
            // get innerText from suggestion
            let { text, randomProduct } = suggestion;
            text = text.replace(/<[^>]*>?/gm, "");
            this.selectedItem = { text, randomProduct };
            console.log(text);

            this.$emit("generate-from-suggestion", text);
        },
        generateSuggestions() {
            let suggestions = [];
            for (let i = 0; i < 50; i++) {
                this.segments.forEach(segment => {
                    let randomProduct = this.getRandomProduct();
                    let strategy = this.getStrategyForSegment(segment);
                    let action = ["Create a", "Make a", "Generate a", "Generate"];
                    let randomAction = action[Math.floor(Math.random() * action.length)];
                    let spotlight = ["featuring", "highlighting", "spotlighting", "to educate customers about", "upselling customers on"];
                    let randomSpotlight = spotlight[Math.floor(Math.random() * spotlight.length)];
                    let suggestionChoices = [
                        `${randomAction} <strong  class="py-0 rounded-full  link-color" style="font-weight:800;">${strategy}</strong> for ${segment} ${randomSpotlight} ${this.processTitle(randomProduct?.title) || ""}.`, //color:#ffed65; text-green-200
                        `${randomAction} <strong class="py-0 rounded-full  link-color" style="font-weight:800;">${strategy}</strong> ${randomSpotlight} ${this.processTitle(randomProduct?.title) || ""}.`, //color:#ffed65; text-green-200
                        `${randomAction} <strong class="py-0 rounded-full  link-color" style="font-weight:800;">${strategy}</strong> ${randomSpotlight} ${this.processTitle(randomProduct?.title) || ""}.`, //color:#ffed65; text-green-200
                        // `${randomAction} <span class="bdg-sgmt border-light">${strategy}</span> for ${segment} ${randomSpotlight} ${this.processTitle(randomProduct?.title) || ""}.`,
                        //   `${randomAction} <span class="bdg-sgmt border-light">${strategy}</span> ${randomSpotlight} ${this.processTitle(randomProduct?.title) || ""}.`,
                        //   `${randomAction} <span class="bdg-sgmt border-light">${strategy}</span> ${randomSpotlight} ${this.processTitle(randomProduct?.title) || ""}.`,
                        //
                    ];
                    let suggestion = suggestionChoices[Math.floor(Math.random() * suggestionChoices.length)];
                    suggestion = suggestion.replace("a email", "an email");
                    suggestion = suggestion.replace("enerate email", "enerate an email");
                    suggestions.push({ text: suggestion, randomProduct });
                });
            }
            return suggestions;
        },
        processTitle(title) {
            if (title) {
                let processedTitle = title;
                // processedTitle = processedTitle.split("-")[0];
                processedTitle = processedTitle.split("{")[0];
                return `<strong class="py-0 rounded-full link-color" style="">${processedTitle.trim()}</strong>`; //color:#5FFF33FF; text-green-200
                // return `<strong class="py-0 rounded-full px-2 whitespace-nowrap text-green-300" style="color:${this.productColor}!important;">${processedTitle.trim()}</strong>`;
            }
        },
        getRandomProduct() {
            const randomIndex = Math.floor(Math.random() * this.products.length);
            return this.products.filter(p => {
                let { name, title } = p;
                name = title || name;
                let filterTerms = ["free", "test", "ecocart", "route", "hide"];
                if (name) {
                    return !filterTerms.some(term => name.toLowerCase().includes(term));
                }
                return true;
            })[randomIndex];
        },

        getStrategyForSegment(segment) {
            // Choosing between email campaign and landing page
            return this.strategies[Math.floor(Math.random() * this.strategies.length)];
        },
    },
};
</script>
<style>
.bdg-sgmt {
    @apply text-gray-900 bg-pink-100 rounded-full px-2 py-0 break-inside-avoid;
}
.bdg-sgmt {
    @apply text-gray-900 bg-sky-100 font-bold rounded-full px-2 whitespace-nowrap;
}
.multiline-background {
    background: lightblue;
    line-height: 1.5; /* Adjust line height as needed */
    display: inline; /* Inline or inline-block */
    //padding: 5px; /* Optional padding */
    box-decoration-break: clone !important;
    -webkit-box-decoration-break: clone !important; /* For older versions of Safari */
}
</style>
