<template>
    <div class="border-bottom mx-auto mb-0 py-4 text-left border-top">
        <div class="container py-3 px-3 md:px-0">
            <div class="grid grid-cols-12">
                <div class="col-span-12 md:col-span-9">
                    <span v-if="styleGuide?.name" :style="aboutHeader" class="d-block !text-xl mb-3" style="font-size: 50px !important">{{ styleGuide.name }}</span>
                    <span v-if="styleGuide?.description" :style="aboutDescription" class="d-block mb-0 mw-600 !font-md">{{ styleGuide.description }}</span>
                    <div v-if="styleGuide?.categories" class="py-3 overflow-hidden">
                        <h4>Categories</h4>
                        <div :class="{ 'flex-wrap': !mobile, 'overflow-x-scroll scrollbar-hide': mobile }" class="f gap-3 mt-3">
                            <RouterLink v-for="category in styleGuide.categories" :class="{ 'px-3 py-1 border border-gray-900 f-20 br-20 whitespace-nowrap line-height-smaller d-inline f-11': !buttonStyle }" :to="`/categories/${category}`" class="button-2 !rounded-full py-2 !f-17 whitespace-nowrap">{{ category }}</RouterLink>
                        </div>
                    </div>
                    <div class="f x gap-3 aic flex-wrap">
                        <div>
                            <a v-if="styleGuide?.url" :href="styleGuide.url" :style="`color:${linkColor}!important;font-family:${buttonStyle?.fontFamily}`" class="fwb line-height-small mb-0 mt-3 inline-block mb-3" target="_blank">
                                <i class="fal fa-link mr-2"></i>
                                {{ styleGuide.url }}
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="!mobile" class="col-span-3">
                    <div class="relative mt-5">
                        <div v-if="styleGuide?.screenshot" class="mw-350 mx-auto x inline-block">
                            <CollapsableMenuItem max-height="500">
                                <template #title>
                                    <div class="h6 border-light py-2 pl-2 br-5 cursor-pointer">
                                        <i class="fal fa-camera mr-2"></i>
                                    </div>
                                </template>
                                <div class="relative text-center">
                                    <div v-if="styleGuide?.screenshot" class="mx-auto inline-block bg-gray-100 text-center p-5 br-20">
                                        <img :src="styleGuide.screenshot" class="mw-600 mx-auto x br-10" />
                                    </div>
                                </div>
                            </CollapsableMenuItem>
                        </div>
                        <template v-if="$store.state?.styleGuide?.css">
                            <div v-for="(item, key) in $store.state?.styleGuide?.css" class="mw-350 mx-auto">
                                <CollapsableMenuItem max-height="500">
                                    <template #title>
                                        <div class="h6 border-light py-2 pl-2 br-5 cursor-pointer">{{ key }}</div>
                                    </template>
                                    <div class="whitespace-pre-wrap break-all f-13 overflow-x-scroll block">{{ item }}</div>
                                </CollapsableMenuItem>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <debug-object v-if="styleGuide" :object="styleGuideAlphabeticalKeys"></debug-object>
            <!--            {{ colors.text_colors }}-->
            <!--            {{ linkColor }}-->
            <!--                <CompletionTest />-->
        </div>
    </div>
</template>
<script>
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import styleGuideTextMixin from "@/mixins/StyleGuideMixins/StyleGuideTextMixin";

export default {
    name: "StyleGuideAboutBrand",
    components: { CollapsableMenuItem, DebugObject },
    mixins: [styleGuideMixin],
    methods: {},

    computed: {
        aboutHeader() {
            let hs = this.mostCommonCombo?.header;
            let style = [`letter-spacing:normal!important;`];
            if (hs) {
                style.push(`font-family:${hs.fontFamily}!important;`);
                style.push(`font-size:${hs.fontSize}!important;`);
                style.push(`font-weight:${hs.fontWeight}!important;`);
            }
            return style.join(" ");
        },
        aboutDescription() {
            let hs = this.mostCommonCombo?.p;
            let style = [`letter-spacing:normal!important;`];
            if (hs) {
                style.push(`font-family:${hs.fontFamily}!important;`);
                style.push(`font-size:${hs.fontSize}!important;`);
                style.push(`font-weight:${hs.fontWeight}!important;`);
            }
            return style.join(" ");
        },
        buttonStyle() {
            try {
                let buttonStyles = this.buttonStyles;
                let buttonStyle = {};
                if (buttonStyles?.length > 0) {
                    buttonStyle = buttonStyles[0];
                }
                return buttonStyle;
            } catch (e) {
                console.error("Button not working");
            }
        },
        styleGuideAlphabeticalKeys() {
            if (this.styleGuide.base) {
                const sortKeys = obj => {
                    const keys = Object.keys(obj).sort();
                    const sortedObject = {};

                    keys.forEach(key => {
                        if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
                            sortedObject[key] = sortKeys(obj[key]); // Recursion for nested objects
                        } else {
                            sortedObject[key] = obj[key];
                        }
                    });

                    return sortedObject;
                };

                return sortKeys(this.styleGuide);
            }
        },
    },
};
</script>
