<template>
    <div class="mt-3 gap-3 relative x z-0 br-20" style="background: inherit">
        <EditSectionIndicator v-if="hovered" key="headerText" class="z-0 absolute p-fill" />
        <transition name="fade-up">
            <div v-if="editButton" class="bg-gray-100 divider-x divide-x overflow-hidden divide-y grid grid-cols-12 br-20 mt-3 border-light z-1 shadow-2xl">
                <div class="col-span-12 f aic jcc flex-wrap gap-4 px-3 py-1 fwb">
                    <h4 class="mb-0">Choose button</h4>
                    <input class="x border-light mx-0" v-model="editText" @blur="saveText" @keydown.enter="saveText" />
                </div>

                <div v-for="(b, i) in buttonStyles" class="col-span-12 md:col-span-6 f aic jcc border-light bg-snow flex-wrap gap-4 p-3">
                    <a :style="[b]" class="inline-block x animated duration-3 fadeInUpSmooth d-2 shadow text-center" href="#" @click.prevent="pickButton(i)">Shop now</a>
                </div>
            </div>
        </transition>
        <a v-if="buttonText" :style="[buttonCss, sizeOverride]" style="background-image: none !important" class="inline-block animated duration-1 fadeInUpSmooth d-2 z-5" href="#" @mouseenter="hovered = true" @mouseleave="hovered = false" @click.prevent="clickButton()">
            {{ buttonText }}
        </a>
    </div>
</template>
<script>
import EditSectionIndicator from "@/components/generative_templates/Editing/EditSectionIndicator.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import { mapGetters } from "vuex";
export default {
    name: "SectionButton",
    components: { EditSectionIndicator },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin],
    props: {
        buttonText: {
            type: String,
            default: "Shop now",
        },
        defaultSize: {
            type: [String, Number],
            default: "",
        },
        section: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
            default: 0,
        },
        css: {
            type: [String, Object],
            default: "",
        },
        identifier: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            hovered: false,
            editButton: false,
            editText: "",
        };
    },
    mounted() {
        this.editText = this.buttonText;
    },
    computed: {
        ...mapGetters("styleGuide", ["buttonStyles"]),
        sizeOverride() {
            if (this.defaultSize) {
                return `font-size: ${this.defaultSize}px!important;`;
            }
            return "";
        },
    },
    methods: {
        saveText() {
            this.updateSection("button.text", this.editText);
            this.editButton = false;
        },
        clickButton() {
            // findContrastingColor(imageColor);
            this.editButton = true;
        },
        pickButton(button) {
            this.updateSection("buttonIndex", button);
            // this.updateStreamProp(`result.sections.${this.index}.buttonIndex`, button);
            this.editButton = false;
        },
    },
};
</script>
