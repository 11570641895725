<template>
    <div class="x overflow-hidden">
        <div v-if="showHeaderParent && !inline" class="menu_section">
            <a is="div" v-if="parentId" :href="parentId" class="block mt-5 p-2 border-light br-10 mb-3 text-gray-900 hover:text-gray-700 bg-gray-50 mt-3 hover:shadow hover:bg-snow cursor-pointer">
                <p v-if="parentCampaign?.name" class="line-height-smaller fwb">{{ parentCampaign.name }}</p>
                <p v-else-if="result?.campaignId" class="line-height-smaller fwb">{{ result.campaignId }}</p>
                <h6 class="fwn o-5 mt-2 f-11">Parent Campaign</h6>
            </a>
        </div>
        <!--        <a href="#" v-if="result.relevant_image_indexes" class="block mt-5 p-2 border-light br-10 text-center fwb mx-2 shadow" @click.prevent="makeAGif">Make a gif</a>-->
        <div v-if="name && !isEmail" class="menu_section">
            <h6>Name</h6>
            <p>{{ name }}</p>
        </div>

        <div v-if="description" class="menu_section">
            <h6>Description</h6>
            <p>{{ description }}</p>
        </div>
        <div v-if="isEmail && inline && subject" class="menu_section f jcb aic bg-snow">
            <div class="f fc" v-if="subject && preview">
                <p v-if="brandName" class="fwb f-20">{{ brandName }}</p>
                <p class="fwb f-15 line-clamp-1 mw-450" v-if="subject">{{ subject }}</p>
                <p class="f-15 o-5 line-clamp-1 mw-450" v-if="preview">{{ preview }}</p>
            </div>
        </div>
        <transition name="fade-up">
            <SideBarSections v-if="sections?.length > 0 && !inline" :sections="sections" class="x" />
        </transition>
        <transition name="fade-up">
            <div v-if="!sections && thinking && thinking?.thoughts?.length > 0 && !inline">
                <SideBarThoughts :thinking="thinking" class="x" />
            </div>
        </transition>

        <ItemCounts v-if="!isEmail" :item-counts="itemCounts" />
    </div>
</template>
<script>
import ItemCounts from "@/components/generative_templates/Campaigns/ItemCounts.vue";
import { mapGetters } from "vuex";
import SideBarSections from "@/components/generative_templates/Campaigns/SideBarSections.vue";
import SideBarThoughts from "@/components/generative_templates/Campaigns/SideBarThoughts.vue";
import ArrayToBulletPoints from "@/components/generative_templates/Campaigns/ArrayToBulletPoints.vue";

export default {
    name: "CampaignHeader",
    components: { ArrayToBulletPoints, SideBarThoughts, SideBarSections, ItemCounts },
    props: {
        result: {
            type: Object,
            default: () => {},
        },
        inline: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "",
        },
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            prntId: "",
        };
    },
    async mounted() {
        await this.fetchParent();
    },
    methods: {
        async fetchParent() {
            // setTimeout(() => {
            // this.$nextTick(async () => {
            //     console.log("Fetching from CampaignHeader");
            // if (this.campaignId) this.prntId = await fetchData("campaigns", this.campaignId);
            // });
            // }, 1000);
        },
    },
    computed: {
        ...mapGetters("styleGuide", ["images"]),
        ...mapGetters("stream", ["campaignData", "showCampaign", "campaigns"]),
        showHeaderParent() {
            return this.parentCampaign || this.result?.campaignId;
            return false;
        },
        brandName() {
            return this.$store.state?.styleGuide?.styleGuide?.name;
        },
        campaignId() {
            return this.result?.campaignId || this.data?.campaignId;
        },
        description() {
            return this.result?.description;
        },
        sections() {
            return this.result?.sections || this.result?.result?.sections || [];
        },
        thinking() {
            if ((this.thoughts && this.reasoning) || this.thinking) {
                if (this.thinking && this.thinking?.thoughts) return this.thinking;
                return {
                    thoughts: this.thoughts || [],
                    reasoning: this.reasoning || [],
                    criticism: this.criticism || [],
                    plan: this.planning || [],
                    speak: this.speak || "",
                };
            }
            // if (this.result?.thinking || this.result?.thinking?.thoughts || this.result?.result?.thinking) {
            //     return this.result?.thinking || this.result?.thinking?.thoughts || this.result?.result?.thinking;
            // } else
            // }
        },
        completed() {
            return this.result?.complete || this.result?.completed;
        },
        parentCampaign() {
            if (this.campaigns?.length > 0) {
                this.campaigns?.find(i => i.id === this.campaignId);
            } else if (this.campaignId) return this.campaignId;
        },
        parentId() {
            return this.parentCampaign?.id || this.result?.campaignId || "";
        },
        objectTypePlainText() {
            return this.objectType?.replace(/_/g, " ");
        },
        createdAt() {
            if (this.result?.created) {
                return this.$filters.timeAgo(this.toDate(this.result.created));
            }
            return "";
        },
        itemCounts() {
            return this.result?.item_counts || {};
        },
        planningIsString() {
            return typeof this.planning === "string";
        },
        reasoningIsString() {
            return typeof this.reasoning === "string";
        },

        objectType() {
            return this.result?.object_type || this.result?.result?.object_type || "campaign";
        },
        isEmail() {
            if (this.type && this.type === "email") return true;
            else if (this.objectType) return this.objectType === "email";
        },
        isLandingPage() {
            return this.objectType === "landing_page";
        },
        isCampaign() {
            return this.objectType === "campaign";
        },
        reasoning() {
            return this.result?.reasoning || this.result?.thinking?.reasoning || this.result?.thinking?.criticism || [];
        },
        preview() {
            return this.result?.preview_text || this.result?.result?.preview_text || "";
        },
        subject() {
            return this.result?.subject_line || this.result?.result?.subject_line || "";
        },
        thoughts() {
            return this.result?.thinking?.thoughts || this.result?.thoughts || [];
        },
        criticism() {
            return this.result?.thinking?.criticism || this.result?.criticism || [];
        },
        planning() {
            return this.result?.planning || this.result?.plan || this.result?.thinking?.plan || [];
        },
        speak() {
            return this.result?.thinking?.speak || this.result?.result?.speak || "";
        },
        strategy() {
            return this.result?.strategy || [];
        },
        name() {
            return this.result?.name || this.result?.result?.name || "";
        },
    },
};
</script>
<style lang="scss" scoped>
h6 {
    @apply mb-0 font-bold;
}

.menu_section {
    border-style: solid;
    @apply py-3 border-b border-l-0 border-r-0 border-t-0 border-b-gray-900/10 p-3;
    p {
        @apply mb-0;
    }
}
</style>
