<template>
    <div>
        <div :class="{ 'ml-2': depth > 0 }">
            <!-- Handling Objects -->
            <div v-if="isObject(dataObject)">
                <div v-for="(value, key) in dataObject" :key="key">
                    <strong class="mb-0 text-capitalize mr-1">{{ key }}:</strong>
                    <!-- Render value on the same line if it's a primitive type -->

                    <template v-if="key.toLowerCase().includes('css')">
                        <chat-markdown :content="`\`\`\`CSS\n${value}\n\`\`\``"></chat-markdown>
                    </template>
                    <template v-if="key?.toLowerCase().includes('messages')">
<!--                        <preview-messages class="f-13" :messages="value" />-->
                        <MessageViewer :chat="value" />
                    </template>
                    <span v-else-if="isPrimitiveType(value)" class="line-height-smaller">
                        <template v-if="formatValue(value)?.isColor">
                            <color-preview :color="formatValue(value).hex" />
                        </template>
                        <template v-else-if="value">{{ value }}</template>
                    </span>
                    <span v-else>
                        <DynamicObjectViewer :image-array="imageArray" :data-object="value" :depth="depth + 1" />
                    </span>
                    <!--                    </template>-->
                </div>
            </div>

            <!-- Handling Arrays -->
            <div v-else-if="isArray(dataObject)" class="py-2">
                <!--            <div>Array:</div>-->
                <div v-for="(item, index) in dataObject" :key="index" class="border-light p-1 br-5">
                    <span class="line-height-smaller" v-if="isPrimitiveType(item)">
                        <template v-if="formatValue(item)?.isColor">
                            <color-preview :color="formatValue(item).hex" />
                        </template>
                        <template v-else>
                            {{ item }}
                        </template>
                    </span>
                    <template v-else>
                        <DynamicObjectViewer :class="{ 'f aic f-17': depth === 1, 'f f-11': depth === 3, 'f f-13': depth === 2 }" :image-array="imageArray" :data-object="item" :depth="depth + 1" />
                    </template>
                </div>
            </div>

            <!-- Handling Primitive Types -->
            <div v-else>
                {{ dataObject }}
            </div>
        </div>
        <BaseAccordion class="my-3" v-if="depth === 0">
            <template #title>View JSON</template>
            <div class="p-3 bg-snow">
                <pre>{{ dataObject }}</pre>
            </div>
        </BaseAccordion>
    </div>
</template>

<script>
import BaseAccordion from "@/components/CoreUI/BaseAccordion.vue";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import PreviewMessages from "@/components/styleGuide/debugging/PreviewMessages.vue";
import ColorPreview from "@/components/styleGuide/Tools/ColorPreview.vue";
import chroma from "chroma-js";
import MessageViewer from "@/components/Admin/MessageViewer.vue";
export default {
    name: "DynamicObjectViewer",
    components: { MessageViewer, ColorPreview, PreviewMessages, ChatMarkdown, BaseAccordion },
    props: {
        dataObject: {
            required: true,
            type: [Object, Array, String, Number, Boolean, null],
        },
        depth: {
            type: Number,
            default: 0,
        },
        imageArray: {
            type: Array,
            default: null,
        },
        preferredKeys: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        sortedAndPreferredKeys() {
            if (this.isObject(this.dataObject)) {
                const keys = Object.keys(this.dataObject);
                const preferred = this.preferredKeys.filter(key => keys.includes(key));
                const others = keys.filter(key => !this.preferredKeys.includes(key)).sort();
                return [...preferred, ...others].map(key => ({ key, value: this.dataObject[key] }));
            } else if (this.isArray(this.dataObject)) {
                // For arrays, we return the items directly without sorting as keys
                return this.dataObject.map((item, index) => ({ key: index, value: item }));
            } else {
                // This else block might not be necessary as per your current template logic
                return []; // Fallback for non-object/non-array dataObject
            }
        },
    },
    methods: {
        formatValue(value) {
            try {
                // Regular expression for hex color (e.g., #fff or #ffffff, case-insensitive)
                const hexColorRegex = /^#(?:[0-9a-f]{3}){1,2}$/i;
                // Regular expression for RGBA color (e.g., rgba(255, 255, 255, 1))
                const rgbaColorRegex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*(0|1|0?\.\d+))?\)$/;

                // Check if value is a hex color
                if (typeof value === "string" && hexColorRegex.test(value)) {
                    return { hex: value.toUpperCase(), isColor: true }; // Return the hex color in uppercase
                } else if (typeof value === "string" && rgbaColorRegex.test(value)) {
                    // Detected RGBA color, convert to HEX
                    const hex = chroma(value).hex(); // Converts to HEX, alpha is considered but not visibly represented in HEX format
                    // If handling of alpha channel in HEX is required, additional conversion logic is needed here
                    return { hex: hex, isColor: true }; // Return the converted hex color
                } else if (value instanceof Date) {
                    // Handle Date objects
                    return this.toDate(value); // Assuming toDate is a method you've defined
                } else {
                    // Handle other types
                    return value;
                }
            } catch (e) {
                return value; // Return original value in case of any error
            }
        },

        isObject(obj) {
            return obj !== null && typeof obj === "object" && !Array.isArray(obj);
        },
        isArray(arr) {
            return Array.isArray(arr);
        },
        // Check if the type is a primitive (string, number, boolean)
        isPrimitiveType(value) {
            return ["string", "number", "boolean"].includes(typeof value);
        },
    },
};
</script>

<style scoped lang="scss">
strong {
    font-weight: bold;
}
</style>
