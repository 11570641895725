<template>
    <div class="chat-error chat-assistant" :key="'chat' + $route.params.id">
        <div class="chat-message chat-assistant chat-error x p-relative" @mouseleave="hovered = false" @mouseover="hovered = true">
            <div class="message_container">
                <div class="x">
                    <div class="relative prose" style="font-weight: 400" :class="{ 'mw-650': !mobile, chat_content_container: m.content !== '' }">
                        <!--                        <chat-markdown :content="m.content.trim()" :message="m" :class="{ 'x block pr-3': mobile, 'mw-650': !mobile }"></chat-markdown>-->
                        <p class="text-sm fwsb mb-0 whitespace-pre-line">{{ m.content }}</p>
                        <slot></slot>
                        <div class="f jcb aic gap-1">
                            <MessageEditButtons :class="{ 'opacity-0': !hovered && !mobile }" :message="m" @edit-message="editMessage" @remove-message="removeMessage"></MessageEditButtons>
                        </div>
                        <span class="f ais jcs">
                            <span class="small line-height-smaller d-block o-3 f-11 order-first mr-2 mt-2 whitespace-nowrap pb-3 text-center italic xl:order-last" v-if="m.createdAt && $store.state.chat.showDebugger">{{ $filters.timeAgo(dateFix(m.createdAt)) }}</span>
                            <div class="f fc">
                                <debugObject :open="index === $store.state.chat.messages.length - 1" :keysToOpen="['action', 'args', 'results', 'data']" :object="m" v-if="$store.state.chat.showDebugger"></debugObject>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MessageEditButtons from "@/components/chat/MessageEditButtons.vue";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import TaskManager from "@/views/Chat/TaskManager.vue";
import ChatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";

export default {
    name: "Assistant",
    emits: ["askQuestion"],
    components: {
        TaskManager,
        DebugObject,
        ChatMarkdown,
        MessageEditButtons,
    },
    mixins: [ChatCommands],
    emits: ["image-loaded"],
    props: {
        // isCode: Function,
        // isCSS: Function,
        m: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            editMode: false,
            hovered: false,
        };
    },
    mounted() {},
    computed: {
        isNotLastMessage() {
            return this.index < this.$store.state.chat.messages.length - 1;
        },
        isCodeMessage() {
            return this.m.kind === "code";
        },
        isCommandObjectMessage() {
            return this.isCommandObject(this.m.content);
        },
        isImageMessage() {
            return this.isImage(this.m);
        },
        user() {
            return this.$store.state.user.user;
        },
        cleanMessage() {
            return this.m.content.replace(/^\n+|\n+$/g, "");
        },
        removeNewlinesAtStart() {
            return this.m.content;
        },
    },
    methods: {
        removeMessage() {
            this.$emit("removeMessage", this.m, this.index);
        },
        imageLoaded(index, images) {
            console.error("imageLoaded on ChatAssistantMessage", index, images);
            this.$emit("imageLoaded", index, images);
        },
    },
};
</script>
<style lang="scss">
.chat-error {
    @apply text-red;
}
</style>
