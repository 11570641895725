<template>
    <div class="accordion-item text-left">
        <div @click="toggle" :style="`${titleStyleString} ${borderBottomString}`" class="f jcb aic accordion-button py-3 border-bottom f-17 line-height-smaller">
            <!--        <div @click="toggle" :class="computedTitleClass" :style="`${titleStyleString}`" class="accordion-button">-->
            <div>
                <span v-if="false && showIcon" class="icon-container">
                    <i v-if="fontAwesomeClass" :class="['fal', fontAwesomeClass, 'fa-fw', 'text-xl', 'mt-1', 'mr-3']"></i>
                    <span v-else-if="emoji" class="text-xl mr-3">{{ emoji }}</span>
                    <span v-else class="text-xl mr-3">{{ feature.icon }}</span>
                </span>
                <span class="block line-height-normal select-none" :class="{ fwsb: isOpen }">{{ title }}</span>
            </div>
            <div>
                <i :style="arrowColorStirng" :class="['fal', isOpen ? 'fa-angle-up' : 'fa-angle-down', 'fa-fw', 'text-xl', 'mt-1', 'mr-3']"></i>
            </div>
        </div>
        <transition name="fade-up" mode="in-out">
            <div v-if="isOpen" :class="computedDescriptionClass" :style="`${descriptionStyleString}`" class="accordion-content py-3">
                <p class="f-20 o-7" style="width: 90%">{{ description }}</p>
            </div>
        </transition>
    </div>
</template>

<script>
import chroma from "chroma-js";
export default {
    name: "AccordionSectionItem",
    props: {
        computedDescriptionClass: {},
        // computedTitleClass: {},
        descriptionStyleString: {},
        feature: {},
        titleStyleString: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        borderBottomString() {
            let stringOpacity = 0.2;
            // if(this.color) change the color's opacity with croma.
            if (this.color) {
                let color = chroma(this.color);
                let colorString = color.alpha(stringOpacity).css();
                return `border-bottom: 1px solid ${colorString};border-opacity: ${stringOpacity};`;
            }
            return `border-bottom: 1px solid ${this.color};border-opacity: 0.25;`;
        },
        arrowColorStirng() {
            let stringOpacity = 0.5;
            // if(this.color) change the color's opacity with croma.
            if (this.color) {
                let color = chroma(this.color);
                let colorString = color.alpha(stringOpacity).css();
                return `color: ${colorString};`;
            }
            return `color: ${this.color};`;
        },
        fontAwesomeClass() {
            return this.feature?.icon?.font_awesome_class;
        },
        emoji() {
            return this.feature?.icon?.emoji;
        },
        title() {
            return this.feature?.title;
        },
        description() {
            return this.feature?.description;
        },
        showIcon() {
            return this.feature?.icon?.font_awesome_class || this.feature?.icon?.emoji || typeof this.feature?.icon === "string";
        },
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style>
/* Add your styles for the accordion here */
.accordion-item {
    /* Style your accordion container */
}

.accordion-button {
    /* Style your accordion buttons */
    cursor: pointer;
}

.accordion-content {
    /* Style your accordion content */
}
</style>
