<template>
    <div class="p-relative inline-block z-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
        <div :class="`border border-snow border-1 c-${size} f f-17 text-shadow aic jcc rounded-full`" :style="`background:${colorToUse}`">
            <!--            <i class="fas fa-check text-snow"></i>-->
        </div>
        <div class="p-absolute top bg-base-900/90 py-1 px-2 rounded-full block white" style="top: -5px; transform: translate(-80%, -8px)" v-if="hovered">
            {{ name }}
        </div>
    </div>
</template>

<script>
export default {
    name: "ColorCircle",
    props: {
        color: {},
        name: {},
        size: {
            type: String,
            default: "30",
        },
    },
    data() {
        return {
            hovered: false,
        };
    },
    computed: {
        colorToUse() {
            if (typeof this.color === "string") {
                return this.color;
            } else {
                return `rgba(${this.color.r},${this.color.g},${this.color.b},1)!important`;
            }
        },
    },
    methods: {
        showTooltip() {
            this.hovered = true;
        },
        hideTooltip() {
            this.hovered = false;
        },
    },
};
</script>

<style scoped></style>