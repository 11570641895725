import imageMixin from "@/mixins/images/imageMixin";
import streamingMixin from "@/mixins/ai/streamingMixin";
import firebaseMixin2 from "@/mixins/firebase/firebaseMixin2";
import ElevenLabsMixin from "@/mixins/ai/ElevenLabsMixin";
import WhisperMixin from "@/mixins/ai/WhisperMixin";

const openAIKey = import.meta.env.VITE_OPENAI_API;
export default {
    data: () => ({
        eventSource: null,
        reconnectDelay: 5000, // Reconnect delay in milliseconds (5 seconds)
    }),
    mixins: [imageMixin, streamingMixin, firebaseMixin2, ElevenLabsMixin, WhisperMixin],
    computed: {},
    methods: {
        async fixJSON(string, format) {
            let response;
            if (format) {
                response = await this.completion(
                    `FIX THIS: ${JSON.stringify(string)}`,
                    `You are a JSON BOT fixes broken JSON. Only return valid JSON, nothing else. Return nothing if it's already valid. If it's a string convert it to the format. 
                Object Format:
               ${format}
                `,
                    "FIX JSON",
                    gpt4,
                    1000,
                );
            } else {
                response = await this.completion(`FIX THIS: ${JSON.stringify(string)}`, `You are a JSON BOT fixes broken JSON. Only return valid JSON, nothing else. Return nothing if it's already valid.`, "FIX JSON", gpt3, 1000);
            }
            if (this.isValidJSON(response)) {
                let json = JSON.parse(response);
                console.error(json);
                return json;
            } else {
                let jsonString = this.jsonSubstring(response);
                let json = JSON.parse(jsonString);
                return json;
                console.log(json);
            }
        },
    },
};
