<template>
    <span :class="classes">
        <span class="fwsb text-capitalize" v-if="label">{{ label }}:</span>
        <!--        <time :datetime="formatDate" class="fwsb text-capitalize" />-->

        {{ relative ? relativeDate : formatDate }}
    </span>
</template>
<script>
import moment from "moment";

export default {
    name: "TimeStamp",
    props: {
        date: {
            type: [String, Number, Object],
            default: "",
        },
        classes: {
            type: String,
            default: "flex-none py-0.5 text-xs leading-5 text-gray-500",
        },
        label: {
            type: String,
        },
        relative: {
            type: Boolean,
            default: false,
        },
        time: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        formatDate() {
            try {
                // Check if date is an object (to handle Firestore timestamp format)
                if (typeof this.date === "object" && this.date !== null && "seconds" in this.date) {
                    // Construct a Date object using the seconds and nanoseconds
                    const date = new Date(this.date.seconds * 1000 + this.date.nanoseconds / 1000000);
                    return moment(date).format("MMM Do YYYY");
                }
                // Handle ISO string or UNIX timestamp
                else if (this.date) {
                    if (typeof this.date === "string" && this.date.includes("T")) {
                        if (this.time) return moment(this.date).format("MMM Do YYYY, h:mm a");
                        else return moment(this.date).format("MMM Do YYYY");
                    } else {
                        // Check if the Unix timestamp has more than 10 digits
                        if (this.date.toString().length > 10) {
                            // Assume the timestamp is in milliseconds
                            if (this.time) return moment(this.date).format("MMM Do YYYY, h:mm a");
                            else return moment(this.date).format("MMM Do YYYY");
                        } else {
                            // Assume the timestamp is in seconds
                            if (this.time) return moment(this.date * 1000).format("MMM Do YYYY, h:mm a");
                            else return moment(this.date * 1000).format("MMM Do YYYY");
                        }
                    }
                }
            } catch (e) {
                return "No Date";
            }
        },
        relativeDate() {
            return this.timeAgo(this.date);
        },
    },
    methods: {
        timeAgo(date) {
            moment.updateLocale("en", {
                relativeTime: {
                    future: "in %s",
                    past: "%s ago",
                    s: "seconds",
                    ss: "%ss",
                    m: "a minute",
                    mm: "%dm",
                    h: "an hour",
                    hh: "%dh",
                    d: "a day",
                    dd: "%dd",
                    M: "a month",
                    MM: "%dM",
                    y: "a year",
                    yy: "%dY",
                },
            });
            let relDate = moment(date, "YYYYMMDD");
            var currentTime = moment();
            var minutes = currentTime.diff(relDate, "minutes");
            let text;
            // If less than 60 minutes have passed, display the timestamp in a relative format
            if (minutes < 120) {
                var timeAgoString = relDate.fromNow();
                text = timeAgoString;
            } else if (minutes < 180) {
                var timeAgoString = relDate.fromNow();
                var dateString = relDate.format("hh:mm a");
                // text = timeAgoString+' '+dateString;
                text = dateString;
            } else if (minutes < 1440) {
                var timeAgoString = relDate.fromNow();
                var dateString = relDate.format("hh:mm a");
                text = "Today at " + dateString;
            } else {
                var dateString = relDate.format("MMM D, YYYY");
                text = dateString;
            }
            return text;
            // return relDate;
        },
    },
};
</script>
