<template>
    <div v-if="messageTokens > 3000" class="summary-questions">
        <h6 class="px-3 pt-2">Summary question</h6>
        <textarea v-model="summaryQuestion" placeholder="Summary question" rows="2" type="textarea" @keydown.enter="summarizeServer(message, summaryQuestion)" />
        <div v-if="messageTokens > 3000" class="summarize-alert">
            This message is long. We'l split it up for you.
            <div class="f gap-3">
                <a href="#" @click.prevent="summarizeLongMessage(message)">Summarize</a>
                <a class="ml-3" href="#" @click.prevent="emitSummarize">Summarize V2</a>
            </div>
        </div>
    </div>
</template>
<script>
import memoryMixin from "@/mixins/ai/memoryMixin";

export default {
    name: "SummarizeLongMessages",
    mixins: [memoryMixin],
    props: {
        message: {},
        messageTokens: {},
    },
    data() {
        return {
            summary: "",
            summaryQuestion: "",
        };
    },
    methods: {
        summarizeServer(message, summaryQuestion) {
            this.summary = message;
            this.summaryQuestion = summaryQuestion;
        },
        summarizeLongMessage(message) {
            this.summary = message;
        },
        emitSummarize() {
            this.$emit("summarizeServer", this.message, this.summaryQuestion);
        },
        // emitSummarize(message, summaryQuestion)
    },
};
</script>
<style scoped>
textarea {
    overflow: hidden;
    resize: none;
    border: none;
    padding: 10px;
    min-height: unset !important;
}
</style>
