import { collection, setDoc, doc, Timestamp, getDoc, query, where, deleteDoc, addDoc } from "firebase/firestore";
import { mapActions } from "vuex";
import { auth, db, googleAuthProvider } from "@/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {};
    },
    computed: {
        // user() {
        //     if (this.$store.state.user.user) {
        //         return this.$store.state.user.user;
        //     } else {
        //         console.log("User not logged in");
        //         return false;
        //     }
        // },
    },
    methods: {
        ...mapActions("user", ["fetchUser"]),
        async login() {
            try {
                let { user } = await signInWithEmailAndPassword(auth, this.email, this.password);
                let dbUser = await fetchData("users", user.uid);
                user = { ...user, ...dbUser };
                logGroup("User", user);
                if (user?.brand) {
                    this.$router.push({ path: `/admin/${user.brand}/campaigns` });

                    // this.$router.push({ name: "admin", params: { id: user?.brand } });
                }
                // alert("User logged in successfully");
                // if (user?.brand) this.$router.push({ name: "admin" });
                this.email = "";
                this.password = "";
                this.$store.dispatch("user/setUser", user);
                // await this.handleUserData(user);
            } catch (error) {
                // alert(error.message);
                alert(this.email);
            }
        },
        async register() {
            try {
                const { user } = await createUserWithEmailAndPassword(auth, this.email, this.password);
                this.email = "";
                this.password = "";
                await this.handleUserData(user);
            } catch (error) {
                // alert(error.message);
                this.failed = true;
                alert(this.email);
            }
        },
        async signInWithGoogle() {
            try {
                const { user } = await signInWithPopup(auth, googleAuthProvider);
                await this.handleUserData(user);
                this.alert({ message: "You signed in", type: "success" });
            } catch (error) {
                alert(error.message);
            }
        },
        async handleUserData(user) {
            const userRef = doc(db, "users", user.uid);
            let obj = {
                displayName: user.displayName || "",
                name: user.displayName || "",
                brand: user.brand || "",
                email: user.email,
                photoURL: user.photoURL || "",
                firstName: user.displayName.split(" ")[0] || "",
                lastName: user.displayName.split(" ")[1] || "",
                uid: user.uid,
                updatedAt: new Date(),
                ...user.metadata,
                id: user.uid,
            };
            if (user.displayName) {
                try {
                    obj.name = user.displayName;
                    obj.first_name = user.displayName.split(" ")[0] || "";
                    obj.last_name = user.displayName.split(" ")[1] || "";
                } catch (e) {
                    console.log(e);
                }
            }
            if (!user.brand && this.styleGuide) obj.brand = this.styleGuide?.id;
            await setDoc(userRef, obj, { merge: true });
            let dbUser = await getDoc(userRef);
            dbUser = dbUser.data();
            user = { ...user, ...dbUser };
            logGroup("User", user);
            this.$store.dispatch("user/setUser", user);
            return user;
        },
        async signOut() {
            try {
                await signOut(auth);
            } catch (error) {
                alert(error.message);
            }
        },
        async updateUser(uid, updateData) {
            if (!uid) {
                uid = this.$store.state.user.user.uid;
            }
            const userRef = doc(db, "users", uid);
            try {
                await setDoc(userRef, updateData, { merge: true });
                console.log("User updated successfully");
            } catch (error) {
                console.error("Error updating user: ", error);
            }
        },
        async addUserProfileData(data) {
            const userId = this.$store.state.user.user.uid;
            try {
                // Create a reference to the Firestore document based on user ID
                const userDocRef = doc(db, "users", userId);

                // Prepare the data to be added to the user profile
                const userProfileData = {
                    ...data,
                    createdAt: Date.now(),
                };
                // Add data to the user's Firestore document
                await setDoc(userDocRef, userProfileData, { merge: true });

                // Success message
                console.log("User profile data added successfully.");
            } catch (error) {
                // Error handling
                console.error("Error adding user profile data: ", error);
            }
        },
    },
};
