<template>
    <span class="bubble-badge gap-2 whitespace-nowrap transition-all" :class="`text-${color}-500 bg-${color}-50 bg-violet border border-${color}-200`">
        <span v-if="icon"><i :class="`${icon} mr-2`"></i></span>
        <span v-if="text">{{ text }}</span>
        <slot></slot>
    </span>
</template>
<script>
export default {
    name: "BaseBadge",
    props: {
        text: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: false,
            default: "gray",
        },
    },
    methods: {},
};
</script>
<style scoped lang="scss">
.bubble-badge {
    @apply font-bold inline-block rounded-full px-2 py-0.5 text-center;
}
.badgeColorIndex {
    @apply bg-indigo-50 text-indigo-500 border border-indigo-200;
    @apply text-indigo-500;
}
</style>
