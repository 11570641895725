<template>
    <div class="space-y-3">
        <p class="text-sm text-gray-800 dark:text-white">{{ text }}</p>
    </div>
</template>
<script>
export default {
    name: "MessageText",
    props: {
        text: {
            type: String,
            default: "",
        },
    },
};
</script>
