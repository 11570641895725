<template>
    <div class="flex items-center">
        <Listbox v-model="selectedMood" as="div">
            <ListboxLabel class="sr-only">Your mood</ListboxLabel>
            <div class="relative">
                <ListboxButton class="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                    <span class="flex items-center justify-center">
                        <span v-if="selectedMood?.value === null">
                            <FaceSmileIcon aria-hidden="true" class="h-5 w-5 flex-shrink-0" />
                            <span class="sr-only">Add your mood</span>
                        </span>
                        <span v-if="!(selectedMood?.value === null)">
                            <span :class="[selectedMood?.bgColor, 'flex h-8 w-8 items-center justify-center rounded-full']">
                                <component :is="selectedMood?.icon" aria-hidden="true" class="h-5 w-5 flex-shrink-0 text-white" />
                            </span>
                            <span class="sr-only">{{ selectedMood?.name }}</span>
                        </span>
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute bottom-10 z-10 -ml-6 w-300 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                        <ListboxOption v-for="mood in moods" :key="mood?.value" v-slot="{ active }" :value="mood" as="template">
                            <li :class="[active ? 'bg-gray-100' : 'bg-snow', 'relative cursor-default px-3 py-2']">
                                <div class="f items-center x">
                                    <div :class="[mood?.bgColor, 'flex h-8 w-8 items-center justify-center rounded-full']">
                                        <component :is="mood?.icon" :class="[mood?.iconColor, 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
                                    </div>
                                    <span class="ml-3 block truncate font-medium">{{ mood?.name }}</span>
                                </div>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { FaceSmileIcon } from "@heroicons/vue/20/solid";

export default {
    name: "MoodSelector",
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        FaceSmileIcon,
    },
    props: {
        moods: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            selectedMood: null,
        };
    },
    mounted() {
        this.selectedMood = this.moods?.[5];
    },
};
</script>
