export default {
    created: function () {},
    mounted() {},
    data: () => ({
        cursorPositions: [],
        currentIndex: 0,
    }),
    computed: {},
    methods: {
        saveCursorPosition(index) {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                this.cursorPositions[index] = range.startOffset;
            }
        },
        setCursorPosition(sectionIndex, position) {
            console.error("setCursorPosition", sectionIndex, position);
            let section = this.$refs["section-" + sectionIndex];
            let sectionRef = section.$refs.contenteditable.$refs.element;
            const el = sectionRef;
            el.focus();
            const range = document.createRange(); // create a new range object
            const sel = window.getSelection(); // get the current selection

            // set the range to start and end at the desired position
            range.setStart(el.childNodes[0], position);
            range.setEnd(el.childNodes[0], position);

            // collapse the selection to the start position
            range.collapse(true);

            // remove any existing selections
            sel.removeAllRanges();

            // add the new selection range to the selection object
            sel.addRange(range);

            // focus the contenteditable section
            el.focus();
        },
        // Restore the cursor position when navigating back to a contenteditable element
        restoreCursorPosition(caretPos) {
            console.error("restore cursor");

            this.$nextTick(() => {
                let section = this.$refs["section-" + this.sectionIndex];
                let sectionTotal = section.$refs.contenteditable.$refs.element;
                const el = sectionTotal;
                console.error(caretPos);
                setTimeout(() => {
                    if (el !== null) {
                        if (el.createTextRange) {
                            var range = el.createTextRange();
                            range.move("character", caretPos);
                            range.select();
                            return true;
                        } else {
                            // (el.selectionStart === 0 added for Firefox bug)
                            if (el.selectionStart || el.selectionStart === 0) {
                                el.focus();
                                el.setSelectionRange(caretPos, caretPos);
                                return true;
                            } else {
                                // fail city, fortunately this never happens (as far as I've tested) :)
                                el.focus();
                                return false;
                            }
                        }
                    }

                    el.setSelectionRange(caretPos, caretPos);
                }, 20);
            });
            return;
            // console.error(element);

            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(element.childNodes[0], element.childNodes[0] + 1);

            console.error(range);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        },
        // Navigate to the next contenteditable element
        navigateNext() {
            console.error("wrong methos triggerd");
            this.saveCursorPosition(this.sectionIndex);
            if (this.sectionIndex === this.cursorPositions.length + 1) {
                this.sectionIndex = 0;
            } else {
                // this.sectionIndex++;
                this.nextSection();
            }
            this.restoreCursorPosition(this.sectionIndex);
        },
        navigatePrev() {
            console.error("wrong methos triggerd");
            this.saveCursorPosition(this.sectionIndex);
            if (this.sectionIndex === 0) {
                this.sectionIndex = this.cursorPositions.length;
            } else {
                this.previousSection();
            }
            this.restoreCursorPosition(this.sectionIndex);
        },
    },
};
