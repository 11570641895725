<template>
    <div ref="styleGuideContainer" class="p-fixed p-fill mt-nav scrollSection" :key="$route.params.id">
        <div class="grid grid-cols-12">
            <!--            <Transition name="slide-left"><StyleGuidesList v-if="showSidebar" :key="$route.params.id" class="p-fixed z-5 mw-350 bg-snow mt-top ease-navigation-from-top top block left bottom" @create-new="createNew" @mouseleave="showSidebar = false" /></Transition>-->
            <div class="p-fixed left top top-10 bottom z-5" style="width: 4px" @mouseenter="showSidebar = true"></div>
            <!--            <Transition name="fade-up" mode="out-in">-->

            <div v-if="showStyleGuide" :key="$route.params.id" class="brand-bg col-span-full mt-0 pb-8 pt-0">
                <div v-if="styleGuide" class="x z-0 relative">
                    <div v-if="showHeader" class="block">
                        <div class="relative block" v-for="(section, index) in storeSections" :key="section">
                            <LandingPageSection class="relative z-0" identifier="result" :result="section" @clicked-logo="populateHeader()" id="header">
                                <div class="x z-3 p-2 f gap-12 px-5 py-5">
                                    <router-link :to="`/categories/${category}`" style="color: inherit" v-for="category in styleGuide?.categories?.slice(0, 4)">
                                        <h6 class="f-20 cursor-pointer">{{ category }}</h6>
                                    </router-link>
                                </div>
                            </LandingPageSection>
                        </div>
                        <BaseButton style-type="secondary" icon-only icon="far fa-sync" size="lg" class="absolute justify-fixed-center mx-auto text-center z-10" :style="`transform: translateX(-50%) translateY(-50%);color:${linkColor}!important`" @click.prevent="populateHeader()"></BaseButton>
                    </div>
                    <div v-else>
                        <ColorSwipeLoader class="relative x vh-100 z-10 overflow-hidden"></ColorSwipeLoader>
                    </div>

                    <ProgressTracker v-if="superAdmin && $store.state?.styleGuide?.progress" />
                    <div class="x py-8" v-if="styleGuide.description">
                        <p class="f-50 text-center mx-auto mw-800 mb-0 fadeInUpOnly">{{ styleGuide.description }}</p>
                    </div>
                    <div class="grid grid-cols-12 z-0 relative my-0">
                        <div v-for="(img, i) in landingPageImages?.slice(0, 1)" class="grid grid-cols-1 md:col-span-12 md:grid-cols-12 text-black overflow-hidden">
                            <div :class="[i % 2 === 0 ? 'col-span-12' : 'col-span-6 order-last', 'aspect-h-1 aspect-w-2 relative']" :style="`background:${img?.colors?.[0]}`">
                                <!--                <img class="o-cover x" :src="imageHelper(i, { width: 2000, height: 1000 })" />-->
                                <StyleGuideImage :image="img" :size="{ width: 2000, height: 1380 }" class="x y" />
                                <div class="bg-gray-900/20 p-absolute p-fill z-1"></div>
                                <div class="p-absolute p-fill f aic jcc z-2">
                                    <brand-logo-component></brand-logo-component>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isAdmin && !mobile" class="x z-10 relative">
                        <BaseButton style-type="secondary" label="Refresh" reverse rounded icon="far fa-sync" size="sm" class="absolute right mx-auto text-center z-5 border-light right z-10" style="transform: translateX(-10%) translateY(-50%)" @click.prevent="updateStyleGuide(styleGuide.url)"></BaseButton>
                    </div>

                    <div class="relative"><StyleGuideAboutBrand /></div>

                    <ProductCarousel v-if="products?.length > 0" :products="products" />
                    <!--                    <Collections />-->
                    <SimilarBrandsCards v-if="superAdmin && styleGuide.categories" :categories="styleGuide.categories" />
                    <StyleGuideColorsNew :key="colors" :colors="colors" />
                    <LogoSection :imgs="logos" bg="" class="col-span-12" section-id="logos" section-name="Logos"></LogoSection>
                    <StyleGuideDivs v-if="styleGuide" />
                    <StyleGuideFonts class="x overflow-hidden" />
                    <StyleGuideButtons v-if="buttonStyles" :buttons="buttonStyles" />
                    <AssignedStyleDisplay></AssignedStyleDisplay>
                    <div class="my-4">
                        <CopyGuidelines class="p-0" :guidelines="copyGuidelines"></CopyGuidelines>
                    </div>
                    <StyleGuideTextSamples v-if="styleGuide?.textSamples" :samples="styleGuide.textSamples" />
                    <Tabs v-if="doneMounting">
                        <template #Images>
                            <div class="x y overflow-hidden"><StyleGuideImageArray v-if="sortedImages" :imgs="sortedImages" :style-guide="styleGuide" class="col-span-12"></StyleGuideImageArray></div>
                        </template>
                        <template #Logos>
                            <div class="x y"><StyleGuideImageArray :imgs="logos" :style-guide="styleGuide" bg="" class="col-span-12" section-id="logos" section-name="Logos"></StyleGuideImageArray></div>
                        </template>
                        <template v-if="styleGuide?.videos?.length > 0" #Videos>
                            <div class="x y"><StyleGuideVideoComponent :videos="styleGuide.videos" class="p-relative"></StyleGuideVideoComponent></div>
                        </template>
                        <template #Typography v-if="styleGuide?.typographyCombos">
                            <div><StyleGuideTypographyCombos :typography-combos="styleGuide.typographyCombos" /></div>
                        </template>
                    </Tabs>

                    <!--                                            <UIImageTiles />-->
                    <!--                                            <UIProductPage :products="products"></UIProductPage>-->
                    <!--                        <BrandCrawler />-->
                    <!--                        <Transition name="fade-up" mode="out-in" appear>-->
                    <!--                        <StyleGuideColors :key="colors" :colors="colors" />-->
                    <!--                        </Transition>-->
                    <!--                        <style-guide-products v-if="styleGuide?.products?.length > 0"></style-guide-products>-->
                    <!--                        <style-guide-typography-headers :style-guide="styleGuide"></style-guide-typography-headers>-->
                    <!--                        <Transition name="fade-up">-->
                    <!--                        </Transition>-->
                    <!--                        <div class="p-fixed justify-fixed-center bottom z-5 p-5" v-if="styleGuide.categories">-->
                    <!--                            <SimilarBrands :categories="styleGuide.categories" />-->
                    <!--                        </div>-->
                </div>
            </div>
            <ColorSwipeLoader class="x vh-100 z-10 fixed z-1 p-fill animated fadeInUpSmooth" :key="divStyles?.[0]" :colors="styleGuide.colors" :style-guide="styleGuide" v-if="!showStyleGuide && divStyles"></ColorSwipeLoader>
        </div>
        <BrandFooter v-if="showStyleGuide" />
    </div>
</template>

<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import Tabs from "@/components/CoreUI/BaseTabs.vue";
import LandingPageSection from "@/components/generative_templates/LandingPageSection.vue";
import StyleGuideAboutBrand from "@/components/styleGuide/Sections/About.vue";
import StyleGuideButtons from "@/components/styleGuide/Sections/Buttons.vue";
import StyleGuideImageArray from "@/components/styleGuide/Sections/ImagesSection.vue";
import StyleGuideTextSamples from "@/components/styleGuide/Sections/TextSamples.vue";
import StyleGuideDivs from "@/components/styleGuide/Sections/Themes/StyleGuideDivs.vue";
import StyleGuideVideoComponent from "@/components/styleGuide/Sections/Videos.vue";
import StyleGuideFonts from "@/components/styleGuide/Typography/StyleGuideFonts.vue";
import StyleGuideTypographyCombos from "@/components/styleGuide/Typography/StyleGuideTypographyCombos.vue";
import randomChoice from "@/mixins/ai/choose_random";
import styleGuideBrandMixin from "@/mixins/StyleGuideMixins/StyleGuideBrandMixin";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import SimilarBrandsCards from "@/views/SimilarBrandsCards.vue";
import ColorSwipeLoader from "@/components/styleGuide/ColorSwipeLoader.vue";
import AssignedStyleDisplay from "@/components/styleGuide/Typography/AssignedStyleDisplay.vue";
import CopyGuidelines from "@/components/generative_templates/Guidelines/CopyGuidelines.vue";
import ProductCarousel from "@/views/ProductCarousel.vue";
import styleGuideCSSMixin from "@/mixins/StyleMixins/StyleGuideCSSMixin";
import StyleGuideImage from "@/components/styleGuide/Images/StyleGuideImage.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import StyleGuideColorsNew from "@/components/styleGuide/Colors/StyleGuideColorsNew.vue";
import LogoSection from "@/components/styleGuide/Sections/LogoSection.vue";
import Collections from "@/views/Collections.vue";
import BrandFooter from "@/views/BrandFooter.vue";
import ProgressTracker from "@/views/ProgressTracker.vue";
import NewMessages from "@/views/NewMessages.vue";

export default {
    components: {
        NewMessages,
        ProgressTracker,
        BrandFooter,
        Collections,
        LogoSection,
        StyleGuideColorsNew,
        BrandLogoComponent,
        StyleGuideImage,
        ProductCarousel,
        CopyGuidelines,
        AssignedStyleDisplay,
        ColorSwipeLoader,
        LandingPageSection,
        SimilarBrandsCards,
        BaseButton,
        StyleGuideAboutBrand,
        Tabs,
        StyleGuideButtons,
        StyleGuideFonts,
        StyleGuideTypographyCombos,
        StyleGuideDivs,
        StyleGuideTextSamples,
        StyleGuideVideoComponent,
        StyleGuideImageArray,
    },
    mixins: [StyleGuideMixin, styleGuideBrandMixin, styleGuideCSSMixin],
    data() {
        return {
            // styleGuide: {},
            newURL: "",
            showSidebar: true,
            styleElements: null,
            loading: false,
            appliedStyles: false,
            savedUrls: [],
            pageTitle: "",
            websiteUrl: "",
            visualPallette: [],
            backgroundImages: [],
            backgrounds: [],
            body: null,
            buttonColors: [],
            description: "",
            headerStyles: [],
            paragraphStyles: [],
            sortColors: "luma",
            doneMounting: false,
        };
    },
    computed: {
        products() {
            return this.$store.getters["styleGuide/products"] || [];
        },
        storeSections() {
            return this.$store.state.stream.stream.result.sections;
        },
        showStyleGuide() {
            return !this.loading && this.$route.params.id && this.styleGuide && this.populated;
        },
        showHeader() {
            // return this.styleGuide.doneFetching
            // return this.styleGuide?.loadedFonts && (this.styleGuide?.doneFetching || (this.images?.length > 5 && this.fonts?.length > 0));
            return this.images?.length > 5 && (this.fonts?.length > 0 || this.styleGuide.fetchComplete);
        },
        populated() {
            if (this.styleGuide?.base) return true;
        },
    },
    watch: {
        // fonts: {
        //     // handler() {
        //     //     this.$nextTick().then(() => {
        //     //         this.applyStyles();
        //     //     });
        //     // },
        // },
        $route: {
            async handler(to, from) {
                this.scrollTop();
                this.doneMounting = false;

                if (to.params.id) await this.resetGuide(to.params.id);
                this.doneMounting = true;
            },
            immediate: false,
        },
        showHeader: {
            handler(to, from) {
                if (to) this.populateHeader();
            },
            immediate: false,
        },
    },
    created() {},
    async mounted() {
        this.scrollTop();
        if (this.$route.params.id) {
            this.showSidebar = false;
            await this.resetGuide(this.$route.params.id);
        }
        setTimeout(async () => {
            if (this.styleGuide && this.styleGuide.base) this.populateHeader();
            this.doneMounting = true;
        }, 100);

        // await this.getStyleGuides();
    },
    beforeUnmount() {
        this.doneMounting = false;
        this.removeStyles();
    },
    methods: {
        async resetGuide(id) {
            await this.fetchStyleGuide(id);
            this.$nextTick(() => {
                this.resetCSS();
            });
            this.resetCSS();
            let { base, url, name } = this.styleGuide;
            if (!base) await this.updateStyleGuide(url);
            if (name && base) this.populateHeader();
            this.$forceUpdate();
            this.doneMounting = true;
        },
        scrollTop() {
            if (this.$refs.styleGuideContainer) this.$refs.styleGuideContainer.scrollTop = 0;
        },
        pageLoaded() {
            this.doneMounting = true;
        },
        populateHeader(random) {
            let example = {
                purpose: "Introduce the Jet Lag Mask",
                imageIndex: 14,
                imageInset: false,
                imageStyle: "round",
                style: "fullBleed",
                textAlign: "center",
                header: "Jet Lag Mask",
                body: "Revitalize tired skin with our Jet Lag Mask. This deeply hydrating and multi-tasking cream moisturizer + mask is perfect for combating the effects of travel fatigue, late-night work, and hectic schedules. Formulated with powerful botanicals, it instantly nourishes, brightens, and plumps the skin for a refreshed and glowing complexion.",
                button: {
                    show: true,
                    text: "Shop Now",
                },
                complete: false,
            };
            if (this.styleGuide.name) {
                let imageIndex;
                let imageCount = this.images?.length;
                if (imageCount > 0) {
                    imageIndex = Math.floor(Math.random() * imageCount);
                } else {
                    imageIndex = 1;
                }
                let styles = ["imageLeft", "imageRight", "fullBleed"];
                let imageInset = [true, false];
                let imageStyle = ["round", "square", "normal"];
                let textAlign = ["left", "center", "right"];
                let hideGradient = [true, false];
                let header = this.styleGuide.name;
                let body = example.body;

                header = this.textSample(4, 10);
                body = this.textSample(10, 30);
                if (this.mobile) {
                    header = this.textSample(4, 5);
                    body = this.textSample(7, 15);
                }
                let result = {
                    imageIndex: imageIndex,
                    header: header,
                    body: body,
                    imageStyle: "square",
                    button: { show: true, text: "Shop now" },
                    style: "imageRight",
                    imageInset: false,
                    textAlign: "left",
                    hideGradient: false,
                };
                result = this.generateRandomContent();
                this.updateStreamProp("result.sections", [result]);
            }
        },
        generateRandomContent() {
            let imageIndex;
            let imageCount = this.images?.length;
            if (imageCount > 0) {
                imageIndex = Math.floor(Math.random() * imageCount);
            } else {
                imageIndex = 0; // Starting index should be 0, not 1 in typical zero-based indexing
            }
            let image = this.images[imageIndex];
            // let styles = ["fullBleed"];
            let styles = ["imageLeft", "imageRight", "fullBleed"];

            let imageInset = [true, false];
            let imageStyle = ["square"];
            // let imageStyle = ["round", "square", "normal"];
            if (image?.transparent) {
                styles = ["imageLeft", "imageRight"];
                imageStyle = ["normal"];
            }
            let style = randomChoice(styles);
            if (style === "fullBleed" && !this.mobile) {
                let imgs = this.images.filter(image => !image.transparent && !image.seamlessBg && image.height > 500 && image.width > image.height * 1.3);
                imgs = this.landingPageImages;
                imageIndex = Math.floor(Math.random() * imgs.length);
                image = imgs[imageIndex];
                let trueIndex = this.images.indexOf(image);
                imageIndex = trueIndex;
                // image = this.images[imageIndex];
                if (!image) {
                    this.$nextTick(() => {
                        this.generateFontFaceRules();
                    });
                }
            }
            let textAlign = ["left", "center"];
            let hideGradient = [true, false];

            let header = this.textSample(4, 6);
            let subheader;
            let body = this.textSample(10, 30);
            if (!header || header === "") header = "The Quick Brown Fox Jumps Over The Lazy Dog";
            if (!body || body === "") body = "The Quick Brown Fox Jumps Over The Lazy Dog";

            // get random samples from styleGuide.ai.textSamples.samples
            if (this.styleGuide?.ai?.textSamples?.samples) {
                let samples = this.styleGuide.ai.textSamples.samples;
                let sampleCount = samples.length;
                let sampleIndex = Math.floor(Math.random() * sampleCount);
                let sample = samples[sampleIndex];
                if (sample) {
                    header = sample.header;
                    subheader = sample.sub_header;
                    body = sample.body;
                }
            }

            let result = {
                imageIndex: imageIndex,
                header: header,
                body: body,
                sub_header: subheader,

                imageStyle: randomChoice(imageStyle),
                button: { show: true, text: "Shop now" },
                style: style,
                // imageInset: randomChoice(imageInset),
                imageInset: false,
                textAlign: randomChoice(textAlign),
                kind: "landing_page",
                hideGradient: false,
            };
            if (result.style === "fullBleed") {
                if (result.textAlign === "center") result.hideGradient = true;
                else result.hideGradient = true;
            } else {
                result.textAlign = "left";
            }
            if (this.mobile) {
                let mobileOverides = {
                    headerSize: 40,
                    bodySize: 20,
                    buttonSize: 20,
                    textAlign: "center",
                };
                result = { ...result, ...mobileOverides };
            }
            // console.log(result);
            return result;
        },
        fixHeaderStylesIfEmpty() {
            const scaleHeader = (source, target) => {
                this.typography.headers[target] = { ...this.typography.headers[source] };
                this.typography.headers[target].fontSize = this.typography.headers[source].fontSize * 1.5;
                this.typography.headers[target].lineHeight = this.typography.headers[source].lineHeight * 1.5;
                this.typography.headers[target].letterSpacing = this.typography.headers[source].letterSpacing * 1.5;
            };

            if (this.typography?.headers) {
                const headerPairs = [
                    ["h1", "h2"],
                    ["h2", "h3"],
                    ["h3", "h4"],
                    ["h4", "h5"],
                    ["h5", "h6"],
                    ["h6", "p"],
                ];

                for (let [target, source] of headerPairs) {
                    if (!this.typography.headers[target] && this.typography.headers[source]) {
                        scaleHeader(source, target);
                    }
                }
            }
        },
        async createNew(brand) {
            let id = brand.id || null;
            let url = brand.url;
            if (!url) return;
            await this.createStyleGuide(url, id, { originalID: id, newGuide: true });
        },
        async handleUrlSubmit(url, multiple) {
            if (!url) {
                url = this.websiteUrl;
            }
            const newItem = { id: "", url: url };
            await this.createStyleGuide(url, null, { sections: undefined, payload: undefined, multiple: multiple, newGuide: true });
        },
        loadUrl(url) {
            this.websiteUrl = url;
            this.createStyleGuide(url);
        },

        sortKeysByValue(obj) {
            if (!obj) {
                obj = this.textStyles;
            }

            if (Array.isArray(obj)) {
                return obj.map(val => this.sortKeysByValue(val));
            } else if (typeof obj === "object") {
                const entries = Object.entries(obj);

                return Object.fromEntries(entries.sort(([, v1], [, v2]) => v2 - v1).map(([k, v]) => [k, this.sortKeysByValue(v)]));
            }
            return obj;
        },

        async fetchStyleGuide(id) {
            this.loading = true;
            this.$store.dispatch("styleGuide/setStyleGuide", { id: id });
            if (id) {
                await this.$store.dispatch("styleGuide/fetchStyleGuide", { id: id, database: "brands" });
                await this.resetCSS();
                this.loading = false;
                this.pageLoaded();
                return;
                // let item = await this.fetchFromFirebase("brands", id);
                // console.log(item);
                // await this.setStyleGuide(item);
                // await this.getBrandImages(id);
                // this.updateStyleGuideProp("fetching", false);
                // if (this.styleGuide.name) document.title = `${this.styleGuide.name} | Brand Style Guide  | BrandBlocks`;
            } else {
                this.loading = false;
            }
        },
        async updateStyleGuide(url, id, styleGuide) {
            this.updateStyleGuideProp("loading", true);
            if (!url) url = this.styleGuide.url;
            if (!id) id = this.$route.params.id;
            try {
                // let brand = await this.findURLinSortedBrands(url);
                // this.updateStyleGuideProp("name", brand.name);
                // this.updateStyleGuideProp("description", brand.description);
            } catch (error) {
                console.error(error.message);
            }
            if (!id) {
                console.groupCollapsed("%cFailed to update", fail);
                console.error("No id", id);
                console.log(this.$route.params.id);
                console.log(this.styleGuide);
                console.groupEnd();
                return;
            }
            await this.createStyleGuide(url, id, { sections: undefined, payload: undefined, multiple: undefined, refresh: true, newGuide: false });
        },
        resetCSS(styleGuide) {
            // this.removeStyles();
            // this.fixHeaderStylesIfEmpty();
            this.applyStyles();
        },
        // async setStyleGuide(styleGuide) {
        // fix font urls to point to the cdn
        // if (styleGuide?.fonts?.length > 0) styleGuide = this.cdnLinkFixer(styleGuide);
        // console.log("Fixed Fonts", styleGuide);
        // this.$store.dispatch("styleGuide/setStyleGuide", styleGuide);

        // this.$nextTick(() => {
        //     this.fixHeaderStylesIfEmpty();
        //     this.applyStyles();
        // });
        // },
    },
};
</script>
