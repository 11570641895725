<template>
    <div :class="activityStateStyles" class="h-6 w-6 rounded-full relative f aic jcc">
        <!--        <CheckCircleIcon v-if="isDone" aria-hidden="true" class="h-6 w-6" />-->
        <div class="h-3.5 w-3.5 f aic jcc"><i :class="activityIcons" /></div>
        <!--        <div class="h-1.5 w-1.5 rounded-full ring-1"></div>-->
        <!--        <div v-else-if="isProcessing" class="h-1.5 w-1.5 rounded-full f aic jcc text-center relative">-->
        <!--            <i class="fas fa-sync animate__infinite fa-spin ease-back duration-3 fa-xs white shadow-purple-900 drop-shadow-xl"></i>-->
        <!--            <div class="p-absolute p-fill f aic jcc">-->
        <!--                <i class="fas fa-sync animate__infinite fa-spin ease-back duration-3 fa-xs"></i>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <PulsingLight color="green" />-->
        <!--            <PulsingLight color="green" />-->
        <!--        <Avatar size="xs">-->
        <!--            <div class="h-1.5 w-1.5 rounded-full ring-1"></div>-->
        <!--            <AvatarImage :image-url="item.url" :name="name" />-->
        <!--            <AvatarFallback :name="name" />-->
        <!--        </Avatar>-->
        <!--        <div v-else class="h-1.5 w-1.5 rounded-full ring-1" />-->
    </div>
    <!--    <Avatar size="xs">-->
    <!--        <div class="h-1.5 w-1.5 rounded-full ring-1"></div>-->
    <!--        <AvatarImage :image-url="imageUrl" :name="name" />-->
    <!--        <AvatarFallback :name="name" />-->
    <!--    </Avatar>-->
    <!--    {{ item }}-->
    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        {{ process }}
        {{ status }}
        <span class="font-medium text-gray-900">{{ name }}</span>
        {{ action }}
    </p>
    <!--        {{ item }}-->

    <time :datetime="dateTime" class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ date }}</time>
</template>
<script>
import { Avatar, AvatarFallback, AvatarImage } from "@/components/shad/ui/avatar";
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import PulsingLight from "@/views/PulsingLight.vue";

export default {
    name: "ActivityItemOther",
    components: { PulsingLight, CheckCircleIcon },
    props: {
        item: {
            type: Object,
            default: () => ({
                status: "default",
                user: { name: "" },
                dateTime: "",
                date: "",
            }),
        },
    },
    computed: {
        failed() {
            return this.status === "failed";
        },
        isCompleted() {
            return this.status === "completed";
        },
        isPending() {
            return this.status === "pending";
        },
        isUpdated() {
            return this.status === "updated";
        },
        isEdited() {
            return this.status === "edited";
        },
        isCreated() {
            return this.status === "created";
        },
        isProcessing() {
            return this.status === "processing";
        },
        isDone() {
            return this.status === "done";
        },
        activityIcons() {
            let iconMap = {
                done: "fa-sharp fa-regular dark fa-check-circle text-green-800",
                // done: "fa-sharp fa-sync animate__infinite fa-xs fa-spin ease-back duration-3",
                processing: "fas fa-sync animate__infinite fa-spin ease-back duration-3",
                queued: "fas fa-sync",
                completed: "far fa-check-circle",
                commented: "far fa-comment",
                failed: "far fa-times-circle fa-lg text-red-500",
                pending: "far fa-clock",
                updated: "far fa-sync",
                edited: "far fa-edit",
                created: "far fa-plus-circle",
                default: "far fa-circle",
            };
            return iconMap[this.status] || iconMap.default;
        },
        activityStateStyles() {
            let colorMap = {
                done: "bg-green-200",
                // done: "bg-green-100 text-green-500",
                queued: "bg-orange-200",
                processing: "bg-indigo-300 !text-indigo-500",
                completed: "bg-green-200",
                commented: "bg-green-200",
                failed: "bg-red-200",
                pending: "bg-yellow-200",
                updated: "bg-blue-200",
                edited: "bg-purple-200",
                created: "bg-red-200",
                default: "ring-gray-50",
            };
            return colorMap[this.status] || colorMap.default;
        },
        name() {
            return this.item?.name || this.item?.user?.name;
        },
        process() {
            return this.item?.process || "";
        },
        status() {
            let status = this.item?.status || "";
            status = status.toLowerCase();
            return status || "";
        },
        imageUrl() {
            return this.item?.user?.imageUrl;
        },
        action() {
            let actionMap = {
                done: "is done",
                queued: "is queued",
                pending: "is waiting",
                completed: "has completed",
                updated: "has updated an item",
                edited: "has edited an item",
                created: "has created a campaign",
                processing: "is processing",
                // Add further mappings here as required
            };
            let status = this.status || "";
            status = status.toLowerCase();
            return actionMap[status] || "is processing";
        },
        dateTime() {
            return this.item?.dateTime;
        },
        date() {
            return this.item?.date;
        },
    },
};
</script>
