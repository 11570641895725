<template>
    <div ref="headerWrapper">
        <div ref="headline" class="x mx-auto f fc aic jcc text-center">
            <div class="fixed p-fill vh-100 f aic jcc">
                <h1 :class="{ 'f-80': mobile, 'f-200': !mobile }" class="mx-auto mw-1000 !text-center" style="text-align: center !important">
                    <span>
                        Your Brand,
                        <br />
                    </span>
                    <span>Supercharged.</span>
                </h1>
            </div>
        </div>

        <div class="x f aic jcc">
            <div class="x">
                <div ref="campaignText"></div>
                <div class="x">
                    <div ref="inputContainer" class="x mx-auto vh-30 f aic jcc">
                        <div :class="{ 'mw-400': !mobile, 'mw-300': mobile }" class="br-10 p-3 border-light bg-snow text-gray-900 shadow text-left mx-auto inline-flex">
                            <div>
                                <span ref="text" class="inline"></span>
                                <BlinkingCursor />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Back, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import BlinkingCursor from "@/views/BlinkingCursor.vue";
import gsapMixin from "@/mixins/ai/gsapMixin";

gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(SplitText);

const { timeline, set, to, from } = gsap;
export default {
    name: "LandingPagePromptInput",
    components: { BlinkingCursor },
    mixins: [gsapMixin],
    data() {
        return {};
    },
    async mounted() {
        await this.$nextTick();
        this.firstAnimation();
        await this.$nextTick();
        this.inputAnimation();
    },
    methods: {
        inputAnimation() {
            let text = this.$refs.text;
            let input = this.$refs.inputContainer;
            set(text, { text: "", scale: 0, z: 0, y: 0, rotateX: 0 }, 0);
            set(input, { opacity: 0, width: 0, rotateZ: 50, scale: 0 }, 0.1);
            let time = "+=3000";
            let copy = "Make a campaign for our new <strong>Ginger</strong> <strong>Ale</strong> <strong>Launch</strong>. Include a few <strong class='text-green-500'>emails</strong> and a <strong>landing</strong> <strong>page</strong>.";
            if (this.mobile) time = "+=2000";
            let t2 = timeline({
                scrollTrigger: { trigger: input, start: "center center", end: time, scrub: true, pin: true, markers: this.markers({}) },
            });

            // Entrance animation: inputContainer

            let scale = 3;
            if (this.mobile) scale = 1.3;
            // INPUT INTRO
            t2.fromTo(input, { rotateZ: 25, opacity: 0, scale: 0, width: 0 }, { rotateZ: 0, opacity: 1, scale: scale, width: "auto", ease: Back.easeOut, duration: 0.3 }, 0.15);

            // INTRO TEXT
            t2.fromTo(text, { text: " ", opacity: 1 }, { text: copy, duration: 0.4, ease: Back.easeOut }, 0.4);

            // t2.fromTo(input, { scale: 3 }, { scale: 2, duration: 0.4 }, 0.6);
            // INPUT LEAVE
            t2.to(input, { opacity: -5, y: -100, duration: 0.3, rotateZ: 10, scale: -5, ease: "back.in" }, 0.9);
        },
        firstAnimation() {
            // this.$nextTick(() => {
            // Set initial states
            let headline = this.$refs.headline;
            set(headline, { opacity: 1, position: "fixed", align: "center", y: 0, rotateX: 0, background: "black" });
            let tl = timeline({
                scrollTrigger: {
                    trigger: headline,
                    id: "headline",
                    start: "top top",
                    end: "+=100",
                    scrub: true,
                    pin: true,
                    markers: this.markers({}),
                },
            });
            tl.to(headline, { opacity: 0, y: -50, rotateX: 20, ease: "power1.easeOut" });
            // tl.to(headline, { opacity: 0, y: -50, rotateX: 20, ease: "slow(0.5, 0.8, false)" });

            // t2.to(this.$refs["text"], { scale: 0, ease: "back.in" }, 0.99);
            // });
        },
    },
};
</script>
<style lang="scss">
@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>
