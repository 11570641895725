<template>
    <div v-if="sections">
        <template v-for="(section, index) in sections" :key="`section${index}`">
            <LandingPageSection :id="id" :identifier="`${identifier}.sections.${index}`" :index="index" :result="section" :sections="sections" style="min-width: 900px" />
        </template>
    </div>
</template>
<script>
import LandingPageSection from "@/components/generative_templates/LandingPageSection.vue";

export default {
    name: "LandingPageSections",
    components: { LandingPageSection },
    props: {
        identifier: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        sections: {
            type: Array,
            required: true,
        },
    },
};
</script>
