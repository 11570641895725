let blacklist_sites = [
    "amazon.com",
    "youtube.com",
    "facebook.com",
    "instagram.com",
    "linkedin.com",
    "channelape.com",
    "similarweb.com",
    "tiktok.com",
    "globenewswire.com",
    "marketresearchreports.com",
    "digitaljournal.com",
    "leaps.org",
    "openpr.com",
    "valuates.com",
    "tim.blog",
    "knowji.com",
    "facebook.com",
    "instagram.com",
    "twitter.com",
    "cbinsights.com",
    "pinterest.com",
    "youtube.com",
    "linkedin.com",
    "quora.com",
    "walmart.com",
    "amazon.com",
    "ebay.com",
    "etsy.com",
    "target.com",
    "bestbuy.com",
    "foundr.com",
    "hashtagpaid.com",
    "marketwatch.com",
];
export default blacklist_sites;
