// prepare_request_data.js
// import { useRoute } from "vue-router";
// import router from "@/routes";

import cleanMessages from "@/mixins/ai/clean_messages";

function prepareRequestData(request, id) {
    // console.log(useRoute);
    let { messages, model, functions, function_call, temperature, response_length, json_mode, ...rest } = request;

    let openAIRequest = {
        model,
        stream: true,
        requestId: id,
        functions,
        function_call,
        temperature,
        response_length,
        messages,
        json_mode,
        ...rest,
    };
    return openAIRequest;
}

export default prepareRequestData;
