<template>
    <div class="relative isolate overflow-hidden">
        <svg aria-hidden="true" class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]">
            <defs>
                <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" height="200" patternUnits="userSpaceOnUse" width="200" x="50%" y="-1">
                    <path d="M.5 200V.5H200" fill="none" />
                </pattern>
            </defs>
            <svg class="overflow-visible fill-gray-800/20" x="50%" y="-1">
                <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
            </svg>
            <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" height="100%" stroke-width="0" width="100%" />
        </svg>
        <div aria-hidden="true" class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]">
            <div class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-pink-200 to-[#4f46e5] opacity-20" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>
        <div class="mx-auto max-w-7xl px-0 pb-5 pt-5 sm:pb-40 flex pt-40 aic">
            <div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl pt-5">
                <div class="width-150"><logo /></div>
                <div class="mt-24 sm:mt-32 lg:mt-16" v-if="tag">
                    <a class="inline-flex space-x-6" href="#">
                        <span class="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">{{ tag }}</span>
                        <span class="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300" v-if="tag2">
                            <span>{{ tag2 }}</span>
                            <ChevronRightIcon aria-hidden="true" class="h-5 w-5 text-gray-500" />
                        </span>
                    </a>
                </div>
                <h1 class="mt-4 text-6xl font-bold tracking-tight text-white">{{ title }}</h1>
                <p class="mt-4 text-lg leading-8 text-gray-300">{{ description }}</p>
                <div class="mt-5 flex items-center gap-x-6">
                    <SimpleButton shimmer v-if="button?.text" shimmer-bg="gray-900 px-5 text-indigo-300 " color="white">{{ button.text }}</SimpleButton>
                    <SimpleButton v-if="secondaryButton?.text" color="text-snow" link>{{ secondaryButton.text }}</SimpleButton>
                </div>
            </div>
            <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                    <img alt="App screenshot" class="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" height="1442" src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png" width="2432" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ArrowPathIcon, CloudArrowUpIcon, Cog6ToothIcon, FingerPrintIcon, LockClosedIcon, ServerIcon } from "@heroicons/vue/20/solid";
import { BoltIcon, CalendarDaysIcon, UsersIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
// import {} from "@heroicons/vue/20/outline";
import Logo from "@/components/CoreUI/navigaion/logo.vue";
import SimpleButton from "@/views/SimpleButton.vue";
export default {
    name: "HeroSection",
    components: { SimpleButton, Logo, ChevronRightIcon },
    props: {
        title: { type: String, default: "Deploy to the cloud with confidence" },
        description: { type: String, default: "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua." },
        tag: { type: String, default: "Get started" },
        tag2: { type: String, default: "Live demo" },
        button: {
            type: Object,
            default: () => ({
                text: "Get started",
                link: "#",
            }),
        },
        secondaryButton: {
            type: Object,
            default: () => ({
                text: "Learn more",
                link: "#",
            }),
        },
    },
};
</script>
