<template>
    <div :class="{ 'f fc': mobile }" class="p-fixed p-fill mt-nav grid grid-cols-12 z-0 bg-gray-100 dark:bg-base-900 brand-bg">
        <collapsable-menu-item v-if="mobile" :key="$route" class="col-span-12">
            <template #title>
                <div class="pl-3 pr-3 fwb f aic jcb border-bottom py-2 !border-gray-100 dark:bg-base-900">
                    <div class="f-20 text-gray-500 hover:text-blue-900 f gap-3">
                        <div class="mx-1 text-center f aic jcc">
                            <i class="fas text-gray-500 o-3 fa-bars"></i>
                        </div>
                        Menu
                    </div>
                </div>
            </template>
            <AdminSidebar :sidebar-items="sidebarItems" />
        </collapsable-menu-item>
        <div class="col-span-12 md:col-span-2 md:mw-350 md:p-3 scrollSection dark:bg-base-700">
            <div class="md:pb-5 border-light br-20 overflow-hidden x shadow bg-gray-50 dark:bg-base-900">
                <Transition appear name="fade-up">
                    <AdminSidebar v-if="!mobile" :sidebar-items="sidebarItems" />
                </Transition>
            </div>
        </div>
        <div class="col-span-12 md:col-span-10 scrollSection dark:bg-base-700">
            <main v-cloak class="mt-nav">
                <RouterView :style-guide="styleGuide" :videos="styleGuide.videos"></RouterView>
            </main>
        </div>
    </div>
</template>

<script setup>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import AdminSidebar from "@/views/AdminSidebar.vue";
</script>

<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import { mapGetters } from "vuex";
import createStyleGuideMixin from "@/mixins/StyleGuideMixins/CreateStyleGuideMixin";
import styleGuideCSSMixin from "@/mixins/StyleMixins/StyleGuideCSSMixin";
import initializeStyleGuideMixin from "@/mixins/StyleGuideMixins/initializeStyleGuideMixin";

export default {
    name: "AdminHome",
    components: {},
    mixins: [initializeStyleGuideMixin],
    data: () => ({
        newURL: "",
        showSidebar: true,
        styleElements: null,
        loading: false,
        appliedStyles: false,
        savedUrls: [],
        pageTitle: "",
        websiteUrl: "",
        visualPallette: [],
        body: null,
        buttonColors: [],
        headerStyles: [],
        paragraphStyles: [],
        sortColors: "luma",
        doneMounting: false,
    }),
    mounted() {
        if (this.$route.params.id) {
            this.showSidebar = false;
            this.loading = true;
            this.getGenerativeFunctions();
            this.resetGuide(this.$route.params.id);
            this.loading = false;
        }
        this.pageLoaded();
    },
    computed: {
        ...mapGetters("styleGuide", ["styleGuide", "products", "fonts", "sources"]),
        ...mapGetters("styleGuide/image", ["images"]),
        ...mapGetters("styleGuide/llm", ["SubjectLines", "HeaderSamples", "AllWebCopy", "ImageList", "ProductArray", "CopyGuidelines", "Context", "Personas"]),
        sidebarItems() {
            let productCount = this.products?.length || 0;
            let imageCount = this.images?.length || 0;
            let fontCount = this.fonts?.length || 0;
            let videoCount = this.styleGuide?.videos?.length || 0;
            let sourceCount = this.styleGuide?.additionalText?.length || 0;
            return [
                { title: "Create new", link: "emails", icon: "fa-plus-circle" },
                { title: "Campaigns", link: "campaigns", count: 45, icon: "fa-envelope" },
                { title: "Style Guide", link: "styles", count: productCount, icon: "fa-paint-brush" },
                { title: "Products", link: "products", count: productCount, icon: "fa-tags" },
                { title: "Images", link: "images", count: imageCount, icon: "fa-image" },
                // { title: "Typefaces", link: "fonts", count: fontCount, icon: "fa-font" },
                // { title: "Buttons", link: "buttons", icon: "fa-mouse-pointer" },
                { title: "Videos", link: "videos", count: videoCount, icon: "fa-film" },
                { title: "Klaviyo Templates", link: "templates", icon: "fa-envelope" },
                { title: "Sources", link: "links", count: sourceCount, icon: "fa-link" },
                { title: "Fine tuning", link: "brand-voice", icon: "fa-sliders-h" },
                { title: "Settings + Billing", link: "settings", icon: "fa-cog" },
                // { title: "Billing", link: "settings", icon: "fa-credit-card" },
                { title: "Account", link: "account", icon: "fa-user" },
            ];
        },
    },
    methods: {
        pageLoaded() {
            this.doneMounting = true;
        },
        fixHeaderStylesIfEmpty() {
            const scaleHeader = (source, target) => {
                this.typography.headers[target] = { ...this.typography.headers[source] };
                this.typography.headers[target].fontSize = this.typography.headers[source].fontSize * 1.5;
                this.typography.headers[target].lineHeight = this.typography.headers[source].lineHeight * 1.5;
                this.typography.headers[target].letterSpacing = this.typography.headers[source].letterSpacing * 1.5;
            };

            if (this.typography?.headers) {
                const headerPairs = [
                    ["h1", "h2"],
                    ["h2", "h3"],
                    ["h3", "h4"],
                    ["h4", "h5"],
                    ["h5", "h6"],
                    ["h6", "p"],
                ];

                for (let [target, source] of headerPairs) {
                    if (!this.typography.headers[target] && this.typography.headers[source]) {
                        scaleHeader(source, target);
                    }
                }
            }
        },
        async createNew(brand) {
            let id = brand.id || null;
            let url = brand.url;
            if (!url) return;
            await this.createStyleGuide(url, id, { originalID: id, newGuide: true });
        },
        async handleUrlSubmit(url, multiple) {
            if (!url) {
                url = this.websiteUrl;
            }
            const newItem = { id: "", url: url };
            await this.createStyleGuide(url, null, {
                sections: undefined,
                payload: undefined,
                multiple: multiple,
                newGuide: true,
            });
        },
        loadUrl(url) {
            this.websiteUrl = url;
            this.createStyleGuide(url);
        },

        sortKeysByValue(obj) {
            if (!obj) obj = this.textStyles;

            if (Array.isArray(obj)) return obj.map(val => this.sortKeysByValue(val));
            else if (typeof obj === "object") {
                const entries = Object.entries(obj);
                return Object.fromEntries(entries.sort(([, v1], [, v2]) => v2 - v1).map(([k, v]) => [k, this.sortKeysByValue(v)]));
            }
            return obj;
        },
    },
    beforeUnmount() {
        this.doneMounting = false;
        this.removeStyles();
    },
};
</script>
