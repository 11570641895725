<template>
    <div class="color_pickers text-input">
        <p class="label mb-0">Background Color</p>
        <div class="f mb-2">
            <div class="f gap-1">
                <div v-for="i of colorCount" :class="`border-light c-25 f fwb f-11 aic jcc text-${slide.mode === 'dark' ? 'light' : 'dark'}-${i * 100} bg-${slide.mode === 'dark' ? 'dark' : 'light'}-${i * 100}`" @click.prevent="updateSlideProp('background_class', `bg-${slide.mode === 'dark' ? 'dark' : 'light'}-${i * 100}`, index)"></div>
            </div>
        </div>
        <p class="label mb-0">Text Color</p>
        <div class="f">
            <div class="f gap-1">
                <div v-for="i of 9" :class="`border-light c-25 f fwb f-11 aic jcc text-${slide.mode !== 'dark' ? 'light' : 'dark'}-${i * 100} bg-${slide.mode !== 'dark' ? 'dark' : 'light'}-${i * 100}`" @click.prevent="updateSlideProp('text_class', `text-${slide.mode !== 'dark' ? 'dark' : 'light'}-${i * 100}`, index)"></div>
            </div>
        </div>
    </div>
</template>
<script>
import updateSlideProp from "@/mixins/ai/update_slide_prop";
import presentationMixin from "@/mixins/presentations/presentationMixin";
export default {
    name: "SlideEditColors",
    mixins: [presentationMixin],
    props: {
        colorCount: {},
        index: {},
        slide: {},
    },
};
</script>
