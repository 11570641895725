<template>
    <div class="justify-fixed-center p-fixed bottom">
        <div v-if="document" ref="containter" :class="{ expanded: !expanded, '': expanded }" class="x input_wrapper" @mouseleave="expanded = false">
            <!--        <div v-if="!mobile" style="width: 275px"></div>-->
            <Transition appear enter-active-class="stagger-10">
                <div v-cloak :class="{ 'duration-5 p-0': !expanded, 'p-2': expanded }" class="f z-0 mb-0 mb-3">
                    <div :class="{ menu_collapsed: !expanded, menu_expanded: expanded }" class="floating_menu" @mouseover="expanded = true">
                        <p class="fwb text_string mb-0 text-snow">presentation</p>
                        <template v-if="!startingNew">
                            <template v-if="!startingNew">
                                <!--                            {{ changeResposeText }}-->
                                <p class="fwb text-snow">Presentation</p>
                            </template>
                            <template v-if="document && document.sections && document.sections.length > 0">
                                <div :class="{ '': !expanded, '': expanded }" class="f jcb no_input aic">
                                    <p :class="{ '': expanded, '': !expanded }" class="fwb line-height-smaller input_text my-0 whitespace-nowrap py-0 text-left text-base-50" style="transition: all 1s cubic-bezier(0.06, 1.59, 0.4, 0.84)">Presentation</p>
                                    <div class="f aic jcc justify-self-center py-2 pl-1" @click.prevent="expanded = !expanded">
                                        <i class="fas fa-ellipsis-h white f-24"></i>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <div class="p-relative f aic jcc">
                            <transition enter-active-class="menu-enter-active" enter-to-class="" leave-active-class="ease-elastic menu-leave-active " leave-to-class="opacity-0 menu-leave-to">
                                <!--                            opacity-100 menu-enter-to ease-elastic-->
                                <div v-if="expanded" :class="{ '': !expanded }" class="input_container x p-relative f aic jcc scale-100">
                                    <!--                                    fadeInUpSmooth-->
                                    <input ref="inputField" v-model="prompt" :placeholder="placeholderText" class="floating_input x f-20 border p-2 px-3 text-base-900 placeholder-gray-300 shadow-md" @focus="resizeHandler" @keyup.enter="enterHit(prompt)" />
                                    <div class="z-5 p-absolute right text-blue p-2" @click.prevent="enterHit(prompt)">
                                        <i class="fas fa-arrow-circle-up f-24"></i>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <!--                        <transition enter-active-class="menu-enter-active" enter-to-class="" leave-active-class="ease-elastic menu-leave-active " leave-to-class="opacity-0 menu-leave-to">-->
                        <!--                            <div class="z-5 relative">-->
                        <!--                                <ToggleButton v-if="expanded" vuex-key="presentation.googleImages" :border="1" :height="22" :width="35" label="🤖" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>-->
                        <!--                            </div>-->
                        <!--                        </transition>-->
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>
<script>
import StartDeckButton from "@/components/Presentations/StartDeckButton.vue";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import ToggleButton from "@/components/CoreUI/ToggleButton.vue";

export default {
    name: "FloatingMenu",
    components: { ToggleButton, StartDeckButton },
    mixins: [presentationMixin],
    props: {
        // document: {},
    },
    emits: ["startNewDeck", "newStoryboardModal"],
    data() {
        return {
            expanded: false,
            responseText: "",
            prompt: "",
            startingNew: true,
        };
    },
    computed: {
        titleText() {
            if (this.document && this.document.sections && this.document.sections[this.sectionIndex]) {
                if (this.document.sections[this.sectionIndex].title) {
                    return this.document.sections[this.sectionIndex].title;
                } else if (this.document.sections[this.sectionIndex].body) {
                    return this.document.sections[this.sectionIndex].body;
                }
            } else {
                return "Untitled";
            }
        },
        showCreateModal() {
            if (this.document && this.document.sections) {
                this.startingNew = false;
            } else if (this.$route.path.includes("storyboard")) {
                this.startingNew = true;
                // this.$emit('newStoryboardModal')
            } else {
                this.startingNew = true;
            }
        },
        changeResposeText() {
            const sectionIndex = this.sectionIndex;
            this.document.sections[sectionIndex].enhancedBody = this.responseText;
        },
        placeholderText() {
            if (this.startingNew) {
                if (this.$route.path.includes("storyboard")) {
                    return "What is your new storyboard about?";
                } else if (this.$route.path.includes("presentation")) {
                    return "What is your new deck about?";
                }
            } else {
                return "Leave a comment";
            }
        },
    },
    watch: {
        expanded(val) {
            if (val) {
                //   const input = this.$refs.inputField;
                this.$nextTick(() => {});
            }
        },
        responseText: {
            handler: function (val, oldVal) {
                if (val !== null) {
                    const sectionIndex = this.sectionIndex;
                    this.document.sections[sectionIndex].enhancedBody = val;
                    val.replace("[[DONE]]", "").replace("null", "");
                }
            },
            deep: true,
        },
    },
    mounted() {
        const self = this;
        if (!this.mobile) {
            this.expanded = true;
        }
        if (this.$route.path.includes("storyboard")) {
            this.startingNew = true;
        }
        window.visualViewport.addEventListener("resize", self.resizeHandler());
    },
    methods: {
        resizeHandler() {
            this.$nextTick(() => {
                if (this.expanded) {
                    setTimeout(() => {
                        this.$refs.containter.style.bottom = "0px";
                    }, 500);
                }
                this.$refs.inputField.focus();
            });
        },
        async modifyPrecursor(prompt) {
            if (!this.startingNew) {
                let instructions = `The following is a user's instructions to edit a presentation slide ( choices are: image, bodyText, titleText, createNew)\n\n${prompt}\n\nUser intent is to edit the slide's: `;
                let request = {
                    model: "text-davinci-003",
                    prompt: instructions,
                    temperature: 1,
                    max_tokens: 50,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                };
                const response = await openai.createCompletion(request);
                let text = response.data.choices[0].text;
                text = text.toLowerCase();
                console.error(text);
                if (text.includes("create") || text.includes("new")) {
                    this.startingNew = true;
                    this.prompt = "";
                } else if (text.includes("body")) {
                    this.modifyPassage(prompt);
                }

                // else else {
                //       return;
                //       await this.modifyImage(prompt);
                //   }
            } else {
                this.startDeck(prompt);
            }
        },
        async cancelNewDeck() {
            await this.removeFromFirebase("documents", this.$route.params.id);
            this.$router.go(-1);
            this.$emit("close");
        },
        startDeck(prompt, kind) {
            if (this.$route.path.includes("presentation")) {
                this.$emit("newDeckModal", prompt);
                console.error("starting deck");
            } else if (this.$route.path.includes("storyboard")) {
                console.error("starting storyboard");
                this.$emit("newStoryboardModal", prompt);
            }
            this.prompt = "";
        },
        // async newDeck(prompt) {
        //     if (prompt) {
        //         await this.startDeck();
        //
        //     } else {
        //         this.$emit("newDeck", this.prompt);
        //     }
        // },
        enterHit(prompt) {
            if (prompt.includes("cancel")) {
                this.startingNew = false;
                this.prompt = "";
            }
            this.modifyPrecursor(prompt);
        },
        modifyPassage(prompt) {
            let comment = {
                comment: prompt,
                original: this.document.sections[this.sectionIndex].enhancedBody,
                user: "Asher Hunt",
            };
            // this.addComment(this.$route.params.id, comment);
            // this.comments.push(comment);
            this.prompt = "";
            const sectionIndex = this.sectionIndex;
            // this.showComments = false;
            this.responseText = "";
            let text = this.document.sections[sectionIndex].body;
            if (this.document.sections[sectionIndex].enhancedBody) {
                text = this.document.sections[sectionIndex].enhancedBody;
            }
            let context = `CONTEXT:
                            ${this.document.summary}

                            `;
            let modString = `${context}
ORIGINAL PASSAGE:
${text}

INSTRUCTIONS:
${prompt}

UPDATED PASSAGE:
###

            `;

            return this.streamText(modString, "responseText"); // NOTE: i want this.streamText() to write to stream to this.responseText
        },
        async streamText(prompt, VueProperty) {
            let streamPrompt = `${prompt}
(please type "[[DONE]]" on a new line when update is complete.)
###

`;
            const request = {
                model: "text-davinci-003",
                prompt: streamPrompt,
                temperature: 0.9,
                max_tokens: 500,
                top_p: 0.9,
                frequency_penalty: 0,
                presence_penalty: 0,
                stream: true,
                stop: "[[DONE]]",
            };
            console.error();
            const self = this;
            console.log(request);
            try {
                const response = await fetch(`${this.apiURL}streamTest`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(request),
                });

                const reader = response.body.getReader();
                while (true) {
                    console.log(response.body);
                    const { value, done } = await reader.read();
                    self[VueProperty] += new TextDecoder("utf-8").decode(value);
                    console.log(new TextDecoder("utf-8").decode(value));
                    if (self[VueProperty].includes("[[DONE]]" || done)) {
                        self[VueProperty] = self[VueProperty].replace(/\[\[DONE\]\]/g, "");
                        await this.saveSection();
                        // this.addComment(this.$route.params.id, {
                        //     comment: "Ok! I did that",
                        //     original: self[VueProperty],
                        //     user: "Clearmind",
                        // });
                        // this.comments.push({comment: 'Ok! I did that', original: self[VueProperty],user:'Clearmind'});
                        break;
                        return;
                    }
                    if (done) {
                        console.error("FINISHED");
                        break;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.h-0 {
    height: 0px;
}
.ease-elastic {
    //transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 1s cubic-bezier(0.06, 1.59, 0.4, 0.84);
    animation: all 1s cubic-bezier(0.06, 1.59, 0.4, 0.84);
    //transition: all 1s cubic-bezier(0.06, 1.59, 0.4, 0.84);
}

.input_wrapper {
    @apply transition-all;
    @apply ease-elastic;
    @apply duration-10;
    &.expanded {
        @apply duration-10;
        @apply ease-elastic;
        @apply transition-all;
    }
}

.floating_input {
    //@apply transition-all;
    //@apply duration-10;
    //@apply ease-swing-in-up;
    @apply scale-0;
    @apply bg-red;
    @apply opacity-0;
    //@apply duration-5;
    //@apply duration-1000;
}

.text_string {
    @apply px-3 py-1;
}

.menu_expanded {
    .text_string {
        //@apply transition-all;
        //@apply duration-2;
        @apply height-0;
        //@apply ease-swing-in-up;
        @apply scale-110;
        @apply bg-red;
        @apply opacity-0;
    }

    .floating_input {
        //@apply transition-all;
        @apply scale-0;
        @apply opacity-100;
        @apply bg-snow;
        //@apply duration-10;
        //@apply ease-swing-in-up;
    }
}

.menu_collapsed {
    //bg-blur-xl btn-bubble border-light
}

.floating_menu {
    //@apply transition-all;
    @apply z-50 mx-auto bg-base-800/80 text-center shadow;
    @apply px-3;
    //@apply transition-all;
    //@apply duration-1;
    //@apply ease-elastic;
    &.menu_collapsed {
        transform: rotate(0deg);
        border-radius: 50px;

        .input_container {
            @apply height-0 w-0;
        }

        //@apply transition-all;
        //@apply ease-elastic;
        @apply rounded-full p-0;
        .no_input {
            //@apply rounded-xl;

            @apply px-3;
            .input_text {
                @apply mb-0 mr-2;
            }
        }
    }

    &.menu_expanded {
        border-radius: 15px;
        //transform: rotate(180deg);
        @apply p-0;
        @apply p-2 md:p-3;
        @apply md:mb-3;
        .no_input {
            .input_text {
                @apply mb-2 mr-2 mt-0 p-1 py-0 pt-0 text-red md:mb-2;
            }
        }
    }

    //@apply duration-1;
    //@apply ease-elastic;
}

.input_container {
    @apply w-auto;

    .menu_expanded {
        @apply bg-red;
        @apply opacity-0;
    }

    .menu_collapsed {
        @apply bg-base-800/80;
        @apply opacity-50;
    }

    input {
        //@apply transition-all;
        //@apply duration-10;
        //@apply duration-5;
        //@apply ease-elastic;
        @apply width-min-400;
    }
}

.input_wrapper {
    @apply inline-block w-auto;
}

.menu {
    &-enter-active {
        @apply height-0 width-0 translate-y-10 scale-0 opacity-0;
        @apply br-70;
    }

    &-enter-to {
        @apply ease-elastic h-auto translate-y-0 scale-100 opacity-0;
        //@extend .fadeInUpSmooth !optional;
        @apply br-20;
    }

    -leave-active {
        //@apply width-0 height-0 scale-100 opacity-0 ease-elastic duration-1 translate-y-0 width-0;
        @apply duration-1;
        @apply br-20;
        @apply width-0 br-50 h-0 translate-y-20 scale-0 scale-0 px-3  opacity-0;
        @apply transition-all;
    }

    &-leave-to {
        @apply br-70;
        @apply width-0 br-50 h-0 translate-y-20 scale-0 scale-0 px-3  opacity-0;
        @apply width-max-0;
        @apply px-3;
    }
}
</style>
