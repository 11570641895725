<template>
    <div class="x p-relative z-0 mw-1400 mx-auto overflow-x-scroll">
        <h1>Brands</h1>
        <table class="min-w-full divide-y divide-gray-200 shadow-sm">
            <thead class="bg-gray-50 sticky-top mt-nav pt-nav">
                <tr>
                    <th scope="col" :class="tableHeaderStyle">Rank</th>
                    <th scope="col" :class="tableHeaderStyle">Name</th>
                    <th scope="col" :class="tableHeaderStyle">Description</th>
                    <th scope="col" :class="tableHeaderStyle">URL</th>
                    <th scope="col" :class="tableHeaderStyle">ID</th>
                    <th scope="col" :class="tableHeaderStyle">Match</th>
                    <th scope="col" :class="tableHeaderStyle">Categories</th>
                    <th scope="col" class="px-2 py-2">Actions</th>
                </tr>
            </thead>
            <recycle-scroller :items="brands" :item-size="50" key-field="id" v-if="brands.length > 0" class="virtual-scroller" style="height: 80vh; overflow-y: auto">
                <template v-slot="{ item: brand }">
                    <tr class="" style="border-bottom: 1px solid black">
                        <td class="px-2 py-2 text-bold border-bottom">
                            <div class="text-sm text-gray-900" v-if="brand.rank">{{ brand.rank }}</div>
                        </td>
                        <td class="px-2 py-2 text-bold border-bottom">
                            <div class="text-sm text-gray-900" v-if="brand.name">{{ brand.name }}</div>
                        </td>
                        <td class="px-2 py-2 border-bottom">
                            <div class="text-sm text-gray-500" v-if="brand.description">{{ brand.description }}</div>
                        </td>
                        <td class="px-2 py-2 mw-250 border-bottom">
                            <div class="text-sm text-gray-500 break-all" v-if="brand?.url">{{ brand?.url }}</div>
                        </td>
                        <td class="px-2 py-2 border-bottom">
                            <div class="text-sm text-gray-500" v-if="brand?.id">{{ brand?.id }}</div>
                        </td>
                        <td class="px-2 py-2 border-bottom">
                            <div class="text-sm text-gray-500" v-if="brand?.match">{{ brand?.match }}</div>
                        </td>
                        <td class="px-2 py-2 border-bottom">
                            <div class="text-sm text-gray-500" v-if="brand?.categories">{{ brand?.categories.join(", ") }}</div>
                        </td>
                        <td class="px-2 py-2 text-sm">
                            <pre v-if="brand">{{ brand }}</pre>
                        </td>
                    </tr>
                </template>
            </recycle-scroller>
        </table>
    </div>
</template>
<script>
// import BaseButton from "@/components/CoreUI/BaseButton.vue";
import styleGuideCSSMixin from "@/mixins/StyleMixins/StyleGuideCSSMixin";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import { RecycleScroller } from "vue-virtual-scroller";

export default {
    components: { RecycleScroller },
    mixins: [styleGuideCSSMixin, styleGuideMixin],
    data() {
        return {
            loadingStyles: false,
            showGuides: false,
        };
    },
    computed: {
        tableHeaderStyle() {
            return `px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider x width-min-250`;
        },
        brands() {
            let brands = this.$store.state.styleGuide.styleGuides.filter(Boolean);
            brands = brands.sort((a, b) => (a.rank || 1000) - (b.rank || 1000));

            brands = brands.filter((brand, index, self) => index === self.findIndex(t => t.url === brand.url));
            brands = brands.map((brand, index) => {
                if (!brand.rank) brand.rank = index + 1;
                return brand;
            });
            return brands;
        },
    },
    // components: { BaseButton },
    async mounted() {
        if (this.$store.state.styleGuide.styleGuides.length === 0) await this.getStyleGuides();
    },
    methods: {
        async selectBrand(index, brand) {
            this.$router.push(`/styleGuide/${brand.id}`);
        },
    },
};
</script>
<!--      <tbody class="" v-if="false">-->
<!--                <tr class="" v-for="(brand, index) in brands" style="border-bottom: 1px solid black">-->
<!--                    <td class="px-2 py-2 text-bold border-bottom">-->
<!--                        <div class="text-sm text-gray-900" v-if="brand.rank">{{ brand.rank }}</div>-->
<!--                    </td>-->
<!--                    <td class="px-2 py-2 text-bold border-bottom">-->
<!--                        <div class="text-sm text-gray-900" v-if="brand.name">{{ brand.name }}</div>-->
<!--                    </td>-->
<!--                    <td class="px-2 py-2 border-bottom">-->
<!--                        <div class="text-sm text-gray-500" v-if="brand.description">{{ brand.description }}</div>-->
<!--                    </td>-->
<!--                    <td class="px-2 py-2 mw-250 border-bottom">-->
<!--                        <div class="text-sm text-gray-500 break-all" v-if="brand?.url">{{ brand?.url }}</div>-->
<!--                    </td>-->
<!--                    <td class="px-2 py-2 border-bottom">-->
<!--                        <div class="text-sm text-gray-500" v-if="brand?.id">{{ brand?.id }}</div>-->
<!--                    </td>-->
<!--                    <td class="px-2 py-2 border-bottom">-->
<!--                        <div class="text-sm text-gray-500" v-if="brand?.match">{{ brand?.match }}</div>-->
<!--                    </td>-->
<!--                    <td class="px-2 py-2 border-bottom">-->
<!--                        <div class="text-sm text-gray-500" v-if="brand?.categories">{{ brand?.categories.join(", ") }}</div>-->
<!--                    </td>-->
<!--                    <td class="px-2 py-2 text-sm" v-if="brand">-->
<!--                        <pre>-->
<!--                        {{ brand }}-->
<!--                      </pre-->
<!--                        >-->
<!--                    </td>-->
<!--                </tr>-->
<!--            </tbody>-->
