<template>
    <div class="fixed bottom left right f aic jcc px-2 z-5 !text-balance" @mouseleave="$emit('hover-edit'), (editFonts = false)" style="transform: translateY(80%) translateX(-0%); bottom: 10px; z-index: 10000">
        <Transition appear name="fade-up" mode="out-in">
            <div class="f aic jcc gap-2" v-if="hovered && !editing && editFonts">
                <div class="z-5 mw-250 scroll-blocked-x bg-gray-900/90 blur-bg border-light rounded-full relative animated fadeInUpSmooth d-1">
                    <UpdateFontPicker @mouseleave="undoFontPreview" class="!py-1 whitespace-nowrap" size="xs" @update-temp-font="updateTempFont" :fonts="fonts" @update-font="updateSectionFont" />
                </div>
                <div class="c-30 border-light bg-gray-900/90 text-gray-500 f aic jcc animated fadeInUpSmooth d-3" @click="editFonts = !editFonts">
                    <i class="f-15 fas fa-times"></i>
                </div>
            </div>

            <div class="f aic jcc gap-2" v-else-if="hovered && !editing && !editFonts">
                <div class="c-30 border-light bg-gray-900/90 text-gray-200 f aic jcc animated fadeInUpSmooth ease-back d-1" @click.prevent="updateAlignment">
                    <i v-if="textAlign === 'left'" class="f-15 fas fa-align-left"></i>
                    <i v-else class="f-15 fas fa-align-center"></i>
                </div>
                <div>
                    <div class="f-11 f rounded-full aic jcc bg-gray-900/90 border-light py-0 gap-3 z-5 px-2 transition-all animated fadeInUpSmooth ease-back d-2">
                        <input v-model="editingFontSize" class="accent-amber-500 relative z-4 range-input py-1" :max="maxSize" :min="minSize" type="range" @blur="changedFontSize" />
                        <div class="y f aic jcc border-snow text-gray-200">{{ editingFontSize }}</div>
                    </div>
                </div>
                <!--              font icon -->
                <div class="c-30 border-light bg-gray-900/90 text-gray-200 f aic jcc animated fadeInUpSmooth d-3 ease-back" @click="editFonts = !editFonts">
                    <i class="f-15 fas fa-font"></i>
                </div>
            </div>
        </Transition>
        <!--        <div class="p-fixed bg-gray-900" style="bottom: -200px">{{ font }}</div>-->
    </div>
</template>
<script>
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import UpdateFontPicker from "@/components/generative_templates/Editing/UpdateFontPicker.vue";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";

export default {
    name: "EditingFont",
    components: { UpdateFontPicker },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin],
    props: {
        fontSize: { default: 60, type: [Number, String] },
        hovered: { default: false, type: Boolean },
        editing: { default: false, type: Boolean },
        maxSize: { type: [Number, String], default: 100 },
        index: { type: Number, default: 0, required: true },
        minSize: { type: [Number, String], default: 30 },
        identifier: { type: String, required: true },
        section: { type: Object, default: () => ({}) },
        kind: { type: String, default: "header" },
    },
    data() {
        return {
            editingFontSize: null,
            isEditing: false,
            // isHovered: false,
            editFonts: false,
            alignment: "left",
            tempFont: null,
            originalFont: null,
        };
    },
    watch: {
        editingFontSize: {
            handler(val, oldVal) {
                if (val !== oldVal && oldVal !== null) {
                    this.$emit("changed-font-size", this.editingFontSize);
                }
            },
        },
    },
    mounted() {
        this.editingFontSize = this.fontSize;
        if (this.section?.textAlign) {
            this.alignment = this.section.textAlign;
        }
    },
    methods: {
        updateTempFont(tempFont) {
            if (!this.originalFont) this.originalFont = this.font[this.kind];
            this.tempFont = tempFont;
            if (this.kind === "header") this.updateHeaderFont(this.tempFont);
            else if (this.kind === "body") this.updateBodyFont(this.tempFont);
            else if (this.kind === "subheader") this.updateSubheaderFont(this.tempFont);
        },
        undoFontPreview() {
            if (this.kind === "header") this.updateHeaderFont(this.originalFont.header);
            else if (this.kind === "body") this.updateBodyFont(this.originalFont.body);
            else if (this.kind === "subheader") this.updateSubheaderFont(this.originalFont.body);
        },
        updateSectionFont(font) {
            if (this.kind === "header") this.updateHeaderFont(font);
            else if (this.kind === "body") this.updateBodyFont(font);
            else if (this.kind === "subheader") this.updateSubheaderFont(font);
        },
        updateAlignment() {
            let newAlignment;
            if (this.textAlign === "left") newAlignment = "center";
            else if (this.textAlign === "center") newAlignment = "left";
            else newAlignment = "center";
            this.updateTextAlign(newAlignment);
        },
        cancelHover() {
            this.$emit("cancel-hover");
        },

        changedFontSize() {
            this.$emit("changed-font-size", this.editingFontSize, true);
        },
    },
};
</script>
