<template>
    <div :class="slideContainerClasses">
        <div v-if="imageTheme" :class="slideContainerClasses">
            <!--        <div v-if="imageTheme" :ref="`slide-${id}${index}`" :class="slideContainerClasses">-->
            <div class="col-12 vh-0 vh-90 text-left">
                <!--            <div :ref="`image-${id}${index}`" class="col-12 vh-0 vh-90 text-left">-->
                <div
                    v-if="section.imageURL || section.images"
                    class="p-absolute p-fill"
                    :class="{ 'o-100': section.background_class === 'bg-dark-1000', 'o-5': section.background_class !== 'bg-dark-1000' && section.background_class !== 'bg-light-1000' }"
                    :style="`mix-blend-mode: ${section.background_class !== 'bg-dark-1000' ? blendMode : 'none'};`">
                    <PresentationImageContainer :key="`image${index}`" class="!font-bold" :full-bleed="true" :document="document" :document-id="documentId" :image-dark="imageDark" :index="index" :section="section"></PresentationImageContainer>
                </div>
                <div class="f jcs y aic p-5">
                    <!--                <div :ref="`text-${id}${index}`" class="f jcs y aic p-5">-->
                    <div class="z-5 mx-auto max-w-md text-center">
                        <PresentationSlideText :section="section" :full-bleed="true" :index="index"></PresentationSlideText>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import presenationMixin from "@/mixins/presentations/presentationMixin";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin, presenationMixin],
    props: {
        id: {},
        index: {},
        section: {},
        documentId: {},
        slideContainerClasses: {},
    },
    watch: {
        "document.theme.colors": {
            handler: function (val, oldVal) {
                if (val !== oldVal) {
                    this.textTheme();
                    // this.slideColors();
                }
            },
            deep: true,
        },
    },
    data() {
        return {
            imageDark: false,
        };
    },
    computed: {
        blendMode() {
            if (this.section?.mode === "dark") {
                return "screen";
            } else {
                return "multiply";
            }
        },
        blendModeOpposite() {
            if (!this.imageDark) {
                return "screen";
            } else {
                return "multiply";
            }
        },
    },
    mounted: function () {
        this.slideColors();
        // this.imageTheme;
        // this.randomImageStyle();
    },
    methods: {
        randomImageStyle() {
            this.imageDark = Math.random() < 0.5 ? true : false;
        },
    },
};
</script>
