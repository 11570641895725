<template>
    <div class="grid-cols-12 grid container">
        <div class="col-span-12 text-snow block x mw-1400 mx-auto pb-8">
            <h1>Pixel perfect AI Generated Campaigns</h1>
        </div>
        <div class="col-span-3 f jcc p-3">
            <div class="white f fc gap-5 mw-400">
                <ListContent key="a" title="Pixel Perfect" body="Craft pixel-perfect marketing with our AI. Effortless and exact, our technology elevates your email and landing pages to new heights of brand alignment." />
                <ListContent key="b" title="Customizable" body="Our platform transforms your ideas into stunning campaigns with just a few clicks. It’s customization without complexity, ensuring a unique brand experience every time." />
                <ListContent key="g" title="Always On Brand" body="Maintain your brand's voice and desig language across all campaigns with ease. Our intelligent design ensures your message resonates consistently, with creative flair." />

                <!--                    <p class="o-5">BrandBlocks blends consistency with creativity, ensuring your brand's voice is clear, compelling, and ever-evolving through intelligent design.</p>-->
            </div>
        </div>
        <div class="col-span-6 text-center">
            <div ref="container" class="f jcc mx-auto">
                <div ref="EmailContainer" class="br-20 vh-100 relative">
                    <div class="absolute left right top height-800 width-500" ref="rainbow" style="filter: blur(50px); transform: rotate(95deg); background: linear-gradient(90deg, #ff0000 0%, #ff7f00 17.5%, #ffff00 35%, #00ff00 52.5%, #0000ff 70%, #4b0082 87.5%, #8f00ff 100%)"></div>
                    <div class="absolute left right top height-800 width-500" ref="rainbowMain" style="filter: blur(150px); transform: rotate(45deg); background: linear-gradient(90deg, #ff0000 0%, #ff7f00 17.5%, #ffff00 35%, #00ff00 52.5%, #0000ff 70%, #4b0082 87.5%, #8f00ff 100%)"></div>
                    <div ref="emailBox" class="relative x bg-gray-100 vh-70 overflow-hidden br-20 x width-min-500 f jce isolate mh-50" style="max-height: 900px; overflow: scroll">
                        <div v-if="text" class="overflow-scroll block relative text-left f fc">
                            <div class="x f">
                                <LandingPageLoader v-if="!text.sections" :result="{ name: text.name, thinking: text.thinking }" class="px-3 x mw-500 y"></LandingPageLoader>
                            </div>
                            <div>
                                <CampaignHeader ref="emailHeader" class="flex-shink-0" :result="text" inline type="isEmail"></CampaignHeader>
                            </div>
                            <div>
                                <EmailSections v-if="text?.sections?.length > 0" :footer="text.email_footer" :sections="text.sections" class="bg-gray-100 mx-auto" style="min-height: 70vh"></EmailSections>
                            </div>
                        </div>
                        <div v-else class="x f relative p-3">
                            <div class="x y bg-green-400 f aic jcc x fc white gap-4 z-1 br-10">
                                <BaseButton class="!bg-green-500 !text-snow" label="Play" icon-only icon="fas fa-play" rounded size="xl" style-type="tertiary" @click="startStreaming" />
                                <h2 class="text-snow mb-3">See it in action</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-3 f ais p-3 jcs">
            <div class="white f fc gap-5 jcc mw-300">
                <ListContent key="we" title="Streamlined Creation" body="AI precision tailors your content, ensuring each pixel serves your campaign's purpose." />
                <ListContent key="dfd" title="Empower Your Design" body="Adaptive tools for impactful, brand-aligned content with minimal input." />
                <ListContent key="er" title="Consistent Innovation" body="Your brand's character, maintained across all marketing fronts by our intelligent AI." />
            </div>
            <!--            <div class="mw-300 jcc white f fc gap-5">-->
            <!--                &lt;!&ndash;<h5>Precision Automated</h5>&ndash;&gt;-->
            <!--                &lt;!&ndash;<p class="o-5">Pixel-perfect marketing, delivered by AI. No fuss, just results.</p>&ndash;&gt;-->
            <!--            </div>-->
        </div>
    </div>
</template>
<script>
import gsapMixin from "@/mixins/ai/gsapMixin";
import EmailSections from "@/components/Admin/EmailSections.vue";
import LandingPageLoader from "@/components/Admin/LandingPageLoader.vue";
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import json_helpers from "@/mixins/ai/json_helpers";
import { mockCampaign, mockThinking } from "@/mixins/StyleGuideMixins/mockCampaign.js";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ListContent from "@/views/ListContent.vue";

gsap.registerPlugin(ScrollTrigger);
const { timeline, set, to, from } = gsap;
const { validateJSON } = json_helpers;

// gsap.registerPlugin(SplitText);

export default {
    name: "HomePageEmailPreview",
    components: { ListContent, BaseButton, CampaignHeader, LandingPageLoader, EmailSections },
    mixins: [styleGuideMixin, gsapMixin],
    data() {
        return {
            text: "",
            mockCampaign: mockThinking,
            campaignItems: mockThinking,
            mock: mockCampaign,
            headerSize: 200,
            loaded: false,
            colorProg: 1,
            currentStream: "",
            currentIndex: 0,
            speed: 15,
            chunkSize: 3,
        };
    },
    computed: {
        sections() {
            return this.text?.sections;
        },
        emailScale() {
            if (this.mobile) {
                return `transform: scale(0.8)!important;`;
            } else {
                return `transform: scale(1)!important;`;
            }
        },
    },
    created() {
        this.fetchStyleGuide();
        this.loaded = true;
    },
    updated() {
        // this.$forceUpdate();
    },
    async mounted() {
        if (this.mobile) this.headerSize = 100;
        this.campaignItems = validateJSON(this.campaignItems);
        this.$nextTick(() => {
            this.rotateRainbow();
        });
    },
    methods: {
        rotateRainbow() {
            this.$nextTick(() => {
                let rainbow = this.$refs.rainbow;
                let rainbowMain = this.$refs.rainbowMain;
                let emailBox = this.$refs.emailBox;
                let tl = timeline({
                    scrollTrigger: {
                        trigger: rainbow,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true,
                    },
                });
                let emb = timeline({
                    scrollTrigger: {
                        trigger: rainbow,
                        start: "center center",
                        end: "+=1000",
                        scrub: true,
                        pin: emailBox,
                        pinSpacing: true,
                    },
                });
                tl.addLabel("beginning", 0);
                tl.fromTo(
                    rainbow,
                    { scale: 0, rotation: 95, opacity: 0, filter: "blur(150px)" }, //
                    { duration: 1, scale: 2, opacity: 0.2, rotation: 360, filter: "blur(300px)", ease: "back.out(2)" },
                    0,
                );
                tl.fromTo(
                    rainbowMain,
                    { scale: 1, rotation: 45, opacity: 0, filter: "blur(150px)" }, //
                    { duration: 1, scale: 2, opacity: 0.2, rotation: 720, filter: "blur(300px)", ease: "back.out(2)" },
                    0.1,
                );
                let color = "#c0006f";
                tl.fromTo(
                    emailBox,
                    { scale: 0.95, y: 20, opacity: 1, boxShadow: `0px 0px 0px ${color}` }, //
                    { duration: 1, y: -50, scale: 1.1, boxShadow: `0px 0px 200px ${color}`, opacity: 1, ease: "back.out(2)" },
                    0,
                );
                emb.to(
                    emailBox,
                    {
                        duration: 0.2, //
                        y: 0,
                        scale: 0.9,
                        opacity: 0,
                        boxShadow: `0px 0px 0px ${color}`,
                        ease: "back.out(2)",
                    },
                    0.99,
                );
                // tl.fromTo(
                //     rainbow,
                //     { rotation: 360, opacity: 1, filter: "blur(500px)" },
                //     { duration: 0.5, rotation: 720, filter: "blur(150px)" },
                //     0.5, //
                // );
                // tl.to(rainbow, { duration: 0.5, rotation: 360, filter: "blur(50px)" }, ">");
            });
        },
        populateThinking() {
            this.$nextTick(() => {
                const ref = this.$refs["thinkingText"];
                const fullText = this.mock; // The full text to display gradually
                const container = this.$refs.container;
                const emailContainer = this.$refs.EmailContainer;
                // const words = fullText.match(/.{1,3}/g) || [];
                const words = fullText.split(" ") || [];
                const length = 5000;
                let currentChunk = "";

                set(ref, { text: "" });

                // TIMELINE 1
                to(ref, {
                    scrollTrigger: {
                        trigger: container,
                        start: "top top",
                        end: `+=${length}`,
                        scrub: true,
                        pin: emailContainer,
                        pinSpacing: true,
                        onUpdate: scrollTrigger => {
                            const progress = scrollTrigger.progress;
                            const chunksToShow = Math.floor(words.length * progress);
                            currentChunk = words.slice(0, chunksToShow).join(" ");
                            // curre ntChunk = words.slice(0, chunksToShow).join("");
                            this.text = validateJSON(currentChunk) || this.text;
                        },
                    },
                });
            });
        },
        async fetchStyleGuide(id = "74QgERD8e7N8o10B6g3T") {
            this.loading = true;
            if (id) {
                let item = await this.fetchFromFirebase("brands", id);
                await this.setStyleGuide(item);
                await this.getBrandImages(id);
                this.updateStyleGuideProp("fetching", false);
                this.loading = false;
            } else this.loading = false;
        },
        mountJSONObject() {
            const topLevelOrder = ["name", "relevant_image_indexes", "thinking", "subject_line", "preview_text", "sections"];
            const thinkingOrder = ["thoughts", "reasoning", "plan", "criticism", "speak"];
            const sectionOrder = ["type", "planning", "imageIndex", "insetImage", "backgroundColor", "color", "imageStyle", "header", "sub_header", "body", "button", "style", "complete", "accordion", "icon", "features"];
            this.$nextTick(() => {
                if (this.mock) {
                    let parsedObject = JSON.parse(this.mock);
                    parsedObject = this.reorderObjectKeys(parsedObject, topLevelOrder);
                    if (parsedObject.thinking) parsedObject.thinking = this.reorderObjectKeys(parsedObject.thinking, thinkingOrder);
                    if (parsedObject.sections) parsedObject.sections = parsedObject.sections.map(section => this.reorderObjectKeys(section, sectionOrder));
                    this.mock = JSON.stringify(parsedObject, null, 2);
                }
            });
        },
        reorderObjectKeys(obj, keyOrder) {
            const ordered = {};
            // Add keys in the specified order
            keyOrder.forEach(key => {
                if (obj.hasOwnProperty(key)) ordered[key] = obj[key];
            });
            // Add remaining keys not specified in the order
            for (const key in obj) {
                if (obj.hasOwnProperty(key) && !keyOrder.includes(key)) ordered[key] = obj[key];
            }
            return ordered;
        },
        async setStyleGuide(styleGuide) {
            // await this.removeStyles();
            // if (styleGuide?.fonts?.length > 0) styleGuide = this.cdnLinkFixer(styleGuide);
            // this.$store.dispatch("styleGuide/setStyleGuide", styleGuide);
            // this.$nextTick(() => this.applyStyles());
        },
        mockStream() {
            let mock = validateJSON(this.mock);
            this.mock = JSON.stringify(mock);
            let currentStream = "";
            this.streaming = true;
            if (!this.streamInterval) {
                this.streamInterval = setInterval(() => {
                    console.log(this.currentIndex, this.mock.length);
                    if (this.currentIndex < this.mock.length) {
                        currentStream += this.mock.substring(this.currentIndex, this.currentIndex + this.chunkSize);
                        this.currentIndex += this.chunkSize;
                        let update = validateJSON(currentStream) || this.currentStream;
                        this.currentStream = update;
                        // this.$emit("simulate-stream", this.currentStream);
                        this.text = update;
                        console.log(update);
                    } else {
                        clearInterval(this.streamInterval);
                        this.streaming = false;
                    }
                }, this.speed); // Adjust the interval as needed
            }
        },
        startStreaming() {
            clearInterval(this.streamInterval);
            this.streamInterval = null;
            this.currentStream = "";
            this.currentIndex = 0;
            this.mockStream();
        },
    },
    beforeUnmount() {
        clearInterval(this.streamInterval);
    },
};
</script>
<style lang="scss">
.cursor-blink {
    width: 2px;
    height: 1rem;
    background: black;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>
