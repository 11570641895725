<template>
    <div class="relative cursor-pointer mt-3" v-if="item" :key="item?.id">
        <div class="br-10 border-light hover:shadow" v-if="isCampaign" @click.prevent="itemClicked">
            <div class="br-10 aspect-landscape f aic x block relative overflow-clip flex-shrink-0">
                <div class="p-3 mt-auto">
                    <img class="absolute p-fill o-cover x y z-0" v-if="imageURL" :src="imageURL" />
                    <div class="z-1 absolute p-fill" :style="`background-image: linear-gradient(to left, transparent 30%, ${itemImage?.backgroundColor} 80%)!important;`"></div>
                    <div class="relative z-1" :style="`color:${textColor}`">
                        <CampaignItemDetails size="lg" :item="item" />
                    </div>
                    <!-- <div class="text-left x"><ItemCounts v-if="itemCounts" class="ml-0 x text-left block !px-0 !mx-0" :item-counts="itemCounts" /></div>-->
                </div>
            </div>
            <!--            {{ itemImage }}-->
        </div>
        <div v-else class="br-10 border-light overflow-hidden hover:shadow-lg transition-all relative" :style="cardSize" @click.prevent="itemClicked">
            <div class="bg-gray-100 o-cover block" :style="cardSize">
                <template v-if="itemImage">
                    <img class="x y o-contain p-3 py-3 px-5" :style="`background:${itemImage.color}`" v-if="itemImage.transparent" :src="imageURL" />
                    <img class="x y o-cover" v-else :src="imageURL" />
                </template>
            </div>
        </div>
        <template v-if="related?.length > 0">
            <collapsable-menu-item class="pl-2 f-13 mt-1">
                <template #title>
                    <div class="f-13 x f aic jcs pr-5">
                        <span class="text-gray-400">Included Campaigns</span>
                        <div class="c-15 ml-1 o-5 fwb f-11 bg-snow border-light f aic jcc">{{ related.length }}</div>
                    </div>
                </template>
                <template v-for="c in related">
                    <div class="p-1 border-light br-5 f-13 mb-1" v-if="c?.result?.subject_line">
                        <div class="d-block h6 mb-0 f-11" v-if="c?.name">{{ c.name }}</div>
                        <div class="whitespace-pre-line line-clamp-1" v-if="c?.result?.subject_line">{{ c.result.subject_line }}</div>
                        <!--                    {{ c.id }}-->
                        <!--                    <div class="d-block whitespace-pre-line" v-if="c.result.campaignIndex">Campaign Index: {{ c.result.campaignIndex }}</div>-->
                        <!--                    <div class="d-block whitespace-pre-line">Item index: {{ c.result.itemIndex }}</div>-->
                    </div>
                </template>
            </collapsable-menu-item>
        </template>
        <!--        <template v-if="campaigns">{{ campaigns }}</template>-->
        <div class="f ais jcb mb-2" v-if="!isCampaign" :style="`max-width:${cardSize.width}`">
            <div @click.prevent="itemClicked">
                <div>
                    <CampaignItemDetails :key="item?.id" :item="item" />
                </div>
            </div>
            <div class="pt-2">
                <div class="c-30 f aic jcc border-light"><i class="fas fa-ellipsis-h"></i></div>
            </div>
        </div>
        <template v-if="parent?.result?.name">
            <ParentCampaign :parent="parent" />
        </template>
        <div class="p-2 top right absolute" @click.prevent="deleteItem">
            <div class="c-30 bg-snow f aic jcc border-light"><i class="fas fa-times"></i></div>
        </div>
    </div>
</template>
<script>
import CampaignItemDetails from "@/components/Admin/CampaignItemDetails.vue";
import ItemCounts from "@/components/generative_templates/Campaigns/ItemCounts.vue";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import ParentCampaign from "@/components/Admin/ParentCampaign.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import chroma from "chroma-js";
// import { updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject.js";
// updateFirebaseObject("campaigns",{childCampaigns:})
export default {
    name: "CampaignCard",
    components: { CollapsableMenuItem, ParentCampaign, ItemCounts, CampaignItemDetails },
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        images: {
            type: Array,
            default: () => [],
            // required: true,
        },
    },
    data() {
        return {
            related: null,
            parent: null,
        };
    },
    computed: {
        textColor() {
            // show light or dark text based on the image color
            // if the image is light, show dark text
            // if the image is dark, show light text
            let bgColor = this.itemImage?.backgroundColor || "#ffffff";
            let color = chroma(bgColor);
            let textColor = color.luminance() > 0.5 ? "black" : "white";
            return textColor;
        },
        parentCampaign() {
            // find campaign from $store.state.stream.stream.campaigns where campaign.result.campaignID = this.item.result.campaignID
            // return this.campaigns?.find(i => i.id === this.campaignId) || null;
        },
        campaignId() {
            return this.item?.result?.campaignId || this.item?.campaignId;
        },
        campaigns() {
            if (this.isCampaign) {
                return this.$store.state?.stream?.stream?.campaigns;
            }
            return null;
        },

        cardSizes() {
            return {
                email: {
                    width: "200px",
                    height: "300px",
                    maxWidth: "200px",
                },
                page: {
                    width: "350px",
                    height: "200px",
                },
                campaign: {
                    width: "300px",
                    height: "200px",
                },
                default: {
                    width: "300px",
                    height: "300px",
                },
            };
        },
        itemCounts() {
            return this.item?.result?.item_counts;
        },
        cardSize() {
            if (this.isEmail) return this.cardSizes.email;
            if (this.isLandingPage) return this.cardSizes.page;
            if (this.isCampaign) return this.cardSizes.campaign;
            return this.cardSizes.default;
        },
        type() {
            return this.item?.result?.object_type?.replace("_", " ");
        },
        imageArray() {
            let indexes = this.item?.result?.relevant_image_indexes || false;
            if (!indexes.length) return false;
            if (indexes) {
                let images = [];
                indexes.forEach(i => {
                    images.push(this.images[i]);
                });
                return images;
            } else {
                return [];
            }
        },
        imageURL() {
            try {
                return this.itemImage?.url?.replace("Original", "public");
            } catch (e) {
                return this.itemImage?.url;
            }
        },
        itemImage() {
            if (this.images) {
                if (this.imageArray?.length > 0) {
                    return this.imageArray?.[0];
                }
                if (this.item?.result?.sections?.[0]?.imageIndex) {
                    let index = this.item.result?.sections[0].imageIndex;
                    return this.images[index];
                } else {
                    // return this.images[0];
                    return false;
                }
            }
        },
        isEmail() {
            return this.item?.object_type === "email";
        },
        isCampaign() {
            return this.item?.object_type === "campaign";
        },
        isLandingPage() {
            return this.item?.object_type === "page";
        },
    },
    async mounted() {
        this.$nextTick(async () => {
            this.related = await this.relatedCampaigns();
            this.parent = await this.getParentCampaign();
        });
    },
    methods: {
        async getParentCampaign() {
            if (this.campaignId) {
                let parent = await fetchData("campaigns", this.campaignId);
                return parent;
            }

            return null;
        },
        async relatedCampaigns() {
            // find campaigns from $store.state.stream.stream.campaigns where campaign.result.campaignID = this.item.id
            try {
                if (this.isCampaign) {
                    if (this.item.childCampaigns) {
                        let childCampaigns = this.item.childCampaigns;
                        let relatedCampaigns = [];
                        for (let i = 0; i < childCampaigns.length; i++) {
                            let campaign = await fetchData("campaigns", childCampaigns[i]);
                            relatedCampaigns.push(campaign);
                        }
                        return relatedCampaigns;
                    }
                }
                let relatedCampaigns = this.campaigns?.filter(i => i.result.campaignId === this.item.id || (i.campaignId && i.campaignId === this.item?.result?.id)) || [];
                if (relatedCampaigns?.length > 0) {
                    let relatedCampaignIds = relatedCampaigns.map(i => i.id);
                    let currentCampaignId = this.item.id;
                    console.log(`Related Campaigns for ${currentCampaignId} `, relatedCampaignIds);
                    // if (parentId) await updateFirebaseArrayProperty("campaigns", parentId, "childCampaigns", matchedObject.id);
                    // await updateFirebaseObject("campaigns", { childCampaigns: relatedCampaignIds }, currentCampaignId);
                    // await updateFirebaseArrayProperty("campaigns", currentCampaignId, "childCampaigns", currentCampaignId);
                }
                return relatedCampaigns;
            } catch (e) {
                console.log(e);
                return null;
            }
        },
        fixItem() {},
        itemClicked() {
            this.$emit("itemClicked", this.item);
        },
        async deleteItem() {
            this.$emit("deleteItem", this.item);
        },
    },
};
</script>
