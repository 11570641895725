let sites_to_include = [
    "thingtesting.com",
    "uncrate.com",
    "gearpatrol.com",
    "gearhungry.com",
    "thedieline.com",
    "abcnews.go.com",
    "acquiremag.com",
    "apnews.com",
    "arstechnica.com",
    "axios.com",
    "bbc.com",
    "blessthisstuff.com",
    "bloomberg.com",
    "businessinsider.com",
    "cbsnews.com",
    "cnn.com",
    "coolmaterial.com",
    "crunchbase.com",
    "digitaltrends.com",
    "economist.com",
    "engadget.com",
    "forbes.com",
    "foxnews.com",
    "gearhungry.com",
    "gearpatrol.com",
    "gizmodo.com",
    "huffingtonpost.com",
    "latimes.com",
    "lifehacker.com",
    "mashable.com",
    "nbcnews.com",
    "newswire.com",
    "newsweek.com",
    "npr.org",
    "nytimes.com",
    "politico.com",
    "prnewswire.com",
    "prweb.com",
    "producthunt.com",
    "recode.net",
    "reuters.com",
    "slate.com",
    "spy.com",
    "techcrunch.com",
    "techradar.com",
    "theatlantic.com",
    "thehill.com",
    "thenextweb.com",
    "theguardian.com",
    "time.com",
    "theverge.com",
    "thingtesting.com",
    "usatoday.com",
    "venturebeat.com",
    "vox.com",
    "wikipedia.org",
    "wired.com",
    "wsj.com",
    "allure.com",
    "bustle.com",
    "cosmopolitan.com",
    "elle.com",
    "glamour.com",
    "harperbazaar.com",
    "marieclaire.com",
    "refinery29.com",
    "seventeen.com",
    "teenvogue.com",
    "vogue.com",
];
export default sites_to_include;
