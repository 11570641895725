<template>
    <li :key="campaign.id" class="flex items-center justify-between gap-x-6 py-3 hover:bg-gray-50 height-min-100">
        <div class="f aic jcc gap-3" @click.prevent="$emit('clicked')">
            <div class="width-max-500 border-light br-5">
                <img :src="imageURL" class="width-max-75" />
            </div>
            <div class="min-w-0">
                <!--            <pre>{{ project }}</pre>-->
                <div class="flex items-start gap-x-3 mb-1">
                    <p class="text-lg font-semibold leading-6 text-gray-900 group-hover:text-green-700">{{ name }}</p>
                    <p :class="[statuses[status], 'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset']">
                        {{ status }}
                    </p>
                    <p :class="[statuses[kind], 'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset']">
                        {{ kind }}
                    </p>
                    <!--                <dynamic-object-viewer :data-object="campaignData" />-->
                </div>
                <div class="f x gap-3 aic">
                    <div v-if="campaignItems?.length" class="f gap-3 text-xs o-5">
                        <div v-for="item in ['email', 'page']" class="f gap-1">
                            <campaign-icon :kind="item" />
                            <div>{{ itemCounts(item) }}</div>
                        </div>
                    </div>
                    <div v-if="campaignItems.length > 0" class="f fc gap-1 visually-hidden">
                        <div v-for="item in campaignItems" :key="item">
                            <div class="f gap-3 text-xs o-5">
                                <CampaignIcon :kind="item?.object_type" class="o-5" />
                                <p v-if="item?.name" class="mb-0">{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <template v-if="user.displayName">
                            <span>•</span>
                            <p class="truncate">Created by {{ user.displayName }}</p>
                        </template>
                        <div class="whitespace-nowrap f gap-2 aic">
                            <span>
                                Created on
                                <time-stamp :date="campaign.created" />
                            </span>
                            <template v-if="campaign.updated">
                                <span>•</span>
                                <span>
                                    Updated on
                                    <time-stamp :date="campaign.updated" />
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
            <a :href="campaign.href" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block" @click.prevent="$emit('clicked')">
                View campaign
                <span class="sr-only">, {{ campaign.name }}</span>
            </a>
            <BaseButton icon="fal fa-trash " icon-only size="xxs" style-type="secondary" @click.prevent="$emit('delete-item', campaign)"></BaseButton>
            <div class="relative flex-none">
                <button class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900" @click="toggleMenu">
                    <span class="sr-only">Open options</span>
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                    </svg>
                </button>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <div v-if="isMenuOpen" class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <a class="block px-3 py-1 text-sm leading-6 text-gray-900" href="#">
                            Edit
                            <span class="sr-only">, {{ campaign.name }}</span>
                        </a>
                        <a class="block px-3 py-1 text-sm leading-6 text-gray-900" href="#">
                            Move
                            <span class="sr-only">, {{ campaign.name }}</span>
                        </a>
                        <a class="block px-3 py-1 text-sm leading-6 text-gray-900" href="#" @click.prevent="$emit('delete-item', campaign)">
                            Delete
                            <span class="sr-only">, {{ campaign.name }}</span>
                        </a>
                    </div>
                </transition>
            </div>
        </div>
    </li>
</template>
<script>
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import { mapGetters } from "vuex";
import CampaignIcon from "@/components/Admin/CampaignIcon.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ItemCountIcon from "@/views/ItemCountIcon.vue";

export default {
    name: "CampaignListItem",
    components: { ItemCountIcon, BaseButton, CampaignIcon, TimeStamp },
    emits: ["clicked", "delete-item"],
    data() {
        return {
            isMenuOpen: false,
            statuses: {
                Complete: "text-green-700 bg-green-50 ring-green-600/20", //
                campaign: "text-green-700 bg-green-50 ring-green-600/20",
                Email: "text-green-700 bg-green-50 ring-green-600/20",
                "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
                Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
                Draft: "text-gray-600 bg-gray-50 ring-gray-500/10",
            },
        };
    },
    computed: {
        ...mapGetters("styleGuide/image", ["images"]),
        name() {
            return this.campaign?.result?.name || "";
        },
        campaignData() {
            let { messages, ...rest } = this.campaign?.result || {};
            return this.campaign;
            return rest;
        },
        kind() {
            return this.campaign?.result?.object_type || "";
        },
        created() {
            // format: { "nanoseconds": 199000000, "seconds": 1710877574 } needs to be converted to proper time
        },
        status() {
            return this.campaign?.result?.status || "draft";
        },
        emailCount() {
            let emails = this.campaignItems.filter(i => i?.object_type === "email");
            return emails.length || 0;
        },
        imageArray() {
            let indexes = this.campaign?.result?.relevant_image_indexes || false;
            if (!indexes.length) return false;
            if (indexes) {
                let images = [];
                indexes.forEach(i => {
                    images.push(this.images[i]);
                });
                return images;
            } else {
                return [];
            }
        },
        imageURL() {
            try {
                return this.itemImage?.url?.replace("Original", "public");
            } catch (e) {
                return this.itemImage?.url;
            }
        },
        campaignItems() {
            // Assuming this.result.schedule_items is an array of objects,
            // each having a campaign_items array.
            let schedule = this.campaign?.result?.schedule_items || [];
            let campaignItems = schedule.flatMap(i => i.campaign_items);
            return campaignItems;
        },
        itemImage() {
            if (this.images) {
                if (this.imageArray?.length > 0) {
                    return this.imageArray?.[0];
                }
                if (this.campaign?.result?.sections?.[0]?.imageIndex) {
                    let index = this.item.result?.sections[0].imageIndex;
                    return this.images[index];
                } else {
                    // return this.images[0];
                    return false;
                }
            }
        },
    },
    methods: {
        itemCounts(kind) {
            // normalize kind to lowercase
            kind = kind.toLowerCase();
            if (kind === "email") kind = "email";
            if (kind === "message") kind = "message";
            if (kind === "landing page") kind = "page";
            if (kind === "schedule") kind = "schedule";
            if (kind === "campaign") kind = "campaign";
            if (kind === "sms") kind = "sms";
            let items = this.campaignItems.filter(i => i?.object_type === kind);
            return items.length || 0;
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
    },
    props: {
        campaign: {},
    },
};
</script>
