<template>
    <span v-if="items">
        <i :class="`fas fa-${icon}`" />
        {{ itemCount }}
    </span>
</template>
<script>
export default {
    name: "ItemCountIcon",
    props: {
        items: {
            type: [Array, Object, String, Number],
            default: null,
        },
        icon: {
            type: String,
            default: "fas fa-images",
        },
    },
    computed: {
        itemCount() {
            if (!this.items) return 0;
            if (typeof this.items === "string" || typeof this.items === "number") {
                return this.items;
            } else if (Array.isArray(this.items)) {
                return this.items.length;
            } else if (typeof this.items === "object") {
                return Object.keys(this.items).length;
            }
            return 0;
        },
    },
};
</script>
