// getCopyGuideMixin.js
import streamCompletion from "@/mixins/ai/stream_completion";

export default {
    data() {
        return {};
    },
    computed: {
        simpleSections() {
            let sections = this.sections.map(section => {
                let obj = {
                    header: section.header || "",
                    body: section.body || "",
                    button: section.button,
                    imageIndex: section.imageIndex || 0,
                    imageInset: section.imageInset || false,
                    features: section.features || null,
                    sub_header: section.sub_header || null,
                    pre_header: section.pre_header || null,
                };
                // if (section.preheader) obj.preheader = section.preheader;
                // if (section.sub_header) obj.sub_header = section.sub_header;
                return obj;
            });
            return sections;
        },
        simpleSectionsString() {
            return this.sections
                .map((section, index) => {
                    let header = section.header || "";
                    let body = section.body || "";
                    let button = section.button || "";
                    let type = section.type || "";
                    let sub_header = section.sub_header || "";
                    let pre_header = section.pre_header || "";

                    let features = section.features || "";
                    if (section.products) type = "products";
                    if (features) {
                        type = "features";
                        features = features
                            .map(feature => {
                                let icon = feature.icon;
                                if (feature.icon) icon = JSON.stringify(icon);
                                let title = feature.title || "";
                                let description = feature.description || "";
                                return ` - ${icon} ${title} - ${description}\n`;
                            })
                            .join("\n");
                    }
                    if (type) type = `SECTION ${index} – ${type.toUpperCase()} SECTION`;
                    if (pre_header) pre_header = `${pre_header} // Pre-header text`;
                    if (header) header = `${header} // Header text`;
                    if (sub_header) sub_header = `${sub_header} // Subheader text`;
                    if (body) body = `${body} // Body text`;
                    if (button?.text) button = `${button.text} // Button text`;
                    if (features) features = `Features:\n${features}`;
                    let parts = [type, pre_header, header, sub_header, body, button, features].filter(Boolean);
                    return parts.join("\n");
                })
                .join("\n--------\n\n");
        },
    },
    methods: {
        simpleSectionString(index, sections) {
            try {
                if (!sections) sections = this.sections;
            } catch (e) {
                console.log(e);
            }
            let section = sections[index];
            let header = section.header || "";
            let body = section.body || "";
            let button = section.button || "";
            let type = section.type || "";
            let sub_header = section.sub_header || "";
            let pre_header = section.pre_header || "";

            let features = section.features || "";
            if (section.products) type = "products";
            if (features) {
                type = "features";
                features = features
                    .map(feature => {
                        let icon = feature.icon;
                        if (feature.icon) icon = JSON.stringify(icon);
                        let title = feature.title || "";
                        let description = feature.description || "";
                        return ` - ${icon} ${title} - ${description}\n`;
                    })
                    .join("\n");
            }
            if (type) type = `SECTION ${index} – ${type.toUpperCase()} SECTION`;
            if (pre_header) pre_header = `${pre_header} // Pre-header text`;
            if (header) header = `${header} // Header text`;
            if (sub_header) sub_header = `${sub_header} // Subheader text`;
            if (body) body = `${body} // Body text`;
            if (button?.text) button = `${button.text} // Button text`;
            if (features) features = `Features:\n${features}`;
            let parts = [type, pre_header, header, sub_header, body, button, features].filter(Boolean);
            return parts.join("\n");
        },
        addSection(obj) {
            this.$store.dispatch("stream/addSection", { id: this.id, index: this.index });
        },
        async addSectionWithAI(input = "make it great") {
            const currentIndex = this.index;
            const newSectionIndex = currentIndex + 1;
            const identifier = this.identifier;
            const id = this.id;
            const nextIdentifier = identifier.replace(`sections.${currentIndex}`, `sections.${newSectionIndex}`);
            // let sections = this.$toRaw(this.sections);
            let simpleSections = this.simpleSections;
            this.setActionEmailSection();
            // this.setActionEmail();
            // remove the image data from the sections

            // let newSection = { header: "New Section", body: JSON.stringify(simpleSections), imageIndex: 4, button: { show: true, text: "Shop now" } };
            this.addSection();
            let sectionString = "Current content:\n\n";
            let simpleSectionString = simpleSections
                .map((section, index) => {
                    let header = section.header || "";
                    let body = section.body || "";
                    let button = section.button || "";
                    let type = section.type || "";
                    let sub_header = section.sub_header || "";
                    let pre_header = section.pre_header || "";

                    let features = section.features || "";
                    if (section.products) type = "products";
                    if (features) {
                        type = "features";
                        features = features
                            .map(feature => {
                                return `${feature.icon} ${feature.title} - ${feature.description}`;
                            })
                            .join("\n");
                    }
                    let string = `Section ${index + 1}:\nType: ${type}\nPreheader: ${pre_header}\nHeader: ${header}\nSubheader: ${sub_header}\nBody: ${body}\nButton: ${button.text}\nFeatures: ${features}\n`;
                    if (currentIndex === index) string += `// SECTION ${index + 2}:\n[THE NEW SECTION GOES HERE]\n`;
                    return string;
                })
                .join("\n");
            let messages = [...this.streamMessages, userMessage(sectionString + simpleSectionString), userMessage(input)];
            this.showPrompt = false;
            await streamCompletion(
                this.$store, // pass the store
                "Make a related products section.", // pass the input, this could be a string or an array
                messages, // pass the messages array to add messages to the stream
                undefined, // pass the system message optionally, but this could also be contained in the messages array.
                () => {},
                (token, function_objects, json) => {
                    let section = json?.section || {};
                    this.updateStreamProp(`editor.${id}.result.sections.${newSectionIndex}`, section, true);
                },
                () => {
                    // this.afterCompletingGeneration(obj); // this function lives in this mixin and is used to reset the props for loader, result and done variables within the generationMixin
                    // this.afterCompletion({ ...obj, result: finalResult }); // pass the afterCompletion function to call anything needed after completing the generation. This will be in the file for the specific function. It handles things like saving the result, triggering follow-on functions, etc.
                    this.$store.dispatch("stream/saveCampaignItem", { id: this.id });
                },
                gpt3, // pass the model to use for the generation (this is set in the file calling this function)
                true, // pass the silent boolean to determine if every step should be logged to the console or not.
                "new section", // set the log name to the function call + input, this is for console logging.
                0, // pass the temperature to use for the generation (this is set in the file calling this function).
                undefined, // pass the functions to use for the generation (this is set in the file calling this function).
                "create_email_section", // pass the function call to use for the generation (this is set in the file calling this function).
                3000, // pass the length to use for the generation (this is set in the file calling this function).
            );

            // await this.generateNewSection(`Update section ${newSectionIndex}. Use the following feedback:\n${input || "Make it great"}`, undefined, {
            //     // id: this.id
            //     identifier: nextIdentifier,
            // });

            // this.updateStreamProp(`editor.${this.id}.result.sections.${newSectionIndex}`, newSection, true);
        },
        // async getCopyGuidOld() {const payload = this.plainTextTextSamples; await this.createStyleGuide(this.styleGuide.url, this.$route.params.id, { sections: ["copyGuidelines"], payload: payload, stream: true }, (token, func, args) => {if (typeof args === "object") {this.updateStyleGuideProp("copyGuidelines", args, true);}}, () => {console.log("Done streaming guide");this.$store.dispatch("styleGuide/saveStyleGuide", this.styleGuide)})},
        beforeStarting() {
            // if (!this.styleGuide.ai) this.updateStyleGuideProp("ai", {}, true);
            console.log("This is the beginning of the stream");
        },
        whileProcessing(json) {
            console.log("While processing");
            // console.log(json);
            // this.updateStreamProp(json.identifier, json);
        },
        afterCompletion(obj) {
            console.log("After completion called", obj);
            let { save, result } = obj;
            if (save) this.updateStreamProp(obj.returnTo, result);
            // if (save) this.$store.dispatch("styleGuide/saveStyleGuide", this.styleGuide); // the generativeMixin will call this after the stream is complete.
        },
        async generateNewSection(input, overrides, options) {
            let { identifier } = options;
            if (overrides?.action) this.setAction(overrides.action);
            // return;
            let { name, type } = this.selectedAction; // get the name and type of the selected action. The prompt will be automatically added via the streamFunction(obj) method. // set the action to get_brand_voice. This sets the prompt and the function_call as well.
            let mods = {
                model: this.model || gpt316, // this is the model that will be used to generate this.
                messages: this.streamMessages, // this is the messages that will be used to generate this.
                temperature: 0, // this is the temperature that will be used to generate this.
                function_call: name, // this is the function_call name that will be called to generate this. It needs to match the fucntion being called either locally or on the server.
                functions: undefined, // this is the functions object that will be used to generate this. If blank it will use the server and name to route to the right function if it exists.
                length: 4000, // the length of the message to generate
                identifier: identifier, // this helps route the stream to the right place and find it later. Good for complex objects and nested sections. For example: 'campaignData.result.schedule_items.${campaignIndex}.campaign_items.${itemIndex}.result'. It is also possible to set a custom identifier for the stream location.
                // identifier: `editor.${id}.result.sections.${index}`, // this helps route the stream to the right place and find it later. Good for complex objects and nested sections. For example: 'campaignData.result.schedule_items.${campaignIndex}.campaign_items.${itemIndex}.result'. It is also possible to set a custom identifier for the stream location.
                brand: this.$route.params.id, // set the brand id from the route params but this could be set from anywhere optionally.
                // prop: "editor",
                input: input, // this is the user input that will added to messages.
                type: type, // what type of campaign is this? Currently only used for campaigns.
                save: false, // if true, use the afterCompletion function to define how to save the results.
                returnTo: identifier,
                // returnTo: `editor.${id}.result.sections.${index}`,
                persistChat: true, //  if true, it streams the result to the messages array in the stream store. This can be used to see the streaming results in chat, but it can also be used to save the results to the database, "chat" add revisions with context, etc.

                section: true,
            };
            if (overrides) mods = { ...mods, ...overrides };
            logGroup("callFunctionFromComponent", mods);
            // return;
            await this.callFunction(mods);
        },
    },
};
