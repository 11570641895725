<template>
    <!--    <div class="c-25 f aic jcc bg-gray-100"><i class="far fa-times o-5 f-20"></i></div>-->
    <div class="remove_button c-15 br-5 border-light aic jcc ml-2 cursor-pointer bg-gray-100 hover:bg-base-600 hover:bg-snow dark:bg-base-900/20">
        <i class="far fa-times o-5 g f-15 dark:text-base-100"></i>
    </div>
</template>
<script>
export default {
    name: "ButtonRemoveListItem",
};
</script>
<style lang="scss">
.inline_task_item {
    @apply hover:text-blue-700;
    @apply text-gray-900 dark:text-base-100;
    .remove_button {
        display: none;
    }

    &:hover {
        cursor: pointer;
        @apply text-gray-900;
        .remove_button {
            display: flex;
        }
    }
}
</style>
