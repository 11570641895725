<template>
  <div class="cloned-item" :style="itemStyle">
    <div class="item-content">
      <span>{{ summary }}</span>
      <!-- Insert additional content here -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    summary: {
      type: String,
      required: true
    },
    itemStyle: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.cloned-item {
  max-width: 250px;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.5rem !important;
  line-height: normal !important;
  position: fixed;
  z-index: 100;
  transition: transform 0.7s, left 0.7s, top 0.7s;
}

.item-content {
  /* Add any custom styles for the content of the cloned item */
}
</style>