<template>
    <tr v-if="font" @click="isOpen = !isOpen" :class="{ 'bg-gray-200': isOpen }">
        <slot name="title"></slot>
        <Transition name="slide-right">
            <div v-if="isOpen" class="x p-fixed top right bottom mw-500 x bg-snow p-5 scrollSection z-10 isolate mt-nav pt-5 pb-8 mb-8 overflow-x-hidden shadow-xl" style="z-index: 10900">
                <!--                <div class="absolute"><slot></slot></div>-->
                <!--                <DynamicObjectViewer :data-object="fontSortedKeys" class="w-100 h-100"></DynamicObjectViewer>-->
            </div>
        </Transition>
    </tr>
    <div v-if="!font">
        <div class="relative" :class="{ 'bg-snow': isOpen, 'br-10 py-1': rounded }" @click="isOpen = !isOpen">
            <slot name="title"></slot>
            <span class="absolute right align-fixed-center p-3 o-3">
                <i v-if="isOpen" class="fas fa-angle-down mb-0"></i>
                <i v-else class="fas fa-angle-right mb-0"></i>
            </span>
        </div>
        <Transition name="fade-up">
            <div v-show="isOpen" class="my-2 text-gray-700" :class="{ 'rounded shadow': !inline, 'br-20 overflow-hidden': rounded, 'mb-4': bottomClose }" :style="maxHeightStyle">
                <slot></slot>
            </div>
        </Transition>
        <div v-if="isOpen && bottomClose" class="z-5 pb-2 justify-fixed-center absolute left right text-center bottom x" @click="isOpen = !isOpen">
            <div class="z-1 p-3 bottom c-30 f aic jcc bg-snow border-light mx-auto">
                <i v-if="isOpen" class="fas fa-times mb-0"></i>
            </div>
        </div>
    </div>
</template>

<script>
// import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";

export default {
    // components: { DynamicObjectViewer },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        font: {
            type: [Boolean, Object],
            default: false,
        },
        bottomClose: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: [String, Number],
            default: "",
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        fontSortedKeys() {
            let keyOrder = ["name", "fontFamily", "fontWeight", "weight", "style", "fontDisplay", "baseUrl", "css", "oldCSS", "oldCss", "display", "src", "usageCount", "srcArray", "oldSRCMap", "originalURL", "url", "fileName", "fontStyle", "from", "kind", "format", "reference"];

            let orderedKeys = keyOrder.filter(key => Object.keys(this.font).includes(key));
            let otherKeys = Object.keys(this.font).filter(key => !keyOrder.includes(key));
            let sortedKeys = [...orderedKeys, ...otherKeys];
            let sortedObject = sortedKeys.reduce((acc, key) => {
                acc[key] = this.font[key];
                return acc;
            }, {});

            return sortedObject;
        },

        maxHeightStyle() {
            if (this.maxHeight) return `max-height: ${this.maxHeight}px;overflow-y:scroll;`;
            return "";
        },
    },
    mounted() {
        if (this.open) {
            this.isOpen = true;
        }
    },
};
</script>

<style scoped>
.collapse-enter-active,
.collapse-leave-active {
    transition: all 0.3s ease;
}
.collapse-enter-from,
.collapse-leave-to {
    height: 0;
    opacity: 0;
}
.collapse-enter-to,
.collapse-leave-from {
    height: auto;
    opacity: 1;
}
</style>
