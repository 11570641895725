<template>
    <div class="p-3 brand-bg">
        <div class="container x p-0">
            <h2>Klaviyo Templates</h2>
            <div class="grid grid-cols-12 gap-8 my-5">
                <div v-for="template in templates" class="col-span-6 overflow-hidden" :key="template">
                    <div v-if="template?.attributes?.html" class="x vh-40" v-html="template.attributes.html"></div>
                    <ChatMarkdown class="whitespace-pre-wrap" :content="`\`\`\`html\n${template.attributes.html}\n\`\`\``" />
                    <h3 v-if="template?.type">{{ template.type }}</h3>
                    <h3 v-if="template?.attributes?.name">{{ template.attributes.name }}</h3>
                    <p v-if="template?.id">ID: {{ template.id }}</p>
                    <p v-if="template?.attributes?.created">Created: {{ template.attributes.created }}</p>
                    <p v-if="template?.attributes?.updated">Updated: {{ template.attributes.updated }}</p>
                    <!--                    {{ template }}-->
                    <ObjectViewer :data="template" />
                </div>
            </div>
            <SimpleButton @click="getTemplates">Get Templates</SimpleButton>
        </div>
    </div>
</template>

<script>
import styleGuideBrandMixin from "@/mixins/StyleGuideMixins/StyleGuideBrandMixin";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import SimpleButton from "@/views/SimpleButton.vue";
import { mapGetters } from "vuex";
import webRequest from "@/mixins/ai/web_request";
import ObjectViewer from "@/components/Admin/ObjectViewer.vue";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";

export default {
    components: { ChatMarkdown, ObjectViewer, SimpleButton },
    mixins: [StyleGuideMixin, styleGuideBrandMixin],
    data() {
        return {
            klaviyoAPI: "",
        };
    },
    computed: {
        ...mapGetters("styleGuide", ["styleGuide"]),
        ...mapGetters("user", ["user"]),
        templates() {
            return this.$store?.state?.stream?.stream.templates || [];
        },
    },
    watch: {
        styleGuide(val) {
            if (val) {
                this.klaviyoAPI = val?.klaviyoAPI;
            }
        },
    },
    async mounted() {
        this.$nextTick(() => {});
    },
    methods: {
        async getTemplates() {
            try {
                let user = this.user;
                let styleGuide = this.styleGuide;
                let obj = { brand: styleGuide.id, user: user };
                let response = await webRequest("email/getTemplates", obj);
                response = await response.json();
                let { templates } = response;
                if (templates) {
                    this.updateStreamProp("templates", templates);
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>
