<template>
    <div class="height-400 width-500 width-min-450 mt-3 x f aic jcc text-center bg-gray-900/10 br-10 mb-4">
        <loading-spinner :color="section.color"></loading-spinner>
    </div>
</template>
<script>
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";

export default {
    name: "EmailImageLoader",
    components: { LoadingSpinner },
    props: {
        section: {},
    },
};
</script>
