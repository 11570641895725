<template>
    <Transition name="fade-up">
        <div v-if="isHovered" class="z-5 f aic jcc relative">
            <div class="z-5 absolute bottom justify-fixed-center py-3 gap-3 f aic jcc text-gray-900">
                <TransitionGroup :css="false" appear class="f aic jcc gap-3" name="" tag="div" @enter="onEnter" @leave="onLeave" @before-enter="onBeforeEnter">
                    <!--            <TransitionGroup name="fade-up" tag="div" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave" appear>-->
                    <!--                <div v-for="(f, index) in functions.filter(f => !f.hide)" :key="f.name" :class="getActionClass(f)" :data-index="index" class="m-0 cursor-pointer border p-1 px-2 border-light flex-shrink-0 rounded-full f aic jcc gap-3" @click="setAction(f)" @mouseout="onMouseOut" @mouseover="onMouseOver"></div>-->

                    <BaseButton v-for="(button, index) in buttons.filter(b => b.condition)" :key="button.id" :class="button.class" :data-index="index" :icon="button.iconClass" :tooltip="button.tooltipText" class="shadow" icon-only @click.prevent="button.clickHandler">
                        <div v-if="button.id === 6" :class="{ 'gradient-line': hideGradient }" class="c-20 gradientBottom border border-2 border-gray-900 transition-all"></div>
                        <div v-if="button.id === 8">GIF</div>
                    </BaseButton>
                </TransitionGroup>

                <!--                <div class="fixed top left right p-3 bg-snow z-10" v-if="section.relevant_image_indexes">{{ section.relevant_image_indexes }}</div>-->
            </div>
        </div>
    </Transition>
</template>

<script>
import { gsap } from "gsap";
import { mapGetters } from "vuex";

import BaseButton from "@/components/CoreUI/BaseButton.vue";
import webRequest from "@/mixins/ai/web_request";
import SectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";

export default {
    name: "ImageTools",
    components: { BaseButton },
    mixins: [SectionStyleMixin],
    props: {
        section: { type: Object, required: true, default: () => ({}) },
        kind: { type: String, required: true, default: "email" },
        image: { type: Object, required: true, default: () => ({}) },
        isHovered: { type: Boolean },
        // relevantImages: { type: Array, default: () => [] },
        index: { type: Number, required: true, default: 0 },
    },
    computed: {
        ...mapGetters("styleGuide", ["fonts"]),
        ...mapGetters("styleGuide/image", ["logos", "images"]),
        swapSidesIconClass() {
            if (this.fullBleed) return "far opacity-100 text-gray-900 fa-solid fa-objects-align-center-horizontal";
            return `fa-solid fa-swap opacity-100 text-gray-900`;
        },
        fullBleedIconClass() {
            return this.isTucked ? "fas fa-arrow-down opacity-100 text-gray-900" : "fas fa-arrow-up opacity-100 text-gray-900";
        },
        sectionOrderClasses() {
            return this.fullBleed ? "fas fa-compress" : "fas fa-align-left";
        },
        fullBleedTooltipText() {
            return this.isTucked ? "Tucked" : "Untucked";
        },
        showGradientButton() {
            return !this.isTransparent && (this.isEmail || this.isRound || this.fullBleed);
        },
        showTuckedButton() {
            return !this.isTransparent && this.isEmail && this.fullBleed && !this.isInset && !this.hideGradient && !this.isRound;
        },
        insetIconClass() {
            return this.isInset ? "far fa-arrows-maximize opacity-100" : "far fa-expand fa-solid fa-square opacity-100";
        },
        insetTooltipText() {
            return this.isInset ? "Inset" : "Maximized";
        },
        styleIconClass() {
            let modifier = ` !opacity-100 !text-gray-900`;
            if (this.isNormal) return "far fa-image" + modifier;
            if (this.isRound) return "far fa-circle !opacity-100 !text-gray-900" + modifier;
            if (this.isSquare) return "far fa-square" + modifier;
            if (this.isLandscape) return "far fa-panorama" + modifier;
            if (this.isRotated) return "far fa-sync" + modifier;
            if (this.isFull) return "far fa-thermometer-full";
            return "far fa-image" + modifier;
        },
        styleTooltipText() {
            if (this.isNormal) return "normal";
            if (this.isRound) return "circle";
            if (this.isSquare) return "square";
            if (this.isLandscape) return "panorama";
            if (this.isRotated) return "Rotated";
            if (this.isFull) return "Full";
            return "image";
        },
        buttons() {
            return [
                {
                    id: 1,
                    condition: true, // Always shown
                    class: "f-24 border-0 opacity-100 !text-gray-900",
                    iconClass: "far fa-images f-20",
                    tooltipText: "Choose image",
                    clickHandler: this.edit,
                },
                {
                    id: 2,
                    condition: true, // Condition for inset button
                    class: "f-24 border-0 opacity-100 !text-gray-900",
                    iconClass: this.insetIconClass,
                    tooltipText: this.insetTooltipText,
                    clickHandler: this.toggleInset,
                },
                {
                    id: 3,
                    condition: this.isEmail || !(this.fullBleed && !this.isEmail), // Condition for style button
                    class: "border-0",
                    iconClass: this.styleIconClass,
                    tooltipText: this.styleTooltipText,
                    clickHandler: this.toggleStyle,
                },
                {
                    id: 4,
                    condition: this.isEmail, // Only shown if isEmail is true
                    class: "border-0",
                    iconClass: this.swapSidesIconClass,
                    tooltipText: "Toggle full bleed",
                    clickHandler: this.toggleFullBleed,
                },
                {
                    id: 5,
                    condition: !this.isEmail, // Always shown
                    class: "border-0",
                    iconClass: this.swapSidesIconClass,
                    tooltipText: "Swap sides",
                    clickHandler: this.toggleSwapSides,
                },
                {
                    id: 6,
                    condition: this.showGradientButton, // Condition for gradient button
                    class: "f-15",
                    iconClass: "", // No icon class for gradient button
                    tooltipText: "Toggle Gradient",
                    clickHandler: this.toggleGradient,
                },
                {
                    id: 7,
                    condition: this.showTuckedButton, // Condition for tucked button
                    class: "f-15 border-0",
                    iconClass: this.fullBleedIconClass,
                    tooltipText: this.fullBleedTooltipText,
                    clickHandler: this.toggleFullBleedMargin,
                },
                {
                    id: 8,
                    condition: this.isEmail && this.relevantImages?.length > 1, // Condition for tucked button
                    class: "f-15 border-0",
                    // iconClass: this.fullBleedIconClass,
                    tooltipText: "Make a GIF",
                    clickHandler: this.makeAGif,
                },
                // Add more button configurations here as needed
            ];
        },
    },
    methods: {
        async makeAGif() {
            let indexes = this.section?.relevant_image_indexes || this.relevantImages;
            if (indexes.length > 1) {
                let images = this.images.filter((image, index) => indexes.includes(index)).map(image => image.url); // Assuming each image object has a 'url' property
                console.log(images);
                let response = await webRequest("makeAGif", {
                    images,
                    text: this.section.header,
                    font: this.fonts[2].url,
                    fontName: this.fonts[2].name,
                    logo: this.logos[0].url,
                });
                let result = await response.json();
                if (result) console.log(result);
                result.kind = "gif";
                result.alt += " gif";
                this.$store.dispatch("styleGuide/addStyleGuideImage", result);
                this.$store.dispatch("styleGuide/saveStyleGuide");
                this.setImageIndex(this.images.length - 1);
                return result; // This will be an array of URLs
            }
        },
        onBeforeEnter(el) {
            gsap.set(el, { alpha: 0.0, y: 10 });
        },
        onEnter(el, done) {
            const delay = el.dataset.index * 0.05;
            gsap.to(el, {
                alpha: 1,
                y: 0,
                delay,
                duration: 0.5,
                ease: "back.inOut",
                onComplete: done,
            });
        },
        onLeave(el, done) {
            gsap.to(el, {
                alpha: 0,
                y: 10,
                duration: 0.5,
                ease: "back.inOut",
                onComplete: done,
            });
        },
        setImageIndex(index) {
            this.$emit("set-image-index", index);
        },
        edit() {
            this.$emit("edit");
        },
        toggleFullBleed() {
            this.$emit("toggleFullBleed");
        },
        toggleSwapSides() {
            this.$emit("toggle-swap-sides");
        },
        toggleInset() {
            this.$emit("toggleInset");
        },
        toggleStyle() {
            this.$emit("toggleStyle");
        },
        toggleGradient() {
            this.$emit("toggleGradient");
        },
        toggleFullBleedMargin() {
            this.$emit("toggleFullBleedMargin");
        },
    },
};
</script>

<style lang="scss" scoped>
.gradient-line {
    background: linear-gradient(to bottom, #ffffff 50%, #000000 50%);
}
</style>
