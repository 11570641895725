<template>
    <div v-if="image && image.url" class="bg-light-500 w-100 br-15 m-0 flex overflow-hidden bg-base-900/10 p-0 shadow-insetHigh p-relative" style="margin-right: 0px;!important;">
        <img v-if="image && image.url" :src="image.url" class="x" />
        <div class="p-absolute p-fill text-shadow white f aie line-height-smaller jce fc z-5 f-13 aic">
            <p v-if="image.title" class="px-3 line-clamp-2">{{ image.title }}</p>
            <p v-if="image.alt" class="px-3 line-clamp-2">{{ image.alt }}</p>
            <p v-if="image.used" class="px-3 line-clamp-2">{{ image.used }}</p>
            <p v-if="image.height" class="px-3 line-clamp-2">{{ image.height }} x {{ image.width }}</p>
            <p class="px-3 line-clamp-2" v-if="image.link">{{ image.link }}</p>
            <p class="px-3 line-clamp-2" v-if="image.source">{{ image.source }}</p>
            <p class="px-3 line-clamp-2">{{ image }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "ImagePickerImage",
    props: {
        addImageToSlide: {},
        image: {},
        index: {},
    },
};
</script>
