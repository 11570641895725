<template>
    <div class="mb-5 text-left">
        <h5>Recent Videos</h5>
        <div class="f flex-wrap gap-0 overflow-hidden">
            <div class="animated fadeInUpSmooth duration-5 flex-2 col-12 col-md-6 px-0" v-for="story in staggeredArticles.slice(0, limitArticles)">
                <div class="br-10 m-2 bg-gray-100 dark:bg-base-600">
                    <SearchArticle :animate="animate" :story="story" />
                </div>
            </div>
        </div>
        <div class="x text-center">
            <BaseButton class="mx-auto" style-type="secondary" rounded v-if="staggeredArticles && staggeredArticles.length > limitArticles" label="See all" @click.prevent="toggleSeeAllArticles"></BaseButton>
        </div>

        <!--        <span class="d-block fwb">Recent videos</span>-->
        <!--        <span class="whitespace-pre-line">{{ articlesString }}</span>-->
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import SearchArticle from "@/components/chat/specialMessages/Search/SearchArticle.vue";

export default {
    name: "InlineVideos",
    inject: ["scrollChatWindowToBottom"],
    components: { SearchArticle, BaseButton },
    props: {
        videos: {
            type: Array,
            required: true,
        },
        animate: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        videos: {
            immediate: true,
            handler(newArticles) {
                this.StaggerArticles(newArticles);
            },
        },
    },
    data() {
        return {
            staggeredArticles: [],
            limitArticles: 6,
        };
    },
    computed: {
        //map articles to a string
        articlesString() {
            // return this.articles.map(article => article.title).join("\n");
            return this.videos.map(article => `### ${article.title}\n(${article.date})`).join("\n");
        },
    },
    methods: {
        toggleSeeAllArticles() {
            if (this.limitArticles === this.videos.length) this.limitArticles = 6;
            else if (this.limitArticles === 6) {
                this.limitArticles = this.videos.length;
            }
        },
        async StaggerArticles(articles) {
            this.staggeredArticles = [];
            if (this.animate) {
                for (let i = 0; i < articles.length; i++) {
                    await this.$nextTick();
                    setTimeout(() => {
                        this.staggeredArticles.push(articles[i]);
                        // this.scrollChatWindowToBottom(true);
                    }, i * 1000); // Adjust delay time (in ms) to your preference
                }
            } else {
                this.staggeredArticles = articles.slice(); // Copy the questions array without animation
            }
        },
    },
};
</script>