import { debounce } from "lodash";
import { db } from "@/firebase";
import { query, collection, where, orderBy, onSnapshot, addDoc, limit, startAfter, doc, Timestamp, getDoc, getDocs } from "firebase/firestore";
// import MemoryStore from "@/store/MemoryStore";
function handleEvent(commit, messageId, event) {
    switch (event.event) {
        case "thread.message.delta":
            commit("appendMessageText", { id: messageId, text: event.data.delta.content[0].text.value });
            break;
        case "thread.run.step.delta":
            if (event.data.delta.step_details.type === "tool_calls") {
                event.data.delta.step_details.tool_calls.forEach(toolCall => {
                    if (toolCall.type === "code_interpreter") {
                        commit("appendMessageText", { id: messageId, text: `\n\nInput:\n${toolCall.code_interpreter.input}\n\nOutput:\n${toolCall.code_interpreter.outputs.map(output => output.logs).join("\n")}\n` });
                    } else if (toolCall.type === "retrieval") {
                        // Handle retrieval tool calls
                    } else if (toolCall.type === "function") {
                        // Handle function tool calls
                    }
                });
            }
            break;
        // Handle other event types as needed
    }
}
import webRequest from "@/mixins/ai/web_request";
import { gpt3, gpt316, gpt4 } from "@/mixins/default_models";
export default {
    namespaced: true,
    modules: {},
    state() {
        return {
            inputHeight: 0,
            threads: [],
            runs: [],
            topic: null,
            model: gpt3,
            threadId: null,
            assistantId: null,
            assistants: [],
        };
    },
    mutations: {
        setThreads(state, threads) {
            state.threads = threads;
        },
        setRuns(state, runs) {
            state.runs = runs;
        },
        setThreadId(state, id) {
            state.threadId = id;
        },
        setAssistantId(state, id) {
            state.assistantId = id;
        },
        setAssistants(state, assistants) {
            state.assistants = assistants;
        },
        addMessage(state, message) {
            state.threads.push(message);
        },
        appendMessageText(state, { id, text }) {
            const message = state.threads.find(msg => msg.id === id);
            if (message) {
                if (!message.content[0].text.value) message.content[0].text.value = "";
                message.content[0].text.value += text;
            }
        },
    },
    actions: {
        setActiveAssistant({ commit, state }, id) {
            commit("setAssistantId", id);
        },
        async createAssistant({ commit, state, dispatch }, obj) {
            const response = await webRequest("assistants/create", obj);
            let data = await response.json();
            logGroup("Make Assistant", data);
            await dispatch("listAssistants");
            return data;
        },
        async modifyAssistant({ commit, state, dispatch }, obj) {
            let { id, ...rest } = obj;
            const response = await webRequest("assistants/modify", obj);
            let data = await response.json();
            logGroup("Modify Assistant", data);
            await dispatch("listAssistants");
            return data;
        },
        async deleteAssistant({ commit, state, dispatch }, obj) {
            const response = await webRequest("assistants/delete", obj);
            await dispatch("listAssistants");
        },
        async createAssistantFile({ commit, state }, obj) {
            const response = await webRequest("assistants/create-file", obj);
            let data = await response.json();
            logGroup("Create Assistant File", data);
            return data;
        },
        setAssistantId({ commit }, id) {
            commit("setAssistantId", id);
        },
        setAssistants({ commit }, assistants) {
            commit("setAssistants", assistants);
        },
        setThreadId({ commit }, id) {
            commit("setThreadId", id);
        },
        async listAssistants({ commit, state }) {
            const response = await webRequest("get-assistants");
            let data = await response.json();
            logGroup("Assistants", data);
            commit("setAssistants", data);
            return data;
        },
        async getThreads({ commit, state, dispatch }, id) {
            commit("setThreadId", id);
            const response = await webRequest("threads", { id });
            let data = await response.json();
            logGroup("Threads", data);
            commit("setThreads", data.thread);
            if (data.runs) commit("setRuns", data.runs);
            let assistantMessages = data.thread.filter(m => m.role === "assistant");
            if (assistantMessages.length > 0) {
                let assistant_id = assistantMessages[0].assistant_id;
                commit("setAssistantId", assistant_id);
            }
            return data;
        },
        // async sendAssistantMessage({ commit, dispatch, state }, obj) {
        //     let { threadId, message, assistantId, model = gpt3 } = obj;
        //     const response = await webRequest("threads/send-message", { ...obj, model });
        //     let data = await response.json();
        //     return await dispatch("getThreads", threadId);
        //     // logGroup("Assistant Message", data);
        //     return data;
        // },
        async addUserMessage({ commit, dispatch, state }, obj) {
            let { threadId, message, assistantId } = obj;
            const newMessage = {
                id: new Date().getTime(),
                object: "thread.message",
                created_at: Math.floor(new Date().getTime() / 1000) - 1,
                // assistant_id: assistantId,
                thread_id: threadId,
                run_id: null,
                role: "user",
                content: [
                    {
                        type: "text",
                        text: {
                            value: message, // once it adds the message to the store it will need to mainly stream here.
                            annotations: [],
                        },
                    },
                ],
                file_ids: [],
                metadata: {},
            };
            commit("addMessage", newMessage);
            return;
        },
        async sendAssistantMessage({ commit, dispatch, state }, obj) {
            let { threadId, message, assistantId, model = gpt3 } = obj;
            // Create a new message object for the streaming response
            await dispatch("addUserMessage", obj);
            const newMessage = {
                id: new Date().getTime(),
                object: "thread.message",
                created_at: Math.floor(new Date().getTime() / 1000) + 1,
                assistant_id: assistantId,
                thread_id: threadId,
                run_id: null,
                role: "assistant",
                content: [
                    {
                        type: "text",
                        text: {
                            value: "", // once it adds the message to the store it will need to mainly stream here.
                            annotations: [],
                        },
                    },
                ],
                file_ids: [],
                metadata: {},
            };

            commit("addMessage", newMessage);
            // return;
            const response = await webRequest("threads/send-message", { ...obj, model }, true);

            if (response.ok) {
                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");

                while (true) {
                    const { value, done } = await reader.read();
                    if (done) break;

                    const chunk = decoder.decode(value);
                    const tokens = chunk.split("\n\n").filter(Boolean);

                    tokens.forEach(token => {
                        let [_, text] = token.split(": ");
                        try {
                            text = JSON.parse(text);
                        } catch (e) {
                            console.log(e);
                        }
                        if (text && typeof text === "string") {
                            if (text?.includes("assistant >")) {
                                text = text.replace("assistant > ", "");
                            } else if (text.includes("output >")) {
                                text = text.replace(`output >`, "");
                            } else if (text.includes("tool >")) {
                                text = text.replace(`tool >`, "");
                                console.log("this was a tool");
                            }
                            commit("appendMessageText", { id: newMessage.id, text: `${text}` });
                        } else if (text) {
                            console.log("Not text", text);
                        }
                    });
                }

                // dispatch("getThreads", threadId);
            }
        },
    },
    getters: {
        assistants: state => state.assistants,
        currentAssistant: (state, getters) => {
            return getters.assistants.find(a => a.id === state.assistantId);
        },
        threads: state => state.threads,
        runs: state => state.runs,
        threadId: state => state.threadId,
        assistantId: state => state.assistantId,
        messages: state => state.threads?.reverse(),
        user: (state, getters, rootState) => {
            return rootState.user.user;
        },
        conversation: state => {
            const messages = state?.threads?.reverse() || [];
            const runs = state?.runs || [];
            return [...messages, ...runs].sort((a, b) => a.created_at - b.created_at);
        },
        currentAssistantFiles: (state, getters) => {
            return getters.currentAssistant?.files_ids || [];
        },
    },
};
