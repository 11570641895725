<template>
    <div class="h-auto">
        <div class="h-full cursor-pointer rounded-dynamic text-sm font-bold py-2 px-3 leading-none transition-colors duration-200 ease-in-out f aic" :class="buttonClass" :style="adjustedStyles" @click.prevent="handleClick">
            <div class="f jcb line-height-small aic">
                <div v-html="label"></div>
                <template v-if="!confirmButton && isMultipleChoice">
                    <template v-if="isSelected">
                        <i class="fas fa-check ml-3"></i>
                    </template>
                    <template v-else>
                        <!-- <i class="fas fa-check ml-3 opacity-0"></i> -->
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        isSelected: Boolean,
        isMultipleChoice: Boolean,
        confirmButton: Boolean,
    },
    computed: {
        adjustedStyles() {
            return {
                fontSize: this.adjustFontSize(this.label),
            };
        },
        formattedLabel() {
            const words = this.label.split(" ");
            const threshold = 26;
            const nbsp = "\u00a0";
            const minCount = 4;

            if (this.label.length > threshold && words.length > minCount) {
                const targetIndex = -(minCount + 1);
                const lastWords = words.slice(targetIndex).join(nbsp);
                words[targetIndex] = lastWords;
                words.splice(targetIndex + 1);
            } else if (words.length > 1) {
                words[words.length - 2] = `${words[words.length - 2]}${nbsp}${words[words.length - 1]}`;
                words.pop();
            }
            return words.join(" ");
        },
        buttonClass() {
            let classes = {
                base: "bg-snow text-teal-700 hover:bg-snow hover:text-teal-500 border-light hover:shadow px-4 dark:bg-base-800 dark:text-base-600 dark:hover:bg-base-600",
                confirm: "bg-gray-700 text-white hover:bg-gray-900 hover:shadow-lg",
                selected: {
                    multiple_choice: "bg-teal-50 text-teal-700 hover:bg-snow hover:text-teal-500 border-light dark:bg-base-700",
                    // single: "bg-snow py-1 border-light shadow",
                },
            };

            if (this.confirmButton) {
                return classes.confirm;
            }

            if (this.isSelected) {
                return this.isMultipleChoice ? classes.selected.multiple_choice : classes.selected.single;
            }

            return classes.base;
        },
    },
    methods: {
        handleClick() {
            // this.$emit("click", this.label);
        },
        adjustFontSize(text) {
            const threshold = 40;
            if (text.length > threshold) {
                return "0.75rem"; // Smaller font size, e.g., 12px
            }
            return ".9rem"; // Default font size, e.g., 16px
        },
    },
};
</script>

<style scoped lang="scss">
a {
  font-size: 15px;
  padding: 0.5rem 1.5rem !important;

  &:active {
    color: unset !important;
  }
}
.rounded-dynamic{
  border-radius: 1rem;
}
  .prevent-widows {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
