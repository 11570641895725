<template>
    <div class="f gap-1 py-2 px-1">
        <div :class="buttonStyle" @click.prevent="summarize(extractText(sectionsObject))">
            <i class="far fa-thought-bubble"></i>
            <span class="ml-2" v-if="!mobile">Summarize</span>
        </div>
        <button
            :class="buttonStyle"
            class="hover:bg-base-50 hover:text-red"
            @click="generateImage(1, null, extractText(sectionsObject))">
            <i aria-hidden="true" class="fa fa-image"></i>
        </button>
        <button
            :class="buttonStyle"
            class="hover:text-red"
            @click="$emit('deleteDoc', 'documents', documentObject, documents)">
            <i aria-hidden="true" class="fa fa-trash"></i>
            <!--                class="f-15 btn btn-outline-black ml-3 border border-solid shadow"-->
            <!--                br-5 hover:text-red d-block ml-3 border border-base-900/20 bg-snow p-1 px-2 shadow hover:bg-base-50-->
        </button>
    </div>
    <!--    style="background:rgba(255,255,255,0.05)"-->
</template>
<script>
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import documentMixins from "@/mixins/DocumentArchive/documentMixins";
import imageMixin from "@/mixins/images/imageMixin";

export default {
    name: "DocumentToolbarActions",
    mixins: [imageMixin, documentMixins, documentManagementMixin],
    props: {
        // document: {
        //     type: Object,
        //     default: () => ({}),
        // },
    },
    computed: {
        sectionsObject() {
            if (this.$store.state.document.sections) {
                return this.$store.state.document.sections;
            }
            return false;
        },
        documentObject() {
          return this.$store.state.document;
        },
        buttonStyle() {
            let base = "z-2 f-15 br-5 f btn aic fwsb jcc border-solid border-1 border";
            let style;
            if (this.document && this.document.theme && this.document.theme.colors) {
                style = `
                bg-base-50/10 mr-2 text-base-50/70
                hover:text-base-50 hover:border-gray-300/5 hover:shadow-md
                active:bg-base-50 active:text-base-900
                `;
            } else {
                style = `
                bg-base-50/10
                hover:border-gray-300/5
                hover:bg-base-900/5
                hover:text-base-900
                `;
            }
            return base + " " + style;

            // f-15 btn btn-outline-black border-1 border border-solid border-base-900/20 shadow
            // return `f-15 btn btn-outline-black border-1 border border-solid border-base-900/20 shadow`;
        },
    },
};
</script>
<style>
input {
}
</style>
