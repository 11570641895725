<template>
    <StyleGuideContainer class="mb-0 !pb-0" section="fonts" title="Fonts">
        <!--        {{ fonts }}-->
        <Transition mode="out-in" :key="fonts.length" name="fade-up">
            <FontSamplesPicker class="px-0 md:px-3 block">
                <div class="x text-center">
                    <BaseButton class="mx-auto !text-base-900" label="Show All" rounded size="lg" @click="filter = !filter"></BaseButton>
                </div>
            </FontSamplesPicker>
        </Transition>
        <div v-if="mobile" class="x col-span-12">
            <template v-for="f in sortedFonts">
                <MobileFontSample :f="f" class="px-3" />
            </template>
        </div>
    </StyleGuideContainer>
    <!--    <dynamic-object-viewer class="container" :data-object="{ count: styleGuide.fonts.length, fonts: styleGuide.fonts }"></dynamic-object-viewer>-->
</template>
<script>
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import FontSamplesPicker from "@/components/styleGuide/Sections/FontsSection.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import MobileFontSample from "@/components/styleGuide/Typography/MobileFontSample.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import StyleGuideColors from "@/components/styleGuide/Colors/StyleGuideColors.vue";

export default {
    name: "StyleGuideFonts",
    components: {
        StyleGuideColors,
        DynamicObjectViewer,
        CollapsableMenuItem,
        MobileFontSample,
        BaseButton,
        FontSamplesPicker,
        StyleGuideContainer,
    },
    mixins: [styleGuideMixin],
    data() {
        return {
            // showUsage: false,
            filter: true,
            showUsage: true,
        };
    },
    computed: {
        showAllButton() {
            return false;
        },

        sortedFonts() {
            let fonts = this.fonts;
            if (!fonts || (fonts && fonts?.length < 1)) return [];
            fonts = fonts.filter(f => {
                return f.name !== "Poppins" && f.name !== "Roboto";
            });
            if (this.filter) {
                fonts = fonts.filter(f => f.usageCount > 0);
            }
            return fonts.sort((a, b) => {
                let aUsageCount = parseInt(a.usageCount) || 0;
                let bUsageCount = parseInt(b.usageCount) || 0;

                // First, sort by usageCount
                if (aUsageCount !== bUsageCount) {
                    return bUsageCount - aUsageCount;
                }

                // // If usageCounts are the same, sort by fontFamily
                // if (a.fontFamily !== b.fontFamily) {
                //     return a.fontFamily.localeCompare(b.fontFamily);
                // }
                //
                // // If fontFamily is the same, sort by font style
                // return a.style.localeCompare(b.style);
            });
        },
    },
    props: {},
};
</script>
<style lang="scss" scoped>
// Basic Airbnb colors (might not be exact but close approximations)
$airbnb_red: #ff5a5f;
$light_grey: #ebebeb;
$dark_grey: #484848;

table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid $light_grey;

    th,
    td {
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid;
        @apply border-gray-200;
    }

    th {
        @apply bg-gray-100;
        font-weight: bold;
    }

    tbody tr {
        @apply bg-snow;
        .url_text {
            @apply hidden;
        }
    }

    tbody tr:hover {
        .url_text {
            @apply transition-all;
            @apply text-gray-900;
            @apply visible;
        }

        //background-color: rgba($airbnb_red, 0.1);
        @apply bg-gray-50;
    }

    &.striped tbody tr:nth-of-type(odd) {
    }

    &.highlight-row tbody tr:hover {
        @apply bg-gray-50;
    }

    caption {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
        color: $airbnb_red;
    }
}
</style>
