<template>
<div></div>
</template>
<script>
export default {
    name: "FlightInfo",
    props: {
        flight: {},
        index: {},
    },
};
</script>
