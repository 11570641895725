<template>
    <div class="x animated swingInUp duration-5 d-1 p-relative block">
        <div v-if="true" class="mb-5 grid grid-cols-12 gap-3">
            <!--        <div v-if="!mobile" class="mb-5 grid grid-cols-12 gap-3">-->
            <!--    <swiper :grabCursor="true" ref="swiper" :autoHeight="false" :slidesPerView="2" :spaceBetween="4" :pagination="{ clickable: true }" :modules="modules" :loop="true" class="x block items-stretch">-->
            <div v-for="(sight, index) in sights.slice(0, limit)" :class="[`d-${index * 2}`, { 'col-span-6': mobile, 'col-span-3': !mobile }]" appear class="x animated swingInUp duration-5 cursor-pointer">
                <Place :sight="sight" class="border-light br-10 y cursor-pointer overflow-hidden shadow hover:shadow-lg" aspect="square"></Place>
                <!--                </swiper-slide>-->
            </div>
        </div>
        <div v-else class="overflow-visible pb-0" style="margin-left: -3rem; margin-right: 0rem !important">
            <template v-if="cards">
                <swiper :effect="'cards'" :modules="modules" class="mySwiper">
                    <!--            <swiper ref="swiperMobile" :autoHeight="false" :slidesPerView="2" :spaceBetween="4" :modules="modules" :pagination="{ clickable: true }" :loop="true">-->
                    <swiper-slide v-for="(sight, index) in sights" class="mw-300 x mx-auto">
                        <Place :sight="sight" class="border-light br-10 y mw-300 mb-4 cursor-pointer bg-snow shadow"></Place>
                    </swiper-slide>
                </swiper>
            </template>
            <template v-else>
                <swiper ref="swiperMobile" :centeredSlides="true" :height="200" :loop="true" :modules="modules" :pagination="{ clickable: true }" :slidesPerView="'auto'" :spaceBetween="15">
                    <!--                    :slidesOffsetBefore="20" :slidesOffsetAfter="20" -->
                    <swiper-slide v-for="(sight, index) in sights" class="mw-200">
                        <Place :class="`d-${index * 2}`" :sight="sight" class="border-light br-10 y animated swingInUp duration-5 mb-4 overflow-hidden bg-snow shadow hover:shadow-lg"></Place>
                    </swiper-slide>
                </swiper>
            </template>
        </div>
    </div>
</template>
<script>
import Place from "@/components/chat/specialMessages/Search/Place.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/effect-cards";
export default {
    setup() {
        const onSwiper = swiper => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            modules: [EffectCards],
            onSlideChange,
        };
    },
    name: "Sights",
    methods: {
        next() {
            this.$refs.swiper.swiper.slideNext();
        },
        prev() {
            this.$refs.swiper.swiper.slidePrev();
        },
    },
    components: { Place, Swiper, SwiperSlide },
    props: {
        sights: Array,
        col: {
            type: Number,
            default: 4,
        },
        limit: {
            type: Number,
            default: 4,
        },
        cards: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss">
.gradientRight{
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.gradientLeft{
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
</style>
