<template>
    <div class="p-relative d-block">
        <!--        <ChatSpecialContainer ref="card" title="Knowledge Group">-->
        <div class="p-relative d-block">
            <div class="border-light br-10 animated fadeInUpSmooth duration-5 mb-5 overflow-hidden bg-snow dark:bg-base-700">
                <div class="f border-bottom gap-md-3 prose flex-column flex-md-row gap-0 p-3">
                    <div class="pr-md-5 pr-0">
                        <p class="animated fadeInUpSmooth duration-5 d-2 font-weight-bolder my-0 py-0" v-if="info.title">
                            <animated-text v-if="info.title" :text="info.title"></animated-text>
                        </p>
                        <p class="animated fadeInUpSmooth duration-5 d-4 my-0" v-if="info.type">
                            <animated-text v-if="info.type" :text="info.type"></animated-text>
                        </p>
                        <p class="animated fadeInUpSmooth duration-5 d-6 mb-md-3 mb-0 text-sm" v-if="info.description">
                            <animated-text v-if="info.description" :text="info.description"></animated-text>
                        </p>
                    </div>
                    <!--                    <img class="c-100 animated fadeInUpSmooth duration-5 d-8" v-if="info.image" :src="info.image" />-->
                </div>
                <div class="d-block x" v-if="info.header_images && info.header_images.length > 1">
                    <inline-images class="x" :images="info.header_images"></inline-images>
                </div>
                <template v-else-if="info.header_images && info.header_images.length > 0">
                    <template v-for="image in info.header_images">
                        <img class="x animated fadeInUpSmooth o-cover duration-5 d-8" :src="image.image" />
                    </template>
                </template>
            </div>

            <template v-if="false">
                <template v-for="(value, key) in info">
                    <div class="d-block first:mt-3 last:mb-4" v-if="!key.includes('_') && key !== 'events' && key !== 'title' && key !== 'tabs' && key !== 'profiles' && key !== 'description' && key !== 'kgmid' && key !== 'type' && key !== 'image' && key !== 'source'">
                        <div class="f jcs ais gap-0">
                            <ul v-if="typeof value == 'array'">
                                <li v-for="val in value">{{ val }}</li>
                            </ul>
                            <div v-if="typeof value == 'object'" class="border-top x">
                                <!--                                <h5 class="mb-3 capitalize">-->
                                <!--                                    <template v-if="key !== 'title'">{{ key }}:</template>-->
                                <!--                                </h5>-->
                                <related-questions class="x" :title="key">
                                    <div class="f gap-1 overflow-x-scroll line-clamp-1">
                                        <div class="f fc w-200 mb-4 gap-1 text-gray-800" v-if="value && value.length > 0" v-for="val of value.slice(0, 6)">
                                            <img v-if="val.image && key !== 'profiles'" :src="val.image" class="x br-10 border-light mb-0" />
                                            <a v-if="val.link" :href="val.link" class="mw-100 d-block my-0 text-xs text-gray-800">{{ val.text || val.name }}</a>
                                            <span v-else-if="val.text" class="d-block fwb text-gray-800">{{ val.text }}</span>
                                        </div>
                                    </div>
                                </related-questions>
                            </div>
                            <div class="f x" v-else>
                                <span class="fwb width-min-75 capitalize">
                                    <template v-if="key !== 'title'">{{ key }}:</template>
                                </span>
                                <span>{{ value }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <div class="p-3" v-if="info.events && false">
                <h5>Events</h5>
                <div class="f flex-wrap items-stretch text-xs">
                    <div class="w-50 pb-3 pr-3" v-for="event in info.events">
                        <a v-if="event.link" :href="event.link">{{ event.name }}</a>
                        <span v-else class="d-block">{{ event.name }}</span>
                        <span class="d-block">{{ event.extensions }}</span>
                        <span class="d-block">{{ event.date }}, {{ event.time }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!--        </ChatSpecialContainer>-->
    </div>
</template>
<script>
import ChatSpecialContainer from "@/components/chat/specialMessages/ChatSpecialContainer.vue";
import RelatedQuestions from "@/components/chat/specialMessages/Search/RelatedQuestions.vue";
import inlineImages from "@/components/chat/specialMessages/Search/InlineImages.vue";
import AnimatedText from "@/components/CoreUI/animatedText.vue";
export default {
    name: "KnowledgeGraph",
    components: { AnimatedText, RelatedQuestions, ChatSpecialContainer, inlineImages },
    props: {
        info: {},
    },
};
</script>
