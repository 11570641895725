import webRequest from "@/mixins/ai/web_request";
async function stopGenerating(store) {
    try {
        let id = store.state.stream.activeStreamIds;
        let request = { requestId: id };
        const response = await webRequest("stop", request);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        console.log("Generation stopped");
    } catch (err) {
        console.error("Error stopping generation:", err.message);
    }
}

export default stopGenerating;
