import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import { mapGetters } from "vuex";
import styleGuideTextMixin from "@/mixins/StyleGuideMixins/StyleGuideTextMixin";
import styleGuideCSSMixin from "@/mixins/StyleMixins/StyleGuideCSSMixin";
import CreateStyleGuideMixin from "@/mixins/StyleGuideMixins/CreateStyleGuideMixin";
import chroma from "chroma-js";
import findMatchingObject from "@/mixins/firebase/findMatchinObject";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import webRequest from "@/mixins/ai/web_request";
import { db } from "@/firebase";
import stopGenerating from "@/mixins/ai/stop_generation";
import removeFromFirebase from "@/mixins/firebase/deleteFromFirebase";
import { logFetch } from "@/mixins/firebase/firebaseHelpers";
import CSSHelpers from "@/mixins/StyleMixins/CSSHelpers";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    mixins: [styleGuideMixin, styleGuideCSSMixin, styleGuideTextMixin, CreateStyleGuideMixin, CSSHelpers],
    methods: {
        async fetchStyleGuide(id) {
            this.$store.dispatch("styleGuide/setStyleGuide", { id: id });
            if (id) {
                await this.$store.dispatch("styleGuide/fetchStyleGuide", { id: id, database: "brands" });
                this.applyStyles();
                this.pageLoaded();
            }
            return;
        },
        async resetGuide(id) {
            await this.fetchStyleGuide(id);
            this.applyStyles();
            let { base, url, name } = this.styleGuide;
            if (!base) await this.updateStyleGuide(url);
            this.$forceUpdate();
        },
        async updateStyleGuide(url, id, styleGuide) {
            this.updateStyleGuideProp("loading", true);
            if (!url) url = this.styleGuide.url;
            if (!id) id = this.$route.params.id;
            try {
                // let brand = await this.findURLinSortedBrands(url);
                // this.updateStyleGuideProp("name", brand.name);
                // this.updateStyleGuideProp("description", brand.description);
            } catch (error) {
                console.error(error.message);
            }
            if (!id) {
                console.groupCollapsed("%cFailed to update", fail);
                console.error("No id", id);
                console.log(this.$route.params.id);
                console.log(this.styleGuide);
                console.groupEnd();
                return;
            }
            await this.createStyleGuide(url, id, { sections: undefined, payload: undefined, multiple: undefined, refresh: true, newGuide: false });
        },
    },
};
