<template>
    <div class='container pt-8'>
        <h1>Stability</h1>
        {{ imageArray }}
        <template v-for='image in imageArray'>
            <img :src='image' />
        </template>
        <input v-model='textPrompt' class='chatInput' />
        <button @click='getStabilityImage'>Get image</button>
    </div>
</template>
<script setup></script>
<script>
import fs from 'node:fs';

import imageMixin from '@/mixins/images/imageMixin';
// import fetch from 'node-fetch';

export default {
    components: {},
    mixins: [imageMixin],
    data() {
        return {
            imageArray: [],
            textPrompt: 'A lighthouse on a cliff',
        };
    },
    computed: {},
    watch: {},
    async mounted() {},
    methods: {
        async getStabilityImage(textPrompt) {
            // eslint-disable no-console, no-control-regex
            console.error('sending request');
            const engineId = 'stable-diffusion-512-v2-0';
            // const apiHost = process.env.API_HOST ?? 'https://api.stability.ai';
            const apiHost = 'https://api.stability.ai';
            const apiKey = 'sk-ZQUJs3ZA28iwRUZxZdC6RMds03ppmdDSDlH8rKmjxB5E5hoR';
            // const apiKey = import.meta.env.STABILITY_API_KEY;

            if (!apiKey) throw new Error('Missing Stability API key.');

            const response = await fetch(`${apiHost}/v1/generation/${engineId}/text-to-image`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${apiKey}`,
                },
                body: JSON.stringify({
                    text_prompts: [
                        {
                            text: this.textPrompt,
                        },
                    ],
                    cfg_scale: 7,
                    clip_guidance_preset: 'FAST_BLUE',
                    height: 512,
                    width: 512,
                    samples: 1,
                    steps: 50,
                }),
            });

            if (!response.ok) {
                console.error(`Non-200 response: ${await response.text()}`);
            }

            // interface GenerationResponse {
            //     artifacts: Array<{
            //         base64: string;
            //         seed: number;
            //         finishReason: string;
            //     }>;
            // }
            // const response = await fetch(
            //     `${apiHost}/v1alpha/generation/${engineId}/image-to-image`,
            //     {
            //         method: 'POST',
            //         headers: {
            //             ...formData.getHeaders(),
            //             Accept: 'image/png',
            //             Authorization: apiKey,
            //         },
            //         body: formData,
            //     },
            // );
            const responseJSON = await response.json();
            // console.error(responseJSON);
            // for (const image of responseJSON.artifacts) {
            //     console.error('artifacts', responseJSON.artifacts);
            //     console.error('artifacts[0]', responseJSON.artifacts[0]);
            // fs.writeFileSync(`./out/v1beta_txt2img_${index}.png`, Buffer.from(image.base64, 'base64'));
            let returnImage = await this.uploadImage(responseJSON.artifacts[0].base64);
            console.error(returnImage);
            this.imageArray.push(returnImage);
            // }

            // const responseJSON = await response.json() as GenerationResponse
        },
    },
    async uploadImageSB(file, kind) {
        let filename = `images/image-${this.randomId()}.png`;

        const apiKey = import.meta.env.VITE_OPENAI_API;
        let blob;

        blob = await fetch(`data:image/png;base64,${file}`).then(r => r.blob());
        filename = `images/image-blob-${this.randomId()}.png`;
        const metadata = {
            contentType: 'image/png',
        };

        const storage = getStorage();
        const imagesRef = ref(storage, filename);
        let imageURL = '';

        try {
            console.log('Is Base54 Image');
            let base64url = await uploadBytes(imagesRef, blob, metadata).then(snapshot => {});
            imageURL = await this.getImageURLFromFirebase(imagesRef);
            // return base64url;
            return imageURL;
        } catch (error) {
            if (error.status === 401) {
                console.log('Error: Invalid API key');
            } else if (error.status === 403) {
                console.log('Error: API key has insufficient permissions');
            } else if (error.status === 429) {
                console.log('Error: API rate limit exceeded');
            } else {
                console.log('Error: '+error.message);
            }
        }
    },
};
</script>
