<template>
    <div>
        <div class="animate-all py-1 block transition-all x animation-back isolate flex duration-3 -space-x-6 hover:transition-all hover:space-x-0 animated">
            <ColorCircle v-if="allColors" v-for="(color, index) in allColors" size="30" :style="`animation-delay:${20 * index}ms`" class="duration-300 elastic-easing" :name="color.key" :color="color.color" />
        </div>

        <!--        <BaseBadge color="gray" v-if="image.textColorCenter">Text: {{ image.textColorCenter }}</BaseBadge>-->
        <!--        <BaseBadge class="f" color="gray" v-if="image.colorToUse"><span>Color:</span></BaseBadge>-->
        <!--        <BaseBadge color="gray" v-if="image.complex">Complex {{ image.complex }}"</BaseBadge>-->
        <!--        <BaseBadge color="gray" v-if="image.textColor">Text color: {{ image.textColor }}</BaseBadge>-->
        <!--        <BaseBadge color="gray" v-if="image.simple">Simple image</BaseBadge>-->
        <!--        <BaseBadge color="gray" v-if="image.score">Score: {{ image.score }}</BaseBadge>-->
        <!--        <BaseBadge color="gray" v-if="image.colorCount">Colors: {{ image.colorCount }}</BaseBadge>-->
        <!--        <BaseBadge color="blue" v-if="image.kind">{{ image.kind }}</BaseBadge>-->
    </div>
</template>
<script>
import ColorCircle from "@/components/styleGuide/Colors/ColorCircle.vue";
import chroma from "chroma-js";
export default {
    name: "ImageDetails",
    components: { ColorCircle },
    props: {
        image: {},
    },
    computed: {
        allColors() {
            try {
                if (!this.image.colors) return [];
                let colorsArray = [
                    { key: "colorToUse", color: this.image.colorToUse },
                    { key: "color", color: this.image.color },
                    { key: "average", color: this.image.average },
                    { key: "backgroundColor", color: this.image.backgroundColor },
                    { key: "dominant", color: this.image.dominant },
                    { key: "bottomColor", color: this.image.bottomColor },
                    ...this.image.colors.map((color, index) => ({ key: `color${index + 1}`, color })),
                ];

                colorsArray = colorsArray.filter(item => item.color); // Remove undefined or falsy colors

                const uniqueColors = new Map();
                colorsArray.forEach(item => {
                    const hexColor = chroma(item.color).hex(); // Convert color to hex
                    if (!uniqueColors.has(hexColor)) {
                        uniqueColors.set(hexColor, item.key);
                    }
                });

                const sortedColors = Array.from(uniqueColors)
                    .map(([color, key]) => ({ key, color }))
                    .sort((a, b) => chroma(a.color).luminance() - chroma(b.color).luminance());

                return sortedColors;
            } catch (error) {
                console.log(error);
                return [];
            }
        },
    },
};
</script>
<style>
.elastic-easing {
    /* Approximate an elastic easing using cubic-bezier */
    transition-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
</style>
