function logFunction(message, completion, content, functionResponse, messages) {
    try {
        console.groupCollapsed("%c🤖 Received Function", purple, message);
        console.log("Raw response: ", completion);
        console.log("Content:", content);
        console.log("Message:", message);
        if (functionResponse) console.log("Function Response:", functionResponse);
        console.table(messages);
        console.table(completion.data.choices[0].message);
        console.groupEnd();
    } catch (error) {
        console.error(error);
    }
}
export default logFunction;
