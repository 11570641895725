<template>
    <li :key="feature.title" :class="{ 'fc text-center mx-auto aic': textAlign === 'center' }" class="relative flex items-start x mw-500 transition-all animate-all animated duration-3" @mouseenter="hovered = true" @mouseleave="hovered = false">
        <!--        <template v-if="styleType === 1">-->
        <!--            <FeatureIcon :emoji="emoji" :feature="feature" :font-awesome-class="fontAwesomeClass" :icon-is-font-awesome-string="iconIsFontAwesomeString" :show-icon="showIcon" :text-align="textAlign" />-->
        <!--            <div :style="`border:2px solid ${darkerColor}`" class="flex-grow mw-450 p-3 bg-snow button-4">-->
        <!--                <h3 v-if="title" :class="[computedTitleClass, 'fwb']" :style="`${titleStyleString}`">-->
        <!--                    &lt;!&ndash;                {{ title }}&ndash;&gt;-->
        <!--                    <span :style="`color:${darkerColor}`">{{ title }}</span>-->
        <!--                </h3>-->
        <!--                <p v-if="description" :class="[computedDescriptionClass, 'o-7']" :style="`color:${darkerColor};`">{{ description }}</p>-->
        <!--                &lt;!&ndash;                        <p v-if="description" :class="[computedDescriptionClass, 'o-5']" :style="`${descriptionStyleString}`">{{ description }}</p>&ndash;&gt;-->
        <!--            </div>-->
        <!--        </template>-->
        <template v-if="styleType === 1">
            <div :class="section?.themeClass" class="flex-grow mw-450 p-3 border border-2">
                <!--                <h3 v-if="title" :class="[computedTitleClass, 'fwb f-30']" :style="` color:inherit!important;`">-->
                <!--                    &lt;!&ndash;              ${titleStyleString};&ndash;&gt;-->
                <!--                    <span>{{ title }}</span>-->
                <!--                </h3>-->
                <!--                <p v-if="description" :class="[computedDescriptionClass, 'o-7 f-24']" :style="`color:inherit!important;`">-->
                <!--                    {{ description }}-->
                <!--                </p>-->

                <div class="relative">
                    <span v-if="title" :class="[computedTitleClass, 'fwb']">
                        <editable-text
                            :default-size="24"
                            :font-size="24"
                            :hovered="hovered"
                            :identifier="identifier"
                            :font-override="font.header"
                            :identifier-details="'features.' + featureIndex + '.title'"
                            :index="index"
                            :max-size="40"
                            :min-size="20"
                            :section="section"
                            :style="`${titleStyleString} color:inherit!important; ${font.header}`"
                            :text="title"
                            hovered-class=" "
                            text-type="custom"
                            @save-section="saveSection"></editable-text>
                    </span>
                </div>
                <div class="relative">
                    <p v-if="description" :class="[computedDescriptionClass, 'o-7 smaller !f-13']" :style="`color:inherit!important;font-size:20px!important;`">
                        <editable-text :style="`color:inherit!important;`" :default-size="20" :font-size="30" :identifier="identifier" :identifier-details="'features.' + featureIndex + '.description'" :index="index" :max-size="40" :min-size="20" :text="description" hovered-class=" " text-type="custom" @save-section="saveSection"></editable-text>
                    </p>
                </div>
            </div>
        </template>
        <template v-else>
            <FeatureIcon :emoji="emoji" :feature="feature" :font-awesome-class="fontAwesomeClass" :icon-is-font-awesome-string="iconIsFontAwesomeString" :show-icon="showIcon" :text-align="textAlign" />
            <div class="flex-grow mw-450">
                <!--                <h3 v-if="title" :class="[computedTitleClass, 'fwb']" :style="`${titleStyleString}`">{{ title }}</h3>-->
                <!--                <p v-if="description" :class="[computedDescriptionClass, 'o-7 smaller !f-13']" :style="`${titleStyleString};font-size:20px!important;`">{{ description }}</p>-->
                <div class="relative">
                    <h3 v-if="title" :class="[computedTitleClass, 'fwb']" :style="`${titleStyleString} ${font?.header}`">
                        <span class="visually-hidden">{{ font }}</span>
                        <editable-text
                            :default-size="24"
                            :font-size="24"
                            :hovered="hovered"
                            :identifier="identifier"
                            :identifier-details="'features.' + featureIndex + '.title'"
                            :index="index"
                            :font-override="font.header"
                            :max-size="40"
                            :min-size="20"
                            :section="section"
                            :style="`${titleStyleString} ${font.header}`"
                            :text="title"
                            hovered-class=" "
                            text-type="custom"
                            @save-section="saveSection"></editable-text>
                    </h3>
                </div>
                <div class="relative">
                    <p v-if="description" :class="[computedDescriptionClass, 'o-7 smaller !f-13']" :style="`${titleStyleString};font-size:20px!important;`">
                        <editable-text :default-size="20" :font-override="font.body" :font-size="30" :identifier="identifier" :identifier-details="'features.' + featureIndex + '.description'" :index="index" :max-size="40" :min-size="20" :text="description" hovered-class=" " text-type="custom" @save-section="saveSection"></editable-text>
                    </p>
                </div>
            </div>
        </template>
        <transition name="fade-up">
            <div v-show="hovered && false" class="p-absolute w-100 bg-snow z-5 p-3">
                <!--          // edit section-->
                <input v-model="feature.icon" type="text" @input="updateFeatureIndex($event.target.value, 'icon')" />
                <input v-model="feature.title" type="text" @input="updateFeatureIndex($event.target.value, 'title')" />
                <textarea v-model="feature.description" type="textarea" @input="updateFeatureIndex($event.target.value, 'description')" />
            </div>
        </transition>
    </li>
</template>
<script>
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import FeatureIcon from "@/components/generative_templates/Email/FeatureIcon.vue";
import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";
import EditableText from "@/components/generative_templates/Editing/EditableText.vue";

export default {
    name: "FeatureLine",
    components: { EditableText, PresentationMarkdown, FeatureIcon },
    mixins: [sectionStyleMixin],
    props: {
        computedDescriptionClass: { type: String, default: "" },
        computedTitleClass: { type: [String, Object], default: "" },
        descriptionStyleString: { type: String, default: "" },
        feature: { type: Object, required: true },
        titleStyleString: { type: String, default: "" },
        textAlign: { type: String, default: "left" },
        sectionFont: { type: [Boolean, Object], default: false },
        index: { type: Number, default: 0 },
        section: { type: Object, required: true },
        identifier: { type: String, required: true },
        featureIndex: { type: Number, default: 0 },
    },
    data() {
        return {
            hovered: false,
        };
    },
    methods: {
        updateFeatureIndex(value, kind) {
            let featureIndex = this.featureIndex;
            this.updateSection(`features.${featureIndex}.${kind}`, value);
        },
        saveSection() {
            this.$emit("save-section", this.section);
        },
    },
    computed: {
        features() {
            return this.section.features || [];
        },
        themeClass() {
            return this.section.themeClass || "";
        },
        styleType() {
            return this.section.styleType || 0;
        },
        darkerColor() {
            let colors = this.$store.getters["styleGuide/colors"];
            if (colors.all_colors) colors = colors.all_colors;
            let colorCount = colors.length;
            let colorString = `var(--color${colorCount - 2})`;
            return colorString;
        },
        fontAwesomeClass() {
            return this.feature?.icon?.font_awesome_class;
        },
        fontAwesomeString() {
            return this.feature?.icon?.font_awesome_class;
        },
        emoji() {
            return this.feature?.icon?.emoji;
        },
        title() {
            return this.feature?.title;
        },
        description() {
            let { description = "" } = this.feature || {};
            return description;
        },
        showIcon() {
            return this.feature?.icon?.font_awesome_class || this.feature?.icon?.emoji || typeof this.feature?.icon === "string";
        },
        iconIsFontAwesomeString() {
            if (typeof this.feature?.icon === "string" && (this.feature?.icon.startsWith("fa-") || this.feature?.icon.startsWith("fa"))) return this.feature?.icon;
            return false;
        },
        icon() {
            if (typeof this.feature?.icon === "string") {
                if (this.feature?.icon.startsWith("fa-") || this.feature?.icon.startsWith("fa")) {
                    return this.feature?.icon;
                }
            }
            return this.feature?.icon?.font_awesome_class || this.feature?.icon?.emoji || this.feature?.icon;
        },
    },
};
</script>
