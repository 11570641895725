<template>
    <div @mouseleave="hovered = false" @mouseover="hovered = true" class="">
        <!--        <CollapsableMenuItem bottom-close rounded max-height="500">-->
        <!--            <template #title>-->
        <!--                <div class="px-3 f-15 mb-3 text-uppercase o-2">Messages</div>-->
        <!--            </template>-->
        <!--            <PreviewMessages v-if="selectedAction" :messages="messages" class="overflow-hidden bg-snow p-3 x mw-900" />-->
        <!--        </CollapsableMenuItem>-->
        <BaseTextarea v-model="userInputModel" inline placeholder="What do you want created?" @keydown.enter="callFunctionFromInput(userInputModel)">
            <Transition name="fade-up">
                <div v-if="hovered && actions" key="kd" class="relative bg-brand brand-bg">
                    <TransitionGroup :css="false" appear class="overflow-scroll scrollbar-hide f items-center gap-3 pb-3 px-3" name="" tag="div" @enter="onEnter" @leave="onLeave" @before-enter="onBeforeEnter">
                        <!--                        <BaseButton key="plus" :data-index="-1" :icon="showInput ? `fas fa-minus` : 'fas fa-plus'" icon-only rounded size="xxs" style-type="secondary" @click.prevent="toggleShowInput" />-->
                        <div v-for="(f, index) in functions.filter(f => !f.hide)" :key="f.name" :class="getActionClass(f)" :data-index="index" class="m-0 cursor-pointer border p-1 px-2 border-light flex-shrink-0 rounded-full f aic jcc gap-3" @click="setAction(f)" @mouseout="onMouseOut" @mouseover="onMouseOver">
                            <i :class="[f.icon, 'fas o-5 mb-0 pb-0 block']"></i>
                            <span class="text-center fwb">{{ f.description }}</span>
                        </div>
                        <!--                        <div class="p-absolute right bottom top gradient-left-white p-3">-->
                        <!--                            <ToggleButtonSimple :vuex-key="'stream.options.advanced'" height="20" :label="currentModel" module="stream" width="35"></ToggleButtonSimple>-->
                        <!--                        </div>-->
                    </TransitionGroup>
                </div>
            </Transition>
        </BaseTextarea>
        <!--        <BaseInput v-model="userInput" @keydown.enter="callFunction" />-->
    </div>
</template>
<script>
import { gsap } from "gsap";
import { mapGetters } from "vuex";

import BaseButton from "@/components/CoreUI/BaseButton.vue";
import BaseTextarea from "@/components/CoreUI/BaseTextarea.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import ToggleButtonSimple from "@/components/CoreUI/ToggleButtonSimple.vue";
import PreviewMessages from "@/components/styleGuide/debugging/PreviewMessages.vue";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "StreamComponentInput",
    components: { CollapsableMenuItem, ToggleButtonSimple, BaseButton, BaseTextarea, PreviewMessages },
    mixins: [styleGuideMixin],
    data() {
        return {
            hovered: false,
            actions: [],
        };
    },
    computed: {
        ...mapGetters("stream", ["messages", "functions", "selectedFunction", "messagesWithContext", "showInput", "generate", "result", "model"]),
        userInputModel: {
            get() {
                return this.$store.getters["stream/userInput"];
            },
            set(value) {
                this.updateStreamProp("options.userInput", value);
            },
        },
    },
    watch: {
        generate: {
            handler(val) {
                if (val.type) this.setSettingsFromGenerate(val.type);
            },
        },
    },
    methods: {
        getActionClass(f) {
            // :class="[selectedAction && selectedAction.name === f.name ? 'bg-blue-50 text-blue-900 hover:text-blue-500' : 'bg-snow hover:bg-gray-50 text-gray-400 o-9 hover:opacity-100']"
            return this.selectedAction && this.selectedAction.name === f.name ? "bg-blue-50 text-blue-900 hover:text-blue-500" : "bg-snow hover:bg-gray-50 text-gray-400 o-9 hover:opacity-100";
        },
        toggleShowInput() {
            this.updateStreamProp("options.showInput", !this.showInput);
        },
        // callFunctionFromInput() {
        //     this.$emit("call-function", { input: this.userInputModel });
        //     this.userInputModel = "";
        // },
        async callFunctionFromInput(input, overrides) {
            if (overrides?.action) this.setAction(overrides.action);
            // return;
            let { name, type } = this.selectedAction; // get the name and type of the selected action. The prompt will be automatically added via the streamFunction(obj) method. // set the action to get_brand_voice. This sets the prompt and the function_call as well.
            this.setActionModel(name);
            let embeddings = await this.getEmbeddings(input);
            this.updateStreamProp("options.userInput", embeddings + "\n\n" + input);
            let mods = {
                model: this.model || gpt4, // this is the model that will be used to generate this.
                // model: this.model || gpt316, // this is the model that will be used to generate this.
                // model: gpt316, // this is the model that will be used to generate this.
                messages: this.streamMessages, // this is the messages that will be used to generate this.
                temperature: 0.2, // this is the temperature that will be used to generate this.
                function_call: name, // this is the function_call name that will be called to generate this. It needs to match the fucntion being called either locally or on the server.
                functions: undefined, // this is the functions object that will be used to generate this. If blank it will use the server and name to route to the right function if it exists.
                length: 4000, // the length of the message to generate
                persistChat: true, // if true, it streams the result to the messages array in the stream store. This can be used to see the streaming results in chat, but it can also be used to save the results to the database, "chat" add revisions with context, etc.
                // identifier: `result`, // this helps route the stream to the right place and find it later. Good for complex objects and nested sections. For example: 'campaignData.result.schedule_items.${campaignIndex}.campaign_items.${itemIndex}.result'. It is also possible to set a custom identifier for the stream location.
                brand: this.$route.params.id, // set the brand id from the route params but this could be set from anywhere optionally.
                input: this.userInputModel, // this is the user input that will added to messages.
                type: type, // what type of campaign is this? Currently only used for campaigns.
                storeKey: "stream", // This is the key of the store to save to. For example $store.state.styleGuide
                prop: `result`, // the prop used in the store
                localHandle: `result`, //the prop used in the local component
                save: true, // if true, use the afterCompletion function to define how to save the results.
                returnTo: "result",
            };
            if (overrides) mods = { ...mods, ...overrides };
            await this.callFunction(mods);
        },
        whileProcessing() {},
        async afterCompletion(obj) {
            let { input, save, messages, prompt, function_call, model, system, silent, result, type } = obj;
            // this.afterCompletingGeneration(obj);
            // this.hideLoader();
            let finalCampaignSaved;
            if (save) finalCampaignSaved = await this.saveToCampaigns(obj);
        },
        async saveToCampaigns(obj, proceed) {
            let {
                //
                name,
                brand = this.$route.params.id,
                result,
                itemIndex,
                identifier,
                campaignId,
                campaignIndex,
                save,
                messages,
                prompt,
                function_call,
                model,
                type,
            } = obj;
            let updatedObject = {
                result: {
                    ...result,
                    object_type: type,
                    campaignId,
                    itemIndex,
                    campaignIndex,
                    messages,
                },
                brand,
                created: new Date(),
                updated: new Date(),
                name: name || "",
                campaignId,
                campaignIndex,
                object_type: type,
                identifier,
                itemIndex,
            };
            // remove null and undefined values
            // remove null and undefined values
            updatedObject = Object.fromEntries(Object.entries(updatedObject).filter(([key, value]) => value != null));
            // updatedObject.result = Object.fromEntries(Object.entries(updatedObject.result).filter(([key, value]) => value != null));
            // navigate to the campaign
            try {
                let { id } = await saveToFirebase("campaigns", updatedObject);
                console.log("Campaign saved with ID:", id);
                if (proceed) this.$router.push({ name: "campaign", params: { cid: id } });
            } catch (error) {
                console.error("Error saving to Firebase:", error);
                // Handle the error appropriately
            }
        },
        setSettingsFromGenerate(val) {
            console.log(val);
            let functionToSelect;
            if (val === "email") this.setActionEmail();
            if (val === "page") this.setActionLandingPage();
            if (val === "presentation") this.setActionPresentation();
            this.callFunction();
        },
        createNew(url) {
            this.$emit("createNew", { url, id: null });
        },
        update() {
            this.$emit("update");
        },
        reset() {
            this.$emit("reset");
        },
        onBeforeEnter(el) {
            gsap.set(el, { alpha: 0.0, y: 20 });
        },
        onEnter(el, done) {
            const delay = el.dataset.index * 0.06;
            gsap.to(el, {
                alpha: 1,
                y: 0,
                delay,
                duration: 0.9,
                ease: "back.inOut",
                onComplete: done,
            });
        },
        onLeave(el, done) {
            gsap.to(el, {
                alpha: 0,
                y: 10,
                duration: 0.5,
                ease: "back.inOut",
                onComplete: done,
            });
        },
        onMouseOver(el) {
            gsap.to(el.target, { scale: 1.1 });
        },
        onMouseOut(el) {
            gsap.to(el.target, { scale: 1 });
        },
    },
};
</script>
<style lang="scss" scoped>
.gradient-left-white {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%);
}

strong {
    font-weight: bold;
}
</style>
