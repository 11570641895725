<template>
    <div v-if="!(m.hide && !$store.state.chat.showDebugger)">
        <ChatMessageWrapper class="x" :m="m" :index="index" @remove-message="removeMessage">
            <div>
                <div>
                    <!--                    <ChatWebResults class="x" v-if="showWebSearch" :key="`web-${index}`" :message="m" :index="index">-->
                    <!--                        <ChatMarkdown :content="m.content.trim()" :message="m" :class="{ 'x block pr-3': mobile, 'mw-650 mb-5': !mobile }"></ChatMarkdown>-->
                    <!--                    </ChatWebResults>-->
                    <div class="prose" v-if="m.role === 'function' && m.command?.arguments?.speak">{{ m.command.arguments.speak }}</div>
                    <div class="whitespace-pre-wrap" v-if="m?.command?.name === 'web_search'">{{ m.content }}</div>
                    <div class="p-5 block" v-else-if="m?.command?.content">
                        <base-accordion class="p-5">
                            <template #title>Function details</template>
                            <div class="whitespace-pre-wrap">
                                <div>{{ m.command.content }}</div>
                            </div>
                        </base-accordion>
                    </div>
                    <!--                    <ChatMarkdown v-else :content="m.content.trim()" :message="m" :class="{ 'x block pr-3': mobile, 'mw-650': !mobile }" />-->
                    <TaskManager class="br-10 x mw-350 border-light my-4 bg-gray-200/20 p-3 dark:bg-base-700" v-if="m.command && m.command.name === 'task_manager'"></TaskManager>
                    <AgentManager :key="index" :message="m" />
                    <slot></slot>
                    <template v-if="m.command">
                        <template v-if="m.commands && m.commands.length > 0">
                            <FunctionPill v-for="command in m.commands" class="" :m="m" @click.prevent="" :command="command" :loading="m.role === 'function'" />
                        </template>
                        <FunctionPill class="" :m="m" v-else-if="m.command" @click.prevent="" :command="m.command" :loading="m.role === 'function'" />
                    </template>
                    <h1 v-if="m.typing">TYPING!</h1>
                </div>
            </div>
        </ChatMessageWrapper>
    </div>
</template>
<script>
import ChatImage from "@/components/chat/specialMessages/ChatImage.vue";
import MessageEditButtons from "@/components/chat/MessageEditButtons.vue";
import ChatMessageEdit from "@/components/chat/messages/ChatMessageEdit.vue";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import ChatCommandObject from "@/components/chat/specialMessages/ChatCommandObject.vue";
import ChatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import ListenButton from "@/components/chat/audio/ListenButton.vue";
import ChatAvatar from "@/components/CoreUI/ChatAvatar.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import ChatMapCard from "@/components/chat/specialMessages/ChatMapCard.vue";
import ChatWebResults from "@/components/chat/specialMessages/Search/ChatWebResults.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import ChatMapKit from "@/components/chat/specialMessages/Search/ChatMapKit.vue";
import FunctionPill from "@/components/chat/messages/FunctionPill.vue";
import TaskManager from "@/views/Chat/TaskManager.vue";
import AgentManager from "@/views/Chat/AgentManager.vue";
import ChatMessageWrapper from "@/components/chat/messages/ChatMessageWrapper.vue";
import BaseAccordion from "@/components/CoreUI/BaseAccordion.vue";

export default {
    name: "Assistant",
    emits: ["askQuestion"],
    components: {
        BaseAccordion,
        ChatMessageWrapper,
        AgentManager,
        TaskManager,
        FunctionPill,
        ChatMapKit,
        LoadingSpinner,
        ChatWebResults,
        ChatMapCard,
        DebugObject,
        ChatAvatar,
        ListenButton,
        ChatCommandObject,
        ChatMarkdown,
        ChatMessageEdit,
        MessageEditButtons,
        ChatImage,
    },
    mixins: [ChatCommands],
    emits: ["image-loaded"],
    props: {
        // isCode: Function,
        // isCSS: Function,
        m: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            editMode: false,
            hovered: false,
        };
    },
    mounted() {},
    computed: {
        showWebSearch() {
            return this.m?.command?.name === "web_search" && this.m.role === "assistant";
        },
        isNotLastMessage() {
            return this.index < this.$store.state.chat.messages.length - 1;
        },
        isCodeMessage() {
            return this.m.kind === "code";
        },
        isCommandObjectMessage() {
            return this.isCommandObject(this.m.content);
        },
        isImageMessage() {
            return this.isImage(this.m);
        },

        user() {
            return this.$store.state.user.user;
        },
        cleanMessage() {
            //remove linebreaks from the beginning and end of the message
            return this.m.content.replace(/^\n+|\n+$/g, "");
        },
        removeNewlinesAtStart() {
            return this.m.content;
            // return this.m.content.replace(/^[\n]+/, "\n");
        },

        isCodeNew() {
            if (this.m.kind && this.m.kind === "code") {
                return true;
            }
            return false;
            // return content => {
            //     return content.startsWith("```");
            // };
        },
        // isCSS() {
        //     return content => {
        //         return content.startsWith("```css");
        //     };
        // },
    },
    methods: {
        askQuestion(question) {
            this.$emit("askQuestion", question);
        },
        cancelEdit() {
            this.editMode = false;
        },
        editMessage() {
            console.error("startEditing");
            this.editMode = true;
        },
        saveEdit() {
            console.error("saveEdit");
            this.editMode = false;
            this.$emit("editMessage", this.m, this.index);
        },
        removeMessage() {
            this.$emit("removeMessage", this.m, this.index);
        },
        imageLoaded(index, images) {
            console.error("imageLoaded on ChatAssistantMessage", index, images);
            this.$emit("imageLoaded", index, images);
        },
    },
};
</script>
<style lang="scss" scoped>
h1,
h2,
h3 {
    font-size: 10px;
}
#map {
    width: 100%;
    height: 400px;
}
</style>
