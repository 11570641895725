<template>
    <div class="f jcb aic editor-section">
        <span class="fwb editor-section-title">Inset Image</span>
        <div class="f">
            <div class="p-2 border-light width-30 height-30 f aic jcc" :class="{ 'opacity-100': s.value === imageInset, 'opacity-50': s.value !== imageInset }" v-for="s in options" :key="s.icon" @click="updateSection('imageInset', s.value)">
                <i :class="[`${s.icon} f-17 text-gray-500`, { 'text-gray-900 far': s.value === imageInset }]"></i>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "ImageInsetEditor",
    components: { BaseButton },
    props: {
        imageInset: {
            type: Boolean,
            default: false,
        },
        identifier: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            options: [
                { icon: "far fa-expand fa-solid fa-square", value: false },
                { icon: "far fa-arrows-maximize", value: true },
            ],
        };
    },
    computed: {
        insetIconClass() {
            return this.isInset ? "far fa-arrows-maximize opacity-100" : "far fa-expand fa-solid fa-square opacity-100";
        },
    },
    methods: {
        updateSection(prop, value) {
            console.log("updateSection", prop, value);
            this.$emit("update-section", prop, value);
            // this.$store.dispatch("stream/updateStreamProp", { keyPath: prop, value });
        },
    },
};
</script>
