<template>
    <div class="px-3 py-2 border border-gray-500 border-opacity-20" v-if="items?.length > 0">
        <h4 class="!f-15 fwb mb-2 !font-bold" style="font-size: 25px !important">{{ title }}</h4>
        <ul class="list-disc pl-3 f-13 mb-3">
            <li v-for="item in items" class="line-height-normal">{{ item }}</li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "PersonaSection",
    props: {
        title: {
            type: String,
        },
        items: {
            type: Array,
        },
        sectionColor: {
            type: String,
        },
    },
};
</script>
<style lang="scss" scoped>
/* Include your styles here */
</style>
