import generateSchema from "@/ai_functions/generate_schema_from_function";
import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import { getFontEmbedCSS, toPng } from "html-to-image";
import webRequest from "@/mixins/ai/web_request";
import chroma from "chroma-js";
import { emailText } from "@/store/SectionsToStrings";
export default {
    mixins: [],
    data() {
        return {
            styleSheet: "",
        };
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        mandatoryHeader() {
            return `<!--[if !mso]><!-->
<meta content="IE=edge" http-equiv="X-UA-Compatible"/>
<!--<![endif]-->
<meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
<meta content="width=device-width, initial-scale=1" name="viewport"/>
<!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
<!--[if lte mso 11]>
        <style type="text/css" data-inliner="ignore">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
<!--[if !mso]><!--><!--<![endif]-->`;
        },
        emailHTML() {
            const backgroundColor = this.footer?.backgroundColor || "";
            let stylesheet = this.stylesheet || "";
            let colors = "";
            let colorsAlt = "";
            let color = this.footer?.color || "#000";
            let bgColor = this.footer?.backgroundColor || "#fff";
            if (color) {
                colorsAlt = `background-color:${color}; color:${bgColor}`;
                colors = `background-color:${bgColor}; color:${color};`;
            }
            let head = `<head>\n${this.mandatoryHeader}\n\t<style>
        * { box-sizing: border-box; }
        body { padding: 0px!important; margin:0px!important; font-family: helvetica, arial, sans-serif;  margin: 0; padding: 0; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
        table, td { border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0; }
        img { border: 0; line-height: 100%; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; } 
        .email-section { display: block; width: 100%; ${colors} }
        .brand_footer { display: block; margin:0 auto; width:100%; ${colors} }
        .footer_button_container{ font-size: 15px; margin-top: 20px; margin-bottom: 20px; width: 80%; margin-left: auto; margin-right: auto; }
        .email-section img { width: 100%; margin: 0; padding: 0; }
        .brand_button { margin-left:auto; text-decoration:none; margin-right:auto; margin-bottom: 20px; text-align: center; font-weight: bold; display: block; padding: 10px 20px; border-radius: 5px; width: 80%; ${colorsAlt}}
        .footer_text { text-align:center;  font-size: 13px; }
        .email_footer_container { width: 100%; padding: 20px; border-radius: 0px; letter-spacing: unset; line-height: unset; ${colors} } 
        .footer_unsubscribe_container { text-align:center; text-align: center; max-width: 500px; margin: 0px auto; }
        .footer_unsubscribe_container a { text-decoration:none; ${colorsAlt} }
        .email_container {width: 100%; max-width: 500px; margin: 0 auto; ${colors} }
        .copyright_container { text-align: center; max-width: 300px; margin: 0px auto; }
        section a { padding:0px; margin:0px; line-height:0; text-decoration:none; }
        ${stylesheet}
        
        
    </style>
    <title>${this.campaignItem?.name}</title>
</head>`;
            const htmlOpen = `
<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml">
    ${head}
        <body>`;
            const htmlClose = `\n\t</body>\n</html>`;
            const htmlWrapper = `\n\t<div class="email_container">
      ${this.sections
          .map((section, index) => {
              const image = this.emailImages?.[index] || "";
              const { pre_header, header, sub_header, body, features } = section;
              const { text: buttonText, url: buttonUrl } = section.button || {};
              const sectionText = [pre_header, sub_header, header, body, features?.map(({ title, description, icon }) => `${icon?.emoji || ""} ${title} ${description}`).join(" ")].filter(Boolean).join(" ");
              const sectionHTML = buttonUrl ? `<a href="${buttonUrl.replace(".com//", ".com/")}" class="email-section">\n\t<img src="${image}" alt="${sectionText}" />\n</a>` : `<img class="email-section" src="${image}" alt="${sectionText}" />`;
              return `\n\t<section>\n\t\t${sectionHTML}\n\t</section>\n`;
          })
          .join("")}\n\t\t${this.footerHTMLStructure}\n\t</div>`;

            return htmlOpen + htmlWrapper + htmlClose;
        },
        footerBackground() {
            return this.emailFooter.backgroundColor || "";
        },
        footerColor() {
            return this.emailFooter.color || "";
        },
        footerText() {
            return this.emailFooter.text || "";
        },
        footerLinks() {
            return this.emailFooter?.links || [];
        },
        unsubscribe() {
            return this.emailFooter?.unsubscribe || {};
        },
        footerHTMLStructure() {
            let name = this.styleGuide?.name;
            let url = this.styleGuide?.url;
            let colors = `color:${this.footerColor}!important; background-color:${this.footerBackground}!important`;
            let colorsAlt = `color:${this.footerBackground}; background-color:${this.footerColor}`;
            let copyright = `<span style="font-weight: 400; font-size: 13px;">Copyright © ${this.currentYear} • All Rights Reserved<br>${name} • ${url}</span>`;
            const links = this.footerLinks.map(link => `<a href="${link.url}" class="brand_button" style="${colorsAlt}">${link.text}</a>`).join("\n\t\t\t\t");
            let unsubscribe = `No longer want to receive these emails? {% unsubscribe %}.<br> {{organization.name}} {{organization.full_address}}`;
            const footer = `
    <div class="brand_footer" style="${colors}">
        <div class="email-footer theme1 email_footer_container" style="${colors}">
            <div class="footer_button_container">
                ${links}
            </div>
            <div class="footer_unsubscribe_container">
              <div class="footer_text">${unsubscribe}</div>
              <div class="copyright_container">${copyright}</div>
            </div>
        </div>
    </div>
  `;

            return footer;
        },
        emailFooter() {
            let footer = this.footer || {};
            let { footer_text = "", footer_links = [], unsubscribe_text = "", unsubscribe_link = "" } = footer;
            if (footer_text) footer_text = footer_text?.trim();
            if (footer_links) {
                let links = footer_links;
                if (links.length > 0) {
                    links = links.map(link => {
                        let footerLink = { ...link };
                        let { text, url } = footerLink;
                        if (text) text = text.trim();
                        if (url) url = url.trim();
                        return { text, url };
                    });
                    footer.links = links;
                }
            }
            if (unsubscribe_text) unsubscribe_text = unsubscribe_text.trim();
            return {
                text: footer_text,
                links: footer_links,
                unsubscribe: { text: unsubscribe_text, link: { text: unsubscribe_link, url: "" } },
            };
        },
    },
    methods: {
        async getComponentHtmlWithInlineStyles() {
            // Step 1: Grab the rendered HTML of the Vue component based on the ref
            const componentHtml = this.$refs?.emailFooter?.$el?.outerHTML;

            // Create a temporary element to hold the component HTML
            const tempElement = document.createElement("div");
            tempElement.innerHTML = componentHtml;

            // Step 2: Create a stylesheet based on the elements
            let stylesheet = "";
            const elements = tempElement.querySelectorAll("*");
            elements.forEach((element, index) => {
                const computedStyle = window.getComputedStyle(this.$refs?.emailFooter?.$el?.querySelector(element.tagName));
                let styles = "";

                // Include only the specified CSS properties
                const cssProperties = ["color", "background-color"];
                // scope it to a particular class
                if (element.classList.contains("brand_button") || element.classList.contains("footer_section")) {
                    cssProperties.forEach(property => {
                        const propertyValue = computedStyle.getPropertyValue(property);
                        if (propertyValue) {
                            styles += `${property}: ${propertyValue}; `;
                        }
                    });

                    // Generate a unique class name for each element
                    let className = `element-${index}`;
                    if (element.tagName === "A") className = `brand_button`;
                    else if (element.classList.contains("footer_section")) className = "brand_footer";
                    element.setAttribute("class", className);

                    // Append the styles to the stylesheet
                    stylesheet += `.${className} { ${styles} }\n`;
                }
            });
            // Step 3: Combine the HTML and stylesheet
            this.stylesheet = stylesheet;
            // this.footerTemplate = tempElement.innerHTML;
            return;
        },

        showSchemaForEmail(schema) {
            schema = create_landing_page;
            if (schema) {
                console.log("schema", schema);
                let result = generateSchema(schema);
                console.log("result", result);
                return result;
            } else {
                console.log("No schema found");
                return "";
            }
        },

        async screenshotAllSections() {
            if (!this.$refs.sections) return;
            this.updateDialog("gatheringImagesAndFonts");
            const fontEmbedCSS = await getFontEmbedCSS(this.$refs.sections);
            this.updateDialog("gatheringImagesAndFonts");
            let sectionsCompleted = 1;
            const sectionPromises = this.sections.map(async (section, index) => {
                const sectionRef = this.$refs[`section-${index}`];
                if (!sectionRef || sectionRef.length === 0) {
                    console.error(`Section component not found for index ${index}`);
                    return null;
                }
                const sectionComponent = sectionRef;
                const sectionElement = sectionComponent?.[0]?.$refs["email-section"];

                if (!sectionElement) {
                    this.updateDialog("sectionNotFound", index);
                    console.error(`Section element not found for index ${index}`);
                    return null;
                }

                // this.updateDialog("sectionUpload", index);

                const sectionImageUrl = await this.captureAndUploadSection(sectionComponent, sectionElement, `section-${index}-image`, fontEmbedCSS, index)
                    .then(url => {
                        sectionsCompleted += 1;
                        // this.updateDialog("sectionUpload", index);
                        this.updateDialog("sectionsProgress", sectionsCompleted);
                        return url;
                    })
                    .catch(error => {
                        console.error("Error in screenshotAllSections:", error);
                        return null;
                    });
                return sectionImageUrl;
            });
            const urls = await Promise.all(sectionPromises);
            this.updateDialog("allSectionsCaptured");
            this.emailImages = urls.filter(url => url !== null);
            console.log(this.emailImages);
            return this.emailImages;
        },
        async upload(blob, imageName = null, imageType = null) {
            try {
                // Setup Blob to FormData
                let formData = new FormData();
                formData.append("image", blob, imageName); //adding blob directly
                formData.append("caption", false); //adding blob directly
                formData.append("upload", true); //adding blob directly
                const response = await fetch(endpointURL("uploadImage"), { method: "POST", body: formData });
                if (!response.ok) {
                    const message = `An error has occurred: ${response.status}`;
                    throw new Error(message);
                }
                const result = await response.json();
                console.log("Uploaded image", result);
                let { url } = result;
                return url;
            } catch (error) {
                console.error("Error:", error);
            }
        },
        async captureAndUploadSection(sectionComponent, sectionElement, imageName, fontEmbedCSS, sectionIndex) {
            try {
                const images = sectionElement.querySelectorAll("img");
                let originalImageSources = new Map();

                let index = 1;
                for (let img of images) {
                    console.log(`Image ${index}`, { url: img.src });
                    originalImageSources.set(img, img.src);
                    if (!img.src.startsWith("blob:")) {
                        let imgURL = img.src || "";
                        imgURL.replace("Original", "public");
                        const response = await fetch(imgURL);
                        const blob = await response.blob();
                        const blobURL = URL.createObjectURL(blob);
                        img.src = blobURL;
                        await new Promise(resolve => (img.onload = resolve));
                    }
                }
                const dataUrl = await toPng(sectionElement, { pixelRatio: 2, fontEmbedCSS });
                const response = await fetch(dataUrl);
                const blob = await response.blob();

                for (let [img, originalSrc] of originalImageSources) {
                    img.src = originalSrc;
                }
                this.updateDialog("imageUpload", index);
                // this.updateDialog("imageUpload", index++);
                let url = await this.upload(blob, imageName)
                    .then(url => {
                        if (url) {
                            this.updateDialog("imageUpload", index++);
                            return url.replace("Original", "public");
                        }
                        return null;
                    })
                    .catch(error => {
                        console.error("Error in captureAndUploadSection:", error);
                        this.updateDialog("sectionPlayingHideAndSeek", sectionIndex);
                        return null;
                    });
                return url;
            } catch (error) {
                console.error("Error in captureAndUploadSection:", error);
                this.updateDialog("sectionPlayingHideAndSeek", sectionIndex);
                return null;
            }
        },

        async exportEmailTemplate(skipCapture = false) {
            this.resetDialog();
            await this.randomTone();
            this.updateDialog("gatheringImagesAndFonts");
            try {
                let urls = [];
                if (!skipCapture) {
                    this.dialog = true;
                    this.loading = true;
                    urls = await this.screenshotAllSections();
                    this.emailImages = urls;
                }
                await this.getComponentHtmlWithInlineStyles();
                let htmlString = this.emailHTML;
                let name = `Email Template - ${new Date().toISOString()}`;
                if (this.campaignItem?.name) name = this.campaignItem.name;
                let text = emailText(this.sections);
                let requestObject = {
                    html_string: htmlString, //
                    name: name,
                    text: text,
                    brand: this.styleGuide?.id,
                    user: this.user,
                    key: this.styleGuide?.klaviyoAPI,
                    campaign_id: this.campaignItem?.id,
                    campaign: this.campaignItem,
                };
                this.updateDialog("creatingEmailTemplate");
                try {
                    // let response = { data: { id: 1 } };
                    let response = await webRequest("email/createTemplate", requestObject);
                    response = await response.json();
                    console.log("Template created successfully:", response);
                    this.updateDialog("emailTemplateComplete");
                    this.dialogContent.buttonLink = response.id;
                    this.loading = false;
                } catch (error) {
                    console.error("Error in exportEmailTemplate:", error);
                    this.updateDialog("errorExporting");
                    this.loading = false;
                }
            } catch (error) {
                console.error("Error in exportEmailTemplate:", error);
                this.updateDialog("errorExporting");
                this.loading = false;
            }
        },
        hasImageIndex(section) {
            return section?.imageIndex !== null;
        },
    },
};
