import gptError from "@/mixins/ai/gpt_error";
import json_helpers from "@/mixins/ai/json_helpers";

const { validateJSON } = json_helpers;

// async function processContentFull(response, whileProcessing, model, onComplete) {
//     console.log("%cProcessing stream", cht);
//     let reader;
//     try {
//         reader = response.body.getReader();
//     } catch (e) {
//         console.log("Error reading stream");
//     }
//     let messageObject = { content: null, function_call: null, role: null };
//     window.messageObject = messageObject;
//     const utf8Decoder = new TextDecoder("utf-8");
//     let msg;
//     let processingLine,
//         messageContent,
//         functionContent,
//         functionName,
//         functionArgs = "";
//     window.streamingText = messageContent;
//
//     messageObject.role = "";
//     messageObject.finish_reason = "";
//     let functionCall;
//     let loggedFingerprint = false;
//     try {
//         // Loop through the response and process it chunk by chunk
//         while (true) {
//             const { value: chunk, done } = await reader.read();
//             if (done) break;
//
//             // Decode and process the chunk, and check for a line break
//             const decodedChunk = utf8Decoder.decode(chunk);
//
//             processingLine += decodedChunk;
//
//             const lineBreakIndex = processingLine.lastIndexOf("\n");
//             let shouldAddLine = lineBreakIndex >= 0;
//             if (shouldAddLine) {
//                 // If a line break is found, split the string into separate lines up to the last line break
//                 const lines = processingLine.slice(0, lineBreakIndex).split("\n");
//
//                 // Remove the processed lines from processingLine, leaving only unprocessed content
//                 processingLine = processingLine.slice(lineBreakIndex + 1);
//                 // Iterate through the lines array
//                 for (const line of lines) {
//                     // Check if the line starts with 'data: ', which indicates it is a relevant piece of data
//                     if (line.trim().startsWith("data:")) {
//                         // Remove the 'data: ' prefix to obtain a usable message
//                         const message = line.replace(/^data: /, "");
//
//                         let json;
//                         try {
//                             json = JSON.parse(message);
//                             if (json.finish_reason) console.log("%cFINISH REASON", json.finish_reason, fail);
//                         } catch (e) {}
//
//                         if (message === "[DONE]" || json?.done) break;
//
//                         let token,
//                             content_token,
//                             content,
//                             function_token,
//                             function_arguments,
//                             function_call,
//                             function_name,
//                             function_content,
//                             finish_reason,
//                             role = "";
//
//                         if (json?.choices?.[0]) {
//                             let chunk = json.choices[0];
//                             msg = json?.choices?.[0]?.delta;
//                             if (json?.system_fingerprint && !loggedFingerprint) console.log("%cSeed", purple, json.system_fingerprint);
//                             loggedFingerprint = true;
//
//                             let fnc = msg.function_call || msg?.tool_calls?.[0]?.function;
//                             // let fnc = msg.function_call || msg.tool_calls[0].function;
//                             if (fnc) function_token = fnc;
//                             if (msg?.content) token = msg.content;
//                             if (msg?.content) content += token;
//                             if (msg?.content) content_token = msg.content;
//                             if (msg?.role) role = msg.role;
//                             if (fnc) function_call = fnc;
//                             if (fnc?.name) function_name = fnc.name;
//                             if (fnc?.content) function_content = fnc.content;
//                             if (fnc?.["arguments"]) function_arguments = fnc["arguments"];
//                             if (chunk?.finish_reason) finish_reason = chunk.finish_reason;
//                             if (content_token) {
//                                 if (!messageObject.content) messageObject.content = "";
//                                 else messageObject.content += content_token;
//                                 whileProcessing(content_token, "");
//                                 token = content_token;
//                                 messageContent += content_token;
//                             }
//                             if (role) {
//                                 if (!messageObject.role) messageObject.role = role;
//                                 else messageObject.role += role;
//                             }
//                             if (function_call) {
//                                 if (!messageObject.function_call) messageObject.function_call = function_call;
//                                 // messageContent += function_call;
//                             }
//                             if (function_name?.length > 0) {
//                                 messageObject.function_call.name = function_name;
//                                 messageContent += function_name;
//                                 functionName = fnc.name;
//                             }
//                             if (function_content) {
//                                 if (!messageObject.function_call.content) messageObject.function_call.content = "";
//                                 else messageObject.function_call.content += function_content;
//                             }
//                             if (function_arguments && function_arguments.length > 0) {
//                                 if (!messageObject.function_call.arguments) messageObject.function_call.arguments = function_arguments;
//                                 else messageObject.function_call.arguments += function_arguments;
//                                 let streamJSON = validateJSON(messageObject.function_call.arguments);
//                                 if (streamJSON) whileProcessing("", messageObject, streamJSON, messageObject);
//                                 else whileProcessing("", messageObject, streamJSON, messageObject);
//
//                                 // this.checkStreamingJSON(window.messageObject.function_call.arguments);
//                             }
//
//                             if (finish_reason && typeof finish_reason === "string") {
//                                 messageObject.finish_reason = finish_reason;
//                                 messageObject.content = messageContent;
//                                 // messageContent += finish_reason;
//                                 onComplete({ messageObject, finish_reason });
//                             }
//                         }
//                         // If the token has content, append it to the messageContent variable
//                         if (token) window.streamingText = messageContent;
//                         // if (function_arguments) console.log(function_arguments);
//                         if (functionName) {
//                             let args = {};
//                             try {
//                                 args = JSON.parse(functionArgs);
//                                 let object = { name: functionName, content: messageContent, arguments: args };
//                                 functionObject = object;
//                             } catch (e) {}
//                         }
//                     }
//                 }
//             }
//         }
//     } catch (err) {
//         gptError(err);
//         console.log(err);
//     }
//
//     let functionObject = { name: functionName, arguments: functionArgs, content: functionContent };
//
//     return { messageContent: messageContent, functionObject: functionObject, role: messageObject.role || "" };
// }
async function processContentFull(response, whileProcessing, model, onComplete) {
    console.log("%cProcessing stream", cht);
    let reader;
    try {
        reader = response.body.getReader();
    } catch (e) {
        console.log("Error reading stream");
    }
    let messageObject = {
        content: null,
        function_call: null,
        role: null,
    };
    window.messageObject = messageObject;
    const utf8Decoder = new TextDecoder("utf-8");
    let processingLine = "";
    let messageContent = "";
    let functionContent = "";
    let functionName = "";
    let functionArgs = "";
    window.streamingText = messageContent;
    let msg;

    messageObject.role = "";
    messageObject.finish_reason = "";
    let functionCall;
    let loggedFingerprint = false;
    try {
        // Loop through the response and process it chunk by chunk
        while (true) {
            const { value: chunk, done } = await reader.read();
            if (done) break;

            // Decode and process the chunk, and check for a line break
            const decodedChunk = utf8Decoder.decode(chunk);

            processingLine += decodedChunk;

            const lineBreakIndex = processingLine.lastIndexOf("\n");
            let shouldAddLine = lineBreakIndex >= 0;
            if (shouldAddLine) {
                // If a line break is found, split the string into separate lines up to the last line break
                const lines = processingLine.slice(0, lineBreakIndex).split("\n");

                // Remove the processed lines from processingLine, leaving only unprocessed content
                processingLine = processingLine.slice(lineBreakIndex + 1);
                // Iterate through the lines array
                for (const line of lines) {
                    // Check if the line starts with 'data: ', which indicates it is a relevant piece of data
                    if (line.trim().startsWith("data:")) {
                        // Remove the 'data: ' prefix to obtain a usable message
                        const message = line.replace(/^data: /, "");

                        let json;
                        try {
                            json = JSON.parse(message);

                            if (json.finish_reason) console.log("%cFINISH REASON", json.finish_reason, fail);
                        } catch (e) {
                            // console.log("JSON PARSE ERROR", e);
                            // console.log(JSON.stringify(message));
                        }

                        if (message === "[DONE]" || json?.done) break;

                        let token = "";
                        let content_token = "";
                        let content = "";
                        let function_token = "";
                        let function_arguments = "";
                        let function_call = "";
                        let function_name = "";
                        let function_content = "";
                        let finish_reason = "";
                        let role = "";
                        if (json?.choices?.length > 0 && json?.choices[0]?.delta) {
                            let chunk = json.choices[0];
                            msg = json.choices[0].delta;
                            if (msg?.function_call) function_token = msg?.function_call || msg?.tool_calls?.[0]?.function;
                            if (json?.system_fingerprint && !loggedFingerprint) console.log("%cSeed", purple, json.system_fingerprint);
                            loggedFingerprint = true;

                            let fnc = msg?.function_call || msg?.tool_calls?.[0]?.function;
                            // let fnc = msg.function_call || msg.tool_calls[0].function;
                            if (fnc) function_token = fnc;
                            if (msg?.content) token = msg.content;
                            if (msg?.content) content += token;
                            if (msg?.content) content_token = msg.content;
                            if (msg?.role) role = msg.role;
                            if (fnc) function_call = fnc;
                            if (fnc?.name) function_name = fnc.name;
                            if (fnc?.content) function_content = fnc.content;
                            if (fnc?.arguments) function_arguments = fnc.arguments;
                            if (chunk?.finish_reason) finish_reason = chunk.finish_reason;
                            if (content_token) {
                                if (!messageObject.content) messageObject.content = "";
                                else messageObject.content += content_token;
                                whileProcessing(content_token, "");
                                token = content_token;
                                messageContent += content_token;
                            }
                            if (role) {
                                if (!messageObject.role) messageObject.role = role;
                                else messageObject.role += role;
                            }
                            if (function_call) {
                                if (!messageObject.function_call) messageObject.function_call = function_call;
                                // messageContent += function_call;
                            }
                            if (function_name?.length > 0) {
                                messageObject.function_call.name = function_name;
                                messageContent += function_name;
                                functionName = fnc.name;
                            }
                            if (function_content) {
                                if (!messageObject.function_call.content) messageObject.function_call.content = "";
                                else messageObject.function_call.content += function_content;
                            }
                            if (function_arguments && function_arguments.length > 0) {
                                if (!messageObject.function_call.arguments) messageObject.function_call.arguments = function_arguments;
                                else messageObject.function_call.arguments += function_arguments;
                                let streamJSON = validateJSON(messageObject.function_call.arguments);
                                if (streamJSON) {
                                    whileProcessing("", messageObject, streamJSON, messageObject);
                                } else {
                                    whileProcessing("", messageObject, streamJSON, messageObject);
                                }

                                // this.checkStreamingJSON(window.messageObject.function_call.arguments);
                            }

                            if (finish_reason && typeof finish_reason === "string") {
                                messageObject.finish_reason = finish_reason;
                                messageObject.content = messageContent;
                                // messageContent += finish_reason;
                                onComplete({ messageObject, finish_reason });
                            }
                        }
                        // If the token has content, append it to the messageContent variable
                        if (token) window.streamingText = messageContent;
                        // if (function_arguments) console.log(function_arguments);
                        if (functionName) {
                            let args = {};
                            try {
                                args = JSON.parse(functionArgs);
                                let object = {
                                    name: functionName,
                                    content: messageContent,
                                    arguments: args,
                                };
                                functionObject = object;
                            } catch (e) {}
                        }
                    }
                }
            }
        }
    } catch (err) {
        gptError(err);
        console.log(err);
    }
    let functionObject = { name: functionName, arguments: functionArgs, content: functionContent };

    return { messageContent: messageContent, functionObject: functionObject, role: messageObject.role || "" };
}
// async function processContentFull(response, whileProcessing, onComplete) {
//     console.log("%cProcessing stream", "color: teal");
//
//     if (!response.body) {
//         console.log("Error: Response body is not available");
//         return;
//     }
//
//     const reader = response.body.getReader();
//     const utf8Decoder = new TextDecoder("utf-8");
//     let processingLine = "";
//     let messageContent = "";
//     let functionObject = { name: "", arguments: "", content: "" };
//     let messageObject = { content: "", function_call: functionObject, role: "", finish_reason: "" };
//     let loggedFingerprint = false;
//
//     try {
//         while (true) {
//             const { value: chunk, done } = await reader.read();
//             if (done) break;
//
//             const decodedChunk = utf8Decoder.decode(chunk, { stream: true });
//             processingLine += decodedChunk;
//             const lineBreakIndex = processingLine.lastIndexOf("\n");
//
//             if (lineBreakIndex >= 0) {
//                 const lines = processingLine.substring(0, lineBreakIndex).split("\n");
//                 console.log("lines", lines);
//                 processingLine = processingLine.substring(lineBreakIndex + 1);
//
//                 lines.forEach(line => {
//                     if (line.trim().startsWith("data:")) {
//                         try {
//                             processLine(line, messageObject, whileProcessing, onComplete);
//                         } catch (e) {}
//                     }
//                 });
//             }
//         }
//     } catch (err) {
//         console.error("Error processing stream:", err);
//     }
//
//     return {
//         messageContent: messageObject.content,
//         functionObject: messageObject.function_call,
//         role: messageObject.role,
//     };
// }
//
// function processLine(line, messageObject, whileProcessing, onComplete) {
//     let message, json;
//     if (typeof line === "string") message = line.replace("data:", "");
//     // if (typeof line === "string") message = line?.replace(/^data: /, "");
//     else message = line;
//
//     json = validateJSON(message);
//
//     if (line) console.log("chunk", json);
//
//     if (json?.choices?.[0]) {
//         let delta = json?.choices?.[0]?.delta;
//         let chunk = json?.choices?.[0];
//         let { finish_reason } = chunk;
//         if (finish_reason) console.log("finish_reason", finish_reason);
//
//         if (delta) {
//             let { content, role, function_call, tool_calls } = delta;
//             // if (content) console.log("Content", content);
//             // if (role) console.log("Role", role);
//             // if (function_call) console.log("Function Call", function_call);
//             // if (tool_calls) console.log("Tool Calls", tool_calls);
//         }
//         if (finish_reason) {
//             console.log("%cFINISH REASON", "color: red", finish_reason);
//             onComplete({ messageObject, finish_reason: finish_reason });
//         } else {
//             processChunk(chunk, messageObject, whileProcessing);
//         }
//     }
// }
//
// function processChunk(chunk, messageObject, whileProcessing) {
//     let { content, role, function_call, tool_calls } = chunk?.delta;
//     let existingFunction;
//     if (content) {
//         messageObject.content += content;
//         whileProcessing(content);
//     }
//
//     if (role) messageObject.role = role;
//
//     if (function_call || tool_calls) {
//         if (!function_call) function_call = tool_calls[0].function;
//         let { name, arguments: args } = function_call;
//         if (!messageObject.function_call) messageObject.function_call = {};
//         if (name && !messageObject.function_call.name) messageObject.function_call.name = name;
//         if (args) {
//             if (!messageObject.function_call["arguments"]) messageObject.function_call["arguments"] = "";
//             messageObject.function_call["arguments"] += args;
//         }
//     }
//     if (messageObject) {
//         let { content, role, tool_calls } = messageObject;
//         if (content) console.log("Content", content);
//         if (role) console.log("Role", role);
//         if (function_call) console.log("Function Call", function_call);
//     }
//     console.log("messageObject", messageObject);
//     whileProcessing("", messageObject);
// }

export default processContentFull;
