export default {
    data() {
        return {
            keyOrder: ["organic", "news", "answer_box", "local_results", "top_sights", "videos", "images", "shopping_results", "product_result", "showtimes", "immersive_products", "related_searches", "recipes_results", "knowledge_graph", "inline_people_also_search_for", "questions"],
            excludedKeys: ["search_information", "search_metadata", "search_parameters"],
        };
    },
    computed: {
        extractedSections() {
            let extractedSections = "";
            const entries = Object.entries(this.filteredResults);
            entries.forEach(([key, section], index) => {
                extractedSections += `${this.extractedSectionString(key, section)}\n\n\n\n`;
            });
            extractedSections += "\n\nCombine these into one summary.";
            return extractedSections;
        },
        extractedURLs() {
            let result = "";
            const keysToInclude = ["url", "link"];
            const extractValues = (obj, separator = "\n") => {
                for (let key in obj) {
                    if (typeof obj[key] === "object" && obj[key] !== null) {
                        // result += key + separator;
                        // If the value is an object, recursively call the function
                        extractValues(obj[key]);
                    } else if (keysToInclude.includes(key)) {
                        // result += key + separator;
                        // If the key is one of the desired keys, append the value to the extractedString string
                        let url = obj[key];
                        if (!result.includes(key)) {
                            if (!url.includes("google.com")) {
                                result += url + separator;
                            }
                        }
                    }
                }
            };
            try {
                if (this.results) {
                    extractValues(this.results);
                    // Remove the trailing separator
                    let sources = result.split("\n");
                    sources = sources.filter(source => source !== "");
                    return sources;
                }
            } catch (e) {
                console.error(e);
            }
        },
        extractedUniqueURLS() {
            let result = "";
            const keysToInclude = ["url", "link"];
            const extractValues = (obj, separator = "\n") => {
                for (let key in obj) {
                    if (typeof obj[key] === "object" && obj[key] !== null) {
                        // result += key + separator;
                        // If the value is an object, recursively call the function
                        extractValues(obj[key]);
                    } else if (keysToInclude.includes(key)) {
                        // result += key + separator;
                        // If the key is one of the desired keys, append the value to the extractedString string
                        let url = obj[key];
                        try {
                            let string = new URL(url);
                            let checkforRepeatingURL = string.hostname;
                            if (!result.includes(checkforRepeatingURL) && !string.includes("google.com")) {
                                result += string + separator;
                            }
                        } catch (e) {
                            if (!result.includes(key)) {
                                if (!url.includes("google.com")) {
                                    result += url + separator;
                                }
                            }
                        }
                    }
                }
            };
            try {
                if (this.results) {
                    extractValues(this.results);
                    // Remove the trailing separator
                    let sources = result.split("\n");
                    sources = sources.filter(source => source !== "");
                    return sources;
                }
            } catch (e) {
                console.error(e);
            }
        },
        sectionSummaries() {
            this.results.summary = this.message.results.summary;
            let summaries = this.results.summary;
            let string = "";
            for (let key in summaries) {
                if (summaries[key].summary !== "undefined") {
                    string += `${summaries[key].summary}\n\n`;
                }
            }
            return string;
        },
        extractedString() {
            let result = "";
            const keysToInclude = ["snippet", "title", "name", "list", "text", "date", "source", "address", "query", "question", "questions", "keywords"];
            const extractValues = (obj, separator = "\n") => {
                for (let key in obj) {
                    if (typeof obj[key] === "object" && obj[key] !== null) {
                        extractValues(obj[key]);
                    } else if (keysToInclude.includes(key)) {
                        result += obj[key] + separator;
                    }
                }
            };
            try {
                if (this.results && this.results.data) {
                    extractValues(this.results.data);
                    // Remove the trailing separator
                    result = result.slice(0, -2);
                    return result;
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
    methods: {
        async extractedSectionsMethod(data) {
            let extractedSections = "";
            const keysOrder = this.keyOrder; // add the keys that you know here, in the order you want

            keysOrder.forEach(key => {
                const section = data[key];
                if (section && key !== "search_information" && key !== "search_metadata" && key !== "search_parameters") {
                    extractedSections += `${this.extractedSectionString(key, section)}\n\n`;
                }
            });

            // handle unknown keys
            const unknownKeys = Object.keys(data).filter(k => !keysOrder.includes(k) && k !== "search_information" && k !== "search_metadata" && k !== "search_parameters");
            unknownKeys.forEach(key => {
                const section = data[key];
                extractedSections += `${this.extractedSectionString(key, section)}\n\n`;
            });

            return extractedSections;
        },
        handlePlaces(data) {
            let result = "";
            // let result = "## Local Results:\n---\n";
            data.forEach(place => {
                result += place.title ? `${place.title}\n` : "";
                result += place.name ? `${place.name}\n` : "";
                result += place.extensions ? `${place.extensions.join(", ")}\n` : "";
                result += place.description ? `"${place.description}"\n` : "";
                result += place.address ? `Address: ${place.address}\n` : "";
                result += place.hours ? `Hours: ${place.hours}\n` : "";
                result += place.rating ? `Rating: ${place.rating}${place.reviews ? ` • ${place.reviews} Reviews` : ""}\n` : "";
                result += place.price ? `Price: ${place.price}\n` : "";
                result += "----\n";
            });
            // result += JSON.stringify(data, null, 2);
            return result;
        },
        handleResult(data) {
            let result = "";
            // let result = "## Web results:\n---\n";
            data.forEach(r => {
                result += r.title ? `From ${r.source}:\n${r.title}\n` : "";
                result += r.snippet ? `${r.snippet}\n` : "";
                result += r.rich_snippet_list ? `- ${r.rich_snippet_list.join("\n- ")}\n` : "";

                if (r.related_questions) {
                    result += "---\n\nRelated Questions:\n";
                    r.related_questions.forEach(q => {
                        result += `Question: ${q.question}\n`;
                        result += q.snippet ? `Answer: ${q.snippet}\n` : "";

                        if (q.snippet_links) {
                            result += "Answer:\n";
                            q.snippet_links.forEach(l => {
                                result += `- ${l.text}\n`;
                            });
                        }
                        result += "---\n";
                    });
                }

                result += r.date ? `${r.date}\n` : "";
                result += r.source_name ? `${r.source_name}\n` : "";
                result += r.keywords ? `Keywords: ${r.keywords.join(", ")}\n` : "";
                // result += r.link ? `${r.link}\n` : "";
                result += "----\n";
                // result += JSON.stringify(r, null, 2);
            });
            return result;
        },
        handleRelatedSearches(data) {
            let result = "RELATED SEARCHES:\n";
            data.forEach(r => {
                result += r.query ? `- ${r.query}\n` : "";
                // result += r.link ? `${r.link}\n` : "";
            });
            // result += JSON.stringify(data, null, 2);
            return result;
        },
        handleVideo(data) {
            let result = "VIDEOS:\n---\n";
            data.forEach(video => {
                if (video.platform) {
                    result += video.channel ? `From ${video.channel} on ${video.platform}:\n` : `${video.platform}\n`;
                }

                result += video.title ? `Title: ${video.title}\n` : "";
                result += video.snippet ? `${video.snippet}\n` : "";
                result += video.date ? `Posted: ${video.date}\n` : "";
                result += video.link ? `Link: ${video.link}\n` : "";

                if (video.key_moments) {
                    result += "Key Moments:\n";
                    video.key_moments.forEach(moment => {
                        result += `\t- ${moment.title} • Timestamp: ${moment.time}\n`;
                    });
                }

                result += "----\n";
            });
            // result += JSON.stringify(r, null, 2);
            return result;
        },
        handleShowtimes(data) {
            let result = "SHOWTIMES:\n---\n";
            data.forEach(showtime => {
                if (showtime.day) result += `Day: ${showtime.day}\n`;
                if (showtime.movies) {
                    showtime.movies.forEach(movie => {
                        if (movie.name) result += `${movie.name}\n`;
                        // if (movie.link) result += `Link: ${movie.link}\n`;
                        if (movie.showing && movie.showing.length > 0) {
                            result += "Showings:\n";
                            movie.showing.forEach(show => {
                                result += show.type ? `Type: ${show.type} · ` : "";
                                if (show.time && Array.isArray(show.time)) {
                                    result += `Times: ${show.time.join(", ")}\n`;
                                }
                            });
                        }
                        result += "----\n";
                    });
                }
                result += "------------\n";
            });
            return result;
        },
        handleRelatedQuestion(data) {
            let result = "";
            // let result = "## Related questions I may ask:\n";
            data.forEach(r => {
                result += r.question ? `\nQuestion: ${r.question}\n` : "";
                result += r.snippet ? `Answer: ${r.snippet}\n` : "";
                result += !r.snippet && r.title ? `Answer: ${r.title}\n` : "";
                result += r.list ? `Answer: \n- ${r.list}\n` : "";

                if (r.snippet_links) {
                    result += "Answer:\n";
                    r.snippet_links.forEach(l => {
                        result += `- ${l.text}\n`;
                    });
                }

                result += "---\n";
                // result += "\n" + JSON.stringify(r, null, 2) + "\n---\n\n";
            });
            return result;
        },
        handleProductResult(data) {
            let result = "PRODUCT:\n---\n";

            let handleProduct = product => {
                result += product.title ? `${product.title}\n` : "";
                result += product.description ? `Description: ${product.description}\n\n` : "";

                if (product.manufacturer) {
                    result += "Manufacturer:\n";
                    result += product.manufacturer.name ? `${product.manufacturer.name}\n` : "";
                    result += product.manufacturer.link ? `Link: ${product.manufacturer.link}\n` : "";
                }

                if (product.features) {
                    result += product.features.features ? `Features: ${product.features.features}\n` : "";
                    // result += product.features.sustainability ? `Sustainability: ${product.features.sustainability}\n` : "";
                    // result += product.features.flavor ? `Flavor: ${product.features.flavor}\n` : "";
                    // result += product.features.type ? `Type: ${product.features.type}\n` : "";
                }

                result += product.rating ? `Rating: ${product.rating}\n` : "";

                // if (product.thumbnails && Array.isArray(product.thumbnails)) {
                //     product.thumbnails.forEach((thumbnail, index) => {
                //         result += `Thumbnail ${index + 1}: ${thumbnail}\n`;
                //     });
                // }

                if (product.reviews_results) {
                    if (product.reviews_results.editorial_reviews && Array.isArray(product.reviews_results.editorial_reviews)) {
                        result += "\nEditorial Reviews:\n";
                        product.reviews_results.editorial_reviews.forEach((review, index) => {
                            if (review.title) result += `Title: ${review.title}\n`;
                            if (review.snippet) result += `Snippet: ${review.snippet}\n`;
                            if (review.link) result += `Link: ${review.link}\n`;
                            if (review.rating) result += `Rating: ${review.rating}\n`;
                            if (review.source) result += `Source: ${review.source}\n`;
                            if (review.date) result += `Date: ${review.date}\n`;
                            if (review.review_link) result += `Review Link: ${review.review_link}\n`;
                            // if (review.thumbnail) result += `Thumbnail: ${review.thumbnail}\n`;
                            result += `----\n`;
                        });
                    }

                    if (product.reviews_results.user_reviews) {
                        if (product.reviews_results.user_reviews.popular_questions && Array.isArray(product.reviews_results.user_reviews.popular_questions)) {
                            result += "\nPopular Questions:\n";
                            product.reviews_results.user_reviews.popular_questions.forEach((question, index) => {
                                result += ` - ${question}\n`;
                            });
                        }
                        if (product.reviews_results.user_reviews.review && Array.isArray(product.reviews_results.user_reviews.review)) {
                            result += "\nUser Reviews:\n";
                            product.reviews_results.user_reviews.review.forEach((review, index) => {
                                if (review.title) result += `**${review.title}**\n`;
                                if (review.snippet) result += `${review.snippet}\n`;
                                if (review.date) result += `Date: ${review.date}\n`;
                            });
                        }
                    }
                }

                result += "----\n";
            };

            if (Array.isArray(data)) {
                data.forEach(handleProduct);
            } else if (typeof data === "object") {
                handleProduct(data);
            }

            // result += JSON.stringify(data, null, 2);
            return result;
        },
        handleRecipes(data) {
            let result = "RECIPES:\n";

            data.forEach(recipe => {
                result += recipe.source ? `From ${recipe.source}:\n` : "";
                result += recipe.title ? `${recipe.title}\n` : "";
                result += recipe.rating ? `Rating: ${recipe.rating}` : "";
                result += recipe.reviews ? ` • ${recipe.reviews} Reviews • ` : "";
                result += recipe.total_time ? `Prep time: ${recipe.total_time} ` : "";
                result += "\n";
                if (recipe.ingredients && recipe.ingredients.length > 0) {
                    result += "Ingredients:\n";
                    recipe.ingredients.forEach(ingredient => {
                        result += `- ${ingredient}\n`;
                    });
                }
                result += recipe.link ? `${recipe.link}\n` : "";

                result += "----\n";
            });

            return result;
        },
        handlePeopleAlsoSearchFor(data) {
            let result = "RELATED:\n";
            data.forEach(r => {
                if (r.name) result += `- ${r.name}\n`;
                if (r.title) result += `${r.title}: \n`;
                if (r.items) {
                    r.items.forEach(item => {
                        if (item.name) {
                            result += ` - ${item.name}\n`;
                        }
                    });
                }
                if (r.snippet) result += `${r.snippet}\n`;
                if (r.date) result += `Posted: ${r.date}\n`;
                if (r.source_name) result += `${r.source_name}\n`;
                if (r.link) result += `Link: ${r.link}\n`;
                result += `----\n`;
            });
            // result += JSON.stringify(r, null, 2) + "\n---\n\n";

            return result;
        },
        handleImmersiveProducts(data) {
            let result = "PRODUCTS:\n";
            data.forEach(product => {
                result += product.title ? `${product.title}\n` : "";
                result += product.source ? `Source: ${product.source}\n` : "";
                result += product.price ? `Price: ${product.price}\n` : "";
                result += product.original_price ? `Original Price: ${product.original_price}\n` : "";
                result += product.extracted_price ? `Price: ${product.extracted_price}\n` : "";
                result += product.extracted_original_price ? `Price: ${product.extracted_original_price}\n` : "";
                result += product.rating ? `Rating: ${product.rating}\n` : "";
                result += product.reviews ? `Reviews: ${product.reviews}\n` : "";
                if (product.snippets && product.snippets.length > 0) {
                    product.snippets.forEach(s => {
                        if (s.source) result += `${s.source} \n`;
                        result += `- ${s.text} \n`;
                    });
                }
                if (product.extensions && product.extensions.length > 0) {
                    product.extensions.forEach(e => {
                        result += `- ${e}\n`;
                        result += `- ${e.source}\n`;
                    });
                }
                result += "----\n";
            });
            return result;
        },
        handleKnowledgeGraph(data, dontLoop) {
            // let result = "## General information:\n";
            // count the number of keys in the object
            let count = Object.keys(data).length;
            if (count === 1) return "";
            let result = "";
            result += data.title ? `${data.title}\n` : "";
            result += data.type ? `${data.type}\n` : "";
            result += data.description ? `"${data.description}"\n` : "";
            result += data.website ? `Website: ${data.website}\n` : "";
            result += data.born ? `Born: ${data.born}\n` : "";
            result += data.height ? `Height: ${data.height}\n` : "";
            result += data.spouse ? `Spouse: ${data.spouse}\n` : "";
            result += data.tv_shows ? `TV Shows: ${data.tv_shows}\n` : "";
            result += data.books ? `Books: ${data.books}\n` : "";

            if (data.source) {
                if (data.source.name) {
                    result += `Source: ${data.source.name}\n`;
                }
                if (data.source.link) {
                    result += `Link: ${data.source.link}\n`;
                }
            }
            if (data.stock_price) result += `Stock Price: ${data.stock_price}\n`;
            if (data.customer_service) result += `Customer Service: ${data.customer_service}\n`;
            if (data.products) result += `Products: ${data.products}\n`;
            if (data.ceo) result += `CEO: ${data.ceo}\n`;
            if (data.headquarters) result += `Headquarters: ${data.headquarters}\n`;
            if (data.founded) result += `Founded: ${data.founded}\n`;
            if (data.nearby_restaurants) {
                result += "Nearby Restaurants:\n";
                try {
                    result += this.handlePlaces(data.nearby_restaurants);
                } catch (e) {
                    console.log(e);
                }
            }
            if (data.founders) {
                result += "Founders:\n";
                if (Array.isArray(data.founders)) {
                    data.founders.forEach(f => {
                        result += `- ${f.name}\n`;
                    });
                } else {
                    result += `- ${data.founders}\n`;
                }
            }

            if (typeof data.movies === "array") {
                if (data.movies && data.movies.length > 0) {
                    result += "\nMovies:\n";
                    data.movies.forEach(m => {
                        if (m.name && m.extensions) result += `- ${m.name} (${m.extensions.join(", ")})\n`;
                        if (m.title) result += `- ${m.title}\n`;
                        if (m.year) result += `- ${m.year}\n`;
                    });
                }
            } else if (typeof data.movies === "object") {
                if (data.movies.name && data.movies.extensions) result += `- ${data.movies.name} (${data.movies.extensions.join(", ")})\n`;
                if (data.movies.title) result += `- ${data.movies.title}\n`;
                if (data.movies.year) result += `- ${data.movies.year}\n`;
            } else if (typeof data.movies === "string") {
                result += data.movies;
            }

            if (data.profiles && data.profiles.length > 0) {
                result += "\nProfiles:\n";
                data.profiles.forEach(p => {
                    result += `${p.name}: ${p.link}\n`;
                });
            }

            if (data.typical_salaries) {
                result += "\nTypical Salaries:\n";
                data.typical_salaries.forEach(s => {
                    if (s.job_title) result += `${s.job_title}\n`;
                    if (s.salary) result += `Salary: ${s.salary}\n`;
                    if (s.salary_range) result += `Range: ${s.salary_range}\n`;
                    if (s.source) result += `Source: ${s.source}\n`;
                    if (s.reviews_original) result += `Reviews: ${s.reviews_original}\n`;
                });
            }
            if (data.events) {
                result += "\nEvents:\n";
                data.events.forEach(e => {
                    if (e.name) result += `${e.name}\n`;
                    if (e.description) result += `Description: ${e.description}\n`;
                    if (e.extensions) result += `Extensions: ${e.extensions}\n`;
                    if (e.date) result += `Date: ${e.date}\n`;
                    if (e.time) result += `Time: ${e.time}\n`;
                    if (e.location) result += `Location: ${e.location}\n`;
                    result += "---\n";
                });
            }
            if (data.service_options) {
                result += "\nService Options:\n";
                result += `- ${data.service_options.join("\n- ")}`;
            }
            if (data.merchant_description) result += `Merchant Description: ${data.merchant_description}\n`;
            if (data.notable_moments) {
                result += "\nNotable Moments:\n";
                data.notable_moments.forEach(m => {
                    if (m.title) result += `Title: ${m.title}\n`;
                    if (m.year) result += `Year: ${m.year}\n`;
                    if (m.source) result += `Source: ${m.source}\n`;
                    if (m.summary) result += `Summary: ${m.summary}\n`;
                });
            }
            if (data.audience_reviews) {
                result += "\nAudience Reviews:\n";
                data.audience_reviews.forEach(r => {
                    if (r.title) result += `Title: ${r.title}\n`;
                    if (r.rating) result += `Rating: ${r.rating}\n`;
                    if (r.source) result += `Source: ${r.source}\n`;
                    if (r.summary) result += `Summary: ${r.summary}\n`;
                });
            }
            if (data.people_also_search_for && data.people_also_search_for.length > 0) {
                result += "\nPeople also search for:\n";
                data.people_also_search_for.forEach(p => {
                    result += `- ${p.name}\n`;
                });
            }
            if (data.tv_shows) result += `TV Shows: ${data.tv_shows}\n`;
            if (data.books && typeof data.books == "string") result += `Books: ${data.books}\n`;
            if (data.books && typeof data.books == "object") {
                result += "Books:\n";
                data.books.forEach(b => {
                    result += `- ${b.name}\n`;
                });
            }
            if (data.list) {
                let result = "\nList:\n";
                // loop through and print key:value of each item. replace _ with space
                data.list.forEach(item => {
                    for (let key in item) {
                        let value = item[key];
                        result += `${key.replace("_", " ")}: ${value}\n`;
                    }
                });
            }
            // result += this.handleAnswerBox(data);
            result += "----\n";
            if (!dontLoop) {
                result += this.handleAnswerBox(data);
            }
            // result += JSON.stringify(data, null, 2);
            return result;
        },
        handleAnswerBox(data, dontLoop) {
            let result = "LIKELY GOOD ANSWERS:\n";
            if (data.type) {
                result += `Type: ${data.type.replace("_", " ")}\n`;
            }
            if (data.title) result += `${data.title}\n`;
            if (data.list) {
                data.list.forEach(l => {
                    result += `- ${l}\n`;
                });
            }
            if (data.snippet) result += `${data.snippet}\n`;
            if (data.source_name) result += `${data.source_name}\n`;
            if (data.date) result += `Date: ${data.date}\n`;
            if (data.link) result += `Link: ${data.link}\n`;
            if (data.result) result += `Result: ${data.result}\n`;
            if (data.rich_snippet_list) {
                result += `- ${data.rich_snippet_list.join("\n- ")}\n`;
            }
            if (data.hours_list) {
                result += "\nHours:\n";
                data.hours_list.forEach(h => {
                    if (h.title) result += `${h.title}\n`;
                    if (h.items) {
                        h.items.forEach(i => {
                            result += `- ${i}\n`;
                        });
                    }
                    result += `- ${h}\n`;
                });
            }
            if (data.definitions) {
                result += "Definitions:\n";
                data.definitions.forEach(d => {
                    result += `- ${d}\n`;
                });
            }
            if (data.hotels) {
                result += "\nHotels:\n";
                data.hotels.forEach(h => {
                    if (h.title) result += `${h.title}\n`;
                    if (h.price) result += `Price: ${h.price}\n`;
                    if (h.rating) result += `Rating: ${h.rating}\n`;
                    if (h.sale) result += `Sale: ${h.sale}\n`;
                    if (h.features) {
                        result += "Features:\n";
                        h.features.forEach(f => {
                            result += `- ${f}\n`;
                        });
                    }
                    result += "---\n";
                });
            }
            if (data.flights) {
                result += "\nFlights:\n";
                data.flights.forEach((f, i) => {
                    if (f.flight_info) result += `Option ${i + 1}: ${f.flight_info.join(" • ")}\n`;
                });
            }
            if (data.word_type) result += `Word Type: ${data.word_type}\n`;

            if (data.synonyms) {
                result += "Synonyms:\n";
                data.synonyms.forEach(s => {
                    result += `- ${s}\n`;
                });
            }
            if (data.born) result += `Born: ${data.born}\n`;
            if (data.died) result += `Died: ${data.died}\n`;
            if (data.spouse) result += `Spouse: ${data.spouse}\n`;
            if (data.children) result += `Children: ${data.children}\n`;
            if (data.parents) result += `Parents: ${data.parents}\n`;
            if (data.notable_works) result += `Notable Works: ${data.notable_works}\n`;
            if (data.pronunciation) result += `Pronunciation: ${data.pronunciation}\n`;
            if (!dontLoop) {
                result += this.handleKnowledgeGraph(data, true);
            }
            return result;
        },
        handleNewsResults(data) {
            let result = "NEWS ARTICLES:\n";
            // result += "Combine and summarize this text:\n";
            data.forEach(r => {
                if (r.source) result += `From "${r.source}":\n`;
                if (r.title) result += `${r.title}\n`;
                if (r.snippet) result += `${r.snippet}\n`;
                if (r.category) result += `Category: ${r.category}\n`;
                if (r.source_name) result += `${r.source_name}\n`;
                if (r.date) result += `${r.date} `;
                if (r.link) result += `• ${r.link}\n`;
                result += "---\n";
                // result += JSON.stringify(r, null, 2);
            });

            return result;
        },
        handleShoppingResults(data) {
            let result = "## Products:\n";
            data.forEach(r => {
                if (r.source) result += `From "${r.source}":\n`;
                if (r.title) result += `${r.title}\n`;
                if (r.snippet) result += `${r.snippet}\n`;
                if (r.price) result += `Price: ${r.price}\n`;
                if (r.source_name) result += `${r.source_name}\n`;
                if (r.link) result += `${r.link}\n`;
                result += "---\n";
                // result += JSON.stringify(r, null, 2);
            });
            return result;
        },
        handleImageResults(data) {
            let result = "## Images:\n";
            data.slice(0, 25).forEach(r => {
                if (r.source) result += `From "${r.source}":\n`;
                if (r.title) result += `${r.title}\n`;
                if (r.snippet) result += `${r.snippet}\n`;
                if (r.source_name) result += `${r.source_name}\n`;
                if (r.link) result += `${r.link}\n`;
                result += "---\n";
            });
            return result;
        },
        handleURLs(data) {
            let result = "## URLs:\n";
            data.forEach(r => {
                // if (r.source) result += `From "${r.source}":\n`;
                // if (r.title) result += `${r.title}\n`;
                // if (r.snippet) result += `${r.snippet}\n`;
                // if (r.source_name) result += `${r.source_name}\n`;
                // if (r.link) result += `${r.link}\n`;
                if (r) result += `${r}\n`;
            });
            return result;
        },
        extractedSectionString(sectionName, data, showURLS) {
            let result = "";
            const sectionHandlers = [
                { name: "organic", handler: this.handleResult },
                { name: "answer_box", handler: this.handleAnswerBox },
                { name: "news", handler: this.handleNewsResults },
                { name: "news_results", handler: this.handleNewsResults },
                // { name: "urls", handler: this.handleURLs },
                { name: "places", handler: data => (data.places ? this.handlePlaces(data.places) : this.handlePlaces(data)) },
                { name: "top_sights", handler: data => (data.sights && Array.isArray(data.sights) ? this.handlePlaces(data.sights) : "") },
                { name: "videos", handler: this.handleVideo },
                { name: "shopping_results", handler: this.handleShoppingResults },
                { name: "product_result", handler: this.handleProductResult },
                { name: "showtimes", handler: this.handleShowtimes },
                { name: "immersive_products", handler: this.handleImmersiveProducts },
                { name: "images", handler: this.handleImageResults },
                { name: "related_searches", handler: this.handleRelatedSearches },
                { name: "recipes_results", handler: this.handleRecipes },
                { name: "knowledge_graph", handler: this.handleKnowledgeGraph },
                { name: "inline_people_also_search_for", handler: this.handlePeopleAlsoSearchFor },
                { name: "questions", handler: this.handleRelatedQuestion },
            ];

            const handler = sectionHandlers.find(handler => handler.name === sectionName);
            if (handler) {
                try {
                    result += handler.handler(data);
                } catch (error) {
                    console.error(`Error handling section "${sectionName}": ${error}`);
                }
            }
            // result = result.slice(0, -1);
            return result;
        },
    },
};
