<template>
    <div class="f aic jcc gap-1">
        <span v-if="label" class="d-block fwsb text-gray-500">{{ label }}</span>
        <div style="position: relative; display: block">
            <label
                class="f aic jcc"
                :style="{
                    position: 'relative',
                    display: 'inline-block',
                    width: `${width}px`,
                    height: `${height}px`,
                }">
                <input type="checkbox" :checked="currentValue" @change="toggleSwitch" style="display: none" />
                <span
                    :style="{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: `${height / 2}px`,
                    }"
                    class=""
                    :class="{
                        'border border-solid border-green-500 bg-green-400 dark:border-green-400 dark:bg-green-500': currentValue,
                        'border-light bg-gray-200/50 border border-solid border-gray-300/50 bg-gray-100 dark:border-base-600 dark:bg-base-900': !currentValue,
                    }"></span>
                <span
                    class="switch-knob dark:shadow-inset"
                    :style="{
                        position: 'absolute',
                        top: `${border}px`,
                        left: `${border}px`,
                        width: `${knobSize}px`,
                        height: `${knobSize}px`,
                        borderRadius: '50%',
                        transform: currentValue ? `translateX(${width - knobSize - 2 * border}px)` : 'translateX(0)',
                        transition: 'transform 150ms ease-in-out',
                    }"
                    :class="{
                        'bg-snow dark:border dark:border-solid dark:border-base-300 dark:bg-base-400': currentValue,
                        'bg-snow shadow dark:border-base-500 dark:bg-base-600': !currentValue,
                    }"></span>
            </label>
        </div>
    </div>
</template>

<script>
import { get } from "lodash";

export default {
    props: {
        module: { type: [String, Number], default: null },
        vuexKey: { type: [String, Number], default: null },
        label: { type: [String, Number], required: false },
        width: { type: [String, Number], default: 54 },
        height: { type: [String, Number], default: 30.6 },
        border: { type: [String, Number], default: 1.8 },
        onColor: { type: [String, Number], default: "#34D399" },
        offColor: { type: [String, Number], default: "#9CA3AF" },
    },
    watch: {
        currentValue(newValue) {
            if (this.module && this.vuexKey) {
                this.$store.commit(`${this.module}/updateToggleValue`, { key: this.vuexKey, value: newValue });
            }
        },
    },
    computed: {
        knobSize() {
            return this.height - 2 * this.border;
        },
        currentValue() {
            if (this.module && this.vuexKey) {
                return get(this.$store.state[this.module], this.vuexKey);
            }
            return null;
        },
    },
    methods: {
        toggleSwitch() {
            const newValue = !this.currentValue;
            if (this.module && this.vuexKey) {
                this.$store.commit(`${this.module}/updateToggleValue`, { key: this.vuexKey, value: newValue });
            }
        },
    },
};
</script>
