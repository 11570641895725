<template>
    <li class="py-2 sm:py-4">
        <div class="max-w-4xl px-4 sm:px-6 lg:px-8 =mx-auto">
            <div class="max-w-2xl flex gap-x-2 sm:gap-x-4">
                <div>
                    <div class="text-end">
                        <button
                            v-for="example in options"
                            :key="example.id"
                            @click="selected = example.id"
                            class="mb-2.5 me-1.5 py-2 px-3 inline-flex justify-center items-center gap-x-2 rounded-lg border border-blue-600 bg-white text-blue-600 align-middle hover:bg-blue-50 text-sm dark:bg-slate-900 dark:text-blue-500 dark:border-blue-500 dark:hover:text-blue-400 dark:hover:border-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            type="button">
                            {{ example.text }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>
<script>
export default {
    name: "MessageMultipleChoice",
    props: {
        options: {
            type: Array,
            default: () => [
                // {
                //     id: 1,
                //     text: "Is Tailwind CSS a free library?",
                // },
                // {
                //     id: 2,
                //     text: "What's the latest Tailwind CSS version?",
                // },
                // {
                //     id: 3,
                //     text: "Is it a utility-class based?",
                // },
            ],
        },
    },
    data() {
        return {
            selected: null,
            examples: [],
        };
    },
};
</script>
