import { doc, Timestamp, getDoc, query, collection, where, deleteDoc, addDoc, getDocs, orderBy, startAfter, limit, updateDoc } from "firebase/firestore";
import firestore, { db } from "@/firebase";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useFirebaseStorage, useStorageFile, useCollection, useDocument } from "vuefire";
import firebaseSave from "@/mixins/firebase/saveToFirebase";
import { logFetchData, getDataSize, logFetch, logSave, logPagenatedFetch } from "@/mixins/firebase/firebaseHelpers.js";
export default {
    data: () => ({
        feedItem: [],
    }),
    mounted() {},
    methods: {
        async fetchPaginatedFromFirebase(database, itemsLimit = 10, startAfterDate = null, startAfterId = null) {
            try {
                logPagenatedFetch(database, itemsLimit, startAfterDate, startAfterId);

                let baseQuery = collection(db, database); // Initialize the base query

                let constraints = [orderBy("updated", "desc"), orderBy("__name__")]; // Added ordering by document ID // Create an array to collect our query constraints

                if (startAfterDate && startAfterId) {
                    constraints.push(startAfter(startAfterDate, startAfterId)); // If pagination details provided, add 'startAfter' constraint
                }
                constraints.push(limit(itemsLimit)); // Apply limit constraint

                baseQuery = query(baseQuery, ...constraints); // Apply all the constraints to the base query

                const querySnapshot = await getDocs(baseQuery);
                console.log(querySnapshot);

                let documents = querySnapshot.docs.map(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    return data;
                });
                logFetchData("collection", database, undefined, documents, true);
                return documents;
            } catch (e) {
                console.error(e);
            }
        },

        async addToFirebase(database, payload) {
            let id;
            if (payload.id) {
                id = payload.id;
            } else {
                id = "no id provided";
            }
            logSave(database, id, payload);
            const created = new Date();
            const updated = new Date();
            const colRef = collection(db, database);
            const dataObj = payload;
            dataObj.created = created;
            dataObj.updated = updated;
            const self = this;
            let docRefNew = {};
            await addDoc(colRef, dataObj)
                .then(function (docRef) {
                    console.log("Document written with ID: ", docRef.id);
                    docRefNew = docRef;
                    dataObj.id = docRef.id;
                })
                .then(() => {
                    console.groupCollapsed(`%c ${database}`, warn, "Document added");
                    console.log("new doc added");
                    console.log(dataObj);
                    console.groupEnd();
                });
            console.groupCollapsed(`%c 💾 Firebase`, warn, database);
            console.log("Document written with ID: ", payload.id);
            console.table(payload);
            console.trace();
            console.groupEnd();
            return dataObj;

            // self.getDBItem(database);
        },

        async fetchFromFirebase(database, id, sort) {
            logFetch(database, id);
            let newId;
            if (id) {
                // use the database name and id to fetch an item from the database
                const docRef = doc(db, database, id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    let data = docSnap.data();
                    logFetchData("single", database, id, data);
                    return data;
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            } else {
                // If no ID is provided, fetch the entire collection
                let querySnapshot;
                if (sort) {
                    querySnapshot = await getDocs(query(collection(db, database), orderBy("updated", "desc")));
                } else {
                    querySnapshot = await getDocs(query(collection(db, database)));
                }

                let documents = querySnapshot.docs.map(doc => {
                    // Create a new object with both the id and the data
                    let data = doc.data();
                    if (doc.id) data.id = doc.id;

                    return data;
                });
                logFetchData("collection", database, null, documents);
                return documents;
            }
        },

        async removeFromFirebase(database, item, preventReload) {
            // 'users' collection reference
            const docRef = doc(db, database, item);
            // await removeDoc(colRef, dataObj);
            deleteDoc(docRef)
                .then(() => {
                    console.log("An item has been deleted from " + database);
                })
                .catch(error => {
                    console.log(error);
                });
            return;
            console.log("Document was removed with ID:", docRef.id);
            if (preventReload) {
            } else {
                await this.getDBItem(database);
            }
        },
        async saveToFirebase(database, payload, id = null) {
            return firebaseSave(database, payload, id);
        },
        async updateObject(collection, query, update) {
            // find the object in the collection that matches the query
            console.error(collection, query, update);
            const doc = await db.collection("feedItem").where(query[0], "==", query[1]).get();
            console.error("doc");
            console.error(doc);
            // get the document ID of the matched object
            const docId = query[1];
            // update the object using the merge option
            const response = await db.collection(collection).doc(docId).set(update, { merge: true });
            this.getDBItem("feedItem");
            // console.error(response);
            return response;
        },
        async updateAnyObject(collection, id, update) {
            // console.error(collection, id, update);
            const doc = await db.collection(collection).where("id", "==", id).get();
            // console.error('doc');
            console.log(doc);
            // get the document ID of the matched object
            const docId = id;
            // console.error(docId);
            // update the object using the merge option
            const response = await db.collection(collection).doc(docId).set(update, { merge: true });
            const updatedDoc = await this.getDocumentById(this.$route.params.id);
            console.error(`Done updating ${updatedDoc.id}:`, updatedDoc);
            // this.getDBItem(collection,false, id);
            // console.error(response);
            return updatedDoc;
        },
        async getDocumentById(id) {
            let document = await getDoc(doc(db, "documents", id))
                .then(doc => {
                    if (doc.exists()) {
                        console.log(`Get document data from Id ${id}:`, doc.data());
                        return doc.data();
                    } else {
                        console.error("No such document!");
                    }
                })
                .catch(error => {
                    console.error("Error getting document:", error);
                });
            return document;
        },
        async saveItem(collection, item, array) {
            await this.addToFirebase(collection, item);
            if (array) {
                this.$nextTick(() => {
                    this.$forceUpdate();
                    array.push(item);
                });
            }
            return array;
            // this.updateObject('genre', ['id', this.selectedGenre.id], this.selectedGenre);
        },
        async uDoc(id, update) {
            const collection = "documents";
            const docId = this.$route.params.id;
            let d = await db.collection(collection).where("id", "==", docId).get();
            console.log(d);
            await db.collection(collection).doc(docId).set(update, { merge: true });
            // let newDoc = await this.getDocumentById(id)
            // console.error(newDoc);
            // this.setDocument(newDoc);
            return;
        },
        async getDBItem(collection, array, id) {
            let items = [];
            if (id) {
                let d = await getDoc(doc(db, collection, id))
                    .then(doc => {
                        if (doc.exists()) {
                            console.log("Document data:", doc.data());
                            return doc.data();
                        } else {
                            console.log("No such document!");
                        }
                    })
                    .catch(error => {
                        console.log("Error getting document:", error);
                    });
                return d;
            } else {
                firestore
                    .collection(collection)
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            let obj = doc.data();
                            obj.id = doc.id;
                            // console.log(obj);
                            items.push(obj);
                        });
                        items.sort(function (x, y) {
                            return y.created - x.created;
                        });
                        if (collection === "feedItem") {
                            let newItems = [];
                            items.forEach(item => {
                                // let fixedResponse = item.response;
                                try {
                                    let fixedItem = JSON.parse(item.response);
                                    item.response = fixedItem;
                                    newItems.push(item);
                                } catch (e) {
                                    newItems.push(item);
                                }
                            });
                            this.$store.commit("refeshUserFeed", newItems);
                        }
                    });
                return items;
            }
        },
        async getAnyDBItem(collection, array, sort) {
            let items = [];
            let hasTime;
            await firestore
                .collection(collection)
                .get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        let obj = doc.data();
                        obj.id = doc.id;
                        if (obj.created) {
                            hasTime = true;
                        }
                        items.push(obj);
                    });
                    if (hasTime) {
                        if (sort === "updated") {
                            items.sort(function (x, y) {
                                let xUpdated = x.updated || x.created;
                                let yUpdated = y.updated || y.created;
                                if (xUpdated && yUpdated) {
                                    return yUpdated - xUpdated;
                                }
                                return true;
                            });
                        } else {
                            items.sort(function (x, y) {
                                if (x.created && y.created) {
                                    return y.created - x.created;
                                }
                                return true;
                            });
                        }
                    }
                    if (collection === "feedItem") {
                        let newItems = [];
                        items.forEach(item => {
                            // let fixedResponse = item.response;
                            try {
                                let fixedItem = JSON.parse(item.response);
                                item.response = fixedItem;
                                newItems.push(item);
                            } catch (e) {
                                newItems.push(item);
                            }
                        });
                        this.$store.commit("refeshUserFeed", newItems);
                    }
                });
            array = items;
            return items;
        },

        async getCollectionFromFirebase(database) {
            // get the collection from firebase then return the data
            const colRef = collection(db, database);
            const self = this;
            let docRefNew = {};
            let dataObj = {};
            let objects = [];
            await getDocs(colRef)
                .then(function (querySnapshot) {
                    // if (querySnapshot.length > 1) {
                    querySnapshot.forEach(function (doc) {
                        console.log(doc.id, " => ", doc.data());
                        dataObj = doc.data();
                        dataObj.id = doc.id;
                        let currentDoc = doc.data();
                        currentDoc.id = doc.id;
                        objects.push(currentDoc);
                    });
                    // }
                })
                .then(() => {
                    console.error(`${database} found`, objects);
                    console.error(objects);
                    return objects;
                });
            return objects;
        },
        async getMatchingFirebaseDoc(database, property, value) {
            console.error(database, property, value);
            // return a document matching the collection, property and value
            try {
                const colRef = collection(db, database);
                let dataObj = {};

                if (property === "__id__") {
                    const docSnapshot = await getDoc(doc(db, database, value));
                    if (docSnapshot.exists()) {
                        dataObj = docSnapshot.data();
                        dataObj.id = docSnapshot.id;
                    }
                } else {
                    const q = query(colRef, where(property, "==", value));
                    const querySnapshot = await getDocs(q);
                    let objects = [];
                    querySnapshot.forEach(function (doc) {
                        let object = doc.data();
                        object.id = doc.id;
                        console.log(property, " => ", doc.data());
                        dataObj = doc.data();
                        dataObj.id = doc.id;
                        objects.push(object);
                    });
                    if (objects.length > 1) {
                        console.error("more than one object found");
                        console.error(objects);
                        dataObj = { ...dataObj, related: objects };
                        return dataObj;
                    }
                }

                return dataObj;
            } catch (error) {
                console.error(error);
                return null;
            }
        },
        async documentFromRoute() {
            const d = await useDocument(doc(collection(db, "documents"), this.$route.params.id));
            // this.setDocument(d);
            return d;
            // this.setDocument = toRaw(d);
            // return this.$store.state.document;
        },
        async getArtistsByGenre(genre) {
            return;
        },
    },
};
