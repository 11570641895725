<!-- TopicSelector.vue -->
<template>
    <div v-if="messages.length < 2">
        <div class="mb-3 px-5 py-3" v-if="recentTopics && recentTopics.length > 0">
            <h6>Recents</h6>
            <div class="x f jcs mw-700 aic aic flex-wrap">
                <topic-button v-for="subject in recentTopics.slice(0, 5)" :text="subject.topic" @click.prevent="setSubject(subject)" />
            </div>
        </div>
        <div class="px-5">
            <h6>Topics</h6>
            <div class="x f jcs mw-700 aic aic flex-wrap">
                <topic-button v-for="subject in prompts" :text="subject.topic" @click.prevent="setSubject(subject)" />
            </div>
        </div>
    </div>
</template>

<script>
import TopicButton from "@/components/CoreUI/TopicButton.vue";

export default {
    components: { TopicButton },
    props: ["messages", "recentTopics", "prompts"],
    methods: {
        setSubject(subject) {
            this.$emit("setSubject", subject);
        },
    },
};
</script>
