<template>
    <div :key="`section${index}`" class="f fc">
        <collapsable-menu-item :key="identifier + editingSection" :open="editingSection" title="Section Type">
            <!--          max-height="600"-->

            <template #title>
                <div class="f gap-3 editor-section aic pr-5 border-b-gray-200 border-bottom group cursor-grabbing hover:bg-green-50">
                    <div v-if="imageIndex" class="flex-shrink-0 relative width-50 height-50 border border-black br-10 overflow-hidden">
                        <img :alt="images[imageIndex]?.alt" :src="images?.[imageIndex]?.url" class="x y o-cover" style="border-radius: 5px !important" />
                        <div class="p-absolute p-fill aic jcc bg-snow/20 ease-back transition-all opacity-0 f group-hover:opacity-100 text-snow bg-blur">
                            <i class="fas fa-arrows-v scale-50 opacity-0 transition-all duration-1 ease-elastic group-hover:opacity-100 group-hover:scale-100" style="text-shadow: 0px 1px 0px black"></i>
                        </div>
                    </div>
                    <div class="group-hover:text-green-800">
                        <span v-if="header" class="leading-tight line-clamp-1 text-sm fwb text-pretty tracking-tight">{{ header }}</span>
                        <span v-if="sub_header" class="fwsb line-clamp-1 text-xs o-7">{{ sub_header }}</span>
                        <span v-if="body" class="text-pretty line-clamp-1 text-xs small o-3 leading-tight">{{ body }}</span>
                    </div>
                </div>
            </template>
            <div>
                <SectionColorEditor v-if="sectionColors?.length > 0" :colors="sectionColors" @update-colors="updateColors" />
                <div class="px-3 pb-3 border-bottom">
                    <div v-if="relevantImages">
                        <div class="overflow-x-scroll scrollbar-hide">
                            <label class="block text-sm font-medium leading-6 text-gray-900">Relevant Images</label>
                            <div class="f gap-1 aic">
                                <!--                                    <img :src="images[imageIndex]?.url" :alt="images[imageIndex]?.alt" class="c-50" style="border-radius: 10px !important" />-->
                                <template v-for="image in relevantImages">
                                    <div :class="{ 'border-2 border-black': section.imageIndex === image }" class="c-30 overflow-hidden" style="border-radius: 10px !important">
                                        <img :alt="images[image]?.alt" :src="images[image].url" class="x y o-cover" @click.prevent="updateSection('imageIndex', image)" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-sections">
                    <SectionStyleEditor :identifier="identifier" :index="index" :section="section" :style="style" @update-section="updateSection" />
                    <ImageStyleEditor :identifier="identifier" :image-style="imageStyle" :index="index" :section="section" @update-section="updateSection" />
                    <ImageInsetEditor :identifier="identifier" :image-inset="imageInset" :index="index" :section="section" @update-section="updateSection" />
                </div>
                <SectionTextEditor :key="`pre_header_${index}`" :campaign="campaign" :font-size="sub_headerSize" :identifier="identifier" :index="index" :section="section" :text="pre_header" type="pre_header" @update-section="updateSection" />
                <SectionTextEditor :key="`header_${index}`" :campaign="campaign" :font-size="headerSize" :identifier="identifier" :index="index" :section="section" :text="header" type="header" @update-section="updateSection" />
                <SectionTextEditor :key="`subheader_${index}`" :campaign="campaign" :font-size="sub_headerSize" :identifier="identifier" :index="index" :section="section" :text="sub_header" type="sub_header" @update-section="updateSection" />
                <SectionTextEditor :key="`body_${index}`" :campaign="campaign" :font-size="bodySize" :identifier="identifier" :index="index" :section="section" :text="body" type="body" @update-section="updateSection" />
                <SectionFeatureEditor :key="`features_${index}`" :campaign="campaign" :index="index" :section="section" @update-section="updateSection"></SectionFeatureEditor>
                <SectionButtonEditor :campaign="campaign" :key="`button_${index}`" :section="section" :index="index" @update-section="updateSection" />
                <!--                <ObjectViewer :data="section" />-->
            </div>
        </collapsable-menu-item>
        <span class="visually-hidden">{{ editingSection }}</span>
    </div>
</template>
<script>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import { mapGetters } from "vuex";
import ImageStyleEditor from "@/components/editor/ImageStyleEditor.vue";
import ImageInsetEditor from "@/components/editor/ImageInsetEditor.vue";
import SectionStyleEditor from "@/components/editor/SectionStyleEditor.vue";
import SectionTextEditor from "@/components/editor/SectionTextEditor.vue";
import SectionFeatureEditor from "@/components/editor/SectionFeatureEditor.vue";
import SectionColorEditor from "@/components/elements/SectionColorEditor.vue";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import chroma from "chroma-js";
import ObjectViewer from "@/components/Admin/ObjectViewer.vue";
import SectionButtonEditor from "@/components/editor/SectionButtonEditor.vue";

export default {
    name: "CurrentlyEditingSection",
    mixins: [StyleGuideMixin],
    components: {
        SectionButtonEditor,
        ObjectViewer,
        SectionColorEditor,
        SectionFeatureEditor,
        SectionTextEditor,
        SectionStyleEditor,
        ImageInsetEditor,
        ImageStyleEditor,
        CollapsableMenuItem,
        DebugObject,
    },
    props: {
        section: {
            type: Object,
            default: {},
            required: true,
        },
        index: {
            type: Number,
            default: 0,
            required: true,
        },
        id: {
            type: String,
            default: "",
            required: true,
        },
        campaign: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    methods: {
        async saveCampaign() {
            await this.$store.dispatch("stream/saveCampaignItem", { id: this.id });
        },
        updateSection(prop, value) {
            prop = this.identifier + "." + prop;
            console.log("updateSection", JSON.stringify(prop), JSON.stringify(value));
            this.$store.dispatch("stream/updateStreamProp", { keyPath: prop, value });
            this.saveCampaign();
        },
        updateColors(obj = {}) {
            let { color, backgroundColor } = obj;
            if (color && backgroundColor) {
                this.updateSection("color", color);
                this.updateSection("backgroundColor", backgroundColor);
            }
        },
    },
    computed: {
        ...mapGetters("styleGuide/image", ["images"]),
        ...mapGetters("styleGuide", ["divStyles"]),
        editingSection() {
            let editing = this.$store.state?.stream?.stream?.editor?.[this.id].editing;
            if (editing === this.index) return true;
            return false;
        },
        sectionType() {
            return this.section?.type || "No Type";
        },
        imageObj() {
            let index = this.section?.imageIndex || 0;
            return this.images[index] || {};
        },
        sectionColors() {
            let colors = [];
            let image = this.imageObj;
            if (!image) return [];
            if (this.section?.colors) colors.push(...this.section.colors);
            let keys = ["topColor", "bottomColor", "primary", "secondary", "dominant", "colors"];
            let divStyles = this.divStyles.map(s => {
                return {
                    color: s.color,
                    backgroundColor: s.backgroundColor,
                };
            });
            keys.forEach(key => {
                let value = image[key];
                if (value) {
                    if (Array.isArray(value)) colors.push(...value);
                    else colors.push(value);
                }
            });
            divStyles.forEach(style => {
                if (style.color) colors.push(style.color);
                if (style.backgroundColor) colors.push(style.backgroundColor);
            });
            colors = [...new Set(colors)];
            colors = colors.filter(c => !c.includes("gradient"));
            colors = colors.map(c => {
                let background = c;
                let color = "black";
                try {
                    color = this.getContrastColor(background, colors);
                } catch (e) {}
                return {
                    color,
                    backgroundColor: background,
                };
            });
            // sort by lightness
            colors.push(...divStyles);
            colors.sort((a, b) => {
                try {
                    let aL = chroma(a.backgroundColor).luminance();
                    let bL = chroma(b.backgroundColor).luminance();
                    return aL - bL;
                } catch (e) {
                    return 0;
                }
            });
            return colors;
            // do the same for topColor bottomColor,primary,second, dominant
        },
        identifier() {
            let identifier = this.section?.identifier;
            if (!identifier) {
                identifier = "editor." + this.id + ".result.sections." + this.index;
            }
            return identifier;
        },
        header() {
            return this.section?.header || "";
        },
        pre_header() {
            return this.section?.pre_header || "";
        },
        imageInset() {
            return this.section?.imageInset || false;
        },
        style() {
            return this.section?.style || false;
        },
        imageStyle() {
            return this.section?.imageStyle || false;
        },
        body() {
            return this.section?.body || "";
        },
        sub_header() {
            return this.section?.sub_header || "";
        },
        imageIndex() {
            return this.section?.imageIndex || false;
        },
        relevantImages() {
            let sectionImages = this.section?.relevant_image_indexes || [];
            let campaignImages = this.campaign?.relevant_image_indexes || [];
            let images = [...sectionImages, ...campaignImages, 0, 1, 2, 3, 4, 5];
            return images;
        },
        headerSize() {
            return this.section?.headerSize || 16;
        },
        sub_headerSize() {
            return this.section?.subheaderSize || 16;
        },
        bodySize() {
            return this.section?.bodySize || 16;
        },
    },
};
</script>
<style lang="scss">
.editor-section-title {
    color: var(--color-primary);
    @apply text-sm;
    font-weight: 500;
    //@apply font-semibold;
    font-family: system-ui;
    //text-transform: uppercase;
}

.editor-sections {
    @apply divide-solid divide-x-0 divide-gray-200 flex flex-column divide-y;
    border-bottom: 1px solid;
    @apply border-b-gray-200;
    color: black !important;
}

.editor-section {
    @apply py-2;
    @apply px-3;
    //@apply bg-gray-100;
}
</style>
