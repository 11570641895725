<template>
    <div class="mw-1500 container m-0 p-0" v-if="section.images" style="background: none">
        <GridLayout
            v-if="section.images"
            ref="gridLayout"
            :col-num="totalColumns"
            :is-draggable="true"
            :is-resizable="true"
            :layout.sync="section.images"
            :margin="[10, 10]"
            :max-rows="8"
            :preventCollision="true"
            :responsive="mobile"
            :row-height="100"
            class="x vh-80 height-min-500"
            horizontal-compact
            style="min-height: 80vh"
            use-css-transforms
            vertical-compact
            @layout-updated="movedImage">
            <template v-for="(item, i) in section.images" :key="'key' + item.i">
                <!--                @move="movedOneImage"    -->
                <GridItem
                    :h="item.h"
                    :i="item.i"
                    :max-h="8"
                    :max-w="8"
                    :min-h="4"
                    :min-w="2"
                    :resize-options="{
                        edges: {
                            left: false,
                            right: true,
                            bottom: true,
                            top: false,
                        },
                    }"
                    :w="item.w"
                    :x="item.x"
                    :y="item.y"
                    class="grid_item br-10 overflow-hidden"
                    :class="`${section.background_class} ${section.text_class}`"
                    @move="moveEvent"
                    @resize="resizeItem">
                    <img v-if="item.url" :src="item.url" class="x y o-cover o-bottom br-10" />

                    <div v-if="item.body" :class="`${section.background_class} ${section.text_class}`" class="x y f aic jcc p-5 text-left">
                        <PresentationSlideText :key="'text' + index + id" :document="document" :document-id="document.id" :image-dark="imageDark" :index="index" :section="section"></PresentationSlideText>

                        <!--                        <div class="p-5 text-left">-->
                        <!--                            <h2>{{ item.title }}</h2>-->
                        <!--                            <p class="f-17 whitespace-pre-wrap">{{ item.enhancedBody || item.body }}</p>-->
                        <!--                        </div>-->
                    </div>
                    <div class="p-absolute bottom right f gap-3 p-3">
                        <BaseButton class="justify-self-end" icon="fas fa-sync f-20 text-shadow" icon-only label="Remix" size="sm" style-type="bubble" @click.prevent="clickedImage(i)" />
                        <BaseButton class="justify-self-end" icon="fas fa-trash f-20 text-shadow" icon-only label="Remix" size="sm" style-type="bubble" @click.prevent="removeImage(i)" />
                    </div>
                </GridItem>
            </template>
        </GridLayout>
    </div>
</template>

<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import presenationMixin from "@/mixins/presentations/presentationMixin";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { GridItem, GridLayout } from "grid-layout-plus";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import updateSlideProp from "@/mixins/ai/update_slide_prop";

// import  from "@/components/Presentations/slides/largeSlides/ResizableGridItem.vue";
import lodash from "lodash";

export default {
    components: { BaseButton, PresentationImageContainer, PresentationSlideText, GridLayout, GridItem },
    mixins: [SlideStyleMixin, presenationMixin],
    data() {
        return {
            imageDark: false,
            totalColumns: 8,
            hovered: false,
            debounceSave: lodash.debounce(() => this.savePresentation(this.$route.params.id), 500),
        };
    },
    props: {
        id: {},
        index: {},
        section: {},
        document: {},
        documentId: {},
        slideContainerClasses: {},
    },
    watch: {
        "document.theme.colors": {
            handler: function (val, oldVal) {
                if (val !== oldVal) {
                    this.textTheme();
                    // this.slideColors();
                }
            },
            deep: true,
        },
        "section.images": {
            handler: function (images, oldImages) {
                // Check if an image was added.
                if(images && oldImages) {
                    if (images.length > oldImages.length || !images[0]?.w) {
                        // Find the newly added image.
                        console.error("images", images);
                        let newImage = images.find(image => !oldImages.includes(image));
                        if (newImage) {
                            // Set the default values.
                            newImage.w = newImage.w ?? 4;
                            newImage.h = newImage.h ?? 4;

                            // Set the position based on the nextEmptySpot
                            let spot = this.nextEmptySpot;
                            newImage.x = spot.x ?? 0;
                            newImage.y = spot.y ?? 0;

                            // Assign a unique key (i value) for the new image based on its index.
                            newImage.i = this.randomId();

                            // Update the image array in section.
                            updateSlideProp(this.$store, "images", images, this.index);
                            // Save the presentation
                            this.savePresentation(this.$route.params.id);
                        }
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        nextEmptySpot() {
            let maxY = 0;
            let maxX = 0;

            // check if this.section.images is not null and has length > 0
            if (this.section.images && this.section.images.length > 0) {
                this.section.images.forEach(item => {
                    if (item.y && item.h) {
                        maxY = Math.max(maxY, item.y + item.h);
                    }
                });
                let sameRowItems = this.section.images.filter(item => item.y && item.h && item.y + item.h === maxY);
                if (sameRowItems && sameRowItems.length > 0) {
                    sameRowItems.forEach(item => {
                        if (item.x && item.w) {
                            maxX = Math.max(maxX, item.x + item.w);
                        }
                    });
                    if (maxX >= 8) {
                        return { x: 0, y: maxY };
                    } else {
                        return { x: maxX, y: maxY - sameRowItems[0].h };
                    }
                }
                return { x: 0, y: 0 };
            }
            // default empty spot
        },

        blendMode() {
            if (this.section?.mode === "dark") {
                return "screen";
            } else {
                return "multiply";
            }
        },
        blendModeOpposite() {
            if (!this.imageDark) {
                return "screen";
            } else {
                return "multiply";
            }
        },
    },
    mounted: function () {
        this.slideColors();
        if (this.section.images) {
            this.fixImages();
        }
        // this.imageTheme;
        // this.randomImageStyle();
    },
    methods: {
        fixImages() {
            let hasBody;
            this.section.images.forEach((item, index) => {
                if (!item.w) {
                    item.w = 4;
                    item.h = 8;
                    item.x = index * 4;
                    item.y = 0;
                }
                if (item.body) {
                    hasBody = true;
                }
                if (!item.i) item.i = this.randomId();
            });
            if (!hasBody) {
                this.section.images.push({
                    w: 4,
                    h: 8,
                    x: 4,
                    y: 0,
                    body: this.section.body,
                    enhancedBody: this.section.enhancedBody,
                    title: this.section.title,
                    i: this.randomId(),
                });
            }
            // find section with body property

            updateSlideProp(this.$store, "images", this.section.images, this.index);
        },
        // moveEvent(e, b, a) {
        //     console.log(e, b, a);
        //     return;
        //     var p;
        //     for (p = 0; p < this.section.images.length; p++) {
        //         //Horizontal swapping
        //         if (newX >= this.section.images[p]["x"] && newX < this.section.images[p]["x"] + this.section.images[p]["w"] && this.section.images[i]["y"] == this.section.images[p]["y"] && i != this.section.images[p]["i"]) {
        //             let initialX = this.section.images[i]["x"];
        //             let finalX = this.section.images[p]["x"];
        //             updateSlideProp(this.$store, "images[" + i + "].x", finalX, this.index);
        //             updateSlideProp(this.$store, "images[" + p + "].x", initialX, this.index);
        //             // this.section.images[i]["x"] = finalX;
        //             // this.section.images[p]["x"] = initialX;
        //         }
        //         // //Vertical swapping
        //         // if (newY >= this.section.images[p]["y"] && newY < this.section.images[p]["y"] + 1 && this.section.images[i]["x"] == this.section.images[p]["x"] && i != this.section.images[p]["i"]) {
        //         //     let initialY = this.section.images[i]["y"];
        //         //     let finalY = this.section.images[p]["y"];
        //         //     this.section.images[i]["y"] = finalY;
        //         //     this.section.images[p]["y"] = initialY;
        //         // }
        //     }
        // },
        moveEvent(i, newX, newY) {
            // Find the index of the item that is moving.
            const movingIndex = this.section.images.findIndex(item => item.i === i);

            // Loop over all items in the layout.
            for (let p = 0; p < this.section.images.length; p++) {
                // Check if the current item is not the item that is moving.
                if (movingIndex !== p) {
                    // Check for a horizontal swap.

                    if (newX >= this.section.images[p]["x"] && newX < this.section.images[p]["x"] + this.section.images[p]["w"] && this.section.images[movingIndex]["y"] == this.section.images[p]["y"]) {
                        let initialX = this.section.images[movingIndex]["x"];
                        let finalX = this.section.images[p]["x"];

                        updateSlideProp(this.$store, `images.${movingIndex}.x`, finalX, this.index);
                        updateSlideProp(this.$store, `images.${p}.x`, initialX, this.index);
                    }

                    // Check for a vertical swap.
                    if (newY >= this.section.images[p]["y"] && newY < this.section.images[p]["y"] + 1 && this.section.images[movingIndex]["x"] == this.section.images[p]["x"]) {
                        let initialY = this.section.images[movingIndex]["y"];
                        let finalY = this.section.images[p]["y"];

                        updateSlideProp(this.$store, `images.${movingIndex}.y`, finalY, this.index);
                        updateSlideProp(this.$store, `images.${p}.y`, initialY, this.index);
                    }
                }
            }

            // Save the presentation after the move event.
            this.debounceSave();
        },

        clickedImage(i) {
            updateQueueProp(this.$store, "selectedImageIndex", i);
            updateSlideProp(this.$store, "showImages", true, this.index);
        },
        resizeItem(i, newH, newW, newHPx, newWPx) {
            // Find the resized item.
            const resizedItem = this.section.images.find(item => item.i === i);

            // The change in width of the resized item.
            const deltaW = newW - resizedItem.w;

            // Update the resized item's dimensions.
            resizedItem.w = newW;
            resizedItem.h = newH;

            // Get all other items in the same row.
            let otherItemsInRow = this.section.images.filter(item => item.i !== resizedItem.i && item.y === resizedItem.y);

            // If there are no other items in the row, no further processing is needed.
            if (otherItemsInRow.length === 0) return;

            // Sort the items by their x-position.
            otherItemsInRow.sort((a, b) => a.x - b.x);

            // The total width available for other items in the row after resizing the current item.
            const availableWidth = this.totalColumns - (newW + resizedItem.x);

            // The width that should be subtracted from each other item in the row to maintain the row width.
            const widthSubtraction = deltaW / otherItemsInRow.length;

            // Apply the x-shift and width reduction to all other items in the row.
            otherItemsInRow.forEach(item => {
                // Ensure the item's width does not fall below the minimum width (1 in this case).
                if (item.w - widthSubtraction >= 1) {
                    item.w -= widthSubtraction;
                    item.x += deltaW;
                }
            });

            // Trigger a layout update in the vue-grid-layout component.
            // this.$refs.gridLayout.layoutUpdated();
        },

        movedImage(event) {
            updateSlideProp(this.$store, "images", event, this.index);
            this.debounceSave();
            // console.log(event);
        },
        movedOneImage(i, newX, newY) {
            // Copy the current images layout
            let images = [...this.section.images];

            // Find the moved image in the images layout
            let image = images.find(image => image.i == i);

            // If image found
            if (image) {
                // Find the image in the images layout that now occupies the original position
                let swappedImage = images.find(otherImage => otherImage.x == image.x && otherImage.y == image.y);

                // If there is a swapped image, swap their positions
                if (swappedImage) {
                    swappedImage.x = image.x;
                    swappedImage.y = image.y;
                }

                // Update the moved image's position to the new one
                image.x = newX;
                image.y = newY;
            }

            // Update the images layout
            updateSlideProp(this.$store, "images", images, this.index);

            // Save the presentation
            this.debounceSave();
        },

        removeImage(index) {
            let images = this.section.images;
            images = images.filter((image, i) => i !== index);
            updateSlideProp(this.$store, "images", images, this.index);
            this.savePresentation(this.$route.params.id);
        },
        randomImageStyle() {
            this.imageDark = Math.random() < 0.5 ? true : false;
        },
    },
};
</script>
<style lang="scss" scoped>
.vgl-layout {
    --vgl-placeholder-bg: green;
}

.grid_item {
    .swap_image {
        @apply transition-all;
        opacity: 0;
    }

    &:hover {
        .swap_image {
            @apply transition-all;
            opacity: 1;
        }
    }
}
</style>
