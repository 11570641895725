<template>
    <div class="z-1 block bg-snow x scrollSection p-4 mw-500 x mx-auto br-20">
        <p v-if="title">{{ title }}</p>
        <ul class="space-y-6" role="list">
            <li v-for="(item, i) in progress.progress" :key="item" class="relative flex gap-x-4">
                <div :class="[i === progress.length - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center']">
                    <div class="w-px bg-gray-100" />
                </div>
                <ItemsComment v-if="item?.status === 'commented'" :item="item" />
                <ItemOther v-else :item="item" />
                <!--                {{ item }}-->
            </li>
        </ul>
        <ActivityNewComment v-if="commentInput" :moods="moods" :selected="selected" />
    </div>
</template>

<script>
import ActivityNewComment from "@/components/generative_templates/Pages/ActivityNewComment.vue";
import ItemsComment from "@/components/generative_templates/Pages/ActivityItemsComment.vue";
import ItemOther from "@/components/generative_templates/Pages/ActivityItemOther.vue";
import StatusLight from "@/views/StatusLight.vue";
import StatusBadge from "@/views/StatusBadge.vue";
import PulsingLight from "@/views/PulsingLight.vue";

export default {
    name: "MiniFeed",
    components: { PulsingLight, StatusBadge, StatusLight, ItemOther, ItemsComment, ActivityNewComment },
    props: {
        title: {
            type: String,
            default: "",
        },
        commentInput: { type: Boolean, default: false },
        progress: {
            type: Array,
            default: () => [
                { status: "queued", user: { name: "Chelsea Hagon" }, date: "7d ago", dateTime: "2023-01-23T10:32" },
                { status: "processing", user: { name: "Chelsea Hagon" }, date: "6d ago", dateTime: "2023-01-23T11:03" },
                { status: "training", user: { name: "Chelsea Hagon" }, date: "6d ago", dateTime: "2023-01-23T11:03" },
                { status: "sent", user: { name: "Chelsea Hagon" }, date: "6d ago", dateTime: "2023-01-23T11:24" },
                {
                    id: 4,
                    status: "commented",
                    user: {
                        name: "Chelsea Hagon",
                        url: "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    },
                    comment: "Called client, they reassured me the invoice would be paid by the 25th.",
                    date: "3d ago",
                    dateTime: "2023-01-23T15:56",
                },
                { status: "viewed", user: { name: "Alex Curren" }, date: "2d ago", dateTime: "2023-01-24T09:12" },
                { status: "done", user: { name: "Alex Curren" }, date: "1d ago", dateTime: "2023-01-24T09:20" },
                { status: "failed", user: { name: "Alex Curren" }, date: "1d ago", dateTime: "2023-01-24T09:20" },
            ],
        },
    },
    data() {
        return {
            // selected: this.moods?.[5],
        };
    },
    computed: {
        selected() {
            return this.moods?.[5];
        },
    },
};
</script>
