<template>
    <div class="flex flex-col rounded-lg overflow-hidden shadow-md p-4" :key="product" v-if="product">
        <a :href="url" v-if="image" target="_blank">
            <img class="x rounded-lg mb-4 border-light" :src="image.url" alt="Product Image" />
        </a>
        <div class="f fc text-left">
            <h3 class="text-lg font-semibold mb-2" v-if="title">{{ title }}</h3>
            <div>
                <ProductReviews v-if="rating" :rating="rating" />
            </div>
            <div class="text-xs text-sm mb-2" v-if="price">{{ price }}</div>

            <collapsable-menu-item class="collapsable">
                <p class="whitespace-pre-line text-sm mb-2 w-90" v-if="description && !htmlDescription">{{ description }}</p>
                <div v-else-if="description && htmlDescription" v-html="description"></div>
                <template #title>Description</template>
            </collapsable-menu-item>
            <template v-if="tabs">
                <collapsable-menu-item class="collapsable" v-for="(tab, key) in tabs">
                    <template #title>{{ key }}</template>
                    <div v-if="tab" v-html="tab"></div>
                </collapsable-menu-item>
            </template>
            <div class="o-2 text-xs text-sm mb-2" v-if="inventory">Inventory: {{ inventory }}</div>
            <!--            <template v-if="url">{{ url }}</template>-->
            <debug-object :object="product"></debug-object>
            <!--            {{ rating }}-->
        </div>
    </div>
</template>
<script>
import ProductReviews from "@/views/ProductReviews.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "BrandProductCard",
    mixins: [styleGuideMixin],
    components: { CollapsableMenuItem, DebugObject, ProductReviews },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        product() {
            if (this.item?.product) {
                return {
                    ...this.item,
                    ...this.item.product,
                };
            }
            return this.product || {};
        },
        title() {
            return this.product?.title || "";
        },
        htmlDescription() {
            if (this.product?.description?.includes("<") || this.product?.description?.includes("<")) return true;
        },
        description() {
            // handle description differently if it contains html
            if (this.product?.description?.includes("<") || this.product?.description?.includes("<")) {
                return this.product?.description;
            }
            return this.product?.description || "";
        },
        tabs() {
            return this.product?.tabs || "";
        },
        price() {
            // if no $ sign, add it
            if (this.product?.price && !this.product?.price.includes("$")) {
                if (this.product.price > 1000) return `$${this.product?.price / 100}`;
                return `$${this.product?.price}`;
            } else if (this.product?.price) {
                return this.product?.price;
            }
            // if it's a shopify price fix it (they multiply by 100)
        },
        rating() {
            if (this.product.reviews) return this.product.reviews;
            if (this.product.reviewCount || this.product.ratingCount) {
                return {
                    rating: this.product?.rating || this.product?.averageRating,
                    reviewCount: this.product?.reviewCount || this.product?.ratingCount,
                };
            }
            return { rating: this.product?.rating || "" };
        },
        inventory() {
            return this.product?.variant_inventory || "";
        },
        reviews() {
            return this.product?.reviewCount || "";
        },
        url() {
            return this.product?.url || "";
        },
        image() {
            if (typeof this.product?.image === "string") {
                return {
                    url: this.product?.image,
                };
            }
            return this.product?.image || {};
        },
    },
};
</script>
