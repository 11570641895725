<template>
    <div class="border-bottom py-4">
        <div class="px-3 m-0">
            <div class="f jcs aic mb-0 gap-4" :class="{ 'mb-4': $slots.default }">
                <h3 :class="` color: ${baseText}!important`" style="" v-if="title">{{ title }}</h3>
                <div class="" v-if="section && isAdmin">
                    <BaseButton class="border-light bg-opacity-0 capitalize transition-all ease-back hover:rotate-180 hover:rotate-90 hover:bg-opacity-100" icon="fas fa-sync" icon-only reverse :label="fixCamelCase(section)" rounded size="xxs" style-type="secondary" @click.prevent="updateSection(section)" />
                    <BaseButton class="border-light bg-opacity-0 capitalize transition-all ease-back hover:rotate-180 hover:rotate-90 hover:bg-opacity-100" icon="fas fa-magic" icon-only reverse :label="fixCamelCase(section)" v-if="title === 'Images'" rounded size="xxs" style-type="secondary" @click.prevent="fetchNewImages()" />
                </div>
            </div>
            <div class="grid grid-cols-12">
                <div v-if="updating" class="x f br-20 aic jcc col-span-12 mx-auto block bg-gray-900 bg-opacity-5 py-8 text-center">
                    <loading-spinner class="mx-auto block text-center" :color="styleGuide?.baseColor || '#666'"></loading-spinner>
                </div>
                <template v-else-if="$slots.default">
                    <slot></slot>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import LoadingState from "@/components/loaders/LoadingState.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";

export default {
    name: "StyleGuideContainer",
    mixins: [styleGuideMixin],
    components: { LoadingSpinner, LoadingState, BaseButton },
    data() {
        return {
            updating: false,
            // loading: false,
        };
    },
    watch: {
        loading(val) {
            this.updating = val;
        },
    },
    computed: {
        loading() {
            return this.$store.state?.styleGuide?.styleGuide?.l;
        },
    },
    methods: {
        async updateSection(section, payload) {
            this.updating = true;
            console.log("Section", section);
            await this.updateGuideSection(section, payload);
            if (this.section === "processedImages") setTimeout(() => (this.updating = false), 2000);
            this.updating = false;
        },
        async fetchNewImages() {
            await this.updateSection("fetchNewImages", { existingImages: [...this.styleGuide.images] });
        },
    },
    props: {
        section: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            default: "Section title",
            required: false,
        },
    },
};
</script>
