<template>
    <button :class="buttonStyles" class="cursor-pointer" :style="`line-height: normal; outline: none !important;${styleOverride} ${brandStyle}`" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
        <!--        @click="$emit('click', $event)"-->
        <transition name="fade-upsimple">
            <ToolTip class="isolate" :show-tooltip="showTooltip" :tooltip="tooltip" />
        </transition>
        <template v-if="showLoader || icon || line">
            <LoadingSpinner v-if="showLoader || icon === 'loader'" :class="{ ' mr-3 ': !iconOnly && !reverse }" :small="true" class="button_loader" />
            <template v-else>
                <i v-if="icon && isFontAwesome" :style="styleOverride" :class="[` o-5  ${icon}  ${iconStyles}`, { 'o-100': brandButton }]"></i>
                <span v-if="icon && !isFontAwesome" :class="iconStyles" :style="styleOverride">{{ icon }}</span>
            </template>
            <!--            <div v-if="line && icon && !iconOnly">-->
            <div v-if="line && icon && !iconOnly" class="button_line" style="height: 100%; width: 1px"></div>
        </template>
        <span v-if="!iconOnly" :class="{ button_text: !brandButton }" :style="styleOverride">{{ label }}</span>
        <slot></slot>
    </button>
</template>

<script>
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import ToolTip from "@/components/CoreUI/ToolTip.vue";

export default {
    excludeGlobalMixin: true,
    components: { ToolTip, LoadingSpinner },
    props: {
        label: String,
        line: { type: Boolean, default: false },
        showLoader: { type: Boolean, default: false },
        reverse: { type: Boolean, default: false },
        styleOverride: { type: String, default: "" },
        styleType: {
            type: String,
            default: "secondary",
            validator: value => ["primary", "secondary", "bubble", "tertiary", "link", "clear", "warning", "success", "info", "brand"].includes(value),
        },
        outlined: Boolean,
        color: { type: String, default: "" },
        rounded: Boolean,
        size: {
            type: String,
            default: "md",
            validator: value => ["xxs", "xs", "sm", "md", "lg", "xl"].includes(value),
        },
        icon: { type: String, default: "" },
        iconOnly: Boolean,
        tooltip: { type: String, default: "" }, // New optional prop for tooltip
    },
    data() {
        return {
            showTooltip: false, // New state for handling tooltip visibility
        };
    },
    computed: {
        brandStyle() {
            if (this.brandButton) {
                return `border:0px`;
            }
        },
        brandButton() {
            return this.styleType === "brand" || this.styleType === "brand1";
        },
        buttonStyles() {
            const baseStyles = `inline-flex ${this.reverse ? "flex-row-reverse gap-2" : "flex-row gap-2 "} aic jcc relative border font-semibold line-height-smaller focus:outline-none transition-colors ${this.iconOnlyStyles} ${this.sizeStyles} ${this.roundedStyles} ${this.styleTypeStyles} ${this.colorStyles} ${this.outlinedStyles}`;

            return this.styleType === "link" ? `linkButton whitespace-nowrap ${this.reverse ? "flex flex-row-reverse gap-2" : "flex flex-row gap-2 "}` : baseStyles;
        },

        isFontAwesome() {
            return this.icon && this.icon.includes("fa");
        },

        sizeStyles() {
            const sizeMapping = {
                xxs: "f-13 px-2 py-1 whitespace-nowrap",
                xs: "text-xs px-2 py-1 whitespace-nowrap",
                sm: "text-sm px-3 py-1 whitespace-nowrap",
                md: "text-base px-4 py-2 whitespace-nowrap",
                lg: "text-lg px-4 py-2 whitespace-nowrap",
                xl: "text-lg px-4 py-2 whitespace-nowrap",
            };
            return sizeMapping[this.size] || "";
        },
        iconStyles() {
            if (this.reverse) return;
            if (!this.iconOnly) {
                if (this.size === "xxs" || this.size === "xs" || this.size === "sm") {
                    return "mr-2";
                } else if (this.size === "md") {
                    return "mr-3";
                } else if (this.size === "lg") {
                    return "mr-3";
                } else if (this.size === "xl") {
                    return "mr-3";
                }
            }
        },
        roundedStyles() {
            if (this.brandButton && !this.rounded) return;
            return this.rounded
                ? "br-50"
                : {
                      xl: "br-10",
                      lg: "br-5",
                      md: "br-5",
                      sm: "br-5",
                      xs: "br-5",
                      xxs: "br-5",
                  }[this.size];
        },
        iconOnlyStyles() {
            if (this.iconOnly) {
                switch (this.size) {
                    case "xxs":
                        return "c-30 br-10";
                    case "xs":
                        return "c-40";
                    case "sm":
                        return "c-40";
                    case "md":
                        return " c-40 br-10 !px-3";
                    case "lg":
                        return "c-45 !px-3";
                    case "xl":
                        return "c-50 !px-3";
                    default:
                        return "c-40";
                }
            }
        },
        colorStyles() {
            if (this.styleType !== "brand") {
                return this.color ? `text-${this.color}-500 border-${this.color}-600 !bg-${this.color}-500/10 focus:ring-${this.color}-200` : "";
            }
        },

        styleTypeStyles() {
            const typeStyles = {
                primary: "primaryButton",
                bubble: "buttonBubble",
                error: "button-red",
                success: "button-green",
                secondary: "secondaryButton",
                warning: "button-yellow",
                tertiary: "tertiaryButton",
                clear: "clearButton",
                main: "button-gray",
                link: "linkButton",
                info: "button-blue",
                brand: "button-1",
                brand1: "button-2",
            };
            return this.outlined ? " bg-transparent border-none" : typeStyles[this.styleType];
            // return this.outlined || this.styleType === "link" ? " bg-transparent border-none" : typeStyles[this.styleType];
        },
        outlinedStyles() {
            return this.outlined && this.styleType !== "link" ? "bg-transparent hover:bg-opacity-10 active:bg-opacity-20" : "";
        },
    },
};
</script>

<style lang="scss">
.base-button-group {
    @apply flex;
    @apply gap-0;
    button {
        @apply rounded-none border-r;
        &:first-child {
            @apply rounded-l-2xl;
            @apply border-r-0;
            @apply pl-3;
        }

        &:last-child {
            @apply rounded-r-2xl;
        }

        &:not(:first-child):not(:last-child) {
            @apply border-r-0;
            @apply border-l;
        }
    }
}

.shadow-and-inset {
    box-shadow:
        0px 0px 0px 0px rgba(255, 255, 255, 0.3) inset,
        0px 0px 1px rgba(0, 0, 0, 0.3);
}

.bg-blur {
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
}

.buttonBubble {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    @apply border-none;
    i {
        @apply opacity-70;
    }

    @apply transition;
    @apply bg-transparent;
    @apply text-snow;
    @apply bg-blur;
    @apply shadow-and-inset;
    &:hover {
        box-shadow:
            0px 0px 3px 1px rgba(255, 255, 255, 0.3) inset,
            0px 1px 2px rgba(0, 0, 0, 0.3);

        //background: rgba(255, 255, 255, 0.5);
        i {
            @apply opacity-100;
        }
    }
}

.clearButton {
    $color: base;
    @apply capitalize;
    @apply border-none;
    @apply bg-#{$color}-500/10;
    @apply dark:bg-#{$color}-400/20;

    //font-family: 'Inter', sans-serif;
    font-variation-settings:
        "wght" 700,
        "wdth" 100 !important;

    // target the button's innertext in sass
    .button_text,
    i {
        @apply mix-blend-color-burn;
        @apply dark:mix-blend-color-dodge;
    }

    &:hover {
        @apply bg-#{$color}-500/20;
    }

    @apply text-gray-900
  dark:text-#{$color}-300
  dark:hover:text-#{$color}-200;
    @apply transition;
    .button_line {
        @apply bg-#{$color}-500/20;
        @apply mr-3;
        @apply ml-1;
        @apply inline-block;
        @apply h-full;
    }

    .spinner-small {
        width: 20px;
        height: 100%;
        @apply mix-blend-color-burn;
        @apply bg-blend-color-burn;
        @apply dark:mix-blend-color-dodge;
        @apply dark:bg-blend-color-dodge;
        .bounce1,
        .bounce2,
        .bounce3 {
            @apply bg-gray-900/50
      dark:bg-#{$color}-300/50;
            //@apply dark:mix-blend-color-burn;
        }
    }
}

.linkButton {
    text-decoration: none;
    @applty flex;
    @apply text-purple-900
  hover:text-purple-700
  dark:text-base-500
  dark:hover:text-purple-400;
    @apply bg-transparent;
    //@apply font-semibold;
    @apply transition;
    @apply border-none;

    @apply gap-2;
    border: 1px solid transparent !important;

    .spinner-small {
        width: 20px;
        height: 100%;
        @apply mix-blend-hard-light;
        @apply bg-blend-hard-light;
        @apply dark:mix-blend-hard-light;
        @apply dark:bg-blend-hard-light;

        .bounce1,
        .bounce2,
        .bounce3 {
            @apply bg-gray-600/50
      dark:bg-base-200/50;
            //@apply dark:mix-blend-color-burn;
        }
    }
}

.tertiaryButton {
    //@apply shadow-amber-700 shadow;
    // border
    $color: base;
    // sass variable for border-[color]-200
    @apply border-#{$color}-600
  dark:border-#{$color}-600;
    // text
    @apply text-snow
  hover:text-#{$color}-50
  dark:text-#{$color}-300
  dark:hover:text-#{$color}-300;
    //background
    @apply bg-#{$color}-600
  hover:bg-#{$color}-500
  dark:bg-#{$color}-800
  dark:hover:bg-#{$color}-600/80;
}
.brandButton {
    @apply ring-0;
    @apply transition;
    @apply shadow-low;
    @apply hover:shadow-high;
}
.secondaryButton {
    @apply outline-0;
    @apply ring-0;
    @apply transition;
    @apply shadow-low;
    @apply hover:shadow-high;
    @apply dark:shadow-inset;
    @apply dark:hover:shadow-insetHigh;
    @apply dark:hover:translate-y-3;
    border-width: 1px;

    &:hover {
        i {
            @apply transition;
            @apply scale-125;
            @apply opacity-100;
        }
    }

    //@extend .shadow-and-inset!optional;
    //@apply inset-shadow;
    //    box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 1px rgba(0, 0, 0, 0.5);

    // border
    @apply border-gray-200
  hover:border-gray-200
  dark:hover:border-base-500
  active:dark:hover:border-base-500
  dark:border-base-500/50;
    // text

    .button_text,
    i {
        //@apply dark:mix-blend-color-dodge;
        @apply dark:mix-blend-color-dodge;
        @apply text-gray-600
    dark:text-base-200;
        @apply dark:border-base-50;
        @apply dark:mix-blend-hard-light;
    }

    .spinner-small {
        width: 20px;
        height: 100%;
        @apply mix-blend-hard-light;
        @apply bg-blend-hard-light;
        @apply dark:mix-blend-hard-light;
        @apply dark:bg-blend-hard-light;
        .bounce1,
        .bounce2,
        .bounce3 {
            @apply bg-gray-600/50
      dark:bg-base-200/50;
            //@apply dark:mix-blend-color-burn;
        }
    }

    //background
    @apply bg-snow
  hover:bg-gray-50
  dark:bg-base-600/10
  dark:hover:bg-base-600/80;
    @apply dark:bg-blend-color-dodge;
}

.primaryButton {
    // loop through array of colors sky,green,red,yellow,orange,purple
    $color: "green";
    $bgColor: 100;
    $darker: calc($bgColor + 100);
    $bgDark: calc(900 - $bgColor);
    // sass variable for border-[color]-200
    @apply border-#{$color}-#{calc($bgColor + 200)}
  hover:border-#{$color}-#{calc($bgColor + 300)}
  dark:border-#{$color}-#{calc($bgColor + 400)}
  dark:hover:border-#{$color}-#{calc($bgColor + 600)};
    // text
    @apply text-#{$color}-#{$bgDark}
  hover:text-#{$color}-#{calc($bgDark + 100)}
  dark:text-#{$color}-300
  dark:hover:text-#{$color}-300;
    //background
    @apply bg-#{$color}-#{$bgColor}/50
  hover:bg-#{$color}-#{$darker}/50
  dark:bg-#{$color}-#{$bgDark}/50
  dark:hover:bg-#{$color}-#{$bgDark}/10;

    i,
    .spinner-small {
        @apply mix-blend-color-burn;
        @apply bg-blend-color-burn;
        @apply dark:mix-blend-color-dodge;
        @apply dark:bg-blend-color-dodge;
    }

    .spinner-small {
        width: 20px;
        height: 100%;

        .bounce1,
        .bounce2,
        .bounce3 {
            @apply bg-#{$color}-#{$bgDark}
      dark:bg-#{$color}-300/50;
            //@apply dark:mix-blend-color-burn;
        }
    }
}

$colors: "sky", "green", "red", "yellow", "orange", "purple", "gray", "blue", "indigo", "violet", "pink", "rose", "pink";
$bgColor: 100;
@each $color in $colors {
    .button-#{$color} {
        $darker: calc($bgColor + 100);
        $bgDark: calc(900 - $bgColor);

        @apply border-#{$color}-#{calc($bgColor + 200)}
    hover:border-#{$color}-#{calc($bgColor + 300)}
    dark:border-#{$color}-#{calc($bgColor + 400)}
    dark:hover:border-#{$color}-#{calc($bgColor + 600)};

        @apply text-#{$color}-#{$bgDark}
    hover:text-#{$color}-#{calc($bgDark + 100)}
    dark:text-#{$color}-300
    dark:hover:text-#{$color}-300;

        @apply bg-#{$color}-#{$bgColor}/50
    hover:bg-#{$color}-#{$darker}/50
    dark:bg-#{$color}-#{$bgDark}/50
    dark:hover:bg-#{$color}-#{$bgDark}/10;
        .spinner-small {
            width: 20px;
            height: 100%;
            @apply dark:bg-blend-color-dodge;
            .bounce1,
            .bounce2,
            .bounce3 {
                @apply bg-#{$color}-#{$bgDark}
        dark:bg-#{$color}-300;
                @apply dark:mix-blend-color-burn;
                @apply dark:bg-blend-color-burn;
            }
        }
    }
}

.button_loader {
    //@apply mx-1;
    @apply block;
    //@apply px-0;
    @apply pb-0;
    @apply items-center justify-center h-auto block;
    //@apply mix-blend-color-burn;
    transform: translateY(-10%);
}

.button_line {
    @apply bg-base-500/20;

    @apply mr-3;
    @apply ml-1;
    @apply inline-block;
    @apply h-auto;
    @apply -my-5;
    //position: absolute;
}
</style>
<!--            <div-->
<!--                class="f aic jcc bg-blur border-light br-5 base-900 px-2 py-2 text-base-50 hover:bg-snow hover:text-base-900 hover:text-base-900"-->
<!--                style="box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5), inset 0px 0px 3px rgba(255, 255, 255, 0.5)"-->
<!--                @click.prevent="fetchingNewImage(), stabilityPresentationImage(index, section.enhancedImage)">-->
<!--                <i class="fas fa-image f-20 text-shadow text-base-50 hover:text-base-900"></i>-->
<!--            </div>-->
