<template>
    <div class="brand-bg">
        <footer class="theme1" aria-labelledby="footer-heading">
            <h2 id="footer-heading" class="sr-only">Footer</h2>
            <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <!--                <img class="h-7" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Company name" />-->
                    <div class="p-3">
                        <div class="mw-150">
                            <brand-logo-component class="width-75 block"></brand-logo-component>
                        </div>
                    </div>
                    <div class="mt-16 grid grid-cols-4 gap-8 xl:col-span-2 xl:mt-0">
                        <FooterLinks :navigation="navigation" />
                    </div>
                </div>
                <div>
                    <FooterNewsletter />
                </div>
            </div>
            <FooterBottom :navigation="navigation" />
        </footer>
    </div>
</template>

<script>
// import FooterNewsletter from "./FooterNewsletter.vue";
// import FooterBottom from "./FooterBottom.vue";
import FooterLinks from "@/views/FooterLinks.vue";
import FooterNewsletter from "@/views/FooterNewsletter.vue";
import FooterBottom from "@/views/FooterBottom.vue";
import Logo from "@/components/CoreUI/navigaion/logo.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";

export default {
    name: "BrandFooter",
    components: {
        BrandLogoComponent,
        Logo,
        FooterBottom,
        FooterNewsletter,
        FooterLinks,
        // FooterBottom,
    },
    data() {
        return {
            navigation: {
                solutions: [
                    { name: "Marketing", href: "#" },
                    { name: "Analytics", href: "#" },
                    { name: "Commerce", href: "#" },
                    { name: "Insights", href: "#" },
                ],
                support: [
                    { name: "Pricing", href: "#" },
                    { name: "Documentation", href: "#" },
                    { name: "Guides", href: "#" },
                    { name: "API Status", href: "#" },
                ],
                company: [
                    { name: "About", href: "#" },
                    { name: "Blog", href: "#" },
                    { name: "Jobs", href: "#" },
                    { name: "Press", href: "#" },
                    { name: "Partners", href: "#" },
                ],
                legal: [
                    { name: "Claim", href: "#" },
                    { name: "Privacy", href: "#" },
                    { name: "Terms", href: "#" },
                ],
                social: [
                    { name: "Facebook", href: "#", icon: "FacebookIcon" },
                    { name: "Instagram", href: "#", icon: "InstagramIcon" },
                    { name: "Twitter", href: "#", icon: "TwitterIcon" },
                    { name: "GitHub", href: "#", icon: "GitHubIcon" },
                    { name: "YouTube", href: "#", icon: "YouTubeIcon" },
                ],
            },
        };
    },
};
</script>
