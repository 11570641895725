<template>
    <div class="x f p-relative br-5 aic jcc" style="height: 0px; padding-bottom: 60%">
        <!--    <div :ref="`slide-sm-${id}${index}`" class="x f p-relative br-5 aic jcc" style="height: 0px; padding-bottom: 60%">-->
        <div class="row no-gutters f p-absolute p-fill aic jcc m-0 p-0">
            <div class="col-6 p-2 pl-2">
                <!--            <div :ref="`text-sm-${id}${index}`" :style="`color:${color}`" class="col-6 p-2 pl-2">-->
                <p v-if="title" class="d-block small fwb line-height-smaller f-11 mb-0 mb-1">{{ title }}</p>
                <p v-if="text" class="d-block o-5 line-height-smaller mb-0" style="font-size: 6px !important">{{ text.slice(0, 150) }}</p>
            </div>
            <div class="col-6 y">
                <!--            <div :ref="`image-sm-${id}${index}`" class="col-6 y">-->
                <img v-if="imageSrc" :src="imageSrc" class="x y o-cover" />
                <div v-else class="x y animated loading-pulse o-2"></div>
            </div>
        </div>
    </div>
</template>
<script>
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import themeMixins from "@/mixins/StyleMixins/themeMixins";
import { mapGetters } from "vuex";
export default {
    name: "SlideRightMini",
    mixins: [SlideStyleMixin, themeMixins],
    props: {
        index: {},
        text: {},
        title: {},
        id: {},
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        slide() {
            return this.document?.sections[this.index];
        },
        color() {
            let theme = this.document?.theme?.colors?.primary;
            if (theme) {
                return this.complimentaryColor(this.document.theme.colors.primary);
            }
            return "";
        },
        imageSrc() {
            if (this.slide?.imageURL) {
                return this.slide.imageURL;
            }
            if (Array.isArray(this.slide?.images) && this.slide.images.length > 0) {
                return this.slide.images[0].url;
            }
            return null;
        },
    },
    mounted() {
        // if (this.document.theme) {
        //     let t = this.document.theme.colors || false;
        //     if (t) this.textTheme();
        // }
    },
};
</script>
