<template>
    <div class="border-light rounded-full pl-3 pr-1 transition-all glowingHalo py-1 w-auto d-inline-flex pr-0 gap-3 aic duration-10 !shadow-purple-600">
        <span class="mb-0 line-height-smaller p-0 m-0" v-html="updateLoadingMessage"></span>
        <div class="c-25 border-light f aic jcc transition-all">
            <div class="animated zoomIn duration-3 f aic jcc">
                <i class="far fa-sync fa-spin transition-1 o-5 duration-4 f-13 ease-back"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "EmailStatusLoader",
    props: {
        updateLoadingMessage: {},
    },
};
</script>
