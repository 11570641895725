<template>
    <div class="mb-0 pb-0 pb-8 brand-bg x">
        <div>
            <slot :action="selectedFunction?.name" :result="result" :sections="result?.sections" :show-loader="showLoader"></slot>
        </div>
        <!--        <FunctionHelper />-->
        <div class="x min-height-500 y d-block z-1 bg-red">
            <div class="container-slim mw-1000 mb-2 block mt-4 fixed z-100 bottom left right x" style="z-index: 1000000">
                <div class="x mx-auto text-center mb-3">
                    <Transition mode="out-in" name="fade-up">
                        <StreamingStatusLoader v-if="!done && showLoader" :result="result"></StreamingStatusLoader>
                        <StreamingStatusDone v-else-if="done && showLoader" />
                    </Transition>
                </div>
                <transition name="fade-up">
                    <div v-if="showInput" class="x f aic jce gap-3 mb-2 mt-3">
                        <BaseInput :model-value="newURL" iclass="f-30 x !bg-snow !f-20" class="x pl-3" inline placeholder="Enter a URL" @update:modelValue="newURL = $event" @keydown.enter="createNew(newURL)" />
                        <BaseButton class="" icon="fas fa-sync" icon-only label="" size="xxs" rounded style-type="secondary" @click.prevent="update" />
                        <BaseButton class="" icon="fas fa-trash" icon-only label="" size="xxs" rounded style-type="secondary" @click.prevent="reset" />
                    </div>
                </transition>
                <div class="p-fixed bottom left right bg-gradient-to-b to-red-500 height-200 z-0" :style="`background:linear-gradient(180deg, rgba(255,255,255,0) 0%, ${styleGuide?.baseBackground} 70%);`"></div>
                <StreamComponentInput class="relative" :result="result" @call-function="callDynamicFunction" />
            </div>
        </div>
    </div>
</template>

<script>
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import StreamingStatusLoader from "@/components/generative_templates/Loaders/StreamingStatusLoader.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import StreamComponentInput from "@/components/generative_templates/StreamComponentInput.vue";
import { mapGetters } from "vuex";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import PreviewMessages from "@/components/styleGuide/debugging/PreviewMessages.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import StreamingStatusDone from "@/components/generative_templates/StreamingStatusDone.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";

export default {
    name: "TemplateDynamicFunctionContent",
    components: { DynamicObjectViewer, StreamingStatusDone, CollapsableMenuItem, PreviewMessages, StreamComponentInput, BaseButton, StreamingStatusLoader, BaseInput },
    props: {
        system: {
            type: String,
            default: "This is a system message",
        },
        inCampaign: {
            type: Boolean,
            default: false,
        },
        contextMessages: {
            type: Object,
            default: () => undefined,
        },
        imageArray: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            newURL: "",
            hovered: false,
            done: false,
            showLoader: false,
        };
    },
    computed: {
        ...mapGetters("stream", ["result", "userInput", "messages", "functions", "selectedFunction", "showInput", "messagesWithContext", "model", "temperature", "generate", "advanced", "campaignData"]),
        ...mapGetters("styleGuide", ["styleGuide"]),
        functionName() {
            return this.selectedFunction.type;
        },
    },
    watch: {
        generate: {
            handler(val) {},
        },
        advanced: {
            handler(val) {
                if (val) return this.updateStreamProp("options.model", gpt4);
                return this.updateStreamProp(
                    "options.model",
                    gpt3, //gpt316
                );
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            // this.updateMessages();
        });
    },
    methods: {
        createNew(url) {
            this.$emit("createNew", { url, id: null });
        },
        update() {
            this.$emit("update");
        },
        reset() {
            this.$emit("reset");
        },
        updateMessages(message) {
            let messages = this.messagesWithContext;
            this.updateStreamProp("messages", messages);
            if (message) this.$store.dispatch("stream/addMessage", userMessage(message));
        },
        addMessage(payload) {
            this.$store.dispatch("stream/addMessage", payload);
        },
        hideLoader() {
            setTimeout(() => (this.showLoader = false), 5000);
        },

        async saveToCampaigns(obj) {
            let {
                //
                name,
                brand = this.$route.params.id,
                result,
                itemIndex,
                identifier,
                campaignId,
                campaignIndex,
                save,
                messages,
                prompt,
                function_call,
                model,
                type,
            } = obj;
            let updatedObject = {
                result: {
                    ...result,
                    object_type: type,
                    campaignId,
                    itemIndex,
                    campaignIndex,
                    messages,
                },
                brand,
                created: new Date(),
                updated: new Date(),
                name: name || "",
                campaignId,
                campaignIndex,
                object_type: type,
                identifier,
                itemIndex,
            };
            // remove null and undefined values
            // remove null and undefined values
            updatedObject = Object.fromEntries(Object.entries(updatedObject).filter(([key, value]) => value != null));
            updatedObject.result = Object.fromEntries(Object.entries(updatedObject.result).filter(([key, value]) => value != null));
            let campaign = await saveToFirebase("campaigns", updatedObject);
            return camapign;
        },
        clearInput() {
            this.updateStreamProp("options.userInput", "");
        },
        async callDynamicFunction(mods, generate) {
            let { input } = mods;
            console.log("mods", mods);
            if (input.length > 0) this.pushToStoreMessage(userMessage(input));
            let { brand = this.$route.params.id, itemIndex, campaignId, campaignIndex, identifier } = this.generate;
            let { name, type } = this.selectedAction;
            // this.updateMessages(this.userInput);
            let setup = {
                params: {
                    input: input,
                    model: this.model,
                    messages: this.streamMessages,
                    temperature: this.temperature,
                    function_call: name,
                    length: 3000,
                    persistChat: true,
                    identifier: identifier,
                },
                campaignData: {
                    brand: brand,
                    itemIndex: itemIndex,
                    campaignId: campaignId,
                    campaignIndex: campaignIndex,
                    type: type,
                    object_type: type,
                },
                storage: {
                    // storeKey: "stream",
                    // prop: "campaignData.result",
                    prop: "campaignData.result",
                    localHandle: "result",
                    messageIndex: null,
                    save: true,
                    returnTo: "result",
                },
            };
            let obj = { ...setup.params, ...setup.campaignData, ...setup.storage, ...mods };
            obj.messageIndex = obj?.messages?.length || undefined;
            obj.object_type = obj.type;
            if (obj.identifier && obj.prop) obj.returnTo = obj.prop + "." + obj.identifier;
            console.log(obj);
            // obj.returnTo = `campaignData.result.schedule_items.${obj.campaignIndex}.campaign_items.${obj.itemIndex}.result`;
            // return;
            await this.streamFunction(obj);
        },
        beforeStarting() {
            this.beforeStartingGeneration();
            this.clearInput();
        },
        async afterCompletion(obj) {
            let { input, save, messages, prompt, function_call, model, system, silent, result, type } = obj;
            this.hideLoader();
            if (save) await this.saveToCampaigns(obj);
        },
        streamToRouter(obj) {
            let { storeKey, prop = "result", json } = obj;
            if (storeKey === "stream") this.updateStreamProp(prop, obj);
            if (storeKey === "styleGuide") this.updateStyleGuideProp(prop, obj);
            if (storeKey === "section") this.updateSection(prop, obj);
        },
    },
};
</script>

<style lang="scss" scoped>
strong {
    font-weight: bold;
}
</style>
