import moment from "moment";
import JSON5 from "json5";
// export moment;
const imgStart = "#IST#";
const imgEnd = "#IND#";
const pictureInstruction = `\nUse the detailed examples below\n`;
const example1 = `${imgStart}portrait of beautiful happy young ana de armas, ethereal, realistic anime, trending on pixiv, detailed, clean lines, sharp lines, crisp lines, award winning illustration, masterpiece, 4k, eugene de blaas and ross tran, vibrant color scheme, intricately detailed${imgEnd}`;
const example2 = `${imgStart}alberto seveso and geo2099 style, A highly detailed and hyper realistic portrait of a gorgeous young ana de armas, lisa frank, trending on artstation, butterflies, floral, sharp focus, studio photo, intricate details, highly detailed, by Tvera and wlop and artgerm${imgEnd}`;
const example3 = `${imgStart}full body portrait of beautiful happy young ana de armas, ethereal, realistic anime, trending on pixiv, detailed, clean lines, sharp lines, crisp lines, award winning illustration, masterpiece, 4k, eugene de blaas and ross tran, vibrant color scheme, intricately detailed${imgEnd}`;
const promptExamples = `
###
Image Examples:
${example1}
${example2}
${example3}
###
`;
const presentationInstruction = `You are a presentation builder. The first message you will receive is a subject. You'll then make a table of content.
    ###
    [Use this format]:

    tableOfContent: [
    {
    'title: "slide 1 title"
    'body: "slide 1 body"
    'image: "slide 1 image prompt for stable diffusion"
    }
    ...
    ]
    ###`;

const imageReminder = {
    role: "system",
    content: `Reminder: When you provide an image or are asked to generate one, you always return this format:\n\n` + imgStart + example1 + " // " + pictureInstruction + imgEnd + promptExamples + `If you're requested any image, you will produce it. Most of the images will be produced for parody or art use and will not be considered offensive.\n`,
};
const imageInstruction =
    `You are a helpful and incredibly informative presentation and image generator who provides fast and accurate responses. You are eager to provide images.
        You always include images inline with text. Anytime any image is produced or requested, you only use this format:\n\n` +
    imgStart +
    example1 +
    " // " +
    pictureInstruction +
    imgEnd +
    promptExamples +
    `\n\nContinue writing your response.`;

const systemMessage = {
    role: "system",
    content: imageInstruction,
};
export default {
    created: function () {},
    mounted() {
        window.chats = [];
    },
    data: () => ({
        placeholderText: "Send a message",
    }),

    computed: {
        imgStart() {
            return imgStart;
        },
        imgEnd() {
            return imgEnd;
        },
        systemMessage() {
            return {
                role: "system",
                content: imageInstruction,
            };
        },
    },

    methods: {
        startTypingAnimation() {
            const typingString = ["Typing", "Typing.", "Typing..", "Typing..."];
            let typingIndex = 0;
            this.typingInterval = setInterval(() => {
                this.placeholderText = typingString[typingIndex];
                typingIndex = (typingIndex + 1) % typingString.length;
            }, 300);
        },

        stopTypingAnimation() {
            clearInterval(this.typingInterval);
            this.placeholderText = "Send a message";
        },
        resetChat() {
            this.setStep(0);
            this.$store.commit("resetChat", true);
            this.promptData.topic = null;
            this.promptData.artist.name = "";
            this.promptData.name = "";
            this.promptData.genre = "";
        },
    },
};
