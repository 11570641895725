<template>
    <span v-if="showIcon" class="flex-shrink-0 text-left" :class="{ 'mr-3 text-left': textAlign === 'left', 'mb-3 !text-center mx-auto': textAlign === 'center' }">
        <i v-if="fontAwesomeClass" :class="['fal', fontAwesomeClass, 'fa-fw', 'text-xl', 'fal', 'mt-1']"></i>
        <i v-else-if="iconIsFontAwesomeString" :class="['fal', iconIsFontAwesomeString, 'fa-fw', 'text-xl', 'fal', 'mt-1']"></i>
        <span v-else-if="emoji" class="text-5xl">{{ emoji }}</span>
        <span v-else-if="feature?.icon && !feature.icon?.startsWith(':')" class="text-3xl">{{ feature.icon }}</span>
    </span>
</template>
<script>
export default {
    name: "FeatureIcon",
    props: {
        emoji: {},
        feature: { type: Object }, // Change the type to Object
        fontAwesomeClass: {},
        iconIsFontAwesomeString: {},
        showIcon: {},
        textAlign: {},
    },
};
</script>
