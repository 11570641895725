import { Back, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import json_helpers from "@/mixins/ai/json_helpers";
const { validateJSON } = json_helpers;
gsap.registerPlugin(ScrollTrigger);
const { timeline, set, to, from } = gsap;
export default {
    data() {
        return {
            colorPalette: ["#264653", "#2A9D8F", "#E9C46A", "#F4A261", "#E76F51", "#D8E2DC", "#FFE5D9", "#FFCAD4", "#9D8189", "#505A5B"],
        };
    },
    computed: {},
    methods: {
        markers({ show = false, inset = 20 }) {
            if (!show) return false;
            let randomColor = this.colorPalette[Math.floor(Math.random() * this.colorPalette.length)];
            return { startColor: randomColor, endColor: randomColor, fontSize: 19, indent: inset };
        },
        animateSentence(timeline, header, sentence, startSize, sizeDecrementPercent, startDelay, delayIncrement, animate) {
            const words = sentence.split(" ");
            let currentText = "";
            let currentSize = startSize;
            let currentTime = startDelay;
            const fadeInDuration = 0.1; // Duration of fade-in animation
            const fadeOutDuration = 0.1; // Duration of fade-out animation

            words.forEach(word => {
                currentText += (currentText ? " " : "") + word;
                if (animate) {
                    let oldSize = currentSize;
                    timeline.fromTo(
                        header,
                        { text: currentText, opacity: 1, fontSize: oldSize },
                        {
                            opacity: 1,
                            fontSize: currentSize,
                            duration: fadeInDuration,
                            ease: "back.out(2)",
                        },
                        currentTime,
                    );
                    timeline.set(header, { text: currentText }, currentTime + fadeInDuration);
                    timeline.to(
                        header,
                        {
                            opacity: 0,
                            duration: fadeOutDuration,
                            ease: "back.in",
                        },
                        currentTime + fadeInDuration + delayIncrement - fadeOutDuration,
                    );

                    currentSize -= currentSize * (sizeDecrementPercent / 100); // Decrease the font size by a percentage
                    currentTime += delayIncrement; // Increment the time for the next animation
                } else {
                    timeline.set(header, { text: currentText, fontSize: currentSize }, currentTime);
                    currentSize -= currentSize * (sizeDecrementPercent / 100); // Decrease the font size by a percentage
                    currentTime += delayIncrement; // Increment the time for the next animation
                }
            });
        },
    },
};
