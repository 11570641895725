<template>
    <div class="p-absolute p-fill f aic jcc overflow-hidden" :style="`background:${theme.backgroundColor};color:${theme.color}!important`" v-if="theme">
        <span :style="applyStyle(randomTextStyle)" class="d-block p-3 text-center">
            <span class="line-clamp-3">{{ description }}</span>
        </span>
        <div class="p-absolute bottom f br-50 aic jcc mx-auto mb-3 gap-1"></div>
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import chroma from "chroma-js";
export default {
    name: "StyleGuideDivTheme",
    mixins: [styleGuideMixin],
    computed: {},
    methods: {
        bgColor(item) {
            console.log(item);
            let newColor = chroma(item).darken();
            console.log(newColor.css());
            return newColor.css();
        },
    },
    props: {
        description: {
            type: String,
            required: true,
        },
        theme: {
            type: Object,
            required: true,
        },
    },
};
</script>
