function completeStep(store, stepName, data) {
    try {
        const index = store.state.presentation.progress.findIndex(item => item.name === stepName);
        if (index !== -1) {
            store.dispatch("presentation/markProgressStepComplete", index);
            if (data) {
                store.dispatch("presentation/addDataToStep", { index: index, data: data });
            }
        } else {
            console.log(`Step ${stepName} not found`);
        }
    } catch (error) {
        console.error("markProgressStepComplete", error.message, stepName);
    }
}

export default completeStep;
