<template>
    <div class="py-2 br-10 bg-snow mw-400 border-light shadow text-gray-900">
        <div class="f border-bottom pb-2 gap-3 px-2">
            <div class="f gap-1 aic jcb x">
                <div v-for="icon in styleTypes" class="x" v-html="icon.html" @click.prevent="updateStyle(icon.id)"></div>
            </div>
            <div class="f x">
                <div class="f gap-1 aic justify-content-stretch x">
                    <div v-for="icon in textAlignTypes" class="x" style="width: 100%" v-html="icon.html" @click.prevent="updateTextAlign(icon.id)"></div>
                </div>
            </div>
        </div>
        <UpdateFontPicker :fonts="fonts" title="Header Font" @update-font="updateHeaderFont" />
        <UpdateFontPicker :fonts="fonts" title="Body Font" @update-font="updateBodyFont" />
        <div class="f flex-wrap px-3 gap-1 mb-2 border-bottom py-3 overflow-x-scroll scrollbar-hide">
            <template v-for="color in colors">
                <!--                <div :style="`background:${color.color}`" class="c-40 border-light f aic jcc" @click="updateSectionColor(color.color)">-->
                <!--                    &lt;!&ndash; @click="updateColors(color)">&ndash;&gt;-->
                <!--                    <span :style="`color:${findContrastingColor(color?.color)}`" class="fwb f-15">Aa</span>-->
                <!--                </div>-->
                <BaseButton icon-only class="f aic jcc c-40" :style="`background:${color?.color}`" :tooltip="color.source" @click="updateSectionColor(color?.color)">
                    <span :style="`color:${findContrastingColor(color?.color)}`" class="fwb f-15">Aa</span>
                </BaseButton>
            </template>
        </div>
        <div class="p-3" v-if="section?.features">
            <h6>Feature Section</h6>
            <div class="f mx-auto mb-5 aic gap-1 flex-wrap mw-450">
                <!--                <div v-for="(item, index) in 10" :class="`theme${index} border f aic jcc fwb`" class="c-40" @click.prevent="setThemeClass(index)">Aa</div>-->

                <div v-for="(item, index) in 2" :class="``" class="c-35 border f aic jcc f-15 fwb" @click.prevent="setListStyle(item)">{{ index }}</div>
                <div v-for="(item, index) in 12" :class="`theme${index}`" class="c-35 border f aic jcc" @click="setListStyle(1), setThemeClass(index)">
                    <span class="fwb f-15">Aa</span>
                </div>
            </div>
        </div>
        <div class="px-3 py-0">
            <ToggleButton :height="24" :vuex-key="`stream.result.sections.${index}.blend`" :width="40" class="f jcb f-15 my-0" label="Blend Section" module="stream" />
        </div>
        <slot></slot>
    </div>
</template>
<script>
import ToggleButton from "@/components/CoreUI/ToggleButtonSimple.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import UpdateFontPicker from "@/components/generative_templates/Editing/UpdateFontPicker.vue";
import { mapGetters } from "vuex";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "SectionSettings",
    components: { BaseButton, UpdateFontPicker, ToggleButton },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin, styleGuideMixin],
    props: {
        index: {
            type: Number,
            default: 0,
            required: true,
        },
        section: {
            type: Object,
            default: () => {
                return {
                    style: "imageLeft",
                    imageStyle: "round",
                    gradient: true,
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    font: "Helvetica",
                    headerFont: "Helvetica",
                    bodyFont: "Helvetica",
                    textAlign: "left",
                };
            },
            required: true,
        },
        colors: {
            type: Array,
            default: () => {
                return ["#ffffff", "#000000", "#ff0000", "#00ff00", "#0000ff", "#ffff00", "#00ffff", "#ff00ff"];
            },
        },
        identifier: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tempBackground: "",
            textAlignTypes: [
                {
                    id: "left",
                    html: '<div class="f aic jcc border-light p-1 py-2 x w-100"><i class="fas fa-align-left o-5"></i></div>',
                },
                {
                    id: "center",
                    html: '<div class="f aic jcc border-light p-1 py-2 x w-100"><i class="fas fa-align-center o-5"></i></div>',
                },
                {
                    id: "right",
                    html: '<div class="f aic jcc border-light p-1 py-2 x w-100"><i class="fas fa-align-right o-5"></i></div>',
                },
            ],
            imageStyles: [
                {
                    id: "round",
                    icon: "fas fa-circle",
                },
                {
                    id: "square",
                    icon: "far fa-square",
                },
                {
                    id: "normal",
                    icon: "fas fa-square",
                },
            ],
            styleTypes: [
                {
                    id: "imageLeft",
                    name: "Image Right",
                    icon: "fas fa-align-right",
                    html: `<div class="f aic jcc border-light p-2 gap-2"><i class="fas fa-image o-5"></i><i class="fas fa-align-left o-5 f-11"></i></div>`,
                },
                {
                    id: "fullBleed",
                    name: "Full Bleed",
                    icon: "fas fa-align-justify",
                    html: `<div class="f aic jcc border-light p-2 px-2 px-3 flex-shrink-0 relative gap-2"><div class="p-absolute p-fill f fc aic jcc z-1" style="gap:2px;"><div class="bg-gray-900 o-5" style="height:2px;width:20px;"></div><div class="bg-gray-900 o-5" style="height:2px;width:10px;"></div></div><i class="fas fa-image o-1"></i></div>`,
                },
                {
                    id: "imageRight",
                    name: "Image Left",
                    icon: "fas fa-align-left",
                    html: `<div class="f aic jcc border-light p-2 gap-2"><i class="fas fa-align-right f-11 o-5"></i><i class="fas fa-image o-5"></i></div>`,
                },
            ],
        };
    },
    computed: {
        ...mapGetters("styleGuide", ["fonts"]),
    },
    methods: {
        updateImageStyle(imageStyle) {
            this.updateStreamProp(`result.sections.${this.index}.imageStyle`, imageStyle);
        },
        updateSectionColor(color) {
            this.$emit("updateSectionColor", color);
        },
    },
};
</script>
