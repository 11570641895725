import { mapActions } from "vuex";
import { imgEnd, imgStart } from "@/mixins/Chat/chatStrings";
export default {
    created: function () {},
    mounted() {},
    data() {
        const start = "#IST#";
        const end = "#IND#";
        return {
            generate_image: {
                name: "Generate Image",
                command: "generate_image",
                start: start,
                end: end,
                kind: "image",
                role: "assistant",
                args: { image_prompt: "" },
                description: `Generate images by describing the image enclosed between ${start} and ${end} tags.`,
                // description: "Generate images by providing a description 'image_description' argument.",
                show: true,
                newMessage: true,
                requiresClose: true,
                resources: ["Generate images"],
                exampleUses: [
                    `Here's a picture of a cat: ${start}A cat cute sitting on a couch, dramatic lighting. Editorial photograph. magazine cover.${end}`,
                    // "Here's a picture of a cat: [IST]A cat cute sitting on a couch, dramatic lighting. Editorial photograph. magazine cover.[IND]",
                ],
            },
            // {
            //     name: "Generate an Image",
            //     command: "generate_image",
            //     kind: "image",
            //     start: "[IST]",
            //     end: "[IND]",
            //     role: "image_description",
            //     args: { image_prompt: "" },
            //     description: "Generate images by providing a description 'image_description' argument.",
            //     show: false,
            //     newMessage: true,
            // },
            // {
            //     name: "Generate Image",
            //     command: "generate_image",
            //     start: "[IST]",
            //     end: "[IND]",
            //     kind: "image",
            //     role: "assistant",
            //     args: { image_description: "" },
            //     description: "Generate images by describing the image enclosed between [IST] and [IND] tags.",
            //     show: false,
            //     newMessage: true,
            // },
        };
    },
    computed: {},
    methods: {
        extractPrompt1(m) {
            const imageRegex = new RegExp(`${imgStart}(.+?)${imgEnd}`, "gms"); // Regular expression to match image tags
            const prompt = m.replace(imageRegex, "").trim(); // Remove the image tag(s) from the message and trim whitespace
            console.error(prompt);
            return prompt;
        },
        imageLoaded(index, images) {
            console.error("Image loaded", index, images);
            this.messages[index].images = images;
            this.messages[index].imageURL = null;
            this.saveChat();
            return;
        },
        async generateImages(args, name, message, index) {
            console.error("Generating Images", args);
            const prompt = args.image_prompt;
            if (prompt) {
                let finalPrompt = `${imgStart} ${prompt} ${imgEnd}`;
                await this.$store.dispatch("chat/addMessageStore", aiMessage(finalPrompt, "image"));
                return finalPrompt;
            }
            return;
            // Send the image URL to the chat
        },
        isImage(message) {
            let actions = this.actionsFiltered;
            let content = message.content;
            if (content) {
                if (message.images || message.imageURL) {
                    return true;
                }
                let action = actions.find(action => {
                    return content.includes(action.start) || content.includes(action.end);
                });
                // get the image action
                if (content.startsWith("#IST#") || content.startsWith("[IST]")) {
                    console.error("contains image: OLD VERSION");
                    return true;
                } else {
                    return false;
                }
                if (action.kind === "image") {
                    return action.kind === "image";
                }
                // return false;
            }
            return false;
        },
    },
};
