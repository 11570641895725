<template>
    <div class="flex items-center ais border-bottom gap-4 x overflow-hidden z-0 relative" v-if="message.content || tool">
        <dt class="py-2 min-w-[60px] max-w-[60px]">
            <div
                class="text-xs px-1.5 py-1 fwsb text-center select-none hover:border-light hover:-translate-y-0.5 hover:translate-x-0.5 transition-all ease-elastic cursor-pointer duration-2"
                @click="toggleTruncation"
                :class="{ 'text-blue-500 bg-blue-100': role === 'system', 'text-green-400 bg-green-100': role === 'assistant', 'text-indigo-500 bg-indigo-100': role === 'user', 'f-13': role.length > 5 }">
                {{ role }}
            </div>
        </dt>
        <dd class="text-gray-700 text-xs x relative" :class="{ 'pb-3': !isTruncated }">
            <div v-if="args" class="pb-2" :class="{ 'max-h-[70px] min-h-[70px] o-6': isTruncated }">
                <div class="fwsb text-capitalize f-17 border-b border-black pb-2" v-if="toolName">{{ toolName }}</div>
                <ObjectViewer v-if="args" :data="args" />
                <template v-if="args?.sections?.length > 0 && !isTruncated">
                    <email-sections :sections="args.sections"></email-sections>
                </template>
            </div>

            <div class="z-1 relative" v-else-if="message?.content" :class="{ 'whitespace-pre-line': !isTruncated, 'line-clamp-3 o-7': isTruncated }">
                <div class="x relative" v-if="!isTruncated">
                    <Markdown v-if="message?.content" :content="message.content" />
                </div>
                <template v-else>{{ displayedContent }}</template>
            </div>
            <div v-if="shouldTruncate" class="absolute bottom left right pt-4 p-2 bg-gradient z-3 bg-gradient-to-t from-30% to-100% from-snow block z-2 x">
                <a v-if="shouldTruncate" class="cursor-pointer text-black-500 fwb hover:underline my-0" @click="toggleTruncation">
                    {{ isTruncated ? "Show more" : "Show less" }}
                </a>
            </div>
        </dd>
    </div>
</template>

<script>
import Markdown from "@/components/chat/specialMessages/Markdown.vue";
import ObjectViewer from "@/components/Admin/ObjectViewer.vue";
import EmailSections from "@/components/Admin/EmailSections.vue";

export default {
    name: "MessageItem",
    components: { EmailSections, ObjectViewer, Markdown },
    props: {
        message: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isTruncated: true,
            truncateLength: 200,
        };
    },
    computed: {
        args() {
            let args = this.tool?.arguments || {};
            if (typeof args === "string") {
                try {
                    args = JSON.parse(args);
                } catch (e) {
                    args = {};
                }
            }
            if (args && Object.keys(args).length === 0) return false;
            return args || false;
        },
        toolName() {
            const toolName = this.tool?.name || "";
            let fixed = toolName.replace(/([A-Z])/g, (match, offset) => (offset === 0 ? match : " " + match));
            fixed = fixed.toLowerCase().replace(/(^|\s)\S/g, t => t.toUpperCase());
            // remove underscores
            fixed = fixed.replace(/_/g, " ");
            return fixed;
        },
        role() {
            return this.message?.role || "";
        },
        tool() {
            return this.message?.function_call || {};
        },
        shouldTruncate() {
            if (!this.message?.content) {
                if (this.args) return true;
            }
            return this.message?.content.length > this.truncateLength;
        },
        displayedContent() {
            if (this.isTruncated) {
                return this.message?.content.slice(0, this.truncateLength) + "...";
            }
            return this.message?.content;
        },
    },
    methods: {
        toggleTruncation() {
            this.isTruncated = !this.isTruncated;
        },
    },
};
</script>
