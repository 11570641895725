import { getCurrentInstance } from "vue";
import { updateDropdownValue, updateToggleValue } from "./StoreHelpers";
import streamingMixin from "@/mixins/ai/streamingMixin";
import { debounce } from "lodash";
import chatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import router from "@/routes";
import mixins from "@/mixins/mixins";
import consoleMixin from "@/mixins/Tools/consoleMixin";
import tasks from "@/store/TaskStore";
import agents from "@/store/AgentStore";
import memory from "@/store/MemStore";
import { db } from "@/firebase";
import { query, collection, where, orderBy, onSnapshot, addDoc, limit, startAfter, doc, Timestamp, getDoc, getDocs } from "firebase/firestore";
import audioStore from "@/store/AudioStore";
// import MemoryStore from "@/store/MemoryStore";
import user from "@/store/UserStore";
import imageStore from "@/store/ImageStore";
import presentationStore from "@/store/PresentationStore";
import webRequest from "@/mixins/ai/web_request";
import { gpt3, gpt316, gpt4 } from "@/mixins/default_models";

export default {
    namespaced: true,
    modules: {
        tasks,
        memory,
        agents,
    },
    state() {
        return {
            inputHeight: 0,
            threads: [],
            runs: [],
            topic: null,
            showDebugger: false,
            models: [],
            owner: null,
            followUps: false,
            chatTitle: null,
            createdAt: null,
            updatedAt: null,
            activeChatIds: [],
            questions: [],
            messages: [],
            commands: false,
            fastBubbles: true,
            id: "",
            model: gpt3,
            showMobileChat: false,
            chatLoading: false,
            chatItems: [],
            messagesForAPI: [],
            chats: [],
            ownerChats: [],
            lastVisible: null,
            lastMemoryIndex: 0,
            lastSummaryIndex: 0,
            summary: [],
            threadId: null,
            assistantId: null,
            assistants: [],
            prompts: [],
        };
    },
    mutations: {
        updateToggleValue,
        updateDropdownValue,
        addMessage(state, message) {
            state.messages.push(message);
        },
        setModels(state, models) {
            state.models = models;
        },
        updateMessage(state, { index, content }) {
            state.messages[index].content += content;
        },
        setShowMobileChat(state, payload) {
            state.showMobileChat = payload;
        },
        setChatModel(state, payload) {
            state.model = payload;
        },
        setMessages(state, payload) {
            state.messages = payload;
        },
        setChatId(state, payload) {
            state.id = payload;
        },

        addToSummary(state, payload) {
            state.summary.push(payload);
        },

        setSummary(state, payload) {
            state.summary = payload;
        },
        setChat(state, payload) {
            window.chat = payload;
            state.id = payload.id;
            state.model = payload.model;
            state.messages = payload.messages;
            state.title = payload.chatTitle;
            state.owner = payload.owner;
            state.summary = payload.summary || [];
            state.lastMemoryIndex = payload.lastMemoryIndex || 0;
            state.bot_id = payload.bot_id || "";
            state.lastSummaryIndex = payload.lastSummaryIndex || 0;
            state.createdAt = payload.createdAt;
            state.chatTitle = payload.chatTitle;
            state.messages = payload.messages;
            state.updatedAt = payload.updatedAt;
            state.model = payload.model;
            state.topic = payload.topic;
            state.id = payload.id;
            state.questions = payload.questions || [];
            state.followUpTopicsArray = payload.followUpTopicsArray || [];
            state.followUpTopicsType = payload.followUpTopicsType || "";
        },
        setLastMemoryIndex(state, payload) {
            state.lastMemoryIndex = payload;
        },
        setLastSummaryIndex(state, payload) {
            state.lastSummaryIndex = payload;
        },
        appendOwnerChats(state, chats) {
            console.log("Appending chats:", chats);

            state.ownerChats.push(...chats);
            // Remove duplicates (in case there's an overlap between the two onSnapshot updates)
            state.ownerChats = _.uniqBy(state.ownerChats, "id");
            state.lastVisible = chats[chats.length - 1];
        },
        addChatMessage(state, payload) {
            state.messages.push(payload);
            state.messagesForAPI.push(payload); // Add the `messagesForAPI` array to your state, if needed
        },
        setChatTitle(state, payload) {
            state.title = payload;
            state.chatTitle = payload;
        },
        updateMessageObject(state, { index, message }) {
            state.messages[index] = message;
        },
        updateOwnerChats(state, chats) {
            state.ownerChats = chats;
            state.lastVisible = chats[chats.length - 1];
        },
        updateMessageAtIndex(state, { index, newMessage }) {
            state.messages[index] = newMessage;
        },
        appendContentToLastMessage(state, payload) {
            // safely check if messages array is not empty
            if (state.messages.length > 0) {
                state.messages[state.messages.length - 1].content += payload;
            }
        },
        setMessageComplete(state, payload) {
            // assume payload contains an index and a boolean 'complete' value
            let index = payload.index;
            let complete = payload.complete;
            if (state.messages && state.messages.length > 0) {
                console.log("Setting message complete to", complete, "for index", index, "in", state.messages[index]);
                state.messages[index].complete = complete;
            }
        },
        addActionToMessage(state, payload) {
            // assume payload contains an index and an action
            if (state.messages.length > payload.index) {
                state.messages[payload.index].action = payload.action;
            }
        },
        UPDATE_MESSAGE_SUMMARY(state, payload) {
            const { index, summary } = payload;
            // Ensure the data structure exists
            if (!state.messages[index].results) {
                state.messages[index].results = {};
            }
            if (!state.messages[index].results.summary) {
                state.messages[index].results.summary = {};
            }
            state.messages[index].results.summary.main = summary;
        },
        removeCurrentMessageProp(state, { keyPath }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");
            const currentIndex = state.messages.length - 1;

            if (currentIndex !== -1) {
                function removeValue(obj, keys) {
                    if (keys.length === 1) {
                        delete obj[keys[0]];
                        return;
                    }
                    removeValue(obj[keys[0]], keys.slice(1));
                }

                removeValue(state.messages[currentIndex], keys);
            }
        },
        updateCurrentMessageProp(state, { keyPath, value }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");
            const currentIndex = state.messages.length - 1;

            if (currentIndex !== -1) {
                function setValue(obj, keys, value) {
                    if (keys.length === 1) {
                        obj[keys[0]] = value;
                        return;
                    }
                    setValue(obj[keys[0]], keys.slice(1), value);
                }

                setValue(state.messages[currentIndex], keys, value);
            }
        },
        updateInputHeight(state, height) {
            state.inputHeight = height;
        },
        updateSlideProp(state, { keyPath, value, index }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");

            function setValue(obj, keys, value) {
                if (keys.length === 1) {
                    obj[keys[0]] = value;
                    return;
                }
                setValue(obj[keys[0]], keys.slice(1), value);
            }

            setValue(state.document.sections[index], keys, value);
        },
        addActiveChatId(state, id) {
            state.activeChatIds.push(id);
        },
        setThreads(state, threads) {
            state.threads = threads;
        },
        setRuns(state, runs) {
            state.runs = runs;
        },
        setThreadId(state, id) {
            state.threadId = id;
        },
        setAssistantId(state, id) {
            state.assistantId = id;
        },
        setAssistants(state, assistants) {
            state.assistants = assistants;
        },
        setPrompts(state, prompts) {
            state.prompts = prompts;
        },
    },
    actions: {
        async getPrompts({ commit, state }) {
            let response = await webRequest("prompts");
            let data = await response.json();
            console.log(data);
            commit("setPrompts", data);
        },
        setAssistantId({ commit }, id) {
            commit("setAssistantId", id);
        },
        setAssistants({ commit }, assistants) {
            commit("setAssistants", assistants);
        },
        setThreadId({ commit }, id) {
            commit("setThreadId", id);
        },
        async listAssistants({ commit, state }) {
            const response = await webRequest("get-assistants");
            let data = await response.json();
            logGroup("Assistants", data);
            commit("setAssistants", data);
            return data;
        },
        async getThreads({ commit, state, dispatch }, id) {
            commit("setThreadId", id);
            const response = await webRequest("threads", { id });
            let data = await response.json();
            logGroup("Threads", data);
            commit("setThreads", data.thread);
            if (data.runs) commit("setRuns", data.runs);
            let assistantMessages = data.thread.filter(m => m.role === "assistant");
            if (assistantMessages.length > 0) {
                let assistant_id = assistantMessages[0].assistant_id;
                commit("setAssistantId", assistant_id);
            }
            return data;
        },
        async sendAssistantMessage({ commit, dispatch, state }, obj) {
            let { threadId, message, model = gpt3 } = obj;
            const response = await webRequest("threads/send-message", { ...obj, model });
            let data = await response.json();
            return await dispatch("getThreads", threadId);
            // logGroup("Assistant Message", data);
            return data;
        },
        async getThreadImage({ commit, state }, { threadId, fileId, messageId }) {
            const response = await webRequest(
                "threads/message-file",
                {
                    thread_id: threadId,
                    file_id: fileId,
                    message_id: messageId,
                },
                undefined,
                undefined,
                undefined,
                undefined,
            );
            let data = await response.json();
            logGroup("Thread Image", data);
            return data;
        },
        addActiveChatId({ commit, router }, id) {
            let newId = router.route.params.id;
            if (id) newId = id;
            commit("addActiveChatId", newId);
        },
        updateInputHeight({ commit }, height) {
            commit("updateInputHeight", height);
        },
        removeCurrentMessageProp({ commit }, prop) {
            commit("removeCurrentMessageProp", prop);
        },
        updateCurrentMessageProp({ commit }, prop) {
            commit("updateCurrentMessageProp", prop);
        },
        addCommandsToMessage({ commit, state }, payload) {},
        async updateMessageObject({ commit, state }, { index, message }) {
            let newIndex;
            if (!index) newIndex = state.messages.length - 1;
            if (index) newIndex = index;
            console.groupCollapsed(`%c Updated message (${newIndex} of ${state.messages.length - 1})`, purple, message.role);
            console.log(index);
            if (message.content) console.table("%c" + message.content, txt);
            console.log(message);
            if (message.command) console.table(message.command);
            if (message.action) console.table(message.action);
            console.groupEnd();
            commit("updateMessageObject", { index: newIndex, message: message });
        },
        async updateCurrentMessage({ commit, state }, newMessage) {
            const index = state.messages.length - 1;
            console.groupCollapsed(`%c Updated current message (${index} of ${state.messages.length - 1})`, purple, newMessage.role);
            console.log(index);
            if (newMessage.content) console.table("%c" + newMessage.content, txt);
            console.log(newMessage);
            if (newMessage.command) console.table(newMessage.command);
            if (newMessage.action) console.table(newMessage.action);
            console.trace();
            console.groupEnd();
            commit("updateMessageObject", { index: index, updatedMessage: newMessage });
        },
        updateMessageSummary({ commit }, payload) {
            commit("UPDATE_MESSAGE_SUMMARY", payload);
        },
        improveMessageObject({ commit, dispatch }, { id, index, newMessage }) {
            let newerMessage = newMessage;
            if (typeof newerMessage.results.data === "object") {
                // newerMessage.results.data = JSON.stringify(newerMessage.results.data);
            }
            commit("updateMessageObject", { index, updatedMessage: newerMessage });
            dispatch("saveMessagesToFirestore", { id });
        },
        saveMessagesToFirestore({ state, dispatch }, { id }) {
            const chatRef = db.collection("chats").doc(id);
            // console.log(chatRef);
            try {
                chatRef
                    .update({ messages: state.messages })
                    .then(() => {
                        console.log("Messages successfully updated!");
                        dispatch("fetchMessagesFromFirestore", { id });
                    })
                    .catch(error => {
                        console.error("Error updating messages: ", error);
                    });
            } catch (error) {
                console.groupCollapsed("%c 🚫 Error", fail, "Saving messages to ☁️");
                console.error("Error saving messages: ", error);
                console.log(messages);
                console.groupEnd();
            }
        },
        fetchMessagesFromFirestore({ commit }, { id }) {
            const chatRef = db.collection("chats").doc(id);

            chatRef
                .get()
                .then(doc => {
                    if (doc.exists) {
                        commit("setMessages", doc.data().messages);
                    } else {
                        console.log("No such document!");
                    }
                })
                .catch(error => {
                    console.log("Error getting document:", error);
                });
        },
        addActionToMessage(context, payload) {
            context.commit("addActionToMessage", payload);
        },
        addToMessageObject({ commit, dispatch }, { index, messageObject }) {},
        // updateMessageSummary({ commit, dispatch }, { index, newMessage }) {
        //     commit("updateMessageAtIndex", { index, newMessage });
        //     dispatch("saveMessagesToFirestore", { id: newMessage.chatID });
        //     return;
        // },
        async loadChatStore({ commit, dispatch, router }, { chatID, route }) {
            try {
                // Fetch chat data from Firestore
                if (!chatID) {
                    chatID = router.route.params.id;
                }

                const chatDoc = await db.collection("chats").doc(chatID).get();

                // Check if the chat exists
                if (chatDoc.exists) {
                    const {
                        messages,
                        model,
                        // questions,
                        followUpTopicsArray,
                        followUpTopicsType,
                        followUpQuestionsArray,
                        chatTitle,
                        createdAt,
                        updatedAt,
                        summary = [],
                        lastSummaryIndex = 0,
                        lastMemoryIndex = 0,
                    } = chatDoc.data();

                    const chat = chatDoc.data();
                    const currentChat = chat;

                    // Use mutations to update the state
                    commit("setChat", chat);
                    commit("setLastMemoryIndex", lastMemoryIndex || 0);
                    commit("setLastSummaryIndex", lastSummaryIndex || 0);
                    commit("setSummary", summary || [{ text: "", type: "text" }]);
                    commit("setMessages", messages);
                    commit("setChatId", chatID);
                    dispatch("setMessageId", messages);
                    // ...
                    // Add other mutations as needed to update the rest of the state

                    if (!createdAt) {
                        currentChat.createdAt = new Date();
                        await db.collection("chats").doc(chatID).set(currentChat);
                    }
                    if (!updatedAt) {
                        currentChat.updatedAt = new Date();
                        await db.collection("chats").doc(chatID).set(currentChat);
                    }

                    // Dispatch an action to scroll the chat window to the bottom, if needed
                    dispatch("scrollChatWindowToBottom", true);
                } else {
                    console.error("Chat not found");
                }
            } catch (error) {
                console.error("Error fetching chat:", error);
            }
        },
        storeMessage(payload, { commit }) {
            commit("addChatMessage", payload);
        },
        appendContentToLastMessage(context, payload) {
            context.commit("appendContentToLastMessage", payload);
        },
        setMessageComplete(context, payload) {
            console.error("setMessageComplete", payload);
            context.commit("setMessageComplete", payload);
        },
        setChat({ commit }, chat) {
            commit("setChat", chat);
        },
        storeChatId(payload, { commit }) {
            commit("setChatId", payload);
        },
        setChatMode(payload, { commit }) {
            commit("setChatMode", payload);
        },

        setMessages({ commit }, payload) {
            console.log(`%c Set message`, purple);
            commit("setMessages", payload);
        },
        addMessageStore({ commit }, payload) {
            console.groupCollapsed(`Added message`, payload.role);
            console.trace();
            if (payload.content) console.table("%c" + payload.content, txt);
            console.log(payload);
            if (payload.command) console.table(payload.command);
            if (payload.action) console.table(payload.action);
            console.groupEnd();
            commit("addChatMessage", payload);
        },
        pushTokenToLastMessage({ commit, state }, payload) {
            let updatesMessage = state.messages[state.messages.length - 1].content + payload;
            commit("updateLastMessage", updatesMessage);
        },
        updateMessageAtIndex({ commit }, payload) {
            commit("updateMessageAtIndex", payload);
        },
        // addMessage({ commit, dispatch }, { message, role, kind }) {
        //     console.error("TRIED TO ADD MESSAGE");
        //     let messageToAdd = { role: role, content: message };
        //     if (kind) {
        //         messageToAdd.kind = kind;
        //     }
        //
        //     messageToAdd.createdAt = new Date();
        //     commit("addChatMessage", messageToAdd);
        //     dispatch("saveChatStore");
        // },

        addToSummaryStore({ commit, dispatch, state }, event) {
            let summaryToAdd = { event: event };
            summaryToAdd.createdAt = new Date();
            commit("addToSummary", summaryToAdd);
            commit("setLastSummaryIndex", state.messages.length - 1);
            setTimeout(() => {
                dispatch("saveChatStore");
            }, 100);
        },
        async setMessageId({ state, commit, dispatch }) {
            let count = 0;
            state.messages.forEach((m, i) => {
                if (!m.id) {
                    m.id = `m-${mixins.methods.randomId()}`;
                    count++;
                    // console.error("message id", m.content, m.id);
                }
            });

            if (state.id && count > 0) {
                // console.error(`updating chat in firestore with ${count} new ids`);
                await db.collection("chats").doc(state.id).update({
                    messages: state.messages,
                });
                dispatch("saveChatStore");
            }

            window.messages = state.messages;
        },
        getChatTitle: debounce(async function ({ state, commit }) {
            return;
            let title = "new chat";
            if (state.messages.length > 3 && (state.chatTitle === "New Chat" || state.title === "New Chat" || state.title === null || state.title === "undefined" || !state.title)) {
                // console.log("Get chat title");
                let messagesString = state.messages
                    .filter(m => m.role !== "system")
                    .map(m => m.role + ": " + m.content.slice(0, 200))
                    .join("\n\n");

                // Wrapper function for completion
                const storeCompletion = async params => {
                    const componentContext = {
                        consoleObject: (message, object) => {
                            // Delegate consoleObject calls to the actual implementation or Vuex action
                        },
                        gptError: error => {
                            // Delegate gptError calls to the actual implementation or Vuex action
                        },
                        $messageLog: (...params) => {
                            consoleMixin.methods.$messageLog.call(componentContext, state.messages, "from store");
                        },
                        $logResponse(content, from) {
                            consoleMixin.methods.$logResponse.call(componentContext, content, from);
                        },
                        consoleObject: str => {},
                        consoleSpecial(title, body, messages, background, textColor, details) {
                            // Delegate consoleSpecial calls to the actual implementation or Vuex action
                            // consoleMixin.methods.consoleSpecial.call(componentContext, title, body, messages);
                        },
                        messageLogger(message) {
                            streamingMixin.methods.messageLogger.call(componentContext, title, "from store");
                        },
                        masterJSONFixer: str => {
                            // Delegate masterJSONFixer calls to the actual implementation or Vuex action
                        },
                    };

                    // Call the completion method with the componentContext
                    return streamingMixin.methods.completion.call(componentContext, ...params);
                };

                title = await storeCompletion([`${messagesString} \n\n[CHOOSE TITLE]:\n\n`, "Choose a very short (3-5 word) title for this conversation.\n\n[TITLE]\n", "getting title", null, 15, false]);

                commit("setChatTitle", title);

                try {
                    await db.collection("chats").doc(state.id).update({ chatTitle: title });
                    document.title = `${title} | Clearmind`;
                } catch (error) {
                    console.error(error);
                }
            }
        }, 300),
        async updateModel({ state, commit }, model) {
            if (state.id) {
                try {
                    await db.collection("chats").doc(state.id).update({ model: model });
                    commit("setChatModel", model);
                    console.log("model updated -", model, state.id);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async saveChatStore({ state, commit, dispatch, rootState }) {
            const nonEmptyMessages = state.messages.filter(message => message?.content && message?.content !== "" && message?.content !== null);

            const user = rootState.user.user; // Access the user from the UserStore
            // console.log(state.id, state.title, state.model, state.owner);
            if (!state.id) {
                const newChatRef = await db.collection("chats").add({
                    messages: nonEmptyMessages,
                    model: state.model,
                    owner: user.uid,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    lastMemoryIndex: state.lastMemoryIndex,
                    lastSummaryIndex: state.lastSummaryIndex,
                    // summary: state.summary,
                });
                commit("setChatId", newChatRef.id);
            } else {
                let title = "New Chat";

                await db.collection("chats").doc(state.id).update(
                    {
                        messages: nonEmptyMessages,
                        model: state.model,
                        owner: user.uid,
                        updatedAt: new Date(),
                        lastSummaryIndex: state.lastSummaryIndex,
                        summary: state.summary,
                        lastMemoryIndex: state.lastMemoryIndex,
                        title: title,
                    },
                    { merge: true },
                );

                if ((!state.title || state.title === "New Chat" || state.title === "undefined") && state.messages.length > 4) {
                    await dispatch("getChatTitle");
                }
            }
        },
        subscribeToChats({ commit }, { ownerId, chatsLimit, startAfterSnapshot = null }) {
            let chatQuery = query(collection(db, "chats"), where("owner", "==", ownerId), orderBy("updatedAt", "desc"), limit(chatsLimit));

            if (startAfterSnapshot) {
                chatQuery = query(collection(db, "chats"), where("owner", "==", ownerId), orderBy("updatedAt", "desc"), startAfter(startAfterSnapshot), limit(chatsLimit));
            }

            onSnapshot(chatQuery, snapshot => {
                const chats = snapshot.docs.map(doc => {
                    let data = doc.data();
                    delete data.messages; // remove the messages field from the data
                    delete data.summary;
                    delete data.memory;
                    delete data.results;
                    delete data.command;
                    delete data.tasks;
                    delete data.agents;
                    delete data.lastMemoryIndex;
                    delete data.lastSummaryIndex;
                    delete data.model;
                    return { ...data, id: doc.id };
                });

                if (startAfterSnapshot) {
                    commit("appendOwnerChats", chats);
                } else {
                    commit("updateOwnerChats", chats);
                }
            });
        },

        async createNewChat({ rootState, dispatch, getters }, subject) {
            try {
                let model;
                const ownerId = getters.getUser.uid;
                let newChat = {
                    title: "New Chat",
                    model: gpt3,
                    owner: ownerId,
                    messages: [],
                };
                if (subject) {
                    const { topic, role, content, welcome_message } = subject;
                    if (subject.model) {
                        newChat.model = subject.model;
                    } else {
                        newChat.model = gpt3;
                    }
                    if (subject.bot_id) {
                        newChat.bot_id = subject.bot_id;
                    }
                    if (subject.input_format) {
                        newChat.input_format = subject.input_format;
                    }
                    newChat = {
                        ...newChat,
                        topic,
                        messages: [
                            { role, content },
                            { role: "assistant", content: welcome_message || "Hey! How can I help you today?" },
                        ],
                    };
                }

                const chatRef = await addDoc(collection(db, "chats"), newChat);
                const chatId = chatRef.id;
                router.push({ path: `/chat/${chatId}` });

                if (subject) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.$parent.$refs.chatInput.focus();
                        }, 200);
                    });
                }
            } catch (error) {
                console.error("Error creating a new chat:", error);
            }
        },
        updateMessagesForChat({ commit, state }) {
            const mixins = [chatCommands].reduce((acc, mixin) => ({ ...acc, ...mixin.methods }), {});

            let modifiedMessages = state.messages;
            let modString = "";
            if (mixins.commands()) {
                modString = mixins.formattedActionString();
            }

            modifiedMessages = state.messages.map((message, index) => {
                if (message.role === "system") {
                    const originalMessage = message;
                    let modifiedMessage = { ...originalMessage };
                    modifiedMessage.content = `${modifiedMessage.content}\n\n${modString}`;
                    if (mixins.commands()) {
                        return modifiedMessage;
                    } else {
                        return originalMessage;
                    }
                } else {
                    return message;
                }
            });

            commit("setMessages", modifiedMessages);
        },
    },
    getters: {
        prompts: state => state.prompts,
        lastMessage: state => {
            let filteredMessages = state.messages;
            // let filteredMessages = state.messages.filter(message => message.content && message.content.trim() !== "");
            return filteredMessages[filteredMessages.length - 1];
            // return state.messages[state.messages.length - 1];
        },
        prevMessage: state => {
            let filteredMessages = state.messages;
            // let filteredMessages = state.messages.filter(message => message.content && message.content.trim() !== "");
            return filteredMessages[filteredMessages.length - 2];
            // return state.messages[state.messages.length - 1];
        },
        messages: state => state.messages,
        getUser: (state, getters, rootState) => {
            return rootState.user.user;
        },
        modifiedMessagesForChat: (state, getters) => {
            const mixins = [chatCommands].reduce((acc, mixin) => ({ ...acc, ...mixin.methods }), {});

            let modString = "";
            if (state.commands) {
                modString = mixins.formattedActionString();
                console.log(modString);
            }

            return state.messages.map((message, index) => {
                if (message.role === "system") {
                    const originalMessage = message;
                    let modifiedMessage = { ...originalMessage };
                    modifiedMessage.content = `${modifiedMessage.content}\n\n${modString}`;
                    if (mixins.commands()) {
                        return modifiedMessage;
                    } else {
                        return originalMessage;
                    }
                } else {
                    return message;
                }
            });
        },
        ownerChats: state => state.ownerChats,
    },
};
// if ((!this.chatTitle || this.chatTitle === "New Chat") && this.messages.length > 4) {
//     await this.getChatTitle();
// }
// const nonEmptyMessages = this.messages.filter(message => message.content && message.content.trim() !== "");
//
// if (!this.chatID) {
//     Create a new chat and store its ID
// const newChatRef = await db.collection("chats").add({
//     messages: nonEmptyMessages,
//     model: this.model,
//     owner: this.user.uid,
//     createdAt: new Date(),
//     updatedAt: new Date(),
// });
// this.chatID = newChatRef.id;
// } else {
//     let title = "New Chat";
//
//     await db.collection("chats").doc(this.chatID).update({
//         messages: nonEmptyMessages,
//         model: this.model,
//         owner: this.user.uid,
//         updatedAt: new Date(),
//         title: title,
//     });
// }
