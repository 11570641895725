<template>
    <div class="text-left" :class="{ 'mw-350': mobile }">
        <p class="fwb">
            <i class="fas fa-newspaper text-blue mr-2"></i>
            Recent Articles
        </p>
        <div class="f flex-wrap gap-0 overflow-hidden">
            <div class="animated fadeInUpSmooth flex-2 col-12 duration-5 gap-2 px-0" :class="{ 'col-md-6': !column }" v-for="story in staggeredArticles.slice(0, limitArticles)">
                <div class="br-10 mb-3 bg-gray-100 text-gray-800 odd:mr-3 hover:bg-snow hover:shadow dark:bg-base-700 dark:text-base-300 dark:hover:bg-base-400">
                    <SearchArticle :animate="animate" :story="story" class="text-base-900" />
                </div>
            </div>
        </div>
        <div class="x text-center">
            <BaseButton class="mx-auto" style-type="secondary" rounded v-if="staggeredArticles && staggeredArticles.length > limitArticles" label="See all" @click.prevent="toggleSeeAllArticles"></BaseButton>
        </div>
    </div>
</template>
<script>
import AnimatedText from "@/components/CoreUI/animatedText.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import SearchArticle from "@/components/chat/specialMessages/Search/SearchArticle.vue";

export default {
    inject: ["scrollChatWindowToBottom"],
    name: "NewsArticles",
    components: { SearchArticle, BaseButton, AnimatedText },
    props: {
        column: {
            type: Boolean,
        },
        results: {},
        articles: {
            type: Array,
            required: true,
        },
        animate: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        articles: {
            immediate: true,
            handler(newArticles) {
                this.StaggerArticles(newArticles);
            },
        },
    },
    data() {
        return {
            staggeredArticles: [],
            limitArticles: 6,
        };
    },
    computed: {
        //map articles to a string
        articlesString() {
            // return this.articles.map(article => article.title).join("\n");
            return this.articles.map(article => `### ${article.title} - (${article.date})`).join("\n");
        },
    },
    methods: {
        toggleSeeAllArticles() {
            if (this.limitArticles === this.articles.length) this.limitArticles = 6;
            else if (this.limitArticles === 6) {
                this.limitArticles = this.articles.length;
            }
        },
        async StaggerArticles(articles) {
            this.staggeredArticles = [];
            if (this.animate) {
                for (let i = 0; i < articles.length; i++) {
                    await this.$nextTick();
                    setTimeout(() => {
                        this.staggeredArticles.push(articles[i]);
                        // this.scrollChatWindowToBottom(true);
                    }, i * 1000); // Adjust delay time (in ms) to your preference
                }
            } else {
                this.staggeredArticles = articles.slice(); // Copy the questions array without animation
            }
        },
    },
};
</script>
