// imagestoreStyleGuide.js
import * as firebase from "@/firebase";
import chroma from "chroma-js";
import { collection, doc, getDoc, getDocs, query, where, deleteDoc, updateDoc, addDoc } from "firebase/firestore";
import { isEqual } from "lodash";
import { db } from "@/firebase";
import removeFromFirebase from "@/mixins/firebase/deleteFromFirebase.js";
import saveFirebase from "@/mixins/firebase/saveToFirebase.js";
import { updateDropdownValue, updateToggleValue } from "@/store/StoreHelpers";

import webRequest from "@/mixins/ai/web_request";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import firebaseSave from "@/mixins/firebase/saveToFirebase.js";
import findMatchingObject from "@/mixins/firebase/findMatchinObject";
import { updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
import imageStoreStyleGuide from "@/store/ImageStoreStyleGuide";
import { logFetch } from "@/mixins/firebase/firebaseHelpers";
async function getSubCollection(id, collectionName) {
    logFetch(`${collectionName}`, id);
    try {
        const brandRef = db.collection("brands").doc(id); // Assuming db is your firestore instance
        const collectionRef = brandRef.collection(collectionName);
        const snapshot = await collectionRef.get();

        if (snapshot.empty) return [];

        let items = [];
        for (let doc of snapshot.docs) {
            const docSnapshot = await doc.ref.get();
            items.push({
                created: docSnapshot.createTime ? docSnapshot.createTime.toDate() : null,
                updated: docSnapshot.updateTime ? docSnapshot.updateTime.toDate() : null,
                id: doc.id,
                ...doc.data(),
            });
        }
        logGroup(collectionName, items);
        return items;
    } catch (error) {
        console.error("Error fetching brand images:", error);
        return []; // Return an empty array or handle the error as needed
    }
}
async function getSubCollectionItem(id, collectionName, itemId) {
    logFetch(`${collectionName}`, id);
    try {
        const brandRef = db.collection("brands").doc(id); // Assuming db is your firestore instance
        const collectionRef = brandRef.collection(collectionName);
        const doc = await collectionRef.doc(itemId).get();
        if (doc.exists) {
            const item = {
                created: doc.createTime ? doc.createTime.toDate() : null,
                updated: doc.updateTime ? doc.updateTime.toDate() : null,
                id: doc.id,
                ...doc.data(),
            };
            logGroup(collectionName, item);
            return item;
        } else {
            console.error("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching brand images:", error);
        return null; // Return an empty array or handle the error as needed
    }
}
async function getBrandImage(id, imageId) {
    logFetch(`images`, id);
    try {
        const brandRef = db.collection("brands").doc(id); // Assuming db is your firestore instance
        const doc = await brandRef.collection("images").doc(imageId).get();
        if (doc.exists) {
            const item = {
                id: doc.id,
                ...doc.data(),
            };
            logGroup("image", item);
            return item;
        } else {
            console.error("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching brand images:", error);
        return null; // Return an empty array or handle the error as needed
    }
}
export default {
    namespaced: true,
    state() {
        return {
            svgs: [],
            images: [],
            logos: [],
            mergedImages: false, // Assuming mergedImages is now in this module
        };
    },
    mutations: {
        SET_IMAGES(state, payload) {
            state.images = payload;
        },
        updateImagesState(state, rootState, { url, image }) {
            const index = state.images.findIndex(img => img.url === url);
            if (index !== -1) {
                state.images[index] = { ...state.images[index], ...image };
            }
        },
        MAKE_LOGO(state, url) {
            const index = state.logos.findIndex(img => img.url === url);
            if (index > -1) {
                const image = state.logos.splice(index, 1)[0];
                state.logos.unshift(image);
            }
        },
        REPLACE_ITEM(state, { originalIndex, image }) {
            logGroup("Replace Item", { originalIndex, image });
            if (originalIndex !== -1) {
                state.images.splice(originalIndex, 1, image);
            } else {
                console.error("Original image index not found.");
            }
        },
        ADD_ITEM(state, { property, item }) {
            if (state[property] && Array.isArray(state[property])) {
                state[property].push(item);
            } else {
                console.error("Property " + property + " does not exist or is not an array in state");
            }
        },
        DELETE_ITEM(state, { property, identifier }) {
            if (state[property]) {
                let index = -1;
                if (typeof identifier === "string") index = state[property].findIndex(item => item.url === identifier);
                else if (typeof identifier === "object") index = state[property].findIndex(item => isEqual(item, identifier));
                if (index !== -1) state[property].splice(index, 1);
                else console.error("Item not found in " + property);
            } else console.error("Property " + property + " does not exist on state");
        },
    },
    actions: {
        makeLogo({ commit }, url) {
            commit("styleGuide/MAKE_LOGO", url);
            // 'saveStyleGuide' action must be in the 'styleGuide' module
            // or adjust accordingly if it's within this module
        },
        async fetchUpdatedImage({ commit, rootState }, { id, image }) {
            // commit("SET_IMAGES", []);
            if (image.hash) image = await getBrandImage(id, image.hash);
            return image;
        },
        async getImages({ commit, rootState }, id) {
            logFetch(`Images`, id);
            commit("SET_IMAGES", []);
            let images = [];
            try {
                const brandRef = db.collection("brands").doc(id); // Assuming db is your firestore instance
                const CollectionRef = brandRef.collection("images");
                const snapshot = await CollectionRef.get();
                if (snapshot.empty) return [];
                images = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            } catch (error) {
                console.error("Error fetching brand images:", error);
            }

            // if (!id) id = rootState.styleGuide.styleGuide.id;
            // let data = await getSubCollection(id, "images");
            logGroup("images", images);
            commit("SET_IMAGES", images);
        },
        async updateImage({ commit, state, dispatch }, { id, image }) {
            try {
                let hash = image?.hash;
                let originalIndex = state.images.findIndex(img => img.hash === hash);
                if (!hash) originalIndex = state.images.findIndex(img => img.originalURL === image.originalURL);
                let response = await webRequest("uploadImage1", { image, id });
                let data = await response.json();

                // const images = data.images;
                // let newImage = images[0];
                let newImage = await dispatch("fetchUpdatedImage", { id, image });
                logGroup("GPT Vision", newImage);
                console.log("New Image", newImage);
                if (originalIndex > -1) commit("REPLACE_ITEM", { originalIndex, image: newImage });
            } catch (error) {
                console.error("Error updating image:", error);
            }
        },
        async deleteImage({ commit, dispatch }, { id, url, image }) {
            try {
                const imagesRef = collection(db, `brands/${id}/images`);
                const querySnapshot = await getDocs(query(imagesRef, where("url", "==", url)));
                const deletePromises = querySnapshot.docs.map(docSnapshot => deleteDoc(docSnapshot.ref));
                await Promise.all(deletePromises);
                if (image?.kind === "logo") commit("DELETE_ITEM", { property: "logos", identifier: url });
                else commit("DELETE_ITEM", { property: "images", identifier: url });
                if (image?.kind === "svg") commit("DELETE_ITEM", { property: "svgs", identifier: url });
            } catch (error) {
                console.error("Error deleting image", error);
            }
        },
        addImage({ commit }, { image, kind = "images" }) {
            if (kind === "images" || kind === "logos" || kind === "svgs") commit("ADD_ITEM", { property: kind, item: image });
            else console.error("Invalid kind: " + kind);
        },
        async moveToLogos({ commit, state }, image) {
            const originalImageIndex = state.images.findIndex(i => i.url === image.url);
            if (originalImageIndex === -1) {
                console.error("Image not found in images.");
                return;
            }

            let currentImage = { ...state.images[originalImageIndex] };
            if (currentImage.kind === "logo") currentImage.kind = "image";
            else currentImage.kind = "logo";

            commit("REPLACE_IMAGE", { originalIndex: originalImageIndex, image: currentImage });
        },
        async clearImages({ state, commit, dispatch, rootState }) {
            const id = rootState.styleGuide.styleGuide.id;
            try {
                const subcollectionPath = `brands/${id}/images`;
                const subcollectionRef = collection(db, subcollectionPath);
                const querySnapshot = await getDocs(subcollectionRef);

                const deletePromises = [];
                querySnapshot.forEach(doc => {
                    deletePromises.push(deleteDoc(doc.ref));
                });
                dispatch("styleGuide/updateStyleGuideProp", { keyPath: "images", value: [] });
                return Promise.all(deletePromises);
            } catch (e) {
                dispatch("styleGuide/updateStyleGuideProp", { keyPath: "images", value: [] });
            }
        },
    },
    getters: {
        sortedImages(getters) {
            if (!getters.images) return [];

            let sort = getters.images.slice().sort((a, b) => {
                // Assign priorities based on the image properties
                const getPriority = image => {
                    // if (!image.transparent && !image.seamlessBg && image?.entropy?.sharpness > 7) return 4; // High entropy
                    if (image.transparent) {
                        let entropy = image?.entropy?.entropy;
                        let sharpness = image?.entropy?.sharpness;
                        if (entropy && sharpness) {
                            if (sharpness > entropy * 2) {
                                return 5;
                            }
                        }
                    } // Transparent and high entropy
                    if (!image.transparent && !image.seamlessBg && image?.entropy?.entropy > 6.5 && image?.entropy?.sharpness < 7 && image?.entropy?.sharpness > 1)
                        return 0; // High entropy
                    else if (!image?.transparent && !image?.seamlessBg)
                        return 1; // Non-transparent and non-seamless
                    else if (image?.seamlessBg)
                        return 2; // Seamless backgrounds
                    else if (image?.transparent) return 3; // Transparent
                };

                const priorityA = getPriority(a);
                const priorityB = getPriority(b);

                // Compare the priorities to sort
                return priorityA - priorityB;
            });
            // map the index to the image
            sort = sort.map((image, index) => {
                return { ...image, index };
            });
            return sort;

            // return this.images?.filter(image => {
            //     return !image?.transparent && !image?.seamlessBg && image?.colors?.length > 0;
            // });
        },
        images: (state, getters, rootState, rootGetters) => {
            let guideImages = rootState.styleGuide?.styleGuide?.images || [];
            // let combinedImages = rootState.styleGuide.combinedImages || [];
            let allImages = [];
            if (guideImages && Array.isArray(guideImages)) allImages = [...guideImages];
            // if (combinedImages) allImages = [...allImages, ...combinedImages];
            if (state.images) allImages = [...state.images, ...allImages]; // let imagesCombinedImages = state.images.combinedImages;
            return allImages || [];
        },
        landingPageImages: (state, getters, rootState) => {
            let images = getters.images;
            // get images that are wider than they are tall
            images = images.filter(image => {
                return image.width > image.height;
            });
            // filter out transparent images
            images = images.filter(image => {
                return !image.transparent;
            });
            images = images.filter(image => {
                if (!image.entropy) return true;
                if (image?.entropy?.entropy > 6.5 && image?.entropy?.sharpness < 7 && image?.entropy?.sharpness > 1) return true;
            });
            return images;
        },
        logos: (state, getters, rootState) => {
            return rootState.styleGuide.styleGuide.logos || [];
        },
        logo: (state, getters, rootState) => {
            return getters?.logos?.[0] || false;
        },
        brandImage: state => {
            let guideImages = state.styleGuide?.images || [];
            let images = state.images || [];
            let allImages = [];
            if (guideImages && Array.isArray(guideImages)) allImages = [...guideImages];
            if (images) allImages = [...allImages, ...images];
            if (allImages.length > 0) {
                const { url, color, backgroundColor, bottomColor } = allImages[0];
                let image = {
                    url: url || null,
                    color: color || "",
                    backgroundColor: backgroundColor || "",
                    bottomColor: bottomColor || "",
                };
                if (url) return image;
            } else {
                console.error("Images array is empty or undefined");
                return null;
            }
        },
        hasImages(state, getters) {
            let images = state.styleGuide?.images?.length > 0 || getters.images?.length > 0;
            return Array.isArray(state.styleGuide.images) && state.styleGuide.images.length > 0;
        },
    },
};
