<template>
    <div v-if="list" class="mb-4">
        <h3 class="h3 mb-3 block f-30">{{ title }}</h3>
        <div class="f fc gap-2 pr-3">
            <div v-for="(rule, index) in list" :key="index" class="f ais gap-2 f-24 mb-1 md:mb-3">
                <i v-if="type === 'dos'" class="far fa-circle-check p-1 text-green-400"></i>
                <i v-if="type === 'donts'" class="far fa-ban p-1 text-red-700"></i>
                <span style="font-weight: 400 !important">{{ rule }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "DosDontsList",
    props: {
        list: {},
        type: {
            type: String,
            default: "dos",
        },
        title: {
            type: String,
            default: "Do's",
        },
    },
};
</script>
