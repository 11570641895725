// imagestoreStyleGuide.js
import * as firebase from "@/firebase";
import chroma from "chroma-js";
import { collection, doc, getDoc, getDocs, query, where, deleteDoc, updateDoc, addDoc } from "firebase/firestore";
import { isEqual } from "lodash";
import { db } from "@/firebase";
import removeFromFirebase from "@/mixins/firebase/deleteFromFirebase.js";
import saveFirebase from "@/mixins/firebase/saveToFirebase.js";
import { updateDropdownValue, updateToggleValue } from "@/store/StoreHelpers";

import webRequest from "@/mixins/ai/web_request";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import firebaseSave from "@/mixins/firebase/saveToFirebase.js";
import findMatchingObject from "@/mixins/firebase/findMatchinObject";
import { updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
import imageStoreStyleGuide from "@/store/ImageStoreStyleGuide";
import { formatObjectToString } from "@/mixins/objectToString";

function formatAttributes(attributes, level = 0, title = "Attributes", separator = "-") {
    let string = "";
    let indent = "";
    let prefix = "";
    for (let i = 0; i < level; i++) prefix += "#";
    let header = `${indent}${prefix}${title}\n`;
    indent = "  ".repeat(level);
    if (level === 0) {
        header = `**${header.trim()}**\n\n`;
        // header += "---\n\n";
    } else if (level >= 0) header = `${header.trim()}\n\n`;
    string += header;
    attributes.forEach(attribute => {
        let { title, description, value } = attribute;
        if (separator === "-") {
            string += `${indent}* **${title}**: ${description}\n\n`;
        } else if (separator === ",") {
            string += `${indent} **${title}**: ${description},\n`;
        } else {
            string += `${indent} **${title}**: ${description}\n\n`;
        }
    });
    return string;
}
function formatAttributesSection(section = {}, title, level = 0) {
    let { introduction = "", attributes, values } = section;
    attributes = attributes || values || [];
    let introString = `## ${title}\n${introduction}`;
    let attributesString = formatAttributes(attributes, level, "");
    let parts = [introString, attributesString];
    return parts.join("\n\n");
}

function formatArray(array, title, sectionName, level = 0, separator = "-", quotes = false) {
    if (!array.length > 0) return;
    // Calculate the indentation based on the level

    let prefix = "";
    let indent = "";
    for (let i = 0; i < level; i++) prefix += "#";

    let header = `${indent}${prefix} ${title}`;
    if (sectionName) {
        sectionName = sectionName.charAt(0).toUpperCase() + sectionName.slice(1);
        header = `${indent}${prefix} ${title} for ${sectionName}`;
    }
    if (level === 0) header = `**${header.trim()}**\n`;
    indent = " ".repeat(level);
    // Format the list based on the specified separator
    let formattedList;
    if (quotes) array = array.map(item => `"${item}"`);
    if (separator === ",") {
        formattedList = array.join(", ");
        formattedList = indent + formattedList;
        if (title) title = `**${title}** `;
        return `${title}${formattedList}`;
    } else if (separator === "-") {
        formattedList = array.map(item => `${indent}* ${item}`).join("\n");
        if (level === 0) return `${header}\n\n---\n\n${formattedList}`;
        else return `${header}\n\n${formattedList}`;
    } else {
        formattedList = array.map(item => `${indent}* ${item}`).join("\n");
        return `${header}\n\n${formattedList}`;
    }
}

function makeSection(section, title) {
    if (section) {
        if (Array.isArray(section)) {
            section = formatArray(section, title, undefined, 3, "-");
        } else if (typeof section === "object") {
            section = formatAttributesSection(section, title);
        } else {
            section = null;
        }
        return section;
    }
    return;
}
function modifyAltText(altText, caption) {
    if (caption) {
        return altText
            .replace(/[-–—]/g, "")
            .replace(/\n/g, " ")
            .split(" ")
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(" ")
            .trim();
    } else {
        return altText
            .replace(/[-–—]/g, "")
            .replace(/\n/g, " ")
            .split(" ")
            .filter((value, index, self) => self.indexOf(value) === index)
            .slice(0, 15)
            .join(" ")
            .trim();
    }
}
function brandCopyString(name) {
    return `\n\n**Be sure that all written output uses ${name}'s tone, pacing, and writing style.**\n\n`;
}
export default {
    namespaced: true,
    state() {
        return {};
    },
    mutations: {},
    actions: {},
    getters: {
        // AboutBrand: (state, getters, rootState) => {
        //     let styleGuide = rootState.styleGuide.styleGuide;
        //     const copyGuidelines = styleGuide?.copyGuidelines || {};
        //     const guidelines = copyGuidelines?.overview?.about || "";
        //     const sellingPoints = copyGuidelines?.overview?.selling_points?.join("\n - ") || "";
        //     const targetAudience = copyGuidelines?.overview?.target_audience?.join("\n- ") || "";
        //     const valueProps = copyGuidelines?.overview?.value_propositions?.join("\n- ") || "";
        //     const punctuation = copyGuidelines?.overview?.punctuation?.join("\n- ") || "";
        //
        //     let finalString = `\n\n#### ABOUT "${styleGuide?.name?.toUpperCase()}"\n${styleGuide?.description || ""}\n${styleGuide.url || ""}`;
        //     if (guidelines) finalString += `\n\n${guidelines.trim()}`;
        //     if (targetAudience) finalString += `\n\n#### TARGET AUDIENCE:\n- ${targetAudience}`;
        //     if (valueProps) finalString += `\n\n#### VALUE PROPS:\n- ${valueProps}`;
        //     if (sellingPoints) finalString += `\n\n#### SELLING POINTS:\n - ${sellingPoints}`;
        //     if (punctuation) finalString += `\n\n#### PUNCTUATION:\n- ${punctuation}`;
        //
        //     return finalString;
        // },
        SellingPoints: (state, getters, rootState) => {
            const { styleGuide } = rootState.styleGuide;
            const { copyGuidelines = {}, name } = styleGuide || {};
            let { overview = {}, audience, ethos, punctuation, rhetorical_strategies } = copyGuidelines;
            let { about = "", selling_points, target_audience: targetAudience = [], value_propositions: valueProps = [] } = overview;
            if (!selling_points) selling_points = valueProps;
            let title = `Selling Points`;
            if (name) title = `${name}'s Selling Points`;
            selling_points = makeSection(selling_points, title);
            return selling_points;
        },
        // Audience: (state, getters, rootState) => {
        //     try {
        //         const { styleGuide } = rootState.styleGuide;
        //         const { copyGuidelines = {}, name = "" } = styleGuide || {};
        //         if (!copyGuidelines) return "No copy guidelines available.";
        //         let { overview = {}, audience } = copyGuidelines;
        //         let { target_audience: targetAudience } = overview;
        //         if (!audience) audience = targetAudience;
        //         let title = `Target Audience`;
        //         if (name) title = `${name}'s Target Audience`;
        //         audience = makeSection(audience, title, "");
        //         return audience;
        //     } catch (e) {
        //         console.error(e.message);
        //         return "";
        //     }
        // },
        Audience: (state, getters, rootState) => {
            try {
                const { styleGuide = {} } = rootState;
                const { copyGuidelines = {} } = styleGuide;
                const { overview = {}, audience: defaultAudience } = copyGuidelines;
                const { target_audience: targetAudience } = overview;

                // Use defaultAudience or fallback to targetAudience from overview
                const audience = defaultAudience || targetAudience || "No audience defined.";

                // Construct title, incorporating styleGuide name if available
                const title = styleGuide.name ? `${styleGuide.name}'s Target Audience` : `Target Audience`;

                // Assuming makeSection function exists and is properly handling all inputs.
                // Ensure makeSection gracefully handles 'audience' being 'undefined' or an empty string.
                return makeSection(audience, title, "");
            } catch (e) {
                // Consider logging the entire error for more detailed debugging information
                console.error("Error processing audience information:", e);
                return "Error processing audience information.";
            }
        },
        BrandValues: (state, getters, rootState) => {
            const { styleGuide } = rootState.styleGuide;
            const { copyGuidelines = {} } = styleGuide || {};
            let { ethos } = copyGuidelines;
            return makeSection(ethos, "Brand Values");
        },
        ValueProps: (state, getters, rootState) => {
            const { styleGuide } = rootState.styleGuide;
            const { copyGuidelines = {} } = styleGuide || {};
            let { overview = {}, value_propositions: valueProps = [] } = copyGuidelines;
            if (valueProps) return makeSection(valueProps, "Value Props");
            return;
        },
        Description: (state, getters, rootState) => {
            const { styleGuide } = rootState.styleGuide;
            const { copyGuidelines = {} } = styleGuide || {};
            let { overview = {} } = copyGuidelines;
            let { about = "" } = overview;
            if (about === styleGuide.description) return about;
            else return [about, styleGuide.description].filter(Boolean).join("\n\n");
        },
        WritingDevices: (state, getters, rootState) => {
            const { styleGuide } = rootState.styleGuide;
            const { copyGuidelines = {} } = styleGuide || {};
            let { overview = {}, punctuation, rhetorical_strategies } = copyGuidelines;
            if (!punctuation) punctuation = overview.punctuation || [];
            if (!rhetorical_strategies) rhetorical_strategies = overview.rhetorical_strategies || [];
            if (Array.isArray(punctuation)) {
                punctuation = [...punctuation, ...rhetorical_strategies];
                punctuation = makeSection(punctuation, "Punctuation");
            } else if (typeof punctuation === "object") {
                let pAttributes = punctuation.attributes || [];
                let rAttributes = rhetorical_strategies.attributes || [];
                pAttributes = [...pAttributes, ...rAttributes];
                let pIntroduction = punctuation.introduction || "";
                let rIntroduction = rhetorical_strategies.introduction || "";
                pIntroduction = [pIntroduction, rIntroduction].filter(Boolean).join("\n\n");
                let object = { introduction: pIntroduction, attributes: pAttributes };
                punctuation = makeSection(object, "Punctuation");
            }
            return punctuation;
        },
        AboutBrand: (state, getters, rootState) => {
            const { styleGuide } = rootState.styleGuide;
            const { copyGuidelines = {} } = styleGuide || {};
            let { overview = {}, punctuation, rhetorical_strategies } = copyGuidelines;
            let { about = "" } = overview;
            const name = styleGuide?.name?.toUpperCase() || "";
            const url = styleGuide?.url || "";
            let parts = [`#### About ${name}`, getters.Description, url].filter(Boolean).join("\n\n");
            parts += "\n\nToday's Date:\n" + new Date();
            return parts;
        },

        plainTextSamples: (state, getters) => {
            let productCopy = getters.textSamples?.productCopy;
            function minimumWords(string, count) {
                let words = string.split(" ");
                words = words.filter(word => word !== "");
                if (words.length < count) return null;
                return words.join(" ");
            }

            if (!getters.textSamples) return "No text samples available.";

            // Headers safety checks
            let headers = getters.textSamples.headers;
            if (!headers || typeof headers !== "object") return "Headers are not properly formatted.";

            let text = "\n**Brand Copy Examples**\n";

            ["h1", "h2", "h3", "h4", "h5", "h6"].forEach(key => {
                try {
                    let header = headers[key];
                    let prepend;
                    if (!header || !Array.isArray(header) || header.length === 0) return;

                    // if (key === "h1") {prepend = "# "};
                    // else if (key === "h2") prepend = "## ";
                    // else if (key === "h3") prepend = "### ";
                    // else if (key === "h4") prepend = "#### ";
                    // else if (key === "h5") prepend = "##### ";
                    // else if (key === "h6") prepend = "###### ";

                    if (key === "h1") prepend = "H1 ";
                    else if (key === "h2") prepend = "H2 ";
                    else if (key === "h3") prepend = "H3 ";
                    else if (key === "h4") prepend = "H4 ";
                    else if (key === "h5") prepend = "H5 ";
                    else if (key === "h6") prepend = "H6 ";

                    if (header.length > 0) text += prepend + "\n";

                    if (header.length > 0) {
                        header.forEach(h => {
                            if (h !== "The quick brown fox jumps over the lazy dog.") text += `"${h.trim()}"` + "\n";
                        });
                    }
                } catch (e) {
                    console.error(`Error processing header for key: ${key}. Error: ${e.message}`);
                }
            });
            // });

            // Paragraphs safety checks
            let paragraphs = getters.textSamples?.paragraphs;
            // let paragraphs = getters.textSamples.divs;
            if (!paragraphs || !Array.isArray(paragraphs)) {
                return "Paragraphs are not properly formatted.";
            }
            paragraphs = paragraphs.join("\n").split("\n");
            paragraphs = [...new Set(paragraphs)];
            if (!productCopy) {
                if (paragraphs.length) text += "Paragraphs\n";
                paragraphs.forEach((paragraph, index, key) => {
                    // replace many consecutive \n with a single \n globally
                    paragraph = paragraph.replace(/\n+/g, "\n");
                    // replace excess spaces globally
                    paragraph = paragraph.replace(/ +/g, " ");
                    paragraph = paragraph.replace(/\s/g, " ");
                    paragraph = paragraph.replace(/­/g, "");
                    paragraph = paragraph.trim();
                    paragraph = minimumWords(paragraph, 4);
                    if (paragraph && paragraph.trim() !== "") {
                        text += `"${paragraph}"` + "\n";
                    }
                });
            } else {
                if (productCopy.length) text += "\n**Product Copy**\n";
                productCopy.forEach((paragraph, index, key) => {
                    // replace many consecutive \n with a single \n globally
                    paragraph = paragraph.replace(/\n+/g, "\n");
                    // replace excess spaces globally
                    paragraph = paragraph.replace(/ +/g, " ");
                    paragraph = paragraph.replace(/\s/g, " ");
                    paragraph = paragraph.replace(/­/g, "");
                    paragraph = paragraph.trim();
                    paragraph = minimumWords(paragraph, 4);
                    if (paragraph && paragraph.trim() !== "") {
                        text += `"${paragraph}"` + "\n";
                    }
                });
            }
            // filter null paragraphs
            paragraphs = paragraphs.filter(paragraph => paragraph !== null);
            if (getters.textSamples?.emailSubjects) {
                let emailSubjects = getters.textSamples.emailSubjects;
                text += "\n**Email Subject Line Examples**\n";
                emailSubjects.forEach((emailSubject, index, key) => {
                    // clear excess spaces globally
                    emailSubject = emailSubject.replace(/\n +/g, "");
                    text += "" + emailSubject + "\n";
                });
            }
            if (getters.textSamples?.emailPreviews) {
                let emailPreviews = getters.textSamples.emailPreviews;
                text += "\n**Email Preview Text Examples**\n";
                emailPreviews.forEach((emailPreview, index, key) => {
                    // replace ­ globally
                    emailPreview = emailPreview.replace(/\n+/g, "");
                    emailPreview = emailPreview.replace(/\n/g, "");
                    emailPreview = emailPreview.replace(/\s+/g, " ");
                    text += "" + emailPreview.replace(/­/g, "") + "\n";
                });
            }
            let buttons = getters.textSamples.buttons;
            text += "\n**CTAS: Buttons & Links**\n";
            buttons.forEach((button, index, key) => {
                text += button + "\n";
            });
            let links = getters.textSamples.links;

            return text;
        },

        CopyGuidelines: (state, getters, rootState) => {
            const styleGuide = rootState.styleGuide.styleGuide;
            if (!styleGuide?.copyGuidelines) return null;

            const formatTone = tone => tone.map(t => `${t.value}% ${t.name}`).join(", ");
            const formatLines = lines => lines.map(l => `- ${l}`).join("\n");
            let formatExamples = examples => examples.map(ex => `"${ex?.replace(/#/g, "").trim()}"`).join("\n");
            const getOverviewString = overview => {
                const about = overview?.about ? `About: ${overview.about}` : "";
                const analogousComparison = overview?.analogous_comparison ? `Analogous Comparison: ${overview.analogous_comparison}` : "";
                const voice = overview?.voice?.length ? `Voice: ${overview.voice.join(", ")}` : "";
                return [about, analogousComparison, voice].filter(section => section).join("\n");
            };

            try {
                let outputSections = [];

                // Tone
                const toneSet = styleGuide.copyGuidelines.guidelines?.filter(line => line.tone).map(line => `### Tone:\n${formatTone(line.tone)}`);
                if (toneSet?.length) outputSections.push(toneSet.join("\n"));

                // Guidelines (Do's)
                const guidelines = styleGuide.copyGuidelines.guidelines?.filter(line => line.guidelines?.length).map(line => formatLines(line.guidelines));
                if (guidelines?.length) outputSections.push(`### Do's:\n${guidelines.join("\n")}`);

                // Avoid (Don'ts)
                const avoid = styleGuide.copyGuidelines.guidelines?.filter(line => line.avoid?.length).map(line => formatLines(line.avoid));
                if (avoid?.length) outputSections.push(`### Don'ts:\n${avoid.join("\n")}`);

                // Examples
                const examples = styleGuide.copyGuidelines.guidelines?.filter(line => line.examples?.length).map(line => formatExamples(line.examples));
                if (examples?.length) outputSections.push(`### Examples:\n${examples.join("\n")}`);

                // Overview
                const overviewStr = getOverviewString(styleGuide.copyGuidelines.overview);
                if (overviewStr) outputSections.push(overviewStr);

                // Return null if no sections have outputs
                if (outputSections.length === 0) return "";

                return `\n## ${styleGuide.name}'s copywriting guidelines:\n\n${outputSections.join("\n\n")}${brandCopyString(styleGuide.name)}`;
            } catch (e) {
                console.error(e.message);
                return "";
            }
        },

        Context(state, getters, rootState, rootGetters) {
            let productCopy = rootGetters["styleGuide/productCopy"];
            const keys = {
                aboutBrand: getters.aboutBrand || "",
                colorArray: getters.ColorsString || "",
                headerSamples: getters.HeaderSamples || "",
                subjectLines: getters.SubjectLines || "",
                imageList: getters.ImageList || "",
                productArray: getters.ProductArray || "",
                copyGuidelines: getters.CopyGuidelines || "",
                paragraphSamples: getters.ParagraphSamples || "",
                productCopy: `- ${productCopy?.map(p => `"${p}"`).join("\n- ")}`,
                // allWebCopy: getters.AllWebCopy,
            };

            const transformations = {
                aboutBrand: userMessage,
                copyGuidelines: sysMessage,
                imageList: sysMessage,
                headerSamples: userMessage,
                paragraphSamples: userMessage,
                subjectLines: userMessage,
                productArray: userMessage,
                colorArray: userMessage,
                productCopy: userMessage,
                // allWebCopy: null, // No transformation
            };

            return Object.entries(keys).reduce((acc, [key, value]) => {
                if (value) {
                    acc[key] = transformations[key] ? transformations[key](value) : value;
                }
                return acc;
            }, {});
        },
        ColorsString: (state, getters, rootState, rootGetters) => {
            let divStyles = rootGetters["styleGuide/styleGuide"]?.divStyles || [];
            let buttonStyles = rootGetters["styleGuide/styleGuide"]?.buttonStyles || [];
            if (!divStyles) return;
            if (!buttonStyles) return;

            let backgroundColors = divStyles.map(style => style.backgroundColor);
            let textColors = divStyles.map(style => style.color);
            let buttonColors = buttonStyles.map(style => style.backgroundColor);
            let allColors = rootGetters["styleGuide/colors"].all_colors || [];
            // make it a deduplicated set
            let colors = [...new Set([...backgroundColors, ...textColors, ...buttonColors, ...allColors])];
            // remove any falsy values
            colors = colors.filter(Boolean);
            // remove any undefined values
            colors = colors.filter(color => color !== undefined);

            let colorString = colors
                .map(color => {
                    return color;
                })
                .join(", ");
            return "BRAND COLORS:\n" + colorString;
        },
        ImageList: (state, getters, rootState, rootGetters) => {
            let images = rootGetters["styleGuide/image/images"];
            if (!images?.length > 0) return " ";

            const altTextOccurrences = {};

            const processedAlts = images.map((image, index) => {
                let altText = (image.alt || "").trim();
                let transparent = image.transparent ? "- transparent bg" : "";
                let color = image.color || "";
                // if (color) color = chroma(color).get("hex");
                // if (color) color = `- ${color}`;
                let width = image.width ? `${image.width}` : "";
                let height = image.height ? `x${image.height}` : "";
                let dimensions = "";
                if (width && height) dimensions = ` (${width}${height}) `;
                let seamless = image.seamlessBg ? "- studio shot on seamless" : "";
                if (altText) altText = altText + transparent + seamless + dimensions;
                let caption = image.caption;
                if (caption) altText = caption + " " + altText;
                altText = modifyAltText(altText, caption);

                if (altText) {
                    if (altTextOccurrences[altText]) {
                        altTextOccurrences[altText]++;
                        altText = `${altText}-${altTextOccurrences[altText]}`;
                    } else {
                        altTextOccurrences[altText] = 1;
                    }
                }

                if (altText === images[0].alt) {
                    return `${index}. ${altText}`;
                }
                return altText ? `${index}. ${altText}` : `${index}.`;
            });

            // Debugging log

            return `Select from this list of images:\n\n${processedAlts.join("\n")}`;
        },
        HeaderSamples: (state, getters, rootState, rootGetterse) => {
            let styleGuide = rootState["styleGuide"].styleGuide;
            let textSamples = styleGuide?.textSamples;
            let headers = textSamples?.headers;
            let name = styleGuide?.name?.toUpperCase();
            if (!headers) return;
            let headerExamples = "";
            let { h1 = [], h2 = [], h3 = [], h4 = [], h5 = [], h6 = [] } = headers;
            let allHeaders = [h1, h2, h3, h4, h5, h6]
                .flat()
                .filter(t => t !== "")
                .filter(Boolean);
            let wordsToExclude = ["advertising", "privacy", "accessibility", "policy", "violation", "security", "advertised", "deny", "legal", "ingredient", "cart"];

            if (allHeaders?.length > 5) {
                allHeaders = allHeaders.map(header => {
                    if (header) {
                        // Check if the paragraph includes any of the words to exclude
                        let shouldExclude = wordsToExclude.some(word => header.toLowerCase().includes(word));
                        // If it doesn't include any excluded words, return it
                        if (!shouldExclude) {
                            return `"${header}"`;
                        }
                    }
                });
                allHeaders = allHeaders.filter(Boolean);
                allHeaders = allHeaders.map(t => {
                    t = t.replace(/(\n)+/g, "\n");
                    t = t.trim();
                    return t;
                });
                allHeaders = allHeaders.filter(t => t !== "" && t !== undefined && t !== null);
                allHeaders = [...new Set(allHeaders)].sort();

                // only return strings with more than 3 words
                allHeaders = allHeaders.filter(t => t.split(" ").length > 3);

                allHeaders = allHeaders
                    .filter(t => t.length > 7)
                    .filter(t => !t.includes("quick brown fox") && !t.includes(""))
                    .join("\n");
                headerExamples = `### EXAMPLE HEADERS IN ${name}'S BRAND VOICE:\n\n${allHeaders}\n\n###${brandCopyString(name)}`;
            }
            headerExamples = headerExamples.replace(/The quick brown fox jumps over the lazy dog./g, "");

            return headerExamples;
        },
        ParagraphSamples: (state, getters, rootState, rootGetters) => {
            let styleGuide = rootState["styleGuide"].styleGuide;
            // console.log(styleGuide);
            let textSamples = styleGuide?.textSamples;

            let name = styleGuide?.name?.toUpperCase();
            let paragraphs = textSamples?.paragraphs;
            if (!paragraphs || paragraphs?.length === 0) paragraphs = textSamples?.divs || [];
            paragraphs = paragraphs.filter(paragraph => paragraph.length > 40);
            paragraphs = paragraphs.map(paragraph => paragraph.slice(0, 300));
            paragraphs = paragraphs
                .map(p => {
                    if (!p) return;
                    let paragraphArray = p.split(" "); // The optional chaining is not needed here as we've already checked if p is truthy
                    if (paragraphArray.length > 7) {
                        let wordsToExclude = ["advertising", "privacy", "accessibility", "policy", "violation", "security", "advertised", "deny", "legal", "ingredient", "cart"];
                        // Check if the paragraph includes any of the words to exclude
                        let shouldExclude = wordsToExclude.some(word => p.toLowerCase().includes(word));
                        // If it doesn't include any excluded words, return it
                        if (!shouldExclude) {
                            return `"${p.slice(0, 300)}"`;
                        }
                    }
                })
                .filter(Boolean); // This will filter out undefined or null values from the final array

            // sort them by length
            paragraphs = paragraphs
                .sort((a, b) => {
                    return b.length - a.length;
                })
                .slice(0, 30);
            paragraphs = `- ${paragraphs.filter(Boolean).join("\n- ")}`;
            let paragraphExamples;
            if (paragraphs?.length > 0) {
                paragraphExamples = `### EXAMPLE PARAGRAPHS FROM ${name}:\n\n${paragraphs}\n\n${brandCopyString(name)}`;
            }
            return paragraphExamples;
        },
        CopyExamplesFromSources: (state, getters, rootState, rootGetters) => {
            try {
                let sources = rootState["styleGuide"]?.sources;
                if (!sources) return;
                let paragraphsFromSources = sources.map(s => s.paragraphs).flat();
                paragraphsFromSources = [...new Set(paragraphsFromSources)];
                // sort by length
                paragraphsFromSources = paragraphsFromSources.sort((a, b) => {
                    return b?.length - a?.length;
                });
                paragraphsFromSources = paragraphsFromSources.filter(p => p?.length > 40);
                paragraphsFromSources = paragraphsFromSources.filter(p => p.length < 400);
                paragraphsFromSources = paragraphsFromSources.slice(0, 15);
                paragraphsFromSources = makeSection(paragraphsFromSources, "Copy Examples from Sources");
                return paragraphsFromSources;
            } catch (e) {
                console.error(e.message);
            }
        },
        SubjectLines: (state, getters, rootState, rootGetters) => {
            let styleGuide = rootState["styleGuide"];
            styleGuide = styleGuide?.styleGuide;
            if (!styleGuide) return;
            let textSamples = styleGuide?.textSamples;
            let subjectLines = textSamples?.emailSubjects?.join("\n");
            let subjectExamples;
            if (subjectLines?.length > 0) {
                subjectExamples = `Here are examples of the subject written in the brand's voice:\n\n${subjectLines}`;
            }
            return subjectExamples;
        },
        //
        // Personas: (state, getters, rootState, rootGetters) => {
        //     // let styleGuide = rootState["styleGuide"].styleGuide;
        //     let copyGuidelines = rootGetters["styleGuide/copyGuidelines"];
        //     if (!copyGuidelines) return;
        //     let personas = copyGuidelines?.overview?.personas;
        //     if (!personas) return null;
        //     let keys = ["pain_points", "demographics", "goals", "psychographics"];
        //     let string = "";
        //     personas.map(persona => {
        //         string += formatObjectToString(persona);
        //         // keys.forEach((item, key) => {
        //         //     try {
        //         //         if (persona[key]) {
        //         //             // item = persona[key].join(", ");
        //         //             // string += `${key}\n${item}`;
        //         //         }
        //         //     } catch (e) {}
        //         // });
        //     });
        //     return string;
        // },
        Personas: (state, getters, rootState, rootGetters) => {
            let styleGuide = rootState["styleGuide"].styleGuide;
            let { name } = styleGuide;
            let { copyGuidelines = {} } = styleGuide || {};
            let { overview = {} } = copyGuidelines;
            let { personas } = overview;
            if (!personas) return;

            function handlePersona(persona) {
                // console.log("persona", index, Object.keys(persona));
                let { about = "", name = "" } = persona;
                let aboutString = [`### ${name}`, about].join("\n\n");

                const attributes = [
                    { key: "demographics", title: "Demographics" },
                    { key: "interests", title: "Interested in" },
                    { key: "goals", title: "Goals" },
                    { key: "pain_points", title: "Pain Points" },
                    { key: "psychographics", title: "Psychographics" },
                    { key: "decision_drivers", title: "Decision Drivers" },
                    { key: "behaviors", title: "Behaviors" },
                    // { key: "about", title: "About" },
                    // { key: "physical_appearance", title: "Physical Appearance" },
                    // { key: "media_consumption", title: "Media Consumption" },
                    // { key: "influencers_and_role_models", title: "Influencers" },
                    // { key: "brand_interaction_history", title: "Brand Interaction History" },
                ];
                let personaArray = [];
                attributes.forEach(({ key, title }) => {
                    const value = persona[key];
                    let string = "";
                    if (value && value.length > 0) {
                        if (Array.isArray(value)) string = `${formatArray(value, title, undefined, 0, ",")}`;
                        else string = `**${title}:** ${value}`;
                    }
                    personaArray.push(string);
                });

                personaArray = personaArray.join("\n* ");
                let parts = [aboutString, personaArray];
                let finalString = parts.join("\n* ");
                return finalString + "\n---\n\n";
            }
            let personasString = `## ${name}'s Customer Personas`;
            personas = personas.map(handlePersona).join("\n");
            return [personasString, personas].join("\n");
        },
        // Vuex Getter
        AllWebCopy: (state, getters, rootState, rootGetters) => {
            let styleGuide = rootState["styleGuide"].styleGuide;
            let samples = styleGuide.additionalText;
            if (!samples) return null;
            samples = samples
                .map(sample => {
                    return sample?.content.trim() || "";
                })
                .join("\n");
            return samples;
            let intro = `Here's a text dump from the brand's website:\n\n`;
            if (!styleGuide?.additionalText) return;

            let strings = styleGuide?.additionalText;

            // Split by new lines, trim each string, and filter out falsy values
            strings = strings
                .flatMap(str => str.split("\n"))
                .map(str => str.trim())
                .filter(Boolean);

            // Deduplicate strings while retaining order
            const seen = new Set();
            strings = strings.filter(item => {
                const lowercasedItem = item.toLowerCase(); // ensures case-insensitive comparison
                if (seen.has(lowercasedItem)) {
                    return false;
                }
                seen.add(lowercasedItem);
                return true;
            });

            // Filter out lines with less than 4 characters
            strings = strings.filter(str => str.length >= 4);

            let consolidatedString = strings.join("\n");
            consolidatedString = consolidatedString.replace(/\n\s*\n/g, "\n"); // Replace multiple newlines with just one

            if (intro && consolidatedString) return intro + consolidatedString;
            return "";
        },

        ProductArray: (state, getters, rootState, rootGetters) => {
            let products = rootGetters["styleGuide/products"];
            let storeImages = rootGetters["styleGuide/image/images"];
            if (!storeImages) return;
            if (!products || products?.length === 0) return null;

            const uniqueProducts = {}; // Hash table for unique product titles
            let dedupedProducts = []; // Array to hold unique products

            // De-duplicate products by title
            products.forEach(product => {
                const { title } = product;
                if (!uniqueProducts[title]) {
                    uniqueProducts[title] = true;
                    dedupedProducts.push(product);
                }
            });

            // Generate product string
            let productsStr = "";
            dedupedProducts.forEach(({ id, title, description, url, images, product_id, handle }, index) => {
                let productString = "";
                let imageIndexes = [];
                let productIndex = index;
                if (productIndex !== null) {
                    productString += `\n### ${productIndex}. `;
                    // productString += `\n${productIndex}. `;
                }
                if (title) productString += ` ${title} \n`;
                // if (title) productString += `**${title}:** \n`;

                // Find indexes for all matching images
                if (images && images.length) {
                    images.forEach(image => {
                        storeImages.forEach((img, index) => {
                            if (img.url === image.url || img.originalURL === image.url || (img.id && id && id === img.id) || (img.product_id && product_id && product_id === img.product_id) || (img.source && handle && handle === img.source)) {
                                imageIndexes.push(index);
                            }
                        });
                    });
                }

                // Remove duplicate indexes if any
                imageIndexes = [...new Set(imageIndexes)];

                if (imageIndexes.length) {
                    let imageIndexStr = `Images [${imageIndexes.join(", ")}]`;
                    productString += imageIndexStr;
                }
                if (url) productString += `\n${url}\n`;
                productsStr += productString + "";
            });

            // TODO: You can remove this comment since we're now finding indexes for all images.
            return `PRODUCT INDEX:\n` + productsStr;
        },
        ProductArraySimple: (state, getters, rootState, rootGetters) => {
            let styleGuide = rootState["styleGuide"].styleGuide;
            if (!styleGuide?.products) return;
            let productString = "";
            let products = styleGuide.products;

            const uniqueProducts = {}; // Hash table for unique product titles
            let dedupedProducts = []; // Array to hold unique products

            // De-duplicate products by title
            products.forEach(product => {
                const { title } = product;
                if (!uniqueProducts[title]) {
                    uniqueProducts[title] = true;
                    dedupedProducts.push(product);
                }
            });

            // Generate product string
            dedupedProducts.map(({ title, description, url, images }) => {
                let image = images?.[0]?.url || undefined;

                if (title) productString += `*${title}*\n`;
                if (description) productString += `${description}\n`;
                if (url) productString += `${url}\n`;
                // if (image) productString += `Image URL: ${image}\n`;
                if (image) {
                    let imageIndex = state.images.findIndex(img => img.url === image);
                    if (imageIndex >= 0) {
                        productString += `Image Index: ${imageIndex}\n`;
                    }
                }
                productString = productString.replace(/(\n)+/g, "\n");
                productString += "\n---\n";
            });
            // TODO: Find the index of the image URL in this.styleGuide.images
            let imageIndex;
            // do the find here and return the index

            return productString;
        },
        EmailSubjects: (state, getters, rootState, rootGetters) => {
            let styleGuide = rootState["styleGuide"].styleGuide;
            if (!styleGuide?.textSamples) return;
            let subjectLines = styleGuide.textSamples.emailSubjects;
            if (!subjectLines) return;
            let subjectString = "";
            subjectLines.map(subject => {
                subjectString += `${subject}\n`;
            });
            return subjectString;
        },
        EmailPreviews: (state, getters, rootState, rootGetters) => {
            let styleGuide = rootState["styleGuide"].styleGuide;
            if (!styleGuide?.textSamples) return;
            let emailPreviews = styleGuide.textSamples.emailPreviews;
            if (!emailPreviews) return;
            let previewString = "";
            emailPreviews.map(preview => {
                previewString += `${preview}\n`;
            });
            return previewString;
        },
        productText: (state, getters, rootState, rootGetters) => {
            let maxProductCount = 50;
            let maxDescriptionLength = 300;
            let productCopy = rootGetters["styleGuide/productCopy"];
            if (productCopy?.length > 0) {
                productCopy = productCopy
                    .map(line => `"${line.replace("\n", " ").slice(0, maxDescriptionLength)}"`)
                    .slice(0, maxProductCount)
                    .join("\n\n");
            }
            productCopy = `### EXAMPLE PARAGRAPHS IN THE BRAND'S VOICE:\n\n${productCopy}\n\n Be sure that all brand copy uses a similar tone, pacing, and writing style.`;
            return productCopy;
        },
        headerParagraph: (state, getters, rootState, rootGetters) => {
            let headers = getters.HeaderSamples;
            let paragraphs = getters.ParagraphSamples;
            if (paragraphs) paragraphs = paragraphs.slice(0, 10000);
            return headers + "\n\n" + paragraphs || "";
        },
        EmailSubjectPreview: (state, getters, rootState, rootGetters) => {
            let subjectLines = getters?.SubjectLines || "";
            let emailPreviews = getters?.EmailPreviews || "";
            if (!subjectLines || !emailPreviews) return "";
            return subjectLines + "\n\n" + emailPreviews;
        },
        ExamplesFromCopyGuidelines: (state, getters, rootState, rootGetters) => {
            let copyGuidelines = rootGetters["styleGuide/copyGuidelines"];
            if (!copyGuidelines) return;
            let { guidelines } = copyGuidelines;
            let string = "More copy examples \n\n";
            let samples = guidelines.map(guideline => {
                let name = guideline?.name?.toUpperCase();
                let examples = guideline?.examples;
                examples = examples.map(example => `"${example}"`);
                return "\n**" + name + "**\n" + examples.join(", ");
            });
            samples = samples.join("\n");
            return string + samples;
        },
        ProductParagraphs: (state, getters, rootState, rootGetters) => {
            return `### EXAMPLE PARAGRAPHS IN THE BRAND'S VOICE:\n\n${getters.productText}\n\n Be sure that all brand copy uses a similar tone, pacing, and writing style.`;
        },
        EmailMessages: (state, getters, rootState, rootGetters) => {
            try {
                let messages = [];
                if (getters?.AboutBrand) messages.push(sysMessage(getters.AboutBrand));
                if (getters?.BrandValues) messages.push(sysMessage(getters.BrandValues));
                if (getters?.Audience) messages.push(sysMessage(getters.Audience));
                if (getters?.ValueProps) messages.push(userMessage(getters.ValueProps));
                // if (getters?.WritingDevices) messages.push(userMessage(getters.WritingDevices));
                if (getters?.SellingPoints) messages.push(userMessage(getters.SellingPoints));
                if (getters?.EmailSubjectPreview) messages.push(userMessage(getters.EmailSubjectPreview));
                if (getters?.HeaderSamples) messages.push(userMessage(getters.HeaderSamples));
                if (getters?.ParagraphSamples) messages.push(userMessage(getters.ParagraphSamples));
                if (getters?.CopyExamplesFromSources) messages.push(userMessage(getters.CopyExamplesFromSources));
                // if (getters?.Personas) messages.push(userMessage(getters.Personas));
                //

                // if (productArray) messages.push(sysMessage(productArray));
                let additionalMessages = [
                    userMessage("Don't be too explicit about the product in the copy."),
                    userMessage("Assume the customer is smart enough to understand the product."),
                    userMessage("Don't mention testimonials, reviews, etc."), //
                    userMessage("Build a relationship with your audience by using a conversational tone and addressing them directly. Use 'you' and 'your' to create a sense of familiarity and connection."),
                ];
                messages.push(...additionalMessages);

                return messages;
            } catch (e) {
                console.error(e.message);
            }
        },
        CampaignMessages: (state, getters, rootState, rootGetters) => {
            try {
                let messages = [];
                if (getters.AboutBrand) messages.push(sysMessage(getters.AboutBrand));
                if (getters.CopyGuidelines) messages.push(sysMessage(getters.CopyGuidelines));
                // if (getters.ProductArray) messages.push(sysMessage(getters.ProductArray));
                if (getters.BrandValues) messages.push(sysMessage(getters.BrandValues));
                if (getters.Audience) messages.push(sysMessage(getters.Audience));
                // if (productArray) messages.push(sysMessage(productArray));
                if (getters.ValueProps) messages.push(userMessage(getters.ValueProps));
                if (getters.WritingDevices) messages.push(userMessage(getters.WritingDevices));
                if (getters.SellingPoints) messages.push(userMessage(getters.SellingPoints));
                if (getters.EmailSubjectPreview) messages.push(userMessage(getters.EmailSubjectPreview));
                if (getters.HeaderSamples) messages.push(userMessage(getters.HeaderSamples));
                if (getters.ParagraphSamples) messages.push(userMessage(getters.ParagraphSamples));
                if (getters.CopyExamplesFromSources) messages.push(userMessage(getters.CopyExamplesFromSources));
                // if (getters.ProductArraySimple) messages.push(sysMessage(getters.ProductArray));
                let otherMessages = [
                    userMessage("Don't include customer stories, testimonials or reviews"),
                    userMessage("Assume the customer is smart enough to understand the product."),
                    userMessage("If product names have technical bits like counts or sizes, etc just omit them from the product name."),
                    userMessage("Don't mention testimonials, reviews, etc."), //
                    userMessage("Build a relationship with your audience by using a conversational tone and addressing them directly. Use 'you' and 'your' to create a sense of familiarity and connection."),
                    aiMessage("Great! I will now make the campaign and I'll be sure to limit the amount of campaign items so I don't make way too many"),
                ];
                messages.push(...otherMessages);
                return messages;
            } catch (e) {
                console.error(e.message);
            }
        },
        LandingPageMessages: (state, getters, rootState, rootGetters) => {
            let messages = [];
            if (getters.AboutBrand) messages.push(sysMessage(getters.AboutBrand));
            if (getters.CopyGuidelines) messages.push(sysMessage(getters.CopyGuidelines));
            // if (getters.ProductArray) messages.push(sysMessage(getters.ProductArray));
            // if (getters.ProductParagraphs) messages.push(sysMessage(getters.ProductParagraphs));
            // sysMessage(images),
            // sysMessage(products),
            // sysMessage(productCopy),
            return messages;
        },
    },
};
