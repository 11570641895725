<template>
    <div>
        <div ref="container">
            <h5 v-if="title" ref="title">{{ title }}</h5>
            <p v-if="body" ref="body">
                <span class="o-5">{{ body }}</span>
            </p>
        </div>
    </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const { timeline, set, to, from } = gsap;

export default {
    name: "ListContent",
    props: {
        body: { type: String, default: "" },
        title: { type: String, default: "" },
    },
    data() {
        return {
            scrollTriggerInstance: null,
        };
    },
    mounted() {
        setTimeout(() => {
            this.animate();
        }, 100);
    },
    beforeUnmount() {
        if (this.scrollTriggerInstance) this.scrollTriggerInstance.kill();
    },
    methods: {
        animate() {
            let title = this.$refs.title;
            let body = this.$refs.body;
            let container = this.$refs.container;
            let tl = timeline({
                scrollTrigger: { trigger: container, start: "top bottom", end: "center center+=200", scrub: true },
            });
            this.scrollTriggerInstance = tl.scrollTrigger;
            let fadeIn = [
                { opacity: 0, y: 20, scale: 0.95 },
                { opacity: 1, y: 0, scale: 1, duration: 1, ease: "back.out(2)" },
            ];
            let containerAnim = [
                { opacity: 0, y: 20 },
                { opacity: 1, y: 0, duration: 1, ease: "back.out(2)" },
            ];
            if (container) tl.fromTo(container, ...containerAnim, 0.01);
            if (title) tl.fromTo(title, ...fadeIn, 0.03);
            if (body) tl.fromTo(body, ...fadeIn, 0.04);
        },
    },
};
</script>
