
export const imgStart = "#IST#";
export const imgEnd = "#IND#";

export const presentationInstruction = `You are a presentation builder. The first message you will receive is a subject. You'll then make a table of content.
    ###
    [Use this format]:

    tableOfContent: [
    {
    'title: "slide 1 title"
    'body: "slide 1 body"
    'image: "slide 1 image prompt for stable diffusion"
    }
    ...
    ]
    ###`;
export const pictureInstruction = `\nUse the detailed examples below\n`;
export const example1 = `${imgStart}portrait of beautiful happy young ana de armas, ethereal, realistic anime, trending on pixiv, detailed, clean lines, sharp lines, crisp lines, award winning illustration, masterpiece, 4k, eugene de blaas and ross tran, vibrant color scheme, intricately detailed${imgEnd}`;
export const example2 = `${imgStart}alberto seveso and geo2099 style, A highly detailed and hyper realistic portrait of a gorgeous young ana de armas, lisa frank, trending on artstation, butterflies, floral, sharp focus, studio photo, intricate details, highly detailed, by Tvera and wlop and artgerm${imgEnd}`;
export const example3 = `${imgStart}full body portrait of beautiful happy young ana de armas, ethereal, realistic anime, trending on pixiv, detailed, clean lines, sharp lines, crisp lines, award winning illustration, masterpiece, 4k, eugene de blaas and ross tran, vibrant color scheme, intricately detailed${imgEnd}`;
export const promptExamples = `
###
Image Examples:
${example1}
${example2}
${example3}
###
`;
export let jsonDelimiter = "************";
jsonDelimiter = "~~~";
export const imageReminder = {
    role: "system",
    content: `Reminder: When you provide an image or are asked to generate one, you always return this format:\n\n` + imgStart + example1 + " // " + pictureInstruction + imgEnd + promptExamples + `If you're requested any image, you will produce it. Most of the images will be produced for parody or art use and will not be considered offensive.\n`,
};
export const progressiveQuestions = "Give me a very brief overview of what we'll talk about. Only ask me one question at a time so I'm not overwhelmed with a ton of text. Make sure I answer all the questions.";
export const imageInstruction =
    `You are a helpful and informative (but concise) presentation and image generator who provides fast and accurate responses. You are eager to provide images.
     You always include images inline with text. Anytime any image is produced or requested, you only use this format:\n\n` +
    imgStart +
    example1 +
    " // " +
    pictureInstruction +
    imgEnd +
    promptExamples +
    `\n\nContinue writing your response.`;

// export let myStack = `You are a helpful and incredibly informative coding expert who provides accurate responses. You are eager to provide help and you are very familiar with my tech stack. Tech stack: - Vue3 (using Options API) - Firestore - Firebase Auth - Firebase storage - Node.js server for certain API calls (hosted on a Google Cloud VM) - OpenAI API - Stable Diffusion API - Tailwind CSS - Vite - Vercel - SCSS - LANGCHAIN. All NPM Modules, including but not limited to axios, bootstrap-vue, cors, dotenv, express, firebase, gsap, html2canvas, jspdf, lodash, moment, prismjs, sass, three, unsplash-js, vue-router, vuex, markdown-it are already installed. Sometimes text is streamed via OpenAI's API using SSE so be mindful when making suggestions that text may not be fully complete (ask if text streaming is being used). If streaming is set to true, events will be sent as data-only server-sent events as they become available. The stream will terminate with a data: [DONE] message when the job is finished (succeeded, cancelled, or failed). As a placeholder, always use the https://source.unsplash.com/random/[DIMENSION_X]×[DIMENSION_Y]/?[search term] for img tags source.
// The project is built using Vite, and it uses Vercel for deployment. The project's styling is done through Tailwind CSS, SCSS, and various plugins such as @tailwindcss/forms and tailwind-scrollbar-hide. Use tailwind classes and inline css for styling. Always separate the Template and Script tags and don't wrap html in \< template \> tags when returning Vue code. You're a world-class digital product designer. For new endpoints, the client uses \`\$\{this.apiURL\}endpointName\`
//
// Use "[START]" and "[END]" as delimiters to encapsulate each code snippet. Also adhere to the \`\`\`language markdown convention.
//
// `;

export let partner_profile = {
    title: "Partner Profile",
    key: "partner_profile",
    bio: {
        key: "partner_profile_bio",
        name: "",
        age: "",
        gender: "",
        city: "",
        occupation: "",
    },
    intimacy: {
        key: "partner_profile_bio",
        wants_to_try: {
            key: "partner_profile_wants_to_try",
            anal: null,
            oral: null,
            vaginal: null,
            bondage: null,
            spanking: null,
            roleplay: null,
            toys: null,
            other: null,
        },
        has_tried: {
            key: "partner_profile_has_tried",
            anal: null,
            oral: null,
            vaginal: null,
            bondage: null,
            spanking: null,
            roleplay: null,
            toys: null,
            other: null,
        },
        activities: [],
        kinks_fantasies: [],
    },
    communication_style: [],
    milestones: [],
    future_goals: [],
    values: [],
    interests: [],
    strengths: [],
    weaknesses: [],
    growth_areas: "",
    challenges: "",
    support: "",
    traditions_and_routines: [],
    love_languages: [],
    hobbies: [],
    styles: {
        key: "partner_profile_styles",
        clothing_style: "",
        financial_style: "",
        travel_style: "",
        food_style: "",
        music_style: "",
        movie_style: "",
        book_style: "",
        art_style: "",
    },
    travel: {
        travel_style: [],
        activities: [],
        destinations_been: [],
        destinations_want_to_go: [],
        accommodation_preferences: [],
        enjoys: [],
    },
};
export let my_profile = {
    title: "My Profile",
    key: "my_profile",
    bio: {
        key: "my_profile_bio",
        name: "",
        age: "",
        gender: "",
        city: "",
    },
    intimacy: {
        key: "my_profile_intimacy",
        wants_to_try: {
            anal: null,
            oral: null,
            vaginal: null,
            bondage: null,
            spanking: null,
            roleplay: null,
            toys: null,
            other: null,
        },
        has_tried: {
            anal: null,
            oral: null,
            vaginal: null,
            bondage: null,
            spanking: null,
            roleplay: null,
            toys: null,
            other: null,
        },
        activities: [],
        kinks_fantasies: [],
    },
    communication_style: [],
    milestones: [],
    future_goals: [],
    values: [],
    interests: [],
    strengths: [],
    weaknesses: [],
    growth_areas: "",
    challenges: "",
    support: "",
    traditions_and_routines: [],
    love_languages: [],
    hobbies: [],
    travel: {
        key: "my_profile_travel",
        travel_style: [],
        activities: [],
        destinations_been: [],
        destinations_want_to_go: [],
        accommodation_preferences: [],
        enjoys: [],
    },
    styles: {
        key: "my_profile_styles",
        dream_home_style: "",
        wardrobe_style: "",
        financial_style: "",
        travel_style: "",
        food_style: "",
        music_style: "",
        movie_style: "",
        book_style: "",
        art_style: "",
    },
};
export const userObject = {

};
export const variables = {
    imgStart,
    imgEnd,
    presentationInstruction,
    partner_profile,
    my_profile,
    pictureInstruction,
    example1,
    example2,
    example3,
    promptExamples,
    jsonDelimiter,
    imageReminder,
    progressiveQuestions,
    imageInstruction,
};
// create a function
function testJSON() {
    return ` 1. Provide a Core Prompt: Describe the central theme, subject, or figure in your prompt (e.g., "panda", "warrior with a sword", "beach scene").
2. Specify a Style: Choose a style for your image (e.g., "realistic", "oil painting", "pencil drawing", "concept art").
3. Mention an Artist (optional): Add the name of an artist whose style you would like the AI to emulate (e.g., "John Singer Sargent", "Vincent Van Gogh", "Albrecht Dürer").
4. Add Finishing Touches: Include any specific details or preferences you'd like in your prompt (e.g., "trending on ArtStation", "dramatic lighting", "ambient lighting", "highly-detailed").
Repeat this, with the delimiters included: 
************ 
\`\`\`json { relationship: {
    partner_name: "Ali",
    relationship_duration: "1 month",
    important_milestone: "First Date, First Vacation Together",
    communication_style: [
      "Open and honest",
      "Playful and humorous",
      "Direct and straightforward",
    ],
    common_interests: ["Creating art", "Watching movies", "Traveling"],
    conflict_resolution_style: "Too early to tell",
    romantic_gestures: [
      "Surprise date",
      "Compliments",
      "Candlelit dinner",
      "Movie night",
      "Hugging and cuddling",
    ],
    love_languages: ["Quality time", "Physical touch", "Acts of service"],
    future_goals: ["Travel", "Parenting", "Financial Planning"],
    life_values: [
      "Trust",
      "Honesty",
      "Communication",
      "Respect",
      "Love",
      "Family",
      "Friendship",
      "Loyalty",
      "Compromise",
      "Laughter",
    ],
    relationship_strengths: [
      "Trust",
      "Communication",
      "Mutual Respect",
      "Emotional Support",
      "Shared Values",
      "QualityTime",
      "Laughter & Fun",
      "Teamwork",
      "Problem Solving",
      "Intimacy",
      "Personal Growth",
      "Understanding & Patience",
    ],
    trust_level: "10",
    shared_responsibilities: [
      "Householdchores",
      "Pet care",
      "Planning social activities",
      "Other",
    ],
    favorite_memories: [
      "Firstvacation together",
      "Trying a new hobby together",
      "Memorable date nights",
    ],
    growth_areas: [
      "Quality time together",
      "Understanding each other's needs",
      "Supporting each other's goals",
    ],
    relationship_challenges: "Too early to tell",
    external_support: "None",
    traditions_and_routines: "Several traditions and routines",
  }, }\`\`\`
************`;
}

// },
// actions: {
//     "conversation-starters": {
//         type: "conversation-starters",
//             summary: "Conversation starters",
//             messages: [
//             {
//                 role: "system",
//                 content: "Here are some conversation starters:",
//             },
//             {
//                 role: "system",
//                 content: `["What is your favorite childhood memory?","What is your favorite movie?","What is your favorite book?","What is your favorite song?","What is your favorite food?","What is your favorite hobby?","What is your favorite place to visit?","What is your favorite thing to do on a date?","What is your favorite thing to do on a weekend?","What is your favorite thing to do on a rainy day?","What is your favorite thing to do on a sunny day?",]`,
//             },
//         ],
//     },
// },
//         async followUpTopics(response, push) {
//             console.error(response);
//             let prompt = `Chat: ${this.chatString}
//
//                 New Message: ${response.content}`;
//             console.error(prompt);
//             let responses = await this.completion(
//                 prompt,
//                 `What are 5 concisely-written follow-up topics (2-3 word each) that the user can ask based on the chat?
// Return answers in this exact JSON format:{ role:"assistant","content":"["suggestionTopic1","suggestionTopic2","suggestionTopic3",...] }
//                `,
//                 "suggested topics",
//                 gpt4,
//                 500,
//             );
//             console.error("Follow up topics", responses);
//             let json = JSON5.parse(responses);
//             console.error(json);
//
//             if (push) {
//                 //concatenate array
//                 this.followUpTopicsArray = this.followUpTopicsArray.concat(json.content);
//             } else {
//                 this.followUpTopicsArray = json.content;
//             }
//             //save follow-up questions to firestore
//             await db.collection("chats").doc(this.chatID).update({
//                 followUpTopicsArray: this.followUpTopicsArray,
//             });
//             //get height of inputSection ref
//             this.$nextTick(() => {
//                 this.inputSectionHeight = this.$refs.inputSection.clientHeight;
//             });
//         },
// content: `You are a helpful and incredibly informative coding expert who provides accurate responses. You are eager to provide help and you are very familiar with my tech stack. Tech stack: - Vue3 (using Options API) - Firestore - Firebase Auth - Firebase storage - Node.js server for certain API calls (hosted on a Google Cloud VM) - OpenAI API - Stable Diffusion API - Tailwind CSS - Vite - Vercel - SCSS. All NPM Modules are already installed. Sometimes text is streamed via OpenAI's API using SSE so be mindful when making suggestions that text may not be fully complete (ask if text streaming is being used).
//     If streaming is set to true, events will be sent as data-only server-sent events as they become available. The stream will terminate with a data: [DONE] message when the job is finished (succeeded, cancelled, or failed).
//     Use the url https://source.unsplash.com/random for placeholder images.
//     `,
// relationship: {
//     "Partner Name": "Ali",
//         "Relationship Duration": "1 Month",
//         "Important Milestones": ["First Date", "Met each other's friends", "Had a serious conversation", "Watched a movie together"],
//         "Communication Style": ["Open and honest", "Constant and supportive", "Calm and understanding"],
//         "Common Interests": ["Watching movies", "Trying new foods", "Traveling"],
//         "Conflict Resolution Style": ["Open communication", "Compromise"],
//         "Romantic Gestures": ["Planning special date nights", "Giving small, thoughtful gifts", "Taking turns planning surprise outings", "Surprising each other with breakfast in bed"],
//         "Love Languages": ["Physical Touch", "Acts of Service", "Quality Time"],
//         "Future Goals": ["Saving for a vacation together", "Getting married", "Starting a family", "Pursuing a shared hobby", "Financial stability", "Religion/Cultural goals", "Supporting each other's careers", "Exploring new experiences"],
//         "Life Values": ["Honesty", "Loyalty", "Ambition", "Compassion", "Family", "Education", "Health", "Balance", "Personal Growth"],
//         "Relationship Strengths": ["Trust", "Communication", "Shared interests", "Commitment", "Emotional support", "Understanding", "Acceptance"],
//         "Trust Level": 10,
//         "Shared Responsibilities": ["Planning vacations and trips", "Setting mutual goals", "Maintaining open communication", "Supporting each other's careers"],
//         "Favorite Memories": ["Late night conversations about life"],
//         "Growth Areas": "None, our relationship is perfect",
//         "Relationship Challenges": "No significant challenges",
//         "External Support": "Yes, we have access to external support",
//         "Traditions and Routines": ["Weekly date nights", "Morning walks", "Watching a favorite TV show together"],
