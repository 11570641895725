<template>
    <Transition name="fade-up" appear>
        <div class="p-3 relative top z-5 x">
            <div class="bg-snow br-20 overflow-hidden border border-2 shadow-xl relative">
                <div class="px-3 !py-2 f aic jcb">
                    <p class="text-xl fwsb mb-0">Select a new image</p>
                    <div class="">
                        <BaseButton @click.prevent="$emit('close')" style-type="secondary" size="xxs" icon-only icon="fa fa-times"></BaseButton>
                    </div>
                </div>
                <input ref="searchInput" type="text" @click.prevent="" style="border: 1px solid black; border-radius: 0px; border-left: none; border-right: none" class="!border-top p-2 px-3 mb-0 br-0 border-bottom" placeholder="Search for an image" v-model="searchQuery" />
                <div class="scrollSection height-max-500 y">
                    <div class="grid grid-cols-12 p-0 mb-0 overflow-hidden">
                        <template v-if="relevantImages?.length > 0">
                            <template v-for="i in relevantImages" :key="i">
                                <div class="col-span-6 text-gray-900 f aic jcc border" v-if="imageArray?.[i]?.url" @click.prevent="changeSectionImage(imageArray[i])" :style="`background:${imageArray?.[i]?.color}`">
                                    <img v-if="imageArray?.[i]?.transparent" class="x br-10 o-contain p-3" style="max-height: 200px" :src="imageArray?.[i]?.url?.replace('Original', 'public')" :alt="imageArray[i]?.alt" />
                                    <div class="p-2">
                                        <img class="x br-10" :src="imageArray[i]?.url?.replace('Original', 'public')" :alt="imageArray?.[i].alt" />
                                    </div>
                                </div>
                            </template>
                        </template>
                        <div :key="image.url" class="col-span-6 text-gray-900 f aic jcc border" :style="`background:${image?.color}`" v-for="image in filteredImages" @click.prevent="changeSectionImage(image)">
                            <img v-if="image.transparent" class="x br-10 o-contain p-3" style="max-height: 200px" :src="image?.url?.replace('Original', 'public')" :alt="image?.alt" />
                            <div v-else class="p-2">
                                <img class="x br-10" :src="image?.url?.replace('Original', 'public')" :alt="image?.alt" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>
<script>
import { mapGetters } from "vuex";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
export default {
    name: "EmailImagePicker",
    components: { BaseButton },
    mixins: [styleGuideMixin, sectionStyleMixin],
    emits: ["close"],
    data() {
        return {
            searchQuery: "",
        };
    },
    props: {
        index: {},
        sectionKey: {
            type: String,
            required: true,
        },
        sectionKind: {
            type: String,
            default: "email",
        },
        imageArray: {
            type: Array,
            required: true,
        },
        identifier: {
            type: String,
            required: true,
        },
        relevantImages: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        filteredImages() {
            return this.imageArray.filter(image => {
                if (!image) return false;
                const query = this.searchQuery.toLowerCase();
                const altText = image.alt ? image.alt.toLowerCase() : "";
                const caption = image.caption ? image.caption.toLowerCase() : "";

                if (this.searchQuery) {
                    return altText.includes(query) || caption.includes(query);
                } else {
                    return image;
                }
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.searchInput.focus();
        });
    },

    methods: {
        changeSectionImage(image) {
            // get index from image
            const imageIndex = this.imageArray.findIndex(i => i.url === image.url);
            if (this.sectionKind && this.sectionKind === "email") {
                // this.updateStyleGuideProp(`${this.sectionKey}.${this.index}.imageIndex`, imageIndex);
                this.updateSection("imageIndex", imageIndex);
            } else if (this.sectionKind && this.sectionKind === "streaming") {
                this.updateStreamProp(`${this.sectionKey}.${this.index}.imageIndex`, imageIndex);
            }
            this.updateSection("imageIndex", imageIndex);
            this.$emit("close");
        },
    },
};
</script>
