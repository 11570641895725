<template>
    <div>
        <table>
            <tbody>
                <tr v-for="(row, key, index) in tableData" :key="`row-${index}`">
                    <td v-for="(value, key) in row" class="text-xs" :key="key">
                        <span class="d-block fwb">{{key}}</span>
                        <template v-if="isImageUrl(value)">
                            <img class="x" :src="value" :alt="value" />
                        </template>
                        <template v-else-if="isUrl(value)">
                            <a :href="value">link</a>
                        </template>
                        <template v-else>
                            {{ value }}
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "DisplayTable",
    data() {
        return {};
    },
    props: {
        tableData: {
            type: Array,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
table {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        width: 100%;
        margin-bottom: 1rem;
        border-collapse: collapse;
        color: #48494d;

        th,
        td {
            padding: 10px 10px;
            text-align: left;
            vertical-align: center;
        }

        th {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #6e80a4;
            border-color: #e0e3eb;
            background-color: #f7f8fa;
            @apply dark:bg-base-800;
        }

        tr:nth-child(2n) {
            background-color: #fafbfd;
          @apply dark:text-base-200;
          @apply dark:bg-base-50/5;
        }

        tr:nth-child(2n + 1) {
            background-color: #ffffff;
            @apply dark:text-base-200;
            @apply dark:bg-base-50/10;

        }

        table,
        th,
        td {
            border: 1px solid #e0e3eb;
            @apply dark:border-base-900;
        }
    }
</style>
