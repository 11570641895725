import moment from "moment";

export default {
    created: function () {},
    mounted() {},
    data() {},

    computed: {
        chatLoading() {
            return this.$store.state.chatLoading;
        },
        hasChats() {
            return Array.isArray(this.chatItems) && this.chatItems.length > 0;
        },
        chatItems() {
            return this.$store.state.chatItems;
        },
    },
    methods: {
        scrollFeed() {
            const feed = this.$refs.feed;
            this.$nextTick(() => {
                feed.scrollTop = feed.offsetHeight;
            });
        },
        saveChatItem(response, request, type) {
            let chatItem;
            let html;
            let lyrics;
            const promptData = this.promptData;
            const prompt = this.promptText;
            let fixedResponse;
            try {
                fixedResponse = JSON.parse(response);
            } catch (e) {
                fixedResponse = response;
            }
            chatItem = {
                answer: response,
                prompt: request.prompt,
                promptData: promptData,
                response: response,
                request: request,
            };
            if (type === "titles") {
                chatItem.type = "titles";
                this.consoleObject("Titles Raw", response);
                try {
                    html = this.jsonString(response, "[JSON ERROR] Titles not parsed");
                    chatItem.bubbles = html;
                    chatItem.answer = html;
                } catch (e) {
                    chatItem.bubbles = response;
                }
            } else if (type === "lyrics") {
                chatItem.type = "lyrics";
                this.consoleObject("Lyrics Raw", response);
                try {
                    lyrics = this.jsonString(response, "[JSON ERROR] Lyrics not parsed");
                    if (lyrics) {
                        chatItem.answer = lyrics;
                        chatItem.html = lyrics;
                    } else {
                        chatItem.answer = lyrics;
                        chatItem.html = false;
                    }
                    chatItem.lyrics = true;
                } catch (error) {
                    console.error("[JSON ERROR] Lyrics not parsed");
                    chatItem.html = "no html";
                }
            } else if (type === "concepts") {
                chatItem.type = "concepts";
                this.consoleObject("Concepts Raw", response);
                try {
                    let concepts = this.jsonString(response, "[JSON ERROR] Concepts not parsed");

                    if (concepts) {
                        chatItem.bubbles = concepts;
                        chatItem.answer = concepts;
                        chatItem.html = concepts;
                    }
                } catch (error) {
                    console.error("[JSON ERROR] Concepts not parsed");
                    chatItem.bubbles = response;
                }
            } else if (type === "chat") {
                chatItem.type = "chat";
                chatItem.answer = response.message;
                chatItem.prompt = response;
                chatItem.promptData = response;
                chatItem.sender = response.send;
                chatItem.response = response;
                chatItem.request = response;
                chatItem.bubbles = [{ id: this.randomId(), message: response }];
            }
            const randomId = this.randomId();
            chatItem.id = randomId;
            chatItem.step = this.step;
            this.consoleObject("CHAT ITEM", chatItem);
            this.$store.commit("addToChat", chatItem);
            this.$nextTick(() => {
                if (!this.toggleView) {
                    this.$store.commit("setToggleView", true);
                    setTimeout(() => {
                        this.scrollChatItem();
                    }, 400);
                } else {
                    this.scrollChatItem();
                }
            });
            return chatItem;
        },
        scrollChatItem() {
            const element = document.getElementById("feed");
            if (element) {
                this.$nextTick(() => {
                    // element.scrollTop = element.scrollHeight;
                    console.log("SCROLL!!!");
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: "smooth",
                    });
                });
            }
        },
    },
};
