import extractText from "@/mixins/Chat/Commands/extract_text";
import getTokenCount from "@/mixins/ai/get_token_count";
import logBrowser from "@/mixins/Chat/Commands/log_browse";
import addProgressStep from "@/mixins/ai/add_progress_step";
import cleanURL from "@/mixins/ai/clean_url";
import updateProgress from "@/mixins/ai/update_progress";
import removeStep from "@/mixins/ai/remove_progress_step";
// import summarizePage from "@/mixins/Chat/Commands/summarize_page";

async function browseWebsiteSimple(store, url, showProgress, looking_for, summarize, context, instructions) {
    let tokenCount;

    let response = await extractText(url, looking_for, undefined, summarize, looking_for, instructions, context);

    let { page = "", links = [], images = [], metadata = {}, tokens, summary, text, original_text, summary_tokens } = response;
    let article = {
        url: url,
        content: page,
        links: links,
        images: images,
        metadata: metadata,
        tokens: tokens,
    };
    // if (url.includes("wikipedia")) {
    delete article.links;
    // }
    if (tokens) article.tokens = tokens;
    if (text && original_text) article.summary = text;
    if (text && original_text) article.content = original_text;
    if (summary_tokens) article.summary_tokens = summary_tokens;
    if (article.content === "") {
        if (showProgress) return { content: "", links, images, metadata };
    }

    let finalResponse;
    article.kind = "article";
    finalResponse = article;
    logBrowser({ url, page, links, images, metadata, article, finalResponse, summary, summary_tokens, tokens });
    return finalResponse;
}
window.browseWebsiteSimple = browseWebsiteSimple;
export default browseWebsiteSimple;

// let summaryPrompt = "You are a helpful assistant who helps condense website content." + `Shorten all content to about 10 information-dense bullet points. Readability is less important than information density. Include all main ideas, entities, and details from the original text. Relate everything back to ${about} Don't chat.`;
// let summaryPrompt = "You are a helpful assistant who helps shorten text." + "Shorten all content to about 2 paragraphs (max). Include all main ideas, entities, and details from the original text. Don't chat. Use Markdown. Bold Important entities.";
// if (this.researchMessages && this.researchMessages.length > 0) {
//     messages = [...messages, ...this.researchMessages];
// }

// messages.push(userMessage(`Can you shorten this and keep all the main ideas? Return results in markdown best suited to the content. Use markdown. Keep it information-dense. Bold important entities. \n\n${page}\n\n`));
