<template>
    <div v-if="email" class="border-light br-10 col-span-3 overflow-hidden bg-gray-50">
        <!--        <div class="d-block x aspect-h-1 aspect-w-2" v-if="image"><img class="o-cover x" :src="image" /></div>-->
        <div class="p-2">
            <div v-if="subject" class="d-block fwb f-20 mb-1">{{ subject }}</div>
            <div v-if="preview" class="d-block f-17">{{ preview.trim() }}</div>
            <!--            <div class="d-block f-15 mt-3"><a v-if="url && date" :href="url">{{ date }}</a></div>-->
        </div>
    </div>
</template>
<script>
export default {
    name: "EmailPreview",
    props: {
        email: { type: Object },
    },
    computed: {
        url() {
            return this.email.url || "";
        },
        date() {
            return this.email.date || "";
        },
        image() {
            return this.email.image || "";
        },
        subject() {
            if (this.email.subject) {
                return this.email.subject.replace("-", "").trim();
            } else {
                return this.email.subject || "";
            }
        },
        preview() {
            if (this.email.preview) {
                return this.email.preview.replace("-", "").trim();
            } else {
                return this.email.preview || "";
            }
        },
    },
};
</script>
