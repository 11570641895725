<template>
    <div class="relative dark:bg-base-700 z-5 block mw-500 x z-10 px-0">
        <div>
            <BaseInput v-model="query" :iclass="`!rounded-none !border-none ${iclass}`" class="mb-0 br-10 overflow-hidden border-none rounded-full rounded-none" inline placeholder="Search for a brand..." type="search" @keydown.up="highlightPrev" @keydown.enter="selectHighlighted" @keydown.down="highlightNext" />
        </div>
        <div v-if="query.includes('http') || query.includes('.com')" class="mx-auto x z-5 isolate">
            <div class="shadow br-5 p-absolute z-10 x border-light overflow-hidden f aic text-left py-3 bg-snow">
                <div class="p-3">
                    <p class="o-5 fwb f-17">This brand doesn't exist yet. Want to add it?</p>
                    <div class="f ais gap-2">
                        <div class="flex-shrink-0 pt-1">
                            <img v-if="newSite.image" :src="newSite.image" class="c-50 o-cover border-light overflow-hidden" />
                        </div>
                        <div>
                            <span v-if="newSite.name" class="block fwb">{{ newSite.name }}</span>
                            <p v-if="newSite.description" class="line-clamp-2 o-5 f-15">{{ newSite.description }}</p>
                            <span v-if="newSite.url" class="block o-5 f-11 text-uppercase">{{ newSite.url }}</span>
                        </div>
                    </div>
                </div>
                <div class="pr-3">
                    <BaseButton class="mt-3" icon="fas fa-plus" label="Add" reverse rounded size="xs" @click="createNewFromURL(newSite)" />
                </div>
            </div>
        </div>
        <div v-if="brands?.length > 0 && query !== ''" class="mx-auto x z-5 isolate">
            <div class="shadow br-5 p-absolute bottom z-10 x border-light height-max-300 scrollbar-hide scrollSection">
                <ul class="list-none">
                    <div v-for="(brand, index) in brands.slice(0, 10)" :key="brand.id" @click="selectBrand(brand)">
                        <BrandListItem v-if="!offerNew" :brand="brand" :class="{ highlighted: index === highlightedIndex }" class="bg-snow x border-top !p-2" @click="" />
                    </div>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import webRequest from "@/mixins/ai/web_request";
import { mapGetters } from "vuex";
import BrandListItem from "@/components/styleGuide/ListOfGuides/BrandListItem.vue";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import findMatchingObject from "@/mixins/firebase/findMatchinObject";
import createStyleGuideMixin from "@/mixins/StyleGuideMixins/CreateStyleGuideMixin";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { findBrand } from "@/mixins/ai/find_brand";

export default {
    name: "StyleGuideSearchNew",
    components: { BaseButton, BaseInput, BrandListItem },
    mixins: [createStyleGuideMixin, styleGuideMixin],
    data() {
        return {
            query: "",
            brands: [],
            highlightedIndex: -1,
            debouncedSearchBrand: null,
            offerNew: false,
            newSite: {
                loader: false,
                name: null,
                url: null,
                description: null,
            },
        };
    },
    props: {
        iclass: {
            type: String,
            default: false,
        },
    },
    watch: {
        query: {
            async handler(val, oldVal) {
                if (val && val.includes("http")) {
                    if (val && oldVal && val === oldVal) return;
                    await this.findNewBrand(val); // Now debounced
                } else if (val && val.length > 2) {
                    await this.debounceSearch(val);
                }
            },
            immediate: true,
        },
    },
    // eslint-disable-next-line vue/order-in-components
    computed: {
        ...mapGetters("styleGuide", ["styleGuides"]),
    },
    async mounted() {},
    methods: {
        debounceSearch: _.debounce(async function (query) {
            let brands = await this.searchBrand(query);
        }, 500), // 5
        async searchBrand(input) {
            if (!input) {
                this.brands = []; // Clear results if input is cleared
                return;
            }
            try {
                const results = await webRequest("searchBrands", { query: input });
                let brands = await results.json(); // Assuming results is the array of brands
                this.brands = brands.map(b => b.metadata);
                console.log("brands", this.brands);
            } catch (error) {
                console.error("Error fetching brands:", error);
                this.brands = [];
            }
        },
        findNewBrand: _.debounce(async function (url) {
            let metadata = await this.getMetadata(url);
            this.newSite = metadata;
        }, 500), // 500ms delay
        async getMetadata(url) {
            this.metadataLoader = true;
            const request = { url: url };
            let response = await webRequest("metadata", request);

            if (!response.ok) {
                this.metadataLoader = false;
                console.error(`HTTP request failed with status ${response.status}`);
                return;
            }

            // Check if the response is JSON before trying to parse it
            if (response.headers.get("content-type").includes("application/json")) {
                let { site_name, description, title, image } = await response.json();
                this.metadataLoader = false;
                let data = { name: site_name || title, description: description, url: url, image };
                console.log(data);
                return data;
            } else {
                // Handle non-JSON responses here
                this.metadataLoader = false;
                console.error("Non-JSON response received:", response);
            }
        },
        async createNewFromURL(newSite) {
            let object = newSite;
            object = await saveToFirebase("brands", object);
            await this.createStyleGuide(object.url, object.id, { newGuide: true });
            this.$router.push(`/styleGuide/${object.id}`);
            await saveToFirebase("brandsList", object);
        },
        async selectBrand(brand) {
            logGroup("selectBrand", { ...brand });
            if (!brand) brand = this.brand;
            // find index baseed on the url in this.styleGuides
            let id = brand.id;
            let url = brand.url;
            let name = brand.name;
            let primaryBrand = await findBrand(id, url, name, "brands");
            if (primaryBrand?.id) {
                logGroup("Primary Brand", { ...primaryBrand });
                await saveToFirebase("brandsList", { ...brand, match: primaryBrand.id }, id);
                this.$router.push(`/styleGuide/${primaryBrand.id}`);
            }
            if (!primaryBrand || !primaryBrand?.id) {
                // this.offerNew = true;
                console.log("creating new");
                let newBrand = await saveToFirebase("brands", { ...brand, match: id }, id);
                let updated = await saveToFirebase("brandsList", { ...brand, match: newBrand.id }, id);
                console.log("updated", updated);
                this.$router.push(`/styleGuide/${newBrand.id}`);

                // await this.createStyleGuide(url, newBrand.id, { newGuide: true, id: newBrand.id });
            }
        },
        highlightPrev() {
            if (this.highlightedIndex > 0) this.highlightedIndex--;
        },
        highlightNext() {
            if (this.highlightedIndex < this.filteredBrands.length - 1) this.highlightedIndex++;
        },
        selectHighlighted() {
            let index = this.highlightedIndex;
            let brand = this.filteredBrands[index];
            // console.log("selectHighlighted", index, brand);
            if (this.brand.id) {
                this.selectBrand(brand);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
input:focus {
    outline: none;
    box-shadow: 0 0 5px rgb(132, 193, 211);
}

.highlighted {
    background: #e6f8ff;
    @apply bg-blue-50 border-blue-50 text-blue-600;
}
</style>
