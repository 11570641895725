<template>
    <ul class="flex flex-col justify-end text-start -space-y-px">
        <MessageDownload v-for="file in files" />
    </ul>
</template>
<script>
import MessageDownload from "@/views/MessageDownload.vue";

export default {
    name: "MessageFileUploads",
    components: { MessageDownload },
    props: {
        files: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
