<template>
    <UIWrapperText title="Text samples" section="textSamples">
        <div class="col-span-12 gap-2">
            <div class="x col-span-12">
                <UICollapsableSection v-if="sources?.length > 0">
                    <template #title>
                        <div class="f block width-100 gap-3 mb-0 x">
                            <div class="mb-0 ml-2 mr-auto x mr-3">Sources</div>
                            <div class="x">
                                <div class="c-20 f aic jcc border-light f-11 bg-gray-100">{{ sources.length }}</div>
                            </div>
                        </div>
                    </template>
                    <div class="relative">
                        <ReadAdditionalSources v-if="sources?.length > 0" :sources="sources" />
                    </div>
                </UICollapsableSection>
                <UICollapsableSection>
                    <template #title>Writing samples</template>
                    <div class="f fc gap-2 bg-gray-50 p-4">
                        <h5>Headers</h5>
                        <div class=" f fc gap-4">
                            <UICollapsableSection v-for="(sampleArray, title) in orderedTextSamples" :key="title">
                                <template #title>{{ formatTitle(title) }}</template>
                                <div class="p-3 bg-snow">
                                    <p class="p-1" v-for="sample in sampleArray" :key="sample">{{ sample }}</p>
                                </div>
                            </UICollapsableSection>
                            <BaseAccordion v-if="plainTextTextSamples">
                                <template #title>All Samples</template>
                                <div class="p-3">
                                    <p class="p-1 whitespace-pre-line">{{ plainTextTextSamples }}</p>
                                </div>
                            </BaseAccordion>
                            <BaseAccordion v-if="styleGuide?.copyGuidelines">
                                <template #title>Guidelines</template>
                                <pre class="whitespace-break-spaces mw-700 p-3">{{ styleGuide.copyGuidelines }}</pre>
                            </BaseAccordion>
                        </div>
                    </div>
                </UICollapsableSection>
            </div>
        </div>
    </UIWrapperText>
</template>
<script>
import BaseAccordion from "@/components/CoreUI/BaseAccordion.vue";
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import CopyGuidelines from "@/components/generative_templates/Guidelines/CopyGuidelines.vue";
import PieDisplsy from "@/components/styleGuide/PieDisplsy.vue";
import streamCompletion from "@/mixins/ai/stream_completion";
import ThemeSamples from "@/components/styleGuide/Sections/Themes/ThemeSamples.vue";
import ThemeSamplesPicker from "@/components/styleGuide/Sections/Themes/ThemeSamplesPicker.vue";
import webRequest from "@/mixins/ai/web_request";
import ReadAdditionalSources from "@/components/generative_templates/Guidelines/ReadAdditionalSources.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";

export default {
    name: "StyleGuideTextSamples",
    mixins: [StyleGuideMixin],
    components: { UIWrapperText, UICollapsableSection, ReadAdditionalSources, ThemeSamplesPicker, ThemeSamples, CopyGuidelines, StyleGuideContainer, BaseAccordion },
    data() {
        return {
            copyGuideProp: null,
        };
    },
    props: {
        samples: {},
    },
    methods: {
        formatTitle(title) {
            if (title.startsWith("Header")) {
                return `Headers ${title.substring(6)}`;
            }
            return title;
        },
    },
    computed: {
        sources() {
            if (this.$store.state?.styleGuide?.sources?.length > 0) return this.$store.state.styleGuide.sources;
            else return this.styleGuide.additionalText || [];
        },
        orderedTextSamples() {
            // Combine all text samples into a flat structure
            const { paragraphs, headers, buttons, links, spans, divs, lists, sections: shopifySections } = this.textSamples;

            // Create an object with header keys sorted and prepended with 'Header'
            const sortedHeaders = Object.keys(headers)
                .sort((a, b) => parseInt(a.substring(1)) - parseInt(b.substring(1)))
                .reduce((acc, key) => {
                    acc[`Header${key}`] = headers[key];
                    return acc;
                }, {});
            let sections = {
                ...sortedHeaders,
                Paragraphs: paragraphs,
                Buttons: buttons,
                Links: links,
                Spans: spans,
                Divs: divs,
                Lists: lists,
                Sections: shopifySections,
            };
            if (this.textSamples.emailSubjects) sections["Email Subjects"] = this.textSamples.emailSubjects;
            if (this.textSamples.emailPreviews) sections["Email Previews"] = this.textSamples.emailPreviews;
            if (this.textSamples.productCopy) sections["Product Copy"] = this.textSamples.productCopy;

            return sections;
        },
        plainTextTextSamples() {
            return this.$store.getters["styleGuide/llm/plainTextSamples"];
        },
    },
};
</script>
