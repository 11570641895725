<template>
    <div class="p-3 container">
        <StyleGuideFonts hide-letter />
        <AssignedStyleDisplay v-if="styleGuide.assignedStyles" />
    </div>
</template>

<script>
import styleGuideBrandMixin from "@/mixins/StyleGuideMixins/StyleGuideBrandMixin";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import StyleGuideFonts from "@/components/styleGuide/Typography/StyleGuideFonts.vue";
import AssignedStyleDisplay from "@/components/styleGuide/Typography/AssignedStyleDisplay.vue";

export default {
    components: { AssignedStyleDisplay, StyleGuideFonts },
    name: "Fonts",
    mixins: [StyleGuideMixin, styleGuideBrandMixin],
    data() {
        return {};
    },
    computed: {},
    methods: {},
    created() {},
};
</script>
