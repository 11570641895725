<template>
    <div class="x animated swingInUp duration-5">
        <h5 class="animated swingInUp duration-5 d-2" v-if="title">{{ title }}</h5>
        <div class="f x animated duration-5 swingInUp d-4 flex-nowrap" :class="{ 'br-10 border-light shadow': rounded }">
            <Swiper :grabCursor="true" ref="swiper" :autoHeight="false" :slidesPerView="'auto'" :spaceBetween="0" navigation @swiper="onSwiper" :pagination="{ clickable: true }" :loop="true" :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }" class="x block items-stretch">
                <SwiperSlide appear v-for="(image, index) in images" class="d-block y flex-1 flex-shrink-0">
                    <img class="y o-cover" v-if="imageURL(image)" :src="imageURL(image)" :class="`height-${height} height-min-${height} height-max-${height}`" />
                    <div class="p-absolute p-fill f aie jcs h-full">
                        <div class="gradientBottom x p-absolute bottom left right o-8 height height-min-150 h-auto"></div>
                        <a :href="image.link || '#'" class="z-5 relative" target="_blank">
                            <div class="z-5 p-3 text-snow" v-if="image.source">
                                <div class="fwb line-clamp-1">{{ imageSource(image.title) }}</div>
                                <div class="o-6 text-sm line-clamp-1" v-if="image.title">{{ imageSource(image.source) }}</div>
                            </div>
                        </a>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div class="border-light swiper-button-next p-absolute top bottom c-50 z-5 f align-fixed-center aic jcc -right-5 bg-snow shadow" @click="swiperInstance.slideNext()">
                <i class="fas fa-arrow-right"></i>
            </div>
            <div class="border-light swiper-button-prev p-absolute top bottom c-50 z-5 f align-fixed-center aic jcc -left-5 bg-snow shadow" @click="swiperInstance.slidePrev()">
                <i class="fas fa-arrow-left"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
// import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss";

export default {
    components: { Swiper, SwiperSlide },
    name: "InlineImages",
    data: function () {
        return {
            swiper: null,
            swiperInstance: null,
        };
    },
    props: {
        height: {
            type: Number,
            default: 200,
        },
        images: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        rounded: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.swiper = this.$refs.swiper.$swiper;
    },
    methods: {
        imageSource(source) {
            try {
                let string = new URL(source).hostname;
                string = string.replace("www.", "");
                return string;
            } catch (error) {
                return source;
            }
        },
        onSwiper(swiperInstance) {
            this.swiperInstance = swiperInstance;
            this.swiper = this.$refs.swiper.$swiper;
        },
        imageURL(image) {
            if (image.url) {
                return image.url;
            } else if (image.url) {
                return image.url;
            } else if (typeof image === "object") {
                return image.url;
            } else {
                return image.image;
            }
        },
    },
};
</script>
<style lang="scss">
hr{
  @apply my-5;
  // style the hr
  border-top: 1px solid;
  @apply border-t border-gray-100;
  @apply dark:border-base-500;
}
</style>
