import updateQueueProp from "@/mixins/ai/update_queue_prop";
import updateDocumentProp from "@/mixins/ai/update_document_prop";
function addImagesToQueue(store, images, url) {
    if (Array.isArray(images)) {
        let object = { images: images };
        if (url) object.prompt = url;
        store.dispatch("presentation/addImagesToQueue", object);
    } else {
        let object = { image: images };
        if (url) object.prompt = url;
        store.dispatch("presentation/pushToQueueImages", object);
    }
    if (store.state.presentation.document.images) {
        // updateQueueProp(store, "images", [...store.state.presentation.queue.images, ...store.state.presentation.document.images]);
        updateDocumentProp(store, "images", [...store.state.presentation.queue.images, ...store.state.presentation.document.images]);
    } else {
        // updateQueueProp(store, "images", [...store.state.presentation.queue.images, ...store.state.presentation.document.images]);
        // updateDocumentProp(store, "images", store.state.presentation.queue.images);
    }
}

export default addImagesToQueue;
