<template>
    <div>
        <div class="f gap-4 mx-auto jcc text-center">
            <div v-for="(section, index) in sections" :key="index" :class="{ 'font-bold': isSelectedSection(index) }" class="py-3 border-bottom last:border-none" @mouseover="selectSection(index)">
                {{ section.name }}
            </div>
            <div class="py-3" @mouseover="selectSection(null)">All</div>
        </div>
        <!--        <div class="col-span-10">-->
        <!-- Display the selected section -->
        <template v-if="selectedSection !== null">
            <CopyGuideSection :key="selectedSection" :section="sections[selectedSection]" />
        </template>
        <!-- Display all sections when 'All' is hovered -->
        <template v-else>
            <CopyGuideSection v-for="(section, index) in sections" :key="index" :section="section" />
        </template>
        <!--        </div>-->
    </div>
</template>

<script>
import CopyGuideSection from "@/components/generative_templates/Guidelines/CopyGuideSection.vue";

export default {
    name: "WritingSamples",
    components: { CopyGuideSection },
    props: {
        processedSections: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedSection: 0, // Default to the first section
        };
    },

    computed: {
        sections() {
            let processedSections = this.processedSections || [];
            return processedSections;
        },
    },
    methods: {
        selectSection(index) {
            this.selectedSection = index;
        },
        isSelectedSection(index) {
            return this.selectedSection === index;
        },
    },
};
</script>
