function updateSlideProp(store, key, value, index) {
    try {
        // const oldVal = this.getNestedValue(this.$store.state.document.sections[index], key);
        store.dispatch("presentation/updateSlideProp", { keyPath: key, value: value, index: index });
        // const newVal = this.getNestedValue(this.$store.state.document.sections[index], key);
        // this.compareAndLogChanges(newVal, oldVal);
    } catch (error) {
        console.error("updateSlideProp", error.message, key, value, index);
    }
}
export default updateSlideProp;
