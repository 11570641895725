<template>
    <div class="x vh-70 f p-fixed p-fill z-3 aic jcc bg-snow py-8 text-center">
        <div>
            <div class="floating-item mb-5">
                <h2 class="animated swingInUp text-nhap d-1 ease-back">Type in any subject</h2>
            </div>
            <div class="floating-item form-group animated swingInUp br-5 d-2 f-20 mx-auto max-w-md bg-snow text-center shadow ease-back">
                <input v-model="presentationPrompt" class="border-light d-block x br-5 !f-24 mb-0 rounded p-2 pb-0 text-gray-900 placeholder-gray-400" placeholder="Literally, any subject ..." style="color: black" type="text" @keydown.enter="newDeck()" />
            </div>
            <!-- toggle selector (ie. styled radio selector) for story or presentation -->
            <!--            <slide-count @slide-count="slideCount"/>-->
            <!-- toggle selector (ie. styled radio selector) for story or presentation -->

            <!--            <toggle-selector class="mx-auto x text-center mw-600" @change="setKind" :kind="kind" ref="toggleSelector" :kinds="presentationKinds"/>-->
            <!--            <toggle-selector class="mx-auto x text-center mw-600" @change="setCount" :kind="kind" ref="toggleSelector" :kinds="deckLength"/>-->
            <div class="f fc floating-item aic jcc mx-auto mt-5 ease-back">
                <a class="x btn btn-primary animated swingInUp border-light d-3 rounded-full shadow ease-back" @click.prevent="newDeck()">Start</a>
                <a class="x btn btn-link animated swingInUp d-4" @click.prevent="close()">cancel</a>
            </div>
        </div>
    </div>
</template>
<script>
import DocumentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import ToggleSelector from "@/components/Presentations/ToggleSelector.vue";
import SlideCount from "@/components/Presentations/SlideCount.vue";

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { SlideCount, ToggleSelector },
    mixins: [DocumentManagementMixin],
    data: () => ({
        presentationPrompt: null,
        slideCount: 5,
        kind: "presentation",
        presentationKinds: [
            { name: "presentation", value: "presentation" },
            { name: "story", value: "story" },
        ],
        deckLength: [
            { name: "short", value: 5 },
            { name: "medium", value: 10 },
            { name: "long", value: 15 },
        ],
    }),
    methods: {
        setCount(payload) {
            console.error("setCount", payload);
            this.slideCount = payload;
        },
        setKind(payload) {
            console.error("setKind", payload);
            this.kind = payload;
        },
        async close() {
            await this.removeFromFirebase("documents", this.$route.params.id);
            this.$router.go(-1);
            this.$emit("close");
        },
        newDeck() {
            this.$emit("newDeck", this.presentationPrompt, this.kind, this.slideCount);
        },
        handleChange(value) {
            this.kind = value;
        },
    },
    props: {
        newDeckStarted: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
