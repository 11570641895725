function gptError(e) {
    let message;

    if (e.response && e.response.data && e.response.data.error && e.response.data.error.message) {
        message = e.response.data.error.message;
    } else {
        message = e;
    }
    return message;
}
export default gptError;
