<template>
    <div class="">
        <div class="mw-1100 p-3 gap-2 bg-gray-100 mx-auto m-0 f fc">
            <presentation-progress-step font-size="17" class="x" v-for="obj in Object.values(progressObjects)" :key="obj.name" :progress-step="obj" />
        </div>
    </div>
</template>
<script>
import PresentationProgressStep from "@/components/Presentations/slides/PresentationProgressStep.vue";

export default {
    name: "StyleGuideProgress",
    components: { PresentationProgressStep },
    props: {
        styleGuide: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            progressOld: [
                "allTextStyles",
                "assignedStyles",
                "backgrounds",
                "base",
                "baseBackground",
                "baseColor",
                "baseSize",
                "bg_color_palette",
                "bodyStyle",
                "buttonStyles",
                "buttons",
                "colors",
                "deduplicatedText",
                "description",
                "divStyles",
                "email",
                "fonts",
                "headerStyles",
                "headersStyles",
                "id",
                "images",
                "logos",
                "metadata",
                "name",
                "pStyles",
                "pageTitle",
                "shopify",
                "textSamples",
                "text_styles",
                "title",
                "typography",
                "typographyCombos",
                "url",
                "videos",
                "websiteUrl",
            ],
            progressObjects: {
                name: { id: "name", name: "name", text: "Name", complete: false },
                description: { id: "description", name: "description", text: "Description", complete: false },
                fonts: { id: "fonts", name: "Typefaces", text: "", complete: false },
                allTextStyles: { id: "allTextStyles", name: "All Text Styles", text: "", complete: false },
                typographyCombos: { id: "typographyCombos", name: "Typography Combos", text: "", complete: false },
                // text_styles: { id: "text_styles", name: "Text Styles", text: "Text styles", complete: false },
                deduplicatedText: { id: "deduplicatedText", name: "Deduplicated Text Styles", text: "", complete: false },
                pStyles: { id: "pStyles", name: "Paragraphs", text: "", complete: false },
                assignedStyles: { id: "assignedStyles", name: "Assigned Text Styles", text: "", complete: false },
                buttonStyles: { id: "buttonStyles", name: "Buttons", text: "", complete: false },
                colors: { id: "colors", name: "colors", text: "", complete: false },
                divStyles: { id: "divStyles", name: "Color Combinations", text: "", complete: false },
                textSamples: { id: "textSamples", name: "Brand voice", text: "", complete: false },
                images: { id: "images", name: "images", text: "", complete: false },
                logos: { id: "logos", name: "logos", text: "", complete: false },
                shopify: { id: "shopify", name: "Store", text: "", complete: false },
                email: { id: "email", name: "email", text: "", complete: false },
                videos: { id: "videos", name: "videos", text: "", complete: false },
                svgs: { id: "videos", name: "SVG Files", text: "", complete: false },
                // typography: { id: "typography", name: "typography", text: "Typography", complete: false },
                // bg_color_palette: { id: "bg_color_palette", name: "Background Color Pallette", text: "Bg color palette", complete: false },
                // bodyStyle: { id: "bodyStyle", name: "bodyStyle", text: "Body style", complete: false },
                // baseSize: { id: "baseSize", name: "baseSize", text: "Base size", complete: false },
                // buttons: { id: "buttons", name: "buttons", text: "Buttons", complete: false },
                // headersStyles: { id: "headersStyles", name: "Header Styles", text: "Headers styles", complete: false },
                // backgrounds: { id: "backgrounds", name: "backgrounds", text: "Backgrounds", complete: false },
                // base: { id: "base", name: "base", text: "Base", complete: false },
                // headerStyles: { id: "headerStyles", name: "headerStyles", text: "Header styles", complete: false },
                // baseBackground: { id: "baseBackground", name: "Background Color", text: "Base background", complete: false },
                // baseColor: { id: "baseColor", name: "baseColor", text: "Text color", complete: false },
                // pageTitle: { id: "pageTitle", name: "pageTitle", text: "Page title", complete: false },
                // metadata: { id: "metadata", name: "metadata", text: "Metadata", complete: false },
                // title: { id: "title", name: "title", text: "Title", complete: false },
                // url: { id: "url", name: "url", text: "Url", complete: false },
                // websiteUrl: { id: "websiteUrl", name: "websiteUrl", text: "Website url", complete: false },
            },
        };
    },
    watch: {
        progressTracker: {
            immediate: true,
            deep: true,
            handler(newProgress) {
                if (this.styleGuide && this.styleGuide.url) {
                    Object.keys(newProgress).forEach(key => {
                        if (this.progressObjects[key]) {
                            this.progressObjects[key].complete = newProgress[key];
                            this.progressObjects[key].data = this.styleGuide[key];
                            if (Array.isArray(this.styleGuide[key])) {
                                let arrayLength = this.styleGuide[key].length;
                                this.progressObjects[key].text = arrayLength;
                                if (arrayLength > 0) {
                                    this.progressObjects[key].complete = true;
                                } else {
                                    this.progressObjects[key].complete = false;
                                }
                            } else if (typeof this.styleGuide[key] === "object") {
                                let keyLength = Object.keys(this.styleGuide[key]).length;
                                if (keyLength > 0) {
                                    this.progressObjects[key].complete = true;
                                } else {
                                    this.progressObjects[key].complete = false;
                                }
                                this.progressObjects[key].text = keyLength;
                            } else if (typeof this.styleGuide[key] === "string") {
                                this.progressObjects[key].text = this.styleGuide[key];
                            }
                        }
                    });
                }
            },
        },
    },
    computed: {
        allTextStylesComplete() {
            if (this.styleGuide?.allTextStyles) return true;
            return false;
        },
        assignedStylesComplete() {
            if (this.styleGuide?.assignedStyles) return true;
            return false;
        },
        backgroundsComplete() {
            if (this.styleGuide?.backgrounds) return true;
            return false;
        },
        baseComplete() {
            if (this.styleGuide?.base) return true;
            return false;
        },
        baseBackgroundComplete() {
            if (this.styleGuide?.baseBackground) return true;
            return false;
        },
        baseColorComplete() {
            if (this.styleGuide?.baseColor) return true;
            return false;
        },
        baseSizeComplete() {
            if (this.styleGuide?.baseSize) return true;
            return false;
        },
        bg_color_paletteComplete() {
            if (this.styleGuide?.bg_color_palette) return true;
            return false;
        },
        bodyStyleComplete() {
            if (this.styleGuide?.bodyStyle) return true;
            return false;
        },
        buttonStylesComplete() {
            if (this.styleGuide?.buttonStyles) return true;
            return false;
        },
        buttonsComplete() {
            if (this.styleGuide?.buttons) return true;
            return false;
        },
        colorsComplete() {
            if (this.styleGuide?.colors) return true;
            return false;
        },
        deduplicatedTextComplete() {
            if (this.styleGuide?.deduplicatedText) return true;
            return false;
        },
        descriptionComplete() {
            if (this.styleGuide?.description) return true;
            return false;
        },
        divStylesComplete() {
            if (this.styleGuide?.divStyles) return true;
            return false;
        },
        emailComplete() {
            if (this.styleGuide?.email) return true;
            return false;
        },
        fontsComplete() {
            if (this.styleGuide?.fonts) return true;
            return false;
        },
        headerStylesComplete() {
            if (this.styleGuide?.headerStyles) return true;
            return false;
        },
        headersStylesComplete() {
            if (this.styleGuide?.headersStyles) return true;
            return false;
        },
        idComplete() {
            if (this.styleGuide?.id) return true;
            return false;
        },
        imagesComplete() {
            if (this.styleGuide?.images) {
                if (this.styleGuide?.images?.length > 0 && this.styleGuide.images?.[0]?.score) {
                    return true;
                }
            }
            return false;
        },
        logosComplete() {
            if (this.styleGuide?.logos) return true;
            return false;
        },
        metadataComplete() {
            if (this.styleGuide?.metadata) return true;
            return false;
        },
        nameComplete() {
            if (this.styleGuide?.name) return true;
            return false;
        },
        pStylesComplete() {
            if (this.styleGuide?.pStyles) return true;
            return false;
        },
        pageTitleComplete() {
            if (this.styleGuide?.pageTitle) return true;
            return false;
        },
        shopifyComplete() {
            if (this.styleGuide?.shopify) return true;
            return false;
        },
        textSamplesComplete() {
            if (this.styleGuide?.textSamples) return true;
            return false;
        },
        text_stylesComplete() {
            if (this.styleGuide?.text_styles) return true;
            return false;
        },
        titleComplete() {
            if (this.styleGuide?.title) return true;
            return false;
        },
        typographyComplete() {
            if (this.styleGuide?.typography) return true;
            return false;
        },
        typographyCombosComplete() {
            if (this.styleGuide?.typographyCombos) return true;
            return false;
        },
        urlComplete() {
            if (this.styleGuide?.url) return true;
            return false;
        },
        videosComplete() {
            if (this.styleGuide?.videos) return true;
            return false;
        },
        websiteUrlComplete() {
            if (this.styleGuide?.websiteUrl) return true;
            return false;
        },
        progressTracker() {
            return {
                allTextStyles: this.allTextStylesComplete,
                assignedStyles: this.assignedStylesComplete,
                backgrounds: this.backgroundsComplete,
                base: this.baseComplete,
                baseBackground: this.baseBackgroundComplete,
                baseColor: this.baseColorComplete,
                baseSize: this.baseSizeComplete,
                bg_color_palette: this.bg_color_paletteComplete,
                bodyStyle: this.bodyStyleComplete,
                buttonStyles: this.buttonStylesComplete,
                buttons: this.buttonsComplete,
                colors: this.colorsComplete,
                deduplicatedText: this.deduplicatedTextComplete,
                description: this.descriptionComplete,
                divStyles: this.divStylesComplete,
                email: this.emailComplete,
                fonts: this.fontsComplete,
                headerStyles: this.headerStylesComplete,
                headersStyles: this.headersStylesComplete,
                id: this.idComplete,
                images: this.imagesComplete,
                logos: this.logosComplete,
                metadata: this.metadataComplete,
                name: this.nameComplete,
                pStyles: this.pStylesComplete,
                pageTitle: this.pageTitleComplete,
                shopify: this.shopifyComplete,
                textSamples: this.textSamplesComplete,
                text_styles: this.text_stylesComplete,
                typography: this.typographyComplete,
                // title: this.titleComplete,
                svgs: this.styleGuide?.svgs,
                typographyCombos: this.typographyCombosComplete,
                url: this.urlComplete,
                videos: this.videosComplete,
                websiteUrl: this.websiteUrlComplete,
            };
        },
    },
};
</script>
