<template>
    <div v-if="isColorValid">
        <div :class="`overflow-hidden mw-${w}`">
            <div class="f fc aic jcc overflow-hidden p-0">
                <div :class="`c-${w} width-max-100 border-light mb-1`" @click.prevent="showColorPicker" :style="colorMixStyle"></div>

                <div class="x text-left text-uppercase fwb x f-11 f fc gap-1 mt-2">
                    <span class="f gap-2">
                        <span class="fwb">RGB</span>
                        <span class="o-7">{{ rgbaColorString }}</span>
                    </span>
                    <span class="f gap-2">
                        <span class="fwb">CMYK</span>
                        <span class="o-7">{{ cmykColorString }}</span>
                    </span>
                    <span class="f gap-2">
                        <span class="fwb">HEX</span>
                        <span class="o-7">{{ tempColor }}</span>
                    </span>
                </div>
            </div>
        </div>

        <div v-if="colorPicker" class="color-picker-wrapper absolute -top-64">
            <!--            <teleport to="body">-->
            <SimpleColorPicker @update-color="updateColor" @close-picker="colorPicker = false" @input="updateColor" />
            <input type="color" v-model="tempColor" @input="updateColor" />
            <button @click="saveColor">Save</button>
            <!--            </teleport>-->
        </div>
    </div>
</template>
<script>
import chroma from "chroma-js";
import SimpleColorPicker from "@/components/documents/SimpleColorPicker.vue";
export default {
    name: "ColorPallet",
    components: { SimpleColorPicker },
    props: {
        w: {
            type: String,
            default: "100", // in increments of 50
            validator(value) {
                const numValue = Number(value); // Convert the value to a number for easier arithmetic operations
                return numValue <= 250 && numValue % 10 === 0; // Check if the value is not above 250 and if it's divisible by 10
            },
        },
        color: {
            type: [String, Array],
            validator(value) {
                return true; // return typeof value === "string" || Array.isArray(value);
            },
            required: true,
            default: "", // Set a default value
        },
    },
    data() {
        return {
            colorPicker: false,
            tempColor: this.color,
        };
    },
    methods: {
        showColorPicker() {
            this.colorPicker = true;
        },
        updateColor(color) {
            // Update the color values in real-time
            this.tempColor = color;
            this.$emit("color-update", color);
        },
        saveColor() {
            this.$emit("save-color", this.tempColor);
            this.colorPicker = false;
        },
    },
    computed: {
        colorMixStyle() {
            return `background: ${this.tempColor}; border:1px solid color-mix(in lab, ${this.tempColor} 20%, white, 50%)`;
        },
        rgbaColorString() {
            let color = chroma(this.tempColor).rgb();
            return `${color[0]} | ${color[1]} | ${color[2]}`;
        },
        cmykColorString() {
            let color = chroma(this.tempColor).cmyk();
            let c = +(parseFloat(color[0]) * 100).toFixed(0);
            let m = +(parseFloat(color[1]) * 100).toFixed(0);
            let y = +(parseFloat(color[2]) * 100).toFixed(0);
            let k = +(parseFloat(color[3]) * 100).toFixed(0);
            // return color;
            return `${c} | ${m} | ${y} | ${k}`;
        },
        // Check if color prop is a valid String or Array
        isColorValid() {
            return (typeof this.color === "string" || Array.isArray(this.color)) && this.color?.includes("#");
        },
    },
};
</script>
<!--<div :class="`c-${w} width-max-100 border-light mb-1`" :style="`background: ${color}; border:1px solid color-mix(in lab, ${color} 20%, white, 50%)`"></div>-->
