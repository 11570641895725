<template>
    <div class="inline-flex gap-3">
        <div v-if="rate" class="flex items-center mb-2 text-sm">
            <i v-for="n in Math.floor(rate)" :key="n" class="fas fa-star" />
            <i v-if="rate % 1 > 0" class="far fa-star-half-alt" />
        </div>
        <div v-if="reviewCount" class="text-sm mb-2">{{ reviewCount }} Reviews</div>
        <div v-else-if="rate" class="text-sm mb-2">{{ rate }}</div>
    </div>
</template>
<script>
export default {
    name: "ProductReviews",
    props: {
        rating: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        reviewCount() {
            return this.rating?.reviewCount || 0;
        },
        rate() {
            return this.rating?.rating || this.rating.ratingValue || "";
        },
    },
};
</script>
