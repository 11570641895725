import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import _ from "lodash";

import firestore, { db } from "@/firebase";
import * as firebase from "@/firebase";

function fixUndefinedProps(obj) {
    console.groupCollapsed(`%cFirebase`, "color: orange", "fixing props");
    console.log("Original Object", obj);
    for (const prop in obj) {
        if (obj[prop] === undefined) {
            obj[prop] = null;
            console.log(`%c"${prop}"`, "color: yellow", "was undefined");
        }
    }
    console.log("Updated Object", obj);
    console.groupEnd();
    return obj;
}

function debouncedFirebaseUpdate(...args) {
    const debouncedFirebaseUpdate = _.debounce(updateFirebaseObject, 100); // 100 milliseconds delay
    debouncedFirebaseUpdate(...args);
}

/**
 * Updates an array property within a Firestore document.
 *
 * @param {string} collectionName - Name of the Firestore collection.
 * @param {string} documentId - ID of the document to update.
 * @param {string} arrayPropertyName - Name of the array property to update.
 * @param {*} newItem - New item to add to the array.
 */
async function updateFirebaseArrayProperty(collectionName, documentId, arrayPropertyName, newItem) {
    const docRef = doc(db, collectionName, documentId);

    try {
        // Dynamically update the specified array property
        await updateDoc(docRef, {
            [arrayPropertyName]: arrayUnion(newItem),
        });
        let logString = `${collectionName}: Updated "${arrayPropertyName}" by adding ${newItem}`;
        console.log(logString);
    } catch (error) {
        console.groupCollapsed(`%cFirebase Error`, fail, `Error updating ${collectionName}`);
        console.log("collection", collectionName);
        console.log("documentId", documentId);
        console.log("arrayPropertyName", arrayPropertyName);
        console.log("newItem", newItem);
        console.error("Error updating array property: ", error);
        console.groupEnd();
    }
}

/**
 * This function updates a document in a Firebase Firestore database.
 *
 * @async
 * @param {string} database - The name of the Firestore database.
 * @param {Object} payload - The data to update the document with.
 * @param {string} id - The ID of the document to update.
 * @param {number} [tries=0] - The number of attempts made to update the document. This is used for recursive error handling.
 * @returns {Object|boolean} - Returns the payload if the document is successfully updated, or false if the maximum number of tries is reached.
 * @throws Will throw an error if the update operation fails.
 */
async function updateFirebaseObject(database, payload, id, tries = 0) {
    if (!id) {
        console.error("Document ID is required to update a Firebase document");
        return;
    }

    if (tries > 2) {
        console.error("Max number of tries reached. Aborting update.");
        return false;
    }

    // If undefined properties exist, fix them
    payload = fixUndefinedProps(payload);
    if (!payload.updated) {
        const updated = new Date();
        payload.updated = updated;
    }

    try {
        const docRef = doc(db, database, id);
        await updateDoc(docRef, payload).then(() => {
            console.groupCollapsed(`%c${database}`, warn, "updated");
            console.log("Document updated with ID: ", id);
            console.log("Payload", payload);
            console.log("docRef", docRef);
            console.groupEnd();
        });
        return payload;
    } catch (error) {
        console.groupCollapsed(`%cFirebase Error`, "color: red", `Error updating ${database}`);
        console.log(error.message);
        console.log(payload);
        console.groupEnd();
        return await updateFirebaseObject(database, payload, id, tries + 1);
    }
}

export { updateFirebaseObject, updateFirebaseArrayProperty };
