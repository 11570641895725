import { imgEnd, imgStart } from "@/mixins/Chat/chatStrings";
import { mapActions } from "vuex";
import { chalk } from "chalk";
import { modifierNames, foregroundColorNames } from "chalk";
import { templateStderr } from "chalk-template";
import processCommands from "@/mixins/Chat/Commands/processing/ProcessCommands";
export default {
    created: function () {},
    mounted() {},
    mixins: [processCommands],
    computed: {},
    methods: {
        // ...mapActions("chat", ["addMessageStore", "addToMemoryStore", "addToSummaryStore", "setMessages", "saveChatStore"]),
        // ...mapActions("user", ["saveUser"]),
        // get tokens since last summary
        async toolBot(content, index) {
            if (content.includes("[") && content.includes("]")) {
                // console.groupCollapsed(`%c🤖 Ignore`, bot);
                // console.log("%c" + content, txt);
                // console.log("index", index);
                // console.groupEnd();
                return;
            } else {
                console.groupCollapsed(`%c🤖 Request`, bot, content.slice(0, 20) + "...");
                console.log("%c" + content, txt);
                console.log("index", index);
                console.groupEnd();
            }
            let latest = { role: "assistant", content: content };
            let prompt = `You are tasked with assessing if the previous message needs any action from the list below to be completed. If yes, follow the instructions below to process the action.

You can chain multiple actions. If you search the web, do not return results, someone else is handling that.
You are not actually searching the web, you are simulating the action and returning the query.

--------- COMMAND INSTRUCTIONS ---------
Your tools:
- Search the Web: Use Google to access the internet, find information, search for things, research and more.
- Render code
- Generate Image: Describing the image prompt.
- Add to Memory: Save a short message to the system’s memory.
- No action: NONE

How to use your tools:
- Search the Web: [QST]<query>[QND]
- Render code: [START]<code>[END]
- Generate Image: [IST]<image_description>[IND]
- Save to Memory: [RST]<remember>[RND]
- No action: NONE

Examples of use:
1. “Here’s a picture of a cat: [IST]A cat cute sitting on a couch, dramatic -lighting. Editorial photograph. magazine cover.[IND]”
2. “I’ll look into flights for you now… [QST] Flights from Los Angeles to Bali [QND]”
3. “Let me research that a bit more [QST] Current best practices for Vue.js [QND]”
4. “I appreciate you letting me know. [RST]User is traveling to Bali next week[RND] I’ll find you some recommendations for your trip, one sec [QST] Michelin star restauraunts in Bali[QND]”
5. “I appreciate you letting me know. [RST]User is traveling to Bali next week[RND] I’ll find you some recommendations for your trip, one sec [QST] Michelin star restauraunts in Bali[QND]”

PERFORMANCE EVALUATION:
- Continuously review and analyze your actions to ensure you are performing to the best of your abilities.
- Every command has a cost, so be smart and efficient. Aim to complete tasks in the least number of steps.

RESPONSE FORMAT:
Exclusively use the commands in the format with brackets. e.g. 
[COMMANDSTART]< arg >[COMMANDEND]

Write the command now.`;
            // - Summarize Chat: [SST]<summary>[SND]
            // - Summarize Chat

            if (this.userPlaceTime) {
                // loop over the keys and values to output Key: value
                let userPlaceTime = this.userPlaceTime;
                let userPlaceTimeString = Object.entries(userPlaceTime)
                    .map(([key, value]) => `${key.toUpperCase()}: ${value} `)
                    .join("\n\n");
                prompt += `\n\n## USER INFO:\n${userPlaceTimeString}`;
            }
            let messages = [
                { role: "system", content: prompt },
                { role: "assistant", content: "I'll remember your preferences for luxury accommodations" },
                { role: "assistant", content: "[RST]User prefers luxury accommodations[RND]" },
                { role: "assistant", content: "NONE" },
                { role: "assistant", content: "I'll generate an image of a panda for you!" },
                { role: "assistant", content: "[IST]A black and white panda eating bamboo in a forest.[IND]" },
                { role: "assistant", content: "NONE" },
                { role: "assistant", content: "I'm glad you like it!" },
                { role: "assistant", content: "NONE" },
                { role: "assistant", content: "I'll remember your preference for direct flights." },
                { role: "assistant", content: "[RST]User prefers direct flights[RND]" },
                { role: "assistant", content: "NONE" },
                { role: "assistant", content: "I'll now research more on this year's tom ford collection." },
                { role: "assistant", content: "[QST]Tom Ford 2023 collection[QND]" },
                { role: "assistant", content: "NONE" },
                { role: "assistant", content: "Let me know if you need any more help!" },
                { role: "assistant", content: "NONE" },
                { role: "assistant", content: "Sure thing! Would you like me to explore upcoming 2024 SUV models from a specific brand or several brands?" },
                { role: "assistant", content: "[QST]2024 SUV Models[QND]" },

                latest,
            ];
            let response = await this.completion(messages, null, "toolbot", gpt3, 50, false, 0, false);
            // response = response.trim();
            if (!response.includes("[") && (response.includes("NONE") || response.includes("none") || response.includes("error"))) {
                console.groupCollapsed(`%c🤖 No response`, bot);
                console.log(response);
                console.groupEnd();
                return;
            }
            let responseObject = {
                role: "assistant",
                content: response,
            };
            if (!response.includes("]")) {
                return;
            }
            this.$nextTick(async () => {
                // this.processCodeOrImage(responseObject);
                this.addMessageStore(responseObject);
                responseObject = this.detectAction(responseObject);
                responseObject = this.fixActions(responseObject);
                console.groupCollapsed(`%c🤖 Response`, bot, responseObject.content.slice(0, 20) + "...");
                console.log("%c" + responseObject.content, txt);
                console.groupCollapsed("Details");
                consoleTableReduced(responseObject, ["action"]);
                if (responseObject.action) console.table(responseObject.action);
                console.groupEnd();
                console.groupEnd();
                await this.chatResponseReceived(responseObject, index, "toolbot");
            });
            return;
        },
        async sideBot() {
            if (!this.$store.state.chat.autoGPT) return;
            //             let prompt = `You are a chatbot's assistant and you determine if you use Tools to assist the chat assistant if action is required.
            //
            // [RULES]
            // - Do not chat.
            // - Respond with commands in the proper format.
            // - keep responses very concise and effective.
            // - If no action is required respond with "NONE"
            //
            // ${this.formattedActionString}.
            // `;
            let prompt = `You are an encouraging assistant and task manager who continually delivers feedback.
[TASKS]
- encourage the assistant to execute tasks
- keep a running task list in memory
- answer any questions for me
- Remind the assistant to complete unfinished tasks
- Provide feedback to guide the assistant to provide better output
- Encourage more detailed output
- Encourage use of tools and commands
- Encourage the assistant to use timelines, task lists, markdown, etc.

[RULES]
- Do not chat.
- keep responses very concise and effective.
- If no action is required respond with "NONE"
- if a task is not complete, remind the assistant of the task that needs to be completed.
- keep doing so until all tasks are successfully completed.


`;
            let messageResponse = "";

            // - if a task is not complete, pick up where the assistant left off.
            const rawMessages = [...this.messages];
            const priorMessages = [...this.messagesForChat(rawMessages.slice(-10))];
            let newMessages = [...priorMessages];
            // newMessages[newMessages.length - 1].content = `Provide feedback and a task list to complete the tasks:\n\n${newMessages[newMessages.length - 1].content}`;
            // newMessages[newMessages.length - 1].role = "assistant";
            newMessages[0] = sysMessage(prompt);
            // messages.push({ role: "user", content: prompt });
            // let cleanMessages = this.cleanMessageFields(newMessages);
            // make a string from the messages. Include the roles.
            // remove the first message from newMessages
            newMessages = newMessages.slice(-5);
            let messageString = newMessages.map(m => `${m.role}: ${m.content}`);
            messageString = messageString.join("\n\n");
            console.error(messageString);
            let msgs = [
                sysMessage(prompt + "\n\n" + this.formattedActionString),
                // { role: "assistant", content: "I'm here to provide feedback, answer questions for you and keep track of tasks." },
                // { role: "user", content: `\t\t${messageString}` },
            ];
            console.error(msgs);
            try {
                await this.streamCompletion(this.$store,
                    `Look at the last messages, Provide feedback, create responses for the chat, and keep track of all tasks. Respond as the user. \t\t${messageString}`,
                    msgs,
                    null,
                    () => {
                        this.$store.dispatch("chat/addMessageStore", sysMessage(" ", "sidebot"));
                    },
                    token => {
                        // this.messages[this.lastAssistantMessageIndex].content += token;
                        messageResponse += token;
                        this.messages[this.messages.length - 1].content += token;
                        this.processCodeOrImage(this.messages[this.messages.length - 1]);
                        // this.processCodeOrImage(this.messages[this.lastAssistantMessageIndex]);
                        this.debounceSave();
                        this.scrollChatWindowToBottom();
                    },
                    async () => {
                        this.messages[this.messages.length - 1].complete = true;
                        if (this.messages[this.messages.length - 1].content.includes("NONE")) {
                            // remove the last message
                            this.messages[this.messages.length - 1].content = "";
                            this.messages.pop();
                            this.setMessages(this.messages);
                            await this.chatResponseReceived(sysMessage(messageResponse, "sidebot"), this.messages.length - 1);
                        } else {
                            // await this.chatResponseReceived(null, this.messages.length - 1);
                            await this.chatResponseReceived(sysMessage(messageResponse, "sidebot"), this.messages.length - 1);
                            await this.sendMessage();
                        }
                    },
                    gpt3,
                    false,
                    "SideBot",
                    0,
                );
                return;
                // let response = await this.completion(cleanMessages, prompt, "sideBot", "gpt-3.5-turbo", 500, false, 0, true);
                // if (response && response && !response.includes("NONE")) {
                //     this.addMessage(response, "system", "sidebot");
                //     await this.chatResponseReceived({ role: "system", content: response, kind: "sidebot" }, this.messages.length - 1);
                //     this.addMessage("proceed", "user");
                //     this.sendMessage();
                //     setTimeout(() => {
                //         let responseMessageObject = { role: "system", content: response, complete: true };
                //         this.processCodeOrImage(responseMessageObject, true);
                //         console.error("Sidebot response: ", response);
                //     }, 100);
                //
                //     await this.checkForCommand(response);

                // return;
                // } else {
                //     console.error("No sidebot response ", response);
                //     return;
                // }
            } catch (e) {
                console.error("Sidebot error ", e);
                return;
            }
        },
    },
};
