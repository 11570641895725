<template>
    <div>
        <div v-if="inline">
            <div :class="`border-l-4 border-${color}-400 bg-${color}-50 p-3`">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <ExclamationTriangleIcon :class="`h-5 w-5 text-${color}-400`" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <p class="text-sm text-${color}-700">
                            {{ title }}
                            {{ " " }}
                            <a href="#" :class="`font-medium text-${color}-700 underline hover:text-${color}-600`">{{ description }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else :class="`rounded-md bg-${color}-50 p-3 text-inherit`">
            <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationTriangleIcon :class="`h-5 w-5 text-${color}-400`" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <h3 :class="`text-sm font-medium text-${color}-800`">{{ title }}</h3>
                    <div :class="`mt-2 text-sm text-${color}-700`">
                        <p>{{ description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
export default {
    name: "SimpleAlert",
    components: { ExclamationTriangleIcon },
    props: {
        title: {
            type: String,
            default: "Attention needed",
        },
        description: {
            type: String,
            default: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum.",
        },
        color: {
            type: String,
            default: "yellow",
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
