function updateDocumentProp(store, key, value) {
    try {
        // The condition now checks if key exists and if value is not undefined
        if (key !== undefined && value !== undefined) {
            store.dispatch("presentation/updateDocumentProp", { keyPath: key, value: value });
        }
    } catch (error) {
        console.groupCollapsed("%cError updating document prop", fail);
        console.error("Update document prop failed");
        console.log(error);
        console.log(error.message);
        console.log(key);
        console.log(value);
        console.groupEnd();
    }
}
export default updateDocumentProp;
