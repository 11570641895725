<template>
    <div class="overflow-hidden bg-white shadow sm:rounded-md mx-auto" :class="{ 'w-80': !mobile }">
        <div class="border-b border-gray-200 px-4 py-3 f aic jcb">
            <div>
                <h3 class="text-2xl font-semibold leading-6 text-gray-900 mt-0">Campaigns</h3>
                <p class="mt-2 max-w-4xl text-sm text-gray-500 mb-0">This is still in beta, you may need to refresh to see your campaign.</p>
            </div>
            <RouterLink :to="`/admin/${$route.params.id}/emails`">
                <SimpleButton class="" :href="`/admin/${$route.params.id}/emails`">New campaign</SimpleButton>
            </RouterLink>
        </div>
        <div class="f aic jcc vh-50" v-if="!campaigns || campaigns.length === 0">
            <EmptyState @clicked="handleEmptyStateClick" title="No campaigns yet" description="Create a new campaign to get started." />
        </div>
        <ul role="list" class="divide-y divide-gray-100 bg-snow mx-auto">
            <template v-for="campaign in sortedCampaigns">
<!--                <template v-if="campaign.updated">{{ campaign.updated }}</template>-->
                <CampaignListItem class="py-2 px-3 group cursor-pointer" :campaign="campaign" @delete-item="deleteItem" @clicked="selectCampaign(campaign)" />
            </template>
        </ul>
    </div>
</template>
<script>
import CampaignListItem from "@/components/Admin/CampaignListItem.vue";
import SimpleButton from "@/views/SimpleButton.vue";
import EmptyState from "@/components/Admin/EmptyState.vue";

export default {
    components: { EmptyState, SimpleButton, CampaignListItem },
    props: {
        campaigns: {
            type: Array,
            required: true,
        },
    },
    emits: ["delete-item"],
    data() {
        return {};
    },
    computed: {
        sortedCampaigns() {
            let campaigns = this.campaigns;
            if (campaigns.length > 0) {
                return campaigns.sort((a, b) => {
                    // Convert each timestamp to a comparable number (nanoseconds)
                    const aTime = a.updated.seconds * 1e9 + a.updated.nanoseconds;
                    const bTime = b.updated.seconds * 1e9 + b.updated.nanoseconds;

                    return bTime - aTime; // For descending order
                });
            } else {
                return campaigns;
            }
        },
    },
    methods: {
        handleEmptyStateClick() {
            this.$router.push(`/admin/${this.$route.params.id}/emails`);
        },
        deleteItem(campaign) {
            this.$emit("delete-item", campaign);
        },
        selectCampaign(item) {
            let campaign = item;
            this.$router.push({ name: "campaign", params: { cid: campaign.id } });
            this.updateStreamProp("campaignData", item);
            this.updateStreamProp("showCampaign", true);
        },
    },
};
</script>
