<template>
    <div v-if="listItem" :class="classesToReturn" class="list_item">
        <div class="x p-3" @click.prevent="selectItem(listItem)">
            <p v-if="headline" class="fwb o-8 mb-0 line-clamp-1">{{ headline }}</p>
            <p v-if="listItem.summary" class="f-13 fwsb mb-0 opacity-60 line-clamp-2">{{ listItem.summary.slice(0, 100) }}</p>
            <p v-if="listItem && listItem.title" class="fwb f-15 mb-0"></p>
            <p v-if="listItem" class="f-13 my-1"></p>
            <p v-if="biline" class="small my-1 mb-0"></p>
            <p v-if="body" class="f-13 mb-0"></p>

            <div class="f jcb x">
                <span v-if="updatedTime" class="fwb f-13 mr-2 opacity-30 dark:opacity-100 dark:mix-blend-color-dodge">
                    {{ $filters.timeAgo(getUpdatedDate) }}
                </span>
                <p class="f-15 mb-0 mr-0 pr-0">
                    <i v-if="listItem.documentType && listItem.documentType === 'presentation'" class="fad fa-gallery-thumbnails !text-orange-600"></i>
                    <i v-else class="fad fa-file-lines !text-aqua-500"></i>
                    <!--                    v-if="listItem.documentType && listItem.documentType === 'textEdit'"-->
                </p>
            </div>
        </div>
        <BaseButton style-type="link" class="o-2 button-gray" icon="fas absolute top right fa-trash o-5 hover:text-red-800 hover:opacity-100 p-2" icon-only @click.prevent="deleteDoc()" size="xs" rounded label="" />
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    components: { BaseButton },
    props: ["headline", "biline", "body", "item", "listItem", "selected"],
    data() {
        return {
            closed: false,
        };
    },
    computed: {
        classesToReturn() {
            if (this.listItem && this.listItem.id === this.$route.params.id) {
                return "hover:bg-base-100 bg-gray-200 hover:text-base-300 hover:brightness-500";
            } else {
                return "dark:hover:bg-base-700 dark:bg-base-800 dark:text-base-100 dark:hover:brightness-500";
            }
            // bg-base-800 text-base-300
        },
        createdTime() {
            if (this.listItem && this.listItem.created) {
                const obj = this.listItem.created;
                const seconds = obj["seconds"];
                const nanoseconds = obj["nanoseconds"];
                const date = new Date(seconds * 1000 + nanoseconds / 1000000);

                const options = {
                    dateStyle: "short", // Use the relative time format
                    timeStyle: "short",
                };
                const dateString = date.toLocaleString("en-US", options);
                return dateString;
            }
            return false;
        },
        updatedTime() {
            if (this.listItem && this.listItem.updated) {
                const obj = this.listItem.updated;
                const seconds = obj["seconds"];
                const nanoseconds = obj["nanoseconds"];
                const date = new Date(seconds * 1000 + nanoseconds / 1000000);

                const options = {
                    dateStyle: "short", // Use the relative time format
                    timeStyle: "short",
                };
                const dateString = date.toLocaleString("en-US", options);
                return dateString;
            }
            return false;
        },
        currentItem(item) {
            if (item.id === this.$route.params.id) {
                return true;
            } else {
                return false;
            }
        },
        getDate() {
            if (this.listItem && this.listItem.created) {
                const obj = this.listItem.created;
                const seconds = obj["seconds"];
                const nanoseconds = obj["nanoseconds"];
                const dateRaw = new Date(seconds * 1000 + nanoseconds / 1000000);
                return dateRaw;
            }
            return false;
        },
        getUpdatedDate() {
            if (this.listItem && this.listItem.updated) {
                const obj = this.listItem.updated;
                const seconds = obj["seconds"];
                const nanoseconds = obj["nanoseconds"];
                const dateRaw = new Date(seconds * 1000 + nanoseconds / 1000000);
                return dateRaw;
            }
            return false;
        },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        selectItem(item) {
            // this.$store.commit('setDocument', item);
            // this.$emit('selectItem', item);
            if (item.documentType && item.documentType === "presentation") {
                // this.$store.state.presentation = {};
                this.$router.push({ path: `/presentations/${item.id}`, params: { id: item.id } });
            } else if (item.documentType && item.documentType === "storyboard") {
                this.$router.push({ path: `/storyboard/${item.id}`, params: { id: item.id } });
            } else {
                this.$router.push({ path: `/documents/${item.id}`, params: { id: item.id } });
            }
            if (this.mobile && this.$store.state.showDocumentDrawer) {
                this.$store.commit("setShowDocumentDrawer", false);
            }

            // this.$router.push({path: `${item.id}`, params: {id: item.id}});
        },
        async deleteDoc(docType, doc) {
            await this.removeFromFirebase("documents", this.listItem.id, false);
            this.$forceUpdate();
            // this.$emit('deleteDoc', 'documents', doc);
        },
    },
};
</script>

<style lang="scss">
.list_item {
  /* flexbox */
  @apply flex flex-col;

  /* positioning */
  @apply relative;

  /* border */
  @apply border-0;
  @apply border-b border-b-gray-200 dark:border-b-base-900 dark:border-t dark:border-t-base-600 border-solid;

  /* background */
  @apply bg-gray-100
    hover:bg-gray-200/75
    dark:hover:bg-base-800
  dark:bg-base-700;
}
</style>