let create_presentation = {
    name: "create_presentation",
    description: "Create an outline of a presentation.",
    // description: "Creates a new presentation outline.",
    parameters: {
        type: "object",
        properties: {
            title: {
                type: "string",
                description: "The title of the new presentation.",
            },
            summary: {
                type: "string",
                description: "A summary of the new presentation.",
            },
            theme_colors_hsl: {
                type: "object",
                description: "Two HSL colors based on the presentation's content which will be used to generate a color scheme for the presentation.",
                properties: {
                    primary: {
                        type: "string",
                        format: "hsl",
                        description: "The primary color for the presentation. Example: hsl(0, 0%, 0%)",
                    },
                    secondary: {
                        type: "string",
                        format: "hsl",
                        description: "The secondary color for the presentation.  Example: hsl(0, 0%, 0%)",
                    },
                },
            },
            image_style: {
                type: "string",
                description: "Choose an image style from the list of presets.",
                enum: ["enhance", "anime", "photographic", "digital-art", "comic-book", "fantasy-art", "line-art", "analog-film", "neon-punk", "isometric", "low-poly", "origami", "modeling-compound", "cinematic", "3d-model", "pixel-art", "tile-texture"],
            },
            sections: {
                type: "array",
                // description: "An outline of the slides to add to the presentation. Minimum 20.",
                // minItems: 15,
                // maxItems: 40,
                description: "An array of slides to add to the presentation. Minimum 20.",
                items: {
                    type: "object",
                    properties: {
                        title: {
                            type: "string",
                            description: "The title of the slide. 2-5 words.",
                        },
                        body: {
                            type: "string",
                            // description: "A very brief outline of what to cover here. 5 words. Example: 'this section should cover XYZ'",
                            // description: "A very brief outline what this section should cover. Markdown is supported. Example: 'Cover XYZ here'",
                            description: "The body of the slide. 5-10 words.",
                        },
                        relevant_image_indexes: {
                            type: "array",
                            description: "Indexes of the most relevant images from the provided Image List.",
                            items: { type: "integer", description: "The index of the image in the image list." },
                            minItems: 3,
                        },
                        style: {
                            type: "string",
                            description: "The style of the slide. Required.",
                            enum: ["imageright", "imageleft"],
                        },
                        mode: {
                            type: "string",
                            description: "visual background of the slide.",
                            enum: ["dark", "light"],
                        },
                        image_description: {
                            type: "string",
                            description: "A description of the image to accompany the slide",
                            example: "A picture of a cat, jumping on a roof.",
                        },
                        imageIndex: {
                            type: "integer",
                            description: "Select the index of the image most relevant to this slide's content.",
                            example: 2,
                        },
                        // image_description: {
                        //     type: "string",
                        //     description: "A written description of the image or photo to accompany the slide. (NOT A URL)",
                        //     example: "A picture of a cat, jumping on a roof.",
                        // },
                    },
                },
                required: ["title", "body", "imageIndex", "image_description", "style", "mode"],
            },
            // theme: {
            //     type: "string",
            //     description: "The theme for the new presentation.",
            // },
        },
        required: [
            "title",
            "sections",
            "summary",
            "style",
            "image_style",
            "image_description",
            // "google_image_query",
            "theme_colors_hsl",
        ],
    },
};
export default create_presentation;
