import { mapGetters } from "vuex";
export default {
    created: function () {},
    mounted() {
        if (this.$route.path.includes("presentations")) {
            this.theme = {
                bgDarks: ["bg-dark-1", "bg-dark-2", "bg-dark-3", "bg-dark-4", "bg-dark-5", "bg-dark-6", "bg-dark-7", "bg-dark-8", "bg-dark-9"],
                bgLights: ["bg-light", "bg-light-2", "bg-light-3", "bg-light-4", "bg-light-5", "bg-light-6", "bg-light-7", "bg-light-8", "bg-light-9"],
                textLight: ["text-light", "text-light-2", "text-light-3", "text-light-4", "text-light-5", "text-light-6", "text-light-7", "text-light-8", "text-light-9"],
                textDark: ["text-dark", "text-dark-2", "text-dark-3", "text-dark-4", "text-dark-5", "text-dark-6", "text-dark-7", "text-dark-8", "text-dark-9"],
            };
        }
    },
    data() {
        return {
            theme: null,
        };
    },
    computed: {
        ...mapGetters("presentation", ["document"]),
        // backgroundColor() {
        //     if (this.document?.theme?.colors) {
        //         let bg = this.document?.theme?.colors?.secondary;
        //         if (bg) {
        //             let c = this.document?.theme?.colors?.secondary || "hsl(100%,0%,90%)";
        //             if (c.startsWith("#")) {
        //                 c = this.hexToHSL(c);
        //             }
        //             let hslColor = c;
        //             let [hue = "250", saturation = "10", lightness = "90"] = hslColor?.match(/\d+/g);
        //             // a random saturation betwen 10% and 30%
        //             lightness = "30";
        //             saturation = "10";
        //             if (!this.darkMode) {
        //                 lightness = "90";
        //                 saturation = "50";
        //             }
        //             // saturation = Math.floor(Math.random() * 50) + 10;
        //             let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        //             // random lightness between 10 and 30
        //             this.$forceUpdate();
        //             return newColor;
        //         } else {
        //             return "hsl(0%,0%,90%)";
        //         }
        //     } else {
        //         // this.document.theme.colors = null;
        //         return false;
        //     }
        // },
        backgroundColor() {
            // Ensure 'document' and 'theme' are not null or undefined
            if (!this.document || !this.document.theme) {
                return false; // Safely return if 'document' or 'theme' is not present
            }

            const themeColors = this.document.theme.colors;
            // Check if 'colors' exists and has 'secondary' property
            if (themeColors && "secondary" in themeColors) {
                let bg = themeColors.secondary;
                // Ensure 'bg' is not null or undefined
                if (bg) {
                    let color = bg || "hsl(100%,0%,90%)"; // Default color fallback
                    if (color.startsWith("#")) {
                        color = this.hexToHSL(color); // Convert hex to HSL if needed
                    }
                    let hslColor = color;
                    // Extract HSL values safely
                    let [hue = "250", saturation = "10", lightness = "90"] = hslColor.match(/\d+/g) || [];
                    lightness = this.darkMode ? "30" : "90";
                    saturation = this.darkMode ? "10" : "50";

                    let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                    this.$forceUpdate();
                    return newColor;
                }
            }
            return "hsl(0%,0%,90%)"; // Default color if no 'secondary' color found
        },

        colorLightest() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);

                if (!this.darkMode) {
                    lightness = "50";
                    saturation = "20";
                } else {
                    lightness = "80";
                    saturation = "15";
                }
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
        colorLighter() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                if (!this.darkMode) {
                    saturation = "60";
                    lightness = "96";
                } else {
                    lightness = "35";
                    saturation = "10";
                }
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
        colorLight() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                lightness = "35";
                saturation = "10";
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
        colorDarker() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                if (!this.darkMode) {
                    saturation = "50";
                    lightness = "80";
                } else {
                    lightness = "25";
                    saturation = "10";
                }
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
        backgroundColorDarkest() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                lightness = "5";
                saturation = "10";
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
    },
    methods: {
        slideColors() {
            if (!this.document?.theme?.colors) return;
            const theme = this.document.theme.colors;
            // const theme = this.document.theme;
            //pick random textLight
            let slideColors = ["light", "dark"];
            let slideColor = slideColors[Math.floor(Math.random() * slideColors.length)];
            let textLightIndex = Math.floor(Math.random() * theme.lights.length);
            let textDarkIndex = Math.floor(Math.random() * theme.darks.length);
            let bgDarkIndex = Math.floor(Math.random() * theme.darks.length);
            let bgLightIndex = Math.floor(Math.random() * theme.darks.length);
            let allBg = theme.darks.concat(theme.lights);
            let allText = theme.lights.concat(theme.darks);
            let randomBg = allBg[Math.floor(Math.random() * allBg.length)];
            let randomText = allText[Math.floor(Math.random() * allBg.length)];
            let darkSlide = {
                text: theme.lights[textLightIndex],
                bg: theme.darks[bgDarkIndex],
                image: randomBg,
            };
            let lightSlide = {
                text: theme.darks[textDarkIndex],
                bg: theme.lights[bgLightIndex],
                image: randomBg,
            };
            //apply classes to refs
            if (slideColor === "dark") {
                if (this.$refs[`slide-${this.id}${this.index}`]) {
                    this.$refs[`slide-${this.id}${this.index}`].style.background = darkSlide.bg;
                }
                if (this.$refs[`image-${this.id}${this.index}`]) {
                    // this.$refs[`image-${this.id}${this.index}`].classList.add(darkSlide.bg);
                }
                if (this.$refs[`text-${this.id}${this.index}`]) {
                    this.$refs[`text-${this.id}${this.index}`].style.color = darkSlide.text;
                }
            } else if (slideColor === "light") {
                if (this.$refs[`slide-${this.id}${this.index}`]) {
                    this.$refs[`slide-${this.id}${this.index}`].style.background = lightSlide.bg;
                }
                if (this.$refs[`image-${this.id}${this.index}`]) {
                    // this.$refs[`image-${this.id}${this.index}`].classList.add(lightSlide.bg);
                }
                if (this.$refs[`text-${this.id}${this.index}`]) {
                    this.$refs[`text-${this.id}${this.index}`].style.color = lightSlide.text;
                }
            }
        },
        animatedList(i) {
            return `animation-delay:${100 + 50 * i}ms;transition-delay:${200 + 50 * i}ms;animation-duration:1000ms;transition-duration:1000ms;user-select:none!important;`;
        },
        colorValueHelper(color, value) {
            try {
                let variations = [];
                if (color.startsWith("#")) {
                    color = this.hexToHSL(color);
                }
                let hsl = color.match(/\d+/g);
                let h = hsl?.[0] || 0,
                    s = hsl?.[1] || 0;

                function easeInOutQuad(t) {
                    return t < 1 ? 3 * t * t : -2 + (8 - 2 * t) * t;
                }

                function roundToClosestWholeNumber(num) {
                    return Math.round(num);
                }

                if (value === "dark") {
                    // Dark Colors
                    for (let i = 0; i <= 100; i += 3) {
                        let l = roundToClosestWholeNumber(easeInOutQuad(i / 100) * 100);
                        variations.push(`hsl(${h}, ${s * 2}%, ${l}%)`);
                    }
                    return variations.slice(4, 9);
                } else {
                    // Light Colors
                    for (let i = 0; i <= 100; i += 2) {
                        let l = roundToClosestWholeNumber(easeInOutQuad(i / 100) * 80);
                        variations.push(`hsl(${h}, ${s}%, ${l}%)`);
                    }
                    return variations.slice(27, 32);
                }
            } catch (e) {
                console.error(e);
            }
        },
        hslToHex(hsl) {
            // Separate the HSL values
            let sep = hsl.indexOf(",") > -1 ? "," : " ";
            hsl = hsl.substr(4).split(")")[0].split(sep);
            let h = hsl[0],
                s = hsl[1].substr(0, hsl[1].length - 1) / 100,
                l = hsl[2].substr(0, hsl[2].length - 1) / 100;

            // Convert HSL to RGB first
            let c = (1 - Math.abs(2 * l - 1)) * s,
                x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
                m = l - c / 2,
                r = 0,
                g = 0,
                b = 0;
            if (0 <= h && h < 60) {
                r = c;
                g = x;
                b = 0;
            } else if (60 <= h && h < 120) {
                r = x;
                g = c;
                b = 0;
            } else if (120 <= h && h < 180) {
                r = 0;
                g = c;
                b = x;
            } else if (180 <= h && h < 240) {
                r = 0;
                g = x;
                b = c;
            } else if (240 <= h && h < 300) {
                r = x;
                g = 0;
                b = c;
            } else if (300 <= h && h < 360) {
                r = c;
                g = 0;
                b = x;
            }

            // Convert RGB to HEX
            r = Math.round((r + m) * 255).toString(16);
            g = Math.round((g + m) * 255).toString(16);
            b = Math.round((b + m) * 255).toString(16);

            // Add leading zeros if needed
            if (r.length == 1) r = "0" + r;
            if (g.length == 1) g = "0" + g;
            if (b.length == 1) b = "0" + b;

            return "#" + r + g + b;
        },
        hexToHSL(H) {
            // Convert hex to RGB first
            let r = 0,
                g = 0,
                b = 0;
            if (H.length == 4) {
                r = "0x" + H[1] + H[1];
                g = "0x" + H[2] + H[2];
                b = "0x" + H[3] + H[3];
            } else if (H.length == 7) {
                r = "0x" + H[1] + H[2];
                g = "0x" + H[3] + H[4];
                b = "0x" + H[5] + H[6];
            }
            // Then to HSL
            r /= 255;
            g /= 255;
            b /= 255;
            let cmin = Math.min(r, g, b),
                cmax = Math.max(r, g, b),
                delta = cmax - cmin,
                h = 0,
                s = 0,
                l = 0;

            if (delta == 0) h = 0;
            else if (cmax == r) h = ((g - b) / delta) % 6;
            else if (cmax == g) h = (b - r) / delta + 2;
            else h = (r - g) / delta + 4;

            h = Math.round(h * 60);

            if (h < 0) h += 360;

            l = (cmax + cmin) / 2;
            s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
            s = +(s * 100).toFixed(0); // changed from toFixed(1)
            l = +(l * 100).toFixed(0); // changed from toFixed(1)

            return "hsl(" + h + "," + s + "%," + l + "%)";
        },
        tetradicColor(color) {
            let colors = [];
            let [h, s, l] = color.match(/\d+/g).map(Number);
            for (let i = 0; i <= 3; i++) {
                h = (h + 90 * i) % 360;
                colors.push(`hsl(${newHue}, ${saturation}%, ${lightness}%)`);
            } // tetradic
        },
        triadicColor(color) {
            let colors = [];
            let [h, s, l] = color.match(/\d+/g).map(Number);
            for (let i = 0; i <= 2; i++) {
                h = (h + 120 * i) % 360;
                colors.push(`hsl(${newHue}, ${saturation}%, ${lightness}%)`);
            }
            return `hsl(${h}, ${s}%, ${l}%)`;
        },
        setColors(colors) {
            try {
                let styleEl = document.getElementById("color-stylesheet");
                if (!styleEl) {
                    styleEl = document.createElement("style");
                    styleEl.setAttribute("type", "text/css");
                    styleEl.setAttribute("id", "color-stylesheet");
                    document.head.appendChild(styleEl);
                }
                styleEl.innerHTML = this.styleSheeter(colors);
            } catch (e) {}
        },
        styleSheeter(colors) {
            let styles = "";
            Object.keys(colors).forEach((colorGroupName, index, key) => {
                if (colorGroupName !== "primary" && colorGroupName !== "secondary") {
                    colors[colorGroupName].forEach((color, index) => {
                        let className = colorGroupName;
                        if (colorGroupName === "primaries") {
                            className = "primary";
                        } else if (colorGroupName === "secondaries") {
                            className = "secondary";
                        } else if (colorGroupName === "lights") {
                            className = "light";
                        } else if (colorGroupName === "darks") {
                            className = "dark";
                        }
                        if (index === 0) {
                            styles += `.bg-${className} { background-color: ${color}; }\n`;
                            styles += `.text-${className} { color: ${color}; }\n`;
                            // styles += `.border-${className} { border: 1px solid ${color}; }\n`;
                        } else {
                            styles += `.bg-${className}-${index * 100} { background-color: ${color}; }\n`;
                            styles += `.text-${className}-${index * 100} { color: ${color}; }\n`;
                            styles += `.border-${className}-${index * 100} { border: 1px solid ${color}; }\n`;
                        }
                    });
                } else {
                    styles += `.bg-${colorGroupName} { background-color: ${key}; }\n`;
                    styles += `.border-${colorGroupName} { border: 1px solid ${key}; }\n`;
                    styles += `.text-${colorGroupName} { color: ${key}; }\n`;
                }
            });
            return styles;
        },
        complimentaryColor(color) {
            let fixedColor = color;
            if (color.startsWith("#")) {
                fixedColor = this.hexToHSL(color);
            }
            let [h, s, l] = fixedColor.match(/\d+/g).map(Number);
            h = (h + 180) % 360; // complimentary

            return `hsl(${h}, ${s}%, ${l}%)`;
        },
        splitComplementaryColors(color, angle = 30) {
            let [h, s, l] = color.match(/\d+/g).map(Number);
            let compHue = (h + 180) % 360;
            let colors = [`hsl(${compHue}, ${s}%, ${l}%)`];
            for (let i = -1; i <= 1; i += 2) {
                let newHue = (compHue + angle * i) % 360;
                colors.push(`hsl(${newHue}, ${s}%, ${l}%)`);
            }
            return colors;
        },
    },
};
