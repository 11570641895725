<template>
    <div class="isolate flex -space-x-2 overflow-hidden">
        <img v-for="img in images" :class="['relative z-30 inline-block rounded-full ring-2 ring-white', sizeClass]" :src="img.url" alt="" />
    </div>
</template>

<script>
export default {
    name: "Avatars",
    props: {
        images: { type: Array, default: () => [] },
        size: { type: String, default: "md" },
    },
    computed: {
        sizeClass() {
            switch (this.size) {
                case "xs":
                    return "h-6 w-6";
                case "sm":
                    return "h-8 w-8";
                case "sm":
                    return "h-10 w-10";
                case "lg":
                    return "h-12 w-12";
                case "xl":
                    return "h-16 w-16";
                default:
                    return "h-10 w-10";
            }
        },
    },
};
</script>
