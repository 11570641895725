<template>
    <div v-if="campaign && styleGuide.base">
        <CampaignObjectPreviewHorizontal v-if="isCampaign" :key="$route?.params?.cid" :campaign="campaign" />
        <!--        <CampaignObjectPreview v-show="isCampaign" :style-guide="styleGuide" :campaign="campaign" @trigger-generation="triggerGeneration" />-->
        <EmailCampaignPreview v-show="isEmail" :id="id" :campaign="campaign" :sections="sections"></EmailCampaignPreview>
        <LandingPagePreview v-show="isLandingPage" :id="id" :campaign="campaign" :style-guide="styleGuide"></LandingPagePreview>
        <!--       :id="campaignData.id"-->
        <!--                <template v-if="sections && isLandingPage">-->
        <!--                    <template v-for="(section, i) in sections" :key="`section${i}`">-->
        <!--                        <LandingPageSection :result="section" :index="i" :sections="sections" />-->
        <!--                        &lt;!&ndash;                        <TestSlotComponent class="" :result="section" :index="i" :images="images" />&ndash;&gt;-->
        <!--                    </template>-->
        <!--                </template>-->
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapGetters } from "vuex";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import LandingPageSection from "@/components/generative_templates/LandingPageSection.vue";
import EmailCampaignPreview from "@/components/Admin/EmailCampaignPreview.vue";
import CampaignObjectPreview from "@/components/Admin/CampaignObjectPreview.vue";
import LandingPagePreview from "@/components/Admin/LandingPagePreview.vue";
import CampaignObjectPreviewHorizontal from "@/components/Admin/CampaignObjectPreviewHorizontalTest.vue";

export default {
    name: "CampaignPreview",
    components: {
        CampaignObjectPreviewHorizontal,
        LandingPagePreview,
        CampaignObjectPreview,
        EmailCampaignPreview,
        LandingPageSection,
        BaseButton,
    },
    mixins: [styleGuideMixin],
    props: {
        selectedCampaign: {
            type: Object,
            required: false,
        },
    },
    computed: {
        ...mapGetters("stream", ["result", "campaignData", "showCampaign"]),
        objectType() {
            if (this.campaign?.object_type) return this.campaign.object_type;
            return null;
        },
        id() {
            return this.campaignData?.id || this.$route?.params?.cid;
        },
        isEmail() {
            return this.objectType === "email";
        },
        isCampaign() {
            return this.objectType === "campaign";
        },
        isLandingPage() {
            return this.objectType === "landing_page";
        },
        sections() {
            if (this.campaign?.sections) return this.campaign.sections;
            return null;
        },
        campaign() {
            if (this.campaignData?.result) return this.campaignData.result;
            return this.result;
        },
    },
    async mounted() {
        if (this.$route?.params?.cid && !this.campaignData?.result) {
            console.log("campaign loaded", this.$route?.params?.cid);
            let campaignData = await this.fetchFromFirebase("campaigns", this.$route.params.cid);
            // let campaignData = await findMatchingDocById("campaigns", this.$route.params.cid);
            this.$nextTick(() => {
                this.updateStreamProp("campaignData", campaignData);
                this.updateStreamProp("showCampaign", true);
            });
        }
    },
    methods: {
        triggerGeneration(object) {
            let path = `/admin/${this.$route.params.id}/emails`;
            console.log("clear preview and generate", path, object);
            this.clearPreview();
            this.$router.push({ path });
        },
        clearPreview() {
            this.updateStreamProp("showCampaign", false);
            this.$emit("clearPreview");
        },
    },
};
</script>
