<template>
    <div v-if="images">
        <Swiper ref="swiper" :centered-slides="false" :height="400" :width="400" @swiper="onSwiper" :loop="true" :pagination="{ clickable: true }" :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }" :slidesPerView="'auto'" :spaceBetween="0" class="x py-0" style="margin-left: -1rem; margin-right: -1rem">
            <SwiperSlide v-for="(image, index) in images" class="x d-block br-10 overflow-hidden bg-snow aspect-square shadow dark:bg-base-700">
                <div class="w-100">
                    <StyleGuideImage class="block x y aspect-square" :image="image" :size="{ width: 400, height: 400 }" />
                    <!--                    <img class="x y o-cover aspect-square" :src="image.url" />-->
                </div>
            </SwiperSlide>
        </Swiper>

        <div class="border-light swiper-button-next p-absolute top bottom c-50 z-5 f align-fixed-center aic jcc -right-5 bg-snow shadow" @click="swiperInstance.slideNext()">
            <i class="fas fa-arrow-right"></i>
        </div>
        <div class="border-light swiper-button-prev p-absolute top bottom c-50 z-5 f align-fixed-center aic jcc -left-5 bg-snow shadow" @click="swiperInstance.slidePrev()">
            <i class="fas fa-arrow-left"></i>
        </div>
    </div>
    <!--                <div v-for="image in product.images"><img class="x y" :src="image.url" /></div>-->
</template>
<script>
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss/pagination";
import "swiper/scss";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import StyleGuideImage from "@/components/styleGuide/Images/StyleGuideImage.vue";
export default {
    name: "ImageSwiper",
    components: { StyleGuideImage, Swiper, SwiperSlide },
    props: {
        images: { type: Array, default: () => [] },
    },
    data: function () {
        return { swiper: null, swiperInstance: null };
    },
    mounted() {
        this.$nextTick(() => {
            this.swiper = this.$refs.$swiper;
        });
    },
    methods: {
        onSwiper(swiperInstance) {
            this.swiperInstance = swiperInstance;
            this.swiper = this.$refs.swiper.$swiper;
        },
    },
    computed: {},
};
</script>
