<template>
    <div>
        <template v-for="item in sources">
            <div class="mw-500 mx-auto">
                <UICollapsableSection class="mw-500 block mx-auto">
                    <template #title>
                        <p class="mb-1" style="font-weight: bold !important" v-if="item.title">{{ item.title }}</p>
                        <p class="!f-11 o-5 mb-0" style="font-weight: 500 !important" v-if="item.url">
                            {{ item.url }}
                        </p>
                    </template>
                    <div class="p-3 bg-gray-50 br-10">
                        <ScrapedPageMarkdown :item="item" />
                        <debug-object :object="item"></debug-object>
                    </div>
                </UICollapsableSection>
            </div>
        </template>
    </div>
</template>
<script>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import ScrapedPageMarkdown from "@/components/generative_templates/Guidelines/ScrapedPageMarkdown.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";

export default {
    name: "ReadAdditionalSources",
    components: { DebugObject, UICollapsableSection, CollapsableMenuItem, ScrapedPageMarkdown },
    props: {
        sources: {
            // type: "array",
            // default: [],
        },
    },
};
</script>
