<template>
    <div :class="{ 'w-80': !mobile }" class="grid grid-cols-12 border-t-brand pt-3 pb-5 mx-auto px-3 md:px-0 x overflow-clip">
        <div class="col-span-12 md:col-span-5 m-0 p-0">
            <div class="f aic gap-3">
                <div class="f fc text-pretty">
                    <div>
                        <h1 v-if="title" class="f-60">{{ title }}</h1>
                        <h1 v-if="subtitle" class="f-40">{{ subtitle }}</h1>
                    </div>
                </div>
                <!--                <h3 :class="` color: ${baseText}!important`" style="" v-if="title">{{ title }}</h3>-->
                <BaseButton :icon="iconClass" :style="buttonColors" class="border-light bg-opacity-0 capitalize transition-all ease-back hover:rotate-180 hover:rotate-90 hover:bg-opacity-100" icon-only reverse rounded size="xxs" style-type="secondary" @click.prevent="update(section)" />
            </div>
            <slot name="left"></slot>
        </div>
        <div class="col-span-12 md:col-span-7 md:px-5 mt-3 text-balance">
            <!--            <FadeUpContainer />-->
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

import BaseButton from "@/components/CoreUI/BaseButton.vue";
import FadeUpContainer from "@/components/CoreUI/FadeUpContainer.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "UIWrapperText",
    components: { BaseButton },
    mixins: [styleGuideMixin],
    props: {
        title: {
            type: String,
            default: "",
        },
        subtitle: {
            type: String,
            default: "",
        },
        section: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapGetters("styleGuide", ["buttonStyles"]),
        mainButton() {
            return this.buttonStyles[0] || {};
        },
        iconClass() {
            // build tailwind class for arbitrary color
            return this.updating ? "fas fa-sync fa-spin ease-back duration-3" : "fas fa-sync";
        },
        loading() {
            return this.$store.state?.styleGuide?.styleGuide?.l;
        },
        buttonColors() {
            // color and backgroundColor
            let { color = "", backgroundColor = "" } = this.mainButton;
            if (this.updating) {
                return { color, backgroundColor };
            } else {
                return {};
            }
        },
    },
    // eslint-disable-next-line vue/order-in-components
    data() {
        return {
            updating: false,
        };
    },
    watch: {
        loading(val) {
            this.updating = val;
        },
    },
    methods: {
        async update(section, payload) {
            this.updating = true;
            console.log("Section", section);
            // if (section.includes("processedImages") && this.styleGuide.shopify) {
            //     section = ["processedImages", "shopify"];
            // }
            await this.updateGuideSection(section, payload);
            if (this.section === "processedImages") {
                setTimeout(() => (this.updating = false), 2000);
            }
            this.updating = false;
        },
        async fetchNewImages() {
            await this.update("fetchNewImages", { existingImages: [...this.styleGuide.images] });
        },
    },
};
</script>
