export default {
    created: function () {},
    data: () => ({
        // artist: null,
    }),
    computed: {
        imageStyles() {
            return this.$store.state.imageStylesStore;
        },
    },
    methods: {
    },
};
