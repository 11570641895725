import fs from "node:fs";

const computedStore = {};
const storeMethods = {};

export const docStore = {
    state() {
        return {};
    },
    mutations: {},
};
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import StableDiffusion from "@/mixins/images/StableDiffusion";
import webRequest from "@/mixins/ai/web_request";

export default {
    created: function () {},
    mounted() {},
    mixins: [StableDiffusion],
    beforeDestroy() {},
    data: () => ({
        imageUrl: "",
    }),
    computed: {
        ...computedStore,
    },
    methods: {
        ...storeMethods,
        async getSImage(request, engine) {
            // eslint-disable no-console, no-control-regex
            console.error("sending request");
            console.error(request);
            // "id": "stable-diffusion-v1-5",

            // const engineId = "stable-diffusion-512-v2-1";
            const engineId = "stable-diffusion-512-v2-0";
            // const apiHost = process.env.API_HOST ?? 'https://api.stability.ai';
            const apiHost = "https://api.stability.ai";
            const apiKey = "sk-ZQUJs3ZA28iwRUZxZdC6RMds03ppmdDSDlH8rKmjxB5E5hoR";
            // const apiKey = import.meta.env.STABILITY_API_KEY;

            if (!apiKey) throw new Error("Missing Stability API key.");

            const response = await fetch(`${apiHost}/v1/generation/${engineId}/text-to-image`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${apiKey}`,
                },
                body: JSON.stringify(request),
            });
            console.error(response.body);
            if (!response.ok) {
                console.error(`Non-200 response: ${await response.text()}`);
            }

            const responseJSON = await response.json();
            console.error(responseJSON);
            // if responseJSON.artifacts is greater than 1, then we need to upload each image to firebase and return an array of urls
            if (responseJSON.artifacts && responseJSON.artifacts.length > 1) {
                let imageURLs = [];
                for (let i = 0; i < responseJSON.artifacts.length; i++) {
                    let imageURL = await this.uploadImage(responseJSON.artifacts[i].base64);
                    imageURLs.push(imageURL);
                }
                return imageURLs;
            } else {
                let imageURL = await this.uploadImage(responseJSON.artifacts[0].base64);
                return imageURL;
            }
            let imageURL = await this.uploadImage(responseJSON.artifacts[0].base64);

            return imageURL;
        },
        async getImageVariations(url) {
            console.error("getting image variations");
            try {
                const response = await openai.createImageVariation(fs.createReadStream(url), 2, "1024x1024", "b64_json");
                console.error(response);
                let imageArray = response.data;
                // iterate through imageArray and upload each image to firebase
                let imageURLArray = [];
                for (let i = 0; i < imageArray.length; i++) {
                    let imageURL = await this.uploadImage(imageArray[i]);
                    imageURLArray.push(imageURL);
                }
                return imageURLArray;
            } catch (e) {
                console.error(e);
                return;
            }
        },
        async generateSectionImage(index, section, summary) {
            this.$emit("fetchingSection", [index, true, `We're fetching your image!`]);
            // this.$refs['section-'+(this.index)].$refs.contenteditable.sectionFetching(true);
            // kind
            let text;
            if (summary) {
                text = summary;
            } else if (section.summary) {
                text = section.summary;
            }
            // return;
            // let promptBase = `Get an image based on this passage Not an illustration.  `;
            let newPrompt = `Write a prompt to generate a high-quality image made to accompany this passage: ${text}`;
            let imagePrompt;
            console.log(newPrompt);
            if (!section || !section.imagePrompt) {
                imagePrompt = await this.imagePrompt(newPrompt, "bot");
            } else {
                imagePrompt = section.imagePrompt;
            }
            this.$emit("fetchingSection", [index, true, `${imagePrompt[0]}`]);
            // this.$emit('fetchingSection', [index, true, `${imagePrompt}`]);
            // return
            console.log(imagePrompt);
            const response = await window.openai.createImage({
                prompt: imagePrompt[0],
                n: 1,
                size: "512x512",
            });
            this.$emit("fetchingSection", [index, true, `Done!`]);
            const imageURL = response.data.data[0].url;
            let sectionPayload = {
                prompt: imagePrompt,
                response: { ...response.data, ...response.config.data },
            };
            setTimeout(() => {
                this.$emit("fetchingSection", [index, false, null]);
                let s = {
                    index: this.index,
                    text: imageURL,
                    kind: "image",
                    prompt: sectionPayload,
                    cursorPosition: null,
                };
                this.newSection(s);
            }, 500);
            // this.$emit('fetching', [index, true,`We're fetching your image!`]);
            // this.$emit('newSection', [this.index, imageURL, null, 'image']);
            // this.newSection(this.index, imageURL,null,'image');
            await this.uploadImage(imageURL);
            console.error("image", imageURL);
            return imageURL;
        },
        async generateLyricsImage(item, lyrics) {
            let promptBase = `a ${item.promptData.genre.name} song called ${item.promptData.name}. Don't use text on the cover. Not an illustration. You can use any images you want. Use the description for inspiration:\n${item.promptData.topic}\n `;
            let newPrompt = `Write a prompt to create an image for a realistic album cover based on ${promptBase}`;
            let imagePrompt;
            console.log(newPrompt);
            if (!item.imagePrompt) {
                imagePrompt = await this.imagePrompt(newPrompt, "bot");
            } else {
                imagePrompt = item.imagePrompt;
            }
            let newImagePrompt = "Epic album cover: " + imagePrompt[0] + " Ultra realistic, 8k, sharp image, not animated, dont use any typography. ";
            console.log(newImagePrompt);
            const response = await window.openai.createImage({
                prompt: newImagePrompt,
                n: 1,
                response_format: "b64_json",
                size: "512x512",
            });
            if (response.data.data[0].url) {
                this.imageUrl = await this.uploadImage(response.data.data[0].url, "url");
            } else {
                this.imageUrl = await this.uploadImage(response.data.data[0].b64_json, "b64_json");
            }
            this.$nextTick(() => {
                this.updateAnyObject("feedItem", item.id, { image: this.imageUrl });
            });
            return this.imageURL;
        },
        async generateImage(index, section, summary) {
            console.error("mage Step 1: Initializing image generation ", index, section, summary);
            this.$emit("fetchingSection", [index, true, `We're fetching your image!`]);
            // this.$refs['section-'+(this.index)].$refs.contenteditable.sectionFetching(true);
            // kind
            let text;
            if (summary) {
                text = summary;
            } else if (section.content) {
                console.error("Image Step 2: Prompt from content ", section.content);
                text = section.content;
            } else if (section.summary) {
                console.error("Image Step 2: Prompt from summary ", section.summary);
                text = section.summary;
            }
            // return;
            // let promptBase = `Get an image based on this passage Not an illustration.  `;
            let newPrompt = `Write a detailed description to generate a high-quality image made to accompany this passage: ${text}`;
            let imagePrompt;
            console.log(newPrompt);
            if (!section || !section.imagePrompt) {
                imagePrompt = await this.imagePrompt(newPrompt, "bot");
            } else {
                imagePrompt = section.imagePrompt;
            }
            this.$emit("fetchingSection", [index, true, `${imagePrompt[0]}`]);
            try {
                // const response = await window.openai.createImage({
                //     prompt: imagePrompt[0],
                //     n: 1,
                //     size: "512x512",
                //     response_format: "b64_json",
                // });

                const imageURL = await this.getStabilityImage(imagePrompt[0]);
                this.$emit("fetchingSection", [index, true, `Done!`]);
                let sectionPayload = {
                    prompt: imagePrompt[0],
                    request: imagePrompt[1],
                };

                setTimeout(() => {
                    console.error("imageURL for section");
                    console.error(imageURL);
                    this.$emit("fetchingSection", [index, false, null]);
                    console.error("Image Step 10: Hide loader ");
                    // this.newSection(this.index, storyImage, null, 'image', sectionPayload);
                    let s = {
                        content: imageURL,
                        kind: "image",
                        cursorPosition: null,
                        prompt: sectionPayload,
                        index: this.index,
                    };
                    this.newSection(s);
                    console.error("Image Step 11: Create new section done", this.index, imageURL, null, "image", sectionPayload);
                    return;
                }, 500);
                // this.section.content = imageURL;
                return;
                // this.$emit('fetching', [index, true,`We're fetching your image!`]);
                // this.$emit('newSection', [this.index, imageURL, null, 'image']);
                // this.uploadToFirebase(imageURL);
                // this.newSection(this.index, imageURL,null,'image');
                return await this.uploadImage(response.data.data[0].b64_json, "b64_json");
                console.error("image", imageURL);
                this.section.content = imageURL;
                return imageURL;
            } catch (error) {
                if (error.status === 401) {
                    console.log("Error: Invalid API key");
                } else if (error.status === 403) {
                    console.log("Error: API key has insufficient permissions");
                } else if (error.status === 429) {
                    console.log("Error: API rate limit exceeded");
                } else {
                    console.log("Error: " + error.message);
                }
            }
        },
        async regenerateImage(index, prompt) {
            this.$emit("fetchingSection", [index, true, `We're fetching your image!`]);
            let text = prompt;
            this.$emit("fetchingSection", [index, true, `${text}`]);
            // let request = {
            //     prompt: text,
            //     n: 1,
            //     size: "512x512",
            //     response_format: "b64_json",
            // };
            // const response = await window.openai.createImage(request);
            this.$emit("fetchingSection", [index, true, `Done!`]);

            // let FBImageURL;

            // FBImageURL = await this.uploadImage(response.data.data[0].b64_json, "b64_json");

            const imageURL = await this.getStabilityImage(prompt);
            // let sectionPayload = {
            //     request: request,
            // };
            setTimeout(() => {
                this.$emit("fetchingSection", [index, false, null]);
                // this.newSection(this.index, storyImage, null, 'image', sectionPayload);
                this.section.content = imageURL;
                this.section.prompt = prompt;
                return;
            }, 300);
            // this.section.content = imageURL;
            return;
            // this.$emit('fetching', [index, true,`We're fetching your image!`]);
            // this.$emit('newSection', [this.index, imageURL, null, 'image']);
            this.section.content = imageURL;
            return imageURL;
        },
        async generateImage2() {
            const apiKey = import.meta.env.VITE_OPENAI_API;
            const organization = import.meta.VITE_OPENAI_ORG;
            const response = await openai.createImage({
                prompt: "a golden goose",
                n: 1,
                response_format: "b64_json",
                size: "512x512",
            });
            console.error(response);
            this.$nextTick(() => {
                if (response.data.data[0].url) {
                    return this.uploadImage(response.data.data[0].url, "url");
                } else {
                    return this.uploadImage(response.data.data[0].b64_json, "b64_json");
                }
            });
        },
        async getImageURLFromFirebase(ref) {
            return await getDownloadURL(ref)
                .then(url => {
                    this.imageUrl = url;
                    console.groupCollapsed("Image URL");
                    console.log(url);
                    console.groupEnd();
                    return url;
                })
                .catch(error => {
                    console.error(error);
                    console.error(this.gptError(error));
                    return;
                });
        },
        async uploadImageFromURL(url) {
            try {
                // Fetch the image data from the URL
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Failed to fetch image");
                }

                // Get the image blob
                const imageBlob = await response.blob();

                // Create a FormData instance to send the image file
                const formData = new FormData();
                formData.append("image", imageBlob, "image.jpg");

                // Send the image file to the server
                const uploadResponse = await fetch("/upload", {
                    method: "POST",
                    body: formData,
                });

                if (!uploadResponse.ok) {
                    throw new Error("Failed to upload image");
                }

                // Get the response data (e.g., the uploaded image URL)
                const data = await uploadResponse.json();
                console.log("Image uploaded successfully:", data);
            } catch (error) {
                console.error("Error:", error.message);
            }
        },
        async fetchImageBlobFromUrl(url) {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const blob = await response.blob();
            return blob;
        },
        async uploadFileAsImage(file) {
            const reader = new FileReader();
            const storage = getStorage();
            const filename = `images/image-blob-${this.randomId()}.png`;
            const imagesRef = ref(storage, filename);
            const metadata = {
                contentType: "image/png",
            };

            const fileAsDataURLPromise = new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file);
            });

            try {
                const dataURL = await fileAsDataURLPromise;
                const base64Data = dataURL.split(",")[1];
                const blob = await fetch(dataURL).then(r => r.blob());
                await uploadBytes(imagesRef, blob, metadata);
                const imageURL = await this.getImageURLFromFirebase(imagesRef);

                return imageURL;
            } catch (error) {
                console.error(`Error uploading file as image: ${error.message}`);
            }
        },
        // async uploadFileAsImageCF(file, object) {
        //     const reader = new FileReader();
        //     const storage = getStorage();
        //     const filename = `images/image-blob-${this.randomId()}.png`;
        //     const imagesRef = ref(storage, filename);
        //     const metadata = {
        //         contentType: "image/png",
        //     };
        //
        //     const fileAsDataURLPromise = new Promise((resolve, reject) => {
        //         reader.onload = () => resolve(reader.result);
        //         reader.onerror = error => reject(error);
        //         reader.readAsDataURL(file);
        //     });
        //
        //     try {
        //         const dataURL = await fileAsDataURLPromise;
        //         const base64Data = dataURL.split(",")[1];
        //         const blob = await fetch(dataURL).then(r => r.blob());
        //         // await uploadBytes(imagesRef, blob, metadata);
        //         let upload = { file: dataURL };
        //         let imageType;
        //         if (dataURL.includes("jpg") || dataURL.includes("jpeg")) {
        //             imageType = "jpeg";
        //         }
        //         if (dataURL.includes("png")) {
        //             imageType = "png";
        //         } else if (dataURL.includes("gif")) {
        //             imageType = "gif";
        //         } else if (dataURL.includes("svg")) {
        //             imageType = "svg+xml";
        //         }
        //         upload.type = imageType;
        //         if (object?.name) {
        //             upload.name = object.name;
        //         }
        //         let imageURL = await webRequest("uploadImage", upload);
        //         imageURL = await imageURL.json();
        //         // const imageURL = await this.getImageURLFromFirebase(imagesRef);
        //
        //         return imageURL;
        //     } catch (error) {
        //         console.error(`Error uploading file as image: ${error.message}`);
        //     }
        // },
        async uploadFileAsImageCF(file, object) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onloadend = async () => {
                    const base64Data = reader.result.split(",")[1];
                    const requestPayload = {
                        file: base64Data,
                        name: object.name,
                        type: object.mimetype,
                    };

                    try {
                        let response = await webRequest("uploadImage", requestPayload);
                        response = await response.json();
                        const images = response.images;
                        console.log(images[0]);
                        resolve(images[0]); // Resolve the Promise
                    } catch (error) {
                        console.error(`Error uploading file as image: ${error.message}`);
                        reject(error); // Reject the Promise
                    }
                };

                reader.onerror = () => {
                    reject(new Error("FileReader error"));
                };
            });
        },

        async uploadImage(file, kind) {
            let filename = `images/image-${this.randomId()}.png`;
            let blob;
            const storage = getStorage();
            const imagesRef = ref(storage, filename);
            let imageURL = "";
            const metadata = {
                contentType: "image/png",
            };

            if (kind === "url") {
                imageURL = file;
                blob = await this.fetchImageBlobFromUrl(imageURL);
            }
            // else {
            //     blob = await fetch(`data:image/png;base64,${file}`).then(r => r.blob());
            //     filename = `images/image-blob-${this.randomId()}.png`;
            // }
            if (kind === "url") {
                let imageBlob = await uploadBytes(imagesRef, blob, metadata).then(snapshot => {
                    console.log("Uploaded a blob or file!");
                });
                imageURL = await this.getImageURLFromFirebase(imageBlob);
                return imageURL;
            } else if (kind === "blob") {
                const storage = getStorage();
                const imagesRef = ref(storage, `images/image-blob-${this.randomId()}.png`);
                try {
                    await uploadBytes(imagesRef, file, metadata);
                    imageURL = await this.getImageURLFromFirebase(imagesRef);
                    return imageURL;
                } catch (error) {
                    console.error(`Error uploading image: ${error.message}`);
                    // ... rest of the error handling
                }
            } else {
                try {
                    console.log("Is Base54 Image");
                    let base64url = await uploadBytes(imagesRef, blob, metadata).then(snapshot => {});
                    imageURL = await this.getImageURLFromFirebase(imagesRef);
                    // return base64url;
                    return imageURL;
                } catch (error) {
                    console.error(`Error uploading image: ${error.message}`);

                    if (error.status === 401) {
                        console.log("Error: Invalid API key");
                    } else if (error.status === 403) {
                        console.log("Error: API key has insufficient permissions");
                    } else if (error.status === 429) {
                        console.log("Error: API rate limit exceeded");
                    } else {
                        console.log("Error: " + error.message);
                    }
                }
            }
        },
        async fetchImages(query) {
            try {
                const response = await fetch(`${this.apiURL}searchImages?q=${query}`);
                console.error("searching for images", query);
                let images = await response.json();
                console.error("images", images);
                // upload each image in the response using this.saveImageFromURL(url)
                // then set this.images to the array of image URLs
                let self = this;
                // for (let i = 0; i < images.length; i++) {
                //     let url = images[i];
                //     let image = await self.uploadImage(url, "url");
                //     images[i] = image;
                // }
                this.images = images;
                return images;
            } catch (err) {
                console.error(err);
            }
        },
        async imageVariationServer(url) {
            const request = {
                url: url,
            };
            const response = await webRequest("imageVariations", request);
            const responseData = await response.json(); // Parse response JSON
            const imageURLS = [];
            const self = this;

            for (const image of responseData) {
                let url = await self.uploadImage(image);
                imageURLS.push(url);
            }
            console.log(responseData); // Log the response data
            console.log(imageURLS); // Log the response data
            return imageURLS; // Return the response data
        },
    },
};
