<template>
    <div class="relative pt-5 mb-5">
        <!-- Fixed Header -->
        <div class="absolute top-0 left-0 px-3 z-2">
            <h3>{{ activeTitle }}</h3>
        </div>
        <!-- Scrolling Content -->
        <div class="flex relative overflow-x-auto scrollbar-hide" style="padding-right: 150px" ref="scrollContainer" @scroll="handleScroll">
            <div v-for="item in colorArrays" :key="item.id" ref="colorSections" class="relative mr-5 px-3">
                <div class="flex gap-3">
                    <ColorPallet v-for="(value, key) in colors[`${item.id}${$store.state.styleGuide.sortColors ? `_${$store.state.styleGuide.sortColors}` : ''}`]" :key="key" :color="value" :w="150" class="p-relative col-span-2 md:col-span-1 overflow-hidden" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash"; // Use debounce from lodash for example
import ColorPallet from "@/components/styleGuide/Colors/ColorPallet.vue";
export default {
    name: "StyleGuideColors",
    components: { ColorPallet },
    data() {
        return {
            activeTitle: "Title 1",
            sectionOffsets: [],
            scrollLeft: 0,
        };
    },
    props: {
        colors: {
            type: Object,
            default: () => {
                return {};
            },
        },
        colorArrays: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    methods: {
        handleScroll: debounce(function () {
            // Existing logic
            this.scrollLeft = this.$refs.scrollContainer.scrollLeft;
            for (let i = 0; i < this.sectionOffsets.length; i++) {
                const start = this.sectionOffsets[i];
                const end = this.sectionOffsets[i + 1] || Infinity;
                if (this.scrollLeft >= start && this.scrollLeft < end) {
                    this.activeTitle = this.colorArrays[i].name;
                    break;
                }
            }
        }, 50), // 200ms debounce time
    },
    mounted() {
        this.$nextTick(() => {
            this.activeTitle = this.colorArrays[0].name;
            this.sectionOffsets = Array.from(this.$refs.colorSections).map(section => section.offsetLeft);
            this.$refs.scrollContainer.addEventListener("scroll", this.handleScroll, { passive: true });
        });
    },
    beforeDestroy() {
        // Remove the event listener
        this.$refs.scrollContainer.removeEventListener("scroll", this.handleScroll);
        // Nullify data
        this.$refs.scrollContainer = null;
        this.$refs.colorSections = null;
        this.sectionOffsets = null;
    },
};
</script>

<style lang="scss">
.absolute {
    position: absolute;
}
</style>
