import chromaMixin from "@/mixins/StyleMixins/chromaMixin";
export default {
    created: function () {},
    data() {
        return {
            tone: "whimsical",
            tones: ["stevejobs", "tomford", "salvadoredali", "paulrand", "dieterrams"],
            dialogContent: {
                title: "Exporting Email Template",
                description: "Please wait while we export your email template",
                button: "",
            },
        };
    },
    mixins: [chromaMixin],
    computed: {},
    methods: {
        resetDialog() {
            this.dialogContent = {
                title: "Exporting Email Template",
                description: "Please wait while we export your email template",
                button: null,
            };
        },
        updateDialog(key, index, link) {
            const { title, description, button } = this.dialogMessages(this.tone, index)[key];
            this.dialogContent = {
                title: title || "",
                description: description || "",
                button: button || "",
                buttonLink: link,
            };
        },
        async randomTone() {
            this.tone = this.tones[Math.floor(Math.random() * this.tones.length)];
            return this.tone;
        },
        getRandomMessage(messages) {
            return messages[Math.floor(Math.random() * messages.length)];
        },
        dialogMessages(persona, index) {
            if (persona === "stevejobs") {
                return {
                    gatheringImagesAndFonts: {
                        title: "Injecting Soul",
                        description: "Injecting soul into pixels and typefaces...",
                    },
                    creatingEmailTemplate: {
                        title: "Designing Simplicity",
                        description: "Simplicity is the ultimate sophistication. Designing your email template...",
                    },
                    emailTemplateComplete: {
                        title: "Innovation Awaits",
                        description: "Innovation distinguishes between a leader and a follower. Your email is ready to lead.",
                        button: "Let's innovate",
                    },
                    errorExporting: {
                        title: "Think Different",
                        description: "Mistakes are a necessary evil to truly innovative email templates. Let's give it another shot.",
                        button: "Let's innovate",
                    },
                    imageUpload: {
                        description: this.getRandomMessage(["Uploading image, pixel by perfect pixel...", "Processing image, making it utterly magical...", "Sending image to the server, where innovation happens...", "Preparing image for export, elevating it to art...", "Exporting image, ready to inspire the world..."]),
                    },
                    sectionUpload: {
                        description: this.getRandomMessage([
                            "Uploading section {index}, making it insanely great...",
                            "Processing section {index}, infusing it with innovation...",
                            "Capturing section {index}, think different...",
                            "Preparing section {index}, pushing the boundaries of what's possible...",
                            "Exporting section {index}, get ready to dent the universe...",
                        ]).replace("{index}", index + 1),
                    },
                    sectionNotFound: {
                        title: "Section Not Found",
                        description: "Section {index} is in stealth mode. One sec, we're on it!".replace("{index}", index + 1),
                    },
                    sectionPlayingHideAndSeek: {
                        title: "Think Different",
                        description: "Section {index} thinks different. Tracking it down now...".replace("{index}", index + 1),
                    },
                    sectionsProgress: {
                        title: "Innovation in Progress",
                        description: "{completed} of {total} sections. We're almost there, and it's going to be insanely great.".replace("{completed}", index).replace("{total}", `${this.sections?.length}`),
                    },
                    allSectionsCaptured: {
                        title: "Innovation Complete",
                        description: "The email, a masterpiece of innovation. It's time to change the world.",
                    },
                };
            } else if (persona === "tomford") {
                return {
                    gatheringImagesAndFonts: {
                        title: "Curating Elegance",
                        description: "Curating a symphony of aesthetics and typography...",
                    },
                    creatingEmailTemplate: {
                        title: "Refining Luxury",
                        description: "Refined simplicity is the essence of true luxury. Curating your email template...",
                    },
                    emailTemplateComplete: {
                        title: "Sophistication Awaits",
                        description: "Style is the perfection of a point of view. Your email exudes pure sophistication.",
                        button: "Unveil sophistication",
                    },
                    errorExporting: {
                        title: "Timeless Resilience",
                        description: "Even in the face of adversity, true style endures. Let's gracefully attempt this again.",
                        button: "Unveil sophistication",
                    },
                    imageUpload: {
                        title: "Uploading Image",
                        description: this.getRandomMessage(["Uploading image, each pixel a brushstroke of artistry...", "Processing image, unveiling its inherent beauty...", "Sending image to the server, a sanctuary of refined taste...", "Preparing image for export, a masterpiece in the making...", "Exporting image, ready to redefine luxury..."]),
                    },
                    sectionUpload: {
                        title: "Sophistication Awaits",
                        description: this.getRandomMessage([
                            "Uploading section {index}, imbuing it with impeccable craftsmanship...",
                            "Processing section {index}, refining it to understated perfection...",
                            "Capturing section {index}, framing a moment of timeless elegance...",
                            "Preparing section {index} for a journey of sophisticated allure...",
                            "Exporting section {index}, ready to captivate the discerning eye...",
                        ]).replace("{index}", index + 1),
                    },
                    sectionNotFound: {
                        title: "Section Not Found",
                        description: "Section {index} is awaiting its bespoke craftsmanship. One moment, please.".replace("{index}", index + 1),
                    },
                    sectionPlayingHideAndSeek: {
                        title: "Elegance Eludes",
                        description: "Section {index}, an elusive muse. Pursuing it with unwavering dedication...".replace("{index}", index + 1),
                    },
                    sectionsProgress: {
                        title: "Elegance in Progress",
                        description: "{completed} of {total} sections. An ode to meticulous craftsmanship.".replace("{completed}", index).replace("{total}", `${this.sections?.length}`),
                    },
                    allSectionsCaptured: {
                        title: "Sophistication Complete",
                        description: "The email, a paragon of sophisticated allure. Prepare to redefine style.",
                    },
                };
            } else if (persona === "salvadoredali") {
                return {
                    gatheringImagesAndFonts: {
                        title: "Assembling Surreality",
                        description: "Assembling a kaleidoscope of visions and whispers, the building blocks of our surreal tapestry...",
                    },
                    creatingEmailTemplate: {
                        title: "Weaving Dreams",
                        description: "In the realm of the subconscious, we weave a template of dreams and desires...",
                    },
                    emailTemplateComplete: {
                        title: "Surreal Enchantment Awaits",
                        description: "Behold! The email template, a manifestation of the surreal, ready to bewitch the world!",
                        button: "Unveil the surreal",
                    },
                    errorExporting: {
                        title: "Embracing Chaos",
                        description: "In the face of chaos, the true artist persists. Let us embrace the unknown and try once more!",
                        button: "Unveil the surreal",
                    },
                    imageUpload: {
                        title: "Uploading Image",
                        description: this.getRandomMessage([
                            "Uploading image, a window into the surreal, transcending the boundaries of reality...",
                            "Processing image, unlocking the hidden dimensions of the psyche...",
                            "Sending image to the server, entrusting it to the guardians of the digital subconscious...",
                            "Preparing image for export, imbuing it with the power to transform perception...",
                            "Exporting image, setting it free to manifest dreams and desires...",
                        ]),
                    },
                    sectionUpload: {
                        title: "Surreal Enchantment Awaits",
                        description: this.getRandomMessage([
                            "Uploading section {index}, embarking on a metaphysical journey...",
                            "Processing section {index}, unveiling the secrets of the subconscious...",
                            "Capturing section {index}, immortalizing a fleeting moment of surreality...",
                            "Preparing section {index} for a voyage into the depths of imagination...",
                            "Exporting section {index}, releasing it into the ethereal realms of cyberspace...",
                        ]).replace("{index}", index + 1),
                    },
                    sectionNotFound: {
                        title: "Section Not Found",
                        description: "Section {index} has disappeared into the labyrinthine depths of the subconscious. Fear not, we shall embark on a quest to retrieve it!".replace("{index}", index + 1),
                    },
                    sectionPlayingHideAndSeek: {
                        title: "Seeking Surreality",
                        description: "Section {index}, an elusive figment of the imagination, teases us with its absence. The eternal game of hide and seek continues!".replace("{index}", index + 1),
                    },
                    sectionsProgress: {
                        title: "Conjuring Surreality",
                        description: "{completed} of {total} sections have been conjured from the realms of the surreal!".replace("{completed}", index + 1).replace("{total}", `${this.sections?.length}`),
                    },
                    allSectionsCaptured: {
                        title: "Surreal Enchantment Complete",
                        description: "The email, a surreal tapestry woven from the threads of dreams and desires, stands ready to enchant the world!",
                    },
                };
            } else if (persona === "paulrand") {
                return {
                    gatheringImagesAndFonts: {
                        title: "Orchestrating Harmony",
                        description: "Orchestrating a harmony of imagery and typography, the cornerstones of effective communication...",
                    },
                    creatingEmailTemplate: {
                        title: "Honing Simplicity",
                        description: "Design is the silent ambassador of your brand. Honing your email template...",
                    },
                    emailTemplateComplete: {
                        title: "Clarity Awaits",
                        description: "Simplicity is not the goal. It is the by-product of a good idea and modest expectations. Your email is a testament to that philosophy.",
                        button: "Reveal clarity",
                    },
                    errorExporting: {
                        title: "Iterating Perfection",
                        description: "In design, there is no such thing as failure, only iterations. Let's approach this with a fresh perspective.",
                        button: "Reveal clarity",
                    },
                    imageUpload: {
                        title: "Uploading Image",
                        description: this.getRandomMessage([
                            "Uploading image, each pixel a brush stroke of intentional design...",
                            "Processing image, revealing its core message...",
                            "Sending image to the server, a repository of purposeful communication...",
                            "Preparing image for export, a visual statement in the making...",
                            "Exporting image, ready to leave a lasting impression...",
                        ]),
                    },
                    sectionUpload: {
                        title: "Clarity Awaits",
                        description: this.getRandomMessage([
                            "Uploading section {index}, imbuing it with purpose and clarity...",
                            "Processing section {index}, refining it to its essential elements...",
                            "Capturing section {index}, framing a moment of visual eloquence...",
                            "Preparing section {index} for a journey of meaningful engagement...",
                            "Exporting section {index}, ready to inspire and inform...",
                        ]).replace("{index}", index + 1),
                    },
                    sectionNotFound: {
                        title: "Section Not Found",
                        description: "Section {index} is undergoing a process of design refinement. One moment, please.".replace("{index}", index + 1),
                    },
                    sectionPlayingHideAndSeek: {
                        title: "Seeking Clarity",
                        description: "Section {index}, an elusive component of the grand design. Locating it with purposeful intent...".replace("{index}", index + 1),
                    },
                    sectionsProgress: {
                        title: "Harmony in Progress",
                        description: "{completed} of {total} sections. An homage to clarity and purposeful design.".replace("{completed}", index + 1).replace("{total}", `${this.sections?.length}`),
                    },
                    allSectionsCaptured: {
                        title: "Clarity Complete",
                        description: "The email, a masterclass in simplicity and effective communication. Prepare to engage and inspire.",
                        button: "See the clarity",
                    },
                };
            } else if (persona === "dieterrams") {
                return {
                    gatheringImagesAndFonts: {
                        title: "Curating Purpose",
                        description: "Curating a symphony of purpose-driven aesthetics and typography...",
                    },
                    creatingEmailTemplate: {
                        title: "Crafting Restraint",
                        description: "Good design is as little design as possible. Crafting your email template with intentional restraint...",
                    },
                    emailTemplateComplete: {
                        title: "Functional Beauty Awaits",
                        description: "Less, but better. Your email template embodies the beauty of functional simplicity.",
                        button: "Embrace simplicity",
                    },
                    errorExporting: {
                        title: "Refining Perfection",
                        description: "Perfection is achieved not when there is nothing more to add, but when there is nothing left to take away. Let's refine and try again.",
                        button: "Embrace simplicity",
                    },
                    imageUpload: {
                        title: "Uploading Image",
                        description: this.getRandomMessage([
                            "Uploading image, each pixel a testament to purposeful design...",
                            "Processing image, revealing its inherent clarity...",
                            "Sending image to the server, a haven of functional aesthetics...",
                            "Preparing image for export, a visual manifestation of simplicity...",
                            "Exporting image, ready to enhance the user's journey...",
                        ]),
                    },
                    sectionUpload: {
                        title: "Functional Beauty Awaits",
                        description: this.getRandomMessage([
                            "Uploading section {index}, imbuing it with the essence of functional beauty...",
                            "Processing section {index}, reducing it to its purest form...",
                            "Capturing section {index}, framing a moment of honest simplicity...",
                            "Preparing section {index} for a journey of intuitive interaction...",
                            "Exporting section {index}, ready to deliver a seamless user experience...",
                        ]).replace("{index}", index + 1),
                    },
                    sectionNotFound: {
                        title: "Section Not Found",
                        description: "Section {index} is undergoing a process of design distillation. One moment, please.".replace("{index}", index + 1),
                    },
                    sectionPlayingHideAndSeek: {
                        title: "Seeking Purpose",
                        description: "Section {index}, a hidden gem of purposeful design. Uncovering its essence...".replace("{index}", index + 1),
                    },
                    sectionsProgress: {
                        title: "Purpose in Progress",
                        description: "{completed} of {total} sections. A testament to the power of less.".replace("{completed}", index + 1).replace("{total}", `${this.sections?.length}`),
                    },
                    allSectionsCaptured: {
                        title: "Functional Beauty Complete",
                        description: "The email, an embodiment of honest design and functional beauty. Prepare to engage and delight.",
                        button: "Embrace simplicity",
                    },
                };
            } else {
                // default to Steve Jobs persona
                return this.dialogMessages("stevejobs");
            }
        },
    },
};
