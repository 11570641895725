// streamingHelpers.js
import { set } from "lodash";

export default {
    data() {
        return {};
    },
    methods: {
        getPromptRules(rules = [], title = "TASK RULES") {
            // string output as a list with new lines preprended by a "- "
            let rulesFix = rules.map((rule, index) => `${index + 1}.) ${rule}`);
            let rulesString = rulesFix.join("\n");
            return `[${title}]\n` + rulesString;
        },
        getConversationContext(messages, count = 4, title = "Conversation context") {
            const messagesString = `[${title.toUpperCase()}]\n---\n${this.getLastMessagesString(messages, count)}----`;
            return messagesString;
        },
        getLatestUserMessage(messages, title = "User's latest messages") {
            // filter this.messages by user messages, return in string format [USER]: content
            let userMessageString = "";
            let userMessages = this.messages.slice(-3).filter(message => {
                return message.role === "user";
            });
            if (userMessages.length > 2) {
                userMessageString = userMessages
                    .map(message => {
                        return `- ${message.content.slice(0, 200)}`;
                    })
                    .join("\n");
                return userMessageString;
            }
            return `[${title.toUpperCase()}]\n${userMessageString}`;
        },
        getResponseExamples(examples, title = "Response examples", previousMessages = [], showPreviousMessages) {
            let examplesString = ``;
            let userResponses = "";
            if (showPreviousMessages && previousMessages && previousMessages.length > 1) {
                userResponses = `\n${this.getLatestUserMessage(previousMessages)}\n`;
            }
            examples.forEach((example, index) => {
                examplesString += `${index + 1}.) ${example}\n`;
            });
            return `${userResponses}[${title.toUpperCase()}]\n${examplesString}`;
        },
        getLastMessagesString(messages, messageCount) {
            let lastMessageIndex = messages.length - 1;
            let result = "";

            for (let i = Math.max(0, lastMessageIndex - (messageCount - 1)); i <= lastMessageIndex - 1; i++) {
                if (messages[i]) {
                    let content = i === lastMessageIndex ? messages[i].content : messages[i].content.slice(0, 500);
                    // add tab to content beginning
                    content.replace("\n", "\t");
                    result += `[${messages[i].role.toUpperCase()}]:\n${content}\n\n`;
                }
            }
            return result;
        },
        stringContainsType(string) {
            let evalString = string.toLowerCase();
            const singleSelectStrings = ["single_select", "single_select", "single_select", "single select", "single-select", "single-Select", "single-select"];
            const multiSelectStrings = ["multi_select", "multi_select", "multi_select", "multi select", "multi-select", "multi-Select", "multi-select", "multi select", "multiple choice"];
            // types is concat of both arrays
            let types = [].concat(singleSelectStrings, multiSelectStrings);
            // see if string contains any of the types then return either "single_select" or "multi_select"
            for (let i = 0; i < types.length; i++) {
                if (evalString.includes(types[i])) {
                    return types[i];
                }
            }
            if (singleSelectStrings.includes(evalString)) {
                return "single_select";
            }
            if (multiSelectStrings.includes(evalString)) {
                return "multi_select";
            }
            return false;
        },
        prepareString(string) {
            let cleanString = string.replace("undefined", "").replace(/^\d+\.\s*/, "");
            // cleanString = cleanString.replace("\n", "").trim();
            return cleanString;
        },
        responsesToIgnore(string) {
            let ignoreString;
            if (typeof string === "string") {
                // remove all punctuation
                ignoreString = string.replace("!", "").replace(".", "");
                ignoreString = ignoreString.trim().toUpperCase();
            }
            let stringsToIgnore = ["[Related question]", "Please", "THANKS", " [Related question]", "[RELATED QUESTION]", "[RELATED TOPIC]", "[CONVERSATION CONTEXT]", "SINGLE_SELECT", "MULTI_SELECT"];
            return stringsToIgnore.includes(ignoreString);
        },
        cleanString(suggestion) {
            let cleanString = suggestion.replace("undefined", "").replace(/^\d+\.\s*/, "");
            cleanString = cleanString.replace("\n", "").trim();
            cleanString = cleanString.replace(/"/g, "");
            cleanString = cleanString.replace(/\(|\)/g, "");
            cleanString = cleanString.replace(/^-/, "");
            return cleanString.replace(/^\d+/, "");
        },
        splitResponses(string, delimiters = ["\n", ",", "[DONE]", "\\"]) {
            //     // split string into array of responses by line breaks, "[DONE]" or ","
            //     return string.split(/\n|\[DONE\]|,/);
            const delimiterRegex = new RegExp(delimiters.map(delimiter => this.escapeRegExp(delimiter)).join("|"), "g");
            const responses = string.split(delimiterRegex);
            return responses;
        },
        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escapes any special characters in the string.
        },
        processAndUpdateSuggestions(array) {
            for (let i = 0; i < array.length - 1; i++) {
                const item = array[i];
                if (item.length > 5) {
                    let cleanString = this.cleanSuggestion(item);
                    this.updateSuggestionsAndEmit(cleanString);
                    this.$forceUpdate();
                }
            }
        },
        // processAndUpdateSuggestions(splitString) {
        //     for (let i = 0; i < splitString.length - 1; i++) {
        //         const suggestion = splitString[i];
        //         if (suggestion.length > 5) {
        //             let cleanString = this.cleanSuggestion(suggestion);
        //             const result = this.updateSuggestions(this.suggestions, this.suggestionBuffer, cleanString, this.questionType, this.responsesToIgnore, this.findType);
        //
        //             this.suggestions = result.suggestions;
        //             this.suggestionBuffer = result.buffer;
        //             this.questionType = result.questionType;
        //
        //             if (result.addSuggestions) {
        //                 this.$emit("addSuggestions", this.suggestions, result.type.trim());
        //             }
        //
        //             if (result.addToSuggestions) {
        //                 this.$emit("addToSuggestions", cleanString.trim());
        //             }
        //
        //             this.$forceUpdate();
        //         }
        //     }
        // },
        updateSuggestions(array, currentBuffer, inputString, type) {
            if (!inputString.includes("quick response")) {
                type = this.findType(inputString);
                if (type) {
                    console.log("Type found:", type);
                    currentBuffer = "";
                    return {
                        array: array,
                        buffer: currentBuffer,
                        type: type,
                        addSuggestions: true,
                    };
                } else {
                    if (!this.responsesToIgnore(inputString)) {
                        array.push(inputString.trim());
                        currentBuffer = "";
                        return {
                            suggestions: array,
                            buffer: currentBuffer,
                            addToSuggestions: true,
                            inputString,
                        };
                    }
                }
            }
            return { array: array, buffer: currentBuffer, type: type };
        },
        findType(string) {
            return this.stringContainsType(string);
        },
        includesDelimiters(string, delimiters = ["\n", ",", "[DONE]"]) {
            return delimiters.some(delimiter => string.includes(delimiter));
        },
        saveType(type, array) {
            console.log("Type found:", type);
            this.$emit("addSuggestions", array, type.trim());
        },
        saveArrayItem(string, vueProp) {
            // this.suggestions.push(string.trim());
            this.$emit("addToSuggestions", string.trim());
            this[vueProp].push(string.trim());
        },
        updateSuggestionsAndEmit(string) {
            if (!string.includes("quick response")) {
                const type = this.findType(string);
                if (type) {
                    console.log("Type found:", type);
                    this.questionType = type;
                    this.$emit("addSuggestions", this.suggestions, type.trim());
                    this.suggestionBuffer = "";
                    return;
                } else {
                    if (!this.responsesToIgnore(string)) {
                        this.suggestions.push(string.trim());
                        this.$emit("addToSuggestions", string.trim());
                        this.suggestionBuffer = "";
                    }
                }
            }
        },
        bufferString(string, response) {
            string += response;
            suggestionBuffer = string.replace(", ", "\n");
            return string;
        },
    },
};
