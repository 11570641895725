<template>
    <div class="f aic jcs gap-2 my-1">
        <div class="fwb badge bg-green">{{ modelShortName(model) }}</div>
        <span class="text-xs o-5">{{ model }}</span>
    </div>
</template>
<script>
export default {
    name: "ModelBadge",
    props: {
        model: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
