<template>
    <div class="d-block">
        <template v-if="!small">
            <div :class="{ dark: dark }" :style="bgMod" class="spinner br-10 d-inline-flex mt-0 cursor-pointer gap-1">
                <div :style="colorMod" class="transition-colors bounce1"></div>
                <div :style="colorMod" class="transition-colors bounce2"></div>
                <div :style="colorMod" class="transition-colors bounce3"></div>
            </div>
        </template>
        <template v-else>
            <div :style="`gap:3px;${bgMod}`" class="spinner-small d-inline-flex h-auto mt-0 cursor-pointer gap-1">
                <div :style="colorMod" class="bounce1"></div>
                <div :style="colorMod" class="bounce2"></div>
                <div :style="colorMod" class="bounce3"></div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        small: { type: Boolean, default: false },
        background: { type: String, default: null },
        color: { type: String, default: null },
        colors: {
            type: Array,
            default: null,
            validator: colors => {
                return colors.every(color => color !== "#000000" && color !== "#FFFFFF");
            },
        },
        dark: { type: Boolean, default: false },
    },
    computed: {
        colorMod() {
            if (this.colors && this.colors.length > 0) {
                return `background: linear-gradient(to right, ${this.colors.join(", ")});`;
            } else if (this.color) {
                return `background:${this.color};`;
            } else {
                return " ";
            }
        },
        bgMod() {
            if (this.background) {
                return `background:${this.background};border-radius:1.2rem;padding:unset;padding:0.8rem 1rem; margin:.3em;white-space:pre-wrap;width:unset;`;
            } else if (this.small) {
                return "padding:unset;margin:unset;white-space:pre-wrap;width:unset;";
            } else {
                return "padding:unset;margin:unset;white-space:pre-wrap;width:unset;";
            }
        },
    },
};
</script>

<style lang="scss">
// ... (keep the existing styles unchanged)

.transition-colors {
    animation: gradientAnimation 5s linear infinite;
    background-size: 400% 400%;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>
