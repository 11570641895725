<template>
    <div>
        <div class="my-3">
            <!--            Locations:-->
            <BaseInput v-show="false" id="kd" :modelValue="address" label="Search places" placeholder="Search for places" @update:modelValue="address = $event" @keydown.enter.prevent="getLatLngFromAddress()" />
            <!--            <BaseButton label="set place" size="md" style-type="secondary" @click="getLatLngFromAddress()"></BaseButton>-->
            <!--            {{ location }}-->
        </div>
        <!--        <div class="p-3 bg-snow shadow br-10 f x items-stretch">-->
        <div class="f fc relative x mw-400">
            <a :href="generateMapsLink(false)" target="_blank" class="d-block x" style="text-decoration-color: none">
                <div class="inline-flex w-auto br-10 overflow-hidden shadow map_card">
                    <PlacesCard class="bg-white relative f fc text-gray-900 x mw-200 p-relative block cursor-pointer" :place="place">
                        <div class="block x relative overflow-hidden z-0" style="min-height: 200px; height: 200px !important">
                            <img class="x y o-cover p-absolute p-fill" style="border-radius: 0px !important" v-if="place && place.photos && place.photos.length > 0" :src="getPhotoUrl(place.photos[0], 200, 200)" alt="place" />
                        </div>
                    </PlacesCard>
                    <!--                <div class="relative x f" v-if="!hideMap">-->
                    <GMapMap
                        ref="map"
                        :center="center"
                        :disableDefaultUI="true"
                        :options="{ scrollWheel: false, zoomControl: false, mapTypeControl: false, scaleControl: false, streetViewControl: false, rotateControl: false, fullscreenControl: false, disableDefaultUi: false }"
                        :zoom="zoom"
                        map-type-id="terrain"
                        class="x"
                        style="width: 200px !important; max-width: 200px; height: 100% !important">
                        <!--                <GMapPolyline ref="polyline" :editable="true" :path="path" />-->
                        <GMapMarker v-for="marker in markers" :key="marker.id" :position="marker.position"></GMapMarker>
                        <!--                <GMapAutocomplete placeholder="Los Angeles, CA" @place_changed="setPlace" @enter.prevent="setPlace"></GMapAutocomplete>-->
                    </GMapMap>
                    <!--                </div>-->
                </div>
            </a>
            <div class="f jcc mt-3">
                <BaseButton label="View on Google" icon="far fa-map" rounded size="sm" style-type="secondary" @click="generateMapsLink(false)"></BaseButton>
            </div>
        </div>
    </div>
</template>

<script type="module">
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import PlacesCard from "@/components/chat/specialMessages/PlacesCard.vue";

export default {
    name: "GoogleMapsCard",
    components: { PlacesCard, BaseButton, BaseInput },
    mounted() {
        this.$nextTick(() => {
            this.address = this.query || this.message.content;
            if (!this.hideMap) {
                this.$refs.map.$mapPromise.then(mapObject => {
                    console.log("Map is fully loaded:", mapObject);
                    // this.place.url =  mapObject.rmiUrl;
                    this.place.url = mapObject.mapUrl;
                    this.getLatLngFromAddress(mapObject);
                    this.$refs.map.scrollWheel = false;
                });
            } else {
                this.getLatLngFromAddress();
            }
        });
    },
    data() {
        return {
            address: "1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA",
            location: null,
            placeSearch: null,
            zoom: 10,
            apiKey: "AIzaSyCNjPznz2wmaQ3A3cokggfx4LhWgrkS8kE",
            markers: [{ position: { lng: 6.84212, lat: 51.093048 } }],
            center: { lat: 51.093048, lng: 6.84212 },
            path: [
                { lat: 51.093048, lng: 6.84212 },
                { lat: 1.43, lng: 103.85 },
            ],
            place: {},
        };
    },
    props: {
        message: {
            type: Object,
            default: null,
        },
        query: {
            type: String,
            default: null,
        },
        hideMap: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getPhotoUrl(photo, maxWidth, maxHeight) {
            const options = {
                maxWidth,
                maxHeight,
            };
            try {
                const url = photo.getUrl(options);
                this.place.image = url;
                return url;
            } catch (error) {
                console.error("image didn't work", error);
            }
        },
        async createService(mapObject) {
            return new Promise((resolve, reject) => {
                if (!mapObject) {
                    const tempMap = new google.maps.Map(document.createElement("div"));
                    resolve(new google.maps.places.PlacesService(tempMap));
                    setTimeout(() => {
                        this.place.url = mapObject.mapUrl;
                    }, 1000);
                } else {
                    resolve(new google.maps.places.PlacesService(mapObject));
                }
            });
        },
        async getLatLngFromAddress(mapObject, useGetDetails = true) {
            const service = await this.createService(mapObject);
            const request = {
                query: this.address,
            };

            this.processTextSearch(service, request, async results => {
                const place = results[0];

                if (useGetDetails) {
                    const placeId = place.place_id;
                    await this.processGetDetails(service, placeId);
                } else {
                    this.handleTextSearchResult(place);
                }
            });
        },
        processTextSearch(service, request, processResponse) {
            service.textSearch(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    processResponse(results);
                } else {
                    console.error("Request failed with status", status);
                }
            });
        },
        async processGetDetails(service, placeId) {
            const detailsRequest = {
                placeId: placeId,
                fields: [
                    "place_id",
                    "formatted_address",
                    "geometry",
                    "name",
                    "reference",
                    "types",
                    "user_ratings_total",
                    "photos",
                    "rating",
                    "icon_background_color",
                    // "icon",
                    // "business_status",
                    // "icon_mask_base_uri",
                    // "plus_code",
                    // "html_attributions",
                ],
            };

            service.getDetails(detailsRequest, (details, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    this.handleDetailsResult(details);
                } else {
                    console.error("Request failed with status", status);
                }
            });
        },
        handleTextSearchResult(place) {
            this.handlePlaceResult(place, false);
        },

        generateMapsLink(showMarkers = true) {
            const zoom = this.zoom;
            const centerLatitude = this.center.lat;
            const centerLongitude = this.center.lng;

            let markersURL = "";
            if (showMarkers && this.markers && this.markers.length > 0) {
                this.markers.forEach((marker, index) => {
                    const color = "blue"; // Set the desired color for the marker
                    const label = String.fromCharCode(65 + index); // ASCII codes 'A', 'B', ...
                    const latitude = marker.position.lat;
                    const longitude = marker.position.lng;

                    markersURL += `&markers=color:${color}%7Clabel:${label}%7C${latitude},${longitude}`;
                });
            }

            return `https://www.google.com/maps?q=&layer=c&z=${zoom}&iwloc=A&sll=${centerLatitude},${centerLongitude}${markersURL}`;
        },
        handleDetailsResult(details) {
            this.handlePlaceResult(details, true);
        },

        handlePlaceResult(place, useGetDetails) {
            const photo = place.photos && place.photos[0] ? place.photos[0] : place;
            if (photo) {
                const maxWidth = 400;
                const maxHeight = 300;
            } else {
                console.warn("No photos found for this place.");
            }

            this.place = { ...this.place, ...place };
            this.place.id = place.reference;
            try {
                // this.place.opening_hours = place.opening_hours.isOpen();
                this.place.open_now = place.opening_hours.open_now;
            } catch (error) {
                console.error("error", error.message);
            }
            // this.center = this.place.geometry.location;
            this.setPlace(this.place);
            this.zoom = 15;

            // console.log("Processed place:", place);
        },

        setPlace(event) {
            let latLong = { lat: event.geometry.location.lat(), lng: event.geometry.location.lng() };
            this.center = latLong;
            let marker = {
                position: latLong,
            };
            this.markers.push(marker);
            this.path.push(latLong);
            console.log(latLong);
        },
    },
};
</script>
<style scoped lang="scss">
.map_card{
  @apply hover:shadow-lg transition relative;
  //@apply transition transition-all hover:transition-all transition-transform hover:transition-transform bottom-0.5 hover:bottom-2 animate__animated hover:animate__animated duration-500 ease-back hover:ease-back transition-all;
  @apply transition-all ease-back duration-500;
@apply hover:bottom-2 hover:transform hover:scale-110;
}
</style>
