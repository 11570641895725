<template>
    <div class="x">
        <div v-for="(product, index) in productsToShow" :key="product.handle">
            <EmailProductRow :index="index" :product="product" />
        </div>
    </div>
</template>
<!--<template>-->
<!--    <div class="x">-->
<!--        <div v-for="(product, index) in productsToShow">-->
<!--            <EmailProductRow :index="index" :ref="product" :key="product.handle" :product="product" />-->
<!--            &lt;!&ndash;            <div>&ndash;&gt;-->
<!--            &lt;!&ndash;                <img class="aspect-square o-cover x" v-if="product.imageIndex" :src="images[product.imageIndex].url" alt="product.name" />&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <h4 v-if="product?.name" class="mt-2">{{ product.name }}</h4>&ndash;&gt;-->
<!--            &lt;!&ndash;            <SectionButton v-if="product.button" key="bottombutton" :button-text="product?.button?.text" css="" default-size="15" identifier="" image-color="" :index="0" section="" class="z-0" @save-section="" />&ndash;&gt;-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->
<script>
import EmailProductRow from "@/components/generative_templates/Email/EmailProductRow.vue";

export default {
    name: "EmailSectionProductsVertical",
    components: { EmailProductRow },
    props: {
        products: {},
        images: {},
    },
    computed: {
        // map getters from styleGuide/image/images
        productsToShow() {
            let products = this.products.filter(product => product?.handle);
            products = products.filter(p => p?.price && p.price !== "0.00");
            return products;
        },
    },
};
</script>
