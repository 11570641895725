<template>
    <div>
        <!--        <StyleGuideContainer :title="`Typography`" class="overflow-hidden" style="max-width: 100vw" section="updateTextStyles">-->
        <!--      s 6 vs ${styleGuide?.deduplicatedText?.length}-->
        <UIWrapperText title="Typography">
            <div class="px-0" v-if="typeStyles">
                <div class="my-4 f aic jcb gap-5 border-b-brand mb-3 pb-3" v-for="item in ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']">
                    <div>
                        <Component :is="'span'" class="mb-0 pb-0">
                            <span class="line-clamp-1" :style="applyStyle(typeStyles[item])">{{ headerSample || "The Quick Brown Fox" }}</span>
                        </Component>
                        <TextStyleDetails v-if="typeStyles[item]" :style="typeStyles[item]" class="mt-3" />
                        <template v-else><span class="monospaced f-11 o-5">No style found</span></template>
                    </div>
                    <div>
                        <span class="text-uppercase mb-3 inline-block border-brand-1 px-2 rounded py-1 bg-brand linkColor c-40 f aic jcc" style="font-family: Helvetica !important; font-size: 13px; letter-spacing: 0rem !important; font-weight: 700">{{ item.toUpperCase() }}</span>
                    </div>
                </div>
            </div>
            <UICollapsableSection>
                <template #title>Style Sheet</template>
                <StyleSheetSection @set-as-style="setAsStyle" :style-sheet="styleSheet" />
            </UICollapsableSection>
        </UIWrapperText>
        <debug-object :object="typography"></debug-object>
        <div class="col-span-12">
            <!--            <DynamicObjectViewer :data-object="typography"></DynamicObjectViewer>-->
        </div>
        <!--                </StyleGuideContainer>-->
        <!--            allTextStyles - array flat-->
        <!--            assignedStyles - object > array > object-->
        <!--            headerStyles - object > array > object-->
        <!--            pStyles -  array-->

        <UIWrapperText section="starter1" title="Typography" v-if="false">
            <div class="container">
                <div class="p-3 bg-gray-100 border-light br-10 mb-3">
                    <h1>Headers Styles</h1>
                    <template v-for="(style, key) in headersStyles">
                        <template v-if="key === 'all'">
                            <div v-if="style.length" class="mb-4 border-top pt-4">
                                <span class="mb-3 block" style="font-size: 20px; text-transform: uppercase; font-weight: bold">{{ key }} Styles</span>
                                <TextStylesArray class="" :styles="style" />
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="style.length" class="mb-4 border-top pt-4">
                                <span class="mb-3 block" style="font-size: 20px; text-transform: uppercase; font-weight: bold">{{ key }} Styles</span>
                                <TextStylesArray class="" :styles="style" />
                            </div>
                        </template>
                    </template>
                </div>
            </div>
            <div class="container">
                <div class="p-3 bg-gray-100 border-light br-10 mb-3">
                    <h1>Heady</h1>
                    <template v-for="(style, key) in heady">
                        <span class="mb-3 block" style="font-size: 20px; text-transform: uppercase; font-weight: bold">{{ key }} Styles</span>

                        <div class="mb-5" v-for="s in style">
                            <Component :is="s.tag" :style="s" class="text-red">
                                {{ s.text || "The Quick Brown Fox" }}
                            </Component>
                            <TextStyleDetails v-if="s" :style="s" class="mt-3" />
                        </div>
                    </template>
                </div>
            </div>
            <div class="container">
                <h1>Paragraphs</h1>
                <h1>P Styles</h1>
                <TextStylesArray class="p-3 bg-gray-100 border-light br-10 mb-3" :styles="styleGuide.pStyles" />
            </div>
            <div class="container">
                <h1>Paragraph Styles</h1>
                <TextStylesArray class="p-3 bg-gray-100 border-light br-10 mb-3" :styles="styleGuide.paragraphStyles" />
            </div>
            <div class="container">
                <h1>Typography</h1>
                <!--                    <h1>Deduplicated Styles</h1>-->
                <TextStylesArray class="p-3 bg-gray-100 border-light br-10 mb-3" :styles="styleGuide.deduplicatedText" />
            </div>
            <div class="container">
                <h1>All Styles</h1>
                <TextStylesArray class="p-3 bg-gray-100 border-light br-10 mb-3" :styles="allStyles" />
            </div>
        </UIWrapperText>
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import TextStyleDetails from "@/components/styleGuide/Typography/TextStyleDetails.vue";
import TextStylesArray from "@/components/styleGuide/Typography/TextStylesArray.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";
import StyleSheetSection from "@/components/styleGuide/Typography/StyleSheetSection.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";

export default {
    components: { UICollapsableSection, CollapsableMenuItem, StyleSheetSection, UIWrapperText, TextStylesArray, TextStyleDetails },
    mixins: [styleGuideMixin],
    name: "AssignedStyleDisplay",
    computed: {
        styleSheet() {
            try {
                if (this.styleGuide?.stylesheet) {
                    // sort by fontSize
                    let styles = this.styleGuide.stylesheet;
                    styles = styles.sort((a, b) => parseFloat(b.fontSize) - parseFloat(a.fontSize));
                    styles = styles.map((style, index) => {
                        // assign text randomly
                        let texts = style.texts;
                        style.text = texts[Math.floor(Math.random() * texts.length)];
                        return style;
                    });
                    // sort by count
                    //   let styles = this.styleGuide.stylesheet;
                    //   styles = styles.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
                    return this.styleGuide.stylesheet;
                }
            } catch (e) {
                console.log(e);
            }
            return [];
        },
        headersStyles() {
            if (this.styleGuide?.headersStyles) {
                const { all = [], h1 = [], h2 = [], h3 = [], h4 = [], h5 = [], h6 = [], p = [], paragraphs = [] } = this.styleGuide.headersStyles;
                return { all, h1, h2, h3, h4, h5, h6, p, paragraphs };
            }
            return {};
        },
        heady() {
            if (this.styleGuide?.typography?.heady) {
                const { all = [], h1 = [], h2 = [], h3, h4 = [], h5 = [], h6 = [], p = [], paragraph = [] } = this.styleGuide?.typography?.heady;
                return { all, h1, h2, h3, h4, h5, h6, p, paragraph };
            }
            return {};
        },
        allStyles() {
            return this.styleGuide?.allTextStyles;
        },
        typeStyles() {
            return this.styleGuide?.assignedStyles;
        },
    },
    methods: {
        setAsStyle(style, type) {
            // if (type.includes("h")) {
            //     type = "h1";
            let cleanStyle = deepCopy(style);
            delete cleanStyle.texts;
            delete cleanStyle.tags;
            delete cleanStyle.text;
            delete cleanStyle.count;
            // delete cleanStyle.fontSize;
            // delete cleanStyle.lineHeight;
            // delete cleanStyle.letterSpacing;
            // delete cleanStyle.fontFamily;
            // delete cleanStyle.fontWeight;
            // let baseSize = 16;
            // let size = parseInt(cleanStyle.fontSize);
            // let scale = baseSize * size;
            // cleanStyle.fontSize = `${scale}px`;
            // if (!this.$store.state.styleGuide.styleGuide.assignedStyles[type]) {
            // }
            this.updateStyleGuideProp(`assignedStyles.${type}`, cleanStyle, true);
            // }
            // this.updateStyleGuideProp("assignedStyles", style, type);
        },
    },
    props: {
        // styleGuide: {},
        // textSamples: {},
    },
};
</script>
