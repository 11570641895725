import { mapGetters } from "vuex";
export default {
    mixins: [],
    data() {
        return {};
    },
    computed: {
        ...mapGetters("styleGuide", ["likelyHeaderCombo"]),
        paragraphSample() {
            let textSamples = this.$store.state.styleGuide.styleGuide.textSamples;
            if (textSamples && textSamples.paragraphs && textSamples.paragraphs.length > 0) {
                let paragraphs = textSamples.paragraphs.filter(paragraph => paragraph.length > 20 && paragraph.length < 200);
                return paragraphs[Math.floor(Math.random() * paragraphs.length)];
            } else {
                return "The quick brown fox jumps over the lazy dog.";
            }
        },
        comboHeader() {
            // return this.likelyHeaderCombo?.header || this.headerAttributes.style;
            return this.mostCommonCombo.header || this.likelyHeaderCombo?.header || this.mostCommonCombo.header;
        },
        comboParagraph() {
            // return this.likelyHeaderCombo?.p || this.paragraphAttributes.style;
            return this.mostCommonCombo?.p || this.likelyHeaderCombo?.p || this.mostCommonCombo?.p;
        },

        mostCommonCombo() {
            const defaultResponse = { header: {}, p: {} };

            // Check if this.styleGuide and this.styleGuide.typographyCombos are both valid and not empty
            if (this.styleGuide?.typographyCombos && this.styleGuide.typographyCombos.length > 0) {
                const combos = this.styleGuide.typographyCombos;

                // Sort by 'count' in descending order
                const sortedCombos = combos.sort((a, b) => {
                    const aCount = parseInt(a.count, 10);
                    const bCount = parseInt(b.count, 10);
                    return isNaN(bCount) - isNaN(aCount) || bCount - aCount;
                });

                // Filter and map the combos
                const filteredSortedCombos = sortedCombos
                    .filter(combo => {
                        return combo?.style?.header?.fontSize && combo?.style?.p?.fontSize;
                    })
                    .map(combo => {
                        const headerFontSize = parseInt(combo.style.header.fontSize, 10);
                        const pFontSize = parseInt(combo.style.p.fontSize, 10);
                        return headerFontSize > pFontSize * 1.68
                            ? {
                                  // header: combo.style.header,
                                  // p: combo.style.p,
                                  header: { ...combo.style.header, fontFamily: this.modifyFontFamily(combo.style.header.fontFamily) },
                                  p: { ...combo.style.p, fontFamily: this.modifyFontFamily(combo.style.p.fontFamily) },
                              }
                            : null;
                    })
                    .filter(Boolean); // Remove any null values
                if (filteredSortedCombos?.length > 0) {
                    // logGroup("Most Common Combo", { filteredSortedCombos });

                    return filteredSortedCombos.length > 0 ? filteredSortedCombos[0] : defaultResponse;
                }
            }
            let alternative = this.likelyHeaderCombo;
            // logGroup("Default response", { defaultResponse, alternative });

            return this.likelyHeaderCombo || defaultResponse;
        },

        headerSample() {
            // If there are H1 header samples available...
            try {
                let textSamples = this.$store.state.styleGuide.styleGuide.textSamples;
                if (textSamples && textSamples.headers.h1 && textSamples.headers.h1.length > 0) {
                    // Combine H1, H2, and H3 header samples into one array.
                    let allHeaderSamples = [...textSamples?.headers?.h1, ...textSamples?.headers?.h2, ...textSamples?.headers.h3];

                    // Filter out header samples that have too few or too many words.
                    let filteredHeaders = allHeaderSamples.filter(headerText => {
                        let wordCount = headerText.split(" ").length; // split by spaces to get an array of words
                        return wordCount > 3 && wordCount < 10; // adjust these numbers as per your needs
                    });

                    // Return a random header sample from the filtered list.
                    return filteredHeaders[Math.floor(Math.random() * filteredHeaders.length)];
                } else {
                    // If there are no H1 samples, return an empty string.
                    return "The quick brown fox jumps over the lazy dog.";
                }
            } catch (error) {}
        },
        randomButtonText() {
            let ButtonStrings = ["Learn more", "Shop now", "Let's get started", "Shop the set"];
            const randomIndex = Math.floor(Math.random() * ButtonStrings.length);
            return ButtonStrings[randomIndex];
            //  pull random button text from textStyles.buttons array
            if (this.textSamples.buttons) {
                let styles = [...this.textSamples.buttons];
                let twoWords = styles.filter(style => style.split(" ").length <= 5 && style.split(" ").length >= 2);
                if (twoWords?.length === 0) {
                    styles = [...this.textSamples.buttons, ...this.textSamples.links];
                }
                twoWords = styles.filter(style => style.split(" ").length <= 5 && style.split(" ").length >= 2);
                // filter styles by strings with two words

                // filter styles by strings with three words
                const randomIndex = Math.floor(Math.random() * twoWords.length);
                return twoWords[randomIndex];
            }
        },
        randomParagraphStyle() {
            // filter all text styles where the font is between 16 and 24 pixels
            const styles = this.allTextStyles.filter(style => parseFloat(style.fontSize) >= 0.9 && parseFloat(style.fontSize) <= 2);

            // If styles are found within the range, return a random one
            if (styles.length) {
                const randomIndex = Math.floor(Math.random() * styles.length);
                let style = styles[randomIndex];
                // if (this.headerImage && this.headerImage.seamlessBg) {
                //     style.color = `${this.headerImage.colors[1]}`;
                // }
                return style;
            } else {
                return null;
            }
        },
        randomDisplayTextStyle() {
            if (!this.styleGuide?.allTextStyles) return false;
            // filter all text styles where the font is between 34 and 50 pixels
            const styles = this.styleGuide.allTextStyles.filter(style => parseFloat(style.fontSize) >= 2 && parseFloat(style.fontSize) <= 10 && style.fontFamily);

            // If styles are found within the range, return a random one
            if (styles.length) {
                const randomIndex = Math.floor(Math.random() * styles.length);
                let style = styles[randomIndex];
                // if (this.headerImage && this.headerImage.seamlessBg) {
                //     style.color = `${this.headerImage.colors[1]}`;
                // }
                return style;
            } else {
                return null;
            }
        },
        randomTextStyle() {
            // let textStyles = this.$store.getters["styleGuide/dedupedTextStyles"];
            // let textStyles = this.$store.getters["styleGuide/allTextStyles"];
            let textStyles = this.$store.getters["styleGuide/deduplicatedText"];
            return textStyles[Math.floor(Math.random() * textStyles.length)];
            // return this.allTextStyles[Math.floor(Math.random() * this.allTextStyles.length)];
        },
    },
    methods: {
        modifyFontFamily(fontFamily) {
            // Check if fontFamily is a string and not empty
            if (typeof fontFamily === "string" && fontFamily.trim() !== "") {
                const fontFamilyParts = fontFamily.split(","); // Split by comma
                const primaryFont = fontFamilyParts[0]; // The primary font
                try {
                    //TODO: Come back to this. Was causing VACATIONs fonts to not load.
                    // if (primaryFont.includes("-")) {
                    //     const modifiedPrimaryFont = primaryFont.split("-")[0]; // Modify primary font
                    //     let newFont = `${primaryFont}, ${modifiedPrimaryFont}, ${fontFamilyParts.slice(1).join(",")}`;
                    //     // if it ends with a comma remove it
                    //     if (newFont.trim().endsWith(",")) {
                    //         newFont = newFont.slice(0, -1);
                    //     }
                    //     return newFont;
                    // }
                    // if (fontFamily.trim().endsWith(",")) {
                    //     fontFamily = fontFamily.slice(0, -1);
                    // }
                } catch (e) {
                    return fontFamily;
                }
                return fontFamily; // Return original if no modification is needed
            }

            // Return a default or unmodified value if fontFamily is not a string or is empty
            return fontFamily || "sans-serif";
        },
        setupHeaderText() {
            let minHeader = 2;
            let maxHeader = 7;
            let minParagraph = 5;
            let maxParagraph = 30;
            if (this.mobile) {
                minHeader = 2;
                maxHeader = 7;
                minParagraph = 5;
                maxParagraph = 30;
            }
            this.hText = this.textSample(minHeader, maxHeader);
            this.pText = this.textSample(minParagraph, maxParagraph);
            return;
        },
        rankResultsByKey() {
            // loop over keys
            Object.keys(this.textStyles).forEach(key => {
                return this.rankResults(this.textStyles[key], true);
            });
            return this.textStyles;
        },
        textSample(minLength = 3, maxLength = 10) {
            try {
                // If there are H1 header samples available...
                let textSamples = this.textSamples;
                // Combine H1, H2, and H3 header samples into one array.
                // let allHeaderSamples = [...textSamples.headers.h1, ...textSamples.headers.h2, ...textSamples.headers.h3];
                let allTextSamples;
                // flatten text samples
                // let allTextSamples = [textSamples.headers.h1, textSamples.headers.h2, textSamples.headers.h3, textSamples.headers.h4, textSamples.headers.h5, textSamples.paragraphs, textSamples.spans, textSamples.links, textSamples.buttons];
                let flatTextSamples = [];
                if (textSamples.h1) flatTextSamples.push(...textSamples.h1);
                if (textSamples.h2) flatTextSamples.push(...textSamples.h2);
                if (textSamples.h3) flatTextSamples.push(...textSamples.h3);
                if (textSamples.h4) flatTextSamples.push(...textSamples.h4);
                if (textSamples.h5) flatTextSamples.push(...textSamples.h5);
                if (textSamples.h6) flatTextSamples.push(...textSamples.h6);
                if (textSamples.emailSubjects) flatTextSamples.push(...textSamples.emailSubjects);
                if (textSamples.emailPreviews) flatTextSamples.push(...textSamples.emailPreviews);
                if (textSamples.paragraphs) flatTextSamples.push(...textSamples.paragraphs);
                // Filter out header samples that have too few or too many words.
                let filteredHeaders = flatTextSamples.filter(headerText => {
                    let wordCount = headerText.split(" ").length; // split by spaces to get an array of words
                    return wordCount > minLength && wordCount < maxLength; // adjust these numbers as per your needs
                });

                // Return a random header sample from the filtered list.
                return filteredHeaders[Math.floor(Math.random() * filteredHeaders.length)];
            } catch (error) {
                return "The quick brown fox jumps over";
                console.log(error.message);
            }
        },
        dynamicTextSample(fontSize) {
            // Validate fontSize input
            if (typeof fontSize !== "number" && typeof fontSize !== "string") {
                return "Invalid font size";
            }

            // Convert fontSize to float if it's a string
            if (typeof fontSize === "string") {
                fontSize = parseFloat(fontSize);
            }

            // Check if conversion was successful
            if (isNaN(fontSize)) {
                return "Invalid font size";
            }

            let textSample;

            // Dynamically set text length based on font size in ems
            // Arguments for this.textSample are minWordCount, maxWordCount
            if (fontSize <= 1) {
                textSample = this.textSample(20, 30); // Suitable for paragraphs
            } else if (fontSize > 1 && fontSize <= 1.5) {
                textSample = this.textSample(20, 30); // Suitable for paragraphs
            } else if (fontSize > 1.5 && fontSize <= 2) {
                textSample = this.textSample(10, 30); // Also paragraph, slightly fewer words
            } else if (fontSize > 2 && fontSize <= 3) {
                textSample = this.textSample(5, 7); // Sub-headers, fewer words
            } else if (fontSize > 3 && fontSize <= 4) {
                textSample = this.textSample(5, 10); // Main headers, even fewer words
            } else if (fontSize > 4 && fontSize <= 6) {
                textSample = this.textSample(2, 5); // Large headers, very few words
            } else if (fontSize > 6 && fontSize <= 8) {
                textSample = this.textSample(3, 5); // Extremely large text, one or few words
            }

            // Fallback text sample
            return textSample || "The quick brown fox";
        },
        randomTextStyleMethod() {
            let textStyles = this.$store.getters["styleGuide/allTextStyles"];
            return textStyles[Math.floor(Math.random() * textStyles.length)];
            // return this.allTextStyles[Math.floor(Math.random() * this.allTextStyles.length)];
        },
    },
};
