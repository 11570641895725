<!--MANAGE TEAM-->
<template>
    <div class="">
        <h4>Team members</h4>
        <ul class="mw-500">
            <AdminTeamMember v-for="member in team" :key="member.id" :member="member" :roles="roles" @update-role="updateRole" />
            <BaseButton label="Invite to team" style-type="secondary" class="mt-3" @click="openInviteDialog = true" />
        </ul>

        <!-- Invite Dialog -->
        <InviteDialog :open="openInviteDialog" :roles="roles" @add-member="addTeamMember" @close-dialog="openInviteDialog = false" />
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import AdminTeamMember from "@/components/Admin/AdminTeamMember.vue";
import roles from "@/views/roles";
import InviteDialog from "@/components/Admin/InviteDialog.vue";

export default {
    name: "AdminManageTeam",
    components: { InviteDialog, AdminTeamMember, BaseButton },
    methods: {
        updateRole(memberId, newRole) {
            const memberToUpdate = this.team.find(member => member.id === memberId);
            if (memberToUpdate) {
                memberToUpdate.role = newRole;
            }
        },
        addTeamMember(newMember) {
            this.team.push(newMember);
            this.openInviteDialog = false;
        },
    },
    created() {},
    data() {
        return {
            openInviteDialog: false,
            newMember: {
                id: null,
                name: "",
                email: "",
                role: "viewer",
            },
            roles,
            team: [
                { id: 1, name: "Asher Hunt", role: "admin", email: "asherhunt@gmail.com", phone: "555-555-5555", permissions: ["admin", "user"], is_active: true },
                { id: 2, name: "Diego Prats", role: "admin", email: "diegoprats@gmail.com", phone: "555-555-5555", permissions: ["admin", "user"], is_active: true },
            ],
        };
    },
};
</script>
<style scoped>
/* Add your SCSS or Tailwind CSS classes here */
.dialog {
    /* Define your dialog styling */
}
</style>
