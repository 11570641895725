function deduplicateUrls(urls = {}, website) {
    let finalUrls = [];

    if (typeof urls === "string") {
        finalUrls.push(urls);
    } else if (Array.isArray(urls)) {
        finalUrls = [...finalUrls, ...urls];
    } else if (typeof urls === "object") {
        for (let key in urls) {
            if (urls.hasOwnProperty(key)) {
                if (Array.isArray(urls[key])) {
                    finalUrls = [...finalUrls, ...urls[key]];
                } else {
                    finalUrls.push(urls[key]);
                }
            }
        }
    }

    if (website) {
        finalUrls = [website, ...finalUrls];
    }

    // make urls a set to remove duplicates
    finalUrls = new Set([...finalUrls]);
    finalUrls = Array.from(finalUrls);
    return finalUrls.slice(0, 30);
}
export default deduplicateUrls;
