<template>
    <div class="f aic jcc fc">
        <div class="mx-auto" id="search_icon"><i class="far fa-search"></i></div>
        <div ref="textContainer"></div>
    </div>
</template>

<script>
import { gsap } from "gsap";

export default {
    name: "StringLoader",
    props: {
        text: {
            type: [Array, String],
            default: () => ["Loading..."],
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    mounted() {
        // this.startAnimation();
        this.startSearchAnimation();
    },
    methods: {
        startSearchAnimation() {
            const stl = gsap.timeline({ repeat: -1, duration: 5, ease: "sine.inOut" });
            gsap.set("#search_icon", { x: 0, y: 0, duration: 0.1, ease: "sine.inOut" });
            stl.to("#search_icon", { x: 0, y: 0, duration: 0.1, ease: "sine.inOut" });
            stl.to("#search_icon", { x: -5, y: -2.5, duration: 0.1, ease: "sine.inOut" });
            stl.to("#search_icon", { x: 0, y: -5, duration: 0.1, ease: "sine.inOut" });
            stl.to("#search_icon", { x: 5, y: -2.5, duration: 0.1, ease: "sine.inOut" });
            stl.to("#search_icon", { x: 0, y: 0, duration: 0.1, ease: "sine.inOut" });
            stl.to("#search_icon", { x: -5, y: 2.5, duration: 0.1, ease: "sine.inOut" });
            stl.to("#search_icon", { x: 0, y: 5, duration: 0.1, ease: "sine.inOut" });
            stl.to("#search_icon", { x: 5, y: 2.5, duration: 0.2, ease: "sine.inOut" });
            stl.to("#search_icon", { x: 0, y: 0, duration: 0.1, ease: "sine.inOut" });
        },
        startAnimation() {
            const textArray = Array.isArray(this.text) ? this.text : [this.text];
            const duration = 1;

            gsap.set(this.$refs.textContainer, { text: textArray[this.currentIndex], opacity: 0, y: 2, ease: "back.out" });

            const tl = gsap.timeline({ repeat: -1 });

            tl.fromTo(this.$refs.textContainer, { y: 2, opacity: 0 }, { y: 0, opacity: 1, duration: duration / 8 });
            tl.to(this.$refs.textContainer, {
                duration: duration,
                repeat: -1,
            });

            tl.to(this.$refs.textContainer, { y: 2, opacity: 0, duration: 0.2, delay: 0.7 }, "<=");

            tl.to(this.$refs.textContainer, {
                duration: duration / 2,
                repeat: -1,
            });
            tl.to(this.$refs.textContainer, {
                onComplete: () => {
                    this.startAnimation();
                    this.currentIndex = (this.currentIndex + 1) % textArray.length;
                },
            });
        },
    },
};
</script>

<style scoped></style>
