<template>
    <div v-if="showProduct" :key="product.handle" class="relative">
        <div class="shadow card border-light br-10 overflow-hidden relative f height-500">
            <a :href="product?.url" :style="`color:'inherit'!important;background:${matchingColorBackground}`" class="mx-auto x p-0" target="_blank">
                <div class="x relative" :class="{ 'z-1': transparent, '!mt-0 z-0': !transparent }" @click.prevent="productClicked(product)">
                    <div v-if="matchingImage" :style="`background:${matchingImageColor}`" class="x overflow-hidden y" :class="{ ' f aic jcc': transparent }">
                        <div class="height-min-300 z-0">
                            <img :class="{ 'z-10 o-contain my-5 mt-auto height-300 x': transparent, 'o-cover y': !transparent }" :src="`${matchingImage.url}`" :style="`background:${matchingImageColor}`" alt="..." class="x y height-min-500" />
                        </div>
                        <div ref="bottomGradient" class="x y bottom p-absolute z-5 height-300" :style="`background: linear-gradient(180deg,rgba(255,255,255,0) 50%, ${matchingImageColorBottom} 100%)`"></div>
                    </div>
                </div>
                <div ref="topGradient" v-if="!transparent && !matchingImage?.seamlessBg" class="z-4 top left right p-absolute o-8" :style="`height:20%;background: linear-gradient(180deg,${matchingImageColortop} 50%, rgba(255,255,255,0) 100%)`"></div>
                <div class="px-4 pt-4 pb-8 p-absolute top left right z-5 w-90 line-clamp-2">
                    <h2 v-if="product?.title" :style="`color:${topTextColor}!important;`" class="link-color fwb f-40 leading-normal !text-balance">{{ product.title }}</h2>
                </div>
                <div class="card-body absolute bottom left right pb-3 px-4 text-left x link-color z-10" :style="`color:${bottomTextColor}!important;`">
                    <div class="f-11 o-5" v-if="rating">
                        <ProductReviews :rating="rating" />
                    </div>
                    <p v-if="product?.description" class="f-17 card-text text-pretty w-80 line-clamp-2">{{ product.description }}</p>
                    <p v-if="product?.price" class="o-5 card-text text-xs line-clamp-1">${{ product.price }}</p>
                </div>
            </a>
            <div class="p-absolute top right p-2 z-5 o-0 hover:opacity-100">
                <BaseButton icon="fa fa-times" icon-only rounded size="xxs" style-type="secondary" @click.prevent="removeProduct(product)"></BaseButton>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
import ProductReviews from "@/views/ProductReviews.vue";
import { mapGetters } from "vuex";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import resizeImage from "@/mixins/StyleGuideMixins/imageResizer";

export default {
    name: "StyleGuideProductCard",
    components: { ProductReviews, BaseButton },
    mixins: [styleGuideMixin],
    computed: {
        showProduct() {
            return this.product && this.product.title && !this.product.title.includes("hidden") && !this.product.title.includes("hide");
        },
        ...mapGetters("styleGuide", ["products", "styleGuideId"]),
        ...mapGetters("styleGuide/image", ["images"]),
        transparent() {
            return this.matchingImage?.transparent || false;
        },
        textColor() {
            let matchingImageColors = this.matchingImage?.colors || [];
            return this.getContrastColor(this.matchingImageColor, matchingImageColors);
        },
        topTextColor() {
            let matchingImageColors = this.matchingImage?.colors || [];
            return this.getContrastColor(this.matchingImageColortop, matchingImageColors);
        },
        bottomTextColor() {
            let matchingImageColors = this.matchingImage?.colors || [];
            return this.getContrastColor(this.matchingImageColorBottom, matchingImageColors);
        },
        rating() {
            if (this.product.reviews) return this.product.reviews;
            if (this.product.reviewCount || this.product.ratingCount) {
                return {
                    rating: this.product?.rating || this.product?.averageRating,
                    reviewCount: this.product?.reviewCount || this.product?.ratingCount,
                };
            }
            return { rating: this.product?.rating || "" };
        },
        matchingImageColor() {
            let matchingImage = this.matchingImage;
            return matchingImage?.primary || matchingImage?.backgroundColor || matchingImage?.bottomColor || "#fff";
        },
        matchingImageColortop() {
            let matchingImage = this.matchingImage;
            return matchingImage?.topColor || matchingImage?.backgroundColor || matchingImage?.topColor || "#fff";
        },
        matchingImageColorBottom() {
            let matchingImage = this.matchingImage;
            return matchingImage?.bottomColor || matchingImage?.backgroundColor || matchingImage?.bottomColor || "#fff";
        },
        matchingColorBackground() {
            let matchingImage = this.matchingImage;
            return matchingImage?.backgroundColor || "#fff";
        },
        productImages() {
            let { images } = this.product;
            // match the image to images array
            let imageUrls = images ? images.map(img => img.url) : [];
            let matchedImages = this.images ? this.images.filter(img => imageUrls.includes(img.originalURL)) : [];
            // sort matched images by entropy (image.entropy.entropy)
            matchedImages.sort((a, b) => {
                let entropyA = a.entropy && a.entropy.entropy ? a.entropy.entropy : 0;
                let entropyB = b.entropy && b.entropy.entropy ? b.entropy.entropy : 0;
                return entropyB - entropyA;
            });

            // find unmatched images
            let unmatchedImages = this.images ? this.images.filter(img => !imageUrls.includes(img.originalURL)) : [];

            // append unmatched images at the end
            let allImages = [...matchedImages, ...unmatchedImages];

            return allImages;
        },
        productImage() {
            return this.productImages[0];
        },
        matchingImage() {
            // let images = this.images;
            return this.productImage;
            let images = this.images;
            let product = this.product;
            let index = 0;
            let { images: pImages = [] } = product;
            if (pImages[index]?.url) {
                let imageURL = pImages[index].originalURL;
                let matchingImage = images.find(image => image.originalURL === imageURL);
                matchingImage = matchingImage || pImages[index];
                return {
                    ...matchingImage,
                    url: resizeImage(matchingImage, { width: 300, height: this.mobile ? 400 : 500 }, "cover"),
                };
            }
        },
    },
    methods: {
        async removeProduct(product) {
            let { handle = "" } = product;
            this.$store.dispatch("styleGuide/deleteProduct", handle);
            // Note the change in arrayKey to 'StyleGuide.products' to match the store structure.
            // await updateFirebaseObject("brands", { products: this.products }, this.styleGuideId);
        },
        productClicked(product) {
            this.updateProp("currentProduct", product);
        },
    },
    props: {
        buttonStyle: {
            type: Object,
            required: false,
        },
        index: {
            type: Number,
            required: true,
        },
        randomDivStyle: {
            type: Object,
        },
        product: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
};
</script>
