<template>
    <PreviewWrapper v-if="campaign" :data="campaign" class="px-8 x" menu-title="Campaign">
        <template #side>
            <div class="pt-6">
                <!--                <CampaignHeader :key="'campy1'" :result="campaign" />-->
                <KlaviyoCampaignForm :campaign="campaign" />

                <div class="f fc gap-3" v-if="superAdmin">
                    <hr />
                    <div>
                        <label class="block text-sm font-medium leading-6 text-gray-900">Settings</label>
                        <div class="f gap-3">
                            <SimpleButton class="x block" secondary @click="generateAllCampaigns">Generate All</SimpleButton>
                            <SimpleButton class="x block" secondary @click="simulateAllCampaigns">Regenerate All</SimpleButton>
                        </div>
                    </div>
                    <hr />
                    <!--                    <ItemDetails title="ItemCount" :description="totalCampaignItemCount" />-->
                    <!--                    <ItemDetails title="name" :description="campaign.name" />-->
                </div>
                <!--                <CampaignScheduleItem :schedule-items="campaign.schedule_items" />-->
            </div>
        </template>
        <div>
            <div v-if="campaign?.schedule_items" class="relative f jcs ais px-2 px-8 pb-8">
                <div :class="containerClass">
                    <template v-for="(item, scheduleIndex) in campaign.schedule_items">
                        <CampaignItem
                            v-for="(campaignItem, campaignIndex) in item.campaign_items"
                            :key="campaignIndex"
                            :ref="`item${scheduleIndex}${campaignIndex}`"
                            :campaign-index="scheduleIndex"
                            :item="campaignItem"
                            :item-index="campaignIndex"
                            :scheduled-on-day="item?.scheduled_on_day"
                            class="mx-auto flex-shrink-0 hover:scale-100"
                            horizontal
                            style="min-width: 250px"
                            @click="showCurrentlyEditing(campaignItem.id)">
                            <!--                            :class="{ 'opacity-20 scale-90 transition-all ease-elastic duration-3': campaignItem.id !== currentlyEditing, '!opacity-100 !scale-100': !currentlyEditing }"-->

                            <!--                            @click="updateStreamProp('currentlyEditing', campaignItem.id)">-->
                            <template #clicked>
                                <!--                                <CampaignItemDebugger :campaign-item="campaignItem" />-->
                            </template>
                            <GenerativeCampaignItem :campaign-item="campaignItem" :index="campaignIndex" :schedule-index="scheduleIndex" />
                        </CampaignItem>
                    </template>
                    <div v-if="currentlyEditing" class="width-350 flex-shrink-0 vh-100"></div>
                    <div v-else class="width-100 flex-shrink-0 vh-100"></div>
                </div>
                <div v-if="!mobile" class="fixed bottom right f jce gap-5 z-10">
                    <div v-if="currentlyEditing" class="mw-350 x flex-shrink-0"></div>
                </div>

                <CurrentlyEditing :id="currentlyEditing" />
            </div>
        </div>
        <div class="visually-hidden">
            <LandingPage :campaign="campaign" :style-guide="styleGuide" class="visually-hidden" />
        </div>
    </PreviewWrapper>
</template>
<script>
import { mapGetters } from "vuex";
import PreviewWrapper from "@/components/Admin/CampaignPreviewWrapper.vue";
import GenerativeCampaignItem from "@/components/Admin/GenerativeCampaignItem.vue";
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import CampaignItem from "@/components/generative_templates/Campaigns/CampaignItem.vue";
import LandingPage from "@/components/generative_templates/Pages/NewCampaignPage.vue";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import SimpleButton from "@/views/SimpleButton.vue";
import ItemDetails from "@/components/Admin/ItemDetails.vue";
import KlaviyoCampaignForm from "@/components/Admin/KlaviyoLists.vue";
import CurrentlyEditing from "@/components/Admin/CurrentlyEditing.vue";
// import CurrentlyEditing from "@/components/Admin/CurrentlyEditing.vue";

export default {
    name: "CampaignObjectPreviewHorizontal",
    components: {
        CurrentlyEditing,
        // CurrentlyEditing,
        KlaviyoCampaignForm,
        ItemDetails,
        SimpleButton,
        GenerativeCampaignItem,
        LandingPage,
        CampaignItem,
        CampaignHeader,
        PreviewWrapper,
    },
    mixins: [styleGuideMixin],
    props: {
        campaign: {},
    },
    data() {
        return {
            vertical: false,
            showDetailBar: true,
            showLeftPanel: false,
            inputText: "",
        };
    },
    computed: {
        ...mapGetters("stream", ["result", "campaignData", "currentlyEditing", "showCampaign", "campaigns", "parentCampaign", "campaignSiblings", "campaignChildren"]),
        totalCampaignItemCount() {
            let total = 0;

            // Check if campaign.schedule_items is an array
            if (Array.isArray(this.campaign?.schedule_items)) {
                // Loop through each schedule_item
                this.campaign.schedule_items.forEach(scheduleItem => {
                    // Check if scheduleItem.campaign_items is an array
                    if (Array.isArray(scheduleItem.campaign_items)) {
                        // Loop through each campaign_item
                        scheduleItem.campaign_items.forEach(campaignItem => {
                            // Check if campaignItem.result and campaignItem.result.sections is not empty
                            if (campaignItem?.id) {
                                total++;
                            }
                        });
                    }
                });
            }

            return total;
        },
        sortedChildren() {
            return this.campaignChildren.sort((a, b) => {
                return a.result.campaignIndex - b.result.campaignIndex;
            });
        },

        containerClass() {
            if (this.vertical) return "f fc mx-auto scrollbar-hide gap-10 p-5 z-0";
            else return "f scrollbar-hide gap-10 px-8 z-0";
        },
    },
    async mounted() {
        await this.$nextTick();
        if (this.campaignData?.childCampaigns?.length > 0) await this.loadChildCampaigns();
        setTimeout(() => this.generateAllCampaigns(), 1000);
    },
    methods: {
        showCurrentlyEditing(id) {
            this.updateStreamProp("editing", id);
        },
        generateAllCampaigns() {
            if (Array.isArray(this.campaign?.schedule_items)) {
                this.campaign.schedule_items.forEach((scheduleItem, scheduleIndex) => {
                    if (Array.isArray(scheduleItem.campaign_items)) {
                        scheduleItem.campaign_items.forEach((campaignItem, itemIndex) => {
                            let refKey = `item${scheduleIndex}${itemIndex}`;
                            if (!campaignItem.result) {
                                logGroup(`Should Generate ${scheduleIndex}–${itemIndex}`, { campaignItem, scheduleIndex, itemIndex });
                                if (this.$refs[refKey] && this.$refs[refKey][0]) this.$refs[refKey][0].triggerGeneration();
                            } else {
                                logGroup(`Has Result ${scheduleIndex}–${itemIndex}`, {
                                    result: campaignItem.result,
                                    campaignItem,
                                    scheduleIndex,
                                    itemIndex,
                                });
                            }
                        });
                    }
                });
            }
        },
        simulateAllCampaigns() {
            if (Array.isArray(this.campaign?.schedule_items)) {
                this.campaign.schedule_items.forEach((scheduleItem, scheduleIndex) => {
                    if (Array.isArray(scheduleItem.campaign_items)) {
                        scheduleItem.campaign_items.forEach((campaignItem, itemIndex) => {
                            let refKey = `item${scheduleIndex}${itemIndex}`;
                            if (this.$refs[refKey] && this.$refs[refKey][0]) this.$refs[refKey][0].startSteamSimulation();
                        });
                    }
                });
            }
        },
        async loadChildCampaigns() {
            if (!this.campaignData?.result) return null;

            if (this.campaignData?.childCampaigns && !this.campaignData?.result?.schedule_items?.[0]?.campaign_items?.[0]?.id) {
                console.log("fetching child campaigns from firebase");
                let childCampaignArray = this.campaignData.childCampaigns;

                const childCampaignRefs = childCampaignArray.map(id => doc(db, "campaigns", id));
                const childCampaignSnapshot = await getDocs(childCampaignRefs);

                const childCampaigns = childCampaignSnapshot.docs.map(doc => {
                    let campaign = doc.data();
                    campaign.id = doc.id;
                    return campaign;
                });

                for (const campaign of childCampaigns) {
                    let result = campaign?.result;
                    let { itemIndex = 0, campaignIndex = 0 } = result;
                    console.groupCollapsed(`%cCampaign Child`, purple, `Day ${campaignIndex}, Item ${itemIndex}`);
                    console.log(campaign);
                    console.log(result);
                    console.groupEnd();

                    this.$nextTick(async () => {
                        let containerParent = {
                            id: campaign.id,
                            campaignId: this.$route.params.cid,
                            brand: this.$route.params.id,
                            created: `${new Date()}`,
                            updated: `${new Date()}`,
                            result: {},
                            ...this.campaignData.result.schedule_items[campaignIndex].campaign_items[itemIndex],
                        };
                        this.updateStreamProp(`campaignData.result.schedule_items.${campaignIndex}.campaign_items.${itemIndex}`, containerParent);
                        await this.$nextTick();

                        let newParent = await saveToFirebase("campaigns", this.campaignData, this.$route.params.cid);
                        console.log("new parent saved", newParent);
                        let fullObject = { ...containerParent, ...campaign };
                        let newObject = await saveToFirebase("campaigns", fullObject, campaign.id);
                        console.log("Container Child saved", newObject);
                        this.updateStreamProp(`editor.${campaign.id}`, newObject);
                    });
                }
                return childCampaigns;
            }
        },
    },
};
</script>
