<template>
    <div v-for="item in ['solutions', 'support', 'company', 'legal']" :key="item">
        <h4 class="font-semibold leading-6 text-capitalize">{{ item }}</h4>
        <ul role="list" class="list-none mt-3 space-y-2">
            <li v-for="item in navigation[`${item}`]" :key="item.name">
                <a :href="item.href" class="text-sm leading-6 o-5 text-inherit hover:text-white">{{ item.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "FooterLinks",
    props: {
        navigation: {
            type: Object,
            required: true,
        },
    },
};
</script>
