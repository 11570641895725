<template>
    <div class="ais jcs inline-block inline-flex ml-1" :class="{ 'o-6 pr-3': state.includes('Web search') }" v-if="simple">
        <!--        <i class="fas fa-search o-3 mr-2 pt-1"></i>-->
        <div>
            <!--            <span v-html="state"></span>-->
            <span class="ml-1" v-if="sources && sources.length > 0" v-html="sourceTitle"></span>
            <i v-if="sources && sources.length > 0" class="fas fa-spinner fa-pulse transition-1 fa-fw ml-3 text-gray-600"></i>
        </div>
    </div>
    <div class="border-light br-5 ais jcs inline-block inline-flex bg-gray-100 px-2 py-2 font-bold dark:bg-base-700" :class="{ 'o-6 pr-4': state.includes('Web search') }" v-else>
        <i class="fas fa-search o-3 mr-2 pt-1"></i>
        <div>
            <span v-html="state"></span>
            <span class="ml-1" v-if="sources && sources.length > 0" v-html="sourceTitle"></span>
            <i v-if="sources && sources.length > 0" class="fas fa-spinner fa-pulse transition-1 fa-fw ml-3 text-gray-600"></i>
        </div>
    </div>
    <!--    <div class="f x aic jcc">-->
    <!--        <div class="x whitespace-pre-wrap text-left">-->
    <!--            <div class="f x aic jcc pt-3">-->
    <!--                <div class="animate duration-5 fadeIn animate-pulse text-center">-->
    <!--                    <loading-spinner color="rgba(155,155,155,0.5)" />-->
    <!--                    <p class="o-5 pt-3">Searching the web</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
</template>
<script>
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";

export default {
    name: "WebLoader",
    components: { LoadingSpinner },
    props: {
        sources: {
            type: Array,
            required: true,
        },
        state: {
            type: String,
            required: true,
        },
        simple: {
            type: Boolean,
            required: false,
        },
        query: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            sourceTitle: [],
        };
    },
    mounted() {
        this.rotateSourceTitles();
    },
    watch: {
        sources: {
            immediate: true,
            handler(newSources) {
                this.clearInterval();
                this.rotateSourceTitles();
            },
        },
    },
    methods: {
        clearInterval() {
            clearInterval(this.rotateSourceTitles);
        },
        rotateSourceTitles() {
            let sources = this.sources || [];
            let i = 0;
            setInterval(() => {
                if (i < sources.length) {
                    if (sources[i].startsWith("Searching") || sources[i].startsWith("Getting results")) {
                        this.sourceTitle = sources[i];
                    } else {
                        this.sourceTitle = `<span class="fwn">${sources[i]}</span>`;
                    }
                    this.sourceTitle = `<span class="fwn">${sources[i]}</span>`;
                    i++;
                } else {
                    i = 0;
                }
            }, 1200);
            // stop interval
        },
    },
};
</script>
<style lang="scss">
hr{
  @apply my-5;
  // style the hr
  border-top: 1px solid;
  @apply border-t border-gray-100;
  @apply dark:border-base-500;
}
</style>
