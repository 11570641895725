<template>
    <div :style="`font-family:${f.name};font-weight:${f.weight}!font-style:${f.style}!important;font-size:40px;`" class="f aic gap-3">
        <template v-for="item in ['dog', 'house', 'envelope', 'check', 'music', 'star']">
            <i :class="`small p-2 fa fa-${item}`"></i>
        </template>
    </div>
</template>
<script>
export default {
    name: "fontAwesomeSample",
    props: {
        f: {},
    },
};
</script>
<style lang="scss" scoped>
// Basic Airbnb colors (might not be exact but close approximations)
$airbnb_red: #ff5a5f;
$light_grey: #ebebeb;
$dark_grey: #484848;

table{
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid $light_grey;

    th,
    td{
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid;
        @apply border-gray-200;
    }

    th{
        @apply bg-gray-100;
        font-weight: bold;
    }

    tbody tr{
        @apply bg-snow;
    }

    tbody tr:hover{

        //background-color: rgba($airbnb_red, 0.1);
        @apply bg-gray-50;
    }

    &.striped tbody tr:nth-of-type(odd){
    }

    &.highlight-row tbody tr:hover{
        @apply bg-gray-50;
    }

    caption{
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
        color: $airbnb_red;
    }
}
</style>