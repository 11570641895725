<template>
    <div class="f fc gap-3 my-5">
        <UIWrapperText section="collections" title="Collections">
            <template v-if="collections">
                <template v-for="collection in collections">
                    <UICollapsableSection v-if="collection?.products?.length > 0">
                        <template #title>{{ collection.title }}</template>
                        <div>
                            <Collection :collection="collection" />
                        </div>
                    </UICollapsableSection>
                </template>
            </template>
        </UIWrapperText>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Collection from "@/views/Collection.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";

export default {
    name: "Collections",
    components: { UIWrapperText, UICollapsableSection, Collection },
    data() {
        return {};
    },
    computed: {
        ...mapGetters("styleGuide", ["collections"]),
    },
    methods: {},
    mounted() {},
};
</script>
