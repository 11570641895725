<template>
    <div class="mx-auto">
        <div class="md:flex">
            <div class="">
                <div v-for="step in steps" class="">
                    <ThreadRunStep :key="step.id" :run="run" :run-step="step" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ThreadRunStep from "@/views/Chat/ThreadRunStep.vue";

export default {
    name: "ThreadRun",
    components: { ThreadRunStep },
    props: {
        run: {},
    },
    //   { "id": "run_dmEp4W7LiwHAo6LvRV0RNepu", "object": "thread.run", "created_at": 1705138762, "assistant_id": "asst_sSd5wzMlnnsNEOjclYHD16fP", "thread_id": "thread_ebQBFIerov1liZIMCh2y5K7j", "status": "completed", "started_at": 1705138763, "expires_at": null, "cancelled_at": null, "failed_at": null, "completed_at": 1705138780, "last_error": null, "model": gpt4, "instructions": "This is an example of an assistant that uses the gpt-4-1106-preview model. The assistant will respond to your messages with a message of its own.", "tools": [ { "type": "code_interpreter" }, { "type": "retrieval" } ], "file_ids": [], "metadata": {} }
    // make computed properties for the stucture above
    computed: {
        id() {
            return this.run?.id || "";
        },
        isRun() {
            return this.run?.object === "thread.run";
        },
        isMessage() {
            return this.run?.object === "thread.message";
        },
        steps() {
            return this.run?.steps || [];
        },
        object() {
            return this.run?.object || "";
        },
        createdAt() {
            return this.run?.created_at || "";
        },
        assistantId() {
            return this.run?.assistant_id || "";
        },
        threadId() {
            return this.run?.thread_id || "";
        },
        status() {
            return this.run?.status || "";
        },
        startedAt() {
            return this.run?.started_at || "";
        },
        expiresAt() {
            return this.run?.expires_at || "";
        },
        cancelledAt() {
            return this.run?.cancelled_at || "";
        },
        failedAt() {
            return this.run?.failed_at || "";
        },
        completedAt() {
            return this.run?.completed_at || "";
        },
        lastError() {
            return this.run?.last_error || "";
        },
        model() {
            return this.run?.model || "";
        },
        instructions() {
            return this.run?.instructions || "";
        },
        tools() {
            return this.run?.tools || "";
        },
        fileIds() {
            return this.run?.file_ids || "";
        },
        metadata() {
            return this.run?.metadata || "";
        },
    },
};
</script>
