import chroma from "chroma-js";
export function gradientHelper(gradientString) {
    let gradientObject = {
        type: "",
        angle: null,
        direction: null,
        shape: null,
        size: null,
        colors: [],
        stops: [],
    };

    try {
        if (!gradientString || typeof gradientString !== "string") {
            throw new Error("Input must be a non-empty string.");
        }

        let colorMatches = gradientString.match(/rgba?\([^\)]+\)/g) || [];
        const remainingString = colorMatches.reduce((acc, color) => acc.replace(color, ""), gradientString);

        const typeMatch = remainingString.match(/^linear-gradient|^radial-gradient/);
        if (typeMatch && typeMatch[0]) {
            gradientObject.type = typeMatch[0];
        }

        const gradientDetails = remainingString.slice(gradientObject.type.length + 1, -1);
        const components = gradientDetails
            .split(",")
            .map(item => item.trim())
            .filter(Boolean);

        if (gradientObject.type === "linear-gradient") {
            handleLinearGradient(components, gradientObject);
        } else if (gradientObject.type === "radial-gradient") {
            handleRadialGradient(components, gradientObject);
        }

        colorMatches = colorMatches.map(color => chroma(color).hex());
        gradientObject.colors = colorMatches;

        for (const [key, value] of Object.entries(gradientObject)) {
            if (value === null || (Array.isArray(value) && value.length === 0)) {
                delete gradientObject[key];
            }
        }

        if (!gradientObject.colors || gradientObject.colors.length === 0) {
            throw new Error("No valid colors detected.");
        }

        gradientObject.reconstructedString = constructGradientString(gradientObject);
    } catch (err) {
        console.error("An error occurred in gradientHelper: ", err);
    }

    return gradientObject;
}

function handleLinearGradient(components, gradientObject) {
    // Safeguard against undefined components
    if (components && components.length > 0 && components[0]) {
        const angleMatch = components[0].match(/^(\d+deg)/);
        if (angleMatch && angleMatch[1]) {
            gradientObject.angle = parseInt(angleMatch[1], 10);
            components.shift();
        }

        // Further safeguard here
        if (components[0]) {
            const directionMatch = components[0].match(/^(to top|to bottom|to left|to right|to bottom right|to bottom left|to top right|to top left)/);
            if (directionMatch && directionMatch[1]) {
                gradientObject.direction = directionMatch[1];
                components.shift();
            }
        }
    }
}

function handleRadialGradient(components, gradientObject) {
    // Safeguard against undefined components
    if (components && components.length > 0 && components[0]) {
        const shapeSizeMatch = components[0].match(/^(circle|ellipse) (closest-side|closest-corner|farthest-side|farthest-corner)/);
        if (shapeSizeMatch && shapeSizeMatch[1] && shapeSizeMatch[2]) {
            gradientObject.shape = shapeSizeMatch[1];
            gradientObject.size = shapeSizeMatch[2];
            components.shift();
        }
    }
}

function constructGradientString(gradientObject) {
    if (!gradientObject || !gradientObject.colors || gradientObject.colors.length === 0) {
        return "";
    }
    const { angle, direction, shape, size, colors, type } = gradientObject;

    let gradientString = "";
    if (type === "linear-gradient") {
        let directionString = angle !== null ? `${angle}deg` : direction;
        gradientString = `${type}(${directionString ? `${directionString}, ` : ""}${colors.join(", ")})`;
    } else if (type === "radial-gradient") {
        let shapeSizeString = shape && size ? `${shape} ${size}` : "";
        gradientString = `${type}(${shapeSizeString ? `${shapeSizeString}, ` : ""}${colors.join(", ")})`;
    }
    return gradientString;
}

export { constructGradientString };

// // Test
// let linearGradient = "linear-gradient(45deg, red 10%, blue 50%, yellow 90%)";
// console.log(gradientHelper(linearGradient));
//
// let radialGradient = "radial-gradient(circle farthest-corner, red 10%, blue 50%, yellow 90%)";
// console.log(gradientHelper(radialGradient));
