function removeStep(store, stepName) {
    setTimeout(() => {
        const index = store.state.presentation.progress.findIndex(item => item.name === stepName);
        if (index !== -1) {
            store.dispatch("presentation/removeStep", index);
        } else {
            console.log(`Step ${stepName} not found`);
        }
    }, 200);
}
export default removeStep;
