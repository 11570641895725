<template>
    <div :class="chatRoleClass" class="x chat-message x p-relative p-0" @mouseout="hovered = false" @mouseover="hovered = true">
        <div class="message_container relative mt-0" :class="{ 'mt-0 py-0': sameRoleAsLastMessage, 'mt-0 py-0': sameRoleAsNextMessage }">
            <!--            <div class="avatar-container">-->
            <ChatAvatar v-if="!hideAvatar" :same-role="sameRoleAsLastMessage" :bg-color="getAvatarBgColor(m.role)" :date="m.createdAt" :role="m.role" />
            <!--            </div>-->
            <div :class="{ 'mw-650': !mobile }" class="chat_content_container x relative">
                <!--                <div class="content-container" :class="{ 'bg-red': hovered }">-->
                <ChatMessageEdit v-if="editMode" :index="index" :m="m" @saveEdit="saveEdit" @cancel-edit="cancelEdit"></ChatMessageEdit>

                <slot v-else></slot>
                <span v-if="$store.state.chat.showDebugger" class="f ais jcs">
                    <span v-if="m.createdAt && $store.state.chat.showDebugger" class="small line-height-smaller d-block o-3 f-11 order-first mr-2 whitespace-nowrap pb-3 text-center italic xl:order-last">{{ $filters.timeAgo(dateFix(m.createdAt)) }}</span>
                    <div class="f fc">
                        <template v-if="$store.state.chat.showDebugger && m.func">{{ m.func }}</template>
                        <debugObject v-if="$store.state.chat.showDebugger" :keysToOpen="['action', 'args', 'results', 'data', 'command', 'arguments']" :object="m" :open="index === $store.state.chat.messages.length - 1"></debugObject>
                    </div>
                </span>
                <div v-if="!mobile && showListenButton" class="x f aic jcc border-t border-t-base-200 pb-3">
                    <span v-if="action(m)" class="o-5 small italic">{{ action(m).name }}</span>
                    <!--                    <ListenButton v-if="showListenButton" :content="m.content" kind="secondary" reverse />-->
                </div>
                <div class="f jcb x aic gap-1 relative">
                    <!--                    <ListenButton v-if="mobile" :content="m.content" class="listen_mobile x f jcs aic" />-->
                </div>
            </div>
            <MessageEditButtons :class="{ 'opacity-0': !hovered }" :message="m" @edit-message="editMessage" @remove-message="removeMessage" />
        </div>
    </div>
</template>

<script>
import ChatAvatar from "@/components/CoreUI/ChatAvatar.vue";
import MessageEditButtons from "@/components/chat/MessageEditButtons.vue";
import ChatMessageEdit from "@/components/chat/messages/ChatMessageEdit.vue";
import ChatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import ListenButton from "@/components/chat/audio/ListenButton.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";

export default {
    name: "ChatMessageWrapper",
    components: { DebugObject, ListenButton, ChatMessageEdit, ChatAvatar, MessageEditButtons },
    mixins: [ChatCommands],
    props: {
        m: {
            type: Object,
            required: true,
        },
        showDebugInfo: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            required: true,
        },
        hideAvatar: {
            type: Boolean,
            default: false,
        },
        // hovered: {
        //     type: Boolean,
        //     default: false,
        // },
    },
    data() {
        return {
            hovered: false,
            editMode: false,
        };
    },
    computed: {
        sameRoleAsLastMessage() {
            try {
                return this.m.role === this.$store.state.chat.messages[this.index - 1].role;
            } catch (e) {
                return false;
            }
        },
        sameRoleAsNextMessage() {
            try {
                return this.m.role === this.$store.state.chat.messages[this.index + 1].role;
            } catch (e) {
                return false;
            }
        },
        chatRoleClass() {
            return `chat-${this.m.role} chat-message x p-relative x`;
        },
        isLastMessage() {
            const messages = this.$store.state.chat.messages;
            return messages[messages.length - 1] === this.m;
        },
        showListenButton() {
            if (this.m.role === "user") {
                return false;
            }
            if (this.m.content === "") {
                return false;
            }
            if (this.m.content && this.m.content !== "") {
                return true;
            }
            if (!this.m.complete || this.isCodeMessage || this.isCommandObjectMessage || this.isImageMessage) {
                return false;
            }
            return true;
        },
    },
    methods: {
        getAvatarBgColor(role) {
            // Implement your logic to determine the avatar background color based on the role
            // Return the appropriate CSS class or inline style
        },
        saveEdit() {
            // this.$store.dispatch("chat/updateMe", this.m);
            this.$store.dispatch("chat/updateMessageObject", { message: this.m, index: this.index });
            console.error("saveEdit");
            this.editMode = false;
            this.$emit("editMessage", this.m, this.index);
        },
        cancelEdit() {
            this.editMode = false;
        },
        editMessage() {
            this.editMode = true;
            // this.$emit("editMessage", this.m, this.index);
        },
        removeMessage() {
            this.$emit("remove-message", this.m);
        },
    },
};
</script>

<style lang="scss">
.chat-assistant {
    //@apply border-b border-base-400;
    @apply bg-gray-50;
    @apply text-gray-700;
    @apply border-b;
    // colors
    //@apply border-base-200;
    //@apply dark:border-base-600/80;
    @apply dark:text-base-300;
    @apply dark:bg-base-950;
    .chat_content_container .markdown-content {
        p,
        span,
        li,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
        }
    }

    //.prose {
    @apply text-base-400;
    //}
}

.chat-user {
    //@apply border-top;
    /*@apply border-bottom;*/
    //@apply border-gray-300;
    //@apply text-gray-700;
    @apply bg-snow;
    // colors
    //@apply border-b;
    //@apply border-base-200;
    //@apply dark:border-base-600/80;
    @apply dark:text-base-300;
    @apply dark:bg-base-950/50;
}

.chat-message {
    @apply border-b;
}

.chat-message-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.avatar-container {
    //margin-right: 1rem;
}

.content-container {
    flex-grow: 1;
}

.edit-buttons-container {
    /*margin-left: 1rem;*/
}
</style>
