<template>
    <div class="f">
        <Avatar size="xs">
            <AvatarImage :image-url="imageUrl" :name="name" />
            <AvatarFallback :name="name" />
        </Avatar>

        <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
            <div class="flex justify-between gap-x-4">
                <div class="py-0.5 text-xs leading-5 text-gray-500">
                    <span class="font-medium text-gray-900">{{ name }}</span>
                    commented
                </div>
                <TimeStamp class="flex-none py-0.5 text-xs leading-5 text-gray-500" :date="dateTime" />
                <time :datetime="dateTime" class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ date }}</time>
            </div>
            <p class="text-sm leading-6 text-gray-500">{{ comment }}</p>
        </div>
    </div>
</template>
<script>
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/shad/ui/avatar";
export default {
    name: "ActivityItemComment",
    components: { AvatarImage, AvatarFallback, TimeStamp, Avatar },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        imageUrl() {
            return this.item?.user?.imageUrl || "";
        },
        name() {
            return this.item?.user?.name || "";
        },
        dateTime() {
            return this.item?.dateTime || "";
        },
        date() {
            return this.item?.date || "";
        },
        comment() {
            return this.item?.comment || "";
        },
    },
};
</script>
