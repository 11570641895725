<template>
    <div
        v-if="$route.params.id"
        ref="documentContainer"
        :key="$route.params.id"
        class="scrollSection x fc align-content-stretch flex flex-grow">
        <div class="x f fc align-content-stretch" style="min-height: 100vh">
            <div ref="mainContent" class="x pt-nav" style="flex-basis: 100%; flex-grow: 1">
                <div class="x" v-if="document">
                    <DocumentsToolbar class="mb-5"></DocumentsToolbar>
                    <!--                    <div class="container">{{ sections }}</div>-->
                    <div
                        v-if="document"
                        :key="$route.params.id"
                        v-drop
                        class="x mx-auto max-w-lg pb-8"
                        @keydown="keydown">
                        <!--            <TransitionGroup :enter-active-class='`fadeInUpSmooth ease-back duration-3 d-1 animated stagger-${index + 1}`' appear leave-active-class='ease-back duration-1 fadeOutDown animated'>-->
                        <draggable
                            v-model="document.sections"
                            group="sections"
                            item-key="element.id"
                            handle=".handle"
                            @focus-next-selection="focusNextSection"
                            @end="drag = false"
                            @start="drag = true">
                            <template #item="{ element, index }">
                                <EditableSection
                                    :ref="`section-${index + 1}`"
                                    :key="element.id + index"
                                    v-model="element.content"
                                    :index="index"
                                    :section="element"
                                    :section-id="`section-${index + 1}`"
                                    :tag="element.tag"
                                    class="mx-auto max-w-lg"
                                    @set-tag="setTag"
                                    @focus-next-section="focusNextSection"
                                    @focus-previous-section="focusSection"
                                    @update-previous-section="updatePreviousSection"
                                    @new-section="newSection"
                                    @remove-section="removeSection"></EditableSection>
                            </template>
                        </draggable>
                        <!--            </TransitionGroup>-->
                    </div>
                    <!--        </template>-->
                </div>
            </div>

            <template v-if="document">
                <DocumentFooter :d="document"></DocumentFooter>
            </template>
        </div>
    </div>
</template>
<script setup>
import { reactive } from "vue";
import { useCollection, useDocument } from "vuefire";
import { doc, getDoc, collection, Timestamp } from "firebase/firestore";
import { useRoute } from "vue-router";
import { db } from "@/firebase";

const router = useRoute();
const id = router.params.id;

import lodash from "lodash";
import draggable from "vuedraggable";
import DocumentFooter from "@/components/documents/DocumentFooter.vue";
</script>
<script>
import { useCollection, useDocument } from "vuefire";

// import {getDoc } from 'vuefire';
import DocumentsToolbar from "@/components/documents/DocumentsToolbar.vue";
import EditableSection from "@/components/documents/editableSection.vue";
import { db } from "@/firebase";
import cursorMixin from "@/mixins/cursorMixin";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import documentMixins from "@/mixins/DocumentArchive/documentMixins";
import imageMixin from "@/mixins/images/imageMixin";
import { isProxy, toRaw } from "vue";
export default {
    name: "DocumentContainer",
    components: { EditableSection, DocumentsToolbar, draggable },
    mixins: [cursorMixin, documentMixins, imageMixin, documentManagementMixin],
    props: {
        type: "",
    },
    data() {
        return {
            sectionCount: 0,
            showDocs: false,
            // document: {},
            drag: false,
            // sections: [
            //     {
            //         content: "New document",
            //         tag: "h1",
            //         favorite: false,
            //         id: "",
            //     },
            //     {
            //         content: " ",
            //         tag: "p",
            //         favorite: false,
            //         id: "",
            //     },
            // ],
        };
    },
    watch: {
        $route: {
            handler(to, from) {
                this.savingStateInit(this.document);
                // if (this.$refs.documentContainer) {
                //     this.$nextTick(() => {
                //         this.$refs.documentContainer.scrollTop = 0;
                //         console.error("fix scroll");
                //     });
                // }
            },
            immediate: true,
        },
        sections: {
            handler() {
                // this.document.sections = toRaw(this.sections);
                // this.sections = this.document.sections;
                // window.sections = this.sections;
                // this.saveDoc(this.document);
            },
            immediate: true,
            deep: true,
        },

        sectionIndex: function (val, oldVal) {
            this.savingStateInit(this.document, "switching sections");

            this.$nextTick(() => {
                if (val === this.index) {
                    const ref = this.$refs[`${this.sectionId}`];
                    //this is how to reference a ref it properly.
                    // good ref
                    if (
                        ref &&
                        ref.$refs &&
                        ref.$refs.contenteditable &&
                        ref.$refs.contenteditable.$refs &&
                        ref.$refs.contenteditable.$refs.element
                    ) {
                        // ref.$refs.contenteditable.$refs.element.focus();
                        // this.setCaretPosition(ref.$refs.contenteditable.$refs.element, window.caret);
                    }
                }
            });
        },
        document: function (val, oldVal) {
            if (val) {
                //sections initialized
                //   this.sections = toRaw(val.sections);
                this.$store.commit("setSections", toRaw(val.sections));
            }
        },
    },
    async created() {
        window.addEventListener("keydown", event => {
            if (event.key === "s" && event.metaKey) {
                event.preventDefault();
                // Trigger the save function for your application
            }
            if (event.key === "n" && event.altKey) {
                event.preventDefault();
                // Trigger the save function for your application
            }
        });
    },
    async mounted() {
        await this.getDocument();
    },
    methods: {
        focusNextSection() {
            // setTimeout(() => {
            console.error("focus next section");
            const currentSection = this.sectionIndex;
            const nextSection = currentSection + 1;
            const sectionId = "section-" + nextSection;
            this.$nextTick(() => {
                console.error("set section index next: ", nextSection, "current: ", this.sectionIndex);
                // this.setSectionIndex(nextSection);
                this.$refs[sectionId].$refs.contenteditable.$refs.element.focus();
            });

            // }, 200);
        },
        focusSection([index, ev, cursorPosition]) {
            console.error("focus" + index);
            let newIndex;

            if (ev.key === "ArrowUp") {
                this.setSectionIndex(this.sectionIndex - 1);
            }
            if (ev.key === "ArrowDown") {
                this.setSectionIndex(this.sectionIndex + 1);
            }
            let cursor = {
                startContainer: cursorPosition,
                startOffset: cursorPosition,
            };
            this.$nextTick(() => {
                if (newIndex < 1) {
                    newIndex = 1;
                } else if (newIndex > this.document.sections.length) {
                    newIndex = this.document.sections.length;
                }
            });
        },
        setTag([index, tag, kind]) {
            console.error(index);
            console.error(tag);
            let validTags = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "blockquote", "pre", "ul", "ol", "li", "div"];
            if (kind) {
                this.document.sections[index].kind = kind;
            }
            if (tag && tag.type) {
                this.document.sections[index].tag = tag.type;
            } else if (tag && validTags.includes(tag)) {
                this.document.sections[index].tag = tag;
            }
        },
        setCaretPosition(elemId, caretPos) {
            if (window.caret) {
                this.$nextTick(() => {
                    const ref = this.$refs[`${this.sectionId}`];
                    let element = ref.$refs.contenteditable.$refs["element"];
                    console.error(element);
                    const startIndex = window.caret;
                    const textNode = element.length;

                    if (startIndex > textNode) {
                        console.error(
                            `Error: offset ${startIndex} is greater than number of child nodes ${childNodeCount}`,
                        );
                        return;
                    }
                    let range, selection;
                    setTimeout(() => {
                        if (document.createRange) {
                            range = document.createRange();
                            range.selectNodeContents(element);
                            range.collapse(true);
                            range.setStart(element.firstChild, window.caret);
                            range.setEnd(element.firstChild, window.caret);
                            selection = window.getSelection();
                            selection.removeAllRanges();
                            selection.addRange(range);
                        }
                        window.caret = null;
                    }, 100);
                });
            }
        },
      removeSection(indexOfSectionToRemove, previousCursorPosition) {
        console.error("direct emit removeSection",indexOfSectionToRemove, previousCursorPosition);
        console.dir({ indexOfSectionToRemove, previousCursorPosition });

        const previousSection = this.document.sections[this.sectionIndex - 1].content;
        console.error("previousSection", previousSection, previousSection.length);
        const cp = this.document.sections[this.sectionIndex - 1].content.length;
        this.document.sections.splice(indexOfSectionToRemove, 1);
        const currentIndex = indexOfSectionToRemove;
        const newIndex = currentIndex;
        setTimeout(() => {
          // this.setSectionIndex(newIndex);
          // this.restoreCursorPosition(cp);
          this.setCursorPosition(newIndex,previousCursorPosition);
          console.trace();
        }, 30);
      },

      keydown(ev) {
            if (ev.key === "s" && ev.metaKey) {
              this.saveThisDoc();
            }
            if (ev.key === "n" && ev.metaKey) {
                this.createDocument();
            }
        },
        addIndexToSections() {
            this.document.sections.forEach((section, index) => {
                section.index = index;
            });
        },
    },
};
</script>
