<template>
    <div class="">
        <div ref="markdownContent" class="markdown-it-container x mx-auto">
            <h6 v-if="message && message.kind && message.kind === 'code'">code</h6>
            <div class="markdown-content" v-html="html"></div>
            <ChatCodeRenderer v-if="extractedCodeBlocks?.vue || extractedCodeBlocks?.html" :extracted-code="true" :message="extractedCodeBlocks.vue || extractedCodeBlocks?.html" />
        </div>
        <template v-if="showHTMLBlock">
            <h6 v-if="message && message.kind && message.kind === 'code'">Front end</h6>

            <div class="html-preview"></div>
        </template>
    </div>
</template>
<script>
import Prism from "prismjs";
import ClipboardJS from "clipboard";
import "prismjs/plugins/toolbar/prism-toolbar.min.js";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min.js";
import "prismjs/plugins/toolbar/prism-toolbar.css";
import "prismjs/components/prism-javascript.js";
import "prismjs/components/prism-css.js";
import "prismjs/themes/prism.css";
import MarkdownIt from "markdown-it";
import mdAbbr from "markdown-it-abbr";
import mdAnchor from "markdown-it-anchor";
// import mdToc from "markdown-it-table-of-contents";
import mdEmoji from "markdown-it-emoji";
import mdFootnote from "markdown-it-footnote";
import mdIns from "markdown-it-ins";
import mdMark from "markdown-it-mark";
import mdSub from "markdown-it-sub";
import mdSup from "markdown-it-sup";
import mdTaskLists from "markdown-it-task-lists";
import { createApp } from "vue";

// import { Vue } from "vue";
// import mdContainer from "markdown-it-container";

import Cm from "@/components/CoreUI/cm.vue";
import ChatCodeRenderer from "@/components/chat/specialMessages/ChatCodeRenderer.vue";
import chatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import newChatMixin from "@/mixins/Chat/newChatMixins";
import TaskManager from "@/views/Chat/TaskManager.vue";
import ChatMapCard from "@/components/chat/specialMessages/ChatMapCard.vue";

export default {
    components: { Cm, ChatCodeRenderer, TaskManager },
    mixins: [newChatMixin, chatCommands],
    props: {
        content: {
            type: String,
            default: "",
            required: true,
        },
        message: {
            type: Object,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            dynamicComponent: null,
            currentAction: null,
            buttonText: "Copy code",
        };
    },
    computed: {
        extractedCodeBlocks() {
            if (!this.content) return;
            const languagesToSupport = ["html", "vue"]; // Add more languages here as needed.
            // const languagesToSupport = ["html", "javascript", "vue"]; // Add more languages here as needed.
            const codeBlocks = {};
            let message = this.content || "";
            if (!message) return;
            if (typeof message === "object") {
                message = JSON.stringify(message, null, 2);
            }
            languagesToSupport.forEach(language => {
                const startDelimiter = "```" + language;
                const endDelimiter = "```";

                let startIndex = message.indexOf(startDelimiter);
                while (startIndex !== -1) {
                    const codeStartIndex = startIndex + startDelimiter.length + "\n".length;
                    const endIndex = message.indexOf(endDelimiter, codeStartIndex);
                    if (endIndex !== -1) {
                        let codeBlock = message.substring(codeStartIndex, endIndex);
                        if (language === "vue") {
                            // codeBlock = codeBlock.replace("<template>", "");
                            // codeBlock = codeBlock.replace("</template>", "");
                            // codeBlock = codeBlock.replace("<script>", `<script type="module">`);
                            codeBlock = codeBlock.replace(/import .+ from .+;/g, "");

                            codeBlock = codeBlock.replace("export default", "return");
                        }
                        codeBlocks[language] = codeBlock.trim();
                        startIndex = message.indexOf(startDelimiter, endIndex + endDelimiter.length);
                    } else {
                        startIndex = -1;
                    }
                }
            });

            return codeBlocks;
        },
        showHTMLBlock() {
            if (!this.content || !this.message) {
                return false;
            }
            // return true;
            let message = this.content.replace("[START]\n", "").replace("[END]", "").replace("```", "");
            const isHTMLorVue = message.includes("```html") || message.includes("```HTML") || message.startsWith("vue");
            const isCodeKind = this.message.kind && this.message.kind === "code";

            return isHTMLorVue && (isCodeKind || !this.message.kind);
        },

        contentWithoutFirstWord() {
            //remove first word from this.content
            let message = this.content;
            let cleanedContent;
            // let cleanedContent = this.content.replace("[START]\n", "").replace("[END]", "").replace("```", "");
            cleanedContent = message.replace("<template>", "").replace("</template>", "");
            cleanedContent = cleanedContent.replace(/^[^\s]+/, "").replace("```", "");
            return cleanedContent;
        },
        containsHtmlCode() {
            const htmlRegex = /(?<!`)<(?:[^>]*>|\/[^>]*>|!--[\s\S]*?-->)(?!`)/gm;
            // const htmlRegex = /<([a-z]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)/g;
            if (this.content.startsWith("html")) {
                return true;
            } else {
                return htmlRegex.test(this.content);
                return false;
            }
            // return htmlRegex.test(this.content);
        },
        modifiedContent() {
            try {
                if (!this.content && !this.message) return "";
                let content;
                content = this.content || "";
                if (typeof content === "object") {
                    content = [
                        "```json", //
                        `${JSON.stringify(content, null, 2)}`,
                        "```",
                    ].join("\n");
                }
                let actions = this.actionsFiltered;
                let action = actions.find(action => {
                    if (!content) return;
                    if (typeof content !== "string") return;
                    return content.includes(action.start) || content.includes(action.end);
                });

                if (action) {
                    this.currentAction = action; // Remember to avoid side-effects in computed properties
                    content = content.replace(action.start, "").replace(action.end, "");
                    if (this.message && this.message.results && this.message.results.content) {
                        return content.replace(`\nRESULTS:\n\n${this.message.results.content}`, "").trim();
                    }
                    return content;
                }
                // console.error("returning content");
                if (content && typeof content !== "string") return;
                content = content.replace("[START]", "").replace("[END]", "");

                return content;
                console.error("final content", content);
                return this.removeHiddenContent(content);
            } catch (e) {
                console.log(e);
                console.error(this.content);
            }
            // }
        },
        html() {
            const md = new MarkdownIt({
                linkify: true,
                typographer: false,
            })
                .use(mdAnchor, {
                    // anchorOptions
                })
                .use(mdFootnote)
                .use(mdEmoji)
                .use(mdSub)
                .use(mdSup)
                .use(mdMark)
                .use(mdIns)
                .use(mdAbbr)
                .use(mdTaskLists, {
                    label: true,
                    enabled: true,
                    labelAfter: true,
                    className: "task-list",
                });
            // .use(markdownItMermaid)
            // .use(markdownItLists)
            this.$forceUpdate();
            md.renderer.rules.bullet_list_open = function (tokens, idx, options, env, self) {
                return '<ul class="custom-bullet-list whitespace-pre-wrap">\n';
            };

            // Custom ordered list rendering
            md.renderer.rules.ordered_list_open = function (tokens, idx, options, env, self) {
                return '<ol class="custom-ordered-list">\n';
            };

            md.inline.ruler.push("custom_image_block", (state, silent) => {
                const start = state.src.indexOf("#IST#", state.pos);
                if (start !== state.pos) {
                    return false;
                }
                const end = state.src.indexOf("#IND#", start + 6);
                if (end === -1) {
                    return false;
                }
                const contentBetweenDelimiters = state.src.slice(start + 6, end);

                if (!silent) {
                    const token = state.push("custom_image_block_token", "div", 0);
                    token.content = contentBetweenDelimiters;
                }
                state.pos = end + 6;
                return true;
            });
            md.inline.ruler.push("custom_todo_block", function (state, silent) {
                const start = state.src.indexOf("[TD]", state.pos);
                if (start !== state.pos) {
                    return false;
                }
                const end = state.src.indexOf("[TDD]", start + 4);
                if (end === -1) {
                    return false;
                }
                const contentBetweenDelimiters = state.src.slice(start + 4, end);

                if (!silent) {
                    const token = state.push("custom_todo_block_token", "div", 0);
                    token.content = contentBetweenDelimiters;
                }

                // check if this.$store.state.chat.tasks.tasks includes contentBetweenDelimiters

                state.pos = end + 5; // [TDD] is 5 characters long
                return true;
            }); //
            md.renderer.rules.custom_todo_block_token = (tokens, idx) => {
                // if()
                if (!this.$store.state.chat.tasks.tasks.includes(tokens[idx].content)) {
                    this.$nextTick(function () {
                        this.$store.dispatch("chat/tasks/addTaskToStore", tokens[idx].content);
                    });
                }
                return `<b>${tokens[idx].content}</b>`;
            };
            md.inline.ruler.push("show_tasks", function (state, silent) {
                let string = "[TASKS]";
                const start = state.src.indexOf(string, state.pos);
                if (start !== state.pos) {
                    return false;
                }
                const end = start + string.length; // [SHOWTASKS] is 11 characters long, but JavaScript is 0-indexed

                if (!silent) {
                    const token = state.push("show_tasks_token", "", 0);
                    // We'll render this token later using the renderer rules
                }

                state.pos = end;
                return true;
            });

            md.renderer.rules.show_tasks_token = function (tokens, idx) {
                // Here you decide what to render when the [SHOWTASKS] tag is encountered
                // This is a placeholder. You'd typically return a string of HTML here
                return "<TaskManager/>";
            };
            md.renderer.rules.custom_image_block_token = (tokens, idx) => {
                // if()
                return `<div class="br-10 shadow f-13 p-3 bg-snow custom-image-block d-block text-blue my-3">${tokens[idx].content}</div>`;
            };

            md.renderer.rules.fence = (tokens, idx, options, env, self) => {
                // 1. Define HTML part
                Prism.languages.vue = Prism.languages.extend("markup", {});

                // 2. Define JavaScript part
                Prism.languages.insertBefore("vue", "tag", { ...Prism.languages.javascript });

                // 3. Define CSS part
                Prism.languages.insertBefore("vue", "javascript", { ...Prism.languages.css });

                // 4. Define other languages like SCSS (optional)
                Prism.languages.insertBefore("vue", "javascript", { ...Prism.languages.scss });

                // 5. Customize specific Vue properties, components, and patterns (if needed)
                Prism.languages.vue["v-attribute"] = {
                    pattern: /v-(bind|on|slot|model|if|else|else-if|for|text|html|cloak|once|pre|is|staticClass)\b/,
                    inside: { ...Prism.languages.vue },
                };

                const token = tokens[idx];
                let langName = token.info.trim().split(/\s+/g)[0];
                let lang = Prism.languages[langName] || Prism.languages.markup;

                const code = token.content.trim();

                // Determine if code block is a Vue.js block or if the language is already set to 'vue'
                if (code.includes("<template>") || langName === "vue") {
                    // langName = "vue";
                    lang = Prism.languages.html;
                }

                const html = Prism.highlight(code, lang, langName);
                return this.fixedHTML(langName, html, code);
            };
            const defaultLinkRender =
                md.renderer.rules.link_open ||
                function (tokens, idx, options, env, self) {
                    return self.renderToken(tokens, idx, options);
                };

            md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
                const aIndex = tokens[idx].attrIndex("target");

                if (aIndex < 0) {
                    tokens[idx].attrPush(["target", "_blank"]);
                } else {
                    tokens[idx].attrs[aIndex][1] = "_blank";
                }

                const relIndex = tokens[idx].attrIndex("rel");
                if (relIndex < 0) {
                    tokens[idx].attrPush(["rel", "noopener noreferrer"]);
                } else {
                    tokens[idx].attrs[relIndex][1] = "noopener noreferrer";
                }

                return defaultLinkRender(tokens, idx, options, env, self);
            };
            let modifiedContent = this.modifiedContent || "";
            return md.render(modifiedContent);
            // figuere out how to add this while typing
            // <div className="inline bg-green-400 flash infinite duration-4 fa-pulse" style="min-width:10px;min-height:15px;">|</div>
        },
    },
    watch: {
        content(newVal, oldVal) {
            this.$nextTick(() => {
                Prism.highlightAll();
            });
        },
        "message.typing": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$nextTick(() => {
                    this.createDynamicComponent();
                });
            }
        },
    },
    updated() {
        Prism.highlightAll();
    },
    mounted() {
        this.$nextTick(() => {
            new ClipboardJS(".copy-to-clipboard-button");
        });
        this.$nextTick(() => {
            if (!this.$refs.markdownContent) return;
            this.createDynamicComponent();
            const copyButtons = this.$refs?.markdownContent?.querySelectorAll(".copy-code-button");
            copyButtons.forEach(button => {
                button.addEventListener("click", event => {
                    const code = event.target.getAttribute("data-code");
                    this.$emit("copied", code);
                    event.preventDefault();
                });
            });
        });
    },
    methods: {
        createDynamicComponent(code) {
            // You should sanitize your code before processing it
            this.dynamicComponent = {
                components: {
                    TaskManager: TaskManager,
                    Map: ChatMapCard,
                },
                props: ["test"],
                template: `
                    <div>${this.html}</div>`, // or your dynamic code string
            };
        },
        removeHiddenContent(text) {
            // console.error("removing hidden content", text);
            let finalText = text.replace(/\[HIDE][\s\S]*?\[HID]/g, "");
            // console.error("final text", finalText);
            return finalText;
        },
        fixedHTML(langName, html, code) {
            let newCode = code.replace(/"/g, "&quot;");
            let renderCode = "";
            if (code.includes("PLACEHOLDER_URL")) {
                renderCode = code.replace("PLACEHOLDER_URL", "https://source.unsplash.com/random/?city,night");
            }
            if (code.includes("<") && code.includes("div") && langName !== "vue" && !code.includes("<script>")) renderCode = code.replace("<template>", "").replace("</template>", "");
            return `<div class="chat_code x mx-auto rounded-lg mb-4 overflow-hidden bg-gray-800 dark:bg-base-800 mt-4 relative mx-auto">
                        <div class="pl-3 pr-3 flex justify-between items-center my-0 py-0" style="padding:0px;!important">
                            <div class="font-bold text-sm mb-0 text-white my-0 py-3 f aic jcc">Code Snippet <span class="ml-2 badge-pill badge text-teal-900 bg-teal dark:text-teal-200 dark:bg-teal-700 f-11 py-1 text-bold font-bold text-uppercase">${langName}</span></div>
                            <button class="border border-gray-50 br-10 bg-transparent text-white hover:text-gray-100 py-0 px-2 rounded font-semibold copy-to-clipboard-button py-1 my-0 text-sm o-5" type="button" data-clipboard-text="${newCode}"> <i class="far fa-clipboard mr-2"></i>Copy Code</button>
                        </div>
                        <div class="code-example mx-auto my-0 py-0" style="padding:0!important"><pre data-previewers="color time" class="py-3 my-0 language-${langName}"><code style="margin:0px;padding:0px!important;" class="my-0 py-0 language-${langName}">${html}</code></pre></div>
                    </div>
                    ${renderCode}
`;
        },
        async copyCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                this.buttonText = "Copied!";
            } catch (err) {
                console.error("Error copying code to clipboard", err);
            } finally {
                setTimeout(() => {
                    this.buttonText = "Copy code";
                }, 2000);
            }
        },
        generateCodeBlock(langName, code) {
            return {
                component: "CodeBlock",
                props: { langName, code },
            };
        },
    },
};
</script>
<style lang="scss">
//.prose {
//    @apply text-gray-900;
//    @apply dark:text-base-300;
//    strong {
//        @apply text-gray-900;
//        @apply dark:text-base-200;
//        @apply font-semibold;
//    }
//}
.chat_content_container {
    .markdown-content {
        button {
            border: 0px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        li {
            line-height: unset;
            margin: unset;
            padding: unset;
            letter-spacing: unset;
        }

        @apply prose;
        h1 {
            @apply text-xl;
            //@apply mb-3;
            //line-height:1;
        }

        h2 {
            @apply text-xl;
        }

        h3 {
            @apply text-lg;
        }

        h5 {
            @apply text-lg;
        }

        h6 {
            color: unset;
        }
    }
}

.markdown-it-container {
    $baseFontSize: 18;

    div {
        h1,
        h2,
        h3 {
            font-family: "Inter", sans-serif !important;
            font-size: calc($baseFontSize * 1.618) px !important;
        }

        p {
            line-height: unset;
            margin-bottom: 0px;

            strong {
                font-family: "Inter", sans-serif !important;
            }
        }
    }

    div {
        ul {
            line-height: unset;
            margin: unset !important;
            padding: unset !important;

            li {
                margin-left: 1.5rem !important;
                padding-left: 0.5rem !important;
                @apply text-base-600 dark:text-base-300;
                //@apply mb-5;
                //margin-bottom:20px!important;
            }
        }
    }

    #isolated-component,
    #isolated-component * {
        /* Reset styles for the target element and its descendants */
        font-family: inherit;
        font-size: 100%;
        font-weight: inherit;
        line-height: inherit;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        text-decoration: none;
        color: inherit;
        border: none;
        background: transparent;
        /* Add any other styles you want to reset */
    }

    .markdown-it-container ul.contains-task-list > li input[type="checkbox"] {
        top: 0px !important;
    }

    ul.contains-task-list {
        padding-left: 0;
        list-style-type: none !important;

        li {
            position: relative;
            padding: 0 0 0 1em !important;
            //top: 0% !important;
            > ul {
                > li {
                    position: relative;
                    padding: 0 0 0 1em !important;

                    input[type="checkbox"] {
                        //top: 50% !important;
                    }
                }
            }

            input[type="checkbox"] {
                position: absolute;
                top: 50% !important;
                left: -20px !important;
                width: 1.1em !important;
                min-width: 1.1em !important;
                height: 1.1em;
                margin-top: 0px;
                transform: translateY(-50%);
                border: 2px solid #e3e4e6;
                border-radius: 3px;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                &:checked {
                    border: 2px solid transparent;
                    @apply bg-base-100;
                    @apply border;
                    @apply border-base-200;

                    &::before {
                        font: var(--fa-font-solid);
                        font-family: "Font Awesome Kit"; /* note that you need to use "font-family" for custom icons */
                        font-size: 0.85em !important;
                        font-weight: bold;
                        top: -0.15em;
                        left: 0.22em;
                        //content: "✓ ";
                        @apply text-base-900;
                        margin-top: 0px;
                        padding: 0px;
                        padding-top: 0px;
                        //background:red;
                        content: "";
                        //content:'✔';
                        @apply flex;
                        @apply justify-center;
                        @apply items-center;
                        @apply w-full;
                        @apply h-full;
                    }
                }
            }
        }
    }

    .contains-task-list {
        li {
            input[type="checkbox"] {
                //top: 25% !important;
                //background:blue;
            }

            ul {
                input {
                    //background: red !important;
                    top: 50% !important;
                }
            }
        }
    }
}
</style>
