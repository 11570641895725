function logBrowser(browser) {
    try {
        let { url, page, links, images, metadata, article, finalResponse, summary, tokens, summary_tokens, request, data } = browser;
        let cleanURL = new URL(url);
        console.groupCollapsed("%c📕 Browse URL", purple, cleanURL.host);
        console.log("url", url);
        if (summary) console.log("Summary\n", summary);
        if (page) {
            console.groupCollapsed("Full Page");
            console.log(`\n%c${page}`, "color:#aaa;font-size:8px;");
            console.groupEnd();
        }
        if (links) console.log("links", links);
        if (images) console.log("images", images);

        if (metadata) {
            console.groupCollapsed("Metadata");
            console.table(metadata);
            console.groupEnd();
        }
        if (request) console.log("Request", request);
        if (article) console.log("article", article);
        if (summary_tokens) console.log("Summary Tolens", summary_tokens);
        if (tokens) console.log("Tokens", tokens);
        if (finalResponse) console.log("response", finalResponse);
        if (article || data) {
            console.groupCollapsed("Raw");
            console.log(article || data);
            console.groupEnd(); // console.log(data.content)
        }
        console.trace();
        console.groupEnd();
    } catch (error) {
        console.error(error);
    }
}
export default logBrowser;
