// directives.js

export const delay = {
    bind(el, binding) {
        console.error("delay: ");
        const duration = binding.value || 500;
        const elements = el.querySelectorAll(binding.arg);

        elements.forEach((element, index) => {
            element.style.animationDelay = index * duration + "ms";
            element.style.transitionDelay = index * duration + "ms";
        });
    },
};

export const typingAnimation = {
    mounted(el, binding, vnode) {
        el.updateTypingString = baseString => {
            el.typingString = [`${baseString}`, `${baseString}.`, `${baseString}..`, `${baseString}...`];
        };

        el.defaultPlaceholder = vnode.props.placeholder;

        el.startTypingAnimation = () => {
            el.typingInterval = setInterval(() => {
                el.placeholder = el.typingString[el.typingIndex];
                el.typingIndex = (el.typingIndex + 1) % el.typingString.length;
            }, 300);
        };
        el.stopTypingAnimation = () => {
            clearInterval(el.typingInterval);
            el.placeholder = vnode.props.placeholder;
        };

        el.changeAnimationState = () => {
            if (binding.instance.isLoading && binding.instance.showIndicator) {
                el.updateTypingString(binding.instance.showIndicator);
                el.startTypingAnimation();
            } else {
                el.stopTypingAnimation();
            }
        };

        vnode.props.onUpdate_isLoading = el.changeAnimationState;
        vnode.props.onUpdate_showIndicator_prop = el.changeAnimationState;

        el.changeAnimationState();
    },
    unmounted(el) {
        el.stopTypingAnimation();
    },
};

// directives.js

export const inputLoader = {
    mounted(el, binding) {
        const { placeholder, isLoading, showIndicator } = binding.value;

        const defaultPlaceholder = placeholder;
        el.startTypingAnimation = baseString => {
            el.typingIndex = 0;
            baseString.replace("...", "");
            el.typingString = [`${baseString}`, `${baseString} .`, `${baseString} ..`, `${baseString} ...`];

            el.typingInterval = setInterval(() => {
                el.placeholder = el.typingString[el.typingIndex];
                el.typingIndex = (el.typingIndex + 1) % el.typingString.length;
            }, 300);
        };

        el.stopTypingAnimation = () => {
            clearInterval(el.typingInterval);
            el.typingInterval = null;
            el.placeholder = defaultPlaceholder;
        };

        if (isLoading && showIndicator) {
            el.startTypingAnimation(showIndicator);
        }
    },

    beforeUnmount(el) {
        el.stopTypingAnimation();
    },

    updated(el, binding) {
        const { isLoading, showIndicator } = binding.value;

        if (isLoading && showIndicator) {
            if (!el.typingInterval) {
                el.startTypingAnimation(showIndicator);
            }
        } else {
            if (el.typingInterval) {
                el.stopTypingAnimation();
            }
        }
    },
};
