<template>
    <div>
        <ModifyAssistant v-if="createNewAssistant" />
        <div class="grid grid-cols-12 vh-100 overflow-hidden p-fixed p-fill pt-nav">
            <div class="col-span-2 scrollSection mh-90 vh-100 z-0">
                <div class="f fc scrollbar-hide">
                    <div v-for="(a, index) in assistants" class="bg-gray-50 x">
                        <CurrentChatInfo :key="'a' + index" :assistant="a" />
                    </div>
                </div>
            </div>
            <div class="col-span-10 f fc mh-90 relative">
                <div class="y">
                    <CurrentChatInfo class="border-bottom block" :key="currentAssistant.id" v-if="currentAssistant" :assistant="currentAssistant" :active="true" />
                    <!--            <div class="f gap-3 overflow-x-scroll scrollbar-hide pl-3 py-3 border-bottom" v-if="files">-->
                    <!--                <div class="flex-shrink-0" v-for="{ filename, bytes, object, id, ...rest } = file in files">-->
                    <!--                    <file-upload-card :key="id" :file="{ name: filename, size: bytes, type: object }" file-preview=""></file-upload-card>-->
                    <!--                </div>-->
                    <!--            </div>-->
                </div>
                <div class="relative vh-60 f fc">
                    <div class="overflow-scroll x h-auto f fc px-5">
                        <div v-for="(message, index) in conversation">
                            <ThreadMessage v-if="message.object === 'thread.message'" :key="'m' + index" :message="message" />
                            <template v-else>
                                <ThreadRun :key="'r' + index" :run="message" />
                                <model-badge :model="message.model" />
                            </template>
                        </div>
                    </div>
                    <!--       make a button group for threadsList items   -->
                    <div class="">
                        <div class="gap-3 f height-100">
                            <div v-for="(thread, index) in threadList" :key="'thread' + index" class="f aic jcc">
                                <BaseButton :label="thread" @click.prevent="getThreads(thread)" />
                            </div>
                        </div>
                    </div>
                    <div class="px-5 f aic jcc bottom x mx-auto left right">
                        <textarea type="text" v-model="inputText" @keyup.enter="sendAssistantMessage(inputText)" />
                    </div>
                    <transition name="fade-up">
                        <div class="f aic jcc z-10" v-if="assistantLoading">
                            <loading-spinner class="p-1 px-3 rounded-full mt-3 bg-snow border-light" :color="'#aa11ff'"></loading-spinner>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ThreadMessage from "@/views/Chat/ThreadMessage.vue";
import ThreadRun from "@/views/Chat/ThreadRun.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import CurrentChatInfo from "@/views/Chat/CurrentChatInfo.vue";
import FileUploadCard from "@/components/images/FileUploadCard.vue";
import ModelBadge from "@/views/Chat/ModelBadge.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ModifyAssistant from "@/views/Chat/ModifyAssistant.vue";

export default {
    name: "ThreadViewerMessages",
    components: { ModifyAssistant, BaseButton, ModelBadge, FileUploadCard, CurrentChatInfo, LoadingSpinner, ThreadRun, ThreadMessage },
    data() {
        return {
            inputText: "",
            threadList: ["thread_jIdDd1Y3jgCksTPu3umrkiVu", "thread_ebQBFIerov1liZIMCh2y5K7j", "thread_2zGcr3tCEFkQoP28gtSFFCnX"],
            assistantLoading: false,
        };
    },
    computed: {
        ...mapGetters("assistants", ["user", "threads", "runs", "assistants", "threadId", "assistantId", "conversation", "currentAssistantFiles"]),
        files() {
            return this.$store.state.stream?.stream?.options?.files || [];
        },
        currentAssistant() {
            if (!this.assistants) return;
            let assistantId = this.$store.state.assistants.assistantId;
            let assistant = this.assistants.find(asst => asst.id === assistantId);
            return assistant ? assistant : {};
        },
    },
    async mounted() {
        this.$store.dispatch("assistants/setThreadId", this.threadList[0]);
        if (!this.conversation.length) await this.getThreads();
        if (!this.assistants?.length) await this.getAssistants();
        if (!this.files?.length) await this.getFiles();
    },
    methods: {
        async getFiles() {
            let files = await this.listFiles();
            this.updateStreamProp("options.files", files);
        },
        async getAssistants() {
            await this.$store.dispatch("assistants/listAssistants");
        },
        async getThreads(id) {
            if (!id) id = this.$store.state.assistants.threadId;
            await this.$store.dispatch("assistants/getThreads", id);
        },
        async sendAssistantMessage(message) {
            this.assistantLoading = true;
            let object = {
                message: message,
                userId: "asher",
                threadId: this.threadId,
                assistantId: this.assistantId,
                model: gpt3,
            };
            this.inputText = "";
            await this.$store.dispatch("assistants/sendAssistantMessage", object);
            this.assistantLoading = false;
        },
    },
};
</script>
