<template>
    <div class="f fc task-list prose-lime gap-2 bg-snow">
        <h6 class="fwb mb-0 mt-0 text-black dark:text-snow">
            <i class="fwb fas fa-check mr-2 text-green"></i>
            TASKS
        </h6>
        <ol class="jcs prose aic px-4">
            <transition-group name="highlight" tag="div" enter-from-class="animated text-amber-300" appear>
                <li v-for="(task, index) in tasks" :key="index" class="inline_task_item mb-0 pb-0">
                    <div class="f task_text aic">
                        <transition enter-from-class="animated text-amber-300" enter-to-class="text-gray-600 dark:text-base-400" appear>
                            <span class="">{{ task }}</span>
                        </transition>
                        <ButtonRemoveListItem @click.prevent="removeTask(task)" />
                    </div>
                </li>
            </transition-group>
        </ol>
        <div v-if="toggleAdd" class="mt-3 block">
            <BaseInput :id="randomId()" ref="input" :modelValue="newTask" :rows="1" class="mb-0" placeholder="Add a task" inline @blur="toggleAddMemory" @keyup.enter="addTask" @update:modelValue="newTask = $event" />
        </div>
        <div v-else class="block">
            <ButtonAddNew class="br-10 border-light mt-3 inline-flex cursor-pointer px-3 hover:bg-snow dark:hover:bg-base-600/50" text="Add task" @click.prevent="toggleAddMemory" />
        </div>
    </div>
</template>

<script>
import taskCommand from "@/mixins/Chat/Commands/TaskCommand";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import processCommands from "@/mixins/Chat/Commands/processing/ProcessCommands";
import ButtonAddNew from "@/views/Chat/ButtonAddNew.vue";
import ButtonRemoveListItem from "@/views/Chat/ButtonRemoveListItem.vue";

export default {
    name: "TaskManager",
    components: { ButtonRemoveListItem, ButtonAddNew, BaseInput },
    mixins: [taskCommand, processCommands],
    data() {
        return {
            newTask: "",
            toggleAdd: false,
        };
    },
    methods: {
        toggleAddMemory() {
            this.toggleAdd = !this.toggleAdd;
            if (this.toggleAdd) {
                this.$nextTick(() => {
                    this.$refs.input?.$refs.input?.focus();
                });
            }
        },
    },
    mounted() {
        if (this.$store.state.chat.tasks && this.$store.state.chat.tasks.tasks && this.$store.state.chat.tasks.tasks.length > 0) {
        } else {
            this.$store.dispatch("chat/tasks/fetchTasks");
        }
    },
};
</script>
<style lang="scss">
.task-list{
  h5{
    @apply text-sm;
  }

  .task_text{
    @apply text-sm;
  }

  /* Initial State */
  .highlight-enter-active{
    transition: background-color 2s ease, color 2s ease;
  }

  /* End State */
  .highlight-enter-to{
    background: transparent;
    color: gray;
  }

  .highlight-enter{
    background: yellow !important;
    color: black !important;
  }
}
</style>
