<template>
    <div>
        <panel-u-i><button-showcase></button-showcase></panel-u-i>
    </div>
</template>

<script>
import ButtonShowcase from "@/components/CoreUI/buttons/ButtonShowcase.vue";
import PanelUI from "@/components/images/PanelUI.vue";

export default {
    components: { ButtonShowcase, PanelUI },

    data() {},
    mounted() {},
};
</script>
