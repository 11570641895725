<template>
    <div ref="inputSection" class="chat input-section bottom left right p-relative overflow-visible" @keydown="keydown($event)" @keyup="keyup($event)" @keydown.esc="wantsToStopGenerating">
        <div v-if="!mobile" :class="{ 'gradient-transparent-white': !darkMode, gradientBottom: darkMode }" class="x z-4 bottom left height-min-100 fixed mr-32"></div>

        <div class="mw-800 x p-relative p-fixed bottom z-5 dark:bg-base-800" ref="inputContainer" :class="{ 'br-0': mobile, 'border-light br-15': !mobile }">
            <div :class="{ 'mw-800 x border-light p-relative p-fixed bottom br-15 mx-auto mb-3 bg-gray-100 p-2 dark:bg-base-800': !mobile, 'br-0': mobile }">
                <div ref="bottomButtons" class="x z-5 left right f aic jcc absolute gap-3 py-3">
                    <BaseButton v-if="lastMessageIsUser || lastMessageIsError" class="animated swingInUp" style-type="secondary" label="Resend message" @click.prevent="triggerSendMessage" />
                    <!--                    <BaseButton class="animated swingInUp" style-type="secondary" label="Continue message" @click.prevent="continueCompletion()" />-->
                    <BaseButton v-if="lastMessageIncomplete" class="animated swingInUp" style-type="secondary" label="Continue message" @click.prevent="continueCompletion()" />
                    <BaseButton v-if="typing" class="animated swingInUp text-2xl transition-all" icon="fal fa-stop" label="Stop generating" size="md" style-type="secondary" @click.prevent="wantsToStopGenerating" />
                </div>
                <div class="chatInput chat-wrapper" :class="{ 'br-5 border': !mobile, 'br-0  border-0  dark:bg-base-900': mobile }">
                    <div class="x p-relative">
                        <div class="p-relative x" ref="inputArea">
                            <textarea ref="aboutInput" @focus="adjustInputField" v-model="message" :placeholder="placeholderText" class="chat-text-area" rows="1" type="textarea" @input="handleInput" @keydown.enter="triggerSendMessage($event)" />
                            <!--                            <textarea ref="aboutInput" v-else @focus="adjustInputField" v-model="message" :placeholder="placeholderText" :style="`height: ${height}px!important; max-height: 75px!important;overflow-y:scroll;`" class="chat-text-area" rows="1" type="textarea" @input="handleInput" @keydown.enter="triggerSendMessage($event)" />-->
                            <div class="voice-section f small jcs aic block overflow-hidden p-2">
                                <RecordAudio ref="recordAudio" class="mr-3" @transcription="transcription" />
                                <span class="o-5">Length: {{ messageTokens }}</span>
                                <ChatInputURLDetector :message="message" />
                            </div>
                        </div>
                        <SummarizeLongMessages :message="message" :message-tokens="messageTokens" @summary-question="summaryQuestion" />
                    </div>
                    <div>
                        <loading-spinner v-if="typing" background="transparent" color="#ddd"></loading-spinner>
                        <div v-else class="f z-3 p-absolute top right bottom aic jcc pr-3 dark:text-base-300" @click.prevent="triggerSendMessage($event)">
                            <i aria-hidden="true" class="far fa-paper-plane o-5 m-0 p-0"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="typing" class="p-absolute p-fill z-5 o-2 bg-base-200"></div>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import { debounce } from "lodash";
import datingBot from "@/mixins/Chat/DatingBot";
import imageMixin from "@/mixins/images/imageMixin";
import usermixin from "@/mixins/usermixin";
import memoryMixin from "@/mixins/ai/memoryMixin";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import newChatMixins from "@/mixins/Chat/newChatMixins";
import chatCommands from "@/mixins/Chat/Commands/processing/ChatCommands";
import RecordAudio from "@/components/chat/audio/RecordAudio.vue";
import SummarizeLongMessages from "@/components/chat/SummarizeLongMessages.vue";
import ChatInputURLDetector from "@/components/chat/ChatInputURLDetector.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import getTokenCount from "@/mixins/ai/get_token_count";

export default {
    components: {
        BaseButton,
        ChatInputURLDetector,
        SummarizeLongMessages,
        RecordAudio,
        LoadingSpinner,
    },
    mixins: [imageMixin, datingBot, usermixin, memoryMixin, newChatMixins, chatCommands],
    data() {
        return {
            messageTokens: 0,
            message: "",
            inputSectionHeight: 0,
            models: [gpt3, gpt4],
            model: gpt3,
            height: 10,
            lastMessage: null,
            summaryQuestion: "",
            placeholderText: "Type a message...",
            isInputFocused: false,
            windowHeight: 0,
        };
    },
    props: {
        typing: {
            type: Boolean,
            default: false,
        },
        chatID: {
            type: String,
            default: "",
        },
        messages: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        lastMessageIncomplete() {
            return this?.messages[this?.messages?.length - 1]?.finish_reason === "length";
        },
        inputStyle() {
            return {
                bottom: this.isInputFocused ? `${this.windowHeight - window.innerHeight}px` : "0",
                "padding-left": "1rem",
                "padding-right": "1rem",
                "padding-bottom": "1rem",
            };
        },

        lastMessageIsUser() {
            const messages = this.messages;
            const lastMessageIndex = messages.length - 1;
            const lastMessage = messages[lastMessageIndex];
            if (lastMessage && lastMessage.role === "user") {
                return true;
            }
            return false;
        },
        lastMessageIsError() {
            const messages = this.messages;
            const lastMessageIndex = messages.length - 1;
            const lastMessage = messages[lastMessageIndex];
            if (lastMessage && lastMessage.kind === "error") {
                return true;
            }
            return false;
        },
    },
    inject: ["scrollChatWindowToBottom", "sendMessage"],
    watch: {
        typing(isTyping) {
            if (isTyping) {
                this.startTypingAnimation();
            } else {
                this.stopTypingAnimation();
            }
        },
        message() {
            this.messageUpdated();
        },
    },
    created() {},
    beforeDestroy() {
        window.removeEventListener("resize");
    },
    async mounted() {
        this.$refs.aboutInput.focus();
        this.inputSectionHeight = this.$refs.inputSection.clientHeight;
        this.$nextTick(() => {
            this.$refs.aboutInput.focus();
        });
        this.handleInput();

        this.$refs.aboutInput.focus();
        // window.addEventListener("resize", () => {
        //     this.windowHeight = window.innerHeight;
        // });
    },
    methods: {
        continueCompletion() {
            this.sendMessage(undefined, true);
        },
        buttonBottom() {
            if (this.$refs.inputContainer) {
                this.$nextTick(() => {
                    let inputSectionHeight = this.$refs.aboutInput.clientHeight;
                    let offset = this.mobile ? 40 : 75;
                    this.$refs.bottomButtons.style.bottom = inputSectionHeight + offset + "px";
                    return this.mobile ? inputSectionHeight + 75 : inputSectionHeight + 100 + "px";
                });
                return 0 + "px";
            }
        },
        adjustInputField() {
            if (this.mobile) {
                let keyboardHeight;
                setTimeout(() => {
                    let initialScreenSize = window.innerHeight;
                    window.scrollBy(0, 1); // Scroll the window to trigger re-calculation of innerHeight

                    let keyboardScreenSize = window.innerHeight;
                    window.scrollBy(0, -1); // Scroll back to original position

                    keyboardHeight = initialScreenSize - keyboardScreenSize;
                    this.$refs.inputArea.style.bottom = keyboardHeight + "px"; // Adjust the bottom position based on keyboard size
                    this.windowHeight = keyboardHeight - this.inputSectionHeight;
                    this.buttonBottom();
                }, 400); // Timeout is here to ensure this code executes after keyboard is fully open
            }
            return;
        },
        // cmd+r triggers record in recording component
        keydown(event) {
            if (event.code === "KeyR" && event.altKey) {
                event.preventDefault();
                console.log("alt + r pressed");
                if (this.$refs.recordAudio && this.$refs.recordAudio.startRecordingMediaRecorder) {
                    this.$refs.recordAudio.startRecordingMediaRecorder();
                }
            }
        },
        keyup(event) {
            // console.log("Key up event fired: " + event.code);
            if (event.code === "KeyR" || event.code === "AltLeft" || event.code === "AltRight") {
                // console.log("alt or r released");
                event.preventDefault();
                if (this.$refs.recordAudio && this.$refs.recordAudio.stopRecordingMediaRecorder) {
                    this.$refs.recordAudio.stopRecordingMediaRecorder();
                }
            }
        },
        transcription(text) {
            this.message = text;
            this.handleInput();
            this.triggerSendMessage();
        },
        async wantsToStopGenerating() {
            this.$emit("stopGenerating");
        },
        async getMessageTokens() {
            let count = await getTokenCount(this.message, this.$store.state.chat.model);
            this.messageTokens = count;
        },
        // debounce get message
        messageUpdated: debounce(function async() {
            if (this.message && this.message.length && this.message.length > 0) {
                this.getMessageTokens();
            }
        }, 400),
        async triggerSendMessage(event) {
            // emit send message with message
            if (this.typing) return;
            const message = this.message;
            if (!event || (event && !event.shiftKey)) {
                if (event) {
                    event.preventDefault();
                }
                try {
                    const message = this.message.trim();
                    if (message && message.length && message.length > 0) {
                        this.$store.dispatch("chat/addMessageStore", userMessage(this.message));
                        this.message = "";
                    }
                } catch (error) {
                    console.log(error);
                }
                this.$emit("triggerSendMessage", message);
                this.resetInput();
            }
        },
        handleInput() {
            const el = this.$refs.aboutInput;

            // Clone the node
            const clone = el.cloneNode(true);
            // Insert the clone into the DOM
            el.parentNode.insertBefore(clone, el.nextSibling);

            // Hide clone and reset style
            clone.style.visibility = "hidden";
            clone.style.height = "auto";

            let max_height;

            if (this.mobile && clone.scrollHeight > 150) {
                max_height = 150;
            } else if (clone.scrollHeight > 300) {
                max_height = 300;
            } else {
                max_height = clone.scrollHeight;
            }

            // Remove the clone
            el.parentNode.removeChild(clone);

            this.height = max_height;
            setTimeout(() => {
                const cssText = max_height === "auto" ? "height: auto!important; min-height: auto;" : `height: ${max_height}px!important; max-height: ${max_height}px!important; min-height: ${max_height}px;`;
                el.style.cssText = cssText;
                this.$store.dispatch("chat/updateInputHeight", this.height);
                this.buttonBottom();
            }, 10);

            this.$nextTick(() => {
                this.scrollChatWindowToBottom(true);
            });
        },

        resetInput() {
            this.message = "";
            this.messageTokens = 0;
            this.$nextTick(() => {
                this.handleInput();
            });
        },
        startTypingAnimation() {
            const typingString = ["Typing", "Typing.", "Typing..", "Typing..."];
            let typingIndex = 0;
            this.typingInterval = setInterval(() => {
                this.placeholderText = typingString[typingIndex];
                typingIndex = (typingIndex + 1) % typingString.length;
            }, 300);
        },

        stopTypingAnimation() {
            clearInterval(this.typingInterval);
            this.placeholderText = "Send a message";
        },
    },
};
</script>
<style scoped lang="scss">
//.chat-gradient {
    //.chat-gradient-light {
    //    background: linear-gradient(to bottom, rgba(255, 255, 225, 0), rgba(255, 255, 225, 1));
    //}
    //.dark {
    //    .chat-gradient-dark {
    //        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    //    }
    //}

//}

textarea {
    overflow: hidden;
    resize: none;
    border: none;
    padding: 10px;
    min-height: unset !important;
}
</style>
