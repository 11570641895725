<template>
    <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between f aic">
        <div>
            <h3 class="text-xl leading-6 text-inherit">Subscribe to our newsletter</h3>
            <p class="mt-2 text-md leading-6 text-inherit o-5">The latest news, articles, and resources, sent to your inbox weekly.</p>
        </div>
        <form class="sm:flex sm:max-w-md lg:mt-0">
            <label for="email-address" class="sr-only">Email address</label>
            <div class="f aic jcc">
                <input
                    type="email"
                    name="email-address"
                    id="email-address"
                    autocomplete="email"
                    required=""
                    class="w-full mb-0 min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-56 sm:text-sm sm:leading-6"
                    placeholder="Enter your email" />
                <div class="mt-0 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                    <button type="submit" class="border border-1 border-indigo-400 flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 button-1">
                        Subscribe
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "FooterNewsletter",
};
</script>
