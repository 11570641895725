<template>
    <div class="relative text-snow z-0" :class="{ 'aspect-h-4 aspect-w-3': brand.image && !related, 'col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2': !scroll && !related, 'aspect-w-5 aspect-h-3': related, 'aspect-h-7 aspect-w-3': scroll && mobile }" @mouseenter="hovered = true" @mouseleave="hovered = false">
        <div v-if="brand.url" class="f gap-3 br-10 overflow-hidden block shadow p-2 cursor-pointer x y" :class="{ 'bg-gray-900 aspect-square text-snow': brand?.match, 'bg-gray-900 button-2 br-20 hover:bg-gray-900 hover:text-snow': !brand?.match }" style="border-radius: 20px" @click.prevent="selectBrand(brand)">
            <!--        <div v-if="brand.url" class="f gap-3 br-10 overflow-hidden block shadow p-2 cursor-pointer bg-snow hover:bg-gray-900 text-gray-900 hover:text-snow" :style="styleColors" :class="{ '!bg-red-100 !text-blue-600': brand?.match }">-->
            <template v-if="brand.image">
                <div class="p-absolute p-fill" v-if="backgroundColor" :style="`background:${backgroundColor}`"></div>
                <div v-if="brand.image" class="p-absolute p-fill"><img :src="image" class="o-cover width-300 height-400 x y" /></div>
                <div class="p-absolute top left right y" style="background: linear-gradient(160deg, rgba(0, 0, 0, 0.3) 0%, transparent 50%)" v-if="!hideGradient"></div>
            </template>
            <div v-else class="aspect-square"></div>
            <div class="z-2 p-absolute top left right p-4 mt-auto line-clamp-2">
                <h1 class="block fwb text-balance" style="font-size: 2em !important; letter-spacing: normal !important" v-if="brand.name">{{ brand.name }}</h1>
            </div>
            <div class="z-1 p-absolute bottom left right p-4 mt-auto">
                <p class="f-15 o-9 mb-0 text-balance line-clamp-2 text-capitalize" v-if="brand?.description">{{ brand?.description?.replace(brand.name, "") }}</p>
            </div>
            <router-link v-if="brand.match" :to="`/styleGuide/${brand.match}`" class="absolute p-fill x y z-5" style="z-index: 1000"></router-link>
            <div v-else class="x y z-5 block aspect-square x y" style="z-index: 10000"></div>
            <!--            <slot></slot>-->
            <div class="absolute top right p-3 z-5" v-if="hovered">
                <BaseButton icon="fas fa-times" @click.prevent="$store.dispatch('styleGuide/deleteStyleGuide', brand)" icon-only size="xxs"></BaseButton>
            </div>
        </div>
    </div>
</template>
<script>
import findMatchingObject from "@/mixins/firebase/findMatchinObject";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import { findBrand } from "@/mixins/ai/find_brand";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "HomePageBrandButton",
    components: { BaseButton },
    props: {
        brand: {
            type: Object,
            default: {},
        },
        scroll: {
            type: Boolean,
            default: false,
        },
        hideGradient: {
            type: Boolean,
            default: false,
        },
        related: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hovered: false,
        };
    },
    methods: {
        openInNewWindow(primaryBrand) {
            // Resolve the route to get the full URL
            const routeData = this.$router.resolve({
                path: `/styleGuide/${primaryBrand.id}`,
                // Add query and params if needed
            });

            // Open the URL in a new window
            window.open(routeData.href, "_blank");
        },
        async selectBrand(brand) {
            if (brand.image) return;
            logGroup("selectBrand", { ...brand });
            if (!brand) brand = this.brand;
            // find index baseed on the url in this.styleGuides
            let id = brand.id;
            let url = brand.url;
            let name = brand.name;
            let primaryBrand = await findBrand(id, url, name, "brands");
            if (primaryBrand?.id) {
                logGroup("Primary Brand", { ...primaryBrand });
                await saveToFirebase("brandsList", { ...brand, match: primaryBrand.id }, id);
                this.$router.push(`/styleGuide/${primaryBrand.id}`);
            }
            if (!primaryBrand || !primaryBrand?.id) {
                // this.offerNew = true;
                console.log("creating new");
                let newBrand = await saveToFirebase("brands", { ...brand, match: id }, id);
                let updated = await saveToFirebase("brandsList", { ...brand, match: newBrand.id }, id);
                console.log("updated", updated);
                this.$router.push(`/styleGuide/${newBrand.id}`);

                // await this.createStyleGuide(url, newBrand.id, { newGuide: true, id: newBrand.id });
            }
            console.log("HELLLOOOOOOOO");
        },
    },
    computed: {
        image() {
            let url = this.brand?.image?.url || this.brand?.image;
            if (url && this.related) url = url.replace("Original", "width=500,height=300,fit=crop,gravity=auto");
            else if (url) url = url.replace("Original", "width=300,height=400,fit=crop,gravity=auto");
            return url;
        },
        textColor() {
            if (this.brand?.divStyles?.color) return `color: ${this.brand.divStyles.color}!important;`;
            else if (this.image) return `color: white!important;`;
        },
        gradientColor() {
            if (this.brand?.divStyles?.backgroundColor) return this.brand.divStyles.backgroundColor;
            else return `rgba(0,0,0,0.5)`;
        },
        backgroundColor() {
            if (this.brand?.divStyles?.backgroundColor) return this.brand.divStyles.backgroundColor;
            else return `rgba(0,0,0,0.5)`;
        },
        styleColors() {
            let style;
            if (this.brand.divStyles) {
                // if (this.brand?.image?.backgroundColor) {
                //     let { color, backgroundColor } = this.brand.image;
                //     return `background: ${backgroundColor}!important; color: ${color}!important;`;
                // } else {
                let { color, backgroundColor } = this.brand.divStyles;
                return `background: ${backgroundColor}!important; color: ${color}!important;`;
                // }
            }
            return "";
        },
        hoverScale() {
            if (this.hovered) {
                return "transform: scale(2)!important;";
            }
            return " transform: scale(1);";
        },
    },
};
</script>
<style scoped lang="scss">
.brand_button {
    //brand_button cursor-pointer f-30 px-3 pb-1 pt-1 pb-2 line-height-small hover:opacity-100 opacity-20 hover:bg-red-600 hover:text-snow hover:scale-110 transition-all block rounded-full
    &:hover {
        transform: scale(1.5);
    }
}
</style>
<!--                <div class="inline-block z-10 px-2 br-10 py-1 text-white bg-green f-15" >Get brand</div>-->
<!--                <h1 class="md:mb-2 block fwb !f-30 md:!f-50 line-clamp-1 text-balance" style="font-size: 1.5em !important; letter-spacing: normal !important" v-if="brand.name">{{ brand.name }}</h1>-->
<!--                <p class="line-clamp-2 f-15 o-5 mb-0 text-balance" v-if="brand?.description">{{ brand?.description?.replace(brand.name, "") }}</p>-->
<!--                <div class="p-absolute bottom left right height-150" style="background: linear-gradient(transparent, rgba(0, 0, 0, 0.5))" v-if="!hideGradient"></div>-->
<!--                <div class="p-absolute bottom left right height-100" :style="`background:linear-gradient(transparent,${gradientColor})`" v-if="!hideGradient"></div>-->
