import updateMessageProp from "@/mixins/ai/update_message_prop";
import gptError from "@/mixins/ai/gpt_error";

async function processContent(store, response, whileProcessing, model) {
    console.log("Processing content", model);
    let reader;
    reader = response.body.getReader();

    const utf8Decoder = new TextDecoder("utf-8");
    let processingLine = "";
    let messageContent = "";
    let functionContent = "";
    let functionName = "";
    let functionArgs = "";
    let msg;
    let functionCall;

    try {
        // Loop through the response and process it chunk by chunk
        while (true) {
            const { value: chunk, done } = await reader.read();

            if (done) break;

            // Decode and process the chunk, and check for a line break
            const decodedChunk = utf8Decoder.decode(chunk);
            processingLine += decodedChunk;

            const lineBreakIndex = processingLine.lastIndexOf("\n");
            let shouldAddLine = lineBreakIndex >= 0;
            if (shouldAddLine) {
                // If a line break is found, split the string into separate lines up to the last line break
                const lines = processingLine.slice(0, lineBreakIndex).split("\n");
                // Remove the processed lines from processingLine, leaving only unprocessed content
                processingLine = processingLine.slice(lineBreakIndex + 1);
                window.streamingText = messageContent;
                window.functionContent = "";
                // Iterate through the lines array
                for (const line of lines) {
                    // Check if the line starts with 'data: ', which indicates it is a relevant piece of data
                    if (line.trim().startsWith("data:")) {
                        // Remove the 'data: ' prefix to obtain a usable message
                        const message = line.replace(/^data: /, "");
                        let json;
                        try {
                            json = JSON.parse(message);
                            if (json.finish_reason) {
                                console.log("%cFINISH REASON", json.finish_reason, fail);
                            }
                        } catch (e) {
                            // console.log("JSON PARSE ERROR", e);
                            // console.log(JSON.stringify(message));
                        }

                        if (message === "[DONE]" || json?.done) break;

                        let token = "";
                        let function_arguments = "";
                        let function_name = "";
                        if (model?.includes("claude")) {
                            token = json.completion;
                        } else {
                            if (json && json.choices && json.choices.length > 0 && json.choices[0].delta) {
                                msg = json.choices[0].delta;
                                if (msg.stop_reason === "function_call") {
                                    console.log("%cSTOP REASON", msg, fail);
                                    console.log("%cSTOP REASON", msg.stop_reason, fail);
                                }
                                token = json.choices[0].delta.content;
                                if (msg) msg.content += token;
                                if (msg.function_call) {
                                    if (msg.function_call.name) {
                                        functionName = msg.function_call.name;
                                        window.functionContent = functionName;
                                        function_name = { name: functionName, content: "", arguments: {} };
                                    }
                                    if (msg.function_call.content) {
                                        token = msg.function_call.content;
                                    }
                                    if (msg.function_call.arguments) {
                                        functionArgs += msg.function_call.arguments;
                                        function_arguments = msg.function_call.arguments;
                                        window.functionContent += functionArgs;
                                    }
                                }
                                if (json.choices[0].finish_reason) {
                                    try {
                                        console.log("FINISH REASON: ", json.choices[0].finish_reason);
                                        updateMessageProp(store, "finish_reason", json.choices[0].finish_reason);
                                    } catch (e) {
                                        console.error(e);
                                    }
                                }
                                if (msg.finish_reason && msg.finish_reason === "function_call") {
                                    console.log("%cSTOP REASON", msg, fail);
                                    console.log("%cSTOP REASON", msg.stop_reason, fail);
                                    window.functionContent += msg.function_call.arguments;
                                }
                            }
                        }
                        // If the token has content, append it to the messageContent variable
                        if (token) {
                            messageContent += token;
                            functionContent += functionArgs;
                            whileProcessing(token, "");
                            // callFunction(functionContent);
                            window.streamingText = messageContent;
                            window.functionContent = functionContent;
                        }
                        if (function_name) {
                            whileProcessing("", function_name);
                        }
                        if (function_arguments) {
                            whileProcessing("", "", function_arguments);
                        }

                        if (functionName) {
                            let args = {};
                            try {
                                args = JSON.parse(functionArgs);
                                let object = {
                                    name: functionName,
                                    content: messageContent,
                                    arguments: args,
                                };
                                // console.log(object);
                            } catch (e) {
                                // console.log(e);
                            }

                            // console.log(functionName);
                            // console.log(functionArgs);
                        }
                    }
                }
            }
        }
    } catch (err) {
        gptError(err);
        console.log(err);
    }

    let functionObject = {
        name: functionName,
        arguments: functionArgs,
        content: functionContent,
    };

    return {
        messageContent: messageContent,
        functionObject: functionObject,
    };
}
export default processContent;
