import { ref as storageRef } from "firebase/storage";
import JSON5 from "json5";
import streamingMixin from "@/mixins/ai/streamingMixin";
import { debounce } from "lodash";
import { mapActions } from "vuex";
import EnhancePresentationColors from "@/mixins/presentations/EnhancePresentationColors";
import EnhancePresentationImages from "@/mixins/presentations/EnhancePresentationImages";
import StableDiffusion from "@/mixins/images/StableDiffusion";
import PresentationCommands from "@/mixins/presentations/PresentationCommands";
import updateSlideProp from "@/mixins/ai/update_slide_prop";
import { mapGetters } from "vuex";

export default {
    created: function () {},
    mixins: [StableDiffusion, EnhancePresentationColors, EnhancePresentationImages, PresentationCommands],
    data() {},
    computed: {
        ...mapGetters("presentation", ["document", "extractPresentationText"]),
    },
    methods: {
        async enhancePresentation(skipImage) {
            // if (!this.document.summary) {
            //     await this.summarizePresentation();
            // }

            const promptStart = `${this.extractPresentationText}`;
            try {
                this.document.sections.forEach((section, index) => {
                    // for (const [index, section] of this.$store.state.document.sections.entries()) {
                    if (section.image && !section.enhancedImage && !section.imageURL) {
                        // const text = section.image;
                        // this.enhancedImagePrompt(text, index, promptStart, skipImage);
                    }
                    if (section.body && !section.enhancedBody) {
                        const text = section.body;
                        this.enhanceBody(text, index, promptStart, section);
                    }
                });
            } catch (error) {
                console.log(error);
            }
            try {
                // this.enhanceColors(this.extractPresentationText);
            } catch (error) {
                console.log(error);
            }
            try {
                this.alert("Done!");
            } catch (error) {
                console.log(error);
            }
            return;
        },
        getDefaultSlide(text = "", context = "", slideNumber = 1) {
            let systemMessage = context;
            let instructions = `\n
[INSTRUCTIONS]\nRewrite and expand on the body for Slide ${slideNumber}. 
- 50 words (max).
- This is the body of a presentations slide so make it very readable.
- Combine a paragraph with markdown (like bullet points, bolded content if it benefits information being conveyed.
- Stick to this slide's topic but feel free to lead into the following slides or make callbacks to prior slides if it provides better context. 
- Only respond with the "Body" text. 
- Do not prepend any unnecessary data.(ie. title, "body:", etc).`;
            let prompt = `${text}${instructions}`;
            return { prompt, systemMessage };
        },
        getFullBleedSlide(text = "", context = "", slideNumber = 1) {
            let systemMessage = context;
            let instructions = `\n
[INSTRUCTIONS]\nRewrite and expand on the body for Slide ${slideNumber}. 
- This is the body of a presentations slide so make it very readable.
- It should be very short — 10 words (max).
- Stick to this slide's topic but feel free to lead into the following slides or make callbacks to prior slides if it provides better context. 
- Only respond with the "Body" text. 
- Do not prepend any unnecessary data.(ie. title, "body:", etc).`;
            let prompt = `${text}${instructions}`;
            return { prompt, systemMessage };
        },
        getMatrixSlide(text = "", context = "", slideNumber = 1) {
            let format = {
                matrix: {
                    headers: ["H1", "H2", "H3"],
                    rows: [
                        {
                            title: "R1",
                            data: ["A", "B", "C"],
                        },
                        {
                            title: "R2",
                            data: ["X", "Y", "Z"],
                        },
                    ],
                },
            };
            let JSONString = `[FORMAT]\n${JSON.stringify(format, null, 0)}`;
            let instructions = `\n\n[INSTRUCTIONS]\nGenerate a detailed matrix related to Slide ${slideNumber}'s content. Respond only with the format below\n\n${JSONString}`;
            let prompt = `${text}${instructions}\n\n Only respond with the JSON:`;
            let systemMessage = context;
            return { prompt, systemMessage };
        },
        getTimelineSlide(text = "", context = "", slideNumber = 1) {
            let format = [
                {
                    date: "Event Date 1",
                    event: "Event Name 1",
                    description: "Description of Event 1",
                },
            ];
            let JSONString = `[FORMAT]\n${JSON.stringify(format, null, 0)}`;
            let instructions = `\n\n[INSTRUCTIONS]\nExpand on Slide ${slideNumber}'s content by generating a detailed timeline. Respond only with JSON. \n\n${JSONString}`;
            let prompt = `${text}${instructions}`;
            let systemMessage = context;
            return { prompt, systemMessage };
        },
        getTableGridSlide(text = "", context = "", slideNumber = 1) {
            let format = [
                {
                    title: "Title 1",
                    subtitle: "Subtitle 1",
                    description: "Description 1",
                },
            ];
            let JSONString = `[FORMAT]\n${JSON.stringify(format, null, 0)}`;
            let instructions = `\n\n[INSTRUCTIONS]\nGenerate a detailed table grid related to Slide ${slideNumber}'s content using the format below.\n\n${JSONString}`;
            let prompt = `${text}${instructions}`;
            let systemMessage = context;
            return { prompt, systemMessage };
        },
        // ...mapActions("presentation", ["setTitle", "getPresentation", "savePresentation", "addSlide", "updatePresentation", "setDocId", "clearSections"]),
        async buildEnhancedBodyPrompt(text, index, overview, section, document) {
            const slideNumber = index + 1;
            const summary = this.document.summary || "";
            const currentSlide = `[SLIDE ${slideNumber}]\n ${text.body || text}`;
            const context = this.getPresentationOverview(summary, overview);

            const styleMethodsMap = {
                timeline: this.getTimelineSlide,
                matrix: this.getMatrixSlide,
                tablegrid: this.getTableGridSlide,
                fullbleed: this.getFullBleedSlide,
                default: this.getDefaultSlide,
            };

            const method = styleMethodsMap[section.style] || styleMethodsMap["default"];
            const { prompt, systemMessage } = method(currentSlide, context, slideNumber);

            return { prompt, systemMessage };
        },
        slideClass(mode, index = this.index) {
            let background;
            let text;
            let border;
            let samples = [100, 200, 300, 400, 500, 600, 700, 800, 900];

            let style = Math.random() < 0.5 ? "dark" : "light"; // pick randomly between 'light' and 'dark';
            if (mode) {
                style = mode;
            }

            if (style === "dark") {
                background = `bg-dark-${this.randomSample(samples)}`;
                text = `text-light-${this.randomSample(samples)}`;
                border = `border-dark-${this.randomSample(samples)}`;
            } else if (style === "light") {
                background = `bg-light-${this.randomSample(samples)}`;
                text = `text-dark-${this.randomSample(samples)}`;
                border = `border-light-${this.randomSample(samples)}`;
            }
            if (this.document.sections[index].style === "fullbleed") {
                background = `bg-${style}-1000`;
            }
            this.textClass = text;
            this.bgClass = background;
            this.borderClass = border;
            updateSlideProp(this.$store, "text_class", text, index);
            updateSlideProp(this.$store, "mode", style, index);
            updateSlideProp(this.$store, "background_class", background, index);
            updateSlideProp(this.$store, "border_class", border, index);
            // return [background, text, border];
        },
        async enhanceBody(text, index, overview, section, doc) {
            try {
                let model, name, tokens, replace, key;

                switch (section.style) {
                    case "matrix":
                        model = gpt3; //gpt316;
                        name = "Enhanced Matrix";
                        tokens = 3000;
                        replace = true;
                        key = "matrix";
                        break;
                    case "fullbleed":
                        model = gpt3; //gpt316;
                        name = "Fullbleed";
                        tokens = 200;
                        replace = false;
                        break;
                    case "timeline":
                        model = gpt3; //gpt316;
                        name = "Enhanced Timeline";
                        tokens = 3000;
                        replace = true;
                        key = "timeline";
                        break;
                    case "tablegrid":
                        model = gpt3; //gpt316;
                        name = "Enhanced Table Grid";
                        tokens = 3000;
                        replace = true;
                        key = "list";
                        break;
                    default:
                        model = gpt3; //gpt316;
                        name = "Enhanced Body";
                        tokens = 3000;
                        replace = false;
                        key = false;
                        break;
                }
                let { prompt, systemMessage } = await this.buildEnhancedBodyPrompt(text, index, overview, section, doc);
                console.groupCollapsed(`%c ✨ Enhancing Body (slide ${index + 1})`, purple);
                console.log("Text:", text);
                console.log("Name:", name);
                console.log("Style:", section?.style);
                console.log("Model:", model);
                console.log("Tokens:", tokens);
                console.log("Replace:", replace);
                console.log("Key:", key);
                let contextString = `CONTEXT:\n${this.extractPresentationText}`;
                let summary = "";
                if (this.document.summary) summary = `SUMMARY:\n${this.document.summary}`;
                let messages = [sysMessage(summary)];
                if (this.document.research?.facts) {
                    messages.push(sysMessage(this.document.research.facts));
                    console.log(messages);
                }
                if (this.document.research.build) {
                    let buildContext = this.makeJSONReadable(this.document.research.build);
                    messages.push(sysMessage(buildContext));
                    console.log(buildContext);
                }
                messages.push(sysMessage(contextString));
                messages.push(userMessage(prompt));
                consoleTableReduced(messages, ["(index)"]);
                console.groupEnd();
                let response = await this.completion(messages, null, name, model, tokens);

                if (replace) {
                    updateSlideProp(this.$store, "enhancedBody", this.document.sections[index].body, index);
                    const parsedResponse = await this.processJSON(response);
                    updateSlideProp(this.$store, key, parsedResponse, index);
                } else {
                    updateSlideProp(this.$store, "enhancedBody", response, index);
                }

                await this.savePresentation(this.$route.params.id);
            } catch (e) {
                console.error(e);
                // await this.saveDoc(this.document);
            }
            return;
        },
    },
};
