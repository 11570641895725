import img2ImageMixin from "@/mixins/images/Img2ImageMixin";
import updateSlideProp from "@/mixins/ai/update_slide_prop";
import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import upresImage from "@/mixins/images/upres_image";
import replaceBackground from "@/mixins/images/replace_background";

export default {
    mixins: [img2ImageMixin],
    methods: {
        async stabilityImageToImageServer(url, prompt, customOptions = {}, newPrompt) {
            const endpoint = "stabilityImageToImage";
            const apiURL = `${this.apiURL}`;
            const requestURL = apiURL + endpoint;

            const defaultOptions = {
                init_image_mode: "IMAGE_STRENGTH",
                image_strength: 0.4,
                prompt_weight: 0.5,
                new_prompt_weight: 2,
                cfg_scale: 7,
                height: 512,
                width: 512,
                samples: 3,
                steps: 50,
                // clip_guidance_preset: "FAST_BLUE",
            };

            const options = { ...defaultOptions, ...customOptions };

            const request = {
                url: url,
                prompt: prompt,
                ...options,
            };
            if (newPrompt) request.newPrompt = newPrompt;

            const response = await fetch(requestURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(request),
            });

            const responseData = await response.json(); // Parse response JSON
            const imageURLs = [];
            const self = this;

            for (const image of responseData) {
                let url = await self.uploadImage(image);
                imageURLs.push(url);
            }

            console.log(responseData); // Log the response data
            console.log(imageURLs); // Log the response data

            return imageURLs; // Return the response data
        },
        async addImageVarationsToSection(index, url, prompt, customOptions, newPrompt) {
            console.error("getting image variations", index, url, prompt, newPrompt);

            const images = await this.stabilityImageToImageServer(url, prompt, customOptions, newPrompt);

            let imageObjectArray = images.concat(this.document.sections[index].images);
            // map each image to ab object with url, prompt, and newPrompt
            let updatedImageObjectArray = imageObjectArray.map(image => {
                return {
                    url: image,
                    prompt: prompt,
                    newPrompt: newPrompt || "",
                };
            });
            let existingImages = this.document.sections[index].images ? this.document.sections[index].images : [];
            updatedImageObjectArray = [...updatedImageObjectArray, ...existingImages];
            updateSlideProp(this.$store, "images", updatedImageObjectArray, index);
            await this.savePresentation(this.$route.params.id);
        },
        upresImage,
        replaceBackground,
        async uploadImageCF(blob, imageName = null, imageType = null) {
            try {
                // Setup Blob to FormData
                let formData = new FormData();

                formData.append("image", blob, imageName); //adding blob directly

                // Post the Blob data to your endpoint
                const response = await fetch(endpointURL("uploadImage"), {
                    method: "POST",
                    body: formData, //changed content to formData
                });

                // Throw an error if the response is not successful
                if (!response.ok) {
                    const message = `An error has occurred: ${response.status}`;
                    throw new Error(message);
                }

                const result = await response.json();

                console.log(result.imageUrl);
                return result.imageUrl;
            } catch (error) {
                console.error("Error:", error);
            }
        },
        base64ToBlob(base64, mimeType) {
            // Split the base64 string in data and contentType
            let block = base64.split(";");
            // Get the content type of the image
            let contentType = block[0].split(":")[1]; // In case you want to use the MIME type from the Data URL
            // get the real base64 content of the file
            let realData = block[1].split(",")[1];

            // Convert it to a blob to upload
            let byteString = atob(realData);
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeType || contentType });
        },
        async getStabilityImage(prompt = "a disappointed man with his head in his hand", size = { height: 512, width: 512 }, model = "stable-diffusion-512-v2-1", steps = 50, scale = 7, count = 1, negativePrompt = "", style = "cinematic") {
            console.error(`Stability request: ${prompt}`);
            const apiHost = "https://api.stability.ai";
            const apiKey = import.meta.env.VITE_STABILITY_API;
            if (!apiKey) throw new Error("Missing Stability API key.");
            // + ' | weird hands: -0.5 | distorted faces: -0.5 | sharp faces:1',

            const prompts = this.buildPrompts(prompt, negativePrompt);
            let request = {
                text_prompts: prompts,
                cfg_scale: scale,
                height: size.height,
                width: size.width,
                samples: count,
                steps: steps,
            };
            if (style) {
                request.style_preset = style;
            }
            console.groupCollapsed(`%c🎆 Get image`, purple, prompt);
            consoleTableReduced({ prompt, negativePrompt, ...request, model }, ["text_prompts"]);
            console.groupEnd();
            const response = await fetch(`${apiHost}/v1/generation/${model}/text-to-image`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${apiKey}`,
                },
                // clip_guidance_preset: mi,
                body: JSON.stringify(request),
            });
            if (!response.ok) {
                const err = JSON.parse(await response.text());
                console.error(err.message);
            }

            const responseJSON = await response.json();
            const imageArray = responseJSON.artifacts;
            // console.log(imageArray);
            let finalArray = [];
            for (let i = 0; i < imageArray.length; i++) {
                // let imageURL = await this.uploadImage(imageArray[i].base64);
                let imageBlob = this.base64ToBlob(imageArray[i].base64, "image/jpeg");
                let imageURL = await this.uploadImageCF(imageBlob);
                console.log(imageURL);

                let imageObject = {
                    url: imageURL,
                    prompt: prompt,
                };
                finalArray.push(imageObject);
            }
            // let imageURL = await this.uploadImage(responseJSON.artifacts[0].base64);
            return finalArray;
        },
        buildPrompts(mainPrompt, negativePrompt) {
            const prompts = [
                {
                    text: mainPrompt,
                    weight: 1.0,
                },
            ];

            if (negativePrompt) {
                prompts.push({
                    text: negativePrompt,
                    weight: -1.0,
                });
            }

            return prompts;
        },
        async makeAPIRequest(url, requestOptions) {
            return await fetch(url, requestOptions);
        },
        async handleError(response) {
            if (!response.ok) {
                const err = JSON.parse(await response.text());
                console.error(err.message);
                return err;
            }

            return await response.json();
        },
    },
    computed: {
        defaultModel() {
            return this.$store.state.image.stability.engine;
        },
        // selectedModelObject() {
        //     return this.models.find(model => model.id === this.StableDiffusionModelId) || {};
        // },
        // getModelFromID(modelId) {
        //     return this.models.find(model => model.id === modelId);
        // },
        StableDiffusionModel() {
            return this.$store.state.image.stability.engine;
        },
        StableDiffusionModelId() {
            return this.$store.state.image.stability.engine.id;
        },
    },
};
// async getStabilityImage(prompt = "Sample Text", size = { height: 512, width: 512 }, model = "stable-diffusion-768-v2-1", steps = 50, scale = 7, count = 1, negativePrompt = "") {
//     console.error(`Stability request: ${prompt}`);
//     const apiHost = "https://api.stability.ai";
//     const apiKey = import.meta.env.VITE_STABILITY_API;
//
//     if (!apiKey) throw new Error("Missing Stability API key.");
//
// const prompts = this.buildPrompts(prompt, negativePrompt);
//
//     const requestOptions = {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${apiKey}`,
//         },
//         body: JSON.stringify({
//             text_prompts: prompts,
//             cfg_scale: 7,
//             clip_guidance_preset: "FAST_BLUE",
//             height: size.height,
//             width: size.width,
//             samples: count,
//             steps: steps,
//         }),
//     };
//     const response = await this.makeAPIRequest(`${apiHost}/v1/generation/${model}/text-to-image`, requestOptions);
//     const responseJSON = await this.handleError(response);
//     let imageURL = await this.uploadImage(responseJSON.artifacts[0].base64);
//     return imageURL;
// },
