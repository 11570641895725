<template>
    <div>
        <h6 v-if="title">{{ title }}</h6>
        <ul class="pl-3 f-17 mt-3">
            <template v-if="typeof array === 'string'">
                <li>{{ array }}</li>
            </template>
            <template v-else>
                <li v-for="item in array" :key="item">
                    <p>{{ item }}</p>
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
export default {
    name: "ArrayToBulletPoints",
    props: {
        array: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: "",
        },
    },
};
</script>
<style lang="scss" scoped></style>
