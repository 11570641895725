<template>
    <component :is="type">
        <span ref="textBlock" class="p-relative d-block" :class="uniqueClass">
            <span :key="index" class="text-item p-relative d-inline-block" v-for="(word, index) in words">{{ word + "&nbsp;" }}</span>
        </span>
    </component>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { nextTick } from "vue";

gsap.registerPlugin(ScrollTrigger);

export default {
    props: {
        link: { type: String, default: "" },
        title: { type: String, default: "" },
        type: { type: String, default: "div" },
        end: { type: String, default: "bottom center" },
        start: { type: String, default: "top bottom" },
    },
    data: () => ({
        words: null,
        uniqueClass: null,
    }),
    mounted() {
        if (this.title) this.getWords();
        this.uniqueClass = `a${this.randomId()}`;
    },
    methods: {
        randomId() {
            return Math.floor(Math.random() * 10000);
        },
        getWords() {
            if (this.title) {
                this.words = this.title.split(" ");
                console.log(this.title.split(" "));
            }
            nextTick(() => {
                this.initScroll();
            });
        },
        initScroll() {
            const uniqueTextItem = `.${this.uniqueClass} .text-item`;
            gsap.fromTo(uniqueTextItem, { y: 20, opacity: 0 }, { scrollTrigger: { trigger: this.$refs.textBlock, start: this.start, end: this.end, scrub: true }, y: 0, opacity: 1, ease: "back.out", stagger: 0.3 });
        },
    },
};
</script>

<style lang="scss" scoped>
//.section-header {
//    font-size: 2vw !important;
//}
//
//.text-block {
//    p {
//        font-size: calc(0.5rem + 3vw) !important;
//        text-align: center;
//
//        &.smallText {
//            font-size: calc(0.5rem + 1vw) !important;
//        }
//    }
//}
</style>
