<template>
    <div class="border-right scrollSection vh-100 col-span-2 mt-nav">
        <div>
            <!--            <style-guide-search></style-guide-search>-->
            <ul class="list-none">
                <template v-for="(brand, index) in styleGuides.slice(0, 200)" :key="brand.id">
                    <BrandListItem @create-new="createNew" :brand="brand" />
                </template>
            </ul>
        </div>
    </div>
</template>
<script>
import BrandListItem from "@/components/styleGuide/ListOfGuides/BrandListItem.vue";
import { mapGetters } from "vuex";
import StyleGuideSearch from "@/components/styleGuide/ListOfGuides/StyleGuideSearch.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "StyleGuidesList",
    mixins: [styleGuideMixin],
    components: { StyleGuideSearch, BrandListItem },
    data: function () {
        return {
            // clean_url: clea,
        };
    },
    computed: {
        ...mapGetters("styleGuide", ["styleGuides"]),
    },
    async created() {
        // this.$nextTick(async () => {
        //     if (!this.styleGuides.length) {

        // } else {
        //     console.log("StyleGuidesList already has styleGuides"
        // this.getStyleGuides();
        // }
        // });
    },
    methods: {
        createNew(brand){
            this.$emit("createNew", brand);
        },
        async createNewStyleGuide(brand) {
            console.log("Creating new style guide", brand);
            await this.createStyleGuide(brand.url, brand.id, { newGuide: true, originalID: brand.id });
        },
        setStyleGuides(items) {
            this.$store.dispatch("styleGuide/setGuideList", items);
            // this.styleGuides = items;
        },
    },
};
</script>

<!--<template>-->
<!--    <div ref="scrollingDiv" class="border-right scrollSection h-auto col-span-2 mt-nav" @scroll="checkBottom">-->
<!--        <div>-->
<!--            <style-guide-search></style-guide-search>-->
<!--            <ul class="list-none">-->
<!--                <template v-for="(brand, index) in styleGuides" :key="brand.id">-->
<!--                    <BrandListItem :brand="brand" />-->
<!--                </template>-->
<!--            </ul>-->
<!--            <div v-if="loading">Loading...</div>-->
<!--            <div v-if="endOfList">End of List</div>-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--import BrandListItem from "@/components/styleGuide/BrandListItem.vue";-->
<!--import StyleGuideSearch from "@/components/styleGuide/StyleGuideSearch.vue";-->

<!--export default {-->
<!--    name: "StyleGuidesList",-->
<!--    components: { StyleGuideSearch, BrandListItem },-->
<!--    data() {-->
<!--        return {-->
<!--            styleGuides: [],-->
<!--            lastFetchedDate: null,-->
<!--            lastFetchedId: null,-->
<!--            loading: false,-->
<!--            endOfList: false,-->
<!--            limit: 30,-->
<!--        };-->
<!--    },-->
<!--    mounted() {-->
<!--        this.getStyleGuides();-->
<!--        this.$refs.scrollingDiv.addEventListener("scroll", this.checkBottom);-->
<!--    },-->
<!--    beforeDestroy() {-->
<!--        this.$refs.scrollingDiv.removeEventListener("scroll", this.checkBottom);-->
<!--    },-->
<!--    methods: {-->
<!--        async getStyleGuides() {-->
<!--            if (this.loading || this.endOfList) return;-->
<!--            this.loading = true;-->

<!--            try {-->
<!--                let newItems = await this.fetchPaginatedFromFirebase("brands", this.limit, this.lastFetchedDate, this.lastFetchedId);-->
<!--                console.log(newItems);-->
<!--                if (newItems.length === 0) {-->
<!--                    this.endOfList = true;-->
<!--                    return;-->
<!--                }-->
<!--                newItems = newItems.map(item => {-->
<!--                    return {-->
<!--                        id: item.id,-->
<!--                        name: item.name,-->
<!--                        url: item.url,-->
<!--                        websiteUrl: item.websiteUrl,-->
<!--                        updated: item.updated,-->
<!--                    };-->
<!--                });-->
<!--                const lastItem = newItems[newItems.length - 1];-->
<!--                this.lastFetchedDate = lastItem.updated;-->
<!--                this.lastFetchedId = lastItem.id;-->

<!--                this.styleGuides.push(...newItems);-->
<!--            } catch (error) {-->
<!--                console.error("Error fetching brands:", error);-->
<!--            } finally {-->
<!--                this.loading = false;-->
<!--            }-->
<!--        },-->

<!--        checkBottom(event) {-->
<!--            let element = event.target;-->
<!--            if (element.scrollHeight - element.scrollTop <= element.clientHeight + 10) {-->
<!--                console.log("Checking scroll position", element.scrollHeight, element.scrollTop, element.clientHeight);-->

<!--                this.getStyleGuides();-->
<!--            }-->
<!--        },-->

<!--        resetLoader() {-->
<!--            this.lastFetchedDate = null;-->
<!--            this.lastFetchedId = null;-->
<!--            this.styleGuides = [];-->
<!--            this.endOfList = false;-->
<!--            this.loading = false;-->
<!--        },-->
<!--    },-->
<!--};-->
<!--</script>-->

<!--<style scoped>-->
<!--/* Add any relevant styles here */-->
<!--</style>-->
