<template>
    <div>
        <div class="mx-auto x text-center mw-500" v-if="selectedAction">
            <h5 v-if="selectedAction.description">{{ selectedAction.description }}</h5>
            <p v-if="selectedAction.about">{{ selectedAction.about }}</p>
        </div>
        <div class="f gap-2 aic jcc flex-wrap mw-500 mx-auto">
            <template v-if="generativeFunctions?.length > 0">
                <BaseButton size="xs" v-for="f in generativeFunctions" rounded :icon="f.icon" @click.prevent="setAction(f)" :label="f.description" />
            </template>
        </div>
        <!--// make a button that calls getGenerativeFunctions-->
        <div class="f aic jcc gap-3 xs:flex-column md:flex-row mw-500 mx-auto mb-4" :class="{ 'flex-column': mobile }">
            <BaseButton size="xs" rounded @click.prevent="getGenerativeFunctions" label="Get generative functions" />
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "EasyActionPicker",
    components: { BaseButton },
};
</script>
