<template>
    <div class="chat-list-item" :class="{ '': !isCurrentChat, 'chat-active': isCurrentChat }">
        <template v-if="editTitle">
            <div class="f aic jcb">
                <input v-model="title" type="text" class="f-13 whitespace-wrap x m-0 border-0 p-0" @keydown.enter="updateTitle(title, chat, index)" placeholder="Title" />
                <div class="border-light p-1" @click.prevent="updateTitle(title, chat, index)">
                    <i class="fas fa-check"></i>
                </div>
            </div>
        </template>
        <div v-else>
            <router-link is="li" :to="'/chat/' + chat.id" @click="closeChatList" @mouseenter="hovered = true" @mouseleave="hovered = false">
                <div class="f aic jcb" :class="{ '': !isCurrentChat, '': isCurrentChat }">
                    <div class="x">
                        <span class="text-primary line-clamp-1 block mb-0 pb-0 transition" :class="{'pr-4':hovered}">{{ title }}</span>
<!--                        <template v-if="false && chat.messages && chat.messages.length && chat.messages[chat.messages.length - 1] && chat.messages[chat.messages.length - 1].content">-->
<!--                            <span class="text-primary line-clamp-1 block fwn o-5 transition" :class="{'pr-4':hovered}">{{ chat.messages[chat.messages.length - 1].content }}</span>-->
<!--                        </template>-->
                        <!--                        <cm p cclass="text-primary line-clamp-1" size="sm" weight="semibold">{{title}}</cm>-->
                        <div class="f x aic jcs gap-1 pr-2">
                            <Button
                                style-type="link"
                                class="f-11 small text-uppercase d-block chat-topic mix-blend-luminosity p-0 o-5"
                                :icon="`${chat.topic.slice(0, 2)}`"
                                v-if="chat.topic"
                                size="xs"
                                rounded
                                :label="chat.topic.slice(2)" />
                            <div class="chat-topic" v-else>
                                <!-- option / select dropdown for topic from prompts.starters array and tailwind -->
                                <select class="form-select" v-model="topic" @change="changeSubject(topic)">
                                    <option value="Set topic" disabled>Set topic</option>
                                    <template v-if="recentSubjects && recentSubjects.length && recentSubjects.length > 0">
                                        <option value="Set topic" v-if="recentSubjects && recentSubjects.length > 0" disabled>Recents</option>
                                        <option v-for="topic in recentSubjects.slice(0, 5)" :value="topic">{{ topic }}</option>
                                        <option value="Set topic" v-if="recentSubjects && recentSubjects.length > 0" disabled>More</option>
                                    </template>
                                    <option :value="prompt.topic" v-for="prompt in prompts">{{ prompt.topic }}</option>
                                </select>
                            </div>
                            <span class="text-tertiary" v-if="chat.updatedAt">
                                {{ $filters.timeAgo(toDate(chat.updatedAt)) }}
                            </span>
                            <!--                            <div class="c-20 f aic jcc bg-teal-700 f-11 ml-auto fwb text-white" v-if="chat.messages.length > 20">{{ chat.messages.length }}</div>-->
                        </div>
                    </div>
                    <div class="p-absolute right f reverse mr-2 gap-1 jcb" v-if="hovered">
                        <Button style-type="link" class="text-uppercase d-block mix-blend-luminosity p-0" icon="fal fa-edit" size="sm" rounded @click.prevent="editTitle = true" />
                        <Button style-type="link" class="text-uppercase d-block mix-blend-luminosity p-0" icon="fal fa-trash" size="sm" rounded @click.prevent="deleteChat()" />
<!--                        <div class="chat-button" @click.prevent="editTitle = true">-->
<!--                            <i class="fal fa-edit"></i>-->
<!--                        </div>-->
<!--                        <div class="chat-button" @click.prevent="deleteChat()">-->
<!--                            <i class="fal fa-trash"></i>-->
<!--                        </div>-->
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
import datingBot from "@/mixins/Chat/DatingBot";
import { db } from "@/firebase";
import Button from "@/components/CoreUI/BaseButton.vue";
import Cm from "@/components/CoreUI/cm.vue";
export default {
    name: "chat-list-item",
    components: { Cm, Button },
    mixins: [datingBot],
    data() {
        return {
            editTitle: false,
            title: "",
            hovered: false,
            topic: "Set topic",
        };
    },
    props: {
        chat: {
            type: Object,
            required: true,
        },
        index: {},
        currentChat: {},
    },
    mounted() {
        if (this.chat.chatTitle) {
            this.title = this.chat.chatTitle;
        } else {
            this.title = "New Chat";
        }
    },
    computed: {
        isCurrentChat() {
            return this.$route.params.id === this.chat.id;
        },
    },
    methods: {
        closeChatList() {
            this.$store.commit("chat/setShowMobileChat", false);
            this.$store.commit("setHideHeader", false);
        },
        async changeSubject(topic) {
            if (topic) {
                // save to local storage array for recent topics
                this.setRecentTopics(topic);
                if (this.$route.params.id) {
                    try {
                        await db.collection("chats").doc(this.$route.params.id).update({
                            topic: topic,
                        });
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        },
        deleteChat() {
            this.$emit("delete-chat", { chat: this.chat, index: this.index });
        },
        updateTitle(title, chat, index) {
            // update title on firestore
            this.editTitle = false;
            this.$emit("update-title", { title, chat, index });
        },
    },
};
</script>
<style scoped lang="scss">
.text-primary {
  @apply line-clamp-1;
}
</style>
