<template>
    <StyleGuideContainer section="textSamples" title="Text samples">
        <div class="col-span-12 gap-2">
            <div class="my-4">
                <FunctionTemplate></FunctionTemplate>
                <ai-typograpy class="my-3"></ai-typograpy>
                <ai-text-samples></ai-text-samples>
                <!--                <EasyActionPicker />-->
                <div class="container p-0">
                    <CopyGuidelines :guidelines="copyGuidelines"></CopyGuidelines>
                </div>
            </div>
            <EmailViewer :emails="styleGuide.email" />

            <div class="x col-span-12">
                <div class="f fc gap-2 bg-gray-100 p-4 container mw-800 x">
                    <h5>Headers</h5>
                    <div v-if="orderedTextSamples" class="f fc gap-4">
                        <template v-for="(sampleArray, title, i) in orderedTextSamples" :key="title">
                            <h4>
                                {{ formatTitle(title) }}
                                <a class="f-15" href="#" @click="updateStyleGuideProp(`textSamples.${title.toLowerCase()}`, [])">Clear {{ title }}</a>
                            </h4>
                            <div class="p-3 bg-snow">
                                <template v-for="(sample, index) in sampleArray" :key="`${sample}${index}`">
                                    <!--                                    <span class="whitespace-pre-line" v-if="title === 'Divs'">{{ sample }}</span>-->

                                    <div class="f hover:bg-gray-100 aic p-2 gap-2 border-bottom">
                                        <template v-if="(title === 'Divs' || title === 'Spans') && sample">
                                            <textarea :value="sample.trim()" class="p-1 border-0 whitespace-pre-line" type="text" @blur="updateInput(sampleArray, title, $event.target.value, index)" @keydown.enter="hitEnter(title, $event.target.value, index, sampleArray)" />
                                        </template>
                                        <template v-else>
                                            <input :value="sample" class="px-2 py-2 border-light mb-0 bg-transparent border-0 focus:!bg-snow" type="text" @blur="updateInput(sampleArray, title, $event.target.value, index)" @keydown.enter="hitEnter(title, $event.target.value, index, sampleArray)" />
                                        </template>
                                        <div class="c-30 f aic jcc hover:bg-snow cursor-pointer" @click="deleteItem(sampleArray, title, index)">
                                            <i class="far o-5 f-17 fa-minus-circle"></i>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="fwb f-17 mb-3 cursor-pointer" @click="addSample(title)">
                                <i class="far fa-plus-circle"></i>
                                Add sample
                            </div>
                        </template>
                        <BaseAccordion v-if="plainTextSamples">
                            <template #title>All Samples</template>
                            <div class="p-3">
                                <p class="p-1 whitespace-pre-line">{{ plainTextSamples }}</p>
                            </div>
                        </BaseAccordion>
                        <BaseAccordion v-if="styleGuide?.copyGuidelines">
                            <template #title>Guidelines</template>
                            <pre class="whitespace-break-spaces mw-700 p-3">{{ styleGuide.copyGuidelines }}</pre>
                        </BaseAccordion>
                    </div>
                </div>
            </div>
        </div>
    </StyleGuideContainer>
</template>
<script>
import { mapGetters } from "vuex";
import BaseAccordion from "@/components/CoreUI/BaseAccordion.vue";
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import CopyGuidelines from "@/components/generative_templates/Guidelines/CopyGuidelines.vue";
import { updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
import EmailViewer from "@/components/styleGuide/Sections/Emails.vue";
import EasyActionPicker from "@/components/Admin/EasyActionPicker.vue";
import PreviewMessages from "@/components/styleGuide/debugging/PreviewMessages.vue";
import getCopyGuideMixin from "@/mixins/ai/getCopyGuideMixin";
import FunctionTemplate from "@/components/generative_templates/Guidelines/functionTemplateStream.vue";
import AiTypograpy from "@/components/generative_templates/Guidelines/aiTypography.vue";
import AiTextSamples from "@/components/generative_templates/Guidelines/aiTextSamples.vue";

export default {
    name: "StyleGuideTextSamples",
    mixins: [StyleGuideMixin, getCopyGuideMixin],
    components: { AiTextSamples, AiTypograpy, FunctionTemplate, PreviewMessages, EasyActionPicker, EmailViewer, CopyGuidelines, StyleGuideContainer, BaseAccordion },
    data() {
        return {
            copyGuideProp: null,
            loadingGuide: false,
        };
    },
    props: {
        samples: {},
    },
    computed: {
        ...mapGetters("styleGuide", ["orderedTextSamples"]),
        ...mapGetters("styleGuide/llm", ["plainTextSamples"]),
    },
    methods: {
        hitEnter(title, sample, index, sampleArray) {
            console.log("Hit enter", title, sample, index, sampleArray);
            if (index === sampleArray.length - 1) {
                this.addSample(title);
            }
            // if (title === "Divs") {this.updateInput(sampleArray, title, sample, index)}
        },
        async updateTextSamples() {
            let payload = await updateFirebaseObject("brands", { textSamples: this.textSamples }, this.$route.params.id);
            console.log("updated payload", payload);
        },
        addSample(title) {
            if (title) {
                try {
                    title = title.replace("Header", "").toLowerCase();
                } catch (e) {
                    console.log(e.message);
                }
            }
            // console.log("Sample Array", sampleArray);
            console.log("Title", title);
            // return;
            const updatedTextSamples = { ...this.textSamples };
            if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(title)) {
                updatedTextSamples["headers"][title].push("");
            } else {
                updatedTextSamples[title].push("");
            }

            // Dispatch to Vuex to update the state.
            this.$store.dispatch("styleGuide/updateTextSamples", updatedTextSamples);
        },
        async deleteItem(sampleArray, title, index) {
            // Clone the array to prevent direct mutations.
            if (title) {
                try {
                    title = title.replace("Header", "").toLowerCase();
                } catch (e) {
                    console.log(e.message);
                }
            }
            // console.log("Sample Array", sampleArray);
            console.log("Title", title);
            console.log("Index", index);
            // return;
            const updatedTextSamples = { ...this.textSamples };
            if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(title)) updatedTextSamples["headers"][title].splice(index, 1);
            else updatedTextSamples[title].splice(index, 1);

            // Dispatch to Vuex to update the state.
            this.$store.dispatch("styleGuide/updateTextSamples", updatedTextSamples);
            await this.updateTextSamples();
        },
        updateInput(sampleArray, title, sample, index) {
            // Assume that orderedTextSamples object is a deep clone, not a reference
            // You may need to clone it deeply
            if (title) {
                try {
                    title = title.replace("Header", "").toLowerCase();
                } catch (e) {
                    console.log(e.message);
                }
            }
            // console.log("Sample Array", sampleArray);
            console.log("Title", title);
            console.log("Sample", sample);
            console.log("Index", index);
            // return;
            const updatedTextSamples = { ...this.textSamples };
            if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(title)) updatedTextSamples["headers"][title][index] = sample;
            else updatedTextSamples[title][index] = sample;
            // Now dispatch this to the store
            this.$store.dispatch("styleGuide/updateTextSamples", updatedTextSamples);
        },
        formatTitle(title) {
            if (title.startsWith("Header")) {
                return `Headers ${title.substring(6)}`;
            }
            return title;
        },
    },
};
</script>
