<template>
    <div class="f">
        <div :class="buttonStyle" class="" @click.prevent="wantsToGeneratePresentation()">
            <i class="fal fa-sync"></i>
            <span v-if="!mobile" class="ml-2">Redo</span>
        </div>
        <div v-if="research" class="scrollSection">
            <!--            <div class="whitespace-pre-wrap">{{ document.research.facts }}</div>-->
            <!--            <div class="whitespace-pre-wrap">{{ document.messages }}</div>-->
        </div>
        <ThemeColorPicker />
        <ToggleButton vuex-key="presentation.showDebugger" :border="1" :height="22" :width="35" label="🐞" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
        <div data-ccursor :class="buttonStyle" @click.prevent="enhancePresentation()">
            <i aria-hidden="true" class="fa fa-magic"></i>
            <span class="ml-2" v-if="!mobile">Enhance</span>
        </div>
        <div :class="buttonStyle" class="" @click.prevent="enhanceColors(document.summary)">
            <i class="fal fa-palette"></i>
            <span v-if="!mobile" class="ml-2">Get Theme</span>
        </div>
        <div :class="buttonStyle" class="hover:!bg-red-200 hover:!text-red-600" @click="$emit('deleteDoc', 'documents', document, documents)">
            <i aria-hidden="true" class="fa fa-trash"></i>
        </div>
    </div>
</template>
<script>
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import presenationMixin from "@/mixins/presentations/presentationMixin";
import ToggleButton from "@/components/CoreUI/ToggleButton.vue";
import ThemeColorPicker from "@/components/documents/ThemeColorPicker.vue";
import updateQueueProp from "@/mixins/ai/update_queue_prop";

export default {
    name: "PresentationToolbarActions",
    components: { ThemeColorPicker, ToggleButton },
    mixins: [presenationMixin, documentManagementMixin],
    props: {},
    methods: {
        async wantsToGeneratePresentation() {
            let messages = this.document.research.messages;
            let lastSlide = messages.length - 1;
            let topic = messages[lastSlide].content;
            updateQueueProp(this.$store, "images", this.document.research.images);
            updateQueueProp(this.$store, "research", this.document.research);
            console.log(topic, this.$toRaw(messages));
            // return;
            await this.generatePresentation(topic, messages);
        },
    },
    computed: {
        research() {
            if (this.document.research) {
                return this.document.research;
            } else if (this.queue.research) {
                return this.queue.research;
            }
        },
        buttonStyle() {
            let base = "z-2 f-15 br-5 f btn aic fwsb jcc border-solid border-1 border";
            let style;
            if (this.document && this.document.theme && this.document.theme.colors) {
                style = `
                dark:bg-base-50/10 mr-2 dark:text-base-50/70
                dark:hover:text-base-50 dark:hover:border-gray-300/5 hover:shadow-md
                active:bg-base-50 active:text-base-900
                `;
            } else {
                style = `
                bg-base-50/10
                hover:border-gray-300/5
                hover:bg-base-900/5
                hover:text-base-900
                `;
            }
            return base + " " + style;
        },
    },
};
</script>
