import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import getTokenCost from "@/mixins/ai/get_token_cost";
import webRequest from "@/mixins/ai/web_request";
async function getTokenCount(messages, model = "gpt-3.5-turbo", response, from) {
    if (typeof messages === "string") {
        messages = [userMessage(messages)];
    } else if (!Array.isArray(messages)) {
        console.error("getTokenCount: Invalid input type");
        return null;
    }
    try {
        try {
            // const request_url = endpointURL("tokenCount");
            const r = await webRequest("tokenCount", { messages });
            if (r.ok) {
                const data = await r.json();
                if (data?.tokenCount) {
                    const tokenCosts = getTokenCost(model, data.tokenCount);
                    return data.tokenCount;
                } else {
                    console.error("Error in getTokenCount:", data);
                    return null;
                }
            } else {
                console.error("Error in getTokenCount: Response status:", response.status);
                return null;
            }
        } catch (error) {
            console.error("Error in getTokenCount:", error);
            return null;
        }
    } catch (error) {
        console.error("Error in getTokenCount:", error);
        return null;
    }
}
export default getTokenCount;
