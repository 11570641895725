function cleanURL(url) {
    try {
        let hostname;
        hostname = new URL(url).hostname;
        hostname = hostname.replace("www.", "");
        // hostname = hostname.replace(".com", "");
        hostname = hostname.replace(".org", "");
        if (hostname.startsWith("en.")) {
            hostname = hostname.replace("en,", "");
        }
        return hostname;
    } catch (error) {
        // console.error("Error cleaning URL:", error);
        return url;
    }
}
export default cleanURL;
