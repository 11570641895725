import { imgEnd, imgStart } from "@/mixins/Chat/chatStrings";
import { mapActions } from "vuex";
import { chalk } from "chalk";
import { modifierNames, foregroundColorNames } from "chalk";
import { templateStderr } from "chalk-template";
import processCommands from "@/mixins/Chat/Commands/processing/ProcessCommands";
export default {
    created: function () {},
    mounted() {},
    mixins: [processCommands],
    computed: {},
    methods: {
        // ...mapActions("chat", ["addMessageStore", "addToMemoryStore", "addToSummaryStore", "setMessages", "saveChatStore"]),
        // ...mapActions("user", ["saveUser"]),


    },
};
