<template>
    <div class="flex flex-col">
        <label for="metadata" class="mb-2 text-sm font-medium text-gray-900">Metadata Key-Value Pairs</label>
        <textarea id="metadata" v-model="metadataString" placeholder="Enter metadata as key:value pairs, separated by commas" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
    </div>
</template>

<script>
export default {
    name: "KeyValueInput",
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        metadataString: {
            get() {
                return Object.entries(this.value)
                    .map(([key, value]) => `${key}:${value}`)
                    .join(", ");
            },
            set(newValue) {
                const metadata = newValue.split(",").reduce((acc, pair) => {
                    const [key, value] = pair.split(":").map(item => item.trim());
                    if (key && value) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
                this.$emit("input", metadata);
            },
        },
    },
};
</script>

<!-- Add any styles here -->
