<template>
    <div :class="slideContainerClasses" class="x vh-60 p-relative h-100 z-0">
        <div class="col-md-12 x h-auto text-left">
            <div class="f jcs y p-md-5 p-md-8 z-3 aic p-2 pl-0">
                <div v-if="section.timeline" class="block">
                    <PresentationSlideText :index="index" :document="document" :document-id="document.id" class="mb-lg-5 mb-2" :image-dark="imageDark" :section="section"></PresentationSlideText>
                    <div class="f flex-wrap">
                        <div v-for="(item, index) in section.timeline" :key="index" class="timeline-item col-12 col-md-3 px-md-0 pr-md-4 p-2 px-5">
                            <h3>{{ item.date }}</h3>
                            <h4>{{ item.event }}</h4>
                            <p class="o-7 f-15 mb-0">{{ item.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div :ref="`image-${id}${index}`" class="col-md-6 h-auto x" :class="randomImageStyle">-->
        <!--            <presentation-image-container :document="document" :document-id="documentId" :image-dark="imageDark" :index="index" :section="section"></presentation-image-container>-->
        <!--        </div>-->
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import presentationMixin from "@/mixins/presentations/presentationMixin";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin, presentationMixin],
    props: {
        id: {},
        index: {},
        section: {},
        documentId: {},
        slideContainerClasses: {},
    },
    data: () => ({
        imageDark: false,
    }),
    computed: {
        imageInset() {
            return "p-3 p-xl-5";
        },
        imageFullbleed() {
            return "p-0";
        },
    },
    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
        this.slideColors();
    },
    methods: {
        randomImageStyle() {
            return Math.random() < 0.5 ? this.imageInset : this.imageFullbleed;
        },
    },
};
</script>
<style scoped>
.timeline {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.timeline-item {
    margin-bottom: 20px;
}

.timeline-item h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.timeline-item h4 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
}
</style>
