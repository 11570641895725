<template>
    <div class="">
        <PreviewWrapper menu-title="Campaign" :collapsed="true" :data="campaign">
            <template #side>
                <div class="">
                    <!--                    <CampaignHeader :key="'campy1'" :result="campaign" />-->
                    <CampaignScheduleItem :schedule-items="campaign.schedule_items" />
                </div>
            </template>
            <div v-if="campaign.sections" class="x">
                <LandingPageSection v-for="(section, i) in campaign.sections" :id="id" :identifier="`campaignData.result.sections.${i}`" :result="section" :index="i" :sections="campaign.sections" />
                <CampaignFooter :key="'campyF1'" class="z-5" :result="campaign" />
            </div>
        </PreviewWrapper>
    </div>
</template>
<script>
import PreviewWrapper from "@/components/Admin/CampaignPreviewWrapper.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import CampaignScheduleItem from "@/components/generative_templates/Campaigns/CampaignScheduleItem.vue";
import LandingPageSection from "@/components/generative_templates/LandingPageSection.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import CampaignFooter from "@/components/generative_templates/Campaigns/CampaignFooter.vue";

export default {
    name: "LandingPagePreview",
    components: {
        CampaignFooter,
        LandingPageSection,
        CampaignHeader,
        CampaignScheduleItem,
        PreviewWrapper,
    },
    props: {
        campaign: {
            type: Object,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        styleGuide: {
            type: Object,
        },
    },
};
</script>
