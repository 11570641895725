import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import { mapGetters } from "vuex";
import sleep from "@/mixins/Chat/Commands/delay_timer";
// import brands from "../../Scratch/brands.js";
let brands;
export default {
    mixins: [],
    data() {
        return {
            brands: brands || [],
        };
    },
    computed: {
        sortedBrands() {
            // sort by likesCount and published == true
            return this.brands
                .filter(brand => brand.status === "Published")
                .sort((a, b) => {
                    let reviewA = a?.reviewSummary?.ratingsCount || 0;
                    let reviewB = b?.reviewSummary?.ratingsCount || 0;
                    return reviewB - reviewA;
                });
            // return this.brands.sort((a, b) => b.likesCount - a.likesCount);
        },
    },
    methods: {
        async findURLinSortedBrands(url) {
            let brand = this.sortedBrands.find(brand => brand.webUrl === url);
            brand = {
                name: brand.title,
                description: brand.description,
                url: brand.webUrl,
            };
            return brand;
        },
        async makeMultipleStyleGuides() {
            let brands = this.sortedBrands.slice(47, 60);
            let brandURLS = brands.map(brand => {
                return {
                    url: brand.webUrl,
                    title: brand.title,
                    description: brand.description,
                };
            });
            // for each url in brand urls
            for (let i = 0; i < brandURLS.length; i++) {
                let url = brandURLS[i]?.url;
                let title = brandURLS[i]?.title;
                let description = brandURLS[i]?.description;
                let styleGuide = {
                    name: title || "",
                    description: description || "",
                    url: url,
                    images: [],
                };
                // await this.saveAndReroute(undefined, styleGuide);
                await this.handleUrlSubmit(url, styleGuide);
                await sleep(1000);
            }
        },
    },
};
