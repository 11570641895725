let start_research = {
    name: "start_research",
    description: "Fill out the queries to research a topic.",
    parameters: {
        type: "object",
        properties: {
            google_query: {
                type: "string",
                description: "A well-written google query to research information about a topic.",
            },
            query_array: {
                type: "array",
                description: "a list of google queries to research information about the topic.",
                items: {
                    type: "string",
                    description: "A well-written google query to research information about a topic.",
                },
            },
            google_image_query: {
                type: "string",
                description: "A google query to find images related to the main topic or entity. Keep it broad to return a diverse set of images related to the main subject. No logos. Sometimes it's best to just use the entity name to keep it relevant. If it's a brand, use the entity name.",
            },
            name: {
                type: "string",
                description: "Extract the name of the topic.",
            },
            looking_for: {
                type: "string",
                description: "When browsing pages, this is what you are looking for.",
            },
            topics_to_cover: {
                type: "array",
                items: {
                    type: "string",
                    description: "Extract the areas of interest into an array of strings. Example:'topic founders', 'topic origin story', 'timelines'",
                },
                description: "Extract the areas of interest into an array of strings",
            },
            category: {
                type: "string",
                enum: ["brand", "historical", "topical", "news", "science", "other", "person", "process"],
                description: "This is the category of the topic.",
            },
        },
        required: ["google_query", "google_image_query", "name", "category", "query_array", "topics_to_cover", "looking_for"],
    },
};
export default start_research;
