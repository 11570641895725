<template>
    <div class="flex justify-between py-2 text-sm font-medium overflow-hidden">
        <dt class="text-gray-500 text-capitalize">{{ displayTitle }}</dt>
        <dd v-if="date" class="text-gray-900 whitespace-pre-line"><time-stamp :date="description"></time-stamp></dd>
        <dd v-else-if="displayTitle?.toLowerCase().includes('url') && description" class="text-gray-900 whitespace-pre-line">
            <SimpleLink is="a" :url="description" target="_blank" class="text-blue-500 hover:underline truncate line-clamp-1">{{ description.slice(0, 40) }}</SimpleLink>
        </dd>
        <div v-else-if="kind === 'color'" class="f flex-wrap">
            <div class="" v-for="color in description">
                <div class="c-25" :style="`background:${color};`"></div>
            </div>
        </div>
        <dd v-else class="text-gray-900 whitespace-pre-line">{{ description }}</dd>
    </div>
</template>
<script>
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import SimpleLink from "@/views/SimpleLink.vue";

export default {
    name: "ItemDetails",
    components: { SimpleLink, TimeStamp },
    props: {
        title: {
            type: String,
            default: "",
        },
        date: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: "",
        },
        kind: {
            type: String,
            default: "",
        },
    },
    computed: {
        displayTitle() {
            let title = this.title || "";
            title = this.convertCase(title);
            return title || "";
        },
    },
};
</script>
