<template>
    <div>
        <div class="white f-13 p-4" v-if="section.enhancedImage">
            <LoadingSpinner background="black" class="z-4 mb-3" color="white"></LoadingSpinner>
            <span class="o-4">{{ section.enhancedImage }}</span>
        </div>
        <div v-else-if="section.image" class="line-height-small mw-400 o-4 f-20 p-3">
            <LoadingSpinner class="z-5 mb-3" color="white"></LoadingSpinner>
            <span class="o-4">{{ section.image }}</span>
        </div>
        <div v-else class="line-height-small mw-400 o-4 f-20 p-3">
            <LoadingSpinner class="z-5 mb-3" color="white"></LoadingSpinner>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";

export default {
    name: "ImageLoadingContent",
    components: { LoadingSpinner },
    props: {
        section: {},
    },
};
</script>
