<template>
    <div class="p-relative">
        <div style="height: 20px; width: 2px" class="bg-gray-100 mx-auto"></div>

        <CardBody @clicked-card="clicked = !clicked" v-if="!horizontal" :complete="complete" :name="name" :show-status="showStatus" :day="scheduledOnDay" :status="statusString" stacked @delete-item="deleteCampaignItem" :type="type">
            <transition name="fade-up">
                <!-- todo: this is a hack with the route inclusion -->
                <BaseButton v-if="!showStatus || $route?.path?.includes('campaign') || complete" @click.prevent="triggerGeneration" label="Generate" class="mt-0 mt-2" size="sm"></BaseButton>
            </transition>
        </CardBody>
        <CardBody v-else @clicked-card="clicked = !clicked" :complete="complete" :name="name" :show-status="showStatus" :day="scheduledOnDay" :status="statusString" @delete-item="deleteCampaignItem" :type="type">
            <transition name="fade-up">
                <!-- todo: this is a hack with the route inclusion -->
                <div class="f gap-1 aic">
                    <!--                    <BaseButton v-if="!showStatus || $route?.path?.includes('campaign') || complete" @click.prevent="simulateStream" label="Generate" class="mt-0" rounded icon-only icon="fas fa-sync" size="xxs"></BaseButton>-->
                    <ToggleButtonSimple :vuex-key="'stream.options.advanced'" height="20" class="f-11 text-uppercase mr-2" module="stream" width="30"></ToggleButtonSimple>

                    <simulate-stream v-if="false && itemJSON" ref="simulate" :key="`object${itemIndex}`" :json-object="itemJSON" @simulate-stream="simulateStream"></simulate-stream>
                    <BaseButton v-if="!showStatus || $route?.path?.includes('campaign') || complete" @click.prevent="triggerGeneration" tooltip="Regenerate" label="Generate" class="mt-0" rounded icon-only icon="fas fa-sparkles !text-purple o-100" size="xs"></BaseButton>
                </div>
            </transition>
        </CardBody>
        <span class="visually-hidden">{{ prompt }}</span>
        <div class="mw-300 whitespace-pre-line visually-hidden" v-if="campaignContext">
            {{ campaignContext }}
        </div>
        <div class="whitespace-pre-line" :class="{ '-mb-8': horizontal }">
            <div style="height: 50px; width: 2px" class="bg-gray-200 mx-auto"></div>
        </div>
        <div class="mw-500 whitespace-pre-wrap visually-hidden" v-if="campaignWithoutMessages">{{ campaignWithoutMessages }}</div>

        <transition name="fade-up">
            <div v-if="clicked && false" class="mw-500 mx-auto">
                <slot name="clicked"></slot>
                <div class="f-15 f fc gap-3 p-3 br-20 border-light bg-snow">
                    <presentation-markdown :content="prompt"></presentation-markdown>
                </div>
                <div style="height: 20px; width: 2px" class="bg-gray-200 mx-auto"></div>
            </div>
        </transition>
        <slot></slot>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import { updateFirebaseArrayProperty } from "@/mixins/firebase/updateFirebaseObject";
import { mapGetters } from "vuex";
import ToggleButtonSimple from "@/components/CoreUI/ToggleButtonSimple.vue";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import SimulateStream from "@/components/generative_templates/Campaigns/SimulateStream.vue";
import CardBody from "@/components/generative_templates/Campaigns/CardBody.vue";
import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";
import groq from "@/mixins/ai/groqCompletion";

export default {
    name: "CampaignItem",
    components: { PresentationMarkdown, CardBody, SimulateStream, ToggleButtonSimple, BaseButton },
    props: {
        item: {
            type: Object,
            required: true,
        },
        campaignIndex: {
            type: Number,
            default: 0,
        },
        itemIndex: {
            type: Number,
            default: 0,
        },
        scheduledOnDay: {
            type: [Number, String],
            default: 0,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            finalStep: `Final talking points ...`,
            nestedCampaign: null,
            clicked: false,
            showStatus: true,
            triggerHide: false,
            modelOptions: [
                { name: "better", model: gpt3 },
                { name: "best", model: gpt4 },
            ],
            componentModel: null,
            itemJSON: null,
        };
    },
    async mounted() {
        let nestedCampaign = await this.loadNestedCampaign();
        if (nestedCampaign?.result) this.itemJSON = nestedCampaign.result;
    },
    computed: {
        ...mapGetters("stream", ["model", "advanced", "campaignData", "editor", "campaignContext"]),
        ...mapGetters("styleGuide", ["styleGuide"]),
        currentCampaignContent() {
            if (this.node.id && this.editor && this.editor[`${this.node?.contentId}`]) {
                return this.editor[`${this.node?.contentId}`];
            }
        },
        campaignWithoutMessages() {
            if (!this.campaignData?.result) return;
            const campaign = this.campaignData?.result;

            // Create a deep copy of the campaign to avoid mutating the original state
            let campaignCopy = JSON.parse(JSON.stringify(campaign));
            delete campaignCopy.messages;
            if (campaignCopy && Array.isArray(campaignCopy.schedule_items)) {
                campaignCopy.schedule_items.forEach(scheduleItem => {
                    if (Array.isArray(scheduleItem.campaign_items)) {
                        // delete campaignItem.messages;
                        scheduleItem.campaign_items.forEach(campaignItem => {
                            // Remove 'messages' property from each campaign_item
                            if (campaignItem.result?.result?.messages) delete campaignItem.result.result.messages;
                            if (campaignItem.result?.result?.sections) delete campaignItem.result.result;
                            if (campaignItem.result) delete campaignItem.result;
                            // if (campaignItem.result?.result) delete campaignItem.result.result;
                            // if (campaignItem.result) delete campaignItem?.result;
                            // delete campaignItem.sections;
                        });
                    }
                });
            }
            return campaignCopy;
        },
        node() {
            if (Array.isArray(this.item)) {
                return this.item[0];
            } else {
                return this.item;
            }
        },
        labelForToggle() {
            if (this.advanced) return "Better";
            else return "Faster";
        },
        complete() {
            return this.node?.complete;
        },
        prompt() {
            if (!this.instructionsString) return "Oops";
            let name = this.styleGuide.name || "";
            let parts = [
                `\nMake a world class **${this.type}** for ${name}'s marketing campaign.`,
                `Take a look at the talking points and instructions then craft it in the brand's voice.`,
                `It's important it all adheres to the ${name}'s style. Only mention social proof or special offers if explicitly prompted to.`,
                "Don't overuse catchphrases or cliches. Keep it fresh and original.",
                "You are writing this as if you are the brand, directed towards the brand's customers.\n",
                `this particular **${this.type}** is titled **${this.name}**. Be sure it fits into the overall campaign properly.\n`,
            ];
            if (this.talkingPoints?.length) parts.push(`The **talking points** are: ${this.talkingPoints.join(", ")}`);
            // ad campaignContext to the beginning of the prompt
            let campaignContext = this.campaignContext || "";

            if (campaignContext) campaignContext = campaignContext + "\n\n----\n\n# Here's the context for this portion of the campaign:\n\n";
            let string = parts.join("\n");
            // string += "\n" + this.talkingPointsString + "\n\n";
            string = this.instructionsString + "\n\n" + "---" + "\n\n" + string;
            if (campaignContext) string = campaignContext + string;
            return string;
        },
        goals() {
            return this.node?.goals;
        },
        name() {
            return this.node?.name;
        },
        type() {
            return this.node?.itemType;
        },
        instructions() {
            return this.node?.instructions;
        },
        talkingPoints() {
            return this.node?.talking_points || [];
        },
        talkingPointsString() {
            let intro = `**TALKING POINTS:**\n`;
            if (this.talkingPoints) return intro + this.arrayToBulletedList(this.talkingPoints, 0);
        },
        instructionsString() {
            try {
                let instructions = this.instructions;
                if (!instructions) return null;
                // .map(([key, section]) => `${key.replace(/_/g, " ")}:\n${this.arrayToBulletedList(section)}`)
                return Object.entries(instructions)
                    .map(([key, section]) => {
                        key = key.replace(/_/g, " ").toUpperCase();
                        key = key.replace("CONTENT STRATEGIST", "");
                        key = key.replace("CREATIVE DIRECTOR", "");
                        key = key.replace("COPYWRITER", "");
                        key = key.replace("CMO", "");
                        key = key.replace("BRAND MANAGER", "");
                        key = key.replace("FROM", "");
                        key = key.trim();
                        let text = `\n\n**${key}**:\n\n${section}`;
                        if (key === "COMPLETE") return null;
                        return text;
                    })
                    .join("\n\n");
            } catch (e) {
                // If something goes wrong, log the error and return a default message or null
                console.error(e);
                return this.instructions;
            }
        },
        campaignId() {
            return this.$route.params.cid || this.$store.state?.stream?.stream?.campaignData?.id;
        },
        brandId() {
            return this.$store.state?.stream?.stream?.campaignData?.brand;
        },
        statusString() {
            try {
                const { instructions, itemType: type, talking_points } = this.node;
                if (talking_points) return this.finalStep;
                else if (instructions && typeof instructions === "object") {
                    const { content_structure_from_content_strategist: structure, copy_instructions_from_copywriter: copy, directions_from_creative_director: creative, strategic_goals_from_CMO: goals, brand_guidelines_from_brand_manager: guidelines } = instructions;
                    if (goals) return `Goals ...`;
                    if (guidelines) return `Copy ideas ...`;
                    if (creative) return `Creative direction ...`;
                    if (copy) return `Instructions ...`;
                    if (structure) return `Content structure ...`;
                } else if (type) return `${type}: `;
                else return `Thinking ...`;
            } catch (e) {
                console.log("status string broken");
            }
            return "Thinking ...";
        },
    },
    watch: {
        advanced: {
            handler(val) {
                if (val) {
                    // if (this.model === gpt4) {
                    this.updateStreamProp("model", gpt4);
                    this.componentModel = gpt4;
                } else {
                    this.updateStreamProp("model", "gpt-3.5-turbo-1106");
                    this.componentModel = "gpt-3.5-turbo-1106";
                }
            },
        },
        statusString: {
            handler(newVal, oldVal) {
                if (newVal?.includes(this.finalStep)) {
                    this.hideStatus();
                }
            },
            // immediate: true,
        },
        complete: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.hideStatus();
                }
            },
        },
    },
    methods: {
        startSteamSimulation() {
            this.$refs.simulate.startStreaming();
        },
        async simulateStream(obj) {
            this.updateStreamProp(`editor.${this.item.id}.result`, obj);
        },
        async saveUpdatedCampaign() {
            const latestCampaign = this.campaignWithoutMessages;
            const id = this.campaignId;
            latestCampaign.id = id;
            // await updateFirebaseObject("campaigns", latestCampaign, id);
            let savedObject = await saveToFirebase("campaigns", latestCampaign, id);
            this.updateStreamProp("campaignData", savedObject);
            this.$forceUpdate();
            // update the entire vue app
        },
        whileProcessing() {},
        matchTypes() {
            if (this.type === "email") this.setActionEmail();
            if (this.type === "page") this.setActionLandingPage();
        },
        async deleteCampaignItem() {
            let scheduleItems = this.campaignData.result.schedule_items;
            let scheduleItem = scheduleItems[this.campaignIndex];
            let campaignItems = scheduleItem.campaign_items;
            // splice the current item out of the array
            campaignItems.splice(this.itemIndex, 1);
            if (campaignItems.length === 0) {
                // remove the schedule item
                scheduleItems.splice(this.campaignIndex, 1);
                this.updateStreamProp("campaignData.result.schedule_items", scheduleItems);
                await this.$nextTick();
                await this.saveUpdatedCampaign();
            } else {
                this.updateStreamProp(`campaignData.result.schedule_items.${this.campaignIndex}.campaign_items`, campaignItems);
                await this.$nextTick();
                await this.saveUpdatedCampaign();
            }
        },
        async saveToCampaigns(obj) {
            let {
                //
                name,
                brand = this.$route.params.id,
                result,
                itemIndex,
                identifier,
                campaignId,
                campaignIndex,
                save,
                messages,
                prompt,
                function_call,
                model,
                type,
            } = obj;
            let updatedObject = {
                result: {
                    ...result,
                    brand,
                    object_type: type,
                    campaignId,
                    itemIndex,
                    campaignIndex,
                    messages,
                },
                brand,
                created: new Date(),
                updated: new Date(),
                name: result.name || "",
                campaignId,
                campaignIndex,
                object_type: type,
                identifier,
                itemIndex,
            };
            // remove null and undefined values
            // remove null and undefined values
            updatedObject = Object.fromEntries(Object.entries(updatedObject).filter(([key, value]) => value != null));
            // updatedObject.result = Object.fromEntries(Object.entries(updatedObject.result).filter(([key, value]) => value != null));
            let savedObject = await saveToFirebase("campaigns", updatedObject);
            console.log("NEW SAVED OBJECT", savedObject);
            console.log("ID OF NEW SAVED OBJECT", savedObject.id);

            this.updateStreamProp(`campaignData.result.schedule_items.${this.campaignIndex}.campaign_items.${this.itemIndex}.result.id`, savedObject.id);
            // this.updateStreamProp(`campaignData.result.schedule_items.${this.campaignIndex}.campaign_items.${this.itemIndex}.id`, savedObject.id);
            return await updateFirebaseArrayProperty("campaigns", this.campaignId, "childCampaigns", savedObject.id);
        },
        async loadNestedCampaign() {
            if (this.node.contentId || this.node.id) {
                let id = this.node.contentId || this.node.id;
                let nestedCampaign = await fetchData("campaigns", id);
                // this.nestedCampaign = nestedCampaign;
                this.updateStreamProp(`editor.${nestedCampaign.id}`, nestedCampaign);
                this.itemJSON = nestedCampaign;
                return nestedCampaign;
            }
        },
        async triggerGeneration(input = "", overrides) {
            this.matchTypes();
            if (overrides?.action) this.setAction(overrides.action);

            // return;
            let newCampaignItem = {
                created: new Date(),
                updated: new Date(),
                campaignId: this.campaignId || null,
                brand: this.$route.params.id,
                object_type: this.type,
                ...deepCopy(this.node),
                result: {
                    loading: true,
                },
            };
            if (newCampaignItem.id) newCampaignItem = await saveToFirebase("campaigns", newCampaignItem, newCampaignItem.id);
            else newCampaignItem = await saveToFirebase("campaigns", newCampaignItem);
            try {
                this.updateStreamProp(`editor.${newCampaignItem.id}`, newCampaignItem);
            } catch (e) {}
            // create a new campaign object.

            // this.campaignData.result.schedule_items[this.campaignIndex].campaign_items[this.itemIndex].contentId = newCampaignItem.id;
            this.updateStreamProp(`campaignData.result.schedule_items.${this.campaignIndex}.campaign_items.${this.itemIndex}`, newCampaignItem);
            await saveToFirebase("campaigns", newCampaignItem);
            await this.$nextTick();
            let campaignData = deepCopy(this.campaignData);
            campaignData.id = this.campaignId;
            await saveToFirebase("campaigns", campaignData, this.campaignId);
            this.updateStreamProp(`editor.${newCampaignItem.id}`, newCampaignItem);
            await this.$nextTick();

            console.log("TRIGGER GENERATION", this.selectedAction);

            let embeddings = await this.getEmbeddings(this.prompt);
            if (embeddings) this.addMessage(userMessage(embeddings));
            this.addMessage(userMessage(this.prompt));
            // this.addMessage(aiMessage("Generating ..."));
            newCampaignItem = await saveToFirebase("campaigns", { ...newCampaignItem, messages: deepCopy(this.streamMessages) }, newCampaignItem.id);
            let newId = newCampaignItem.id;
            let messages = newCampaignItem.messages;
            let g = await this.groqPromptAdjustment(this.prompt, messages);
            // return;
            if (g && g !== "") {
                messages.push(userMessage(g));
                logGroup("Messages", messages);

                newCampaignItem = await saveToFirebase("campaigns", { ...newCampaignItem, messages: messages }, newCampaignItem.id);
            }
            this.updateStreamProp(`editor.${newCampaignItem.id}`, newCampaignItem);
            logGroup("New Campaign Item", newCampaignItem);
            let { name, type } = this.selectedAction; // get the name and type of the selected action. The prompt will be automatically added via the streamFunction(obj) method. // set the action to get_brand_voice. This sets the prompt and the function_call as well.
            let mods = {
                model: this.componentModel || this.model, // this is the model that will be used to generate this.
                // model: gpt316, // this is the model that will be used to generate this.
                messages: messages, // this is the messages that will be used to generate this.
                temperature: 0, // this is the temperature that will be used to generate this.
                function_call: name, // this is the function_call name that will be called to generate this. It needs to match the fucntion being called either locally or on the server.
                functions: undefined, // this is the functions object that will be used to generate this. If blank it will use the server and name to route to the right function if it exists.
                length: 4000, // the length of the message to generate
                persistChat: true, //  if true, it streams the result to the messages array in the stream store. This can be used to see the streaming results in chat, but it can also be used to save the results to the database, "chat" add revisions with context, etc.
                brand: this.$route.params.id, // set the brand id from the route params but this could be set from anywhere optionally.
                input: ``, // this is the user input that will added to messages.
                type: type, // what type of campaign is this? Currently only used for campaigns.
                save: false, // if true, use the afterCompletion function to define how to save the results.
                returnTo: `editor.${newCampaignItem.id}.result`,
                id: newCampaignItem.id,
                brandId: this.brandId || null,
                campaignName: this.name,
            };
            if (overrides) mods = { ...mods, ...overrides };
            // return;
            // return;

            await this.callFunction(mods);
            if (newId) {
                let finalCampaign = deepCopy(this.editor[`${newId}`]);
                finalCampaign = this.fixMessages({ ...finalCampaign, function_call: name, result: finalCampaign.result });
                finalCampaign = {
                    ...finalCampaign,
                    updated: new Date(),
                };
                let newEditor = await saveToFirebase("campaigns", finalCampaign, newId);
                logGroup("Final Campaign", newEditor);
                this.updateStreamProp(`editor.${newId}`, newEditor);
                this.$forceUpdate();
            }
        },
        fixMessages(campaign) {
            let newCampaign = deepCopy(campaign);
            let { messages, result, function_call } = newCampaign;
            messages = deepCopy(messages);
            result = deepCopy(result);
            messages.push({
                role: "assistant",
                content: "",
                function_call: { name: function_call, arguments: result },
            });
            newCampaign.messages = messages;
            return newCampaign;
        },
        async afterCompletion(obj) {
            this.$forceUpdate();
            let { input, save, messages, prompt, function_call, model, system, silent, result, type, id } = obj;

            // let newEditor = await saveToFirebase("campaigns", finalCampaign, newId);
            this.afterCompletingGeneration(obj);
            // this.hideLoader();
            let childObj;
            if (save) childObj = await this.saveToCampaigns(obj);
        },
        clearPreview() {
            this.updateStreamProp("showCampaign", false);
        },
        hideStatus() {
            if (!this.triggeredHide) {
                this.triggeredHide = true;
                setTimeout(() => {
                    this.showStatus = false;
                }, 100); // Adjusted to 5 seconds as per the comment
            }
        },
    },
};
</script>
