<template>
    <div class="x overflow-hidden">
        <StyleGuideToolbar />
        <div class="vh-70 f aic jcc fc border-bottom mb-6 overflow-hidden x" v-if="tone">
            <!--          :style="buttonStyle"-->
            <div class="block z-0 relative">
                <brand-logo-component width="500" class="relative mb-4" :color="styleGuide.baseColor"></brand-logo-component>
            </div>
            <span v-if="overviewAbout" class="block mw-1400 mx-auto text-center fwn mb-5 mobile:f-40 f-70">{{ overviewAbout }}</span>
            <div class="mx-auto mw-500 text-center">
                <ChartTone :tone-data="tone" />
            </div>
        </div>
        <div class="grid grid-cols-1">
            <TraitList v-if="targetAudience?.length" title="Audience" :list="targetAudience" icon="fal fa-users" />
            <TraitList v-if="valueProps?.length" :title="`Why ${styleGuide.name}`" :list="valueProps" icon="fal fa-lightbulb" />
            <TraitList v-if="sellingPoints?.length" title="Selling Points" :list="sellingPoints" icon="fal fa-star" />
            <TraitList v-if="voiceArray?.length" title="Brand Voice" :list="voiceArray" icon="fal fa-megaphone">
                <!--                <div class="mx-auto mw-500 text-center" v-if="tone"><ChartTone :tone-data="tone" /></div>-->
            </TraitList>
        </div>
        <BrandEthos v-if="audience" title="Audience" :ethos="audience" />
        <BrandEthos v-if="ethos" title="Brand Ethos" :ethos="ethos" />
        <BrandEthos v-if="voice" title="Brand Voice" :ethos="voice"></BrandEthos>
        <BrandEthos v-if="punctuation2" title="Writing Style" :ethos="punctuation2"></BrandEthos>
        <BrandEthos v-if="rhetoricalStrategy" title="Writing Devices" :ethos="rhetoricalStrategy" />
        <div :style="mergedStyles" class="">
            <template v-if="isOverviewAvailable">
                <!--                <h2 class="my-3" v-if="overviewName">Brand tone</h2>-->
                <!--                <div v-if="overviewAbout">-->
                <!--                    <h5 class="fwn mb-5">{{ overviewAbout }}</h5>-->
                <!--                </div>-->
                <div class="f fc gap-4">
                    <CopyGuideSection v-if="tone" :header-style="headerStyle" :paragraph-style="paragraphStyle" :section="{ name: ' ', tone: tone, guidelines: dos, avoid: donts }">
                        <DosDontsList v-if="targetAudience?.length > 0" :list="targetAudience" class="col-span-12 md:col-span-3" title="Target Audience" type="dos" :header-style="headerStyle" />
                        <!--                        <DosDontsList v-if="valueProps?.length > 0" :list="valueProps" class="col-span-12 md:col-span-3" title="Value Props" type="dos" :header-style="headerStyle" />-->
                        <!--                        <DosDontsList v-if="sellingPoints?.length > 0" :list="sellingPoints" class="col-span-12 md:col-span-3" title="Selling Points" type="dos" :header-style="headerStyle" />-->
                        <DosDontsList v-if="rhetoricalStrategies?.length > 0" :list="rhetoricalStrategies" class="col-span-12 md:col-span-3" title="Rhetoric" type="dos" :header-style="headerStyle" />
                        <DosDontsList v-if="punctuation?.length > 0" :list="punctuation" class="col-span-12 md:col-span-3" title="Punctuation" type="dos" :header-style="headerStyle" />
                    </CopyGuideSection>
                    <!--                    <pre>{{ guidelines.copywriting }}</pre>-->
                </div>
            </template>

            <!--            <div class="grid grid-cols-12">-->
            <!--                <div class="col-span-2">-->
            <!--                    <div class="py-3 border-bottom last:border-none" v-for="section in processedSections">{{ section.name }}</div>-->
            <!--                    <div class="py-3">All</div>-->
            <!--                </div>-->
            <!--                <div class="col-span-10">-->
            <!--                    <template v-for="(section, index) in processedSections">-->
            <!--                        <CopyGuideSection :header-style="headerStyle" :paragraph-style="paragraphStyle" :section="section" />-->
            <!--                    </template>-->
            <!--                </div>-->
            <!--            </div>-->
            <WritingSamples v-if="processedSections?.length > 0" :header-style="headerStyle" :paragraph-style="paragraphStyle" :processed-sections="processedSections" />
            <div class="personas grid gap-3 mb-5 x" v-if="personas">
                <h1 class="f-70" v-if="overviewName">Personas</h1>
                <div class="x f fc gap-5">
                    <template v-for="(persona, index) in personas" :key="persona.name">
                        <CopyGuideLinesPersona class="col-span-3" :ref="`persona${index}`" :index="index" :persona="persona" />
                    </template>
                </div>
            </div>

            <CopyGuidelineExamples v-if="examples" :examples="examples" />
        </div>
    </div>
</template>

<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import CopyGuideSection from "@/components/generative_templates/Guidelines/CopyGuideSection.vue";
import getCopyGuideMixin from "@/mixins/ai/getCopyGuideMixin";
import CopyGuideLinesPersona from "@/components/generative_templates/Guidelines/CopyGuideLinesPersona.vue";
import DosDontsList from "@/components/generative_templates/Guidelines/DosDontsList.vue";
import { mapGetters } from "vuex";
import ChartTone from "@/components/generative_templates/Guidelines/ChartTone.vue";
import TraitList from "@/components/generative_templates/Guidelines/TraitList.vue";
import WritingSamples from "@/components/generative_templates/Guidelines/WritingSamples.vue";
import BrandEthos from "@/components/generative_templates/Guidelines/BrandEthos.vue";
import CopyGuidelineExamples from "@/components/generative_templates/Guidelines/CopyGuidelineExamples.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import StyleGuideToolbar from "@/components/generative_templates/Guidelines/StyleGuideToolbar.vue";

export default {
    name: "CopyGuidelines",
    components: {
        StyleGuideToolbar,
        BrandLogoComponent,
        CopyGuidelineExamples,
        BrandEthos,
        WritingSamples,
        TraitList,
        ChartTone,
        DosDontsList,
        CopyGuideLinesPersona,
        CopyGuideSection,
    },
    mixins: [styleGuideMixin, getCopyGuideMixin],
    props: {
        guidelines: {
            type: Object,
            default: () => ({
                overview: {
                    name: "",
                    about: "",
                    analogous_comparison: "",
                    voice: [],
                },
                guidelines: [
                    {
                        name: "",
                        tone: "",
                        examples: [],
                        guidelines: [],
                        avoid: [],
                    },
                ],
            }),
        },
    },
    data() {
        return {
            aborted: false,
            loadingGuide: false,
        };
    },
    watch: {
        // guidelines: {
        //     handler(newValue) {
        //         this.updateGuidelines(newValue);
        //     },
        //     deep: true,
        // },
    },
    methods: {
        async startBrandPersonas() {
            // model = gpt4;
            this.updateStyleGuideProp("copyGuidelines.overview.personas", []);
            await this.getBrandPersonas(`Make ${this.styleGuide.name}'s personas.`);
            this.updateStyleGuideProp("copyGuidelines.overview.personas", this.personas);
            this.updateStyleGuideProp("personas", null);
            this.getPersonaImages();
        },
        async getPersonaImages() {
            // Wrap the nextTick and for-loop logic in a new Promise

            return new Promise((resolve, reject) => {
                this.$nextTick(() => {
                    if (this.personas.length > 0) {
                        const imagePromises = [];

                        for (const [index, persona] of this.personas.entries()) {
                            // Ensure the ref is available and has the method
                            if (this.$refs[`persona${index}`] && typeof this.$refs[`persona${index}`][0].generatePersonaImagePrompt === "function") {
                                // Push each promise to the array
                                imagePromises.push(this.$refs[`persona${index}`][0].generatePersonaImagePrompt());
                            } else {
                                console.error("generatePersonaImagePrompt method not found for persona", index);
                                // If a method is not found, reject the promise
                                reject(new Error(`generatePersonaImagePrompt method not found for persona ${index}`));
                                return;
                            }
                        }

                        // Resolve once all promises are completed
                        Promise.all(imagePromises).then(resolve).catch(reject);
                    } else {
                        // Resolve immediately if there are no personas
                        resolve();
                    }
                });
                this.$store.dispatch("styleGuide/saveStyleGuide", this.styleGuide);
            });
        },
    },

    computed: {
        ...mapGetters("styleGuide", ["productCount", "copyGuidelines"]),
        valueProps() {
            let purchaseReasons = this.copyGuidelines?.overview?.purchase_motivations || [];
            let valueProps = this.copyGuidelines?.overview?.value_propositions || [];
            return [...purchaseReasons, ...valueProps];
        },
        audience() {
            return this.copyGuidelines?.audience;
        },
        examples() {
            return this.copyGuidelines?.copywriting?.examples || [];
        },
        ethos() {
            return this.copyGuidelines?.ethos;
        },
        voice() {
            return this.copyGuidelines?.voice;
        },
        personas() {
            return this.styleGuide?.personas?.personas || this.copyGuidelines?.overview?.personas;
        },
        tone() {
            return this.copyGuidelines?.overview?.tone;
        },
        dos() {
            return this.copyGuidelines?.overview?.dos;
        },
        donts() {
            return this.copyGuidelines?.overview?.donts;
        },
        sellingPoints() {
            return this.copyGuidelines?.overview?.selling_points;
        },
        rhetoricalStrategies() {
            return this.copyGuidelines?.overview?.rhetorical_strategies;
        },
        isOverviewAvailable() {
            return this.copyGuidelines?.overview;
        },
        targetAudience() {
            return this.copyGuidelines?.overview?.target_audience;
        },
        punctuation2() {
            return this.copyGuidelines?.punctuation;
        },
        rhetoricalStrategy() {
            return this.copyGuidelines?.rhetorical_strategies;
        },
        punctuation() {
            return this.copyGuidelines?.overview?.punctuation;
        },
        overviewName() {
            return this.copyGuidelines?.overview?.name;
        },
        overviewAbout() {
            return this.copyGuidelines?.overview?.about;
        },
        voiceArray() {
            return this.copyGuidelines?.overview?.voice;
        },
        processedSections() {
            return this.copyGuidelines?.guidelines;
        },
        mergedStyles() {
            const baseStyle = "line-height:unset;font-weight:unset";
            const paragraphStyle = this.paragraphStyle || "";
            return [baseStyle, paragraphStyle];
        },
    },
};
</script>

<style lang="scss" scoped>
h3 {
    font-size: 2rem !important;
}

strong {
    font-weight: 500 !important;
}

ol > div {
    counter-increment: list-counter;
}
.link-pile {
    a {
        font-size: 13px !important;
        font-weight: bold !important;
        text-transform: none !important;
        letter-spacing: normal !important;
        line-height: normal !important;
        padding: 0.5rem 1rem !important;
    }
}
ol > div > li {
    position: relative;
    padding-left: 2em;
    list-style-type: none;

    &::before {
        font-size: 0.8rem;
        line-height: 1.5em;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.5em;
        height: 1.5em;
        margin-right: 30px;
        @apply text-base-500;
        content: counter(list-counter);
        transform: translateY(-50%);
        @apply border-base-900/10;
        @apply bg-base-900/5;
        text-align: center;
        border: 1px solid;
        border-radius: 50%;
    }
}

p {
    font-size: 1rem !important;
    font-weight: normal !important;
    line-height: normal !important;
}

/* Include your styles here */
</style>
