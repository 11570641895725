<template>
    <div>
        <div v-for="(message, i) in messages" class="f-15 my-2 whitespace-pre-line" :key="i">
            <div v-if="message.role !== 'system'">
                <span class="fwb text-uppercase">
                    <template v-if="message.role === 'assistant' && message?.content && message?.command?.name">{{ message?.command?.name }}:</template>
                    <template v-else-if="message.role === 'user'">YOU:</template>
                    <!--                    <template v-else-if="message.role === 'user'">{{ message.role }}:</template>-->
                </span>
                {{ message.content }}
            </div>
            <!--                                {{ message.role }}: {{ message.content }}-->
        </div>
    </div>
</template>
<script>
export default {
    name: "ChatPlainText",
    props: {
        messages: {
            type: Array,
            required: true,
        },
    },
};
</script>
