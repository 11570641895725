<template>
    <div v-show="false">
        <span class="whitespace-pre-wrap text-left block">{{ buildPrompt("hello").instructions }}</span>
        <span class="whitespace-pre-wrap text-left block">{{ buildPrompt(promptText).user }}</span>
        <span class="whitespace-pre-wrap text-left block">
            {{ suggestions.join(", ") }}
        </span>
    </div>
</template>

<script>
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import _, { debounce } from "lodash";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import BaseTextarea from "@/components/CoreUI/BaseTextarea.vue";
import TopicButton from "@/components/CoreUI/TopicButton.vue";
import Button from "@/components/CoreUI/BaseButton.vue";
import { mapGetters } from "vuex";
import newChatMixins from "@/mixins/Chat/newChatMixins";
export default {
    components: { Button, TopicButton, BaseTextarea, BaseInput },
    mixins: [documentManagementMixin, newChatMixins],
    data() {
        return {
            userInput: "",
            suggestions: [],
            suggestionString: "",
            suggestionBuffer: "",
            responseString: "",
            summary: "",
            questionType: null,
        };
    },
    props: {
        promptText: {
            type: String,
            default: "",
        },
        messages: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters("chat", ["lastMessage"]),
    },
    methods: {
        buildPrompt(message) {
            let rules = [
                // "Think through each rule step-by-step.",
                "Analyze the message received from User 1 and [CONVERSATION] context.",
                "Try to predict the user 2's next messages.",
                "These will make it easier for me to respond to User 1",
                "Generate 3-5 relevant Quick Responses (2-3 words) based on the last message.",
                "Include diverse response options such as follow-up responses, related topics, or suggested questions.",
                "AVOID generic responses like 'thank you,' 'what else?,' 'Any other options?,' or 'ok'.",
                "Delimit responses using '\n' for clear presentation.",
                "Prioritize user-friendly UI/UX for Quick Responses engagement.",
                "Ensure a minimum of 3 buttons for seamless conversation continuation, tailored to the specific context.",
                "ALWAYS Use 'single_select' or 'multi_select' question types for responses.",
            ];
            let examples = [
                "[Example 1] (expand):Tell me about [TOPIC], Give me more ideas for [TOPIC], Can you give me more suggestions for [TOPIC]?, Let's explore [BROADER TOPIC]",
                "[Example 2] (range):$50-100, $100-200, $200-300",
                "[Example 3] (offer related topics): [RELATED TOPIC 1],[RELATED TOPIC 2],[RELATED TOPIC 3]",
                "[Example 4] (offer other options): [BROADER TOPIC 1],[BROADER TOPIC 2],[BROADER TOPIC 3]",
                "[Example 5] (get into detail): Provide more details, Give me examples of this., Is there another approach?",
            ];
            let prompt = `${this.getConversationContext(this.messages, 4)}\n\n${this.getPromptRules(rules)}\n\n${this.getResponseExamples(examples, "response examples", this.messages, false)}\n- < choose "single_select" or "multi_select" >`;
            let instructions = prompt;
            let user = `\n\n[LAST MESSAGE]:\n${message}\n\n[ONLY THE QUICK RESPONSES]:`;
            // console.error("Get suggestions", instructions, user);
            return { user, instructions };
        },
        async getSuggestions(prompt) {
            this.suggestionBuffer = "";
            // const prompt = `Auto-complete the following sentence: "${this.userInput}" [GENERATED PROMPT END]`;
            // console.groupCollapsed("Get suggestions");
            const { user, instructions } = this.buildPrompt(this.lastMessage.content);
            await this.simpleMessage(
                user,
                instructions,
                null,
                content => {
                    // this.processStreamedResponse(content);
                    this.responseString = content;
                },
                () => {
                    this.processStreamedResponse("\n[DONE]");
                },
                100,
                token => {
                    // console.error("Partial:", token);
                    this.processStreamedResponse(token);
                },
                0,
                "suggestions",
            );
            // console.groupEnd();
            this.$nextTick(() => {
                this.$emit("addSuggestions", this.suggestions, this.questionType);
            });
        },

        /**
         * Processes the streamed response, handling numbered and comma-separated lists.
         * This function updates the suggestions array based on the response, removing any numbered prefixes.
         *
         * @param {string} response - The streamed response text, which can be a numbered or comma-separated list.
         */
        async processStreamedResponse(token) {
            // if (response !== "[DONE]") {
            this.suggestionBuffer += token;
            // this.suggestionBuffer.replace(", ", "\n");
            const array = this.splitResponses(this.suggestionBuffer);
            for (let i = 0; i < array.length - 1; i++) {
                const item = array[i];
                if (item.length > 4) {
                    let string = this.prepareString(item);
                    if (this.includesDelimiters(token)) {
                        // let cleanString = string.replace("\n", "").trim();
                        let cleanString = string.trim();
                        if (!string.includes("quick response")) {
                            cleanString = this.cleanString(cleanString);
                            const type = this.stringContainsType(cleanString);
                            if (type) {
                                await this.saveType(type, this.suggestions);
                                this.suggestionBuffer = "";
                                return;
                            } else {
                                if (!this.responsesToIgnore(cleanString)) {
                                    this.saveArrayItem(cleanString, "suggestions");
                                    this.suggestionBuffer = "";
                                }
                            }
                        }
                    }
                    this.suggestionBuffer += token;
                    this.$forceUpdate();
                }
            }
        },
        addToSuggestions(suggestion) {
            this.$emit("addToSuggestions", suggestion);
        },
        selectSuggestion(suggestion) {
            this.$emit("pickSuggestion", suggestion);
        },
        debounceSuggestion: _.debounce(function () {
            this.getSuggestions(this.lastMessage.content);
        }, 300),
    },
    mounted() {
        // this.testingMethod();
        // return;
        if (this.lastMessage.followUpTopics && this.lastMessage.followUpTopics.length > 0) {
            // this.consoleObject("Follow-ups exist", this.lastMessage.followUpTopics);
            if (this.lastMessage.followUpTopicsType) {
                this.questionType = this.lastMessage.followUpTopicsType;
            }
        } else {
            this.debounceSuggestion();
        }
    },
};
</script>
