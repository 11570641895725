<template>
    <div class="block mb-3 ease-back duration-2 animated fadeInUpSmooth d-2 relative" @mouseenter="hovered = true" @mouseleave="(hovered = false), (hoverEdit = false)">
        <PresentationMarkdown v-if="!isEditing" :class="{ 'mb-6 transition-all': hovered }" :content="text" :style="styleString"></PresentationMarkdown>
        <EditSectionIndicator v-if="hovered || isEditing" key="textEdit" :editing="isEditing" @click="isEditing = true" @mouseenter="hoverEdit = true" />
        <EditingFont
            :editing="isEditing"
            :font-size="fontSize"
            :hovered="hovered"
            :identifier="identifier"
            :index="index"
            :max-size="maxFontSize"
            :min-size="minFontSize"
            :section="section"
            class="block x"
            kind="subheader"
            @update-font-name="updateSubheaderFont"
            @hover-edit="hoverEdit = true"
            @changed-font-size="editFontSize"
            @font-done-changing="editFontSize" />
        <contenteditable v-if="isEditing" ref="contenteditable" v-model="textEdit" :class="{ 'mb-6 transition-all': hovered }" :style="[font.body, `font-size:${fontSize}px`, `color:${textColorOverride}`]" class="block z-6" data-placeholder="Enter your text here" tag="p" @blur="doneEditing"></contenteditable>
    </div>
</template>
<script>
import EditSectionIndicator from "@/components/generative_templates/Editing/EditSectionIndicator.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";
import contenteditable from "vue-contenteditable";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import EditingFont from "@/components/generative_templates/Editing/EditingFont.vue";

export default {
    name: "SubheaderText",
    components: { EditingFont, PresentationMarkdown, EditSectionIndicator, contenteditable },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin],
    props: {
        text: { type: String, default: "" },
        font: {},
        defaultSize: { type: [String, Number], default: "" },
        index: { default: 0, type: [Number, String], required: true },
        textColorOverride: {},
        fontOverride: { type: String, default: "" },
        identifier: { type: String, required: true },
        section: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            hovered: false,
            fontSize: 20,
            minFontSize: 15,
            maxFontSize: 30,
            hoverEdit: false,
            isEditing: false,
            textEdit: "",
        };
    },
    computed: {
        styleString() {
            let styles = [this.fontString, `font-size:${this.fontSize}px!important;`, `color:${this.textColorOverride}`, "line-height:1.2em;letter-spacing:0px;"];
            return styles;
        },
        fontString() {
            if (this.fontOverride) return this.fontOverride;
            else return this.font.subheader || this.font.body;
        },
    },
    watch: {
        isEditing: {
            handler(val, oldVal) {
                if (val && val !== oldVal) this.$nextTick(() => this.$refs.contenteditable.$refs.element.focus());
            },
        },
    },
    mounted() {
        if (this.defaultSize) this.fontSize = this.defaultSize;
        this.textEdit = this.text;
    },
    methods: {
        editFontSize(number, save) {
            this.fontSize = number;
            if (save) this.updateSection("subheaderSize", number);
        },
        doneEditing(event) {
            this.updateSection("sub_header", this.textEdit);
            this.isEditing = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.range-input[type="range"] {
    @apply cursor-pointer;
}
</style>
