<template>
    <div class="x chatEdit">
        <div class="x mw-800 whitespace-pre-wrap">
            <!--            <BaseTextarea v-model="m.content" class="x" :rows="m.content.split('\n').length*3" @keydown.enter="saveEdit"></BaseTextarea>-->
            <textarea ref="textarea" v-model="m.content" class="br-10 w-100 border border-solid border-gray-200 bg-gray-100 p-2" :style="{ height: textAreaHeight }" @keydown.enter="saveEdit"></textarea>
            <!--            <textarea v-model="m.content" class="w-100" :rows="m.content.split('\n').length" @keydown.enter="saveEdit"></textarea>-->
        </div>
        <div class="f ais mw-350 jcc mx-auto gap-3">
            <BaseButton style-type="secondary" class="x" @click.prevent="cancelEdit" size="md" rounded label="Cancel" />
            <BaseButton style-type="secondary" class="x" @click.prevent="saveEdit" size="md" rounded label="Save" />
        </div>
    </div>
</template>

<script>
import BaseTextarea from "@/components/CoreUI/BaseTextarea.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    components: { BaseButton, BaseTextarea },
    watch: {
        "m.content": function (newContent) {
            // this.$nextTick(() => {
            //     this.calculateTextAreaHeight();
            // });
            this.calculateTextAreaHeight();
        },
    },
    props: {
        m: Object,
        index: Number,
    },
    mounted() {
        this.minHeight = `${this.$refs.textarea.offsetHeight}px`;
        this.calculateTextAreaHeight();
    },
    data: () => ({
        textAreaHeight: "auto",
        minHeight: "auto",
    }),
    methods: {
        calculateTextAreaHeight() {
            const textarea = this.$refs.textarea;
            textarea.style.height = "auto";
            const scrollHeight = textarea.scrollHeight;
            setTimeout(() => {
                this.textAreaHeight = `${scrollHeight+20}px`;
                this.minHeight = `${scrollHeight+20}px`;
            }, 0);
        },
        saveEdit(event) {
            // prevent shift enter from triggering save
            if (event.shiftKey) {
                return;
            }
            this.$emit("saveEdit", this.m);
        },
        cancelEdit() {
            this.$emit("cancelEdit");
        },
    },
};
</script>
<style scoped lang="scss">
textarea{
  @apply dark:text-base-400;
  @apply border-0;
  resize: none;
  @apply dark:bg-base-900;
}
</style>
