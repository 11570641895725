<template>
    <div :key="`slide-${index}`" :class="slideContainerClasses" class="x vh-70 p-relative slide_background transition-all">
        <!--    <div :ref="`slide-${id}${index}`" :key="`slide-${id}${index}`" :class="slideContainerClasses" class="x vh-70 p-relative">-->
        <div class="col-md-6 x sticky-top slide_image" :class="randomImageStyle">
            <!--        <div :ref="`image-${id}${index}`" class="col-md-6 x sticky-top" :class="randomImageStyle">-->
            <PresentationImageContainer :document="document" :key="`image${index}`" :document-id="documentId" :image-dark="imageDark" :index="index" :inset="inset" :section="section"></PresentationImageContainer>
        </div>
        <div class="col-md-6 x text-left">
            <div class="f jcs y z-5 px-xl-6 py-md-8 slide_text aic p-3">
                <!--            <div :ref="`text-${id}${index}`" class="f jcs y z-5 px-xl-6 py-md-8 bg-darks-1 aic p-3">-->
                <PresentationSlideText :index="index" :key="'text' + index + id" :document="document" :document-id="document.id" :image-dark="imageDark" :section="section"></PresentationSlideText>
            </div>
        </div>
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin],
    props: {
        id: {},
        index: {},
        section: {},
        documentId: {},
        slideContainerClasses: {},
    },
    watch: {
        "document.theme.colors": {
            handler: function (val, oldVal) {
                if (val !== oldVal) {
                    this.textTheme();
                    // this.slideColors();
                }
            },
            deep: true,
        },
    },
    data() {
        return {
            imageDark: false,
            inset: false,
        };
    },
    computed: {
        randomImageStyle() {
            let inset = Math.random() < 0.5;
            if (inset) {
                this.inset = true;
            }
            return inset ? this.imageInset : this.imageFullbleed;
        },
        imageInset() {
            return "p-3 py-xl-5 pl-xl-5";
        },

        imageFullbleed() {
            return "p-0";
        },
    },
    mounted() {
        if (this.document.theme) {
            let t = this.document?.theme?.colors || false;
            this.$nextTick(() => {
                if (t) this.textTheme();
            });
            this.slideColors();
            // if (t) this.textTheme();
        }
    },
};
</script>
