<!-- IconPicker.vue -->
<template>
    <div class="icon-picker">
        <div class="grid grid-cols-6 gap-2">
            <div v-for="(icon, index) in icons" :key="index" class="icon-item p-2 border rounded cursor-pointer text-center" :class="{ 'bg-blue-100': selectedIcon === icon }" @click="selectIcon(icon)">
                <template v-if="icon.emoji">{{ icon.emoji }}</template>
                <template v-else-if="icon.font_awesome_class">
                    <i :class="icon.font_awesome_class"></i>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "IconPicker",
    data() {
        return {
            selectedIcon: null,
            icons: [
                { emoji: "🚀" },
                { emoji: "✨" },
                { emoji: "🌟" },
                { emoji: "💡" },
                { emoji: "🎉" },
                { emoji: "🌈" },
                { emoji: "🦄" },
                { emoji: "🧩" },
                { emoji: "🎨" },
                { emoji: "🎮" },
                { emoji: "🏆" },
                { emoji: "🚀" },
                { font_awesome_class: "fal fa-2xl f-30 fa-rocket" },
                { font_awesome_class: "fal fa-2xl f-30 fa-star" },
                { font_awesome_class: "fal fa-2xl f-30 fa-lightbulb" },
                { font_awesome_class: "fal fa-2xl f-30 fa-gift" },
                { font_awesome_class: "fal fa-2xl f-30 fa-palette" },
                { font_awesome_class: "fal fa-2xl f-30 fa-puzzle-piece" },
                { font_awesome_class: "fal fa-2xl f-30 fa-gamepad" },
                { font_awesome_class: "fal fa-2xl f-30 fa-trophy" },
                // Add more icons to the library
            ],
        };
    },
    methods: {
        selectIcon(icon) {
            this.selectedIcon = icon;
            this.$emit("icon-selected", icon);
        },
    },
};
</script>

<style scoped>
.icon-item:hover {
    background-color: #e0e0e0;
}
</style>
