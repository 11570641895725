function cleanMessageFields(messages) {
    try {
        const newMessages = messages
            .map(message => {
                const assistant = message.role === "assistant";
                const user = message.role === "user";
                const system = message.role === "system";
                const func = message.role === "function";
                const blank = message?.content?.trim() === "" || message.content === null;
                const image = message.kind === "image";
                if ((user || assistant || system || func) && !blank && !image) {
                    let newMessage = roleMessage(message.content, message.role);
                    if (message.role === "function") newMessage.name = message.name;
                    if (message?.results?.summary?.main) newMessage.content = newMessage.content + "\n\n ---" + message.results.summary.main;
                    if (message.function_call) {
                        let { name, arguments: args } = message.function_call;
                        newMessage.function_call = { name, arguments: JSON.stringify(args) };
                    }
                    return newMessage;
                }
            })
            .filter(Boolean);
        return newMessages;
    } catch (e) {
        console.error(e);
    }
}

export default cleanMessageFields;
