<template>
    <div class="absolute bottom left right top f aie jcc" style="z-index: 101000">
        <transition name="fade-scale" appear>
            <div class="bg-base-900/10 z-10 p-fill p-absolute"></div>
        </transition>
        <transition name="fade-up" appear>
            <div class="p-3 bg-snow m-3 aspect-landscape br-20 text-gray-900" style="z-index: 102000">
                <h4>Add a section</h4>
                <p>Type something like "A related products section" or "Add more details about the product's features"</p>
                <textarea v-model="userInput" class="border-light br-10" placeholder="What do you want in this section?" @keyup.enter="addSectionWithAI"></textarea>
                <BaseButton label="Add Section" size="md" class="z-5 x relative" @click.prevent="addSectionWithAI()"></BaseButton>
            </div>
        </transition>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "NewSectionModal",
    components: { BaseButton },
    props: {},
    data() {
        return {
            userInput: "",
        };
    },
    methods: {
        addSectionWithAI() {
            this.$emit("add-section", this.userInput);
            this.userInput = "";
        },
    },
};
</script>
