<template>
    <div appear class="animated fadeInUpSmooth duration-5 d-20 chat_code x relative mx-auto mx-auto mb-4 mt-4 overflow-hidden rounded-lg bg-gray-200 shadow dark:bg-base-800">
        <div class="my-0 flex items-center justify-between border-b border-b-base-200 py-0 pl-3 pr-3" style="padding:0px;!important">
            <div class="f aic jcc my-0 mb-0 py-3 text-sm font-bold text-gray-900 dark:text-base-100">
                {{ title }}
                <span class="badge-pill badge text-bold text-uppercase f-11 ml-2 bg-teal py-1 font-bold text-teal-900 dark:bg-teal-700 dark:text-teal-200">{{ detail }}</span>
            </div>
            <!--            <BaseButton style-type="info" size="sm" rounded icon="far fa-clipboard" data-clipboard-text="{{newCode}}" label="Button">-->
            <!--            </BaseButton>-->
        </div>
        <div class="code-example mx-auto my-0 py-0" style="padding: 0 !important">
            <div class="my-0 bg-snow py-3 dark:bg-base-600/50">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "ChatSpecialContainer",
    components: { BaseButton },
    data() {
        return {
            code: "",
            pill: "",
            title: "",
        };
    },
    props: {
        code: {
            type: String,
            required: true,
        },
        detail: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
            default: "Content",
        },
        langName: {
            type: String,
            required: true,
        },
    },
};
</script>
