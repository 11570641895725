<!-- src/components/CodeRenderer.vue -->
<template>
    <div class="my-4">
        <div class="x bg-snow-50 border-light br-10 component-sample mb-4 p-3 shadow" v-if="message && receivedVueComponent" ref="dynamicComponentContainer"></div>
        <!--        <div v-html="receivedVueComponent.template"></div>-->
        <BaseButton @click="runCode" size="sm" v-if="message && receivedVueComponent" class="!hover:bg-snow bg-transparent" rounded style-type="secondary" label="Run"></BaseButton>
    </div>
</template>

<script>
import { createApp, defineAsyncComponent } from "vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    components: { BaseButton },
    props: {
        message: {
            type: String,
            default: "",
        },
        extractedCode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            code: "",
            codeAvailable: false,
            receivedVueComponent: "",
        };
    },
    mounted() {
        this.extractCodeAndText();
        this.$nextTick(() => {
            this.runCode();
        });
    },
    methods: {
        importComponent() {
            let extractedCode = this.receivedVueComponent;

            // const appScript = new Function(extractedCode.script.replace("export default", "return"));
            const appScript = new Function(extractedCode.script);
            let appConfig = appScript();

            // Compile the template into a render function
            const { render } = compile(extractedCode.template);
            appConfig.render = render;

            this.dynamicComponent = appConfig;
        },
        extractCodeAndText() {
            if (typeof this.message !== "string") {
                console.error("Invalid message data received:", this.message);
                return;
            }

            const templateRegEx = /<template>((.|\n)*)<\/template>/;
            const scriptRegEx = /<script>((.|\n)*)<\/script>/;
            const scriptRegExModule = /<script type="module">((.|\n)*)<\/script>/;

            const templateMatch = this.message.match(templateRegEx);
            const scriptMatch = this.message.match(scriptRegEx) || this.message.match(scriptRegExModule);
            // const scriptMatchModule = this.message.match(scriptRegExModule);

            const template = templateMatch ? templateMatch[1] : "";
            const script = scriptMatch ? scriptMatch[1] : "";

            if (template && script) {
                this.codeAvailable = true;
                this.receivedVueComponent = { template, script };
            }
            this.code = this.message || "";
            try {
                this.code = this.code.replace(templateRegEx, "").replace(scriptRegEx, "");
            } catch (e) {
                console.log(e);
            }
        },
        runCode() {
            try {
                if (this.receivedVueComponent || this.extractedCode) {
                    const container = this.$refs.dynamicComponentContainer;
                    let { script = "", template = "" } = this.receivedVueComponent;
                    const appScript = new Function(script.replace(/export default/, "return"));
                    const appConfig = appScript();
                    if (!appConfig) return;
                    appConfig.template = this.receivedVueComponent.template;

                    const dynamicApp = createApp(appConfig);
                    dynamicApp.mount(container);
                }
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>
<style lang="scss">
.component-sample {
    h1 {
        font-size: 1.4rem !important;
    }
    h2 {
        font-size: 1.2rem !important;
    }
    button {
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
        border-radius: 0.5rem !important;
    }
    input {
        @apply border;
        @apply border-gray-900;
        @apply rounded-md;
        @apply border-opacity-25;
        @apply placeholder-base-400;
        @apply my-3;
    }
    button {
        @apply px-3;
        @extend .btn!optional;
        padding: 10px 20px !important;
        @apply rounded-xl;
        @apply bg-gray-700;
        @apply font-bold;
        @apply text-base-50;
        @apply my-2;
        //@apply f-17;
        @apply block;
        @apply border-gray-900;
        @apply hover:shadow;
        @apply border-0;
    }
}
</style>
