<!--<template>-->
<!--    <BaseBadge v-if="item?.status?.toLowerCase() === 'done'" color="green" :text="item.status" />-->
<!--    <BaseBadge v-else-if="item?.status === 'processing' || item?.status === 'Processing' || item?.status === 'queued'" color="yellow" :text="item.status" />-->
<!--    <BaseBadge v-else-if="item?.status === 'training' || item?.status === 'embedding'" color="indigo" :text="item.status" />-->
<!--    <BaseBadge v-else-if="item?.status === 'Failed' || item?.status === 'failed'" color="red" :text="item.status" />-->
<!--    <BaseBadge v-else color="gray" v-if="item?.status" :text="item.status" />-->
<!--</template>-->
<!--<script>-->
<!--import BaseBadge from "@/components/CoreUI/BaseBadge.vue";-->

<!--export default {-->
<!--    name: "StatusBadge",-->
<!--    components: { BaseBadge },-->
<!--    props: {-->
<!--        item: { type: Function },-->
<!--    },-->
<!--};-->
<!--</script>-->
<template>
    <BaseBadge v-if="item.status" :color="getColor(item?.status)" :text="item?.status" />
</template>

<script>
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";

export default {
    name: "StatusBadge",
    components: { BaseBadge },
    props: {
        item: { type: Object },
    },
    methods: {
        getColor(status) {
            if (!status) return "gray";
            status = status.toLowerCase();
            if (status === "done") return "green";
            if (["processing", "queued", "uploading"].includes(status)) return "yellow";
            if (["training", "embedding"].includes(status)) return "indigo";
            if (status === "failed") return "red";
            return "gray";
        },
    },
};
</script>
