<template>
    <div class="mx-auto mt-32 max-w-7xl px-3 sm:mt-56 lg:px-3">
        <div class="mx-auto lg:mx-0 max-w-2xl text-center">
            <h2 class="text-base font-semibold leading-8 text-green-400">{{ preheader }}</h2>
            <p class="mt-2 font-bold tracking-tight text-white text-6xl">{{ title }}</p>
            <p class="mt-3 text-lg leading-8 text-gray-300">{{ description }}</p>
        </div>
        <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            <div v-for="stat in stats" :key="stat.title" v-motion-up class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
                <dt class="text-sm leading-6 whitespace-pre" v-motion-fu v-if="stat.title">{{ stat.title }}</dt>
                <dd class="order-first text-6xl font-semibold tracking-tight whitespace-pre-line text-indigo-500" v-motion-fu v-if="stat.description">{{ stat.description }}</dd>
            </div>
        </dl>
    </div>
</template>
<script>
export default {
    name: "HomeContentStats",
    props: {
        stats: {
            type: Array,
            default: () => [],
        },
        preheader: {
            type: String,
            default: "Our track record",
        },
        title: {
            type: String,
            default: "Trusted by thousands of developers worldwide",
        },
        description: {
            type: String,
            default: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        },
    },
};
</script>
