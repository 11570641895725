import { auth, db } from "@/firebase";
import { my_profile, partner_profile } from "@/mixins/Chat/chatStrings"; // import firebaseConfig from your firebase initialization file

export default {
    namespaced: true,
    state() {
        return {
            auth0User: {},
            users: [],
            user: {
                name: "",
                email: "",
                brand: "",
                lastSeen: "",
                firstName: "",
                lastName: "",
                photoURL: "",
                displayName: "",
                title: "My Profile",
                role: { id: "user", name: "User" },
                uid: "",
            },
        };
    },
    mutations: {
        // <span data-v-2e008874="" class="d-block">User ID: Pn6tFBNy63US4RpIGyEsLdaSyRy1</span>
        setUser(state, payload) {
            state.user = payload;
        },
        updateUser(state, payload) {
            Object.assign(state.user, payload);
        },
        SET_AUTH_0_USER(state, payload) {
            state.auth0User = payload;
        },
        UPDATE_USER_PROP(state, { keyPath, value }) {
            const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");

            function setValue(obj, keys, value) {
                if (keys.length === 1) {
                    obj[keys[0]] = value;
                    return;
                }
                setValue(obj[keys[0]], keys.slice(1), value);
            }

            setValue(state.user, keys, value);
        },
        setUsers(state, users) {
            state.users = users;
        },
    },
    actions: {
        setUsers({ commit, rootState, state }, users) {
            commit("setUsers", users);
        },
        updateUserProp({ commit, rootState, state }, payload) {
            commit("UPDATE_USER_PROP", payload);
        },
        setAuth0User({ commit, rootState, state }, user) {
            commit("SET_AUTH_0_USER", user);
            // console.groupCollapsed("setUser");
            // console.log("setUser");
            // console.groupEnd();
            // commit("setUser", user);
        },
        setUser({ commit, rootState, state }, user) {
            console.groupCollapsed("setUser");
            console.log("setUser");
            console.groupEnd();
            commit("setUser", user);
        },
        async updateLastSeen() {
            const userId = this.$store.state.user.user.uid;
            if (userId) {
                try {
                    const userRef = doc(db, "users", userId);
                    await setDoc(userRef, { lastSeen: Timestamp.now() }, { merge: true });
                    console.log("Last seen updated successfully");
                } catch (error) {
                    console.error("Error updating last seen: ", error);
                }
            }
        },
        async fetchUser({ commit, rootState, state }) {
            // console.log("user: ", auth, user);
            console.groupCollapsed("fetchUser");
            console.log("fetchUser");
            // const userId = state.dbUser.uid;
            const userId = state?.user?.uid || "";
            console.log(userId);
            console.groupEnd();
            if (!userId) {
                return;
            } else {
                const userRef = db.collection("users").doc(userId);
                const userSnapshot = await userRef.get();
                if (userSnapshot.exists) {
                    // log("userSnapshot.exists");
                    let user = userSnapshot.data();
                    logGroup("User", user);
                    console.log("User", user);
                    commit("setUser", user);
                } else {
                    // Create a new user object with default data
                    console.log("New user");
                    await userRef.set(state.user);
                }
            }

            return;
        },
        async saveUser({ commit, rootState, state }, userData) {
            console.groupCollapsed("SaveUser");
            const userId = state.user.uid;
            console.log("saveUser", state.user.uid);
            console.groupEnd();
            if (userId) {
                const userRef = db.collection("users").doc(userId);
                await userRef.update(userData, { merge: true });
                let user = await userRef.get();
                user = user.data();
                commit("updateUser", user);
                console.log("User updated successfully", user);
                return user;
            }
            return null;
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
        users(state) {
            return state.users;
        },
    },
};
