<template>
    <div class="z-0 x">
        <button class="text-white p-2 rounded" @click="isOpen = !isOpen">
            <i class="far fa-gear text-gray-800 opacity-0 group-hover:opacity-100 translate-y-10 group-hover:translate-y-0 elastic-easing"></i>
        </button>
        <!--        <transition name="fade-up">-->
        <teleport to="body">
            <div v-if="isOpen" class="z-10 width-900 f align-fixed-center justify-fixed-center absolute z-5 bg-snow overflow-hidden shadow-lg br-10 border-light">
                <div class="f aic jcc bg-black-50 px-6 x gridBackground !bg-base-50 overflow-hidden relative">
                    <div :style="{ ...itemStyle, ...previewStyle }">Shop now</div>
                    <div class="x overflow-hidden p-absolute p-fill">
                        <!--                        <pre class="absolute bottom left f-11 p-3 x">{{ previewStyle }}</pre>-->
                        <!--                        <pre class="absolute top left f-11 x">{{ itemStyle }}</pre>-->
                    </div>
                </div>
                <div class="flex-shrink-0 border-left">
                    <div class="f fc border-top">
                        <div class="text-left f aic jcb editor-section border-bottom">
                            <label class="block editor-section-title">Text</label>
                            <input v-model="color" class="c-25 rounded-full" type="color" />
                        </div>

                        <div class="text-left f aic jcb editor-section border-bottom">
                            <label class="block editor-section-title mb-0">Background</label>
                            <input v-model="backgroundColor" class="c-25 rounded-full border" style="border: 1px solid black" type="color" />
                        </div>
                    </div>

                    <div class="border-bottom text-left editor-section">
                        <label class="block editor-section-title">Radius:</label>
                        <div class="f gap-2">
                            <input v-model="borderRadius" class="w-full accent-black-900" max="2" min="0" step="0.1" type="range" />
                            <div class="!m-0 editor-section-title f-11 border p-1 c-25 br-5 f aic jcc">{{ borderRadius }}</div>
                        </div>
                    </div>
                    <div class="border-bottom text-left editor-section">
                        <label class="block editor-section-title">Font Size:</label>
                        <div class="f gap-2">
                            <input v-model="fontSize" class="w-full accent-black-900 !range-input" max="2" min="0.8" step="0.1" type="range" />
                            <div class="!m-0 editor-section-title f-11 border p-1 c-25 br-5 f aic jcc">{{ fontSize }}</div>
                        </div>
                    </div>

                    <div class="border-bottom text-left editor-section">
                        <div class="f gap-2 aic">
                            <label class="block editor-section-title">Y</label>
                            <input v-model="paddingX" class="w-full accent-black-400" max="1.5" min="0.2" step="0.1" type="range" />
                            <div class="!m-0 editor-section-title f-11 border p-1 c-25 br-5 f aic jcc">{{ paddingX }}</div>
                        </div>
                    </div>

                    <div class="border-bottom text-left editor-section">
                        <div class="f gap-2 aic">
                            <label class="block editor-section-title">X</label>
                            <input v-model="paddingY" class="w-full accent-black-900" max="3" min="1" step="0.1" type="range" />
                            <div class="!m-0 editor-section-title f-11 border p-1 c-25 br-5 f aic jcc">{{ paddingY }}</div>
                        </div>
                    </div>
                    <div class="p-3 f fx gap-2">
                        <SimpleButton class="x" soft @click="close">Cancel</SimpleButton>
                        <SimpleButton class="x" @click="saveStyle">Save</SimpleButton>
                    </div>
                </div>
            </div>
        </teleport>
        <!--        </transition>-->
    </div>
</template>

<script>
import SimpleButton from "@/views/SimpleButton.vue";

export default {
    components: { SimpleButton },
    props: {
        itemStyle: {
            type: Object,
            default: () => ({
                color: "#000000",
                backgroundColor: "#ffffff",
                // borderRadius: 0,
                paddingX: this.splitPadding?.[0],
                paddingY: this.splitPadding?.[1],
            }),
        },
    },
    data() {
        return {
            isOpen: false,
            color: this.itemStyle.color,
            backgroundColor: this.itemStyle.backgroundColor,
            borderRadius: `${this.itemStyle.borderRadius}`.replace("em", ""),
            paddingX: `${this.splitPadding?.[0] || 0}`.replace("em", ""),
            paddingY: `${this.splitPadding?.[1] || 0}`.replace("em", ""),
            fontSize: `${this.itemStyle.fontSize}`.replace("rem", ""),
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.isOpen = false;
            this.color = this.itemStyle.color;
            this.backgroundColor = this.itemStyle.backgroundColor;
            this.borderRadius = `${this.itemStyle.borderRadius}`.replace("em", "");
            this.paddingX = `${this.splitPadding?.[0] || 0}`.replace("em", "");
            this.paddingY = `${this.splitPadding?.[1] || 0}`.replace("em", "");
            this.fontSize = `${this.itemStyle.fontSize}`.replace("rem", "");
        });
    },
    computed: {
        splitPadding() {
            return this.itemStyle.padding.split(" ");
        },
        combinedPadding() {
            let paddingX = this.paddingX || this.splitPadding?.[0];
            let paddingY = this.paddingY || this.splitPadding?.[1];
            return `${paddingX}em ${paddingY}em`;
        },
        previewStyle() {
            return {
                color: this.color,
                backgroundColor: this.backgroundColor,
                borderRadius: `${this.borderRadius}em`,
                padding: `${this.combinedPadding}`,
                fontSize: `${this.fontSize}rem`,
            };
        },
    },
    methods: {
        close() {
            this.isOpen = false;
        },
        saveStyle() {
            let item = {
                color: this.color,
                backgroundColor: this.backgroundColor,
                borderRadius: this.borderRadius,
                padding: this.combinedPadding,
                fontSize: this.fontSize,
            };
            let style = { ...this.itemStyle, ...this.previewStyle };
            this.$emit("save-style", style);
            this.isOpen = false;
        },
    },
};
</script>
