<template>
    <div class="agent-manager prose">
        <!--        <p class="whitespace-pre-line" v-if="agentString">{{ agentString }}</p>-->
        <div v-if="showConversation">
            <template v-for="(agent, index) in activeAgents">
                <div v-if="message.command.arguments.agent_name === agent.name">
                    Conversation:
                    <div v-if="agent.conversation" class="br-10 border-light my-3 bg-gray-100 dark:bg-base-900 p-3">
                        <h5 class="mb-3" v-if="agent?.name">Chat with {{ agent?.name }}</h5>
                        <ChatPlainText class="mb-4" :messages="agent.conversation" />
                        <BaseInput :modelValue="newMessage" :key="`${agent.name}-conversation`" :rows="1" class="mb-1 mt-3" @keydown.enter.shift.prevent="" @keydown.enter="addMessageToAgentConvo(agent.name, newMessage)" placeholder="Add a message to the conversation" @update:modelValue="newMessage = $event" />
                    </div>
                </div>
            </template>
        </div>
        <div v-if="showAgentManager">
            <h6>ACTIVE AGENTS</h6>
            <ol class="">
                <transition-group name="highlight" tag="div" enter-active-class="animated text-amber-300" appear>
                    <li v-for="(agent, index) in activeAgents" :key="index" class="inline_task_item">
                        <div class="f aic">
                            <div v-if="agent.description">
                                <span class="fwb" v-if="agent.name">{{ agent.name }}:</span>
                                <span class="line-height-small" v-if="agent.description">{{ agent.description }}</span>
                                <div class="d-none f-15 mb-5 whitespace-pre-line" v-if="agent.goals?.length > 0">
                                    Goals:
                                    <ol class="d-block">
                                        <li v-for="(goal, index) in agent.goals" :key="index">{{ goal }}</li>
                                    </ol>
                                </div>
                            </div>
                            <div>
                                <ButtonRemoveListItem @click.prevent="removeAgent(agent.name)" />
                            </div>
                        </div>
                    </li>
                </transition-group>
            </ol>
            <form v-if="toggleAdd" class="mt-1 block" @blur="toggleAddAgent">
                <BaseInput ref="input" :modelValue="newAgentName" :rows="1" class="mb-1" placeholder="Add an agent name" @keyup.enter="addAgent" @update:modelValue="newAgentName = $event" />
                <BaseInput :modelValue="newAgentDescription" :rows="1" class="mb-1" placeholder="Add an agent description" @keyup.enter="addAgent" @update:modelValue="newAgentDescription = $event" />
            </form>
            <BaseInput :modelValue="newTask" :rows="1" class="mb-1" placeholder="Add an agent task" @keyup.enter="requestAgent(newTask)" @update:modelValue="newTask = $event" />
            <div class="block">
                <ButtonAddNew class="br-10 border-light mt-3 inline-flex cursor-pointer px-3 hover:bg-snow dark:hover:bg-base-600/50" text="Add Agent" @click.prevent="toggleAddAgent" />
            </div>
        </div>
    </div>
</template>

<script>
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import ButtonAddNew from "@/views/Chat/ButtonAddNew.vue";
import ButtonRemoveListItem from "@/views/Chat/ButtonRemoveListItem.vue";
import agentCommand from "@/mixins/Chat/Commands/agentCommand";
import ChatPlainText from "@/views/Chat/ChatPlainText.vue";

export default {
    name: "AgentManager",
    components: { ChatPlainText, ButtonRemoveListItem, ButtonAddNew, BaseInput },
    data() {
        return {
            newTask: "",
            newAgentName: "",
            newAgentDescription: "",
            toggleAdd: false,
            newMessage: "",
        };
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    mixins: [agentCommand],
    computed: {
        activeAgents() {
            return this.$store.getters["chat/agents/activeAgents"];
        },
        showConversation() {
            if (this.message?.command?.name === "message_agent" || this.message?.command?.name === "reply_to_agent") {
                return true;
            } else {
                return false;
            }
            // if (this.message?.command?.name === "create_agent") {
            //     return false;
            // }
        },
        showAgentManager() {
            if (this.message && this.message.role === "system") {
                return true;
            }
            if (this.message?.command?.name === "create_agent") {
                return true;
            }
            return false;
        },
    },
    methods: {
        async requestAgent(newTask) {
            this.newTask = "";
            await this.agentBot({ task: newTask });
        },
        toggleAddAgent() {
            this.toggleAdd = !this.toggleAdd;
            if (this.toggleAdd) {
                this.$nextTick(() => {
                    this.$refs.input.$refs.input.focus();
                });
            }
        },
    },
    async mounted() {
        if (!this.activeAgents.length) {
            if(!this.$store?.state?.chat?.agents?.agents?.length > 0) {
                await this.$store.dispatch("chat/agents/fetchAgents");
            }
        }
    },
};
</script>
