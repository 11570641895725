<template>
    <div class="y relative" :key="image" @mouseover="hovered = true" @mouseleave="hovered = false">
        <div class="block y shadow border-light text-snow border br-20 m-5 overflow-hidden relative mw-400 mx-auto brand-bg" style="max-height: 750px">
            <!--        <StyleGuideImage v-if="image" :image="image" :size="{ width: 600, height: 1600 - 1600 * value }" :bottom-offset="value * 10" class="x y" />-->
            <div class="x y p-absolute p-fill">
                <div :style="`background:url('${image?.url.replace('Original', 'public')}'); background-size:cover;background-position:center;height:${100 - value}%`" class="top bottom left right"></div>
            </div>
            <div class="p-absolute z-0 bottom left right x top">
                <ImageGradient :image="{ color: image?.backgroundColor }" :height="value * 10" :offset="50 - value" class="x y p-absolute bottom left right p-fill" />

                <transition name="fade-up">
                    <div v-if="value < 15" class="top left right p-5 pt-8 text-center"><h1 class="f-20 h6 button-4 rounded-full inline-block mx-auto py-1 px-3 fwb" :style="`font-family:${font.fontFamily};letter-spacing:0px;`">BACK IN STOCK</h1></div>
                </transition>
                <div class="p-absolute bottom left right p-5 text-center z-1">
                    <div :style="`margin-bottom:${value * 1}px`">
                        <ImageCaption class="mb-4 mt-4" :key="image" :color="textColor" :text="text" size="20" :style="`text-wrap:pretty;font-family:${font.fontFamily};font-weight:500;text-transform:${fonts?.textTransform}`" />
                    </div>
                    <!--                        <p class="mb-5 f-24 px-3 py-3 br-20" :style="`background:${imageObj.backgroundColor};color:${getContrastColor(imageObj.backgroundColor)}`">{{ styleGuide.description }}</p>-->
                    <div class="relative transition-all" :style="`margin-bottom:${value * 0.5}px`">
                        <BrandLogoComponent :color="textColor" :width="150" class="mx-auto"></BrandLogoComponent>

                        <transition name="fade-up">
                            <h6 class="mt-5 mb-0 button-1" v-if="value > 15">Swipe up</h6>
                        </transition>
                    </div>
                </div>
            </div>
            <!--         add an input range slider  -->
        </div>
        <div class="absolute bottom left right z-4 pb-2" style="bottom: 50px">
            <transition name="fade-up">
                <!--       increment by 10   -->
                <div class="px-3 py-0 bg-snow/50 f aic jcc bg-blur mw-300 rounded-full mx-auto text-center border-light shadow" v-if="hovered">
                    <input type="range" min="0" max="30" v-model="value" class="w-full mb-0 pb-2" />
                </div>
            </transition>
            <!--          increment must work -->
        </div>
    </div>
</template>
<script>
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import ImageCaption from "@/components/styleGuide/Sections/Themes/ImageCaption.vue";
import ImageGradient from "@/components/generative_templates/ImageGradient.vue";
import StyleGuideImage from "@/components/styleGuide/Images/StyleGuideImage.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "StyleGuideAd",
    mixins: [styleGuideMixin],
    components: { BrandLogoComponent, ImageCaption, ImageGradient, StyleGuideImage },
    props: {
        image: {
            type: Object,
            default: () => ({}),
        },
        text: {
            type: String,
            default: "New Arrivals",
        },
    },
    mounted() {
        let allColors = this.styleGuide?.colors?.all_colors || [];
        let imageColors = this.image?.colors || [];
        let color = this.image.color || "";
        let combinedColors = [...allColors, ...imageColors];
        let contrastColor = this.getContrastColor(color, combinedColors);
        if (this.image?.backgroundColor) this.textColor = contrastColor;
    },
    data() {
        return {
            value: 10,
            hovered: false,
            textColor: "#000",
        };
    },
    computed: {
        font() {
            return this.fonts[0];
        },
    },
};
</script>
