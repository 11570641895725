// streamingMixin.js
import { set } from "lodash-es";
import streamingHelpers from "@/mixins/ai/streamingHelpers";
import { mapActions } from "vuex";
import completion from "@/mixins/ai/completion";
import getModel from "@/mixins/ai/get_model";
import getTokenCost from "@/mixins/ai/get_token_cost";
import setupFunctionCall from "@/mixins/ai/setup_function_call";
import initializeMessages from "@/mixins/ai/initialize_messages";
import setupMessages from "@/mixins/ai/setup_messages";
import setupFunctions from "@/mixins/ai/setup_functions";
import logTokens from "@/mixins/ai/log_tokens";
import getModels from "@/mixins/ai/get_models";
import messageLogger from "@/mixins/ai/message_logger";
import gptError from "@/mixins/ai/gpt_error";
import randomId from "@/mixins/ai/random_id";
import cleanMessages from "@/mixins/ai/clean_messages";
import isCommandObject from "@/mixins/ai/is_command_object";
import streamRequest from "@/mixins/ai/stream_request";
import stopGenerating from "@/mixins/ai/stop_generation";
import prepareRequestData from "@/mixins/ai/prepare_request";
import processContent from "@/mixins/ai/process_streaming_tokens";

import generativeMixin from "@/mixins/ai/generativeMixin";
import { streamClaude, streamMetaprompt } from "@/mixins/ai/stream_claude.js";

export default {
    data: () => ({
        eventSource: null,
        reconnectDelay: 5000, // Reconnect delay in milliseconds (5 seconds)
    }),
    mixins: [streamingHelpers, generativeMixin],
    computed: {},
    methods: {
        ...mapActions("chat", ["updateMessageObject"]),
        completion,
        setupFunctionCall,
        setupFunctions,
        initializeMessages,
        getModel,
        getTokenCost,
        setupMessages,
        getModels,
        logTokens,
        cleanMessages,
        isCommandObject,
        messageLogger,
        streamRequest,
        prepareRequestData,
        stopGenerating,
        async streamCompletionSimple(requestObject, from) {
            // console.log("streamCompletionSimple");
            try {
                let aiModel = gpt3; // gpt316;
                if (requestObject.model) aiModel = requestObject.model;
                let requestData = {
                    model: aiModel,
                    messages: [sysMessage(requestObject.prompt), userMessage(requestObject.message)],
                    stream: true,
                    requestId: randomId(),
                };
                if (requestObject.max_tokens) requestData.response_length = requestObject.max_tokens;
                if (requestObject.temperature) requestData.temperature = requestObject.temperature;
                if (typeof requestObject.message === "Array") {
                    console.log("quick response: Messages is an array");
                    requestData.messages = requestObject.message;
                    // console.log("requestData Message", requestData.message);
                } else if (typeof requestObject.message === "object") {
                    requestData.messages = requestObject.message;
                    // console.log("requestData Message", requestObject.message);
                } else {
                    // console.log("quick response: Messages is a string");
                    // console.log("requestData Message", requestObject.message);
                }
                const response = await streamRequest(requestData, from);

                let messageContent = "";
                const processToken = token => {
                    messageContent += token;
                    requestObject.updateFunction(messageContent);
                    if (requestObject.onPartialUpdate) requestObject.onPartialUpdate(token);
                };

                await processContent(this.$store, response, processToken);
                // await this.processContent(response, processToken);

                // Call the onComplete callback, if provided
                if (requestData.onComplete) requestData.onComplete();
                try {
                    await messageLogger(messageContent, from, requestData, messages, response);
                } catch (error) {
                    console.error(error);
                }
                return messageContent;
            } catch (e) {
                gptError(e);
            }
        },
        async GetStreamingCompletion(options) {
            let {
                model = gpt3,
                messages = [aiMessage("hey I'm a helpful assistant"), userMessage("what's up?")],
                max_tokens = 4096,
                temperature = 0,
                id,
                functions = null,
                function_call = null,
                from = "chat",
                metaprompt, //
                onPartialUpdate,
                updateFunction,
                onComplete,
            } = options;

            if (!id) id = randomId();
            try {
                let requestObject = {
                    ...options,
                    model,
                    messages,
                    requestId: id,
                    max_tokens,
                    metaprompt,
                    temperature,
                    onPartialUpdate,
                    updateFunction,
                    onComplete,
                };

                // Remove empty values from options
                // Object.keys(requestObject).forEach(key => requestObject[key] === null && delete requestObject[key]);
                let response = await streamMetaprompt({ ...metaprompt }, from);
                // if (model.includes("claude")) response = await streamRequest(requestObject, from);
                // else response = await streamClaude(requestObject, from);

                let messageContent = "";
                const processToken = token => {
                    if (token) messageContent += token;
                    if (options.onPartialUpdate) options.onPartialUpdate(token);
                    if (options.updateFunction) options.updateFunction(messageContent);
                };

                await processContent(this.$store, response, processToken);

                // Call the onComplete callback with the final messageContent
                if (onComplete) onComplete(messageContent);

                try {
                    await messageLogger(messageContent, from, requestObject, messages, response);
                } catch (error) {
                    console.error(error);
                }
                return messageContent;
            } catch (e) {
                gptError(e);
            }
        },
        async processResponseStream(response, onDataCallback, onErrorCallback) {
            try {
                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");

                try {
                    while (true) {
                        const { value, done } = await reader.read();
                        if (done) break;

                        const chunk = decoder.decode(value);
                        const tokens = chunk.split("\n\n").filter(Boolean);

                        tokens.forEach(token => {
                            let [_, text] = token.split(": ");
                            try {
                                text = JSON.parse(text);
                                if (typeof text === "object" && text?.choices?.[0]?.delta?.content) text = text.choices[0].delta.content;
                            } catch (e) {
                                onErrorCallback(e); // Using onErrorCallback for error handling
                                return;
                            }
                            if (text && typeof text === "string") {
                                if (text === "[DONE]") return;
                                onDataCallback(text); // Using onDataCallback for valid text data
                            }
                        });
                    }
                } catch (error) {
                    onErrorCallback(error); // Handling potential reading errors
                }
            } catch (e) {
                onErrorCallback(e); // Handling potential connection errors
            }
        },
        updateStreamProp(key, value, stream) {
            try {
                this.$store.dispatch("stream/updateStreamProp", { keyPath: key, value: value, stream });
            } catch (error) {
                console.groupCollapsed(`%c Failed to update ${key}`, fail, `${value}`);
                console.error("updateStreamProp", error.message, key, value);
                console.groupEnd();
            }
        },
        async simpleMessage(message, prompt, target, onUpdate, onComplete, tokens, onPartialUpdate, temperature, from, model) {
            let responseText = "";
            let request = {
                prompt: prompt,
                message: message,
                updateFunction: onUpdate
                    ? onUpdate
                    : content => {
                          // let handle = target();
                          let handle;
                          set(this, handle, content);
                          responseText += content;
                      },
                onComplete: onComplete
                    ? onComplete
                    : () => {
                          console.log("Processing completed", responseText);
                          return responseText;
                      },
            };
            if (tokens) request.max_tokens = tokens;
            if (temperature) request.temperature = temperature;
            if (onPartialUpdate) request.onPartialUpdate = onPartialUpdate;
            request.model = model ? model : gpt3;

            await this.streamCompletionSimple(request, from);
        },
    },
};
