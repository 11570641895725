<template>
    <div class="d-block x relative">
        <div class="f x justify-content-end">
            <div class="x">
                <div class="f jcs x aic cursor-pointer py-2" @click="toggleCollapse()">
                    <!-- Conditionally apply the animation style based on the `animate` prop -->
                    <div class="px-1" v-if="animate">
                        <p class="d-block mb-0 pr-4 pt-1 transition" v-if="title" :class="{ fwb: !closed }">
                            <span class="animated o-0 mb-0 mr-1 inline-block" v-for="(word, index) in splitWords(title)" :key="`title-word-${index}`" :style="animatedWordStyle(index)">{{ word }}</span>
                        </p>
                        <p class="d-block mb-0 pr-4 pt-1 transition" v-else-if="question" :class="{ fwb: !closed }">
                            <span class="animated o-0 mb-0 mr-1 inline-block" v-for="(word, index) in splitWords(question.question)" :key="`quesiton-word-${index}`" :style="animatedWordStyle(index)">{{ word }}</span>
                        </p>
                    </div>
                    <div v-else>
                        <p class="d-block mb-0 transition" v-if="title" :class="{ fwb: !closed }">
                            {{ title }}
                        </p>
                        <p class="d-block mb-0 transition" v-else-if="question" :class="{ fwb: !closed }">
                            {{ question.question }}
                        </p>
                    </div>
                </div>
                <div class="" v-if="!closed">
                    <template v-if="question">
                        <ul v-if="question.list" class="prose mt-1">
                            <li class="animated fadeInUpSmooth duration-5 text-gray-500 dark:text-base-400" :class="`d-${index * 2}`" v-for="(item, index) in question.list">
                                {{ item }}
                            </li>
                        </ul>
                        <div class="content animated fadeInUpSmooth prose duration-5 block">
                            <div class="m-0 whitespace-pre-wrap text-gray-400 dark:text-base-400" v-if="question.snippet">{{ question.snippet }}</div>
                        </div>
                        <div v-if="false" class="d-inline-flex mw-150 jcs animated fadeInUpSmooth f p-relative duration-5 aic mt-4 overflow-hidden rounded-full border border-gray-300 p-1 pl-2 dark:border-base-600" :class="`d-${showIndexAnimation}`">
                            <div class="p-relative br-50 mr-1 flex-1 flex-shrink-0 overflow-hidden border border-gray-300 bg-snow dark:border-base-600" style="min-width: 20px; min-height: 20px; width: 20px; height: 20px" v-if="question.thumbnail">
                                <img class="p-absolute p-fill o-cover block" :src="question.thumbnail" />
                            </div>
                            <div class="line-height-smaller x o-5">
                                <p class="fwsb dark:txt-bade-500 m-0 mb-0 block flex-1 whitespace-nowrap text-xs line-clamp-1" v-if="question.title">{{ question.title }}</p>
                                <!--                                <a v-if="question.link" class="mb-0 text-xs text-base-500" :href="question.link">{{ question.displayed_link }}</a>-->
                                <!--                                <span v-if="question.source" class="mb-0 text-xs text-base-500">{{ question.source }}</span>-->
                            </div>
                        </div>
                        <!--                        <div class="o-8 gap-2" v-if="question.snippet_links">-->
                        <!--                            <div class="f gap-1 mobile-scroll scrollbar-hide mt-3">-->
                        <!--                                <div v-for="item in question.snippet_links">-->
                        <!--                                    <BaseButton :is="'a'" style-type="secondary" v-if="item.link" :href="item.link" size="sm" rounded :label="item.text" target="_blank"></BaseButton>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </template>
                    <div class="x mt-3 overflow-visible whitespace-pre-wrap px-0">
                        <slot></slot>
                    </div>
                </div>
            </div>
            <div class="p-absolute right top bottom p-2 pr-3">
                <i class="fal fa-chevron-down o-5"></i>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "RelatedQuestions",
    components: { BaseButton },
    props: {
        title: {
            type: String,
            default: () => {},
            required: false,
        },
        question: {
            type: Object,
            default: () => {},
            required: false,
        },
        openOverride: {
            type: Boolean,
            default: false,
            required: false,
        },
        animate: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.openOverride) {
            this.closed = false;
        }
    },
    computed: {
        showIndexAnimation() {
            if (this.question && this.question.list && this.question.list.length > 0) {
                return this.question.list.length > 1;
            } else {
                return 2;
            }
        },
    },
    methods: {
        splitWords(text) {
            return text.split(" ");
        },
        animatedWordStyle(index) {
            const delayInSeconds = index * 0.2;
            return `animation: fadeIn 0.5s ${delayInSeconds}s forwards`;
        },
        toggleCollapse() {
            this.closed = !this.closed;
        },
    },
    data() {
        return {
            closed: true,
        };
    },
};
</script>
<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform:translateY(10px);
  }
  to {
    opacity: 1;
      transform:translateY(0px);
  }
}
</style>
