<template>
    <div>
        <div class="sm:flex sm:justify-between">
            <div>
                <div class="inline-flex border border-gray-200 rounded-full p-0.5 dark:border-gray-700">
                    <button
                        class="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-full text-gray-500 hover:bg-blue-100 hover:text-blue-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:hover:bg-blue-900 dark:hover:text-blue-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        type="button">
                        <IconTHumbsUp />
                    </button>
                    <button
                        class="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-full text-gray-500 hover:bg-blue-100 hover:text-blue-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:hover:bg-blue-900 dark:hover:text-blue-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        type="button">
                        <IconThumbsDown />
                    </button>
                </div>
                <button class="py-2 px-3 inline-flex items-center gap-x-2 text-sm rounded-full border border-transparent text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" type="button">
                    <IconCopy />
                    Copy
                </button>
                <button class="py-2 px-3 inline-flex items-center gap-x-2 text-sm rounded-full border border-transparent text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" type="button">
                    <IconShare />
                    Share
                </button>
            </div>

            <div class="mt-1 sm:mt-0">
                <button class="py-2 px-3 inline-flex items-center gap-x-2 text-sm rounded-full border border-transparent text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" type="button">
                    <svg class="flex-shrink-0 size-4" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 12a9 9 0 1 1-9-9c2.52 0 4.93 1 6.74 2.74L21 8" />
                        <path d="M21 3v5h-5" />
                    </svg>
                    New answer
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import IconCopy from "@/views/IconCopy.vue";
import IconShare from "@/views/IconShare.vue";
import IconTHumbsUp from "@/views/IconTHumbsUp.vue";
import IconThumbsDown from "@/views/IconThumbsDown.vue";

export default {
    name: "MessageButtonPile",
    components: { IconCopy, IconShare, IconTHumbsUp, IconThumbsDown },
    props: {
        message: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
