<template>
    <ChatMessageWrapper :hide-avatar="hideAvatar" class="x" :m="m" :index="index" :showDebugger="$store.state.chat.showDebugger">
        <div>
            <!--                <ChatMessageEdit v-if="editMode" :m="m" @saveEdit="saveEdit" @cancelEdit="cancelEdit"></ChatMessageEdit>-->
            <ChatMarkdown :class="{ 'x block pr-3': mobile, 'x mw-650': !mobile }" :content="m.content"></ChatMarkdown>
            <ChatMarkdown v-if="m.function_call" :class="{ 'x block pr-3': mobile, 'x mw-650': !mobile }" :content="m.function_call.arguments"></ChatMarkdown>
        </div>
    </ChatMessageWrapper>
</template>
<script>
import MessageEditButtons from "@/components/chat/MessageEditButtons.vue";
import ChatMessageEdit from "@/components/chat/messages/ChatMessageEdit.vue";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import ChatAvatar from "@/components/CoreUI/ChatAvatar.vue";
import ChatMessageWrapper from "@/components/chat/messages/ChatMessageWrapper.vue";

export default {
    name: "User",
    components: { ChatAvatar, ChatMarkdown, ChatMessageEdit, MessageEditButtons, ChatMessageWrapper },
    props: {
        m: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        hideAvatar: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            editMode: false,
            hovered: false,
        };
    },
    methods: {
        cancelEdit() {
            this.editMode = false;
        },
        editMessage() {
            console.error("startEditing");
            this.editMode = true;
        },
        saveEdit() {
            console.error("saveEdit");
            this.editMode = false;
            this.$emit("editMessage", this.m, this.index);
        },
        removeMessage() {
            this.$emit("remove-message", this.m);
        },
    },
};
</script>
