import { ref as storageRef } from "firebase/storage";
import JSON5 from "json5";
import streamingMixin from "@/mixins/ai/streamingMixin";
import { debounce } from "lodash";
import { mapActions, mapGetters } from "vuex";
import Stability from "@/views/Stability.vue";
import EnhancePresentationColors from "@/mixins/presentations/EnhancePresentationColors";
import StableDiffusion from "@/mixins/images/StableDiffusion";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import updateSlideProp from "@/mixins/ai/update_slide_prop";
import addImagesToQueue from "@/mixins/ai/add_images_to_que";
import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import gptError from "@/mixins/ai/gpt_error";
import completion from "@/mixins/ai/completion";
import googleImageSearch from "@/mixins/ai/google_image_search";
import webRequest from "@/mixins/ai/web_request";

// let newImagePrompt = `Generate a detailed and visually descriptive textual representation based on the user input, designed for AI-generated text-to-image generation, using keyword-style tags for aspects such as image resolution and popular trends. The output should include multiple elements and details like the provided examples.
//
// User Input: "[a brief description of a scene or subject]"
//
// Please provide a clear and concise description that accurately interprets the user's text and contains an abundance of details to guide the AI in generating a visually striking and relevant image. Use keyword-style tags and the following format:
//
// "A [subject description], [action/scene details], [additional details], [style_tags], [artist_tags], [resolution_tags (e.g., #4K, #8K)], [trending_tags (e.g., #trending_on_Artstation)]"
//
// Example Inputs and Outputs:
//
// 1. Input: "soviet flat"
//    Output: "A Soviet flat interior, home, ultra realism, intricate details, detailed image."
//
// 2. Input: "vintage house at night"
//    Output: "An illustration of the interior of a vintage house in the night, porcelain, vintage, anime style, 4k, HD, unreal engine, greg rutkowski, loish, rhads, beeple, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, alphonse mucha, global illumination, detailed and intricate environment."
//
// 3. Input: "Midcentury living room in Seoul"
//    Output: "Midcentury living room in Seoul belonging to a music/journalist couple, intricate detail, 600mm film, soft shadows, 8k resolution, professional photography, creative design and striking colors, extreme symmetry, perfect composition, volumetric lighting, centre framing."
//
// [OUTPUT]:`;

// let systemPrompt = `Write a prompt for an AI Image generator to add succinct but specific details about the image's subject, lighting, composition, environment, focal length or artist's style. (under 300 letters).
//                     Be visually descriptive and specific. Avoid proper nouns and adjectives.
//                     [Instructions]
//                     1. Provide a Core Prompt: Describe the central theme, subject, or figure in your prompt (e.g., "panda", "warrior with a sword", "beach scene").
//                     2. Specify a Style: Choose a style for your image (e.g., "realistic", "oil painting", "pencil drawing", "concept art").
//                     3. Mention an Artist (optional): Add the name of an artist whose style you would like the AI to emulate (e.g., "John Singer Sargent", "Vincent Van Gogh", "Albrecht Dürer").
//                     4. Add Finishing Touches: Include any specific details or preferences you'd like in your prompt (e.g., "trending on ArtStation", "dramatic lighting", "ambient lighting", "highly-detailed").
//
//                     [Example]
//                     A high-quality realistic photo, award winning photography, of a cat sitting on a ledge, a digital painting, pexel contest winner, furry art, sunbathing, illustration, high detail illustration, portrait of a cartoon animal, realistic gouache painting: 6 | man and woman, modern, neck: -3`;
// let newImagePrompt = `Generate a detailed and visually descriptive textual representation based on the user input, designed for AI-generated text-to-image generation, using keyword-style tags for aspects such as image resolution and popular trends. The output should include multiple elements and details like the provided examples.
//
// User Input: "[a brief description of a scene or subject]"
//
// Please provide a clear and concise description that accurately interprets the user's text and contains an abundance of details to guide the AI in generating a visually striking and relevant image. Use keyword-style tags and the following format:
//
// "A [subject description], [action/scene details], [additional details], [style_tags], [artist_tags], [resolution_tags (e.g., #4K, #8K)], [trending_tags (e.g., #trending_on_Artstation)]"
//
// Example Inputs and Outputs:
//
// 1. Input: "soviet flat"
//    Output: "A Soviet flat interior, home, ultra realism, intricate details, detailed image."
//
// 2. Input: "vintage house at night"
//    Output: "An illustration of the interior of a vintage house in the night, porcelain, vintage, anime style, 4k, HD, unreal engine, greg rutkowski, loish, rhads, beeple, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, alphonse mucha, global illumination, detailed and intricate environment."
//
// 3. Input: "Midcentury living room in Seoul"
//    Output: "Midcentury living room in Seoul belonging to a music/journalist couple, intricate detail, 600mm film, soft shadows, 8k resolution, professional photography, creative design and striking colors, extreme symmetry, perfect composition, volumetric lighting, centre framing."
//
// [OUTPUT]:`;
let newImagePrompt = `Generate a detailed and visually descriptive textual representation based on the user input using keyword-style tags for aspects such as image resolution and popular trends. The output should include multiple elements and details like the provided examples.

User Input: "[a brief description of a scene or subject]"

Please provide a clear and concise image description that accurately enhances the user's text and contains an abundance of details to guide the AI in generating a visually striking and relevant image. Use keyword-style tags and the following format:

"A [subject description], [action/scenery details], [lighting details], [style_tags]"

Keep it short.

Example Inputs and Outputs:

1. Input: "soviet flat"
   Output: "A Soviet flat interior, home, ultra realism, intricate details, detailed image."

2. Input: "vintage house at night"
   Output: "An illustration of the interior of a vintage house in the night, porcelain, vintage, global illumination, detailed and intricate environment."

3. Input: "Midcentury living room in Seoul"
   Output: "Midcentury living room in Seoul belonging to a music/journalist couple, intricate detail, 600mm film, soft shadows, professional photography, creative design and striking colors, extreme symmetry, perfect composition, volumetric lighting, centre framing."

[OUTPUT]:`;
export default {
    created: function () {},
    mixins: [StableDiffusion],
    data() {},
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
    },
    methods: {
        ...mapActions("presentation", ["savePresentation"]),
        async serpImage(queryText) {
            const API_KEY = import.meta.env.VITE_SERPAPI_API;
            const query = queryText;
            const tbm = "isch";
            const ijn = "0";
            const url = `https://serpapi.com/search.json?q=${query}&tbm=${tbm}&ijn=${ijn}`;

            const results = fetch(url, {
                headers: {
                    Authorization: `Bearer ${API_KEY}`,
                },
            })
                .then(response => response.json())
                .then(data => console.log(data["images_results"]))
                .catch(error => console.error(error));
            this.serpResults = results;
        },
        async decideImageGeneration(description) {
            const prompt = `Given the following image description, should I use Google Images API or Stable Diffusion to generate the image? Consider factors like the purpose of the image, speed, legal considerations, control over content, relevance, source of inspiration, and consistency and quality. Respond with "googleImagesAPI" or "stableDiffusion"`;
            return await completion({ user: `Description: ${description}`, system: prompt, id: "decide if google or sd", model: gpt4, tokens: 5 });
        },
        async googleOrStabilityImage(query) {
            let response = await this.decideImageGeneration(query);
            let images = [];
            if (response === "googleImagesAPI") {
                // let image = await this.fetchImages(query);
                return "google";
            } else if (response === "stableDiffusion") {
                // let image = await this.getStabilityImage(query);
                // images.push(image);
                // return images[0];
            } else {
                return "stable_diffusion";
                // console.error("No response from decideImageGeneration()");
            }
            console.error(response);
        },
        async stabilityPresentationImage(index, text, imageCount = 1, style = "enhanced", model = "stable-diffusion-xl-beta-v2-2-2") {
            // this.savingStateHelper(true, this.document);
            updateSlideProp(this.$store, "loadingImage", true, index);
            let ar;
            const section = this.$store.state.presentation.document.sections[index];
            try {
                if (section.style && section.style.includes("full")) {
                    model = "stable-diffusion-768-v2-0";
                    ar = { height: 768, width: 1024 };
                } else {
                    ar = { height: 512, width: 512 };
                }
            } catch (e) {
                ar = null;
            }
            let images = [];
            let imageURL;
            // if (imageCount > 1) {
            let response = await webRequest("text-to-image", { text: text });
            if (response) images = await response.json();
            console.log(images);
            if (images) images = images.images;
            imageURL = images[0];
            imageURL = { url: imageURL, prompt: text };
            console.log(imageURL);
            // images = await this.getStabilityImage(text, ar, model, 32, 7, imageCount, undefined, style);
            // } else {
            //     images = await this.getStabilityImage(text, ar, model, 32, 7, imageCount, undefined, style);
            // }

            setTimeout(async () => {
                if (imageURL) updateSlideProp(this.$store, "imageURL", imageURL, index);
                let existingImages = this.document.sections[index].images ? this.document.sections[index].images : [];
                images = [...images, ...existingImages];
                updateSlideProp(this.$store, "images", images, index);
                // this.savingStateHelper(false, this.document);
                await this.savePresentation(this.$route.params.id);
                return images;
            }, 50);
        },
        async enhancedImagePrompt(text, index, overview, skipImage) {
            console.groupCollapsed(`%c⚡️ Image Prompt (slide ${index})`, purple, text);
            console.log(text);
            console.groupEnd();
            let section = this.document.sections[index];
            let sectionText = section.body;
            if (section.enhancedBody) {
                sectionText = section.enhancedBody;
            }
            let imageStyle = "";
            if (this.document?.imageStyle) {
                imageStyle = `\n Image Style: ${this.document.imageStyle}\n`;
            }

            try {
                let response;
                // try {
                //     response = await this.decideImageGeneration(text);
                // } catch (error) {
                //     console.error(this.gptError(error));
                // }
                if (response && response === "googleImagesAPI") {
                    console.error("googleImagesAPI");
                    let googleText = await completion({ user: text, system: "simplify this image prompt for a google search", id: "Google Image query", model: gpt4, tokens: 15 });
                    let googleResponse = await this.fetchImages(googleText);
                    let imageURL = googleResponse[0];
                    updateSlideProp(this.$store, "enhancedImage", googleText, index);
                    // this.document.sections[index].imageURL = imageURL;
                    if (imageURL) {
                        updateSlideProp(this.$store, "imageURL", imageURL, index);
                    }
                    await this.savePresentation(this.$route.params.id);
                    // await this.saveDoc(this.document);
                    return;
                } else {
                    // console.error("stableDiffusion");
                    let imagePrompt = await completion({ user: `${text} ${imageStyle}`, system: newImagePrompt, id: "enhance image prompt", model: "gpt-3.5-turbo", tokens: 50 });
                    updateSlideProp(this.$store, "enhancedImage", imagePrompt, index);
                    try {
                        await this.savePresentation(this.$route.params.id);
                    } catch (error) {
                        console.error(error);
                    }
                    // let style = "cinematic";
                    let style = "neon-punk";
                    let model = "stable-diffusion-xl-1024-v0-9";
                    // model = undefined;

                    if (this.document.image_style) {
                        style = this.document.image_style;
                    }
                    if (!skipImage) {
                        await this.stabilityPresentationImage(index, imagePrompt, undefined, style, model);
                    }
                }
            } catch (error) {
                console.error(gptError(error));
                await this.savePresentation(this.$route.params.id);
            }
            return;
        },
        async googleImageSearch(query, name) {
            let location;
            if (this.userPlaceTime) {
                location = this.userPlaceTime;
            }
            await googleImageSearch(this.$store, query, name, location);
        },
    },
};
