<template>
    <div>
        <!--        <div v-if="product">{{ product }}</div>-->

        <div class="bg-snow relative br-10 overflow-hidden border-light hover:shadow cursor-pointer !hover:bg-snow" v-if="image">
            <div class="bg-snow bottom right left p-3 break-inside-avoid f gap-3">
                <div :class="{ 'p-1': image?.transparent }" class="width-75 br-10 height-100 overflow-hidden flex-shrink-0 border-light" :style="`background:${image.dominant}`">
                    <img class="x height-max-300 y z-1" :class="{ 'o-cover': !image?.transparent, 'o-contain': image.transparent }" @error="hideImageParent" v-if="image" :src="image.url" />
                </div>
                <div>
                    <div class="inline-block f-13 mb-2"><ProductBadge :product="product" /></div>
                    <div class="text-lg z-10 fwsb line-clamp-3 text-balance" v-html="text.trim()"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProductBadge from "@/components/styleGuide/Images/ProductBadge.vue";
export default {
    name: "SuggestedCampaignCard",
    components: { ProductBadge },
    props: {
        product: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        text: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapGetters("styleGuide/image", ["images"]),
        productColor() {
            let color = this.buttonStyles?.[0]?.backgroundColor || "#fff";
            return color;
        },
        image() {
            // match the image to images array
            let originalURL = this.product?.images?.[0]?.url || "";
            let image = this.images.find(img => img.originalURL === originalURL);
            return image;
        },
    },
    methods: {
        suggestedImage(product) {
            let imgUrl = product?.images?.[0]?.url || "";
            // find the image in the images array
            imgUrl = imgUrl.replace("Original", "public");
            return imgUrl;
        },
    },
};
</script>
