<!--text component receiving slot data -->
<template>
    <div @mouseenter="hovered = true" @mouseleave="hovered = false" :id="identifierId" :style="sectionHeight">
        <template v-if="mobile && section">
            <NewEmailSection :index="index" :result="section" :sections="sections" @clicked-logo="clickedLogo" />
        </template>
        <div v-else class="block section relative" id="landingPageSection">
            <div v-if="section" :style="imageColorLogic" class="grid grid-cols-12 relative overflow-clip">
                <!--              :class="{ 'vh-70': fullBleed, 'vh-70': !fullBleed }"-->
                <!--                <Transition appear name="fade-up">-->
                <template v-if="imageURL && imageLeft && !fullBleed">
                    <div class="col-span-12 !relative md:col-span-6 transition-all order-0 md:order-1 m-0!important p-0" :class="{ 'f aic fc jcc': !mobile }">
                        <LandingPageImage :key="`image${index}Left`" :kind="kind" :identifier="identifier" :image="image" :images="images" :index="index" :section="section" appear class="x block transition-all" :class="{ '': !isInset }" @save-section="saveSections" />
                    </div>
                </template>
                <!--                </Transition>-->
                <div :class="{ 'col-span-12 grid grid-cols-12 y': fullBleed, 'col-span-12 md:col-span-6': !fullBleed }" class="f aic jcc order-1 md:order-0 y relative">
                    <!--                    <Transition appear name="fade-up">-->
                    <LandingPageImage v-if="imageURL && fullBleed" :identifier="identifier" :image="image" :image-inset="imageInset" :images="images" :index="index" :kind="kind" :section="section" class="x vh-70" @save-section="saveSections" />
                    <!--                    </Transition>-->

                    <!--                  <div class="bg-red p-absolute p-fill z-5 m-3"></div>-->
                    <div :class="{ 'pl-8': fullBleed && textAlign === 'left', 'absolute p-fill text-center x f aic jcc flex z-5 jcc': fullBleed, textAlign: !fullBleed, 'p-md-6 px-md-8 mw-800 vh-70 f aic jcc': !fullBleed }" :style="[textColorOverride]" class="z-1">
                        <ImageGradient v-if="showFullBleedGradient && !hideGradient" :class="{ x: fullBleed && textAlign === 'left' }" :image="{ color: section.backgroundColor }" class="z-0 x y absolute" radial />
                        <!--                        <ImageGradient v-if="fullBleed && image" class="z-1 x y" radial :image="image" />-->
                        <div :class="[{ 'mw-800': !fullBleed, 'mw-600': !fullBleed, ' x': fullBleed && textAlign === 'left' }, `text-${textAlign}`]" class="z-2 relative mx-auto pb-5 md:pb-0 transition-all">
                            <div class="mx-auto block relative px" style="max-width: 95vw">
                                <EditableText
                                    key="header"
                                    :class="textClasses.header"
                                    :default-size="headerSize"
                                    :section-font="font"
                                    :font-override="sectionHeaderFont"
                                    :full-bleed="fullBleed"
                                    :identifier="identifier"
                                    :index="index"
                                    :max-size="120"
                                    :min-size="30"
                                    :section="section"
                                    :text="header"
                                    :text-color-override="textColorOverride"
                                    hovered-class="mb-0 transition-all"
                                    text-type="header"
                                    @save-section="saveSections"></EditableText>
                            </div>
                            <EditableText
                                key="body"
                                :class="textClasses.body"
                                :default-size="bodySize"
                                :section-font="font"
                                :font-override="sectionBodyFont"
                                :full-bleed="fullBleed"
                                :identifier="identifier"
                                :index="index"
                                :max-size="40"
                                :min-size="15"
                                :section="section"
                                :text="body"
                                :text-color-override="textColorOverride"
                                hovered-class="mb-0 transition-all"
                                text-type="body"
                                @save-section="saveSections"></EditableText>

                            <FeaturesSection v-if="features" :color="textColorOverride" :default-font-size="20" :font="font" :full-bleed="fullBleed" :identifier="identifier" :section="section" :text-color-override="textColorOverride" class="x" />
                            <AccordionSection v-if="accordion" :color="textColorOverride" :default-font-size="20" :font="font" :full-bleed="fullBleed" :identifier="identifier" :section="section" :text-color-override="textColorOverride" class="x" />
                            <SectionButton v-if="button" :button-text="button" :css="buttonCss" :default-size="buttonSize" :identifier="identifier" :image-color="imageColor" :index="index" :section="section" :text="button?.text || 'Shop now'" class="mt-4" @save-section="saveSections" />
                        </div>
                    </div>
                </div>
                <Transition appear name="fade-up">
                    <template v-if="imageURL && imageRight">
                        <div class="col-span-12 !relative md:col-span-6 transition-all order-0 md:order-1 m-0!important p-0" :class="{ 'f aic fc jcc': !mobile }">
                            <LandingPageImage :key="`image${index}Right`" :kind="kind" :identifier="identifier" :image="image" :images="images" :index="index" :section="section" appear class="x block transition-all" :class="{ '': !isInset }" @save-section="saveSections" />
                        </div>
                    </template>
                </Transition>

                <div :class="{ 'left right': imageLeft, 'left right': imageRight }" class="p-absolute bottom p-3 z-10 x justify-fixed-center mw-300" @mouseleave="showSettings = false">
                    <Transition appear name="fade-up">
                        <div v-if="showSettings" class="pb-5 p-absolute bottom z-6 mx-auto left right x">
                            <SectionSettings @update-section-color="updateColors" :colors="colors" :identifier="identifier" :index="index" :section="section" class="mx-auto" @save-section="saveSections">
                                <DebugObject :object="section" class="px-3 text-center -mt-5 x"></DebugObject>
                            </SectionSettings>
                        </div>
                    </Transition>
                    <Transition appear name="fade-up">
                        <ImageTools
                            v-if="fullBleed && !isEmail && hovered"
                            :image="image"
                            :is-hovered="true"
                            :kind="kind"
                            :index="index"
                            :section="section"
                            class="z-1 block fixed bottom"
                            style="bottom: 0px"
                            @edit="hover"
                            @toggle-gradient="toggleGradient"
                            @toggle-inset="toggleInset"
                            @toggle-style="toggleStyle"
                            @toggle-full-bleed="toggleFullBleed"
                            @toggle-swap-sides="toggleSwapSides" />
                    </Transition>
                    <a class="bg-snow edit_button relative transition-all text-gray-900 mx-auto hover:bg-gray-50/10 border border-gray-50 p-3 c-30 f aic jcc mix-blend-difference shadow border" href="#" @mouseenter="showSettings = true">
                        <div class="absolute p-fill z-1 blurred"></div>
                        <i class="fas fa-ellipsis-h relative z-1"></i>
                    </a>
                </div>
                <div v-if="showLogo" class="p-absolute top right z-6 justify-fixed-center text-center" @click="clickedLogo">
                    <BrandLogoComponent :color="textColorOverride" :is-header="showLogo" class="mt-4 text-center" max-height="50" width="100"></BrandLogoComponent>
                </div>
                <div class="p-absolute top right z-5" style="textColorOverride">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import ImageGradient from "@/components/generative_templates/ImageGradient.vue";
import LandingPageImage from "@/components/generative_templates/Pages/LandingPageImage.vue";
import SectionSettings from "@/components/generative_templates/Editing/SectionSettings.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import BodyText from "@/components/generative_templates/Editing/BodyText.vue";
import HeaderText from "@/components/generative_templates/Editing/HeaderText.vue";
import SectionButton from "@/components/generative_templates/Editing/SectionButton.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import SectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import NewEmailSection from "@/components/generative_templates/Email/NewEmailSection.vue";
import FeaturesSection from "@/components/generative_templates/Email/FeaturesSection.vue";
import AccordionSection from "@/components/generative_templates/Email/AccordionSection.vue";
import SubheaderText from "@/components/generative_templates/Editing/SubheaderText.vue";
import { updateFirebaseArrayProperty, updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import ImageTools from "@/components/generative_templates/Pages/ImageTools.vue";
import EditableText from "@/components/generative_templates/Editing/EditableText.vue";

export default {
    name: "LandingPageSection",
    components: {
        EditableText,
        ImageTools,
        SubheaderText,
        AccordionSection,
        FeaturesSection,
        NewEmailSection,
        BrandLogoComponent,
        SectionButton,
        HeaderText,
        BodyText,
        SectionSettings,
        LandingPageImage,
        ImageGradient,
        DebugObject,
    },
    mixins: [styleGuideMixin, styleGuideSettingsMixin, SectionStyleMixin],
    props: {
        result: {
            type: Object,
            default: () => ({
                header: "",
                body: "",
                button: {
                    show: true,
                    text: "Shop now",
                },
                style: "imageLeft",
                imageStyle: "normal",
                imageIndex: 0,
                textAlign: "left",
                imageInset: false,
            }),
        },
        identifier: {
            type: String,
            default: "",
        },
        index: {
            type: Number,
            default: 0,
        },
        id: {
            type: String,
            required: true,
        },
        sections: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            textColorOverride: "",
            backgroundColor: "",
            textColor: "",
            pickedColor: false,
            showSettings: false,
            hovered: false,
            hover: false,
            tempBackgroundColor: "",
        };
    },
    computed: {
        identifierId() {
            return this.makeId(this.identifier);
        },
        sectionHeight() {
            // if (!this.mobile) return "height:80vh;overflow:hidden;";
            if (this.fullBleed && !this.mobile) return "max-height:70vh;height:70vh;min-height:70vh;overflow:hidden;position:relative;";
            if (!this.mobile) return "min-height:70vh; overflow:clip; position:relative;";
            // if (!this.mobile) return "max-height:80vh;height:80vh;min-height:80vh;overflow:clip;position:relative;";
        },
        features() {
            return this.section?.features;
        },
        accordion() {
            return this.section?.accordion;
        },
        textClasses() {
            return {
                header: "mb-3 relative duration-3 animated fadeInUpSmooth d-1 ease-back z-5 mw-700",
                subheader: "block mb-3 ease-back duration-2 animated fadeInUpSmooth d-2 relative fwb z-2 mw-450",
                body: "block mb-3 ease-back duration-2 animated fadeInUpSmooth d-2 relative mw-700",
                preheader: "block mb-3 ease-back duration-2 animated fadeInUpSmooth d-2 relative text-uppercase fwb h6",
            };
        },
        showFullBleedGradient() {
            return this.fullBleed && this.imageURL && !this.isEmail;
        },
        section() {
            const result = this.result || {};
            const images = this.images;
            let imageIndex = result.imageIndex || 0;
            let image = images[imageIndex];
            let tempBGColor = "";
            const { backgroundColor: resultBackground } = result;
            const newImage = { backgroundColor: image?.backgroundColor, bottomColor: image?.bottomColor };
            const { bottomColor = "", backgroundColor = "", dominant } = newImage;
            if (bottomColor) tempBGColor = bottomColor;
            else if (backgroundColor) tempBGColor = backgroundColor;
            if (resultBackground) tempBGColor = resultBackground;
            // let section = this.section;
            return { ...result, backgroundColor: tempBGColor };
        },
        kind() {
            return "landing_page";
        },
        image() {
            let imageIndex = this.imageIndex;
            let images = this.images;
            if (imageIndex !== null && imageIndex !== undefined) {
                return images[imageIndex];
            } else {
                return {};
            }
        },

        imageRoundClasses() {
            if (this.isRound) return `aspect-w-1 aspect-h-1 rounded-full`;
        },

        componentData() {
            return JSON.stringify(this.$data, null, 2);
        },
    },
    watch: {
        imageStyle: {
            handler(val, oldVal) {
                if (val === "fullBleed") {
                    // this.updateColors(this.image.backgroundColor);
                    this.updateSection("imageInset", false);
                    this.updateSection("imageStyle", "normal");
                }
            },
            immediate: true,
        },
        imageColor: {
            handler(val, oldVal) {
                // if (!val) return;
                // console.log(val);
                this.textColorOverride = this.findContrastingColor(val);
                if (this.image?.textColorCenter === "light") {
                    this.textColorOverride = "#fff";
                }
                this.$nextTick(() => {
                    // this.textColorOverride = this.getContrastColor(val);
                    this.textColorOverride = `color:${this.textColorOverride}!important;`;
                    let background = this.image?.backgroundColor;
                    if (background) this.updateColors(background);
                });
            },
            // immediate: true,
        },
        imageIndex: {
            handler(val, oldVal) {
                if (val) {
                    this.updateColors(this.imageColor);
                }
            },
        },
    },
    mounted() {
        if (this.imageColor) {
            this.textColorOverride = this.findContrastingColor(this.imageColor);
        }
        if (!this.pickedColor) {
            this.getRandomTheme();
            this.pickedColor = true;
        }
        this.$nextTick(() => {
            if (!this.section.color || !this.section.backgroundColor) {
                this.updateColors(this.image?.backgroundColor);
            }
        });
    },
    methods: {
        async saveSections(obj) {
            let prop = obj?.prop;
            let identifier = obj?.identifier;
            const sections = deepCopy(this.sections);
            const section = deepCopy(this.section);
            let matchedObject = await fetchData("campaigns", this.id);
            const id = this.id || matchedObject.id;
            matchedObject = deepCopy(matchedObject);
            console.log("index", this.index);
            console.groupCollapsed(`%cSection Saved ${this.index}`, purple, prop);
            if (this.id) console.log("ID", this.id);
            if (sections) console.log("sections", sections);
            if (identifier) console.log("Identifier", identifier);
            if (section) console.log("Section", section);
            console.log("Matched Campaign", deepCopy(matchedObject));
            console.groupEnd();
            matchedObject.result.sections = sections;

            await this.$nextTick();
            setTimeout(async () => {
                let campaignItem = await updateFirebaseObject("campaigns", { result: matchedObject.result }, id);
                console.log(campaignItem);
                let parentId = matchedObject?.result?.campaignId || matchedObject?.campaignId;
                console.log("Parent ID", parentId);
                console.log("Saving from the email section controller");
                try {
                    if (this.campaignData && !this.campaignData.childCampaigns) await updateFirebaseObject("campaigns", { childCampaigns: [] }, parentId);
                    if (this.campaignData && !this.campaignData.id) await updateFirebaseObject("campaigns", { id: parentId }, parentId);
                    if (parentId) await updateFirebaseArrayProperty("campaigns", parentId, "childCampaigns", id);
                } catch (e) {
                    console.log(e);
                }
            }, 100);
        },
        // Filter out background colors with gradients
        // updateColors(color) {
        //     let object = {};
        //
        //     if (!color && this.image) color = this.image?.backgroundColor;
        //     let textColor = this.findContrastingColor(color);
        //     // let textColor = this.getContrastColor(color);
        //     this.backgroundColor = color;
        //     this.textColor = textColor;
        //     this.textColorOverride = textColor;
        //     if (color) this.updateSection("backgroundColor", color, true);
        //     if (textColor) this.updateSection("color", textColor);
        //     // return
        // },
        getRandomTheme() {
            const themes = this.styleGuide?.divStyles;
            const { backgroundColor, color } = themes[Math.floor(Math.random() * themes.length)];
            if (backgroundColor) this.backgroundColor = backgroundColor;
            if (color) this.textColor = color;
            return { color, backgroundColor };
        },
    },
};
</script>
<style lang="scss" scoped>
.edit_button {
    @apply opacity-0;
    @apply bg-opacity-50;
    @apply border-opacity-0;
    @apply backdrop-blur-2xl;
}

.section {
    &:hover {
        .edit_button {
            @apply opacity-100;
            @apply bg-opacity-80;
            @apply border-opacity-100;
        }
    }
}
</style>
<!--                :style="`border-color:${textColorOverride};color:${textColorOverride}`"-->
