<template>
    <div>
        <div class="f aic jcb mb-2">
            <div class="f fc ais jcs x mw-300">
                <h6 v-if="type" class="mb-0 mr-2">{{ type }}</h6>
                <span v-if="name" class="fwn f-13 o-5 w-75 line-clamp-1">{{ name }}</span>
            </div>
            <div class="f-11 o-2 f fc jce text-right x">
                <span v-if="createdAt" class="fwn">{{ createdAt }}</span>
                <span v-if="elementId" class="mb-0">{{ elementId }}</span>
            </div>
        </div>
        <div class="block relative br-10 overflow-hidden border-light hover:shadow-high hover:shadow-black/5 transition-all">
            <CampaignHeader v-if="isEmail && result" :key="elementId" :result="result" :type="type" class="x" inline />
            <transition name="fade-up">
                <div v-if="!sections.length">
                    <LandingPageLoader :style="dynamicGradient" :result="result" />
                </div>
            </transition>

            <div class="">
                <div v-if="type === 'email'" :class="{ 'lg:height-900': sections?.length > 0 }" class="overflow-y-scroll scrollbar-hide transition-all relative">
                    <EmailSections v-if="sections" :campaign-item="editorItem" :footer="footer" :id="elementId" :identifier="identifier" :sections="sections" />
                </div>

                <div v-if="isPage" class="br-10 border-light overflow-y-scroll scrollbar-hide mw-1600 relative" :class="{ 'lg:height-900': sections?.length > 0 }" style="min-width: 1600px">
                    <LandingPageSections v-if="sections" :id="elementId" :identifier="identifier" :sections="sections" />
                    <!--                                    <template :key="`section${index}`" v-for="(section, index) in campaignChildrenFindResult(scheduleIndex, campaignIndex)"></template>-->
                </div>
                <Transition name="fade-up" mode="out-in">
                    <div v-if="result && !result.complete && result.name" class="z-10 block y">
                        <div class="x f jcc py-3 absolute left right z-10 pt-3 px-3" :style="dynamicGradient" :class="{ 'p-0': subject, 'sectionBg animated-pastel-gradient bottom aic': sections?.length < 1, 'bottom aie': sections?.length > 0 }">
                            <StreamingStatusLoader :result="result" class="z-1 text-black f-20" />
                        </div>
                    </div>
                    <div v-else-if="showLoader && result?.complete" class="x f jcc py-3 absolute left right z-10 pt-3" :class="{ 'pt-8': subject, 'sectionBg animated-pastel-gradient p-fill top bottom aic': sections?.length < 1, 'bottom aie': sections?.length > 0 }">
                        <StreamingStatusDone />
                    </div>
                </Transition>
            </div>
        </div>
        <debug-object v-if="result" :object="editorItem"></debug-object>
    </div>
</template>
<script>
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import EmailSections from "@/components/Admin/EmailSections.vue";
import StreamingStatusLoader from "@/components/generative_templates/Loaders/StreamingStatusLoader.vue";
import LandingPageSections from "@/components/Admin/LandingPageSections.vue";
import { mapGetters } from "vuex";
import StreamingStatusDone from "@/components/generative_templates/StreamingStatusDone.vue";
import chroma from "chroma-js";
import LandingPageLoader from "@/components/Admin/LandingPageLoader.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";

export default {
    name: "GenerativeCampaignItem",
    components: { DebugObject, LandingPageLoader, StreamingStatusDone, LandingPageSections, CampaignHeader, EmailSections, StreamingStatusLoader },
    props: {
        index: {
            type: Number,
            required: true,
        },
        campaignItem: {
            type: Object,
            required: true,
        },
        scheduleIndex: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        currentColorIndex: 0,
        showLoader: false,
    }),
    mounted() {
        // setInterval(() => {
        //     this.currentColorIndex = (this.currentColorIndex + 1) % (this.colors?.bg_colors_sat?.length || 1);
        // }, 3000); // Change the gradient every 3 seconds
    },
    computed: {
        ...mapGetters("stream", ["campaignData", "editor"]),
        ...mapGetters("styleGuide", ["colors"]),
        isEmail() {
            return this.type && this.type === "email";
        },
        isPage() {
            return this.type && this.type === "page";
        },
        dynamicGradient() {
            return;
            if (this.sections.length > 0) return;
            try {
                let colors = this.colors?.bg_colors_sat || [];
                if (!colors || colors.length === 0) return {};

                // Define a saturation threshold (e.g., 0.3 on a scale from 0 to 1)
                const saturationThreshold = 0.05;

                // Filter out colors with low saturation
                let filteredColors = colors.filter(color => {
                    try {
                        let sat = chroma(color).hsl()[1];
                        let luma = chroma(color).hsl()[2];
                        return sat > saturationThreshold && luma < 0.9;
                    } catch (e) {
                        return false;
                    }
                });
                filteredColors = filteredColors.sort((a, b) => {
                    // sort by saturation
                    let satA = chroma(a).hsl()[0];
                    let satB = chroma(b).hsl()[0];
                    return satB - satA;
                });
                // Transform remaining colors into pastels
                let gradientColors = filteredColors.concat(filteredColors).map(color => {
                    let pastelColor = chroma(color);
                    pastelColor = pastelColor.hsl();
                    let { 0: hue, 1: sat, 2: lum } = pastelColor;
                    hue = parseInt(hue);
                    sat = parseInt(90) + "%";
                    pastelColor = `hsl(${hue}, ${sat}, ${95}%)`;
                    return pastelColor;
                });
                gradientColors = gradientColors.concat().join(", ");
                // gradientColors = gradientColors.concat(gradientColors).join(", ");
                let gradientCSS = `radial-gradient(circle, ${gradientColors})`;

                // Calculate the background size based on the number of filtered colors
                let backgroundSize = filteredColors.length * 100 * 3 + "%!important";

                return {
                    background: gradientCSS,
                    backgroundSize: backgroundSize,
                    animation: "slide 10s linear infinite",
                };
            } catch (e) {
                return false;
            }
        },

        createdAt() {
            if (this.result?.created) {
                return this.$filters.timeAgo(this.toDate(this.result.created));
            }
        },
        identifier() {
            // return `campaignData.result.schedule_items.${this.scheduleIndex}.campaign_items.${this.index}.result.result`;
            if (!this.campaignItem) return;
            let id = this.campaignItem?.contentId || this.campaignItem?.id;
            if (!id) return;
            return `editor.${id}.result`;
            // return `campaignData.result.schedule_items.${this.scheduleIndex}.campaign_items.${this.index}.result.result`;
        },
        footer() {
            return this.result?.result?.email_footer || this.result?.email_footer;
        },
        sections() {
            let newSections = this.editorItem?.result?.sections;
            return newSections || this.result?.result?.sections || [];
        },
        subject() {
            return this.editorItem?.result?.subject_line;
            return this.result?.result?.subject_line || this.result?.result?.subject;
        },
        editorItem() {
            let id = this.campaignItem?.contentId || this.campaignItem?.id;
            return this.editor?.[id] || {};
        },
        result() {
            return this.editorItem?.result || this.campaignItem?.result || {};
        },
        name() {
            return this.editorItem?.name;
            return this.result?.name;
        },
        type() {
            return this.editorItem.itemType;
            return this.campaignItem?.itemType;
        },
        elementId() {
            return this.campaignItem?.contentId || this.campaignItem?.id || this.result?.id || "";
            return this.result?.id;
        },
    },
    watch: {
        result: {
            handler(newval, oldval) {
                // this.showLoader = true
                this.$nextTick(() => {
                    if (newval?.complete) {
                        this.hideLoader();
                    } else {
                        this.showLoader = true;
                    }
                });
            },
            immediate: false,
        },
    },
    methods: {
        hideLoader() {
            setTimeout(() => (this.showLoader = false), 5000);
        },
    },
    // :embedded-campaign="embeddedCampaign(result)" :embedded-campaign-details="embeddedCampaignDetails(result)"
};
</script>
<style lang="scss">
@keyframes slide {
    from {
        background-position: 0% 50%;
    }
    to {
        background-position: 100% 50%;
    }
}
</style>
