<template>
    <div class="aic jcs f gap-1 my-1">
        <div class="rating" :class="color">
            <i v-for="(star, index) in stars" :key="index" :class="{ 'fas fa-star': star === 1, 'fas fa-star-half-alt': star === 0.5 }"></i>
            <!--            <template v-if="count">{{ count }}</template>-->
        </div>
        <div class="f gap-1">
            <span class="o-5">{{ value }}</span>
            <span class="o-5" v-if="count">({{ count }})</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "RatingStarsComponent",
    props: {
        color: {
            type: String,
            default: "",
            required: false,
        },
        value: {
            type: Number,
            required: true,
        },
        count: {
            type: Number,
            required: false,
        },
    },
    computed: {
        stars() {
            const rating = Math.round(this.value * 2) / 2;
            const fullStars = Math.floor(rating);
            const halfStars = rating - fullStars;

            return [...Array(fullStars).fill(1), ...(halfStars ? [0.5] : []), ...Array(5 - fullStars - (halfStars ? 1 : 0)).fill(0)];
        },
    },
};
</script>

<style scoped>
.rating {
    /* Add your styles here */
}
</style>
