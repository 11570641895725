<template>
    <div v-if="text" class="mb-3 relative duration-3 animated fadeInUpSmooth d-1 ease-back" @blur="isEditing = false" @mouseenter="hovered = true" @mouseleave="(hovered = false), (hoverEdit = false)">
        <!--        <div v-if="isEditing" style="left: -15px; right: -15px; top: -15px; bottom: -15px; z-index: -1 !important" class="bg-gray-900/10 br-10 p-3 absolute p-fill z-0"></div>-->
        <PresentationMarkdown v-if="!isEditing" :class="{ '!f-80': fullBleed, '!f-60': !fullBleed, 'mb-5 transition-all': hovered }" :content="text" :style="styleString" class="ease-back break-word z-1" @click="isEditing = true"></PresentationMarkdown>
        <EditSectionIndicator v-if="hovered || isEditing" :editing="isEditing" key="headerText" @click="isEditing = true" @mouseenter="hoverEdit = true" />
        <EditingFont
            :editing="isEditing"
            :font-size="fontSize"
            :hovered="hovered"
            :identifier="identifier"
            :index="index"
            :max-size="maxFontSize"
            :min-size="minFontSize"
            :section="section"
            class="block x"
            kind="header"
            @update-font-name="updateHeaderFont"
            @hover-edit="hoverEdit = true"
            @changed-font-size="editFontSize"
            @font-done-changing="editFontSize" />

        <contenteditable v-if="isEditing" ref="contenteditable" v-model="textEdit" :class="computedClass" :style="styleString" class="ease-back block z-6" data-placeholder="Enter your text here" tag="span" @blur="doneEditing"></contenteditable>
    </div>
</template>
<script>
import EditSectionIndicator from "@/components/generative_templates/Editing/EditSectionIndicator.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import contenteditable from "vue-contenteditable";
import PresentationMarkdown from "@/components/Presentations/PresentationMarkdown.vue";
import sectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import EditingFont from "@/components/generative_templates/Editing/EditingFont.vue";

export default {
    name: "HeaderText",
    components: { EditingFont, PresentationMarkdown, EditSectionIndicator, contenteditable },
    mixins: [styleGuideSettingsMixin, sectionStyleMixin],
    props: {
        font: {},
        index: { default: 0, required: true },
        defaultSize: { type: [String, Number], default: "" },
        fullBleed: { type: Boolean, default: false },
        text: { type: String, default: "" },
        textColorOverride: { type: String, default: "" },
        fontOverride: { type: String, default: "" },
        identifier: { type: String, required: true },
        section: { type: Object, default: () => {} },
    },
    computed: {
        styleString() {
            return [this.fontString, `font-size:${this.fontSize}px!important;`, `color:${this.textColorOverride}`];
        },
        fontString() {
            if (this.fontOverride) return this.fontOverride;
            else return this.font.header;
        },
        computedClass() {
            return {
                "!f-80": this.fullBleed,
                "!f-60": !this.fullBleed,
                "mb-5 transition-all": this.hovered,
            };
        },
    },
    watch: {
        isEditing: {
            handler(val, oldVal) {
                if (val && val !== oldVal) this.$nextTick(() => this.$refs.contenteditable.$refs.element.focus());
            },
        },
    },
    data() {
        return {
            minFontSize: 30,
            maxFontSize: 120,
            hovered: false,
            fontSize: 60,
            hoverEdit: false,
            isEditing: false,
            textEdit: "",
        };
    },
    mounted() {
        if (this.fullBleed) this.fontSize = 80;
        else this.fontSize = 60;
        if (this.defaultSize) this.fontSize = this.defaultSize;
        this.textEdit = this.text;
    },
    methods: {
        editFontSize(number, save) {
            this.fontSize = number;
            if (save) this.updateSection("headerSize", number);
        },
        doneEditing() {
            // this.updateSection("header", this.headerEdit);
            this.updateSection("header", this.textEdit);
            this.$nextTick(() => (this.isEditing = false));
        },
    },
};
</script>