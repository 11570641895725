function objectToCss(styles) {
    return Object.entries(styles).reduce((cssString, [key, value]) => {
        // Convert camelCase to kebab-case
        const kebabCaseKey = key.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
        // Append the property and its value to the CSS string
        if (key !== "score" && key !== "totalScore" && key !== "hasText") {
            return `${cssString}\t${kebabCaseKey}: ${value};\n`;
        } else {
            return "";
        }
    }, "");
}
function objectToCssSimple(styleObj) {
    return Object.keys(styleObj)
        .map(key => `${key}: ${styleObj[key]};`)
        .join("\n");
}
export default {
    mixins: [],
    data() {
        return {};
    },
    computed: {
        // ...mapGetters("styleGuide", ["styleGuide", "textStyles", "colors", "fonts", "buttonStyles", "headerImage", "divStyles", "allTextStyles", "textSamples", "svgs", "hasImages", "website"]),
    },
    watch: {},
    methods: {
        simplifyFont(font) {
            let {
                //
                fontFamily,
                name,
                weight,
                fontWeight,
                style,
                fontStyle,
                stretch,
                fontStretch,
                variant,
                fontVariant,
                featureSettings,
                fontFeatureSettings,
                textTransform = "",
            } = font;
            fontFamily = fontFamily || name;
            fontWeight = fontWeight || weight;
            fontStyle = fontStyle || style;
            stretch = fontStretch || stretch;
            variant = fontVariant || variant;

            featureSettings = fontFeatureSettings || featureSettings;
            return { fontFamily, fontWeight, fontStyle, textTransform };
        },
        applyStyle(style) {
            if (!style) return;
            // this.createFontStyles();
            const styleProperties = ["fontSize", "lineHeight", "textShadow", "letterSpacing", "color", "fontWeight", "height", "fontFamily", "textTransform", "fontStyle", "background", "backgroundColor", "padding", "border", "borderRadius", "boxShadow", "fontVariationSettings"];

            // Define the properties that require 'px' unit
            const propertiesWithPx = ["fontSize", "lineHeight", "letterSpacing", "borderRadius"];

            const validStyles = {};
            if (!style.lineHeight) style.lineHeight = 1;

            for (const prop of styleProperties) {
                if (style[prop] !== null && style[prop] !== undefined) {
                    // Convert camelCase to kebab-case for CSS properties
                    const cssProperty = prop.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
                    let propValue = style[prop];
                    if (prop === "lineHeight") {
                    }
                    if (prop === "padding") {
                        // Convert "0px 0px 0px 0px" format to rem
                        let padding = style[prop].split(" ").map(val => {
                            const valInt = parseInt(val);
                            // If value is a pixel value, convert to rem
                            return val.endsWith("px") ? `${(valInt / parseInt(style.fontSize)).toFixed(2)}em` : val;
                        });
                        propValue = padding.join(" ");
                    } else if (propertiesWithPx.includes(prop) && !propValue.toString().endsWith("px")) {
                        propValue = propValue;
                    }

                    if (propValue !== null) validStyles[cssProperty] = `${propValue}!important`;
                }
            }
            return validStyles;
        },
    },
};
