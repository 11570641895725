<template>
    <div class="border-light br-5 px-2 inline-flex">
<!--                                                {{ tool }}-->
        <div>
            <span v-if="tool?.type" class="text-sm text-capitalize">{{ tool.type }}</span>
            <span class="text-xs block pb-1" v-if="tool?.function?.name">{{ tool.function.name }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "AssistantToolCard",
    props: {
        tool: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
