const research_topic = {
    name: "research_topic",
    description: "Fill out the queries to research a topic.",
    parameters: {
        type: "object",
        properties: {
            google_image_query: {
                type: "string",
                description: "A google query to find the best images related to the brand. Never use logos.",
            },
            google_query: {
                type: "string",
                description: "A well-written google query to research information about a brand (usually just the brand's name)",
            },
            category_scope: {
                type: "string",
                description: "The category of the topic.",
            },
            category_knowledge: {
                type: "array",
                description: "Websites that publish content about the category. Minimmum of 5-10",
                items: {
                    type: "string",
                    description: "a website from the category. Example: 'http://architecturaldigest.com'",
                },
            },
            website: {
                type: "string",
                description: "The website of the brand. (leave blank if unknown)",
            },
            writing_style: {
                type: "string",
                description: "The writing style to used for the presentation of this topic. Example: 'In the style of an Architectural Digest article'.",
            },
            obvious_topics: {
                type: "array",
                description: "Write the topics to cover (or topics) into an array of (strings minimum 10,based on slide/page count)",
                items: {
                    type: "string",
                    description: "Extract the areas of interest into an array of strings. Example:'topic founders', 'topic origin story', 'timelines'",
                },
            },
            unobvious_topics: {
                type: "array",
                description: "Write the topics to cover (or topics) into an array of (strings minimum 10,based on slide/page count)",
                items: {
                    type: "string",
                    description: "Extract the areas of interest into an array of strings. Example:'topic founders', 'topic origin story', 'timelines'",
                },
            },
            thoughts: {
                type: "string",
                description:
                    "Record detailed, strategic expert thoughts on what would make the best possible output for this topic. Example:'I believe we should highlight XYZ and illustrate ABC frame to best present the deck. We should frame their competitors as ...., if we want to get investors, we should strategically trinkle in ABC to leave them thinking XYZ' etc ...",
            },
            // plan: {
            //     type: "string",
            //     description: "List out a 10-step plan to research and then best use the research to provide the best possible output. Example: 1. I will search google to find top-level information.\n2. I will search google for compelling images about the topic.\n3. I'll browse the website to find more images and information. etc ... ",
            //     Example: "1. I will search google to find top-level information.\n2. I will search google for compelling images about the topic.\n3. I'll browse the website to find more images and information. etc ... ",
            //     // description: "This is the plan on what to do with the research.",
            // },
            // whats_needed: {
            //     type: "string",
            //     description: "List what is needed to execute the plan. Example: Browse the brand's website\nSearch google for [brand name], wikipedia and more.\n I should also search google for news and recent press clippings.",
            // },
        },
        required: ["google_query", "google_image_query", "category_scope", "category_knowledge", "obvious_topics", "unobvious_topics"],
    },
};
export default research_topic;
