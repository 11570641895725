<template>
    <div class="y relative" @mouseover="hovered = true" @mouseleave="hovered = false">
        <div class="block y text-snow border-snow border br-20 m-5 overflow-hidden relative mw-400" :style="`max-height: 750px; background:${image?.colors?.[4]};`">
            <!--        <StyleGuideImage v-if="image" :image="image" :size="{ width: 600, height: 1600 - 1600 * value }" :bottom-offset="value * 10" class="x y" />-->

            <div class="x y p-absolute p-fill mx-auto p-4">
                <div id="adImage" :style="`background:url('${image?.url.replace('Original', 'public')}'); background-size:cover;height:${100 - value}%;margin-top:${0.5 * value}%!important;background-position:center;`" class="br-20 bottom w-90"></div>
            </div>
            <div class="top left right p-5 text-center z-5">
                <!--            <h1 class="f-60" :style="`font-family:${font.fontFamily};letter-spacing:0px;`">BACK IN STOCK</h1>-->
                <div class="relative" :style="`margin-top:${50 - value * 4.5}px; transform:scale(${1 / (value / 10) > 1 ? 1 : 1 / (value / 12)})`">
                    <BrandLogoComponent :color="getContrastColor(image.color)" :width="150" class="mx-auto"></BrandLogoComponent>
                </div>
            </div>
            <div class="p-absolute z-0 bottom left right x top">
                <!--            <ImageGradient :image="{ color: image?.backgroundColor }" :height="value * 20" :offset="50 - value" class="x y p-absolute bottom left right p-fill" />-->
                <div class="p-absolute bottom left right p-5 text-center z-1">
                    <div :style="`margin-bottom:${50 - value * 4}px`">
                        <ImageCaption class="mb-4 mt-4" :color="getContrastColor(image?.backgroundColor)" :text="text" size="20" :style="`text-wrap:pretty;font-family:${font.fontFamily};font-weight:500;text-transform:${fonts?.textTransform}`" />
                    </div>
                    <!--                        <p class="mb-5 f-24 px-3 py-3 br-20" :style="`background:${imageObj.backgroundColor};color:${getContrastColor(imageObj.backgroundColor)}`">{{ styleGuide.description }}</p>-->
                </div>
            </div>
            <!--         add an input range slider  -->
        </div>
        <div class="absolute bottom left right z-4 pb-2" style="bottom: 50px">
            <transition name="fade-up">
                <!--       increment by 10   -->
                <div class="px-3 py-0 bg-snow/50 f aic jcc bg-blur mw-300 rounded-full mx-auto text-center border-light shadow" v-if="hovered">
                    <input type="range" min="0" max="15" v-model="value" class="w-full mb-0 pb-2" />
                </div>
            </transition>
            <!--          increment must work -->
        </div>
    </div>
</template>
<script>
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import ImageCaption from "@/components/styleGuide/Sections/Themes/ImageCaption.vue";
import ImageGradient from "@/components/generative_templates/ImageGradient.vue";
import StyleGuideImage from "@/components/styleGuide/Images/StyleGuideImage.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "StyleGuideAdStyle2",
    mixins: [styleGuideMixin],
    components: { BrandLogoComponent, ImageCaption, ImageGradient, StyleGuideImage },
    props: {
        image: {
            type: Object,
            default: () => ({}),
        },
        text: {
            type: String,
            default: "New Arrivals",
        },
    },
    data() {
        return {
            value: 15,
            hovered: false,
        };
    },
    computed: {
        font() {
            return this.fonts[0];
        },
    },
};
</script>
