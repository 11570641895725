<template>
    <div :id="'slide' + index" :class="`slide${index}`" class="p-relative z-1 x slide" style="backface-visibility: hidden" @mouseleave="moveOver = false">
        <!--        <div class="f"><div v-for="index of 9" :class="`c-50 f fwb aic jcc text-light-${index * 100} bg-dark-${index * 100}`">Hey</div></div>-->
        <!--        <div class="f"><div v-for="index of 9" :class="`f fwb c-50 aic jcc border-light-${index * 100} text-dark-${index * 100} bg-light-${index * 100}`">Hey</div></div>-->
        <Transition enter-active-class="swingInUp ease-back duration-5 d-1 animated" leave-active-class="ease-back duration-1 fadeOutDown animated">
            <div class="p-relative px-lg-5 container br-10 m-0 mx-auto mb-3 overflow-hidden p-0 px-1 text-center" style="max-width: 100%">
                <div class="f x p-relative">
                    <Transition enter-active-class="swingInUp duration-3 animated" enter-from-class="o-0 left-50" enter-to-class="bg-blur" leave-active-class=" duration-1 fadeOut animated ">
                        <PresentationEditMenu v-show="moveOver" :document="$store.state.presentation.document" :index="index" :section="section" :slide="slide" @mouseleave="moveOver = false" @save-doc="handleSaveDoc"></PresentationEditMenu>
                    </Transition>

                    <div :class="{ moveover: moveOver }" class="x d-block z-1" style="transition: transform 0.3s ease-in-out">
                        <div v-if="slide" ref="slide" class="d-block height-min-500">
                            <Component
                                :is="getSlideComponent"
                                :id="document.id + index"
                                :key="`random${index}`"
                                :class="`${slide.background_class || bgClass} ${slide.text_class || textClass} transition-colors`"
                                :document="$store.state.presentation.document"
                                :document-id="$route.params.id"
                                :index="index"
                                :section="section"
                                :slide-container-classes="slideContainerClasses"
                                @click="mobileImagePicker()"></Component>
                            <ImagePicker v-if="slide.showImages" :document="document" :index="index" :slide="slide" class="z-5 y" />
                        </div>
                        <div class="f jcb x aic mb-3 mt-3 gap-2" @mouseleave="showInput = false">
                            <div class="f d-block width-min-100 aic jcc text-left">
                                <div v-if="document.sections" class="text-dark-300 dark:text-lights-200 z-0 mx-auto max-w-xs opacity-50">{{ index + 1 }} of {{ document.sections.length }}</div>
                            </div>
                            <div class="animated fadeInUpSmooth x d-6" v-if="showInput">
                                <BaseInput :id="randomId()" ref="newInput" iclass="!f-20 height-50 bg-snow active:!bg-snow focus:!bg-snow shadow" :modelValue="newSlidePrompt" class="x f-30 bg-transparent" inline placeholder="Add a new slide" @update:modelValue="newSlidePrompt = $event" @keydown.enter.prevent="addSlideBelow" />
                            </div>
                            <div class="f z-5 aic jcc text-center" :class="{ 'gap-2': showInput, 'gap-3': !showInput }">
                                <ButtonIconWhite class="fas translate zoomIn f-20 align-self-center transform cursor-pointer transition-all ease-back" :small="showInput" v-if="showInput" :class="{ 'c-40 text-purple  transition-all': showInput }" icon="sparkles fas f-15 mb-0 text-purple" @click.prevent="addSlideBelow()"></ButtonIconWhite>
                                <ButtonIconWhite class="fas translate zoomIn f-20 align-self-center transform cursor-pointer transition-all ease-back" :small="showInput" :class="{ 'c-40 rotate45 transition-all': showInput }" icon="plus fad " @mouseenter="showInput = true" @click.prevent="wantsNewSlide()"></ButtonIconWhite>
                                <ButtonIconWhite class="fas translate f-17 align-self-center cursor-pointer transition-all ease-back" icon="image fad" :small="showInput" :class="{ 'c-40  d-20 transition-all': showInput }" @click.prevent="updateSlideProp('showImages', !slide.showImages, index)"></ButtonIconWhite>
                                <ButtonIconWhite class="fas translate f-17 align-self-center cursor-pointer transition-all ease-back" icon="trash fad" :small="showInput" :class="{ 'c-40  d-20 transition-all': showInput }" @click.prevent="removeSlideClicked(index)"></ButtonIconWhite>
                            </div>
                        </div>
                    </div>
                    <div v-if="!moveOver" :class="{ 'text-dark-900': slide.mode === 'light', 'text-light-400': slide.mode === 'dark' }" class="p-absolute top right z-4 f-30 py-2 pr-4" @mouseover.prevent="moveOver = true" @click.prevent="moveOver = true">
                        <i class="fas fa-ellipsis-h"></i>
                    </div>
                    <div v-if="moveOver" class="p-absolute top right z-4 text-shadow f-30 py-2 pr-4 text-base-300 mix-blend-hard-light" @click.prevent="moveOver = false">
                        <ButtonIconWhite class="fas f-17 align-self-center cursor-pointer" icon="times fas" small text="" @click.prevent="moveOver = false"></ButtonIconWhite>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>
import ButtonIconWhite from "@/components/CoreUI/buttons/buttonIconWhite.vue";
import SlideFullbleed from "@/components/Presentations/slides/largeSlides/FullbleedSlide.vue";
import SlideLeft from "@/components/Presentations/slides/largeSlides/LeftSlide.vue";
import SlideMatrix from "@/components/Presentations/slides/largeSlides/MatrixSlide.vue";
import SlideOther from "@/components/Presentations/slides/largeSlides/OtherSlide.vue";
import SlideRight from "@/components/Presentations/slides/largeSlides/RightSlide.vue";
import SlideTable from "@/components/Presentations/slides/largeSlides/TableSlide.vue";
import SlideTimeline from "@/components/Presentations/slides/largeSlides/TimelineSlide.vue";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import presenationMixin from "@/mixins/presentations/presentationMixin";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import PresentationEditMenu from "@/components/Presentations/slides/PresentationEditMenu.vue";
import { mapActions } from "vuex";
import ImagePicker from "@/components/Presentations/slides/ImagePicker.vue";
import MultiImageSlide from "@/components/Presentations/slides/largeSlides/MultiImageSlide.vue";
import updateSlideProp from "@/mixins/ai/update_slide_prop";
import BaseInput from "@/components/CoreUI/BaseInput.vue";

export default {
    components: {
        BaseInput,
        MultiImageSlide,
        ImagePicker,
        PresentationEditMenu,
        SlideRight,
        SlideLeft,
        SlideFullbleed,
        SlideOther,
        SlideMatrix,
        SlideTimeline,
        ButtonIconWhite,
        SlideTable,
    },
    mixins: [SlideStyleMixin, presenationMixin, documentManagementMixin],
    data: () => ({
        // edit: false,
        moveOver: false,
        blockStyle: null,
        documents: [],
        selectedBackground: null,
        selectedText: null,
        theme: null,
        colors: null,
        newSlidePrompt: "",
        hue: 0,
        color: "#000000",
        colorSecondary: "#000000",
        saturation: 0,
        lightness: 0,
        textClass: null,
        bgClass: null,
        borderClass: null,
        showInput: false,
    }),
    mounted() {
        if (!this.slide.background_class) {
            this.slideClass();
        }
        this.$nextTick(() => {
            if (!this.slide.background_class) {
                let colors = ["light", "dark"];
                let r = Math.floor(Math.random() * colors.length);
                this.slideClass(r);
            }
        });
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        slide: {
            type: Object,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
        documentId: {
            type: String,
            // required: true,
        },
    },
    methods: {
        ...mapActions("presentation", ["updateSlide"]),
        wantsNewSlide() {
            this.showInput = !this.showInput;
            if (this.showInput) {
                this.$nextTick(() => {
                    this.$refs.newInput.$refs.input.focus();
                });
            }
            // this.addSlideBelow(index);
        },
        mobileImagePicker() {
            if (this.mobile) {
                updateSlideProp(this.$store, "showImages", true, this.index);
            }
        },
        offsetHeight() {
            // display offset height for section

            return this.$refs[`slide-${this.index}`].offsetHeight;
        },

        updateColors() {
            this.$nextTick(() => {
                if (this.document.theme) {
                    let style = this.slideStyle;
                    let t = this.document.theme.colors || false;
                    if (t) this.textTheme();
                    this.blockStyle = this.slideStyle;
                }
            });
        },
        removeSlideClicked(index) {
            this.removeSlide(index);
        },
        async changeStyle() {
            await this.savePresentation(this.$route.params.id);
        },
        async handleSaveDoc(payload) {
            await this.savePresentation(this.$route.params.id);
        },
    },
    computed: {
        getSlideComponent() {
            const slideStyle = this.slideStyle;

            const stylesMap = {
                right: SlideRight,
                left: SlideLeft,
                matrix: SlideMatrix,
                timeline: SlideTimeline,
                tablegrid: SlideTable,
                fullbleed: SlideFullbleed,
                multiimage: MultiImageSlide,
            };

            if (slideStyle) {
                const lowerCasedSlideStyle = slideStyle.toLowerCase();

                for (let style in stylesMap) {
                    if (style === lowerCasedSlideStyle) {
                        return stylesMap[style];
                    }
                }
            }
            return SlideOther;
        },
        slideStyle() {
            if (this.slide && this.slide.style) {
                const slideStyle = this.slide.style.toLowerCase();

                const stylesMap = {
                    right: ["right", "imageright", "rslide"],
                    left: ["left", "imageleft"],
                    matrix: ["matrix"],
                    timeline: ["timeline"],
                    tablegrid: ["tablegrid", "list"],
                    fullbleed: ["full", "fullbleed", "full bleed", "fullbleedImage"],
                    multiimage: ["multiimage", "images"],
                };

                for (let style in stylesMap) {
                    if (stylesMap[style].some(keyword => slideStyle.includes(keyword))) return style;
                }
            }
        },
        slideContainerClasses() {
            return `row x no-gutters m-0 p-0 overflow-hidden br-10 h-100 shadow-md`;
            //
        },
    },
    watch: {
        showInput: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        this.$refs.newInput.$refs.input.focus();
                    });
                }
            },
            immediate: true,
        },
        "slide.style": {
            immediate: false,
            handler(val, oldVal) {
                if (val) {
                    updateSlideProp(this.$store, "style", val, this.index);
                    let slide = this.document.sections[this.index];
                    slide.index = this.index;
                    this.savePresentation(this.$route.params.id);
                    if (val === "fullbleed") {
                        this.slideClass("dark");
                    }
                    // console.error("updated Block Style", oldVal, val);
                }
                if (oldVal && oldVal?.includes("full")) {
                    this.slideClass("light");
                }
            },
        },
        "document.theme.colors": {
            immediate: true,
            handler(val, oldVal) {
                if (val && !oldVal) {
                    if (!this.slide.background_class) {
                        this.slideClass();
                    }
                }
            },
        },
        "document.sections": {
            immediate: true,
            async handler(val, oldVal) {
                if (this.document.theme) {
                    // if (!this.slide.background_class) {
                    // this.slideClass();
                    // }
                }
            },
        },
        document: {
            immediate: true,
            async handler(val, oldVal) {
                if (this.document.theme) {
                    let t = this.document.theme.colors || false;
                    if (t) this.textTheme();
                    let style = this.slideStyle;
                }
            },
        },
    },
};
</script>
<style scoped>
.rotate45 {
    @apply ease-in-out;
    @apply ease-back;
    @apply duration-3;
    transform: rotate(-135deg);
}

input,
textarea {
    border: 1px solid white;
    margin-bottom: 0px;
    background: none;
    color: white;
}

.moveover {
    transition: all 0.3s ease-in-out;
    transform: translateX(-95%);
}

@media (min-width: 576px) {
    .moveover {
        transition: all 0.3s ease-in-out;
        transform: translateX(-450px);
    }
}

@media (min-width: 768px) {
    .moveover {
        transition: all 0.3s ease-in-out;
        transform: translateX(-450px);
    }
}

@media (min-width: 992px) {
    .moveover {
        transition: all 0.3s ease-in-out;
        transform: translateX(-450px);
    }
}

@media (min-width: 1200px) {
    .moveover {
        transition: all 0.3s ease-in-out;
        transform: translateX(-450px);
    }
}
</style>
