<template>
    <div @mouseleave="hovered = false" class="f border-light br-10 relative items-center p-2 py-1">
        <div style="height: 75px; width: 75px" class="relative overflow-hidden rounded-lg" @mouseenter="hovered = true" :key="url" :style="`background:${bg}`">
            <div class="p-absolute p-fill o-cover y" v-if="image.color" :style="`background:${image.color}`"></div>
            <img v-if="image?.transparent" class="x p-absolute p-fill o-contain y p-1 py-3" :src="url" :alt="image.alt || 'Image'" />
            <img v-else-if="image?.kind === 'logo'" class="x p-absolute p-fill o-cover y" :src="url" :alt="image.alt || 'Image'" />
            <img v-else class="x p-absolute p-fill o-cover y" :src="url" :alt="image.alt || 'Image'" />
        </div>
        <div class="fc ml-3 flex">
            <div class="fc flex flex-wrap gap-1 text-left text-sm text-gray-500">
                <div class="mb-2 pr-5">
                    <input @blur="(editing = false), updateAlt(image.alt)" @keydown.enter.prevent="updateAlt(image.alt)" type="text" v-model="image.alt" :class="{ '!text-red-600': !image.alt }" class="x f-20 m-0 block rounded !border-none bg-transparent p-0 placeholder-red-600" placeholder="Edit alt text" />
<!--                    <span v-else @click.prevent="editing = true" :class="{ 'text-red-600 f-24': !image.alt }" class="f-17 line-clamp-3">{{ image.alt || "Add text" }}</span>-->
                </div>
                <div class="f jcs fc f-13 flex-wrap" :class="{ 'o-5': !image.alt }">
                    <div v-if="image.colorToUse">
                        <div class="f ais jcs gap-2">
                            <div class="f gap-1">
                                Color:
                                <div :style="`background:${image.color}`" class="border-light h-3 w-3 rounded-full"></div>
                            </div>
                            <div class="f gap-1">
                                BG:
                                <div :style="`background:${image.backgroundColor}`" class="border-light h-3 w-3 rounded-full"></div>
                            </div>
                            <!--                            {{ image.backgroundColor }}-->
                        </div>
                    </div>
                    <div class="f flex-wrap gap-3 mt-2">
                        <span v-if="image.textColorCenter">Text: {{ image.textColorCenter }}</span>
                        <span v-if="image.complex">Complex {{ image.complex }}</span>
                        <span v-if="image.textColor">Text color: {{ image.textColor }}</span>
                        <span v-if="image.score">Score: {{ image.score }}</span>
                        <span v-if="image.colorCount">Colors: {{ image.colorCount }}</span>
                        <div v-if="image.kind" class="">Kind: {{ image.kind }}</div>
                    </div>
                </div>
            </div>
            <span v-if="image.keywords" class="block">
                Keywords:
                <template v-for="(keyword, index) in image.keywords" :key="index">
                    <span>{{ keyword }}</span>
                </template>
            </span>
        </div>
        <div class="top right absolute cursor-pointer c-30 f aic jcc" @click.prevent="deleteImage(image)"><i class="fa fal fa-times"></i></div>
    </div>
</template>

<script>
import saveToFirebase from "@/mixins/firebase/saveToFirebase";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";

export default {
    name: "StyleGuideImageRow",
    mixins: [styleGuideMixin],
    methods: {
        saveGuide() {
            this.$store.dispatch("styleGuide/saveStyleGuide", this.$store.state.styleGuide.styleGuide);
        },
        async deleteImage() {
            await this.$store.dispatch("styleGuide/deleteStyleGuideImage", this.image);
        },
        updateAlt() {
            let keypath = `images.${this.index}.alt`;
            this.updateStyleGuideProp(keypath, this.image.alt);
            this.saveGuide(this.$store.state.styleGuide.styleGuide);
        },
        isEditing() {
            this.editing = true;
        },
    },
    data() {
        return {
            hovered: false,
            editing: false,
        };
    },
    computed: {
        url() {
            if (!this.hovered) {
                if (this.image && this.image.crops) {
                    return this.image.crops.square || this.image.url;
                } else {
                    return this.image.url;
                }
            } else {
                return this.image.url;
            }
        },
    },
    props: {
        bg: {},
        image: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style scoped>
/* Any additional styles you may want to add */
</style>
