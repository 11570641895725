<script setup>
// import SiteFooter from '@/components/SiteFooter.vue';
// import SiteHeader from "@/components/SiteHeader.vue";
</script>

<template>
    <div class="mt-8 bg-base-100 dark:bg-base-900 vh-100 f aic jcc">
        <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight">Whoops!</h1>
            <p>We'll redirect you back to</p>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.redirect();
    },
    methods: {
        // redirect to home after 5 sections
        redirect() {
            setTimeout(() => {
                this.$router.push("/");
            }, 5000);
        },
    },
};
</script>
