import { mapActions } from "vuex";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            recommend_location: {
                name: "Recommend location",
                command: "recommend_location",
                kind: "map",
                start: "[MST]",
                end: "[MND]",
                role: "assistant",
                args: { location_name_or_address: "" },
                // description: "Generate maps for any location by providing a 'location' argument.",
                description: "Recommend a location by providing a 'location_name_or_address' argument.",
                show: false,
                newMessage: true,
                resources: ["Recommend a location."],
            },
        };
    },
    computed: {},
    methods: {
        // ...mapActions("chat", ["addToMemoryStore"]),
    },
};
