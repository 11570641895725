import { mapGetters } from "vuex";
import research_topic from "@/ai_functions/research_command";
import research_brand from "@/ai_functions/research_brand";
import start_research from "@/ai_functions/start_research";
import sites_to_include from "@/utils/sites_to_include";
import blacklist_sites from "@/utils/blacklist_sites";
import browseWebsiteSimple from "@/mixins/Chat/Commands/browse_website_simple";
import randomId from "@/mixins/ai/random_id";
import cleanURL from "@/mixins/ai/clean_url";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import deduplicateUrls from "@/mixins/ai/deduplicate_urls";
import addProgressStep from "@/mixins/ai/add_progress_step";
import { checkAllUrlsForShopify, tryForShopifyInfo, extractShopifyImages } from "@/mixins/ai/check_shopify_store";
import completeStep from "@/mixins/ai/complete_progress_step";
import addToProgress from "@/mixins/ai/add_to_progress";
import updateProgress from "@/mixins/ai/update_progress";
import removeStep from "@/mixins/ai/remove_progress_step";
import completion from "@/mixins/ai/completion";
import googleImageSearch from "@/mixins/ai/google_image_search";
import addImagesToQueue from "@/mixins/ai/add_images_to_que";
import logSearchUrls from "@/mixins/ai/logSearchUrls";
import updateDocumentProp from "@/mixins/ai/update_document_prop";
export default {
    name: "ResearchCommand",
    created: function () {},
    mounted() {
        window.streamResearch = this.streamResearch;
    },
    data() {
        return {
            save_summary: {
                name: "Research Topic",
                command: "research_topic",
                kind: "research",
                start: "[RSS]",
                end: "[RSE]",
                role: "function",
                args: {
                    url: "",
                    google_image_query: "",
                    google_search_query: "",
                },
                description: "Research a topic.",
                show: false,
                newMessage: true,
            },
            includeSites: sites_to_include,
            excludeSites: blacklist_sites,
            extraTerms: [],
            excludeTerms: ["promo", "bbb", "zoominfo", "globenewswire", "glassdoor"],
        };
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue", "progress"]),
        researchMessages() {
            return this.queue.research.messages;
        },
        researchCommand() {
            return start_research;
        },
        researchBrand() {
            return research_brand;
        },
        researchTopicBuild() {
            return research_topic;
        },
    },
    methods: {
        addToProgress(name, text) {
            addToProgress(this.$store, name, text);
        },
        removeFromProgress() {
            removeStep(this.$store, this.state[0].name);
        },
        addProgressStep(name, text = "", data) {
            addProgressStep(this.$store, name, (text = ""), data);
        },
        clearProgress() {
            this.$store.dispatch("presentation/clearProgress");
        },
        removeStep(stepName) {
            removeStep(this.$store, stepName);
        },
        completeStep(stepName, data) {
            completeStep(this.$store, stepName, data);
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async streamResearch(topic, system) {
            let messages = [sysMessage(system), userMessage(topic)];
            const functions = this.researchTopicBuild;
            await this.streamCompletion(
                this.$store,
                messages,
                undefined,
                undefined,
                undefined,
                (token, function_objects, json) => {
                    updateQueueProp(this.$store, "research.stream", json);
                },
                undefined,
                gpt3,
                undefined,
                `presentation - ${topic}`,
                0.5,
                functions,
                "research_topic",
                5000,
            );
        },
        // async getImagesFromResearch(image_query) {
        //     addProgressStep(this.$store, "Sourcing images", image_query);
        //     let allImages = [];
        //     // loop over this assuming there will be multiple queries in the array.
        //     let images = await googleImageSearch(this.$store, image_query, undefined, this.userPlaceTime);
        //     let image_urls = images.map(image => image.link);
        //     completeStep(this.$store, "Sourcing images", images);
        //     return { images, image_urls };
        // },
        async getImagesFromResearch(image_query) {
            if (!image_query || (Array.isArray(image_query) && !image_query.length)) {
                throw new Error("Invalid image query");
            }

            try {
                let allImages = [];
                let queries = Array.isArray(image_query) ? image_query : [image_query];
                let limit = 50;
                let index = 0;
                for (let query of queries) {
                    index++;
                    // Add a progress step for each query
                    addProgressStep(this.$store, `Finding images of ${query}`, "");

                    let images = await googleImageSearch(this.$store, query, undefined, this.userPlaceTime, limit);
                    updateProgress(this.$store, `Finding images of ${query}`, "Images of " + query, `Found ${images.length} images`, true, { images });

                    logGroup(`🖼️ Images for ${query}`, { images });
                    if (Array.isArray(images)) {
                        let imagesWithQuery = images.map(image => ({ ...image, query }));
                        allImages.push(...imagesWithQuery);
                    }
                    completeStep(this.$store, `Images for ${query}`, images);
                }

                let image_urls = allImages.map(image => image.link) || [];

                // completeStep(this.$store, "Sourcing images", allImages);

                return { images: allImages, image_urls };
            } catch (error) {
                console.error("Error in getImagesFromResearch: ", error);
                throw error;
            }
        },

        async initialQuery(topic) {
            let initial_research;
            updateQueueProp(this.$store, "showProgress", true);
            initial_research = await this.researchTopic(topic);
            let { google_query, brand_name, google_image_query, image_query, instructions_for_factsheet, website, new_prompt } = initial_research;
            if (google_image_query) image_query = initial_research.google_image_query;
            completeStep(this.$store, "Starting Research", initial_research);
            if (new_prompt) topic = new_prompt;
            initial_research.topic = topic;
            return { initial_research, google_query, topic, brand_name, image_query, website, instructions: instructions_for_factsheet };
        },
        // async googleQueryForPresentation(query, brand_name) {
        //     try {
        //         addProgressStep(this.$store, "Finding sources", query);
        //         let search_results = await this.searchWeb({ query: this.buildGoogleSearchQuery(query, brand_name) }, undefined, undefined, undefined, true, "brand");
        //         updateProgress(this.$store, "Finding sources", `Found ${search_results.urls.length} sources`, "", true, search_results);
        //         console.groupCollapsed("%c🔍 Search Web", purple);
        //         console.log(search_results);
        //         console.groupEnd();
        //         updateQueueProp(this.$store, "search", search_results);
        //         let urls = search_results.urls;
        //         return { search_results, urls };
        //     } catch (error) {
        //         console.error("googleQueryForPresentation", error.message, query);
        //     }
        // },
        async googleQueryForPresentation(query, brand_name) {
            try {
                if (!query || (Array.isArray(query) && !query.length)) {
                    console.error("Invalid query", query);
                    return { search_results: [], urls: [] };
                }

                let allSearchResults = [];
                let queries = Array.isArray(query) ? query : [query];

                for (let q of queries) {
                    addProgressStep(this.$store, `Researching ${q}`, q);
                    // let search_results = await this.searchWeb({ query: q }, undefined, undefined, undefined, true, "brand");
                    let search_results = await this.searchWeb({ query: this.buildGoogleSearchQuery(q, brand_name) }, undefined, undefined, undefined, true, "brand");
                    if (!this.$store.state?.presentation?.queue?.research?.results) {
                        // if (!this.$store.state?.presentation?.queue?.research) {updateQueueProp(this.$store, "research", {})}
                        // updateQueueProp(this.$store, "research.results", {});
                    }
                    // replace all spaces with underscores in the query
                    // let queryKey = q.replace(/\s+/g, "_");
                    // updateQueueProp(this.$store, `research.results.${queryKey}`, search_results);
                    // Enhance search results with the query used
                    let resultsWithQuery = search_results?.urls.map(url => ({ url, query: q }));
                    allSearchResults.push(...resultsWithQuery);
                    updateProgress(this.$store, `Researching ${q}`, q, `Found ${search_results.urls.length} sources`, true, { urls: resultsWithQuery, queries });

                    completeStep(this.$store, q, search_results);

                    console.groupCollapsed(`%c🔍 ${q}`, purple);
                    console.log(search_results);
                    console.groupEnd();
                    // completeStep(this.$store, "Finding sources", search_results);
                }

                let urls = allSearchResults.map(result => result.url) || [];

                // updateQueueProp(this.$store, "search", { urls, queries });
                // updateProgress(this.$store, "Finding sources", `Found ${urls.length} sources`, "", true, { urls, queries });
                console.log(`${query} RESULTS`, allSearchResults);

                let objToReturn = { search_results: allSearchResults, urls };
                console.log("objToReturn", objToReturn);
                return objToReturn;
            } catch (error) {
                console.error("googleQueryForPresentation", error.message, query);
                console.trace();
                return { search_results: [], urls: [] };
            }
        },
        async resetDocument() {
            this.clearQueue();
            updateDocumentProp(this.$store, "images", []);
            updateDocumentProp(this.$store, "summary", "");
            updateQueueProp(this.$store, "images", []);
            return;
        },
        async researchPresentation(topic, messages) {
            try {
                await this.resetDocument();
                let websites = [];
                let longSummary = "";
                updateQueueProp(this.$store, "showProgress", true);
                this.clearProgress();
                addProgressStep(this.$store, "Starting Research", "");
                let response = await this.initialQuery(topic);
                let { initial_research, google_query, brand_name, image_query, website, instructions } = response;
                updateQueueProp(this.$store, "showProgress", true);

                messages.push(userMessage(`Context from the web:\n${JSON.stringify(initial_research, null, 2)}`));

                let { image_urls } = await this.getImagesFromResearch(image_query);
                const googleQueryResult = await this.googleQueryForPresentation(google_query, brand_name);
                let { urls } = googleQueryResult;
                if (urls) {
                    // messages.push(userMessage(`Here's context on the brand: ${googleQueryResult.results}`));
                    if (urls?.length > 0) {
                        urls = deduplicateUrls({ results: urls }, website);
                        await checkAllUrlsForShopify(this.$store, urls);
                        messages = await this.searchWebsites(urls, google_query, messages, instructions);
                    }

                    console.log(messages);

                    addProgressStep(this.$store, "Research complete", "");
                    setTimeout(() => {
                        completeStep(this.$store, "Research complete");
                    }, 500);
                    setTimeout(() => {
                        updateQueueProp(this.$store, "showProgress", false);
                    }, 1500);
                }

                return messages;
            } catch (error) {
                console.error("Error in researchPresentation", error.message);
                return []; // Error handling as per application's needs
            }
        },

        async searchWebsites(urls, looking_for, messages, instructions) {
            let longSummary;
            longSummary = await this.summarizeManyUrls(urls, looking_for, instructions);
            logSearchUrls(urls, looking_for, longSummary);
            updateQueueProp(this.$store, "research.facts", longSummary);
            // let summary = await completion(
            //     longSummary,
            //     `Make a comprehensive (very detailed, well-organized and fact-heavy) reference doc (use sections and bullet points) for my research project about ${looking_for}. Don't leave out any details. Use shorthand notes to preserve space.`,
            //     "summary",
            //     gpt316,
            //     5000,
            //     false,
            //     0.3,
            //     false,
            //     false,
            // );
            // if (summary) messages.push(userMessage(summary));
            messages.push(userMessage(this.queue.research.facts));
            completeStep(this.$store, "Summarizing", longSummary);
            // updateQueueProp("research.websites", websites,this.$store);
            updateQueueProp(this.$store, "research.messages", messages);
            console.log("RESEARCH DONE");
            return messages;
        },
        async summarizeManyUrls(urls, looking_for, instructions) {
            let summaryParts = `Fact sheet on ${looking_for}:\n\n`;
            let filteredUrls = urls.filter(url => !this.blacklist.some(excluded => url.includes(excluded)));
            let browsePromises = filteredUrls.map(async (url, i) => {
                await this.sleep(500 * i);
                let hostname = cleanURL(url);
                let reading_step = `Reading ${hostname}`;
                let summarizing_step = `Summarizing ${hostname}`;
                addProgressStep(this.$store, reading_step, "");
                await browseWebsiteSimple(this.$store, url, true, looking_for, true, summaryParts, instructions, looking_for).then(results => {
                    // if (results.images)  this.addImagesToQueue(results.images);

                    delete results.images;
                    delete results.metadata;
                    if (results.content && results.content !== "") {
                        if (results.summary) {
                            // summaryParts += "Results from: " + url + "\n";
                            summaryParts += results.summary + "\n\n";
                        } else {
                            // summaryParts += "Results from: " + url + "\n";
                            // summaryParts += results.content + "\n\n";
                        }
                        updateQueueProp(this.$store, "research.facts", summaryParts);
                    } else {
                        // console.log(`No content from ${url}`);
                    }
                });
                updateProgress(this.$store, reading_step, summarizing_step, "", false);
                setTimeout(() => {
                    updateProgress(this.$store, summarizing_step, summarizing_step, "", true);
                    removeStep(this.$store, summarizing_step);
                }, 500);
                // this.completeStep(`Reading ${hostname}`, finalResponse);
            });
            await Promise.all(browsePromises);
            addProgressStep(this.$store, "Summarizing", "");
            // summaryParts = googleResponse + summaryParts;
            // longSummary = await this.completion(summaryParts, `Make a comprehensive (very detailed, well-organized and fact-heavy) reference doc (use sections and bullet points) for my research project about ${google_query}. Don't leave out any details. Don't include links.`, "summary", gpt316, 8000, false, 0.3, false, false);
            return summaryParts;
        },
        async checkAllUrlsForShopify(urls) {
            await checkAllUrlsForShopify(this.$store, urls);
        },
        buildGoogleSearchQuery(searchQuery, brand) {
            let baseTerms = ["news", "founders", "press", "brand", "products", "launch", "founding story", "revenue"];
            // let baseTerms = ["news", "founders", "press", "brand", "products", "launch", "founding story", "revenue"];
            let query = "";
            query += `"${brand}" `;
            query = `${searchQuery} (${baseTerms.join(" OR ")})`;

            this.excludeSites.forEach(site => {
                query += " -site:" + site;
            });

            this.excludeTerms.forEach(term => {
                query += " -" + term;
            });

            return query;
        },
        updateProgress(name, title, text, completion, data) {
            updateProgress(this.$store, name, title, text, completion, data);
        },
        async researchTopic(topic) {
            updateQueueProp(this.$store, "showProgress", true);
            let response = await completion({
                user: topic,
                system: `Help me build a research template for my presentation.
- New Prompt: Rewrite my original prompt in a way that is more specific and detailed, include a page count, and some of the content that should be included in the pages. Min page count is 10. Write this in paragraph form using natural language and a spartan tone.
- Your goal is to set the tone for the presentation and provide a comprehensive overview of the topic. 
- This means you are to be responsible and proactive for crafting the research plan. 
- Be proactive about image queries: If you don't queue up enough queues for images the presentation wil be dull. Be sure to get 3-4 image queries on your own. Be sure to include people, entities, or any supporting images one can think of. Do this especially for specific people.
- Actively seek supporting google searches for things like news, founders, related information to the main topic requested.            
`,
                id: "research topic",
                model: gpt3,
                tokens: 2000,
                json: false,
                temperature: 0,
                sideBot: false,
                functions: this.researchBrand,
                function_call: "research_brand",
            });
            // let response = await this.completion(topic, "Help me build a research template for any type of medium.", "research topic", "gpt-3.5-turbo", 1000, false, 0, false, this.researchCommand, "start_research");
            try {
                console.groupCollapsed("%c✅ Research Topic Response", purple, topic);
                if (response.name) console.log(response.name);
                if (response.arguments) console.log("ARGUMENTS:");
                if (response.arguments) consoleTableReduced(response.arguments, ["query_array", "topics_to_cover"]);
                if (response.arguments) console.log("TOPICS:");
                if (response.arguments.topics_to_cover) console.table(response.arguments.topics_to_cover);
                if (response.arguments) console.log("GOOGLE QUERIES:");
                if (response.arguments.query_array) console.table(response.arguments.query_array);
                consoleTableReduced(response, ["function_call", "query_array"]);
                // console.table({
                //     name: response.name,
                //     ...response.arguments,
                // });
                completeStep(this.$store, "Starting Research");
                updateQueueProp(this.$store, "research.build", response.arguments);

                console.groupEnd();
                // todo: fill this in
                return response.arguments;
            } catch (e) {
                console.error(e);
            }
        },
    },
};
