<script lang="ts" setup>
import { h, ref } from "vue";
import { FieldArray, useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import * as z from "zod";
import { Cross1Icon } from "@radix-icons/vue";
import { cn } from "@/lib/utils";

import { Input } from "@/components/shad/ui/input";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/shad/ui/form";
import { Separator } from "@/components/shad/ui/separator";
import { Textarea } from "@/components/shad/ui/textarea";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/shad/ui/select";
import { Button } from "@/components/shad/ui/button";
import { toast } from "@/components/shad/ui/toast";

const verifiedEmails = ref(["m@example.com", "m@google.com", "m@support.com"]);

const profileFormSchema = toTypedSchema(
    z.object({
        username: z.string().min(2, { message: "Username must be at least 2 characters." }).max(30, { message: "Username must not be longer than 30 characters." }),
        email: z.string({ required_error: "Please select an email to display." }).email(),
        bio: z.string().max(160, { message: "Bio must not be longer than 160 characters." }).min(4, { message: "Bio must be at least 2 characters." }),
        urls: z.array(z.object({ value: z.string().url({ message: "Please enter a valid URL." }) })).optional(),
    }),
);

const { handleSubmit, resetForm } = useForm({
    validationSchema: profileFormSchema,
    initialValues: {
        bio: "I own a computer.",
        urls: [{ value: "https://shadcn.com" }, { value: "http://twitter.com/shadcn" }],
    },
});

const onSubmit = handleSubmit(values => {
    toast({
        title: "You submitted the following values:",
        description: h("pre", { class: "mt-2 w-[340px] rounded-md bg-slate-950 p-4" }, h("code", { class: "text-white" }, JSON.stringify(values, null, 2))),
    });
});
</script>

<template>
    <div>
        <h3 class="text-lg font-medium">Profile</h3>
        <p class="text-sm text-muted-foreground">This is how others will see you on the site.</p>
    </div>
    <Separator />
    <form class="space-y-8" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="username">
            <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                    <Input placeholder="shadcn" type="text" v-bind="componentField" />
                </FormControl>
                <FormDescription>This is your public display name. It can be your real name or a pseudonym. You can only change this once every 30 days.</FormDescription>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="email">
            <FormItem>
                <FormLabel>Email</FormLabel>

                <Select v-bind="componentField">
                    <FormControl>
                        <SelectTrigger>
                            <SelectValue placeholder="Select an email" />
                        </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem v-for="email in verifiedEmails" :key="email" :value="email">
                                {{ email }}
                            </SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <FormDescription>You can manage verified email addresses in your email settings.</FormDescription>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="bio">
            <FormItem>
                <FormLabel>Bio</FormLabel>
                <FormControl>
                    <Textarea placeholder="Tell us a little bit about yourself" v-bind="componentField" />
                </FormControl>
                <FormDescription>
                    You can
                    <span>@mention</span>
                    other users and organizations to link to them.
                </FormDescription>
                <FormMessage />
            </FormItem>
        </FormField>

        <div>
            <FieldArray v-slot="{ fields, push, remove }" name="urls">
                <div v-for="(field, index) in fields" :key="`urls-${field.key}`">
                    <FormField v-slot="{ componentField }" :name="`urls[${index}].value`">
                        <FormItem>
                            <FormLabel :class="cn(index !== 0 && 'sr-only')">URLs</FormLabel>
                            <FormDescription :class="cn(index !== 0 && 'sr-only')">Add links to your website, blog, or social media profiles.</FormDescription>
                            <div class="relative flex items-center">
                                <FormControl>
                                    <Input type="url" v-bind="componentField" />
                                </FormControl>
                                <button class="absolute py-2 pe-3 end-0 text-muted-foreground" type="button" @click="remove(index)">
                                    <Cross1Icon class="w-3" />
                                </button>
                            </div>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>

                <Button type="button" variant="outline" size="sm" class="text-xs w-20 mt-2" @click="push({ value: '' })">Add URL</Button>
            </FieldArray>
        </div>

        <div class="flex gap-2 justify-start">
            <Button type="submit">Update profile</Button>

            <Button type="button" variant="outline" @click="resetForm">Reset form</Button>
        </div>
    </form>
</template>
<!--<template>-->
<!--    <div>-->
<!--        <h3 class="text-lg font-medium">Profile</h3>-->
<!--        <p class="text-sm text-muted-foreground">This is how others will see you on the site.</p>-->
<!--    </div>-->
<!--    <Separator />-->
<!--    <form class="space-y-8" @submit.prevent="onSubmit">-->
<!--        <FormField name="username">-->
<!--            <FormItem>-->
<!--                <FormLabel>Username</FormLabel>-->
<!--                <FormControl>-->
<!--                    <Input v-model="form.username" placeholder="shadcn" type="text" />-->
<!--                </FormControl>-->
<!--                <FormDescription>This is your public display name. It can be your real name or a pseudonym. You can only change this once every 30 days.</FormDescription>-->
<!--                <FormMessage v-if="!v$.form.username.required">Username is required</FormMessage>-->
<!--                <FormMessage v-else-if="!v$.form.username.minLength">Username must be at least 2 characters</FormMessage>-->
<!--                <FormMessage v-else-if="!v$.form.username.maxLength">Username must not be longer than 30 characters</FormMessage>-->
<!--            </FormItem>-->
<!--        </FormField>-->

<!--        <FormField name="email">-->
<!--            <FormItem>-->
<!--                <FormLabel>Email</FormLabel>-->

<!--                <Select v-model="form.email">-->
<!--                    <FormControl>-->
<!--                        <SelectTrigger>-->
<!--                            <SelectValue placeholder="Select an email" />-->
<!--                        </SelectTrigger>-->
<!--                    </FormControl>-->
<!--                    <SelectContent>-->
<!--                        <SelectGroup>-->
<!--                            <SelectItem v-for="email in verifiedEmails" :key="email" :value="email">-->
<!--                                {{ email }}-->
<!--                            </SelectItem>-->
<!--                        </SelectGroup>-->
<!--                    </SelectContent>-->
<!--                </Select>-->
<!--                <FormDescription>You can manage verified email addresses in your email settings.</FormDescription>-->
<!--                <FormMessage v-if="!v$.form.email.required">Please select an email to display</FormMessage>-->
<!--                <FormMessage v-else-if="!v$.form.email.email">Please enter a valid email</FormMessage>-->
<!--            </FormItem>-->
<!--        </FormField>-->

<!--        <FormField name="bio">-->
<!--            <FormItem>-->
<!--                <FormLabel>Bio</FormLabel>-->
<!--                <FormControl>-->
<!--                    <Textarea v-model="form.bio" placeholder="Tell us a little bit about yourself" />-->
<!--                </FormControl>-->
<!--                <FormDescription>-->
<!--                    You can-->
<!--                    <span>@mention</span>-->
<!--                    other users and organizations to link to them.-->
<!--                </FormDescription>-->
<!--                <FormMessage v-if="!v$.form.bio.maxLength">Bio must not be longer than 160 characters</FormMessage>-->
<!--                <FormMessage v-else-if="!v$.form.bio.minLength">Bio must be at least 4 characters</FormMessage>-->
<!--            </FormItem>-->
<!--        </FormField>-->

<!--        <div>-->
<!--            <div v-for="(url, index) in form.urls" :key="index">-->
<!--                <FormField :name="`urls[${index}]`">-->
<!--                    <FormItem>-->
<!--                        <FormLabel :class="cn(index !== 0 && 'sr-only')">URLs</FormLabel>-->
<!--                        <FormDescription :class="cn(index !== 0 && 'sr-only')">Add links to your website, blog, or social media profiles.</FormDescription>-->
<!--                        <div class="relative flex items-center">-->
<!--                            <FormControl>-->
<!--                                <Input v-model="form.urls[index].value" type="url" />-->
<!--                            </FormControl>-->
<!--                            <button class="absolute py-2 pe-3 end-0 text-muted-foreground" type="button" @click="removeUrl(index)">-->
<!--                                <Cross1Icon class="w-3" />-->
<!--                            </button>-->
<!--                        </div>-->
<!--                        <FormMessage v-if="!v$?.form?.urls?.$each?.[index]?.value?.url">Please enter a valid URL</FormMessage>-->
<!--                    </FormItem>-->
<!--                </FormField>-->
<!--            </div>-->

<!--            <Button class="text-xs w-20 mt-2" size="sm" type="button" variant="outline" @click="addUrl">Add URL</Button>-->
<!--        </div>-->

<!--        <div class="flex gap-2 justify-start">-->
<!--            <Button type="submit">Update profile</Button>-->
<!--            <Button type="button" variant="outline" @click="resetForm">Reset form</Button>-->
<!--        </div>-->
<!--    </form>-->
<!--</template>-->

<!--<script>-->
<!--import { Cross1Icon } from "@radix-icons/vue";-->
<!--import { Input } from "@/components/shad/ui/input";-->
<!--import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/shad/ui/form";-->
<!--import { Separator } from "@/components/shad/ui/separator";-->
<!--import { Textarea } from "@/components/shad/ui/textarea";-->
<!--import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/shad/ui/select";-->
<!--import { Button } from "@/components/shad/ui/button";-->
<!--import { toast } from "@/components/shad/ui/toast";-->
<!--import { useVuelidate } from "@vuelidate/core";-->
<!--import { email, maxLength, minLength, required, url } from "@vuelidate/validators";-->
<!--import { cn } from "@/lib/utils";-->
<!--// import { email, required } from "@vuelidate/validators";-->
<!--// import { useVuelidate } from "@vuelidate/core";-->

<!--export default {-->
<!--    components: {-->
<!--        Cross1Icon,-->
<!--        Input,-->
<!--        FormControl,-->
<!--        FormDescription,-->
<!--        FormField,-->
<!--        FormItem,-->
<!--        FormLabel,-->
<!--        FormMessage,-->
<!--        Separator,-->
<!--        Textarea,-->
<!--        Select,-->
<!--        SelectContent,-->
<!--        SelectGroup,-->
<!--        SelectItem,-->
<!--        SelectTrigger,-->
<!--        SelectValue,-->
<!--        Button,-->
<!--    },-->
<!--    setup: () => ({ v$: useVuelidate() }),-->
<!--    data() {-->
<!--        return {-->
<!--            verifiedEmails: ["m@example.com", "m@google.com", "m@support.com"],-->
<!--            form: {-->
<!--                username: "",-->
<!--                email: "",-->
<!--                bio: "I own a computer.",-->
<!--                urls: [{ value: "https://shadcn.com" }, { value: "http://twitter.com/shadcn" }],-->
<!--            },-->
<!--        };-->
<!--    },-->

<!--    validations: {-->
<!--        form: {-->
<!--            username: {-->
<!--                required,-->
<!--                minLength: minLength(2),-->
<!--                maxLength: maxLength(30),-->
<!--            },-->
<!--            email: { required, email },-->
<!--            bio: {-->
<!--                maxLength: maxLength(160),-->
<!--                minLength: minLength(4),-->
<!--            },-->
<!--            urls: {-->
<!--                $each: { value: { url } },-->
<!--            },-->
<!--        },-->
<!--    },-->

<!--    methods: {-->
<!--        cn(input) {-->
<!--            cn(input);-->
<!--        },-->
<!--        addUrl() {-->
<!--            this.form.urls.push({ value: "" });-->
<!--        },-->

<!--        removeUrl(index) {-->
<!--            this.form.urls.splice(index, 1);-->
<!--        },-->

<!--        onSubmit() {-->
<!--            this.v$.$touch();-->
<!--            if (!this.v$.$invalid) {-->
<!--                toast({-->
<!--                    title: "You submitted the following values:",-->
<!--                    description: this.$createElement(-->
<!--                        "pre",-->
<!--                        { class: "mt-2 w-[340px] rounded-md bg-slate-950 p-4" }, //-->
<!--                        this.$createElement("code", { class: "text-white" }, JSON.stringify(this.form, null, 2)),-->
<!--                    ),-->
<!--                });-->
<!--            }-->
<!--        },-->

<!--        resetForm() {-->
<!--            this.v$.$reset();-->
<!--            this.form = {-->
<!--                username: "",-->
<!--                email: "",-->
<!--                bio: "I own a computer.",-->
<!--                urls: [{ value: "https://shadcn.com" }, { value: "http://twitter.com/shadcn" }],-->
<!--            };-->
<!--        },-->
<!--    },-->
<!--};-->
<!--</script>-->
