function compareAndLogChanges(newVal, oldVal) {
    // Traverse through the new object
    for (let prop in newVal) {
        if (newVal.hasOwnProperty(prop)) {
            if (prop !== "index") {
                if (oldVal && newVal[prop] !== oldVal[prop] && !parseInt(prop)) {
                    console.groupCollapsed(`%cProp changed:`, fail, `${prop}`);
                    // Property value has changed
                    console.log(`NEW: "${newVal[prop]}"`);
                    console.log(`OLD: "${oldVal[prop]}"`);
                    console.trace();
                    console.groupEnd();
                }
            }
        }
    }

    // Check if any property was removed
    if (oldVal) {
        for (let prop in oldVal) {
            if (oldVal.hasOwnProperty(prop) && !newVal.hasOwnProperty(prop)) {
                console.log(`REMOVED: "${prop}"`);
            }
        }
    }
}
export default compareAndLogChanges;
