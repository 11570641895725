<template>
    <div>
        <div v-if="$route.path.includes('admin')" class="py-3 px-3">
            <upload-user-photo @photo-uploaded="handleUploadedImage"></upload-user-photo>
        </div>
        <div class="text-center x"></div>
        <div class="mw-700 mx-auto my-3 text-center f fc gap-4 jcc aic">
            <template v-if="isAdmin">
                <BaseButton :style="buttonStyles[0]" class="mx-auto" label="Get more Images" style-type="seconda" @click.prevent="getMoreImages()"></BaseButton>
                <BaseButton v-if="styleGuide.shopify && !styleGuide.hasShopifyImages" :style="buttonStyles[0]" class="mx-auto" label="Sync shopify" style-type="seconda" @click.prevent="updateGuideSection('shopify')"></BaseButton>

                <a v-if="styleGuide.shopify" class="!rounded-md" href="#" @click.prevent="updateGuideSection('shopify')">Sync shopify</a>
                <span v-else-if="!styleGuide.shopify && !styleGuide.hasShopifyImages" class="o-5 text-gray-900">
                    Want more images?
                    <a class="!rounded-md text-blue-900" href="#" @click.prevent="updateGuideSection('hasShopify')">Check for Shopify</a>
                    <a v-if="styleGuide.shopify" class="!rounded-md" href="#" @click.prevent="updateGuideSection('shopify')">Sync shopify</a>
                </span>
            </template>
            <!--                <a class="!rounded-md" href="#" @click.prevent="$store.dispatch('styleGuide/clearImages')">Clear Images</a>-->
            <BaseButton icon="fas fa-trash" label="Clear Images" class="!text-red-400" rounded @click="$store.dispatch('styleGuide/clearImages')"></BaseButton>
        </div>
<!--        <style-guide-image-array section-name="Other sources" section-id="additionalText" :imgs="sortedImages" />-->
        <style-guide-image-array section-name="Logos" section-id="logos" :imgs="logos" />
        <style-guide-image-array :imgs="sortedImages" />
      <div popover class="bg-snow p-5 p-fixed top left right center z-5">Hey!</div>
    </div>
</template>
<script>
import StyleGuideImage from "@/components/styleGuide/Images/StyleGuideImage.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import StyleGuideImageRow from "@/components/styleGuide/Images/StyleGuideImageRow.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import UploadUserPhoto from "@/components/images/PhotoUploader.vue";
import StyleGuideImageArray from "@/components/styleGuide/Sections/ImagesSection.vue";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import firebaseSave from "@/mixins/firebase/saveToFirebase";

export default {
    name: "AdminImages",
    components: {
        StyleGuideImageArray,
        UploadUserPhoto,
        BaseButton,
        StyleGuideImageRow,
        StyleGuideContainer,
        StyleGuideImage,
    },
    mixins: [styleGuideMixin],
    props: {
        sectionName: {
            type: String,
            required: false,
            default: "Images",
        },
        sectionId: {
            type: String,
            required: false,
            default: "processedImages",
        },
        imgs: {
            type: Array,
            default: () => [],
            validator: value => {
                return Array.isArray(value) || value === null;
            },
        },
        bg: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            weights: {
                type: 1,
                size: 0,
                dimensions: 0.5,
                vibrancy: 3,
                // type: 5,
                // size: 5,
                // dimensions: 0.08,
                // vibrancy: 266,
            },
            edit: false,
            showAll: false,
            altImages: [],
            order: ["webp", "png", "jpeg", "svg", "gif"],
        };
    },
    mounted() {
        this.$nextTick(() => {
            // this.getBrandImages();
        });
    },
    computed: {
        sectionIdOverride() {
            let id = "processedImages";
            if (this.sectionId) {
                id = this.sectionId;
            }
            return id;
        },
        filteredImages() {
            if (this.public && !this.showAll) {
                return this.imgs.slice(0, 10);
            } else if (this.showAll) {
                return this.imgs;
            } else if (this.isAdmin) {
                return this.imgs;
            } else {
                return this.imgs.slice(0, 10);
            }
        },
    },
    methods: {
        async getMoreImages() {
            await this.createStyleGuide(this.styleGuide.url, this.$route.params.id, {
                sections: ["additionalText"],
                id: this.$route.params.id,
            });
        },
        handleUploadedImage(image) {
            console.log("handling uploaded image", image);
            if (this.sectionIdOverride === "logos") {
                this.updateStyleGuideProp("logos", [{ url: image.url }]);
            }
            this.addStyleGuideImage(image);
        },
    },
};
</script>
