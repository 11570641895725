<template>
    <header class="nav-bar bg-snow dark:bg-base-900 vw-100" v-if="!mobile || (mobile && !($route.path.includes('chat') || $route.path.includes('presentation')))" ref="navBar" style="zoom: 1 !important">
        <RouterLink class="f jcs d-block logo width-100 width-min-150 aic mb-0 cursor-pointer pb-0" to="/" v-if="!mobile">
            <Logo :fill-color="logoColor"></Logo>
        </RouterLink>
        <transition name="fade-in">
            <StyleGuideSearch :key="$route.params.id" iclass="!f-20" v-if="showSearch" class="x md:ml-5 x z-0 relative br-5 border-light"></StyleGuideSearch>
            <!--            <style-guide-search-new :key="$route.params.id" iclass="!f-20" v-if="showSearch" class="x md:ml-5 x z-0 relative br-5 border-light" />-->
        </transition>
        <template v-if="mobile">
            <div class="z-3 relative line-height-smaller f-24 px-2 py-3 transition-all" @click.prevent="showMenu = !showMenu">
                <i class="fa-solid fa-bars"></i>
            </div>
            <Transition duration="300" enter-active-class="animated slideInRight duration-1 ease-1" leave-active-class="animated slideOutRight" name="drawer">
                <MobileDrawer @hide-menu="showMenu = false" class="z-9" v-if="showMenu" :nav-items="navList" :show-menu="showMenu" />
            </Transition>
            <template v-if="$route.params.id">
                <li class="nav-link px-0" v-if="$route?.path?.includes('styleGuide')">
                    <RouterLink :to="`/admin/${$route.params.id}/campaigns`"><BaseButton style-type="secondary" class="x mw-200 px-3 text-base" size="xs" rounded label="Admin"></BaseButton></RouterLink>
                </li>
                <li class="nav-link px-0" v-if="$route?.path?.includes('admin')">
                    <RouterLink :to="`/styleGuide/${$route.params.id}`"><BaseButton style-type="secondary" class="x mw-200 px-3 text-base" size="xs" rounded label="Guide"></BaseButton></RouterLink>
                </li>
            </template>
        </template>
        <template v-if="!mobile">
            <div class="f jcb x p-relative aic">
                <div class="x f jce aic">
                    <ul class="f aic py-0">
                        <li v-for="item in navList" class="nav-link px-2">
                            <RouterLink :to="item.path"><BaseButton style-type="link" class="text-gray-600 text-base" size="md" rounded :label="item.title"></BaseButton></RouterLink>
                        </li>
                        <template v-if="user.brand">
                            <li class="nav-link px-2">
                                <RouterLink :to="`/admin/${user.brand}/campaigns`"><BaseButton style-type="secondary" class="x mw-200 px-3 text-base" size="xs" rounded label="Admin"></BaseButton></RouterLink>
                            </li>
                        </template>
                        <template v-if="$route.params.id && showAdminButton">
                            <li class="nav-link px-2" v-if="$route?.path?.includes('styleGuide')">
                                <RouterLink :to="`/admin/${$route.params.id}/campaigns`"><BaseButton style-type="secondary" class="x mw-200 px-3 text-base" size="xs" rounded label="Switch to Admin"></BaseButton></RouterLink>
                            </li>
                            <li class="nav-link px-2" v-if="$route?.path?.includes('admin')">
                                <RouterLink :to="`/styleGuide/${$route.params.id}`"><BaseButton style-type="secondary" class="x mw-200 px-3 text-base" size="xs" rounded label="Switch to Profile"></BaseButton></RouterLink>
                            </li>
                        </template>
                        <NavItem v-if="user" :sub-array="toolItems" title="Tools" avatar class="ml-2"></NavItem>
                        <li class="nav-link" v-else><router-link to="/auth">Login</router-link></li>
                    </ul>
                </div>
            </div>
        </template>
    </header>
</template>
<script>
import Logo from "@/components/CoreUI/navigaion/logo.vue";
import NavItem from "@/components/CoreUI/navigaion/NavItem.vue";
import { BookOpenIcon, ChatBubbleOvalLeftIcon, PhotoIcon, PresentationChartLineIcon, UsersIcon } from "@heroicons/vue/24/outline";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import StyleGuideSearch from "@/components/styleGuide/ListOfGuides/StyleGuideSearch.vue";
import MobileDrawer from "@/components/CoreUI/navigaion/MobileDrawer.vue";
import Categories from "@/views/Categories.vue";
import StyleGuideSearchNew from "@/components/styleGuide/ListOfGuides/StyleGuideSearchNew.vue";

export default {
    components: { StyleGuideSearchNew, MobileDrawer, StyleGuideSearch, BaseButton, NavItem, Logo },
    data: () => ({
        showMenu: false,
        navItems: [
            { title: "Categories", description: "Bring your stories to life visually", path: "/categories/Featured", iconComponent: "" },
            { title: "About", description: "Bring your stories to life visually", path: "/about", iconComponent: BookOpenIcon },
            { title: "Presentation", description: "Create and edit slide presentations", path: "/presentations", iconComponent: PresentationChartLineIcon },
            { title: "Chat", description: "Interact and collaborate with teammates", path: "/chat", iconComponent: ChatBubbleOvalLeftIcon },
            // { title: "Admin", description: "Edit your site", path: "/admin", iconComponent: BookOpenIcon },
        ],
        toolItems: [{ title: "Account", description: "Manage your account", path: "/auth", iconComponent: UsersIcon }],
        logoColorStart: "rgba(0, 0, 0, 1)",
        logoColorEnd: "rgba(0, 0, 0, 1)",
        navColorStart: "rgba(57, 69, 105, 1)",
        navColorEnd: "rgba(0, 0, 0, 1)",
        navIconColorStart: "rgba(0, 0, 0, 1)",
        navIconColorEnd: "rgba(0, 0, 0, 1)",
        navShadowStart: "0 1px 1px rgba(0,0,0,0.0), 0 2px 2px rgba(0,0,0,0.0), 0 4px 4px rgba(0,0,0,0.0), 0 8px 8px rgba(0,0,0,0.0), 0 16px 16px rgba(0,0,0,0.0)",
        navShadowEnd: "0 1px 1px rgba(0,0,0,0.02), 0 2px 2px rgba(0,0,0,0.03), 0 4px 4px rgba(0,0,0,0.02), 0 8px 8px rgba(0,0,0,0.02), 0 16px 16px rgba(0,0,0,0.02)",
        backgroundColorStart: "rgba(255, 255, 255, 0)",
        backgroundColorEnd: "rgba(255, 255, 255, 1)",
        marginTopStart: 0,
        marginTopEnd: 0,
        borderBottomStart: "0px solid rgba(0,0,0,0.0)",
        borderBottomEnd: "1px solid rgba(0,0,0,0.1)",
        paddingLeft: "1em",
        paddingRight: "1em",
        paddingBottom: "1em",
        opacityStart: 0,
        opacityEnd: 0,
        showAlertBar: true,
        showDesktopNav: false,
        aboveNavScene: null,
        mobileNavScene: null,
    }),
    computed: {
        logoColor() {
            return "rgba(0,0,0,1)";
        },
        showAdminButton() {
            let styleGuide = this.$route?.params?.id || false;
            let userBrand = this.user?.brand || false;
            if (styleGuide && styleGuide === userBrand) return true;
            else if (this.isAdmin) return true;
            else return false;
        },
        showSolidHeader() {
            const paths = ["/documents", "/imgplay", "/free", "/styleGuides", "/canvas", "/storyboard", "presentations"];
            return paths.some(path => this.$route.path.includes(path));
        },
        showSearch() {
            if (!this.superAdmin) return false;
            const { path, params } = this.$route;
            const isOnAdminPage = path.includes("admin");
            const hasCIDParam = params?.cid;

            if (isOnAdminPage && hasCIDParam) return false;

            return !this.showMenu && (isOnAdminPage || path.includes("styleGuide") || path.includes("categories"));
        },
        bgGradient() {
            let color;
            let gradient = true;

            if (this.showSolidHeader) {
                gradient = false;
                color = "rgba(255,255,255,1)";
            }
            let background = `background:${color};overflow-x:hidden;background-image:${color}`;
            // background = `background:${color};position:relative;overflow-x:hidden;background-image:${color}`;

            return background;
            // return false;
        },
        navList() {
            if (!this.superAdmin) {
                return [];
            } else {
                return this.navItems;
            }
        },
    },
    mounted() {
        this.navBarHeight();
    },
    methods: {
        navBarHeight() {
            this.$nextTick(() => {
                if (!this.$refs.navBar) return;
                const navBarHeight = this.$refs.navBar.clientHeight;
                // add a CSS class to head called navTop
                const head = document.querySelector("head");
                // add properties to a CSS class called navTop
                let navTop = document.createElement("style");
                navTop.type = "text/css";
                navTop.innerHTML = `
                .pt-nav{ padding-top:${navBarHeight}px!important; }
                .top-nav{ top:${navBarHeight}px!important; }
                .h-nav{ height:${navBarHeight}px!important; }
                .navTop{ top:${navBarHeight}px!important; }
                .mt-nav{ margin-top:${navBarHeight}px!important; }`;
                head.appendChild(navTop);
                head.classList.add("navTop");
            });
        },
        bodyFix() {
            let route = this.$route.path === "/";
            if (route) {
                this.lockBody();
                const body = document.querySelector("body");
                body.classList.add("funBackground");
                console.log("lock body");
            } else {
                this.unlockBody();
                const body = document.querySelector("body");
                body.classList.remove("funBackground");

                console.log("unlock body");
            }
        },
        lockBody() {
            const body = document.querySelector("body");
            body.classList.add("bodyHeightFix");
            body.classList.add("p-fixed");
            body.classList.add("p-fill");
        },
        unlockBody() {
            const body = document.querySelector("body");
            // body.classList.remove('bodyHeightFix p-fixed p-fill');
            body.classList.remove("bodyHeightFix");
            body.classList.remove("p-fixed");
            body.classList.remove("p-fill");
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-link {
    color: black;
}
</style>
