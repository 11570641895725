<template>
    <div class="">
        <PreviewWrapper :data="campaign" menu-title="Campaign">
            <template #side>
                <div class="">
                    <CampaignHeader :key="'campy1'" :result="campaign" />
                    <CampaignScheduleItem :schedule-items="campaign.schedule_items" @trigger-generation="triggerGeneration" />
                </div>
            </template>
            <div v-if="campaign?.schedule_items" class="f fc jcc aic px-2">
                <CollapsableMenuItem class="container-slim">
                    <template #title>
                        <div class="py-3">Debug</div>
                    </template>
                    <DynamicObjectViewer v-if="campaignData" :data-object="campaignData" class="container-slim"></DynamicObjectViewer>
                </CollapsableMenuItem>
                <CampaignScheduleItem :schedule-items="campaign.schedule_items" @trigger-generation="triggerGeneration" />
                <template v-for="(child, index) in sortedChildren">
                    {{ child.name }} - {{ child.result.campaignIndex }}
                    <!--                <LandingPage :result="child.result" :style-guide="styleGuide" in-campaign :images="images"></LandingPage>-->
                    <template v-if="child.result.object_type === 'landing_page'">
                        <LandingPageSection v-for="(section, i) in child.result.sections" :id="child.result.id || 0" :key="`section${i}`" :index="i" :result="section" :sections="child.result.sections" :identifier="identifier" />
                    </template>
                    <div v-if="child.result.object_type === 'email'" class="mw-500">
                        <NewEmailSection v-for="(section, i) in child.result.sections" :id="child.result.id || 0" :key="`section${i}`" :index="i" :result="section" :sections="child.result.sections" />
                        <EmailFooterSection v-if="child?.result?.email_footer" :footer="child.result.email_footer" />
                    </div>
                </template>
            </div>
        </PreviewWrapper>
    </div>
</template>
<script>
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import CampaignScheduleItem from "@/components/generative_templates/Campaigns/CampaignScheduleItem.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import PreviewWrapper from "@/components/Admin/CampaignPreviewWrapper.vue";
import { mapGetters } from "vuex";
import LandingPageSection from "@/components/generative_templates/LandingPageSection.vue";
import NewEmailSection from "@/components/generative_templates/Email/NewEmailSection.vue";
import EmailFooterSection from "@/components/generative_templates/Email/EmailFooterSection.vue";

export default {
    name: "CampaignObjectPreview",
    components: {
        EmailFooterSection,
        NewEmailSection,
        LandingPageSection,
        CampaignHeader,
        CampaignScheduleItem,
        CollapsableMenuItem,
        DynamicObjectViewer,
        PreviewWrapper,
    },
    computed: {
        ...mapGetters("stream", ["result", "campaignData", "showCampaign", "campaigns", "parentCampaign", "campaignSiblings", "campaignChildren"]),
        sortedChildren() {
            if (this.campaignChildren.length > 0) {
                return this.campaignChildren.sort((a, b) => {
                    if (a.result && b.result) {
                        return a.result.campaignIndex - b.result.campaignIndex;
                    }
                });
            }
        },
    },
    props: {
        campaign: {},
        styleGuide: {},
    },
};
</script>
