//setupStreamId.js
import random_id from "@/mixins/ai/random_id";

function setupStreamId(store) {
    try {
        let id = random_id();
        if (store?.state?.currentRoute?.params?.id) id = store.state.currentRoute.params.id;
        store.dispatch("stream/addActiveStreamId", id);
        return id;
    } catch (e) {
        console.error("Error setting up stream id:", e);
        return null;
    }
}

export default setupStreamId;
