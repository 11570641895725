<template>
    <!--    <div class="grid grid-cols-6 py-5">-->

    <UICollapsableSection v-if="collapse && title">
        <template v-if="title" #title>{{ title }}</template>
        <div>
            <span class="f-34 !leading-normal block" v-if="ethos.introduction">{{ ethos.introduction }}</span>

            <div v-for="(item, index) in values" class="border-b-brand mb-2 pb-4 last:border-0" :key="`item${index}`">
                <div class="f jcs aic">
                    <h4 class="fwb f-30">{{ item.title }}</h4>
                </div>
                <div class="block f-20 !font-light">
                    <span class="w-80">{{ item.description }}</span>
                </div>
            </div>
        </div>
    </UICollapsableSection>
    <UIWrapperText v-else :title="title">
        <div class="grid grid-cols-1 gap-8">
            <div>
                <span class="f-34 !leading-normal block" v-if="ethos.introduction">{{ ethos.introduction }}</span>
            </div>
            <div class="f fc gap-4">
                <div v-for="(item, index) in values" class="border-b-brand mb-2 pb-4 last:border-0" :key="`item${index}`">
                    <div class="f jcs aic">
                        <h4 class="fwb f-30">{{ item.title }}</h4>
                    </div>
                    <div class="block f-20 !font-light">
                        <span class="w-80">{{ item.description }}</span>
                    </div>
                </div>
                <slot></slot>
            </div>
        </div>
    </UIWrapperText>
</template>
<script>
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";

export default {
    name: "BrandEthos",
    components: { UICollapsableSection, UIWrapperText },
    props: {
        ethos: {},
        title: {
            type: String,
            default: "Brand Ethos",
        },
        collapse: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        values() {
            return this.ethos?.values || this.ethos?.attributes || this.ethos?.rules || [];
        },
    },
};
</script>
