// getCopyGuideMixin.js
import { mapGetters } from "vuex";
import personaMixin from "@/mixins/ai/PersonaMixin";
import copyGuideContinuedMixin from "@/mixins/ai/CopyGuideContinuedMixin";
export default {
    mixins: [personaMixin, copyGuideContinuedMixin],
    data() {
        return {};
    },
    computed: {
        ...mapGetters("stream", ["messages"]),
    },
    methods: {
        beforeStarting() {},
        whileProcessing(json) {},
        afterCompletion(obj) {
            let { save, result } = obj;
            console.log("After completion called", obj);
            this.$store.dispatch("styleGuide/saveStyleGuide"); // the generativeMixin will call this after the stream is complete.
        },
        async getCopyGuide(input, overrides) {
            this.updateStyleGuideProp("copyGuidelines", {});
            this.setActionBrandVoice();
            await this.$nextTick();
            let model = gpt4;
            model = gpt316;
            let { name, type } = this.selectedAction; // get the name and type of the selected action. The prompt will be automatically added via the streamFunction(obj) method. // set the action to get_brand_voice. This sets the prompt and the function_call as well.
            let mods = {
                model, //"gpt-3.5-turbo-1106", // this is the model that will be used to generate this.
                // model: gpt4, // this is the model that will be used to generate this.
                messages: deepCopy(this.messages), // this is the messages that will be used to generate this.
                temperature: 0, // this is the temperature that will be used to generate this.
                function_call: name, // this is the function_call name that will be called to generate this. It needs to match the fucntion being called either locally or on the server.
                functions: undefined, // this is the functions object that will be used  to generate this. If blank it will use the server and name to route to the right function if it exists.
                length: 3000, // the length of the message to generate
                persistChat: true, // if true, it streams the result to the messages array in the stream store. This can be used to see the streaming results in chat, but it can also be used to save the results to the database, "chat" add revisions with context, etc.
                identifier: "copyGuidelines", // this helps route the stream to the right place and find it later. Good for complex objects and nested sections. For example: 'campaignData.result.schedule_items.${campaignIndex}.campaign_items.${itemIndex}.result'. It is also possible to set a custom identifier for the stream location.
                brand: this.$route.params.id, // set the brand id from the route params but this could be set from anywhere optionally.
                input: input, // this is the user input that will added to messages.
                type: type, // what type of campaign is this? Currently only used for campaigns.
                storeKey: "styleGuide", // This is the key of the store to save to. For example $store.state.styleGuide
                prop: "copyGuidelines", // the prop used in the store
                // localHandle: "copyGuidelines", //the prop used in the local component
                save: true, // if true, use the afterCompletion function to define how to save the results.
                returnTo: "result",
            };
            if (overrides) mods = { ...mods, ...overrides };

            return await this.callFunction(mods);
        },
    },
};
