<template>
    <div>
        <div class="flight-results animated fadeInUpSmooth border-light br-10 duration-5 my-3 bg-snow pb-2 pt-3 dark:bg-base-700/50">
            <p class="fwb px-3">
                <i class="fas fa-plane text-blue small relative mr-2 rotate-90"></i>
                Flights
            </p>
            <div class="f fc">
                <div v-for="(flight, index) in flights" :key="index" class="f fc border-bottom px-3 py-2 last:border-none">
                    <a v-if="flight.link" :href="flight.link" target="_blank" rel="noopener noreferrer" class="text-inherit hover:text-blue-800">
                        <div class="f jcs aic gap-4" v-if="flight.info">
                            <div v-if="flight.image">
                                <img :src="flight.image" :alt="'Flight icon ' + (index + 1)" class="c-20 border-light o-cover mx-auto h-[16] w-[16]" />
                            </div>
                            <div class="f jcb x aic">
                                <div v-for="(info, i, key) in flight.info" class="x" :key="i">
                                    <span :class="{ 'font-bold': i === 0, 'o-8 text-uppercase x d-block f-15 text-right': i > 0 }">{{ info }}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RelatedQuestions from "@/components/chat/specialMessages/Search/RelatedQuestions.vue";
import FlightInfo from "@/components/chat/specialMessages/Search/FlightInfo.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import DisplayTable from "@/components/chat/specialMessages/Search/DisplayTable.vue";

export default {
    name: "FlightResults",
    components: { DisplayTable, BaseButton, FlightInfo, RelatedQuestions },
    data() {
        return {};
    },
    props: {
        flights: {
            type: Array,
            default: () => [],
        },
        // answer: {
        //     type: Object,
        //     default: () => ({
        //         type: "",
        //         title: "",
        //         ad: "",
        //         flights: [],
        //         link: "",
        //         search_information: {},
        //         cheapest_flights: [],
        //     }),
        // },
    },
};
</script>
