function generateSchema(schema) {
    if (!schema || typeof schema !== "object") {
        return null;
    }

    if (schema.type === "string") {
        return schema.example || ""; // use example if available
    }

    if (schema.type === "number") {
        return schema.example || null; // use example if available, or null to denote an empty number field
    }

    if (schema.type === "object") {
        const obj = {};
        if (schema.properties) {
            for (const [key, value] of Object.entries(schema.properties)) {
                obj[key] = generateSchema(value);
            }
        }
        return obj;
    }

    if (schema.type === "array") {
        if (schema.items) {
            return [generateSchema(schema.items)]; // Generate one example item
        }
        return [];
    }

    if (schema.value !== undefined) {
        return schema.value;
    }

    return null; // Default case
}

// Starting point function to generate example from the whole schema
function generateExampleObjectFromSchema(rootSchema) {
    if (!rootSchema || !rootSchema.parameters || !rootSchema.parameters.properties) {
        console.error("Invalid root schema");
        return null;
    }
    return generateSchema(rootSchema.parameters);
}

export default generateExampleObjectFromSchema;
