<template>
    <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">{{ label }}</label>

        <label class="block text-gray-700 text-sm font-bold mb-2">
            {{ field.label }}
        </label>
        <!--        <input v-if="field.type === 'text'" :id="field.id" :type="field.type" :placeholder="field.placeholder" :value="value" @input="$emit('update:value', $event.target.value)" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />-->

        <input v-if="type === 'text'" :placeholder="placeholder" :value="value" @input="$emit('update:modelValue', $event.target.value)" @blur="validateInput" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        <select v-else-if="type === 'select'" :value="value" @change="$emit('update:modelValue', $event.target.value)" @blur="validateInput" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
            <option v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</option>
        </select>
        <!-- Additional input types like 'number', 'email', etc., go here -->
    </div>
</template>

<script>
export default {
    name: "FormField",
    props: {
        label: { String },
        type: String,
        placeholder: String,
        options: Array,
        modelValue: [String, Number],
        validation: Object,
    },
    emits: ["update:value"],
    methods: {
        validateInput() {
            // Perform input validation
            if (this.validation.required && !this.modelValue) {
                this.$emit("validation-error", "This field is required");
            } else {
                this.$emit("validation-error", null);
            }
        },
    },
};
</script>
