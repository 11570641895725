<template>
    <div class="py-3 scrollbar-hide z-4 x text-center" :class="{ 'bottom absolute': absolute }" :style="`height: ${height}px`">
        <div class="scrollbar-hide rounded-full shadow inline-flex mx-auto overflow-scroll relative p-1 bg-gray-50 gap-1" :class="{ 'mw-350': mobile, 'mw-600': !mobile }">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "PickerContainer",
    props: {
        fixed: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: 50,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
