<template>
    <li class="py-2 sm:py-4">
        <div class="max-w-4xl px-4 sm:px-6 lg:px-8 mx-auto">
            <div class="max-w-2xl flex gap-x-2 sm:gap-x-4">
                <span class="flex-shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                    <span class="text-sm font-medium text-white leading-none">AZ</span>
                </span>
                <div class="grow mt-2 space-y-3">
                    <Markdown :content="text"></Markdown>
                    <!--                    <p class="text-gray-800 dark:text-gray-200">{{ text }}</p>-->
                    <SpecialMessages :message="message" />
                </div>
            </div>
        </div>
    </li>
</template>
<script>
import MessageFileUploads from "@/views/MessageFileUploads.vue";
import SpecialMessages from "@/views/SpecialMessages.vue";
import Markdown from "@/components/chat/specialMessages/Markdown.vue";

export default {
    name: "MessageUserMessage",
    components: { Markdown, SpecialMessages, MessageFileUploads },
    props: {
        message: {
            type: Object,
            default: () => ({}),
        },
        files: {
            type: Array,
            default: () => [
                {
                    name: "file1.jpg",
                    url: "https://via.placeholder.com/150",
                },
                {
                    name: "file2.jpg",
                    url: "https://via.placeholder.com/150",
                },
            ],
        },
        text: {
            type: String,
            default: "Hello, how can I help you?",
        },
    },
};
</script>
