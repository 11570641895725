import { mapActions } from "vuex";
export default {
    created: function () {},
    mounted() {},
    data() {
        return {
            newTask: "",
            task_manager: {
                name: "Manage Tasks",
                command: "task_manager",
                start: "[TASK]",
                end: "[TASK]",
                kind: "TaskManager",
                role: "assistant",
                description: "Manage Tasks",
                show: true,
                newMessage: false,
                resources: ["Add tasks to your tasks list", "Remove tasks from your tasks list"],
            },
        };
    },
    computed: {
        tasks() {
            return this.$store.state.chat.tasks.tasks;
        },
        tasksString() {
            const tasks = this.$store.state.chat.tasks.tasks;
            let string = "";
            if (tasks && tasks.length > 0) {
                string += this.headerString("Tasks");
                string += this.arrayToNumberedList(tasks);
            }
            // string = `\n\nTASKS:\n`;

            return `${string}`;
        },
    },
    methods: {
        async taskManager(args, name, message, index) {
            let tasks = args.task || args.tasks; // Use args.tasks instead of arg.tasks
            // split tasks into array
            if (typeof tasks === "string" && tasks.includes(",")) {
                tasks = tasks.split(",");
                tasks = tasks.map(task => task.trim());
            } else if (Array.isArray(tasks)) {
                // Use Array.isArray to check if variable is an array
                // tasks = tasks; // Not necessary as it's already set
            } else {
                // tasks = task; // task is not defined, replace it with tasks
                tasks = [tasks];
            }
            if (args.operation === "add") {
                for (let task of tasks) {
                    await this.$store.dispatch("chat/tasks/addTaskToStore", task);
                }
            } else if (args.operation === "remove") {
                if (tasks && tasks.includes("all")) {
                    await this.$store.dispatch("chat/tasks/removeAllTasks");
                }
                for (let task of tasks) {
                    await this.$store.dispatch("chat/tasks/removeTaskFromStore", task);
                }
            }
            let content = this.$store.state.chat.tasks.tasks;
            return { tasks: content, operation: args.operation };
        },
        async addTask() {
            if (this.newTask.trim()) {
                await this.$store.dispatch("chat/tasks/addTaskToStore", this.newTask);
                this.newTask = "";
            }
        },
        async removeTask(task) {
            await this.$store.dispatch("chat/tasks/removeTaskFromStore", task);
        },
        // ...mapActions("chat", ["addToMemoryStore"]),
    },
};
