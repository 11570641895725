import { db } from "@/firebase";
import { query, collection, where, orderBy, onSnapshot, addDoc, limit, startAfter, doc, Timestamp, getDoc, getDocs } from "firebase/firestore";

export default {
    namespaced: true,
    state() {
        return {
            tasks: [],
        };
    },
    mutations: {
        addTask(state, payload) {
            state.tasks.push(payload);
        },
        removeTask(state, payload) {
            const index = state.tasks.indexOf(payload);
            if (index > -1) {
                state.tasks.splice(index, 1);
            }
        },
        setTasks(state, payload) {
            state.tasks = payload;
        },
    },
    actions: {
        async addTaskToStore({ commit, state, rootState, dispatch }, task) {
            try {
                if (state.tasks && state.tasks.length > 0) {
                } else {
                    await dispatch("fetchTasks");
                }

                const chatRef = await db.collection("chats").doc(rootState.chat.id);
                const updatedTasks = [...state.tasks, task];

                await chatRef.update({ tasks: updatedTasks });
                commit("addTask", task);
                // console.log("Task added successfully!");
            } catch (error) {
                console.error("Error adding task: ", error);
            }
        },
        async removeTaskFromStore({ commit, state, rootState, dispatch }, task) {
            try {
                if (state.tasks && state.tasks.length > 0) {
                } else {
                    await dispatch("fetchTasks");
                }

                const chatRef = await db.collection("chats").doc(rootState.chat.id);
                const updatedTasks = state.tasks.filter(t => t !== task);

                await chatRef.update({ tasks: updatedTasks });
                commit("removeTask", task);

                // console.log("Task removed successfully!");
            } catch (error) {
                console.error("Error removing task: ", error);
            }
        },
        // remove all tasks from store
        async removeAllTasks({ commit, state, rootState, dispatch }) {
            try {
                if (state.tasks && state.tasks.length > 0) {
                } else {
                    await dispatch("fetchTasks");
                }

                const chatRef = await db.collection("chats").doc(rootState.chat.id);
                const updatedTasks = [];

                await chatRef.update({ tasks: updatedTasks });
                commit("setTasks", updatedTasks);

                console.log("All tasks removed successfully!");
            } catch (error) {
                console.error("Error removing all tasks: ", error);
            }
        },
        async fetchTasks({ commit, state, rootState }) {
            try {
                const chatRef = await db.collection("chats").doc(rootState.chat.id);
                const doc = await chatRef.get();

                if (!doc.exists) {
                    console.log("No such document!");
                } else {
                    commit("setTasks", doc.data().tasks || []);
                    console.log("Tasks fetched successfully!");
                }
            } catch (error) {
                console.error("Error fetching tasks: ", error);
            }
        },
    },
    getters: {
        tasks: state => state.tasks,
    },
};
