<template>
    <UIWrapperText v-if="processedSection" :title="processedSection.name || 'Overall'">
        <div v-if="processedSection" class="mb-5 border-bottom">
            <!--            <h2 class="mb-4 text-capitalize">{{ processedSection.name || "Loading ..." }}</h2>-->
            <div v-if="Object.keys(processedSection.tone).length" class="col-span-12 md:col-span-2 md:p-3">
                <h4 class="md:mb-3">Tone</h4>
                <PercentageBarDisplay v-for="(trait, index) in processedSection.tone" :key="trait.name" :index="index + 2" :name="trait.name" :value="trait.value" />
            </div>
            <!--                <div class="col-span-10 grid grid-cols-10"></div>-->
            <CopyGuidelinesExamples class="col-span-12 md:col-span-6" v-if="processedSection?.examples?.length" :color="color" :examples="processedSection?.examples" title="Examples" />
            <div class="grid grid-cols-2">
                <DosDontsList v-if="processedSection.guidelines?.length > 0" :list="processedSection.guidelines" title="Do's" type="dos" />
                <DosDontsList v-if="processedSection?.avoid?.length > 0" :list="processedSection.avoid" title="Dont's" type="donts" />
            </div>
            <template v-if="hasSlotContent">
                <slot></slot>
            </template>
        </div>
    </UIWrapperText>
    <!--        <div :style="`background:${background};color:${color};`" class="p-4 border-light br-20 mb-5 bg-gray-900/10 bg-blur">-->
    <!--            <div class="f fc md:gap-5">-->
    <!--                <div v-if="Object.keys(processedSection.tone).length" class="col-span-12 md:col-span-2 md:p-3">-->
    <!--                    <h4 class="md:mb-3">Tone</h4>-->
    <!--                    <PercentageBarDisplay v-for="(trait, index) in processedSection.tone" :key="trait.name" :index="index + 2" :name="trait.name" :value="trait.value" />-->
    <!--                </div>-->
    <!--                &lt;!&ndash;                <div class="col-span-10 grid grid-cols-10"></div>&ndash;&gt;-->
    <!--                <CopyGuidelinesExamples class="col-span-12 md:col-span-6" v-if="processedSection?.examples?.length" :color="color" :examples="processedSection?.examples" title="Examples" />-->
    <!--                <div class="col-span-10 grid grid-cols-12">-->
    <!--                    <template v-if="hasSlotContent">-->
    <!--                        <slot></slot>-->
    <!--                    </template>-->
    <!--                    <DosDontsList v-if="processedSection.guidelines?.length > 0" :list="processedSection.guidelines" class="col-span-12 md:col-span-3" title="Do's" type="dos" />-->
    <!--                    <DosDontsList v-if="processedSection?.avoid?.length > 0" :list="processedSection.avoid" class="col-span-12 md:col-span-3" title="Dont's" type="donts" />-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            &lt;!&ndash;            <div class="grid grid-cols-12 md:gap-5">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div v-if="Object.keys(processedSection.tone).length" class="col-span-12 md:col-span-2 md:p-3">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <h4 class="md:mb-3">Tone</h4>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <PercentageBarDisplay v-for="(trait, index) in processedSection.tone" :key="trait.name" :index="index + 2" :name="trait.name" :value="trait.value" />&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                &lt;!&ndash;                <div class="col-span-10 grid grid-cols-10"></div>&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-span-10 grid grid-cols-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <CopyGuidelinesExamples class="col-span-12 md:col-span-6" v-if="processedSection?.examples?.length" :color="color" :examples="processedSection?.examples" title="Examples" />&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <template v-if="hasSlotContent">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <slot></slot>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <DosDontsList v-if="processedSection.guidelines?.length > 0" :list="processedSection.guidelines" class="col-span-12 md:col-span-3" title="Do's" type="dos" />&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <DosDontsList v-if="processedSection?.avoid?.length > 0" :list="processedSection.avoid" class="col-span-12 md:col-span-3" title="Dont's" type="donts" />&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--        </div>-->
    <!--    </div>-->
</template>
<script>
import PercentageBarDisplay from "@/components/CoreUI/PercentageBarDisplay.vue";
import DosDontsList from "@/components/generative_templates/Guidelines/DosDontsList.vue";
import { mapGetters } from "vuex";
import CopyGuidelinesExamples from "@/components/generative_templates/Guidelines/CopyGuidelinesExamples.vue";
import StyleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";

export default {
    name: "CopyGuideSection",
    components: { UIWrapperText, CopyGuidelinesExamples, DosDontsList, PercentageBarDisplay },
    mixins: [StyleGuideMixin],
    props: {
        section: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters("styleGuide", ["divStyles", "backgroundColors", "baseColor"]),
        color() {
            return chroma(this.baseColor).alpha(1).css();
        },
        background() {
            let background = this.backgroundColors?.[0];
            return chroma(background).alpha(1).css();
        },
        colorOpacity() {
            let color = this.color;
            return chroma(color).alpha(0.1).css();
        },
        hasSlotContent() {
            return !!this.$slots.default;
        },
        processedSection() {
            if (!this.section || !this.section.name) {
                return null;
            }

            const { name, tone, examples, guidelines, avoid } = this.section;

            // Initialize the processed object with default values
            const processed = {
                name: "",
                tone: {},
                examples: [],
                guidelines: [],
                avoid: [],
            };

            // Validate and set each property
            if (name && typeof name === "string") {
                processed.name = name;
            }

            if (Array.isArray(tone)) {
                processed.tone = tone;
            }

            if (Array.isArray(examples)) {
                processed.examples = examples;
            }

            if (Array.isArray(guidelines)) {
                processed.guidelines = guidelines;
            }

            if (Array.isArray(avoid)) {
                processed.avoid = avoid;
            }

            return processed;
        },
    },
};
</script>
<style lang="scss" scoped>
h3 {
    font-size: 2rem !important;
}

strong {
    font-weight: 500 !important;
}

ol > div {
    counter-increment: list-counter;
}

ol > div > li {
    position: relative;
    padding-left: 2em;
    list-style-type: none;

    &::before {
        font-size: 0.8rem;
        line-height: 1.5em;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.5em;
        height: 1.5em;
        margin-right: 30px;
        @apply text-base-500;
        content: counter(list-counter);
        transform: translateY(-50%);
        @apply border-base-900/10;
        @apply bg-base-900/5;
        text-align: center;
        border: 1px solid;
        border-radius: 50%;
    }
}

p {
    font-size: 1rem !important;
    font-weight: normal !important;
    line-height: normal !important;
}

/* Include your styles here */
</style>
