<template>
    <div>
        <!--        <pre class="bg-snow z-10 fixed p-fill vh-100 overflow-y-scroll">{{ campaign }}</pre>-->
        <Transition name="slide-right">
            <div v-if="id" class="mw-350 bg-snow vh-100 width-min-300 fixed right top bottom mt-nav flex-shrink-0">
                <div v-if="id" class="scrollSection">
                    <CurrentlyEditingBar :key="id" :currently-editing="campaign" :id="id">
                        <div class="f fc y">
                            <div class="y self-stretch overflow-y-scroll scrollbar-hide">
                                <MessageViewer :key="id" :chat="messagesToShow"></MessageViewer>
                            </div>
                            <div class="px-2 pb-2 border-top flex-shrink-0 mb-8">
                                <SimpleTextarea v-model="inputText" inline placeholder="Send a message" @keydown.enter="sendCampaignMessage"></SimpleTextarea>
                            </div>
                        </div>
                    </CurrentlyEditingBar>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>
import CurrentlyEditingBar from "@/components/elements/CurrentlyEditingBar.vue";
import MessageViewer from "@/components/Admin/MessageViewer.vue";
import SimpleTextarea from "@/views/SimpleTextArea.vue";
import streamCompletion from "@/mixins/ai/stream_completion";
import saveToFirebase from "@/mixins/firebase/saveToFirebase";

export default {
    name: "CurrentlyEditing",
    components: { CurrentlyEditingBar, MessageViewer, SimpleTextarea },
    props: {
        id: { type: String, default: "" },
    },
    data() {
        return {
            inputText: "",
        };
    },
    methods: {
        showCurrentlyEditing(id) {
            console.log(id);
        },
        async sendCampaignMessage({ id, message }) {
            if (id && message) {
                let messages = this.messagesToShow;
                messages.push(userMessage(message));
                this.updateStreamProp(`editor.${id}.messages`, messages);
                let responseText = "";
                let function_call = this.campaign?.function_call;
                let function_response;
                await streamCompletion(
                    this.$store,
                    message,
                    messages,
                    undefined,
                    () => {
                        messages.push(aiMessage(""));
                        this.updateStreamProp(`editor.${id}.messages`, messages);
                    },
                    (token, json, data) => {
                        console.log("token", token);
                        console.log("data", data);
                        let messagesLength = messages.length;
                        responseText += token;
                        this.updateStreamProp(`editor.${id}.messages.${messagesLength - 1}`, json);
                        if (data) this.updateStreamProp(`editor.${id}.result`, data);
                        function_response = data;
                    },
                    async () => {
                        messages[messages.length - 1] = {
                            role: "assistant",
                            content: "Here you go",
                            function_call: { name: function_call, arguments: JSON.stringify(function_response) },
                        };
                        this.updateStreamProp(`editor.${id}.messages`, messages);
                        let campaign = this.$store.getters["stream/editor"][id];
                        await saveToFirebase("campaigns", campaign, id);
                        // messages.push(aiMessage("I'm sorry, I don't understand."));
                        // this.updateStreamProp(`editor.${id}.messages`, messages);
                    },
                    gpt3,
                    undefined,
                    "campain chat",
                    0,
                    undefined,
                    function_call,
                    3000,
                );
                this.inputText = "";
            }
        },
    },
    computed: {
        messagesToShow() {
            if (this.campaign?.messages) return this.campaign.messages || [];
            if (this.$store.getters["stream/messages"]?.length > 0) return this.$store.getters["stream/messages"];
            else return [];
        },
        campaign() {
            if (this.id) {
                let id = this.id;
                return this.$store.getters["stream/editor"][id];
            }
            return {};
        },
    },
};
</script>
