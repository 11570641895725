<template>
    <div class="border-b-brand" :class="{ 'py-3': !small }">
        <div class="relative f jcb aic" :class="{ '': !isOpen }" @click="isOpen = !isOpen">
            <div class="p-2 f gap-3 aic border-bottom x" v-if="left" :class="`ml-${depth * 1}`">
                <div class="w-max-[15px] w-[15px] w-min-[15px]">
                    <i v-if="isOpen" class="fal mb-0" :class="[openIcon, { 'fa-xl': !small, 'f-17': small }]"></i>
                    <i v-else class="fal fa-xl mb-0" :class="[closeIcon, { 'fa-xl': !small, 'f-17': small }]"></i>
                </div>
                <span :class="{ 'f-30': !small }" class="mb-0 block !font-light"><slot name="title"></slot></span>
                <div v-if="count" class="rounded-full f-13 fwb text-green-500 min-w-[17px] h-[17px] bg-green-100/75 f aic jcc" :class="{ 'px-2': count > 9 }">{{ count }}</div>
            </div>
            <template v-if="!left">
                <span :class="{ 'f-30': !small }" class="mb-0 block !font-light"><slot name="title"></slot></span>
                <div class="p-2 f gap-3 aic">
                    <div v-if="count" class="rounded-full f-13 fwb text-green-500 min-w-[17px] h-[17px] bg-green-100/75 f aic jcc" :class="{ 'px-2': count > 9 }">{{ count }}</div>
                    <i v-if="isOpen" class="fal mb-0" :class="[openIcon, { 'fa-xl': !small, 'f-20': small }]"></i>
                    <i v-else class="fal fa-xl mb-0" :class="[closeIcon, { 'fa-xl': !small, 'f-20': small }]"></i>
                </div>
            </template>
        </div>
        <Transition name="fade-up">
            <div v-show="isOpen" class="my-0" :class="{ '': !inline, '': rounded, 'mb-4': bottomClose, 'pb-2': !small }" :style="maxHeightStyle">
                <slot></slot>
            </div>
        </Transition>
        <!--        <div v-if="isOpen && bottomClose" class="z-5 pb-2 justify-fixed-center absolute left right text-center bottom x" @click="isOpen = !isOpen"><div class="z-1 p-3 bottom c-30 f aic jcc bg-red mx-auto"><i v-if="isOpen" class="fas fa-times mb-0"></i></div></div>-->
    </div>
</template>

<script>
export default {
    name: "UICollapsableSection",
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        font: {
            type: [Boolean, Object],
            default: false,
        },
        bottomClose: {
            type: Boolean,
            default: false,
        },
        depth: {
            type: Number,
            default: 0,
        },
        count: {
            type: Number,
            default: 0,
        },
        small: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        openIcon: {
            type: String,
            default: "fa-angle-down",
        },
        closeIcon: {
            type: String,
            default: "fa-angle-right",
        },
        maxHeight: {
            type: [String, Number],
            default: "",
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        maxHeightStyle() {
            if (this.maxHeight) return `max-height: ${this.maxHeight}px;overflow-y:scroll;`;
            return "";
        },
    },
    mounted() {
        if (this.open) {
            this.isOpen = true;
        }
    },
};
</script>

<style scoped lang="scss">
.collapse-enter-active,
.collapse-leave-active {
    transition: all 0.3s ease;
}
.collapse-enter-from,
.collapse-leave-to {
    height: 0;
    opacity: 0;
}
.collapse-enter-to,
.collapse-leave-from {
    height: auto;
    opacity: 1;
}
</style>
