<template>
    <div>
        <StyleGuideContainer class="gap-4" title="Section styles" section="divs">
            <div class="col-span-12" id="divSection">
                <ThemeSamplesPicker v-if="divStyles?.length" class="md:px-5">
                    <ThemeSamples class="x br-10"></ThemeSamples>
                </ThemeSamplesPicker>
            </div>
        </StyleGuideContainer>
    </div>
</template>
<script>
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import StyleGuideDivTheme from "@/components/styleGuide/Sections/Themes/StyleGuideDivTheme.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import ThemeSamples from "@/components/styleGuide/Sections/Themes/ThemeSamples.vue";
import ThemeSamplesPicker from "@/components/styleGuide/Sections/Themes/ThemeSamplesPicker.vue";

export default {
    name: "StyleGuideDivs",
    components: { StyleGuideContainer, StyleGuideDivTheme, ThemeSamples, ThemeSamplesPicker },
    mixins: [styleGuideMixin],
};
</script>
<!--                    <div v-for="theme in styleGuide.divStyles" class="col-span-4" :key="randomId()">-->
<!--                        <div class="aspect-h-1 aspect-w-1">-->
<!--                            <div class="f aic jcc p-3 text-center">-->
<!--                                <StyleGuideDivTheme v-if="theme && theme.color" :description="headerSample" :theme="theme" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
