<template>
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-3">
        <div class="flex h-16 shrink-0 items-center">
            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company" />
        </div>
        <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                            <a
                                :href="item.href"
                                :class="[
                                    item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                ]">
                                <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                                {{ item.name }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="-mx-6 mt-auto">
                    <a href="#" class="flex items-center gap-x-4 px-3 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800">
                        <img class="h-8 w-8 rounded-full bg-gray-800" :src="user.photoURL" alt="" />
                        <span class="sr-only">Your profile</span>
                        <span aria-hidden="true">Tom Cook</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
export default {
    name: "SideBarTemplate",
    props: {
        navigation: {},
    },
};
</script>
