<template>
    <div class="f jcb aic editor-section">
        <span class="fwb editor-section-title">Image Style</span>
        <div class="f">
            <div class="p-2 border-light width-40 height-40 f aic jcc" :class="{ '': s.id === imageStyle, 'o-5': s.id !== imageStyle }" v-for="s in options" :key="s" @click="updateSection('imageStyle', s.id)">
                <i :class="[`${s.icon} f-17`, { far: s.id !== imageStyle, fas: s.id === imageStyle }]"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ImageStyleEditor",
    props: {
        imageStyle: {},
        identifier: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            options: [
                //
                { icon: "fa-image", id: "normal" },
                { icon: "fa-square", id: "square" },
                { icon: "fa-circle", id: "round" },
                { icon: "fa-panorama", id: "landscape" },
            ],
        };
    },
    methods: {
        updateSection(prop, value) {
            console.log("updateSection", prop, value);
            this.$emit("update-section", prop, value);
            // this.$store.dispatch("stream/updateStreamProp", { keyPath: prop, value });
        },
    },
};
</script>
