<template>
    <div class="mx-auto mt-8 max-w-7xl px-3 sm:mt-56 lg:px-3">
        <div class="mx-auto max-w-2xl text-center">
            <h2 v-if="preheader" :class="`text-lg font-semibold leading-7 text-${color}-400`">{{ preheader }}</h2>
            <p class="mt-2 font-bold tracking-tight text-white text-5xl text-pretty">{{ title }}</p>
            <p class="mt-3 text-lg leading-8 text-gray-300 text-balance">{{ description }}</p>
        </div>
        <div class="mx-auto mt-8 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl class="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-3 jcc">
                <div v-for="feature in features" :key="feature.title" class="flex flex-col">
                    <dt class="text-base font-semibold leading-7 text-white">
                        <div v-motion-fu :class="`relative overflow-hidden mb-3 flex h-10 w-10 items-center justify-center rounded-lg bg-${feature.color}-300/20 bg-gradient-to-b to-green border border-${feature.color}-200/50`">
                            <div class="absolute o-2 mix-blend-screen inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#000_0%,#fff_50%,#000_100%)]" style="z-index: -2"></div>
                            <component :is="feature.icon" :class="`h-6 w-6 text-${feature.color}-200`" aria-hidden="true" />
                        </div>
                        <p v-motion-fu>
                            {{ feature.title }}
                        </p>
                    </dt>
                    <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300 text-pretty">
                        <p v-motion-fu class="flex-auto">{{ feature.description }}</p>
                        <p v-if="feature?.button?.text" class="mt-3">
                            <a v-motion-fu :class="`text-sm font-semibold leading-6 text-${feature.color}-400`" :href="feature.button.link">
                                {{ feature.button.text }}
                                <span aria-hidden="true">→</span>
                            </a>
                        </p>
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</template>
<script>
export default {
    name: "HomeContentSection",
    props: {
        preheader: {
            type: String,
            default: "Our track record",
        },
        title: {
            type: String,
            default: "Trusted by thousands of developers worldwide",
        },
        color: {
            type: String,
            default: "indigo",
        },
        description: {
            type: String,
            default: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        },
        features: {
            type: Array,
            default: () => [
                {
                    title: "Deploy to the cloud",
                    description: "Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.",
                    icon: "CloudArrowUpIcon",
                    color: "blue",
                    button: {
                        text: "Get started",
                        link: "#",
                    },
                },
                {
                    title: "Automate your workflow",
                    description: "Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.",
                    icon: "Cog6ToothIcon",
                    color: "green",
                    href: "#",
                },
                {
                    title: "Secure your data",
                    description: "Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.",
                    icon: "LockClosedIcon",
                    color: "red",
                    href: "#",
                },
            ],
        },
    },
};
</script>
