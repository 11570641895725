<!--text component receiving slot data -->
<template>
    <div :id="identifierId" ref="email-section" class="block section overflow-hidden z-0 !pb-0" style="z-index: 2" @mouseenter="hovered = true" @mouseleave="hovered = false">
        <transition appear enter-active-class="fadeIn height-min-0 z-0 elastic-easing duration-1" leave-active-class="slideOutUp opacity-0 duration-1 ease-back height-0">
            <SectionBlenderNew
                v-if="section.blend"
                key="topgradient"
                :transparent="isTransparent"
                :fullbleed="fullBleed"
                :height="blenderHeight"
                :index="index"
                :section="section"
                :sections="sections"
                :style="`z-index: ${blenderZIndex} !important`"
                class="top left right p-absolute z-0 transition-all duration-2"
                :class="{ '-mt-20': isTucked && !isInset }"></SectionBlenderNew>
        </transition>
        <div
            class="z-10 width-100 height-25 rounded-full mx-auto absolute bottom bg-snow/20 justify-fixed-center mb-2 hover:shadow-lg transition-all hover:bg-snow/50 hover:border-1 !scale-100 duration-1 cursor-pointer"
            style="height: 7px; transform: translateY(30px) translateX(-40%) scale(1.2) !important; z-index: 2001"
            @click="toggleBlendMode()"></div>
        <div v-if="logoHeight" class="h1 z-5 text-red">{{ logoHeight }}</div>
        <div v-if="showLogo" ref="logoContainer" class="p-absolute top right z-6 justify-fixed-center text-center" @click="clickedLogo">
            <BrandLogoComponent :color="textColorOverride" class="mt-3" max-height="75" width="120"></BrandLogoComponent>
        </div>
        <div v-if="section" :class="{ 'pt-6': showLogo && fullBleed, 'pt-6': fullBleed }" :style="imageColorLogic" class="transition-all grid grid-cols-12 relative">
            <Transition appear name="fade-up">
                <template v-if="imageURL && !fullBleed">
                    <div class="col-span-12 transition-all relative">
                        <LandingPageImage v-if="!showImageLoader" :key="`image${index}Left`" ref="section-image" :identifier="identifier" :image="image" :images="images" :index="index" :kind="kind" :relevant-images="relevantImages" :section="section" appear class="block transition-all" @save-section="saveSections">
                            <div>
                                <Transition appear name="fade-scale">
                                    <ImageGradient v-if="showTopGradient" :height="80" :image="{ color: section.backgroundColor }" class="z-1 animated fadeIn" top />
                                </Transition>
                                <!--                                <transition enter-active-class="fadeIn" leave-active-class="fadeOut" appear>-->
                                <Transition appear name="fade-scale">
                                    <ImageGradient v-if="showBottomGradient" :height="80" :image="{ color: imageGradientColor }" class="z-1 animated fadeIn" />
                                </Transition>
                            </div>
                        </LandingPageImage>
                    </div>
                </template>
            </Transition>
            <div :class="textPaddingTop" class="f aic jcc order-1 md:order-0 col-span-12 x">
                <!--            <div :class="textPaddingTop" class="px-5 f aic jcc order-1 md:order-0 col-span-12 x">-->
                <transition appear name="fade-up">
                    <div v-if="!transparent && fullBleed && !isInset && !isRound" :style="`background:${imageTopGradientColor};background: linear-gradient(180deg, ${imageTopGradientColor} 500px, ${imageGradientColor} 50%)`" class="p-absolute top left right h-100"></div>
                </transition>
                <div :style="[textColorOverride]" class="z-2">
                    <EmailImageLoader v-if="showImageLoader && !fullBleed && !products" :section="section" class="x width-500" />
                    <transition appear name="fade-scale">
                        <ImageGradient v-if="showFullBleedGradient" :image="{ color: imageGradientColor }" class="z-1 x y" radial />
                    </transition>
                    <div :class="textAlignClass" class="px-5 z-2 relative mx-auto x">
                        <!--                        <HeaderText :class="headerComputedClass" :default-size="headerSize" :font="font" :font-override="sectionHeaderFont" :full-bleed="fullBleed" :identifier="identifier" :index="index" :section="section" :text="header" :text-color-override="textColorOverride" class="z-5 mw-450 break-words" @save-section="saveSections" />-->

                        <div class="f fc">
                            <div v-if="preheader" class="mx-auto block relative mt-5" style="max-width: 95vw">
                                <EditableText
                                    key="preheader"
                                    :class="[{ 'mb-4 z-10 relative': section.features }, textClasses.preheader]"
                                    :default-size="preheaderSize"
                                    :font-override="sectionSubheaderFont"
                                    :identifier="identifier"
                                    :index="index"
                                    :max-size="40"
                                    :min-size="11"
                                    :section="section"
                                    :section-font="font"
                                    :text="preheader"
                                    :text-color-override="textColorOverride"
                                    hovered-class="mb-0 transition-all"
                                    text-type="preheader"
                                    @save-section="saveSections"></EditableText>
                            </div>
                            <div class="mx-auto block relative px" style="max-width: 95vw">
                                <EditableText
                                    key="header"
                                    :class="[textClasses.header, headerComputedClass]"
                                    :default-size="headerSize"
                                    :font-override="sectionHeaderFont"
                                    :full-bleed="fullBleed"
                                    :identifier="identifier"
                                    :index="index"
                                    :max-size="120"
                                    :min-size="30"
                                    :section="section"
                                    :section-font="font"
                                    :text="header"
                                    :text-color-override="textColorOverride"
                                    hovered-class="mb-0 transition-all"
                                    text-type="header"
                                    @save-section="saveSections"></EditableText>
                            </div>
                            <!--                        <SubheaderText :class="{ 'mb-4': section.features }" :default-size="subheaderSize" :font="font" :font-override="sectionSubheaderFont" :identifier="identifier" :index="index" :section="section" :text="subheader" :text-color-override="textColorOverride" class="fwb z-2" @save-section="saveSections" />-->
                            <div class="o-8 z-10 relative transition-all elastic-easing duration-3">
                                <!--                            <div :class="{ 'mb-5 -mt-3': fullBleed && !button && isInset, '-mb-6 -mt-3': fullBleed && !isInset && !button }" class="o-8 z-10 relative transition-all elastic-easing duration-3">-->
                                <EditableText
                                    key="subheader"
                                    :class="[{ 'mb-4 z-10 relative': section.features }, textClasses.subheader]"
                                    :default-size="subheaderSize"
                                    :font-override="sectionSubheaderFont"
                                    :identifier="identifier"
                                    :index="index"
                                    :max-size="40"
                                    :min-size="11"
                                    :section="section"
                                    :section-font="font"
                                    :text="subheader"
                                    :text-color-override="textColorOverride"
                                    hovered-class="mb-0 transition-all"
                                    text-type="subheader"
                                    @save-section="saveSections"></EditableText>
                            </div>
                        </div>

                        <template v-if="fullBleed">
                            <SectionButton :button-css="buttonCss" :button-text="button" :class="{ 'mt-4': !subheader, 'mt-0 pt-0': subheader }" :default-size="buttonSize" :identifier="identifier" :image-color="imageColor" :index="index" :section="section" class="z-4" @save-section="saveSections" />
                            <div class="z-1 transition-all x" :class="{ 'width-min-500': !mobile, 'width-min-450': mobile }" style="z-index: 0; margin: 0px -40px">
                                <!--                                <transition name="fade-in">-->
                                <!--                                    <EmailImageLoader class="x width-500" v-if="showImageLoader && fullBleed" :section="section" />-->
                                <!--                                </transition>-->
                                <transition name="fade-up">
                                    <LandingPageImage
                                        v-if="!showImageLoader"
                                        :key="`image${index}Left`"
                                        ref="section-image"
                                        :class="{ 'mb-5': !isTransparent && hideGradient, 'mb-3': !isTransparent && !hideGradient, 'mb-0': isTransparent }"
                                        :identifier="identifier"
                                        :image="image"
                                        :images="images"
                                        :index="index"
                                        :kind="kind"
                                        :relevant-images="relevantImages"
                                        :section="section"
                                        appear
                                        class="block transition-all z-0"
                                        max-height="32"
                                        @save-section="saveSections">
                                        <Transition name="fade-scale">
                                            <div v-if="showTopGradient || showBottomGradient">
                                                <ImageGradient v-if="showTopGradient" :height="isTucked ? 150 : 80" :image="{ color: imageTopGradientColor || imageGradientColor }" class="z-1" top />
                                                <ImageGradient v-if="showBottomGradient" :height="80" :image="{ color: imageGradientColor }" class="z-1" />
                                            </div>
                                        </Transition>
                                    </LandingPageImage>
                                </transition>
                            </div>
                        </template>
                        <FeaturesSection v-if="section.features" :default-size="section.bodySize" :font="font" :full-bleed="fullBleed" :identifier="identifier" :index="index" :section="section" :text-align="textAlign" :text-color-override="textColorOverride" class="x" :class="{ 'pb-5': fullBleed }" @save-section="saveSections" />
                        <EditableText
                            key="body"
                            :class="textClasses.body"
                            :default-size="bodySize"
                            :font-override="sectionBodyFont"
                            :identifier="identifier"
                            :index="index"
                            :max-size="30"
                            :min-size="15"
                            :section="section"
                            :section-font="font"
                            :text="body"
                            :text-color-override="textColorOverride"
                            class="mx-auto"
                            hovered-class="!mb-8 transition-all block"
                            style="max-width: 95vw"
                            text-type="body"
                            @save-section="saveSections"></EditableText>
                        <!--                        <BodyText :default-size="bodySize" :font="font" :font-override="sectionBodyFont" :identifier="identifier" :index="index" :section="section" :text="body" :text-color-override="textColorOverride" class="mb-5 z-1" @save-section="saveSections" />-->
                        <SectionButton v-if="!fullBleed" key="bottombutton" :button-text="button" :css="buttonCss" :default-size="buttonSize" :identifier="identifier" :image-color="imageColor" :index="index" :section="section" class="z-0 pb-6 pt-3" @save-section="saveSections" />
                        <div v-if="imageData" class="visually-hidden">{{ imageData }}</div>
                    </div>
                    <EmailSectionProducts ref="section-products" v-if="products?.length > 0" :images="images" :products="products" />
                </div>
            </div>
            <!--            <FeaturesSection v-if="section.type === 'features'" :font="font" :section="section" :default-font-size="20" :full-bleed="fullBleed" :text-color-override="textColorOverride" />-->

            <div :class="{ 'left right': imageLeft, 'left right': imageRight }" class="p-absolute bottom p-3 z-3 x justify-fixed-center mw-300 z-7" @mouseleave="showSettings = false">
                <Transition appear name="fade-up">
                    <div v-if="showSettings" class="pb-5 p-absolute bottom z-4 mx-auto left right x">
                        <SectionSettings :colors="colors" :fonts="fonts" :identifier="identifier" :index="index" :section="section" class="mx-auto z-7" @update-section-color="updateSectionColor" @save-section="saveSections">
                            <DebugObject :object="section" class=""></DebugObject>
                        </SectionSettings>
                    </div>
                </Transition>
                <SectionToolButtons :hovered="hovered" @remove-section="removeSection" @show-email-modal="showEmailModal" @show-settings="showSettings = true" />
            </div>
            <NewSectionModal v-if="showPrompt" @add-section="addSectionWithAI" />
            {{ campaignContext }}
        </div>
        <slot :section="section"></slot>
        <!--        <pre class="my-5" v-for="c in campaignStrings">{{ c }}</pre>-->
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import ImageGradient from "@/components/generative_templates/ImageGradient.vue";
import LandingPageImage from "@/components/generative_templates/Pages/LandingPageImage.vue";
import SectionSettings from "@/components/generative_templates/Editing/SectionSettings.vue";
import styleGuideSettingsMixin from "@/mixins/StyleGuideMixins/StyleGuideSettingsMixin";
import SectionButton from "@/components/generative_templates/Editing/SectionButton.vue";
import BrandLogoComponent from "@/components/styleGuide/Images/BrandLogoComponent.vue";
import SectionStyleMixin from "@/mixins/StyleGuideMixins/SectionStyleMixin";
import FeaturesSection from "@/components/generative_templates/Email/FeaturesSection.vue";
import { updateFirebaseArrayProperty, updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import { mapGetters } from "vuex";
import EditableText from "@/components/generative_templates/Editing/EditableText.vue";
import EmailImageLoader from "@/components/generative_templates/Email/EmailImageLoader.vue";
import SectionBlenderNew from "@/components/generative_templates/Pages/SectionBlenderNew.vue";
import generativeSectionMixin from "@/mixins/ai/generativeSectionMixin";
import streamCompletion from "@/mixins/ai/stream_completion";
import EmailSectionProducts from "@/components/generative_templates/Email/EmailSectionProducts.vue";
import SectionToolButtons from "@/components/generative_templates/Email/SectionToolButtons.vue";
import NewSectionModal from "@/components/generative_templates/Email/NewSectionModal.vue";

export default {
    name: "NewEmailSection",
    components: {
        NewSectionModal,
        SectionToolButtons,
        EmailSectionProducts,
        SectionBlenderNew,
        EmailImageLoader,
        EditableText,
        FeaturesSection,
        BrandLogoComponent,
        SectionButton,
        SectionSettings,
        LandingPageImage,
        ImageGradient,
        DebugObject,
    },
    mixins: [styleGuideMixin, styleGuideSettingsMixin, SectionStyleMixin, generativeSectionMixin],
    props: {
        result: {
            type: Object,
            default: () => ({
                header: "",
                body: "",
                button: {
                    show: true,
                    text: "Shop now",
                },
                // pick a random style from "imageLeft", "imageRight", "fullBleed"
                style: "imageLeft",
                imageIndex: 1,
                textAlign: "center",
                imageInset: false,
            }),
            required: true,
        },
        identifier: {
            type: String,
            default: "",
        },
        campaignItem: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            default: 0,
        },
        id: {
            type: String,
            required: true,
        },
        sections: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            textColorOverride: "",
            tempBackgroundColor: "",
            textColor: "",
            pickedColor: false,
            showSettings: false,
            showPrompt: false,
            userInput: "",
            hovered: false,
        };
    },
    computed: {
        ...mapGetters("stream", ["campaignData", "campaignStrings"]),
        ...mapGetters("stream", ["editor"]),
        ...mapGetters("styleGuide/image", ["images"]),
        logoHeight() {
            return this.$store?.stream?.stream?.options?.logoHeight;
        },
        identifierId() {
            return this.makeId(this.identifier);
        },
        textClasses() {
            let nobodyTextOffset = "";
            let preheaderOffset = "";
            if (!this.body && this.fullBleed) nobodyTextOffset = "!my-6";
            if (!this.body && !this.fullBleed && this.preheader) nobodyTextOffset = "!my-6";
            if (this.fullBleed && this.preheader) preheaderOffset = "!-mt-8";
            if (!this.fullBleed && this.preheader) preheaderOffset = "!-mt-16 mb-0";
            return {
                header: "mb-3 relative duration-3 animated fadeInUpSmooth d-1 ease-back z-5 mw-450", //break-words
                subheader: `block mb-3 ease-back duration-2 animated fadeInUpSmooth leading-tight d-2 relative fwb z-2 mw-450 ${this.fullBleed ? "mt-0" : "mt-0"}`, //break-words
                // subheader: `block mb-3 ease-back duration-2 animated fadeInUpSmooth leading-tight d-2 relative fwb z-2 mw-450 ${nobodyTextOffset}`, //break-words
                body: "block mb-3 elastic-easing duration-2 animated fadeInUpSmooth d-2 relative",
                preheader: `block mb-3 ease-back duration-2 animated fadeInUpSmooth d-2 relative fwb h6 !py-0  ${nobodyTextOffset} ${preheaderOffset}`,
                // preheader: `block mb-3 ease-back duration-2 animated fadeInUpSmooth d-2 relative text-uppercase fwb h6 !my-0 mt-5 !py-0 ${nobodyTextOffset} ${preheaderOffset}`,
            };
        },
        blenderZIndex() {
            if (this.isTransparent) {
                return 200;
            } else {
                if (!this.isInset) return 1000;
                else if (this.isInset) return 200;
            }
        },
        blenderHeight() {
            if (this.transparent) {
                if (this.fullBleed) return 300;
                else return 300;
            } else {
                if (this.isInset) return 500;
                if (this.fullBleed) return 200;
                else return 100;
            }
        },
        showFullBleedGradient() {
            return this.fullBleed && this.imageURL && !this.isEmail;
        },
        kind() {
            return "email";
        },
        section() {
            let result = this.result;
            let tempBGColor = this.tempBackgroundColor || "";
            let imageIndex = result.imageIndex;
            if (!imageIndex) imageIndex = "0";
            let images = this.images;
            let products = result.products;

            let image = images[imageIndex] || {};
            let { bottomColor, backgroundColor, dominant, topColor } = image || {};
            if (dominant) tempBGColor = dominant;
            if (backgroundColor) tempBGColor = backgroundColor;
            if (bottomColor) tempBGColor = bottomColor;
            if (result?.backgroundColor) tempBGColor = result?.backgroundColor;
            // let section = this.section;
            if (products) products = this.products;
            let final = { ...result, backgroundColor: tempBGColor, topColor: topColor };
            if (products) {
                final.products = products.map(product => {
                    if (product && product.images && product.images.length > 0) {
                        const productImages = product.images.map(img => img.originalURL);
                        const matchingImages = images.filter(img => productImages.includes(img.originalURL));
                        return {
                            ...product,
                            images: matchingImages,
                        };
                    }
                    return product;
                });
            }
            return final;
        },
        headerComputedClass() {
            // { 'mt-3': fullBleed && !showLogo, 'mt-0': fullBleed && showLogo }
            return {
                "mt-3": this.fullBleed && !this.showLogo,
                "mt-0": this.fullBleed && this.showLogo,
            };
        },

        showBottomGradient() {
            return this.imageURL && !this.isTransparent && !this.hideGradient;
        },
        showTopGradient() {
            return this.imageURL && !this.isTransparent && !this.isInset && (this.fullBleed || this.showLogo);
            // return this.imageURL && !this.isTransparent && !this.hideGradient && (this.fullBleed || this.showLogo);
        },
        allProducts() {
            return this.$store.getters["styleGuide/products"] || [];
        },
        products() {
            const products = this.allProducts;
            const sectionProducts = this.result?.products || [];
            if (!Array.isArray(sectionProducts) || sectionProducts.length === 0) return [];
            if (typeof sectionProducts[0] === "object") return sectionProducts;
            else if (typeof sectionProducts[0] === "number") return sectionProducts.map(i => products[i]).filter(product => product !== undefined);
            else return [];
        },

        textPaddingTop() {
            return {
                "!pt-6 mt-4 transition-all duration-2 d-4": !this.fullBleed && !this.showLogo && this.hideGradient && !this.isRound,
                "!pt-7 mt-2 transition-all duration-3 d-6": !this.fullBleed && this.showLogo && this.hideGradient && !this.isRound,
                "!pt-6 mt-3 transition-all duration-2 d-2": !this.fullBleed && this.hideGradient && this.isRound,
                "!pt-0 transition-all duration-2 d-4": !this.fullBleed && !this.hideGradient,
                "pb-0": this.fullBleed,
                "mb-5": !this.blendSections,
                "mb-0": this.blendSections,
                // "mb-8": this.blendSections && this.hovered,
                // "pb-5": this.fullBleed && !this.hideGradient,
                "pt-5 !pb-0 mb-0 mt-logo d-3": this.fullBleed && this.showLogo, //removed but may need to fix it!pt-0
                "pb-8 transition-all d-3": this.index === this.sections.length - 1, //removed but may need to fix it!pt-0
                "mt-5": !this.imageIndex && this.products.length > 0, //removed but may need to fix it
                // "block -mb-8 transition-all": this.fullBleed && this.isTucked,
                "-mt-16 -pt-5 d-0 duration-1": this.blendSections && this.fullBleed,
                "!mb-8 !pb-8 elastic-easing": this.fullBleed && this.features,
                // "!mb-5 !pb-8 !mt-0 !pt-0": this.fullBleed && this.products && !this.isInset,
                "swingInOut transition-all duration-2": true,
            };
        },
        image() {
            if (this.imageData) return this.imageData;
            let imageIndex = 1;
            if (this.imageIndex !== null) imageIndex = parseInt(this.imageIndex);
            if (imageIndex !== null && imageIndex !== undefined) return this.images[imageIndex];
            return this.images[imageIndex];
        },
        imageData() {
            return this.section.imageData;
        },
        componentData() {
            return JSON.stringify(this.$data, null, 2);
        },
        showImageLoader() {
            let index = this.section?.imageIndex;
            return index === null || index === -1;
        },
        imageIndex() {
            if (this.section && typeof this.section.imageIndex !== "undefined" && this.section.imageIndex !== null) {
                if (this.section?.imageIndex < 0) return "0";
                return this.section.imageIndex || "0";
            }
            return "0";
        },
        textAlign() {
            return this.section?.textAlign || "center";
        },
        textAlignClass() {
            let alignment = this.section?.textAlign || "center";
            if (this.textAlign && this.textAlign !== "") return `text-${alignment}`;
            else return "text-center";
        },

        complete() {
            return this.section?.complete || false;
        },
    },
    watch: {
        imageStyle: {
            handler(val, oldVal) {
                if (val === "fullBleed") {
                    // this.updateColors(this.image.backgroundColor);
                    // if (!this.isEmail) this.updateSection("imageInset", false);
                    this.updateSection("imageStyle", "normal");
                }
            },
            immediate: true,
        },
        imageIndex: {
            handler(val, oldVal) {
                if (val) {
                    this.$nextTick(() => {
                        if (!val) val = 0;
                        this.updateSection("imageData", this.images[val]);
                        this.updateColors(this.imageColor);
                    });
                }
            },
        },
        // image(newVal, oldVal) {
        //     this.findContrastingColor(val);
        // },
    },
    mounted() {
        this.$nextTick(() => {
            this.mountWithCorrectedColors();
            this.updateColors(this.imageColor);
        });
    },
    methods: {
        updateSectionColor(color) {
            this.updateColors(color);
            this.saveSections();
        },
        showEmailModal() {
            this.showPrompt = true;
            this.userInput = "";
        },
        // Filter out background colors with gradients
        async removeSection(obj) {
            this.$store.dispatch("stream/removeSection", { id: this.id, index: this.index });
        },
        async saveSections(obj) {
            if (this.id) {
                let prop = obj?.prop;
                let identifier = obj?.identifier;
                let sections = deepCopy(this.sections);
                const section = deepCopy(this.section);
                let matchedObject = await fetchData("campaigns", this.id);
                const id = this.id || matchedObject.id;
                matchedObject = this.$toRaw(matchedObject);
                console.log("index", this.index);
                console.groupCollapsed(`%cSection Saved ${this.index}`, purple, prop);
                if (this.id) console.log("ID", this.id);
                if (sections) console.log("sections", sections);
                if (identifier) console.log("Identifier", identifier);
                if (section) console.log("Section", section);
                console.log("Matched Campaign", deepCopy(matchedObject));
                console.groupEnd();
                // sections = sections.map(section => {
                //     return {
                //         ...section,
                //         imageData: this.images[section.imageIndex],
                //     };
                // });
                matchedObject.result.sections = sections;

                await this.$nextTick();
                setTimeout(async () => {
                    let campaignItem = await updateFirebaseObject("campaigns", { result: matchedObject.result }, id);
                    console.log(campaignItem);
                    let parentId = matchedObject?.result?.campaignId || matchedObject?.campaignId;
                    console.log("Parent ID", parentId);
                    console.log("Saving from the email section controller");
                    try {
                        if (this.campaignData && !this.campaignData.childCampaigns) await updateFirebaseObject("campaigns", { childCampaigns: [] }, parentId);
                        if (this.campaignData && !this.campaignData.id) await updateFirebaseObject("campaigns", { id: parentId }, parentId);
                        if (parentId) await updateFirebaseArrayProperty("campaigns", parentId, "childCampaigns", id);
                    } catch (e) {
                        console.log(e);
                    }
                }, 100);
            }
        },
        findUndefinedProps(obj, parent = "") {
            const undefinedProps = [];
            for (const [key, value] of Object.entries(obj)) {
                const propPath = parent ? `${parent}.${key}` : key;
                if (value === undefined) {
                    undefinedProps.push(propPath);
                } else if (value && typeof value === "object" && !Array.isArray(value)) {
                    undefinedProps.push(...this.findUndefinedProps(value, propPath));
                }
            }
            return undefinedProps;
        },
        clickedLogo() {
            this.$emit("clicked-logo");
        },
        toggleBlendMode() {
            this.updateStreamProp(`editor.${this.id}.result.sections.${this.index + 1}.blend`, !this.editor[this.id].result.sections[this.index + 1].blend);
            this.saveSections();
            // this.updateSection("blend", !this.section.blend);
        },
    },
};
</script>
<style lang="scss" scoped>
.scale-110 {
    transform: scale(1.1);
}

.scale-100 {
    transform: scale(1);
}

.tucked {
    @apply -mb-8;
}

.edit_button {
    @apply opacity-0;
    @apply bg-opacity-50;
    @apply border-opacity-0;
    @apply backdrop-blur-2xl;
}

.section {
    &:hover {
        .edit_button {
            @apply opacity-100;
            @apply bg-opacity-70;
            @apply border-opacity-100;
        }
    }
}
</style>
<!--                :style="`border-color:${textColorOverride};color:${textColorOverride}`"-->
