<template>
    <div>
        <!--        <span class="block" v-for="style in allTextStyles" :style="style">The quick brown fox jumps over the lazy dog.</span>-->
        <div class="f aic jcc gap-3">
            <a :class="{ disabled: generativeLoader }" :style="buttonStyles[0]" class="btn mb-4" href="#" @click.prevent="callForAI('make it good')">
                <template v-if="generativeLoader">
                    <i class="fal mr-2 fa-sync spinner infinite ease-back"></i>
                    <span>Loading ...</span>
                </template>
                <template v-else>
                    <i class="fal mr-2 fa-pencil-alt"></i>
                    <span>Get typography</span>
                </template>
            </a>
        </div>
        <div class="mw-500 mx-auto">
            <template v-for="item in ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']">
                <component :is="item" v-if="aiContent?.[item]?.style" class="block transition-all !line-height-normal duration-2 mb-3" :style="aiContent[item].style">
                    <template v-if="aiContent[item]?.sampleText">
                        {{ aiContent[item].sampleText }}
                    </template>
                    <template v-else>The Quick Brown Fox Jumps Over The Lazy Dog</template>
                </component>
            </template>
            <div class="f fc gap-3">
                <template v-for="item in ['primary', 'secondary', 'tertiary']">
                    <button v-if="aiContent?.buttons?.[item]?.style" class="block transition-all duration-2" :style="aiContent.buttons[item].style">
                        <template v-if="aiContent?.buttons?.[item]?.sampleText">
                            {{ aiContent.buttons?.[item].sampleText }}
                        </template>
                        <template v-else>The Quick Brown Fox Jumps Over The Lazy Dog</template>
                    </button>
                </template>
            </div>
        </div>
        <CollapsableMenuItem class="container-slim f-13" v-if="aiContent">
            <template #title><div class="py-2 pl-3 f-11 z-5">Debug Generated</div></template>
            <DynamicObjectViewer v-if="aiContent" :data-object="aiContent" class="container-slim"></DynamicObjectViewer>
        </CollapsableMenuItem>

        <div class="x"></div>
        <div :style="mergedStyles" class=""></div>
    </div>
</template>

<script>
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import CopyGuideSection from "@/components/generative_templates/Guidelines/CopyGuideSection.vue";
import PreviewMessages from "@/components/styleGuide/debugging/PreviewMessages.vue";
import StreamObjectDebugger from "@/components/Presentations/slides/StreamObjectDebugger.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import aiTextMixin from "@/mixins/ai/aiTextMixin";
import generativeStyleGuideMixin from "@/mixins/ai/generativeStyleGuideMixin";

export default {
    name: "aiTypograpy",
    components: { CollapsableMenuItem, DynamicObjectViewer, StreamObjectDebugger, PreviewMessages, CopyGuideSection, ChatMarkdown },
    mixins: [styleGuideMixin, generativeStyleGuideMixin],
    props: {},
    data() {
        return {
            styleGuideKey: "typography",
            componentModel: gpt4,
            save: true,
        };
    },
    async mounted() {
        setTimeout(() => {
            this.appendStylesToHead(this.aiContent);
        }, 2000);
    },
    beforeUnmount() {
        this.removeStylesFromHead();
    },
    methods: {
        async callForAI() {
            this.setActionTypographyGuide();
            // this.setActionWritingSamples();
            await this.callFunctionFromComponent("make it good");
            this.appendStylesToHead(this.aiContent);
        },
        async appendStylesToHead(styles) {
            let css = "";
            for (const key in styles) {
                if (styles.hasOwnProperty(key)) {
                    // Prefixing with a class for scoping the styles
                    if (styles[key]?.style) css += `.brand-bg ${key} { ${this.convertStyleObjectToCSS(styles[key]?.style)} } `;
                    console.log("CSS LINE", styles[key]?.style);
                }
            }
            console.log("CSS", css);
            const styleSheet = document.createElement("style");
            styleSheet.type = "text/css";
            styleSheet.id = "dynamic-brand-styles";

            styleSheet.textContent = css;
            console.log("STYLE SHEET", styleSheet);
            document.head.appendChild(styleSheet);
        },
        removeStylesFromHead() {
            const styleSheet = document.getElementById("dynamic-brand-styles");
            if (styleSheet) {
                document.head.removeChild(styleSheet);
            }
        },
        convertStyleObjectToCSS(styleObj) {
            return Object.entries(styleObj)
                .map(([key, value]) => `${this.camelCaseToDash(key)}: ${value};`)
                .join(" ");
        },
        camelCaseToDash(str) {
            return str.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);
        },
    },

    computed: {
        aiContent() {
            return this.styleGuide?.ai?.[this.styleGuideKey];
        },
        mergedStyles() {
            const baseStyle = "line-height:unset;font-weight:unset";
            const paragraphStyle = this.paragraphStyle || "";
            return [baseStyle, paragraphStyle];
        },
        headerStyle() {
            // let style = this.likelyHeaderCombo?.style?.header || {};
            // style.fontSize = style.fontSize || "3rem";
            // return style;
        },
        paragraphStyle() {
            // let style = this.likelyHeaderCombo?.style?.p || {};
            // style.fontSize = style.fontSize || "unset";
            // return style;
        },
    },
};
</script>

<style lang="scss" scoped>
h3 {
    font-size: 2rem !important;
}

strong {
    font-weight: 500 !important;
}

ol > div {
    counter-increment: list-counter;
}

ol > div > li {
    position: relative;
    padding-left: 2em;
    list-style-type: none;

    &::before {
        font-size: 0.8rem;
        line-height: 1.5em;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.5em;
        height: 1.5em;
        margin-right: 30px;
        @apply text-base-500;
        content: counter(list-counter);
        transform: translateY(-50%);
        @apply border-base-900/10;
        @apply bg-base-900/5;
        text-align: center;
        border: 1px solid;
        border-radius: 50%;
    }
}

p {
    font-size: 1rem !important;
    font-weight: normal !important;
    line-height: normal !important;
}

/* Include your styles here */
</style>
