<template>
    <div class="text-snow bg-black x overflow-x-hidden">
        <div class="relative pt-1"><LandingPagePromptInput /></div>
        <div class="relative"><LandingPageCampaignItems /></div>
        <div class="relative text-black"><HomePageEmailPreview /></div>
        <div class=""><LandingPageColorPallette /></div>
        <!--        <div><home-page-email-preview-scroll></home-page-email-preview-scroll></div>-->
        <div class="relative"><GSAPImageScroller v-if="$store.state?.styleGuide?.styleGuides?.length > 0" /></div>
        <div class="vh-100 f aic jcc">
            <BaseButton label="Get Started" class="f-30" @click="$emit('show-guides')" style-type="primary" size="xl" :style="{ width: '200px' }" />
        </div>
    </div>
</template>
<script>
// import { SplitText } from "gsap/SplitText";
import gsapMixin from "@/mixins/ai/gsapMixin";
import EmailSections from "@/components/Admin/EmailSections.vue";
import LandingPageLoader from "@/components/Admin/LandingPageLoader.vue";
import CampaignHeader from "@/components/generative_templates/Campaigns/CampaignHeader.vue";
import json_helpers from "@/mixins/ai/json_helpers";
import { mockCampaign, mockThinking } from "@/mixins/StyleGuideMixins/mockCampaign.js";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import LandingPagePromptInput from "@/views/LandingPagePromptInput.vue";
import GSAPImageScroller from "@/views/GSAPImageScroller.vue";
import LandingPageColorPallette from "@/views/LandingPageColorPallette.vue";
import LandingPageCampaignItems from "@/views/LandingPageCampaignItems.vue";
import { Back, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HomePageEmailPreview from "@/views/HomePageEmailPreview.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import HomePageEmailPreviewScroll from "@/views/HomePageEmailPreviewScroll.vue";
gsap.registerPlugin(ScrollTrigger);
const { timeline, set, to, from } = gsap;
const { validateJSON } = json_helpers;

// gsap.registerPlugin(SplitText);

export default {
    name: "HomePageHeader",
    components: { HomePageEmailPreviewScroll, BaseButton, HomePageEmailPreview, LandingPageCampaignItems, LandingPageColorPallette, GSAPImageScroller, LandingPagePromptInput, CampaignHeader, LandingPageLoader, EmailSections },
    mixins: [styleGuideMixin, gsapMixin],
    data() {
        return {
            text: "",
            mockCampaign: mockThinking,
            campaignItems: mockThinking,
            mock: mockCampaign,
            headerSize: 200,
            loaded: false,
            colorProg: 1,
        };
    },
    computed: {},
    created() {},
    updated() {
        // this.$forceUpdate();
    },
    async mounted() {},
    methods: {},
};
</script>
<style lang="scss">
.cursor-blink {
    width: 2px;
    height: 1rem;
    background: black;
    animation: blink 1s linear infinite;
}
@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>
