<template>
    <div class="bg-snow mw-900 mx-auto br-10 border-light shadow" v-if="images">
        <ProgressStats :stats="stats" />
        <template v-for="item in sortedImagesArray.slice(0, 10)">
            <SourceProgressItem :item="item" kind="image" />
        </template>
    </div>
</template>
<script>
import SourceProgressItem from "@/views/SourceProgressItem.vue";
import ProgressStats from "@/views/ProgressStats.vue";

export default {
    name: "ImageProgress",
    components: { ProgressStats, SourceProgressItem },
    props: {
        images: {
            type: Object,
            default: () => ({
                progress: {},
            }),
        },
        stats: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        sortedImagesArray() {
            try {
                let images = this.images?.progress || [];
                let imagesArray = images;
                imagesArray.sort((a, b) => b.updated - a.updated);
                return imagesArray;
            } catch (e) {
                console.log(e);
                return [];
            }
        },
    },
};
</script>
