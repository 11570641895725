<template>
    <div class="f jcs aic">
        <BaseButton style-type="link" icon="fas fa-bars" icon-only class="f-20 border-0 border-none border-opacity-0 py-3 pl-2 pr-3 transition-all" @click.prevent="openChatList" size="md" rounded />
        <swiper class="mySwiper">
            <swiper-slide>
                <div class="f aic jcc py-3">
                    <div class="x">
                        <p class="mb-0 text-gray-900 line-clamp-1 dark:text-base-400" v-if="chat && chat.chatTitle">{{ chat.chatTitle }}</p>
                    </div>
                    <div class="f gap-1 px-2 pr-3">
                        <ToggleButton vuex-key="chat.showDebugger" :border="1" :height="20" :width="30" label="🐞" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
                        <ToggleButton vuex-key="chat.followUps" :border="1" :height="20" :width="30" label="🔄" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
                    </div>
                </div>
            </swiper-slide>
            <SwiperSlide>
                <div class="f aic jcc">
                    <div class="x f aic jcc py-2">
                        <BaseDropdown id="stylePreset" :inline="true" :modelValue="model" :options="models.map(m => ({ label: m, value: m }))" class="mb-0 mr-2 block" placeholder="Select a style preset" @update:modelValue="model = $event"></BaseDropdown>
                        <Voice-Picker class="f visually-hidden my-0 py-0"></Voice-Picker>
                        <ToggleButton vuex-key="chat.commands" :border="1" :height="20" :width="30" label="🪖" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
                        <ToggleButton vuex-key="chat.autoGPT" :border="1" :height="20" :width="30" label="🤖" off-color="#F4F2F3" on-color="#12BE00"></ToggleButton>
                    </div>
                </div>
            </SwiperSlide>
        </swiper>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ToggleButton from "@/components/CoreUI/ToggleButton.vue";
import VoicePicker from "@/components/CoreUI/VoicePicker.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-cards";
import BaseDropdown from "@/components/CoreUI/BaseDropdown.vue";
export default {
    setup() {
        const onSwiper = swiper => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
        };
    },
    name: "ChatToolbarMobile",
    components: { BaseDropdown, BaseButton, ToggleButton, VoicePicker, Swiper, SwiperSlide },
    props: {
        chat: {},
    },
    data() {},
    computed: {
        model: {
            get() {
                // Get the value from the Vuex store
                return this.$store.state.chat.model;
            },
            set(value) {
                // Update the value in the Vuex store using the 'updateModel' action
                this.$store.dispatch("chat/updateModel", value);
            },
        },
        models() {
            if (this.$store.state.chat.models && this.$store.state.chat.models.length > 0) {
                return this.$store.state.chat.models;
            } else {
                return ["OpenAI", gpt3, gpt4, "claude-1.3", "ANTHROPIC", "claude-instant-1.1", "claude-instant-1.1-100k", "claude-1-100k"];
            }
        },
    },
    methods: {
        openChatList() {
            // this.isChatListVisible = true;
            this.$store.commit("chat/setShowMobileChat", true);
        },
    },
};
</script>
<style scoped>
textarea {
    overflow: hidden;
    resize: none;
    border: none;
    padding: 10px;
    min-height: unset !important;
}
</style>
