<template>
    <div>
        <div v-if="text">
            <chat-markdown :content="text"></chat-markdown>
        </div>
        <div v-if="annotation">
            <h1>{{ annotation }}</h1>
        </div>
        <!--        {{ messageFileHelper }}-->
        <div class="border-light inline-block br-5 overflow-hidden" v-if="image">
            <img class="x mw-800" :src="image" />
        </div>
        <div>
            <div v-if="image" class="br-5 p-1 px-2 pr-3 border-light text-gray-500 bg-gray-50 inline-flex aic jcs gap-3">
                <div class="c-25 f aic jcc bg-green white"><i class="fas fa-xs fa-image"></i></div>
                {{ type }}
            </div>
        </div>
    </div>
</template>
<script>
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import webRequest from "@/mixins/ai/web_request";

export default {
    name: "ThreadMessageContent",
    components: { ChatMarkdown },
    props: {
        content: {
            type: Object,
            default: () => ({}),
        },
        messageId: {
            type: String,
            default: "",
        },
        threadId: {
            type: String,
            default: "",
        },
        fileIds: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.imageFileId) this.getThreadImage();
        });
    },
    data() {
        return {
            image: null,
            type: null,
            annotation: null,
            text: null,
        };
    },
    methods: {
        async getThreadImage() {
            // let image = await this.$store.dispatch("assistants/getThreadImage", {
            //     fileId: this.imageFileId,
            // });
            const response = await webRequest("threads/message-file", { file_id: this.imageFileId }, true);

            if (!response.ok) {
                throw new Error("Failed to load image");
            }

            const imageBlob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const dataUrl = reader.result;
                    console.log("Thread Image URL:", dataUrl);
                    this.image = dataUrl;
                    resolve(dataUrl);
                };
                reader.onerror = () => {
                    reject("Error occurred while reading the Blob");
                };
                reader.readAsDataURL(imageBlob);
            });
            console.log("image", image);
            this.image = image;
        },
    },
    computed: {
        messageFileHelper() {
            // get threadid, messaageid, fileid
            let threadId = this.threadId;
            let messageId = this.messageId;
            if (this.imageFileId) {
                let fileId = this.imageFileId;
                // let url = `https://api.openai.com/v1/threads/${threadId}/messages/${messageId}/files/${fileId}`;
                // fetch the image
                // this.getThreadImage();
                // return url;
            } else {
                return "";
            }
        },
        imageFileId() {
            return this.content?.image_file?.file_id;
        },
        type() {
            return this.content?.type || "";
        },
        annotation() {
            if (this.content?.annotation?.length) {
                return this.content?.annotation || [];
            }
        },
        text() {
            return this.content?.text?.value || "";
        },
    },
};
</script>
