<template>
    <div class="flex flex-col">
        <!--        <label for="tools" class="mb-2 text-sm font-medium text-gray-900">Tools</label>-->
        <div class="flex flex-wrap gap-2">
            <label v-for="(type, index) in tools" class="flex items-center gap-2" :key="index">
                <input type="checkbox" :value="type" :checked="isSelected(type)" @change="updateSelectedTools(type, $event.target.checked)" />
                {{ type }}
            </label>
        </div>
        <label for="Functions" class="mb-2 mt-3 text-sm font-medium text-gray-900">Functions</label>
        <template v-for="f in selectedTools.filter(t => t.type === 'function')">
            <div>
                <collapsable-menu-item>
                    <template #title>
                        <div class="border-light p-1 px-2">{{ f.function.name }}</div>
                    </template>
                    <div>
                        <input type="text" :value="f?.function?.name" />
                        <input type="text" :value="f?.function?.description" />
                        <textarea type="text" :value="JSON.stringify(f?.function?.parameters, null, 2)" />
                        <textarea type="text" :value="JSON.stringify(f?.function, null, 2)" />
                        <ToolBuilder />
                    </div>
                </collapsable-menu-item>
            </div>
        </template>
    </div>
</template>

<script>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import ToolBuilder from "@/views/Chat/ToolBuilder.vue";

export default {
    name: "AssociateTools",
    components: { ToolBuilder, CollapsableMenuItem },
    props: {
        selectedTools: {
            type: Array,
            default: () => [],
        },
        availableTools: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            types: ["code_interpreter", "retrieval", "function"],
        };
    },
    computed: {
        tools() {
            return this.availableTools;
        },
    },
    methods: {
        isSelected(type) {
            let selectedTools = JSON.stringify(this.selectedTools);
            return selectedTools.includes(type);
        },
        updateSelectedTools(type, isChecked) {
            let newSelectedTools;
            if (isChecked) {
                newSelectedTools = [...this.selectedTools, { type: type }];
            } else {
                newSelectedTools = this.selectedTools.filter(t => t.type !== type);
            }
            this.$emit("update:selectedTools", newSelectedTools);
        },
    },
};
</script>

<!-- Add any styles here -->
