<template>
    <div>
        <div class="mw-300 block">
            <div v-if="filePreview" class="preview mb-2 px-1">
                <img v-if="isImage" :src="filePreview" class="br-10 x mw-300 block" />
                <iframe v-else ref="myIframe" :src="filePreview" class="br-20 border-light p-2 scrollbar-hide f-11" style="height: 200px" width="300"></iframe>
            </div>
        </div>
        <div class="filePreview br-10 bg-snow border-light p-2 f aic mw-300 relative overflow-hidden z-0">
            <!-- Bind the background color and icon dynamically -->
            <div v-if="type" :class="['width-50 height-50 f aic jcc br-5 border-light mr-2 flex-shrink-0 text-white', backgroundColorClass]">
                <i :class="iconClass"></i>
            </div>
            <div class="relative overflow-hidden z-1">
                <span v-if="name" class="text-left fwb f-17 line-clamp-1">{{ name }}</span>
                <span v-if="type" class="block text-left o-5 fwn f-13">{{ type }}</span>
                <span v-if="size" class="block text-left o-5 fwb f-13">{{ size }}</span>
            </div>
            <div class="gradient-right-white absolute top bottom right z-2 width-50 block bg-red"></div>
            <div class="z-3 p-absolute right p-2" v-if="showRemove">
                <BaseButton class="" icon="fa fas fa-trash !opacity-30" icon-only size="xxs" @click.prevent="removeItem"></BaseButton>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "FileUploadCard",
    components: { BaseButton },
    props: {
        file: {
            type: Object,
            required: true,
        },
        filePreview: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        name() {
            return this.file?.name;
        },
        isImage() {
            return this.type.includes("image") || this.name.includes(".jpg") || this.name.includes(".png");
        },
        type() {
            return this.file?.type;
        },
        size() {
            if (this.file?.size) {
                return this.formattedSizeString(this.file.size);
            }
            return false;
        },
        backgroundColorClass() {
            // Define your logic here to determine the background color based on the file type
            if (this.name.includes(".jpg") || this.name.includes(".png")) {
                return this.getBackgroundColor("image");
            }
            return this.getBackgroundColor(this.file?.type);
        },
        iconClass() {
            if (this.loading) return "fa fa-sync fa-xs o-5 fa-spin ease-back duration-3";
            // Define your logic here to determine the icon class based on the file type
            //  if the name includes an image extension correct the file type
            if (this.name.includes(".jpg") || this.name.includes(".png")) {
                return this.getIconClass("image");
            }
            return this.getIconClass(this.file?.type);
        },
    },
    methods: {
        shrinkIframe() {
            this.$nextTick(() => {
                let iframe = this.$refs.myIframe;
                let iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

                // Apply the CSS transform
                iframeDocument.body.style.transform = "scale(0.5)";
                iframeDocument.body.style.transformOrigin = "top left";
            });
        },
        removeItem() {
            this.$emit("removeItem", this.file);
        },
        getBackgroundColor(fileType) {
            switch (fileType) {
                case "application/pdf":
                    return "bg-pink-500";
                case "application/msword":
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    return "bg-blue-400";
                case "image":
                case "image/jpeg":
                case "image/png":
                    return "bg-green-300";
                case "audio/mpeg":
                case "audio/mp3":
                    return "bg-orange-500";
                default:
                    return "bg-blue-500";
            }
        },
        getIconClass(fileType) {
            // Logic to return the FontAwesome icon class based on file type
            // Example: 'fa-file-pdf' for .pdf files, 'fa-file-word' for .doc, etc.
            switch (fileType) {
                case "application/pdf":
                    return "fa fa-file-pdf";
                case "file":
                    return "fa fa-file";
                case "image":
                    return "fa fa-image";
                // Add more cases as needed
                default:
                    return "fa fa-save";
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.preview img {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
}

input[type="file"] {
}

progress {
    width: 100%;
    height: 20px;
}

.preview img {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
}

.gradient-right-white {
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    transition: width 0.5s ease;
}
</style>
