<template>
    <div>
        <div class="line f">
            <div class="indentation" :style="{ 'margin-left': depth * 20 + 'px' }"></div>
            <div class="p-relative" :class="['f', `depth-${depth}`, isValueEmpty ? 'empty-value' : '']">
                <div @mouseover="showIcons = true" @mouseleave="showIcons = false" style="left: -20px; top: 3px" class="ellipsis fwb c-15 f fwb br-5 p-absolute f-11 aic jcc border border-gray-200 bg-gray-50 p-1 text-gray-500" v-if="keyProp !== null && (isObject(node) || isArray(node))" @click="isOpen = !isOpen">
                    {{ node.length || Object.keys(node).length }}
                    <div v-show="showIcons" class="icon-buttons f-15">
                        <i class="fas fa-copy" @click="copyToClipboard(path)"></i>
                        <i class="fas fa-clipboard" @click="copyToClipboard(node)"></i>
                    </div>
                </div>
                <span class="key" v-if="keyProp !== null" @click="isOpen = !isOpen">{{ keyProp }}:</span>
                <span class="value mw-400 whitespace-pre-wrap break-all" v-if="!isObjectOrArray(node)">
                    <template v-if="formatValue(node)?.isColor">
                        <color-preview :color="formatValue(node).hex" />
                    </template>
                    <template v-else>{{ formatValue(node) }}</template>
                </span>
                <!--                <div v-if="!isValueEmpty" class="ellipsis">-->
                <!--                    <span>...</span>-->
                <!--                </div>-->
            </div>
            <i class="fas o-5 ml-2" v-if="depth !== 0 && isObjectOrArray(node)" :class="{ 'fa-caret-down ': isOpen, 'fa-caret-right': !isOpen }"></i>
        </div>
        <div v-if="isOpen && isObjectOrArray(node)" class="fwb mb-3 mt-1">
            <debug-node v-for="(value, innerKey) in node" :key="innerKey" :node="value" :keysToHide="keysToHide" :depth="depth + 1" :keyProp="innerKey" :path="addToPath(path, innerKey)" :openKeys="openKeys" />
        </div>
    </div>
</template>

<script>
import ColorPreview from "@/components/styleGuide/Tools/ColorPreview.vue";

export default {
    name: "DebugNode",
    components: { ColorPreview },
    props: {
        node: {
            type: [Object, Array, String, Number, Boolean, Date],
            required: true,
        },
        keysToHide: {
            type: Array,
            default: () => [],
        },
        depth: {
            type: Number,
            required: true,
        },
        keyProp: {
            type: String,
            default: null,
        },
        path: {
            type: String,
            default: "",
        },
        openKeys: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isOpen: this.depth === 0 || this.openKeys.includes(this.keyProp),
            showIcons: false,
        };
    },
    computed: {
        isValueEmpty() {
            return this.node === null || this.node === undefined || this.node === "";
        },
    },
    methods: {
        isObject(value) {
            return value && typeof value === "object" && value.constructor === Object;
        },
        isArray(value) {
            return Array.isArray(value);
        },
        isObjectOrArray(value) {
            return this.isObject(value) || this.isArray(value);
        },
        copyToClipboard(value) {
            navigator.clipboard.writeText(JSON.stringify(value));
        },
        addToPath(currentPath, key) {
            return currentPath ? `${currentPath}.${key}` : key;
        },
        formatValue(value) {
            // Regular expression for hex color (e.g., #fff or #ffffff)
            const hexColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

            // Check if value is a hex color
            if (typeof value === "string" && hexColorRegex.test(value)) {
                return { hex: value, isColor: true }; // Return the hex color
            } else if (value instanceof Date) {
                // Handle Date objects
                return this.toDate(value); // Assuming toDate is a method you've defined
            } else {
                // Handle other types
                try {
                    return value;
                } catch (e) {
                    return value;
                }
            }
        },
    },
};
</script>

<style lang="scss">
.indentation {
    display: inline-block;
}
.key {
    //font-weight: bold;
    margin-right: 10px;
    cursor: pointer;
}
.value {
    color: gray;
}
.ellipsis {
    //position: relative;
    //display: inline-block;
    .icon-buttons {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        i {
            @apply bg-snow p-1 gap-2;
            cursor: pointer;
            display: inline;
            margin-right: 5px;
        }
    }
    &:hover .icon-buttons {
        display: flex;
        top: -50%;
        @apply bg-snow;
        @apply p-1;
        @apply border;
        @apply shadow;
        @apply border-solid;
        @apply border-gray-200;
        @apply rounded-md;
        transform: translateY(-50%);
        transform: translateX(-50%);
        z-index: 500;
    }
}
.empty-value .key,
.empty-value .value {
    color: #ccc;
}
.empty-value .ellipsis {
    display: none;
}
.depth-0 {
    font-size: 16px;
    //color: red;
}
.depth-1 {
    font-size: 14px;
    @apply text-gray-900 dark:text-base-200;
    //margin-bottom:20px;
    display: block;
}
.depth-2 {
    font-size: 13px;
    color: #1c691c;
    @apply text-green-700 dark:text-green-200;
    font-weight: 500;
}
.depth-3 {
    font-size: 12px;
    @apply text-gray-900 dark:text-base-200;
}
.depth-4 {
    font-size: 12px;
    color: #444;
    @apply text-gray-400 dark:text-base-400;
}
.depth-5 {
    font-size: 12px;
    color: #444;
}
</style>
