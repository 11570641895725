import { db } from "@/firebase";
import { doc, getDoc, updateDoc, deleteDoc, collection, getDocs, addDoc } from "firebase/firestore";

export default {
    namespaced: true,
    state() {
        return {
            agents: {},
        };
    },
    mutations: {
        setAgent(state, agent) {
            state.agents[agent.name] = agent;
        },
        removeAgent(state, agentName) {
            delete state.agents[agentName];
        },
        addMessageToAgentConvo(state, { agentName, message }) {
            if (state.agents[agentName]) {
                state.agents[agentName].conversation.push(message);
            }
        },
    },
    actions: {
        async fetchAgent({ commit }, agentRef) {
            try {
                const agentSnap = await getDoc(agentRef);

                if (!agentSnap.exists) {
                    console.log("No such document!");
                } else {
                    let agent = agentSnap.data();
                    agent.id = agentRef.id;
                    commit("setAgent", agent);
                    console.log("Agent fetched successfully!");
                }
            } catch (error) {
                console.error("Error fetching agent: ", error);
            }
        },
        async updateAgent({ commit, state }, { agentRef, conversationUpdate }) {
            try {
                const agentSnap = await getDoc(agentRef);
                let agent = agentSnap.data() || { conversation: [] };
                agent.conversation.push(conversationUpdate);

                await updateDoc(agentRef, agent);
                agent.id = agentRef.id;
                commit("setAgent", agent);

                console.log("Agent updated successfully!");
            } catch (error) {
                console.error("Error updating agent: ", error);
            }
        },
        async addAgent({ commit, rootState }, agentData) {
            try {
                const agentRef = await addDoc(collection(db, "agents"), agentData);
                const chatRef = doc(db, "chats", rootState.chat.id);
                const chatSnap = await getDoc(chatRef);
                let chatData = chatSnap.data();
                if (chatData.agents) {
                    chatData.agents.push(agentRef);
                } else {
                    chatData.agents = [agentRef];
                }
                await updateDoc(chatRef, chatData);

                // Form agent object with correct structure
                let agent = {
                    ...agentData,
                    id: agentRef.id,
                };

                commit("setAgent", agent);

                console.log("Agent added successfully!");
            } catch (error) {
                console.error("Error adding agent: ", error);
            }
            return;
        },
        async fetchAgents({ commit, state, rootState, getters }) {
            if (state.agents && state.agents.length > 0) return;
            try {
                const chatRef = doc(db, "chats", rootState.chat.id);
                const chatSnap = await getDoc(chatRef);
                const chatData = chatSnap.data();
                if (chatData.agents) {
                    for (const agentObj of chatData.agents) {
                        const agentRef = doc(db, "agents", agentObj.id);
                        const agentSnap = await getDoc(agentRef);
                        let agent = agentSnap.data();

                        // Check if agent is undefined
                        if (!agent) {
                            // console.warn(`No data found for agent with ID ${agentObj.id}`);
                            continue;
                        }

                        agent.id = agentObj.id;
                        if (!agent.conversation && agent.original) {
                            let prompt = agent.original;
                            agent.conversation = [sysMessage(prompt)];
                        } else {
                            agent.conversation = [];
                        }
                        commit("setAgent", agent);
                    }
                }

                // console.log("Agents fetched successfully!");
            } catch (error) {
                console.error("Error fetching agents: ", error);
            }
        },
        async removeAgent({ commit, rootState }, agentId) {
            try {
                const chatRef = doc(db, "chats", rootState.chat.id);
                const chatSnap = await getDoc(chatRef);
                let chatData = chatSnap.data();
                chatData.agents = chatData.agents.filter(id => id !== agentId);

                await updateDoc(chatRef, chatData);
                const agentRef = doc(db, "agents", agentId);

                const agentSnap = await getDoc(agentRef);
                let agent = agentSnap.data();

                await deleteDoc(agentRef);
                commit("removeAgent", agent.name);

                console.log("Agent removed successfully!");
            } catch (error) {
                console.error("Error removing agent: ", error);
            }
        },
        async addMessageToAgentConvo({ commit, state }, { agentName, message }) {
            try {
                let agent = state.agents[agentName];
                agent.conversation.push(message);
                commit("setAgent", agent);

                console.log("Message added to agent conversation successfully!");
            } catch (error) {
                console.error("Error adding message to agent conversation: ", error);
            }
        },
        async fetchAgentConvo({ commit }, agentName) {
            try {
                const agentRef = doc(db, "agents", agentName);
                const agentSnap = await getDoc(agentRef);
                let agent = agentSnap.data();

                if (!agent.conversation) {
                    console.warn(`No conversation data found for agent ${agentName}`);
                    agent.conversation = [];
                }

                commit("setAgent", agent);

                console.log("Agent conversation fetched successfully!");
                return agent.conversation;
            } catch (error) {
                console.error("Error fetching agent conversation: ", error);
            }
        },
    },

    getters: {
        getAgent: state => name => {
            return state.agents[name] || { conversation: [] };
        },
        getAgentConvo: state => name => {
            return state.agents[name]?.conversation || [];
        },
        activeAgents: state => {
            return Object.entries(state.agents).map(([agentName, agent]) => ({
                name: agent.name,
                description: agent.description,
                goals: agent.goals || [],
                conversation: agent.conversation || [sysMessage(agent.original)],
                original: agent.original,
            }));
        },
    },
};
