<template>
    <div class="p-absolute p-fill p-3 border-opacity border-opacity-10">
        <div v-if="editing" style="left: -15px; right: -15px; top: -15px; bottom: -15px; z-index: -1 !important" class="bg-gray-900/10 br-10 p-3 absolute p-fill z-0"></div>
        <div class="animated fadeIn duration-2" appear v-else>
            <span class="border p-absolute p-fill p-3 border-dashed border-4 border-opacity o-1" style="left: -15px; right: -15px; top: -15px; bottom: -15px"></span>
        </div>

        <!--        <transition name="fade-up" appear>-->
        <!--            <div class="p-absolute top right c-30 f aic jcc !f-17 p-3 bg-snow border-light font-weight-bold" style="font-size: 17px; font-family: arial !important">-->
        <!--                <i class="fa fa-pencil o-5"></i>-->
        <!--            </div>-->
        <!--        </transition>-->
    </div>
</template>
<script>
export default {
    name: "EditSectionIndicator",
    props: {
        editing: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
