<template>
    <div class="p-2 pl-2 border-light bg-gray-100 br-10 x f aic jcb mx-auto flex-shrink-0 relative" :class="{ 'fc mw-250': stacked, 'mw-450': !stacked }" @mouseenter="hovered = true" @mouseleave="hovered = false">
        <div @click.prevent="clickedCard" class="f x">
            <div class="c-30 border-light bg-snow mr-2 f aic jcc bg" v-if="type">
                <!--          email, webpage, sms fontAwesome icons depending on item.itemType      -->
                <i class="o-3 fa-xs far fa-envelope" v-if="type === 'email'"></i>
                <i class="o-3 fa-xs far fa-globe" v-if="type === 'page'"></i>
                <i class="o-3 fa-xs far fa-sms" v-if="type === 'SMS'"></i>
            </div>
            <div class="pl-1">
                <div class="f-17 pr-3 line-clamp-2" v-if="name">
                    <span class="fwb">{{ name }}</span>
                </div>
                <div class="f aic jcs mb-2 mt-2 gap-3">
                    <div v-if="type" class="border border-green d-inline-block py-0 br-10 border-green-500 text-uppercase text-green-500 bg-snow px-2 fwb py-0 f-13">{{ type }}</div>
                    <div class="border d-inline-block py-0 br-10 border-gray-200 text-uppercase text-gray-400 bg-snow px-2 fwb py-0 f-13" v-if="day !== null">
                        <span>day</span>
                        {{ day }}
                    </div>
                </div>
                <div class="f-15 mt-2 f aic jcs gap-1" v-if="false && showStatus && !complete && !$route.params.cid">
                    <!--                        <span class="fwb">Generating:</span>-->
                    <div class="c-20 f-15 bg-snow border-light f aic jcc"><i class="f-11 o-2 fas fa-sync fa-animated fa-spin ease-back duration-5"></i></div>
                    <span class="line-clamp-1 x o-5">{{ status }}</span>
                </div>
            </div>
            <div class="p-absolute top right" v-if="hovered" style="top: -15px; right: -15px">
                <BaseButton icon-only icon="fas fa-times f-13 text-purple" size="xxs" @click="deleteCampaignItem" class="p-absolute top right" />
            </div>
        </div>
        <div :class="{ 'x text-center block': stacked }">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    name: "CardBody",
    components: { BaseButton },
    emits: ["delete-item", "clicked-card"],
    props: {
        stacked: {
            type: Boolean,
            default: false,
        },
        complete: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: "",
        },
        showStatus: {
            type: Boolean,
            default: true,
        },
        status: {
            type: String,
            default: "",
        },
        day: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            hovered: false,
        };
    },
    methods: {
        clickedCard() {
            this.$emit("clicked-card");
        },
        deleteCampaignItem() {
            this.$emit("delete-item");
        },
    },
};
</script>
