<template>
    <div class="imagePile f transition-all ease-back jcc my-3 mt-5">
        <transition-group class="f aic jcc gap-2" name="list" tag="div">
            <div v-for="(image, index) in displayedImages" :key="image.id" class="image_item border-light overflow-hidden">
                <img :src="image.url" class="x y o-cover image_item" />
                <!--                {{ image.id }}-->
            </div>
        </transition-group>
    </div>
</template>
<script>
export default {
    name: "imagePileRotation",
    data() {
        return {
            newImages: [], // Array of all images
            start: 0, // Index to start the slice
        };
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
    },
    computed: {
        displayedImages() {
            if (this.newImages && this.newImages.length > 5) {
                return this.newImages.slice(this.start, this.start + 5);
            }
        },
    },
    mounted() {
        let images = this.images.slice(0, 100);
        images.forEach(image => {
            image.id = this.randomId();
            this.newImages.push(image);
        });
        this.cycleImages();
    },
    methods: {
        cycleImages() {
            setInterval(() => {
                this.start = (this.start + 1) % this.images.length;
            }, 500); // Change image every 3 seconds
        },
    },
};
</script>
<style lang="scss" scoped>
$animationTime: 0.5s;
$circleSize:75px;
//$easeVar: @apply ease-in-out;
.list-enter-active, .list-leave-active{
    transition: all $animationTime;
    //$easeVar;
    @apply ease-in-out;
    transform: scale(0.5);
}

.image_item{
    width: $circleSize !important;
    max-width: $circleSize !important;
    height: $circleSize !important;
    max-height: $circleSize !important;
    @apply br-50;
    @apply overflow-hidden;
}

.list-enter-to, .list-leave-from{
    transition: all $animationTime;
    @apply ease-in-out;
    //$easeVar;
    transform: scale(1);
    opacity: 1;

}

.list-enter-from, .list-leave-to{
    width: 0px!important;
    @apply ease-in-out;
    height: 0px!important;
    transition: all $animationTime;
    transform: scale(0.5);
    opacity: 0;
    //transform: translateY(30px);
}
</style>
