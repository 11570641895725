<template>
    <div class="relative">
        <a :href="product?.url" target="_blank">
            <!--        <template v-if="image">{{ image.url }}</template>-->
            <img ref="imgTag" class="section_img_data x aspect-square br-10 o-contain" v-if="image?.url" :src="image.url" :alt="product.name" />
            <!--            {{ image }}-->
        </a>
        <span class="fwb mb-3 mt-3 f-17 w-80 block text-center text-pretty mx-auto" v-if="title">{{ title }}</span>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "SmallProductItem",
    props: {
        product: {},
    },
    computed: {
        // map getters from styleGuide/image/images
        ...mapGetters("styleGuide/image", ["images"]),
        title() {
            let title = this.product?.title || this.product?.name || "";
            return title.split("|")[0].trim();
        },
        image() {
            // find cooresonding image in this
            if (!this.product?.images?.[0]?.url) return;
            if (!this.images) return;
            let url = this.product.images[0].url;
            // return { url };
            // return url;
            // find the url in images
            return this.images.find(i => i.originalURL === url) || this.images.find(i => i.originalURL === this.product.image);
        },
    },
};
</script>
