<!--<template>-->
<!--    <div>-->
<!--        <div v-if="localFeatures?.length">-->
<!--            <draggable v-model="localFeatures" :item-key="id" @change="saveFeatureOrder">-->
<!--                {{ id }}-->
<!--                <template #item="{ element, index }">-->
<!--                    <SectionFeatureEditorItem :feature="element" @delete-feature="deleteFeature" :index="index" @update-section="updateFeature" />-->
<!--                </template>-->
<!--            </draggable>-->
<!--        </div>-->
<!--        <div>-->
<!--            <div class="f jcb aic px-2.5 o-4">-->
<!--                <span class="editor-section-title">Add a Feature</span>-->
<!--                <div class="p-1" @click.prevent="addFeature">-->
<!--                    <i class="far fa-plus-circle f-20 o-2"></i>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->
<!--<script>-->
<!--import SectionFeatureEditorItem from "@/components/editor/SectionFeatureEditorItem.vue";-->
<!--import draggable from "vuedraggable";-->

<!--let featureId = 0;-->

<!--export default {-->
<!--    name: "SectionFeatureEditor",-->
<!--    components: { SectionFeatureEditorItem, draggable },-->
<!--    props: {-->
<!--        section: {-->
<!--            type: Object,-->
<!--            default: () => ({ features: [] }),-->
<!--        },-->
<!--    },-->
<!--    emits: ["update-section"],-->
<!--    data() {-->
<!--        return {-->
<!--            featureId: 0,-->
<!--            localFeatures: [],-->
<!--        };-->
<!--    },-->
<!--    watch: {-->
<!--        section: {-->
<!--            handler(val) {-->
<!--                this.localFeatures = val.features ? [...val.features] : [];-->
<!--                this.localFeatures = this.addKeysToFeatures(this.localFeatures);-->
<!--            },-->
<!--            deep: true,-->
<!--            immediate: true,-->
<!--        },-->
<!--    },-->
<!--    computed: {-->
<!--        id() {-->
<!--            let arrangementString = "";-->
<!--            this.localFeatures.forEach(feature => {-->
<!--                arrangementString += feature.id;-->
<!--            });-->
<!--            return arrangementString;-->
<!--        },-->
<!--    },-->
<!--    methods: {-->
<!--        addKeysToFeatures(features) {-->
<!--            return features.map(feature => {-->
<!--                return {-->
<!--                    ...feature,-->
<!--                    id: `feature-${this.featureId++}`,-->
<!--                };-->
<!--            });-->
<!--        },-->
<!--        deleteFeature(index) {-->
<!--            this.localFeatures.splice(index, 1);-->
<!--            this.$emit("update-section", "features", this.localFeatures);-->
<!--        },-->
<!--        async addFeatureWithAI() {-->
<!--            this.loading = true;-->
<!--            let section = deepCopy(this.section);-->
<!--            delete section.imageData;-->

<!--            let obj = {-->
<!--                id: this.campaign.id,-->
<!--                index: this.index,-->
<!--                input: `CURRENT STATE\n${stringifySections([section])}\n\nJust return the string (NOT in quotes)\n\nGenerate 3 feature objects with different titles, descriptions, and icons for SECTION ${this.index}. Return as an array of objects, each with "title", "description", and "icon" properties. The "icon" property should contain "emoji" and "font_awesome_class" properties.`,-->
<!--                existing: section,-->
<!--                type: "features",-->
<!--            };-->
<!--            console.log("addFeatureWithAI", obj);-->
<!--            await this.$store.dispatch("stream/addSectionWithAI", obj);-->
<!--            this.loading = false;-->
<!--        },-->
<!--        addFeature() {-->
<!--            const newFeature = {-->
<!--                id: `feature-${this.featureId++}`,-->
<!--                title: "New Feature",-->
<!--                description: "New Description",-->
<!--                icon: {-->
<!--                    emoji: "🚀",-->
<!--                    font_awesome_class: "fas fa-rocket",-->
<!--                },-->
<!--            };-->
<!--            this.localFeatures.push(newFeature);-->
<!--            this.$emit("update-section", "features", this.localFeatures);-->
<!--        },-->
<!--        updateFeature({ index, prop, value }) {-->
<!--            this.localFeatures[index] = value;-->
<!--            console.log("updateFeature", index, prop, value);-->
<!--            this.$emit("update-section", "features", this.localFeatures);-->
<!--            this.saveFeatureOrder();-->
<!--        },-->
<!--        saveFeatureOrder() {-->
<!--            this.$emit("update-section", "features", this.localFeatures);-->
<!--        },-->
<!--    },-->
<!--};-->
<!--</script>-->
<template>
    <div :class="{ 'editor-section': localFeatures.length > 0, 'px-2.5 border-top o-4': localFeatures.length === 0 }" class="f fc border-bottom">
        <template v-if="localFeatures.length > 0">
            <div>
                <div v-for="(feature, index) in localFeatures" :key="feature.id">
                    <SectionFeatureEditorItem :feature="feature" @delete-feature="deleteFeature" :index="index" @update-section="updateFeature" />
                </div>
                <div class="pb-1 relative">
                    <BaseButton v-if="!loading" icon="fas f-17 fa-sparkles !text-indigo-500 opacity-100 !scale-100" size="xxs" icon-only @click="addFeatureWithAI" />
                    <BaseButton v-else icon="fas f-17 fas fa-sync fa-spin ease-back duration-3 text-purple-500" class="" size="xxs" icon-only @click="addFeatureWithAI" />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="">
                <div class="f jcb aic">
                    <span class="editor-section-title">Add Features</span>
                    <div class="p-1" @click.prevent="addFeatures">
                        <i class="far fa-plus-circle f-20 o-2"></i>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import SectionFeatureEditorItem from "@/components/editor/SectionFeatureEditorItem.vue";
import draggable from "vuedraggable";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { stringifySections } from "@/store/SectionsToStrings";
import completion from "@/mixins/ai/completion";
import { gpt3 } from "@/mixins/default_models";

let featureId = 0;

export default {
    name: "SectionFeatureEditor",
    components: { SectionFeatureEditorItem, draggable, BaseButton },
    props: {
        section: {
            type: Object,
            default: () => ({ features: [] }),
        },
        campaign: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    emits: ["update-section"],
    data() {
        return {
            featureId: 0,
            localFeatures: [],
            loading: false,
        };
    },
    watch: {
        section: {
            handler(val) {
                this.localFeatures = val.features ? [...val.features] : [];
                this.localFeatures = this.addKeysToFeatures(this.localFeatures);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        id() {
            let arrangementString = "";
            this.localFeatures.forEach(feature => {
                arrangementString += feature.id;
            });
            return arrangementString;
        },
    },
    methods: {
        addKeysToFeatures(features) {
            return features.map(feature => {
                return {
                    ...feature,
                    id: `feature-${this.featureId++}`,
                };
            });
        },
        deleteFeature(index) {
            this.localFeatures.splice(index, 1);
            this.$emit("update-section", "features", this.localFeatures);
        },
        async addFeatureWithAI() {
            this.loading = true;
            let section = deepCopy(this.section);
            delete section.imageData;

            let obj = {
                id: this.campaign.id,
                index: this.index,
                input: `CURRENT STATE\n${stringifySections([section])}\n\nJust return the string (NOT in quotes)\n\nGenerate 3 feature objects with different titles, descriptions, and icons for SECTION ${this.index}. Return as an array of objects, each with "title", "description", and "icon" properties. The "icon" property should contain "emoji" and "font_awesome_class" properties.`,
                existing: section,
                type: "features",
            };
            console.log("addFeatureWithAI", obj);
            let function_call = {
                name: "generate_feature_options",
                description: "Generates 3 feature objects with different titles, descriptions, and icons.",
                parameters: {
                    type: "object",
                    description: "The object containing the feature options.",
                    properties: {
                        feature_options: {
                            type: "array",
                            items: {
                                type: "object",
                                properties: {
                                    title: { type: "string", description: "The feature title" },
                                    description: { type: "string", description: "The feature description" },
                                    icon: {
                                        type: "object",
                                        properties: {
                                            emoji: { type: "string", description: "The emoji for the feature" },
                                            font_awesome_class: { type: "string", description: "The Font Awesome class for the feature" },
                                        },
                                        required: ["emoji", "font_awesome_class"],
                                    },
                                },
                                required: ["title", "description", "icon"],
                            },
                            minItems: 3,
                        },
                    },
                },
            };
            let string = await completion({
                messages: [userMessage(obj.input)],
                functions: [function_call],
                function_call: "generate_feature_options",
                model: gpt3,
                returnFunction: true,
            });
            console.log("Response string:", string.feature_options);

            this.$emit("update-section", "features", string.feature_options);
            this.loading = false;
        },
        async addFeatures() {
            await this.addFeatureWithAI();
        },
        updateFeature({ index, prop, value }) {
            this.localFeatures[index] = value;
            console.log("updateFeature", index, prop, value);
            this.$emit("update-section", "features", this.localFeatures);
        },
    },
};
</script>
