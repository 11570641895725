function setupFunctionCall(function_call, functions) {
    let requestFunctioncalls = "none";
    if (Array.isArray(function_call)) {
        requestFunctioncalls = function_call;
    } else if (typeof function_call === "string") {
        if (function_call === "auto" || function_call === "none") {
            requestFunctioncalls = function_call;
        } else {
            requestFunctioncalls = { name: function_call };
        }
    } else if (functions === "commands") {
        requestFunctioncalls = "auto";
    }
    if (!functions && function_call === "string") {
        requestFunctioncalls = function_call;
    }
    return requestFunctioncalls;
}
export default setupFunctionCall;
