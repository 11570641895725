<template>
    <div>
        <template v-for="(i, iIndex) in scheduleItems">
            <div style="width: 2px; height: 20px" class="bg-gray-200 mx-auto"></div>
            <!--            <div class="border-light br-20 text-center block mw-200 mx-auto px-3">Day {{ i.scheduled_on_day }}</div>-->
            <div class="x block text-center">
                <div class="border-light br-20 text-center inline-block mx-auto px-3 text-uppercase f-15 fwb text-gray-400 py-1" v-if="scheduleItems.length > 0 && iIndex !== 0">{{ daysLater[iIndex] }} days later</div>
            </div>
            <div v-if="i?.campaign_items?.length > 1" style="width: 2px; height: 20px" class="bg-gray-200 mx-auto"></div>
            <div v-if="i?.campaign_items?.length > 1" style="width: 268px; height: 1px" class="bg-gray-200 mx-auto"></div>

            <div v-if="i?.campaign_items" class="f ais jcc">
                <div v-for="(item, index) in i.campaign_items" :key="index" class="x fc aic jcc gap-3">
                    <div class="f x aic jcc flex-shrink-0">
                        <CampaignItem class="mx-auto mw-250 x flex-shrink-0" style="min-width: 250px" :campaign-index="iIndex" :item-index="index" :item="item" />
                        <div v-if="index < i.campaign_items.length - 1" style="width: 20px; min-width: 20px; height: 1px" class="x bg-gray-200"></div>
                    </div>
                    <!--                    <TeamDiscussions title="Team Discussions" class="p-3 bg-snow x mx-300 block" :key="`followup${index}`" v-if="item?.team_discussions" :discussion="item.team_discussions" />-->
                    <!--                    <TeamDiscussions title="Follow-ups" class="p-3 bg-snow x mx-300 block" :key="`dicussion${index}`" v-if="item?.team_followups" :discussion="item.team_followups" />-->
                    <!--                    <TeamDiscussions title="Executive Decisions" class="p-3 pt-3 bg-green-400 bg-snow x mx-300 block" :key="`exec${index}`" v-if="item?.executive_decisions" :discussion="item.executive_decisions" />-->
                    <div class="c-30 border-light f aic jcc mx-auto" v-if="index === i.campaign_items.length - 1 && showLoader">
                        <i class="fal fa-spinner fa-spin fa-pulse fa-animate-pulse o-3"></i>
                    </div>
                    <div class="c-30 border-light f aic jcc mx-auto" v-else-if="!showLoader">
                        <i class="fal fa-plus-circle o-3"></i>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CampaignItem from "@/components/generative_templates/Campaigns/CampaignItem.vue";
import TeamDiscussions from "@/components/generative_templates/Campaigns/TeamDiscussions.vue";
export default {
    name: "CampaignScheduleItem",
    components: { CampaignItem, TeamDiscussions },
    props: {
        scheduleItems: {},
        showLoader: {},
    },
    methods: {
        triggerGeneration(object) {
            this.$emit("trigger-generation", object);
        },
    },
    computed: {
        daysLater() {
            return this.scheduleItems.map((i, iIndex) => {
                if (iIndex === 0) return 0;
                return i.scheduled_on_day - this.scheduleItems[iIndex - 1]?.scheduled_on_day;
            });
        },
    },
};
</script>
