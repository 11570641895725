import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import gptError from "@/mixins/ai/gpt_error";
// import { useStore } from "vuex";
import logTokens from "@/mixins/ai/log_tokens";

const reconnectDelay = 5000;

async function logMessages(store) {
    console.log('logging messages');
    // const store = useStore();
    const request_url = endpointURL("logs");
    const eventSource = new EventSource(request_url);

    eventSource.addEventListener("ping", () => {
        console.log("Ping from server");
    });

    eventSource.onmessage = event => {
        let content;
        if (typeof event.data === "object") {
            content = { ...event.data };
        } else if (typeof event.data === "string") {
            try {
                content = JSON.parse(event.data);
                if (content.log) {
                    console.log(content.log);
                }
                if (content.chunks) {
                    // for each chunk add a new message
                    content.chunks.forEach((chunk, index) => {
                        let textChunk = `* CHUNK ${index + 1}/${content.chunks.length}*\n ${chunk}`;
                        // add \m to the end of every sentence
                        textChunk = textChunk.replace(/\. /g, ". \n");
                        store.dispatch("chat/addMessageStore", aiMessage(textChunk));
                    });
                }
            } catch (e) {
                content = event.data;
            }
        } else if (Array.isArray(event.data)) {
            content = event.data;
        } else {
            content = event.data;
        }

        try {
            if (content) {
                if (content.kind && content.kind === "log") {
                    console.log(content.message);
                    if (content.message.chunks) {
                        content.message.chunks.forEach((chunk, index) => {
                            let textChunk = `* CHUNK ${index + 1}/${content.message.chunks.length}*\n ${chunk}`;
                            // add \m to the end of every sentence
                            store.dispatch("chat/addMessageStore", aiMessage(chunk.summary, "chunk"));
                        });
                    }
                } else if (content.kind === "assistant") {
                    store.dispatch("chat/addMessageStore", aiMessage(content.message));
                } else if (content.kind === "error") {
                    store.dispatch("chat/addMessageStore", errorMessage(content.message));
                } else if (content.kind === "system") {
                    store.dispatch("chat/addMessageStore", sysMessage(content.message));
                } else if (content.kind === "function") {
                } else if (content.kind === "user") {
                    store.dispatch("chat/addMessageStore", userMessage(content.message));
                } else if (content.kind === "tokens") {
                    logTokens(content.message);
                } else if (content.kind === "console") {
                    console.log("Server: ", content.message);
                } else if (content.kind === "consoleError") {
                    console.error("Server: ", content.message);
                } else if (content.chunks) {
                    // for each chunk add a new message
                    content.chunks.forEach((chunk, index) => {
                        let textChunk = `* CHUNK ${index + 1}/${content.chunks.length}*\n ${chunk}`;
                        textChunk = textChunk.replace(/\. /g, ". \n");
                        // self.addMessage("```json " + textChunk + "```", "assistant");
                    });
                } else {
                    console.log("Server log:");
                    console.log(content);
                }
            }
        } catch (error) {
            gptError(error);
        }
        return content;
    };
    eventSource.onerror = error => {
        console.log("🚫 Error connecting to stream ... :", error.message);
        eventSource.close();
        setTimeout(() => {
            // Reconnect after a certain delay
            console.log(`Reconnecting after ${reconnectDelay}ms ...`);
            logMessages();
        }, reconnectDelay);
    };
}

export default logMessages;
