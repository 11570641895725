<template>
    <div>
        <!--        <Transition name="fade-up" appear>-->
        <div v-if="showCampaign && !collapsed" class="fixed z-0 p-fill bg-gray-900/50" @click.prevent="clearPreview"></div>
        <!--        </Transition>-->
        <Transition name="slide-right" appear>
            <div v-if="showCampaign" class="top right fixed bottom bg-snow z-5 block x scrollSection pt-3 mt-nav shadow-lg" :style="windowWidth">
                <div class="grid col-span-12 grid-cols-12 bg-gray-100 p-absolute p-fill">
                    <UISlideLeftPanel :open="showLeftPanel" @close="showLeftPanel = false">
                        <slot name="side"></slot>
                    </UISlideLeftPanel>
                    <div class="absolute top left p-3 z-5 f gap-3">
                        <BaseButton v-if="!showSidebar" icon-only rounded size="xxs" class="" style-type="secondary" icon="fas fa-list" @mouseenter="showLeftPanel = true"></BaseButton>
                        <!--                        <BaseButton v-if="!showSidebar" icon-only rounded size="xxs" class="" style-type="secondary" icon="fas fa-list" @mouseenter="(sideBarHovered = true), (showLeftPanel = true)"></BaseButton>-->
                        <BaseButton icon-only rounded size="xxs" class="" style-type="secondary" icon="fas fa-times" @click.prevent="clearPreview"></BaseButton>
                    </div>
                    <transition class="slide-right">
                        <div class="p-0 m-0 bg-snow scrollSection x" v-if="showSidebar" :class="sideBarClass" @mouseleave="sideBarHovered = false">
                            <CollapsableMenuItem v-if="mobile">
                                <template #title>
                                    <div class="py-2 pl-6 f-20 fwb x border-bottom">{{ menuTitle }}</div>
                                </template>
                                <slot name="side"></slot>
                            </CollapsableMenuItem>
                            <template v-else>
                                <slot name="side"></slot>
                            </template>
                        </div>
                    </transition>
                    <div class="scrollSection" :class="mainContainerClass">
                        <div class="x block mx-auto">
                            <div class="container-slim f-13">
                                <CollapsableMenuItem max-height="500">
                                    <template #title><div class="py-2 pl-3 f-11 z-5">Debug</div></template>
                                    <DynamicObjectViewer v-if="data" :data-object="data" class="container-slim bg-snow f-15 p-3"></DynamicObjectViewer>
                                </CollapsableMenuItem>
                            </div>
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import CollapsableMenuItem from "@/components/CoreUI/CollapsableMenuItem.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import { mapGetters } from "vuex";
import UISlideLeftPanel from "@/components/Admin/UISlideLeftPanel.vue";

export default {
    name: "CampaignPreviewWrapper",
    mixins: [styleGuideMixin],
    components: { UISlideLeftPanel, BaseButton, DynamicObjectViewer, CollapsableMenuItem },
    data() {
        return {
            sideBarHovered: false,
            showLeftPanel: false,
        };
    },
    props: {
        menuTitle: {
            type: String,
            required: true,
            default: "Campaign Preview",
        },
        campaign: {
            type: Object,
            default: () => {},
        },
        sections: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Object,
            default: () => {},
        },
        collapsed: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters("stream", ["result", "campaignData", "showCampaign"]),
        sideBarClass() {
            if (this.sideBarHovered) return "col-span-2";
            return `col-span-12 md:col-span-2`;
        },
        mainContainerClass() {
            if (this.collapsed && !this.sideBarHovered) return "col-span-12";
            if (this.mobile) return "col-span-12 md:col-span-10";
            let windowWidth = window.innerWidth;
            let largeWindow = windowWidth > 1591;
            if (largeWindow) return "col-span-12 md:col-span-10";
            if (this.sideBarHovered) return "col-span-10";
            if (!this.showSidebar) return "col-span-12";
            return "col-span-12 md:col-span-12";
        },
        showSidebar() {
            let windowWidth = window.innerWidth;
            let largeWindow = windowWidth > 1591;
            if (this.collapsed && !this.sideBarHovered) return false;
            if (largeWindow) return true;
            else if (this.sideBarHovered) return true;
            if (windowWidth > 1591) return true;
        },
        windowWidth() {
            return `max-width:100vw`;
            let windowWidth = window.innerWidth;
            if (windowWidth < 1591) return `max-width:100vw`;
            if (this.collapsed) return `max-width: 100vw`;
            // if (this.mobile || this.tablet) return `max-width:100vw`;
            return `max-width: 97vw`;
        },
    },
    methods: {
        clearPreview() {
            // route back to campaign page
            this.$router.push({ name: "campaigns" });
            this.$nextTick(() => {
                this.updateStreamProp("showCampaign", false);
            });
            // this.$emit("clearPreview");
        },
    },
};
</script>
