<template>
    <div v-if="list" class="mb-3 border-b-brand pb-3">
        <h3 class="mb-3 block lg:!f-20 pl-3 lg:pl-0" v-if="title">{{ title }}</h3>
        <div class="f gap-3 lg:flex-wrap pl-3 lg:pl-0 overflow-x-scroll scrollbar-hide">
            <div class="border-light !py-1 f aic jcc !line-height-normal mb-0 !f-15 !rounded-full px-3 whitespace-nowrap" :style="buttonStyle" v-for="i in list">{{ i }}</div>
            {{ sectionColor }}
        </div>
    </div>
</template>
<script>
export default {
    name: "PersonaSectionAlt",
    props: {
        title: {
            type: String,
            default: "",
        },
        list: {
            type: Array,
            default: [],
        },
        sectionColors: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        buttonStyle() {
            let background = "#000";
            let color = "#fff";
            let buttonStyle = this.$store?.state?.styleGuide?.styleGuide?.buttonStyles?.[0];
            if (buttonStyle) {
                background = buttonStyle?.backgroundColor;
                color = buttonStyle?.color;
            }
            if (this.sectionColors) {
                if (this.sectionColors?.color) color = this.sectionColors?.color;
                if (this.sectionColors?.bg) background = this.sectionColors?.bg;
            }
            return `background: ${background}!important; color: ${color}!important`;
        },
    },
};
</script>
<style lang="scss" scoped></style>
