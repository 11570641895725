<template>
    <div :key="`slide-${index}`" :class="slideContainerClasses" class="x vh-70 grid grid-cols-12">
        <div class="col-span-12 h-auto text-left" v-if="!mobile" :class="colSpanText">
            <div class="f jcs y z-5 px-xl-6 py-md-8 aic p-3">
                <PresentationSlideText :key="'t' + index + id" class="text-lights-3" :document="document" :document-id="document.id" :image-dark="imageDark" :index="index" :section="section"></PresentationSlideText>
            </div>
        </div>
        <div class="x relative" :class="`${randomImageStyle} ${mobile ? 'col-span-12' : colSpanImage}`">
            <!--            {{ textColSpan }}:{{ imgColSpan }}-->
            <div style="width: 5px" class="p-absolute align-fixed-center z-5 f height-50 br-50 aic jcc -left-3 cursor-grabbing bg-white opacity-10 transition-all hover:opacity-30" ref="drag_icon" @mousedown.prevent="startMove">
                <!--                <i class="fas fa-box"></i>-->
            </div>
            <PresentationImageContainer :key="`image${index}`" :document="document" :document-id="documentId" :inset="inset" :image-dark="imageDark" :index="index" :section="section"></PresentationImageContainer>
        </div>
        <div class="col-span-12 h-auto text-left" v-if="mobile">
            <div class="f jcs y z-5 px-xl-6 py-md-8 aic p-3">
                <PresentationSlideText :key="'text' + index + id" :document="document" :document-id="document.id" :image-dark="imageDark" :index="index" :section="section"></PresentationSlideText>
            </div>
        </div>
        <div class="visually-hidden col-span-1 col-span-10 col-span-11 col-span-12 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9"></div>
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import SlideStyleMixin from "@/mixins/presentations/slideStyleMixin";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import updateSlideProp from "@/mixins/ai/update_slide_prop";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin, documentManagementMixin, presentationMixin],
    props: {
        id: {},
        index: {},
        section: {},
        // document: {},
        documentId: {},
        slideContainerClasses: {},
    },
    data() {
        return {
            imageDark: false,
            inset: false,
            textColSpan: 6, // Column Span for Text (Default to 4 columns)
            imgColSpan: 6, // Column Span for Image (Default to 8 columns)
            moveStartX: 0, // Starting X-Position of Mouse Drag
            isDragging: false,
            moveEndX: 0, // Adding this new property to store the drag end position
        };
    },
    watch: {
        "document.theme.colors": {
            handler: function (val, oldVal) {
                if (val !== oldVal) {
                    this.textTheme();
                    // this.slideColors();
                }
            },
            deep: true,
        },
        // imgColSpan: function (val, oldVal) {
        //     updateSlideProp(this.$store, "textColSpan", 12 - val, this.index);
        //     updateSlideProp(this.$store, "imgColSpan", val, this.index);
        // },
    },
    computed: {
        randomImageStyle() {
            let inset = Math.random() < 0.5;
            if (inset) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.inset = true;
            }
            return inset ? this.imageInset : this.imageFullbleed;
        },
        imageInset() {
            return "p-3 py-xl-5 pr-xl-5";
        },
        imageFullbleed() {
            return "p-0";
        },
        colSpanText() {
            return `col-span-${this.textColSpan}`;
        },
        colSpanImage() {
            return `col-span-${this.imgColSpan}`;
        },
    },
    mounted() {
        if (this.document?.theme?.colors) {
            let t = this.document.theme.colors;
            this.$nextTick(() => {
                if (t) {
                    this.textTheme();
                }
            });
            // if (t) this.textTheme();
        }
        if (this.section.textColSpan) {
            this.textColSpan = this.section.textColSpan;
        }
        if (this.section.imgColSpan) {
            this.imgColSpan = this.section.imgColSpan;
        }
    },
    methods: {
        startMove(e) {
            this.moveStartX = e.clientX;
            this.isDragging = true;
            window.addEventListener("mousemove", this.moving);
            window.addEventListener("mouseup", this.endMove);
        },
        moving(e) {
            if (!this.isDragging) return;

            const pixelsPerIncrement = 50;
            const minimumColSize = 3;

            this.moveEndX = e.clientX;
            let moveAmount = this.moveStartX - this.moveEndX;

            if (Math.abs(moveAmount) >= pixelsPerIncrement) {
                let moveDirection = moveAmount > 0 ? -1 : 1;
                let moveAmountInCols = Math.floor(Math.abs(moveAmount) / pixelsPerIncrement);

                let adjustedTextColSpan = this.textColSpan + moveDirection * moveAmountInCols;
                let adjustedImgColSpan = this.imgColSpan - moveDirection * moveAmountInCols;

                if (adjustedTextColSpan < minimumColSize) {
                    adjustedTextColSpan = minimumColSize;
                    adjustedImgColSpan = 12 - minimumColSize;
                } else if (adjustedImgColSpan < minimumColSize) {
                    adjustedImgColSpan = minimumColSize;
                    adjustedTextColSpan = 12 - minimumColSize;
                }

                this.textColSpan = adjustedTextColSpan;
                this.imgColSpan = adjustedImgColSpan;
                updateSlideProp(this.$store, "textColSpan", adjustedTextColSpan, this.index);
                updateSlideProp(this.$store, "imgColSpan", adjustedImgColSpan, this.index);
                this.savePresentation(this.$route.params.id);
                this.moveStartX = this.moveEndX; // Reset starting X position whenever a column change occurs
            }
        },
        endMove() {
            this.isDragging = false;
            window.removeEventListener("mousemove", this.moving);
            window.removeEventListener("mouseup", this.endMove);
        },
    },
};
</script>
