import setupMessages from "@/mixins/ai/setup_messages";
import setupFunctions from "@/mixins/ai/setup_functions";
import setupFunctionCall from "@/mixins/ai/setup_function_call";
import messageLogger from "@/mixins/ai/message_logger";
import gptError from "@/mixins/ai/gpt_error";
import jsonFixers from "@/mixins/ai/json_helpers";
import logFunction from "@/mixins/ai/log_function";
import handleFunctionResponse from "@/mixins/ai/handle_function_response";
import { endpointURL } from "@/mixins/Chat/Commands/api_url";
import webRequest from "@/mixins/ai/web_request";

let { masterJSONFixer } = jsonFixers;
async function completion(options) {
    let {
        user, //
        system = "",
        id,
        model = "gpt-3.5-turbo",
        tokens = 2000,
        json = false,
        temperature = 0,
        sideBot = false,
        functions,
        function_call = "none",
        messages,
        returnFunction,
    } = options;
    if (messages) messages = await setupMessages(messages);
    else messages = await setupMessages(user, system);
    if (sideBot) {
        messages = user;
    }
    let request = {
        model: model || gpt3,
        messages,
        max_tokens: tokens,
        temperature: temperature,
        top_p: 0.9,
    };
    if (json) request.json_mode = true;
    if (functions) {
        request.functions = setupFunctions(functions);
        if (functions) request.function_call = setupFunctionCall(function_call, functions);
    }
    try {
        try {
            let lastMessage = messages[messages.length - 1];
            let lastMessageContent = lastMessage.content;
            if (id !== "toolbot") {
                if (functions) await messageLogger(lastMessageContent, id, request, messages, functions);
                else await messageLogger(lastMessageContent, id, request, messages);
            }
        } catch (error) {
            console.error(error);
        }
        // const chatCompletion = await openai.createChatCompletion(request);
        let request_url = endpointURL("completion");
        let chatCompletion = await webRequest("completion", request);

        chatCompletion.data = await chatCompletion.json();
        console.log(chatCompletion);
        if (returnFunction) return chatCompletion.data;
        console.groupCollapsed(`%c ✔️ 💬 ${id}`, purple);
        console.log(chatCompletion.data);
        console.groupEnd();
        let content, functionResponse, message;
        if (chatCompletion.data.choices[0]?.message) {
            message = chatCompletion.data.choices[0].message;
            if (typeof message === "string") {
                message = JSON.parse(message);
            }
            if (message.function_call) {
                functionResponse = message.function_call;
                if (functionResponse.arguments) functionResponse.arguments = JSON.parse(functionResponse.arguments);
                console.log("Function Response:", functionResponse);
            }
        }
        if (chatCompletion.data.choices[0]?.message?.content?.trim()) {
            content = chatCompletion.data.choices[0].message.content.trim();
        }

        if (functions) {
            if ((!message.content || message.content === "") && message.function_call && message.function_call.name) {
                logFunction(message, chatCompletion, content, functionResponse, messages);
                return handleFunctionResponse(message.function_call);
            } else {
                return message;
            }
        }
        let chatResponse;
        if (chatCompletion.data.choices[0].message.content) chatResponse = chatCompletion.data.choices[0].message.content.trim();

        if (!chatResponse.includes("NONE")) await messageLogger(chatResponse, id, request, messages, chatResponse);

        if (json) {
            try {
                let jsonString = masterJSONFixer(chatResponse);

                if (jsonString) {
                    console.log("JSON FROM COMPLETION METHOD", jsonString);
                    return jsonString;
                } else {
                    return chatResponse;
                }
            } catch (error) {
                console.error("Completion Error", gptError(error));
                // return chatResponse;
            }
        }

        return chatResponse;
    } catch (error) {
        console.error(error);
        console.error(gptError(error));
        return null;
    }
}

window.completion = completion;
export default completion;
