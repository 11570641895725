// main.js

// window.gpt4 = "gpt-4-0125-preview";
window.gpt4 = "gpt-4-turbo-preview";
window.gpt3 = "gpt-3.5-turbo-0125";
// window.gpt3 = "gpt-3.5-turbo-1106";
window.gpt316 = "gpt-3.5-turbo-16k";
import axios from "axios";
import { ReCaptchaV3Provider } from "firebase/app-check";
import moment from "moment";
import drag from "v-drag";
import { createApp } from "vue";
import draggable from "vuedraggable";
import { VueFire, VueFireAuth, VueFireAppCheck, databasePlugin, VueFireFirestoreOptionsAPI, VueFireDatabaseOptionsAPI, getCurrentUser } from "vuefire";
import { prettyObject, prettyObjectNew, timeAgo } from "./filters.js";
import { debounce, appHeight, showConsoleLoader } from "./utils.js";
import { delay, typingAnimation, inputLoader } from "./directives.js";
import { MotionPlugin } from "@vueuse/motion";
import App from "./App.vue";
import "/public/tailwind.css";
import "/src/tailwind.css";
import "/public/assets/main.scss";
import "primevue/resources/themes/aura-light-green/theme.css";
import firebaseApp from "./firebase";
import DataMixin from "./mixins/firebase/mixinData.js";
import MyMixin from "./mixins/mixins.js";
import AIMixin from "./mixins/ai/openAIMixins.js";
import router from "./routes.js";
import store from "@/store";
import themeMixins from "@/mixins/StyleMixins/themeMixins";
import chatmixin from "@/mixins/Chat/chatmixin";
import getAIModels from "@/mixins/firebase/getAIModels";
import VueVirtualScroller from "vue-virtual-scroller";

import { auth, onAuthStateChanged } from "@/firebase";
import { VueMasonryPlugin } from "vue-masonry";
import VueLazyload from "vue-lazyload";

import consoleMixin from "@/mixins/Tools/consoleMixin";
import aiHelper from "@/mixins/Tools/aiHelper.js";
import chalk from "chalk";
import { toRaw } from "vue";
import Anime from "./anime";
import { useLocalStorage, useMouse, usePreferredDark } from "@vueuse/core";
import chroma from "chroma-js";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { createAuth0 } from "@auth0/auth0-vue";
import PrimeVue from "primevue/config";

gsap.registerPlugin(TextPlugin);
gsap.registerPlugin(ScrollTrigger);
window.chroma = chroma;
window.gsap = gsap;

const environment = import.meta.env.VITE_ENV;

const app = createApp(App);
// app.use
// createAuth0({
//     domain: "dev-s3g8um8o375cbjan.us.auth0.com",
//     clientId: "yoAYpMiL4Tc2XVfbieYLqCyLrRzxrBRi",
//     authorizationParams: {
//         redirect_uri: window.location.origin,
//     },
// }),
// ();

if (environment !== "production") {
    // app.config.globalProperties.$log = log;
}
// app.use(signalePlugin);
app.config.globalProperties.$toRaw = toRaw;
app.config.productionTip = true;
app.use(drag);
app.use(VueVirtualScroller);
app.use(PrimeVue, { unstyled: true });
app.use(aiHelper);
app.use(VueMasonryPlugin);
app.use(moment);
app.use(databasePlugin);
app.use(VueLazyload);
app.use(MotionPlugin, {
    directives: {
        fu: {
            initial: {
                scale: 0.95,
                opacity: 0,
                y: 20,
            },
            visible: {
                scale: 1,
                opacity: 1,
                y: 0,
                transition: { delay: 50, duration: 800, type: "keyframes", ease: "backOut" },
            },
        },
    },
});

app.provide("message", "hello");
app.provide("app", "Vue3");
app.provide("mainApp", app);
app.mixin(MyMixin).mixin(DataMixin).mixin(AIMixin).mixin(themeMixins).mixin(chatmixin).mixin(getAIModels).mixin(consoleMixin);
app.use(store);
app.use(router);
app.use(Anime);
app.provide("isDarkMode", usePreferredDark());

// Add the delay directive
app.directive("delay", delay);
// app.directive("input-loader", typingAnimation);
app.directive("input-loader", inputLoader);

//mixins after store
app.config.globalProperties.$filters = {
    prettyObject,
    timeAgo,
    prettyObjectNew,
};
setTimeout(() => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 600) {
        store.commit("setMobile", true);
    } else if (windowWidth < 1024) {
        store.commit("setMobile", false);
        // Add tablet support here (e.g., width < 1024 for tablets)
        store.commit("setTablet", true); // Create a new mutation for tablet support if needed
    } else {
        store.commit("setMobile", false);
        store.commit("setTablet", false); // Reset tablet flag
    }
}, 50);
window.addEventListener(
    "resize",
    debounce(() => {
        let windowWidth = window.innerWidth;
        if (windowWidth < 600) {
            store.commit("setMobile", true);
            store.commit("setTablet", false); // Reset tablet flag on resize
        } else if (windowWidth < 1024) {
            // Add tablet support here (e.g., width < 1024 for tablets)
            store.commit("setMobile", false);
            store.commit("setTablet", true); // Create a new mutation for tablet support if needed
        } else {
            store.commit("setMobile", false);
            store.commit("setTablet", false); // Reset tablet flag
        }
    }),
);
let isAppMounted = false;
onAuthStateChanged(auth, async user => {
    // Update the Vuex store with the current user state
    // store.commit("setUser", user);
    store.commit("user/setUser", user);

    // store.user.commmit("setUser")

    // Mount the app after the authentication state is resolved
    if (!isAppMounted) {
        app.mount("#app");
        isAppMounted = true;
    }
});

// Set app height
appHeight();

// Listen for window resize and update app height
window.addEventListener("resize", appHeight);
