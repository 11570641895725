<template>
    <span v-if="style" :class="{ fc: newLines }" class="o-5 f jcs ais line-height-smaller sansSerif x monospaced ais f-11 my-0 flex-wrap text-left" style="line-height: 1 !important">
        <!--        <span v-if="style.type" class="">Kind: {{ style.type }}</span>-->
        <!--        <span v-if="style.tag" class="">Kind: {{ style.tag }}</span>-->
        <!--        <span v-if="style.kind" class="">Font: {{ style.kind }}</span>-->
        <!--        <span v-if="style.fontFamily" class="">Font: {{ style.fontFamily }}</span>-->
        <!--        <span v-if="style.fontWeight" class="">Weight: {{ style.fontWeight }}</span>-->
        <!--        <span v-if="style.fontStyle" class="">Style: {{ style.textTransform }} {{ style.fontStyle }}</span>-->
        <!--        <span v-if="style.fontSize" class="">Size: {{ style.fontSize }}</span>-->
        <!--        <span v-if="style.lineHeight" class="">Line Height: {{ style.lineHeight }}</span>-->
        <!--        <span v-if="style.letterSpacing" class="">Spacing: {{ style.letterSpacing }}</span>-->
        <!--        <span v-if="style.border && !style.border.includes('0px')" class="">Border: {{ style.border }}</span>-->
        <!--        <span v-if="style.boxShadow && style.boxShadow !== 'none'" class="">Box Shadow: {{ style.boxShadow }}</span>-->
        <!--        <span v-if="style.background" class="">Background: {{ style.background }}</span>-->
        <!--        <span v-if="style.backgroundColor" class="">Background Color: {{ style.backgroundColor }}</span>-->
        <!--        <span v-if="style.padding && style.padding !== '0px'" class="">Padding: {{ style.padding }}</span>-->
        <!--        <span v-if="style.margin && style.margin !== '0px'" class="">Margin: {{ style.margin }}</span>-->
        <!--        <span v-if="style.textAlign" class="">Align: {{ style.textAlign }}</span>-->
        <!--        <span v-if="style.textTransform" class="">Transform: {{ style.textTransform }}</span>-->
        <!--        <span v-if="style.textDecoration" class="">Decoration: {{ style.textDecoration }}</span>-->
        <!--        <span v-if="style.color" class="">Color: {{ style.color }}</span>-->
        <!--        <span v-if="style.opacity && style.opacity !== '1'" class="">Opacity: {{ style.opacity }}</span>-->
        <!--        <span v-if="style.textTransform" class="">Text transform: {{ style.textTransform }}</span>-->

        <span v-for="item in formatProperties(style)" class="mb-0 pr-3 text-left" v-html="item"></span>
    </span>
</template>
<script>
export default {
    name: "TextStyleDetails",
    props: {
        style: {},
        newLines: {
            type: Boolean,
            default: false,
        },
    },
    computed: {},
    mounted() {
        // this.;
    },
    methods: {
        formatProperties(properties) {
            // Function to split camelCase into words and convert to uppercase

            // type
            let order = [
                "tag",
                //
                "fontFamily",
                "fontWeight",
                "fontSize",
                "lineHeight",
                "letterSpacing",
                "textTransform",
                "fontStyle",
                "kind",
                "border",
                "boxShadow",
                "background",
                "backgroundColor",
                "padding",
                "margin",
                "textAlign",
                "textDecoration",
                "color",
                "opacity",
            ];

            const splitCamelCase = str => {
                return str
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .split(/[\s-]+/)
                    .map(word => word)
                    .join(" ");
            };

            const orderedProperties = {};
            // Add properties in the specified order
            order.forEach(key => {
                if (properties[key] !== undefined) {
                    orderedProperties[key] = properties[key];
                }
            });

            // Add remaining properties that were not in the order array
            Object.keys(properties).forEach(key => {
                if (!orderedProperties.hasOwnProperty(key)) {
                    orderedProperties[key] = properties[key];
                }
            });
            let keysToRemove = ["texts", "text", "type", "kind"];
            keysToRemove.forEach(key => {
                if (orderedProperties.hasOwnProperty(key)) {
                    delete orderedProperties[key];
                }
            });
            const outputParts = [];
            for (const [key, value] of Object.entries(orderedProperties)) {
                let formattedValue = typeof value === "string" ? value : value;
                outputParts.push(`<span class="fwb text-capitalize pr-1">${splitCamelCase(key)}:</span><span class="inline-block" style="word-break: break-all;">${formattedValue}</span>\n`);
            }

            return outputParts;
        },
        //         formatProperties(properties) {
        //             // Function to split camelCase into words and convert to uppercase
        //
        //             // type
        //             let order = [
        //                 "tag",
        //                 //
        //                 "fontFamily",
        //                 "fontWeight",
        //                 "fontSize",
        //                 "lineHeight",
        //                 "letterSpacing",
        //                 "textTransform",
        //                 "fontStyle",
        //                 "kind",
        //                 "border",
        //                 "boxShadow",
        //                 "background",
        //                 "backgroundColor",
        //                 "padding",
        //                 "margin",
        //                 "textAlign",
        //                 "textDecoration",
        //                 "color",
        //                 "opacity",
        //             ];
        //
        //             const splitCamelCase = str => {
        //                 return str
        //                     .replace(/([a-z])([A-Z])/g, "$1 $2")
        //                     .split(/[\s-]+/)
        //                     .map(word => word)
        //                     .join(" ");
        //             };
        //
        //             const outputParts = [];
        //
        //             for (const [key, value] of Object.entries(properties)) {
        //                 let formattedValue = typeof value === "string" ? value : value;
        //                 outputParts.push(`<span class="fwb text-capitalize pr-1">${splitCamelCase(key)}:</span><span class=" inline-block" style="word-break: break-all;">${formattedValue}</span>
        // `);
        //             }
        //             return outputParts;
        //         },
    },
};
</script>
