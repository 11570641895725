import { doc, getDoc, query, collection, orderBy, getDocs } from "firebase/firestore";
import { db } from "@/firebase";
import { logFetchData, logFetch } from "@/mixins/firebase/firebaseHelpers.js";

async function fetchData(database, id, sort) {
    logFetch(database, id);

    if (id) {
        // Get a single document; will be cached by Firestore if persistence is enabled
        const docRef = doc(db, database, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let data = docSnap.data();
            data.id = docSnap.id;
            logFetchData("single", database, id, data);
            return data;
        } else {
            console.log("No such document!");
        }
    } else {
        // Get a collection of documents; will be cached by Firestore if persistence is enabled
        let q;
        if (sort) {
            q = query(collection(db, database), orderBy("updated", "desc"));
        } else {
            q = query(collection(db, database));
        }
        const querySnapshot = await getDocs(q);

        let documents = querySnapshot.docs.map(doc => {
            let data = doc.data();
            data.id = doc.id;

            return data;
        });
        // logFetchData("collection", database, null, documents);
        return documents;
    }
}
export default fetchData;
