<template>
    <div v-if="document && document.theme" class="f aic jcc mr-2 gap-1" @mouseover="showPicker = true" @mouseleave="hoverOut">
        <div id="primaryColorPicker" v-if="document.theme?.colors?.primary" :class="{ 'border border-solid border-base-900': selectedColor === 'primary' }" :style="`background:${document.theme.colors.primary}`" class="c-20 border-light" @click.prevent="selectColor('primary')"></div>
        <div id="secondaryColorPicker" v-if="document.theme?.colors?.primary" :class="{ 'border border-solid border-base-900': selectedColor === 'secondary' }" :style="`background:${document.theme.colors.secondary}`" class="c-20 border-light" @click.prevent="selectColor('secondary')"></div>
        <div v-if="showPicker && selectedColor" class="p-fixed top-9 z-10 pt-5">
            <div :style="{ background: color }">
                <ColorPicker :color="color" :sucker-area="suckerArea" :sucker-hide="true" :colors-default="[]" :sucker-canvas="suckerCanvas" class="mw-250 w-auto" theme="dark" @changeColor="changeColor" @inputBlur="inputBlur" @inputFocus="inputFocus" @openSucker="openSucker" />
              import { ColorPicker } from "vue-color-kit";
                <div class="done">Done</div>
            </div>
        </div>
        <!--        <div class="whitespace-pre-wrap">{{ documentColors }}</div>-->
    </div>
</template>
<script>
import { ColorPicker } from "vue-color-kit";
import "vue-color-kit/dist/vue-color-kit.css";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import updateDocumentProp from "@/mixins/ai/update_document_prop";
import sleep from "@/mixins/Chat/Commands/delay_timer";
import { mapActions } from "vuex";
export default {
    name: "ThemeColorPicker",
    components: {
        ColorPicker,
    },
    mixins: [presentationMixin],
    data() {
        return {
            color: "#59c7f9",
            suckerCanvas: null,
            suckerArea: [],
            isSucking: false,
            showPicker: false,
            selectedColor: null,
            documentColors: null,
        };
    },
    computed: {
        document() {
            return this.$store.state.presentation.document;
        },
    },
    methods: {
        ...mapActions("presentation", ["savePresentation"]),
        async updateColorsOnTheme() {
            updateDocumentProp(this.$store, "hideSlides", true);
            let colors = {
                primary: this.document?.theme?.colors?.primary || "#000000",
                secondary: this.document?.theme?.colors?.secondary || "#000000",
            };
            this.documentColors = colors;
            let updatedColors = this.getColorScheme(colors); // Pass colors object to getColorScheme method
            updateDocumentProp(this.$store, "theme", updatedColors);
            console.log("updated colors", updatedColors);
            this.setColors(updatedColors.colors);
            // await sleep(100);
            updateDocumentProp(this.$store, "hideSlides", false);
        },
        saveDocumentColors() {
            let colors = this.documentColors;
            // let primaryString = JSON.stringify(colors.primary).replace(`hsl(`, "").replace(`)`, "");
            // let secondaryString = JSON.stringify(colors.secondary).replace(`hsl(`, "").replace(`)`, "");
            // primaryString = primaryString;
            // secondaryString = secondaryString
            let object = {
                primary: `${colors?.primary}` || "#000000",
                // primary: `${primaryString}`,
                secondary: `${colors?.secondary}` || "#000000",
                // secondary: `${secondaryString}`,
            };
            console.log(object);
            updateDocumentProp(this.$store, "theme_colors_hsl", object);
            // this.debounceSaveDeck();
        },
        selectColor(color) {
            console.log("color");
            this.selectedColor = color;
            let convertedColor = this.hslToHex(this.document.theme.colors[color]);
            console.log(convertedColor);
            this.color = convertedColor;
            this.showPicker = true;
        },
        hoverOut() {
            this.showPicker = false;
            this.selectedColor = null;
            this.saveDocumentColors();
            this.savePresentation(this.$route.params.id);
        },
        changeColor(color) {
            // const { h, s, l } = color.hsl;
            // this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
            console.log(color);
            this.color = color.hex;
            console.log(this.color);
            this.updateColor(this.color);
        },
        updateColor(color) {
            let convertedColor = this.hexToHSL(color);
            const selectedColor = this.selectedColor;
            let updateString = `theme.colors.${selectedColor}`;
            console.log("Converted to HSL", convertedColor);
            updateDocumentProp(this.$store, updateString, convertedColor);
            this.updateColorsOnTheme();
        },
        openSucker(isOpen) {
            if (isOpen) {
                // ... canvas be created
                // this.suckerCanvas = canvas
                // this.suckerArea = [x1, y1, x2, y2]
            } else {
                // this.suckerCanvas && this.suckerCanvas.remove
            }
        },
        inputFocus(event) {
            console.log(event);
            // this will get triggered on input field (hex and rgba) get focus
            // prop value will be FocusEvent object associated with the input
        },
        inputBlur(event) {
            console.log(event);
            // this  will get triggeredon input field (hex and rgba) get out of focus (blur event)
            // prop value will be FocusEvent object associated with the input
        },
    },
};
</script>
