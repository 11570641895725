<template>
    <div :class="{ 'mb-4': !inline }" class="select-wrapper">
        <label v-if="label" :for="id" class="label">{{ label }}</label>
        <select :id="id" v-model="currentValue" class="select" :class="{ 'm-0': inline, disabled: disabled }" @change="onChange" @blur="$emit('blur', $event)" @focus="$emit('focus', $event)" @input="$emit('input', $event)" :disabled="disabled">
            <option disabled value="">{{ placeholder }}</option>
            <template v-for="option in options" :key="option.value" :value="option">
                <option disabled value="" v-if="option.value === 'OpenAI' || option.value === 'ANTHROPIC'">{{ option.label }}</option>
                <option v-else :value="option.value">{{ option.label }}</option>
                <!--                <option v-if="option.label" :value="option.value">{{ option.label }}</option>-->
                <!--                <option :value="option.value">{{ option.label }}</option>-->
            </template>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        vuexKey: {
            type: String,
            default: null,
        },
        id: String,
        inline: Boolean,
        label: String,
        modelValue: {
            type: [String, Number, Object],
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: String,
    },
    watch: {
        currentValue(newValue) {
            if (this.vuexKey) {
                localStorage.setItem(this.vuexKey, newValue);
                if (typeof newValue === "object") {
                    let newValueStr = JSON.stringify(newValue);
                    console.error(newValue);
                    localStorage.setItem(this.vuexKey, newValueStr);
                }
                if (this.vuexKey.includes(".") || this.vuexKey.includes("/")) {
                    const [moduleName, prop] = this.vuexKey.split(/[./]/);
                    this.$store.commit(`${moduleName}/updateDropdownValue`, { key: prop, value: newValue });
                } else {
                    this.$store.commit("updateToggleValue", { key: this.vuexKey, value: newValue });
                }
            } else {
                localStorage.setItem(this.vueKey, newValue);
            }
        },
    },
    mounted() {
        const savedValue = localStorage.getItem(this.vuexKey || this.vueKey);
        const parsedValue = savedValue === "true"; // Convert savedValue to boolean

        if (this.vuexKey) {
            if (this.vuexKey.includes(".") || this.vuexKey.includes("/")) {
                const [moduleName, prop] = this.vuexKey.split(/[./]/);
                this.$store.commit(`${moduleName}/updateDropdownValue`, { key: prop, value: parsedValue });
            } else {
                this.$store.commit("updateDropdownValue", { key: this.vuexKey, value: parsedValue });
            }
        } else {
            this.$emit("update:vueKey", parsedValue);
        }
    },
    computed: {
        currentValue: {
            get() {
                if (this.vuexKey) {
                    if (this.vuexKey.includes(".") || this.vuexKey.includes("/")) {
                        const [moduleName, prop] = this.vuexKey.split(/[./]/);
                        return this.$store.state[moduleName][prop];
                    } else {
                        return this.$store.state[this.vuexKey];
                    }
                } else {
                    return this.modelValue;
                }
            },
            set(value) {
                // const savedValue = localStorage.getItem(this.vuexKey || this.vueKey);
                // const parsedValue = savedValue === value; // Convert savedValue to boolean
                if (this.vuexKey) {
                    if (this.vuexKey.includes(".") || this.vuexKey.includes("/")) {
                        const [moduleName, prop] = this.vuexKey.split(/[./]/);
                        let finalValue = value;
                        if (typeof value === "object") {
                            // finalValue = value.id;
                            let newValueStr = JSON.stringify(value);
                            localStorage.setItem(this.vuexKey, newValueStr);
                            console.error(this.vuexKey, newValueStr);
                        }
                        this.$store.commit(`${moduleName}/updateDropdownValue`, { key: prop, value: finalValue });
                    } else if (this.vuexKey) {
                        this.$store.commit("updateDropdownValue", { key: this.vuexKey, value });
                        this.$emit("update:modelValue", value); // Emit the update event
                    }
                } else {
                    this.$emit("update:modelValue", value);
                }
            },
        },
    },
    methods: {
        onChange() {
            console.error(this.modelValue);
            return;
            if (this.vuexKey) {
                if (this.vuexKey.includes(".") || this.vuexKey.includes("/")) {
                    const [moduleName, prop] = this.vuexKey.split(/[./]/);
                    this.$store.commit(`${moduleName}/updateDropdownValue`, { key: prop, value: $event });
                } else {
                    this.$store.commit("updateDropdownValue", { key: this.vuexKey, value: $event });
                }
            } else {
                localStorage.setItem(this.vueKey, newValue);
                this.$emit("change");
            }
        },
    },
    emits: ["update:modelValue", "enter", "blur", "focus", "input", "change"],
};
</script>
