import getTokenCount from "@/mixins/ai/get_token_count";
import { toRaw } from "vue";

function logFunction(content, message, role) {
    try {
        let args;
        try {
            args = JSON.parse(content);
        } catch (e) {
            args = content;
        }
        let name = message.name;
        // switch name to title case
        name = name.charAt(0).toUpperCase() + name.slice(1);
        console.groupCollapsed(`%c 🚀 💬 ${name}`, purple, "Posting Response");
        console.log(content);
        let mapArgs = {};
        mapArgs["name"] = {
            value: name,
        };
        Object.entries(args).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    let role = "";
                    let val = "";
                    let item = value[i];

                    if (key) role = `${key} ${i}`;
                    if (item) val = item;

                    mapArgs[role] = {
                        value: val,
                    };
                }
            } else {
                mapArgs[key] = {
                    value: value,
                };
            }
        });
        console.table(mapArgs);
        return;
    } catch (e) {
        let args = content;
        let name = message.name;
        console.groupCollapsed(`%c 💬 ${name}`, purple, "Posting Response");
        console.log("%c" + args, txt);
        console.groupEnd();

        console.error(e);
        return;
    }
}
function logMessageAsTable(messages) {
    try {
        let mapMessages = {};
        for (let i = 0; i < messages.length; i++) {
            let role = "";
            let content = "";
            let message = messages[i];

            if (message.role) role = `${i}: ${message.role}`;
            else role = "AI";
            if (message.name) role = `${role} (${message.name})`;

            if (message.content) content = message.content;
            else if (message.function_call) content = message.function_call;
            else content = "";

            mapMessages[role] = {
                content: content,
            };
        }

        console.table(mapMessages);
        mapMessages = {};
    } catch (e) {
        console.error(e);
    }
}
async function messageLogger(message, from, requestData, messages, response, functions, role = "") {
    try {
        let model;
        let content = message.content || "";
        if (role && role === "function") {
            logFunction(content, message, role);
            // console.groupCollapsed(`%c ✔️ 💬 ${from} ${role}`, purple, name);
        } else if (response) {
            // console.log(response);
            if (response.function_call && response.function_call.name) {
                let functionCall = response.function_call;
                console.groupCollapsed(`%c ✔️ 💬 ${from} ${role}`, rose, functionCall.name + `...`);
                // if (content !== "") console.log("%c" + content, txt);
                if (content !== "") console.log("%cContent", `color: black;font-weight:bold;`, content);

                console.log("%c" + JSON.stringify(functionCall), txt);
            } else {
                console.groupCollapsed(`%c ✔️ 💬 ${from} ${role}`, info, content.slice(0, 20) + `...`);
            }
            // console.log("%c" + content, txt);
            console.log("%cContent", `color: black;font-weight:bold;`, content);
        } else {
            console.groupCollapsed(`%c 💬 ${from} ${role}`, info, content.slice(0, 20) + `...`);
            console.log("%cContent", `color: black;font-weight:bold;`, content);
        }
        if (requestData?.model) model = requestData.model;
        if (model) {
            if (response) await getTokenCount(content, model, response, from);
            else await getTokenCount(messages, model, response, from);
        }
        if (message.finish_reason) {
            console.log(`%c🛑 Finish Reason`, `color: darkred;font-weight:700;`, message.finish_reason);
        }
        if (response?.function_call) {
            let function_call = response.function_call;
            console.groupCollapsed("%c🤖 Had Function Object", purple);
            console.table({ name: function_call.name, ...function_call.arguments });
            console.groupCollapsed("Raw");
            console.table(function_call);
            console.groupEnd();
            console.groupEnd();
            console.groupCollapsed("💬 Message Object");
            console.log(message);
            consoleTableReduced(message, ["function_call"]);
            console.groupEnd();
        } else {
            console.groupCollapsed("💬 Message Object");
            console.log(message);
            consoleTableReduced(message, ["function_call"]);
            console.groupEnd();
        }

        if (requestData) {
            console.groupCollapsed("📞 Request Data");
            consoleTableReduced(requestData, ["messages"]);
            console.groupEnd();
        }
        if (functions) {
            try {
                if (functions?.length > 0) {
                    functions.forEach(func => {
                        console.table(func);
                    });
                }
            } catch (e) {
                console.log("%c🤖 Functions", `color: magenta;font-weight:bold;`, `${functions}`);
            }
        }
        if (messages?.length > 0) {
            console.groupCollapsed(`📚 Messages (${messages.length})`);
            logMessageAsTable(messages);
            if (messages) console.log(toRaw(messages));
            console.groupCollapsed(`%c Cleaned messages...`, "color:gray;font-size:10px;font-weight:normal;");
            messages.forEach(newMessage => {
                console.log(`%c ${newMessage.role}: ${newMessage.name ? `(${newMessage.name})` : ""}: ${newMessage.content}`, "color:gray;font-size:10px;font-weight:normal;");
            });
            console.groupEnd();
            console.groupEnd();
        }

        console.groupEnd();
        return;
    } catch (e) {
        console.error("Error logging message:", e);
    }
    return;
}
export default messageLogger;
