import { db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";

export default {
    namespaced: true,
    state() {
        return {
            memory: [],
        };
    },
    mutations: {
        addMemory(state, payload) {
            state.memory.push(payload);
        },
        removeMemory(state, payload) {
            const index = state.memory.indexOf(payload);
            if (index > -1) {
                state.memory.splice(index, 1);
            }
        },
        setMemories(state, payload) {
            state.memory = payload;
        },
    },
    actions: {
        async addMemoryToStore({ commit, state, rootState }, mem) {
            try {
                const chatRef = db.collection("chats").doc(rootState.chat.id);
                const updatedMemories = [...state.memory, mem];
                await chatRef.update({ memory: updatedMemories });
                commit("addMemory", mem);
                console.log("Memory added successfully!");
            } catch (error) {
                console.error("Error adding memory: ", error);
            }
        },
        async removeMemoryFromStore({ commit, state, rootState }, mem) {
            try {
                const chatRef = db.collection("chats").doc(rootState.chat.id);
                const updatedMemories = state.memory.filter(m => m.event !== mem.event || m.createdAt !== mem.createdAt);

                await chatRef.update({ memory: updatedMemories });
                commit("removeMemory", mem);

                console.log("Memory removed successfully!");
            } catch (error) {
                console.error("Error removing memory: ", error);
            }
        },
        async fetchMemories({ commit, state, rootState }) {
            try {
                const chatRef = db.collection("chats").doc(rootState.chat.id);
                const docSnapshot = await getDoc(chatRef);

                if (!docSnapshot.exists()) {
                    console.log("No such document!");
                } else {
                    commit("setMemories", docSnapshot.data().memory || []);
                    console.log("Memories fetched successfully!");
                }
            } catch (error) {
                console.error("Error fetching memories: ", error);
            }
        },
    },
    getters: {
        memory: state => state.memory,
    },
};
