<template>
    <div class="x">
        <UIProductPage class="xblock" v-if="item?.product || item.kind === 'product'" :product="item"></UIProductPage>
        <markdown class="prose" v-if="item.content" :content="item.content"></markdown>
        <markdown class="prose" v-if="item.text" :content="item.text"></markdown>
        <ChatSourcesLinks v-if="item?.links" :list="item?.links" />
        <!--        <dynamic-object-viewer :data-object="item" />-->
    </div>
</template>
<script>
import Markdown from "@/components/chat/specialMessages/Markdown.vue";
import ChatSourcesLinks from "@/views/ChatSourcesLinks.vue";
import UIProductPage from "@/views/UIProductPage.vue";
import DynamicObjectViewer from "@/components/generative_templates/Tools/DynamicObjectViewer.vue";

export default {
    name: "SourceDetails",
    components: { DynamicObjectViewer, UIProductPage, ChatSourcesLinks, Markdown },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
