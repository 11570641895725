<template>
    <div v-if="user">
        <div class="flex items-center justify-center w-full">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-3 border-gray-900 border-opacity-30 border-dashed rounded-lg cursor-pointer bg-gray-50/50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100/50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600" @dragover.prevent @drop.prevent="onDrop">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400 animated swingInUp duration-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 animated swingInUp duration-3 d-2">
                        <span class="font-semibold">Click to upload</span>
                        or drag and drop
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400 animated swingInUp duration-3 d-4">PNG, JPG or GIF</p>
                </div>
                <input id="dropzone-file" type="file" accept="image/*" @change="onFileChange" class="hidden visually-hidden" />
            </label>
        </div>
    </div>
</template>

<script>
import user from "@/mixins/usermixin";
import imageMixin from "@/mixins/images/imageMixin";
import webRequest from "@/mixins/ai/web_request";

export default {
    mixins: [user, imageMixin],
    data() {
        return {
            email: "",
        };
    },
    methods: {
        async onFileChange(event) {
            const file = event.target.files[0];
            if (!file) return;
            await this.uploadImageFile(file);
        },

        async onDrop(event) {
            const file = event.dataTransfer.files[0];
            if (!file) return;
            await this.uploadImageFile(file);
        },
        async uploadImageFile(file, name, type = "image/png") {
            try {
                let object = {
                    name: name || `whaatttt`,
                    mimetype: type,
                };

                let image = await this.uploadFileAsImageCF(file, object);
                if (image) {
                    console.log(image);
                    this.$emit("photoUploaded", image);
                }
            } catch (error) {
                console.error("Error uploading user photo:", error);
            }
        },
    },
};
</script>
