<template>
    <div class="h-full">
        <div class="f mw-500 h-auto x jcb br-10 aic bg-snow p-2 pl-3 pr-2 shadow" @click.prevent="toggleShowData">
            <div v-if="progressStep.name !== 'Thoughts'" class="jcs info_line aic flex gap-1">
                <div>
                    <span v-if="shouldShowImagesCount" :class="progressStepNameClass">Sourced {{ progressStep.data.length }} Images</span>
                    <span v-else-if="progressStep.name" :class="progressStepNameClass">
                        {{ progressStep.name }}
                    </span>
                    <template v-if="progressStepTextIsArray">
                        <template v-if="!(hideIfComplete && progressStep.complete)">
                            <template v-if="progressStep.text.length === 1">
                                <span class="list-disc f-15 text-gray-500 block w-100 pt-1 pb-2">{{ progressStep.text[0] }}</span>
                            </template>
                            <ul class="list-disc f-15 text-gray-500 block w-100 px-3 pt-1 pb-2" v-else>
                                <li v-for="step in progressStep.text">{{ step }}</li>
                            </ul>
                        </template>
                    </template>
                    <span v-else-if="progressStep.text" :class="progressStepTextClass">
                        {{ progressStep.text }}
                    </span>
                </div>
            </div>
            <template v-else>
                <div class="jcs info_line">
                    <p v-if="progressStep.text" class="f-17 whitespace-pre-line line-clamp-2">{{ progressStep.text }}</p>
                </div>
            </template>
            <transition enter-active-class="animated zoomIn duration-2 elastic-ease" leave-active-class="animated zoomOut duration-1" mode="out-in">
                <div v-if="progressStep.complete" :class="[stepClasses, 'c-25', 'border-light', 'f', 'aic', 'jcc', 'ml-3', 'transition-all', 'mr-2']">
                    <div class="animated zoomIn duration-3">
                        <transition enter-active-class="animated zoomIn duration-2 elastic-ease">
                            <i v-if="progressStep.complete" class="fas fa-check text-shadow white"></i>
                        </transition>
                        <i v-if="!progressStep.complete" class="far fa-sync fa-spin transition-1 duration-4 f-15 ease-back"></i>
                    </div>
                </div>
            </transition>
            <div v-if="!progressStep.complete" class="f aic jcc f-13" :class="[stepClasses, 'c-25', 'border-light', 'f', 'aic', 'jcc', 'ml-3', 'transition-all', 'mr-2']">
                <div class="animated zoomIn duration-3">
                    <transition enter-active-class="animated zoomIn duration-2 elastic-ease">
                        <i v-if="progressStep.complete" class="fas fa-check text-shadow white"></i>
                    </transition>
                    <i v-if="!progressStep.complete" class="far fa-sync fa-spin transition-1 duration-4 f-15 ease-back"></i>
                </div>
            </div>
<!--            <div v-else class="f aic jcc f-13" :class="[stepClasses, 'c-25', 'border-light', 'f', 'aic', 'jcc', 'ml-3', 'transition-all', 'mr-2']">-->
<!--                <div class="animated zoomIn duration-3">-->
<!--                    <div>-->
<!--                        <span v-if="shouldShowImagesCount" :class="progressStepNameClass">Sourced {{ progressStep.data.length }} Images</span>-->
<!--                        <span v-else-if="progressStep.name" :class="progressStepNameClass">-->
<!--                            {{ progressStep.name }}-->
<!--                        </span>-->
<!--                        <template v-if="progressStepTextIsArray">-->
<!--                            <template v-if="!(hideIfComplete && progressStep.complete)">-->
<!--                                <template v-if="progressStep.text.length === 1">-->
<!--                                    <span class="list-disc f-15 text-gray-500 block w-100 pt-1 pb-2">{{ progressStep.text[0] }}</span>-->
<!--                                </template>-->
<!--                                <ul class="list-disc f-15 text-gray-500 block w-100 px-3 pt-1 pb-2" v-else>-->
<!--                                    <li v-for="step in progressStep.text">{{ step }}</li>-->
<!--                                </ul>-->
<!--                            </template>-->
<!--                        </template>-->
<!--                        <span v-else-if="progressStep.text" :class="progressStepTextClass">-->
<!--                            {{ progressStep.text }}-->
<!--                        </span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div v-if="progressStep.data">
            <slot></slot>
            <div v-if="showData" class="whitespace-pre-wrap p-3">{{ progressStep.data }}</div>
        </div>
    </div>
    <!--        </transition>-->
</template>
<script>
export default {
    name: "PresentationProgressStep",
    props: {
        progressStep: {
            type: Object,
            default: () => ({
                name: "Loading",
                text: "",
                complete: false,
            }),
        },
        hideIfComplete: {
            type: Boolean,
            default: false,
        },
        fontSize: {
            type: [Number, String],
            default: 20,
        },
    },
    computed: {
        shouldShowImagesCount() {
            return this.progressStep.name === "Sourcing images" && this.progressStep.data;
        },
        progressStepNameClass() {
            return `fwb text-capitalize f-${this.fontSize} mr-1`;
        },
        progressStepTextIsArray() {
            return Array.isArray(this.progressStep.text);
        },
        progressStepTextClass() {
            return {
                "line-height-smaller": true,
                block: true,
                "f-15": true,
                "w-100": true,
                "o-5": true,
                "whitespace-pre-line": true,
                "pb-3": this.progressStep?.text?.length > 20,
            };
        },
        stepClasses() {
            if (this.progressStep?.complete) {
                return "bg-green-300";
            } else if (this.progressStep?.name.includes("Summar")) {
                return "bg-sky-300 text-snow duration-1";
            } else {
                return "bg-gray-100 text-gray-300 duration-1";
            }
        },
    },
    methods: {
        toggleShowData() {
            this.showData = !this.showData;
        },
    },
    data() {
        return {
            showData: false,
        };
    },
};
</script>
<style lang="scss">
@keyframes figureEight {
    0% {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}
</style>
