<template>
    <div class="f fc animated fadeInUpSmooth mb-3">
        <p class="width-min-150 text-capitalize x f aic jcb mb-1">
            {{ name }}
            <strong>{{ value }}%</strong>
        </p>
        <div class="border border-light bg-gray-900/5 rounded-full h-2 x" :style="`border:1px solid ${colorOpacity};`">
            <div :style="[{ width: displayValue + '%' }, `background:${color};border:1px solid ${colorOpacity}`]" class="bg-base-900 rounded-full h-full transition-all ease-back duration-3"></div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import chroma from "chroma-js";
export default {
    name: "PercentageBarDisplay",
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
            default: 0,
        },
        index: {
            type: Number,
            required: false,
            default: 1,
        },
    },
    data() {
        return {
            displayValue: 0,
        };
    },
    computed: {
        ...mapGetters("styleGuide", ["divStyles", "backgroundColors", "baseColor"]),
        // color() {
        //     let color = this.divStyles?.[0]?.color;
        //     return chroma(color).alpha(0.5).css();
        // },
        colorOpacity() {
            let color = this.color;
            return chroma(color).alpha(0.1).css();
        },
        color() {
            return chroma(this.baseColor).alpha(1).css();
        },
        background() {
            let background = this.backgroundColors?.[0];
            return chroma(background).alpha(1).css();
        },
    },
    mounted() {
        let index = this.index || 1;
        setTimeout(() => {
            this.displayValue = this.value;
        }, 200 * index);
    },
};
</script>
