<template>
    <div>
        <div v-if="radial" class="p-absolute p-fill" :class="{ 'transition-all': !offset }" :style="`background-image: radial-gradient(circle, ${color} 0%,  ${colorTransparent} 50%);background-position:center center;background-size:cover;`"></div>
        <Transition name="fade-scale">
            <div v-if="top" :style="`background:linear-gradient(${color} 0%,${colorTransparent} ${offset}%);height: ${height}px`" class="p-absolute top left right" :class="{ 'transition-all': !offset }"></div>
            <div v-else :style="`background:linear-gradient( ${colorTransparent} 0%, ${color} ${offset}%);height: ${height}px`" class="p-absolute bottom left right" :class="{ 'transition-all': !offset }"></div>
        </Transition>
    </div>
</template>
<script>
import chroma from "chroma-js";

export default {
    name: "ImageGradient",
    props: {
        image: {
            type: Object,
            default: () => ({
                color: "rgb(0,0,0)",
                colorTransparent: "",
            }),
        },
        height: { default: 50 },
        radial: { default: false, type: Boolean },
        top: { default: false, type: Boolean },
        offset: {
            default: 100,
            type: Number,
        },
    },
    computed: {
        color() {
            return this.image?.color;
        },
        colorTransparent() {
            let color = this.color;
            if (!this.image?.colorTransparent) {
                try {
                    color = chroma(color).alpha(0.001).css();
                } catch (e) {}
            } else {
                color = this.image.colorTransparent;
            }
            return color;
        },
    },
};
</script>
