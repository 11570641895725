const indicators = false;
export default {
    mixins: [],
    data() {
        return {
            // styleIds: [],
            // fontLinks: []
        };
    },
    computed: {
        emailTransparentContainerClasses() {
            // this is the container
            let classes = [];
            if (this.isNormal) return this.normalImage.container;
            if (this.isFull) return this.fullImage.container;
            if (this.isRotated) return this.rotatedImage.container;
            return classes.join(" ");
        },
        rotatedImage() {
            if (this.isRotated) {
                let classes = {
                    image: ["y x o-contain rotate-20"],
                    container: ["block x y mx-auto o-contain"],
                    inset: [""],
                };
                if (indicators) classes.container.push("bg-green-400/20");

                if (this.isInset) {
                    classes.image.push("height-max-350 !py-0 !my-0");
                    if (!this.fullBleed) classes.inset.push("!pt-5 mt-5 px-5 !-mb-4");
                    else classes.inset.push("!pt-0 mt-5 px-5");
                } else {
                    classes.container.push("!pt-0");
                    classes.image.push("height-max-500");
                    classes.inset.push("!mt-5 !pt-5");
                }
                if (this.showLogo && !this.fullBleed) {
                    classes.container.push("");
                    if (this.isInset) classes.inset.push("!mt-8 !pt-7");
                    else classes.inset.push("!mt-8 !pt-3 pb-4");

                    classes.container.push("mt-5 !mb-0");
                    classes.inset.push("!mb-0");
                }
                if (this.fullBleed) classes.inset.push("mb-4");

                ["image", "container", "inset"].forEach(prop => (classes[prop] = classes[prop].join(" ")));
                return classes;
            }
        },
        fullImage() {
            if (this.isFull) {
                let classes = {
                    image: ["y x o-contain height-max-500"],
                    container: ["block x y mx-auto mb-0 o-contain height-max-500"],
                    inset: ["px-5 py-3"],
                };
                if (indicators) classes.container.push("bg-green-400/20");

                if (this.isInset) {
                    classes.container.push("!height-max-500");
                    classes.image.push("!height-max-500");
                } else {
                    if (indicators) classes.container.push("bg-sky-400/30");
                    classes.container.push("!height-max-700");
                    classes.container.push("");
                    classes.image.push("!height-max-700");
                }

                if (this.fullBleed) {
                    classes.inset.push("!mt-5 -mb-4");
                } else {
                    classes.container.push("!height-700");
                    classes.image.push("!height-700");
                    classes.image.push("!height-450");
                    classes.inset.push("px-3");
                    if (this.showLogo) {
                        if (this.isInset) classes.inset.push("!mt-0 !pt-0 mb-0 !pb-0");
                        if (this.isInset) classes.inset.push("!mt-0 !pt-0 mb-0 !pb-0");
                        classes.container.push("mt-7 !-mb-5");
                    }
                }

                ["image", "container", "inset"].forEach(prop => (classes[prop] = classes[prop].join(" ")));
                return classes;
            }
        },
        normalImage() {
            // TODO was in imageNormalClasses
            // if (this.isEmail) {
            //     if (this.isRound || this.isSquare) return "aspect-h-1 aspect-w-1 overflow-hidden o-contain br-10";
            //     if (this.isHorizontal) return this.isHorizontal + " overflow-hidden o-contain br-10";
            //     else if (this.isVertical) return this.isVertical + ` overflow-hidden o-contain br-10`;
            // }
            if (this.isNormal) {
                let classes = {
                    image: [" y o-contain x "],
                    container: [""],
                    inset: [""],
                };
                if (indicators) classes.container.push("bg-green-400/20");

                if (this.isInset) {
                    classes.image.push("height-max-400 !py-0 !my-0");
                    if (!this.fullBleed) classes.inset.push("!pt-5 mt-5 px-5 !-mb-4");
                    else classes.inset.push("!pt-0 mt-5 px-5");
                } else {
                    classes.container.push("!pt-0");
                    classes.image.push("height-max-450");
                    classes.inset.push("!mt-5 !pt-5");
                }
                if (this.showLogo && !this.fullBleed) {
                    classes.container.push("");
                    if (this.isInset) classes.inset.push("!mt-8 !pt-7");
                    else classes.inset.push("!mt-8 !pt-3");
                    classes.container.push("mt-5 !mb-0");
                    classes.inset.push("!mb-2 transition-all");
                }
                if (this.fullBleed) classes.inset.push("mb-3");

                ["image", "container", "inset"].forEach(prop => (classes[prop] = classes[prop].join(" ")));
                return classes;
            }
        },
        normalImageOpaque() {
            if (this.isNormal) {
                let classes = {
                    image: [" x y o-cover block"],
                    container: [""],
                    inset: ["px-0"],
                };
                if (indicators) classes.container.push("bg-green-400/20");

                if (this.isInset) {
                    // classes.image.push("height-max-400");
                    if (!this.fullBleed) classes.inset.push("!pt-0 !mt-8 px-5 !-mb-4");
                    if (this.fullBleed) classes.inset.push("!-mt-8 px-5 !mb-4");
                } else {
                    if (this.fullBleed) classes.inset.push("!-mt-12 block");
                    if (this.fullBleed) classes.container.push("!-mt-12 !pt-0 block");
                    if (this.fullBleed) classes.inset.push("!pt-0");
                    if (this.fullBleed) classes.container.push("block");
                }
                if (this.showLogo && !this.fullBleed) {
                    if (this.isInset) classes.inset.push("!mt-8 !pt-8 !mb-3 block");
                    // if (this.else) classes.inset.push("!mt-8 !pt-8 !mb-3 block");
                }
                if (this.fullBleed) {
                    // classes.inset.push("!-mt-8");
                    // classes.container.push("!-mt-9");
                }
                ["image", "container", "inset"].forEach(prop => (classes[prop] = classes[prop].join(" ")));
                return classes;
            }
        },
        emailTransparentImageShapes() {
            if (this.transparent) {
                if (this.isFull) return this.fullImage.container;
                if (this.isRotated) return this.rotatedImage.container;
                if (this.isNormal) return this.normalImage.container;
            }
        },
        squareImage() {
            if (this.isSquare) {
                let classes = {
                    image: ["height-max-500 x y o-cover"],
                    container: ["x y o-cover"],
                    inset: [""],
                };
                if (indicators) classes.container.push("bg-green-400/20");
                if (indicators) classes.inset.push("bg-red-400/20");

                if (this.isInset) {
                    if (!this.fullBleed) classes.inset.push("px-5");
                    if (this.fullBleed) classes.inset.push("px-5 !-mt-6 !mb-4");
                } else {
                    if (!this.fullBleed) classes.inset.push("!mt-0 pt-0 px-0");
                    if (this.fullBleed) classes.inset.push("mt-0");
                    if (this.fullBleed) classes.container.push("!-mt-12");
                }
                if (this.showLogo && !this.fullBleed) {
                    if (this.isInset) classes.inset.push("mt-6 pt-3 block");
                }
                if (!this.showLogo && !this.fullBleed) {
                    if (this.isInset) classes.inset.push("!mt-5 !pt-6 !px-8 !mw-350 ");
                    else if (!this.isInset) classes.inset.push("");
                }
                ["image", "container", "inset"].forEach(prop => (classes[prop] = classes[prop].join(" ")));
                return classes;
            }
        },
        roundImage() {
            if (this.isRound) {
                let classes = {
                    image: ["height-max-500 x y o-cover rounded-full"],
                    container: ["x y o-cover rounded-full"],
                    inset: ["mb-0 rounded-full mx-auto"],
                };
                if (indicators) classes.container.push("bg-green-400/20");
                if (indicators) classes.inset.push("bg-red-400/20");

                if (this.isInset) {
                    if (!this.fullBleed) classes.inset.push("px-5");
                    if (this.fullBleed) classes.inset.push("px-5 !-mt-6 !mb-4");
                } else {
                    if (this.fullBleed) classes.inset.push("!-mt-12");
                    if (this.fullBleed) classes.container.push("!-mt-12 px-4 mb-4");
                }
                if (this.showLogo && !this.fullBleed) {
                    if (this.isInset) classes.inset.push("!mt-6 pt-5 block");
                    if (!this.isInset) classes.inset.push("!mt-6 pt-5 block");
                }
                if (!this.showLogo && !this.fullBleed) {
                    if (this.isInset) classes.inset.push("!mt-5 !pt-6 !px-8 !mw-350 ");
                    else if (!this.isInset) classes.inset.push("!mt-6 px-3");
                }
                ["image", "container", "inset"].forEach(prop => (classes[prop] = classes[prop].join(" ")));
                return classes;
            }
        },
        landscapeImage() {
            if (this.isLandscape) {
                let classes = {
                    image: ["x y o-cover"],
                    // image: ["height-max-500 x y o-cover"],
                    container: ["x y o-cover"],
                    // container: [` o-cover`],
                    inset: [],
                };
                if (indicators) classes.container.push("bg-green-400/20");
                if (indicators) classes.inset.push("bg-red-400/20");

                if (this.isInset) {
                    // classes.image.push('br-10')
                    if (!this.fullBleed) classes.inset.push("px-5");
                    if (this.fullBleed) classes.inset.push("px-5 !-mt-6 !mb-4");
                } else {
                    if (!this.fullBleed) classes.inset.push("!mt-0 pt-0 px-0");
                    if (this.fullBleed) classes.inset.push("mt-0");
                    if (this.fullBleed) classes.container.push("!-mt-12");
                }
                if (this.showLogo && !this.fullBleed) {
                    if (this.isInset) classes.inset.push("mt-6 pt-3 block");
                }
                if (!this.showLogo && !this.fullBleed) {
                    if (this.isInset) classes.inset.push("!mt-5 !pt-6 !px-8 !mw-350 ");
                    else if (!this.isInset) classes.inset.push("");
                }
                ["image", "container", "inset"].forEach(prop => (classes[prop] = classes[prop].join(" ")));
                return classes;
            }
        },
        emailTransparentClasses() {
            // this is the image itself.
            if (this.isNormal && this.transparent) return this.normalImage.image;
            if (this.isFull) return this.fullImage.image;
            if (this.isRotated) return this.rotatedImage.image;
        },
        emailContainerClasses() {
            let classes = [];
            if (this.transparent) return this.emailTransparentContainerClasses;
            else {
                if (this.isSquare) return this.squareImage.container;
                else if (this.isRound) return this.roundImage.container;
                else if (this.isLandscape) return this.landscapeImage.container;
                else if (this.isNormal) return this.normalImageOpaque.container;
            }
        },
        emailImageClasses() {
            // this is the image itself
            // if(this.isRound) return 'aspect-w-1 aspect-h-1 height-300 overflow-hidden br-20 bg-red'
            if (this.transparent) return this.emailTransparentClasses;
            else {
                if (this.isNormal) return this.normalImageOpaque.image;
                if (this.isSquare) return this.squareImage.image;
                if (this.isRound) return this.roundImage.image;
                if (this.isLandscape) return this.landscapeImage.image;
            }
            return;
            if (this.isNormal) {
                if (this.isHorizontal) return "x o-cover";
                if (this.isVertical) return "x height-500 o-cover";
            }
            if (this.isSquare) return this.squareImage.image;
            if (this.isSquare) return "x o-cover";
            if (this.isLandscape) return this.landscapeImage.image;
            // if (this.isLandscape) return "x y o-cover";
            if (this.isRound) return "x y o-cover";
            // if (this.fullBleed) classes.push("");
            // return classes.join(" ");
            if (this.isSeamless) classes.push(`x y o-contain`);
            // if (this.isLandscape) return "x o-cover";

            return `o-cover`;
        },
        shapeClasses() {
            if (this.isRound) return "";
            if (this.isSquare) return "";
            if (this.isLandscape) return "";
            if (this.isNormal) return "";
        },
        emailInsetClasses() {
            let classes = [];
            if (this.isInset) {
                // return "px-8";
                if (this.isRound) {
                    return this.roundImage.inset;
                    if (!this.fullBleed) classes.push("pt-8 px-5");
                    if (this.fullBleed) classes.push("px-5 pt-4 !-mb-3");
                    if (this.showLogo && !this.fullBleed) classes.push("px-3 mt-5 pb-0");
                }
                if (this.isSquare) return this.squareImage.inset;
                if (this.isFull) return this.fullImage.inset;
                if (this.isNormal) {
                    if (this.transparent) {
                        return this.normalImage.inset;
                    } else {
                        return this.normalImageOpaque.inset;
                        if (this.fullBleed) classes.push("mt-5 px-5 !-mb-4");
                        if (!this.fullBleed) classes.push("mt-8 pt-5 pt-2 px-5");
                    }
                }
                if (this.isRotated) return this.rotatedImage.inset;

                if (this.isLandscape) {
                    return this.landscapeImage.inset;
                    if (this.fullBleed) return "px-5 mt-5";
                    if (!this.fullBleed) return "px-5 pt-5 mt-6";
                    // if (this.isLandscape && this.fullBleed && this.isHorizontal) return "px-5 px-5 mt-5";
                    // if (this.isLandscape && !this.fullBleed) return "pt-5 rounded-none";
                }
                return classes.join(" ");
                // return "px-5 pt-5";
            } else if (!this.isInset) {
                if (this.isFull) return this.fullImage.inset;
                if (this.isRotated) return this.rotatedImage.inset;
                if (this.isNormal && this.transparent) return this.normalImage.inset;
                if (this.isRound) return this.roundImage.inset;
                if (this.isSquare) return this.squareImage.inset;
                if (this.isLandscape) return this.landscapeImage.inset;
                // if (this.isLandscape && this.fullBleed) return "h-50";

                if (this.isNormal) {
                    if (this.fullBleed) return "y px-0 pt-5";
                    if (!this.fullBleed) return "x px-0";
                }
                if (this.isRound) {
                    if (this.fullBleed) return "pt-3 pb-3 px-3 !-mb-5";
                    if (!this.fullBleed) return "pt-8 pb-3 px-3 mt-5 !-mb-5";
                    if (this.fullBleed) return "pt-3 px-3";
                }
                if (this.isSquare) {
                    if (this.fullBleed) return "mt-5";
                    // if (!this.fullBleed) return "pt-8 px-3 mt-5";
                }
                if (this.isLandscape) {
                    if (this.fullBleed) {
                        if (this.isVertical) return "pt-2 mt-8";
                        if (this.isHorizontal) return "mt-5";
                    } else {
                        if (this.isVertical) return "pt-2 mt-8";
                        if (this.isHorizontal) return "mt-5";
                    }
                }
                if (this.isSquare) return "";
                if (this.isNormal) return "";
                return "";
            }
        },
    },
    methods: {
        clickedLogo() {
            this.$emit("clickedLogo");
        },
        updateSection(prop, value, skipSave = false) {
            if (this.identifier) {
                // console.log("Updating with identifier", `${this.identifier}.${prop}`, value);
                if (!skipSave) this.$emit("save-section", { prop: prop, identifier: this.identifier });
                return this.updateStreamProp(`${this.identifier}.${prop}`, value);
            }
            if (this.inCampaign) {
                return this.updateStreamProp(this.sectionProp(prop), value);
            } else {
                return this.updateStreamProp(this.sectionProp(prop), value);
            }
        },
        sectionProp(prop, sections = "result.sections") {
            return `${sections}.${this.index}.${prop}`;
        },
    },
};
