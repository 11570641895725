<template>
    <div>
        <BaseButton v-for="button in buttons" :key="button.label" style-type="secondary" :icon-only="false" size="xxs" :label="button.label" @click.prevent="clickSort(button.sortType)" />
        <!--         :icon="button.icon"-->
    </div>
</template>
<script>
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapGetters } from "vuex";
export default {
    name: "sortColorPicker",
    components: { BaseButton },

    computed: {
        ...mapGetters("styleGuide", ["sortColors"]),
    },
    data() {
        return {
            buttons: [
                { label: "luma", sortType: "luma", icon: "fas fa-arrow-down" },
                { label: "light", sortType: "light", icon: "fas fa-arrow-up" },
                { label: "none", sortType: "", icon: "fas fa-paintbrush" },
                { label: "sat", sortType: "sat", icon: "fas fa-lightbulb" },
                { label: "hue", sortType: "hue", icon: "fas fa-lightbulb" },
            ],
        };
    },
    methods: {
        clickSort(sort) {
            this.$store.dispatch("styleGuide/updateSortColors", sort);
        },
    },
    props: {
        // sortColors: {},
    },
};
</script>
