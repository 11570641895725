<template>
    <div class="mx-auto">
        <div class="mx-auto mw-500 x block">
            <transition name="fade-up" class="duration-3 ease-back d-10" mode="out-in">
                <Loader class="bg-snow" :style="`background:#ffffff`" v-if="updateLoadingMessage && updateLoadingMessage !== 'done'" :update-loading-message="updateLoadingMessage" @click.prevent="showDetails = !showDetails" />
            </transition>
            <transition name="fade-up" mode="out-in">
                <LoadingDetails v-if="showDetails" :email-plan="planning" :email-preview="preview" :email-reasoning="reasoning" :email-subject="subject" />
            </transition>
        </div>
    </div>
</template>
<script>
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import Loader from "@/components/generative_templates/Loaders/EmailStatusLoader.vue";
import LoadingDetails from "@/components/generative_templates/Email/EmailDetailedThinking.vue";

const fields = ["name", "subject_line", "preview_text", "thinking", "reasoning", "planning", "subject", "preview", "sections", "footer", "complete", "email_footer", "strategy"];

export default {
    name: "StreamingStatusLoader",
    components: {
        LoadingDetails,
        Loader,
    },
    mixins: [styleGuideMixin],
    props: {
        result: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            emailSections: {},
            showDetails: false,
            done: false,
        };
    },
    computed: {
        updateLoadingMessage() {
            const statusObj = this.status;
            let currentMessage = "";

            const keySequence = ["name", "subject_line", "preview_text", "thinking", "reasoning", "planning", "purpose", "subject", "preview", "sections", "footer", "complete"];

            for (const key of keySequence.reverse()) {
                if (key === "sections" && statusObj[key] && Array.isArray(statusObj[key]) && statusObj[key].length > 0) {
                    const lastSection = statusObj[key][statusObj[key].length - 1];
                    currentMessage = lastSection.message;
                    break;
                } else if (statusObj[key] && statusObj[key].status) {
                    currentMessage = statusObj[key].message;
                    break;
                }
            }
            if (currentMessage === "Done") {
                this.done = true;
                return "done";
            }
            if (currentMessage === "") {
                return false;
            } else {
                return `<span class="fwb">${currentMessage}</span>`;
            }
        },
        ...fields.reduce((acc, field) => {
            acc[field] = function () {
                return this.result?.[field];
            };
            return acc;
        }, {}),
        sections() {
            if (this.result?.sections?.length > 0) {
                return this.result.sections;
            } else if (this.result?.schedule_items?.length > 0) {
                return this.result.schedule_items;
            } else if (this.result?.items?.length) {
                return this.result.items;
            } else {
                return [];
            }
        },
        status() {
            let sectionStatus;
            if (this.sections && this.sections.length > 0) {
                sectionStatus = this.sections.map((section, index) => {
                    const { imageIndex, header, body, button, purpose, name, campaign_items, scheduled_on_day, features, complete } = section;

                    let message = `<span class="fwb">Section ${index + 1}: </span>`;
                    let messageDetails = "";

                    if (complete) messageDetails = `On to the next section`;
                    if (button !== undefined) messageDetails = `Adding Button ...`;
                    else if (features) messageDetails = `Writing Features Section ...`;
                    else if (body) messageDetails = `Writing Body Copy ...`;
                    else if (header) messageDetails = `Writing Header text ...`;
                    else if (scheduled_on_day !== null) messageDetails = `Scheduling ...`;
                    else if (name) messageDetails = `Naming it ...`;
                    else if (purpose) messageDetails = `Planning ...`;
                    else if (imageIndex !== undefined) messageDetails = `Selecting image ...`;
                    else messageDetails = `Thinking ...`;

                    if (campaign_items?.length > 0) {
                        let campaignMessages = campaign_items.map((item, itemIndex) => {
                            const { itemType, name, instructions, talking_points } = item;
                            let campaignMessage = `Campaign Item ${itemIndex + 1}: `;
                            if (itemType) messageDetails = `Setting type ...`;
                            if (talking_points) messageDetails = `Generating talking points ...`;
                            else if (instructions) {
                                messageDetails = `Writing instructions ...`;
                                const {
                                    content_structure_from_content_strategist,
                                    //
                                    copy_instructions_from_copywriter,
                                    directions_from_creative_director,
                                    strategic_goals_from_CMO,
                                    brand_guidelines_from_brand_manager,
                                } = instructions;
                                if (brand_guidelines_from_brand_manager) messageDetails = `Brand guidelines ...`;
                                else if (strategic_goals_from_CMO) messageDetails = `Setting goals ...`;
                                else if (directions_from_creative_director) messageDetails = `Creative outline ...`;
                                else if (copy_instructions_from_copywriter) messageDetails = `Instructions ...`;
                                else if (content_structure_from_content_strategist) messageDetails = `Content structure ...`;
                            } else if (name) messageDetails = `Naming it ...`;
                            else messageDetails = `Thinking ...`;
                            if (itemType) {
                                message = `<span class="fwb">Generating ${itemType}: </span>`;
                            }

                            return campaignMessage;
                        });
                    }

                    message += `<span class="fwn">${messageDetails}</span>`;
                    return {
                        status: true, // We're always in some state of "in-progress" for this section
                        message: message,
                    };
                });
            }

            return {
                thinking: {
                    status: this.thinking?.thoughts?.length > 0 ? this.thinking?.thoughts : false,
                    message: `Thinking ...`,
                },
                name: {
                    status: this.name?.length > 0,
                    message: "Naming it ...",
                },
                // support either this.thinkinking.reasoning or this.reasoning
                reasoning: {
                    status: this.thinking?.reasoning?.length > 0 || this.reasoning?.length > 0,
                    message: `Thinking ...`,
                },
                // same for plan. this.thinking.plan or this.planning.
                planning: {
                    status: this.thinking?.plan?.length > 0 || this.planning?.length > 0,
                    message: "Planning ...",
                },
                strategy: {
                    status: this.strategy?.length > 0 ? this.strategy : false,
                    message: "Writing Strategy ...",
                },
                // this.subject or this.subject_line
                subject: {
                    status: this.subject?.length > 0 || this.subject_line?.length > 0,
                    message: "Writing Subject Line ...",
                },
                //this.preview or this.preview_text.
                preview: {
                    status: this.preview?.length > 0 || this.preview_text?.length > 0,
                    message: "Writing preview text ...",
                },
                sections: sectionStatus,
                footer: {
                    status: this.email_footer?.footer_text ? true : false,
                    message: "Adding Footer ...",
                },
                // this.complete or this.email_footer
                complete: {
                    status: this.complete || this.email_footer?.unsubscribe?.link?.text?.length > 0,
                    message: "Done",
                },
            };
        },
    },
    mounted() {},
    methods: {},
};
</script>
