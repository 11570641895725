<!--OLD COMPONENT-->
<template>
    <div class="bg-gray-100">
        <!--                        <button-showcase class="x"></button-showcase>-->
        <!--        <AuthZeroLogin />-->
        <Profile v-if="$store?.state?.user?.user" />
        <LogIn v-else />
    </div>
</template>

<script>
import { doc, setDoc } from "firebase/firestore";
import moment from "moment";
import user from "@/mixins/usermixin";
import Profile from "@/views/Profile.vue";
import { mapGetters } from "vuex";
import LogIn from "@/views/LogIn.vue";
// import AboutPage from "@/views/AboutPage.vue";
import { mapState } from "vuex";
export default {
    components: { LogIn, Profile },
    mixins: [user],
    data() {
        return {
            email: "",
            password: "",
            showRegister: false,
            userDescription: "",
        };
    },
    watch: {},
    computed: {
        ...mapGetters("styleGuide", ["styleGuide"]),
        ...mapState("user", ["user"]),
        createdAtFormatted() {
            if (this.user?.createdAt) {
                return moment(parseInt(this.user.createdAt)).format("MMMM Do YYYY");
            }
        },
    },
    methods: {},
};
</script>
<style lang="scss">
html {
    @apply bg-gray-100;
}
</style>
<!--        <template v-if="!user">-->
<!--            <div class="container x">-->
<!--                <div class="mx-auto w-full max-w-md x">-->
<!--                    <h4 class="text-2xl font-bold mb-4">Authentication</h4>-->
<!--                    <h5 class="text-xl font-semibold mb-2" v-if="showRegister">Sign Up</h5>-->
<!--                    <h5 class="text-xl font-semibold mb-2" v-else>Login</h5>-->
<!--                    <form @submit.prevent="showRegister ? register() : login()" class="mb-4 f fc">-->
<!--                        <BaseInput :id="randomId()" label="Email" type="email" class="mb-0" :modelValue="email" @update:modelValue="email = $event" placeholder="Email address" />-->
<!--                        <BaseInput :id="randomId()" label="Password" type="password" class="mb-0" :modelValue="password" @update:modelValue="password = $event" placeholder="Password" />-->
<!--                        <Button style-type="secondary" class="x button-sky" icon="" size="md" type="submit" label="Sign up" v-if="showRegister" />-->
<!--                        <Button style-type="primary" class="x" icon="" size="md" type="submit" label="Log in" v-else />-->
<!--                        <div class="my-3 text-center">-->
<!--                            <template v-if="showRegister">-->
<!--                                Already have an account?-->
<!--                                <Button style-type="link" class="d-inline whitespace-normal text-center" icon="" size="md" @click="showRegister = false" label="Sign in here" />-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                Don't have an account?-->
<!--                                <Button style-type="link" class="d-inline whitespace-normal text-center" icon="" size="md" @click="showRegister = true" label="Create one here" />-->
<!--                            </template>-->
<!--                        </div>-->
<!--                        <span class="d-block my-1 x text-center">or</span>-->
<!--                    </form>-->
<!--                    <div class="mt-4">-->
<!--                        <Button style-type="secondary" class="x" icon="fab fa-google" size="md" label="Sign in with Google" @click="signInWithGoogle" />-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </template>-->
