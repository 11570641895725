<template>
    <button
        class="py-1.5 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        type="button">
        <IconStop />
        {{ text }}
    </button>
</template>
<script>
import IconStop from "@/views/IconStop.vue";

export default {
    name: "ChatButtonStop",
    components: { IconStop },
    props: {
        text: {
            type: String,
            default: "Stop generating",
        },
    },
    data() {
        return {};
    },
    methods: {
        stopGenerating() {
            this.$emit("stop-generating");
        },
    },
};
</script>
