<template>
    <div class="" v-if="superAdmin">
        <!--    <div class="x mb-4" >-->
        <span class="o-5 d-inline-block f-13 cursor-pointer hover:text-teal-700" @click.prevent="isOpen = !isOpen">
            Debug
            <i class="fas o-5 ml-2" :class="{ 'fa-caret-up o-5 ml-1': isOpen, 'fa-caret-down o-5 ml-2': !isOpen }" aria-hidden="true"></i>
        </span>
        <div v-if="isOpen" class="animated text-left fadeInUpSmooth line-height-normal d-block w-100 br-20 duration-5 mb-4 mt-4 w-full overflow-hidden bg-snow p-3 shadow dark:bg-base-900">
            <debug-node v-if="isOpen" :node="object" :keysToHide="keysToHide" :depth="0" :openKeys="keysToOpen" />
            <template v-if="isOpen">
                <template v-if="object.images && object.images.length && object.images.length > 0">
                    <div class="f x gap-3 p-3">
                        <div class="x" v-for="image in object.images">
                            <img :src="image.url" class="h-100 w-100" :alt="image.alt || ''" />
                        </div>
                    </div>
                </template>
            </template>
            <span class="o-5 d-inline-block f-13 x text-center cursor-pointer hover:text-teal-700" @click.prevent="isOpen = !isOpen">
                Close
                <i class="fas o-5 ml-2" :class="{ 'fa-times o-5 ml-1': isOpen, 'fa-times o-5 ml-2': !isOpen }" aria-hidden="true"></i>
            </span>
        </div>
    </div>
</template>

<script>
import DebugNode from "@/components/chat/specialMessages/DebugNode.vue";

export default {
    name: "debugObject",
    mounted() {
        // if (this.open) {
        //     this.isOpen = true;
        // }
    },
    components: {
        DebugNode,
    },
    props: {
        object: {
            type: [Object, Array],
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
        keysToOpen: {
            type: Array,
            default: () => [],
        },
        keysToHide: {
            type: Array,
            default: () => ["createdAt", "role"],
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
};
</script>
