<template>
    <div>
        <BaseButton class="!gap-0 !p-2 c-40 !px-2" v-if="buttonWrapper" style-type="bubble" @click.prevent="toggleLike" :icon="`fas fa-heart !mr-1 text-${isLiked ? 'red-500' : 'snow'}`" size="sm" :label="`${likeCount}`" />
        <button v-else @click.prevent="toggleLike" class="bg-transparent" :class="btnClass">
            <i :class="`fas fa-${icon} text-${isLiked ? 'red-500' : 'base-500'} dark:text-${isLiked ? 'red-500' : 'base-500'}`"></i>
            <span v-if="showNumLikes" class="text-white ml-1">{{ likeCount }}</span>
        </button>
    </div>
</template>

<script>
import { db } from "@/firebase";
import { addDoc, deleteDoc, query, where, getDocs, collection, onSnapshot, doc } from "firebase/firestore";
import BaseButton from "@/components/CoreUI/BaseButton.vue";

export default {
    components: { BaseButton },
    props: {
        icon: {
            type: String,
            default: "heart",
        },
        parent: {
            type: String,
        },
        btnClass: {
            type: String,
            default: "",
        },
        collection: {
            type: String,
            required: true,
        },
        buttonWrapper: {
            type: Boolean,
            default: false,
        },
        showNumLikes: {
            type: Boolean,
            default: false,
        },
        objId: {
            type: String,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLiked: false,
            likeCount: 0,
        };
    },
    async mounted() {
        const likeRef = collection(db, "likes");
        // Fetch initial like count
        onSnapshot(query(likeRef, where("objId", "==", this.objId)), querySnapshot => {
            this.likeCount = querySnapshot.size;
        });

        // Fetch initial isLiked state
        const isLikedSnapshot = await getDocs(query(likeRef, where("userId", "==", this.userId), where("objId", "==", this.objId)));
        this.isLiked = !isLikedSnapshot.empty;
    },
    methods: {
        async toggleLike() {
            console.log("toggleLike() called, current state: isLiked =", this.isLiked, "likeCount =", this.likeCount);

            const likeCollection = collection(db, "likes");

            if (this.isLiked) {
                await this.deleteLike(likeCollection, this.userId, this.objId, this.collection);
                this.isLiked = false;
            } else {
                await this.addLike(likeCollection, this.userId, this.objId, this.collection);
                this.isLiked = true;
            }
        },
        async addLike(likeCollection, userId, objId, collection) {
            console.log("addLike() called with userId =", userId, "objId =", objId);
            let object = {
                userId,
                objId,
                created: Date.now(),
            };
            if (collection) {
                object.collection = collection;
            }
            if (this.parent) {
                object.parent = {
                    id: this.parent,
                    collection: collection,
                };
            }
            await addDoc(likeCollection, object);
        },
        async deleteLike(likeCollection, userId, objId) {
            console.log("deleteLike() called with userId =", userId, "objId =", objId);

            // Query the "likes" collection for the document matching the userId and objId.
            const q = query(likeCollection, where("userId", "==", userId), where("objId", "==", objId));
            const querySnapshot = await getDocs(q);

            // Delete the matching document from the "likes" collection.
            if (!querySnapshot.empty) {
                const docID = querySnapshot.docs[0].id;
                await deleteDoc(doc(db, "likes", docID));
            } else {
                console.error("deleteLike() didn't find any documents matching the query.");
            }
        },
    },
};
</script>