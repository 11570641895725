<template>
    <div>
        <div class="w-80" v-if="fineTuneMapping?.length > 0">
            <div>
                <chat-markdown :content="`\`\`\`javascript\n\n${finetuning}\n\n\`\`\``"></chat-markdown>
<!--                                <div class="my-2" v-for="campaign in filteredCampaigns">{{ campaign }}</div>-->
                                <pre class="my-2" v-for="campaign in finetuning">{{ campaign }}</pre>
                <!--                <template v-for="(c, i) in fineTuneMapping" :key="'message' + i"></template>-->
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import { fineTuneMapping } from "@/mixins/ai/fineTuneMapping";
export default {
    name: "FineTuningHelper",
    components: { ChatMarkdown },
    computed: {
        ...mapGetters("stream", ["fineTuneMapping", "campaigns"]),
        filteredCampaigns() {
            return this.campaigns.filter(i => i?.object_type === "campaign");
        },
        finetuning() {
            const desiredStructure = {
                object_type: null,
                name: null,
                thinking: {
                    thoughts: null,
                    reasoning: null,
                    plan: null,
                    criticism: null,
                },
                schedule_items: [
                    {
                        scheduled_on_day: null,
                        scheduled_on_date: null,
                        campaign_items: [
                            {
                                itemType: null,
                                type_of_campaign: null,
                                name: null,
                                goal: null,
                                instructions: {
                                    content_structure_from_content_strategist: null,
                                    copy_instructions_from_copywriter: null,
                                    directions_from_creative_director: null,
                                    strategic_goals_from_CMO: null,
                                    brand_guidelines_from_brand_manager: null,
                                },
                                talking_points: null,
                                complete: null,
                            },
                        ],
                    },
                ],
                complete: null,
            };

            let campaigns = this.campaigns.filter(i => i?.object_type === "campaign");
            let data = fineTuneMapping(campaigns, desiredStructure);
            return data.map(c => JSON.stringify(c)).join("\n");
        },

        // return this.fineTuneMapping
        //     .map((c, i) => {
        //         return `${JSON.stringify(c)}`;
        //     })
        //     .join("\n");
    },
};
</script>
