<template>
    <a :href="url" :class="`font-semibold text-${color}-${brightness} hover:text-${color}-${brightness - 100}`" @click.prevent="onClick">
        <slot></slot>
    </a>
</template>
<script>
export default {
    name: "SimpleLink",
    props: {
        color: {
            type: String,
            default: "green",
        },
        brightness: {
            type: String,
            default: "700",
        },
        url: {
            type: String,
            default: "#",
        },
    },
    methods: {
        onClick: function () {
            // do something
            this.$emit("clicked");
        },
    },
};
</script>
