import updateQueueProp from "@/mixins/ai/update_queue_prop";
import completeStep from "@/mixins/ai/complete_progress_step";
import addProgressStep from "@/mixins/ai/add_progress_step";
import addImagesToQueue from "@/mixins/ai/add_images_to_que";
async function getShopifyInfo(url) {
    try {
        var full_url = url + "/products.json";
        if (url && url.endsWith("/")) {
            full_url = url + "products.json";
        }
        let products = false;
        await fetch(full_url)
            .then(response => response.json())
            .then(data => {
                console.log(data.products);
                products = data.products;
            })
            .catch(error => console.error("Error:", error));
        return products;
    } catch (e) {}
}
async function tryForShopifyInfo(store, url) {
    console.group("%c🛒 Shopify info", purple, url);
    let products = await getShopifyInfo(url);
    console.log(products);
    console.groupEnd();
    if (products) {
        updateQueueProp(store, "research.shopify", true);
        try {
            addProgressStep(store, "Browsing store", "", products);
            console.log(products);
            const images = await extractShopifyImages(products, url);
            await addImagesToQueue(store, images, url);
            console.log(images);
            completeStep(store, "Browsing store");
        } catch (e) {
            console.log(`Error: ${e}`);
        }
        return products;
    }
}
function extractShopifyImages(products, url) {
    let finalImages = [];
    for (let product of products) {
        let { title, handle, images, body_html, tags, price, variants, sku } = product;
        if (variants) {
            for (let variant of variants) {
                if (variant.available) {
                    if (variant.featured_image) {
                        console.log(variant.featured_image.src);
                        let image = variant.featured_image;
                        let imageObject = {
                            ...variant.featured_image,
                            alt: image.alt || title || "",
                            title: title,
                            url: image.src,
                            source: url,
                            product_id: variant.product_id || "",
                            shopify: true,
                        };
                        finalImages.push(imageObject);
                    }
                }
            }
        }
        if (images) {
            console.log(images);
            for (let image of images) {
                //     // console.log(image.src);
                let imageObject = {
                    ...image,
                    alt: title,
                    title: title,
                    url: image.src,
                    source: `${product.handle}`,
                    product_id: image.product_id || "",
                    shopify: true,
                };
                finalImages.push(imageObject);
            }
        }
    }
    return finalImages;
}
async function checkAllUrlsForShopify(store, urls) {
    try {
        for (const url of urls) {
            if (url.includes("shopify") && !url.includes("cdn.") && !store.state.presentation.queue.research.shopify) {
                let potentialShopifyUrl = new URL(url);
                await tryForShopifyInfo(store, "https://" + potentialShopifyUrl.host);
            }
        }
        return urls;
    } catch (error) {
        console.error(error);
        return urls;
    }
}
export { checkAllUrlsForShopify, tryForShopifyInfo, extractShopifyImages };
