<template>
    <div class="f fc gap-5" style="font-size: 16px !important">
        <div v-for="style in styles">
            <span :style="style">The Quick Brown Fox</span>
            <TextStyleDetails :style="style" />
        </div>
    </div>
</template>
<script>
import TextStyleDetails from "@/components/styleGuide/Typography/TextStyleDetails.vue";

export default {
    name: "TextStylesArray",
    components: { TextStyleDetails },
    props: {
        styles: {},
    },
};
</script>
