<template>
    <div>
        <!--        {{ examples }}-->
        <template v-for="example in examples">
            <UIWrapperText :title="example.kind">
                <div class="mb-5 pb-5 border-b-brand" v-if="example.description !== example.kind">
                    <span class="!f-40 x w-80 block !leading-normal">{{ example.description }}</span>
                </div>
                <!--<IconCheck class="" size="lg" />-->
                <!--<IconNo class="" size="lg" />-->

                <template v-for="(e, index) in example.examples" :key="'e' + index">
                    <div class="f fc gap-5 border-b-brand pb-4 mb-5 pt-0 mt-0 last:border-0">
                        <div v-if="example?.tag && example?.tag.includes('button')">
                            <div class="f bg-gray-100 p-4 br-10 border-light gap-10" :class="{ fc: mobile }">
                                <a v-if="e.good" :style="mainButton" class="f aic jcc block x text-center" href="#">{{ e.good }}</a>
                                <a v-if="e.bad" :style="mainButton" class="f aic jcc block x text-center disabled !bg-gray-200 !text-gray-300 !border-0" href="#">
                                    <s>{{ e.bad }}</s>
                                </a>
                            </div>
                        </div>
                        <div class="f fc gap-3" v-else-if="example?.tag?.includes('h1')">
                            <h3 v-if="e.good" class="h1 w-80">{{ e.good }}</h3>
                            <h3 v-if="e.bad" class="h2 w-80 o-5">
                                <s>{{ e.bad }}</s>
                            </h3>
                        </div>
                        <div v-else-if="example?.tag?.includes('h')">
                            <span v-if="e.good" class="h4 fwb block">{{ e.good }}</span>
                            <s v-if="e.bad" class="h4 block o-5">{{ e.bad }}</s>
                        </div>
                        <div class="w-80 f fc gap-4 f-30" v-else>
                            <div v-if="e.good">
                                {{ e.good }}
                            </div>
                            <s v-if="e.bad">{{ e.bad }}</s>
                        </div>
                        <p v-if="e.why" class="!f-17 w-70 mb-0 o-5" style="font-weight: 400 !important; font-family: unset !important">{{ e.why }}</p>
                    </div>
                </template>
                <div class="grid md:grid-cols-2">
                    <DosDontsList :list="example.dos" title="Do's" type="dos" />
                    <DosDontsList :list="example.donts" title="Don't's" type="donts" />
                </div>
            </UIWrapperText>
        </template>
        <!--        <div v-for="example in examples" class="grid grid-cols-12 my-4 border-bottom py-4 mx-auto w-80 x">-->
        <!--            <div class="col-span-5">-->
        <!--                <h1>{{ example.kind }}</h1>-->
        <!--            </div>-->
        <!--            <div class="col-span-7 px-5">-->
        <!--                <h4 class="w-80 mb-4" v-if="example.description !== example.kind">{{ example.description }}</h4>-->
        <!--                <template v-for="(e, index) in example.examples" :key="'e' + index">-->
        <!--                    <div class="f fc gap-5 my-3 border-bottom py-3 last:border-0">-->
        <!--                        <div v-if="example?.tag && example?.tag.includes('button')">-->
        <!--                            <div class="f bg-gray-100 p-4 br-10 border-light gap-4">-->
        <!--<div class="f aic jcb gap-3 x">-->
        <!--    &lt;!&ndash;                                <IconCheck class="" size="lg" />&ndash;&gt;-->
        <!--    <a href="#" v-if="e.good" class="block x text-center" :style="mainButton">{{ e.good }}</a>-->
        <!--</div>-->
        <!--<div class="f aic jcb gap-3 x">-->
        <!--    &lt;!&ndash;                                <IconNo class="" size="lg" />&ndash;&gt;-->
        <!--    <a href="#" v-if="e.bad" :style="mainButton" class="block x text-center disabled !bg-gray-200 !text-gray-300 !border-0">-->
        <!--        <s>{{ e.bad }}</s>-->
        <!--    </a>-->
        <!--</div>-->
        <!--                            </div>-->
        <!--                            <p v-if="e?.why" style="font-family: unset" class="mt-4 block">{{ e.why }}</p>-->
        <!--                        </div>-->
        <!--                        <div v-else-if="example?.tag?.includes('h1')">-->
        <!--                            <div class="f aic jcs gap-4">-->
        <!--&lt;!&ndash;                                <IconCheck class="" size="2xl" />&ndash;&gt;-->
        <!--<h3 v-if="e.good" class="h1">{{ e.good }}</h3>-->
        <!--                            </div>-->
        <!--                            <div class="f aic jcs gap-4">-->
        <!--&lt;!&ndash;                                <IconNo class="" size="2xl" />&ndash;&gt;-->
        <!--<h3 class="h1 block o-8" v-if="e.bad">-->
        <!--    <s>{{ e.bad }}</s>-->
        <!--</h3>-->
        <!--                            </div>-->
        <!--                            <p class="mt-4 text-xs w-80" style="font-family: unset" v-if="e.why">{{ e.why }}</p>-->
        <!--                        </div>-->
        <!--                        <div v-else-if="example?.tag?.includes('h')">-->
        <!--                            <div class="f aic jcs gap-4">-->
        <!--&lt;!&ndash;                                <IconCheck class="" size="2xl" />&ndash;&gt;-->
        <!--<span class="h4 fwb" v-if="e.good">{{ e.good }}</span>-->
        <!--                            </div>-->
        <!--                            <div class="block o-8 f aic jcs gap-4" v-if="e.bad">-->
        <!--&lt;!&ndash;                                <IconNo class="" size="xl" />&ndash;&gt;-->
        <!--<span class="h4">-->
        <!--    <s>{{ e.bad }}</s>-->
        <!--</span>-->
        <!--                            </div>-->
        <!--                            <p class="mt-4 text-xs w-80" style="font-family: unset" v-if="e.why">{{ e.why }}</p>-->
        <!--                        </div>-->
        <!--                        <template v-else>-->
        <!--                            <div v-if="e.good" class="f ais jcs gap-5">-->
        <!--&lt;!&ndash;                                <IconCheck class="pt-3" size="lg" />&ndash;&gt;-->
        <!--<span class="pt-0 !mt-0 f-20">{{ e.good }}</span>-->
        <!--                            </div>-->
        <!--                            <div v-if="e.bad" class="f aic jcs gap-5 relative">-->
        <!--&lt;!&ndash;                                <IconNo class="" size="lg" />&ndash;&gt;-->
        <!--<span class="!pt-0 !mt-0 f-20">-->
        <!--    <s class="!mt-0">{{ e.bad }}</s>-->
        <!--</span>-->
        <!--                            </div>-->
        <!--                            <p class="mt-4 text-xs w-80" style="font-family: unset" v-if="e.why">{{ e.why }}</p>-->
        <!--                        </template>-->
        <!--                    </div>-->
        <!--                </template>-->
        <!--                <div class="grid grid-cols-2">-->
        <!--                    &lt;!&ndash;                    <div class="col-span-3">&ndash;&gt;-->
        <!--                    <DosDontsList :list="example.dos" title="Do's" type="dos" />-->
        <!--                    <DosDontsList :list="example.donts" title="Don't's" type="donts" />-->
        <!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
        <!--                </div>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;            <div class="col-span-3">&ndash;&gt;-->
        <!--            &lt;!&ndash;                <DosDontsList :list="example.dos" class="col-span-12 md:col-span-3" title="Do's" type="dos" />&ndash;&gt;-->
        <!--            &lt;!&ndash;                <DosDontsList :list="example.donts" class="col-span-12 md:col-span-3" title="Don't's" type="donts" />&ndash;&gt;-->
        <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--        </div>-->
    </div>
</template>
<script>
import IconCheck from "@/components/generative_templates/Guidelines/CheckIcon.vue";
import IconNo from "@/components/generative_templates/Guidelines/IconNo.vue";
import DosDontsList from "@/components/generative_templates/Guidelines/DosDontsList.vue";
import { mapGetters } from "vuex";
import UIWrapperText from "@/components/generative_templates/Guidelines/UIWrapperText.vue";

export default {
    name: "CopyGuidelineExamples",
    components: { UIWrapperText, DosDontsList, IconNo, IconCheck },
    props: { examples: {} },
    computed: {
        ...mapGetters("styleGuide", ["buttonStyles"]),
        mainButton() {
            return this.buttonStyles[0] || {};
        },
    },
};
</script>
<style lang="scss" scoped>
.h1,
.h2,
.h3,
h1,
h2,
h3 {
    s {
        text-decoration-color: rgba(200, 0, 0, 1) !important;
        -webkit-text-decoration-color: rgba(200, 0, 0, 1) !important;
        color: rgba(0, 0, 0, 0.3);
        text-decoration-thickness: 2px !important;
        text-decoration-skip-ink: none;
    }
}

a {
    position: relative;

    s {
        text-decoration-color: rgba(200, 0, 0, 1) !important;
        -webkit-text-decoration-color: rgba(200, 0, 0, 1) !important;
        text-decoration-thickness: 1px !important;
    }
}

p,
span {
    s {
        text-decoration-color: rgba(200, 0, 0, 1) !important;
        -webkit-text-decoration-color: rgba(200, 0, 0, 1) !important;
        color: rgba(0, 0, 0, 0.3);
        text-decoration-thickness: 1px !important;
        text-decoration-skip-ink: none;
        color: rgba(200, 0, 0, 0.5);
    }
}
</style>
