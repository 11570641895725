<template>
    <!--            <transition enter-active-class="animated swingInUp duration-3" leave-active-class="animated swingInUp duration-1 reverse-animation">-->
    <div class="h-full">
        <div class="f border-light mw-500 h-auto x jcb br-10 aic bg-snow p-2 pl-3 pr-2 shadow" @click.prevent="showData = !showData">
            <div class="jcs info_line aic flex gap-1" v-if="progressStep.name !== 'Thoughts'">
                <template v-if="progressStep.text && progressStep.text.length > 15">
                    <div>
                        <span :class="`fwb text-capitalize f-${fontSize} mr-1`" v-if="progressStep.name === 'Sourcing images' && progressStep.data">Sourced {{ progressStep.data.length }} Images</span>
                        <span v-else-if="progressStep.name" class="fwb text-capitalize f-20 mr-1">{{ progressStep.name }}</span>
                        <span v-if="progressStep.text" class="line-height-smaller block f-15 w-75 o-5">{{ progressStep.text }}</span>
                    </div>
                </template>
                <template v-else>
                    <div class="f x aic">
                        <span :class="`fwb text-capitalize f-${fontSize} mr-1`" v-if="progressStep.name === 'Sourcing images' && progressStep.data">Sourced {{ progressStep.data.length }} Images</span>
                        <span v-else-if="progressStep.name" :class="`fwb text-capitalize f-${fontSize} mr-1`">{{ progressStep.name }}</span>
                        <span v-if="progressStep.text" class="line-height-smaller">{{ progressStep.text }}</span>
                    </div>
                </template>
            </div>
            <template v-else>
                <div class="jcs info_line">
                    <p v-if="progressStep.text" class="f-17 whitespace-pre-line line-clamp-2">{{ progressStep.text }}</p>
                </div>
            </template>
            <div :class="stepClasses" class="c-25 border-light f aic jcc ml-3 transition-all">
                <div class="animated zoomIn duration-3">
                    <i v-if="progressStep.complete" class="fas fa-check text-shadow white"></i>
                    <i v-else class="far fa-sync fa-spin transition-1 duration-4 f-15 ease-back"></i>
                </div>
            </div>
        </div>
        <div v-if="progressStep.data">
            <slot></slot>
            <div class="whitespace-pre-wrap p-3" v-if="showData">{{ progressStep.data }}</div>
        </div>
    </div>
    <!--        </transition>-->
</template>
<script>
export default {
    name: "progressStep",
    props: {
        progressStep: {
            type: Object,
            default: () => {
                return {
                    name: "Loading",
                    text: "",
                    complete: false,
                };
            },
        },
        fontSize: {
            type: Number,
            default: 20,
        },
    },
    computed: {
        stepClasses() {
            if (this.progressStep.complete) {
                return "bg-green-300";
            } else if (this.progressStep?.name.includes("Summar")) {
                return "bg-sky-300 text-snow duration-1";
            } else {
                return "bg-gray-100 text-gray-300 duration-1";
            }
        },
    },
    data() {
        return {
            showData: false,
        };
    },
};
</script>
<style lang="scss">
@keyframes figureEight {
    0% {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}
</style>
