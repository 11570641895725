<template>
    <div class="mb-5 whitespace-pre-line mw-500 mx-auto prose" v-if="item">
        <div class="mb-3">
<!--            <image-swiper :images="item.images" />-->
        </div>
        <h1 v-if="item.title">{{ item.title }}</h1>
        <a v-if="item.url" class="o-5 fwb" :href="item.url">{{ item.url }}</a>
        <ChatMarkdown v-if="item.content" class="f-15 source-content prose" :content="content"></ChatMarkdown>
    </div>
</template>
<script>
import ChatMarkdown from "@/components/chat/specialMessages/ChatMarkdown.vue";
import MultiImageSlide from "@/components/Presentations/slides/largeSlides/MultiImageSlide.vue";
import ImageSwiper from "@/components/styleGuide/Sections/imageSwiper.vue";

export default {
    name: "ScrapedPageMarkdown",
    components: { ImageSwiper, MultiImageSlide, ChatMarkdown },
    props: {
        item: {},
    },
    computed: {
        content() {
            return this.item?.content || "";
        },
    },
};
</script>
<style lang="scss">
.source-content {
    //line-height: auto !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        //font-size: 1rem !important;
        font-weight: 700;
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
        padding-top: 0rem !important;
    }

    // sizes for h1 - h6
    h1 {
        font-size: 1rem !important;
    }
    h2 {
        font-size: 1.5rem !important;
    }
    h3 {
        font-size: 1.25rem !important;
    }
    h4 {
        font-size: 1rem !important;
    }
    h5 {
        font-size: 0.875rem !important;
    }
    h6 {
        font-size: 0.75rem !important;
    }
    p {
        font-size: 15px !important;
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
        padding-top: 0rem !important;
    }
    li {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
        line-height: 1rem !important;
    }
    a {
        color: #000 !important;
        text-decoration: underline !important;
    }
}
/* Include your styles here */
</style>
