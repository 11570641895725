import { set } from "lodash";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import { gpt3, gpt316, gpt4 } from "@/mixins/default_models";
import { stringifySections } from "@/store/SectionsToStrings";
import streamCompletion from "@/mixins/ai/stream_completion";
import store from "@/store/index";
import logMessages from "@/mixins/ai/log_messages";
import groqCompletion from "@/mixins/ai/groqCompletion";
import completion from "@/mixins/ai/completion";
import { updateFirebaseArrayProperty, updateFirebaseObject } from "@/mixins/firebase/updateFirebaseObject";
function arrayToBulletPoints(arr) {
    return arr.map(item => `• ${item}`).join("\n\n");
}

export default {
    namespaced: true,
    state() {
        return {
            activeStreamIds: [],
            maxMessageCount: 0,
            stream: {
                messages: [],
                results: [],
                result: {},
                previewCampaign: {},
                generate: {},
                showCampaign: false,
                campaignData: {},
                editing: null,
                options: {
                    files: [],
                    advanced: false,
                    model: gpt4, //gpt4,
                    // model: gpt316,
                    functions: [],
                    selectedFunction: null,
                    userInput: "",
                    showInput: false,
                    temperature: 1,
                },
                editor: {},
                campaigns: [],
            },
        };
    },
    mutations: {
        // updateToggleValue,
        // updateDropdownValue,
        updateToggleValue(state, payload) {
            set(state, payload.key, payload.value);
        },
        addActiveStreamId(state, id) {
            state.activeStreamIds.push(id);
        },
        updateStreamProp(state, { keyPath, value }) {
            try {
                const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");

                function setValue(obj, keys, value) {
                    if (keys.length === 1) return (obj[keys[0]] = value);
                    setValue(obj[keys[0]], keys.slice(1), value);
                }

                setValue(state.stream, keys, value);
            } catch (e) {
                console.groupCollapsed(`%c Update failed`, fail, keyPath);
                console.log(keyPath);
                console.log(value);
                console.log(e);
                console.error(e.message);
                console.groupCollapsed(`%c Trace`, warn, keyPath);
                console.trace();
                console.groupEnd();
                console.groupEnd();
            }
        },
        addMessage(state, message) {
            state.stream.messages.push(message);
        },
        SET_MAX_MESSAGE_COUNT(state, maxCount) {
            state.maxMessageCount = parseInt(maxCount) || 0;
        },
        REMOVE_SECTION(state, { id, index }) {
            state.stream.editor?.[id]?.result?.sections.splice(index, 1);
        },
        ADD_SECTION(state, { id, index, section }) {
            // Directly insert at index + 1 if index is defined
            if (index !== undefined && index !== null) {
                state.stream.editor?.[id]?.result?.sections.splice(index + 1, 0, section);
            } else {
                // If index is not provided, push to the end of the sections array
                state.stream.editor?.[id]?.result?.sections.push(section);
            }
        },
    },
    actions: {
        async addSection({ commit, state, rootState, dispatch }, { id, index, section }) {
            if (!id) return;
            if (!section) {
                section = {
                    header: "New section",
                    body: "New section body",
                    imageIndex: 2,
                    style: "fullBleed",
                    button: { url: "", show: true, text: "Shop Now" },
                };
                if (index) commit("ADD_SECTION", { id, index, section });
                // if no index put it after the last section
                else commit("ADD_SECTION", { id, index: state.stream.editor?.[id]?.result?.sections.length, section });
            }
            // await dispatch("saveStyleGuide", state.styleGuide);
        },
        addMessage({ commit, state }, message) {
            commit("addMessage", message);
        },
        updateMaxMessageCount({ commit }, maxCount) {
            commit("SET_MAX_MESSAGE_COUNT", maxCount);
        },
        setAction({ commit, dispatch, state, getters }, action) {
            if (typeof action === "string") action = getters?.functions.find(f => f.name === action);
            dispatch("updateStreamProp", { keyPath: "options.selectedFunction", value: action });
            try {
                dispatch("buildMessagesForActions");
                dispatch("setActionModel", action.name);
                // dispatch('updateMessages');
            } catch (e) {
                console.error("Error building messages for action", e);
            }
        },
        buildMessagesForActions({ commit, state, getters }) {
            try {
                let { name, prompt, excluded } = getters.selectedFunction;
                let messagesMapping = getters.getMessagesMapping;
                let currentActionMessages = messagesMapping[name] || [];
                currentActionMessages = [sysMessage(prompt), ...currentActionMessages];
                currentActionMessages = currentActionMessages.filter(message => message?.content);

                commit("updateStreamProp", { keyPath: "messages", value: currentActionMessages });
            } catch (e) {
                console.error("Error building messages for action", e);
            }
        },
        addActiveStreamId({ commit, rootState }, id) {
            let newId = rootState.currentRoute.params.id;
            // console.log("Adding active stream id", newId, id);
            if (id) newId = id;
            commit("addActiveStreamId", newId);
        },
        async updateStreamProp({ commit, state, rootState, dispatch }, prop) {
            commit("updateStreamProp", prop);
            // await dispatch("saveStyleGuide", state.styleGuide);
        },
        // remove section in the editor using the id and replace with sections
        async removeSection({ commit, state, rootState, dispatch }, { id, index }) {
            commit("REMOVE_SECTION", { id, index });
            // await dispatch("saveStyleGuide", state.styleGuide);
        },
        async addSectionWithAI({ commit, state, rootState, dispatch, getters }, { id, index, input, existing, type }) {
            dispatch("setAction", "create_email_section");
            if (!input) input = `Add a new section after section ${index + 1}`;
            if (!id) return;
            const campaign = state.stream.editor[id];
            const currentIndex = index;
            let newSectionIndex = currentIndex + 1;
            if (existing) newSectionIndex = currentIndex;
            const nextIdentifier = `sections.${newSectionIndex}`;

            let simpleSections = campaign?.result?.sections || [];
            let prompt = getters.selectedFunction?.prompt;
            // Use the helper function to format the sections
            let simpleSectionString = stringifySections(simpleSections);

            let messages = [...state.stream.messages];
            if (campaign?.messages?.length > 0) messages = campaign.messages;
            let campaignContext = getters.campaignContext;
            if (campaignContext) campaignContext = campaignContext + "\n\n----\n\n# Here's the context for this portion of the campaign:\n\n";
            let messagesToAdd = [sysMessage(prompt), userMessage(`${campaignContext}\n\n----\n\n${simpleSectionString}`), userMessage(input)];
            if (!type) messages = messages.concat(messagesToAdd);
            if (type) messages = [].concat(messagesToAdd);

            // campaignContext

            messages.map(m => console.log("%c" + m.role, purple, m.content));
            let function_call = "create_email_section";
            if (existing && type) function_call = null;
            let path = `editor.${id}.result.sections.${newSectionIndex}`;
            if (type === "button") {
                let function_call = {
                    name: "generate_button_options",
                    description: "Generates 3 button objects with different text and URLs.",
                    parameters: {
                        type: "object",
                        description: "The object containing the button options.",
                        properties: {
                            button_options: {
                                type: "array",
                                items: {
                                    type: "object",
                                    properties: {
                                        text: { type: "string", description: "The button text" },
                                        url: { type: "string", description: "The button URL" },
                                    },
                                    required: ["text", "url"],
                                },
                                minItems: 3,
                            },
                        },
                    },
                };
                let string = await completion({
                    messages,
                    functions: [function_call],
                    function_call: "generate_button_options",
                    model: gpt3,
                    returnFunction: true,
                });
                console.log("Response string:", string.button_options);
                commit("updateStreamProp", {
                    keyPath: `${path}.buttons`,
                    value: string.button_options,
                });
                return;
            }

            if (type) path = `editor.${id}.result.sections.${newSectionIndex}.${type}`;

            let responseString = "";

            if (type) {
                let function_call = {
                    name: "alternative_copy_options",
                    description: "Creates 5 alternative copy options for the section.",
                    parameters: { type: "object", description: "The object containing the text options.", properties: { text_options: { type: "array", items: { type: "string", description: "A rewritten version of the string" }, minItems: 5 } } },
                };
                let string = await completion({
                    messages,
                    functions: [function_call],
                    function_call: "alternative_copy_options",
                    model: gpt3,
                    returnFunction: true,
                });
                console.log("Response string:", string.text_options);
                commit("updateStreamProp", {
                    keyPath: `${path}Options`,
                    value: string.text_options,
                });
                return;
            }
            await streamCompletion(
                store, // pass   the store dispatch function
                "Make a related products section.", // pass the input, this could be a string or an array
                messages, // pass the messages array to add messages to the stream
                undefined, // pass the system message optionally, but this could also be contained in the messages array.
                () => {},
                (token, function_objects, json) => {
                    let section = json?.section || {};
                    if (existing) section = { ...existing, ...section };
                    if (type && existing) section = responseString += token;
                    commit("updateStreamProp", {
                        keyPath: path,
                        value: section,
                    });
                },
                () => {
                    // this.afterCompletingGeneration(obj); // this function lives in this mixin and is used to reset the props for loader, result and done variables within the generationMixin
                    // this.afterCompletion({ ...obj, result: finalResult }); // pass the afterCompletion function to call anything needed after completing the generation. This will be in the file for the specific function. It handles things like saving the result, triggering follow-on functions, etc.
                },
                gpt3, // pass the model to use for the generation (this is set in the file calling this function)
                true, // pass the silent boolean to determine if every step should be logged to the console or not.
                "new section", // set the log name to the function call + input, this is for console logging.
                0, // pass the temperature to use for the generation (this is set in the file calling this function).
                undefined, // pass the functions to use for the generation (this is set in the file calling this function).
                function_call, // pass the function call to use for the generation (this is set in the file calling this function).
                3000, // pass the length to use for the generation (this is set in the file calling this function).
            );
        },
        async saveCampaignItem({ commit, state, rootState, dispatch, getters }, { id, item }) {
            if (id) {
                let campaign = getters.editor[id];
                let matchedObject = await fetchData("campaigns", id);
                matchedObject = deepCopy(matchedObject);
                if (id) console.log("Updated Campaign ID", id);
                let mergedCampaign = { ...matchedObject, ...campaign };
                setTimeout(async () => {
                    let campaignItem = await updateFirebaseObject("campaigns", { ...mergedCampaign }, id);
                    console.log(campaignItem);
                    commit("updateStreamProp", { keyPath: `editor.${id}`, value: campaignItem });
                }, 100);
            }
        },
    },
    getters: {
        activeStreamIds: state => state.activeStreamIds,
        parentCampaign: (state, getters) => getters.campaigns.find(i => i.id === getters.campaignData?.result?.campaignId) || null,
        files(state) {
            console.log("state.stream.files:", state.stream.files);

            if (!state.stream?.options?.files?.length) return [];
            return state.stream.options.files.map(file => {
                let updated;
                if (file?.created_at) {
                    try {
                        updated = new Date(file.created_at * 1000).toISOString();
                    } catch (e) {
                        console.log("Error:", e, "Value of file.created_at:", file.created_at);
                    }
                }
                return {
                    title: file.filename || file.title,
                    url: file.id || file.url,
                    size: file.bytes || file.size,
                    id: file.id || file.url,
                    kind: file.purpose || file.kind,
                    updated: updated || file.updated,
                    status: file.status || file.status,
                };
            });
        },
        currentlyEditing: state => state.stream.editing,
        editing(state) {
            if (!state.stream.editing) return {};
            else return state.stream.editor[state.stream.editing];
        },
        campaignSiblings: (state, getters) => getters.parentCampaign?.result?.schedule_items || null,
        generate: state => state.stream.generate,
        temperature: state => state.stream.options.temperature,
        userInput: state => state.stream.options.userInput,
        selectedFunction: state => state.stream.options.selectedFunction,
        functions: state => state.stream.options.functions,
        generativeFunctions(state) {
            return state.stream?.options?.functions || [];
        },
        showInput: state => state.stream.options.showInput,
        messages: state => state.stream.messages,
        streamMessages: state => state.stream.messages,
        lastMessage: state => {
            let lastIndex = state.stream?.messages?.length - 1;
            return state.stream?.messages[lastIndex];
        },
        campaigns: state => state.stream.campaigns,
        result: state => state.stream.result,
        model: state => state.stream.options.model,
        showCampaign: state => state.stream.showCampaign,
        campaignData: state => state.stream.campaignData,
        campaignChildren: (state, getters) => {
            const currentCampaignId = getters.campaignData?.id;
            if (!currentCampaignId) return [];

            return getters.campaigns.filter(campaign => campaign.result?.campaignId && campaign.result.campaignId === currentCampaignId);
        },
        editor: state => state.stream?.editor || {},
        currentSections: state => state.stream.result?.sections || [],
        advanced: state => state.stream.options.advanced,
        emails: state => {
            return state.stream.campaigns.filter(campaign => campaign?.object_type === "email");
        },
        pages: state => {
            return state.stream.campaigns.filter(campaign => campaign?.object_type === "page");
        },
        campaignIndex: (state, getters) => {
            return getters.campaigns.filter(campaign => campaign?.object_type === "campaign");
        },
        campaignContext(state, getters, rootState, rootGetters) {
            let campaign = getters?.campaignData || {};
            let { thinking, name } = campaign?.result || {};
            if (!thinking) return "";
            let { thoughts = [], plan = [], speak = [], reasoning = [], criticisms = [] } = thinking;
            if (Array.isArray(thoughts)) thoughts = arrayToBulletPoints(thoughts);
            if (Array.isArray(criticisms)) criticisms = arrayToBulletPoints(criticisms);
            if (Array.isArray(plan)) plan = arrayToBulletPoints(plan);
            if (Array.isArray(speak)) speak = arrayToBulletPoints(speak);
            if (Array.isArray(reasoning)) reasoning = arrayToBulletPoints(reasoning);
            let context = [
                "# Context on the overarching campaign", //
            ];
            // `Name of the campaign: ${name}`,
            // `**Thoughts:**\n${thoughts}`,
            // `**Plan:**\n${plan}`,
            // `**Speak:**\n${speak}`,
            // `**Reasoning:**\n${reasoning}`,
            // `**Criticisms:**\n${criticisms}`,
            if (name) context.push(`Name of the campaign: ${name}`);
            if (thoughts) context.push(`**Thoughts:**\n${thoughts}`);
            if (plan) context.push(`**Plan:**\n${plan}`);
            if (reasoning) context.push(`**Reasoning:**\n${reasoning}`);
            if (criticisms) context.push(`**Criticisms:**\n${criticisms}`);
            if (speak) context.push(`**Speak:**\n${speak}`);
            return context.join("\n\n");
        },
        messagesWithContext: (state, getters, rootState, rootGetters) => {
            let llmContext = rootGetters["styleGuide/llm/Context"];
            if (!getters.selectedFunction) return [];
            let prompt = getters.selectedFunction.prompt;
            let messages = [sysMessage(prompt)];
            if (llmContext) {
                Object.entries(llmContext).forEach(([key, value]) => {
                    if (!getters.selectedFunction.excluded?.includes(key)) {
                        messages.push(value);
                    }
                });
            }
            return messages || [];
        },
        campaignStrings: (state, getters) => {
            let stringifiedCampaigns = Object.keys(getters.editor).map((key, index) => {
                let editor = getters.editor[key];
                let result = editor.result;
                let sections = result.sections;
                if (!sections) return;
                let name = result?.name || result.title || ``;
                let title = `Campaign ${index + 1}: ` + (name ? `${name}` : "");
                let type = result?.type || "email";
                return title + ` (${type})\n\n--------\n\n` + stringifySections(sections);
            });
            return stringifiedCampaigns;
        },
        fineTuneMapping: (state, getters) => {
            if (!state?.stream?.campaigns) return [];

            let messages = getters.campaignIndex.map(c => {
                const { result } = c;
                const { object_type, name, thinking, schedule_items = [], complete } = result;
                if (!schedule_items?.length) return;
                const obj = {
                    object_type,
                    name,
                    thinking,
                    schedule_items: schedule_items.map(i => {
                        const { scheduled_on_day, scheduled_on_date, campaign_items = [] } = i;
                        return {
                            scheduled_on_day,
                            scheduled_on_date,
                            campaign_items: campaign_items.map(c => {
                                const { object_type, type_of_campaign, talking_points, instructions, goal, name, complete } = c;
                                return { object_type, name, goal, type_of_campaign, talking_points, instructions, complete };
                            }),
                        };
                    }),
                    complete,
                };
                return { role: "assistant", content: "", function_call: { name: "create_campaign", arguments: JSON.stringify(obj, null, 0) } };
            });
            let function_call = {
                name: "create_campaign",
                description: "Generate a comprehensive schedule for an ecommerce brand's marketing campaign.",
                parameters: {
                    type: "object",
                    properties: {
                        name: { type: "string", description: "The subject of this campaign's item" },
                        relevant_image_indexes: { type: "array", description: "An array of image indexes that are relevant to this campaign.", items: { type: "number" } },
                        thinking: {
                            type: "object",
                            properties: {
                                thoughts: { type: "array", description: "Explain your thinking. Example:'I believe it would be helpful if I told the user hello", items: { type: "string" } },
                                reasoning: { type: "array", description: "Explain your reasoning", items: { type: "string" } },
                                plan: { type: "array", description: "long-term plan for this project", items: { type: "string" } },
                                criticism: { type: "array", description: "constructive self-criticism.", items: { type: "string" } },
                            },
                            required: ["thoughts", "reasoning", "plan", "criticism"],
                        },
                        schedule_items: {
                            type: "array",
                            description: "A chronological array of campaign touchpoints, including emails, SMS messages, and landing pages, along with their scheduled dates.",
                            items: {
                                type: "object",
                                description: "A date in the campaign schedule.",
                                properties: {
                                    scheduled_on_day: { type: "number", description: "The day of the campaign on which this item is scheduled, relative to the campaign start date." },
                                    scheduled_on_date: { type: "string", format: "date", description: "The exact date (YYYY-MM-DD) when this campaign item is scheduled to be delivered or published." },
                                    campaign_items: {
                                        type: "array",
                                        description: "An array of marketing items to be created and delivered on this specific date.",
                                        items: {
                                            type: "object",
                                            properties: {
                                                object_type: {
                                                    type: "string",
                                                    description: "Type of campaign item",
                                                    enum: ["email", "SMS", "page"],
                                                },
                                                type_of_campaign: {
                                                    type: "string",
                                                    description: "The specific campaign type or objective that this item is designed to support.",
                                                    enum: ["welcome_series", "abandoned_checkout", "remarketing", "winback", "upsell", "cross-sell", "product_launch", "holiday", "flash_sale", "content_promotion", "newsletter", "announcement", "follow-up"],
                                                },
                                                name: {
                                                    type: "string",
                                                    description: "The subject of this campaign's item",
                                                },
                                                goal: {
                                                    description: "The primary goal or objective that this campaign item aims to achieve.",
                                                    type: "string",
                                                    enum: ["Increase Sales", "Enhance Brand Image", "Brand Awareness", "Lead Generation", "Product Sales", "Brand Awareness", "teaser", "announcement", "follow-up"],
                                                },
                                                instructions: {
                                                    description: "Detailed Instructions from each stakeholder on what should be covered in this campaign item.",
                                                    type: "object",
                                                    properties: {
                                                        content_structure_from_content_strategist: {
                                                            type: "array",
                                                            description: "Provide a detailed outline of the sections to include in this item, optimized for reader engagement and flow.",
                                                            items: { type: "string" },
                                                        },
                                                        copy_instructions_from_copywriter: {
                                                            type: "array",
                                                            description: "Offer specific guidance on crafting compelling, on-brand copy for this item, including storytelling techniques, tone, and language.",
                                                            items: { type: "string" },
                                                        },
                                                        directions_from_creative_director: {
                                                            type: "array",
                                                            description: "Provide comprehensive direction on the overarching theme and emotional impact for this item.",
                                                            items: { type: "string" },
                                                        },
                                                        strategic_goals_from_CMO: {
                                                            type: "array",
                                                            description: "Define the strategic objectives and key performance indicators (KPIs) for this campaign item.",
                                                            items: { type: "string" },
                                                        },
                                                        brand_guidelines_from_brand_manager: {
                                                            type: "array",
                                                            description: "Provide essential brand guidelines to ensure this item aligns with the brand.",
                                                            items: { type: "string" },
                                                        },
                                                    },
                                                    required: ["content_structure_from_content_strategist", "copy_instructions_from_copywriter", "directions_from_creative_director", "strategic_goals_from_CMO", "brand_guidelines_from_brand_manager"],
                                                },
                                                talking_points: {
                                                    type: "array",
                                                    description: "A list of key messages, benefits, or CTAs to emphasize in this campaign item.",
                                                    items: { type: "string" },
                                                },
                                                complete: { const: "true", description: "Indicates that all required components for this campaign item have been provided." },
                                            },
                                            required: ["object_type", "type_of_campaign", "name", "goal", "instructions", "talking_points", "complete"],
                                        },
                                    },
                                },
                                required: ["scheduled_on_day", "scheduled_on_date", "campaign_items"],
                            },
                        },
                        complete: { const: "true" },
                    },
                    required: ["name", "thinking", "relevant_image_indexes", "schedule_items", "complete"],
                },
            };
            messages = messages
                .filter(m => m)
                .map(m => {
                    if (!m) return;
                    let messages = [sysMessage("You are an expert AI specializing in creating highly effective, on-brand, multi-channel marketing campaigns."), m];
                    return { messages: messages, functions: [function_call] };
                });

            return messages;
        },
        getMessagesMapping({ state, rootGetters }) {
            try {
                return {
                    create_email_new: rootGetters["styleGuide/llm/EmailMessages"] || [],
                    create_campaign: rootGetters["styleGuide/llm/CampaignMessages"] || [],
                    create_email_section: rootGetters["styleGuide/llm/EmailMessages"] || [],
                    create_landing_page: rootGetters["styleGuide/llm/LandingPageMessages"] || [],
                    get_brand_voice: [sysMessage(rootGetters["styleGuide/llm/AboutBrand"] || ""), sysMessage(rootGetters["styleGuide/llm/headerParagraph"] || "")],
                    get_brand_voice_continued: [sysMessage(rootGetters["styleGuide/llm/CopyGuidelines"] || ""), sysMessage(rootGetters["styleGuide/llm/AboutBrand"] || ""), sysMessage(rootGetters["styleGuide/llm/headerParagraph"] || "")],
                    get_brand_personas: [userMessage(rootGetters["styleGuide/llm/HeaderSamples"] || ""), userMessage(rootGetters["styleGuide/llm/ParagraphSamples"] || ""), sysMessage(rootGetters["styleGuide/llm/CopyGuidelines"] || ""), sysMessage(rootGetters["styleGuide/llm/ProductParagraphs"] || "")],
                    create_brand_voice_samples: [sysMessage(rootGetters["styleGuide/llm/AboutBrand"] || ""), sysMessage(rootGetters["styleGuide/llm/CopyGuidelines"] || ""), userMessage(rootGetters["styleGuide/llm/HeaderSamples"] || ""), userMessage(rootGetters["styleGuide/llm/ParagraphSamples"] || "")],
                    generate_synthetic_brand_copy: [sysMessage(rootGetters["styleGuide/llm/AboutBrand"] || ""), sysMessage(rootGetters["styleGuide/llm/CopyGuidelines"] || ""), userMessage(rootGetters["styleGuide/llm/HeaderSamples"] || ""), userMessage(rootGetters["styleGuide/llm/ParagraphSamples"] || "")],
                    create_typography_style_guide: [sysMessage(rootGetters["styleGuide/llm/AboutBrand"] || ""), userMessage(rootGetters["styleGuide/allTextStyles"] || ""), userMessage(rootGetters["styleGuide/fonts"] || ""), userMessage(rootGetters["styleGuide/buttonStyles"] || "")],
                };
            } catch (e) {
                console.error("Error retrieving message mappings:", e.message);
                return {};
            }
        },
    },
};
