<template>
    <div class="mt-6 flex gap-x-3">
        <img alt="" class="h-6 w-6 flex-none rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" />
        <form action="#" class="relative flex-auto">
            <div class="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label class="sr-only" for="comment">Add your comment</label>
                <textarea id="comment" class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" name="comment" placeholder="Add your comment..." rows="2" />
            </div>

            <div class="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div class="flex items-center space-x-5">
                    <div class="flex items-center">
                        <button class="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500" type="button">
                            <PaperClipIcon aria-hidden="true" class="h-5 w-5" />
                            <span class="sr-only">Attach a file</span>
                        </button>
                    </div>
                    <MoodSelector :moods="moods" />
                </div>
                <button class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" type="submit">Comment</button>
            </div>
        </form>
    </div>
</template>

<script>
import { FaceFrownIcon, FaceSmileIcon, FireIcon, HandThumbUpIcon, HeartIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import PaperClipIcon from "@heroicons/vue/20/solid/PaperClipIcon";
import MoodSelector from "./ActivityMoodSelector.vue";

export default {
    name: "NewCommentForm",
    components: {
        PaperClipIcon,
        MoodSelector,
    },
    data: {
        moods: [
            { name: "Excited", value: "excited", icon: FireIcon, iconColor: "text-white", bgColor: "bg-red-500" },
            { name: "Loved", value: "loved", icon: HeartIcon, iconColor: "text-white", bgColor: "bg-pink-400" },
            { name: "Happy", value: "happy", icon: FaceSmileIcon, iconColor: "text-white", bgColor: "bg-green-400" },
            { name: "Sad", value: "sad", icon: FaceFrownIcon, iconColor: "text-white", bgColor: "bg-yellow-400" },
            { name: "Thumbsy", value: "thumbsy", icon: HandThumbUpIcon, iconColor: "text-white", bgColor: "bg-blue-500" },
            { name: "I feel nothing", value: null, icon: XMarkIcon, iconColor: "text-gray-400", bgColor: "bg-transparent" },
        ],
    },
};
</script>
