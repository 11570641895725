<template>
    <div class="p-8">
        <form @submit.prevent="submitForm">
            <FormField v-for="field in schema.fields" :key="field.id" :field="field" :value="formData[field.id]" @update:value="formData[field.id] = $event" />
            <!-- Dynamic form field generated based on schema -->
            <!--            <FormField v-for="(field, index) in schema.fields" :key="index" :label="field.label" :type="field.type" :placeholder="field.placeholder" :options="field.options" v-model="formData[field.key]" :validation="field.validation" @validation-error="handleValidationError(field.key, $event)" />-->
            <button type="submit" class="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Submit</button>
        </form>
        <div v-if="Object.keys(validationErrors).length" class="text-red-500">
            <p>Please fix the following errors:</p>
            <ul>
                <li v-for="(error, field) in validationErrors" :key="field">
                    {{ error }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import FormField from "@/views/Chat/FormField.vue";

export default {
    name: "ToolBuilder",
    components: {
        FormField,
    },
    data() {
        return {
            // Initial form data state
            formData: {},
            // Validation errors state
            validationErrors: {},
            // JSON schema for form generation
            schema: {
                fields: [
                    {
                        id: "name",
                        key: "name",
                        label: "Name",
                        type: "text",
                        placeholder: "Enter your name",
                        validation: { required: true },
                    },
                    // Add other schema fields here...
                ],
            },
        };
    },
    methods: {
        submitForm() {
            if (this.validateForm()) {
                console.log("Form data:", this.formData);
                // Handle form submission here, like sending to an API
            }
        },
        validateForm() {
            this.validationErrors = {}; // Reset errors
            let isValid = true;
            // Validate each form field
            for (const field of this.schema.fields) {
                const value = this.formData[field.key];
                if (field.validation.required && !value) {
                    this.validationErrors[field.key] = `${field.label} is required.`;
                    isValid = false;
                }
                // Include other validation checks here...
            }
            return isValid;
        },
        handleValidationError(key, message) {
            this.validationErrors = { ...this.validationErrors, [key]: message };
        },
    },
};
</script>
