<template>
    <div v-if="grid" class="grid grid-cols-1 md:grid-cols-12 gap-2 border-t-brand py-3 text-balance">
        <span v-if="title" class="md:col-span-3 block fwb text-capitalize text-xl">{{ convertCase(title) }}</span>
        <span class="font-light md:col-span-8 block mb-0 pb-0 !mt-0 pt-0">
            <span v-if="Array.isArray(body)">{{ body.join(", ") }}</span>
            <span v-else-if="typeof body === 'string'" class="whitespace-pre-line block">{{ body }}</span>
        </span>
    </div>
    <UICollapsableSection v-else-if="collapse && title">
        <template v-if="title" #title>{{ convertCase(title) }}</template>
        <p v-if="body" class="text lg:w-90 mb-3 f-24 font-light !leading-normal text-pretty">
            <span v-if="Array.isArray(body)">{{ body.join(", ") }}</span>
            <span v-else-if="typeof body === 'string'" class="whitespace-pre-line block">{{ body }}</span>
            <span v-else-if="typeof body === 'object'" class="">
                <span v-for="(value, key) in body" :key="key">
                    <span class="fwb block">{{ convertCase(key) }}:</span>
                    {{ value }}
                </span>
            </span>
        </p>
    </UICollapsableSection>
    <div v-else class="f-20">
        <span v-if="title" class="col-span-3 block fwb text-capitalize text-pretty">{{ convertCase(title) }}</span>
        <span class="font-light col-span-9 block">
            <slot></slot>
            <span v-if="Array.isArray(body)">{{ body.join(", ") }}</span>
            <span v-else-if="typeof body === 'string'" class="whitespace-pre-line block">{{ body }}</span>
            <span v-else-if="typeof body === 'object'" class="whitespace-pre-wrap">
                <span v-for="(value, key) in body" :key="key">
                    <span class="fwb block">{{ convertCase(key) }}:</span>
                    {{ value }}
                </span>
            </span>
        </span>
    </div>
</template>
<script>
import UICollapsableSection from "@/components/CoreUI/UICollapsableSection.vue";

export default {
    name: "UITextBlurb",
    components: { UICollapsableSection },
    props: {
        body: {
            type: [String, Array],
        },
        title: {
            type: String,
            default: "",
        },
        collapse: {
            type: Boolean,
            default: false,
        },
        grid: {
            type: Boolean,
            default: false,
        },
    },
    methods: {},
};
</script>
