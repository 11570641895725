<!-- MessageViewer.vue -->
<template>
    <div class="relative x" v-if="chat">
        <div v-for="(message, index) in chat" :key="index">
            <MessageItem :message="message" />
        </div>
    </div>
</template>

<script>
import MessageItem from "@/components/Admin/MessageItem.vue";

export default {
    name: "MessageViewer",
    components: { MessageItem },
    props: {
        chat: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            truncatedItems: {},
        };
    },
    mounted() {
        this.initializeTruncatedItems();
    },
    methods: {
        initializeTruncatedItems() {
            this.chat.forEach(message => {
                if (this.shouldTruncate(message.content)) {
                    this.truncatedItems[message.content] = true;
                }
            });
        },
        getTruncatedContent(content) {
            if (this.isTruncated(content)) {
                return content.slice(0, 150) + "...";
            }
            return content;
        },
        toTitleCase(str) {
            if (!str || typeof str !== "string") return str;
            return str
                .split(/[-_]/)
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        },
        shouldTruncate(content) {
            return typeof content === "string" && content.length > 150;
        },
        isTruncated(content) {
            return this.truncatedItems[content] || false;
        },
        toggleTruncation(content) {
            this.truncatedItems[content] = !this.truncatedItems[content];
        },
    },
};
</script>
