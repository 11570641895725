<template>
    <button :class="{ 'bg-red text-snow': recording }" class="f microphone-button border-light" @mousedown="startRecordingMediaRecorder" @mouseup="stopRecordingMediaRecorder">
        <i :class="{ 'fas fa-stop': recording, 'fas fa-microphone': !recording }"></i>
    </button>
</template>

<script>
const openAIKey = import.meta.env.VITE_OPENAI_API;

export default {
    name: "record-audio",
    data() {
        return {
            mediaRecorder: null,
            mediaStream: null,
            recordedChunks: [],
            recordedBlobs: [],
            recordedText: "",
            recording: false,
            recordedAudio: null,
        };
    },
    methods: {
        handleRecording() {
            if (this.recording) {
                this.stopRecording();
                console.log("Clicked Stop Recording");
            } else {
                this.startRecording();
                console.log("Clicked Start Recording");
            }
            this.recording = !this.recording;
        },
        resetRecordings() {
            this.recordedChunks = [];
            this.recordedBlobs = [];
            this.recordedText = "";
            this.recordedAudio = null;
        },
        async startRecordingMediaRecorder() {
            if (!this.recording) {
                this.resetRecordings();
                const mimeType = "audio/mp4";
                this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                if (MediaRecorder.isTypeSupported(mimeType)) {
                    this.mediaRecorder = new MediaRecorder(this.mediaStream, { mimeType });
                } else {
                    this.mediaRecorder = new MediaRecorder(this.mediaStream);
                }

                this.mediaRecorder.ondataavailable = e => {
                    this.recordedChunks.push(e.data);
                    console.log("Data is available: ", e.data);
                };

                this.mediaRecorder.start();
                console.log("Recording started");
                this.recording = true;
            }
        },
        async stopRecordingMediaRecorder() {
            if (this.recording) {
                this.mediaRecorder.stop();
                console.log("Recording stopped");
                this.recording = false;

                // Stop each audio track within the stream
                this.mediaStream.getAudioTracks().forEach(track => track.stop());
                console.log("Audio tracks stopped");

                // Add a short delay before creating the blob and processing
                setTimeout(async () => {
                    const blob = new Blob(this.recordedChunks, { type: "audio/mp4" });
                    this.recordedAudio = URL.createObjectURL(blob);
                    console.log("Blob created: ", blob);
                    this.transcribeAudio(blob);
                }, 1000);
            }
        },
        async startRecordingMediaStream() {
            if (!this.recording) {
                this.resetRecordings();
                try {
                    this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    console.log("mediaStream:", this.mediaStream); // Add this line
                    this.handleRecordingStartMediaStream();
                } catch (e) {
                    console.error("navigator.getUserMedia error:", e);
                }
                this.recording = true;
            }
        },
        stopRecordingMediaStream() {
            if (this.recording) {
                this.mediaRecorder.stop();
                this.mediaStream.getAudioTracks()[0].stop();
                // And here as well
                this.recording = false;
                console.log("Recorded Blobs: ", this.recordedBlobs);
                this.recordedAudio = new Blob(this.recordedBlobs, { type: "audio/mp4" });

                // Delay upload to allow all blobs to be processed
                setTimeout(() => {
                    this.uploadAudio();
                }, 1000);
            }
        },
        handleRecordingStartMediaStream() {
            let options = { mimeType: "audio/mp4;codecs=opus" };
            try {
                this.mediaRecorder = new MediaRecorder(this.mediaStream, options);
                console.log("mediaRecorder:", this.mediaRecorder); // Add this line
            } catch (e) {
                console.error("Exception while creating MediaRecorder:", e);
                return;
            }
            console.log("Created MediaRecorder", this.mediaRecorder, "with options", options);
            this.recording = true;
            this.mediaRecorder.onstop = event => {
                console.log("Recorder stopped: ", event);
            };
            this.mediaRecorder.ondataavailable = this.handleDataAvailableMediaStream;
            this.mediaRecorder.start(10); // collect 10ms of data
            console.log("MediaRecorder started", this.mediaRecorder);
        },
        handleDataAvailableMediaStream(event) {
            console.log("handleDataAvailable", event);
            if (event.data && event.data.size > 0) {
                this.recordedBlobs.push(event.data);
            }
        },
        async uploadAudio() {
            if (!this.recordedBlobs.length) return;

            const audioBlob = new Blob(this.recordedBlobs, { type: "audio/mp4" });

            try {
                const headers = {
                    Authorization: `Bearer ${openAIKey}`,
                };
                const formData = new FormData();
                formData.append("file", audioBlob, "audio.mp4");
                formData.append("model", "whisper-1");

                console.log("Submitting transcription request");
                const response = await fetch("https://api.openai.com/v1/audio/transcriptions", {
                    method: "POST",
                    headers: headers,
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error(`Failed to process the file upload, status: ${response.status}`);
                }

                const data = await response.json();
                console.log("Transcription response:", data);
                this.processTranscription(data);
            } catch (error) {
                console.error("Error processing the file upload:", error.message);
            }
        },
        async transcribeAudio(file) {
            const headers = {
                Authorization: `Bearer ${openAIKey}`,
            };
            const formData = new FormData();
            formData.append("file", file, "audio.mp4");
            formData.append("model", "whisper-1");

            try {
                console.log("Submitting transcription request");
                const response = await fetch("https://api.openai.com/v1/audio/transcriptions", {
                    method: "POST",
                    headers: headers,
                    body: formData,
                });
                const data = await response.json();
                console.log("Transcription response: ", data);
                this.processTranscription(data);
            } catch (error) {
                console.error("Error transcribing audio:", error);
            }
        },
        processTranscription(resp) {
            if (resp && resp.text) {
                this.recordedText = resp.text;
                this.$emit("transcription", resp.text);
                console.log("Processed transcription: ", resp.text);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.voice-section {
  @apply flex aic justify-start;
  @apply f-13 px-3 pb-2;
  @apply bg-snow;
  @apply dark:bg-transparent;
  .microphone-button{
    @apply f-17 width-30 height-30 aic jcc flex items-center justify-center rounded-full p-3;
    @apply border-gray-300 bg-snow text-gray-300 hover:border-gray-400 hover:bg-gray-50 hover:text-gray-400;
    @apply dark:border-base-500 dark:text-base-500 dark:hover:border-base-400 dark:hover:text-base-400;
    @apply dark:bg-transparent dark:hover:bg-base-50/5;
  }
}
</style>
