<template>
    <div>
        <ModifyAssistant v-if="createNewAssistant" />
        <ThreadViewerMessages v-else />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ThreadMessage from "@/views/Chat/ThreadMessage.vue";
import ThreadRun from "@/views/Chat/ThreadRun.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import CurrentChatInfo from "@/views/Chat/CurrentChatInfo.vue";
import FileUploadCard from "@/components/images/FileUploadCard.vue";
import ModelBadge from "@/views/Chat/ModelBadge.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import ModifyAssistant from "@/views/Chat/ModifyAssistant.vue";
import ThreadViewerMessages from "@/views/Chat/ThreadViewerMessages.vue";

export default {
    name: "ThreadViewer",
    components: { ThreadViewerMessages, ModifyAssistant, BaseButton, ModelBadge, FileUploadCard, CurrentChatInfo, LoadingSpinner, ThreadRun, ThreadMessage },
    data() {
        return {
            inputText: "",
            threadList: ["thread_jIdDd1Y3jgCksTPu3umrkiVu", "thread_ebQBFIerov1liZIMCh2y5K7j", "thread_2zGcr3tCEFkQoP28gtSFFCnX"],
            assistantLoading: false,
            createNewAssistant: false,
        };
    },
    computed: {
        ...mapGetters("assistants", ["user", "threads", "runs", "assistants", "threadId", "assistantId", "conversation", "currentAssistantFiles"]),
        files() {
            return this.$store.state.stream?.stream?.options?.files || [];
        },
        currentAssistant() {
            if (!this.assistants) return;
            let assistantId = this.$store.state.assistants.assistantId;
            let assistant = this.assistants.find(asst => asst.id === assistantId);
            return assistant ? assistant : {};
        },
        // conversations() {
        //     const messages = this.$store?.state?.assistants?.threads?.reverse() || [];
        //     const runs = this.$store?.state?.assistants?.runs || [];
        //
        //     // Merge and sort by created_at
        //     return [...messages, ...runs].sort((a, b) => a.created_at - b.created_at);
        // },
    },
    methods: {
        async getFiles() {
            let files = await this.listFiles();
            this.updateStreamProp("options.files", files);
        },
        async getAssistants() {
            await this.$store.dispatch("assistants/listAssistants");
        },
        async getThreads(id) {
            if (!id) id = this.$store.state.assistants.threadId;
            await this.$store.dispatch("assistants/getThreads", id);
        },
        async sendAssistantMessage(message) {
            this.assistantLoading = true;
            let object = {
                message: message,
                userId: "asher",
                threadId: this.threadId,
                assistantId: this.assistantId,
                model: gpt3,
            };
            this.inputText = "";
            await this.$store.dispatch("assistants/sendAssistantMessage", object);
            this.assistantLoading = false;
        },
    },
};
</script>
