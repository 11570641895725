<template>
    <div v-if="result && !result.name" class="height-100 f aic jcc sectionBg animated-pastel-gradient x shadow-high">
        <div class="f aic jcc fc">
            <!--            <StringLoader class="f-15 mix-blend-multiply" :text="['Loading', 'finding things', 'making it great']" />-->
            <!--                        <StringLoader class="f-15 mix-blend-multiply" v-if="result?.loading" :text="['Loading', 'finding things', 'making it great']" />-->
            <!--            <div class="mix-blend-overlay" v-else>-->
            <LoadingSpinner v-if="result?.loading" class="o-3" color="black"></LoadingSpinner>
            <!--            </div>-->
        </div>
        <!--        <loading-bounce :colors="$store.getters['styleGuide/colors'].all_colors"></loading-bounce>-->
    </div>
    <div v-else-if="result && result.name" class="x mx-auto pb-8" :class="{ '': result.name }">
        <!--        {{ result }}-->
        <div class="flex mw-400 fc gap-3 py-3 mx-auto">
            <template v-for="(value, key, index) in result" :key="key + index + 'parent'">
                <template v-if="key === 'name'">
                    <h4 class="x text-center fwb mt-5 pt-5 block">{{ value }}</h4>
                </template>
                <Transition name="fade-up">
                    <div class="mw-400 fc f gap-2" v-if="!excludedKeys.includes(key)">
                        <template v-if="key === 'thinking'">
                            <template v-for="(it, kk, ii) of value" :key="kk.replace('_', ' ') + index">
                                <template v-if="kk === 'plan'">
                                    <Transition name="fade-up">
                                        <template v-if="ii < Object.keys(value)?.length - 1">
                                            <PresentationProgressStep hide-if-complete :progress-step="{ name: 'Planning', text: processText(it), complete: ii < Object.keys(value)?.length - 1 }"></PresentationProgressStep>
                                        </template>
                                        <div v-else class="p-3 border-light br-10">
                                            <p class="fwb">Plan</p>
                                            <div class="x pb-2 f ais jcs gap-3" v-for="(task, ti) in it">
                                                <div class="c-25 bg-snow border-light br-10 f aic jcc animated zoomIn d-2 elastic-easing" style="border-radius: 10px">
                                                    <transition name="zoom-in">
                                                        <div v-if="ti < it.length - 1 || value?.criticism || value?.speak"><i class="fas fa-check animated zoomIn d-5 block elastic-easing" appear></i></div>
                                                    </transition>
                                                    <!--                                        <pre class="f-11">{{ value }}</pre>-->
                                                </div>
                                                <span style="font-family: monospace, monospace" class="f-15 w-75 block fwb">{{ task }}</span>
                                            </div>
                                        </div>
                                    </Transition>
                                </template>
                                <template v-else-if="kk === 'criticism'">
                                    <Transition name="fade-up">
                                        <template v-if="ii < Object.keys(value)?.length - 1">
                                            <PresentationProgressStep hide-if-complete :progress-step="{ name: 'Refining', text: processText(it), complete: ii < Object.keys(value)?.length - 1 }"></PresentationProgressStep>
                                        </template>
                                        <div class="p-3 border-light br-10 shadow" v-else>
                                            <p class="fwb">Notes</p>
                                            <div class="x pb-2 f ais jcs gap-3" v-for="(task, ti) in it">
                                                <div class="c-20 bg-snow border-light br-10 f aic jcc animated zoomIn d-2 elastic-easing rounded-full f-11" style="border-radius: 10px">
                                                    <Transition name="zoom-in">
                                                        <div v-if="ti < it.length - 1 || value?.criticism || value?.speak"><i class="fas bold fa-dash animated zoomIn d-5 block elastic-easing" appear></i></div>
                                                    </Transition>
                                                </div>
                                                <span style="font-family: monospace, monospace" class="f-15 w-75 block fwb">{{ task }}</span>
                                            </div>
                                        </div>
                                    </Transition>
                                </template>
                                <template v-else-if="kk === 'thoughts'">
                                    <Transition name="fade-up">
                                        <div v-if="ii < Object.keys(value)?.length - 1">
                                            <PresentationProgressStep hide-if-complete :progress-step="{ name: 'Thinking', text: processText(it), complete: ii < Object.keys(value)?.length - 1 }"></PresentationProgressStep>
                                        </div>
                                        <div v-else>
                                            <PresentationProgressStep hide-if-complete :progress-step="{ name: 'Thinking', text: processText(it), complete: ii < Object.keys(value)?.length - 1 }"></PresentationProgressStep>
                                        </div>
                                    </Transition>
                                </template>
                                <template v-else-if="kk === 'speak'">
                                    <p class="fwb">Next up</p>
                                    <div class="x pb-2">
                                        <span style="font-family: monospace, monospace" class="f-15 x block">{{ it }}</span>
                                    </div>
                                </template>
                                <PresentationProgressStep v-else hide-if-complete :progress-step="{ name: kk.replace('_', ' '), text: processText(it), complete: ii < Object.keys(value)?.length - 1 }"></PresentationProgressStep>
                            </template>
                        </template>
                        <template v-else-if="Array.isArray(value) && key">
                            <PresentationProgressStep v-for="li in value" hide-if-complete :progress-step="{ name: key?.replace('_', ' '), text: processText(li), complete: index < Object.keys(result)?.length - 1 }"></PresentationProgressStep>
                        </template>
                        <template v-else-if="typeof value === 'object' && key">
                            <PresentationProgressStep v-for="li in value" hide-if-complete :progress-step="{ name: key?.replace('_', ' '), text: processText(li), complete: index < Object.keys(result)?.length - 1 }"></PresentationProgressStep>
                        </template>
                        <PresentationProgressStep v-else-if="key" hide-if-complete :progress-step="{ name: key?.replace('_', ' '), text: processText(value), complete: index < Object.keys(result)?.length - 1 }"></PresentationProgressStep>
                    </div>
                </Transition>
                <!--            {{ index }}-->
                <!--            {{ Object.keys(result).length }}-->
                <!--        count the number of keys in the object-->
            </template>
        </div>
    </div>
</template>
<script>
import PresentationProgressStep from "@/components/Presentations/slides/PresentationProgressStep.vue";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import StringLoader from "@/components/Admin/StringLoader.vue";

export default {
    name: "LandingPageLoader",
    components: { StringLoader, LoadingSpinner, PresentationProgressStep },
    props: {
        result: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            excludedKeys: [
                "name",
                "object_type",
                "relevant_image_indexes",
                "relevant_product_indexes",
                "relevant_product_indexes",
                "relevant_image_indexes",
                "relevant_images",
                "relevant_product",
                "relevant_product_index",
                "subject_line",
                "item_counts",
                "schedule_items",
                "preview_text",
                "campaignId",
                "relevant_image_indexes",
                "sections",
                "itemIndex",
                "brand",
                "messages",
                "campaignIndex",
                "complete",
            ],
        };
    },
    mounted() {
        this.animateText();
    },
    methods: {
        processText(i) {
            // Check if 'i' is an array and join with new line if it is
            return i;
            return Array.isArray(i) ? ` - ${i.join("\n - ")}` : i;
        },
        animateText() {
            gsap.utils.toArray(".animateText").forEach(element => {
                const words = element.textContent.split(" ");
                element.textContent = "";
                words.forEach(word => {
                    let span = document.createElement("span");
                    span.textContent = word + " ";
                    element.appendChild(span);
                });

                gsap.fromTo(element.children, { translateY: 200, opacity: 0 }, { translateY: 0, opacity: 1, stagger: 0.1, duration: 0.5, ease: "power2.out" });
            });
        },
    },
};
</script>
<style lang="scss">
@keyframes slide {
    from {
        background-position: 0% 50%;
    }
    to {
        background-position: 100% 50%;
    }
}
</style>
