import { mapGetters } from "vuex";
import create_presentation from "@/ai_functions/create_presentation";
import create_slide from "@/ai_functions/create_slide";
import updateDocumentProp from "@/mixins/ai/update_document_prop";
export default {
    created: function () {},
    mixins: [],
    data() {},
    computed: {
        ...mapGetters("presentation", ["document"]),
        addSmartSlide() {
            return create_slide;
        },
    },
    methods: {
        processPresentation(functionArgs) {
            let presentation = {
                title: functionArgs.title || "Title",
                summary: functionArgs.summary || "Body",
                sections: functionArgs.slides || "",
            };
            // presentation.sections.forEach(slide => {
            //     this.$store.dispatch("presentation/addSlide", slide);
            // });
            updateDocumentProp(this.$store, "title", presentation.title);
            updateDocumentProp(this.$store, "summary", presentation.summary);
            updateDocumentProp(this.$store, "sections", presentation.sections);
            console.table(presentation);
            // this.$store.dispatch("presentation/addPresentation", presentation);
            this.$store.dispatch("presentation/savePresentation", this.$router.params.id);
        },
        processSlideCommand(functionArgs) {
            let slide = {
                title: functionArgs.title || "Title",
                body: functionArgs.body || "Body",
                image: functionArgs.image_description || "",
                style: functionArgs.style || "left",
                index: functionArgs.index || false,
            };
            if (slide.index && slide.index > 0) {
                this.$store.dispatch("presentation/updateSlide", slide);
                this.addSlideAtIndex(slide, slide.index);
            } else {
                this.addSlide(slide);
            }
            console.table(slide);
            console.groupEnd();
            // this.$store.dispatch("presentation/addSlide", slide);
            this.$store.dispatch("presentation/savePresentation", this.$router.params.id);
        },
        presentationCommand() {
            return create_presentation;
        },
    },
};
