import { doc, Timestamp, getDoc, query, collection, where, deleteDoc, addDoc, getDocs, orderBy, updateDoc, setDoc } from "firebase/firestore";
import _ from "lodash";

import firestore, { db } from "@/firebase";
import * as firebase from "@/firebase";

function isPathArrayElement(pathParts) {
    const lastPart = pathParts[pathParts.length - 1];
    return !isNaN(parseInt(lastPart)) && pathParts.length > 1;
}
function getPathValue(obj, pathArray) {
    return pathArray.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
}
async function updateFirestoreProperty(database, value, id, nestedPath) {
    if (!id || typeof id !== "string" || !nestedPath || (typeof nestedPath !== "string" && !Array.isArray(nestedPath))) {
        console.error('Invalid "id" or "nestedPath". Both must be provided with "id" as a string and "nestedPath" as a string or array.');
        return false; // Adjust according to your error handling
    }

    const path = Array.isArray(nestedPath) ? nestedPath.join(".") : nestedPath;
    const docRef = doc(db, database, id);

    try {
        if (isPathArrayElement(nestedPath)) {
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) throw new Error("Document does not exist.");

            const data = docSnap.data();
            const arrayPath = path.substring(0, path.lastIndexOf("."));
            const lastIndex = parseInt(nestedPath[nestedPath.length - 1], 10);
            let arrayValue = getPathValue(data, arrayPath.split(".")) || [];

            if (lastIndex < 0 || lastIndex >= arrayValue.length) throw new Error("Array index out of bounds.");

            arrayValue[lastIndex] = value;
            const updateObj = {};
            updateObj[arrayPath] = arrayValue;
            await updateDoc(docRef, updateObj);
        } else {
            await updateDoc(docRef, { [path]: value });
        }

        // return { success: true, updatedPath: nestedPath, value: value };
        console.log(`%Updated Path`, logFirebase, `Successfully updated ${path} in ${database}`);
        // console.log(`Successfully updated ${path} in ${database}`);
        return true;
    } catch (error) {
        console.error(`Error updating property in ${database}: ${error.message}`);
        return false; // Adjust according to your error handling
    }
}
// let object = await updateFirestoreProperty("brands", payload, id, path);
export { updateFirestoreProperty };
