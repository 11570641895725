<template>
    <div class="p-absolute p-fill f aic jcc brand-bg brand-bg" ref="solidBg" :key="$route.params.id">
        <!--        <div class="bg-snow p-fixed p-fill z-5"></div>-->
        <div v-for="(color, index) in loadingColor" :ref="`colorDiv${index}`" :style="getStyle(color)" :class="`z-${index + 1}`" class="p-absolute top left bottom z-5"></div>
        <div class="p-absolute p-fill f aic jcc z-10">
            <div ref="text" class="text-center">
                <!--                <h1 v-if="status" class="" :style="`font-size:200px;color:${textColor}`">{{ status }}</h1>-->
                <h1 v-if="styleGuide.name" :style="`${fontSize}color:unset;color:${textColor} `">
                    <!--                  font-size:200px!important;-->
                    <span class="animated fadeInUpSmooth" :key="styleGuide.fonts + styleGuide.name">{{ styleGuide.name }}</span>
                </h1>
                <p v-if="styleGuide.description" class="text-center mx-auto line-height-normal mw-400 o-5 mb-5 f-20">{{ styleGuide.description }}</p>
                <div v-if="status" class="relative mw-300 x text-center mx-auto flex aic jcc rounded-full shadow" style="height: 30px" :style="pillStyle">
                    <div :key="status" v-if="status" class="line-height-normal mw-400 f aic jcc absolute block x text-uppercase fwb f-15 rounded-full">
                        {{ status }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { gsap } from "gsap";
let { fromTo, to, timeline } = gsap;
import { mapGetters } from "vuex";
export default {
    name: "ColorSwipeLoader",
    components: {},
    props: {
        // colors: {
        //     type: Array,
        //     required: true,
        // },
    },
    data() {
        return {
            clicked: false,
            loadingTimer: null,
            showFirstLayer: false,
            textColor: "",
            colorArray: [],
            textColors: [],
            timeline: null,
        };
    },
    computed: {
        ...mapGetters("styleGuide", ["styleGuide", "divStyles", "justLoaded"]),
        fontSize() {
            if (this.mobile) {
                return ` font-size: 100px!important;`;
            } else {
                return ` font-size: 200px!important;`;
            }
        },
        filteredDivStyles() {
            let styles = this.divStyles || [];
            styles = styles.filter(s => s.backgroundColor !== "#ffffff");
            styles = styles.filter(s => !s.backgroundColor.includes("gradient"));
            return styles;
        },
        pillStyle() {
            if (this.styleGuide?.buttonStyles?.[0]) {
                let pillStyle = this.styleGuide.buttonStyles[0];
                return `background:${pillStyle.backgroundColor}; color:${pillStyle.color}`;
            } else return "";
        },
        loadingColor() {
            if (!this.filteredDivStyles) return [];
            let colors = this.filteredDivStyles.map(style => style.backgroundColor);
            if (!colors || !Array.isArray(colors)) return [];

            let repeats = 30; // Number of times to repeat
            let repeatedColors = [];

            for (let i = 0; i < repeats; i++) {
                repeatedColors.push(...colors);
            }

            return repeatedColors;
        },
        loadingText() {
            let colors = this.filteredDivStyles.map(style => style.color);
            if (!colors || !Array.isArray(colors)) return [];

            let repeats = 30; // Number of times to repeat
            let repeatedColors = [];

            for (let i = 0; i < repeats; i++) {
                repeatedColors.push(...colors);
            }

            return repeatedColors;
        },

        status() {
            return this.styleGuide.justLoaded;
        },
    },
    watch: {
        "styleGuide.divStyles": {
            handler: function (to, from) {
                if (to && to.length > 0) {
                    if (this.timeline) this.timeline.kill();
                    this.$nextTick(() => {
                        this.animateSequence();
                    });
                }
            },
            immediate: true,
            deep: true,
        },
    },
    async mounted() {
        this.colorArray = this.loadingColor;
        this.textColors = this.loadingText;
        // setTimeout(() => this.animateSequence(), 1500);
        // this.animateColors();
    },
    beforeUnmount() {
        try {
            this.timeline.kill();
        } catch (e) {}
    },
    methods: {
        getStyle(color) {
            return { width: "0%", height: "100vh", background: color, borderRadius: "0 100% 100% 0", filter: "blur(100px)" };
        },

        animateSequence() {
            if (this.timeline) this.timeline.kill();
            const self = this;
            this.$nextTick(() => {
                // Create a timeline that does not repeat automatically
                const tl = timeline({ repeat: -1 });
                this.timeline = tl;
                const textEl = this.$refs.text;
                const solid = this.$refs.solidBg;
                // Animate each layer sequentially
                this.loadingColor.forEach((_, index) => {
                    const el = this.$refs[`colorDiv${index}`];

                    if (el) {
                        // Animate width and border-radius
                        tl.to(
                            el,
                            {
                                onStart: () => {
                                    to(el, { width: `${100}%`, borderRadius: "0% 0% 0% 0%", filter: "blur(500px)", scale: 3, duration: 0.5, ease: "back.easeOut" });
                                    if (solid && self.colorArray[index]) to(solid, { background: self.colorArray[index], ease: "back.easeOut" });
                                    if (textEl) to(textEl, { color: self.textColors[index], duration: 0.5, ease: "back.easeOut" });
                                },
                            },
                            `+=0.5`,
                        );
                        tl.to(
                            el,
                            {
                                onStart: () => {
                                    to(el, { width: `${0}%`, borderRadius: "100%", left: "110vw", filter: "blur(500px)", scale: 2, duration: 1, ease: "back.easeOut" });
                                },
                            },
                            `+=0.0`,
                        );
                    }
                });

                // At the end of the sequence, reset all layers except the first
                this.loadingColor.forEach((_, index) => {
                    if (index > 0) {
                        // Skip the first layer
                        const el = this.$refs[`colorDiv${index}`];
                        if (el) {
                            tl.to(el, { width: "0%", duration: 0, immediateRender: true }, "+=0.5");
                        }
                    }
                });
            });
        },
    },
};
</script>
