<template>
    <StyleGuideContainer class="gap-3" title="Typography combos" section="typographyCombos">
        <debug-object class="x col-span-12" v-if="typographyCombos?.length > 0" :object="{ typography: typographyCombos }"></debug-object>
        <div class="col-span-12 grid grid-cols-12" v-if="typographyCombos && typographyCombos.length > 0">
            <!--                            {{ typographyCombos }}-->

            <div class="col-span-6" v-f="mostCommonCombo">
                <h4>Most Common</h4>
                <span class="d-block" :style="mostCommonCombo.header">The quick brown fox jumps over the lazy dog</span>
                <span class="d-block" :style="mostCommonCombo.p">The quick brown fox jumps over the lazy dog</span>
                <div class="py-3">
                    <text-style-details class="my-2" :style="mostCommonCombo.header"></text-style-details>
                    <text-style-details class="my-2" :style="mostCommonCombo.p"></text-style-details>
                </div>
            </div>
            <div class="col-span-6">
                <h4>Largest</h4>
                <span class="d-block" :style="likelyHeaderCombo.header">The quick brown fox jumps over the lazy dog</span>
                <span class="d-block" :style="likelyHeaderCombo.p">The quick brown fox jumps over the lazy dog</span>
                <div class="py-3">
                    <text-style-details class="my-2" :style="likelyHeaderCombo.header"></text-style-details>
                    <text-style-details class="my-2" :style="likelyHeaderCombo.p"></text-style-details>
                </div>
            </div>
            <div class="col-span-6">
                <span v-if="dedupTypographyCombos?.length && typographyCombos?.length > 0">{{ dedupTypographyCombos.length }} {{ typographyCombos.length }}</span>
                <template v-for="combo in dedupTypographyCombos">
                    <div class="f jcs aic my-5" v-if="combo.count">
                        <div class="c-30 f border-light f-13 aic jcc mr-3 bg-gray-50">{{ combo.count }}</div>
                        <div>
                            <!--                        {{combo.style.header.fontSize}}-->
                            <span class="d-block" :style="combo.style.header">
                                <!--                            <span class="d-block" :style="applyStyle(combo.style.header)">-->
                                <!--                            {{ combo.style.header.tag || "" }}-->
                                The Quick brown fox jumps over the lazy dog
                            </span>
                            <!--                            <span :style="applyStyle(combo.style.p)">-->
                            <span :style="combo.style.p">
                                <!--                            {{ combo.style.p.tag || "" }} -->
                                The Quick brown fox jumps over the lazy dog
                            </span>
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-6">
                <template v-for="combo in originalCombos">
                    <div class="f jcs aic my-5" v-if="combo.count">
                        <div class="c-30 f border-light f-13 aic jcc mr-3 bg-gray-50">{{ combo.count }}</div>
                        <div>
                            <!--                        {{combo.style.header.fontSize}}-->
                            <span class="d-block" :style="applyStyle(combo.style.header)">
                                <!--                            {{ combo.style.header.tag || "" }}-->
                                The Quick brown fox jumps over the lazy dog
                            </span>
                            <span :style="applyStyle(combo.style.p)">
                                <!--                            {{ combo.style.p.tag || "" }} -->
                                The Quick brown fox jumps over the lazy dog
                            </span>
                        </div>
                    </div>
                </template>
            </div>
            <!--            <template v-for="item in dedupTypographyCombos">{{ item }}</template>-->
            <template v-for="(styleValue, styleKey) in styles">
                <!--                <div class="f jcs aic my-5">-->
                <!--                    <div class="c-30 f border-light f-13 aic jcc mr-3 bg-gray-50">{{ styleKey.toUpperCase() }}</div>-->
                <!--                    <div>-->
                <!--                        <span class="d-block" :style="applyStyle(styleValue)">{{ styleKey }} The Quick brown fox jumps over the lazy dog</span>-->
                <!--                    </div>-->
                <!--                </div>-->
            </template>
        </div>
    </StyleGuideContainer>
</template>
<script>
import StyleGuideContainer from "@/components/styleGuide/Sections/Wrapper.vue";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import TextStyleDetails from "@/components/styleGuide/Typography/TextStyleDetails.vue";

export default {
    name: "StyleGuideTypographyCombos",
    mixins: [styleGuideMixin],
    components: { TextStyleDetails, DebugObject, StyleGuideContainer },
    computed: {
        // Deduplicate typography combinations based on their styles
        dedupTypographyCombos() {
            // Exit early if `this.typographyCombos` is not defined or null
            if (!this.typographyCombos) return;

            // Create a new Map to hold the deduplicated combinations
            const resultMap = new Map();

            // Loop through each element in `this.typographyCombos`
            this.typographyCombos.forEach(({ count, style }) => {
                // Loop through each style entry; `type` is the style type, `attributes` are its properties
                Object.entries(style).forEach(([type, attributes]) => {
                    // Destructure `attributes` into `tag` and `restAttributes`
                    const { tag, ...restAttributes } = attributes;

                    // Generate a unique key for each combination of type and remaining attributes
                    const key = `${type}:${JSON.stringify(restAttributes)}`;

                    // If the combination already exists in the Map, update its count
                    if (resultMap.has(key)) {
                        resultMap.get(key).count += count;

                        // Otherwise, add the new combination to the Map
                    } else {
                        resultMap.set(key, { count, style: { [type]: { ...restAttributes, tag } } });
                    }
                });
            });

            // Convert the Map's values to an array
            let results = Array.from(resultMap.values());

            // Sort the array by count in descending order
            results = results.sort((a, b) => b.count - a.count);

            // Return the sorted, deduplicated combinations
            return results;
        },

        originalCombos() {
            if (!this.typographyCombos) return;
            let results = this.typographyCombos;
            results = results.sort((a, b) => b.count - a.count);
            return results;
        },
    },
    data() {
        return {
            styles: {},
        };
    },
    props: {
        typographyCombos: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
