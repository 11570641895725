<template>
    <div class="f aic jcc mr-2 gap-1" @mouseleave="hoverOut">
        <div class="absolute top-9 z-10 pt-5">
            <div :style="{ background: color }">
                <ColorPicker :color="color" :sucker-area="suckerArea" :sucker-hide="true" :colors-default="[]" :sucker-canvas="suckerCanvas" class="mw-250 w-auto" theme="dark" @change-color="changeColor" @input-blur="inputBlur" @input-focus="inputFocus" @open-sucker="openSucker" />
            </div>
        </div>
    </div>
</template>
<script>
import { ColorPicker } from "vue-color-kit";
import "vue-color-kit/dist/vue-color-kit.css";
export default {
    name: "SimpleColorPicker",
    components: {
        ColorPicker,
    },
    props: {
        color: {
            type: String,
            default: "#59c7f9",
        },
    },
    data() {
        return {
            tempColor: "#59c7f9",
            suckerCanvas: null,
            suckerArea: [],
            isSucking: false,
            showPicker: false,
            selectedColor: null,
        };
    },
    methods: {
        async updateColorsOnTheme() {
            this.$emit("color-update", this.tempColor);
        },
        selectColor(color) {
            console.log("color");
            this.selectedColor = color;
            let convertedColor = this.hslToHex(this.document.theme.colors[color]);
            console.log(convertedColor);
            this.color = convertedColor;
            this.showPicker = true;
        },
        hoverOut() {
            this.$emit("close-picker");
        },
        changeColor(color) {
            this.$emit("update-color", color.hex);
        },
        updateColor(color) {
            this.$emit("color-update", color);
        },
        openSucker(isOpen) {
            if (isOpen) {
                // ... canvas be created
                // this.suckerCanvas = canvas
                // this.suckerArea = [x1, y1, x2, y2]
            } else {
                // this.suckerCanvas && this.suckerCanvas.remove
            }
        },
        inputFocus(event) {
            console.log(event);
            // this will get triggered on input field (hex and rgba) get focus
            // prop value will be FocusEvent object associated with the input
        },
        inputBlur(event) {
            console.log(event);
            // this  will get triggeredon input field (hex and rgba) get out of focus (blur event)
            // prop value will be FocusEvent object associated with the input
        },
    },
};
</script>
