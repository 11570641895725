<template>
    <transition mode="out-in" name="fade-up">
        <div>
            <transition name="fade-in">
                <div v-if="currentProductIndex" appear class="p-absolute p-fill bg-gray-900/30" style="z-index: 1000" @click.prevent="updateProp('currentProduct', null)"></div>
            </transition>
            <div class="px-6 relative">
                <transition mode="out-in" name="fade-up">
                    <div :key="productId" appear class="bg-snow z-10 overflow-hidden relative block shadow br-20" style="width: 100%; max-width: 1400px">
                        <div class="grid grid-cols-12 animated">
                            <div class="col-span-6 scrollSection h-auto">
                                <div class="x relative">
                                    <image-swiper :key="product.handle" :images="product.images" />
                                </div>
                            </div>
                            <div class="col-span-6 scrollSection overflow-y-scroll">
                                <div class="p-absolute top right p-3">
                                    <BaseButton icon="fa fa-times" icon-only size="xxs" @click.prevent="updateProp('currentProduct', null)" />
                                </div>
                                <div class="p-5 height-max-500">
                                    <pre class="o-5 text-xs text-balance">{{ productId }}</pre>
                                    <h1 :key="header" :style="getFontStyle(80)" class="block mb-2 text-balance">{{ product.title }}</h1>
                                    <div class="mb-4 !text-balance" v-for="(text, index) in product?.description?.split('\n')">
                                        <div v-if="index === 0" class="mb-4" style="max-width:80%;">
                                            <p :style="getFontStyle(20)" class="block !text-balance">{{ text }}</p>
                                            <a :href="product.url" :style="buttonStyle" class="text-center f aic jcc mt-3" target="_blank">View Product</a>
                                        </div>
                                        <span v-else class="mb-3" :style="getFontStyle(14)">{{ text }}</span>
                                    </div>
                                    <p v-if="product.price">{{ product.price }}</p>
                                    <div class="f flex-wrap gap-3 my-3">
                                        <base-badge v-for="badge in product.tags" :key="badge" :style="reverseButtonStyle" :text="badge" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="p-absolute p-fill x z-5 col-span-12 f aic jcc">
                    <div class="f aic jcb gap-3 x">
                        <BaseButton icon="fa fa-arrow-left" icon-only @click.prevent="updateProp('currentProduct', allProduct[currentProductIndex - 1])" />
                        <BaseButton icon="fa fa-arrow-right" icon-only @click.prevent="updateProp('currentProduct', allProducts[currentProductIndex + 1])" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss/pagination";
import "swiper/scss";
import styleGuideMixin from "@/mixins/StyleGuideMixins/StyleGuideMixin";
import ImageSwiper from "@/components/styleGuide/Sections/imageSwiper.vue";

export default {
    mixins: [styleGuideMixin],
    name: "ProductDetail",
    components: { ImageSwiper, BaseButton, TimeStamp, BaseBadge, Swiper, SwiperSlide },
    props: {
        product: { type: Object, default: () => ({}) },
        allProducts: { type: Array, default: () => [] },
    },
    data: function () {
        return { swiper: null, swiperInstance: null };
    },
    mounted() {
        this.$nextTick(() => {
            this.swiper = this.$refs.$swiper;
        });
    },
    methods: {
        onSwiper(swiperInstance) {
            this.swiperInstance = swiperInstance;
            this.swiper = this.$refs.swiper.$swiper;
        },
    },
    computed: {
        ...mapGetters("styleGuide/image", ["images"]),
        ...mapGetters("styleGuide", ["buttonStyles", "deduplicatedText", "assignedStyles", "allTextStyles"]),
        productId() {
            if (this.product?.images?.length > 0) {
                return this.product.images[0].id;
            } else return this.product.id || "";
        },
        currentProductIndex() {
            return this.allProducts.indexOf(this.product);
        },
        buttonStyle() {
            return this.buttonStyles[0];
        },
        reverseButtonStyle() {
            return {
                color: this.buttonStyle.backgroundColor,
                backgroundColor: this.buttonStyle.color,
            };
        },
    },
};
</script>
