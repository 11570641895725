<template>
    <div>
        <a class="bg-snow edit_button relative transition-all text-gray-900 hover:bg-gray-50/10 border border-gray-50 p-3 c-30 f aic jcc mix-blend-difference shadow" href="#">
            <div class="absolute p-fill z-1 blurred"></div>
            <i class="far relative z-1" :class="`fa-${icon}`"></i>
        </a>
    </div>
</template>
<script>
export default {
    name: "ToolButton",
    props: {
        icon: {
            type: String,
            default: "trash",
        },
    },
};
</script>
<style lang="scss">
.edit_button {
    //@apply opacity-0;
    @apply bg-opacity-50;
    @apply border-opacity-0;
    @apply backdrop-blur-2xl;
}
</style>
