import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "@/firebase";

async function findAllMatchingObjects(database, field = "url", value) {
    try {
        const q = query(collection(db, database), where(field, "==", value));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const matchingDocs = querySnapshot.docs.map(doc => {
                const data = doc.data();
                // console.log(doc.id);
                data.id = doc.id; // Adding the id to the object
                return data;
            });
            console.log("Found matching docs!", matchingDocs.length);
            return matchingDocs;
        } else {
            console.log("No match found");
            return [];
        }
    } catch (error) {
        console.error(`An error occurred while querying ${database} for matching ${field}s:`, error);
        throw error;
    }
}

export default findAllMatchingObjects;
