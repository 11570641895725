<template>
    <div v-if="user" class="bg-snow vh-100">
        <!--        <ProfileForm />-->
        <div class="container-slim pt-nav mt-nav">
            <div>
                <div>
                    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[900px] lg:w-full">
                        <div class="bg-white px-4 py-12 shadow sm:rounded-lg sm:px-12">
                            <div class="border-b border-gray-900/10 pb-4">
                                <h2 class="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                                <p class="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p>
                            </div>
                            <div class="x px-3">
                                <!--                            <upload-user-photo></upload-user-photo>-->
                                <!--                            <upload-image></upload-image>-->
                                <div class="forms py-5">
                                    <SimpleInput id="name" v-model="name" label="Name" type="text" />
                                    <SimpleInput id="email" v-model="email" autocomplete="current-email" label="Email address" type="email" />
                                    <SimpleInput id="firstName" v-model="firstName" label="First Name" type="text" />
                                    <SimpleInput id="lastName" v-model="lastName" label="Last Name" type="text" />
                                    <SimpleInput id="Brand" v-model="brand" label="Brand" required type="text" />
                                    <SimpleSelector v-model="role" label="User Role" />
                                    <div class="my-3">
                                        <label class="block text-sm font-medium leading-6 text-gray-900">Profile</label>
                                        <div v-if="user?.photoURL" class="c-50 overflow-hidden" style="border: 2px solid black">
                                            <img class="x" :src="user.photoURL" />
                                        </div>
                                    </div>
                                </div>
                                <!--                                <AdminManageTeam class="py-3" />-->

                                <div class="f gap-5 jce">
                                    <SimpleButton class="" soft @click.prevent="signOut" secondary>Log out</SimpleButton>
                                    <SimpleButton class="" @click="saveProfile" label="Save profile">Save Profile</SimpleButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from "@/firebase";
import { signOut } from "firebase/auth";
import moment from "moment";
import user from "@/mixins/usermixin";

import BaseTextarea from "@/components/CoreUI/BaseTextarea.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import BaseInput from "@/components/CoreUI/BaseInput.vue";
import UploadUserPhoto from "@/components/images/UploadUserPhoto.vue";
import KlaviyoOauth from "@/views/KlaviyoOauth.vue";
import AdminManageTeam from "@/components/Admin/AdminManageTeam.vue";
import UploadImage from "@/components/images/uploadImage.vue";
import SimpleInput from "@/views/SimpleInput.vue";
import SimpleButton from "@/views/SimpleButton.vue";
import { mapState } from "vuex";
import SimpleSelector from "@/views/SimpleSelect.vue";
import ProfileForm from "@/components/shad/examples/forms/Example.vue";
export default {
    components: { SimpleSelector, SimpleButton, SimpleInput, UploadImage, AdminManageTeam, KlaviyoOauth, UploadUserPhoto, BaseInput, BaseButton, BaseTextarea, ProfileForm },
    mixins: [user],
    data() {
        return {
            email: "",
            name: "",
            brand: "",
            firstName: "",
            lastName: "",
            role: {},
        };
    },
    watch: {
        user: {
            handler() {
                let user = this.user;
                this.email = user?.email;
                this.name = user?.name || user?.displayName;
                this.brand = user?.brand;
                this.firstName = user?.firstName;
                this.lastName = user?.lastName;
                this.role = user?.role;
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        ...mapState("user", ["user"]),
        createdAtFormatted() {
            if (this.user && this.user.createdAt) {
                return moment(parseInt(this.user.createdAt)).format("MMMM Do YYYY");
            }
        },
    },
    async mounted() {
        await this.$nextTick(() => {
            const user = this.$store.state.user.user;
            this.name = user?.name || user?.displayName || user?.email || "";
            this.brand = user?.brand || "";
            this.email = user?.email || "";
            this.firstName = user?.firstName || "";
            this.lastName = user?.lastName || "";
            this.role = user?.role || { id: "user", name: "User" };
        });
    },
    methods: {
        saveProfile() {
            let user = this.user;
            this.$store.dispatch("user/saveUser", {
                ...user,
                name: this.name || "",
                displayName: this.name || "",
                role: this.role || { id: "user", name: "User" },
                email: this.email || "",
                brand: this.brand || "",
                firstName: this.firstName || "",
                lastName: this.lastName || "",
            });
        },
    },
};
</script>
