// generateMixin.js
import webRequest from "@/mixins/ai/web_request";
import streamCompletion from "@/mixins/ai/stream_completion";
import { set } from "lodash-es";
import { formatObjectToString } from "@/mixins/objectToString";
import logMessages from "@/mixins/ai/log_messages";
import { mapGetters } from "vuex";
import generateEmailMixin from "@/mixins/ai/generateEmailMixin";
import groq from "@/mixins/ai/groqCompletion";
export default {
    mixins: [generateEmailMixin],
    data() {
        return {
            generativeLoader: false,
            generativeResult: null,
            generatingDone: false,
        };
    },
    computed: {
        ...mapGetters("stream", ["streamMessages", "selectedFunction", "model"]),
        ...mapGetters("styleGuide/llm", [
            "HeaderSamples",
            "ParagraphSamples",
            "ImageList",
            "AboutBrand",
            "EmailSubjectPreview",
            "productText",
            "ProductArray",
            "headerParagraph",
            "BrandValues",
            "WritingDevices",
            "Audience",
            "ValueProps",
            "SellingPoints",
            "EmailSubjects",
            "EmailPreviews",
            "ColorsString",
            "ExamplesFromCopyGuidelines",
            "ProductParagraphs",
            "CopyGuidelines",
            "EmailMessages",
            "CampaignMessages",
            "LandingPageMessages",
        ]),
        generativeFunctions() {
            return this.$store.state?.stream?.stream?.options?.functions || [];
        },
        selectedAction() {
            let action = this.selectedFunction;
            if (action) return action;
            return {};
        },
        currentModel() {
            let model = this.model;
            if (model && model.includes("gpt-3.5")) return "3.5-turbo";
            if (model && model.includes("gpt-4")) return "GPT4";
            return model;
        },
    },
    methods: {
        async listFiles() {
            try {
                let response = await webRequest("files/list");

                let data = await response.json();
                logGroup("Files", data);
                return data;
            } catch (error) {
                console.error("Error fetching files:", error);
            }
        },
        messageStream(result) {
            let { function_call, json } = result;
            let string = objectToMarkdown(result);
            return `\`\`\`json\n${JSON.stringify(result, null, 2)}\n\`\`\``; // this formats the json result into a string that wraps it so it can be displayed in chat with the right markdown.
        },
        async getGenerativeFunctions() {
            const response = await webRequest("getFunctions");
            let actions = await response.json();
            this.updateStreamProp("options.functions", actions);
            this.setAction(actions[0]);
        },
        setAction(action) {
            if (typeof action === "string") action = this.generativeFunctions.find(func => func.name === action);
            this.updateStreamProp("options.selectedFunction", action);
            try {
                this.buildMessagesForActions();
                this.setActionModel(action.name);
                // this.updateMessages();
            } catch (e) {
                console.error("Error building messages for action", e);
            }
        },
        /**
         * This method is used to add a new message to the Vuex store.
         * If the payload is a string, it converts it to a user message object using the userMessage function.
         * It then dispatches an action to the Vuex store to add the message.
         *
         * @param {Object|string} payload - The message to add. If it's a string, it's converted to a user message object.
         */
        pushToStoreMessage(payload) {
            if (typeof payload === "string") payload = userMessage(payload);
            this.$store.dispatch("stream/addMessage", payload);
        },
        updateMessages(message) {
            // this.updateStreamProp("messages", this.messagesWithContext);
            if (message) {
                this.$store.dispatch("stream/addMessage", userMessage(message));
            }
        },
        buildMessagesForActions() {
            try {
                let { name, prompt, excluded } = this.selectedAction;
                let messagesMapping = this.getMessagesMapping();
                let currentActionMessages = messagesMapping[name] || [];
                currentActionMessages = [sysMessage(prompt), ...currentActionMessages];
                //remove messages where the content is null
                currentActionMessages = currentActionMessages.filter(message => message?.content);
                // Update the messages property with the messages for the current action
                this.updateStreamProp("messages", currentActionMessages);
            } catch (e) {
                console.error("Error building messages for action", e);
            }
        },
        getMessagesMapping() {
            // let campaign = this.$store.state?.stream?.stream?.campaignData;
            // if (campaign) campaignMessage = userMessage(`This is the current state of the campaign \n\n${JSON.stringify(campaign.result)}`);

            try {
                // if (this.styleGuide?.copyGuidelines?.overview?.personas.length > 0) {
                //     personas = formatObjectToString({ overview: { personas: this.styleGuide?.copyGuidelines.overview.personas } });
                //     personas = userMessage(personas);
                // }
                // if (guidelines) guidelines = formatObjectToString(guidelines);

                return {
                    create_email_new: this.EmailMessages,
                    create_campaign: this.CampaignMessages,
                    create_email_section: this.EmailMessages,
                    create_landing_page: this.LandingPageMessages,
                    get_brand_voice: [
                        sysMessage(this.AboutBrand), //
                        userMessage(this.headerParagraph),
                    ],
                    get_brand_voice_continued: [
                        sysMessage(this.CopyGuidelines),
                        sysMessage(this.AboutBrand), //
                        userMessage(this.headerParagraph),
                    ],
                    get_brand_personas: [
                        userMessage(this.HeaderSamples), //
                        userMessage(this.ParagraphSamples),
                        sysMessage(this.CopyGuidelines),
                        sysMessage(this.ProductParagraphs),
                    ],
                    create_brand_voice_samples: [
                        sysMessage(this.AboutBrand), //
                        sysMessage(this.CopyGuidelines),
                        userMessage(this.HeaderSamples),
                        userMessage(this.ParagraphSamples),
                    ],
                    generate_synthetic_brand_copy: [
                        sysMessage(this.AboutBrand), //
                        userMessage(this.headerParagraph),
                        sysMessage(this.CopyGuidelines),
                        userMessage("Don't be too explicit about the product in the copy. Assume the customer is smart enough to understand the product."),
                    ],
                    // presentation: [userMessage(this.headerParagraph)],
                    create_typography_style_guide: [
                        sysMessage(this.AboutBrand), //
                        userMessage(`${JSON.stringify(this.$store.getters["styleGuide/allTextStyles"], null, 2)}`),
                        userMessage(`${JSON.stringify(this.$store.getters["styleGuide/fonts"], null, 2)}`),
                        userMessage(`${JSON.stringify(this.$store.getters["styleGuide/buttonStyles"], null, 2)}`),
                    ],
                };
            } catch (e) {
                console.error(e.message);
                return [];
            }
        },
        setActionModel(action) {
            const modelMapping = {
                //  GPT4
                create_landing_page: gpt316,
                create_landing_page: gpt4,
                create_landing_page_section: gpt3, //gpt4,

                // GPT3
                create_email_new: gpt3,
                // create_campaign: gpt316,
                // create_campaign: "ft:gpt-3.5-turbo-0125:asher:campaign:99UyXxCj",
                create_campaign: gpt3,
                experimental_create_campaign: gpt3,
                get_brand_personas: gpt3,
                generate_synthetic_brand_copy: gpt4,
                get_brand_voice: gpt3,
                get_brand_voice_continued: gpt3, //gpt316,
                create_brand_voice_samples: gpt3, // gpt316,
                presentation: gpt3, //gpt316,
                extract_text_content: gpt3, //gpt316,
                create_email_section: gpt3, //gpt316,
                create_typography_style_guide: gpt3, //gpt316,
                // create_brand_voice_samples: gpt4,
                // get_brand_voice: gpt4,
                // experimental_create_campaign: gpt316,
                // create_email_new: gpt4,
                // create_campaign: "gpt-3.5-turbo-1106",// just switched on 1/22/24 to the next
                // create_campaign: gpt4,
                // create_typography_style_guide: gpt4,
            };

            const model = modelMapping[action];

            if (model) {
                this.updateStreamProp("options.model", model);
            } else {
                console.error(`No model found for action: ${action}`);
            }
        },
        setActionTypographyGuide() {
            this.setAction("create_typography_style_guide");
        },
        setActionEmail() {
            this.setAction("create_email_new");
        },
        setActionEmailSection() {
            this.setAction("create_email_section");
        },
        setActionCampaign() {
            this.setAction("create_campaign");
        },
        setActionCampaignExperimental() {
            this.setAction("experimental_create_campaign");
        },
        setActionLandingPage() {
            this.setAction("create_landing_page");
        },
        setActionBrandPersonas() {
            this.setAction("get_brand_personas");
        },
        setActionBrandVoice() {
            this.setAction("get_brand_voice");
        },
        setActionBrandVoiceContinued() {
            this.setAction("get_brand_voice_continued");
        },
        setActionWritingSamples() {
            this.setAction("create_brand_voice_samples");
        },
        setActionPresentation() {
            this.setAction("presentation");
        },
        setCopywritingGuide() {
            this.setAction("generate_synthetic_brand_copy");
        },
        setActionExtractContent() {
            this.setAction("extract_text_content");
        },
        setActionClean() {
            this.setAction("extract_text_content");
        },
        async getEmbeddings(query, count = 3) {
            let response = await webRequest("embeddings/email", { id: this.styleGuide.id, query: query, productCount: 6, textCount: 20 });
            // let sources = await webRequest("embeddings/sources", { id: this.styleGuide.id, query: query, count: 100 });
            // make the above a promise
            let { prompt } = await response.json();
            // sources = await sources.json();
            // console.log("Embeddings", prompt);
            let string = `${prompt}`;
            return string || "";
        },
        async callFunction(mods, otherData) {
            console.groupCollapsed(`%c⚙️ Tool called`, purple, mods.function_call);
            consoleTableReduced(mods, ["messages"]);
            logGroup("messages", this.streamMessages);
            console.groupEnd();
            logGroup(`${mods?.function_call}`, { ...mods });

            let { input, model, messages, temperature, function_call, section, length, persistChat, identifier, brand, functions, type, storeKey, prop, localHandle, save, returnTo, nested, id } = mods;
            let { itemIndex, campaignId, campaignIndex, campaignName } = mods; //
            // this.setAction(function_call);
            let setup = {
                ...mods,
                params: {
                    input, // this is the user input that will added to messages.
                    model, // this is the model that will be used to generate this.
                    messages, // this is the messages array that will be used to generate this.
                    temperature, // this is the temperature that will be used to generate this.
                    function_call, // this is the function_call name that will be called to generate this. It needs to match the fucntion being called.
                    functions, // this is the functions object that will be used to generate this. If blank it will use the server and name to route to the right function.
                    length, // this is the length of the message that will be generated.
                    persistChat, // Should this stream into the messages array?
                    identifier, // this helps route the stream to the right place and find it later.
                    nested,
                    section,
                    id,
                },
                campaignData: {
                    name: campaignName,
                    campaignId,
                    brand, // this is the brand id, typically set from the $route.params.id
                    campaignIndex, // this is the index of the schedule item
                    itemIndex, // this is the node of the schedule item
                    type, // what type of campaign is this? Currently only used for campaigns.
                    object_type: type, // what type of object is this?
                },
                storage: {
                    storeKey, // This is the key of the store to save to. For example $store.state.styleGuide
                    prop, // the prop used in the store
                    localHandle, //the prop used in the local component
                    messageIndex: messages.length - 1, // the index of the message to update
                    save, // save to firebase
                    returnTo, // prop within the StreamStore to return to
                },
            };
            if (otherData) setup = { ...otherData, ...setup };
            // obj.returnTo = `campaignData.result.schedule_items.${obj.campaignIndex}.campaign_items.${obj.itemIndex}.result`;
            await this.streamFunction(setup); // call the stream function in the generativeMixin
        },
        addMessage(message) {
            this.$store.dispatch("stream/addMessage", message);
        },
        async streamFunction(setup) {
            let obj = { ...setup.params, ...setup.campaignData, ...setup.storage }; // merge all the objects together
            obj.messageIndex = obj?.messages?.length || undefined; // set the message index to the length of the messages array
            obj.object_type = obj.type; // set the object type to the type
            if (obj.identifier && obj.prop) obj.returnTo = obj.prop + "." + obj.identifier; // set the returnTo to the prop + identifier if it exists

            let finalResult; // set up a variable to hold the final result in a scope that can be accessed by all the functions.
            let { input, save, messages, prompt, function_call, model, system, silent, temperature, functions, length, messageIndex, nested } = obj; // destructure the object after merging.
            let logName = function_call + "-" + input; // set the log name to the function call + input, this is for console logging.
            if (input && input.length > 0) {
                // check if the last message was an ai message
                this.addMessage(userMessage(input)); // if the input exists and has a length, it pushes the input to the store as a user message as a user message.
                messages = this.streamMessages; // update the messages to the messages in the store if they've been updated.
            }
            await streamCompletion(
                this.$store, // pass the store
                input, // pass the input, this could be a string or an array
                messages, // pass the messages array to add messages to the stream
                system, // pass the system message optionally, but this could also be contained in the messages array.
                () => {
                    this.beforeStarting(obj); // this function lives in the "parent" file of the mixin (the file that calls this function). It handles things that are needed before starting the generation.
                    this.beforeStartingGeneration(obj); // this function lives in this mixin and is used to set the generativeLoader, generativeResult, and generatingDone variables.
                    this.addMessage(aiMessage("Generation"));
                },
                (token, function_objects, json) => {
                    finalResult = json; // set the final result to the json
                    this.whileProcessing(obj, { token, function_objects, json });
                    this.whileProcessingGeneration(obj, { token, function_objects, json }); // call the whileProcessing to handle the stream and route the data.
                },
                () => {
                    this.afterCompletingGeneration(obj); // this function lives in this mixin and is used to reset the props for loader, result and done variables within the generationMixin
                    this.afterCompletion({ ...obj, result: finalResult }); // pass the afterCompletion function to call anything needed after completing the generation. This will be in the file for the specific function. It handles things like saving the result, triggering follow-on functions, etc.
                },
                model, // pass the model to use for the generation (this is set in the file calling this function)
                silent, // pass the silent boolean to determine if every step should be logged to the console or not.
                logName, // set the log name to the function call + input, this is for console logging.
                temperature, // pass the temperature to use for the generation (this is set in the file calling this function).
                functions, // pass the functions to use for the generation (this is set in the file calling this function).
                function_call, // pass the function call to use for the generation (this is set in the file calling this function).
                length, // pass the length to use for the generation (this is set in the file calling this function).
            );
        },
        beforeStartingGeneration(obj) {
            this.generativeLoader = true; // set the generative loader to true. I've put it in this mixin so it can be used by all the functions if needed.
            this.generativeResult = null; // set the generative result to null so that it can be used by all the functions if needed
            this.generatingDone = false; // set the generating done to false so that it can be used by all the functions if needed
            console.log("Messages for generation", obj.messages);
            this.addMessage(aiMessage("Generation"));
        },
        async whileProcessingGeneration(obj, data) {
            let { token, function_objects, json } = data; // destructure the data streaming back from the server. It could be a "token" which returns the single token (which should be used to accumulate into a string - usually message content), the function object, or the OpenAI function's json.
            let { prop, brand, campaignId, section, campaignIndex, persistChat, storeKey, itemIndex, localHandle, type, messageIndex, returnTo, identifier, nested } = obj; // destructure the object that was passed in to the function. This is the object that was merged together in the streamFunction function.
            if (typeof json === "object") {
                obj = { ...obj, result: json }; // merge the object that was passed in from streamFunction. It includes additional data from the initial setup; which could be campaign data, settings, etc.
                this.generativeResult = obj; // This updates the mixin's property for the generative result. This is doesn't have a use yet but i think it would be useful.
                if (localHandle) set(this, localHandle, obj); // if a 'localHandle' was passed in from setup, it takes the key of the calling file and set it. For example, if the calling file had "localHandle: 'styleGuide'", it would set the styleGuide property on the file accessing this mixin.
                this.streamToMessages(obj); // if persistChat is true, it streams the result to the messages array in the stream store. This can be used to see the streaming results in chat, but it can also be used to save the results to the database, "chat" add revisions with context, etc.
                if (section) {
                    console.log("Updating Section");
                    let existing = { headline: "", body: "", imageIndex: 0, button: { show: true, url: "", text: "Shop now" } };
                    let updatedObject = { ...existing, ...json };
                    this.updateStreamProp(returnTo, updatedObject, true);
                } else {
                    if (prop && prop === "campaignData" && identifier) {
                        this.updateStreamProp(`campaignData.result.${identifier}`, obj); // if a returnTo was passed in, it updates the stream property with the result. This is used to update the result of the stream in the stream store.
                    } else if (returnTo) {
                        if (nested) {
                            let existing = this.$store.state?.styleGuide?.styleGuide?.[prop];
                            let updatedObject = { ...existing, ...json };
                            this.updateStyleGuideProp(prop, updatedObject);
                            // if the storeKey is "styleGuide", it uses the helper method to stream to the styleGuide store. Note: Must be called from a file containing the styleGuideMixin with the helper in it.
                        } else if (storeKey === "styleGuide") this.updateStyleGuideProp(prop, json);
                        else this.updateStreamProp(returnTo, obj.result, true);
                        // else this.updateStreamProp("result", obj); // if no returnTo was passed in, it updates the stream property with the result. This is used to update the result $store.state.stream.stream.result.
                    } else if (prop === "editor") {
                    } // if a returnTo was passed in, it updates the stream property with the result. This is used to update the result of the stream in the stream store.
                }
                return obj;
            }
        },
        streamToMessages(obj) {
            let messageIndex = this.streamMessages.length - 1;
            this.updateStreamProp(`messages.${messageIndex}.content`, this.messageStream(obj.result)); // this updates the messages array in the stream store with the result of the stream. It uses the messageStream method to format the json result into a message.
        },
        afterCompletingGeneration(obj) {
            let { input, save, messages, prompt, function_call, model, system, silent, type } = obj;
            this.generatingDone = true;
            this.generativeLoader = false;
            this.generativeResult.object_type = type;
            console.groupCollapsed(`%c⚙️ Tool done`, purple, function_call);
            consoleTableReduced(obj, ["messages"]);
            console.log("Messages", obj.messages);
            console.log("Final result", this.generativeResult);
            console.groupEnd();
        },
        async groqPromptAdjustment(prompt, messages) {
            try {
                return await groq({
                    seed: 1,
                    messages: [
                        sysMessage("You are an AI Tasked with rewriting the prompt for this email. You do not chat."), //
                        ...messages.slice(1, 5),
                        userMessage(this.prompt),
                        userMessage("Write a very detailed plain english version of this prompt as if you're writing instructions to task your coworker who is a marketer and copywriter. Make sure it's clear. discourage the use of any discounts and don't worry about the design – focus on copywriting and don't mention visual design."),
                        userMessage("Format should be sentences, not markdown"),
                        userMessage("Add additional copywriting suggestions for this email. "),
                        sysMessage("Discourage the use of discounts or testimonials. Provide examples. Encouraging the humanization of product titles and offer examples."),
                        sysMessage("Discourage the use of discounts or testimonials. Suggest a different topic if they are included"),
                        userMessage("DISCOURAGE CUSTOMER TESTIMONIALS OR STORIES. Suggest a different topic if they are included"),
                        userMessage("DISCOURAGE DISCOUNTS. Suggest a different topic if they are included"),
                        sysMessage("Your response should be plaintext paragraphs. DO NOT CHAT. PROMPT + INSTRUCTIONS:"), //
                        aiMessage("Ok, I won't mention discounts or testimonials, and I'll remove technical details from product names. I'll also suggest a different topic if they are included."),
                        sysMessage("Remove technical details from product names"), //
                    ],
                });
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        async expandPrompt(input) {
            try {
                let response = await webRequest("expand", { input });
                let data = await response.json();
                return data.completion;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
};
