<template>
    <tr>
        <td class="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div class="flex items-center">
                <div class="h-11 w-11 flex-shrink-0">
                    <img class="h-11 w-11 rounded-full" v-if="userImage" :src="userImage" alt="" />
                    <div class="h-11 w-11 rounded-full bg-green-100 flex items-center justify-center" v-else>
                        <span class="text-green-300 text-2xl" v-if="name">{{ name[0] }}</span>
                    </div>
                </div>
                <div class="ml-4">
                    <div class="font-medium text-gray-900">{{ name }}</div>
                    <div class="mt-1 text-gray-500">{{ email }}</div>
                </div>
                <!--                <pre>              {{ user }}</pre>-->
            </div>
        </td>
        <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
            <time-stamp v-if="lastSeen" :date="lastSeen" time :class="lastSeenColor" />
            <div class="text-gray-900" v-if="user.title">{{ user.title }}</div>
            <div class="mt-1 text-gray-500" v-if="user.department">{{ user.department }}</div>
        </td>
        <td class="whitespace-nowrap px-3 py-3 text-gray-500 f-15">
            <!--            <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Active</span>-->
            <base-badge size="xs" color="green" v-if="currentlyActive">
                <div class="f aic jcc gap-2">
                    <PulsingLight color="green" />
                    Currently active
                </div>
            </base-badge>
            <base-badge color="blue" class="inline-flex aic jcc" v-else-if="active">
                <div class="f aic jcc gap-2">Active</div>
            </base-badge>
            <base-badge color="gray" v-else-if="inactive">Inactive</base-badge>
        </td>
        <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500 fc">
            <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20" v-if="brand?.name">
                <span>{{ brand.name }}</span>
            </span>
            <div>{{ brand.klaviyoAPI }}</div>
        </td>
        <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
            <span v-if="user?.role?.name">{{ user.role.name }}</span>
        </td>
        <td class="relative whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
            <a href="#" class="text-indigo-600 hover:text-indigo-900">
                Edit
                <span class="sr-only">, {{ user.name }}</span>
            </a>
        </td>
    </tr>
</template>
<script>
import fetchData from "@/mixins/firebase/fetchFromFirebase";
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import BaseBadge from "@/components/CoreUI/BaseBadge.vue";
import PulsingLight from "@/views/PulsingLight.vue";

export default {
    name: "UserTableItem",
    components: { PulsingLight, BaseBadge, TimeStamp },
    data() {
        return {
            brand: {},
        };
    },
    props: {
        user: {},
    },
    mounted() {
        setTimeout(() => {
            this.getBrand();
        }, 200);
    },
    methods: {
        async getBrand() {
            if (this.user?.brand) {
                let brandId = this.user.brand;
                let brand = await fetchData("brands", brandId);
                this.brand = brand;
            }
        },
    },
    computed: {
        email() {
            return this.user.email;
        },
        lastSeen() {
            return this.user.lastSeen || this.user.lastSignInTime;
        },
        lastSeenColor() {
            return this.currentlyActive ? "text-green-500" : "";
        },
        currentlyActive() {
            if (this.lastSeen) {
                // Convert the lastSeen timestamp to a Date object
                const lastSeenDate = new Date(this.lastSeen);

                // Get the current timestamp
                const currentTimestamp = Date.now();

                // Calculate the time difference in milliseconds
                const timeDiff = currentTimestamp - lastSeenDate.getTime();

                // Convert the time difference to minutes
                const minutesDiff = timeDiff / (1000 * 60);

                // Check if the user was last seen within the past 5 minutes
                return minutesDiff <= 60 * 1;
            }
            return false;
        },
        active() {
            // seen in the last week
            const lastSeenTimestamp = new Date(this.lastSeen).getTime();
            return lastSeenTimestamp > Date.now() - 7 * 24 * 60 * 60 * 1000;
        },
        inactive() {
            return !this.active && !this.currentlyActive;
        },

        name() {
            return this.user.name || this.user.displayName;
        },
        userImage() {
            let url = this.user.image || this.user.photoURL;
            if (url && url.includes("http")) {
                return url;
            }
            return false;
        },
    },
};
</script>
