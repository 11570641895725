function handleFunctionResponse(functionResponse) {
    let function_call = functionResponse;
    try {
        if (function_call.arguments) function_call.arguments = JSON.parse(function_call.arguments);
        return function_call;
    } catch (e) {
        try {
            if (function_call.arguments) {
                return function_call;
            }
        } catch (e) {
            console.error("Error processing function response", e);
            console.log(function_call);
        }
    }
    return function_call;
}

export default handleFunctionResponse;
