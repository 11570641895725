<template>
    <div class="f gap-3 jcs text-left" :class="{ 'fc inline-flex': !bubble }">
        <div v-for="(item, index) in list" :key="'a' + index" class="f f-34 border-brand border-b-brand last:border-0 pb-3 !last:pb-0 gap-3 mb-2 aic" :class="classes">
            <i class="far mr-2 fa-check" v-if="check" />
            <span>{{ item }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "BaseChecklist",
    props: {
        list: {},
        title: {},
        icon: {},
        check: {
            type: Boolean,
            default: true,
        },
        bubble: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
