<template>
    <div class="mb-md-5 relative mb-3">
        <div class="places">
            <h5 v-if="title" class="animated swingInUp duration-4">
                <animated-text :text="title" class="d-block fwb mb-0"></animated-text>
            </h5>
            <div v-if="map && map.image" class="map-view border-light br-10 animated swingInUp duration-5 d-2 overflow-hidden shadow">
                <img :src="map.image" class="y x object-cover" />
            </div>
            <div v-if="places && places.places && !mobile && !slider" class="f flex-wrap gap-3">
                <template v-for="(place, index) in places.places">
                    <PlacesCard :class="`d-${index * 3}`" :place="place" class="place_card animated swingInUp duration-5"></PlacesCard>
                </template>
            </div>
            <template v-else-if="(places && places.places && mobile) || slider">
                <Swiper ref="swiper" :centeredSlides="true" :height="260" @swiper="onSwiper" :loop="true" :pagination="{ clickable: true }" :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }" :slidesPerView="'auto'" :spaceBetween="10" class="py-1" style="margin-left: -3rem; margin-right: -3rem">
                    <SwiperSlide v-for="(place, index) in places.places" :class="{ 'mw-200': mobile }" class="place_card d-block br-10 overflow-hidden bg-snow shadow dark:bg-base-700">
                        <PlacesCard :class="`d-${index * 3}`" :place="place" class="x animated swingInUp duration-5 y overflow-hidden"></PlacesCard>
                        <!--                    <Place class="border-light br-10 y animated swingInUp duration-5 mb-4 overflow-hidden bg-snow shadow hover:shadow-lg" :class="`d-${index * 2}`" :sight="place"></Place>-->
                    </SwiperSlide>
                </Swiper>
                <div class="border-light swiper-button-next p-absolute top bottom c-50 z-5 f align-fixed-center aic jcc -right-5 bg-snow shadow" @click="swiperInstance.slideNext()">
                    <i class="fas fa-arrow-right"></i>
                </div>
                <div class="border-light swiper-button-prev p-absolute top bottom c-50 z-5 f align-fixed-center aic jcc -left-5 bg-snow shadow" @click="swiperInstance.slidePrev()">
                    <i class="fas fa-arrow-left"></i>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import PlacesCard from "@/components/chat/specialMessages/PlacesCard.vue";
import AnimatedText from "@/components/CoreUI/animatedText.vue";
import Place from "@/components/chat/specialMessages/Search/Place.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss/pagination";
import "swiper/scss";
export default {
    name: "LocalResults",
    components: { Place, AnimatedText, PlacesCard, Swiper, SwiperSlide },
    data: function () {
        return {
            swiper: null,
            swiperInstance: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.swiper = this.$refs.$swiper;
        });
    },
    methods: {
        onSwiper(swiperInstance) {
            this.swiperInstance = swiperInstance;
            this.swiper = this.$refs.swiper.$swiper;
        },
    },
    props: {
        slider: {
            type: Boolean,
            required: false,
            default: false,
        },
        places: {
            type: Array,
            required: false,
        },
        map: {
            type: Object,
            required: false,
        },
        title: {
            type: String,
            required: true,
            default: "Recommended places",
        },
    },
};
</script>
<style lang="scss">
.place_card{
  @apply border;
  @apply rounded-xl;
  @apply overflow-hidden;
  @apply bg-snow;
  @apply shadow;
  @apply dark:bg-base-700;
  @apply w-full;
  @apply width-max-200;
  //mw-250 x br-10 overflow-hidden bg-white shadow
}

.map-view{
  @apply transition-all;
  @apply object-cover;
  @apply aspect-h-5;
  @apply aspect-w-16;

  &:hover{
    @apply transition-all;
    @apply aspect-h-2;
    @apply aspect-w-4;
  }
}

hr{
  @apply my-5;
  // style the hr
  border-top: 1px solid;
  @apply border-t border-gray-100;
  @apply dark:border-base-500;
}
</style>
