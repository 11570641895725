let roles = [
    {
        id: "admin",
        name: "Admin",
        permissions: {
            artifacts: {
                styleGuides: ["view", "edit", "create"],
                emails: ["view", "edit", "create"],
                landingPage: ["view", "edit", "create"],
                pageSection: ["view", "edit", "create"],
                guidelines: ["view", "edit", "create"],
                copyExample: ["view", "edit", "create"],
            },
            actions: ["send", "invite"],
        },
    },
    {
        id: "brand_manager",
        name: "Brand Manager",
        permissions: {
            artifacts: {
                styleGuides: ["view", "edit", "create"],
                emails: ["view", "edit", "create"],
                landingPage: ["view", "edit", "create"],
                pageSection: ["view", "edit", "create"],
                guidelines: ["view", "edit", "create"],
                copyExample: ["view", "edit", "create"],
            },
            actions: ["send"],
        },
    },
    {
        id: "brand_marketer",
        name: "Brand Marketer",
        permissions: {
            artifacts: {
                styleGuides: ["view"],
                emails: ["view", "edit", "create"],
                landingPage: ["view", "edit", "create"],
                pageSection: ["view", "edit", "create"],
                guidelines: ["view"],
                copyExample: ["view", "edit", "create"],
            },
            actions: ["send"],
        },
    },
    {
        id: "viewer",
        name: "Viewer",
        permissions: {
            artifacts: {
                styleGuides: ["view"],
                emails: ["view"],
                landingPage: ["view"],
                pageSection: ["view"],
                guidelines: ["view"],
                copyExample: ["view"],
            },
            actions: [],
        },
    },
];
export default roles;
