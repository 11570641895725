<template>
    <div class="p-4 relative">
        <transition name="fade-up" mode="out-in">
            <ModifyAssistant v-if="active && editing" :original-assistant="assistant" />
            <div v-else-if="assistant" class="width-min-200">
                <h6 v-if="active" class="font-semibold mb-1">Active Assistant</h6>
                <h3 class="font-semibold text-lg mb-1">{{ assistant.name || "Unnamed" }}</h3>
                <ModelBadge :model="assistant.model" />
                <div v-if="assistant?.description" class="text-sm text-gray-600" :class="{ 'line-clamp-1': !active }">Description: {{ assistant.description }}</div>
                <!--                <div v-if="assistant?.instructions" class="text-sm text-gray-60 line-clamp-1" :class="{ 'line-clamp-1': !active }">{{ assistant.instructions }}</div>-->
                <!--                <div v-if="assistant?.id" class="text-sm text-gray-600">{{ assistant.id }}</div>-->
                <TimeStamp class="text-xs text-gray-600 block" :date="assistant.created_at" />
                <!--            <div v-if="assistant?.tools?.length" class="text-sm text-gray-600">Tools: {{ listTools(assistant.tools) }}</div>-->
                <!-- Other details such as file_ids and metadata can be handled here based on the need and permissions -->
                <!--            <pre>{{ assistant }}</pre>-->
                <div class="f aic gap-2 mt-2">
                    <div class="inline-flex fc gap-1 py-1">
                        <div v-for="tool in assistant.tools">
                            <AssistantToolCard :tool="tool" />
                        </div>
                    </div>
                    <div v-if="allFiles?.length > 0">
                        <h6 class="font-semibold mb-0">{{ files.length }} Files</h6>
                        <!--<div class="inline-flex fc gap-1 py-1">-->
                        <!--<div v-for="file in files" class="text-xs">-->
                        <!--{{ file }}-->
                        <!--</div>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="text-sm text-gray-600">No active assistant selected.</p>
            </div>
        </transition>
        <div v-if="active" class="p-absolute top right p-5">
            <BaseButton @click="editing = !editing" size="sm" rounded>Edit Assistant</BaseButton>
        </div>
        <div class="p-absolute top right p-3 f gap-2">
            <BaseButton @click="deleteAssistant({ id: assistant.id })" size="xxs" rounded class="x" icon-only icon="fas fa-trash o-100 !text-red-400"></BaseButton>
            <BaseButton @click="setActiveAssistant(assistant?.id)" size="xxs" rounded class="x" icon-only icon="fas fa-plus"></BaseButton>
        </div>
        <!-- Other component content -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModelBadge from "@/views/Chat/ModelBadge.vue";
import ModifyAssistant from "@/views/Chat/ModifyAssistant.vue";
import AssistantToolCard from "@/views/Chat/AssistantToolCard.vue";
import TimeStamp from "@/views/Chat/TimeStamp.vue";
import BaseButton from "@/components/CoreUI/BaseButton.vue";
import { mapActions } from "vuex";
export default {
    name: "CurrentChatInfo",
    components: { BaseButton, TimeStamp, AssistantToolCard, ModifyAssistant, ModelBadge },
    props: {
        assistant: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    computed: {
        ...mapGetters("assistants", ["user", "threads", "assistants", "threadId", "assistantId"]),
        // get thread,assistant, assistant list, and thread list from store
        allFiles() {
            if (this.$store.state?.stream?.stream?.options?.files && this.assistant?.file_ids) {
                let assistantFiles = this.$store.state.stream.stream.options.files.filter(file => this.assistant.file_ids.includes(file.id));
                if (assistantFiles.length > 0) return assistantFiles;
                return this.assistant?.file_ids;
            }
        },
        files() {
            return this.assistant?.file_ids;
        },
    },
    methods: {
        ...mapActions("assistants", ["deleteAssistant"]),
        setActiveAssistant(id) {
            this.$store.dispatch("assistants/setActiveAssistant", id);
        },
        fixTitle(str = "") {
            // turn pascal, kebab, camel or snake case to title case
            str = str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
                return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
            });
            str = str.replace(/\s+/g, ""); // trim
            str = str.replace(/-+/g, " "); // collapse dashes
            str = str.replace(/^-+/, ""); // trim leading dashes
            str = str.replace(/-+$/, ""); // trim trailing dashes
            return str;
        },
        listTools(tools) {
            return tools.map(tool => tool.type).join(", ");
        },
        // other methods
    },
};
</script>
