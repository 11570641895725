import { mapGetters } from "vuex";
import styleGuideCSSMixin from "@/mixins/StyleMixins/StyleGuideCSSMixin";
import webRequest from "@/mixins/ai/web_request";
import JSON5 from "json5";
import recieveStream from "@/mixins/ai/receive_stream";
import _ from "lodash";
import findMatchingObject from "@/mixins/firebase/findMatchinObject";
import saveFirebase from "@/mixins/firebase/saveToFirebase";
import fetchData from "@/mixins/firebase/fetchFromFirebase";
export default {
    // mixins: [styleGuideCSSMixin],
    data() {
        return {
            // styleIds: [],
            // fontLinks: [],
            updateQueue: [],
            updateInterval: null, // Store the interval ID
        };
    },
    computed: {
        // ...mapGetters("styleGuide", ["styleGuide", "styleGuides", "typography", "textStyles", "colors", "fonts", "images", "logos", "videos", "buttonStyles", "images", "headerImage", "divStyles", "allTextStyles", "textSamples", "svgs", "hasImages", "website", "logo"]),
    },
    methods: {
        async findMatchingBrand(url) {
            try {
                let brand;
                // let brand = await this.findURLinSortedBrands(url);
                this.updateStyleGuideProp("name", brand.name);
                this.updateStyleGuideProp("description", brand.description);
            } catch (error) {
                console.error(error.message);
            }
        },
        logIncomingData(type, data) {
            const label = `%c🎨 ${type.charAt(0).toUpperCase() + type.slice(1)}:`;
            console.groupCollapsed(label, purple);
            console.log(data);
            console.groupEnd();
        },
        logIncomingDataError(type, error, data) {
            const typeProtected = type ? type.charAt(0).toUpperCase() + type.slice(1) : "Unknown";
            console.groupCollapsed(`%c🎨 Key: ${typeProtected}:`, hiliteError, error.message);
            console.trace();
            console.error(error);
            if (data) console.log(data);
            console.groupEnd();
        },
        async styleGuideResponse(response) {
            const reader = response.body.getReader();
            const utf8Decoder = new TextDecoder("utf-8");
            let buffer = "";

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                buffer += utf8Decoder.decode(value);
                let chunks = buffer.split("\n");
                // if (styleGuide && !styleGuide.id) { //     newItem = await this.$store.dispatch("styleGuide/saveStyleGuide", { ...this.styleGuide }); //     this.updateStyleGuideProp("id", newItem.id); //     this.$router.push({ path: `/styleGuide/${this.styleGuide.id}` }); //     await this.$store.dispatch("styleGuide/saveStyleGuide", { ...this.styleGuide }); // }
                // const typeToPropMapping = {textSamples: "textSamples", fonts: "typography.fonts", colors: "colors", images: "images", image: null, // special case, requires a different functionlogos: "logos",videos: "videos",buttons: "buttonStyles",textStyles: "textStyles",email: "email",svgs: "svgs",metadata: "metadata",shopifyImages: "shopifyImages",additionalText: "additionalText",};
                chunks.slice(0, -1).forEach(chunk => {
                    this.processStyleGuideChunk(chunk);
                });
                buffer = chunks[chunks.length - 1];
            }
            return buffer;
        },
        async createStyleGuide(url, id, options, whileProcessing, afterCompleting) {
            this.updateStyleGuideProp("justLoaded", "Getting started");
            let sectionsToHandle = [
                "allTextStyles",
                "assignedStyles",
                "backgrounds",
                "base",
                "baseBackground",
                "baseColor",
                "baseSize",
                "bg_color_palette",
                "bodyStyle",
                "buttonStyles",
                "buttons",
                "colors",
                "deduplicatedText",
                "description",
                "divStyles",
                "email",
                "fonts",
                "headerStyles",
                "headersStyles",
                "id",
                "images",
                "logos",
                "metadata",
                "name",
                "pStyles",
                "pageTitle",
                "shopify",
                "textSamples",
                "text_styles",
                "title",
                "typography",
                "typographyCombos",
                "url",
                "videos",
                "websiteUrl",
            ];
            let { payload, multiple, refresh, sections, updating, stream, newGuide, originalID, url_to_scrape, textOnly, saveImages } = options;
            let styleGuide;
            let exisitingGuide;
            if (!sections || newGuide) {
                console.log("New guide or refresh");
                // this.updateStyleGuideProp("images", []);
                // this.setStyleGuide();
                if (newGuide) styleGuide = { images: [], url: url, id: id };
                if (refresh) {
                    let keysToClear = [
                        "allTextStyles",
                        "assignedStyles",
                        "backgrounds",
                        "base",
                        "baseBackground",
                        "baseColor",
                        "baseSize",
                        "bg_color_palette",
                        "bodyStyle",
                        "buttonStyles",
                        "buttons",
                        "colors",
                        "deduplicatedText",
                        "divStyles",
                        "div_styles",
                        "fonts",
                        "headerStyles",
                        "headersStyles",
                        "logos",
                        "pStyles",
                        "pageTitle",
                        "shopify",
                        "textSamples",
                        "text_styles",
                        "title",
                        "typography",
                        "typographyCombos",
                        "videos",
                    ];
                    // loop over the keys and delete the keys to clear
                    keysToClear.forEach(key => {
                        if (styleGuide?.[key]) styleGuide[key] = null;
                    });
                    styleGuide = _.omitBy(styleGuide, _.isEmpty);
                    styleGuide.id = id;
                }
                if (multiple) styleGuide = multiple;
                let existingGuide = await findMatchingObject("brands", "url", url);
                if (existingGuide) styleGuide = { styleGuide, ...existingGuide };

                if (originalID) styleGuide.originalID = originalID;
                this.$store.dispatch("styleGuide/setStyleGuide", styleGuide);
                if (newGuide) {
                    console.log("StyleGuide id", this.$route.params.id);
                    let n = await this.$store.dispatch("styleGuide/saveStyleGuide", { ...this.styleGuide });
                    console.log("StyleGuide new", n);

                    await this.$nextTick();
                    if (!window.location.href.includes("admin")) this.$router.push({ path: `/styleGuide/${this.styleGuide.id}` });
                }
            }
            if (refresh || updating) this.updateStyleGuideProp("loading", true);
            let newItem = styleGuide;
            //TODO: REVISIT THIS if (updating) {//     try {//         styleGuide.id = this.$route.params.id;//         id = this.$route.params.id;//         newItem.id = this.$route.params.id;//     } catch (error) {//         console.error(error.message);//     }// }

            try {
                let requestObject = { url: url, id: this.$route.params.id, options };
                if (textOnly) requestObject.textOnly = true;
                if (payload) requestObject.payload = payload;
                // if (!sections) this.loading = true; TODO fix this
                if (sections) requestObject.sections = sections;
                if (url_to_scrape) requestObject.url_to_scrape = url_to_scrape;
                //TODO FIX HANDLING OF SHOPIFY IMAGES if (sections && sections.includes("images") && this.styleGuide.shopify) sections = ["images", "shopify"];
                this.updateStyleGuideProp("url", url);
                this.updateStyleGuideProp("fetching", true);
                const response = await webRequest("getStyleGuide", requestObject, stream);
                if (stream) return await this.streamStyleGuideResponse(response, whileProcessing, afterCompleting);
                if (response.ok) {
                    console.log("Processing Style Guide");
                    await this.styleGuideResponse(response);
                    if (id) this.updateStyleGuideProp("id", this.$route.params.id);
                    try {
                        // await this.$store.dispatch("styleGuide/addStyleGuide", newItem);
                        if (window.location.href.includes("styleGuide")) {
                            if (!id) this.$router.push({ path: `/styleGuide/${newItem.id}` });
                            else this.$router.push({ path: `/styleGuide/${id}` });
                        }
                    } catch (err) {
                        console.error(err.message);
                    }
                    this.loading = false;
                } else console.log("styleGuide error");

                this.updateStyleGuideProp("fetching", false);
            } catch (err) {
                this.error = err.message || "An error occurred";
                console.error(err);
            } finally {
                this.loading = false;
            }
            console.log("Finished Style Guide");
        },
        justLoaded(section) {
            this.updateQueue.push(section);

            if (!this.updateInterval) {
                this.updateInterval = setInterval(() => {
                    if (this.updateQueue.length > 0) {
                        const nextSection = this.updateQueue.shift();
                        this.performUpdate(nextSection);
                    } else {
                        clearInterval(this.updateInterval);
                        this.updateInterval = null;
                    }
                }, 300); // Update every 1 second
            }
        },
        performUpdate(section) {
            const keys = {
                allTextStyles: "Sorting typography",
                assignedStyles: "Sorting typography",
                backgrounds: "Background Colors",
                doneFetching: "Done",
                base: "Getting styles",
                imagesDone: "Getting Images",
                additionalText: "Fetching sources",
                advancedTextSources: "Copywriting",
                advancedTextSamples: "Organizing writing samples",
                products: "Getting products",
                baseBackground: "Getting styles",
                baseColor: "Getting styles",
                baseSize: "Getting styles",
                bg_color_palette: "Getting styles",
                bodyStyle: "Getting styles",
                buttonStyles: "Sorting Button styles",
                listStyles: "Getting styles",
                name: "Checking name",
                description: "Dialing in description",
                metadata: "Getting styles",
                pageTitle: "Getting styles",
                headers: "Sorting typography",
                websiteURL: "Getting styles",
                buttons: "Sorting Button Styles",
                colors: "Got Colors",
                deduplicatedText: "Sorting typography",
                divStyles: "Brand colors",
                div_styles: "Brand colors",
                fonts: "Fetching Typefaces",
                headerStyles: "Sorting typography",
                headersStyles: "Sorting typography",
                text_styles: "Sorting typography",
                body: "Sorting typography",
                logos: "Finding logos",
                pStyles: "Sorting typography",
                shopify: "Finding products",
                productCopy: "Finding products",
                textSamples: "Brand copy samples",
                typography: "Typefaces",
                typographyCombos: "Sorting typography",
                videos: "Getting videos",
                email: "Looking up emails",
                images: "Loading images",
                image: "Loading images",
                sourcesDone: "Gathering sources",
            };
            if (section === "fetchComplete") {
                this.updateStyleGuideProp("justLoaded", "");
                clearInterval(this.updateInterval);
                this.updateInterval = null;
                this.updateQueue = [];
            } else {
                if (keys.hasOwnProperty(section)) {
                    const value = keys[section];
                    this.updateStyleGuideProp("justLoaded", value);
                } else {
                    this.updateStyleGuideProp("justLoaded", "Organizing information");
                    console.log("loading", section);
                }
            }
        },
        async finishUpdatingStyleGuide() {
            console.log("Finishing StyleGuide");
            try {
                let listID;
                let originalBrand;
                let foundBrand = await findMatchingObject("brandsList", "url", this.styleGuide.url).then(brand => {
                    if (brand) {
                        listID = brand.id;
                        originalBrand = brand;
                        // this.updateStyleGuideProp("name", brand.name);
                        // this.updateStyleGuideProp("description", brand.description);
                    }
                });
                await this.handleDataUpdated();
                await saveFirebase("brandsList", { ...originalBrand, updated: new Date() }, listID);
                console.groupCollapsed("%cFinished Updating", purple);
                console.log("Comparing IDs", listID, this.styleGuide.id);
                console.log("Original brand", originalBrand);
                console.log("StyleGuide", this.styleGuide);
                console.groupEnd();
            } catch (e) {
                console.error(e);
            }
        },
        async streamStyleGuideResponse(response, whileProcessing, afterCompleting) {
            return await recieveStream(
                response,
                (token, function_object, function_arguments) => {
                    whileProcessing(token, function_object, function_arguments);
                },
                g,
                () => {
                    afterCompleting();
                },
            );
        },
        addStyleGuideImage(image) {
            this.$store.dispatch("styleGuide/addStyleGuideImage", image);
        },
        updateProgressStatus(data) {
            this.logIncomingData("progress", data);
            try {
                let progress = this.$store?.state?.styleGuide?.progress;
                if (progress) {
                    if (progress !== data) {
                        //merge the progress
                        if (progress) {
                            progress = { ...progress, ...data };
                        } else {
                            progress = data;
                        }
                    }
                    this.updateProp("progress", progress);
                }
            } catch (e) {
                console.error(e);
            }
        },
        processStyleGuideChunk(chunk) {
            try {
                const chunkJSON = JSON.parse(chunk);
                const { type, data } = chunkJSON; // console.log(chunkJSON);
                // if (type in typeToPropMapping) {// if(type ==='fonts') this.updateStyleGuideProp(typeToPropMapping[type], data); // console.log(`${type.charAt(0).toUpperCase() + type.slice(1)}:`, data);// }
                if (type === "progress") return this.updateProgressStatus(data);
                if (type !== "image") this.logIncomingData(type, data);
                if (type === "fonts") this.handleStyleGuideFonts(data);
                if (type === "image") this.addStyleGuideImage(data);
                else if (type === "fetchNewImages") this.handleFetchNewImages(data);
                else if (type === "buttons") this.handleStyleGuideButtons(data);
                else if (type === "shopifyImages") this.handleShopifyImages(data);
                else if (type === "typography") this.handleStyleGuideTypography(data);
                else if (type === "productCopy") this.handleProductCopy(data);
                else if (type === "products") this.handleProducts(data);
                else if (type === "imagesDone") this.handleImagesDone(data);
                else if (type === "imagesUpdated") this.handleImagesDone(data);
                else if (type === "sourcesDone") this.handleSourcesDone(data);
                else if (type === "sourcesUpdated") this.handleSourcesDone(data);
                else if (type === "productsUpdated") this.handleProducts(data);
                else if (type === "button_styles") console.log("skipping button styles");
                else if (type === "buttonStyles") this.handleStyleGuideButtons(data);
                else if (type === "email") this.handleStyleGuideEmails(data);
                else if (type === "name") this.handleName(data);
                else if (type === "description") this.handleDescription(data);
                else if (type === "fetchComplete") this.handleFetchComplete(data);
                else if (type === "advancedTextSamples") this.handleMoreTextSamples(data);
                else if (type === "additionalText") this.handleAdditionalText(data);
                else if (type === "syncSource") this.handleSyncSource(data);
                else if (type === "extracted_text") this.handleExtractedText(data);
                if (type === "dataUpdated") this.handleDataUpdated();
                else this.updateStyleGuideProp(type, data);
                this.justLoaded(type);
                // this.$store.dispatch("styleGuide/saveStyleGuide", this.styleGuide);
                // this.debounceSaveToFirebase(); // TODO removed this temporaryoly
                this.debounceFetchStyleGuide(this.$route.params.id);
            } catch (error) {
                console.error(error);
                this.logIncomingDataError(chunk, error, chunk);
            }
        },

        async handleAdditionalText(data) {
            await this.$store.dispatch("styleGuide/getSources");
        },
        async handleDataUpdated(data) {
            await this.debounceFetchStyleGuide(this.$route.params.id);
            // await this.$store.dispatch("styleGuide/fetchStyleGuide", { id: this.$route.params.id });
            this.$nextTick(() => {
                try {
                    this.applyStyles();
                } catch (e) {}
                this.$forceUpdate();
            });
        },
        fixStrings(string = "", key) {
            try {
                if (!string) string = "";
                string = string.replace(/#/g, "");
                string = string.replace(/\*\*/g, "");
                string = string.replace(/\d+\sReviews/g, " ");
                // processed = processed.replace(/\b\d{4,}([-]\d+)*\b/g, "");
                string = string.replace(/\b\d+([-]\d+)+\b/g, "");
                string = string.replace(/includes:/gi, "");
                string = string.replace(/size:/gi, "");
                string = string.replace(/select:/gi, "");
                string = string.replace(/color:/gi, "");
                string = string.replace(/quantity:/gi, "");
                string = string.replace("(auto replenishes)", "");
                string = string.replace("Auto replenishes", "");
                string = string.replace("size:", "");
                string = string.replace(/\$\d+(\.\d{2})?/g, " ");
                string = string.replace(/\£\d+(\.\d{2})?/g, " ");
                string = string.replace(/(\u00A0\s*)+/g, " ");
                string = string.replace(/(\s|\u00A0)+/g, " ");
                string = string.replace(/original price:/gi, ""); // remove 'quantity:', case-insensitive
                string = string.replace(/current price:/gi, ""); // remove 'quantity:', case-insensitive
                string = string.replace(/bestseller /gi, ""); // remove 'quantity:', case-insensitive
                string = string.replace(/\$\d+(\.\d{2})?/g, " "); // remove prices
                string = string.replace(/This is a carousel with auto-rotating slides./gi, ""); // remove spaces
                string = string.replace(/Activate any of the buttons to disable rotation./gi, ""); // remove spaces
                string = string.replace(/Use Next Previous buttons to navigate, or jump to a slide with the slide dots./gi, ""); // remove spaces
                string = string.replace(/\\n/g, "\n");
                if (key.includes("paragraph") && string.length < 20) string = "";
                if (key.includes("header") && string.length < 15) string = "";
                if (key.includes("divs") && string.length < 15) string = "";
                if (string.startsWith('"')) string = "";
                if (string.startsWith("“")) string = "";
                string = string
                    .split("\n")
                    .map(str => {
                        return str.trim() !== "" ? str.trim() : null;
                    })
                    .filter(Boolean)
                    .join("\n");
                let existsInAnyHeader = false;
                if (this.textSamples?.headers) {
                    for (let i = 1; i <= 6; i++) {
                        const headerKey = `h${i}`;
                        if (this.textSamples.headers[headerKey] && this.textSamples.headers[headerKey].includes(string)) {
                            existsInAnyHeader = true;
                            // break;
                        }
                    }
                }

                // If the string doesn't exist in any of the headers, return it
                // if (!existsInAnyHeader) {
                return string;
                // }
            } catch (e) {
                console.log(e.message, string);
            }
        },
        concatAndDeduplicate(arr1 = [], arr2 = [], key) {
            if (!Array.isArray(arr1)) arr1 = [];
            if (!Array.isArray(arr2)) arr2 = [];

            const combinedArray = Array.from(new Set([...arr1, ...arr2]));

            const processedArray = combinedArray
                .map(input => {
                    let processed = input;
                    processed = this.fixStrings(processed, key);

                    return processed;
                })
                .filter(string => string !== "");

            let filteredArray = [];

            for (let i = 0; i < processedArray.length; i++) {
                let currentString = processedArray[i];
                if (currentString) currentString = currentString.toLowerCase(); // convert to lower case
                let isSubstring = false;

                for (let j = 0; j < processedArray.length; j++) {
                    let nextString = processedArray[j];
                    if (nextString) nextString = nextString.toLowerCase(); // convert to lower case
                    if (i !== j && nextString?.includes(currentString)) {
                        // convert to lower case
                        isSubstring = true;
                        break;
                    }
                }

                if (!isSubstring) {
                    filteredArray.push(processedArray[i]);
                }
            }

            return filteredArray.sort((a, b) => {
                const alphabeticalOrder = a.toLowerCase().localeCompare(b.toLowerCase()); // convert to lower case for case-insensitive comparison
                if (alphabeticalOrder !== 0) return alphabeticalOrder;
                return b.length - a.length;
            });
        },
        async handleSourcesDone(data) {
            this.logIncomingData(`Sources updated`);
            await this.$store.dispatch("styleGuide/getSources");
            // await this.getBrandImages(this.$route.params.id);
        },
        async handleImagesDone(data) {
            if (data) {
                this.logIncomingData(`Images updated`);

                await this.getBrandImages(this.$route.params.id);
            }
        },
        handleExtractedText(data) {
            if (!this.styleGuide.ai) this.updateStyleGuideProp("ai", {});
            this.updateStyleGuideProp("ai.website_text", data);
        },
        handleSyncSource(data) {
            console.log("Incoming data:", data);
            // return;
            let textSamples = data.textSamples;

            // Initialize if it's null or undefined
            if (!this.styleGuide.additionalText) {
                this.styleGuide.additionalText = [];
            }
            data = [data];
            let additionalText = this.styleGuide.additionalText;
            console.log("Additional text:", additionalText);
            data.forEach(item => {
                const index = additionalText.findIndex(text => text.url === item.url);
                item.updated = new Date();
                // If a URL match is found, replace the existing item
                if (index !== -1) {
                    additionalText[index] = item;
                } else {
                    // Otherwise, add the new item to the array
                    additionalText.push(item);
                }

                console.log("Processed item:", item);

                // Update the state; assuming updateStyleGuideProp does this
                this.updateStyleGuideProp("additionalText", additionalText);
            });
            if (textSamples) this.handleMoreTextSamples(textSamples);
        },
        handleMoreTextSamples(data) {
            let existingSamples = this.textSamples;
            let newSamples = data;

            // Loop through root keys (headers, paragraphs, etc.)
            for (const key in existingSamples) {
                if (key === "headers") {
                    // Handle nested keys within headers (h1, h2, h3, etc.)
                    for (const hKey in existingSamples[key]) {
                        if (!existingSamples[key][hKey]) existingSamples[key][hKey] = [];
                        existingSamples[key][hKey] = this.concatAndDeduplicate(existingSamples[key][hKey], newSamples[key][hKey], key);
                    }
                } else {
                    if (!existingSamples[key]) existingSamples[key] = [];
                    existingSamples[key] = this.concatAndDeduplicate(existingSamples[key], newSamples[key], key);
                }
            }

            // Update textSamples with the newly combined and deduplicated samples
            // this.textSamples = existingSamples;
            this.updateStyleGuideProp("textSamples", existingSamples);
            // return this.textSamples;
        },
        async handleFetchComplete(data) {
            this.loading = false;
            this.updateStyleGuideProp("loading", false);
            await this.finishUpdatingStyleGuide();
        },
        handleName(data) {
            if (!this.styleGuide.name) {
                this.updateStyleGuideProp("name", data);
            }
        },
        handleDescription(data) {
            if (!this.styleGuide.description) {
                this.updateStyleGuideProp("description", data);
            }
        },
        handleStyleGuideButtons(data) {
            this.updateStyleGuideProp("buttonStyles", data);
        },
        handleStyleGuideTypography(data) {
            if (!this.styleGuide.typography) this.updateStyleGuideProp("typography", {});
            if (Array.isArray(data)) {
                data.forEach((item, key) => {
                    this.logIncomingData(key, item);
                });
            } else if (typeof data === "object") {
                Object.keys(data).forEach(key => {
                    this.logIncomingData(`Typography: ${key}`, data[key]);
                    // Assuming updateStyleGuideProp can handle nested keys like "typography.fontSize"
                    this.updateStyleGuideProp(`typography.${key}`, data[key]);
                });
            }
            // this.updateStyleGuideProp("typography", data);
        },
        // debounce saveToFirebase
        debounceSaveToFirebase(...args) {
            this.debouncedSaveToFirebase =
                this.debouncedSaveToFirebase ||
                _.debounce((...args) => {
                    this.$store.dispatch("styleGuide/saveStyleGuide", this.styleGuide);
                }, 500);

            this.debouncedSaveToFirebase(...args);
        },
        debounceFetchStyleGuide: _.debounce(async function (id) {
            this.debounceFetchSources(id);
            let styleGuide = await fetchData("brands", id);
            this.$store.dispatch("styleGuide/setStyleGuide", styleGuide);
        }, 1000),
        debounceFetchSources: _.debounce(async function (id) {
            this.$store.dispatch("styleGuide/image/getImages", id);
            this.$store.dispatch("styleGuide/getProducts");
            this.$store.dispatch("styleGuide/getSources");
            this.$store.dispatch("styleGuide/getCollections");
        }, 3000),
        handleFetchNewImages(data) {
            const images = this.styleGuide?.images || [];
            let newImages = [...images, ...data];
            this.updateStyleGuideProp("images", newImages);
        },
        handleProductCopy(data) {
            if (Array.isArray(data)) {
                const seen = new Set();
                const dedupedCopy = data.filter(item => {
                    if (seen.has(item)) {
                        return false;
                    } else {
                        // replace all multiple \n+ with a single space
                        if (!item) item = "";
                        if (item) item = item.replace(/(\n)+/g, "\n");
                        if (item) item = item.trim();
                        if (item) seen.add(item);
                        return true;
                    }
                });
                this.updateStyleGuideProp("textSamples.productCopy", dedupedCopy);
            }
        },
        async handleProducts(data) {
            this.logIncomingData(`Products updated`);
            await this.getSubcollection(this.$route.params.id, "products");
            // this.updateStyleGuideProp("products", data);
        },
        handleShopifyImages(data) {
            let images = this.styleGuide?.images || [];
            // filter out existing shopify images
            images = images.filter(image => image.kind !== "shopify");
            // add new shopify
            images = [...images, ...data];
            this.updateStyleGuideProp("images", images);
        },
        async handleStyleGuideFonts(data) {
            if (!this.styleGuide?.typography) this.updateStyleGuideProp("typography", {});
            this.updateStyleGuideProp("fonts", data);
            this.applyStyles();
            setTimeout(() => {
                this.updateStyleGuideProp("loadedFonts", true);
            }, 3000);
        },
        handleStyleGuideEmails(data) {
            let textSamples = this.styleGuide.textSamples;
            textSamples.emailSubjects = data.map(email => {
                return email.subject || "";
            });
            textSamples.emailPreviews = data.map(email => {
                return email.preview || "";
            });

            this.updateStyleGuideProp("email", data);
            this.updateStyleGuideProp("textSamples", textSamples);
        },
    },
};
