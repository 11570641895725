<template>
    <div ref="presentation_container">
        <!--        v-if="showContainer"-->
        <!--        <div class="container" v-if="queue.research.stream">-->
        <!--            {{ queue.research.stream }}-->
        <!--        </div>-->
        <!--        <div v-if="true" class="vh-90 sectionBg gradient-mint-white">-->
        <div v-if="$store.state.presentation.queue.showProgress" class="vh-90 sectionBg gradient-mint-white">
            <!--                <div class="p-fixed p-fill scrollSection z-5 bg-base-900/50" v-if="queue.showProgress">-->
            <PresentationProgress class="mx-auto" :key="'progress' + $route.params.id" />
        </div>
        <div v-else :style="`transition:200ms background ease-in;background:${backgroundColor || ''}`" class="mainScroll vh-100 pb-28">
            <DocumentsToolbar class="x" @summarize="summarize" @enhance-presentation="enhancePresentation" @enhance-colors="enhanceColors"></DocumentsToolbar>
            <div class="px-2">
                <div class="mt-nav container" v-if="$store.state.presentation.showDebugger">
                    <p class="fwb">Document</p>
                    <debug-object :object="document"></debug-object>
                    <p class="fwb">Queue</p>
                    <debug-object v-if="queue" :object="queue"></debug-object>
                </div>
                <Transition appear enter-active-class="swingInUp animated stagger-1">
                    <DocumentSummary v-if="showSummary" :key="document.id" :document="document" class="x mw-2100 mx-auto" />
                </Transition>
                <div v-if="document && !document.hideSlides" :key="$route.params.id" class="mw-2200 mx-auto">
                    <!--                    <SerpapiImage></SerpapiImage>-->
                    <pre class="visually-hidden">{{ imagesList}}</pre>
                    <div v-for="(section, index) in slides" :id="`section` + index" :key="`slide-${$route.params.id}-${index}`" :ref="`section` + index">
                        <div ref="slidesContainer" class="p-relative d-block z-0">
                            <Transition :enter-active-class="`swingInUp animated stagger-${index + 2}`" appear leave-active-class="fadeOut animated">
                                <SlideTemplate :id="$route.params.id + 'slide' + index" :key="$route.params.id + index + 'slide'" :ref="`slide-${index}`" :document="document" :index="index" :section="section" :slide="section" @click="setSectionIndex(index)"></SlideTemplate>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="bottom z-5 fixed right-20 block bg-red px-2 py-1" v-if="tryGoogleImages">Google <div v-if="$store.state.presentation?.queue?.loading">LOADING</div></div>-->

        <FloatingMenu :document="document" @start-new-deck="$emit('startNewDeck')" @new-deck="$emit('newDeck')" @new-deck-modal="newDeckModal"></FloatingMenu>
    </div>
</template>
<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { toRaw } from "vue";
import { mapActions, mapGetters } from "vuex";
import DocumentSummary from "@/components/Presentations/slides/DocumentSummary.vue";
import DocumentsToolbar from "@/components/documents/DocumentsToolbar.vue";
import SlideTemplate from "@/components/Presentations/slides/SlideContainer.vue";
import documentManagementMixin from "@/mixins/DocumentArchive/documentManagementMixin";
import presentationMixin from "@/mixins/presentations/presentationMixin";
import FloatingMenu from "@/components/Presentations/slides/FloatingMenu.vue";
import BrowseCommand from "@/mixins/Chat/Commands/BrowseCommand";
import PresentationProgress from "@/components/Presentations/slides/PresentationProgress.vue";
import DebugObject from "@/components/chat/specialMessages/DebugObject.vue";
import updateQueueProp from "@/mixins/ai/update_queue_prop";
import updateDocumentProp from "@/mixins/ai/update_document_prop";

export default {
    name: "PresentationSlides",
    components: { DebugObject, PresentationProgress, DocumentSummary, SlideTemplate, FloatingMenu, DocumentsToolbar },
    mixins: [presentationMixin, documentManagementMixin, BrowseCommand],
    data() {
        return {
            currentSection: 1,
            newDeck: false,
        };
    },
    props: {
        slideOverride: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters("presentation", ["document", "queue"]),
        slides() {
            if (this.slideOverride) {
                return this.slideOverride.sections;
            } else {
                return this.document.sections;
            }
        },
        themeColors() {
            return this.document.theme_colors_hsl;
        },
        tryGoogleImages() {
            return this.queue.tryGoogleImages;
        },

        showSlide() {
            // return true;
            return this.document && this.document.sections && this.document.sections.length > 0;
        },
        showContainer() {
            // return true;
            return this.document && this.document.theme && this.document.theme.colors;
        },
        showSummary() {
            if (this.document && this.document.summary) {
                return true;
            }
        },
        showSlides() {
            // return true;
            return this.document && this.document.sections && this.document.theme && this.document.theme.colors && this.document.sections[0].title;
        },
    },
    async mounted() {
        // window.browseWebsiteSimple = this.browseWebsiteSimple;
        // window.researchTopic = this.researchTopic;
        // window.googleQuery = this.buildGoogleSearchQuery;
        // window.tryForShopifyInfo = this.tryForShopifyInfo;
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll());
    },
    async created() {
        // if (this.document?.sections?.length > 0) {
        if (this.$route.params.id) {
            updateQueueProp(this.$store, "fetching", true);
            await this.getPresentation(this.$route.params.id);
        }

        // }
    },
    methods: {
        ...mapActions("presentation", ["getPresentation", "savePresentation", "addSlide", "updatePresentation"]),
        newDeckModal(payload) {
            this.newDeck = true;
            this.$emit("newDeckModal", payload);
        },
        applyColors() {
            setTimeout(() => {
                const document = toRaw(this.document);
                console.error(document);
                this.document.sections.forEach((section, index) => {
                    console.error("applyingColors");
                    //get elementbyId and set background-color

                    this.$refs["slide-" + index].style.backgroundColor = "red";
                });
            }, 1000);
        },
        initScrollTrigger() {
            gsap.registerPlugin(ScrollTrigger);
            const box = document.querySelectorAll(`.slide`);
            const boxes = gsap.utils.toArray(`.slide`);

            // tweens applied for each box that triggers when they enter the viewport
            boxes.forEach((el, i) => {
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: "top bottom", // top of container hits bottom of viewport
                        end: "top center", // bottom of container hits center of viewport
                        markers: false,
                        scrub: 0,
                    },
                });
                let st = ScrollTrigger.create({
                    trigger: el,
                    start: "top center", // top of container hits bottom of viewport
                    end: "bottom center", // bottom of container hits center of viewport
                    onEnter: ({ progress, direction, isActive }) => {
                        console.log(progress, direction, isActive, i);
                        this.setSectionIndex(i);
                        this.savingStateInit();
                        // console.error(this.$refs[`slide-${i}`][0].$refs.slide[0][0].$refs);
                    },
                    onEnterBack: ({ progress, direction, isActive }) => {
                        console.log(progress, direction, isActive, i);
                        this.setSectionIndex(i);
                        this.savingStateInit();
                        // console.error(this.$refs[`slide-${i}`][0][`image-${sections[i].id}`]);
                    },
                });
                tl.fromTo(
                    el,
                    {
                        scale: 0.8,
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                        scale: 1,
                        //ease back out
                        ease: "back.out",
                    },
                );
            });
        },
    },
    watch: {
        "document.sections": {
            async handler(newValue, oldValue) {
                if (newValue && oldValue && newValue.length && oldValue.length && newValue.length > oldValue.length) {
                    console.log("new section added");
                    for (const section of newValue) {
                        if (section.title === "New slide") {
                            const index = this.document.sections.indexOf(section);
                            await this.enhanceBody("", index, this.extractPresentationText, section, this.document);
                        }
                    }
                    // find the slide with text that says "New slide"
                }
            },
            deep: true,
            immediate: true,
        },
        "document.image_style": {
            handler(newValue, oldValue) {
                // if (this.document.google_image_query) {
                //     updateDocumentProp(this.$store, "tryGoogleImages", true);
                // }
            },
            deep: true,
            immediate: false,
        },
        themeColors: {
            handler(oldValue, newValue) {
                if (this.document && this.document.theme) {
                    if (this.themeColors?.primary) {
                        let colors = this.getColorScheme(this.themeColors);
                        // console.log("COLORS", colors);
                        updateDocumentProp(this.$store, "theme", colors);
                        this.setColors(colors?.colors);
                    }
                }
            },
            deep: true,
            immediate: true,
        },
        document: {
            async handler(newValue, oldValue) {
                if (newValue && newValue.sections && newValue.sections.length) {
                    if (newValue.sections && newValue.sections.length > 0 && newValue.sections[0].title) {
                        if (!this.document.complete) updateQueueProp(this.$store, "tryGoogleImages", true);
                        // newValue.theme &&
                        // newValue.theme.colors
                        this.newDeck = false;
                    }
                }
            },
            immediate: true,
            deep: true,
        },
        $route: {
            async handler(id) {
                if (this.$route.params.id) {
                    await this.getPresentation(this.$route.params.id);
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
@keyframes figureEight {
    0% {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}

.lemniscate {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 100px;
    animation: figureEight 1.5s linear infinite;
}
</style>
